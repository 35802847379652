import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import CommonHeader from "../../../portal/modules/public/common/common-header";
import { ReloadTenantEntityInfo } from "../../../portal/modules/public/login/components/utils";
import { removeTenant } from "../../../redux/actions/global";
import { LOGIN_PATH, LOGOUT_PATH } from "../../../constants";
import { PortalLink } from "../../../services/utils";
import { clearLanguage } from "../../../redux/actions/language";
import MainLoading from "../../../portal/layouts/components/main-loading/main-loading";
import { clearRedux } from "../../../redux/actions/clear-redux";

/**
 * Manage client settings
 *
 *
 * @param {*} param0
 * @returns
 */
const ClientLoader = ({ clientInfo, auth, children, loggedInDateTime }) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({
    isNotFound: false,
    isSystemError: false,
  });
  const removeCachedTenant = () => dispatch(removeTenant());
  const removeLanguage = () => dispatch(clearLanguage());
  const clearStorage = () => dispatch(clearRedux());
  const pathname = history.location.pathname;
  const search = history.location.search;

  useEffect(() => {
    const getClient = async () => {
      let currentClientLoginUrl = PortalLink(
        `${LOGIN_PATH.LOGIN}/${clientInfo?.tenantCode}`
      );
      if (!!clientInfo?.entityCode) {
        currentClientLoginUrl += `/${clientInfo?.entityCode}`;
      }

      let _error = { ...error };
      _error.isNotFound = false;
      _error.isSystemError = false;

      if (!pathname.startsWith(`/${LOGIN_PATH.LOGIN}`)) {
        setLoading(false);
        setError(_error);
        return;
      }

      if (
        pathname.startsWith(`/${LOGIN_PATH.LOGIN}`) &&
        auth &&
        !!loggedInDateTime &&
        pathname.toUpperCase() === currentClientLoginUrl.toUpperCase()
      ) {
        setLoading(false);
        setError(_error);
        return;
      }

      if (
        pathname.startsWith(`/${LOGIN_PATH.LOGIN}`) &&
        auth &&
        !!loggedInDateTime &&
        pathname.toUpperCase() !== currentClientLoginUrl.toUpperCase()
      ) {
        let loginUrl = "";
        const differentInSeconds = (Date.now() - loggedInDateTime) / 1000;
        if (differentInSeconds > 1) {
          loginUrl = pathname + search;
        }

        setLoading(false);
        setError(_error);

        if (!!loginUrl) {
          history.push({
            pathname: PortalLink(`${LOGOUT_PATH.LOGOUT}`),
            state: {
              newLoginUrl: pathname + search,
            },
          });
        }
        return;
      }

      try {
        setLoading(true);
        removeCachedTenant();
        removeLanguage();
        clearStorage();

        let _clientInfo = await ReloadTenantEntityInfo();
        pathname = pathname.replace(
          pathname.split("/")[2],
          _clientInfo.tenantCode
        );
        if (pathname.split("/").length > 3) {
          pathname = pathname.replace(
            pathname.split("/")[3],
            _clientInfo.entityCode
          );
        }
        if (history.location.pathname !== pathname) {
          history.replace(pathname + search);
        }
      } catch (err) {
        // display UI according to response status
        if (err?.response?.status === 404) {
          _error.isNotFound = true;
        }

        if (err?.response?.status === 500) {
          _error.isSystemError = true;
        }
        setError(_error);
      } finally {
        setLoading(false);
      }
    };

    getClient();

    return () => {
      setLoading(true);
      setError({
        isNotFound: false,
        isSystemError: false,
      });
    };
  }, [pathname]);

  if (JSON.stringify({ ...error }).includes("true")) {
    return <CommonHeader {...error} />;
  }

  if (loading) {
    return <MainLoading />;
  }

  return children;
};
export default ClientLoader;
