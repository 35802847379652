import { LOGIN_PATH } from "../../constants";
import {
  CLEAR_TENANT,
  RELOAD_TENANT,
  SET_DEVICE_TYPE,
  SET_GLOBAL,
  BROADCAST_TENANT,
} from "../reduxConstants";
const pathWithTenantEntityName = [
  LOGIN_PATH.LOGIN,
  LOGIN_PATH.FORGOT_PWD,
  LOGIN_PATH.FST_LOGIN,
  LOGIN_PATH.PWD_EXP,
  LOGIN_PATH.RST_PWD,
];

const initialState = {
  clientInfo: {
    tenantCode: pathWithTenantEntityName.includes(
      window.location.pathname.split("/")[1]
    )
      ? window.location.pathname.split("/")[2]
      : undefined,
    entityCode: pathWithTenantEntityName.includes(
      window.location.pathname.split("/")[1]
    )
      ? window.location.pathname.split("/")[3]
      : undefined,
    authenticationTypes: [],
  },
};

export default function global(state = initialState, action) {
  switch (action.type) {
    case SET_GLOBAL: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case RELOAD_TENANT: {
      return {
        ...state,
        clientInfo: action.payload,
      };
    }
    case BROADCAST_TENANT: {
      return {
        ...state,
        clientInfo: action.payload,
      };
    }
    case CLEAR_TENANT: {
      return {
        ...state,
        clientInfo: {},
      };
    }
    case SET_DEVICE_TYPE: {
      return {
        ...state,
        deviceType: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
