import "./notification-list.scss";

import { UserNotificationsService } from "../../../../services/hrmnet-api";
import moment from "moment";

import React, { useEffect, useState } from "react";
import { setNotification } from "../../../../redux/actions/notification";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { showSpinner } from "../../../../redux/actions/spinner";
import { useHistory } from "react-router-dom";
import { Animated } from "react-animated-css";
import { Skeleton } from "primereact/skeleton";
import { DataScroller } from "primereact/datascroller";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { navigateToNotificationOrigin } from "./notification-constant";
import {
  DATE_TIME_FORMAT,
  DEFAULT_DATETIME_FORMAT,
} from "../../../../constants";

const NotificationList = () => {
  const dispatch = useDispatch();
  const toast = useSelector((state) => state.global.toast);
  const notificationContent = useSelector((state) => state.notification);
  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );
  const clientInfo = useSelector((state) => state.global?.clientInfo);
  const dateTimeFormat =
    clientInfo?.dateTimeFormat || DATE_TIME_FORMAT.DATE_TIME_GMT;
  const { t } = useTranslation();
  let history = useHistory();

  const [state, setState] = useState({
    loadingMobileView: true,
    notificationId: "",
    loadMessageDetails: false,
    refreshNotifications: false,
  });

  const getData = async () => {
    try {
      setState({
        loadingMobileView: true,
        refreshNotifications: true,
      });
      const cmd = UserNotificationsService.notificationGetNotifications();
      const [res] = await Promise.all([cmd]);
      let notifications = [];
      if (res && res.data) {
        let _data = res.data;
        notifications = _data?.map((data) => {
          return {
            ...data,
            publishedDate: new moment(data?.publishedDate, dateTimeFormat),
          };
        });
        // Sort array from latest date to oldest date
        // And store it into redux
        notifications?.sort(
          (date_1, date_2) =>
            new Date(date_2?.publishedDate).getTime() -
            new Date(date_1?.publishedDate).getTime()
        );
        let newNotificationContent = {
          data: notifications,
        };
        dispatch(setNotification(newNotificationContent));
      }
    } catch (err) {
      dispatch(setNotification({ data: [] }));
    } finally {
      setState({
        loadingMobileView: false,
        refreshNotifications: false,
      });
    }
  };

  useEffect(() => {
    const _getData = async () => {
      await getData();
    };
    _getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLangKey]);

  // Update notification status for is_read to true
  const updateMessageIsRead = async (notificationId) => {
    // Update Notification Read Status in Redux State
    let updateReadStatusNotificationContent = [...notificationContent.data];
    let newNotificationContent = {};
    let newIndex = {};
    notificationContent.data?.forEach(function (message, index) {
      if (notificationId === message?.notificationId) {
        newIndex = index;
      }
      return [];
    });
    newNotificationContent = {
      ...updateReadStatusNotificationContent[newIndex],
    };

    // Check if notification status is new, if new update redux and database
    // else return
    if (newNotificationContent.status === "New") {
      newNotificationContent.status = "Read";
      updateReadStatusNotificationContent[newIndex] = newNotificationContent;
      dispatch(
        setNotification({
          data: updateReadStatusNotificationContent,
        })
      );
      // Update notification read status in database
      try {
        await UserNotificationsService.notificationUpdateNotificationStatus({
          notificationId: notificationId,
        });
      } catch (error) {}
    }
  };

  const updateMessageReadAll = async () => {
    let updateReadStatusNotificationContent = [...notificationContent.data];

    for (var notification in updateReadStatusNotificationContent) {
      updateReadStatusNotificationContent[notification].status = "Read";
    }
    dispatch(
      setNotification({
        data: updateReadStatusNotificationContent,
      })
    );

    // Update notification read status in database
    dispatch(showSpinner(true));
    try {
      var api = await UserNotificationsService.notificationUpdateAllUnreadNotificationStatus({});
      let [res] = await Promise.all([api]);
      
      if (res && res?.data && res.data.status) {
        await getData();
        dispatch(showSpinner(false));
      }
    } catch (error) {}
  }

  // Notification template to generate notification list view
  const notificationTemplate = (notification) => {
    return (
      <div
        key={notification?.notificationId}
        className="m-n-t-card-container"
        onClick={() => {
          setState({
            notificationId: notification?.notificationId,
            loadMessageDetails: true,
          });
          updateMessageIsRead(notification?.notificationId);
        }}
      >
        <div className="p-grid p-nogutter p-justify-between">
          <div className="p-col">
            <div
              className={`${
                notification?.status === "Read"
                  ? "n-m-t-title"
                  : "n-m-t-title n-m-t-unread-n"
              }`}
            >
              {notification?.title}
            </div>
          </div>
          <div className="p-col-fixed n-m-t-date-time">
            {moment(notification?.publishedDate).format(
              DEFAULT_DATETIME_FORMAT
            )}
          </div>
        </div>
      </div>
    );
  };

  // Toolbar Left Content
  const toolbarLeftContent = (
    <>
      <div className="p-grid p-nogutter">
        <i
          className="p-col-fixed pi pi-arrow-left"
          onClick={() => {
            setState({
              loadMessageDetails: false,
            });
          }}
        />
      </div>
    </>
  );

  // Toolbar Right Content
  const toolbarRightContent = () => {
    let notificationIndex = {};
    notificationContent.data?.map(function (notification, index) {
      if (notification.notificationId === state.notificationId) {
        notificationIndex = index;
      }
      return [];
    });

    // console.log("---notificationDetails---", notificationDetails);
    // console.log("---notificationIndex---", notificationIndex);

    // Left button - render previous notification
    const leftBtnNotification = () => {
      if (notificationIndex <= 0) {
        toast.show({
          severity: "error",
          summary: t("notification_current_latest"),
          detail: t("notification_current_latest_details"),
          life: 10000,
        });
        return;
      }
      return (notificationIndex -= 1);
    };

    // Right button - render next notification
    const rightBtnNotification = () => {
      if (notificationIndex >= notificationContent.data?.length - 1) {
        toast.show({
          severity: "error",
          summary: t("notification_current_oldest"),
          detail: t("notification_current_oldest_details"),
          life: 10000,
        });
        return;
      }
      return (notificationIndex += 1);
    };

    return (
      <>
        <div className="p-grid p-nogutter">
          <div className="t-r-c-left p-col-fixed">
            <i
              className="pi pi-angle-left"
              onClick={() => {
                // On left button click
                // Call left button notification function
                // Then get the previous notificationId and set loadMessageDetails to true and setState
                // To navigate to the previous notification and display it
                leftBtnNotification();
                setState({
                  notificationId:
                    notificationContent.data[notificationIndex].notificationId,
                  loadMessageDetails: true,
                });
                // Update the read status in the database
                updateMessageIsRead(
                  notificationContent.data[notificationIndex].notificationId
                );
              }}
            />
          </div>
          <div className="t-r-c-right p-col-fixed">
            <i
              className="pi pi-angle-right"
              onClick={() => {
                // On right button click
                // Call right button notification function
                // Then get the next notificationId and set loadMessageDetails to true and setState
                // To navigate to the next notification and display it
                rightBtnNotification();
                setState({
                  notificationId:
                    notificationContent.data[notificationIndex].notificationId,
                  loadMessageDetails: true,
                });
                // Update the read status in the database
                updateMessageIsRead(
                  notificationContent.data[notificationIndex].notificationId
                );
              }}
            />
          </div>
        </div>
      </>
    );
  };

  // Render Notification Details for mobile view
  const NotificationDetails = () => {
    // console.log("---notificationContent.data---", notificationContent.data);
    // console.log("---state.notificationId---", state.notificationId);
    let notificationDetails = {};
    notificationContent.data?.map((notification) => {
      if (notification.notificationId === state.notificationId) {
        notificationDetails = notification;
      }
      return [];
    });

    return (
      <>
        {notificationDetails && (
          <div className="n-m-d-container">
            <Toolbar left={toolbarLeftContent} right={toolbarRightContent} />
            <div className="p-grid p-dir-col p-nogutter n-m-d-grid-container">
              <div className="p-col-12">
                <div className="p-grid p-justify-end">
                  <div className="p-col-fixed n-m-d-date-time">
                    {moment(notificationDetails?.publishedDate).format(
                      DEFAULT_DATETIME_FORMAT
                    )}
                  </div>
                </div>
              </div>
              <div className="p-col-12 n-m-d-title">
                {notificationDetails?.title}
              </div>
              <div className="p-col-12 n-m-d-content">
                {notificationDetails?.message}
              </div>
              <div className="p-col p-col-align-center">
                <Button
                  className="n-m-d-take-action-btn"
                  label={t("notification_take_action")}
                  onClick={() => {
                    navigateToNotificationOrigin(notificationDetails, history);
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  // Render Skeleton while waiting for the data to be fetched
  const SkeletonNotificationDetails = () => {
    return (
      <>
        <div className="m-n-t-s-card-container">
          <div className="p-grid p-nogutter">
            <div className="p-col-12">
              <div className="p-grid p-justify-between m-n-t-s-card-container-content">
                <Skeleton width="60%" height="2rem"></Skeleton>
                <Skeleton width="35%" height="2rem"></Skeleton>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Animated
        animationIn="slideInRight"
        animationOut="slideOutRight"
        animationInDuration={200}
        animationOutDuration={200}
        isVisible={true}
      >
        {state.loadMessageDetails ? (
          <NotificationDetails />
        ) : (
          <div className="n-m-container">
            <div className="p-grid p-nogutter p-justify-between p-align-baseline">
              <div className="p-col-fixed">
                <h2 className="n-m-title">{t("notification_notifications")}</h2>
              </div>
              <div className="p-col-fixed n-m-btn-refresh">
                <Button
                  className="p-button-icon-only p-button-rounded"
                  /*className={`${
                    state.refreshNotifications
                      ? "p-button-icon-only p-button-rounded p-disabled"
                      : "p-button-icon-only p-button-rounded"
                  }`}*/
                  style={{ marginRight: 5 }}
                  onClick={async () => {
                    await updateMessageReadAll();
                  }}
                >
                  <span className="p-button-icon p-c pi pi-check" />
                </Button>
                <Button
                  className={`${
                    state.refreshNotifications
                      ? "p-button-icon-only p-button-rounded p-disabled"
                      : "p-button-icon-only p-button-rounded"
                  }`}
                  onClick={async () => {
                    // If refresh button is clicked, call getData()
                    // Then show a loading spinning icon and disabled it
                    await getData();
                  }}
                >
                  {state.refreshNotifications ? (
                    <span className="p-button-icon p-c p-button-loading-icon pi pi-spinner pi-spin" />
                  ) : (
                    <span className="p-button-icon p-c pi pi-refresh" />
                  )}
                </Button>
              </div>
            </div>
            {state.refreshNotifications || state.loadingMobileView ? (
              <>
                <SkeletonNotificationDetails />
                <SkeletonNotificationDetails />
                <SkeletonNotificationDetails />
              </>
            ) : (
              <div className="data-scroller-container">
                {notificationContent.data?.length === 0 ||
                notificationContent.data?.length === undefined ? (
                  <div className="data-scroller-no-data">
                    {t("common_noData")}
                  </div>
                ) : (
                  <>
                    <DataScroller
                      value={notificationContent?.data}
                      itemTemplate={notificationTemplate}
                      rows={10}
                      buffer={0.9}
                      className="n-m-scroller"
                    ></DataScroller>
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </Animated>
    </>
  );
};
export default NotificationList;
