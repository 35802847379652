import "./block-leave.scss";

//components
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { resetBcDynamicPaths } from "../../../../../redux/actions/breadcrumb";
import { openModal } from "../../../../../redux/actions/modal";
import { showSpinner } from "../../../../../redux/actions/spinner";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import LanguageLoader from "../../../../../components/language-loader/language-loader";
import { LeaveService } from "../../../../../services/hrmnet-api";
import { formSkeleton } from "../../utils";
import { ToUIOption } from "../../../public/login/components/utils";
import {
  EEPORTAL_LABEL_BASE_TABLE,
  Permission,
} from "../../../../../constants";
import {
  LEAVE_T_CONTEXT_KEY,
  LEAVE_MENU_MODULE_KEY,
  LEAVE_MENU_PAGE_KEY,
} from "../../constants/contants";
import {
  getColumModel,
  DEFAULT_BT_SEARCH,
  getBTConfig,
} from "./../../../../../components/base-table/base-table-model";
import BaseTable from "../../../../../components/base-table/base-table-portal-rental";
import { showTimestampToastSuccess } from "../../../../../services/utils/message";
import { LeaveMenu, ScrollToLeaveMenuActiveItem } from "../../components";
import { getDisplayUom } from "../../utils/utils";
import SelectControl from "../../../../../components/base-control/select-control/select-control";
import CheckboxControl from "../../../../../components/base-control/checkbox/checkbox-control";

const DATE_FORMAT = "YYYY-MM-DD";
const DATE_FORMAT_WITH_WEEKDAY = "YYYY-MM-DD (ddd)";
const DATETIME_FORMAT = "YYYY-MM-DD h:mm A";
const DATETIME_FORMAT_WITH_WEEKDAY = "YYYY-MM-DD (ddd) h:mm A";

const BlockLeave = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  let history = useHistory();
  const tableRef = useRef();
  const menus = useSelector((state) => state.menu.sideBar);
  const isSupportUser = useSelector((state) => state.auth?.isSupportUser);
  const leaveMenu = menus.find(
    (x) => x.key === LEAVE_MENU_MODULE_KEY
  )?.subMenus;
  const [leaveEmployee, setLeaveEmployee] = useState([]);
  const [employeeCode, setEmployeeCode] = useState(
    props?.location?.state?.employeeCode
  );
  const [isSelf, setIsSelf] = useState(true);

  const [state, setState] = useState({
    isLoading: true,
    leaveYearFilter: [],
    blockLeaveData: null,
    blockLeaveDeclared: false,
    blockLeaveExpandedRows: null,
    declarableDates: null,
    triggerSelectedLeaveYear: false,
    selectedLeaveYear: null,
    selectedKey: null,
    selectedDate: null,
    employeeCode: null,
  });

  useEffect(() => {
    dispatch(resetBcDynamicPaths());
  }, [dispatch]);

  // init
  useEffect(() => {
    loadInfo();
    if (isSupportUser && !employeeCode) {
      setIsSelf(false);
      loadSubmitedData();
    } else {
      loadData();
      setIsSelf(!employeeCode);
    }
    ScrollToLeaveMenuActiveItem();
  }, []);

  useEffect(() => {
    if (state.blockLeaveData) {
      setState((state) => ({
        ...state,
        isLoading: false,
      }));
    }
  }, [state.blockLeaveData]);

  const loadInfo = async () => {
    var res = await LeaveService.leaveGetDelegateEmployeeApproveList();
    let [delegateRes] = await Promise.all([res]);
    if (delegateRes && delegateRes.data) {
      setLeaveEmployee(
        delegateRes.data
          .filter((x) => x.descriptionValue !== Permission.Self)
          .map((x) => {
            x.description = i18n.exists(x.description)
              ? t(x.description)
              : x.descriptionValue;
            return x;
          })
      );
    }
    if (isSupportUser && !employeeCode) {
      setState((state) => ({
        ...state,
        employeeCode: delegateRes.data[0].value,
        selectedLeaveYear: new Date().getFullYear(),
      }));

      setEmployeeCode(state.employeeCode);
    }
  };

  const loadData = async () => {
    setState((state) => ({
      ...state,
      isLoading: true,
    }));

    var api = await LeaveService.leaveGetBlockLeave({});
    let [res] = await Promise.all([api]);

    parseRes(res, true);
  };

  const loadSubmitedData = async () => {
    setState((state) => ({
      ...state,
      isLoading: true,
    }));

    var api = await LeaveService.leaveGetBlockLeaveByYear({
      year: state.selectedLeaveYear,
      employeeCode: state.employeeCode,
    });
    let [res] = await Promise.all([api]);

    parseRes(res, true);
  };

  const parseRes = (res, setYearFilter) => {
    let leaveYearFilter = [];
    let selectedLeaveYear = null;
    let blockLeaveData = null;
    let blockLeaveDeclared = false;
    let declarableDates = null;
    let selectedDate = null;
    let employeeCode = null;

    if (res) {
      blockLeaveData = { ...res };

      if (res?.eligibles && res?.eligibles?.length > 0) {
        res.eligibles.forEach((e, i) => {
          let startDateList = [];
          let endDateList = [];
          e?.applications.forEach((app) => {
            startDateList.push(moment(app.leaveFrom));
            endDateList.push(moment(app.leaveTo));
          });
          e?.holidaysBefore.forEach((h) => {
            if (h?.shiftActualStartAt) {
              startDateList.push(moment(h.shiftActualStartAt));
            }
          });
          e?.holidaysAfter.forEach((h) => {
            if (h?.shiftActualEndAt) {
              endDateList.push(moment(h.shiftActualEndAt));
            }
          });

          blockLeaveData.eligibles[i].coverPeriodFrom = moment
            .min(startDateList)
            .format(DATE_FORMAT_WITH_WEEKDAY);
          blockLeaveData.eligibles[i].coverPeriodTo = moment
            .max(endDateList)
            .format(DATE_FORMAT_WITH_WEEKDAY);
        });
      }

      if (res?.appliedKeys && res?.appliedKeys?.length > 0) {
        blockLeaveDeclared = true;

        if (res?.eligibles && res?.eligibles?.length > 0) {
          const selectedEligibles = res.eligibles.filter((e) =>
            res.appliedKeys.includes(e.key)
          );

          if (selectedEligibles.length === 1) {
            const selectedEligible = selectedEligibles[0];
            declarableDates = selectedEligible.declarableDates.map((d) => ({
              label: moment(d).format(DATE_FORMAT_WITH_WEEKDAY).toUpperCase(),
              value: moment(d).format(DATE_FORMAT),
            }));
            if (declarableDates.length === 1) {
              selectedDate = declarableDates[0].value;
            }
            blockLeaveData.eligibles = [selectedEligible];
          }
        }
      }

      if (res?.leavePeriods && setYearFilter) {
        leaveYearFilter = res.leavePeriods.map((y) => {
          return {
            ...y,
            label: y.value,
          };
        });
        if (leaveYearFilter.length) {
          selectedLeaveYear = leaveYearFilter[0].value;
          let obj = leaveYearFilter.find((o, i) => {
            if (o.value === new Date().getFullYear().toString()) {
              selectedLeaveYear = leaveYearFilter[i].value;
              return true;
            }
          });
        }

        setState((state) => ({
          ...state,
          leaveYearFilter,
          selectedLeaveYear,
          employeeCode,
        }));
      }
    }

    setState((state) => ({
      ...state,
      blockLeaveData,
      blockLeaveDeclared,
      declarableDates,
      selectedDate,
    }));
  };

  useEffect(() => {
    const getBlockLeaveByYear = async () => {
      var api = await LeaveService.leaveGetBlockLeaveByYear({
        year: state.selectedLeaveYear,
        ...(employeeCode && { employeeCode: employeeCode }),
      });
      let [res] = await Promise.all([api]);

      parseRes(res, false);
    };

    if (state.triggerSelectedLeaveYear) {
      setState((state) => ({
        ...state,
        isLoading: true,
        triggerSelectedLeaveYear: false,
      }));
      getBlockLeaveByYear();
    }
  }, [state.triggerSelectedLeaveYear]);

  useEffect(() => {
    const getBlockLeaveByYear = async () => {
      var api = await LeaveService.leaveGetBlockLeaveByYear({
        year: state.selectedLeaveYear,
        ...(employeeCode && { employeeCode: employeeCode }),
      });
      let [res] = await Promise.all([api]);

      parseRes(res, false);
    };

    if (employeeCode) {
      setState((state) => ({
        ...state,
        isLoading: true,
        employeeCode: employeeCode,
      }));
      getBlockLeaveByYear();
    }
  }, [employeeCode]);

  const tableGetData = async () => {
    if (state.isLoading) {
      return new Promise(() => {});
    } else {
      return {
        datas: state.blockLeaveData.eligibles,
        searchConfig: BT_SEARCH,
        total: state.blockLeaveData.eligibles.length,
      };
    }
  };
  const BT_SEARCH = {
    ...DEFAULT_BT_SEARCH,
    sortObj: {
      key: "applicationId",
      direction: -1,
    },
  };

  // render
  const renderFormSkeleton = () => formSkeleton;

  const renderEmployeeFilter = () => (
    <div className="leave-filter leave-section-wrapper">
      <div className="filter-block-employee filter-block__employee p-col-4">
        <div className="base-control">
          <SelectControl
            key="employeeCode"
            label={t("leave_blockLeave_filter_employee")}
            placeholder={t("leave_blockLeave_filter_selectEmployee")}
            className="filter-employee"
            enum={ToUIOption(leaveEmployee)}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                employeeCode: e.value,
              }));
              setEmployeeCode(e.value);
            }}
            value={employeeCode}
            config={{ disabled: isSelf }}
          />
        </div>
      </div>
      {isSupportUser ? (
        <></>
      ) : (
        <div className="filter-block-employee filter-block__self p-col-4">
          <div className="base-control">
            <CheckboxControl
              key="self"
              label="leave_blockleave_filter_self"
              value={isSelf}
              onChange={(e) => {
                setIsSelf(e.value);
                if (e.value) {
                  setEmployeeCode(null);
                  setState((state) => ({
                    ...state,
                    triggerSelectedLeaveYear: true,
                  }));
                }
              }}
            />
          </div>
        </div>
      )}
    </div>
  );

  const renderYearFilter = () => {
    return (
      <div className="year-filter-container">
        <div className="year-filter-block">
          <div className="base-control">
            <label className="filter-label">
              {t("leave_blockLeave_filter_year")}
            </label>
            <Dropdown
              placeholder={t("base_control_select_choose")}
              options={state.leaveYearFilter}
              value={state.selectedLeaveYear}
              onChange={(e) => {
                setState((state) => ({
                  ...state,
                  selectedLeaveYear: e.value,
                  triggerSelectedLeaveYear: true,
                }));
              }}
            />
          </div>
        </div>
        {state.selectedLeaveYear ? (
          <div className="year-filter-block">
            <label className="filter-label">
              {t("leave_blockLeave_filter_yearPeriod")}
            </label>
            <div className="leave-year-filter-value">
              {
                state.leaveYearFilter.filter(
                  (y) => y.value === state.selectedLeaveYear
                )[0].description
              }
            </div>
          </div>
        ) : null}
        <div className="year-filter-block">
          <label className="filter-label">
            {t("leave_blockLeave_filter_requiredBlockLeaveDays")}
          </label>
          <div className="leave-year-filter-value">
            {state.blockLeaveData?.requiredBlockLeaveDays}
          </div>
        </div>
        <div className="year-filter-block">
          <label className="filter-label">
            {t("leave_blockLeave_filter_requiredWorkingDays")}
          </label>
          <div className="leave-year-filter-value">
            {state.blockLeaveData?.requiredWorkingDays}
          </div>
        </div>
      </div>
    );
  };

  const renderBlockLeaveInfo = () => {
    if (state.blockLeaveData.requiredBlockLeaveDays < 1) return;

    let leaveUnit = "DAY";
    if (state.blockLeaveData.eligibles.length > 0) {
      if (state.blockLeaveData.eligibles[0].applications.length > 0) {
        leaveUnit = state.blockLeaveData.eligibles[0].applications[0].uom;
      }
    }

    const infoColumn = [
      {
        key: "coverPeriodFrom",
        header: t("leave_blockLeave_table_leaveCoverPeriodFrom"),
        hideInMobile: false,
      },
      {
        key: "coverPeriodTo",
        header: t("leave_blockLeave_table_leaveCoverPeriodTo"),
        hideInMobile: false,
      },
      {
        key: "totalDaysAmount",
        header: t("leave_blockLeave_table_totalUomAmount", {
          uom: getDisplayUom(leaveUnit, t),
        }),
        hideInMobile: true,
      },
      {
        key: "totalHolidaysAmount",
        header: t("leave_blockLeave_table_totalHolidaysAmount"),
        hideInMobile: true,
      },
      {
        key: "totalWorkingDaysAmount",
        header: t("leave_blockLeave_table_totalWorkingUomAmount", {
          uom: getDisplayUom(leaveUnit, t),
        }),
        hideInMobile: true,
      },
    ];
    const infoColumnConfig = infoColumn.map((column) => {
      return getColumModel({
        header: column.header,
        key: column.key,
        dataType: "string",
        sortable: false,
        hideInMobile: column.hideInMobile,
      });
    });
    const renderInfoColumn = infoColumn
      .filter((c) => c.hideInMobile)
      .map((column) => {
        const columnConfig = {
          field: column.key,
          header: column.header,
          style: column.style,
          body: (data) => (
            <>
              <div className="p-column-title">{column.header}: </div>
              <div className="p-column-value">{data[column.key]}</div>
            </>
          ),
        };
        return <Column {...columnConfig} />;
      });

    if (!state.blockLeaveDeclared) {
      infoColumnConfig.unshift(
        getColumModel({
          header: "",
          key: "head-column-dummy",
          dataType: "string",
          frozen: true,
          sortable: false,
          width: 0.1,
          hideInMobile: true,
          render: (data) => <></>,
        })
      );
    }

    const applicationColumn = [
      {
        key: "leaveType",
        header: t("leave_applicationList_leaveType"),
      },
      {
        key: "leaveFrom",
        header: t("leave_applicationList_from"),
        render: (data) => (
          <div>{moment(data.leaveFrom).format(DATETIME_FORMAT)}</div>
        ),
      },
      {
        key: "leaveTo",
        header: t("leave_applicationList_to"),
        render: (data) => (
          <div>{moment(data.leaveTo).format(DATETIME_FORMAT)}</div>
        ),
      },
      {
        key: "unit",
        header: t("leave_applicationList_units"),
        style: { width: "8rem" },
      },
      {
        key: "referenceNo",
        header: t("leave_applicationList_refNo"),
      },
    ];
    const renderApplicationColumn = applicationColumn.map((column) => {
      const columnConfig = {
        field: column.key,
        header: column.header,
        style: column.style,
        body: (data) => (
          <>
            <div className="p-column-title">{column.header}: </div>
            <div className="p-column-value">
              {column.key === "leaveFrom" || column.key === "leaveTo"
                ? moment(data[column.key])
                    .format(DATETIME_FORMAT_WITH_WEEKDAY)
                    .toUpperCase()
                : data[column.key]}
            </div>
          </>
        ),
      };
      return <Column {...columnConfig} />;
    });

    const configModel = getBTConfig({
      columns: infoColumnConfig,
      hasIndex: false,
      mode: "list",
      defaultMode: "list",
      showGlobal: false,
      hasColumnSelector: false,
      rowHover: true,
      responsive: true,
      actionsMulti: !state.blockLeaveDeclared ? [{}] : null,
      hideItemSelectedToolbar: true,
      onSelectionChangeGetSelected: (data) => {
        const selectedKey = data.length ? data[0].key : null;
        let declarableDates = null;
        let selectedDate = null;
        if (data.length) {
          declarableDates = data[0].declarableDates.map((d) => ({
            label: moment(d).format(DATE_FORMAT_WITH_WEEKDAY).toUpperCase(),
            value: moment(d).format(DATE_FORMAT),
          }));
          if (declarableDates.length === 1) {
            selectedDate = declarableDates[0].value;
          }
        }
        setState((state) => ({
          ...state,
          selectedKey,
          declarableDates,
          selectedDate,
          employeeCode,
        }));
      },
      isExpandable: true,
      expandedRows: state.blockLeaveExpandedRows,
      onRowToggle: (e) => {
        setState((state) => ({
          ...state,
          blockLeaveExpandedRows: e.data,
        }));
      },
      rowExpansionTemplate: (data) => {
        return (
          <>
            <div className="block-leave-info-table-mobile">
              <DataTable
                value={[data]}
                emptyMessage={t("base_table_no_results")}
              >
                {renderInfoColumn}
              </DataTable>
            </div>
            <div className="block-leave-application-table">
              <DataTable
                value={data.applications}
                emptyMessage={t("base_table_no_results")}
              >
                {renderApplicationColumn}
              </DataTable>
            </div>
          </>
        );
      },
    });

    return (
      <div className="block-leave-info-container">
        <div className="declare-date-container">
          {!state.blockLeaveDeclared ? (
            <div className="declare-date-block">
              <div className="base-control">
                <label className="filter-label">
                  {t("leave_blockLeave_info_declareStartDate")}
                </label>
                <Dropdown
                  className="declare-date-dropdown"
                  placeholder={
                    state.declarableDates?.length > 0
                      ? t("base_control_select_choose")
                      : "-"
                  }
                  options={state.declarableDates}
                  filter={true}
                  value={state.selectedDate}
                  disabled={
                    state.blockLeaveDeclared ||
                    !state.declarableDates ||
                    state.declarableDates?.length === 1
                  }
                  onChange={(e) => {
                    setState((state) => ({
                      ...state,
                      selectedDate: e.value,
                    }));
                  }}
                />
              </div>
            </div>
          ) : (
            <>
              <div className="declare-date-block">
                <label className="date-label">
                  {t("leave_blockLeave_info_declaredStartDate")}
                </label>
                <div className="date-value">
                  {moment(state.blockLeaveData.eligibles[0].declaredFrom)
                    .format(DATE_FORMAT_WITH_WEEKDAY)
                    .toUpperCase()}
                </div>
              </div>
              <div className="declare-date-block">
                <label className="date-label">
                  {t("leave_blockLeave_info_declaredEndDate")}
                </label>
                <div className="date-value">
                  {moment(state.blockLeaveData.eligibles[0].declaredTo)
                    .format(DATE_FORMAT_WITH_WEEKDAY)
                    .toUpperCase()}
                </div>
              </div>
            </>
          )}
        </div>
        <div className="block-leave-info-table">
          <BaseTable
            ref={tableRef}
            isClientSize={true}
            configModel={configModel}
            searchConfig={BT_SEARCH}
            searchFn={tableGetData}
            selectableKey="key"
            selectOneOnly={true}
          />
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="footer p-grid p-align-center p-justify-between">
        <div className="left-button-group">
          <Button
            onClick={() => history.goBack()}
            className="p-button-outlined secondary"
          >
            {t("leave_common_actionBack")}
          </Button>
        </div>

        <div className="right-button-group">
          {!state.blockLeaveDeclared && (
            <Button
              className="p-button"
              disabled={!state.selectedDate || !state.selectedKey}
              onClick={confirmSubmit}
            >
              {t("leave_common_actionSubmit")}
            </Button>
          )}
        </div>
      </div>
    );
  };

  // Submit
  const confirmSubmit = () => {
    dispatch(
      openModal({
        title: t("leave_message_confirmation"),
        content: t("leave_blockLeave_submit_confirmMessage"),
        classNameMainDialog: "confirm-message-modal",
        primaryButtonText: t("leave_submit_confirmSubmitButton"),
        primaryButtonClickFn: async ({ closeFn }) => {
          closeFn();
          submitAction();
        },
        secondButtonClickFn: ({ closeFn }) => {
          closeFn();
        },
      })
    );
  };

  const submitAction = async () => {
    try {
      dispatch(showSpinner(true));

      var api = await LeaveService.leaveDeclareBlockLeave({
        leaveYear: state.selectedLeaveYear,
        key: state.selectedKey,
        startDate: state.selectedDate,
        ...(employeeCode && { employeeCode: employeeCode }),
      });

      let res = await Promise.all([api]);

      // if (res) {
      // }

      showTimestampToastSuccess({
        message: t("leave_blockLeave_submit_successMessage"),
        t: t,
      });
      loadSubmitedData();
    } catch (e) {
    } finally {
      dispatch(showSpinner(false));
    }
  };

  useEffect(() => {
    // link from submit page
    if (employeeCode && leaveEmployee.length > 0) {
      var employee = leaveEmployee.find((x) => x.value === employeeCode);
      setEmployeeCode(employee?.name);
    }
  }, [leaveEmployee]);

  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          LEAVE_T_CONTEXT_KEY.COMMON,
          LEAVE_T_CONTEXT_KEY.SUBMIT_APPLICATION,
          LEAVE_T_CONTEXT_KEY.LIST,
          LEAVE_T_CONTEXT_KEY.BLOCK_LEAVE,
        ]}
      />
      <div className="leave-container block-leave">
        <div className="header">
          <LeaveMenu
            menu={leaveMenu}
            currentPageKey={LEAVE_MENU_PAGE_KEY.BLOCK_LEAVE}
          />
          <div className="title">{t("leave_blockLeave_pageTitle")}</div>
        </div>
        <div className="main">
          {/* Leave Filter */}
          {state.isLoading
            ? renderFormSkeleton()
            : leaveEmployee.length > 0
            ? renderEmployeeFilter()
            : null}
          {/* Year Filter */}
          {state.isLoading ? renderFormSkeleton() : renderYearFilter()}

          {/* Block Leave Info  */}
          {state.isLoading ? null : renderBlockLeaveInfo()}

          {/* Footer  */}
          {state.isLoading ? null : renderFooter()}
        </div>
      </div>
    </>
  );
};

export default BlockLeave;
