import "./change.scss";

import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { Skeleton } from "primereact/skeleton";
import { openModal } from "../../../../../redux/actions/modal";

import { setBcDynamicPaths } from "../../../../../redux/actions/breadcrumb";
import {
  RentalDetailsService,
  HRIS_Api_Common_Enums_Enums_RentalApplicationForm,
} from "./../../../../../services/hrmnet-api/index";
import { Steps, MultiStepForm } from "../../components";
import {
  configToStepNavModel,
  configToFormModel,
  formSkeleton,
  initFormValue,
  submitChange,
  getCustomFunctionLibrary,
} from "../../utils";
import { FUNCTION_CODE, MESSAGE_SEVERITY } from "../../../../../constants";

import { showTimestampToastSuccess } from "../../../../../services/utils/message";
import { store } from "../../../../../redux/store";
import { useSelector } from "react-redux";
import {
  resetRentalForm,
  setRentalForm,
  setRentalFormConfig,
  setRentalSectionConfig,
} from "../../../../../redux/actions/rental";
import { isResponseOk } from "../../../../../utils/utils";
import {
  scrollToErrorControl,
  scrollToMainTop,
} from "../../../../../components/base-form/utils";
import LanguageLoader from "../../../../../components/language-loader/language-loader";
import { RENTAL_T_CONTEXT_KEY } from "../../constants";
import { showSpinner } from "../../../../../redux/actions/spinner";
import { PortalLink } from "../../../../../services/utils";

const ChangeRentalApplication = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();
  let message = store.getState().global?.toast;

  const [state, setState] = useState({
    activeStep: 0,
    formResp: {},
    isLoading: true,
  });

  const form = useSelector((state) => state.rental.form);
  const setForm = (x) => dispatch(setRentalForm(x));
  const formConfig = useSelector((state) => state.rental.formConfig);
  const setFormConfig = (x) => dispatch(setRentalFormConfig(x));
  const formSectionConfig = useSelector(
    (state) => state.rental.formSectionConfig
  );
  const setFormSectionConfig = (x) => dispatch(setRentalSectionConfig(x));

  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );

  const formRef = React.createRef();
  const selectedLangRef = useRef();

  // Init
  useEffect(() => {
    dispatch(resetRentalForm());
    return () => {
      dispatch(resetRentalForm());
    };
  }, [dispatch]);

  const customFunctionLibrary = getCustomFunctionLibrary({ dispatch });

  // Fetch Data
  useEffect(() => {
    const loadApplicationDetail = async () => {
      const isLangChange =
        selectedLangRef.current != null &&
        selectedLangRef.current !== selectedLangKey;
      selectedLangRef.current = selectedLangKey;

      const applicationId = props.match.params.applicationId;
      const api1 = RentalDetailsService.rentalGetRentalNewApplicationSteps({
        formType:
          HRIS_Api_Common_Enums_Enums_RentalApplicationForm.ChangeRentalForm,
      });
      const api2 = RentalDetailsService.rentalGetChangeApplication({
        applicationId,
      });
      let [stepRes, formRes] = await Promise.all([api1, api2]);

      if (
        stepRes &&
        stepRes.data &&
        formRes &&
        formRes.data &&
        formRes.data.sections &&
        formRes.data.sections.length > 0
      ) {
        let stepList = [];
        stepList = configToStepNavModel(stepRes.data);
        const originalForm = initFormValue(formRes.data.sections);
        let formConfig = {
          originalForm: originalForm,
          ...configToFormModel({
            applicationId: applicationId,
            stepConfig: stepRes.data,
            sectionFieldConfig: formRes.data.sections,
            customData: {
              customFunctionLibrary: customFunctionLibrary,
              attachmentControlConfig: formRes.data.attachmentType,
            },
            t: t,
            form: isLangChange ? form : null,
          }),
        };
        setFormConfig(formConfig);
        setFormSectionConfig(formRes.data);
        setState((state) => ({
          ...state,
          formResp: formRes?.data,
          stepList,
          isLoading: false,
        }));
      }
    };
    loadApplicationDetail();
  }, [selectedLangKey]);

  // Breadcrumb
  useEffect(() => {
    dispatch(setBcDynamicPaths([{ label: t("rental_change_breadcrumb") }]));
  }, [dispatch, t]);

  // Navigate
  const validateStep = () => {
    // Check from invalid
    const isValid = !formRef.current.getActiveFormState({ expand: true })
      .invalid;
    setTimeout(() => {
      scrollToErrorControl();
    }, 100);
    if (!isValid) return false;

    // Check for actual change
    for (const step of formConfig.steps) {
      for (const section of step.sections) {
        for (const control of section.controls) {
          if (control.dependentFields == null && form[control.key] != null) {
            // if there's an actual change
            const value = JSON.stringify(form[control.key]);
            if (value === "[]" || value === "{}") {
              continue;
            }
            return true;
          }
        }
      }
    }
    message.show({
      severity: MESSAGE_SEVERITY.WARN,
      detail: <span>{t("rental_change_pleaseSpecifyChange")}</span>,
    });
    return false;
  };

  const nextStep = () => {
    const _state = { ...state };
    if (_state.activeStep < _state.stepList.length - 1) {
      // validation
      if (validateStep()) {
        // move to next setp
        setState({
          ...state,
          activeStep: state.activeStep + 1,
          touched: false,
        });
        scrollToMainTop();
      } else {
        setState({
          ...state,
          touched: true,
        });
      }
    }
  };

  const backStep = () => {
    if (state.activeStep > 0) {
      setState({
        ...state,
        activeStep: state.activeStep - 1,
      });
      scrollToMainTop();
    } else {
      backPage({ confirm: true });
    }
  };

  const backPage = ({ confirm } = {}) => {
    if (confirm) {
      dispatch(
        openModal({
          title: t("rental_change_backModalTitle"),
          content: t("rental_change_backModalContent"),
          classNameMainDialog: "confirm-message-modal",
          primaryButtonText: t("rental_common_actionConfirm"),
          primaryButtonClickFn: async ({ closeFn }) => {
            closeFn();
            history.push(PortalLink(FUNCTION_CODE.Rental));
          },
          secondButtonClickFn: ({ closeFn }) => {
            closeFn();
          },
        })
      );
    } else {
      history.push(PortalLink(FUNCTION_CODE.Rental));
    }
  };

  // submit
  const confirmSubmit = () => {
    dispatch(
      openModal({
        title: t("rental_change_submitModalTitle"),
        content: t("rental_change_submitModalContent"),
        classNameMainDialog: "confirm-message-modal",
        primaryButtonText: t("rental_change_submitModalConfirm"),
        primaryButtonClickFn: async ({ closeFn }) => {
          closeFn();
          submitChangeAction();
        },
        secondButtonClickFn: ({ closeFn }) => {
          closeFn();
        },
      })
    );
  };

  const submitChangeAction = async () => {
    try {
      // show spinner
      dispatch(showSpinner(true));

      const res = await submitChange(
        state.formResp.sections,
        form,
        props.match.params.applicationId
      );
      if (!isResponseOk(res)) throw new Error("Submit Failed");
      showTimestampToastSuccess({
        message: t("rental_change_successMessageSubmit"),
        t: t,
      });
      backPage();
    } catch (e) {
      // show notification?
      console.error(e.message);
    } finally {
      // end spinner
      dispatch(showSpinner(false));
    }
  };

  // render
  const renderFormTitle = () => (
    <div className="rental-form-title">
      <Button
        onClick={backStep}
        icon="pi pi-angle-left"
        className="p-button-rounded-lg p-button-text"
      />
      <h2>{state.formResp.formName}</h2>
    </div>
  );

  const renderFooter = () => (
    <div className="footer p-grid p-align-center">
      <Button
        onClick={() => backStep()}
        className="p-button-outlined secondary back-button"
      >
        {t("rental_common_actionBack")}
      </Button>
      {state.stepList && state.activeStep < state.stepList.length - 1 ? (
        <Button onClick={() => nextStep()} className="p-button next-button">
          {t("rental_common_actionNext")}
        </Button>
      ) : (
        <Button
          onClick={() => confirmSubmit()}
          className="p-button next-button"
        >
          {t("rental_common_actionSubmit")}
        </Button>
      )}
    </div>
  );

  const renderStepSkeleton = (
    <div className="p-grid p-my-6">
      <Skeleton height="20px"></Skeleton>
    </div>
  );

  const renderFormSkeleton = () => formSkeleton;

  const renderForm = () => (
    <div>
      <MultiStepForm
        form={form}
        activeStep={state.activeStep}
        config={formConfig}
        ref={formRef}
        touched={state.touched}
        onChange={(state) => {
          setForm((form) => ({
            ...form,
            ...state.form,
          }));
        }}
      />
    </div>
  );

  return (
    <>
      <LanguageLoader
        contexts={[RENTAL_T_CONTEXT_KEY.COMMON, RENTAL_T_CONTEXT_KEY.CHANGE]}
      />
      <div className="rental-change rental-ms-form">
        {/* Form Name */}
        {!state.isLoading && renderFormTitle()}

        {/* Steps Nav*/}
        {state.isLoading ? (
          renderStepSkeleton
        ) : (
          <Steps
            className="steps"
            model={state.stepList}
            activeIndex={state.activeStep}
          />
        )}

        {/* Form, field rendering */}
        {state.isLoading ? renderFormSkeleton() : renderForm()}

        {/* Footer */}
        {renderFooter()}
      </div>
    </>
  );
};

export default ChangeRentalApplication;
