import { Button } from "primereact/button";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { MESSAGE_SEVERITY } from "../../../../../../constants";
import { closeLoading } from "../../../../../../redux/actions/modal";
import { store } from "../../../../../../redux/store";
import { AccessRightsService } from "../../../../../../services/hrmnet-api";
import { isResponseOk } from "../../../../../../utils/utils";
import { ActiveSessionDialog } from "../../login-load-info";
import { OnLogin } from "../utils";
import jwt_decode from "jwt-decode";

const UnifySunshineLogin = ({ token }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  let alert = store.getState().global.alert;
  const setLoading = () => dispatch(closeLoading());

  const changeSession = useCallback(async (confirm) => {
    if (confirm) {
      var res = {
        data: {
          token: token,
          isValidUser: true,
          isChangePassword: false,
          isFirstTimeLogin: false,
          employeeCode: jwt_decode(token)["EmployeeCode"],
        },
      };
      if (res && res.data && res.data?.isValidUser) {
        await OnLogin(res, history);
      }
    }
  }, []);

  useEffect(() => {
    const init = async () => {
      try {
        const options = {
          headers: {
            Authorization: "Bearer " + token,
          },
          suppressErrorMsg: true,
        };
        const response = await AccessRightsService.accessGetMenus(options);
        //check active session
        if (isResponseOk(response)) {
          await ActiveSessionDialog(store, t, history, changeSession);
        }
      } catch (error) {
        if (error?.response?.status === 401) {
          alert.show({
            severity: MESSAGE_SEVERITY.ERROR,
            detail: (
              <div
                className="hris-message-error"
                dangerouslySetInnerHTML={{
                  __html: t("redirect_error_invalidToken"),
                }}
              />
            ),
            life: 6000,
            sticky: true,
          });
        }
      } finally {
        //clear url query/search
        history.replace();
      }
    };

    init();

    return function cleanup() {
      setLoading();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default UnifySunshineLogin;
