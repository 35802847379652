import { createStore, applyMiddleware } from "redux";
// import rootReducer from "./reducers";
import { persistStore } from "redux-persist";
// import storage from "redux-persist/lib/storage";
// import { parse, stringify } from "flatted";
import persistedReducer from "./reducers";
import {
  createStateSyncMiddleware,
  initStateWithPrevTab,
} from "redux-state-sync";
import {
  AUTH_SET_INFO,
  AUTH_RESET_INFO,
  LANGUAGE_CLEAR_LOADED_CONTEXT,
  LANGUAGE_SET_LOADED_CONTEXT,
  BROADCAST_TENANT,
  BROADCAST_LANGUAGE_LIST,
  BROADCAST_USER_SELECTED_LANGUAGE,
} from "./reduxConstants";

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// https://www.npmjs.com/package/redux-state-sync
const config = {
  whitelist: [
    AUTH_SET_INFO,
    AUTH_RESET_INFO,
    BROADCAST_TENANT,
    BROADCAST_LANGUAGE_LIST,
    BROADCAST_USER_SELECTED_LANGUAGE,
    LANGUAGE_CLEAR_LOADED_CONTEXT,
    LANGUAGE_SET_LOADED_CONTEXT,
  ],
};
const middlewares = [createStateSyncMiddleware(config)];
const store = createStore(
  persistedReducer,
  {},
  applyMiddleware(...middlewares)
);
// init state with other tabs
initStateWithPrevTab(store);

const persistor = persistStore(store);

export { store, persistor };
// export default createStore(rootReducer);
