import { MENU_TYPE } from "../../../../constants";

/**
 *
 * @param {*} module
 * @param {number} depth
 * @param {boolean} getActionLists
 * true - get list of actions match using first 2 routes of location.pathname (e.g. admin and email-configuration);
 * false - matching based on whole location.pathname string
 * @returns boolean
 */
function IsActive(module, depth, getActionLists = false) {
  if (module.type === MENU_TYPE.MENU || !getActionLists) {
    if (module.type === MENU_TYPE.Action) {
      if (!module?.url) {
        return false;
      }
      /**
       * const regexString = "/" + "rental/view/{id}".replace(new RegExp("\{[^}]*\}"), '[\\w]*');
          const _regex = new RegExp(regexString);
          const result = _regex.test('/rental/view/19');
       */

      const regexString =
        "/" + module?.url?.replace(new RegExp("{[^}]*}"), "[\\w]*");
      const regex = new RegExp(regexString);
      return regex.test(window.location.pathname);
    }
    const result = window.location.pathname.startsWith(
      module?.url?.split("/").slice(0, depth).join("/"),
      1
    );
    return result;
  }

  return window.location.pathname.startsWith(
    module?.url?.split("/").slice(0, 2).join("/"),
    1
  );
}

function ProcessMenuRecursive(
  t,
  sideBarInfo,
  module,
  activeModuleKeys,
  depth = 2,
  getMenuOnly = true
) {
  var children = [];
  if (module.subMenus && module.subMenus.length > 0) {
    return module.subMenus.map((_function, index) => {
      var key = module.url;
      if (module.url !== _function.url) {
        key = module.url + "~" + _function.url;
      }

      const isActive = IsActive(_function, depth);

      if (isActive) {
        var existIndex = activeModuleKeys.findIndex(
          (x) => x.url === _function.url
        );
        if (existIndex > -1) {
          activeModuleKeys[existIndex] = {
            key: _function.key,
            url: _function.url,
          };
        } else {
          activeModuleKeys.push({
            key: _function.key,
            url: _function.url,
          });
        }
      }

      const _result = {
        key: key,
        location: _function.url,
        label: t(_function.name),
        icon: _function.icon,
        isActive: isActive,
        leaf: true,
        type: _function.type,
        children: ProcessMenuRecursive(
          t,
          sideBarInfo,
          _function,
          activeModuleKeys,
          depth + 1,
          getMenuOnly
        ),
        data: {
          module,
          function: _function,
        },
        display: _function.display,
      };
      return _result;
    });
  }

  return children;
}

export const ProcessMenuTree = (t, sideBarInfo, menus, getMenuOnly = true) => {
  const activeModuleKeys = [];
  var result = menus
    .filter((menu) => menu.url !== undefined && menu.type === MENU_TYPE.MENU)
    .map((module) => {
      const _result = {
        key: module?.url,
        location: module?.url,
        label: t(module.name),
        icon: module.icon,
        isActive: false,
        children: [],
        type: module.type,
        data: {
          module,
          function: { url: module.url },
        },
        display: module.display,
      };

      const isActive =
        sideBarInfo &&
        sideBarInfo.expandedKeys &&
        sideBarInfo.expandedKeys[module?.url];

      if (isActive) {
        activeModuleKeys.push({
          key: module.key,
          url: module.url,
        });
        _result.activeModuleKeys = activeModuleKeys;
      }

      const children = ProcessMenuRecursive(
        t,
        sideBarInfo,
        module,
        activeModuleKeys,
        2,
        getMenuOnly
      );

      _result.children = children;
      _result.isActive = isActive;

      return _result;
    });
  return result;
};

export function GetActiveMenus(menus) {
  var result = menus
    .filter((menu) => menu.url !== undefined && menu.type === MENU_TYPE.MENU)
    .map((module) => {
      var { subMenus, ...rest } = module;
      if (subMenus && subMenus.length > 0) {
        subMenus = GetActiveMenus(subMenus);
      }
      const isActive = window.location.pathname.startsWith(module.url, 1);

      const _result = {
        ...rest,
        isActive,
        subMenus,
      };

      return _result;
    });
  return result;
}

export function GetCurrentActions(
  menus,
  actions,
  depth = 1,
  getActionLists = true
) {
  menus
    .filter((x) => IsActive(x, depth, getActionLists))
    .forEach((module) => {
      let { subMenus, ...rest } = module;
      if (subMenus && subMenus.length > 0) {
        GetCurrentActions(subMenus, actions, depth + 1, getActionLists);
      } else if (rest.type === MENU_TYPE.Action) {
        actions.push(module);
      }
    });
}
