import { Messages } from "primereact/messages";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { MESSAGE_SEVERITY } from "../../../../constants";
import { SystemNotificationsService } from "../../../../services/hrmnet-api";
import "./main-system-banner.scss";

const MainSystemBanner = () => {
  const messagesRef = useRef(null);
  const auth = useSelector((state) => state.auth);
  const [systemMessages, setSystemMessages] = useState([]);

  const GetContent = async () => {
    var res =
      await SystemNotificationsService.systemNotificationGetSystemNotification();
    if (res?.data) {
      var data = [res?.data];

      if (Array.isArray(res?.data)) {
        data = [...res?.data];
      }

      const messageObject = data?.map((x, id) => {
        return {
          severity: MESSAGE_SEVERITY.ERROR,
          sticky: true,
          content: (
            <div
              id={id}
              dangerouslySetInnerHTML={{
                __html: x,
              }}
            />
          ),
        };
      });

      setSystemMessages(messageObject);
      messagesRef?.current?.clear();
      messagesRef?.current?.show(messageObject);
    } else {
      setSystemMessages([]);
      messagesRef?.current?.clear();
    }
  };

  useEffect(() => {
    let timeout = 1800000;

    if (!!window.SYSTEM_NOTIFICATION_INTERVAL) {
      let radix = 10; // decimal
      let _timeout = parseInt(window.SYSTEM_NOTIFICATION_INTERVAL, radix);
      if (!isNaN(_timeout)) {
        timeout = _timeout;
      }
    }

    const interval = setInterval(() => {
      document.body.classList.add("no-banner");
      GetContent();
    }, timeout);

    GetContent();
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (Array.isArray(systemMessages) && systemMessages.length === 0) {
      document.body.classList.add("no-banner");
    } else {
      document.body.classList.remove("no-banner");
    }
  }, [systemMessages]);

  return (
    <Messages
      className={`banner-news${auth?.isLoggedIn ? "" : " sticky"}`}
      ref={messagesRef}
      onRemove={(msg) => {
        let index = systemMessages.findIndex((x) => x === msg.id);
        const _systemMessages = [...systemMessages];
        _systemMessages.splice(index, 1);
        setSystemMessages(_systemMessages);
      }}
    />
  );
};

export default MainSystemBanner;
