import "./personal-info.scss";

import { Animated } from "react-animated-css";
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Skeleton } from "primereact/skeleton";

import BaseForm from "./../../../../../components/base-form/base-form";

// services
import { EmployeeDetailsService } from "./../../../../../services/hrmnet-api";
import { EmployeeTabConfigurationService } from "./../../../../../services/hrmnet-api";

//components
import {
  MyInfoSkeleton,
  FormControlsGenerator,
  ToPascalCase,
  GetControlType,
} from "./components/utils";
import { PortalSystemErrorLink } from "../../../../../services/utils";

const PersonalInfo = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [state, setState] = useState({
    loading: true,
    enumData: [],
    i18nConstant: "eeinfo_psn_",
    hideTooltip: t("common_reveal"),
  });
  const mountedRef = useRef(true);
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    const loadData = async () => {
      let enumData = {};

      try {
        const cmd1 =
          EmployeeTabConfigurationService.employeeConfigurationGetPersonalTabConfiguration();

        const cmd2 = EmployeeDetailsService.employeeGetPersonalDetails();

        const [res1, res2] = await Promise.all([cmd1, cmd2]);
        let data = {};
        let sections = [];
        let currSection = [];

        if (res2 && res2.data) {
          //TODO: conduct manual data mapping
          /* new API JSON format
        age: null
        chineseName: null
        christianName: null
        citizenIdNo: null
        countryOfIssue: null
        dateOfBirth: null
        displayName: "Chan,Four "
        employeeCode: "HK004"
        employeeId: 0
        firstName: "Four"
        gender: null
        lastName: "Chan"
        maritalStatus: "S"
        middleName: null
        namePrefix: null
        nationality: null
        passportNo: null
        remarks: null
        workVisaExpiryDate: null
        workVisaHolder: false
        workVisaIssueDate: null
        workVisaLandingDate: null
         */
          data = res2.data;
          // data["alias"] = res2.data?.christianName;
          // data["martialStatus"] = res2.data?.maritalStatus;
          // data["workArrivingDate"] = res2.data?.workVisaStartDate;
        }

        if (res1 && res1.data) {
          sections = res1.data;
          if (sections.length > 0) {
            currSection = sections.map((s, sIndex) => {
              let keyToPascalFields = [];
              if (s.fields.length > 0) {
                const visiblefields = s.fields.filter(
                  (x) => x.isVisible === true
                );
                keyToPascalFields = visiblefields.map((f, fIndex) => {
                  const pcKey = ToPascalCase(f.key);
                  if (f.isVisible) {
                    return {
                      ...f,
                      key: pcKey,
                      controlType: GetControlType(data[pcKey], pcKey),
                      fieldConfig: {
                        isVisible: f.isVisible,
                        isMasked: f.isMasked,
                        isEditable: f.isEditable,
                      },
                    };
                  }
                  return {};
                });
              }

              return {
                ...s,
                fields: keyToPascalFields,
              };
            });
          }
          sections = currSection.filter((x) => x.fields.length > 0);
        }

        if (sections.length === 0 || sections === undefined) {
          props.setTabViewVisibility(true);
        }

        let _state = {
          ...state,
          enumData,
          data: data,
          sections: sections,
          loading: false,
        };
        setState(_state);
      } catch (error) {
        history.push(PortalSystemErrorLink());
      }
    };
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *
   * @param {*} clsName
   * @param {*} controlName
   * @returns
   */
  const personalInfoColConfig = (
    controlName,
    clsName = "p-col-12 p-md-6 p-lg-4"
  ) => {
    return {
      config: {
        className: clsName,
      },
      control: controlName,
    };
  };

  /**
   *
   */
  const personalField = (fields) => {
    return {
      controls: fields.length > 0 || fields !== undefined ? fields : null,
      layout:
        fields.length === 0 || fields === undefined
          ? {}
          : {
              // [View_Section_ID] = 24
              rows: [
                {
                  columns: [
                    personalInfoColConfig("firstName"),
                    personalInfoColConfig("middleName"),
                    personalInfoColConfig("lastName"),
                  ],
                },
                {
                  columns: [
                    personalInfoColConfig("alias"),
                    personalInfoColConfig("christianName"), //not in db
                    personalInfoColConfig("displayName"),
                    personalInfoColConfig("namePrefix"),
                  ],
                },
                {
                  columns: [personalInfoColConfig("chineseName")],
                },
                {
                  columns: [
                    personalInfoColConfig(
                      "dateOfBirth",
                      "p-col-12 p-md-4 p-lg-2"
                    ),
                    personalInfoColConfig("age", "p-col-12 p-md-4 p-lg-2"),
                    personalInfoColConfig("gender", "p-col-12 p-md-4 p-lg-2"),
                    personalInfoColConfig(
                      "martialStatus",
                      "p-col-12 p-md-4 p-lg-2"
                    ),
                    personalInfoColConfig(
                      "maritalStatus",
                      "p-col-12 p-md-4 p-lg-2"
                    ),
                  ],
                },
                {
                  columns: [
                    personalInfoColConfig(
                      "employeeId",
                      "p-col-12 p-md-4 p-lg-2"
                    ), //not in db
                    personalInfoColConfig(
                      "preferredLanguage",
                      "p-col-12 p-md-4 p-lg-2"
                    ), //not in db
                  ],
                },
                {
                  // [View_Section_ID] = 25
                  columns: [
                    personalInfoColConfig("citizenIdNo"),
                    personalInfoColConfig("nationality"),
                  ],
                },
                {
                  columns: [
                    personalInfoColConfig("passportNo"),
                    personalInfoColConfig("countryOfIssue"),
                  ],
                },
                {
                  columns: [
                    personalInfoColConfig("homeCountry"),
                    personalInfoColConfig("workVisaStartDate"),
                  ],
                },
                {
                  columns: [
                    personalInfoColConfig(
                      "workVisaHolder",
                      "p-col-12 p-md-4 p-lg-4"
                    ),
                    personalInfoColConfig(
                      "workVisaExpiryDate",
                      "p-col-12 p-md-4 p-lg-4"
                    ),
                    personalInfoColConfig(
                      "workVisaIssueDate",
                      "p-col-12 p-md-4 p-lg-4"
                    ), //not in db
                    personalInfoColConfig(
                      "workArrivingDate",
                      "p-col-12 p-md-4 p-lg-4"
                    ),
                    personalInfoColConfig(
                      "workVisaLandingDate",
                      "p-col-12 p-md-4 p-lg-4"
                    ), //not in db
                  ],
                },
                {
                  // [View_Section_ID] = 26
                  columns: [personalInfoColConfig("remarks", "p-col-12")],
                },
                {
                  //add
                  columns: [
                    personalInfoColConfig("reserveFields", "p-col-12"), //not in db
                  ],
                },
              ],
            },
    };
  };

  // TODO - the masking is dynamic, we need to update this to use Map()
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map
  const [mapMasked, setMapMasked] = useState(new Map([]));

  const renderSection = (index, sectionTitle, fields) => {
    return (
      <div id={index} key={index}>
        <div className="c-group-title ep-language-toogle">
          {/* <p>{t("employeeProfile_personalInfo")}</p> */}
          <p>
            {t(
              state.i18nConstant +
                sectionTitle.replace("-", "_").replace(/ /g, "").toLowerCase()
            )}
          </p>
        </div>
        <div className="c-group-panel">
          {
            <BaseForm
              id={"employeeProfile" + index}
              config={personalField(
                FormControlsGenerator(
                  fields,
                  state.hideTooltip,
                  mapMasked,
                  setMapMasked,
                  state.enumData,
                  t,
                  state.i18nConstant
                )
              )}
              readOnly={props.readOnly ?? true}
              form={state.data}
            />
          }
        </div>
      </div>
    );
  };

  const renderContent = (
    <Animated
      animationIn="slideInRight"
      animationOut="slideOutRight"
      animationInDuration={200}
      animationOutDuration={200}
      isVisible={true}
    >
      {state.sections === null ? (
        { MyInfoSkeleton }
      ) : (
        <>
          {state?.sections?.map((section, index) =>
            renderSection(
              index,
              "personal_" + section.sectionName,
              section.fields
            )
          )}
        </>
      )}
    </Animated>
  );

  return <>{state.loading ? <Skeleton /> : renderContent}</>;
};

export default PersonalInfo;
