import "./shortcut.scss";
// import { Animated } from "react-animated-css";

// import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
// import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { AccessRightsService } from "../../../../services/hrmnet-api";

import { Skeleton } from "primereact/skeleton";
import { PortalLink } from "../../../../services/utils";

const ShortcutIcons = () => {
  // const employeeId = useSelector((state) => state.auth.data?.user?.employeeId);
  // const entityId = useSelector((state) => state.auth.data?.user?.entityId);
  // const clientId = useSelector((state) => state.auth.data?.user?.clientId);
  const isSupportUser = useSelector((state) => state.auth?.isSupportUser);
  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );
  const employeeCode = useSelector((state) => state.auth?.employeeCode);
  const [state, setState] = useState({
    shortcutItem: [],
    loading: true,
  });

  // let match = useRouteMatch();

  const isMobile = useMediaQuery({ maxWidth: "768px" });

  useEffect(() => {
    const getData = async () => {
      try {
        var res = await AccessRightsService.accessGetHomeShortcuts();
        let shortcutItem = [];
        // console.log("--shortcut--", res);
        if (res && res.data) {
          shortcutItem = res.data ? res.data : [];
        }
        setState({
          shortcutItem,
          loading: false,
        });
      } catch (error) {
        setState({
          shortcutItem: [],
          loading: false,
        });
      }
    };

    if (!isSupportUser) {
      getData();
    } else {
      setState({
        shortcutItem: [],
        loading: false,
      });
    }

    return () => {
      setState({
        shortcutItem: [],
        loading: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLangKey, employeeCode]);
  // console.log("--shortcut state--", state);

  const renderShortcutConfig = []
    .concat(state.shortcutItem)
    .map((item, index) => {
      const x = item;
      if (x) {
        const path = PortalLink(x?.url);
        return isMobile ? (
          <div key={index} className="p-col-4 shortcut-mobile">
            <Link to={path}>
              <div className="p-col-12">
                <span className={`p-col-12 ${x?.icon}`}></span>
                <p className="p-col-12 p-jc-center">{x?.name}</p>
              </div>
            </Link>
          </div>
        ) : (
          <div key={index} className="p-col-12 p-lg-3 shortcut">
            <Link to={path}>
              <div className="p-col-12">
                <span className={`p-col-3 p-lg-12 ${x?.icon}`}></span>
                <p className="p-col-9 p-jc-start p-jc-lg-center p-lg-12">
                  {x?.name}
                </p>
              </div>
            </Link>
            {/* </a> */}
          </div>
        );
      }
      return <div key={index}></div>;
    });

  return (
    <>
      {state.loading ? (
        <>
          <div className="p-grid">
            <div className="p-col-12 p-lg-4">
              <Skeleton
                shape="square"
                height="20vh"
                className="p-col-3 p-lg-12 shortcut-skeleton"
              ></Skeleton>
            </div>
            <div className="p-col-12 p-lg-4">
              <Skeleton
                shape="square"
                height="20vh"
                className="p-col-3 p-lg-12 shortcut-skeleton"
              ></Skeleton>
            </div>
            <div className="p-col-12 p-lg-4">
              <Skeleton
                shape="square"
                height="20vh"
                className="p-col-3 p-lg-12 shortcut-skeleton"
              ></Skeleton>
            </div>
          </div>
        </>
      ) : (
        <div
          className={`p-grid ${
            isMobile ? "shortcut-grid-mobile" : "shortcut-grid"
          }`}
        >
          {renderShortcutConfig}
        </div>
      )}
    </>
  );
};

export default ShortcutIcons;
