import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

//
import { setLoadedContext } from "./../../redux/actions/language";
import { ContentService } from "./../../services/hrmnet-api";
import { LOGIN_PATH, LOGOUT_PATH } from "../../constants";
import { loadMessages } from "@progress/kendo-react-intl";
import { addLocale, locale } from "primereact/api";
import { store } from "../../redux/store";
import { openLoading, closeLoading } from "../../redux/actions/modal";
// components
// import MainLoading from "./../../portal/layouts/components/main-loading/main-loading";

/**
 * Wrapper to provide a service to call backend to retrive the labels if it is not loaded before.
 * Basically to wrap over any components that required to load the language bundle from backend.
 *
 * Usually best place to add wrapper object at module top level component.
 *
 * @param {*} param0
 * @returns
 */
const LanguageLoader = ({ contexts, children, auth = true }) => {
  // --------------------- hooks to various library -------------------------
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.language) || {};
  const languageList =
    useSelector((state) => state.language.languageList) || {};
  const loadedContext =
    useSelector((state) => state.language.loadedContext) || {};
  const { i18n } = useTranslation();

  // --------------------- states (if any) ----------------------------------
  const [isLoading, setIsLoading] = useState(true);
  // ----- life cycle -------------------------------------------------------
  useEffect(() => {
    const loadLang = async (loadcontexts, lang) => {
      try {
        setLoading(true);
        if (!lang) {
          return;
        }
        const pcontext = loadcontexts.join(",");
        const res = await ContentService.contentGetContentByContexts({
          contexts: pcontext,
          language: lang,
        });

        // console.log("--loadLang---", res, lang, i18n.language);

        // update i18n bundle
        if (i18n.language !== lang) {
          locale(lang);
          await i18n.changeLanguage(lang);
        }
        let bundle = res.data;
        // if ((lang != 'en-HK') && (lang != 'en')) {
        //   bundle['home_newsfeed_olderNotifications'] = 'test ' + lang;
        // }
        i18n.addResourceBundle(lang, "translation", bundle, true, true);

        //for kendo && primeapi component localization
        if (loadcontexts.includes("eeportal_base_control.label")) {
          loadMessages(bundle, lang);
          addLocale(lang, bundle);
        }
        // update redux for loadedContext
        let newContext = { ...loadedContext.context };
        if (!newContext[lang]) {
          newContext[lang] = {};
        }
        loadcontexts.forEach((ctx) => {
          newContext[lang][ctx] = true;
        });

        // data
        let newData = { ...loadedContext.data };
        if (!newData[lang]) {
          newData[lang] = {};
        }
        newData[lang] = { ...newData[lang], ...res.data };

        dispatch(setLoadedContext(newContext, newData));
      } catch (err) {
        // console.log("--LanguageLoader--useEffect--loadLang--err", err);
        // For test only
        // let newLoadedContext = { ...language.loadedContext };
        // loadcontexts.forEach((ctx) => {
        //     newLoadedContext[lang][ctx] = true;
        // });
        // dispatch(setLoadedContext(newLoadedContext));
      } finally {
        setLoading(false);
      }
    };

    // logic
    //exclude path for general users (those without tenantCode and entityCode)
    const tenantName = store.getState().global?.clientInfo?.tenantCode;

    if (
      languageList?.length > 0 &&
      contexts?.length > 0 &&
      !!tenantName &&
      ![
        "/" + LOGIN_PATH.CLIENT_CONFIGURATION,
        `/${LOGOUT_PATH.LOGOUT}`,
      ].includes(window.location.pathname) &&
      (auth || (window.location.pathname !== "/" && !auth))
    ) {
      const lang = language?.selectedLang?.key; // selected language, i.e. en, en-HK
      let _loadedContext = loadedContext?.context || {};
      // console.log("---loadedContext--", loadedContext);

      let listLoad = [];
      if (_loadedContext[lang] == null) {
        _loadedContext[lang] = {};
      }
      contexts.forEach((ctx) => {
        // check if the context is already loaded or not, if not added loadContext
        if (!_loadedContext[lang][ctx]) {
          listLoad.push(ctx);
        }
      });

      if (listLoad.length > 0) {
        loadLang(listLoad, lang);
      } else {
        let bundle = {};
        if (!!loadedContext?.data && loadedContext?.data.hasOwnProperty(lang)) {
          bundle = loadedContext?.data[lang];
          return updatei18n(lang, bundle, contexts);
        }
        setLoading(false);
      }
    } else {
      setLoading(false);
    }

    return () => {
      setLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language?.selectedLang?.key]);

  async function updatei18n(lang, bundle, loadcontexts) {
    if (i18n.language !== lang) {
      locale(lang);
      await i18n.changeLanguage(lang);
    }

    i18n.addResourceBundle(lang, "translation", bundle, true, true);

    //for kendo && primeapi component localization
    if (loadcontexts.includes("eeportal_base_control.label")) {
      // ignore error thrown here
      try {
        loadMessages(bundle, lang);
        addLocale(lang, bundle);
      } catch (error) {}
    }

    setLoading(false);
  }

  function setLoading(isLoading) {
    setIsLoading(isLoading);
    if (isLoading) {
      dispatch(openLoading());
      return;
    }
    dispatch(closeLoading());
  }

  if (isLoading) {
    return <></>;
  }

  return <>{Array.isArray(children) ? children.map((c) => c) : children}</>;
};
export default LanguageLoader;
