import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "primereact/button";

import { FormControlsGenerator } from "./utils";
import BaseForm from "./../../../../../../components/base-form/base-form";

const SectionWithDataArray = (props) => {
  console.log("SectionWithDataArray", props.cData);
  // TODO - the masking is dynamic, we need to update this to use Map()
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map
  const [mapMasked, setMapMasked] = useState(new Map([]));
  const { t } = useTranslation();

  const tab = [];
  const key = props.currIndex ?? "";
  const i18nConstant = props.i18nConstant ?? "";
  if (!props.cData || props.cData.length === 0) {
    if (props.readOnly) {
      return <div style={{ padding: "1rem" }}>{props.noDataLabel}</div>;
    }
    return null;
  }

  const onStateChange = (key, state) => {
    let newMap = { ...mapMasked };
    newMap[key] = state;
    setMapMasked(newMap);
  };

  props.cData.forEach((data, index) => {
    if (mapMasked[key + index + "-close"] === undefined) {
      onStateChange(key + index + "-close", true);
    }

    const structureSettings = FormControlsGenerator(
      props.fields,
      props.hideTooltip,
      mapMasked,
      setMapMasked,
      props.enumData,
      t,
      i18nConstant,
      index
    );

    const formConfig = props.formStructure(structureSettings);

    tab.push(
      <div key={index}>
        <div
          className="c-group-sub-title"
          onClick={() => {
            onStateChange(
              key + index + "-close",
              !mapMasked[key + index + "-close"]
            );
          }}
        >
          {`${props.panelTitle} (${index + 1})`}

          <div className="c-group-sub-tile-action">
            <Button
              type="button"
              icon={
                mapMasked[key + index + "-close"]
                  ? "pi pi-chevron-down"
                  : "pi pi-chevron-up"
              }
              className="p-button-text"
            />
          </div>
        </div>
        <div
          className={`${
            mapMasked[key + index + "-close"] ? "hide-panel" : ""
          } c-group-panel-section-data-array`}
        >
          <BaseForm
            id={key + index}
            config={formConfig}
            form={data}
            readOnly={props.readOnly}
          />
        </div>
      </div>
    );
  });
  return tab;
};

export default SectionWithDataArray;
