import { ScrollPanel } from "primereact/scrollpanel";
import { Button } from "primereact/button";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setSideBar } from "../../../../redux/actions/sidebar";
import { Link, useLocation } from "react-router-dom";

//const
import { PortalLink } from "../../../../services/utils";
import { MENU_TYPE, ViewType } from "../../../../constants";
import { useEffect } from "react";

const MainSideBarLeaf = (props) => {
  const dispatch = useDispatch();
  const sideBarInfo = useSelector((state) => state.sidebar) || null;
  const match = useLocation();

  useEffect(() => {
    if (props.type === ViewType.WEB) {
      document.body.classList.add("push-margin");
      return () => {
        document.body.classList.remove("push-margin");
      };
    }
  }, []);

  // My Docs Submenus
  function SidebarSubmenus(props) {
    const subMenus = props.subMenus;

    let selectedSidebarSubMenus = sideBarInfo.isLeafNode ?? true;

    return (
      <>
        <div className="menu-item-my-docs-submenus-container selected-menu-item-my-docs-submenus-container">
          <div className="menu-item-my-docs-submenus-content">
            <h3 className="menu-item-my-docs-submenus-heading-text">
              <Button
                icon="pi pi-chevron-left"
                iconPos="right"
                onClick={() => {
                  let newSideBarInfo = {};
                  newSideBarInfo = {
                    ...sideBarInfo,
                    isLeafNode: !selectedSidebarSubMenus,
                  };
                  dispatch(setSideBar(newSideBarInfo));
                  return;
                }}
              />
              <Link to={PortalLink(subMenus.key)}>
                <p>{subMenus.label}</p>
              </Link>
            </h3>
            <ScrollPanel className="sidebar-submenu-scroll-panel">
              {props.subMenus !== undefined ? (
                <ul className="my-docs-container">
                  <SidebarSubmenuItem
                    key={subMenus.key}
                    subMenuItem={subMenus?.children.filter(
                      (x) => x.type === MENU_TYPE.MENU
                    )}
                    onSelectRework={(e) => props.onSelectRework(e)}
                  />
                </ul>
              ) : null}
            </ScrollPanel>
          </div>
        </div>
      </>
    );
  }

  function SidebarSubmenuItem(props) {
    const subMenuItem = props.subMenuItem;

    return (
      <>
        {subMenuItem?.map((subMenu) => {
          const selectedSubMenuItem = sideBarInfo.selectedKey === subMenu.key;
          return (
            <li
              key={subMenu.key}
              className="my-docs-list-container"
              onClick={() => {
                const currPath = match.pathname;
                const selectedPath = `/${subMenu?.data?.function?.url}`;
                if (selectedSubMenuItem) {
                  let newSideBarInfo = {};
                  newSideBarInfo = {
                    ...sideBarInfo,
                    isOpenSidebarMobile: false,
                  };
                  dispatch(setSideBar(newSideBarInfo));
                  // return;
                }

                //prevent history push activity
                if (currPath !== selectedPath) {
                  props.onSelectRework(subMenu);
                }
              }}
            >
              <div
                className={`${
                  selectedSubMenuItem
                    ? "my-docs-item-container selected-my-docs-item-container"
                    : "my-docs-item-container"
                }`}
              >
                <div
                  className={`${
                    selectedSubMenuItem
                      ? "my-docs-item-text selected-my-docs-item-text"
                      : "my-docs-item-text"
                  }`}
                >
                  {subMenu.label}
                </div>
              </div>
            </li>
          );
        })}
      </>
    );
  }

  const renderContent = () => {
    if (props.menu) {
      return (
        <SidebarSubmenus
          subMenus={props.menu}
          onSelectRework={(e) => props.onSelectRework(e)}
        />
      );
    }
    return null;
  };

  return renderContent();
};
export default MainSideBarLeaf;
