import {
  Configuration /* , LogLevel */,
  PublicClientApplication,
} from "@azure/msal-browser";
import { LOGOUT_PATH, STORAGE_KEY } from "../constants";
import { Decrypt } from "../utils/crypto";

const AzureActiveDirectoryAppClientId: any = "";

const AzureActiveDirectoryAppRedirectUrl: any =
  window.location.origin + "/sso-redirect";

export const MSAL_CONFIG: Configuration = {
  auth: {
    clientId: AzureActiveDirectoryAppClientId,
    redirectUri: AzureActiveDirectoryAppRedirectUrl,
    postLogoutRedirectUri: window.location.origin + "/" + LOGOUT_PATH.LOGOUT,
  },
  cache: {
    cacheLocation: "sessionStorage",
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        // switch (level) {
        //   case LogLevel.Error:
        //     console.error(message);
        //     return;
        //   case LogLevel.Info:
        //     console.info(message);
        //     return;
        //   case LogLevel.Verbose:
        //     console.debug(message);
        //     return;
        //   case LogLevel.Warning:
        //     console.warn(message);
        //     return;
        // }
      },
    },
  },
};

export function MsalConfig() {
  var clientId = Decrypt(localStorage.getItem(STORAGE_KEY.APP_CLIENT_ID));
  if (clientId) {
    return new PublicClientApplication({
      ...MSAL_CONFIG,
      auth: {
        ...MSAL_CONFIG.auth,
        clientId: clientId,
      },
    });
  } else {
    return new PublicClientApplication(MSAL_CONFIG);
  }
}
