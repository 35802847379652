import "./mfa-login.scss";

import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "primereact/button";

import BaseForm from "../../../../../../components/base-form/base-form";
import { getControlModel } from "../../../../../../components/base-control/base-cotrol-model";

import { CancelBtn } from "./../utils";

import { AuthenticationService } from "../../../../../../services/hrmnet-api";

const MFALogin = (props) => {
  const { t } = useTranslation();

  const onResendOtp = async () => {
    try {
      await AuthenticationService.authenticationRefreshMfaOtp({
        body: {
          employeeCode: props.form?.employeeCode,
        },
      });
    } catch (error) {}
  };

  const renderRefreshOtp = () => {
    return (
      <Button
        className="hris-mfa-resendOtp-button"
        label={t("login_mfa_resendOTP")}
        onClick={(e) => onResendOtp(e)}
      />
    );
  };

  const mfaForm = (formSubmitFn, afterFormSubmitFn) => {
    const _mfaForm = {
      controls: [
        getControlModel({
          key: "otp",
          label: t("login_mfa_title"),
          placeholder: " ",
          required: true,
          noRequiredLabel: true,
        }),
      ],
      layout: {
        rows: [
          {
            columns: [
              {
                control: "otp",
              },
            ],
          },
          {
            config: {
              style: {
                marginBottom: "1rem",
                marginTop: "1rem",
              },
            },
            columns: [
              props.loginBtn,
              CancelBtn(t("login_cancel"), props.onCancel),
            ],
          },
        ],
      },
      formSubmitFn,
      afterFormSubmitFn,
    };

    return _mfaForm;
  };

  return (
    <>
      <BaseForm
        config={mfaForm(props.formSubmitFn, props.afterFormSubmitFn)}
        form={props.form}
      />
      {renderRefreshOtp()}
    </>
  );
};

export default MFALogin;
