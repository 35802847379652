// Components
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import jwt_decode from "jwt-decode";
import moment from "moment";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { setCountdownTimer } from "../../../../redux/actions/countdown-timer";
import { LOGOUT_PATH } from "../../../../constants";

const CountdownTimer = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const clientInfo = useSelector((state) => state.global?.clientInfo);
  const countdownTimerContent = useSelector((state) => state.countdownTimer);
  const authInfo = useSelector((state) => state.auth);

  // Handle token expired or expiring soon
  // const auth = useSelector((state) => state.auth);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [tokenSoonExpired, setTokenSoonExpired] = useState(false);

  // Post R1 allow user to customize and set countdown time in redux
  // R1 - 3 minutes (180 seconds) countdown timer
  const countdown = countdownTimerContent?.countdownTime;
  const userCustomizeCdTime = countdownTimerContent?.userCustomizeCdTime;

  let initialTime = moment(countdown).diff(
    moment(new Date().getTime()),
    "seconds"
  );
  if (initialTime < 0) {
    initialTime = 0;
  }
  const [displayCountdown, setDisplayCountdown] = useState(initialTime);

  useEffect(() => {
    // Handle if user clear browser data while countdown timer is still running
    if (!localStorage.getItem("persist:global")) {
      history.push(`/${LOGOUT_PATH.LOGOUT}`);
      return;
    }
    if (displayCountdown === 0) {
      setTokenExpired(true);
      setTokenSoonExpired(true);
      dispatch(setCountdownTimer({ countdownTime: 0 }));
      props.onComplete();
      return;
    }
    const countdownTimer = setTimeout(async () => {
      if (authInfo?.token) {
        const res = authInfo;
        var decodedToken = jwt_decode(res?.token);
        // For testing - set HRIS.Api backend (appsettings.json) - "TokenExpires": "4" (line 19)
        // POST http://localhost:5100/authentication/authenticatebyusername with valid credentials
        // Copy the token, uncomment (line 54) and paste inside of jwt_decode("eyJhbGciOi...") and comment out (line 50)
        // var decodedToken = jwt_decode("eyJh...");

        // Check if token is expired
        if (decodedToken?.exp * 1000 < new Date().getTime() && !tokenExpired) {
          // If token is expired set display countdown to zero
          setDisplayCountdown(0);
          setTokenExpired(true);
          return;
        }

        // Check if token is expiring soon
        var tokenLeftoverSeconds = moment(decodedToken?.exp * 1000).diff(
          moment(new Date().getTime()),
          "seconds"
        );
        if (tokenLeftoverSeconds < userCustomizeCdTime && !tokenSoonExpired) {
          // The token is expiring soon but the countdown is already ongoing
          // If the ongoing countdown is lesser than the leftover seconds
          // Then use back the ongoing countdown timer and countdown
          // Else set the countdown with token leftover seconds
          if (displayCountdown < tokenLeftoverSeconds) {
            let convertToTime = moment(countdown).diff(
              moment(new Date().getTime()),
              "seconds"
            );
            if (convertToTime < 0) {
              convertToTime = 0;
            }
            setDisplayCountdown(convertToTime);
            return;
          }

          // If token is expiring soon set countdown with the leftover seconds
          if (tokenLeftoverSeconds < 0) {
            tokenLeftoverSeconds = 0;
          }
          setDisplayCountdown(tokenLeftoverSeconds);
          return;
        }
      }

      let convertToTime = moment(countdown).diff(
        moment(new Date().getTime()),
        "seconds"
      );
      if (convertToTime < 0) {
        convertToTime = 0;
      }
      setDisplayCountdown(convertToTime);
    }, 1000);
    return () => {
      clearTimeout(countdownTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, countdown, displayCountdown]);

  function convertSecondsToTimeObj(input) {
    // Convert to minutes
    var divisor_for_minutes = input % (60 * 60);
    var minutes = Math.floor(divisor_for_minutes / 60);

    // Convert to seconds
    var divisor_for_seconds = divisor_for_minutes % 60;
    var seconds = Math.ceil(divisor_for_seconds);

    // Time object
    var obj = {
      minutes: minutes,
      seconds: seconds,
    };

    // Return time object
    return obj;
  }

  // Convert to time object and display countdown
  let timeObj = convertSecondsToTimeObj(displayCountdown);

  // Zero pad add leading zero for countdown timer
  const zeroPad = (value) => {
    if (value === 0) return `0${value}`;
    if (value > 9) {
      return `${value}`;
    }
    return `0${value}`;
  };

  return (
    <div className="cd-timer-content">
      {displayCountdown === 0 ? (
        <span>{t("misc_idle_dialog_complete_subtitle")}</span>
      ) : (
        <span>
          {t("misc_idle_dialog_subtitle")}&nbsp;{zeroPad(timeObj?.minutes)}:
          {zeroPad(timeObj?.seconds)}
        </span>
      )}
    </div>
  );
};

export default CountdownTimer;
