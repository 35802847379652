import "./portal.scss";

import React, { useEffect, useState } from "react";

//import FullPageLayout from "./layouts/full-page-layout";
import HorizontalLayout from "./layouts/horizontal-layout";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ScrollTop } from "primereact/scrolltop";
//import { useParams } from "react-router-dom";
//import { useSelector } from "react-redux";

function Portal() {
  const [/* state, */ setState] = useState({
    loading: false,
  });
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    const getUserInfo = async () => {
      /* get authorize user info */
    };
    getUserInfo();
  }, [dispatch, setState, history]);

  //return <>{state.loading ? null : <HorizontalLayout />}</>;

  return (
    <>
      <HorizontalLayout />
      <div className="scroll-to-top">
        <div className="scroll-to-top-content-wrapper">
          <ScrollTop className="scroll-to-top-button" icon="pi pi-arrow-up" />
        </div>
      </div>
    </>
  );
}

export default Portal;
