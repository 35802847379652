import "./contact.scss";

import { Animated } from "react-animated-css";
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Skeleton } from "primereact/skeleton";

import BaseForm from "./../../../../../components/base-form/base-form";

// services
import { EmployeeDetailsService } from "./../../../../../services/hrmnet-api";
import { EmployeeTabConfigurationService } from "./../../../../../services/hrmnet-api";

//components
import {
  MyInfoSkeleton,
  FormControlsGenerator,
  ToPascalCase,
  GetControlType,
} from "./components/utils";
import SectionWithDataArray from "./components/section-with-data-array";
import { PortalSystemErrorLink } from "../../../../../services/utils";

const Contact = (props) => {
  const { t } = useTranslation();
  let history = useHistory();
  const [state, setState] = useState({
    loading: true,
    i18nConstant: "eeinfo_con_",
    hideTooltip: t("common_reveal"),
    sectionWithChild: [
      { sectionCode: "CON_EMERG", dataKey: "emergencyContacts" },
    ],
  });

  // TODO - the masking is dynamic, we need to update this to use Map()
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map
  const [mapMasked, setMapMasked] = useState(new Map([]));

  const mountedRef = useRef(true);
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    const loadData = async () => {
      // const apiRequestParam = await ApiRequest.createParam();
      let enumData = {};

      try {
        const cmd2 =
          EmployeeTabConfigurationService.employeeConfigurationGetContactTabConfigurations();

        const cmd3 = EmployeeDetailsService.employeeGetContactDetails();

        const [res2, res3] = await Promise.all([cmd2, cmd3]);

        let data = {};
        let sections = [];
        let currSection = [];
        let childSections = [];

        if (res3 && res3.data) {
          // data = res3.data;
          //TODO: conduct manual data mapping
          /* new API JSON format
                city: null
                correspondenceAddress: null
                country: null
                homePhone: null
                hongKongDistrict: null
                mobilePhone: null
                officePhone: null
                personalEmail: null
                postalCode: null
                remarks: null
                residentialAddress: null
                state: null
                workEmail: "alex.ct.siu@hk.tricorglobal.com"
                 */
          data = res3.data;
          data["postalAddress1"] = res3.data?.correspondenceAddress;
          // data["residentialAddress1"] = res3.data?.residentialAddress;
          // data["residentialState"] = res3.data?.state;
          // data["residentialCountry"] = res3.data?.country;
          // data["residentialCity"] = res3.data?.city;
          // data["residentialHongkongTaxForm"] = res3.data?.hongKongDistrict;
          // data["residentialPostalCode"] = res3.data?.postalCode;
        }

        if (res2 && res2.data) {
          sections = res2.data;
          if (sections.length > 0) {
            currSection = sections.map((section) => {
              let keyToPascalFields = [];
              if (section.fields.length > 0) {
                const visiblefields = section.fields.filter(
                  (x) => x.isVisible === true
                );
                keyToPascalFields = visiblefields.map((field, fIndex) => {
                  const pcKey = ToPascalCase(field.key);
                  if (field.isVisible) {
                    return {
                      ...field,
                      key: pcKey,
                      controlType: GetControlType(data[pcKey], pcKey),
                      fieldConfig: {
                        isVisible: field.isVisible,
                        isMasked: field.isMasked,
                        isEditable: field.isEditable,
                      },
                    };
                  }
                  return {};
                });
              }

              return {
                ...section,
                fields: keyToPascalFields,
              };
            });
          }
          sections = currSection.filter((x) => x.fields.length > 0);
        }

        if (sections.length === 0 || sections === undefined) {
          props.setTabViewVisibility(true);
          history.go(-1);
        } else if (
          state.childWithSection?.length > 0 &&
          sections !== undefined &&
          sections.length > 0
        ) {
          state.childWithSection.forEach((childWithSection) => {
            const filteredArray = sections.filter((value) =>
              childWithSection.sectionCodes.includes(value.sectionCode)
            );
            sections = sections.filter((x) => !filteredArray.includes(x));
            childSections.push({
              sections: filteredArray,
              data:
                childWithSection.dataKey === "all"
                  ? data
                  : data[childWithSection.dataKey],
              title: childWithSection.title,
              childTitle: childWithSection.childTitle,
            });
          });
        }

        let _state = {
          ...state,
          enumData,
          data: data,
          sections: sections,
          cSections: childSections,
          loading: false,
        };
        setState(_state);
      } catch (error) {
        history.push(PortalSystemErrorLink());
      }
    };
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *
   * @param {*} clsName
   * @param {*} controlName
   * @returns
   */
  const contactColConfig = (
    controlName,
    clsName = "p-col-12 p-md-6 p-lg-4"
  ) => {
    return {
      config: {
        className: clsName,
      },
      control: controlName,
    };
  };

  const contactField = (fields) => {
    return {
      controls: fields.length > 0 || fields !== undefined ? fields : null,
      layout:
        fields === undefined || fields.length === 0
          ? {}
          : {
              rows: [
                // [View_Section_ID] = 27
                {
                  columns: [
                    contactColConfig("homePhone", "p-col-12 p-md-4 p-lg-2"),
                    contactColConfig("officePhone", "p-col-12 p-md-4 p-lg-2"),
                    contactColConfig(
                      "mobilePhoneAreaCode",
                      "p-col-4 p-md-4 p-lg-2"
                    ),
                    contactColConfig("mobilePhone", "p-col-12 p-md-4 p-lg-2"),
                  ],
                },
                {
                  columns: [
                    contactColConfig("workEmail"),
                    contactColConfig("personalEmail"),
                  ],
                },
                // [View_Section_ID] = 27
                {
                  columns: [
                    contactColConfig("residentialAddress1", "p-col-12"), //not in db
                    contactColConfig("residentialAddress", "p-col-12"),
                    contactColConfig("residentialAddress2", "p-col-12"), //not in db
                    contactColConfig("residentialAddress3", "p-col-12"), //not in db
                  ],
                },
                {
                  columns: [
                    contactColConfig("residentialDistrict"), //not in db
                    contactColConfig("residentialState"), //not in db
                    contactColConfig("residentialCity"), //not in db
                    contactColConfig("city"),
                    contactColConfig("state"),
                    contactColConfig("country"),
                  ],
                },
                {
                  columns: [
                    contactColConfig(
                      "residentialPostalCode",
                      "p-col-12 p-md-4 p-lg-2"
                    ), //not in db
                    contactColConfig("postalCode", "p-col-12 p-md-4 p-lg-2"),
                    contactColConfig(
                      "residentialCountry",
                      "p-col-12 p-md-4 p-lg-2"
                    ), //not in db
                    contactColConfig(
                      "residentialHongkongTaxForm",
                      "p-col-12 p-md-4 p-lg-2"
                    ), //not in db
                    contactColConfig(
                      "hongKongDistrict",
                      "p-col-12 p-md-4 p-lg-2"
                    ),
                  ],
                },
                // [View_Section_ID] = 28
                {
                  columns: [
                    contactColConfig("postalAddress1", "p-col-12"), //not in db
                    contactColConfig("correspondenceAddress", "p-col-12"),
                    contactColConfig("postalAddress2", "p-col-12"), //not in db
                    contactColConfig("postalAddress3", "p-col-12"), //not in db
                  ],
                },
                {
                  columns: [
                    contactColConfig("postalDistrict"), //not in db
                    contactColConfig("postalState"), //not in db
                    contactColConfig("postalCity"), //not in db
                  ],
                },
                {
                  columns: [
                    contactColConfig(
                      "postalPostalCode",
                      "p-col-12 p-md-4 p-lg-2"
                    ), //not in db
                    contactColConfig("postalCountry", "p-col-12 p-md-4 p-lg-2"), //not in db
                    contactColConfig(
                      "postalHongkongTaxForm",
                      "p-col-12 p-md-4 p-lg-2"
                    ), //not in db
                  ],
                },
                // [View_Section_ID] = 30
                {
                  columns: [contactColConfig("remarks", "p-col-12")],
                },
                {
                  columns: [contactColConfig("reserveFields", "p-col-12")], //not in db
                },
                // [View_Section_ID] = N/A - R2 only //not in db
                {
                  columns: [
                    contactColConfig("name"),
                    contactColConfig("lastName"),
                  ],
                },
                {
                  columns: [
                    contactColConfig("emergencyMobilePhone"),
                    contactColConfig("emergencyEmail"),
                  ],
                },
                {
                  columns: [
                    contactColConfig("statusConfirmationReminderValue"),
                    contactColConfig("statusConfirmationReminderType"),
                  ],
                },
                {
                  columns: [
                    contactColConfig("statusConfirmationReminder"),
                    contactColConfig("retirementValue"),
                  ],
                },
                {
                  columns: [
                    contactColConfig("retirementType"),
                    contactColConfig("retirementOpt"),
                  ],
                },
                {
                  columns: [
                    contactColConfig("workVisaValue"),
                    contactColConfig("workVisaType"),
                  ],
                },
                {
                  columns: [
                    contactColConfig("workVisaOpt"),
                    contactColConfig("probationValue"),
                  ],
                },
                {
                  columns: [
                    contactColConfig("probationType"),
                    contactColConfig("probationOpt"),
                  ],
                },
                {
                  columns: [contactColConfig("description", "p-col-12")],
                },
                {
                  columns: [contactColConfig("file", "p-col-12")],
                },
              ],
            },
    };
  };

  const renderSection = (index, sectionTitle, fields, sectionCode) => {
    return (
      <div id={index} key={index}>
        <div className="c-group-title ep-language-toogle">
          <p>
            {t(
              state.i18nConstant +
                sectionTitle.replace("-", "_").replace(/ /g, "").toLowerCase()
            )}
          </p>
        </div>
        {
          <div className="c-group-panel">
            {state.sectionWithChild.findIndex(
              (x) => x.sectionCode === sectionCode
            ) > -1 ? (
              // <></>
              <SectionWithDataArray
                currIndex={index}
                noDataLabel={t("common_noData")}
                cData={
                  state.sectionWithChild.find(
                    (x) => x.sectionCode === sectionCode
                  ).dataKey === "all"
                    ? state.data
                    : state.data?.[
                        state.sectionWithChild.find(
                          (x) => x.sectionCode === sectionCode
                        ).dataKey
                      ]
                } //MANUAL, need to change to auto
                readOnly={props.readOnly ?? true}
                panelTitle={t(
                  state.i18nConstant +
                    sectionTitle
                      .replace("-", "_")
                      .replace(/ /g, "")
                      .toLowerCase()
                )}
                formStructure={contactField}
                fields={fields}
                hideTooltip={t(state.hideTooltip)}
                enumData={state.enumData}
                i18nConstant={state.i18nConstant}
              />
            ) : (
              // <></>
              <BaseForm
                id={"employeeContact" + index}
                config={contactField(
                  FormControlsGenerator(
                    fields,
                    t(state.hideTooltip),
                    mapMasked,
                    setMapMasked,
                    state.enumData,
                    t,
                    state.i18nConstant
                  )
                )}
                readOnly={props.readOnly ?? true}
                form={state.data}
              />
            )}
          </div>
        }
      </div>
    );
  };

  const renderContent = (
    <Animated
      animationIn="slideInRight"
      animationOut="slideOutRight"
      animationInDuration={200}
      animationOutDuration={200}
      isVisible={true}
    >
      {state.sections === null ? (
        { MyInfoSkeleton }
      ) : (
        <>
          {state?.sections?.map((section, index) =>
            renderSection(
              index,
              "contact_" + section.sectionName,
              section.fields,
              section.sectionCode
            )
          )}
        </>
      )}
    </Animated>
  );

  return (
    <>{state.loading || state.submitting ? <Skeleton /> : renderContent}</>
  );
};

export default Contact;
