import "./bottom-nav-bar.scss";

import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

// import { useTranslation } from "react-i18next";
// import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { AccessRightsService } from "../../../../services/hrmnet-api";
import { PortalLink } from "../../../../services/utils";

const BottomNavBar = () => {
  // const { i18n } = useTranslation();
  // let history = useHistory();
  const [state, setState] = useState({
    loading: true,
  });
  const selectedKey = useSelector((state) => state.sidebar.selectedKey);
  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );

  useEffect(() => {
    const getData = async () => {
      let shortcutItem = [];
      try {
        var res = await AccessRightsService.accessGetMobileShortcuts();
        // console.log("--shortcut--", res);
        if (res && res.data) {
          shortcutItem = res.data ? res.data : [];
        }
        setState({
          shortcutItem,
          loading: false,
        });
      } catch (error) {
      } finally {
        setState({
          shortcutItem: shortcutItem,
          loading: false,
        });
      }
      if (!!shortcutItem && shortcutItem.length > 0) {
        return document.body.classList.add("mobile-footer");
      }
      return document.body.classList.remove("mobile-footer");
    };
    getData();
  }, [selectedLangKey]);

  const renderShortcutConfig = []
    .concat(state.shortcutItem)
    .map((item, index) => {
      const x = item;
      if (x) {
        const path = PortalLink(x?.url);
        return (
          <div
            key={index}
            className={`p-col-3 l-footer-item${
              selectedKey && selectedKey.split("~").find((e) => e === x.url)
                ? " active"
                : ""
            }`}
          >
            <Link to={path} className="l-footer-item-content">
              <i className={`l-footer-item-icon ${x?.icon}`}></i>
              <small className="l-footer-item-text">{x?.name}</small>
            </Link>
          </div>
        );
      }
      return <div key={index}></div>;
    });

  return (
    <div className="p-d-md-none l-footer p-grid">{renderShortcutConfig}</div>
  );
};

export default BottomNavBar;
