import "./dependent.scss";

import { Animated } from "react-animated-css";
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Skeleton } from "primereact/skeleton";

import BaseForm from "./../../../../../components/base-form/base-form";

// services
import { EmployeeDetailsService } from "./../../../../../services/hrmnet-api";
import { EmployeeTabConfigurationService } from "./../../../../../services/hrmnet-api";

//components
import {
  MyInfoSkeleton,
  FormControlsGenerator,
  ToPascalCase,
  GetControlType,
} from "./components/utils";
import SectionWithDataArray from "./components/section-with-data-array";
import DataArrayWithSections from "./components/data-array-with-sections";
import { PortalSystemErrorLink } from "../../../../../services/utils";

const Depandent = (props) => {
  let history = useHistory();

  const { t } = useTranslation();
  const [state, setState] = useState({
    loading: true,
    i18nConstant: "eeinfo_dep_",
    hideTooltip: t("common_reveal"),
    sectionWithChild: [],
    childWithSection: [
      // {
      //   sectionCodes: ["DEP_BASIC", "DEP_CTZ", "DEP_CON"],
      //   dataKey: "dependents",
      //   title: "dependents",
      //   childTitle: "dependant",
      // },
    ],
    sections: [],
  });

  // TODO - the masking is dynamic, we need to update this to use Map()
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map
  const [mapMasked, setMapMasked] = useState(new Map([]));
  const mountedRef = useRef(true);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);
  /** Init data */

  useEffect(() => {
    const loadData = async () => {
      // const apiRequestParam = await ApiRequest.createParam();
      let enumData = {};

      try {
        const cmd2 =
          EmployeeTabConfigurationService.employeeConfigurationGetDependentTabConfigurations();

        const cmd3 = EmployeeDetailsService.employeeGetDependents();

        const [res2, res3] = await Promise.all([cmd2, cmd3]);

        let data = {};
        let sections = [];
        let currSection = [];
        let cSections = [];

        if (res3 && res3.data) {
          // data = res3.data;
          //TODO: conduct manual data mapping
          /* new API JSON format
        spouseHKIdCardNumber: null
        spouseName: null
        spousePassportCountry: null
        spousePassportNumber: null
         */
          data = res3.data;
          data["citizenIdNo"] = res3.data?.spouseHKIdCardNumber;
          data["firstName"] = res3.data?.spouseName;
          data["countryOfIssue"] = res3.data?.spousePassportCountry;
          data["passportIdNo"] = res3.data?.spousePassportNumber;
        }

        if (res2 && res2.data) {
          sections = res2.data;
          if (sections.length > 0) {
            currSection = sections.map((section, sIndex) => {
              let keyToPascalFields = [];
              if (section.fields.length > 0) {
                const visiblefields = section.fields.filter(
                  (x) => x.isVisible === true
                );
                keyToPascalFields = visiblefields.map((field, fIndex) => {
                  const pcKey = ToPascalCase(field.key);
                  if (field.isVisible) {
                    return {
                      ...field,
                      key: pcKey,
                      controlType: GetControlType(data[pcKey], pcKey),
                      fieldConfig: {
                        isVisible: field.isVisible,
                        isMasked: field.isMasked,
                        isEditable: field.isEditable,
                      },
                    };
                  }
                  return {};
                });
              }

              return {
                ...section,
                fields: keyToPascalFields,
              };
            });
          }
          sections = currSection.filter((x) => x.fields.length > 0);
        }

        if (sections.length === 0 || sections === undefined) {
          props.setTabViewVisibility(true);
          history.go(-1);
        } else if (
          state.childWithSection.length > 0 &&
          sections !== undefined &&
          sections.length > 0
        ) {
          state.childWithSection.forEach((childWithSection) => {
            const filteredArray = sections.filter((value) =>
              childWithSection.sectionCodes.includes(value.sectionCode)
            );
            sections = sections.filter((x) => !filteredArray.includes(x));
            cSections.push({
              sections: filteredArray,
              data:
                childWithSection.dataKey === "all"
                  ? data
                  : data[childWithSection.dataKey],
              title: childWithSection.title,
              childTitle: childWithSection.childTitle,
            });
          });
        }

        let _state = {
          ...state,
          enumData,
          data: data,
          sections: sections,
          loading: false,
        };
        setState(_state);
      } catch (error) {
        history.push(PortalSystemErrorLink());
      }
    };
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *
   * @param {*} clsName
   * @param {*} controlName
   * @returns
   */
  const dependantColConfig = (
    controlName,
    clsName = "p-col-12 p-md-6 p-lg-4"
  ) => {
    return {
      config: {
        className: clsName,
      },
      control: controlName,
    };
  };

  /**
   *
   */
  const dependantField = (fields) => {
    return {
      controls: fields.length > 0 || fields !== undefined ? fields : null,
      layout:
        fields.length === 0 || fields === undefined
          ? {}
          : {
              rows: [
                //[View_Section_ID] = 31
                {
                  columns: [
                    dependantColConfig("dependentId", "p-col-12 p-md-3 p-lg-2"), //not in db
                    dependantColConfig("employeeId", "p-col-12 p-md-3 p-lg-2"), //not in db
                  ],
                },
                {
                  columns: [
                    dependantColConfig(
                      "relationshipToEmployee",
                      "p-col-12 p-md-3 p-lg-2"
                    ), //not in db
                    dependantColConfig(
                      "relationshipOther",
                      "p-col-12 p-md-3 p-lg-2"
                    ), //not in db
                  ],
                },
                {
                  columns: [
                    dependantColConfig("firstName"), //not in db
                    dependantColConfig("spouseName"),
                    dependantColConfig("middleName"), //not in db
                    dependantColConfig("lastName"), //not in db
                  ],
                },
                {
                  columns: [
                    dependantColConfig("alias"), //not in db
                    dependantColConfig("namePrefix", "p-col-12 p-md-3 p-lg-2"), //not in db
                  ],
                },
                {
                  columns: [
                    dependantColConfig("dateOfBirth", "p-col-12 p-md-3 p-lg-2"), //not in db
                    dependantColConfig("gender", "p-col-12 p-md-3 p-lg-2"), //not in db
                  ],
                },
                {
                  columns: [
                    dependantColConfig("citizenIdNo"), //not in db
                    dependantColConfig("spouseHKIdCardNumber"),
                  ],
                },
                {
                  columns: [
                    dependantColConfig("passportIdNo"), //not in db
                    dependantColConfig("spousePassportNumber"),
                    dependantColConfig("countryOfIssue"), //not in db
                    dependantColConfig("spousePassportCountry"),
                  ],
                },
                {
                  columns: [dependantColConfig("nationality")], //not in db
                },
                {
                  columns: [dependantColConfig("address", "p-col-12")], //not in db
                },
                {
                  columns: [
                    dependantColConfig(
                      "workVisaHolder",
                      "p-col-12 p-md-3 p-lg-2"
                    ), //not in db
                    dependantColConfig(
                      "workVisaExpiryDate",
                      "p-col-12 p-md-3 p-lg-2"
                    ), //not in db
                    dependantColConfig(
                      "workVisaIssueDate",
                      "p-col-12 p-md-3 p-lg-2"
                    ), //not in db
                    dependantColConfig("arrivalDate", "p-col-12 p-md-3 p-lg-2"), //not in db
                  ],
                },
                // [View_Section_ID] = N/A - R2 only //not in db
                {
                  columns: [dependantColConfig("remarks", "p-col-12")], //not in db
                },
                {
                  columns: [dependantColConfig("reserveFields", "p-col-12")], //not in db
                },
              ],
            },
    };
  };

  const renderSection = (index, sectionTitle, fields, sectionCode) => {
    return (
      <div id={index} key={index}>
        <div className="c-group-title ep-language-toogle">
          <p>
            {t(
              state.i18nConstant +
                sectionTitle.replace("-", "_").replace(/ /g, "").toLowerCase()
            )}
          </p>
        </div>
        {
          <div className="c-group-panel">
            {state.sectionWithChild.findIndex(
              (x) => x.sectionCode === sectionCode
            ) > -1 ? (
              // <></>
              <SectionWithDataArray
                currIndex={index}
                noDataLabel={t("common_noData")}
                cData={
                  state.sectionWithChild.find(
                    (x) => x.sectionCode === sectionCode
                  ).dataKey === "all"
                    ? state.data
                    : state.data?.[
                        state.sectionWithChild.find(
                          (x) => x.sectionCode === sectionCode
                        ).dataKey
                      ]
                }
                readOnly={props.readOnly ?? true}
                panelTitle={t(
                  state.i18nConstant +
                    sectionTitle
                      .replace("-", "_")
                      .replace(/ /g, "")
                      .toLowerCase()
                )}
                formStructure={dependantField}
                fields={fields}
                hideTooltip={t(state.hideTooltip)}
                enumData={state.enumData}
                i18nConstant={state.i18nConstant}
              />
            ) : state.data.length === 0 ? (
              <div style={{ padding: "1rem" }}>{props.noDataLabel}</div>
            ) : (
              <BaseForm
                id={"employeeContact" + index}
                config={dependantField(
                  FormControlsGenerator(
                    fields,
                    t(state.hideTooltip),
                    mapMasked,
                    setMapMasked,
                    state.enumData,
                    t,
                    state.i18nConstant
                  )
                )}
                readOnly={props.readOnly ?? true}
                form={state.data}
              />
            )}
          </div>
        }
      </div>
    );
  };

  const renderContent = (
    <Animated
      animationIn="slideInRight"
      animationOut="slideOutRight"
      animationInDuration={200}
      animationOutDuration={200}
      isVisible={true}
    >
      {state.sections === null ? (
        { MyInfoSkeleton }
      ) : (
        <>
          {state?.cSections?.map((item, index) => (
            <div id={index} key={index}>
              <div className="c-group-title ep-language-toogle">
                <p>
                  {t(
                    state.i18nConstant +
                      item.title
                        .replace("-", "_")
                        .replace(/ /g, "")
                        .toLowerCase()
                  )}
                </p>
              </div>
              {
                <div className="c-group-panel">
                  <DataArrayWithSections
                    cData={item.data}
                    key={index}
                    sections={item.sections}
                    formStructure={dependantField}
                    i18nConstant={state.i18nConstant}
                    hideTooltip={state.hideTooltip}
                    panelTitle={t(state.i18nConstant + item.childTitle)}
                    readOnly={state.readOnly ?? true}
                    enumData={state.enumData}
                  />
                </div>
              }
            </div>
          ))}
          {state?.sections?.map((section, index) =>
            renderSection(
              index,
              "dependents_" + section.sectionName,
              section.fields,
              section.sectionCode
            )
          )}
        </>
      )}
    </Animated>
  );

  return <>{state.loading ? <Skeleton /> : renderContent}</>;
};

export default Depandent;
