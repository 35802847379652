import {
  downloadFile,
  GetFileDownload,
} from "../../../../components/base-control/upload-control/utils";
import { CCType } from ".";
import { store } from "../../../../redux/store";
import {
  resetRentalFormConfig,
  resetRentalFormValue,
  setRentalForm,
  setRentalFormConfig,
} from "../../../../redux/actions/rental";
import { RentalDetailsService } from "../../../../services/hrmnet-api";
import {
  FieldConfiguration,
  FormConfigInterface,
  initFormValueByControlList,
  onTrueUpdateInterface,
  initDependentFieldActionFn,
  SectionConfig,
  initFormValue,
} from "./formConfiguration";
import { prepareSubmitFormPayload } from "./formSubmit";
import { DISPLAY_LAYOUT_TYPE } from "../components/multi-step-form";
import { isResponseOk } from "../../../../utils/utils";

export enum CustomFunctionKey {
  UPLOAD_CONTROL_ON_DOWNLOAD = "uploadControlOnDownload",
  APPLICATION_TYPE_CONTROL_ON_SELECT = "applicationTypeControl/onSelect",
  APPLICATION_TYPE_CONTROL_ON_SELECT_NEW = "applicationTypeControl/onSelectNew",
  DEPENDENT_FIELD_ACTION = "common/dependentFieldAction",

  // submit claim
  ASSiGN_CLAIM_CONTROL_GET_FILES = "assignClaimControl/getFiles",
  ASSIGN_CLAIM_CONTROL_ON_REMOVE = "assignClaimControl/onRemove",

  // event function
  EVENT_FUNCTION_CALCULATE_RENTAL_FEES = "calculateRentalFees",
  EVENT_FUNCTION_CALCULATE_ELIGIBLE_AMT = "calculateEligibleAmount",
  EVENT_FUNCTION_CALCULATE_EFFECTIVE_DATE = "calculateEffectiveDate",
  EVENT_FUNCTION_CALCULATE_END_DATE = "calculateEndDate",
  EVENT_FUNCTION_GET_RENTAL_ATTACHMENT = "getRentalAttachment",
  EVENT_FUNCTION_VALIDATE_ACTUAL_AMOUNT = "validateActualAmount",
  EVENT_FUNCTION_DOWNLOAD_FILE = "downloadFile",

  // comment
  SEND_COMMENT = "sendComment",
}

export const DEPENDENT_FIELD_ACTION_WILDCARD = "$any";
export enum DEPENDENT_FIELD_ACTION_TYPE {
  HIDE = "Hide",
  SHOW = "Show",
  SET_MAX_DATE = "SetMaxDate",
  SET_MIN_DATE = "SetMinDate",
}

/**
 * dependentFieldActionFn helper
 * return new formConfig after update dynamic field display
 */
export const updateFormConfigFieldDisplay = ({
  formConfig,
  action,
  show,
}: {
  formConfig: FormConfigInterface;
  action: DependentFieldActionInterface;
  show: boolean;
}) => {
  let _formConfig: FormConfigInterface = { ...formConfig };
  const dependentFields = action.Fields.split(",").map((x) => x.trim());
  const dependentSections = action.Sections
    ? action.Sections.split(",").map((x) => Number(x.trim()))
    : [];
  _formConfig.steps.forEach((step, sIdx) => {
    step.sections.forEach((section, secIdx) => {
      const isUpdateSection = dependentSections.includes(
        Number(section.sectionId)
      );
      if (isUpdateSection) {
        _formConfig.steps[sIdx].sections[secIdx].config.hidden = !show;
      }
      section.controls.forEach((control, cIdx) => {
        const isUpdateField = dependentFields.includes(control.refKey);
        if (isUpdateSection || isUpdateField) {
          _formConfig.steps[sIdx].sections[secIdx].controls[
            cIdx
          ].layoutConfig.hidden = !show;
        }
      });
    });
  });
  return _formConfig;
};

const setMinMaxDate = ({
  dispatch,
  fields,
  minDate,
  maxDate,
}: {
  dispatch: any;
  fields: string[];
  minDate?: Date;
  maxDate?: Date;
}) => {
  const formConfig = store.getState().rental.formConfig;
  let _formConfig: FormConfigInterface = { ...formConfig };
  // const dependentFields = action.Fields.split(",").map((x) => x.trim());

  _formConfig.steps.forEach((step, sIdx) => {
    step.sections.forEach((section, secIdx) => {
      section.controls.forEach((control, cIdx) => {
        const isUpdateField = fields.includes(control.refKey);
        if (isUpdateField) {
          if (minDate) {
            _formConfig.steps[sIdx].sections[secIdx].controls[
              cIdx
            ].config.minDate = minDate;
            _formConfig.steps[sIdx].sections[secIdx].controls[
              cIdx
            ].config.viewDate = minDate;
          }
          if (maxDate) {
            _formConfig.steps[sIdx].sections[secIdx].controls[
              cIdx
            ].config.maxDate = maxDate;
          }
        }
      });
    });
  });
  dispatch(setRentalFormConfig(_formConfig));
};

export const onChangeDependentFieldActionFn = ({
  dispatch,
  action,
  update,
  section,
  control,
}: {
  dispatch: any;
  action: DependentFieldActionInterface;
  update: {
    prevValue?: any;
    value: any;
    controlState?: any;
  };
  section: SectionConfig;
  control: FieldConfiguration;
}) => {
  const updateDynamicDisplay = ({ show }: { show: boolean }) => {
    const formConfig = store.getState().rental.formConfig;
    const form = store.getState().rental.form;

    let _formConfig: FormConfigInterface = { ...formConfig };
    let _form = { ...form };

    // extract dependent fields and sections
    const dependentFields = action.Fields.split(",").map((x) => x.trim());
    const dependentSections = action.Sections
      ? action.Sections.split(",").map((x) => Number(x.trim()))
      : [];

    // special handle for DISPLAY_LAYOUT_TYPE.SECTION_TABLE
    if (section.displayLayout === DISPLAY_LAYOUT_TYPE.SECTION_TABLE) {
      const fieldGroup = control.fieldGroup;
      const sectionId = section.id;
      // loop thru formConfig, update all dependent fields and sections
      _formConfig.steps.forEach((step, sIdx) => {
        step.sections.forEach((section, secIdx) => {
          const isUpdateSection = dependentSections.includes(
            Number(section.sectionId)
          );
          if (isUpdateSection) {
            _formConfig.steps[sIdx].sections[secIdx].config.hidden = !show;
          }
          if (isUpdateSection || section.sectionId === sectionId) {
            section.controls.forEach((control, cIdx) => {
              const isUpdateField =
                dependentFields.includes(control.refKey) &&
                fieldGroup === control.fieldGroup;
              if (isUpdateSection || isUpdateField) {
                _formConfig.steps[sIdx].sections[secIdx].controls[
                  cIdx
                ].layoutConfig.hidden = !show;

                // if hide, also reset value
                if (!show) {
                  _form[control.key] = undefined;
                }
              }
            });
          }
        });
      });
    } else {
      // loop thru formConfig, update all dependent fields and sections
      _formConfig.steps.forEach((step, sIdx) => {
        step.sections.forEach((section, secIdx) => {
          const isUpdateSection = dependentSections.includes(
            Number(section.sectionId)
          );
          if (isUpdateSection) {
            _formConfig.steps[sIdx].sections[secIdx].config.hidden = !show;
          }
          section.controls.forEach((control, cIdx) => {
            const isUpdateField = dependentFields.includes(control.refKey);

            if (isUpdateSection || isUpdateField) {
              _formConfig.steps[sIdx].sections[secIdx].controls[
                cIdx
              ].layoutConfig.hidden = !show;

              // if hide, also reset value
              if (!show) {
                _form[control.key] = undefined;
              }
            }
          });
        });
      });
    }
    dispatch(setRentalFormConfig(_formConfig));
    dispatch(setRentalForm(_form));
  };

  switch (action.Action) {
    case DEPENDENT_FIELD_ACTION_TYPE.HIDE:
      updateDynamicDisplay({ show: false });
      break;
    case DEPENDENT_FIELD_ACTION_TYPE.SHOW:
      updateDynamicDisplay({ show: true });
      break;
    case DEPENDENT_FIELD_ACTION_TYPE.SET_MAX_DATE:
      if (update.value instanceof Date)
        setMinMaxDate({
          dispatch,
          fields: action.Fields.split(",").map((x) => x.trim()),
          maxDate: new Date(update.value.getTime()),
        });
      break;
    case DEPENDENT_FIELD_ACTION_TYPE.SET_MIN_DATE:
      if (update.value instanceof Date)
        setMinMaxDate({
          dispatch,
          fields: action.Fields.split(",").map((x) => x.trim()),
          minDate: new Date(update.value.getTime()),
        });
      break;
    default:
      break;
  }
};

export interface DependentFieldActionInterface {
  Value: any;
  Action: DEPENDENT_FIELD_ACTION_TYPE;
  Fields: string;
  Sections: string;
}

export const getCustomFunctionLibrary = ({ dispatch }: any) => ({
  [CustomFunctionKey.UPLOAD_CONTROL_ON_DOWNLOAD]: (file: any) => {
    downloadFile(file);
  },
  [CustomFunctionKey.APPLICATION_TYPE_CONTROL_ON_SELECT]: async (data: any) => {
    const res = await RentalDetailsService.rentalGetCloneApplication({
      applicationId: data.value,
    });

    const formUpdate = initFormValue(res.data.sections, []);

    const form = store.getState().rental.form;
    const formConfig = store.getState().rental.formConfig;
    const newForm = {
      ...form,
      ...formUpdate,
    };
    dispatch(setRentalForm(newForm));
    const newFormConfig = initDependentFieldActionFn({
      formConfig: formConfig,
      form: newForm,
    });
    dispatch(setRentalFormConfig(newFormConfig));
  },
  [CustomFunctionKey.APPLICATION_TYPE_CONTROL_ON_SELECT_NEW]: async ({
    selfKey,
    value,
  }: any) => {
    dispatch(resetRentalFormValue());
    dispatch(resetRentalFormConfig());
  },
  [CustomFunctionKey.DEPENDENT_FIELD_ACTION]: ({
    update,
    control,
    section,
  }: onTrueUpdateInterface) => {
    const dependentFieldActions: DependentFieldActionInterface[] =
      control.dependentFields ? JSON.parse(control.dependentFields) : [];
    dependentFieldActions.forEach((action) => {
      if (
        action.Value === update.value ||
        action.Value === DEPENDENT_FIELD_ACTION_WILDCARD
      ) {
        onChangeDependentFieldActionFn({
          dispatch,
          action,
          update,
          section,
          control,
        });
      }
    });
    return;
  },
  [CustomFunctionKey.EVENT_FUNCTION_CALCULATE_ELIGIBLE_AMT]: async ({
    update: { value, prevValue },
    control,
  }: onTrueUpdateInterface) => {
    // eslint-disable-next-line eqeqeq
    if (value != prevValue) {
      const form = store.getState().rental.form;
      const formConfig = store.getState().rental.formConfig;
      const formSectionConfig = store.getState().rental.formSectionConfig;
      const applicationId = formConfig.applicationId
        ? formConfig.applicationId
        : 0;
      //Create Rental application on behalf of employee
      const employeeCode =
        store.getState().rental?.employeeDetails?.employeeCode;

      const payload = {
        ...(await prepareSubmitFormPayload(formSectionConfig.sections, form, [
          CCType.ATTACHMENT,
        ])),
        employeeCode: employeeCode,
      };

      if (!employeeCode) {
        delete payload.employeeCode;
      }
      // Add Validation
      const validateRes =
        await RentalDetailsService.rentalValidateApplicationActualAmount({
          body: payload,
        });
      if (isResponseOk(validateRes)) {
        // validate pass
        const res = await RentalDetailsService.rentalRecalculateEligibleAmount({
          applicationId: applicationId,
          body: payload,
        });
        if (res?.data) {
          const newForm = initFormValueByControlList(res.data as any);
          dispatch(
            setRentalForm((form: any) => ({
              ...form,
              ...newForm,
            }))
          );
        }
      } else {
        // validate fail, reset input
        dispatch(
          setRentalForm((form: any) => ({
            ...form,
            [control.fieldName]: null,
          }))
        );
      }
    }
  },
  [CustomFunctionKey.EVENT_FUNCTION_CALCULATE_RENTAL_FEES]: async ({
    update: { value, prevValue },
    control,
  }: onTrueUpdateInterface) => {
    // eslint-disable-next-line eqeqeq
    if (value != prevValue) {
      const form = store.getState().rental.form;
      const formConfig = store.getState().rental.formConfig;
      const formSectionConfig = store.getState().rental.formSectionConfig;
      const applicationId = formConfig.applicationId
        ? formConfig.applicationId
        : 0;
      //Create Rental application on behalf of employee
      const employeeCode =
        store.getState().rental?.employeeDetails?.employeeCode;

      const payload = {
        ...(await prepareSubmitFormPayload(formSectionConfig.sections, form, [
          CCType.ATTACHMENT,
        ])),
        employeeCode: employeeCode,
      };

      if (!employeeCode) {
        delete payload.employeeCode;
      }
      const res = await RentalDetailsService.rentalRecalculateRentalFees({
        applicationId: applicationId,
        body: payload,
      });
      if (res?.data) {
        const newForm = initFormValueByControlList(res.data as any);
        dispatch(
          setRentalForm((form: any) => ({
            ...form,
            ...newForm,
          }))
        );
      }
    }
  },
  [CustomFunctionKey.EVENT_FUNCTION_CALCULATE_EFFECTIVE_DATE]: async ({
    update: { value, prevValue },
    control,
  }: onTrueUpdateInterface) => {
    // eslint-disable-next-line eqeqeq
    if (value != prevValue) {
      const form = store.getState().rental.form;
      const formConfig = store.getState().rental.formConfig;
      const formSectionConfig = store.getState().rental.formSectionConfig;
      const applicationId = formConfig.applicationId
        ? formConfig.applicationId
        : 0;
      //Create Rental application on behalf of employee
      const employeeCode =
        store.getState().rental?.employeeDetails?.employeeCode;

      const payload = {
        ...(await prepareSubmitFormPayload(formSectionConfig.sections, form, [
          CCType.ATTACHMENT,
        ])),
        employeeCode: employeeCode,
      };

      if (!employeeCode) {
        delete payload.employeeCode;
      }

      const res =
        await RentalDetailsService.rentalRecalculateReimbursementEffectiveDate({
          applicationId: applicationId,
          body: payload,
        });
      if (res?.data) {
        // custom logic add minMax date
        let effectiveDate = res.data.find(
          (field: FieldConfiguration) =>
            field.fieldName === "App_Effective_Date"
        )?.value;
        let terminationDate = res.data.find(
          (field: FieldConfiguration) =>
            field.fieldName === "App_Termination_Date"
        )?.value;
        if (effectiveDate && terminationDate) {
          setMinMaxDate({
            dispatch,
            fields: ["App_Effective_Date", "App_Termination_Date"],
            minDate: new Date(effectiveDate),
            maxDate: new Date(terminationDate),
          });
        }
        const newForm = initFormValueByControlList(res.data as any);
        dispatch(
          setRentalForm((form: any) => ({
            ...form,
            ...newForm,
          }))
        );
      }
    }
  },
  [CustomFunctionKey.EVENT_FUNCTION_CALCULATE_END_DATE]: async ({
    update: { value, prevValue },
    control,
    applyValue = true,
  }: any) => {
    // eslint-disable-next-line eqeqeq
    if (value != prevValue) {
      const form = store.getState().rental.form;
      const formConfig = store.getState().rental.formConfig;
      const formSectionConfig = store.getState().rental.formSectionConfig;
      const applicationId = formConfig.applicationId
        ? formConfig.applicationId
        : 0;
      //Create Rental application on behalf of employee
      const employeeCode =
        store.getState().rental?.employeeDetails?.employeeCode;

      const payload = {
        ...(await prepareSubmitFormPayload(formSectionConfig.sections, form, [
          CCType.ATTACHMENT,
        ])),
        employeeCode: employeeCode,
      };

      if (!employeeCode) {
        delete payload.employeeCode;
      }
      const res =
        await RentalDetailsService.rentalRecalculateReimbursementEndDate({
          applicationId: applicationId,
          body: payload,
        });
      if (res?.data) {
        // custom logic add minMax date
        let effectiveDate = res.data.find(
          (field: FieldConfiguration) =>
            field.fieldName === "App_Effective_Date"
        )?.value;
        let terminationDate = res.data.find(
          (field: FieldConfiguration) =>
            field.fieldName === "App_Termination_Date"
        )?.value;
        if (effectiveDate && terminationDate) {
          setMinMaxDate({
            dispatch,
            fields: ["App_Effective_Date", "App_Termination_Date"],
            minDate: new Date(effectiveDate),
            maxDate: new Date(terminationDate),
          });
        }
        if (applyValue) {
          const newForm = initFormValueByControlList(res.data as any);
          dispatch(
            setRentalForm((form: any) => ({
              ...form,
              ...newForm,
            }))
          );
        }
      }
    }
  },
  [CustomFunctionKey.EVENT_FUNCTION_VALIDATE_ACTUAL_AMOUNT]: async ({
    update: { value, prevValue },
    control,
  }: onTrueUpdateInterface) => {
    // eslint-disable-next-line eqeqeq
    if (value != prevValue) {
      const form = store.getState().rental.form;
      const formSectionConfig = store.getState().rental.formSectionConfig;
      //Create Rental application on behalf of employee
      const employeeCode =
        store.getState().rental?.employeeDetails?.employeeCode;

      const payload = {
        ...(await prepareSubmitFormPayload(formSectionConfig.sections, form, [
          CCType.ATTACHMENT,
        ])),
        employeeCode: employeeCode,
      };

      if (!employeeCode) {
        delete payload.employeeCode;
      }
      // Add Validation
      const validateRes =
        await RentalDetailsService.rentalValidateApplicationActualAmount({
          body: payload,
        });
      if (!isResponseOk(validateRes)) {
        // validate fail, reset input
        dispatch(
          setRentalForm((form: any) => ({
            ...form,
            [control.fieldName]: null,
          }))
        );
      }
    }
  },
  [CustomFunctionKey.SEND_COMMENT]: async (commentDetail: any) => {
    try {
      const formConfig = store.getState().rental.formConfig;
      const applicationId = formConfig.applicationId;
      const api = RentalDetailsService.rentalCreateComment({
        applicationId: applicationId,
        body: commentDetail,
      });
      const [res] = await Promise.all([api]);
      return res;
    } catch (e) {
      // show notification?
      console.error(e);
    }
  },
  [CustomFunctionKey.ASSIGN_CLAIM_CONTROL_ON_REMOVE]: ({
    relativeFormKey,
    fileIndex,
  }: any) => {
    dispatch(
      setRentalForm((form: any) => {
        let _form = { ...form };
        _form[relativeFormKey]?.splice(fileIndex, 1);
        return _form;
      })
    );
  },
  [CustomFunctionKey.EVENT_FUNCTION_GET_RENTAL_ATTACHMENT]: async () => {
    const res = await RentalDetailsService.rentalDownloadCompanyPolicy();
    if (res && res.data) {
      downloadFile({
        id: res.data.fileId,
        name: res.data.fileName,
      });
    }
  },
  [CustomFunctionKey.EVENT_FUNCTION_DOWNLOAD_FILE]: async (
    fileId: string,
    fileName: string = "Document"
  ) => {
    GetFileDownload(fileId, fileName);
  },
});
