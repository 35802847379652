import "./array-control.scss";

import {
  isError,
  BCProps,
  BCStates,
  validateControl,
  BCType,
} from "./../base-control";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { jsonEqual, makeId } from "./../../../utils/utils";
import { useTranslation } from "react-i18next";
import { Animated } from "react-animated-css";
import ArrayControlBaseForm, {
  ArrayControlBaseFormRefHandler,
  ArrayControlRefHandlerObject,
} from "./array-control-base-form";
import ArrayControlBaseField, {
  ArrayControlRefHandler,
} from "./array-control-base-field";
import {
  onInlineTableCancel,
  onInlineTableSubmit,
} from "./array-control-base-inlinetable-helper";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable, DataTableRowEditSaveParams } from "primereact/datatable";
import { Toolbar } from "primereact/toolbar";

export interface ArrayControlHeader {
  key: string;
  header: string;
}

export enum ArrayCRUDType {
  inlinescreen = "inline-screen",
  inlinetable = "inline-table",
}

export enum ArrayEditingMode {
  single = "single",
  multiple = "multiple",
}

export interface ArrayControlProps extends BCProps {
  headers?: ArrayControlHeader[];
  crudType?: ArrayCRUDType;
  editingMode?: ArrayEditingMode;
  touched?: boolean;
  saveButtonLabel?: string;
}

export interface ArrayControlState extends BCStates {}

function AssignIdToValue(value: any) {
  return value.map((x: any) => {
    if (!Object.keys(x).includes("id")) {
      return {
        ...x,
        id: makeId(),
      };
    }
    return x;
  });
}

const ArrayControl: React.FC<ArrayControlProps> = (props) => {
  const { t } = useTranslation();
  // extract props
  const ruleList = props.ruleList || [];
  if (props.required) {
    ruleList.push({
      name: "required",
    });
  }

  // State
  let initState: ArrayControlState = {
    touched: false,
    loading: true,
    value: props.value ? AssignIdToValue(props.value) : null,
    valueStr: props.value ? AssignIdToValue(props.value) : null,
    controlState: {
      invalid: true,
    },
    form: {},
  };

  // prepare state
  const [originalRows, setOriginalRows]: any[] = useState([]);
  const [editingRows, setEditingRows] = useState({});
  const [state, setState] = useState(initState);
  const mountedRef = useRef(true);
  const inlineScreenFormRef = useRef<ArrayControlBaseFormRefHandler>(null);
  const inlineTableFormCreateRef = useRef<
    (ArrayControlRefHandlerObject | null)[]
  >([]);
  const inlineTableFormEditRef = useRef<
    (ArrayControlRefHandlerObject | null)[]
  >([]);
  /** Hook */
  useEffect(() => {
    const getData = async () => {
      let value = props.value ? AssignIdToValue(props.value) : [];
      const valueStr = JSON.stringify(value);
      const _ruleList = props.ruleList || [];
      if (props.required) {
        _ruleList.push({
          name: "required",
        });
      }

      const controlState = props.controlState
        ? props.controlState
        : validateControl(_ruleList || [], value, t);
      if (!mountedRef.current) {
        if (
          editingRows &&
          Object.keys(editingRows).length === 0 &&
          Object.getPrototypeOf(editingRows) === Object.prototype
        ) {
          setEditingRows({});
        }
        return;
      }
      if (!Array.isArray(value)) {
        value = [];
      }

      setState({
        loading: false,
        touched: false,
        value,
        valueStr,
        controlState,
        form: {},
      });
    };
    getData();
    return () => {
      mountedRef.current = false;
    };
  }, [props, t]);

  /**
   * crudType :-
   * inlineTable - allow edit and create at the same times
   * inlineScreen - allow edit or create per actions
   */
  const renderInlineScreenForm = useMemo(() => {
    const isEditedValue = Object.keys(editingRows).length > 0;

    const prevvalue = isEditedValue
      ? {
          ...state.value.find(
            (x: any) =>
              x.id.toString() === Object.getOwnPropertyNames(editingRows)[0]
          ),
        }
      : {};

    return (
      <ArrayControlBaseForm
        ref={inlineScreenFormRef}
        prevvalue={prevvalue}
        headers={props?.headers}
        onTrueUpdateValue={props?.onTrueUpdateValue}
      />
    );
  }, [props.headers, editingRows]);

  const onChange = async (value: any, resetForm = false) => {
    const valueStr = JSON.stringify(value);
    const controlState = validateControl(ruleList, value, t);
    let _state = {
      ...state,
      value,
      valueStr,
      controlState,
      loading: false,
    } as any;
    if (resetForm) {
      _state.form = {};
      _state.formState = {};
      _state.invalid = false;
    }
    if (props.onChange) {
      props.onChange({
        controlState: _state.controlState,
        value: _state.value,
        valueStr: _state.valueStr,
      });
    }
    if (props.onTrueUpdateValue) {
      props.onTrueUpdateValue({
        controlState: _state.controlState,
        value: _state.value,
        valueStr: _state.valueStr,
      });
    }
    if (!jsonEqual(_state, state)) {
      setState(_state);
    }
  };

  const onRowEditInit = (e: DataTableRowEditSaveParams) => {
    const _originalRows = [...originalRows];
    const currentIndex = [...originalRows].findIndex(
      (x: any) => x.id === e.data.id
    );
    if (currentIndex === -1) {
      _originalRows.push({
        ...state.value.find((x: any) => x.id === e.data.id),
      });
      setOriginalRows(_originalRows);
    }
  };

  const onRowEditCancel = (e: any) => {
    if (
      !!inlineScreenFormRef.current &&
      props.crudType === ArrayCRUDType.inlinetable
    ) {
      onInlineTableCancel(props.headers, inlineTableFormCreateRef);
      onInlineTableCancel(props.headers, inlineTableFormEditRef);
    }
    if (
      !!inlineScreenFormRef.current &&
      props.crudType === ArrayCRUDType.inlinescreen
    ) {
      inlineScreenFormRef.current.onCancel();
    }
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label={t("base_control_array_cancel")}
          className="p-button-secondary"
          onClick={() => cancelAll()}
        />
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label={props?.saveButtonLabel || t("base_control_array_save")}
          onClick={() => saveAll()}
        />
      </React.Fragment>
    );
  };

  const saveAll = () => {
    if (props.crudType === ArrayCRUDType.inlinetable) {
      throw new Error("Code Incomplete");
    }

    let value: any = {};
    if (
      !!inlineScreenFormRef.current &&
      props.crudType === ArrayCRUDType.inlinescreen
    ) {
      value = inlineScreenFormRef.current.onSubmit();
    }

    if (value) {
      if (!value?.id && Object.keys(value).length > 0) {
        const result = [...state.value];
        result.push({ ...value });
        onChange(AssignIdToValue(result));
      } else {
        const result = [...state.value];
        const valueIdx = result.findIndex(
          (x: any) => x.id.toString() === value.id.toString()
        );
        result[valueIdx] = value;
        onChange(result);
      }

      if (
        !!inlineScreenFormRef.current &&
        props.crudType === ArrayCRUDType.inlinescreen
      ) {
        inlineScreenFormRef.current.onCancel();
      }
    }
    setEditingRows({});
  };

  const cancelAll = () => {
    if (!!inlineScreenFormRef.current) {
      inlineScreenFormRef.current.onCancel();
    }
    setEditingRows({});
  };

  const onRowEditChange = (e: any) => {
    //restrict allow editing single row
    var editedRow = e.data;
    if (
      (!!e.data && !props.editingMode) ||
      props.editingMode === ArrayEditingMode.single
    ) {
      editedRow = {};
      const keyObject = Object.keys(e.data).find(
        (x: any) => !editingRows.hasOwnProperty(x)
      );
      if (!!keyObject) {
        editedRow = { [keyObject]: e.data[keyObject] };
      }
    }

    const rowData = {
      ...state.value.find(
        (x: any) => x.id.toString() === Object.getOwnPropertyNames(editedRow)[0]
      ),
    };

    inlineScreenFormRef.current?.updateValue(rowData);

    setEditingRows(editedRow);
  };

  const rowEditValidation = (
    id: any,
    currRef: (ArrayControlRefHandlerObject | null)[] = inlineTableFormEditRef.current
  ) => {
    let isValid = false;
    let _formValue: any = {};
    if (!currRef) {
      return isValid;
    }
    if (props.crudType === ArrayCRUDType.inlinetable) {
      const submitResult = onInlineTableSubmit(props?.headers, currRef);
      isValid = !submitResult.fieldState.invalid;
      _formValue = submitResult.formValue;
    }

    if (isValid === true) {
      const result = [...state.value];
      if (!!id) {
        const valueIdx = result.findIndex(
          (x: any) => x.id.toString() === id.toString()
        );
        result[valueIdx] = { ...result[valueIdx], ..._formValue };
        onChange(result);
      } else {
        result.push({ ..._formValue });
        onChange(AssignIdToValue(result));
      }
      onInlineTableCancel(props.headers, currRef);
    }

    return isValid;
  };

  const renderColumn = useMemo(() => {
    let _headers = props.headers || [
      {
        key: "label",
        header: t("base_control_array_label"),
      },
      {
        key: "value",
        header: t("base_control_array_value"),
      },
    ];
    let headerColumn: any = [];
    _headers.forEach((header: any, index: any) => {
      const columnConfig: any = {
        key: index,
        field: header.key,
        header: header.header,
        body: (dataRow: any) => {
          const componentBody = () => {
            const { config, fieldGroup, label, ...rest } = header;
            rest.onChange = (params: any) => {
              if (props.onTrueUpdateValue) {
                props.onTrueUpdateValue(params);
              }
              return true;
            };
            if (dataRow.dump) {
              return (
                <ArrayControlBaseField
                  key={`array-control-inline-table-dump-${header.key}`}
                  ref={(el: ArrayControlRefHandler) => {
                    const refObject: ArrayControlRefHandlerObject = {
                      key: header.key,
                      controls: el,
                    };
                    inlineTableFormCreateRef.current[_headers.indexOf(header)] =
                      refObject;
                  }}
                  config={config}
                  {...rest}
                  prevvalue={null}
                  fieldKey={header.key}
                />
              );
            } else {
              let value = dataRow[header.key];
              switch (header.type) {
                case BCType.select:
                  return header.enum.find((x: any) => x.value === value)?.label;
                case BCType.checkbox:
                  return <Checkbox checked={value} disabled={true} />;

                default:
                  return value;
              }
            }
          };

          return (
            <div
              className={`bt-row bt-row-${index} bt-cell table-expandable-row`}
            >
              <span className="p-column-title p-d-md-none">
                {header.header}:
              </span>
              <div className="bt-cell-value">{componentBody()}</div>
            </div>
          );
        },
      };

      if (props.crudType === ArrayCRUDType.inlinetable) {
        columnConfig.editor = (props: any) => {
          const { config, fieldGroup, label, ...rest } = header;
          rest.onChange = (params: any) => {
            if (props.onTrueUpdateValue) {
              props.onTrueUpdateValue(params);
            }
            return true;
          };
          return (
            <ArrayControlBaseField
              key={`array-control-inline-table-edit-${header.key}`}
              ref={(el: ArrayControlRefHandler) => {
                const refObject: ArrayControlRefHandlerObject = {
                  key: header.key,
                  controls: el,
                };
                inlineTableFormEditRef.current[_headers.indexOf(header)] =
                  refObject;
              }}
              config={config}
              {...rest}
              prevvalue={props.rowData[header.key]}
              fieldKey={header.key}
            />
          );
        };
      }
      headerColumn.push(<Column {...columnConfig} />);
    });

    //actions
    if (props.crudType === ArrayCRUDType.inlinetable) {
      headerColumn.push(
        <Column
          rowEditor
          bodyClassName="array-action-column"
          headerClassName="array-action-column p-d-flex p-flex-row-reverse"
          key={`$inline-table-col-action`}
          field={"actions"}
          header={t("base_control_array_actions")}
          body={(dataRow: any, props: any) => {
            if (dataRow.dump) {
              return (
                <div className="p-d-flex p-flex-row-reverse">
                  <Button
                    type="button"
                    icon="pi pi-plus"
                    onClick={() => {
                      rowEditValidation(null, inlineTableFormCreateRef.current);
                    }}
                    className="p-button-secondary p-button-text"
                  />
                </div>
              );
            }
            return (
              <>
                {props?.rowEditor?.element}
                <Button
                  type="button"
                  icon="pi pi-trash"
                  onClick={() => {
                    let index = state.value.indexOf(dataRow);
                    state.value.splice(index, 1);

                    let _state = { ...state };
                    _state.value = state.value;
                    onChange(state.value);
                  }}
                  className="p-button-secondary p-button-text"
                />
              </>
            );
          }}
        ></Column>
      );
    }

    if (props.crudType === ArrayCRUDType.inlinescreen) {
      headerColumn.push(
        <Column
          rowEditor
          key={`$inline-screen-col-action`}
          field={"actions"}
          header={t("base_control_array_actions")}
          bodyClassName="array-action-column"
          headerClassName="array-action-column  p-d-flex p-flex-row-reverse"
          body={(dataRow: any, props: any) => {
            return (
              <div className="p-d-flex p-flex-row-reverse">
                {props?.rowEditor?.element}
                <Button
                  type="button"
                  icon="pi pi-trash"
                  onClick={() => {
                    let index = state.value.indexOf(dataRow);
                    state.value.splice(index, 1);

                    let _state = { ...state };
                    _state.value = state.value;
                    onChange(state.value);
                  }}
                  className="p-button-secondary p-button-text"
                />
              </div>
            );
          }}
        ></Column>
      );
    }

    return headerColumn;
  }, [props.headers, editingRows]);

  const renderControl = () => {
    const tableConfig: any = {};
    switch (props.crudType) {
      case ArrayCRUDType.inlinetable: {
        tableConfig.value = state.value ? [{ dump: true }, ...state.value] : [];
        tableConfig.editMode = "row";
        tableConfig.onRowEditInit = (e: any) => onRowEditInit(e);
        tableConfig.onRowEditCancel = (e: any) => onRowEditCancel(e);
        tableConfig.rowEditorValidator = (data: any) => {
          return rowEditValidation(data.id);
        };
        tableConfig.editingRows = editingRows;
        tableConfig.onRowEditChange = (e: any) => onRowEditChange(e);
        break;
      }
      case ArrayCRUDType.inlinescreen: {
        tableConfig.value = state.value ? state.value : [];
        tableConfig.editMode = "row";
        tableConfig.onRowEditInit = (e: any) => onRowEditInit(e);
        tableConfig.onRowEditCancel = (e: any) => onRowEditCancel(e);
        tableConfig.rowEditorValidator = (data: any) => {
          return true;
        };
        tableConfig.editingRows = editingRows;
        tableConfig.onRowEditChange = (e: any) => onRowEditChange(e);
        tableConfig.onRowEditSave = (e: any) => saveAll();
        break;
      }
      default: {
        tableConfig.value = state.value || [];
      }
    }

    return (
      <DataTable
        dataKey="id"
        className="array-control-table"
        scrollable
        scrollHeight="50rem"
        {...tableConfig}
      >
        {renderColumn}
      </DataTable>
    );
  };

  return (
    <>
      <div
        className={`array-control-inner p-field ${
          props.noLabel ? "no-label" : ""
        }`}
      >
        <label htmlFor={props.id}>
          {props.label}
          {props.required && !props.noRequiredLabel ? (
            <small className="required p-invalid">&nbsp;*</small>
          ) : null}
        </label>
        <div
          className={`p-inputgroup ${
            isError(state, props) ? "p-inputgroup-error" : ""
          }`}
        >
          <Animated
            animationIn="slideInUp"
            animationOut="slideOutUp"
            animationInDuration={200}
            animationOutDuration={200}
            isVisible={true}
          >
            {props.crudType === ArrayCRUDType.inlinescreen &&
              renderInlineScreenForm}
            <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} />
          </Animated>
          {renderControl()}
        </div>
      </div>
    </>
  );
};

export default ArrayControl;
