import "./privacy-policy.scss";
import { Animated } from "react-animated-css";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { setBcDynamicPaths } from "../../../redux/actions/breadcrumb";

import { ContentService } from "./../../../services/hrmnet-api";
import { ERROR_PAGE } from "../../../constants";

//wrapper
import DocumentWrapper from "./../my-docs/document-wrapper/document-wrapper";
import { PortalSystemErrorLink } from "../../../services/utils";

const PrivacyPolicy = () => {
  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );
  const isLoggedIn = useSelector((state) => state.auth?.isLoggedIn);
  const [state, setState] = useState({
    content: [],
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(
      setBcDynamicPaths([{ label: t("employeeProfile_privacyPolicy") }])
    );
  }, [dispatch, t]);

  useEffect(() => {
    const getData = async () => {
      try {
        var res = await ContentService.contentGetPrivacyPolicy();
        let content = "";

        if (res && res?.data) {
          content = res?.data;
        }
        setState({
          content: content,
        });
      } catch (error) {
        if (isLoggedIn) {
          history.push(PortalSystemErrorLink());
        } else {
          return history.push(`/${ERROR_PAGE.MAIN_ERROR_500}`);
        }
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLangKey]);

  return (
    <>
      <Animated
        animationIn="slideInRight"
        animationOut="slideOutRight"
        animationInDuration={200}
        animationOutDuration={200}
        isVisible={true}
      >
        <DocumentWrapper content={state.content} noDataTitle="common_noData" />
      </Animated>
    </>
  );
};

export default PrivacyPolicy;
