import "./notification-page.scss";

import { UserNotificationsService } from "../../../../services/hrmnet-api";
import moment from "moment";

import { useEffect, useState, useRef } from "react";
import { setNotification } from "../../../../redux/actions/notification";
import { setBcDynamicPaths } from "../../../../redux/actions/breadcrumb";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { showSpinner } from "../../../../redux/actions/spinner";
import { useHistory } from "react-router-dom";
import { Animated } from "react-animated-css";
import { useMediaQuery } from "react-responsive";
import BaseTable, {
  BUILD_SEARCH_CONFIG,
} from "./../../../../components/base-table/base-table-portal";
import {
  DEFAULT_BT_SEARCH,
  getBTConfig,
  getColumModel,
} from "./../../../../components/base-table/base-table-model";
import { getControlModel } from "./../../../../components/base-control/base-cotrol-model";

import NotificationDetails from "./notification-details";
import NotificationList from "./notification-list";
import { navigateToNotificationOrigin } from "./notification-constant";
import {
  DATE_TIME_FORMAT,
  DEFAULT_DATETIME_FORMAT,
} from "../../../../constants";

const NotificationPage = () => {
  const dispatch = useDispatch();
  const employeeCode = useSelector((state) => state.auth?.employeeCode);
  const notificationContent = useSelector((state) => state.notification);
  const selectedLangKey = useSelector(
    (state) => state.language?.language?.selectedLang?.key
  );
  const clientInfo = useSelector((state) => state.global?.clientInfo);
  const datetimeFormat =
    clientInfo?.dateTimeFormat || DATE_TIME_FORMAT.DATE_TIME_GMT;
  const { t } = useTranslation();
  const dataTable = useRef();
  let history = useHistory();
  const initialState = {
    tableConfig: null,
    loading: true,
    notifications: [],
    loadNotificationDetailsDesktop: false,
    scrollToNotificationDetails: false,
  };
  const [state, setState] = useState(initialState);
  const [rerenderLanguage, setRerenderLanguage] = useState(false);

  /** Default search */
  const BT_SEARCH = {
    ...DEFAULT_BT_SEARCH,
    sortObj: {
      key: "publishedDate",
      direction: -1,
    },
    // sortObj: {
    //   key: "message",
    //   direction: 1,
    // },
    // filterText: "payslipDescription"
  };

  // ------------ customFunction ---------------------------------------------------
  const tableSearchFn = async (searchConfig, employeeCode) => {
    if (!searchConfig) {
      searchConfig = { ...BT_SEARCH };
    }
    searchConfig = BUILD_SEARCH_CONFIG(searchConfig);

    try {
      var cmd = await UserNotificationsService.notificationGetNotifications({
        employeeCode,
      });
    } catch (error) {
      return {
        datas: [],
        total: 0,
        searchConfig: searchConfig,
      };
    }

    const [res] = await Promise.all([cmd]);
    if (res && res.data) {
      let _data = res.data;
      // Store data into redux for desktop to view previous and next notification details
      let newNotificationDesktopContent = {};
      newNotificationDesktopContent = {
        notificationDesktopContent: _data?.map((data) => {
          return {
            ...data,
            id: data?.notificationId,
            publishedDate: new moment(data?.publishedDate, datetimeFormat),
          };
        }),
      };
      dispatch(setNotification(newNotificationDesktopContent));
      setRerenderLanguage(true);
      return {
        datas: _data?.map((data) => {
          return {
            ...data,
            id: data?.notificationId,
            publishedDate: new moment(data?.publishedDate, datetimeFormat),
          };
        }),
        total: res?.data?.length,
        searchConfig: searchConfig,
      };
    } else {
      return {
        datas: [],
        total: 0,
        searchConfig: searchConfig,
      };
    }
  };

  const updateData = async (searchConfig) => {
    if (!searchConfig) {
      searchConfig = { ...BT_SEARCH };
    }
    searchConfig = BUILD_SEARCH_CONFIG(searchConfig);
    if (notificationContent?.notificationDesktopContent) {
      return {
        datas: notificationContent?.notificationDesktopContent,
        total: notificationContent?.notificationDesktopContent?.length,
        searchConfig: searchConfig,
      };
    } else {
      return {
        datas: [],
        total: 0,
        searchConfig: searchConfig,
      };
    }
  };

  // ------------ useEffect ---------------------------------------------------
  useEffect(() => {
    dispatch(setBcDynamicPaths([{ label: t("notification_notifications") }]));
  }, [dispatch, t]);

  const mountedRef = useRef(true);

  useEffect(() => {
    const initPage = async () => {
      if (mountedRef.current) return null;
    };

    try {
      initPage();
    } finally {
      if (!mountedRef.current) {
        // preventing to load twice
        mountedRef.current = true;
      }
    }
  }, [employeeCode]);

  useEffect(() => {
    if (rerenderLanguage) {
      setState(initialState);
      dataTable.current?.reload();
      dataTable.current?.rerenderLanguage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLangKey]);

  const configModel = getBTConfig({
    title: t("notification_notifications"),
    description: t("notification_description"),
    columns: [
      getColumModel({
        header: t("notification_title"),
        key: "title",
        width: 45,
        dataType: "string",
        frozen: true,
        config: {
          sortable: true,
        },
        control: getControlModel({
          placeholder: t("notification_searchTitle"),
        }),
        render: (data) => <>{data?.title}</>,
      }),
      getColumModel({
        header: t("notification_dateTime"),
        key: "publishedDate",
        width: 12,
        dataType: "date",
        filterMatchMode: "range",
        config: {
          sortable: true,
        },
        control: getControlModel({
          placeholder: t("notification_searchDateTime"),
          type: "daterange",
        }),
        render: (data) => (
          <>{moment(data?.publishedDate).format(DEFAULT_DATETIME_FORMAT)}</>
        ),
      }),
    ],
    actionsSingle: [
      {
        className: "action-button",
        renderIcon: "pi pi-sign-in",
        title: t("notification_viewMsg"),
        isOverflow: false,
        showOnGrid: true,
        clickFn: (item) => navigateToNotificationOrigin(item, history),
      },
    ],
    actionsHead: [
      {
        title: t("notification_readAll"),
        className: "p-button-read-all",
        clickFn: () => updateMessageReadAll(),
      },
    ],
    actionsMulti: [],
    hasIndex: false,
    mode: "both", // changed from "list" for render table header action
    tableConfig: {
      selectionMode: "single",
      selection:
        state.loadNotificationDetailsDesktop &&
        notificationContent?.selectedNotification,
    },
    defaultMode: "list",
    onBaseSelectionChange: (e) => {
      // If on the first e.value.status === New, then update the notification
      // read status in the redux and database once
      if (e.value.status === "New") {
        // Update Read Status in Redux and Database
        updateMessageIsRead(e.value.notificationId, e.value);
      }
    },
    hideItemSelectedToolbar: true,
    onBaseTableSingleRowSelection: true,
    rowClassName: (rowData) => {
      // console.log("rowData", rowData);
      let className = {};
      if (rowData.status === "New") {
        className["unread-notification"] = true;
      }
      return className;
    },
    onRowClick: (e) => {
      // If notification status is read
      if (e.data.status === "Read") {
        let newNotificationContent = {
          selectedNotification: e.data,
        };
        dispatch(setNotification(newNotificationContent));
        // Set state.loadNotificationDetailsDesktop to true
        // To Render Notification Details for Desktop
        setState({
          ...state,
          loadNotificationDetailsDesktop: true,
          scrollToNotificationDetails: true,
        });
      }
    },
  });

  // Update notification status to read in redux and database
  const updateMessageIsRead = async (notificationId, notfication) => {
    // Dispatch and update notification read status in redux
    let newNotificationContent = {};
    let updateNotificationReadStatus = notfication;

    let updateNotificationDesktopReadStatus = [
      ...notificationContent?.notificationDesktopContent,
    ];
    // Update Read Status in selectedNotification in Redux State
    updateNotificationReadStatus.status = "Read";
    // Update Read Status in notificationDesktopContent in Redux State
    for (var notification in updateNotificationDesktopReadStatus) {
      if (
        updateNotificationDesktopReadStatus[notification].notificationId ===
        notfication.notificationId
      ) {
        updateNotificationDesktopReadStatus[notification].status = "Read";
      }
    }
    newNotificationContent = {
      notificationDesktopContent: updateNotificationDesktopReadStatus,
      selectedNotification: updateNotificationReadStatus,
    };
    dispatch(setNotification(newNotificationContent));
    // Set state.loadNotificationDetailsDesktop to true
    // To Render Notification Details for Desktop
    setState({
      ...state,
      loadNotificationDetailsDesktop: true,
      scrollToNotificationDetails: true,
    });

    // Update notification read status in database
    try {
      await UserNotificationsService.notificationUpdateNotificationStatus({
        notificationId: notificationId,
      });
    } catch (error) {}
  };

  // Read All notifications
  const updateMessageReadAll = async () => {
    let newNotificationContent = {};

    let updateNotificationDesktopReadStatus = [
      ...notificationContent?.notificationDesktopContent,
    ];
    for (var notification in updateNotificationDesktopReadStatus) {
      updateNotificationDesktopReadStatus[notification].status = "Read";
    }
    newNotificationContent = {
      notificationDesktopContent: updateNotificationDesktopReadStatus,
    };
    dispatch(setNotification(newNotificationContent));

    // Update notification read status in database
    dispatch(showSpinner(true));
    try {
      var api =
        await UserNotificationsService.notificationUpdateAllUnreadNotificationStatus(
          {}
        );
      let [res] = await Promise.all([api]);

      if (res && res?.data && res.data.status) {
        setState(initialState);
        dataTable.current?.reload();
        dispatch(showSpinner(false));
      }
    } catch (error) {}
  };

  // When on select a row in desktop notification table, then scroll and auto focus notificationDetails component
  const scrollToDesktopNotificationDetails = (scrollRef) => {
    // If scrollRef, state.loadNotificationDetailsDesktop and notificationContent.selectedNotification is true
    // Then scroll and auto focus notificationDetails component
    if (
      scrollRef &&
      state.loadNotificationDetailsDesktop &&
      notificationContent.selectedNotification &&
      state.scrollToNotificationDetails
    ) {
      scrollRef.scrollIntoView({ behavior: "smooth", block: "start" });
      setState({
        ...state,
        scrollToNotificationDetails: false,
      });
    }
  };

  // When on click back to top, then scroll and auto focus notification DataTable component
  const scrollToDesktopNotificationGridTable = (scrollRef) => {
    // If scrollRef, state.scrollToNotificationGridTable is true
    // Then scroll and auto focus notification DataTable component
    if (scrollRef && notificationContent?.scrollToNotificationGridTable) {
      scrollRef.scrollIntoView({ behavior: "smooth", block: "start" });
      let newNotificationDetailsDesktop = {
        scrollToNotificationGridTable: false,
      };
      dispatch(setNotification(newNotificationDetailsDesktop));
    }
  };

  // Use media query to control what to display depending on device width
  const isDesktop = useMediaQuery({ minWidth: "769px" });
  const isMobile = useMediaQuery({ maxWidth: "768px" });

  return (
    <div ref={scrollToDesktopNotificationGridTable}>
      {isDesktop && (
        <Animated
          animationIn="slideInRight"
          animationOut="slideOutRight"
          animationInDuration={200}
          animationOutDuration={200}
          isVisible={true}
        >
          <BaseTable
            ref={dataTable}
            configModel={configModel}
            searchConfig={BT_SEARCH}
            searchFn={() => tableSearchFn(null, employeeCode)}
            updateData={() => updateData(null)}
            isClientSize={true}
            id="notification-table"
          />
          {/* If state.loadNotificationDetailsDesktop is true and notificationContent.selectedNotification */}
          {/* Have selectedNotification then display NotificationDetails */}
          {/* Else notificationContent.data does not have any data then display null */}
          {state.loadNotificationDetailsDesktop &&
          notificationContent.selectedNotification ? (
            <NotificationDetails
              innerRef={scrollToDesktopNotificationDetails}
              updateData={() => {
                dataTable.current?.updateData();
              }}
            />
          ) : null}
        </Animated>
      )}
      {isMobile && <NotificationList />}
    </div>
  );
};

export default NotificationPage;
