import {
  ADD_LANGUAGE_LIST,
  LANGUAGE_SET_LOADED_CONTEXT,
  LANGUAGE_CLEAR_LOADED_CONTEXT,
  SET_USER_SELECTED_LANGUAGE,
  CLEAR_LANGUAGE,
  BROADCAST_LANGUAGE_LIST,
  BROADCAST_USER_SELECTED_LANGUAGE,
} from "./../reduxConstants";

// ----------------------------------------------------------------------------
// loadedContext - usage/example
// loadedContext['en-HK']['eeportal.label.xx'] = true;
//
// ----------------------------------------------------------------------------

/**
 * @param language
 * @param loadedContext
 */
const initialState = {
  language: {
    /**
     * @field selectedLang
     * @field languageList
     */
    selectedLang: {
      /**
       * @field label
       * @field key
       * @field data
       */
      label: "English",
      key: "en-HK",
      data: {},
    },
  },
  languageList: [],
  loadedContext: {
    context: {},
    data: {},
  },
};

export default function language(state = initialState, action) {
  switch (action.type) {
    case ADD_LANGUAGE_LIST: {
      const _state = { ...state };
      let languageList = action.payload.map((x) => {
        return {
          label: x.description,
          key: x.value,
        };
      });
      _state.languageList = languageList;

      return {
        ..._state,
      };
    }
    case BROADCAST_LANGUAGE_LIST: {
      const _state = { ...state };
      let languageList = action.payload.map((x) => {
        return {
          label: x.description,
          key: x.value,
        };
      });
      _state.languageList = languageList;

      return {
        ..._state,
      };
    }
    case LANGUAGE_SET_LOADED_CONTEXT: {
      return {
        ...state,
        loadedContext: action.payload,
      };
    }
    case LANGUAGE_CLEAR_LOADED_CONTEXT: {
      return {
        ...state,
        loadedContext: { context: {}, data: {} },
      };
    }
    case BROADCAST_USER_SELECTED_LANGUAGE: {
      return {
        ...state,
        language: {
          ...state.language,
          selectedLang: state.language?.languageList?.find(
            (language) => language.key === action.payload
          ) ?? { key: action.payload },
        },
      };
    }
    case SET_USER_SELECTED_LANGUAGE: {
      return {
        ...state,
        language: {
          ...state.language,
          selectedLang: state.language?.languageList?.find(
            (language) => language.key === action.payload
          ) ?? { key: action.payload },
        },
      };
    }
    case CLEAR_LANGUAGE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
