import { useTranslation } from "react-i18next";

import "./leave-status.scss";

export enum LEAVE_STATUS {
  DRAFT = "Draft",
  PENDING_FOR_APPROVAL = "Leave application submit",
  CANCELLED = "Cancelled",
  APPROVED = "Leave application approved", // TODO confirm
  REJECTED = "Leave application rejected",

  // non workflow
  PERFORMING_APPLICATION_APPROVAL = "Performing application approval",
  LEAVE_APPLICATION_SUBMIT = "Leave application submit",
}

type LeaveStatusProps = {
  status: LEAVE_STATUS;
  data: any;
  indicator?: boolean;
  showText?: boolean;
};

const LeaveStatus = ({
  status,
  data,
  indicator = true,
  showText = true,
}: LeaveStatusProps) => {
  const { t } = useTranslation();
  let indicatorClassName = "";
  let text = "";

  if (data?.isPending) {
    indicatorClassName =
      "leave-status__indicator leave-status__indicator--pending-for-approval";
  }
  if (data?.isCancelled) {
    indicatorClassName =
      "leave-status__indicator leave-status__indicator--cancelled";
  }
  if (data?.isApproved) {
    indicatorClassName =
      "leave-status__indicator leave-status__indicator--approved";
  }
  if (data?.isRejected) {
    indicatorClassName =
      "leave-status__indicator leave-status__indicator--rejected";
  }

  switch (status) {
    case LEAVE_STATUS.DRAFT:
      indicatorClassName =
        "leave-status__indicator leave-status__indicator--draft";
      text = t("leave_leaveStatus_draft");
      break;
    case LEAVE_STATUS.PENDING_FOR_APPROVAL:
      indicatorClassName =
        "leave-status__indicator leave-status__indicator--pending-for-approval";
      text = t("leave_leaveStatus_pendingForApproval");
      break;
    case LEAVE_STATUS.CANCELLED:
      indicatorClassName =
        "leave-status__indicator leave-status__indicator--cancelled";
      text = t("leave_leaveStatus_cancelled");
      break;
    case LEAVE_STATUS.APPROVED:
      indicatorClassName =
        "leave-status__indicator leave-status__indicator--approved";
      text = t("leave_leaveStatus_approved");
      break;
    case LEAVE_STATUS.REJECTED:
      indicatorClassName =
        "leave-status__indicator leave-status__indicator--rejected";
      text = t("leave_leaveStatus_rejected");
      break;

    // temp
    case LEAVE_STATUS.PERFORMING_APPLICATION_APPROVAL:
      indicatorClassName =
        "leave-status__indicator leave-status__indicator--pending-for-approval";
      text = status;
      break;
    case LEAVE_STATUS.LEAVE_APPLICATION_SUBMIT:
      indicatorClassName =
        "leave-status__indicator leave-status__indicator--pending-for-approval";
      text = status;
      break;
    default:
      text = status;
      break;
  }

  return (
    <div className="leave-status">
      {indicator && <span className={indicatorClassName}></span>}
      {showText && <span>{text}</span>}
    </div>
  );
};

export default LeaveStatus;
