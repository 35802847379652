import { SIDEBAR_MENU } from "../reduxConstants";
export interface ISideBarMenu {
  id: number;
  name: string;
  key: string;
  type: string;
  url: string;
  subMenus: ISideBarMenu[];
}

export interface IMenu {
  sideBar: ISideBarMenu[];
  isLoading: boolean;
}

interface ISideBarAction {
  type: string;
  payload: ISideBarMenu;
}

const initialState: IMenu = {
  sideBar: [],
  isLoading: true,
};

export default function sidebar(state = initialState, action: ISideBarAction) {
  switch (action.type) {
    case SIDEBAR_MENU: {
      return {
        ...state,
        sideBar: action.payload,
        isLoading: false,
      };
    }
    default: {
      return state;
    }
  }
}
