import "./faq.scss";

import { Animated } from "react-animated-css";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { ContentService } from "../../../../services/hrmnet-api";
import { resetBcDynamicPaths } from "../../../../redux/actions/breadcrumb";

//wrapper
import DocumentWrapper from "./../../my-docs/document-wrapper/document-wrapper";
import { PortalSystemErrorLink } from "../../../../services/utils";

const Faq = () => {
  const language =
    useSelector((state) => state.language.language.selectedLang?.key) || "";
  const [state, setState] = useState({
    loading: true,
    content: [],
  });
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(resetBcDynamicPaths());
  }, [dispatch]);

  useEffect(() => {
    const getData = async () => {
      try {
        var res = await ContentService.contentGetFaq();
        let content = "";

        if (res && res?.data) {
          content = res ? res?.data : "";
        }
        setState({
          content: content,
          loading: false,
        });
      } catch (error) {
        history.push(PortalSystemErrorLink());
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return (
    <Animated
      animationIn="slideInRight"
      animationOut="slideOutRight"
      animationInDuration={200}
      animationOutDuration={200}
      isVisible={true}
    >
      <DocumentWrapper content={state.content} noDataTitle="common_noData" />
    </Animated>
  );
};

export default Faq;
