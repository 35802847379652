import { AUTH_SET_INFO, AUTH_SET_TOKEN_EXPIRED } from "./../reduxConstants";
import { AUTH_RESET_INFO } from "./../reduxConstants";

/**
 * set user status
 */
export const setAuthInfo = (authInfo) => {
  return {
    type: AUTH_SET_INFO,
    payload: { ...authInfo, loggedInDateTime: Date.now() },
  };
};

/**
 * reset user status
 */
export const resetAuthInfo = () => {
  return {
    type: AUTH_RESET_INFO,
  };
};

/**
 * set token expire to force token refresh
 */
export const setTokenExpired = (isExpired = true) => {
  return {
    type: AUTH_SET_TOKEN_EXPIRED,
    payload: isExpired,
  };
};
