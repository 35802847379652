import "./planner.scss";

//components
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setBcDynamicPaths } from "../../../../../redux/actions/breadcrumb";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import moment from "moment";

import { formSkeleton } from "../../utils";
import { Legend } from "../../components";
import {
  FUNCTION_CODE,
  LEAVE_SUBMODULE_CODE,
  EEPORTAL_LABEL_BASE_TABLE,
} from "../../../../../constants";
import {
  DATE_TYPE,
  DATE_TYPE_CONFIG,
  LEAVE_T_CONTEXT_KEY,
} from "../../constants/contants";
import {
  LEAVE_WORKFLOW_STATUS,
  LEAVE_MENU_MODULE_KEY,
  LEAVE_MENU_ACTION_KEY,
} from "../../constants/contants";
import BaseForm from "./../../../../../components/base-form/base-form";
import { getControlModel } from "./../../../../../components/base-control/base-cotrol-model";
import LanguageLoader from "../../../../../components/language-loader/language-loader";
import { sampleTeamTransactionList } from "./sample";

// services
import { LeaveService } from "../../../../../services/hrmnet-api";
import { PortalLink } from "../../../../../services/utils";
import { saveAs } from "file-saver";

const Planner = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let history = useHistory();
  const userProfile = useSelector((state) => state.user?.profile);

  const menus = useSelector((state) => state.menu.sideBar);
  const employeeSearchPermission = true;

  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );

  // let defaultFilterValues = {
  //   employeeName: [userProfile?.employeeCode],
  //   year: "2021",
  //   fromMonth: moment().format("M"),
  //   toMonth:
  //     moment().format("M") === "12" ? "12" : moment().add(1, "M").format("M"),
  // };

  const [state, setState] = useState({
    isLoading: true,
    isLoadingData: true,
    leaveCodeData: [],
    leaveDateColumn: [],
    leaveData: [],
    expandedRows: [],

    filterExpanded: true,
    form: {},
    formConfigInit: false,
    formConfig: {
      controls: [],
      layout: {},
    },
  });

  useEffect(() => {
    dispatch(setBcDynamicPaths([{ label: t("leave_planner_title") }]));
  }, [dispatch]);

  const loadFilterConfig = (options, currentFilter) => {
    let renderForm = {
      controls: [
        ...(employeeSearchPermission
          ? [
              getControlModel({
                key: "employeeName",
                label: t("leave_planner_filter_employeeName"),
                type: "multiselect",
                placeholder: t("base_control_multiselect_choose"),
                config: {
                  readOnly: false,
                  filter: true,
                },
              }),
            ]
          : []),
        getControlModel({
          key: "reportingLine",
          label: t("leave_planner_filter_reportingLine"),
          type: "select",
          placeholder: t("base_control_multiselect_choose"),
          config: {
            readOnly: false,
            filter: true,
          },
        }),
        getControlModel({
          key: "groupBy",
          label: t("leave_planner_filter_groupBy"),
          type: "select",
          placeholder: t("base_control_multiselect_choose"),
          config: {
            readOnly: false,
            filter: true,
          },
        }),
        getControlModel({
          key: "year",
          label: t("leave_planner_filter_year"),
          type: "select",
          placeholder: t("base_control_multiselect_choose"),
          config: {
            readOnly: false,
            filter: true,
          },
        }),
        getControlModel({
          key: "fromMonth",
          label: t("leave_planner_filter_monthFrom"),
          type: "select",
          placeholder: t("base_control_multiselect_choose"),
          config: {
            readOnly: false,
            filter: true,
          },
        }),
        getControlModel({
          key: "toMonth",
          label: t("leave_planner_filter_monthTo"),
          type: "select",
          placeholder: t("base_control_multiselect_choose"),
          config: {
            readOnly: false,
            filter: true,
          },
        }),
      ],
      layout: {
        rows: [
          {
            columns: [
              {
                control: "employeeName",
                config: {
                  className: "p-col-12 p-lg-4 p-md-6 p-sm-12",
                },
              },
              {
                control: "reportingLine",
                config: {
                  className: "p-col-12 p-lg-4 p-md-6 p-sm-12",
                },
              },
              // {
              //   control: "groupBy",
              //   config: {
              //     className: "p-col-12 p-lg-4 p-md-6 p-sm-12",
              //   },
              // },
            ],
          },
          {
            columns: [
              {
                control: "year",
                config: {
                  className: "p-col-12 p-lg-4 p-md-6 p-sm-12",
                },
              },
              {
                control: "fromMonth",
                config: {
                  className: "p-col-12 p-lg-4 p-md-6 p-sm-12",
                },
              },
              {
                control: "toMonth",
                config: {
                  className: "p-col-12 p-lg-4 p-md-6 p-sm-12",
                },
              },
            ],
          },
        ],
      },
    };

    const months = [
      { value: "1", label: t("leave_planner_filter_January") },
      { value: "2", label: t("leave_planner_filter_Febuary") },
      { value: "3", label: t("leave_planner_filter_March") },
      { value: "4", label: t("leave_planner_filter_April") },
      { value: "5", label: t("leave_planner_filter_May") },
      { value: "6", label: t("leave_planner_filter_June") },
      { value: "7", label: t("leave_planner_filter_July") },
      { value: "8", label: t("leave_planner_filter_August") },
      { value: "9", label: t("leave_planner_filter_September") },
      { value: "10", label: t("leave_planner_filter_October") },
      { value: "11", label: t("leave_planner_filter_November") },
      { value: "12", label: t("leave_planner_filter_December") },
    ];

    renderForm.controls.map((c) => {
      switch (c.key) {
        case "employeeName":
          if (options.employeeCodes) {
            c.enum = options.employeeCodes.map((option) => {
              return {
                ...option,
                label: option.description,
              };
            });
          }
          break;
        case "reportingLine":
          if (options.reportingLine) {
            c.enum = options.reportingLine.map((option) => {
              return {
                label: option,
                value: option,
              };
            });
          }
          break;
        case "groupBy":
          if (options.groupByList) {
            c.enum = options.groupByList.map((option) => {
              return {
                label: option,
                value: option,
              };
            });
          }
          break;
        case "year":
          if (options.year) {
            c.enum = options.year.map((option) => {
              return {
                ...option,
                label: option.description,
              };
            });
          }
          break;
        case "fromMonth":
        case "toMonth":
          c.enum = months;
          break;
        default:
          break;
      }
      return c;
    });

    let defaultForm = {};
    let currentFilterKey = currentFilter ? Object.keys(currentFilter) : null;
    if (currentFilter && currentFilterKey && currentFilterKey.length) {
      if ("reportingLine" in currentFilter) {
        defaultForm["reportingLine"] =
          currentFilter["reportingLine"].toString();
      }
      if ("filter" in currentFilter) {
        if ("filters" in currentFilter["filter"]) {
          let employeeCode = [];
          currentFilter["filter"]["filters"].forEach((f) => {
            if (f.field === "Employee_Code") {
              employeeCode.push(f.value);
            }
          });
          defaultForm["employeeName"] = employeeCode;
        }
      }
      if (employeeSearchPermission) {
        if (
          options?.employeeCodes.filter((e) => e.value === userProfile.staffId)
        ) {
          let employeeCode = [];
          employeeCode.push(userProfile.staffId);
          defaultForm["employeeName"] = employeeCode;
        }
      }
      if ("groupBy" in currentFilter) {
        defaultForm["groupBy"] = currentFilter["groupBy"].toString();
      }
      if ("year" in currentFilter) {
        defaultForm["year"] = currentFilter["year"].toString();
      }
      if ("fromMonth" in currentFilter) {
        defaultForm["fromMonth"] = currentFilter["fromMonth"].toString();
      }
      if ("toMonth" in currentFilter) {
        defaultForm["toMonth"] = currentFilter["toMonth"].toString();
      }
    }

    setState((state) => ({
      ...state,
      form: defaultForm,
      formConfigInit: true,
      formConfig: renderForm,
    }));
  };

  const summarizeData = (data) => {
    let parsedDataByMonth = [];
    let parsedDataByEmployee = [];
    let leaveCode = {};
    let allLeaveDate = [];

    data.forEach((d) => {
      let dayDataByEmployee = {};
      Object.entries(d.calendar).forEach(([monthString, leaveDays]) => {
        let dayDataByMonth = {};

        Object.entries(leaveDays).forEach(([leaveDate, leaveInfo]) => {
          dayDataByMonth[moment(leaveDate).format("D")] = leaveInfo;
          dayDataByEmployee[leaveDate] = leaveInfo;

          // leave day
          if (!(leaveDate in allLeaveDate)) {
            allLeaveDate.push(leaveDate);
          }

          // leave type
          if (!(leaveInfo[0].dateCode in leaveCode)) {
            leaveCode[leaveInfo[0].dateCode] = {};
          }
          if (leaveInfo[0]?.leaveRecord) {
            if (!(leaveInfo[0].leaveRecord.leaveCode in leaveCode)) {
              leaveCode[leaveInfo[0].leaveRecord.leaveCode] = {};
            } else if (
              !leaveCode[leaveInfo[0].leaveRecord.leaveCode]["label"]
            ) {
              leaveCode[leaveInfo[0].leaveRecord.leaveCode]["label"] =
                leaveInfo[0].leaveRecord?.leaveType;
              leaveCode[leaveInfo[0].leaveRecord.leaveCode]["color"] =
                leaveInfo[0].leaveRecord?.webColor;
            }
          }
        });

        let recordByMonth = {
          ...d,
          month: monthString,
          days: dayDataByMonth,
        };
        delete recordByMonth.calendar;
        parsedDataByMonth.push(recordByMonth);
      });

      let recordByEmployee = {
        ...d,
        days: dayDataByEmployee,
      };
      delete recordByEmployee.calendar;
      parsedDataByEmployee.push(recordByEmployee);
    });

    // date column
    allLeaveDate.sort();
    const startDay = moment(allLeaveDate[0]).startOf("month");
    const endDay = moment(allLeaveDate[allLeaveDate.length - 1]).endOf("month");
    let dateColumn = [];
    let monthColSpan = {};
    for (let d = startDay; d < endDay; d.add(1, "days")) {
      if (!dateColumn.includes(d.format("YYYY-MM-DDT00:00:00"))) {
        dateColumn.push(d.format("YYYY-MM-DDT00:00:00"));
      }
      if (!(d.format("M") in monthColSpan)) {
        monthColSpan[d.format("MMM")] = Number(
          moment(d).endOf("month").format("D")
        );
      }
    }

    // Default Leave Type
    Object.entries(leaveCode).forEach(([lc, lcInfo]) => {
      if (!Object.entries(lcInfo).length) {
        const key = Object.keys(DATE_TYPE).find((k) => DATE_TYPE[k] === lc);
        if (key) {
          leaveCode[lc]["label"] = t("leave_leaveType_".concat(key));
          leaveCode[lc]["color"] = DATE_TYPE_CONFIG[DATE_TYPE[key]]["color"];
        }
      }
    });

    setState((state) => ({
      ...state,
      leaveCodeData: leaveCode,
      leaveDateColumn: dateColumn,
      leaveData: parsedDataByEmployee,
      expandedRows: parsedDataByEmployee,
    }));
  };

  const loadData = async (body) => {
    setState((state) => ({
      ...state,
      isLoadingData: true,
    }));

    renderDayColumns(columns);

    var cmd = await LeaveService.leaveGetTeamTransactions({
      body: body,
    });
    let [res] = await Promise.all([cmd]);

    // mocked data
    // res = sampleTeamTransactionList;
    if (res && res.data) {
      if (res.data.employees) {
        summarizeData(res.data.employees);
      }
      let filterOptions = {};
      if (!state.formConfigInit && res.data.filterOptions) {
        filterOptions = res.data.filterOptions;
        loadFilterConfig(res.data.filterOptions, res.data.currentFilter);
      }
      setState((state) => ({
        ...state,
        isLoading: false,
        isLoadingData: false,
      }));
    }
  };

  useEffect(() => {
    loadData(generateFilters());
  }, [selectedLangKey]);

  const columns = [
    {
      header: state.form?.year ? state.form.year : moment().format("YYYY"),
      key: "month",
      width: 4,
      className: "info-cell info-cell__year",
      frozen: true,
    },
    {
      header: t("leave_planner_table_employee"),
      key: "employeeName",
      width: 15,
      className: "info-cell info-cell__employee",
      frozen: true,
    },
  ];

  const generateFilters = () => {
    let params = {};
    const fields = [
      "employeeName",
      "reportingLine",
      "groupBy",
      "year",
      "fromMonth",
      "toMonth",
    ];

    fields.forEach((f) => {
      if (state.form[f]) {
        params[f] = state.form[f];
        if ("employeeName" === f && employeeSearchPermission) {
          let employee = params[f].reduce((acc, e) => {
            acc.push({
              field: "employeeCode",
              operator: "eq",
              value: e,
            });
            return acc;
          }, []);

          params["Filter"] = {
            filters: employee,
            logic: "or",
          };
        }
      }
    });

    return params;
  };

  const generateLeavePlanner = () => {
    loadData(generateFilters());
  };

  const exportLeavePlanner = async () => {
    var res = await LeaveService.leaveExportLeaveTransactions(
      { body: generateFilters() },
      { responseType: "blob" }
    );

    if (res) {
      saveAs(res, "Leave Planner");
    }
  };

  // render
  const renderFormSkeleton = () => formSkeleton;

  const renderFilter = () => {
    // if (!state.showFilter) return null;

    return (
      <div className="filter-form">
        <div className="title p-mb-4 filter-title">
          {t("leave_planner_filter_title")}
          <Button
            className="show-more-btn"
            label={
              state.filterExpanded
                ? t("leave_action_showLess")
                : t("leave_action_showMore")
            }
            icon={
              state.filterExpanded ? "pi pi-chevron-up" : "pi pi-chevron-down"
            }
            iconPos="left"
            onClick={() => {
              setState((state) => ({
                ...state,
                filterExpanded: !state.filterExpanded,
              }));
            }}
          />
        </div>
        <div
          className={`filter-form-container ${
            !state.filterExpanded ? "filter-form-container-collapsed" : ""
          }`}
        >
          <BaseForm
            id="leave-planner-filter"
            config={state.formConfig}
            form={state.form}
            // onChange={() => generateLeavePlanner()}
          />
          <div className="filter-form-action">
            <Button onClick={() => exportLeavePlanner()} className="btn-margin">
              <span className="">{t("leave_report_actionExport")}</span>
            </Button>

            <Button
              onClick={() => generateLeavePlanner()}
              className="p-button next-button"
            >
              {t("leave_planner_action_generate")}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const renderDayColumns = (cols) => {
    // for (var i = 0; i < 31; i++) {
    //   const dayIndex = (i+1).toString();
    //   cols.push({
    //     header: dayIndex,
    //     className: "leave-cell",
    //     frozen: false,
    //     //width: 3,
    //     body: (rowData) => {
    //       if (dayIndex in rowData.days && rowData.days[dayIndex].length) {
    //         const cellColor =
    //           (rowData.days[dayIndex][0].dateCode === PUBLIC_HOLIDAY_LEAVE_CODE)
    //             ? PUBLIC_HOLIDAY_LEAVE_COLOR
    //             : rowData.days[dayIndex][0]?.leaveRecord?.webColor;
    //         return <div style={{ background: cellColor, height: "100%" }}></div>
    //       }
    //       return null;
    //     }
    //   });
    // };

    state.leaveDateColumn.forEach((d) => {
      cols.push({
        header: (
          <div>
            <span>
              {moment(d).format("D") === "1" ? moment(d).format("MMM") : ""}
            </span>
            <br />
            <span>{moment(d).format("D")}</span>
          </div>
        ),
        key: d,
        className: "leave-cell",
        frozen: false,
        width: 2,
        body: (rowData) => {
          if (d in rowData.days) {
            const cellColor = Object.values(DATE_TYPE).includes(
              rowData.days[d][0].dateCode
            )
              ? DATE_TYPE_CONFIG[
                  DATE_TYPE[
                    Object.keys(DATE_TYPE).find(
                      (k) => DATE_TYPE[k] === rowData.days[d][0].dateCode
                    )
                  ]
                ]["color"]
              : rowData.days[d][0]?.leaveRecord?.webColor;
            const cellBorder =
              rowData.days[d][0]?.leaveRecord?.workflowStatus ===
              LEAVE_WORKFLOW_STATUS.COMPLETE
                ? "solid"
                : "";
            return (
              <div
                style={{
                  background: cellColor,
                  height: "100%",
                  borderStyle: cellBorder,
                  borderWidth: 3,
                }}
              ></div>
            );
          }
          return null;
        },
      });
    });

    return cols;
  };

  const renderTableLegend = () => {
    return (
      <div className="leave-planner-legend">
        <div className="legend-title">{t("leave_planner_table_legend")}</div>
        <div className="legend-container">
          <div className="custom-legend approved-leave">
            <span className="legend-icon legend-square"></span>
            <span className="legend-text">
              {t("leave_planner_legend_approvedLeave")}
            </span>
          </div>
          {Object.keys(state.leaveCodeData).map((lc, i) => (
            <Legend
              key={i}
              legend="square"
              legendColor={state.leaveCodeData[lc].color}
              text={`${lc} - ${t(state.leaveCodeData[lc].label)}`}
              textColor={state.leaveCodeData[lc].color}
            />
          ))}
        </div>
      </div>
    );
  };

  const renderRegionRow = () => {
    let tableColumns = [...columns];
    tableColumns = renderDayColumns(tableColumns);

    const columnsRender = [];
    tableColumns.forEach((column, index) => {
      const columnConfig = {
        key: `bt-column-${index}`,
        field: column.key,
        header: column.header,
        frozen: column.frozen,
        filter: null,
        showFilter: true,
        sortable: column.sortable,
        className: column.className,
        style: {
          width: column.width ? `${column.width}rem` : "auto",
        },
        headerStyle: {
          width: column.width ? `${column.width}rem` : "auto",
        },
        body: column.body,
        // className: `bt-${column.key} table-expandable-header`,
        // body: (rowData, config) => {
        //   if (state.loading) {
        //     return <Skeleton></Skeleton>;
        //   } else {
        //     return (
        //       <div className={`bt-row bt-row-${config.rowIndex} bt-cell`}>
        //         <span className="p-column-title">{column.header}:</span>
        //         <div className="bt-cell-value">
        //           {renderTableCell(rowData, column)}
        //         </div>
        //       </div>
        //     );
        //   }
        // },
      };
      columnsRender.push(<Column {...columnConfig} />);
    });

    return columnsRender;
  };

  const renderLeavePlannerTable = () => {
    return (
      <div>
        {renderTableLegend()}
        <DataTable
          key="info-table"
          value={state.leaveData}
          className="p-datatable-responsive leave-planner-table"
          // rowGroupMode="subheader"
          // groupField="companyCode"
          // rowGroupHeaderTemplate={(data) => (
          //   <span className="row-group-header">
          //     {/* {t("leave_planner_table_region")}: {data.region} */}
          //     {t("Company")}: {data.companyCode}
          //   </span>
          // )}
          // rowGroupFooterTemplate={(data) => null}
          // expandableRowGroups
          // expandedRows={state.expandedRows}
          // onRowToggle={(e) =>
          //   setState((state) => ({
          //     ...state,
          //     expandedRows: e.data,
          //   }))
          // }
          scrollable
          frozenWidth="19rem"
          loading={state.isLoadingData}
        >
          {renderRegionRow()}
        </DataTable>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="footer p-grid p-align-center p-justify-between">
        <Button
          onClick={() => {
            history.push(
              PortalLink(
                `${FUNCTION_CODE.Leave}/${LEAVE_SUBMODULE_CODE.APPROVAL}`
              )
            );
          }}
          className="p-button-outlined secondary"
        >
          {t("leave_common_actionBack")}
        </Button>
        {/* <Button
          onClick={() =>
            setState((state) => ({
              ...state,
              showFilter: true,
            }))
          }
          className="p-button next-button"
        >
          {t("leave_planner_action_advanceSearch")}
        </Button> */}
      </div>
    );
  };

  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          LEAVE_T_CONTEXT_KEY.COMMON,
          LEAVE_T_CONTEXT_KEY.PLANNER,
        ]}
      />
      <div className="leave-container leave-planner">
        <div className="header">
          <div className="title">
            {t("leave_planner_title")}
            <div className="title-desc">{t("leave_planner_desc")}</div>
          </div>
        </div>
        <div className="main">
          <div className="leave-planner-filter">
            {/* Filter */}
            {state.isLoading ? null : renderFilter()}
          </div>

          <div className="leave-planner-container">
            {/* Leave Planner Table */}
            {state.isLoading ? renderFormSkeleton() : renderLeavePlannerTable()}

            {/* Footer */}
            {state.isLoading ? null : renderFooter()}
          </div>
        </div>
      </div>
    </>
  );
};

export default Planner;
