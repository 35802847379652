import { Animated } from "react-animated-css";

import React, { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
// import { useDispatch, useSelector } from "react-redux";

import { Skeleton } from "primereact/skeleton";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { TabMenu } from "primereact/tabmenu";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import Highlighter from "react-highlight-words";

import { ClaimStatus } from "../components";
import { ApplicationStatus } from "../components";
import { FUNCTION_CODE, RENTAL_SUBMODULE_CODE } from "../../../../constants";

import "./rental-application-list.scss";

// component
import BaseTable, {
  BUILD_SEARCH_CONFIG,
} from "../../../../components/base-table/base-table-portal-rental";
import { getControlModel } from "../../../../components/base-control/base-cotrol-model";
import {
  DEFAULT_BT_SEARCH,
  getBTConfig,
  getColumModel,
} from "../../../../components/base-table/base-table-model";
import { useWindowSize } from "../../utils/window-size/useWindowSize";

// services
import { RentalDetailsService } from "../../../../services/hrmnet-api";
import { resetBcDynamicPaths } from "../../../../redux/actions/breadcrumb";
import { isResponseOk } from "../../../../utils/utils";
import { showTimestampToastSuccess } from "../../../../services/utils/message";
import { PortalLink } from "../../../../services/utils";

// default search
const BT_SEARCH = {
  ...DEFAULT_BT_SEARCH,
  // sortObj: {
  //   key: "period",
  //   direction: 1,
  // },
};

const RentalApplicationList = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  let history = useHistory();
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const dataTable = useRef();
  const [state, setState] = useState({
    tableConfig: null,
    detailExpandedRows: null,
  });
  const [claims, setClaims] = useState([]);
  const [taxYears, setTaxYears] = useState([]);
  const [yearIndex, setYearIndex] = useState(-1);

  const mountedRef = useRef(true);

  useEffect(() => {
    dispatch(resetBcDynamicPaths());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const tableSearchFn = async (searchConfig) => {
    if (!searchConfig) {
      searchConfig = { ...BT_SEARCH };
    }
    searchConfig = BUILD_SEARCH_CONFIG(searchConfig);

    var cmd = await RentalDetailsService.rentalGetApplicationList({});
    const [res] = await Promise.all([cmd]);

    let years = [];
    if (res && res.data) {
      const result = {
        datas: res.data.map((x) => {
          if (!years.includes(x.taxYear)) {
            years.push(x.taxYear);
          }
          return {
            ...x,
            id: x.id.toString(),
            period: [x.applicationStartDate, " - ", x.applicationEndDate].join(
              ""
            ),
            leaseAgreementDate: [
              x.leaseAgreementStartDate,
              " - ",
              x.leaseAgreementEndDate,
            ].join(""),
          };
        }),
        total: res.data.length,
        searchConfig: searchConfig,
      };

      const yearsList = years.sort().reverse();
      const yearsMenu = yearsList.reduce((a, y) => {
        const el = { label: y, value: y };
        a.push(el);
        return a;
      }, []);

      if (result.datas.length > 0 && taxYears.length === 0) {
        setTaxYears(yearsMenu);
        setYearIndex(0);
      }

      return result;
    }
  };

  useEffect(() => {
    if (taxYears && yearIndex > -1) {
      dataTable.current.filterByTaxYear(taxYears[yearIndex].label);
    }
  }, [yearIndex]);

  const onActionClick = (subModule) => {
    history.push(PortalLink(`${FUNCTION_CODE.Rental}/${subModule}`));
  };

  const deleteDraft = async (applicationId) => {
    let res = await RentalDetailsService.rentalDeleteDraftApplication({
      applicationId,
    });
    if (isResponseOk(res)) {
      showTimestampToastSuccess({
        message: t("rental_deleteDraft_successMessage"),
        t: t,
      });
      dataTable.current.reload();
    }
  };

  const reOpenApplication = async (applicationId) => {
    let res = await RentalDetailsService.rentalReOpenApplication({
      applicationId,
    });
    if (isResponseOk(res)) {
      showTimestampToastSuccess({
        message: t("rental_reOpenApplication_successMessage"),
        t: t,
      });
      dataTable.current.reload();
    }
  };

  const configColumns = [
    getColumModel({
      header: t("rental_datatable_period"),
      key: "period",
      dataType: "string",
      // frozen: true,
      width: 12,
      config: {
        sortable: true,
      },
      isRowExpansion: false,
      isHeaderExpansion: true,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t("rental_datatable_period_placeholder"),
      }),
      render: (data) => (
        <div style={{ display: "flex", alignContent: "center" }}>
          {isMobile && (
            <ApplicationStatus status={data.status} showText={false} />
          )}
          <span>{data.period}</span>
        </div>
      ),
    }),
    getColumModel({
      header: "",
      // header: t("rental_datatable_taxYear"),
      key: "taxYear",
      dataType: "string",
      width: 0.1,
      sortable: false,
      isRowExpansion: true,
      isHeaderExpansion: false,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t("rental_datatable_taxYear_placeholder"),
      }),
      render: (data) => <></>,
    }),
    getColumModel({
      header: t("rental_datatable_status"),
      key: "status",
      dataType: "string",
      width: 10,
      isRowExpansion: true,
      isHeaderExpansion: false,
      hideMobile: false,
      hideInMobile: true,
      control: getControlModel({
        placeholder: t("rental_datatable_status_placeholder"),
      }),
      render: (data) => (
        <>
          <ApplicationStatus status={data.status} />
        </>
      ),
    }),
    getColumModel({
      header: t("rental_datatable_leaseAgreementDate"),
      key: "leaseAgreementDate",
      dataType: "string",
      width: 10,
      isRowExpansion: true,
      isHeaderExpansion: false,
      hideMobile: false,
      hideInMobile: true,
      control: getControlModel({
        placeholder: t("rental_datatable_leaseAgreementDate_placeholder"),
      }),
    }),
  ];

  const configModel = getBTConfig({
    // title: t("rental_application"),
    // description: t("rental_datatable_description"),
    columns: configColumns,
    actionsSingle: [
      {
        isOverflow: true,
        name: "View_Application",
        title: t("rental_action_viewApplication"),
        clickFn: (item) => {
          onActionClick(`${RENTAL_SUBMODULE_CODE.VIEW_APPLICATION}/${item.id}`);
        },
      },
      {
        isOverflow: true,
        name: "View_Application_History",
        title: t("rental_action_viewHistory"),
        clickFn: (item) => {
          onActionClick(
            `${RENTAL_SUBMODULE_CODE.VIEW_APPLICATION_HISTORY}/${item.id}`
          );
        },
      },
      {
        isOverflow: true,
        name: "Edit_Application",
        title: t("rental_action_editApplication"),
        clickFn: (item) => {
          onActionClick(`${RENTAL_SUBMODULE_CODE.EDIT_APPLICATION}/${item.id}`);
        },
      },
      {
        isOverflow: true,
        name: "Change_Application",
        title: t("rental_action_changeApplication"),
        clickFn: (item) => {
          onActionClick(
            `${RENTAL_SUBMODULE_CODE.CHANGE_APPLICATION}/${item.id}`
          );
        },
      },
      {
        isOverflow: true,
        name: "Submit_Claims",
        title: t("rental_action_submitClaim"),
        clickFn: (item) => {
          onActionClick(`${RENTAL_SUBMODULE_CODE.SUBMIT_CLAIM}/${item.id}`);
        },
      },
      {
        isOverflow: true,
        name: "Withdraw_Application",
        title: t("rental_action_withdrawApplication"),
        clickFn: (item) => {
          onActionClick(
            `${RENTAL_SUBMODULE_CODE.WITHDRAW_APPLICATION}/${item.id}`
          );
        },
      },
      {
        isOverflow: true,
        name: "Delete_Draft",
        title: t("rental_action_deleteDraft"),
        clickFn: (item) => {
          deleteDraft(item.id);
        },
      },
      {
        isOverflow: true,
        name: "ReOpen_Application",
        title: t("rental_action_reOpenApplication"),
        clickFn: (item) => {
          reOpenApplication(item.id);
        },
      },
    ],
    actionsSingleConfig: {
      isRowExpansion: false,
      isHeaderExpansion: true,
    },
    actionWidth: 5.3,
    hasIndex: false,
    mode: "list",
    defaultMode: "list",
    showGlobal: true,
    isExpandable: true,
    // hasColumnSelector: true,
    expandedRows: state.expandedRows,
    onRowToggle: (e) => {
      let newState = {
        ...state,
        expandedRows: e.data,
      };
      setState(newState);
    },
    rowExpansionTemplate: (data) => {
      let _data = [];
      _data.push(data);
      const record = _data[0];

      let tableColumnsOption = configColumns.map((x, index) => {
        return {
          ...x,
          // header taxYear
          header:
            x.key === "taxYear" ? t("rental_datatable_taxYear") : x.header,
          render: x.key === "taxYear" ? null : x.render,
          className: x?.frozen || x?.onTop ? "bt-frozen" : "",
          disabled: x?.frozen,
          isDefaultHide: x?.frozen,
          index,
        };
      });
      let tableColumns = tableColumnsOption.filter(
        (x) => !x.isDefaultHide || x?.frozen || x.onTop
      );

      const checkMobile = () => {
        // 1 rem = 16 px
        var viewportWidth = window.innerWidth;
        return viewportWidth <= 768;
      };

      const renderTableCell = (rowData, column) => {
        if (column.render) {
          return <div className="bt-cell-render">{column.render(rowData)}</div>;
        } else {
          let value = rowData[column.key];
          let type = column.dataType;
          switch (type) {
            case "date":
              if (value) return moment(new Date(value)).format("DD/MM/YYYY");
              return "";
            case "boolean":
              return value.toString() === "true"
                ? t("base_table_true")
                : t("base_table_false");
            default:
              if (!column.excludeGlobalFilter) {
                return (
                  <>
                    <Highlighter
                      highlightClassName="custom-highlighter"
                      searchWords={[state.table?.searchConfig.filterText]}
                      autoEscape={true}
                      textToHighlight={value?.toString() || ""}
                    ></Highlighter>
                  </>
                );
              } else {
                return value;
              }
          }
        }
      };

      const renderTableRow = () => {
        let hasFrozen = false;
        let columns = tableColumns
          ? tableColumns.filter((x) => x.isRowExpansion && !x.hideMobile)
          : [];

        const columnsRender = [];
        let noContentColumn = true;
        columns.forEach((column, index) => {
          if (column.frozen) {
            hasFrozen = true;
          } else {
            noContentColumn = false;
          }
          let filterElement;
          let filterFunction = column.filterFunction;

          if (!column.width && column.frozen) {
            column.width = 10;
          }

          //expander
          const isExpandableHeader = false;
          const isExpandableRow = column.isRowExpansion && checkMobile();
          const columnConfig = {
            filterElement,
            filterFunction,
            ...column.columnConfig,
            key: `bt-column-${index}`,
            field: column.key,
            header: column.header,
            frozen: !checkMobile() ? column.frozen : false,
            filter: null,
            showFilter: true,
            sortable: column.sortable,
            style: {
              width: column.width ? `${column.width}rem` : "auto",
            },
            headerStyle: {
              //expander + && !isExpandableHeader
              width:
                column.width && !isExpandableHeader
                  ? `${column.width}rem`
                  : "auto",
            },
            //expander + isExpandableHeader
            className: `bt-${column.key} ${
              isExpandableHeader ? "table-expandable-header" : ""
            }`,
            body: (rowData, config) => {
              if (/* state.processing || */ state.loading) {
                return <Skeleton></Skeleton>;
              } else {
                return (
                  //expander + isExpandableHeader
                  <div
                    className={`bt-row bt-row-${config.rowIndex} bt-cell ${
                      isExpandableRow ? "" : "table-expandable-row"
                    }`}
                  >
                    <span className="p-column-title">{column.header}:</span>
                    <div className="bt-cell-value">
                      {renderTableCell(rowData, column)}
                    </div>
                  </div>
                );
              }
            },
          };
          columnsRender.push(<Column {...columnConfig} />);
        });

        if (noContentColumn) {
          columnsRender.push(
            <Column
              key={`column-2000`}
              field={"dummy2"}
              header={""}
              className="bt-content-dummy"
              filter={state.showFilter ? true : null}
              filterElement={<></>}
            ></Column>
          );
        }
        if (!checkMobile() && hasFrozen) {
          columnsRender.push(
            <Column
              key={`column-1999`}
              field={"dummy"}
              header={"dummy"}
              className="bt-frozen-dummy"
              frozen
              filter={state.showFilter ? true : null}
              filterElement={<></>}
            ></Column>
          );
        }

        return columnsRender;
      };

      const tablesRender = [];
      tablesRender.push(
        <DataTable
          key="info-table"
          value={_data}
          className="p-datatable-responsive p-datatable-infotable"
        >
          {renderTableRow()}
        </DataTable>
      );

      const columnsRender = [];
      let configModel = [];
      const configModelDesktopFrozen = [
        // { key: "monthIndex", header: t("rental_datatable_month") },
        { key: "monthRefIndex", header: t("rental_datatable_month") },
      ];
      const configModelMobileFrozen = [
        { key: "claimPeriod", header: t("rental_datatable_month") },
      ];
      const configModelDesktopUnfrozen = [
        {
          key: "claimPeriodFrom",
          header: t("rental_approval_datatable_claimPeriodFrom"),
        },
        {
          key: "claimPeriodTo",
          header: t("rental_approval_datatable_claimPeriodTo"),
        },
      ];
      const configModelMobileUnfrozen = [
        { key: "status", header: t("rental_datatable_status") },
        { key: "attachmentFiles", header: t("rental_datatable_attachment") },
        { key: "totalClaim", header: t("rental_datatable_totalClaim") },
        {
          key: "approvedAmount",
          header: t("rental_approval_datatable_approvedAmount"),
        },
      ];

      if (!checkMobile()) {
        configModel = configModel.concat(
          configModelDesktopFrozen,
          configModelDesktopUnfrozen,
          configModelMobileUnfrozen
        );
      } else {
        configModel = configModel.concat(configModelMobileFrozen);
        columnsRender.push(
          <Column
            key="expander-1"
            expander
            style={{ width: "1em", padding: "0" }}
          />
        );
      }

      const monthMapping = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const roundNumberToDecimalPlacesWithThousandSeparator = (
        num,
        decimalPlaces
      ) => {
        return (
          Math.round(num * Math.pow(10, decimalPlaces)) /
          Math.pow(10, decimalPlaces)
        )
          .toFixed(decimalPlaces)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      };

      const renderColumnConfig = (column, index, showHeader = false) => {
        const columnConfig = {
          key: `bt-sub-column-${index}`,
          field: column.key,
          header: column.header,
          style: { width: "8rem" },
          headerStyle: { width: "8rem" },
          sortable:
            column.key === "monthIndex" ||
            column.key === "monthRefIndex" ||
            column.key === "claimPeriod"
              ? true
              : false,
          //className: "table-expandable-header",
          body: (rowData, config) => {
            if (state.loading) {
              return <Skeleton></Skeleton>;
            } else {
              return (
                <div
                  className={`bt-row bt-row-${config.rowIndex} bt-cell table-expandable-row`}
                >
                  {showHeader ? (
                    <span className="p-column-title">{column.header}:</span>
                  ) : null}
                  <div className="bt-cell-value">
                    {column.key === "attachmentFiles" ? (
                      rowData[column.key].map((f) => (
                        <div className="attachment-block">
                          <div className="attachment-filename">
                            {f.fileName}
                          </div>
                        </div>
                      ))
                    ) : column.key === "status" ? (
                      <ClaimStatus status={rowData[column.key]} />
                    ) : column.key === "monthIndex" ? (
                      monthMapping[rowData[column.key]]
                    ) : column.key === "monthRefIndex" ? (
                      rowData["month"]
                    ) : (
                      rowData[column.key]
                    )}
                  </div>
                </div>
              );
            }
          },
        };

        return columnConfig;
      };

      const renderMobileColumns = (data) => {
        let _data = [];
        _data.push(data);
        const mobileColumnsRender = [];

        configModelMobileUnfrozen.forEach((column, index) => {
          const columnConfig = renderColumnConfig(column, index, true);
          mobileColumnsRender.push(<Column {...columnConfig} />);
        });

        return (
          <DataTable
            value={_data}
            key="detail-mobile-table"
            className="p-datatable-mobiletable"
          >
            {mobileColumnsRender}
          </DataTable>
        );
      };

      const getPeriodClaims = async (id) => {
        var cmd = await RentalDetailsService.rentalGetClaimList({
          applicationId: id,
        });
        const [res] = await Promise.all([cmd]);

        if (res && res.data) {
          const claim = res.data.map((x, i) => {
            return {
              ...x,
              id: x.id.toString(),
              monthIndex: monthMapping.findIndex((m) => m === x.month),
              monthRefIndex: i,
              claimPeriodFrom: x.claimPeriodFrom.substr(0, 10),
              claimPeriodTo: x.claimPeriodTo.substr(0, 10),
              claimPeriod: [
                x.claimPeriodFrom.substr(0, 10),
                " - ",
                x.claimPeriodTo.substr(0, 10),
              ].join(""),
              totalClaim: `$${roundNumberToDecimalPlacesWithThousandSeparator(
                x.totalClaim,
                2
              )}`,
              approvedAmount: `$${roundNumberToDecimalPlacesWithThousandSeparator(
                x.approvedAmount,
                2
              )}`,
            };
          });
          const claimsData = { ...claims };
          claimsData[id] = claim;
          setClaims(claimsData);
        }
      };

      if (!Object.keys(claims).includes(record.id)) {
        getPeriodClaims(record.id);
      }

      configModel.forEach((column, index) => {
        const columnConfig = renderColumnConfig(column, index);
        columnsRender.push(<Column {...columnConfig} />);
      });

      tablesRender.push(
        <DataTable
          key={`detail-table`}
          value={claims[record.id]}
          className="p-datatable-detailtable"
          tableClassName="mobile-expander-header-style"
          emptyMessage={t("No results")}
          expandedRows={state.detailExpandedRows}
          onRowToggle={(e) => {
            let newState = { ...state, detailExpandedRows: e.data };
            setState(newState);
          }}
          rowExpansionTemplate={renderMobileColumns}
        >
          {columnsRender}
        </DataTable>
      );

      return tablesRender;
    },
  });

  return (
    <div className="rental-allowance-baseTable">
      <Animated
        animationIn="slideInRight"
        animationOut="slideOutRight"
        animationInDuration={200}
        animationOutDuration={200}
        isVisible={true}
      >
        {isMobile && yearIndex !== -1 && (
          <div className="dropdown-wrapper">
            <Dropdown
              value={taxYears[yearIndex].value}
              options={taxYears}
              onChange={(e) => {
                setYearIndex(taxYears.findIndex((t) => t.value === e.value));
              }}
            />
          </div>
        )}
        {!isMobile && (
          <TabMenu
            model={taxYears}
            activeIndex={yearIndex}
            onTabChange={(e) => {
              setYearIndex(e.index);
            }}
          />
        )}
        <div className="rental-datatable">
          <BaseTable
            ref={dataTable}
            configModel={configModel}
            searchConfig={BT_SEARCH}
            // searchFn={state.searchFn}
            searchFn={tableSearchFn}
            isClientSize={true}
          />
        </div>
      </Animated>
    </div>
  );
});

export default RentalApplicationList;
