import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BCProps,
  BCStates,
  isError,
  renderError,
  validateControl,
} from "../base-control";
import { Chips, ChipsChangeParams, ChipsProps } from "primereact/chips";
import { Button } from "primereact/button";
import "./chip-control.scss";
import { jsonEqual } from "../../../utils/utils";

export interface ChipControlProps extends BCProps {
  config?: any;
}
export interface ChipControlControlState extends BCStates {}

function StringToArray(
  value: string,
  separator?: string,
  allowDuplicate?: boolean
) {
  if (!value || value.length === 0) return [];
  if (Array.isArray(value)) return value;
  let result: string[] = [];
  if (!allowDuplicate) {
    const _result = value
      .split(separator ?? ",")
      .filter((x: string) => x.length > 0);
    if (value.includes(",,")) {
      _result.push(",");
    }
    if (value === ",") {
      _result.push(",");
    }
    return [...new Set(_result)];
  } else {
    result = value.split(separator ?? ",");
    const emptyIndex = result.findIndex((x: string) => x.length > 0);
    if (value.includes(",,") && emptyIndex > 0) {
      result.splice(emptyIndex, 1);
      result.push(",");
    }
  }
  return result;
}

function ArrayToString(
  value: any[],
  separator?: string,
  allowDuplicate?: boolean
) {
  if (!value || value.length === 0) return "";
  if (allowDuplicate) return value.join(`${separator}`);
  return [...new Set(value)].join(`${separator}`);
}

const ChipControl: React.FC<ChipControlProps> = (props) => {
  const DEFAULT_SEPARATOR = ",";
  const DEFAULT_ALLOWDUPLICATE = false;
  let { config, value, tooltip, controlState, required, ...rest } = props;
  const { t } = useTranslation();
  // extract rest
  const ruleList = rest.ruleList || [];
  if (required) {
    ruleList.push({
      name: "required",
    });
  }

  // State
  let initState: ChipControlControlState = {
    touched: false,
    value: StringToArray(value),
    valueStr: value,
    controlState: {
      invalid: false,
    },
  };
  initState.controlState =
    controlState || validateControl(ruleList || [], initState.value, t);
  const [state, setState] = useState(initState);
  const mountedRef = useRef(true);
  // unsubcribe
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  // Update state if control state changed
  useEffect(() => {
    const ruleList = rest.ruleList || [];
    if (required) {
      ruleList.push({
        name: "required",
      });
    }

    const _state = {
      ...state,
      value:
        value && Array.isArray(value)
          ? value
          : StringToArray(
              value || "",
              config?.separator || DEFAULT_SEPARATOR,
              config?.allowDuplicate || DEFAULT_ALLOWDUPLICATE
            ),
      valueStr: `${ArrayToString(
        value || [],
        config?.separator || DEFAULT_SEPARATOR,
        config?.allowDuplicate || DEFAULT_ALLOWDUPLICATE
      )}`,
      setDefault: true,
    };
    _state.controlState =
      controlState || validateControl(ruleList || [], _state.valueStr, t);
    if (!mountedRef.current) return;
    setState(_state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlState, required, value]);

  function GetValidate(
    values: any[],
    valueStr: string,
    allowDuplicate?: boolean
  ) {
    let result = { invalid: false };
    if (values.length === 0) {
      return validateControl(ruleList, values, t);
    } else {
      values.forEach((value) => {
        const res = validateControl(ruleList, value, t);
        if (res && res?.invalid) {
          result = res;
        }
      });
    }
    return result;
  }

  const onChange = async (e: ChipsChangeParams, updateLastDefault = false) => {
    const data = e.value ?? [];
    const dataStr = ArrayToString(
      data,
      config?.separator || DEFAULT_SEPARATOR,
      config?.allowDuplicate || DEFAULT_ALLOWDUPLICATE
    );
    let _controlState = GetValidate(
      data,
      dataStr,
      config?.allowDuplicate || DEFAULT_ALLOWDUPLICATE
    );
    let _state = {
      ...state,
      value: data,
      valueStr: dataStr,
      controlState: _controlState,
      loading: false,
    } as any;

    if (updateLastDefault) {
      _state.lastDefault = rest.defaultValue;
    }

    if (rest.onChange) {
      rest.onChange({
        controlState: _state.controlState,
        value: _state.valueStr,
        valueStr: _state.valueStr,
      });
    }
    if (rest.onTrueUpdateValue) {
      rest.onTrueUpdateValue({
        controlState: _state.controlState,
        value: _state.valueStr,
        valueStr: _state.valueStr,
        mode: "onChange",
      });
    }

    if (!jsonEqual(_state, state)) {
      setState({ ..._state, touched: true });
    }
  };

  const renderControl = () => {
    return (
      <Chips
        tooltip={state.valueStr}
        tooltipOptions={{ position: "top" }}
        className={`${props.className} ${
          isError(state, props) ? "p-invalid" : "testing"
        }`}
        separator={DEFAULT_SEPARATOR}
        allowDuplicate={DEFAULT_ALLOWDUPLICATE}
        onChange={onChange}
        value={state.value}
        placeholder={rest.placeholder}
        {...config}
        disabled={config?.readOnly}
      />
    );
  };

  return (
    <>
      <div className={`chips-inner p-field ${rest.noLabel ? "no-label" : ""}`}>
        <label htmlFor={rest.id}>
          {rest.label}
          {required && !rest.noRequiredLabel ? (
            <small className="required p-invalid">&nbsp;*</small>
          ) : null}
          <Button
            type="button"
            tooltip={`${t(`${tooltip || "base_control_chip_toolTip"}`, {
              separator: DEFAULT_SEPARATOR,
              allowDuplicate: DEFAULT_ALLOWDUPLICATE,
              ...config,
            })}`}
            tooltipOptions={{ position: "top" }}
            icon="pi pi-info-circle"
            className="p-button-rounded label-help p-button-text p-button-plain"
          />
        </label>
        <div
          className={`p-inputgroup ${
            isError(state, rest) ? "p-inputgroup-error" : ""
          }`}
        >
          {renderControl()}
        </div>
        {rest.hintBottom && (
          <div className={"chips-hint-bottom"}>{rest.hintBottom}</div>
        )}
        {renderError(state, rest, t)}
      </div>
    </>
  );
};

export default ChipControl;
