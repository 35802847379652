import { sleep } from "./../../utils/utils";
// TODO: using another storage ex: redis ...
export enum StorageKey {
  PORTAL_CODE = "PORTAL_CODE",
  USER_INFO = "USER_INFO",
  TENNANT_INFO = "TENNANT_INFO",
  TOKEN_INFO = "TOKEN_INFO",
  SELECTED_LANGUAGE = "SELECTED_LANGUAGE",
  GLOBAL = "persist:global",
}
/** Check storage */

function setCookie(name: string, value: string, days: number) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
function getCookie(name: string) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
function eraseCookie(name: string) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}
function checkLocalStorage(): boolean {
  var test = "test";
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}
function checkSectionStorage(): boolean {
  var test = "test";
  try {
    sessionStorage.setItem(test, test);
    sessionStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

function checkIndexedDB(): boolean {
  let _window = window as any;
  //prefixes of implementation that we want to test
  // _window.indexedDB = _window.indexedDB
  //   || _window.mozIndexedDB
  //   || _window.webkitIndexedDB
  //   || _window.msIndexedDB;

  // /**
  // * prefixes of window.IDB objects
  // * IDBTransaction interface of the IndexedDB API provides a static,
  // * asynchronous transaction on a database using event handler attributes.
  // */
  // _window.IDBTransaction = _window.IDBTransaction
  //   || _window.webkitIDBTransaction
  //   || _window.msIDBTransaction;

  // _window.IDBKeyRange = _window.IDBKeyRange
  //   || _window.webkitIDBKeyRange
  //   || _window.msIDBKeyRange;

  if (!_window.indexedDB) {
    return false;
  }
  return true;
}

async function initStorage() {
  // let preferStorage = "cookie";
  // let preferStorage = "localStorage";
  // let preferStorage = "sessionStorage";
  let preferStorage = "IndexedDB";
  const cookieStorage = {
    removeItem: async (key: string) => {
      await sleep(1);
      return eraseCookie(key);
    },
    setItem: async (key: string, value: string) => {
      await sleep(1);
      return setCookie(key, value, 100);
    },
    getItem: async (key: string) => {
      await sleep(1);
      return getCookie(key);
    },
  };
  switch (preferStorage) {
    case "localStorage":
      if (checkLocalStorage()) {
        return {
          removeItem: async (key: string) => {
            await sleep(1);
            return localStorage.removeItem(key);
          },
          setItem: async (key: string, value: string) => {
            await sleep(1);
            return localStorage.setItem(key, value);
          },
          getItem: async (key: string) => {
            await sleep(1);
            return localStorage.getItem(key);
          },
        };
      }
      break;
    case "sessionStorage":
      if (checkSectionStorage()) {
        return {
          removeItem: async (key: string) => {
            await sleep(1);
            return sessionStorage.removeItem(key);
          },
          setItem: async (key: string, value: string) => {
            await sleep(1);
            return sessionStorage.setItem(key, value);
          },
          getItem: async (key: string) => {
            await sleep(1);
            return sessionStorage.getItem(key);
          },
        };
      }
      break;
    default:
      break;
  }
  return cookieStorage;
}

export class StorageSerive {
  /** Remove item */
  static async removeItem(key: string) {
    await sleep(1);
    return await (await initStorage()).removeItem(key);
  }
  /** Set item */
  static async setItem(key: string, value: string) {
    await sleep(1);
    return await (await initStorage()).setItem(key, value);
  }
  /** Get item */
  static async getItem(key: string) {
    await sleep(1);
    return await (await initStorage()).getItem(key);
  }
  /** Get item */
  static async getItemAsString(key: string) {
    await sleep(1);
    return (await (await initStorage()).getItem(key)) as string;
  }

  /** Get item */
  static async getItemJSON(key: string) {
    await sleep(1);
    let value = (await this.getItem(key)) as string;
    if (!value) {
      return null;
    }
    try {
      return JSON.parse(value);
    } catch (error) {
      return null;
    }
  }

  /** Remove token */
  static async removeToken() {
    return await this.removeItem(StorageKey.TOKEN_INFO);
  }

  /** Set token */
  static async setToken(value: string) {
    return await this.setItem(StorageKey.TOKEN_INFO, JSON.stringify(value));
  }
  /** Get token */
  static async getToken() {
    return await this.getItemJSON(StorageKey.TOKEN_INFO);
  }
}
