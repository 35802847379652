import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { scrollToErrorControl } from "../../../../components/base-form/utils";
import { showSpinner } from "../../../../redux/actions/spinner";
import BaseMultiStepForm from "../../../../components/base-multi-step-form/base-multi-step-form";
import { BaseMultiStepFormSkeleton } from "../../../../components/base-multi-step-form/base-multi-step-form-skeleton";
import {
  BaseMultiStepConfigToFormModel,
  BaseMultiStepConfigToStepNavModel,
  BaseMultiStepCustomFunctionLibrary,
} from "../../../../components/base-multi-step-form/base-multi-step-form-utils";
import { BaseMultiStepFormScrollToMultiStepFormMenuActiveItem } from "../../../../components/base-multi-step-form/base-multi-step-form-scroll";
import {
  filterMainFormFieldEnum,
  setFormLanguage,
  setMainForm,
  setMainFormConfig,
  setMainFormSectionConfig,
} from "../../../../redux/actions/form";
import { showErrorMessageModal } from "../../../../services/utils/message";
import {
  getResponseMessage,
  isResponseOk,
  TimeRoundUp,
} from "../../../../utils/utils";
import { useHistory } from "react-router-dom";
import {
  DEFAULT_DATE_FORMAT,
  FUNCTION_CODE,
  MODULE_CODE,
  SCHEDULER_TYPE,
} from "../../../../constants";
import { PortalLink } from "../../../../services/utils";
import { store } from "../../../../redux/store";
import { MessageSeverity } from "primereact/api";
import {
  EmailReminderFormApiSteps,
  EmailReminderInitForm,
  InitEmailReminderForm,
} from "./email-reminder-default-form";
import { GetCurrentActions } from "../../../layouts/components/main-sidebar/main-menu-tree";
import {
  EmailConfigurationService,
  EmailService,
  EmployeeDetailsService,
  HRIS_Api_Common_Enums_TaskUnitTypes,
  MasterService,
} from "../../../../services/hrmnet-api";
import moment from "moment";

const EmailReminderForm = ({ location }) => {
  const scheduleId = location?.state?.scheduleId;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const baseFormRef = useRef();
  const sendDateRef = useRef();
  let toast = store.getState().global.toast;

  const [state, setState] = useState({
    isLoading: true,
    activeStep: 0,
    formResp: {},
    touched: false,
  });

  const actionsAccessRights = useSelector((state) => {
    const result = [];
    GetCurrentActions(state.menu.sideBar, result, 1, false);
    return result;
  });

  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );
  const formLanguage = useSelector((state) => state.commonForm.formLanguage);

  const form = useSelector((state) => state.commonForm.form);
  const selectedTemplateName = useSelector(
    (state) => state.commonForm.form?.TemplateName
  );
  const setForm = (x) => dispatch(setMainForm(x));
  const formConfig = useSelector((state) => state.commonForm.formConfig);
  const setFormConfig = (x) => dispatch(setMainFormConfig(x));
  const filterFieldEnum = (x) => dispatch(filterMainFormFieldEnum(x));
  const setFormSectionConfig = (x) => dispatch(setMainFormSectionConfig(x));

  const formObjRef = React.createRef();

  let customFunctionLibrary = BaseMultiStepCustomFunctionLibrary({
    dispatch,
  });

  customFunctionLibrary["onSendTestEmail"] =
    async (/* { update, control, section } */) => {
      if (!!baseFormRef?.current.form?.runTestEmail) {
        const response = await EmailService.emailSendEmail({
          body: {
            toEmails: baseFormRef?.current.form?.runTestEmail.split(","),
            subject: baseFormRef?.current.form?.subject,
            content: baseFormRef?.current.form?.content,
          },
        });

        if (
          !!response &&
          typeof response === "object" &&
          response.hasOwnProperty("text") &&
          response.hasOwnProperty("type")
        ) {
          let toast = store.getState().global.toast;
          toast.show({
            severity: MessageSeverity.INFO,
            detail: response.text,
            life: 3000,
          });
        }
      }
    };

  customFunctionLibrary["onSubmitRecipientTemplate"] =
    async (/* { update, control, section } */) => {
      var employeeCodesArray = [];
      var companyCodesArray = [];
      const recepientTemplateApis = [];
      if (!!baseFormRef?.current.form?.recepientTemplate) {
        baseFormRef?.current.form?.recepientTemplate.forEach(
          (recepientTemplate) => {
            const api =
              EmailConfigurationService.emailConfigurationGetReceipientsAsync({
                file: recepientTemplate,
              });
            recepientTemplateApis.push(api);
          }
        );

        const recepientTemplateResponses = await Promise.all([
          ...recepientTemplateApis,
        ]);
        recepientTemplateResponses.forEach((response) => {
          if (
            !!response &&
            typeof response === "object" &&
            response.hasOwnProperty("employeeCodes") &&
            response.hasOwnProperty("companyCodes")
          ) {
            employeeCodesArray = [
              ...employeeCodesArray,
              ...response.employeeCodes,
            ];
            companyCodesArray = [
              ...companyCodesArray,
              ...response.companyCodes,
            ];
          }
        });

        if (employeeCodesArray && companyCodesArray) {
          filterFieldEnum({
            formConfig: {
              employeeCodes: {},
              companyCodes: {},
            },
            form: {
              employeeCodes: employeeCodesArray,
              companyCodes: companyCodesArray,
            },
          });
        }
      }
    };

  customFunctionLibrary["onScheduleChange"] = async ({
    update,
    control,
    section,
  }) => {
    if (!!update.value && update.value === SCHEDULER_TYPE.SCHEDULED) {
      const now = new Date();
      now.setSeconds(0);
      now.setMilliseconds(0);
      setForm({
        ...baseFormRef?.current.form,
        unit: HRIS_Api_Common_Enums_TaskUnitTypes.Once,
        sendDate: now,
        sendTime: TimeRoundUp(now),
      });
    }
  };

  customFunctionLibrary["onTemplateNameSelected"] = async ({
    update,
    control,
    section,
  }) => {
    if (!!update.value) {
      const response =
        await EmailConfigurationService.emailConfigurationGetEmailTemplate({
          id: update.value,
        });

      if (!!response?.data) {
        setForm({
          ...baseFormRef?.current.form,
          templateName: update.value,
          subject: response?.data?.subject,
          content: response?.data?.templateText,
          bccEmails: response?.data?.bccEmails,
          description: response?.data?.description,
        });
      }
    }
  };

  customFunctionLibrary["onSendTimeChange"] = async ({
    update,
    control,
    section,
  }) => {
    if (!!update.value) {
      var currentSendDate = patchSendDateTime(update.value);
      const now = new Date();
      if (currentSendDate <= now) {
        const validSendDateTime = TimeRoundUp(currentSendDate);
        filterFieldEnum({
          formConfig: {},
          form: {
            sendDate: validSendDateTime,
            sendTime: validSendDateTime,
          },
        });
        toast.show({
          severity: MessageSeverity.ERROR,
          detail: t("email_reminder_SendTimeIsLesserThanNow"),
          life: 3000,
        });
      }
    }
  };

  customFunctionLibrary["onSendDateChange"] = async ({
    update,
    control,
    section,
  }) => {
    if (!!update.value) {
      var currentSendDate = patchSendDateTime(
        baseFormRef?.current.form?.sendTime
      );
      const now = new Date();
      if (currentSendDate <= now) {
        const validSendDateTime = TimeRoundUp(currentSendDate);
        filterFieldEnum({
          formConfig: {},
          form: {
            sendDate: validSendDateTime,
            sendTime: validSendDateTime,
          },
        });
        toast.show({
          severity: MessageSeverity.ERROR,
          detail: t("email_reminder_SendTimeIsLesserThanNow"),
          life: 3000,
        });
      }
    }
  };

  useEffect(() => {
    const loadForm = async () => {
      const applicationId = selectedTemplateName;
      const isLangChange = formLanguage != selectedLangKey;
      dispatch(setFormLanguage(selectedLangKey));

      let stepRes = EmailReminderFormApiSteps(t);
      let formConfigRes = EmailReminderInitForm(t);

      let stepList = [];
      let newForm = InitEmailReminderForm;
      let newFormResp = {};
      let newFormConfig = {};

      var companyList = [];
      var employeelist = [];
      var templateList = [];
      // var templateFieldList = [];
      var recurrenceTypeList = [];
      var currentForm;
      const employeeListApi = EmployeeDetailsService.employeeGetEmployeeList();
      const companyListApi = MasterService.masterGetFilteredCompaniesAsync();
      const recurrenceTypeApi = MasterService.masterGetRecurrenceTypesAsync();
      const templateListApi =
        EmailConfigurationService.emailConfigurationGetEmailTemplateList();
      // const templateFieldApi =
      //   EmailConfigurationService.emailConfigurationGetTemplateFields();

      if (!scheduleId) {
        [
          companyList,
          employeelist,
          templateList,
          // templateFieldList,
          recurrenceTypeList,
        ] = await Promise.all([
          companyListApi,
          employeeListApi,
          templateListApi,
          // templateFieldApi,
          recurrenceTypeApi,
        ]);
      } else {
        const formApi =
          EmailConfigurationService.emailConfigurationReminderDetailsAsync({
            scheduleId,
          });
        [
          currentForm,
          companyList,
          employeelist,
          templateList,
          // templateFieldList,
          recurrenceTypeList,
        ] = await Promise.all([
          formApi,
          companyListApi,
          employeeListApi,
          templateListApi,
          // templateFieldApi,
          recurrenceTypeApi,
        ]);
        const selectedCompanies =
          currentForm?.data?.employeeCodes.length > 0
            ? Array.from(
                employeelist.filter((y) =>
                  currentForm?.data?.employeeCodes?.includes(y.value)
                ),
                (y) => y.description
              )
            : [];
        const selectedCompanyCodes =
          companyList.length > 0 && selectedCompanies.length > 0
            ? Array.from(
                companyList.filter((x) => selectedCompanies.includes(x.value)),
                (y) => y.value
              )
            : [];

        newForm = {
          ...currentForm?.data,
          enabled: !currentForm?.data?.isEnabled
            ? false
            : currentForm?.data?.isEnabled,
          companyCodes: selectedCompanyCodes,
          bccList: currentForm?.data?.bccEmails,
          ccList: currentForm?.data?.ccEmails,
        };

        var _sendDateTime = Date.parse(currentForm?.data?.sendDateTime);
        if (isNaN(_sendDateTime) == false) {
          newForm.sendDate = moment(
            new Date(currentForm?.data?.sendDateTime)
          ).format(DEFAULT_DATE_FORMAT);
          newForm.sendTime = new Date(currentForm?.data?.sendDateTime);
        }
      }

      if (stepRes && stepRes.data && formConfigRes && formConfigRes.data) {
        //partial hardcore, find ways to completely detach from code

        let customData = {
          customFunctionLibrary: customFunctionLibrary,
          attachmentControlConfig: {
            allowedFileExt: "(\\.|\\/)(msexcel|xlsx)$",
          },
        };
        stepList = BaseMultiStepConfigToStepNavModel(stepRes.data);

        // init form and active step
        if (
          !isLangChange ||
          (form &&
            Object.keys(form).length === 0 &&
            Object.getPrototypeOf(form) === Object.prototype)
        ) {
          // newForm = {};
        } else {
          newForm = form;
        }

        // newFormResp and formConfig
        newFormResp = formConfigRes.data;
        newFormConfig = BaseMultiStepConfigToFormModel({
          applicationId: applicationId,
          stepConfig: stepRes.data,
          sectionFieldConfig: formConfigRes.data.sections,
          customData: customData,
          t: t,
          form: newForm,
          dropdownOptions: {
            schedule: Object.keys(SCHEDULER_TYPE).map((x) => {
              return {
                name: x,
                value: SCHEDULER_TYPE[x],
              };
            }),
            unit: recurrenceTypeList,
            templateName: templateList?.data,
            employeeCodes: employeelist,
            companyCodes: companyList,
            // content: {
            //   "email-configuration/template-fields": templateFieldList,
            // },
          },
        });
      }
      setForm(newForm);
      setFormConfig(newFormConfig);
      setFormSectionConfig(newFormResp);

      setState({
        ...state,
        stepList,
        isLoading: false,
      });
    };

    loadForm();

    BaseMultiStepFormScrollToMultiStepFormMenuActiveItem(".form-title");

    return () => {
      setState({
        ...state,
        isLoading: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleId]);

  // Navigation and action
  const validateCurrentStep = () => {
    const isValid = !formObjRef.current.getActiveFormState({ expand: true })
      .invalid;
    setTimeout(() => {
      scrollToErrorControl();
    }, 100);
    return isValid;
  };

  const confirmSubmitApplication = async () => {
    if (validateCurrentStep()) {
      submitApplicationAction();
    } else {
      setState({
        ...state,
        touched: true,
      });
    }
  };

  function patchSendDateTime(sendTime) {
    var currentSendDate = new Date();
    if (sendTime instanceof Date) {
      currentSendDate = sendTime;
    }

    if (baseFormRef?.current.form?.sendDate instanceof Date) {
      currentSendDate = new Date(
        baseFormRef?.current.form?.sendDate?.valueOf()
      );
    }

    if (typeof sendTime === "string") {
      sendTime = new Date(sendTime);
    }

    if (sendDateRef.current instanceof Date) {
      currentSendDate = new Date(sendDateRef.current.valueOf());
    }

    if (currentSendDate === sendTime) {
      return sendTime;
    }

    currentSendDate.setHours(0, 0, 0, 0);
    if (sendTime instanceof Date) {
      const valueTime = new Date(sendTime.valueOf());
      const valueTimeHour = valueTime.getHours();
      const valueTimeMinutes = valueTime.getMinutes();
      currentSendDate.setHours(valueTimeHour, valueTimeMinutes, 0, 0, 0);
    }
    return currentSendDate;
  }

  const submitApplicationAction = async () => {
    try {
      // show spinner
      dispatch(showSpinner(true));
      const _form = { ...form };
      if (!!form.sendTime && !!form.sendDate) {
        _form.sendDateTime = patchSendDateTime(form.sendTime);
      }
      if (_form.sendDateTime instanceof Date) {
        _form.sendDateTime = moment(
          new Date(_form.sendDateTime.valueOf())
        ).format();
      }
      if (!!form?.employeeCodes) {
        _form.recipients = form.employeeCodes;
      }
      let res =
        await EmailConfigurationService.emailConfigurationCreateReminderAsync({
          body: {
            ..._form,
          },
        });

      if (isResponseOk(res) && !!res?.data?.scheduleId) {
        toast.show({
          severity: MessageSeverity.SUCCESS,
          detail: t("admin_common_submitMessageSuccess"),
          life: 3000,
        });

        setForm({ ..._form, scheduleId: res?.data?.scheduleId });
      } else {
        showErrorMessageModal({
          dispatch,
          t,
          content: getResponseMessage(res),
        });
      }
    } catch (e) {
      // show notification?
      console.error(e.message);
    } finally {
      // end spinner
      dispatch(showSpinner(false));
    }
  };

  function onChange({ state, changed }) {
    baseFormRef.current = state;
    if (changed?.control?.key === "sendDate") {
      if (state.form?.sendDate instanceof Date) {
        sendDateRef.current = new Date(state.form?.sendDate?.valueOf());
      }
    }
  }

  // Rendering
  const renderForm = () => (
    <BaseMultiStepForm
      form={form}
      activeStep={state.activeStep}
      config={formConfig}
      ref={formObjRef}
      touched={state.touched}
      onChange={onChange}
    />
  );

  const renderFooter = () => {
    return (
      <div className="footer p-grid p-align-center p-justify-between">
        <Button
          label={t("admin_common_actionCancel")}
          className="p-button-secondary"
          onClick={() =>
            history.push(
              PortalLink(
                `${MODULE_CODE.Admin}/${FUNCTION_CODE.EmailConfiguration}/${FUNCTION_CODE.EmailReminder}`
              )
            )
          }
        />
        <div className="right-button-group">
          <Button
            onClick={() => confirmSubmitApplication()}
            className="p-button next-button"
          >
            {t("admin_common_actionSubmit")}
          </Button>
        </div>
      </div>
    );
  };

  // Rendering
  const renderFormTitle = () => (
    <div className="form-title">
      <h2>
        {actionsAccessRights.length > 0 ? actionsAccessRights[0]?.name : ""}
      </h2>
    </div>
  );

  return (
    <>
      <div className=" admin-form-style  email-reminder">
        <div className="header header-sticky">
          {/* Title */}
          {renderFormTitle()}
        </div>
        {state.isLoading ? (
          <div className="form-loading">{BaseMultiStepFormSkeleton}</div>
        ) : (
          renderForm()
        )}

        {/* Footer */}
        {renderFooter()}
      </div>
    </>
  );
};

export default EmailReminderForm;
