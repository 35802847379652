import "./movement.scss";

import { Animated } from "react-animated-css";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Skeleton } from "primereact/skeleton";

import BaseForm from "./../../../../../components/base-form/base-form";

// services
import { EmployeeDetailsService } from "./../../../../../services/hrmnet-api";
import { EmployeeTabConfigurationService } from "./../../../../../services/hrmnet-api";

import MultiEntititesDropDown from "./multi-entities-drop-down/multi-entities-drop-down";

//components
import {
  MyInfoSkeleton,
  FormControlsGenerator,
  ToPascalCase,
  GetControlType,
} from "./components/utils";
import SectionWithDataArray from "./components/section-with-data-array";
import DataArrayWithSections from "./components/data-array-with-sections";
import { PortalSystemErrorLink } from "../../../../../services/utils";

const Movement = (props) => {
  const clientInfo = useSelector((state) => state.global?.clientInfo);
  const { t } = useTranslation();
  let history = useHistory();
  const [state, setState] = useState({
    loading: true,
    i18nConstant: "eeinfo_mov_",
    hideTooltip: t("common_reveal"),
    sectionWithChild: [],
    childWithSection: [
      // {
      //   sectionCodes: ["MOV_BASIC", "MOV_ORG", "MOV_ADD"],
      //   dataKey: "all",
      //   title: "movements",
      //   childTitle: "movement",
      // },
    ],
    sections: [],
  });
  const [selectedClient, setSelectedClient] = useState();
  const [entitiesList, setEntitiesList] = useState([]);

  // TODO - the masking is dynamic, we need to update this to use Map()
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map
  const [mapMasked, setMapMasked] = useState(new Map([]));
  const mountedRef = useRef(true);
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    const loadEntitiesList = async () => {
      var res = await EmployeeDetailsService.employeeGetEmployeeEntityHistory();
      if (res && res.data) {
        const _entitiesList = res.data.map((data) => {
          return {
            ...data,
            key: data.value,
            label: data.name,
          };
        });

        let _selectedEntity =
          _entitiesList.find((x) => x.key === clientInfo.entityCode) ??
          _entitiesList[0];
        if (!_selectedEntity) {
          setState({ ...state, loading: false });
        } else {
          setSelectedClient(_selectedEntity);
          setEntitiesList(_entitiesList);
        }
      }
    };

    loadEntitiesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const loadData = async () => {
      setState({ ...state, loading: true });
      // const apiRequestParam = await ApiRequest.createParam();
      let enumData = {};

      try {
        const cmd2 =
          EmployeeTabConfigurationService.employeeConfigurationGetMovementTabConfigurations();

        const cmd3 = EmployeeDetailsService.employeeGetMovementDetails({
          entityCode: selectedClient.key,
        });

        const [res2, res3] = await Promise.all([cmd2, cmd3]);

        let data = {};
        let sections = [];
        let currSection = [];
        let cSections = [];

        if (res3 && res3.data) {
          // data = res3.data;
          //TODO: conduct manual data mapping
          /* new API JSON format
        baseSalaryUnit: null
        basicSalary: 11000
        costCenter: null
        defaultPaymentCurrency: null
        defaultSalaryCurrency: null
        directManager: null
        entitledEAO: null
        externalJobTitle: null
        internalJobTitle: null
        jobGrade: null
        location: null
        otGrade: null
        paidCycle: "Monthly"
        partTime: 0
        secondApprover: null
        staffType: null
        workCalendar: "H01"
         */
          data = res3.data;
          data["entitledEao"] = res3.data?.entitledEAO;
          // data["workCalender"] = res3.data?.workCalendar;

          if (data !== undefined && data !== null && data.length > 0) {
            data = data.map((childWithSection) => {
              return {
                ...childWithSection,
                staffTags:
                  childWithSection?.staffTags &&
                  !Array.isArray(childWithSection.staffTags)
                    ? childWithSection?.staffTags
                        .split(",")
                        .map((x) => x.trim())
                    : childWithSection?.staffTags,
              };
            });
          }
        }

        if (res2 && res2.data) {
          sections = res2.data;
          if (sections.length > 0) {
            currSection = sections.map((section) => {
              let keyToPascalFields = [];
              if (section.fields.length > 0) {
                const visiblefields = section.fields.filter(
                  (x) => x.isVisible === true
                );
                keyToPascalFields = visiblefields.map((field, fIndex) => {
                  const pcKey = ToPascalCase(field.key);
                  if (field.isVisible) {
                    return {
                      ...field,
                      key: pcKey,
                      controlType: GetControlType(data[pcKey], pcKey),
                      fieldConfig: {
                        isVisible: field.isVisible,
                        isMasked: field.isMasked,
                        isEditable: field.isEditable,
                      },
                    };
                  }
                  return {};
                });
              }

              return {
                ...section,
                fields: keyToPascalFields,
              };
            });
          }
          sections = currSection.filter((x) => x.fields.length > 0);
        }

        if (sections.length === 0 || sections === undefined) {
          props.setTabViewVisibility(true);
          history.go(-1);
        } else if (
          state.childWithSection.length > 0 &&
          sections !== undefined &&
          sections.length > 0
        ) {
          state.childWithSection.forEach((childWithSection) => {
            const filteredArray = sections.filter((value) =>
              childWithSection.sectionCodes.includes(value.sectionCode)
            );
            sections = sections.filter((x) => !filteredArray.includes(x));
            cSections.push({
              sections: filteredArray,
              data:
                childWithSection.dataKey === "all"
                  ? data
                  : data[childWithSection.dataKey],
              title: childWithSection.title,
              childTitle: childWithSection.childTitle,
            });
          });
        }

        let _state = {
          ...state,
          enumData,
          data: data,
          sections: sections,
          cSections: cSections,
          loading: false,
        };
        setState(_state);
      } catch (error) {
        history.push(PortalSystemErrorLink());
      }
    };
    if (selectedClient && selectedClient.key) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient]);

  /**
   *
   * @param {*} clsName
   * @param {*} controlName
   * @returns
   */
  const movementtColConfig = (
    controlName,
    clsName = "p-col-12 p-md-6 p-lg-4"
  ) => {
    return {
      config: {
        className: clsName,
      },
      control: controlName,
    };
  };

  /**
   *
   */
  const movementField = (fields) => {
    return {
      controls: fields.length > 0 || fields !== undefined ? fields : null,
      layout:
        fields.length === 0 || fields === undefined
          ? {}
          : {
              rows: [
                {
                  //[View_Section_ID] = 34
                  columns: [
                    movementtColConfig("staffType"),
                    movementtColConfig("partTime"),
                  ],
                },
                {
                  columns: [
                    movementtColConfig("internalJobTitle"),
                    movementtColConfig("externalJobTitle"),
                  ],
                },
                {
                  columns: [
                    movementtColConfig("jobGrade"),
                    movementtColConfig("otGrade"),
                  ],
                },
                {
                  columns: [
                    movementtColConfig("directManager"),
                    movementtColConfig("secondApprover"),
                  ],
                },
                {
                  columns: [
                    movementtColConfig("costCenter"),
                    movementtColConfig("workCalendar"),
                  ],
                },
                {
                  columns: [
                    movementtColConfig("paidCycle", "p-col-12 p-md-4 p-lg-2"),
                    movementtColConfig("basicSalary", "p-col-12 p-md-4 p-lg-2"),
                    movementtColConfig(
                      "baseSalaryUnit",
                      "p-col-12 p-md-4 p-lg-2"
                    ), //not in db
                  ],
                },
                {
                  columns: [
                    movementtColConfig(
                      "defaultSalaryCurrency",
                      "p-col-12 p-md-6 p-lg-4"
                    ),
                    movementtColConfig(
                      "defaultPaymentCurrency",
                      "p-col-12 p-md-6 p-lg-4"
                    ),
                  ],
                },
                {
                  columns: [
                    movementtColConfig("entitledEAO"),
                    movementtColConfig("entitledEao"), // not in db
                  ],
                },
                {
                  columns: [
                    movementtColConfig("location", "p-col-12 p-md-4 p-lg-2"),
                  ],
                },
                //[View_Section_ID] = 35
                {
                  columns: [movementtColConfig("region")],
                },
                {
                  columns: [
                    movementtColConfig("division"),
                    movementtColConfig("businessUnit"),
                  ],
                },
                {
                  columns: [
                    movementtColConfig("department"),
                    movementtColConfig("team"),
                  ],
                },
                // [View_Section_ID] = N/A - R2 only //not in db
                {
                  columns: [movementtColConfig("remarks", "p-col-12")],
                },
                {
                  columns: [movementtColConfig("reserveFields", "p-col-12")],
                },
                // [View_Section_ID] = N/A - R2 only //not in db
                {
                  columns: [movementtColConfig("bonusAccual")],
                },
                {
                  columns: [
                    movementtColConfig("employmentId"),
                    movementtColConfig("businessUnitId"),
                  ],
                },
                {
                  columns: [
                    movementtColConfig("startDate"),
                    movementtColConfig("endaDate"),
                  ],
                },
                {
                  columns: [
                    movementtColConfig("entitled13thPay", "p-col-12"),
                    movementtColConfig("entitledOt", "p-col-12"),
                    movementtColConfig("entitledSpecialBonus", "p-col-12"),
                  ],
                },
                {
                  columns: [
                    movementtColConfig("movementId"),
                    movementtColConfig("movementName"),
                  ],
                },
                {
                  columns: [
                    movementtColConfig("renumerationPackage"),
                    movementtColConfig("staffTags"),
                  ],
                },
              ],
            },
    };
  };

  const renderSection = (index, sectionTitle, fields, sectionCode) => {
    return (
      <div id={index} key={index}>
        <div className="c-group-title ep-language-toogle">
          <p>
            {t(
              state.i18nConstant +
                sectionTitle.replace("-", "_").replace(/ /g, "").toLowerCase()
            )}
          </p>
        </div>
        {
          <div className="c-group-panel">
            {state.sectionWithChild.findIndex(
              (x) => x.sectionCode === sectionCode
            ) > -1 ? (
              // <></>
              <SectionWithDataArray
                currIndex={index}
                noDataLabel={t("common_noData")}
                cData={
                  state.sectionWithChild.find(
                    (x) => x.sectionCode === sectionCode
                  ).dataKey === "all"
                    ? state.data
                    : state.data?.[
                        state.sectionWithChild.find(
                          (x) => x.sectionCode === sectionCode
                        ).dataKey
                      ]
                } //MANUAL, need to change to auto
                readOnly={props.readOnly ?? true}
                panelTitle={t(
                  state.i18nConstant +
                    sectionTitle
                      .replace("-", "_")
                      .replace(/ /g, "")
                      .toLowerCase()
                )}
                formStructure={movementField}
                fields={fields}
                hideTooltip={t(state.hideTooltip)}
                enumData={state.enumData}
                i18nConstant={state.i18nConstant}
              />
            ) : (
              // <></>
              <BaseForm
                id={"employeeContact" + index}
                config={movementField(
                  FormControlsGenerator(
                    fields,
                    t(state.hideTooltip),
                    mapMasked,
                    setMapMasked,
                    state.enumData,
                    t,
                    state.i18nConstant
                  )
                )}
                readOnly={props.readOnly ?? true}
                form={state.data}
              />
            )}
          </div>
        }
      </div>
    );
  };

  const renderContent = (
    <Animated
      animationIn="slideInRight"
      animationOut="slideOutRight"
      animationInDuration={200}
      animationOutDuration={200}
      isVisible={true}
    >
      {state.sections === null ? (
        { MyInfoSkeleton }
      ) : (
        <>
          {state?.cSections?.map((item, index) => {
            // console.log("--index--", index, state?.cSections);
            return (
              <div id={index} key={index}>
                <div className="c-group-title ep-language-toogle">
                  <p>
                    {t(
                      state.i18nConstant +
                        item.title
                          .replace("-", "_")
                          .replace(/ /g, "")
                          .toLowerCase()
                    )}
                  </p>
                </div>
                <div className="c-group-panel">
                  <DataArrayWithSections
                    cData={item.data}
                    sections={item.sections}
                    noDataLabel={t("common_noData")}
                    formStructure={movementField}
                    i18nConstant={state.i18nConstant}
                    hideTooltip={state.hideTooltip}
                    panelTitle={t(state.i18nConstant + item.childTitle)}
                    readOnly={state.readOnly ?? true}
                    enumData={state.enumData}
                    sectionWithChild={state.sectionWithChild}
                  />
                </div>
              </div>
            );
          })}
          {state?.sections?.map((section, index) =>
            renderSection(
              index,
              "movement_" + section.sectionName,
              section.fields,
              section.sectionCode
            )
          )}
        </>
      )}
    </Animated>
  );

  return (
    <>
      <MultiEntititesDropDown
        entitiesList={entitiesList}
        setSelectedClient={setSelectedClient}
        selectedClient={selectedClient}
      />
      {state.loading ? <Skeleton /> : renderContent}
    </>
  );
};

export default Movement;
