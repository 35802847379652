import { useCallback } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import BaseForm from "../../../../../../components/base-form/base-form";
import { getControlModel } from "../../../../../../components/base-control/base-cotrol-model";
import { ActiveSessionDialog } from "./../../login-load-info";

import MfaLogin from "./../mfa-login/mfa-login";
import { OnLogin } from "./../utils";
import { LoginBtn } from "./../utils";

import { AuthenticationService } from "../../../../../../services/hrmnet-api";

//const
import { LOGIN_PATH } from "./../../../../../../constants/index";

//redux functions
import { store } from "../../../../../../redux/store";
import { LoginLink } from "../../../../../../services/utils";

const StandardLogin = (props) => {
  const { clientCode, entityCode } = useParams();
  let history = useHistory();
  const initialState = { ...props.clientInfo, isValidUser: false };
  const [state, setState] = useState(initialState);
  const mountedRef = useRef(true);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    return () => {
      setState({
        form: {
          userName: "",
          password: "",
        },
      });
      mountedRef.current = false;
    };
  }, []);

  const formSubmitFn = () => {
    //check active session
    return true;
  };

  const afterFormSubmitFn = async (payload) => {
    return await ActiveSessionDialog(store, t, history, changeSession, payload);
  };

  const changeSession = useCallback(async (confirm, payload) => {
    let res = {};
    if (confirm) {
      if (payload.form.username) {
        try {
          res =
            await AuthenticationService.authenticationAuthenticateByUserName({
              body: {
                userName: payload.form.username,
                password: payload.form.password,
              },
            });
        } catch (error) {
          return false;
        } finally {
        }
      }

      if (payload.form.otp) {
        try {
          res = await AuthenticationService.authenticationAuthenticateByMfaOtp({
            body: {
              employeeCode: payload.form.employeeCode,
              otp: payload.form.otp,
            },
          });
        } catch (error) {
          return false;
        } finally {
        }
      }

      if (res?.data?.token) {
        await OnLogin(res, history);
        return true;
      } else if (res?.data && res.data.isMfaEnabled && res.data.isValidUser) {
        setState({ ...state, ...res.data, form: res.data });
        return true;
      }
    }

    return false;
  }, []);

  const loginForm = {
    controls: [
      getControlModel({
        key: "username",
        label: t("login_username"),
        placeholder: t("login_username"),
        required: true,
        noRequiredLabel: true,
      }),
      getControlModel({
        key: "password",
        label: t("login_password"),
        type: "password",
        placeholder: t("login_yourPassword"),
        required: true,
        noRequiredLabel: true,
        config: {
          feedback: false, // we don't need to show strength during login
        },
      }),
      getControlModel({
        key: "isRemember",
        label: t("login_remember"),
        type: "checkbox",
      }),
    ],
    layout: {
      rows: [
        {
          columns: [
            {
              control: "username",
            },
          ],
        },
        {
          columns: [
            {
              control: "password",
            },
          ],
        },
        {
          columns: [
            {
              component: () => (
                <Link
                  className="forgot"
                  to={LoginLink(
                    { tenantCode: clientCode, entityCode: entityCode },
                    LOGIN_PATH.FORGOT_PWD
                  )}
                >
                  {t("login_forgotPassword")}
                </Link>
              ),
              config: {
                style: {
                  textAlign: "right",
                  // marginBottom: "2.5rem",
                  marginTop: "-0.3rem",
                },
              },
            },
          ],
        },
        {
          config: {
            style: {
              // marginBottom: "1rem",
              marginBottom: "0.5rem",
              // marginTop: "1rem",
            },
          },
          columns: [LoginBtn(t("login_submit")), {}],
        },
      ],
    },
    formSubmitFn,
    afterFormSubmitFn,
  };

  return (
    <>
      {state.isMfaEnabled && state.isValidUser ? (
        <MfaLogin
          formSubmitFn={(e) => formSubmitFn(e)}
          afterFormSubmitFn={(e) => afterFormSubmitFn(e)}
          form={state.form}
          onCancel={() => setState(initialState)}
          loginBtn={LoginBtn(t("login_submit"))}
        />
      ) : (
        <BaseForm config={loginForm} form={state.form} />
      )}
    </>
  );
};

export default StandardLogin;
