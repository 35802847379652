import "./base-multi-steps.scss";
import { Steps as PrimeSteps } from "primereact/steps";

const BaseMultiSteps = ({ className, ...props }) => {
  return (
    <PrimeSteps
      className={`custom-steps ${className ? className : ""}`}
      {...props}
      model={props.model || []}
    />
  );
};

export default BaseMultiSteps;
