import "./my-summary.scss";
import MySummaryForm from "./my-summary-form";

import { useRef } from "react";
import { Animated } from "react-animated-css";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useHistory } from "react-router-dom";

import { Skeleton } from "primereact/skeleton";
import BaseForm from "./../../../../components/base-form/base-form";
import { PROFILE_IMAGE_PLACEHOLDER } from "../../../../constants/index";
import { ERROR_PAGE } from "../../../../constants/index";
import { EmployeeDetailsService } from "../../../../services/hrmnet-api/index";

import { setUserInfo } from "../../../../redux/actions/user";

const MySummary = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  // const [languageToogle, setLanguageToogle] = useState(false);
  // const [activePersonalPanel, setActivePersonalPanel] = useState(
  //   props.activePanel || 0
  // );
  const userProfile = useSelector((state) => state.user?.profile);
  const employeeCode = useSelector((state) => state.auth?.employeeCode);

  // unsubcribe
  const mountedRef = useRef(true);
  useEffect(() => {
    const initPage = async () => {
      try {
        //when session is changed
        //update auth information
        let initForm = {};

        const cmd = EmployeeDetailsService.employeeGetProfileSummary();

        const [res] = await Promise.all([cmd]);

        if (res && res.data) {
          initForm = {
            ...res.data,
            email: res.data?.emailId,
            staffId: res.data?.employeeCode,
            profileImageUrl: res.data?.profileImageUrl || 0,
          };
        }

        dispatch(
          setUserInfo({
            profile: initForm,
          })
        );
      } catch (error) {
        history.push(`/${ERROR_PAGE.MAIN_ERROR_500}`);
      }
    };

    initPage();

    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeCode, dispatch, history]);

  const MyInfoSkeleton = (
    <div className="p-col-12">
      <div className="c-group-title">{t("employeeProfile")}</div>
      <div className="c-group-panel">
        <div className="p-grid">
          <Skeleton width="100%" height="20vh" />
        </div>
      </div>
    </div>
  );

  const isBreakpoint = useMediaQuery({ maxWidth: "924px" });

  const renderAvatar = (profileImageUrl) => {
    return (
      <>
        <LazyLoadImage
          className={`${
            isBreakpoint ? "c-avatar-thumb-breakpoint" : "c-avatar-thumb"
          }`}
          alt={t("employeeProfile_avatar")}
          effect="blur"
          src={profileImageUrl ?? PROFILE_IMAGE_PLACEHOLDER.DEFAULT}
          onError={(e) => (e.target.src = PROFILE_IMAGE_PLACEHOLDER.ERROR)}
        />
      </>
    );
  };

  const renderContent = (
    <div className="p-col-12">
      {/* <div className="c-group-title">{t("Profile")}</div> */}
      <div className="c-group-panel">
        <div className="p-grid">
          <div className="c-left-panel p-col-4 p-grid">
            <div
              className={`p-col-fixed p-col-align-center ${
                isBreakpoint
                  ? "c-avatar-container-breakpoint"
                  : "c-avatar-container"
              }`}
            >
              {renderAvatar(userProfile?.profileImageUrl)}
            </div>
            <div
              className={`p-col ${
                isBreakpoint
                  ? "c-u-details-container-breakpoint"
                  : "c-u-details-container"
              }`}
            >
              <div className="c-item-main">
                <span style={{ display: "inline-block" }}>
                  {userProfile?.name}
                </span>
              </div>
              <div className="c-item-main-status">{userProfile?.status}</div>

              <div className="c-item-main-desc">
                {t("eeinfo_emp_employeeNo")} {userProfile?.staffId}
              </div>

              <div className="c-item-main-desc">
                <i className="pi pi-phone" />
                &nbsp; {userProfile?.mobileNo}
              </div>

              <div className="c-item-main-desc">
                <i className="pi pi-envelope" />
                &nbsp; {userProfile?.email}
              </div>
            </div>
          </div>

          <div className="styled-readonly c-right-panel p-col-12 p-md-7 p-lg-8">
            {/* <div className="c-item-main">
              <div className="c-item-main-desc">{t("Name")}:</div>
              {userProfile?.name}
            </div> */}
            <BaseForm
              id="employeeProfileSummary"
              config={MySummaryForm()}
              readOnly
              form={userProfile}
            />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Animated
        animationIn="slideInRight"
        animationOut="slideOutRight"
        animationInDuration={200}
        animationOutDuration={200}
        isVisible={true}
      >
        <div className="mobile-profile c-page-body p-grid">
          {!userProfile ? MyInfoSkeleton : renderContent}
        </div>
      </Animated>
    </>
  );
};

export default MySummary;
