import { DocumentsService } from "../../../services/hrmnet-api";
import { saveAs } from "file-saver";
import { BLOB, DOCUMENT, SUGGESTED_FILENAME } from "../../../constants";

export async function downloadFile(file) {
  if (file.id != null) {
    var res = await DocumentsService.documentDownloadSingleFile(
      {
        body: { fileId: file.id },
      },
      {
        responseType: BLOB,
      }
    );
    if (res) {
      var filename = file.name ? file.name : DOCUMENT;
      saveAs(res, filename);
    }
  } else {
    // Is already blob
    saveAs(file, file.name);
  }
}

export async function GetFileDownload(uploadId, filename) {
  if (uploadId != null) {
    const axiosCallback = (resp) => {
      filename = resp.headers[SUGGESTED_FILENAME];
    };
    var res = await DocumentsService.documentDownloadSingleFile(
      {
        body: { fileId: uploadId },
      },
      {
        responseType: BLOB,
        callback: axiosCallback,
      }
    );

    if (res) {
      saveAs(res, filename);
    }
  }
}

export async function GetUploadDownload(fileId, filename) {
  if (fileId != null) {
    const axiosCallback = (resp) => {
      filename = resp.headers[SUGGESTED_FILENAME];
    };
    var res = await DocumentsService.documentGetFileDownload(
      {
        body: { fileId: fileId },
      },
      {
        responseType: BLOB,
        callback: axiosCallback,
      }
    );

    if (res) {
      saveAs(res, filename);
    }
  }
}

export function getFileSizeString(input, toFixed = 0) {
  let size = parseFloat(input);
  if (!size && size !== 0) return "";
  const units = ["KB", "MB", "GB"];
  let curUnit = "B";
  units.forEach((unit) => {
    if (size >= 1024) {
      size = size / 1024;
      curUnit = unit;
    }
  });
  return `${size.toFixed(toFixed)} ${curUnit}`;
}
