import "./assistance-message.scss";

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { Card } from "primereact/card";
import { Skeleton } from "primereact/skeleton";

//const
import SupportDesktopWrapper from "./support-desktop-wrapper";
import SupportMobileWrapper from "./support-mobile-wrapper";

import { ContentService } from "../../../../services/hrmnet-api";

const AssistanceMessage = () => {
  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );
  const employeeCode = useSelector((state) => state.auth?.employeeCode);
  const { t } = useTranslation();
  const [state, setState] = useState({
    supportItem: [],
    loading: true,
  });

  const isMobile = useMediaQuery({ maxWidth: "990px" });

  useEffect(() => {
    // console.log('!--useEffect-assistance--', selectedLangKey);
    const getData = async () => {
      try {
        var res = await ContentService.contentGetAssistance();
        let supportItem = [];
        if (res && res.data) {
          supportItem.push(res?.data);
        }
        setState({
          ...state,
          supportItem,
          loading: false,
        });
      } catch (error) {
        setState({
          supportItem: [],
          loading: false,
        });
      }
    };
    getData();
    return () => {
      setState({
        supportItem: [],
        loading: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLangKey, employeeCode]);

  return (
    <>
      {state.loading ? (
        <>
          <Card>
            <div className="p-d-flex">
              <div style={{ flex: "1" }}>
                <Skeleton width="100%" className="p-mb-2"></Skeleton>
                <Skeleton width="75%"></Skeleton>
              </div>
            </div>
          </Card>
        </>
      ) : (
        <>
          {state.supportItem?.length === 0 ? null : (
            <div>
              {isMobile ? (
                <SupportMobileWrapper
                  noDataTitle={t("login_noSupport")}
                  supportItem={state.supportItem}
                  title={t("login_needAssistance")}
                />
              ) : (
                <div className="assistance">
                  <SupportDesktopWrapper
                    noDataTitle={t("login_noSupport")}
                    supportItem={state.supportItem}
                    title={t("login_needAssistance")}
                  />
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AssistanceMessage;
