import { Redirect, Route, Switch } from "react-router-dom";

//const
import { STORAGE_KEY } from "./../../constants/index";
import { PrivateRoutes } from "../../portal/employee-router";
import { PublicRoutes } from "../../App";
import CommonHeader from "../../portal/modules/public/common/common-header";

/**
 * For protecting the pages to be accessible after login
 * @param {} param0
 */
const GuardedRoute = ({
  component: Component,
  auth,
  authEmployeeCode,
  ...rest
}) => {
  const employeeRoute = Array.from(
    PrivateRoutes.filter((r) => r.restrictedContent),
    (route) => route.path
  );

  // Check previous session employee code and current employee code to ensure correct user access when view application details
  var previousSessionEmployeeCode = window.sessionStorage.getItem(
    STORAGE_KEY.AUTH_EMPLOYEE_CODE
  );
  var validSessionEmployeeCode =
    previousSessionEmployeeCode === authEmployeeCode &&
    authEmployeeCode !== undefined
      ? true
      : false;
  if (
    !auth &&
    employeeRoute.includes(window.location.pathname.split("/")[1]) &&
    validSessionEmployeeCode
  ) {
    window.sessionStorage.setItem(
      STORAGE_KEY.PATH_AFTER_LOGIN,
      window.location.pathname
    );
    window.sessionStorage.setItem(
      STORAGE_KEY.AUTH_EMPLOYEE_CODE,
      authEmployeeCode
    );
  } else {
    window.sessionStorage.removeItem(STORAGE_KEY.PATH_AFTER_LOGIN);
    window.sessionStorage.removeItem(STORAGE_KEY.AUTH_EMPLOYEE_CODE);

    const renderRoutes = () => {
      var result =
        Array.isArray(rest.children) &&
        rest.children.concat(
          <Route
            key={rest.children.length}
            render={() => <CommonHeader isNotFound={true} />}
          />
        );

      if (auth) {
        result = PublicRoutes.filter((x) => !x.redirectExclude)
          .map((route, index) => {
            if (!!route.Component) {
              return (
                <Route
                  key={index}
                  path={`/${route.path}`}
                  component={route.component}
                  exact={true}
                />
              );
            }

            return (
              <Route key={`${index}`} exact path={`/${route.path}`}>
                <Redirect to={`/`} />
              </Route>
            );
          })
          .concat([
            <Route
              key={PublicRoutes.length}
              render={(props) => <Component {...props} />}
            />,
          ]);
      }

      return result;
    };

    return <Switch>{renderRoutes()}</Switch>;
  }
};
export default GuardedRoute;
