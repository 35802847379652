import { ArrayCRUDType } from "../../../../components/base-control/array-control/array-control";
import { BCType } from "../../../../components/base-control/base-control";
import { ToUIOption } from "../../public/login/components/utils";

export const InitForm = {
  name: "",
  bfCutOff: "",
  bfCutOffUom: "",
  companyCode: null,
  grade: null,
  staffType: null,
  team: null,
  employeeId: null,
  dateJoinGroupMonthOfService: null,
  commencementDateMonthOfService: null,
  details: [
    // {
    //     id: 0,
    //     leaveCode: "",
    //     bfUnit: "",
    //     bfUnitUom: "",
    //     bfExpiry: "",
    //     bfExpiryUom: "",
    //     isStatutory: false,
    //     mode: "",
    // }
  ],
};

export function FormApiSteps(t) {
  return {
    data: [
      {
        displaySequence: 1,
        id: 1,
        sections: [
          {
            displaySequence: 1,
            isEditable: true,
            sectionId: 100,
            sectionName: t("Leave_Configuration_Policy_Formname_Section1"), // brought forward policy
          },
          {
            displaySequence: 1,
            isEditable: true,
            sectionId: 200,
            sectionName: t("Leave_Configuration_Policy_Formname_Section2"), // brought forward policy leave
          },
        ],
      },
    ],
  };
}

export function FormSectionFields(t, dropdownOptions) {
  return {
    data: {
      id: 1000,
      sections: [
        {
          defaultExpanded: true,
          displayLayout: 2,
          displaySequence: 1,
          id: 100,
          isDefaultHide: false,
          fields: [
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 1,
              fieldLabel: t("Leave_Configuration_Policy_policyName"),
              fieldName: "name",
              id: 1,
              inputName: "name",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-col-12",
              translationKey: "Leave_Configuration_Policy_policyName",
              viewSectionId: 100,
              styleClass: "p-lg-6 p-md-12 p-sm-12",
            },
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 2,
              fieldGroup: 2,
              fieldLabel: t("Leave_Configuration_Policy_bfCutOff"),
              fieldName: "bfCutOff",
              id: 2,
              inputName: "bfCutOff",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-col-12",
              translationKey: "Leave_Configuration_Policy_bfCutOff",
              viewSectionId: 100,
              styleClass: "p-lg-6 p-md-12 p-sm-12",
            },
            {
              controlType: "DropDownList",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 2,
              fieldLabel: t("Leave_Configuration_Policy_bfCutOffUom"),
              fieldName: "bfCutOffUom",
              id: 3,
              inputName: "bfCutOffUom",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-col-12",
              translationKey: "Leave_Configuration_Policy_bfCutOffUom",
              viewSectionId: 100,
              styleClass: "p-lg-6 p-md-12 p-sm-12",
            },
            {
              controlType: "DropDownList",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 3,
              fieldLabel: t("Leave_Configuration_Policy_company"),
              fieldName: "companyCode",
              id: 4,
              inputName: "companyCode",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-col-12",
              translationKey: "Leave_Configuration_Policy_company",
              viewSectionId: 200,
              styleClass: "p-lg-6 p-md-12 p-sm-12",
            },
            {
              controlType: "DropDownList",
              dataType: "string",
              displaySequence: 3,
              fieldGroup: 3,
              fieldLabel: t("Leave_Configuration_Policy_grade"),
              fieldName: "grade",
              id: 5,
              inputName: "grade",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-col-12",
              translationKey: "Leave_Configuration_Policy_grade",
              viewSectionId: 300,
              styleClass: "p-lg-6 p-md-12 p-sm-12",
            },
            {
              controlType: "DropDownList",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 4,
              fieldLabel: t("Leave_Configuration_Policy_staffType"),
              fieldName: "staffType",
              id: 6,
              inputName: "staffType",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-col-12",
              translationKey: "Leave_Configuration_Policy_staffType",
              viewSectionId: 300,
              styleClass: "p-lg-6 p-md-12 p-sm-12",
            },
            {
              controlType: "DropDownList",
              dataType: "string",
              displaySequence: 2,
              fieldGroup: 4,
              fieldLabel: t("Leave_Configuration_Policy_team"),
              fieldName: "team",
              id: 7,
              inputName: "team",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-col-12",
              translationKey: "Leave_Configuration_Policy_team",
              viewSectionId: 300,
              styleClass: "p-lg-6 p-md-12 p-sm-12",
            },
            {
              controlType: "DropDownList",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 5,
              fieldLabel: t("Leave_Configuration_Policy_employees"),
              fieldName: "employeeId",
              id: 8,
              inputName: "employeeId",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-col-12",
              translationKey: "Leave_Configuration_Policy_employees",
              viewSectionId: 300,
              styleClass: "p-lg-6 p-md-12 p-sm-12",
            },
            {
              controlType: "NumericBox",
              dataType: "string",
              displaySequence: 2,
              fieldGroup: 5,
              fieldLabel: t("Leave_Configuration_dateJoinGroup"),
              fieldName: "dateJoinGroupMonthOfService",
              id: 9,
              inputName: "dateJoinGroupMonthOfService",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-col-12",
              translationKey: "Leave_Configuration_dateJoinGroup",
              viewSectionId: 100,
              styleClass: "p-lg-6 p-md-12 p-sm-12",
            },
            {
              controlType: "NumericBox",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 6,
              fieldLabel: t("Leave_Configuration_commencementDate"),
              fieldName: "commencementDateMonthOfService",
              id: 10,
              inputName: "commencementDateMonthOfService",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-col-12",
              translationKey: "Leave_Configuration_commencementDate",
              viewSectionId: 100,
              styleClass: "p-lg-6 p-md-12 p-sm-12",
            },
            // {
            //   controlType: "InputGroup",
            //   dataType: "string",
            //   displaySequence: 1,
            //   fieldGroup: 6,
            //   fieldLabel: t("Leave_Configuration_commencementDate"),
            //   fieldName: "commencementDateMonthOfService2",
            //   id: 10,
            //   inputName: "commencementDateMonthOfService2",
            //   isAllowNull: false,
            //   isDefaultHide: false,
            //   isDetailView: false,
            //   isEditable: true,
            //   isElasticData: false,
            //   isHideLabelField: false,
            //   isHtmlField: false,
            //   isSpecialField: false,
            //   length: -1,
            //   storageDataType: "String",
            //   styleClass: "p-col-12",
            //   translationKey: "Leave_Configuration_commencementDate",
            //   viewSectionId: 100,
            //   styleClass: "p-lg-6 p-md-12 p-sm-12",
            //   dropdownOptions: [
            //     {
            //       name: t("Leave_Configuration_commencementDate"),
            //       value: "(?<unitValue>\\d+)(?<unitType>D)",
            //     },
            //     {
            //       name: t("Leave_Configuration_dateJoinGroup"),
            //       value: "(?<unitValue>\\d+)(?<unitType>R)",
            //     },
            //   ]
            // },
          ],
        },
        {
          defaultExpanded: true,
          displayLayout: 2,
          displaySequence: 2,
          id: 200,
          isDefaultHide: false,
          fields: [
            {
              controlType: "ArrayControl",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 1,
              fieldLabel: t("Leave_Configuration_Policy_bfPolicyLeave"),
              fieldName: "details",
              id: 1,
              inputName: "details",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-col-12",
              translationKey: "Leave_Configuration_Policy_bfPolicyLeave",
              viewSectionId: 100,
              eventFunctionName: "onDetailsUpdate",
              config: {
                saveButtonLabel: t(
                  "Leave_Configuration_Policy_bfPolicyLeave_saveButtonLabel"
                ),
                crudType: ArrayCRUDType.inlinescreen,
                headers: [
                  {
                    key: "leaveCode",
                    header: t(
                      "Leave_Configuration_Policy_bfPolicyLeave_leaveType_tableHeader"
                    ),
                    label: t(
                      "Leave_Configuration_Policy_bfPolicyLeave_leaveType_formLabel"
                    ),
                    placeholder: t("base_control_select_choose"),
                    required: true,
                    type: BCType.select,
                    enum: ToUIOption(dropdownOptions?.leaveCode),
                    fieldGroup: 1,
                  },
                  {
                    key: "mode",
                    header: t(
                      "Leave_Configuration_Policy_bfPolicyLeave_mode_tableHeader"
                    ),
                    label: t(
                      "Leave_Configuration_Policy_bfPolicyLeave_mode_formLabel"
                    ),
                    placeholder: t("base_control_select_choose"),
                    required: true,
                    type: BCType.select,
                    enum: ToUIOption(dropdownOptions?.mode),
                    fieldGroup: 1,
                  },
                  {
                    key: "bfUnitUom",
                    header: t(
                      "Leave_Configuration_Policy_bfPolicyLeave_bfUnitUom_tableHeader"
                    ),
                    label: t(
                      "Leave_Configuration_Policy_bfPolicyLeave_bfUnitUom_formLabel"
                    ),
                    placeholder: t("base_control_select_choose"),
                    required: true,
                    type: BCType.select,
                    enum: ToUIOption(dropdownOptions?.bfUnitUom),
                    fieldGroup: 2,
                    defaultValue: "Day",
                  },
                  {
                    key: "bfUnit",
                    header: t(
                      "Leave_Configuration_Policy_bfPolicyLeave_bfUnit_tableHeader"
                    ),
                    label: t(
                      "Leave_Configuration_Policy_bfPolicyLeave_bfUnit_formLabel"
                    ),
                    placeholder: t("base_control_input_text"),
                    type: BCType.number,
                    required: true,
                    fieldGroup: 2,
                    defaultValue: 0,
                    min: 0,
                  },
                  {
                    key: "bfExpiryUom",
                    header: t(
                      "Leave_Configuration_Policy_bfPolicyLeave_bfExpiryUom_tableHeader"
                    ),
                    label: t(
                      "Leave_Configuration_Policy_bfPolicyLeave_bfExpiryUom_formLabel"
                    ),
                    placeholder: t("base_control_select_choose"),
                    required: true,
                    type: BCType.select,
                    enum: ToUIOption(dropdownOptions?.bfExpiryUom),
                    fieldGroup: 3,
                    defaultValue: "Day",
                  },
                  {
                    key: "bfExpiry",
                    header: t(
                      "Leave_Configuration_Policy_bfPolicyLeave_bfExpiry_tableHeader"
                    ),
                    label: t(
                      "Leave_Configuration_Policy_bfPolicyLeave_bfExpiry_formLabel"
                    ),
                    placeholder: t("base_control_input_text"),
                    type: BCType.number,
                    required: true,
                    fieldGroup: 3,
                    defaultValue: 0,
                    min: 0,
                  },
                  {
                    key: "isStatutory",
                    header: t(
                      "Leave_Configuration_Policy_bfPolicyLeave_isStatutoryLeave_tableHeader"
                    ),
                    label: t(
                      "Leave_Configuration_Policy_bfPolicyLeave_isStatutoryLeave_formLabel"
                    ),
                    placeholder: t("base_control_select_choose"),
                    required: false,
                    type: BCType.checkbox,
                    fieldGroup: 4,
                    defaultValue: false,
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  };
}
