import { SET_SIDEBAR, SIDEBAR_CLEAR } from "../reduxConstants";

interface ExpandedKeys {
  [any: string]: boolean;
}

export interface ISideBar {
  isOpenSidebar: boolean;
  isOpenSidebarMobile: boolean;
  mustLoad: boolean;
  data: any[];
  selectedKey?: string;
  expandedKeys?: ExpandedKeys;
  isLeafNode?: boolean;
}

interface ISideBarAction {
  type: string;
  payload: ISideBar;
}

const initialState: ISideBar = {
  isOpenSidebar: true,
  isOpenSidebarMobile: false,
  mustLoad: false,
  data: [],
};

function sidebar(state = initialState, action: ISideBarAction) {
  switch (action.type) {
    case SET_SIDEBAR: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SIDEBAR_CLEAR: {
      // reset to initialState
      return initialState;
    }
    default: {
      return state;
    }
  }
}

export default sidebar;
