//direct from api
export const InitCompanyDocumentConfigurationForm = {
  id: undefined,
  modifiedDate: undefined,
  company: undefined,
  documentType: undefined,
  status: undefined,
  content: undefined,
  effective_From: undefined,
  effective_To: undefined,
  language: undefined,
};

export function CompanyDocumentConfigurationApiSteps(t) {
  return {
    data: [
      {
        displaySequence: 1,
        id: 1,
        stepName: t("Company_Document_Configuration_Step1"), //"Start",
        translationKey: "Company_Document_Configuration_Step1",
        sections: [
          {
            displaySequence: 1,
            isEditable: true,
            sectionId: 100,
            sectionName: t("Company_Document_Configuration_Formname_Update"), //"Edit",
            translationKey: "Company_Document_Configuration_Formname_Update",
          },
        ],
      },
    ],
  };
}

export function CompanyDocumentConfigurationInitForm(t) {
  return {
    data: {
      formName: t("Company_Document_Update_Configuration_Formname"), //
      id: 1000,
      sections: [
        {
          defaultExpanded: true,
          displayLayout: 2,
          displaySequence: 1,
          id: 100,
          isDefaultHide: false,
          sectionName: t("Company_Document_Configuration_Edit"), //"Edit",
          translationKey: "Company_Document_Configuration_Edit",
          fields: [
            {
              // controlType: "AutoComplete",
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 1,
              fieldLabel: t("Company_Document_Configuration_DocumentType"), //"DocumentType,
              fieldName: "documentType",
              id: 1,
              inputName: "documentType",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: false,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "Company_Document_Configuration_DocumentType",
              viewSectionId: 100,
              // config: {
              //   forceSelection: true,
              //   dropdown: true,
              // },
              // eventFunctionName: "onCompanyCodeSelect",
            },
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 2,
              fieldGroup: 1,
              fieldLabel: t("Company_Document_Configuration_Language"), //"Language",
              fieldName: "language",
              id: 2,
              inputName: "language",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: false,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "Company_Document_Configuration_Language",
              viewSectionId: 200,
            },
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 3,
              fieldGroup: 2,
              fieldLabel: t("Company_Document_Configuration_CompanyCode"),
              fieldName: "company",
              id: 3,
              inputName: "company",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: false,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "Company_Document_Configuration_CompanyCode",
              viewSectionId: 300,
            },
            {
              controlType: "Toggle",
              dataType: "string",
              displaySequence: 4,
              fieldGroup: 2,
              fieldLabel: t("Company_Document_Configuration_Status"), //"Status",
              fieldName: "status",
              id: 4,
              inputName: "status",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "Company_Document_Configuration_Status",
              viewSectionId: 400,
              config: {
                truevalue: t("Company_Document_Configuration_Status_Active"),
                falsevalue: t("Company_Document_Configuration_Status_Inactive"),
              },
              dependentFields:
                '[{"Value":"undefined","Action":"Hide","Fields":"effective_From, effective_From", "Sections":""},{"Value":true,"Action":"Show","Fields":"effective_To, effective_From", "Sections":""},{"Value":false,"Action":"Hide","Fields":"effective_To, effective_From", "Sections":""}]',
            },
            {
              controlType: "DatePicker",
              dataType: "string",
              displaySequence: 5,
              dropdownOptions: [],
              fieldGroup: 3,
              fieldLabel: t("Company_Document_Configuration_EffectiveFrom"), //"effectiveFrom",
              fieldName: "effective_From",
              id: 5,
              inputName: "effective_From",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: 0,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "Company_Document_Configuration_EffectiveFrom",
              viewSectionId: 500,
              config: {
                showButtonBar: true,
                clearButtonClassName: "display-none",
              },
            },
            {
              controlType: "DatePicker",
              dataType: "string",
              displaySequence: 6,
              dropdownOptions: [],
              fieldGroup: 3,
              fieldLabel: t("Company_Document_Configuration_EffectiveTo"), //"effectiveTo",
              fieldName: "effective_To",
              id: 6,
              inputName: "effective_To",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: 0,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "Company_Document_Configuration_EffectiveTo",
              viewSectionId: 600,
              config: {
                showButtonBar: true,
                todayButtonClassName: "display-none",
                minDate: new Date(),
              },
            },
            {
              controlType: "KendoEditor",
              dataType: "string",
              displaySequence: 10,
              fieldGroup: 6,
              fieldLabel: t("Company_Document_Configuration_Content"), //"Content",
              fieldName: "content",
              id: 10,
              inputName: "content",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-12 p-md-12 p-sm-12",
              translationKey: "Company_Document_Configuration_Content",
              viewSectionId: 1000,
              config: {},
            },
          ],
        },
      ],
    },
  };
}
