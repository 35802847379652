import "./entitlement.scss";

//components
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import merge from "deepmerge";
import { useHistory } from "react-router";
import moment from "moment";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TabMenu } from "primereact/tabmenu";

import { setBcDynamicPaths } from "../../../../../redux/actions/breadcrumb";
import { twoColformSkeleton } from "./../../utils/skeleton";
import { useTranslation } from "react-i18next";
import { BCType } from "../../../../../components/base-control/base-control";
import BaseForm from "../../../../../components/base-form/base-form";
import {
  getControlsAndLayout,
  initFormWithLeaveApplication,
  prepareApplicationPayload,
} from "./config-helper";
import { BASIC_CONTROL_LAYOUT_CONFIG } from "./../../utils/config-helper";
import { openModal } from "../../../../../redux/actions/modal";
import {
  AppConstant,
  EEPORTAL_LABEL_BASE_TABLE,
  FUNCTION_CODE,
  MENU_NAME,
  MESSAGE_SEVERITY,
  RESPONSE_MESSAGE_ERROR,
} from "../../../../../constants";
import {
  LEAVE_T_CONTEXT_KEY,
  LEAVE_WORKFLOW_STATUS,
  LEAVE_MENU_MODULE_KEY,
  LEAVE_MENU_PAGE_KEY,
} from "../../constants/contants";
import { LeaveService } from "../../../../../services/hrmnet-api";
import { isResponseOk } from "../../../../../utils/utils";
import {
  showTimestampToastSuccess,
  showToast,
} from "../../../../../services/utils/message";
import { showSpinner } from "../../../../../redux/actions/spinner";
import LanguageLoader from "../../../../../components/language-loader/language-loader";
import {
  confirmApproveApplication,
  confirmRejectApplication,
} from "../approval/helper";
import { LeaveMenu, ScrollToLeaveMenuActiveItem } from "../../components";
import axios from "axios";

const mergeControlAndControlConfig = (controls, controlConfig) => {
  return controls.map((control) => {
    if (control.key in controlConfig) {
      return {
        ...control,
        ...controlConfig[control.key],
        config: {
          ...control.config,
          ...controlConfig[control.key].config,
        },
      };
    } else {
      return control;
    }
  });
};

const ApplyEntitlement = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const menus = useSelector((state) => state.menu.sideBar);
  const leaveMenu = menus.find(
    (x) => x.key === LEAVE_MENU_MODULE_KEY
  )?.subMenus;
  const { cancel, token } = axios.CancelToken.source();
  const requestConfig = {
    cancelToken: token,
  };

  const editMode = !!props.application && !props.readOnly;
  const viewMode = !!props.readOnly;
  const approverMode = !!props.enableApproval;
  const delegateeMode = props?.delegateeMode ?? false;
  const isAllowApproveReject =
    !!props.application &&
    props.application?.workflowStatus === LEAVE_WORKFLOW_STATUS.IN_PROGRESS;
  const isApprovedOrRejected =
    !!props.application &&
    (props.application?.workflowStatus === LEAVE_WORKFLOW_STATUS.COMPLETE ||
      props.application?.workflowStatus === LEAVE_WORKFLOW_STATUS.REJECT);

  const displayCancelButton =
    [MENU_NAME.Submit_Leave_Application].filter((y) =>
      Array.from(leaveMenu, (x) => x?.key).includes(y)
    )?.length > 0;

  const initViewDate = viewMode
    ? new Date(props.application.leaveFrom)
    : new Date();
  const initSelectedDate = viewMode ? initViewDate : null;

  const initState = {
    touched: false,
    isLoading: props?.isUserApplication ?? true,
    isLoadingForm: false,
    viewDate: initViewDate,
  };
  const initForm = {
    EmployeeCode: (approverMode || viewMode)
      ? props.application?.employeeCode
      : props?.employeeCode,
    IsUserApplication: props?.isUserApplication,
    LeaveStartDate: null,
    LeaveStartTime: new Date(1970, 1, 1, 9, 0, 0),
    LeaveStartTimeSegment: "AM",
    LeaveEndDate: null,
    LeaveEndTime: new Date(1970, 1, 1, 18, 0, 0),
    LeaveEndTimeSegment: "PM",
  };

  const [state, setState] = useState(initState);
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [leaveInfo, setLeaveInfo] = useState({});
  const [disabledLeaveType, setDisabledLeaveType] = useState(true);
  const [isInitApplication, setIsInitApplication] = useState(
    editMode || viewMode
  );

  const [form, setForm] = useState(initForm);
  const [formControls, setFormControls] = useState([]);
  const [formLayout, setFormLayout] = useState([]);

  // for validation
  const [formControlConfig, setFormControlConfig] = useState({});
  const [triggerCalculateUnit, setTriggerCalculateUnit] = useState(false);
  const [pendingCalculateUnit, setPendingCalculateUnit] = useState(false);
  const [blockCalculateUnit, setBlockCalculateUnit] = useState(false);

  // workflow
  const [showWorkflow, setShowWorkflow] = useState(false);
  const [isLoadingWorkflow, setIsLoadingWorkflow] = useState(false);
  const [applicationWorkflow, setApplicationWorkflow] = useState(null);
  const [applicationWorkflowInfo, setApplicationWorkflowInfo] = useState(null);
  const [workflowExpandedRows, setWorkflowExpandedRows] = useState(null);
  const [isLoadingWorkflowExpansion, setIsLoadingWorkflowExpansion] =
    useState(false);
  const [requestedApplicationId, setRequestedApplicationId] = useState([]);

  const formRef = React.createRef();
  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );

  // fetch data
  useEffect(() => {
    const getLeaveTypes = async () => {
      setDisabledLeaveType(true);
      let res = await LeaveService.leaveGetLeaveTypeList(
        {
          applyentitlement: "Y",
          employeecode: form["EmployeeCode"],
        },
        requestConfig
      );
      if (res && res.data) {
        setLeaveTypes(res.data);
      }
      setState({
        ...state,
        isLoading: false,
      });
      setDisabledLeaveType(false);
    };

    if (
      (approverMode && !!form["EmployeeCode"]) ||
      (!approverMode && (!!form["EmployeeCode"] || !!form["IsUserApplication"]))
    ) {
      getLeaveTypes();
      ScrollToLeaveMenuActiveItem();
    }

    return () => {
      cancel(RESPONSE_MESSAGE_ERROR.CANCEL);
    };
  }, [selectedLangKey, form["EmployeeCode"], form["IsUserApplication"]]);

  // cancel
  const cancelApplicationModal = () => {
    dispatch(
      openModal({
        title: t("leave_viewApplication_cancelTitle"),
        content: t("leave_viewApplication_cancelMessage", {
          date: moment(initViewDate).format("YYYY-MM-DD"),
        }),
        classNameMainDialog: "confirm-message-modal form-modal",
        primaryButtonText: t("leave_common_actionYes"),
        secondaryButtonText: t("leave_common_actionNo"),
        form: {
          config: {
            controls: [
              {
                key: "Remarks", // custom field
                label: t("leave_viewApplication_cancelReason"),
                type: BCType.input,
              },
            ],
          },
          form: {},
        },
        primaryButtonClickFn: async ({ closeFn, form }) => {
          await cancelApplication({
            applicationId: props.application?.applicationId,
            remarks: form["Remarks"],
            employeeCode: props.application?.employeeCode,
          });
          closeFn();
        },
        secondButtonClickFn: ({ closeFn }) => {
          closeFn();
        },
        force_touched: true,
      })
    );
  };

  const cancelApplication = async ({
    applicationId,
    remarks,
    employeeCode,
  }) => {
    try {
      dispatch(showSpinner(true));
      const res = await LeaveService.leaveCancelLeaveApplication({
        applicationId,
        remarks,
        employeeCode,
      });
      if (isResponseOk(res)) {
        backToList();
        showTimestampToastSuccess({
          message: t("leave_calendarView_cancelSuccess"),
          t: t,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(showSpinner(false));
    }
  };

  const calculateUnits = async () => {
    if (!formControls) {
      return;
    }
    console.log("leaveInfo", leaveInfo);
    // TODO enhance logic
    if (
      !form["LeaveStartDate"] ||
      !form["LeaveStartTime"] ||
      (!form["LeaveEndDate"] &&
        !leaveInfo.leavePreApproval?.[0]?.eventDateApplication) ||
      !form["LeaveEndTime"]
    ) {
      setForm((form) => ({
        ...form,
        Units: undefined,
      }));
      return;
    }

    if (blockCalculateUnit) {
      setPendingCalculateUnit(true);
      return;
    }

    // start calculate
    setBlockCalculateUnit(true);
    const payload = prepareApplicationPayload({
      form,
      leaveInfo,
      preApproval: true,
      includeAttachment: false,
      defaultUnits: 1, // custom logic, requried by backend
    });
    let res = await LeaveService.leaveCalculateUnitsEntitlement(payload);

    // prepare time segment
    if (leaveInfo.leavePreApproval?.[0]?.useTimeSegmentName) {
      let segmentTimeInfo = prepareTimeSegment();
      if (segmentTimeInfo) {
        setLeaveInfo((leaveInfo) => ({
          ...leaveInfo,
          segmentTimeInfo: segmentTimeInfo,
        }));
      }
    }

    if (isResponseOk(res)) {
      setForm((form) => ({
        ...form,
        Units: Number(res.data),
      }));
    } else {
      setForm((form) => ({
        ...form,
        Units: null,
      }));
    }
  };

  const autofillEndDate = async () => {
    if (form["LeaveStartDate"]) {
      let newEndDate = new Date(form["LeaveStartDate"]);
      newEndDate.setDate(newEndDate.getDate());
      setForm((form) => ({
        ...form,
        LeaveEndDate: newEndDate,
      }));
    }
    return;
  };

  const prepareTimeSegment = () => {
    if (
      form["LeaveStartDate"] &&
      form["LeaveEndDate"] &&
      leaveInfo?.officeHrStart &&
      leaveInfo?.lunchHrStart &&
      leaveInfo?.lunchHrEnd &&
      leaveInfo?.officeHrEnd
    ) {
      let startTimeAM = new Date(form["LeaveStartDate"]);
      let startTimePM = new Date(form["LeaveStartDate"]);
      let endTimeAM = new Date(form["LeaveEndDate"]);
      let endTimePM = new Date(form["LeaveEndDate"]);
      startTimeAM.setHours(
        (leaveInfo?.officeHrStart).substr(0, 2),
        (leaveInfo?.officeHrStart).substr(3, 2)
      );
      startTimePM.setHours(
        (leaveInfo?.lunchHrEnd).substr(0, 2),
        (leaveInfo?.lunchHrEnd).substr(3, 2)
      );
      endTimeAM.setHours(
        (leaveInfo?.lunchHrStart).substr(0, 2),
        (leaveInfo?.lunchHrStart).substr(3, 2)
      );
      endTimePM.setHours(
        (leaveInfo?.officeHrEnd).substr(0, 2),
        (leaveInfo?.officeHrEnd).substr(3, 2)
      );

      const segmentTimeInfo = {
        start: {
          AM: startTimeAM,
          PM: startTimePM,
        },
        end: {
          AM: endTimeAM,
          PM: endTimePM,
        },
      };
      return segmentTimeInfo;
    }

    return null;
  };

  const updateFormControlConfig = () => {
    if (formControls.length) {
      setFormControlConfig((formControlConfig) => ({
        ...formControlConfig,
        LeaveStartDate: {
          config: {
            ...formControlConfig?.["LeaveStartDate"]?.config,
            className: form["LeaveStartDate"]
              ? null
              : formControlConfig?.["LeaveStartDate"]?.config.className, // update error status
            // maxDate: form["LeaveEndDate"],
            minDate: leaveInfo?.earliestAllowedDate
              ? new Date(leaveInfo.earliestAllowedDate)
              : new Date(moment().subtract(1, "years").startOf("year")),
            maxDate: leaveInfo?.latestAllowedDate
              ? new Date(leaveInfo.latestAllowedDate)
              : new Date(moment().add(1, "years").endOf("year")),
            viewDate: form["LeaveStartDate"]
              ? form["LeaveStartDate"]
              : form["LeaveEndDate"],
          },
        },
        LeaveEndDate: {
          config: {
            ...formControlConfig?.["LeaveEndDate"]?.config,
            className: form["LeaveEndDate"]
              ? null
              : formControlConfig?.["LeaveEndDate"]?.config.className, // update error status
            // minDate: form["LeaveStartDate"],
            minDate: form["LeaveStartDate"]
              ? form["LeaveStartDate"]
              : leaveInfo?.earliestAllowedDate
              ? new Date(leaveInfo.earliestAllowedDate)
              : new Date(moment().subtract(1, "years").startOf("year")),
            maxDate: leaveInfo?.latestAllowedDate
              ? new Date(leaveInfo.latestAllowedDate)
              : new Date(moment().add(1, "years").endOf("year")),
            viewDate: form["LeaveEndDate"]
              ? form["LeaveEndDate"]
              : form["LeaveStartDate"],
          },
        },
        LeaveStartTime: {
          config: {
            ...formControlConfig?.["LeaveStartTime"]?.config,
            className: form["LeaveStartTime"]
              ? null
              : formControlConfig?.["LeaveStartTime"]?.config.className, // update error status
            readOnly: viewMode || !form["LeaveStartDate"],
          },
        },
        LeaveStartTimeSegment: {
          config: {
            readOnly: viewMode || !form["LeaveStartDate"],
          },
        },
        LeaveEndTime: {
          config: {
            ...formControlConfig?.["LeaveEndTime"]?.config,
            className: form["LeaveEndTime"]
              ? null
              : formControlConfig?.["LeaveEndTime"]?.config.className, // update error status
            readOnly:
              viewMode || !form["LeaveStartDate"] || !form["LeaveEndDate"],
          },
        },
        LeaveEndTimeSegment: {
          config: {
            readOnly:
              viewMode || !form["LeaveStartDate"] || !form["LeaveEndDate"],
          },
        },
      }));
    }
  };

  // form validation
  useEffect(() => {
    updateFormControlConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formControls,
    JSON.stringify(form["LeaveStartDate"]),
    JSON.stringify(form["LeaveStartTime"]),
    JSON.stringify(form["LeaveEndDate"]),
    JSON.stringify(form["LeaveEndTime"]),
  ]);

  // autofill end date
  useEffect(() => {
    autofillEndDate();
  }, [JSON.stringify(form["LeaveStartDate"])]);

  // calculate units
  useEffect(() => {
    if (!viewMode && !isInitApplication) {
      calculateUnits();
      // autofillEndDate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // JSON.stringify(form["LeaveStartDate"]),
    JSON.stringify(form["LeaveStartTime"]),
    JSON.stringify(form["LeaveStartTimeSegment"]),
    JSON.stringify(form["LeaveEndDate"]),
    JSON.stringify(form["LeaveEndTime"]),
    JSON.stringify(form["LeaveEndTimeSegment"]),
  ]);

  // calculate unit trigger
  // this is to cater calling calculateUnits() in the timer
  // timer is initiated once on startup so it cannot access state regularly
  useEffect(() => {
    if (triggerCalculateUnit) {
      setTriggerCalculateUnit(false);
      calculateUnits();
    }
  }, [triggerCalculateUnit]);

  // Timer for execute pending calculate unit job
  React.useEffect(() => {
    const interval = setInterval(() => {
      setBlockCalculateUnit(false);
      setPendingCalculateUnit((pending) => {
        if (pending) {
          setTriggerCalculateUnit(true);
        }
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const loadForm = async () => {
    // reset form or init edit application
    let _form = {};
    if (isInitApplication) {
      _form = { ...form, ...initFormWithLeaveApplication(props.application) };
    } else {
      _form = {
        ...initForm,
        //...form,
        LeaveCode: form["LeaveCode"],
        EmployeeCode: form["EmployeeCode"],
        IsUserApplication: form["IsUserApplication"],
      };
    }

    // Load form config
    let leaveInfo = {};
    if (_form["LeaveCode"]) {
      let res;
      res = await LeaveService.leaveGetLeaveTypesInfoByLeaveCode({
        leavecode: _form["LeaveCode"],
        employeeCode: _form["EmployeeCode"],
      });

      // const res = sampleLeaveInformationCL
      if (res?.data?.length) {
        leaveInfo = { ...res.data[0] };
      }
    }

    // prepare time segment
    if (
      (editMode || viewMode) &&
      leaveInfo.leavePreApproval?.[0]?.useTimeSegmentName &&
      props.application?.leaveFrom &&
      props.application?.leaveTo
    ) {
      const startTime = moment(props.application?.leaveFrom).format("HH:mm:ss");
      const endTime = moment(props.application?.leaveTo).format("HH:mm:ss");
      let updateSegment = {};
      if (startTime === leaveInfo?.officeHrStart) {
        updateSegment["LeaveStartTimeSegment"] = "AM";
      }
      if (startTime === leaveInfo?.lunchHrEnd) {
        updateSegment["LeaveStartTimeSegment"] = "PM";
      }
      if (endTime === leaveInfo?.lunchHrStart) {
        updateSegment["LeaveEndTimeSegment"] = "AM";
      }
      if (endTime === leaveInfo?.officeHrEnd) {
        updateSegment["LeaveEndTimeSegment"] = "PM";
      }

      _form = {
        ..._form,
        ...updateSegment,
      };

      let segmentTimeInfo = prepareTimeSegment();
      if (segmentTimeInfo) {
        leaveInfo.segmentTimeInfo = segmentTimeInfo;
      }
    }

    // default time
    if (!editMode && leaveInfo?.officeHrStart && leaveInfo?.officeHrEnd) {
      const startHour = Number((leaveInfo?.officeHrStart).substr(0, 2));
      const startMinute = Number((leaveInfo?.officeHrStart).substr(3, 2));
      const endHour = Number((leaveInfo?.officeHrEnd).substr(0, 2));
      const endMinute = Number((leaveInfo?.officeHrEnd).substr(3, 2));

      const startDateTime = new Date(
        1970,
        1,
        1,
        !isNaN(startHour) ? startHour : 0,
        !isNaN(startMinute) ? startMinute : 0,
        0
      );
      const endDateTime = new Date(
        1970,
        1,
        1,
        !isNaN(endHour) ? endHour : 0,
        !isNaN(endMinute) ? endMinute : 0,
        0
      );

      _form = {
        ..._form,
        LeaveStartTime: startDateTime,
        LeaveEndTime: endDateTime,
      };
    }

    setLeaveInfo(leaveInfo);
    const [controls, layout] = getControlsAndLayout({
      leaveInfo,
      leaveTypes,
      selectedLeaveCode: _form["LeaveCode"],
      t,
      delegatedEmployeeList:
        viewMode || approverMode ? null : props?.employeeList,
      form,
      editMode,
      delegateeMode,
      readOnly: viewMode,
    });
    setFormControls(controls);
    setFormLayout(layout);
    setForm(_form);

    // Finish update form
    setState({
      ...state,
      isLoading: false,
      isLoadingForm: false,
    });
  };

  useEffect(() => {
    if (isInitApplication && leaveTypes.length) {
      loadForm();
    }

    return () => {
      setState({ ...state, isLoadingForm: true });
    };
  }, [JSON.stringify(form["LeaveCode"]), leaveTypes]);

  useEffect(() => {
    if (approverMode) {
      dispatch(
        setBcDynamicPaths([
          { label: t("leave_entitlement_breadcrumb_approve") },
        ])
      );
    } else if (viewMode) {
      dispatch(
        setBcDynamicPaths([{ label: t("leave_entitlement_breadcrumb_view") }])
      );
    } else {
      dispatch(
        setBcDynamicPaths([{ label: t("leave_entitlement_breadcrumb") }])
      );
    }
  }, [dispatch]);

  // Reload Form when fetched leave types and when leave code is selected
  useEffect(() => {
    // skip reload when init'ing for edit application
    if (
      !isInitApplication ||
      (!form["IsUserApplication"] &&
        !JSON.stringify(form["EmployeeCode"]) &&
        !editMode)
    ) {
      loadForm();
      setFormControlConfig({});
    }

    // update isInitApplication when init finished
    if (form["LeaveCode"] && leaveTypes.length && isInitApplication) {
      setIsInitApplication(false);
    }

    return () => {
      setState({ ...state, isLoadingForm: true });
    };
  }, [
    JSON.stringify(form["LeaveCode"]),
    leaveTypes,
    JSON.stringify(form["EmployeeCode"]),
    JSON.stringify(form["IsUserApplication"]),
  ]);

  // Validation
  const validateAll = () => {
    if (!validateBase()) {
      return false;
    }
    setState((state) => ({
      ...state,
      touched: true,
    }));
    return !formRef.current.getFormState().invalid;
  };

  const validateBase = () => {
    let _config = {};
    let hasEmpty = false;
    const preApprovalConfig = leaveInfo.leavePreApproval?.[0];
    let validateFieldList = [];
    if (preApprovalConfig.showDate) {
      validateFieldList.push("LeaveStartDate");
      if (!preApprovalConfig.eventDateApplication) {
        validateFieldList.push("LeaveEndDate");
      }
    }
    if (preApprovalConfig.showTime) {
      validateFieldList.push("LeaveStartTime");
      validateFieldList.push("LeaveEndTime");
    }

    validateFieldList.forEach((field) => {
      if (!form[field]) {
        _config[field] = { config: { className: "p-invalid" } };
        hasEmpty = true;
      }
    });

    if (hasEmpty) {
      setFormControlConfig((controlConfig) => merge(controlConfig, _config));
      return false;
    }

    // validate start time < end time
    const payload = prepareApplicationPayload({
      form,
      leaveInfo,
      preApproval: true,
      includeAttachment: false,
    });
    console.log("payload", payload);
    if (
      new Date(payload["LeaveStartDateTimeOrDate"]) >=
      new Date(payload["LeaveEndDateTimeOrDate"])
    ) {
      // bad input

      // highlight error fields
      _config["LeaveStartTime"] = { config: { className: "p-invalid" } };
      _config["LeaveEndTime"] = { config: { className: "p-invalid" } };

      setFormControlConfig((controlConfig) => merge(controlConfig, _config));
      showToast({
        summary: t("leave_form_messagePleaseProvideValidDateRange"),
        severity: MESSAGE_SEVERITY.WARN,
      });
      return false;
    }

    return true;
  };

  // Save
  const save = async () => {
    try {
      dispatch(showSpinner(true));
      if (!validateBase()) return;

      const applicationId = props?.application?.applicationId;

      const payload = prepareApplicationPayload({
        form,
        leaveInfo,
        preApproval: true,
      });

      let res;
      if (applicationId != null) {
        res = await LeaveService.leaveUpdateLeaveDraftApplication({
          applicationId,
          form: payload,
        });
      } else {
        res = await LeaveService.leaveSaveLeaveDraftApplication(payload);
      }

      if (isResponseOk(res)) {
        backToList();
        showTimestampToastSuccess({
          message: t("leave_entitlement_saveSuccessMessage"),
          t: t,
        });
      }
    } catch (e) {
    } finally {
      dispatch(showSpinner(false));
    }
  };

  // Submit
  const confirmSubmit = () => {
    if (!validateAll()) {
      return;
    }

    dispatch(
      openModal({
        title: t("leave_message_confirmation"),
        content: t("leave_entitlement_confirmSubmitMessage"),
        classNameMainDialog: "confirm-message-modal",
        primaryButtonText: t("leave_entitlement_confirmSubmitButton"),
        primaryButtonClickFn: async ({ closeFn }) => {
          closeFn();
          submitAction();
        },
        secondButtonClickFn: ({ closeFn }) => {
          closeFn();
        },
      })
    );
  };

  const submitAction = async () => {
    try {
      dispatch(showSpinner(true));
      const applicationId = props?.application?.applicationId;
      const payload = prepareApplicationPayload({
        form,
        leaveInfo,
        preApproval: true,
      });

      let res;
      if (applicationId != null) {
        res = await LeaveService.leaveSubmitLeaveDraftApplication({
          applicationId,
          form: payload,
        });
      } else {
        res = await LeaveService.leaveSubmitLeaveApplication(payload);
      }

      if (isResponseOk(res)) {
        backToList();
        showTimestampToastSuccess({
          message: t("leave_submit_submitSuccessMessage"),
          t: t,
        });
      }
    } catch (e) {
    } finally {
      dispatch(showSpinner(false));
    }
  };

  // Back
  const backToList = () => {
    history.goBack();
    // history.push(`/${AppConstant.PORTAL}/${FUNCTION_CODE.Leave}`);
  };

  const confirmBack = async () => {
    dispatch(
      openModal({
        title: t("leave_message_confirmation"),
        content: t("leave_entitlement_confirmBackMessage"),
        classNameMainDialog: "confirm-message-modal",
        primaryButtonText: t("leave_common_actionConfirm"),
        primaryButtonClickFn: async ({ closeFn }) => {
          closeFn();
          backToList();
        },
        secondButtonClickFn: ({ closeFn }) => {
          closeFn();
        },
      })
    );
  };

  // Delete Draft
  const confirmDeleteDraft = async () => {
    dispatch(
      openModal({
        title: t("leave_editApplication_confirmDeleteTitle"),
        content: t("leave_editApplication_confirmDeleteMessage"),
        classNameMainDialog: "confirm-message-modal",
        primaryButtonText: t("leave_common_actionConfirm"),
        primaryButtonClickFn: async ({ closeFn }) => {
          closeFn();
          deleteDraft();
        },
        secondButtonClickFn: ({ closeFn }) => {
          closeFn();
        },
      })
    );
  };

  const deleteDraft = async () => {
    try {
      dispatch(showSpinner(true));
      const res = await LeaveService.leaveDeleteLeaveDraftApplication({
        applicationId: props.application.applicationId,
        employeeCode: form["EmployeeCode"],
      });
      if (isResponseOk(res)) {
        backToList();
        showTimestampToastSuccess({
          message: t("leave_editApplication_deleteSuccessMessage"),
          t: t,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(showSpinner(false));
    }
  };

  // Workflow
  const getApplicationWorkflow = async (refNo) => {
    if (isLoadingWorkflow) return;
    setShowWorkflow(true);
    setIsLoadingWorkflow(true);

    if (!applicationWorkflow) {
      let tranRes = await LeaveService.leaveGetLeaveTransactionHeaderbyRefNo({
        referenceNo: refNo,
        employeeCode: form["EmployeeCode"],
      });

      if (tranRes && tranRes.data) {
        setApplicationWorkflow(tranRes.data);
      }
    }

    setIsLoadingWorkflow(false);

    // scroll to section
    let element = document.querySelector(".leave-workflow");
    element.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const getWorkflowRowExpansionData = async (appId) => {
    let tranDetailApi = LeaveService.leaveGetLeaveTransactionDetailbyId({
      applicationId: appId,
      employeeCode: form["EmployeeCode"],
    });
    let tranWorkflowApi = LeaveService.leaveGetLeaveTransactionWorkflowbyId({
      applicationId: appId,
      employeeCode: form["EmployeeCode"],
    });
    let [tranDetailRes, tranWorkflowRes] = await Promise.all([
      tranDetailApi,
      tranWorkflowApi,
    ]);

    let tranData = {
      displayTab: 0,
    };
    if (
      tranDetailRes &&
      tranDetailRes.data &&
      tranWorkflowRes &&
      tranWorkflowRes.data
    ) {
      tranData.detail = tranDetailRes.data;
      tranData.content = tranWorkflowRes.data;
    }

    let workflowDetailData = { ...applicationWorkflowInfo };
    workflowDetailData[appId] = tranData;

    setApplicationWorkflowInfo(workflowDetailData);
  };

  useEffect(() => {
    setIsLoadingWorkflowExpansion(false);
  }, [applicationWorkflowInfo]);

  // Render
  const renderApplicationMeta = () => {
    if (state.isLoading) return null;

    const application = props.application;
    // console.info(application);

    if (!application) return null;
    const _form = initFormWithLeaveApplication(application);

    const metaControls = [
      {
        key: "$Employee", // custom field
        label: t("leave_calendarView_employee"),
        type: BCType.input,
        componentRender: () => (
          <span>{`${application.employeeName} (${application.employeeCode})`}</span>
        ),
      },
      {
        key: "$RefNo", // custom field
        label: t("leave_calendarView_refNo"),
        type: BCType.input,
        componentRender: () => <span>{application.referenceNo}</span>,
      },
      {
        key: "$Status", // custom field
        label: t("leave_calendarView_status"),
        componentRender: () => (
          <>
            <span>{application.status}</span>
            {!approverMode && (
              <span
                className="leave-workflow-btn"
                onClick={() => getApplicationWorkflow(application.referenceNo)}
              >
                ({t("leave_calendarView_workflow_title")})
              </span>
            )}
          </>
        ),
      },
    ];

    const metaLayout = {
      rows: [
        {
          columns: [
            {
              control: "$Employee",
              config: { ...BASIC_CONTROL_LAYOUT_CONFIG },
            },
            {
              control: "$RefNo",
              config: { ...BASIC_CONTROL_LAYOUT_CONFIG },
            },
            {
              control: "$Status",
              config: { ...BASIC_CONTROL_LAYOUT_CONFIG },
            },
          ],
        },
      ],
    };

    return (
      <div className="leave-application-meta">
        {/* Generl Meta */}
        <BaseForm
          config={{
            controls: metaControls,
            layout: metaLayout,
          }}
          form={_form}
          readOnly
        ></BaseForm>
      </div>
    );
  };

  const renderSubmitForm = () => {
    if (state.isLoadingForm) {
      return twoColformSkeleton;
    }

    const controls = mergeControlAndControlConfig(
      formControls,
      formControlConfig
    ).filter((x) => !!x);

    if (props.showSelf === false && Array.isArray(controls)) {
      const index = controls.findIndex((x) => x.key === "IsUserApplication");
      if (index > -1) {
        controls.splice(index, 1);
      }
    }

    if(!viewMode && !approverMode)
    {
      const index = controls.findIndex((x) => x.key === "LeaveCode");
      if (index > -1) {
        controls[index] = {
          ...controls[index],
          config: {
            disabled: disabledLeaveType
          }
        }
      }
    }

    return (
      <>
        {approverMode || viewMode ? renderApplicationMeta() : null}
        <BaseForm
          ref={formRef}
          config={{
            controls: controls,
            layout: formLayout,
          }}
          form={form}
          touched={state.touched}
          onChange={(change) => {
            let _form = change.state.form;
            const _changed = change.changed;
            if (_changed?.control?.key === "IsUserApplication" && !editMode) {
              _form = {
                ...initForm,
                IsUserApplication: _changed?.data?.value,
              };
            }

            if (
              _changed?.control?.key === "EmployeeCode" &&
              !!_changed?.data?.value &&
              !editMode
            ) {
              _form = {
                ...initForm,
                EmployeeCode: _changed?.data?.value,
                IsUserApplication: false,
              };
            }
            setForm({ ..._form });
          }}
        />
      </>
    );
  };

  const renderWorkflow = () => {
    if (state.isLoading || isLoadingWorkflow || !showWorkflow) {
      return null;
    }

    const renderleaveWorkflowColumn = (columns) =>
      columns.map((column) => {
        const columnConfig = {
          field: column.key,
          header: column.header,
          style: column.style,
          body: (data) => (
            <>
              <div className="p-column-title">{column.header}: </div>
              <div className="p-column-value">
                {column.key === "leaveFrom" || column.key === "leaveTo"
                  ? moment(data[column.key]).format("YYYY-MM-DD h:mm A (ddd)")
                  : column.key === "submissionDate" ||
                    column.key === "lastUpdated" ||
                    column.key === "activityUpdated"
                  ? moment(data[column.key]).format("YYYY-MM-DD HH:mm")
                  : data[column.key]}
              </div>
            </>
          ),
        };
        return <Column {...columnConfig} />;
      });

    const leaveWorkflowHeaderColumnConfig = [
      {
        key: "applicationId",
        header: "ID",
      },
      {
        key: "submissionDate",
        header: t("leave_calendarView_workflow_applicationDate"),
      },
      {
        key: "transactionReferenceNo",
        header: t("leave_calendarView_workflow_transactionSequence"),
      },
      {
        key: "transactionType",
        header: t("leave_calendarView_workflow_transactionType"),
      },
      {
        key: "leaveFrom",
        header: t("leave_calendarView_workflow_startDate"),
      },
      {
        key: "leaveTo",
        header: t("leave_calendarView_workflow_endDate"),
      },
      {
        key: "appliedUnits",
        header: t("leave_calendarView_workflow_appliedUnitsInPeriod"),
      },
      {
        key: "committedUnits",
        header: t("leave_calendarView_workflow_approvedUnitsInPeriod"),
      },
      {
        key: "status",
        header: t("leave_calendarView_workflow_status"),
      },
      {
        key: "workflowStatus",
        header: t("leave_calendarView_workflow_workflowStatus"),
      },
      {
        key: "lastUpdated",
        header: t("leave_calendarView_workflow_approvalRejectDate"),
      },
    ];
    const leaveWorkflowDetailColumnConfig = [
      {
        key: "year",
        header: t("leave_calendarView_workflow_year"),
        style: { width: "6rem" },
      },
      {
        key: "period",
        header: t("leave_calendarView_workflow_period"),
        style: { width: "6rem" },
      },
      {
        key: "periodDescription",
        header: t("leave_calendarView_workflow_periodDescription"),
      },
      {
        key: "leaveFrom",
        header: t("leave_calendarView_workflow_startDate"),
      },
      {
        key: "leaveTo",
        header: t("leave_calendarView_workflow_endDate"),
      },
      {
        key: "appliedUnits",
        header: t("leave_calendarView_workflow_appliedUnitsInPeriod"),
      },
      {
        key: "committedUnits",
        header: t("leave_calendarView_workflow_approvedUnitsInPeriod"),
      },
    ];
    const leaveWorkflowContentColumnConfig = [
      {
        key: "activityId",
        header: t("leave_calendarView_workflow_activity"),
        style: { width: "6rem" },
      },
      {
        key: "activityUpdated",
        header: t("leave_calendarView_workflow_updatedOn"),
        style: { width: "10rem" },
      },
      {
        key: "activityDescription",
        header: t("leave_calendarView_workflow_description"),
      },
      {
        key: "userName",
        header: t("leave_calendarView_workflow_employeeName"),
      },
      {
        key: "workflowActivityStatus",
        header: t("leave_calendarView_workflow_status"),
        style: { width: "8rem" },
      },
      {
        key: "comments",
        header: t("leave_calendarView_workflow_comments"),
      },
    ];

    const renderleaveWorkflowHeaderColumn = renderleaveWorkflowColumn(
      leaveWorkflowHeaderColumnConfig
    );
    renderleaveWorkflowHeaderColumn.unshift(
      <Column
        expander
        style={{ width: "3em" }}
        className="workflow-expander-start"
      />
    );
    renderleaveWorkflowHeaderColumn.push(
      <Column
        expander
        style={{ width: "3em" }}
        className="workflow-expander-end"
      />
    );
    const renderLeaveWorkflowDetailColumn = renderleaveWorkflowColumn(
      leaveWorkflowDetailColumnConfig
    );
    const renderLeaveWorkflowContentColumn = renderleaveWorkflowColumn(
      leaveWorkflowContentColumnConfig
    );

    const workflowTabMenu = [
      { label: t("leave_calendarView_workflow_breakdownByPeriods") },
      { label: t("leave_calendarView_workflow_title") },
    ];

    const workflowRowExpansion = (data) => {
      if (!requestedApplicationId.includes(data.applicationId)) {
        const reqAppId = [...requestedApplicationId];
        reqAppId.push(data.applicationId);
        setRequestedApplicationId(reqAppId);
        setIsLoadingWorkflowExpansion(true);
        getWorkflowRowExpansionData(data.applicationId);
      }

      if (isLoadingWorkflowExpansion) {
        return <div>{t("leave_calendarView_workflow_loading")} ...</div>;
      } else if (!applicationWorkflowInfo) {
        return <div>{t("leave_calendarView_workflow_loading")} ...</div>;
      }

      const workflowRowExpansionData =
        applicationWorkflowInfo[data.applicationId];

      return (
        <>
          <TabMenu
            model={workflowTabMenu}
            activeIndex={workflowRowExpansionData?.displayTab}
            className="leave-workflow-tabmenu"
            onTabChange={(e) => {
              let applicationWorkflowInfoRef = { ...applicationWorkflowInfo };
              applicationWorkflowInfoRef[data.applicationId].displayTab =
                e.index;
              setApplicationWorkflowInfo(applicationWorkflowInfoRef);
            }}
          />
          {workflowRowExpansionData?.displayTab === 0 ? (
            <DataTable
              value={workflowRowExpansionData.detail}
              emptyMessage={t("leave_calendarView_workflow_noDetailsFound")}
              className="leave-workflow-detail-table"
            >
              {renderLeaveWorkflowDetailColumn}
            </DataTable>
          ) : null}
          {workflowRowExpansionData?.displayTab === 1 ? (
            <DataTable
              value={workflowRowExpansionData.content}
              emptyMessage={t("leave_calendarView_workflow_noDetailsFound")}
              className="leave-workflow-content-table"
            >
              {renderLeaveWorkflowContentColumn}
            </DataTable>
          ) : null}
        </>
      );
    };

    return (
      <div className="leave-workflow">
        <div className="leave-workflow-title">
          {t("leave_calendarView_workflow_leaveWorkflow")}
        </div>
        <DataTable
          value={applicationWorkflow}
          className="leave-workflow-header-table"
          expandedRows={workflowExpandedRows}
          onRowToggle={(e) => {
            setWorkflowExpandedRows(e.data);
          }}
          rowExpansionTemplate={workflowRowExpansion}
        >
          {renderleaveWorkflowHeaderColumn}
        </DataTable>
      </div>
    );
  };

  const renderHeader = () => {
    let selectedEmployee;
    if (!!form["EmployeeCode"]) {
      selectedEmployee = props?.employeeList?.find(
        (x) => x?.value === form["EmployeeCode"]
      );
    }

    let title;
    if (approverMode) {
      title = t("leave_entitlement_title_approve");
    } else if (viewMode) {
      title = t("leave_entitlement_title_view");
    } else if (!!form["EmployeeCode"] && selectedEmployee) {
      title = t("leave_entitlement_title_applyDelegated", selectedEmployee);
    } else {
      title = t("leave_entitlement_title");
    }
    return (
      <div className="header">
        <LeaveMenu
          menu={leaveMenu}
          currentPageKey={LEAVE_MENU_PAGE_KEY.APPLY_FOR_ENTITLEMENT}
        />
        <div className="title">
          <div
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="footer p-grid p-align-center p-justify-between">
        <div className="left-button-group">
          <Button
            className="p-button-outlined secondary back-button"
            onClick={viewMode ? backToList : confirmBack}
          >
            {t("leave_common_actionBack")}
          </Button>
          {editMode && (
            <Button
              className="p-button-outlined secondary back-button"
              onClick={confirmDeleteDraft}
            >
              {t("leave_common_actionDelete")}
            </Button>
          )}
        </div>
        <div className="right-button-group">
          {!viewMode && !approverMode && form["LeaveCode"] && (
            <>
              <Button className="p-button-outlined secondary" onClick={save}>
                {t("leave_common_actionSave")}
              </Button>
              <Button
                className="p-button"
                onClick={confirmSubmit}
                disabled={!form["Units"] || form["Units"] === 0}
              >
                {t("leave_common_actionSubmit")}
              </Button>
            </>
          )}

          {approverMode && isAllowApproveReject && (
            <>
              <Button
                onClick={() => {
                  confirmApproveApplication({
                    applicationId: props.application.applicationId,
                    date: new Date(props.application.leaveFrom),
                    dispatch,
                    history,
                    t,
                  });
                }}
                className="p-button"
              >
                {t("leave_common_actionApprove")}
              </Button>
              <Button
                onClick={() => {
                  confirmRejectApplication({
                    applicationId: props.application.applicationId,
                    date: new Date(props.application.leaveFrom),
                    dispatch,
                    history,
                    t,
                  });
                }}
                className="p-button"
              >
                {t("leave_common_actionReject")}
              </Button>
            </>
          )}

          {!approverMode &&
            viewMode &&
            props?.application?.cancellable &&
            displayCancelButton && (
              <Button onClick={cancelApplicationModal} className="p-button">
                {t("leave_viewApplication_cancelApplication")}
              </Button>
            )}
        </div>
      </div>
    );
  };

  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          LEAVE_T_CONTEXT_KEY.COMMON,
          LEAVE_T_CONTEXT_KEY.ENTITLEMENT,
          LEAVE_T_CONTEXT_KEY.FORM,
          LEAVE_T_CONTEXT_KEY.CALENDAR_VIEW,
        ]}
      >
        <div className="leave-container leave-submit-application">
          {/* Header */}
          {renderHeader()}
          <div className="main leave-application-form">
            {/* Main */}
            {state.isLoading ? twoColformSkeleton : renderSubmitForm()}

            {/* Workflow */}
            {renderWorkflow()}
          </div>

          {/* Footer */}
          {renderFooter()}
        </div>
      </LanguageLoader>
    </>
  );
};

export default ApplyEntitlement;
