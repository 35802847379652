import "./claim-table.scss";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataTable } from "primereact/datatable";
import { Skeleton } from "primereact/skeleton";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";
import moment from "moment";
import Highlighter from "react-highlight-words";
import { v4 as uuidv4 } from "uuid";

import BaseTable from "../../../../components/base-table/base-table-portal-rental";
import {
  DEFAULT_BT_SEARCH,
  getBTConfig,
  getColumModel,
} from "../../../../components/base-table/base-table-model";
import { CLAIM_STATUS } from "../components";
import { downloadFile } from "../../../../components/base-control/upload-control/utils";
import ClaimStatus from "./claim-status";

const DATE_FORMAT = "yyyy/MM/DD";
const DATETIME_FORMAT = "yyyy/MM/DD HH:mmA";
const DATETIME_CLAIM_FORMAT = "yyyy-MM-DDT00:00:00";

const ClaimTable = (props) => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    // detailExpandedRows: null,
    selectedClaims: [], // for approver upload attachment
    loading: true,
    tableData: [],
    editing: [],
    editedData: {},
  });

  const fileInputRef = React.createRef();

  const roundNumberToDecimalPlacesWithThousandSeparator = (
    num,
    decimalPlaces
  ) => {
    return (
      Math.round(num * Math.pow(10, decimalPlaces)) /
      Math.pow(10, decimalPlaces)
    )
      .toFixed(decimalPlaces)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const summarizedTotal = (data) => {
    let totals = {
      monthlyRentalCap: 0,
      reimbursementAmount: 0,
      actualRentWithGovtRentMgtFee: 0,
      actualRentalAmount: 0,
      govtRentMgtFee: 0,
      lesserOfMonthlyRentalCapAndActualRentWithGovtRentMgtFee: 0,
      salaryOrHousingAllowanceAdjustment: 0,
      totalClaim: 0,
      approvedAmount: 0,
    };

    const getValue = (rowData, field) => {
      return rowData.id in state.editedData &&
        field in state.editedData[rowData.id]
        ? state.editedData[rowData.id][field]
        : rowData[field];
    };

    if (data) {
      data.forEach((x) => {
        totals.monthlyRentalCap += getValue(x, "monthlyRentalCap");
        totals.reimbursementAmount += getValue(x, "reimbursementAmount");
        totals.actualRentWithGovtRentMgtFee += x.actualRentWithGovtRentMgtFee;
        totals.actualRentalAmount += getValue(x, "actualRentalAmount");
        totals.govtRentMgtFee += getValue(x, "govtRentMgtFee");
        totals.lesserOfMonthlyRentalCapAndActualRentWithGovtRentMgtFee +=
          x.lesserOfMonthlyRentalCapAndActualRentWithGovtRentMgtFee;
        totals.salaryOrHousingAllowanceAdjustment +=
          x.salaryOrHousingAllowanceAdjustment;
        totals.totalClaim += getValue(x, "totalClaim");
        totals.approvedAmount += x.approvedAmount;
      });
    }

    return totals;
  };

  const tableSearchFn = async (searchConfig) => {
    searchConfig = DEFAULT_BT_SEARCH;

    const data = props.data ? props.data : [];

    let summarizedRecords = [];
    summarizedRecords = data.map((x) => {
      let record = { ...x };
      record.claimPeriodFromTo = [
        moment(record.claimPeriodFrom).format(DATE_FORMAT),
        " - ",
        moment(record.claimPeriodTo).format(DATE_FORMAT),
      ].join("");

      if (props.approvalMode && props.claimChange) {
        if (Object.keys(props.claimChange).includes(x.id.toString())) {
          const change = props.claimChange[x.id];
          record = {
            ...record,
            ...change,
            attachmentFiles: change.attachmentFiles?.length
              ? [...record.attachmentFiles, ...change.attachmentFiles]
              : record.attachmentFiles,
            changed: true,
          };
        }
      }

      // static actions
      if (x.isAllowApproval) {
        const staticActions = ["Edit", "Download"];
        record.actions = x.actions
          ? [...x.actions, ...staticActions]
          : staticActions;
      }
      return record;
    });

    if (summarizedRecords.length > 0) {
      const totals = summarizedTotal(summarizedRecords);
      const summary = {
        id: "summary",
        monthlyRentalCap: totals.monthlyRentalCap,
        reimbursementAmount: totals.reimbursementAmount,
        actualRentWithGovtRentMgtFee: totals.actualRentWithGovtRentMgtFee,
        actualRentalAmount: totals.actualRentalAmount,
        govtRentMgtFee: totals.govtRentMgtFee,
        lesserOfMonthlyRentalCapAndActualRentWithGovtRentMgtFee:
          totals.lesserOfMonthlyRentalCapAndActualRentWithGovtRentMgtFee,
        salaryOrHousingAllowanceAdjustment:
          totals.salaryOrHousingAllowanceAdjustment,
        totalClaim: totals.totalClaim,
        approvedAmount: totals.approvedAmount,
      };
      summarizedRecords.push(summary);
    }

    return {
      datas: summarizedRecords,
      total: summarizedRecords.length,
      searchConfig: searchConfig,
    };
  };

  const init = async () => {
    const res = await tableSearchFn();
    let resData = res.datas.slice(0, -1);
    setState({ ...state, loading: false, tableData: resData });
  };

  useEffect(() => {
    setState({ ...state, loading: true });
    init();
  }, [props.data]);

  // changes
  const updateChanges = (editedData) => {
    setState({ ...state, editedData: editedData });

    const parsedData = {};
    Object.keys(editedData).forEach((id) => {
      let data = {};
      Object.keys(editedData[id])
        .filter(
          (k) =>
            ![
              "claimPeriodFromObject",
              "claimPeriodToObject",
              "totalClaim",
              "approvedAmount",
              "salaryOrHousingAllowanceAdjustment",
            ].includes(k)
        )
        .forEach((k) => {
          data[k] = editedData[id][k];
        });
      parsedData[id] = data;
    });
    if (props.changeHandler) {
      props.changeHandler(parsedData);
    }
  };

  const recordChanged = (id, field) => {
    if (!id || !state.editedData) return false;

    if (id.toString() in state.editedData) {
      if (field === "row" || field in state.editedData[id]) {
        return true;
      }
    }

    return false;
  };

  const configColumns = [
    getColumModel({
      header: t("rental_datatable_period"),
      key: "claimPeriodFromTo",
      dataType: "string",
      sortable: false,
      width: 0.1,
      isRowExpansion: false,
      isHeaderExpansion: true,
      mobileWidthAuto: true,
      render: (data) => (
        <>
          <ClaimStatus
            status={
              recordChanged(data.id, "status")
                ? state.editedData[data.id].status
                : data.status
            }
            showText={false}
          />
          <span>{data.claimPeriodFromTo}</span>
        </>
      ),
    }),
    getColumModel({
      header: t("rental_datatable_month"),
      key: "month",
      dataType: "string",
      frozen: props.approvalMode ? false : true,
      sortable: false,
      filter: true,
      width: 5.5,
      isRowExpansion: true,
      isHeaderExpansion: false,
      hideInMobile: true,
      mobileWidthAuto: true,
      cellContentAlignTop: true,
    }),
    getColumModel({
      header:
        true || props.approvalMode // force approval mode
          ? t("rental_approval_datatable_claimPeriodFrom")
          : t("rental_datatable_claimPeriodFrom"),
      key: "claimPeriodFrom",
      dataType: "string",
      sortable: false,
      filter: true,
      width: 12,
      isRowExpansion: true,
      isHeaderExpansion: false,
      hideInMobile: true,
      mobileWidthAuto: true,
      cellContentAlignTop: true,
      render: (data) =>
        moment(data.claimPeriodFrom).isValid() && data.id !== "summary" ? (
          state.editing.includes(data.id) ? (
            <Calendar
              dateFormat="yy/mm/dd"
              value={state.editedData[data.id].claimPeriodFromObject}
              onChange={(e) => {
                let editedData = { ...state.editedData };
                editedData[data.id].claimPeriodFrom = moment(e.value).format(
                  DATETIME_CLAIM_FORMAT
                );
                editedData[data.id].claimPeriodFromObject = e.value;
                updateChanges(editedData);
              }}
            />
          ) : (
            moment(data.claimPeriodFrom).format(DATE_FORMAT)
          )
        ) : (
          data.claimPeriodFrom
        ),
    }),
    getColumModel({
      header:
        true || props.approvalMode // force approval mode
          ? t("rental_approval_datatable_claimPeriodTo")
          : t("rental_datatable_claimPeriodTo"),
      key: "claimPeriodTo",
      dataType: "string",
      sortable: false,
      filter: true,
      width: 12,
      isRowExpansion: true,
      isHeaderExpansion: false,
      hideInMobile: true,
      mobileWidthAuto: true,
      cellContentAlignTop: true,
      render: (data) =>
        moment(data.claimPeriodTo).isValid() && data.id !== "summary" ? (
          state.editing.includes(data.id) ? (
            <Calendar
              dateFormat="yy/mm/dd"
              value={state.editedData[data.id].claimPeriodToObject}
              onChange={(e) => {
                let editedData = { ...state.editedData };
                editedData[data.id].claimPeriodTo = moment(e.value).format(
                  DATETIME_CLAIM_FORMAT
                );
                editedData[data.id].claimPeriodToObject = e.value;
                updateChanges(editedData);
              }}
            />
          ) : (
            moment(data.claimPeriodTo).format(DATE_FORMAT)
          )
        ) : (
          data.claimPeriodTo
        ),
    }),
  ];

  const statusColumn = getColumModel({
    header: t("rental_datatable_status"),
    key: "status",
    dataType: "string",
    sortable: false,
    filter: true,
    width: 10,
    isRowExpansion: true,
    isHeaderExpansion: false,
    hideInMobile: true,
    mobileWidthAuto: true,
    cellContentAlignTop: true,
    render: (data) => (
      <ClaimStatus
        status={
          recordChanged(data.id, "status")
            ? state.editedData[data.id].status
            : data.status
        }
      />
    ),
  });

  const attachmentColumn = getColumModel({
    header:
      true || props.approvalMode // force approval mode
        ? t("rental_approval_datatable_attachment")
        : t("rental_datatable_attachment"),
    key: "attachmentFiles",
    dataType: "string",
    sortable: false,
    filter: true,
    width: 12,
    isRowExpansion: true,
    isHeaderExpansion: false,
    hideInMobile: true,
    mobileWidthAuto: true,
    cellContentAlignTop: true,
    render: (data) => {
      const activeRow =
        !Object.keys(data).includes("enable") ||
        (Object.keys(data).includes("enable") && data["enable"]);

      const attachmentFiles = state.editedData?.[data.id]?.attachmentFiles
        ? data.attachmentFiles.concat(state.editedData[data.id].attachmentFiles)
        : data.attachmentFiles;

      if (data.id === "summary") {
        return (
          <span>
            <b>{t("rental_datatable_total")}</b>
          </span>
        );
      } else if (attachmentFiles && attachmentFiles.length > 0) {
        return attachmentFiles.map((f) => {
          if (
            (props.attachmentDeletable && activeRow) ||
            (props.approvalMode && f.uploadId == null)
          ) {
            return (
              <div
                className="attachment-block"
                onClick={(e) => {
                  if (
                    e.target.classList.contains("attachment-block") ||
                    e.target.classList.contains("attachment-filename")
                  ) {
                    if (f.fileId != null) {
                      downloadFile({
                        id: f.fileId,
                        name: f.fileName || f.name,
                      });
                    } else {
                      downloadFile(f);
                    }
                  }
                }}
              >
                <div className="attachment-filename">
                  {f.fileName || f.name}
                </div>
                {/* <span
                  className="pi pi-times-circle attachment-delete-btn"
                  onClick={() => {
                    if (props.approvalMode) {
                      deleteFileHandler({
                        claimData: data,
                        fileId: f.fileId,
                        fileName: f.fileName || f.name,
                        file: f,
                      });
                    } else {
                      props.deleteAttachment({
                        claimData: data,
                        fileId: f.fileId,
                        fileName: f.fileName || f.name,
                        file: f,
                      });
                    }
                  }}
                /> */}
              </div>
            );
          } else {
            return (
              <div
                className="attachment-block"
                onClick={(e) => {
                  if (f.fileId != null) {
                    downloadFile({
                      id: f.fileId,
                      name: f.fileName || f.name,
                    });
                  } else {
                    downloadFile(f);
                  }
                }}
              >
                <div className="attachment-filename">
                  {f.fileName || f.name}
                </div>
              </div>
            );
          }
        });
      }

      return <span>-</span>;
    },
  });

  const lastUpdateColumn = getColumModel({
    header: t("rental_datatable_lastUpdated"),
    lastUpdated: "claimPeriodFrom",
    dataType: "string",
    sortable: false,
    filter: true,
    width: 15,
    isRowExpansion: true,
    isHeaderExpansion: false,
    hideInMobile: true,
    mobileWidthAuto: true,
    cellContentAlignTop: true,
    render: (data) =>
      moment(data.lastUpdated).isValid() && data.id !== "summary"
        ? moment(data.lastUpdated).format(DATETIME_FORMAT)
        : data.lastUpdated,
  });

  const configAmountColumn = (column) =>
    getColumModel({
      header: column.header,
      key: column.key,
      dataType: "string",
      sortable: false,
      filter: true,
      width: column.width,
      isRowExpansion: true,
      isHeaderExpansion: false,
      hideInMobile: true,
      mobileWidthAuto: true,
      cellContentAlignTop: true,
      render: (data) => (
        <span>
          {data.id === "summary" ? (
            <b>{`$${roundNumberToDecimalPlacesWithThousandSeparator(
              summarizedTotal(state.tableData)[column.key],
              2
            )}`}</b>
          ) : state.editing.includes(data.id) &&
            [
              "monthlyRentalCap",
              "reimbursementAmount",
              "actualRentalAmount",
              "govtRentMgtFee",
            ].includes(column.key) ? (
            <InputNumber
              value={state.editedData[data.id][column.key]}
              // mode="currency"
              // currency="USD"
              prefix="$"
              minFractionDigits={2}
              onValueChange={(e) => {
                let editedData = { ...state.editedData };
                editedData[data.id][column.key] = e.value;
                updateChanges(editedData);
              }}
            />
          ) : (
            `$${roundNumberToDecimalPlacesWithThousandSeparator(
              recordChanged(data.id, column.key)
                ? state.editedData[data.id][column.key]
                : data[column.key],
              2
            )}`
          )}
        </span>
      ),
    });

  const monthlyRentalCapColumn = {
    header:
      true || props.approvalMode // force approval mode
        ? t("rental_approval_datatable_monthlyRentalCap")
        : t("rental_datatable_monthlyRentalCap"),
    key: "monthlyRentalCap",
    width: 10,
  };

  const reimbursementAmountColumn = {
    header:
      true || props.approvalMode // force approval mode
        ? t("rental_approval_datatable_reimbursementAmount")
        : t("rental_datatable_reimbursementAmount"),
    key: "reimbursementAmount",
    width: 10,
  };

  const actualRentWithGovtRentMgtFeeColumn = {
    header: t("rental_datatable_actualRentWithGovtRentMgtFee"),
    key: "actualRentWithGovtRentMgtFee",
    width: 10,
  };

  const actualRentalAmountColumn = {
    header:
      true || props.approvalMode // force approval mode
        ? t("rental_approval_datatable_actualRentalAmount")
        : t("rental_datatable_actualRentalAmount"),
    key: "actualRentalAmount",
    width: 10,
  };

  const govtRentMgtFeeColumn = {
    header:
      true || props.approvalMode // force approval mode
        ? t("rental_approval_datatable_govtRentMgtFee")
        : t("rental_datatable_govtRentMgtFee"),
    key: "govtRentMgtFee",
    width: 10,
  };

  const lesserOfMonthlyRentalCapAndActualRentWithGovtRentMgtFeeColumn = {
    header: t(
      "rental_datatable_lesserOfMonthlyRentalCapAndActualRentWithGovtRentMgtFee"
    ),
    key: "lesserOfMonthlyRentalCapAndActualRentWithGovtRentMgtFee",
    width: 12,
  };

  const salaryOrHousingAllowanceAdjustmentColumn = {
    header:
      true || props.approvalMode // force approval mode
        ? t("rental_approval_datatable_salaryOrHousingAllowanceAdjustment")
        : t("rental_datatable_salaryOrHousingAllowanceAdjustment"),
    key: "salaryOrHousingAllowanceAdjustment",
    width: 12,
  };

  const totalClaimColumn = {
    header: t("rental_datatable_totalClaim"),
    key: "totalClaim",
    width: 10,
  };

  const approvedAmountColumn = {
    header: t("rental_approval_datatable_approvedAmount"),
    key: "approvedAmount",
    width: 10,
  };

  let columnList = [];
  if (props.approvalMode) {
    configColumns.unshift(
      getColumModel({
        header: "",
        key: "head-column-dummy",
        dataType: "string",
        frozen: true,
        sortable: false,
        filter: true,
        width: 0.1,
        isRowExpansion: false,
        isHeaderExpansion: true,
        mobileWidthAuto: true,
        hideInMobile: true,
        render: (data) => <></>,
      })
    );
    columnList = [
      statusColumn, // 4
      attachmentColumn, // 5
      configAmountColumn(monthlyRentalCapColumn), // 6
      configAmountColumn(reimbursementAmountColumn), // 7
      configAmountColumn(actualRentalAmountColumn), // 8
      configAmountColumn(govtRentMgtFeeColumn), // 9
      configAmountColumn(totalClaimColumn), // 10
      configAmountColumn(approvedAmountColumn), // 11
      configAmountColumn(salaryOrHousingAllowanceAdjustmentColumn), // 12
      lastUpdateColumn, // 13
    ];
  } else {
    columnList = [
      // In case user wants to change again for the 4th time
      // statusColumn, // 4
      // attachmentColumn, // 5
      // configAmountColumn(monthlyRentalCapColumn), // 6
      // configAmountColumn(reimbursementAmountColumn), // 7
      // configAmountColumn(actualRentWithGovtRentMgtFeeColumn), // 8
      // configAmountColumn(actualRentalAmountColumn), // 9
      // configAmountColumn(govtRentMgtFeeColumn), //10
      // configAmountColumn(
      //   lesserOfMonthlyRentalCapAndActualRentWithGovtRentMgtFeeColumn
      // ), // 11
      // configAmountColumn(salaryOrHousingAllowanceAdjustmentColumn), // 12
      statusColumn, // 4
      attachmentColumn, // 5
      configAmountColumn(monthlyRentalCapColumn), // 6
      configAmountColumn(reimbursementAmountColumn), // 7
      configAmountColumn(actualRentalAmountColumn), // 8
      configAmountColumn(govtRentMgtFeeColumn), // 9
      configAmountColumn(totalClaimColumn), // 10
      configAmountColumn(approvedAmountColumn), // 11
      configAmountColumn(salaryOrHousingAllowanceAdjustmentColumn), // 12
      lastUpdateColumn, // 13
    ];
  }

  columnList.forEach((c) => {
    configColumns.push(c);
  });

  const actionHandler = (data, actionType) => {
    let claims = [];
    if (!data) {
      return;
    } else if (!Array.isArray(data)) {
      claims.push(data);
    } else {
      claims = data.filter((r) => r.isAllowApproval);
    }

    let editedData = { ...state.editedData };
    claims.forEach((c) => {
      const id = c.id;
      let updatedData = {};
      updatedData[id] = id.toString() in editedData ? editedData[id] : {};

      const recordIndex = state.tableData.findIndex((d) => d.id === id);
      const record = state.tableData[recordIndex];

      // reset updatedData
      delete updatedData[id].totalClaim;
      delete updatedData[id].approvedAmount;
      delete updatedData[id].salaryOrHousingAllowanceAdjustment;

      // updateData
      updatedData[id].status = actionType;
      if (actionType === CLAIM_STATUS.VERIFIED) {
        updatedData[id].totalClaim =
          record.totalClaim - record.salaryOrHousingAllowanceAdjustment;
        updatedData[id].approvedAmount =
          record.totalClaim - record.salaryOrHousingAllowanceAdjustment;
        updatedData[id].salaryOrHousingAllowanceAdjustment = 0;
      }
      if (actionType === CLAIM_STATUS.REJECTED) {
        updatedData[id].totalClaim = 0;
        updatedData[id].approvedAmount = 0;
        updatedData[id].salaryOrHousingAllowanceAdjustment =
          0 - record.totalClaim;
      }

      editedData = { ...editedData, ...updatedData };
    });

    updateChanges(editedData);
  };

  const downloadHandler = (data) => {
    let claims = [];
    if (!data) {
      return;
    } else if (!Array.isArray(data)) {
      claims.push(data);
    } else {
      claims = data.filter((r) => r.isAllowApproval);
    }

    claims.forEach((c) => {
      c.attachmentFiles.forEach((uploadedFile) => {
        downloadFile({
          id: uploadedFile.fileId,
          name: uploadedFile.fileName,
        });
      });
      state.editedData[c.id]?.attachmentFiles?.forEach((f) => {
        downloadFile(f);
      });
    });
  };

  const uploadHandler = (e) => {
    let targetFiles = e.target.files;

    if (!targetFiles || targetFiles.length <= 0) {
      return;
    }

    targetFiles = Array.from(targetFiles);
    targetFiles.forEach((x, idx) => {
      targetFiles[idx].customFileId = uuidv4();
    });
    if (!props.validateFiles || props.validateFiles(targetFiles)) {
      // set files into change
      let targetClaims = state.selectedClaims;
      if (!Array.isArray(targetClaims)) {
        targetClaims = [targetClaims];
      }
      targetClaims = targetClaims.filter((c) => c.isAllowApproval);

      let changes = targetClaims.reduce((acc, curr) => {
        acc[curr.id] = {
          attachmentFiles: [
            ...curr.attachmentFiles.filter((f) => f.uploadId == null),
            ...targetFiles,
          ],
        };
        return acc;
      }, {});

      let _editedData = { ...state.editedData };
      targetClaims.forEach((claim) => {
        if (claim.id in _editedData) {
          _editedData[claim.id] = {
            ..._editedData[claim.id],
            attachmentFiles: _editedData[claim.id].attachmentFiles
              ? [..._editedData[claim.id].attachmentFiles, ...targetFiles]
              : targetFiles,
          };
        } else {
          _editedData[claim.id] = {
            attachmentFiles: targetFiles,
          };
        }
      });
      updateChanges(_editedData);
    } else {
      props.onFileError?.({ defaultMessage: t("base_control_upload_error") });
    }
  };

  function isFileEqual(f1, f2) {
    return (
      f1.name === f2.name &&
      f1.lastModified === f2.lastModified &&
      f1.size === f2.size
    );
  }

  const deleteFileHandler = ({ claimData, fileId, fileName, file }) => {
    const claimId = claimData.id;
    let _editedData = { ...state.editedData };
    _editedData[claimId].attachmentFiles = _editedData[
      claimId
    ].attachmentFiles?.filter((f) => !isFileEqual(f, file));
    updateChanges(_editedData);
  };

  const configModel = getBTConfig({
    emptyMessage: t("base_table_no_results"),
    columns: configColumns,
    actionsSingle: [],
    actionsSingleConfig: {
      isRowExpansion: false,
      isHeaderExpansion: true,
    },
    actionWidth: 5.3,
    hasIndex: false,
    mode: "list",
    defaultMode: "list",
    showGlobal: true,
    isExpandable: true,
    isExpandInMobileOnly: true,
    searchForm: false,
    paginator: null,
    style: {
      minHeight: "100px",
    },
    actionsMulti: props.approvalMode ? [{}] : null,
    actionsSingle: props.approvalMode
      ? [
          {
            isOverflow: true,
            name: "Return_Claim",
            title: t("rental_approveApp_actionReturn"),
            isGroupAction: true,
            clickFn: (data) => {
              actionHandler(data, CLAIM_STATUS.RETURNED);
            },
          },
          {
            isOverflow: true,
            name: "Approve_Claim",
            title: t("rental_approveApp_actionVerify"),
            isGroupAction: true,
            clickFn: (data) => {
              actionHandler(data, CLAIM_STATUS.VERIFIED);
            },
          },
          {
            isOverflow: true,
            name: "Reject_Claim",
            title: t("rental_approveApp_actionReject"),
            isGroupAction: true,
            clickFn: (data) => {
              actionHandler(data, CLAIM_STATUS.REJECTED);
            },
          },
          {
            isOverflow: true,
            name: "Edit",
            title: t("rental_approveApp_actionEdit"),
            isGroupAction: false,
            clickFn: (data) => {
              let editing = [...state.editing];
              let editedData = { ...state.editedData };
              const id = data.id;
              let updatedDataObj = {};
              let editableDataObj = {};
              updatedDataObj =
                id.toString() in editedData ? editedData[id] : {};

              if (editing.filter((e) => e === id).length === 0) {
                editing.push(id);
                editableDataObj = {
                  claimPeriodFrom: data.claimPeriodFrom,
                  claimPeriodFromObject: new Date(data.claimPeriodFrom),
                  claimPeriodTo: data.claimPeriodTo,
                  claimPeriodToObject: new Date(data.claimPeriodTo),
                  monthlyRentalCap: data.monthlyRentalCap,
                  reimbursementAmount: data.reimbursementAmount,
                  actualRentalAmount: data.actualRentalAmount,
                  govtRentMgtFee: data.govtRentMgtFee,
                };

                let dataObj = { ...updatedDataObj, ...editableDataObj };
                editedData[id] = dataObj;

                setState({
                  ...state,
                  editing: editing,
                  editedData: editedData,
                });
              }
            },
          },
          {
            isOverflow: true,
            name: "Download",
            title: t("rental_approveApp_downloadAttachments"),
            isGroupAction: true,
            clickFn: (data) => {
              downloadHandler(data);
            },
          },
          {
            isOverflow: true,
            name: "Submit_Claims",
            title: t("rental_approveApp_uploadAttachments"),
            isGroupAction: true,
            clickFn: (data) => {
              setState((state) => ({
                ...state,
                selectedClaims: Array.isArray(data) ? data : [data],
              }));
              fileInputRef.current.click();
            },
          },
        ]
      : null,
    expandedRows: state.expandedRows,
    onRowToggle: (e) => {
      let newState = {
        ...state,
        expandedRows: e.data,
      };
      setState(newState);
    },
    rowClassName: (data) => {
      if (recordChanged(data.id, "row")) {
        return {
          "changed-row": true,
        };
      }
      if (data.id === "summary") {
        return {
          "summary-row": true,
        };
      }
      return {};
    },
    rowExpansionTemplate: (data) => {
      let _data = [];
      _data.push(data);
      const summaryContent = [];

      let tableColumnsOption = configColumns.map((x, index) => {
        return {
          ...x,
          className: x?.frozen || x?.onTop ? "bt-frozen" : "",
          disabled: x?.frozen,
          isDefaultHide: x?.frozen,
          index,
        };
      });
      let tableColumns = tableColumnsOption.filter(
        (x) => !x.isDefaultHide || x?.frozen || x.onTop
      );

      const renderTableCell = (rowData, column) => {
        if (column.render) {
          return <div className="bt-cell-render">{column.render(rowData)}</div>;
        } else {
          let value = rowData[column.key];
          let type = column.dataType;
          switch (type) {
            case "date":
              if (value) return moment(new Date(value)).format("DD/MM/YYYY");
              return "";
            case "boolean":
              return value.toString() === "true"
                ? t("base_table_true")
                : t("base_table_false");
            default:
              if (!column.excludeGlobalFilter) {
                return (
                  <>
                    <Highlighter
                      highlightClassName="custom-highlighter"
                      searchWords={[state.table?.searchConfig.filterText]}
                      autoEscape={true}
                      textToHighlight={value?.toString() || ""}
                    ></Highlighter>
                  </>
                );
              } else {
                return value;
              }
          }
        }
      };

      const renderTableRow = () => {
        let columns = tableColumns
          ? tableColumns.filter((x) => x.isRowExpansion && !x.hideMobile)
          : [];

        const columnsRender = [];
        columns.forEach((column, index) => {
          let filterElement;
          let filterFunction = column.filterFunction;

          if (!column.width && column.frozen) {
            column.width = 10;
          }

          //expander
          const columnConfig = {
            filterElement,
            filterFunction,
            ...column.columnConfig,
            key: `bt-column-${index}`,
            field: column.key,
            header: column.header,
            frozen: false,
            filter: null,
            showFilter: true,
            sortable: column.sortable,
            style: {
              width: column.width ? `${column.width}rem` : "auto",
            },
            headerStyle: {
              width: column.width ? `${column.width}rem` : "auto",
            },
            className: `bt-${column.key} table-expandable-header`,
            body: (rowData, config) => {
              if (state.loading) {
                return <Skeleton></Skeleton>;
              } else {
                return (
                  <div className={`bt-row bt-row-${config.rowIndex} bt-cell`}>
                    <span className="p-column-title">{column.header}:</span>
                    <div className="bt-cell-value">
                      {renderTableCell(rowData, column)}
                    </div>
                  </div>
                );
              }
            },
          };
          columnsRender.push(<Column {...columnConfig} />);
        });

        return columnsRender;
      };

      return (
        <DataTable
          key="info-table"
          value={_data}
          className="p-datatable-responsive claim-table-mobile"
        >
          {renderTableRow()}
        </DataTable>
      );
    },
  });

  const renderSummarySection = () => {
    const renderSummaryRow = () => {
      const summaryRow = [];
      const totals = summarizedTotal(state.tableData);
      const columns = [
        {
          title: t("rental_datatable_monthlyRentalCap"),
          value: `$${roundNumberToDecimalPlacesWithThousandSeparator(
            totals.monthlyRentalCap,
            2
          )}`,
        },
        {
          title: t("rental_datatable_reimbursementAmount"),
          value: `$${roundNumberToDecimalPlacesWithThousandSeparator(
            totals.reimbursementAmount,
            2
          )}`,
        },
        {
          title: t("rental_datatable_actualRentWithGovtRentMgtFee"),
          value: `$${roundNumberToDecimalPlacesWithThousandSeparator(
            totals.actualRentWithGovtRentMgtFee,
            2
          )}`,
        },
        {
          title: t("rental_datatable_actualRentalAmount"),
          value: `$${roundNumberToDecimalPlacesWithThousandSeparator(
            totals.actualRentalAmount,
            2
          )}`,
        },
        {
          title: t("rental_datatable_govtRentMgtFee"),
          value: `$${roundNumberToDecimalPlacesWithThousandSeparator(
            totals.govtRentMgtFee,
            2
          )}`,
        },
        {
          title: t(
            "rental_datatable_lesserOfMonthlyRentalCapAndActualRentWithGovtRentMgtFee"
          ),
          value: `$${roundNumberToDecimalPlacesWithThousandSeparator(
            totals.lesserOfMonthlyRentalCapAndActualRentWithGovtRentMgtFee,
            2
          )}`,
        },
        {
          title: t("rental_datatable_salaryOrHousingAllowanceAdjustment"),
          value: `$${roundNumberToDecimalPlacesWithThousandSeparator(
            totals.salaryOrHousingAllowanceAdjustment,
            2
          )}`,
        },
      ];

      columns.forEach((c) => {
        summaryRow.push(
          <td
            role="cell"
            className="bt-claimSummaryRow"
            style={{ width: "10rem" }}
          >
            <div className="bt-row bt-row-0 bt-cell">
              <span className="p-column-title">{c.title}</span>
              <div className="bt-cell-value">
                <div className="bt-cell-render">
                  <span>
                    <b>{c.value}</b>
                  </span>
                </div>
              </div>
            </div>
          </td>
        );
      });

      return summaryRow;
    };

    return (
      <div className="bt-table bt-table-custom claim-summary-row-table">
        <div className="p-datatable p-component datatable-responsive">
          <table role="grid" className="mobile-expander-header-style">
            <thead className="p-datatable-thead">
              <tr role="row">
                <th
                  role="columnheader"
                  className="p-sortable-disabled hris-bt-expander-column"
                >
                  <span className="p-column-title"></span>
                </th>
                <th
                  role="columnheader"
                  className="p-sortable-disabled bt-month table-expandable-header"
                >
                  <span className="p-column-title">
                    {t("rental_datatable_total")}
                  </span>
                </th>
                <th
                  role="columnheader"
                  className="p-sortable-disabled bt-content-dummy"
                >
                  <span className="p-column-title"></span>
                </th>
              </tr>
            </thead>
            <tbody className="p-datatable-tbody">
              <tr role="row" className="p-row-expanded">
                <td role="cell" colSpan="3">
                  <div className="p-datatable p-component p-datatable-responsive">
                    <table role="grid">
                      <tbody className="p-datatable-tbody">
                        <tr role="row">{renderSummaryRow()}</tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  let printConfigModel = JSON.parse(JSON.stringify(configModel));
  printConfigModel.onRowToggle = configModel.onRowToggle;
  printConfigModel.rowClassName = configModel.rowClassName;
  printConfigModel.rowExpansionTemplate = configModel.rowExpansionTemplate;
  printConfigModel.columns = printConfigModel.columns.map((c, i) => {
    return {
      ...c,
      width: "10%",
      render: configModel.columns[i].render,
    };
  });

  if (props.approvalMode) {
    printConfigModel.columns = printConfigModel.columns.slice(1);
    printConfigModel.actionsMulti = null;
    printConfigModel.actionsSingle = null;
  } else {
    printConfigModel.columns[0].frozen = false;
  }

  if (state.loading) {
    return null;
  } else {
    return (
      <div className="claim-summary-table">
        <div className="claim-summary-table-display">
          <BaseTable
            key="claim-summary-table"
            configModel={configModel}
            searchFn={tableSearchFn}
            isClientSize={true}
            approvalMode={props.approvalMode}
            selectableKey="isAllowApproval"
            inactiveKey="enable"
            onlyCheckboxSelection={true}
          />
        </div>
        <div className="claim-summary-table-print">
          <BaseTable
            key="claim-summary-table-print"
            configModel={printConfigModel}
            searchFn={tableSearchFn}
            isClientSize={true}
            approvalMode={props.approvalMode}
            selectableKey="isAllowApproval"
            inactiveKey="enable"
          />
        </div>
        {renderSummarySection()}
        <input
          hidden
          type="file"
          accept={props.attachmentConfig?.allowedFileExt}
          ref={fileInputRef}
          multiple={true}
          onChange={uploadHandler}
        />
      </div>
    );
  }
};
export default ClaimTable;
