import "./date-control.scss";

import {
  BCProps,
  BCStates,
  isError,
  renderError,
  validateControl,
} from "./../base-control";
import React, { useEffect, useRef, useState } from "react";
import { getDate, jsonEqual, sleep } from "./../../../utils/utils";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Skeleton } from "primereact/skeleton";

export interface CalenderControlAction {
  /**
   * Max length
   */
  label?: string;
  /**
   * Max length
   */
  icon?: string;
  /**
   * Max length
   */
  tooltip?: string;
  /**
   * Max length
   */
  clickFn?: () => any;
  /**
   * Max length
   */
  className?: string;
  /**
   * onMouseDown
   */
  onMouseDown?: () => any; //aw
  /**
   * onMouseLeave
   */
  onMouseLeave?: () => any; //aw
  /**
   * onTouchStart
   */
  onTouchStart?: () => any; //aw
  /**
   * onTouchEnd
   */
  onTouchEnd?: () => any; //aw
  /**
   * onMouseUp
   */
  onMouseUp?: () => any; //aw
  /**
   * onTouchMove
   */
  onTouchMove?: () => any; //aw
}

export interface DateControlProps extends BCProps {
  /**
   * Date Format for prime react component
   */
  dateFormat?: string;
  /**
   * Include timezone
   */
  includeTimeZone?: boolean;
  /**
   * Action
   */
  action?: CalenderControlAction; //aw
}

export interface DateControlState extends BCStates {}
const DATE_FORMART = "dd/mm/yy";
const DateControl: React.FC<DateControlProps> = (props) => {
  const { t } = useTranslation();
  // extract props data
  const id = props.id || "";
  const ruleList = props.ruleList || [];
  if (props.required) {
    ruleList.push({
      name: "required",
    });
  }

  let value;
  let valueStr = "";
  if (props.value) {
    try {
      value = getDate(props.value).toDate();
      valueStr = moment(value).format("DD/MM/YYYY");
    } catch (error) {}
  }

  // init state control
  let initState: DateControlState = {
    touched: false,
    loading: true,
    value,
    valueStr,
    controlState: {
      invalid: false,
    },

    viewDate: props.value ? moment(props.value).toDate() : new Date(),
  };
  initState.controlState =
    props.controlState ||
    validateControl(ruleList || [], initState.value, t, "date");
  const [state, setState] = useState(initState);
  const mountedRef = useRef(true);
  // unsubcribe
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);
  // Update state if control state changed
  useEffect(() => {
    const getData = async () => {
      await sleep(1);
      const ruleList = props.ruleList || [];
      if (props.required) {
        ruleList.push({
          name: "required",
        });
      }
      let value;
      let valueStr = "";
      if (props.value) {
        try {
          value = getDate(props.value).toDate();
          valueStr = moment(value).format("DD/MM/YYYY");
        } catch (error) {}
      }
      let controlState =
        props.controlState || validateControl(ruleList || [], value, t, "date");
      if (!mountedRef.current) return;
      setState({
        ...state,
        loading: false,
        value,
        valueStr,
        controlState,
      });
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.controlState]);

  const convertPrimeDateFormatToMoment = (dateFormat: string) => {
    if (dateFormat.includes("yy")) {
      dateFormat = dateFormat.replace("yy", "YYYY");
    } else if (dateFormat.includes("y")) {
      dateFormat = dateFormat.replace("y", "YY");
    }

    if (dateFormat.includes("mm")) {
      dateFormat = dateFormat.replace("mm", "MM");
    } else if (dateFormat.includes("MM")) {
      dateFormat = dateFormat.replace("MM", "MMMM");
    }

    if (dateFormat.includes("dd")) {
      dateFormat = dateFormat.replace("dd", "DD");
    }

    return dateFormat;
  };

  useEffect(() => {
    const getData = async () => {
      await sleep(1);
      const ruleList = props.ruleList || [];
      if (props.required) {
        ruleList.push({
          name: "required",
        });
      }
      let value;
      let valueStr = "";
      let viewDate = new Date(state.viewDate);
      let printValueStr = "";
      if (props.value) {
        try {
          value = getDate(props.value).toDate();
          valueStr = moment(value).format("DD/MM/YYYY");
          viewDate = new Date(value);
          let dateFormat = props.dateFormat ? props.dateFormat : DATE_FORMART;
          let dateStringFormat = convertPrimeDateFormatToMoment(dateFormat);
          printValueStr = moment(value).format(dateStringFormat);
        } catch (error) {}
      }
      let controlState = validateControl(ruleList || [], value, t, "date");
      if (!mountedRef.current) return;
      setState({
        ...state,
        loading: false,
        value,
        valueStr,
        controlState,
        viewDate,
        printValueStr,
        lastDefault: props.defaultValue,
      });
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.required, props.value]);

  useEffect(() => {
    if (
      props.defaultValue &&
      props.defaultValue?.getTime() !== state.lastDefault?.getTime()
    ) {
      onChange(props.defaultValue, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultValue, state.lastDefault]);

  useEffect(() => {
    setViewDate(props.config?.viewDate);
  }, [props.config?.viewDate]);

  const setViewDate = (viewDate: any) => {
    setState((state) => ({
      ...state,
      viewDate,
    }));
  };

  const onChange = async (value: any, updateLastDefault = false) => {
    const valueStr = props.config?.timeOnly
      ? moment(value).format(props.dateFormat)
      : moment(value).format("DD/MM/YYYY");
    if (!props.includeTimeZone && !updateLastDefault && value) {
      value = moment(value).utc().add(moment(value).utcOffset(), "m").toDate();
    }
    const controlState = validateControl(ruleList, value, t, "date");
    await sleep(1);
    let _state = {
      ...state,
      value,
      valueStr,
      controlState,
      loading: false,
    } as any;
    if (updateLastDefault) {
      _state.lastDefault = props.defaultValue;
    }
    if (props.onChange) {
      props.onChange({
        controlState: _state.controlState,
        value: _state.value,
        valueStr: _state.valueStr,
      });
    }
    if (props.onTrueUpdateValue) {
      props.onTrueUpdateValue({
        controlState: _state.controlState,
        value: _state.value,
        prevValue: state.value,
        valueStr: _state.valueStr,
      });
    }
    if (!jsonEqual(_state, state)) {
      setState(_state);
    }
  };
  const onFocus = () => {
    if (props.onTouched) {
      props.onTouched();
      return;
    }
    if (!state.touched) {
      setState({
        ...state,
        touched: true,
      });
    }
  };
  const onKeyUp = (event: any) => {
    if (event.key === "Enter") {
      if (props.onTrueUpdateValue) {
        props.onTrueUpdateValue({
          controlState: state.controlState,
          value: state.value,
          valueStr: state.valueStr,
        });
      }
    }
  };

  //aw
  const renderControl = () => {
    let dateFormat = props.dateFormat ? props.dateFormat : DATE_FORMART;
    return (
      <Calendar
        numberOfMonths={1}
        showIcon
        tooltip={state.valueStr}
        {...props.config}
        className={`${props.className} ${props.config.className || ""} ${
          isError(state, props) ? "p-invalid" : ""
        }`}
        onViewDateChange={(e) => setViewDate(e.value)}
        view={props.config?.monthPicker ? "month" : "date"}
        viewDate={state.viewDate}
        dateFormat={dateFormat}
        onKeyUp={onKeyUp}
        placeholder={props.placeholder}
        id={id}
        value={state.value}
        tooltipOptions={{ position: "top" }}
        onChange={(event) => onChange(event.value)}
        onFocus={() => onFocus()}
        readOnlyInput={props.config?.readOnly || props.fromFilter}
        disabled={props.config?.readOnly}
        monthNavigator={
          props.config?.monthNavigator != null
            ? props.config?.monthNavigator
            : true
        }
        yearNavigator={
          props.config?.yearNavigator != null
            ? props.config?.yearNavigator
            : true
        }
        yearRange={
          props.config?.yearRange ||
          `${new Date().getFullYear() - 100}:${new Date().getFullYear() + 100}`
        }
      />
    );
  };

  //aw
  const renderAction = () => {
    if (!props.action) {
      return null;
    } else {
      return (
        <Button
          label={props.action?.label}
          icon={props.action?.icon}
          tooltip={props.action?.tooltip}
          tooltipOptions={{ position: "top" }}
          onClick={props.action?.clickFn}
          onMouseDown={props.action?.onMouseDown} // added custom function - aw
          onMouseLeave={props.action?.onMouseLeave} // added custom function - aw
          onTouchStart={props.action?.onTouchStart} // added custom function - aw
          onTouchEnd={props.action?.onTouchEnd} // added custom function - aw
          onMouseUp={props.action?.onMouseUp} // added custom function - aw
          onTouchMove={props.action?.onTouchMove} // added custom function - aw
          className={props.action?.className + " custom-input-action-btn"}
          type="button"
        />
      );
    }
  };

  return (
    <>
      <div
        className={`date-control-inner p-field ${
          props.noLabel ? "no-label" : ""
        }`}
      >
        <label htmlFor={props.id}>
          {props.label}
          {props.required && !props.noRequiredLabel ? (
            <small className="required p-invalid">&nbsp;*</small>
          ) : null}
          {props.tooltip ? (
            <Button
              type="button"
              tooltip={props.tooltip}
              tooltipOptions={{ position: "top" }}
              icon="pi pi-info-circle"
              className="p-button-rounded label-help p-button-text p-button-plain"
            />
          ) : null}
        </label>
        <span className="form-date-readyonly-value" style={{ display: "none" }}>
          {state.printValueStr}
        </span>
        <div
          className={`p-inputgroup ${
            isError(state, props) ? "p-inputgroup-error" : ""
          }`}
        >
          {state.loading ? (
            <Skeleton />
          ) : (
            <>
              {renderControl()}
              {/* aw*/}
              {renderAction()}
              {/* aw*/}
              {props.hintRight && (
                <span className={"control-hint-right"}>{props.hintRight}</span>
              )}
            </>
          )}
        </div>
        {props.hintBottom && (
          <div className={"control-hint-bottom"}>{props.hintBottom}</div>
        )}
        {renderError(state, props, t)}
      </div>
    </>
  );
};

export default DateControl;
