import "./submit.scss";

//components
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import merge from "deepmerge";
import { useHistory } from "react-router";
import { Button } from "primereact/button";

import {
  resetBcDynamicPaths,
  setBcDynamicPaths,
} from "../../../../../redux/actions/breadcrumb";
import { twoColformSkeleton } from "./../../utils/skeleton";
import { useTranslation } from "react-i18next";
import BaseForm from "../../../../../components/base-form/base-form";
import {
  getControlsAndLayout,
  initFormWithNwowApplication,
  prepareApplicationPayload,
  identifyTimeSegment,
} from "./config-helper";
import { openModal } from "../../../../../redux/actions/modal";
import {
  EEPORTAL_LABEL_BASE_TABLE,
  FUNCTION_CODE,
  NWOW_SUBMODULE_CODE,
  MESSAGE_SEVERITY,
  MODULE_CODE,
} from "../../../../../constants";
import {
  NWOW_T_CONTEXT_KEY,
  DATETIME_FORMAT,
  NWOW_MENU_MODULE_KEY,
  NWOW_MENU_PAGE_KEY,
  UOM,
  DATE_TYPE,
  DATE_TYPE_CONFIG,
} from "../../constants/contants";
import { NwowService } from "../../../../../services/hrmnet-api";
import { isResponseOk } from "../../../../../utils/utils";
import {
  showTimestampToastSuccess,
  showToast,
} from "../../../../../services/utils/message";
import { showSpinner } from "../../../../../redux/actions/spinner";
import LanguageLoader from "../../../../../components/language-loader/language-loader";
import { isAutoCalculateEndDate } from "../../utils/config-helper";
import { NwowMenu, ScrollToNwowMenuActiveItem } from "../../components";
import { PortalLink } from "../../../../../services/utils";
import { Tooltip } from 'primereact/tooltip';

const mergeControlAndControlConfig = (controls, controlConfig) => {
  return controls.map((control) => {
    if (control.key in controlConfig) {
      return {
        ...control,
        ...controlConfig[control.key],
        config: {
          ...control.config,
          ...controlConfig[control.key].config,
        },
      };
    } else {
      return control;
    }
  });
};

const Submit = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const menus = useSelector((state) => state.menu.sideBar);
  const nwowMenu = menus.find((x) => x.key === NWOW_MENU_MODULE_KEY)?.subMenus;

  const editMode = !!props.application;
  const disabledAppliedDates = false;

  const initState = {
    touched: false,
    isLoading: true,
    isLoadingForm: false,
  };
  const initForm = {
    NwowStartTime: new Date(1970, 1, 1, 9, 0, 0),
    NwowStartTimeSegment: "AM",
    NwowEndTime: new Date(1970, 1, 1, 18, 0, 0),
    NwowEndTimeSegment: "PM",
  };

  const [state, setState] = useState(initState);
  const [nwowTypes, setNwowTypes] = useState([]);
  const [nwowInfo, setNwowInfo] = useState({});
  const [isInitEditMode, setIsInitEditMode] = useState(editMode);
  const [weekDays, setWeekDays] = useState({});
  
  const [restDates, setRestDates] = useState(null);
  const [nWoWAppliedDates, setNWoWAppliedDates] = useState(null);
  const [redDayDates, setRedDayDates] = useState(null);

  const [form, setForm] = useState(initForm);
  const [formControls, setFormControls] = useState([]);
  const [formLayout, setFormLayout] = useState([]);

  // for validation
  const [formControlConfig, setFormControlConfig] = useState({});
  //const [triggerCalculateUnit, setTriggerCalculateUnit] = useState(false);
  //const [pendingCalculateUnit, setPendingCalculateUnit] = useState(false);
  //const [blockCalculateUnit, setBlockCalculateUnit] = useState(false);

  const formRef = React.createRef();
  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );

  // init
  useEffect(() => {
    const getNwowTypes = async () => {
      let res = await NwowService.nwowGetNwowTypeList();
      if (res && res.data) {
        setNwowTypes(res.data);
      }
      setState({
        ...state,
        isLoading: false,
      });
    };
    getNwowTypes();
    ScrollToNwowMenuActiveItem();
  }, [selectedLangKey]);

  /*
  const calculateUnits = async () => {
    if (!formControls) {
      return;
    }
    if (
      !form["NwowStartDate"] ||
      !form["NwowStartTime"] ||
      !form["NwowEndDate"] ||
      !form["NwowEndTime"]
    ) {
      setForm((form) => ({
        ...form,
        Units: undefined,
      }));
      return;
    }

    if (blockCalculateUnit) {
      setPendingCalculateUnit(true);
      return;
    }

    // start calculate
    setBlockCalculateUnit(true);
    const payload = prepareApplicationPayload({
      form,
      nwowInfo,
      preApproval: false,
      includeAttachment: false,
      defaultUnits: 1, // Custom logic, requried by backend
    });
    let res = await NwowService.nwowCalculateUnits(payload);
    if (isResponseOk(res)) {
      // Warning if isPartialBeforeMeal/isPartialAfterMeal
      if (
        (res.data?.startDateInformation &&
          (res.data.startDateInformation?.isPartialBeforeMeal ||
            res.data.startDateInformation?.isPartialAfterMeal)) ||
        (res.data?.toDateInformation &&
          (res.data.toDateInformation?.isPartialBeforeMeal ||
            res.data.toDateInformation?.isPartialAfterMeal))
      ) {
        if (!(nwowInfo.uom === UOM.HOUR && !nwowInfo.useTimeSegmentName)) {
          showToast({
            summary: t("Nwow_form_messageMismatchTime"),
            severity: MESSAGE_SEVERITY.WARN,
          });
        }
      }

      let updatedNwowStartTime;
      let updatedNwowEndTime;
      if (nwowInfo.useTimeSegmentName && res.data?.units > 0) {
        let segmentTimeInfo = prepareTimeSegment(res.data);
        updatedNwowStartTime =
          segmentTimeInfo["start"][form["NwowStartTimeSegment"]];
        updatedNwowEndTime =
          segmentTimeInfo["end"][form["NwowEndTimeSegment"]];
        if (segmentTimeInfo) {
          setNwowInfo((nwowInfo) => ({
            ...nwowInfo,
            segmentTimeInfo: segmentTimeInfo,
          }));
        }
      }

      setForm((form) => ({
        ...form,
        // NwowStartDate: nwowInfo.useTimeSegmentName
        //   ? updatedNwowStartTime
        //   : form["NwowStartDate"],
        // NwowStartTime: nwowInfo.useTimeSegmentName
        //   ? updatedNwowStartTime
        //   : form["NwowStartTime"],
        // NwowEndDate: nwowInfo.useTimeSegmentName
        //   ? updatedNwowEndTime
        //   : form["NwowEndDate"],
        // NwowEndTime: leaveInfo.useTimeSegmentName
        //   ? updatedNwowEndTime
        //   : form["NwowEndTime"],
        Units: Number(res.data.units),
      }));
    } else {
      setForm((form) => ({
        ...form,
        Units: null,
      }));
    }
  };*/
  const getWeekDays = async () => {
    if (form["NwowStartDate"] && form["NwowEndDate"]) {
      let teleweek = formControls.find((x) => x.key === "ATeleWeek");
      if (teleweek !== undefined) {
        form["ATeleWeek"] = [];
        var now = new Date();
        var daysOfWeek = daysOfWeek || [];
        var timeDifferent =
          form["NwowEndDate"].getTime() - form["NwowStartDate"].getTime();
        // To calculate the no. of days between two dates
        var dayDifferent = timeDifferent / (1000 * 3600 * 24);
        if (dayDifferent > 6) {
          dayDifferent = 7;
        }
        var curDate = new Date(form["NwowStartDate"].getTime());
        for (var d = 0; d <= dayDifferent; d++) {
          var date = curDate.setDate(curDate.getDate() + 1);
          var dates = new Date(date);
          daysOfWeek.push(String(dates.getDay() - 1));
        }
        let allFounded = weekDays.filter((ai) => daysOfWeek.includes(ai.value));
        teleweek.enum = allFounded;
      }
      return;
    }
  };

  const calculateEndDate = async () => {
    const numDaysToAdd = Number(nwowInfo.minPreApproveApplication) - 1;
    if (
      !isAutoCalculateEndDate(nwowInfo) ||
      !numDaysToAdd ||
      !form["NwowStartDate"]
    ) {
      return;
    }

    // calculate
    let newEndDate = new Date(form["NwowStartDate"]);
    newEndDate.setDate(newEndDate.getDate() + numDaysToAdd);
    setForm((form) => ({
      ...form,
      NwowEndDate: newEndDate,
    }));
  };

  const prepareTimeSegment = (data) => {
    if (
      data?.startDateInformation?.shiftActualStartAt &&
      data?.toDateInformation?.mealActualStartAt &&
      data?.startDateInformation?.mealActualEndAt &&
      data?.toDateInformation?.shiftActualEndAt
    ) {
      const segmentTimeInfo = {
        start: {
          AM: new Date(data.startDateInformation.shiftActualStartAt),
          PM: new Date(data.startDateInformation.mealActualEndAt),
        },
        end: {
          AM: new Date(data.toDateInformation.mealActualStartAt),
          PM: new Date(data.toDateInformation.shiftActualEndAt),
        },
      };
      return segmentTimeInfo;
    }

    return null;
  };

  const dateTemplate = (date) => {
    const application = nWoWAppliedDates.find(
      (d) =>
        d.targetDate.getFullYear() === date.year &&
        d.targetDate.getMonth() === date.month &&
        d.targetDate.getDate() === date.day
    );

    if(!!application)
    {
      return (
        <>
        {application?.tooltip}
        <strong
          className={`${application.target} pointer`}
          style={{
            width: "2rem",
            height: "2rem",
            border: "2px solid",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bold",
          }}
        >
          {date.day}
        </strong>
        </>
        )
    }

    const isRedDay = redDayDates.find(
      (d) =>
        d.getFullYear() === date.year &&
        d.getMonth() === date.month &&
        d.getDate() === date.day
    );
    if (isRedDay) {
      return (
        <strong
          style={{
            color: "#ba1b22",
            fontWeight: 900,
            opacity: 1,
          }}
        >
          {date.day}
        </strong>
      );
    } else {
      return date.day;
    }
  };

  const updateFormControlConfig = () => {
    if (props?.application?.isAllowChange) {
      nwowInfo.earliestAllowedDate = props?.application?.nwowFrom;
    }

    let appliedDates = Array.isArray(nWoWAppliedDates) ? Array.from(nWoWAppliedDates, x => x.targetDate) : [];

    let disabledDates = [];
    if(!!appliedDates.length && disabledAppliedDates)
    {
      disabledDates = appliedDates
    }
    if(restDates && redDayDates)
    {
      disabledDates = [...disabledDates, ...restDates, ...redDayDates]
    }

    if (formControls.length) {
      setFormControlConfig((formControlConfig) => ({
        ...formControlConfig,
        NwowStartDate: {
          config: {
            ...formControlConfig?.["NwowStartDate"]?.config,
            className: form["NwowStartDate"]
              ? null
              : formControlConfig?.["NwowStartDate"]?.config.className, // update error status
            // maxDate: form["NwowEndDate"],

            minDate: nwowInfo?.earliestAllowedDate
              ? new Date(nwowInfo.earliestAllowedDate)
              : new Date(moment().subtract(1, "years").startOf("year")),

            maxDate: nwowInfo?.latestAllowedDate
              ? new Date(nwowInfo.latestAllowedDate)
              : new Date(moment().add(1, "years").endOf("year")),
            viewDate: form["NwowStartDate"]
              ? form["NwowStartDate"]
              : form["NwowEndDate"],
            disabledDates: !!disabledDates.length ? disabledDates : null,
            dateTemplate: dateTemplate,
          },
        },
        NwowEndDate: {
          config: {
            ...formControlConfig?.["NwowEndDate"]?.config,
            className: form["NwowEndDate"]
              ? null
              : formControlConfig?.["NwowEndDate"]?.config.className, // update error status
            // minDate: form["NwowStartDate"],
            minDate: form["NwowStartDate"]
              ? form["NwowStartDate"]
              : nwowInfo?.earliestAllowedDate
              ? new Date(nwowInfo.earliestAllowedDate)
              : new Date(moment().subtract(1, "years").startOf("year")),
            maxDate: nwowInfo?.latestAllowedDate
              ? new Date(nwowInfo.latestAllowedDate)
              : new Date(moment().add(1, "years").endOf("year")),
            viewDate: form["NwowEndDate"]
              ? form["NwowEndDate"]
              : form["NwowStartDate"],
            disabledDates: !!disabledDates.length ? disabledDates : null,
            dateTemplate: dateTemplate,
          },
        },
        /*
        NwowStartTime: {
          config: {
            ...formControlConfig?.["NwowStartTime"]?.config,
            className: form["NwowStartTime"]
              ? null
              : formControlConfig?.["NwowStartTime"]?.config.className, // update error status
            readOnly:
              !form["NwowStartDate"] || isAutoCalculateEndDate(nwowInfo),
          },
        },
        NwowStartTimeSegment: {
          config: {
            readOnly:
              !form["NwowStartDate"] || isAutoCalculateEndDate(nwowInfo),
          },
        },*/
        /*NwowEndTime: {
          config: {
            ...formControlConfig?.["NwowEndTime"]?.config,
            className: form["NwowEndTime"]
              ? null
              : formControlConfig?.["NwowEndTime"]?.config.className, // update error status
            readOnly:
              !form["NwowStartDate"] ||
              !form["NwowEndDate"] ||
              isAutoCalculateEndDate(nwowInfo),
          },
        },
        NwowEndTimeSegment: {
          config: {
            readOnly:
              !form["NwowStartDate"] ||
              !form["NwowEndDate"] ||
              isAutoCalculateEndDate(nwowInfo),
          },
        },*/
      }));
    }
  };

  // form validation
  useEffect(() => {
    updateFormControlConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formControls, JSON.stringify(form["NwowEndDate"])]);

  // autofill end date
  useEffect(() => {
    if (!isInitEditMode) {
      const numDaysToAdd = Number(nwowInfo.minPreApproveApplication) - 1;
      if (
        !isAutoCalculateEndDate(nwowInfo) ||
        !numDaysToAdd ||
        !form["NwowStartDate"]
      ) {
        let newEndDate = new Date(form["NwowStartDate"]);
        newEndDate.setDate(newEndDate.getDate());
        setForm((form) => ({
          ...form,
          NwowEndDate: newEndDate,
        }));
      }
      getWeekDays();
      return;
    }
  }, [JSON.stringify(form["NwowStartDate"])]);

  // get weekdays
  useEffect(() => {
    console.log("isInitEditMode", isInitEditMode);
    if (!isInitEditMode) {
      getWeekDays();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(form["NwowEndDate"])]);

  // calculate unit trigger
  // this is to cater calling calculateUnits() in the timer
  // timer is initiated once on startup so it cannot access state regularly
  /*useEffect(() => {
    if (triggerCalculateUnit) {
      setTriggerCalculateUnit(false);
      calculateUnits();
    }
    var value = form['Acceptance'];
    if(form['Acceptance'] == false)
    {
      var b = 1;
    }
  }, [triggerCalculateUnit]); */

  // Timer for execute pending calculate unit job
  /* React.useEffect(() => {
    const interval = setInterval(() => {
      setBlockCalculateUnit(false);
      setPendingCalculateUnit((pending) => {
        if (pending) {
          setTriggerCalculateUnit(true);

        }
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);*/

  const loadForm = async () => {
    let nwowInfo = {};

    // prepare to update form
    setState((state) => ({
      ...state,
      isLoadingForm: true,
    }));

    // reset form or init edit application
    let _form = {};
    if (isInitEditMode) {
      _form = initFormWithNwowApplication(props.application);
      setForm(_form);
    } else {
      _form = {
        ...initForm,
        // ...form,
        NwowCode: form["NwowCode"],
      };
      setForm(_form);
    }

    // Load form config
    if (_form["NwowCode"]) {
      const res = await NwowService.nwowGetNwowTypesInfoByNwowCode({
        nwowcode: _form["NwowCode"],
      });

      if (res?.data?.length) {
        //if got data then load form
        nwowInfo = { ...res.data[0] };
      } else {
        setForm((form) => ({
          ...form,
          NwowCode: null,
        }));
      }

      // get disallowed days
      if (
        !restDates &&
        !redDayDates &&
        nwowInfo?.earliestAllowedDate &&
        nwowInfo?.latestAllowedDate
      ) {
        const res = await NwowService.nwowGetDateTypeList({
          fromDate: moment(nwowInfo["earliestAllowedDate"]).format(
            "YYYY-MM-DD"
          ),
          toDate: moment(nwowInfo["latestAllowedDate"]).format("YYYY-MM-DD"),
          employeeCode: form["EmployeeCode"],
        });

        if (res?.data?.length) {
          let restDatesList = [];
          let redDayDatesList = [];
          let nWoWAppliedDayDatesList = [];
          res.data.forEach((d, id) => {
            if(!!d?.applications?.length) {
              nWoWAppliedDayDatesList.push({
                target: `application${id}`,
                targetDate: new Date(d.targetDate),
                tooltip: <Tooltip 
                mouseTrack
                mouseTrackTop={15}
                key={id} 
                position="top"
                target={`.application${id}`} >
                  <div style={{
                    display: "grid"
                  }}>
                  {
                    d?.applications?.map(x => {
                      if(!x?.nwowRecord?.nwowType)
                      {
                        return;
                      }
                      return <strong>{`${x?.nwowRecord?.nwowCode} - ${x?.nwowRecord?.nwowType}`}</strong>
                    })
                    .filter(x => !!x)
                  }
                  </div>
                </Tooltip>,
              });
              return;
            }

            if (
              [
                DATE_TYPE.REST,
                DATE_TYPE.NON_PAID_REST,
                DATE_TYPE.HOLIDAY,
                DATE_TYPE.STATUTORY,
              ].includes(d.dateType)
            ) {
              if (DATE_TYPE_CONFIG[d.dateType].isRedDay) {
                redDayDatesList.push(new Date(d.targetDate));
              } else {
                restDatesList.push(new Date(d.targetDate));
              }
            }
          });
          setRestDates(restDatesList);
          setNWoWAppliedDates(nWoWAppliedDayDatesList);
          setRedDayDates(redDayDatesList);
        }
      }
    }

    // use segment in edit mode
    if (
      editMode &&
      nwowInfo.useTimeSegmentName &&
      !isAutoCalculateEndDate(nwowInfo) &&
      _form["NwowStartDate"] &&
      _form["NwowEndDate"]
    ) {
      // temp to identify segment
      const tempPayload = {
        NwowCode: _form["NwowCode"],
        UnitOfMeasure: nwowInfo.uom,
        Remarks: "",
        Units: 1,

        NwowStartDateTimeOrDate: moment(_form["NwowStartDate"]).format(
          DATETIME_FORMAT
        ),
        NwowEndDateTimeOrDate: moment(_form["NwowEndDate"]).format(
          DATETIME_FORMAT
        ),
        Amend: false,
        IsAccrual: false,
      };

      let unitRes = await NwowService.nwowCalculateUnits(tempPayload);
      if (isResponseOk(unitRes)) {
        let result = identifyTimeSegment({
          form: _form,
          unitInfo: unitRes.data,
        });
        let updateSegment = {};
        if (result["start"].length > 0) {
          updateSegment["NwowStartTimeSegment"] = result["start"];
        }
        if (result["end"].length > 0) {
          updateSegment["NwowEndTimeSegment"] = result["end"];
        }
        setForm((form) => ({
          ...form,
          ...updateSegment,
        }));

        let segmentTimeInfo = prepareTimeSegment(unitRes.data);
        if (segmentTimeInfo) {
          nwowInfo.segmentTimeInfo = segmentTimeInfo;
        }
      }
    }

    if (!editMode && nwowInfo?.officeHrStart && nwowInfo?.officeHrEnd) {
      const startHour = Number((nwowInfo?.officeHrStart).substr(0, 2));
      const startMinute = Number((nwowInfo?.officeHrStart).substr(3, 2));
      const endHour = Number((nwowInfo?.officeHrEnd).substr(0, 2));
      const endMinute = Number((nwowInfo?.officeHrEnd).substr(3, 2));

      const startDateTime = new Date(
        1970,
        1,
        1,
        !isNaN(startHour) ? startHour : 0,
        !isNaN(startMinute) ? startMinute : 0,
        0
      );
      const endDateTime = new Date(
        1970,
        1,
        1,
        !isNaN(endHour) ? endHour : 0,
        !isNaN(endMinute) ? endMinute : 0,
        0
      );

      setForm((form) => ({
        ...form,
        NwowStartTime: startDateTime,
        NwowEndTime: endDateTime,
      }));
    }

    setNwowInfo(nwowInfo);
    const [controls, layout] = getControlsAndLayout({
      nwowInfo,
      nwowTypes,
      selectedNwowCode: _form["NwowCode"],
      onSeeBalanceDetail: redirectToNwowBalance,
      t,
      isChangeApplication: props?.application?.isAllowChange,
    });
    setFormControls(controls);
    setFormLayout(layout);

    // Finish update form
    setState((state) => ({
      ...state,
      isLoadingForm: false,
    }));
    let teleweek = controls.find((x) => x.key === "ATeleWeek");
    if (teleweek !== undefined) {
      let weekDays = JSON.parse(JSON.stringify(teleweek.enum));
      setWeekDays(weekDays);
    }
  };

  // init when edit application
  useEffect(() => {
    if (isInitEditMode && nwowTypes.length) {
      loadForm();
    }
  }, [nwowTypes]);

  useEffect(() => {
    if (props?.application?.applicationId) {
      dispatch(
        setBcDynamicPaths([{ label: t("Nwow_submit_breadcrumb_edit") }])
      );
    } else {
      dispatch(setBcDynamicPaths([{ label: t("Nwow_submit_breadcrumb") }]));
      // dispatch(resetBcDynamicPaths());
    }
  }, [dispatch]);

  // Reload Form when fetched nwow types and when nwow code is selected
  useEffect(() => {
    // skip reload when init'ing for edit application
    if (!isInitEditMode) {
      loadForm();
      setFormControlConfig({});
    }

    // update isInitEditMode when init finished
    if (form["NwowCode"] && nwowTypes.length && isInitEditMode) {
      setIsInitEditMode(false);
    }
  }, [JSON.stringify(form["NwowCode"]), nwowTypes]);

  // Validation
  const validateAll = () => {
    if (!validateBase()) {
      return false;
    }
    setState((state) => ({
      ...state,
      touched: true,
    }));
    return !formRef.current.getFormState().invalid;
  };

  const validateBase = () => {
    let _config = {};
    let hasEmpty = false;
    ["NwowStartDate", "NwowStartTime", "NwowEndDate", "NwowEndTime"].forEach(
      (field) => {
        if (!form[field]) {
          _config[field] = { config: { className: "p-invalid" } };
          hasEmpty = true;
        }
      }
    );

    if (hasEmpty) {
      setFormControlConfig((controlConfig) => merge(controlConfig, _config));
      return false;
    }

    // validate start time < end time
    const payload = prepareApplicationPayload({
      form,
      nwowInfo,
      preApproval: false,
      includeAttachment: false,
    });

    if (
      new Date(payload["NwowStartDateTimeOrDate"]) >=
      new Date(payload["NwowEndDateTimeOrDate"])
    ) {
      // bad input

      // highlight error fields
      _config["NwowStartTime"] = { config: { className: "p-invalid" } };
      _config["NwowEndTime"] = { config: { className: "p-invalid" } };

      setFormControlConfig((controlConfig) => merge(controlConfig, _config));
      showToast({
        summary: t("Nwow_form_messagePleaseProvideValidDateRange"),
        severity: MESSAGE_SEVERITY.WARN,
      });
      return false;
    }

    return true;
  };

  const redirectToNwowBalance = () => {
    history.push(
      PortalLink(`${FUNCTION_CODE.Nwow}/${NWOW_SUBMODULE_CODE.BALANCE}`)
    );
  };

  // Save
  const save = async () => {
    try {
      dispatch(showSpinner(true));
      if (!validateBase()) return;

      const applicationId = props?.application?.applicationId;

      const payload = prepareApplicationPayload({
        form,
        nwowInfo,
        preApproval: false,
      });

      let res;
      if (applicationId != null) {
        res = await NwowService.nwowUpdateNwowDraftApplication({
          applicationId,
          form: payload,
        });
      } else {
        res = await NwowService.nwowSaveNwowDraftApplication(payload);
      }

      if (isResponseOk(res)) {
        backToList();
        showTimestampToastSuccess({
          message: t("Nwow_submit_saveSuccessMessage"),
          t: t,
        });
      }
    } catch (e) {
    } finally {
      dispatch(showSpinner(false));
    }
  };

  // Submit
  const confirmSubmit = () => {
    if (!validateAll()) {
      return;
    }
    dispatch(
      openModal({
        title: t("Nwow_message_confirmation"),
        content: t("Nwow_submit_confirmSubmitMessage"),
        classNameMainDialog: "confirm-message-modal",
        primaryButtonText: t("Nwow_submit_confirmSubmitButton"),
        primaryButtonClickFn: async ({ closeFn }) => {
          closeFn();
          submitAction();
        },
        secondButtonClickFn: ({ closeFn }) => {
          closeFn();
        },
      })
    );
  };

  const submitAction = async () => {
    try {
      dispatch(showSpinner(true));
      const applicationId = props?.application?.applicationId;
      const isAllowChange = props?.application?.isAllowChange;
      const payload = prepareApplicationPayload({
        form,
        nwowInfo,
        preApproval: false,
      });
      let res;

      if (applicationId != null) {
        if (isAllowChange) {
          res = await NwowService.nwowSubmitNwowChangeApplication({
            applicationId,
            form: payload,
          });
        } else {
          res = await NwowService.nwowSubmitNwowDraftApplication({
            applicationId,
            form: payload,
          });
        }
      } else {
        res = await NwowService.nwowSubmitNwowApplication(payload);
      }

      if (isResponseOk(res)) {
        backToList();
        showTimestampToastSuccess({
          message: t("Nwow_submit_submitSuccessMessage"),
          t: t,
        });
      }
    } catch (e) {
    } finally {
      dispatch(showSpinner(false));
    }
  };

  // Back
  const backToList = () => {
    history.push(PortalLink(`${FUNCTION_CODE.Nwow}`));
  };

  const confirmBack = async () => {
    dispatch(
      openModal({
        title: t("Nwow_message_confirmation"),
        content: t("Nwow_submit_confirmBackMessage"),
        classNameMainDialog: "confirm-message-modal",
        primaryButtonText: t("Nwow_common_actionConfirm"),
        primaryButtonClickFn: async ({ closeFn }) => {
          closeFn();
          backToList();
        },
        secondButtonClickFn: ({ closeFn }) => {
          closeFn();
        },
      })
    );
  };

  const confirmDeleteDraft = async () => {
    dispatch(
      openModal({
        title: t("Nwow_editApplication_confirmDeleteTitle"),
        content: t("Nwow_editApplication_confirmDeleteMessage"),
        classNameMainDialog: "confirm-message-modal",
        primaryButtonText: t("Nwow_common_actionConfirm"),
        primaryButtonClickFn: async ({ closeFn }) => {
          closeFn();
          deleteDraft();
        },
        secondButtonClickFn: ({ closeFn }) => {
          closeFn();
        },
      })
    );
  };

  const deleteDraft = async () => {
    try {
      dispatch(showSpinner(true));
      const res = await NwowService.nwowDeleteNwowDraftApplication({
        applicationId: props.application.applicationId,
      });
      if (isResponseOk(res)) {
        backToList();
        showTimestampToastSuccess({
          message: t("Nwow_editApplication_deleteSuccessMessage"),
          t: t,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(showSpinner(false));
    }
  };

  // Render
  const renderSubmitForm = () => {
    if (state.isLoadingForm) {
      return twoColformSkeleton;
    }

    return (
      <BaseForm
        ref={formRef}
        config={{
          controls: mergeControlAndControlConfig(
            formControls,
            formControlConfig
          ),
          layout: formLayout,
        }}
        form={form}
        touched={state.touched}
        onChange={(change) => {
          setForm({ ...change.state.form });
        }}
      />
    );
  };

  const renderFooter = () => {
    return (
      <div className="footer p-grid p-align-center p-justify-between">
        <div className="left-button-group">
          <Button
            className="p-button-outlined secondary back-button"
            onClick={confirmBack}
          >
            {t("Nwow_common_actionBack")}
          </Button>
          {editMode && (
            <Button
              className="p-button-outlined secondary back-button"
              onClick={confirmDeleteDraft}
            >
              {t("Nwow_common_actionDelete")}
            </Button>
          )}
        </div>

        <div className="right-button-group">
          {form["NwowCode"] && (
            <>
              <Button className="p-button-outlined secondary" onClick={save}>
                {t("Nwow_common_actionSave")}
              </Button>
              <Button
                className="p-button"
                onClick={confirmSubmit}
                disabled={
                  !form["NwowStartDate"] ||
                  !form["NwowEndDate"] ||
                  !form["Acceptance"]
                }
              >
                {t("Nwow_common_actionSubmit")}
              </Button>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          NWOW_T_CONTEXT_KEY.COMMON,
          NWOW_T_CONTEXT_KEY.SUBMIT_APPLICATION,
          NWOW_T_CONTEXT_KEY.FORM,
        ]}
      />
      <div className="nwow-container nwow-submit-application">
        <div className="header">
          <NwowMenu
            menu={nwowMenu}
            currentPageKey={NWOW_MENU_PAGE_KEY.SUBMIT_LEAVE_APPLICATION}
          />
          <div className="title">
            {props?.application?.isAllowChange
              ? t("Nwow_change_title")
              : t("Nwow_submit_title")}
            {/* <div className="title-desc"></div> */}
          </div>
        </div>

        {/* Main */}
        <div className="main nwow-application-form">
          {state.isLoading ? twoColformSkeleton : renderSubmitForm()}
        </div>

        {/* Footer */}
        {renderFooter()}
      </div>
    </>
  );
};

export default Submit;
