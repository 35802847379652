import "./nwow.scss";

import React, { useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useDispatch } from "react-redux";

// components
import {
  NwowApplicationList,
  SubmitNwowApplication,
  EditApplication,
  ChangeApplication,
  ViewApplication,
  NwowCalendarView,
  NwowDelegation,
  NwowApproverCorner,
  ApproveApplication,
  HolidayList,
  Report,
  NwowPlanner,
} from "./modules";

import { NWOW_SUBMODULE_CODE } from "../../../constants/index";
import PageNotFound from "../public/error/employee-error-404";
import { resetBcDynamicPaths } from "../../../redux/actions/breadcrumb";
// import RentalConfigLoader from "./rental-config-loader";
// import NWowApplicationList from "./list/list";

const Nwow = (props) => {
  let match = useRouteMatch();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetBcDynamicPaths());
  }, [dispatch]);

  const routes = [
    {
      id: "list",
      path: `${match.path}`,
      component: NwowApplicationList,
      exact: true,
      strict: true,
    },
    {
      id: "submit",
      path: `${match.path}/${NWOW_SUBMODULE_CODE.SUBMIT_APPLICATION}`,
      component: SubmitNwowApplication,
      exact: true,
      strict: true,
    },
    {
      id: "planner",
      path: `${match.path}/${NWOW_SUBMODULE_CODE.PLANNER}`,
      component: NwowPlanner,
      exact: true,
      strict: true,
    },
    {
      id: "application-view",
      path: `${match.path}/${NWOW_SUBMODULE_CODE.APPLICATION}/:applicationId`,
      component: ViewApplication,
      exact: true,
      strict: true,
    },
    {
      id: "application-edit",
      path: `${match.path}/${NWOW_SUBMODULE_CODE.APPLICATION}/:applicationId/${NWOW_SUBMODULE_CODE.EDIT}`,
      component: EditApplication,
      exact: true,
      strict: true,
    },
    {
      id: "application-change",
      path: `${match.path}/${NWOW_SUBMODULE_CODE.APPLICATION}/:applicationId/${NWOW_SUBMODULE_CODE.CHANGE}`,
      component: ChangeApplication,
      exact: true,
      strict: true,
    },
    {
      id: "calendar-view-all",
      path: `${match.path}/${NWOW_SUBMODULE_CODE.CALENDAR_VIEW}`,
      component: NwowCalendarView,
      exact: true,
      strict: true,
    },
    {
      id: "delegation",
      path: `${match.path}/${NWOW_SUBMODULE_CODE.DELEGATION}`,
      component: NwowDelegation,
      exact: true,
      strict: true,
    },
    {
      id: "approval",
      path: `${match.path}/${NWOW_SUBMODULE_CODE.APPROVAL}`,
      component: NwowApproverCorner,
      exact: true,
      strict: true,
    },
    {
      id: "approve-application",
      path: `${match.path}/${NWOW_SUBMODULE_CODE.APPROVAL}/${NWOW_SUBMODULE_CODE.APPLICATION}/:applicationId`,
      component: ApproveApplication,
      exact: true,
      strict: true,
    },
    {
      id: "holiday",
      path: `${match.path}/${NWOW_SUBMODULE_CODE.HOLIDAY}`,
      component: HolidayList,
      exact: true,
      strict: true,
    },
    {
      id: "report",
      path: `${match.path}/${NWOW_SUBMODULE_CODE.REPORT}`,
      component: Report,
      exact: true,
      strict: true,
    },
  ];

  return (
    <>
      {/* <RentalConfigLoader /> */}
      <Switch>
        {props.routeConfig ? props.routeConfig(routes) : null}
        <Route component={PageNotFound} />
      </Switch>
    </>
  );
};

export default Nwow;
