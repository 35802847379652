import {
  SET_FORM,
  RESET_FORM,
  SET_FORM_CONFIG,
  SET_SECTION_CONFIG,
  RESET_FORM_CONFIG,
  RESET_FORM_VALUE,
  SET_MODULE_CONFIG,
  CLEAR_ALL_CONTEXT,
  SET_FORM_LANGUAGE,
  FILTER_FIELD_ENUM,
  UPDATE_FIELD_PROPERTIES,
} from "../reduxConstants";

/**
 * set  key-value form
 */
export const setMainForm = (payload) => {
  return {
    type: SET_FORM,
    payload: payload,
  };
};

/**
 * reset  key-value form
 */
export const resetMainForm = (payload) => {
  return {
    type: RESET_FORM,
    payload: payload,
  };
};

/**
 * set  form configuration
 */
export const setMainFormConfig = (payload) => {
  return {
    type: SET_FORM_CONFIG,
    payload: payload,
  };
};

/**
 * set  form configuration
 */
export const setMainFormSectionConfig = (payload) => {
  return {
    type: SET_SECTION_CONFIG,
    payload: payload,
  };
};

/**
 * update field's enum and its original enum stored in formConfig's dropdownOptions
 */
export const filterMainFormFieldEnum = (payload) => {
  return {
    type: FILTER_FIELD_ENUM,
    payload: payload,
  };
};

/**
 * update field's properties field
 */
export const updateMainFormProperties = (payload) => {
  return {
    type: UPDATE_FIELD_PROPERTIES,
    payload: payload,
  };
};

/**
 * Reset  form config to original
 */
export const resetMainFormConfig = () => {
  return {
    type: RESET_FORM_CONFIG,
  };
};

/**
 * Reset  form value
 */
export const resetMainFormValue = () => {
  return {
    type: RESET_FORM_VALUE,
  };
};

/**
 * set  module config
 */
export const setModuleConfig = (payload) => {
  return {
    type: SET_MODULE_CONFIG,
    payload: payload,
  };
};

/**
 * clear all  context
 */
export const clearAllContext = () => {
  return {
    type: CLEAR_ALL_CONTEXT,
  };
};

/**
 * clear all  context
 */
export const setFormLanguage = (payload) => {
  return {
    type: SET_FORM_LANGUAGE,
    payload: payload,
  };
};
