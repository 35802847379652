import { SET_NOTIFICATION } from "../reduxConstants";

/**
 * set notification
 */
export const setNotification = (notificationContent) => {
  return {
    type: SET_NOTIFICATION,
    payload: notificationContent,
  };
};
