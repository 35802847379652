import "./approve-application.scss";

//components
import { useEffect, useRef, useState } from "react";
// import { useDispatch } from "react-redux";
// import { resetBcDynamicPaths } from "../../../../../redux/actions/breadcrumb";
import TeamCalendarView from "../calendar/calendar-view-team";
import { calendarViewSkeleton } from "../../utils/skeleton";
import { LeaveService } from "../../../../../services/hrmnet-api";
import { isResponseOk } from "../../../../../utils/utils";

const ApproveApplication = (props) => {
  // const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const applicationRef = useRef();

  useEffect(() => {
    const loadApplication = async () => {
      const applicationId = props.match.params.applicationId;
      const res = await LeaveService.leaveGetLeaveApplicationById({
        applicationId,
      });

      if (isResponseOk(res)) {
        applicationRef.current = res.data;
        setIsLoading(false);
      }
    };
    loadApplication();
  }, [props.match.params.applicationId]);

  if (isLoading) {
    return calendarViewSkeleton;
  }

  return (
    <div className="leave-approve-application">
      <TeamCalendarView application={applicationRef.current} enableApproval />
    </div>
  );
};

export default ApproveApplication;
