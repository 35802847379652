import "./number-control.scss";

import {
  BCProps,
  BCStates,
  isError,
  renderError,
  validateControl,
} from "./../base-control";
import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Slider } from "primereact/slider";
import { jsonEqual } from "./../../../utils/utils";
import { useTranslation } from "react-i18next";

export interface NumberControlAction {
  /**
   * Max length
   */
  label?: string;
  /**
   * Max length
   */
  icon?: string;
  /**
   * Max length
   */
  tooltip?: string;
  /**
   * Max length
   */
  clickFn?: () => any;
  /**
   * Max length
   */
  className?: string;
  /**
   * onMouseDown
   */
  onMouseDown?: () => any; //aw
  /**
   * onMouseLeave
   */
  onMouseLeave?: () => any; //aw
  /**
   * onTouchStart
   */
  onTouchStart?: () => any; //aw
  /**
   * onTouchEnd
   */
  onTouchEnd?: () => any; //aw
  /**
   * onMouseUp
   */
  onMouseUp?: () => any; //aw
  /**
   * onTouchMove
   */
  onTouchMove?: () => any; //aw
}
export interface NumberControlProps extends BCProps {
  /**
   * Max
   */
  max?: number;
  /**
   * Min
   */
  min?: number;
  /**
   * Show slider
   */
  slider?: boolean;
  /**
   * Action
   */
  action?: NumberControlAction;
}

export interface NumberControlState extends BCStates {}

const NumberControl: React.FC<NumberControlProps> = (props) => {
  const { t } = useTranslation();
  // extract props data
  const id = props.id || "";
  const ruleList = props.ruleList || [];
  if (props.required) {
    ruleList.push({
      name: "required",
    });
  }

  let placeholder = props.placeholder;
  if (!props.placeholderStrict) {
    if (props.max !== undefined && props.min !== undefined) {
      placeholder = t("base_control_number", {
        min: props.min,
        max: props.max,
      });
    } else if (props.max !== undefined) {
      placeholder = t("base_control_number_less_than", {
        max: props.max,
      });
    } else if (props.min !== undefined) {
      placeholder = t("base_control_number_more_than", {
        min: props.min,
      });
    } else if (props.config?.disabled) {
      //aw
      placeholder = t("base_control_number_no"); //aw
    } //aw
    else {
      placeholder = t("base_control_number_input_number");
    }
  }

  // init state control
  let initState: NumberControlState = {
    touched: false,
    value: props.value,
    valueStr: (props.value || "").toString(),
    controlState: {
      invalid: false,
    },
  };
  initState.controlState =
    props.controlState || validateControl(ruleList || [], initState.value, t);
  // Update state if value changed
  const [state, setState] = useState(initState);
  // unsubcribe
  const mountedRef = useRef(true);
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);
  useEffect(() => {
    let value = props.value;

    const ruleList = props.ruleList || [];
    if (props.required) {
      ruleList.push({
        name: "required",
      });
    }
    let controlState =
      props.controlState || validateControl(ruleList || [], value, t);
    setState({
      touched: false,
      value,
      valueStr: (value || "").toString(),
      controlState,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, props.controlState]);

  // Update if rule update
  useEffect(() => {
    if (!mountedRef.current) return;
    onChange(state.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.required, props.ruleList, props.max, props.min]);

  const onChange = async (value: any) => {
    if (props.max && value > props.max) {
      value = props.max;
    }
    if (props.min && value < props.min) {
      value = props.min;
    }

    const valueStr = value ? value.toString() : "";
    const controlState = validateControl(ruleList, value, t);
    let _state = {
      ...state,
      value,
      valueStr,
      controlState,
      loading: false,
    };

    if (props.onChange) {
      props.onChange({
        controlState: _state.controlState,
        value: _state.value,
        valueStr: _state.valueStr,
      });
    }
    if (props.onTrueUpdateValue) {
      props.onTrueUpdateValue({
        controlState: _state.controlState,
        prevValue: state.value,
        value: _state.value,
        valueStr: _state.valueStr,
      });
    }
    if (!jsonEqual(_state, state)) {
      setState(_state);
    }
  };
  const onBlur = () => {
    if (props.onTrueUpdateValue) {
      props.onTrueUpdateValue({
        controlState: state.controlState,
        prevValue: state.value,
        value: state.value,
        valueStr: state.valueStr,
      });
    }
  };
  const onFocus = () => {
    if (props.onTouched) {
      return props.onTouched();
    }
    if (!state.touched) {
      setState({
        ...state,
        touched: true,
      });
    }
  };
  const onKeyUp = (event: any) => {
    if (event.key === "Enter") {
      if (props.onTrueUpdateValue) {
        props.onTrueUpdateValue({
          controlState: state.controlState,
          prevValue: state.value,
          value: state.value,
          valueStr: state.valueStr,
        });
      }
    }
  };

  const renderAction = () => {
    if (!props.action) {
      return null;
    } else {
      return (
        <Button
          label={props.action?.label}
          icon={props.action?.icon}
          tooltip={props.action?.tooltip}
          tooltipOptions={{ position: "top" }}
          onClick={props.action?.clickFn}
          className={props.action?.className + " custom-input-action-btn"}
          onMouseDown={props.action?.onMouseDown} // added custom function - aw
          onMouseLeave={props.action?.onMouseLeave} // added custom function - aw
          onTouchStart={props.action?.onTouchStart} // added custom function - aw
          onTouchEnd={props.action?.onTouchEnd} // added custom function - aw
          onMouseUp={props.action?.onMouseUp} // added custom function - aw
          onTouchMove={props.action?.onTouchMove} // added custom function - aw
          type="button"
        />
      );
    }
  };

  return (
    <>
      <div
        className={`number-control-inner p-field ${
          props.noLabel ? "no-label" : ""
        }`}
      >
        <label htmlFor={props.id}>
          {props.label}
          {props.required && !props.noRequiredLabel ? (
            <small className="required p-invalid">&nbsp;*</small>
          ) : null}
          {props.tooltip ? (
            <Button
              type="button"
              tooltip={props.tooltip}
              tooltipOptions={{ position: "top" }}
              icon="pi pi-info-circle"
              className="p-button-rounded label-help p-button-text p-button-plain"
            />
          ) : null}
        </label>
        <div
          className={`p-inputgroup p-inputgroup-number ${
            isError(state, props) ? "p-inputgroup-error" : ""
          }`}
        >
          <InputNumber
            showButtons
            tooltip={state.valueStr}
            tooltipOptions={{ position: "top" }}
            {...props.config}
            id={id}
            className={`${props.className} ${
              isError(state, props) ? "p-invalid" : ""
            } `}
            max={props.max}
            min={props.min}
            placeholder={placeholder}
            autoFocus={props.autoFocus}
            value={state.value}
            onValueChange={(event) => onChange(event.value)}
            onBlur={() => onBlur()}
            onKeyUp={(event: any) => onKeyUp(event)}
            onFocus={() => onFocus()}
          />
          {props.slider ? (
            <Slider
              className="silder-number"
              value={state.value}
              max={props.max}
              min={props.min}
              onChange={(event) => {
                onChange(event.value);
              }}
            />
          ) : null}

          {renderAction()}
          {props.hintRight && (
            <span className={"control-hint-right"}>{props.hintRight}</span>
          )}
        </div>
        {props.hintBottom && (
          <div className={"control-hint-bottom"}>{props.hintBottom}</div>
        )}
        {renderError(state, props, t)}
      </div>
    </>
  );
};

export default NumberControl;
