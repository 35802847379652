import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useMediaQuery } from "react-responsive";
import { Button } from "primereact/button";

import { UserNotificationsService } from "../../../../services/hrmnet-api";
import { isResponseOk } from "../../../../utils/utils";
import { MENU_NAME, MODULE_CODE } from "./../../../../constants/index";
import { PortalLink } from "../../../../services/utils";
import "./main-notification-icon.scss";

function MainNotificationIcon() {
  const history = useHistory();
  let notifications = useSelector((state) => state.notification);
  const isDesktop = useMediaQuery({ minWidth: "769px" });
  const isMobile = useMediaQuery({ maxWidth: "768px" });
  const isDisplay = useSelector((state) => {
    return (
      Array.isArray(state.menu.sideBar) &&
      state.menu.sideBar.findIndex((x) => x.key === MENU_NAME.Notification) > -1
    );
  });

  const [state, setState] = useState({
    showUnreadCount: false,
    unreadCount: 0,
  });

  const getUnreadCount = async () => {
    var api =
      await UserNotificationsService.notificationGetUnreadNotificationCount({});
    let [res] = await Promise.all([api]);

    if (
      isResponseOk(res) &&
      res.data &&
      res.data?.unreadNotificationCount > 0
    ) {
      setState((state) => ({
        ...state,
        showUnreadCount: true,
        unreadCount: res.data.unreadNotificationCount,
      }));
    } else {
      setState((state) => ({
        ...state,
        showUnreadCount: false,
        unreadCount: 0,
      }));
    }
  };

  useEffect(() => {
    const isNotificationPage =
      window.location.pathname === ["/", MODULE_CODE.Messaging].join("");

    if (!isNotificationPage) {
      getUnreadCount();
    } else {
      const notificationList =
        isMobile && notifications?.data
          ? notifications?.data
          : isDesktop && notifications?.notificationDesktopContent
          ? notifications?.notificationDesktopContent
          : [];
      if (notificationList.length) {
        let unreadCount = 0;
        notificationList.forEach((n) => {
          if (n.status === "New") {
            unreadCount++;
          }
        });
        if (unreadCount === 0) {
          getUnreadCount();
        } else {
          setState((state) => ({
            ...state,
            showUnreadCount: unreadCount > 0 ? true : false,
            unreadCount: unreadCount,
          }));
        }
      }
    }
  }, [notifications]);

  const renderContent = useMemo(() => {
    if (isDisplay) {
      return (
        <div className="main-notification">
          <Button
            className="notification-icon p-button-secondary p-button-text"
            onClick={(e) => {
              history.push(PortalLink(`${MODULE_CODE.Messaging}`));
            }}
          >
            <span className="p-button-icon pi pi-bell"></span>
            {state.showUnreadCount && (
              <span className="unread-count">{state.unreadCount}</span>
            )}
          </Button>
        </div>
      );
    }

    return <></>;
  }, [isDisplay, state]);

  return renderContent;
}

export default MainNotificationIcon;
