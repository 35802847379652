import "./nwow-menu.scss";
import { TabMenu } from "primereact/tabmenu";
import { NWOW_MENU_TYPE_KEY } from "../constants/contants";

interface NwowMenuProps {
  menu: any;
  currentPageKey?: string;
  redirectPage?: any;
  [key: string]: any;
}

export const NwowMenu = ({ menu, currentPageKey }: NwowMenuProps) => {
  if (!menu) return null;
  let selectedIndex = -1;

  const menuItems = menu
    .filter((m: any) => m.type === NWOW_MENU_TYPE_KEY.MENU)
    .map((m: any, i: number) => {
      if (m.key === currentPageKey) {
        selectedIndex = i;
      }
      return {
        ...m,
        label: m.name,
        url: selectedIndex === 0 ? m.url : `/${m.url}`,
        icon: `nwow-menu-icon-${m.key.toLowerCase()}`,
      };
    });

  return (
    <div className="nwow-menu">
      <TabMenu
        className="nwow-tab-menu"
        model={menuItems}
        activeIndex={selectedIndex}
      />
    </div>
  );
};

export const ScrollToNwowMenuActiveItem = () => {
  let element = document.querySelector(
    ".header .nwow-menu .p-tabmenuitem.p-highlight"
  );
  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
      inline: "start",
      block: "nearest",
    });
  }
};
