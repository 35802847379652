import "./toggle-control.scss";

import {
  BCProps,
  BCStates,
  isError,
  renderError,
  validateControl,
} from "./../base-control";
import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { useTranslation } from "react-i18next";

export interface ToggleControlAction {
  //aw
  /**
   * Max length
   */
  label?: string; //aw
  /**
   * Max length
   */
  icon?: string; //aw
  /**
   * Max length
   */
  tooltip?: string; //aw
  /**
   * Max length
   */
  clickFn?: () => any; //aw
  /**
   * Max length
   */
  className?: string; //aw
  /**
   * onMouseDown
   */
  onMouseDown?: () => any; //aw
  /**
   * onMouseLeave
   */
  onMouseLeave?: () => any; //aw
  /**
   * onTouchStart
   */
  onTouchStart?: () => any; //aw
  /**
   * onTouchEnd
   */
  onTouchEnd?: () => any; //aw
  /**
   * onMouseUp
   */
  onMouseUp?: () => any; //aw
  /**
   * onTouchMove
   */
  onTouchMove?: () => any; //aw
}

export interface ToggleControlProps extends BCProps {
  /**
   * trueValue
   */
  trueValue?: string;
  /**
   * pattern message
   */
  falseValue?: string;
  /**
   * Action
   */
  action?: ToggleControlAction;
}

export interface ToggleControlState extends BCStates {}

const ToggleControl: React.FC<ToggleControlProps> = (props) => {
  const { t } = useTranslation();
  // extract props
  const ruleList = props.ruleList || [];
  // if (props.required) {
  //   ruleList.push({
  //     name: "required",
  //   });
  // }

  // State
  let initState: ToggleControlState = {
    touched: false,
    value: props.value ? true : false,
    valueStr: props.value
      ? t("base_control_toggle_true")
      : t("base_control_toggle_false"),
    controlState: {
      invalid: false,
    },
  };
  initState.controlState =
    props.controlState ||
    validateControl(ruleList || [], initState.value, t, "toggle");

  // prepare state
  const [state, setState] = useState(initState);

  const mountedRef = useRef(true);
  // unsubcribe
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);
  // Update state if control state changed
  useEffect(() => {
    const ruleList = props.ruleList || [];
    // if (props.required) {
    //   ruleList.push({
    //     name: "required",
    //   });
    // }

    let controlState =
      props.controlState ||
      validateControl(ruleList || [], props.value, t, "toggle");
    if (!mountedRef.current) return;
    setState({
      ...state,
      value: props.value ? true : false,
      valueStr: props.value
        ? t("base_control_toggle_true")
        : t("base_control_toggle_false"),
      controlState,
      setDefault: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.controlState, props.required, props.value]);

  useEffect(() => {
    if (props.defaultValue !== state.lastDefault && state.setDefault) {
      onChange(props.defaultValue, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultValue, state.lastDefault]);

  /**
   * On change
   * @param {*} event
   */
  const onChange = (value: boolean, updateLastDefault = false) => {
    const valueStr = value
      ? props.trueValue || t("base_control_toggle_yes")
      : props.falseValue || t("base_control_toggle_no");
    const controlState = validateControl(ruleList, value, t, "toggle");
    let _state = {
      ...state,
      value,
      valueStr,
      controlState,
    } as any;
    if (updateLastDefault) {
      _state.lastDefault = props.defaultValue;
    }
    if (props.onChange) {
      props.onChange({
        controlState: _state.controlState,
        value: _state.value,
        valueStr: _state.valueStr,
      });
    }
    if (props.onTrueUpdateValue) {
      props.onTrueUpdateValue({
        controlState: _state.controlState,
        value: _state.value,
        valueStr: _state.valueStr,
      });
    }
    setState(_state);
  };

  const onFocus = () => {
    if (props.onTouched) {
      return props.onTouched();
    }
    if (!state.touched) {
      setState({
        ...state,
        touched: true,
      });
    }
  };

  //aw
  const renderAction = () => {
    if (!props.action) {
      return null;
    } else {
      return (
        <Button
          label={props.action?.label}
          icon={props.action?.icon}
          tooltip={props.action?.tooltip}
          tooltipOptions={{ position: "top" }}
          onClick={props.action?.clickFn}
          onMouseDown={props.action?.onMouseDown} // added custom function - aw
          onMouseLeave={props.action?.onMouseLeave} // added custom function - aw
          onTouchStart={props.action?.onTouchStart} // added custom function - aw
          onTouchEnd={props.action?.onTouchEnd} // added custom function - aw
          onMouseUp={props.action?.onMouseUp} // added custom function - aw
          onTouchMove={props.action?.onTouchMove} // added custom function - aw
          className={props.action?.className + " custom-input-action-btn"}
          type="button"
        />
      );
    }
  };

  //aw
  const renderControl = () => {
    return (
      <div
        className={`p-inputgroup ${
          isError(state, props) ? "p-inputgroup-error" : ""
        }`}
      >
        <InputSwitch
          {...props.config}
          id={props.id}
          className={`${props.className} p-inputswitch-custom ${
            isError(state, props) ? "p-invalid" : ""
          }`}
          checked={state.value}
          disabled={state.submitting || props.config?.disabled}
          onFocus={() => onFocus()}
          onChange={(e) => onChange(e.value)}
        />
        <Button
          type="button"
          disabled={state.submitting || props.config?.disabled}
          className="btn-input-switch p-button-text p-button-plain p-button-sm"
          autoFocus={props.autoFocus}
          onFocus={() => onFocus()}
          onClick={() => onChange(!state.value)}
          label={
            state.value
              ? props.trueValue || t("base_control_toggle_yes")
              : props.falseValue || t("base_control_toggle_no")
          }
        />
      </div>
    );
  };

  return (
    <>
      <div
        className={`toggle-control-inner p-field ${
          props.noLabel ? "no-label" : ""
        }`}
      >
        <label htmlFor={props.id}>
          {props.label}
          {props.required && !props.noRequiredLabel ? (
            <small className="required p-invalid">&nbsp;*</small>
          ) : null}
          {props.tooltip ? (
            <Button
              type="button"
              tooltip={props.tooltip}
              tooltipOptions={{ position: "top" }}
              icon="pi pi-info-circle"
              className="p-button-rounded label-help p-button-text p-button-plain"
            />
          ) : null}
        </label>
        <div
          className="p-inputgroup"
          style={{ justifyContent: "space-around" }}
        >
          {renderControl()}
          {/*aw*/}
          {renderAction()}
          {/*aw*/}
        </div>
        {props.hintBottom && (
          <div className={"control-hint-bottom"}>{props.hintBottom}</div>
        )}
        {renderError(state, props, t)}
      </div>
    </>
  );
};

export default ToggleControl;
