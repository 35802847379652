import { Route, Switch, useRouteMatch } from "react-router-dom";

import NotificationPage from "./notification-page/notification-page";

import PageNotFound from "../public/error/employee-error-404";
import LanguageLoader from "../../../components/language-loader/language-loader";
import {
  EEPORTAL_LABEL_BASE_TABLE,
  EEPORTAL_LABEL_NOTIFICATION,
  enumsKeyToArray,
} from "../../../constants";

const Notifications = (props) => {
  let match = useRouteMatch();

  const routes = [
    {
      path: `${match.path}`,
      component: NotificationPage,
      exact: true,
      strict: true,
    },
  ];

  return (
    <>
      <LanguageLoader
        contexts={enumsKeyToArray([
          EEPORTAL_LABEL_BASE_TABLE,
          EEPORTAL_LABEL_NOTIFICATION,
        ])}
      />
      <Switch>
        {props.routeConfig ? props.routeConfig(routes) : null}
        <Route component={PageNotFound} />
      </Switch>
    </>
  );
};

export default Notifications;
