import "./report.scss";

import { Animated } from "react-animated-css";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TabMenu } from "primereact/tabmenu";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { saveAs } from "file-saver";

import { reportConfig, RENTAL_REPORT_TYPE } from "./config";
import {
  DEFAULT_BT_SEARCH,
  getBTConfig,
} from "./../../../../../components/base-table/base-table-model";
import BaseTable from "../../../../../components/base-table/base-table-portal-rental";
import { useWindowSize } from "../../../utils/window-size/useWindowSize";
import { configToFormModel, initFormValue, formSkeleton } from "../../utils";
import {
  MultiStepForm,
  RentalMenu,
  ScrollToRentalMenuActiveItem,
} from "../../components";
import {
  EEPORTAL_LABEL_BASE_TABLE,
  RENTAL_SUBMODULE_CODE,
} from "../../../../../constants";
import {
  MasterService,
  RentalReportsService,
} from "./../../../../../services/hrmnet-api/index";
import { resetBcDynamicPaths } from "../../../../../redux/actions/breadcrumb";
import LanguageLoader from "../../../../../components/language-loader/language-loader";
import {
  RENTAL_T_CONTEXT_KEY,
  RENTAL_MENU_MODULE_KEY,
  RENTAL_MENU_PAGE_KEY,
} from "../../constants";
import moment from "moment";

export const ExportInterfaceFileHistory = async (reference, name) => {
  const fileName = `${name}.xlsx`;
  const body = {
    body: {
      batchReference: reference,
    },
  };
  const responseType = {
    responseType: "blob",
  };
  let res = await RentalReportsService.rentalReportExportInterfaceFileHistory(
    body,
    responseType
  );

  if (res) {
    saveAs(res, fileName);
  }
};

export const PostInterfaceFileHistory = async (reference) => {
  const body = {
    body: {
      batchReference: reference,
    },
  };
  let res = await RentalReportsService.rentalReportPostInterfaceFileHistory(
    body
  );

  if (res) {
    // re-generate records
    const generateBtn = document.getElementsByClassName("generate-button");
    if (generateBtn.length) {
      generateBtn[0].click();
    }
  }
};

const ApplicationReport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();
  const { isMobile } = useWindowSize();
  const menus = useSelector((state) => state.menu.sideBar);
  const rentalMenu = menus.find(
    (x) => x.key === RENTAL_MENU_MODULE_KEY
  )?.subMenus;
  const reportSubMenu = rentalMenu.find(
    (x) => x.key === RENTAL_MENU_PAGE_KEY.SEARCH_REPORT
  )?.subMenus;
  const reportList = reportSubMenu.reduce((a, r) => {
    const reportType = Object.keys(RENTAL_REPORT_TYPE).find(
      (rt) => r.key === RENTAL_REPORT_TYPE[rt].KEY
    );
    if (reportType) {
      a.push(reportType);
    }
    return a;
  }, []);
  const config = reportConfig(t, reportList);
  const reportTypeList = config.map((c) => {
    return c.reportType;
  });
  const tableRef = useRef();
  const tableLoadingRef = useRef();

  const dateRangeFilters = [
    {
      filter: "applicationDate",
      fromKey: "Application_Date_From",
      toKey: "Application_Date_To",
    },
    {
      filter: "approvalDate",
      fromKey: "Approval_Date_From",
      toKey: "Approval_Date_To",
    },
    {
      filter: "reimbursementStartDate",
      fromKey: "Reimbursement_Start_Date_From",
      toKey: "Reimbursement_Start_Date_To",
    },
    {
      filter: "reimbursementEndDate",
      fromKey: "Reimbursement_End_Date_From",
      toKey: "Reimbursement_End_Date_To",
    },
    {
      filter: "employeeTerminateDate",
      fromKey: "Last_Employment_Date_From",
      toKey: "Last_Employment_Date_To",
    },
    {
      filter: "lastModifiedDate",
      fromKey: "Last_Modified_Date_From",
      toKey: "Last_Modified_Date_To",
    },
    {
      filter: "actionDate",
      fromKey: "Action_Date_From",
      toKey: "Action_Date_To",
    },
  ];

  const dateFilters = [
    {
      filter: "periodFrom",
      key: "Period_Date_From",
    },
    {
      filter: "periodTo",
      key: "Period_Date_To",
    },
    {
      filter: "effectiveFrom",
      key: "Effective_Period_Date_From",
    },
    {
      filter: "effectiveTo",
      key: "Effective_Period_Date_To",
    },
  ];

  // load previous setting
  const prevSettingString = sessionStorage.getItem("rentalReportSetting");
  let prevSetting = prevSettingString ? JSON.parse(prevSettingString) : {};

  const [state, setState] = useState({
    activeStep: 0,
    form: prevSetting?.form ? JSON.parse(prevSetting.form) : {},
    formName: "",
    formConfig: null,

    filterOption: {},
    reportTypeIndex: prevSetting?.reportTypeIndex
      ? prevSetting?.reportTypeIndex
      : 0,
    showFilter: false,
    showData: false,
    loadingData: true,
    data: [],
    pagination: {},
    page: prevSetting?.page ? prevSetting?.page : 1,
    pageSize: prevSetting?.pageSize ? prevSetting?.pageSize : 10,
    sortingColumn: null,
    sortingDirection: null,
    reportInit: prevSettingString ? true : false,
    mainLoading: true,
  });

  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );

  // Breadcrumb
  useEffect(() => {
    dispatch(resetBcDynamicPaths());
  }, [dispatch, t]);

  // config
  const loadConfig = async () => {
    // search field options
    const api = RentalReportsService.rentalReportGetFilterOptions({});
    const getCompanyPermission = reportList.includes("RENTAL_ATTACHMENT");
    const getCostCenterPermission = reportList.includes("RENTAL_INTERFACE");
    const getExportOptionPermission = reportList.includes("RENTAL_INTERFACE");
    let apiList = [api];
    let resList = [null];

    if (getCompanyPermission) {
      const companyApi = MasterService.masterGetCompanyList({});
      apiList.push(companyApi);
      resList.push("masterCompanyCodes");
    }
    if (getCostCenterPermission) {
      const costCenterApi = MasterService.masterGetCostCenterList({});
      apiList.push(costCenterApi);
      resList.push("costCenterCodes");
    }
    if (getExportOptionPermission) {
      const exportOptionApi = MasterService.masterGetExportOptionList({});
      apiList.push(exportOptionApi);
      resList.push("exportOptions");
    }

    let options = {};
    await Promise.all(apiList).then((response) => {
      response.forEach((res, i) => {
        if (res) {
          if (i === 0) {
            options = res.data;
          } else {
            options[resList[i]] = res;
          }
        }
      });
    });

    if (Object.keys(options).length) {
      setState((state) => ({
        ...state,
        filterOption: options,
      }));
      prepareFilters(options, state.reportTypeIndex);
    } else {
      prepareFilters(null, state.reportTypeIndex);
    }
  };

  // init
  useEffect(() => {
    loadConfig();
    ScrollToRentalMenuActiveItem();
    let reGenerate = false;
    if (
      prevSetting &&
      Object.keys(prevSetting).filter((k) => k !== "reportTypeIndex").length > 0
    ) {
      reGenerate = true;
    }
    if (state.showData || reGenerate) {
      generateReport();
    }
  }, [selectedLangKey, t]);

  const prepareFilters = (options, reportTypeIndex, isLoadConfig = true) => {
    // search field config
    let res = config[reportTypeIndex].filterFields;

    // filter config
    let filteroptions = options
      ? options
      : state.filterOption
      ? state.filterOption
      : null;
    if (filteroptions) {
      res.data.sections[0].fields.forEach((f) => {
        if (Object.keys(filteroptions).find((o) => o === f.inputName)) {
          f.dropdownOptions = filteroptions[f.inputName];
          // MAPPING
          if (
            reportList[reportTypeIndex] === "RENTAL_ATTACHMENT" &&
            f.inputName === "companyCodes"
          ) {
            f.dropdownOptions = filteroptions["masterCompanyCodes"];
          }
        }
        // MAPPING
        if (reportList[reportTypeIndex] === "RENTAL_INTERFACE") {
          if (f.inputName === "companyCodeSingle") {
            f.dropdownOptions = filteroptions["companyCodes"];
          }
          if (f.inputName === "postingStatus") {
            f.dropdownOptions = filteroptions["exportOptions"];
          }
        }
      });
    }

    if ("form" in prevSetting && isLoadConfig) {
      const prevForm = JSON.parse(prevSetting.form);

      let dateFilterKey = [];
      dateRangeFilters.forEach((f) => {
        dateFilterKey.push(f.fromKey);
        dateFilterKey.push(f.toKey);
      });
      dateFilters.forEach((f) => {
        dateFilterKey.push(f.key);
      });

      res.data.sections[0].fields.forEach((f) => {
        if (
          Object.keys(prevForm).find((o) => o === f.fieldName) &&
          prevForm[f.fieldName].length > 0
        ) {
          if (dateFilterKey.includes(f.fieldName)) {
            f.value = new Date(prevForm[f.fieldName]);
          } else {
            f.value = prevForm[f.fieldName];
          }
        }
      });
    }

    // filter form
    let stepConfig = [];
    stepConfig = [
      {
        sections: res.data.sections.map((sec) => ({
          sectionId: sec.id,
          sectionName: sec.sectionName,
          label: sec.sectionName,
          isEditable: true,
        })),
      },
    ];
    let formConfig = configToFormModel({
      applicationId: null,
      stepConfig: stepConfig,
      sectionFieldConfig: res.data.sections,
      t: t,
    });
    const initForm = initFormValue(res.data.sections);

    // default filter values
    if (reportList[reportTypeIndex] === "RENTAL_INTERFACE") {
      if (initForm && filteroptions) {
        if (
          initForm["Company_Code_Single"] === "" &&
          filteroptions["companyCodes"]
        ) {
          initForm["Company_Code_Single"] =
            filteroptions["companyCodes"][0].value;
          initForm["Company_Code_Single_obj"] =
            filteroptions["companyCodes"][0];
        }
        if (
          initForm["Posting_Status"] === "" &&
          filteroptions["exportOptions"]
        ) {
          initForm["Posting_Status"] = filteroptions["exportOptions"][0].value;
          initForm["Posting_Status_obj"] = filteroptions["exportOptions"][0];
        }
      }
    }

    setState((state) => ({
      ...state,
      formName: res?.data?.formName,
      formConfig,
      showFilter: true,
      form: initForm,
      mainLoading: false,
    }));

    // default generate
    if (reportList[reportTypeIndex] === "RENTAL_INTERFACE") {
      setState((state) => ({
        ...state,
        showData: true,
        loadingData: true,
      }));
    }
  };

  // session storage
  const sessionStorageSetItem = (items, reset) => {
    let currSettingString = sessionStorage.getItem("rentalReportSetting");
    let currSetting = currSettingString ? JSON.parse(currSettingString) : {};

    let newSetting = reset ? items : { ...currSetting, ...items };
    sessionStorage.setItem("rentalReportSetting", JSON.stringify(newSetting));
  };

  // filter
  const getFiltersValue = () => {
    const keys = Object.keys(state.form);
    let values = {};

    const filters = [
      { key: "Company_Code", filter: "companyCodes" },
      { key: "Company_Code_Single", filter: "companyCodes" },
      { key: "Assessment_Year", filter: "assessmentYears" },
      { key: "Application_Status", filter: "applicationStatuses" },
      { key: "Department", filter: "departments" },
      { key: "Employee_Code", filter: "employeeCodes" },
      { key: "Business_Unit", filter: "businessUnits" },
      { key: "Division", filter: "divisions" },
      { key: "Staff_Type", filter: "staffTypes" },
      { key: "Month", filter: "months" },
      { key: "Posting_Status", filter: "postingStatus" },
    ];

    filters.forEach((f) => {
      if (
        keys.includes(f.key) &&
        state.form[f.key] &&
        state.form[f.key].length > 0
      ) {
        values[f.filter] = state.form[f.key];
      }
    });

    let reimbursementStartDate = {};
    let reimbursementEndDate = {};

    dateRangeFilters.forEach((f) => {
      let dateRange = {};
      if (state.form[f.fromKey]) {
        dateRange.from = new Date(state.form[f.fromKey]).toISOString();
      }
      if (state.form[f.toKey]) {
        dateRange.to = new Date(state.form[f.toKey]).toISOString();
      }
      if (state.form[f.fromKey] || state.form[f.toKey]) {
        if (f.filter === "reimbursementStartDate") {
          reimbursementStartDate = dateRange;
        } else if (f.filter === "reimbursementEndDate") {
          reimbursementEndDate = dateRange;
        } else {
          values[f.filter] = dateRange;
        }
      }
    });

    let reimbursementDate = {};
    if (Object.keys(reimbursementStartDate).length) {
      reimbursementDate.from = reimbursementStartDate;
    }
    if (Object.keys(reimbursementEndDate).length) {
      reimbursementDate.to = reimbursementEndDate;
    }
    if (
      Object.keys(reimbursementStartDate).length ||
      Object.keys(reimbursementEndDate).length
    ) {
      values["reimbursementDate"] = reimbursementDate;
    }

    dateFilters.forEach((f) => {
      if (state.form[f.key]) {
        values[f.filter] = moment(state.form[f.key])
          .format("YYYY-MM-DDTHH:mm:ss.000Z")
          .toString();
      }
    });

    const rangeFilters = [
      {
        filter: "applicationNo",
        fromKey: "Application_No_From",
        toKey: "Application_No_To",
      },
    ];

    rangeFilters.forEach((f) => {
      let range = {};
      if (state.form[f.fromKey]) {
        range.from = state.form[f.fromKey];
      }
      if (state.form[f.toKey]) {
        range.to = state.form[f.toKey];
      }
      if (state.form[f.fromKey] || state.form[f.toKey]) {
        values[f.filter] = range;
      }
    });

    return values;
  };

  // navigation
  const redirect = ({ applicationId }) => {
    history.push(`${RENTAL_SUBMODULE_CODE.VIEW_APPLICATION}/${applicationId}`);
  };

  // report
  const setData = (res) => {
    setState((state) => ({
      ...state,
      data: res.data,
      pagination: res?.pagination,
      loadingData: false,
    }));
  };

  const generateReportReq = async () => {
    const values = getFiltersValue();
    const params = {
      body: values,
      page: state.page,
      pageSize: state.pageSize,
    };
    let api;

    switch (reportList[state.reportTypeIndex]) {
      case "APPLICATION_SUMMARY":
        api =
          RentalReportsService.rentalReportGetApplicationSummaryReport(params);
        break;
      case "CLAIM_SUMMARY":
        api = RentalReportsService.rentalReportGetClaimSummaryReport(params);
        break;
      case "APPLICATION_LOG":
        api = RentalReportsService.rentalReportGetApplicationLogReport(params);
        break;
      case "ANNUAL_TAX":
        api = RentalReportsService.rentalReportGetAnnualTaxReport(params);
        break;
      case "CLAIM_DETAIL":
        api = RentalReportsService.rentalReportGetClaimDetailReport(params);
        break;
      case "MONTHLY_RENTAL":
        api = RentalReportsService.rentalReportGetMonthlyRentalReport(params);
        break;
      case "RENTAL_ATTACHMENT":
        api = RentalReportsService.rentalReportGetAttachmentLogReport(params);
        break;
      case "RENTAL_INTERFACE":
        if (!state.sortingColumn && !state.sortingDirection) {
          params.sorting = "-exportedAt";
        } else {
          params.sorting = `${state.sortingDirection === 1 ? "+" : "-"}${
            state.sortingColumn
          }`;
        }
        api =
          RentalReportsService.rentalReportGetInterfaceFileHistories(params);
        break;
      default:
        break;
    }

    if (api) {
      let [res] = await Promise.all([api]);
      if (res) {
        // Pagination handling by frontend
        if (reportList[state.reportTypeIndex] === "RENTAL_INTERFACE") {
          /*const resData = res.data.payload;
          const targetData = resData.slice((params.page - 1) * params.pageSize, params.page * params.pageSize);
          const pagination = {
            pageIndex: params.page,
            pageSize: params.pageSize,
            totalPages: Math.ceil(resData.length/params.pageSize) || 0,
            totalRecords: resData.length
          };*/
          const resData = res.data.payload;
          const pagination = res.pagination;

          res = {
            data: resData,
            pagination: pagination,
          };
        }
        setData(res);
      }
    }
  };

  const exportRequestReq = async () => {
    const values = getFiltersValue();
    const fileName = `${getConfigItem("title")}.xlsx`;
    const body = {
      body: values,
    };
    const responseType = {
      responseType: "blob",
    };
    let res;

    switch (reportList[state.reportTypeIndex]) {
      case "APPLICATION_SUMMARY":
        res =
          await RentalReportsService.rentalReportExportApplicationSummaryReport(
            body,
            responseType
          );
        break;
      case "CLAIM_SUMMARY":
        res = await RentalReportsService.rentalReportExportClaimSummaryReport(
          body,
          responseType
        );
        break;
      case "APPLICATION_LOG":
        res = await RentalReportsService.rentalReportExportApplicationLogReport(
          body,
          responseType
        );
        break;
      case "ANNUAL_TAX":
        res = await RentalReportsService.rentalReportExportAnnualTaxReport(
          body,
          responseType
        );
        break;
      case "CLAIM_DETAIL":
        res = await RentalReportsService.rentalReportExportClaimDetailReport(
          body,
          responseType
        );
        break;
      case "MONTHLY_RENTAL":
        res = await RentalReportsService.rentalReportExportMonthlyRentalReport(
          body,
          responseType
        );
        break;
      case "RENTAL_ATTACHMENT":
        res = await RentalReportsService.rentalReportExportAttachmentLogReport(
          body,
          responseType
        );
        break;
      case "RENTAL_INTERFACE":
        const paramBody = { ...body.body };
        if (paramBody.companyCodes) {
          paramBody.companyCode = paramBody.companyCodes;
          delete paramBody.companyCodes;
        }
        const reqBody = {
          body: paramBody,
        };
        res = await RentalReportsService.rentalReportExportInterfaceFile(
          reqBody,
          responseType
        );
        break;
      default:
        break;
    }

    if (res) {
      saveAs(res, fileName);
    }
  };

  const getReport = (action) => {
    if (action === "generate") {
      generateReportReq();
    } else if (action === "export") {
      exportRequestReq();
    }
  };

  useEffect(() => {
    if (state.loadingData && (state.reportInit || state.page === 1)) {
      getReport("generate");
    }
  }, [state.page, state.pageSize, state.loadingData]);

  const generateReport = () => {
    setState((state) => ({
      ...state,
      showData: true,
      loadingData: true,
    }));
    sessionStorageSetItem({
      form: JSON.stringify(state.form),
    });
  };

  const exportReport = () => {
    sessionStorageSetItem({
      form: JSON.stringify(state.form),
    });
    getReport("export");
  };

  // Table Configurations
  const tableGetData = (searchConfig) => {
    // if (state.data) {
    if (!state.reportInit) {
      setState((state) => ({
        ...state,
        reportInit: true,
      }));
    } else {
      if (searchConfig) {
        setState((state) => ({
          ...state,
          page: searchConfig.pi,
          pageSize: searchConfig.ps,
          loadingData: true,
        }));

        sessionStorageSetItem({
          page: searchConfig.pi,
          pageSize: searchConfig.ps,
        });
      }
    }

    if (reportList[state.reportTypeIndex] === "RENTAL_INTERFACE") {
      let sortingColumn;
      let sortingDirection;
      if (!state.sortingColumn && !state.sortingDirection) {
        sortingColumn = "exportedAt";
        sortingDirection = "-1";
      } else {
        sortingColumn = searchConfig
          ? searchConfig.sortObj.key
          : state.sortingColumn;
        sortingDirection = searchConfig
          ? searchConfig.sortObj.direction
          : state.sortingDirection;
      }

      setState((state) => ({
        ...state,
        sortingColumn: sortingColumn,
        sortingDirection: sortingDirection,
      }));

      searchConfig = {
        ...BT_SEARCH,
        sortObj: {
          key: sortingColumn,
          direction: sortingDirection,
        },
      };
    }

    return {
      datas: state.data,
      searchConfig: searchConfig ? searchConfig : BT_SEARCH,
      total: state.pagination.totalRecords,
    };
  };
  const BT_SEARCH = {
    ...DEFAULT_BT_SEARCH,
    pi: state.page,
    ps: state.pageSize,
    sortObj: {
      key: "rentalReimbursementId",
      direction: -1,
    },
  };

  const tableLoadingData = () => {
    return new Promise(() => {});
  };

  const getConfigItem = (item) => {
    return config[state.reportTypeIndex][item];
  };

  const configModel = getBTConfig({
    title: getConfigItem("title"),
    columns: getConfigItem("columnConfig"),
    hasIndex: false,
    mode: "list",
    defaultMode: "list",
    showGlobal: false,
    hasColumnSelector: false,
    onRowClick: getConfigItem("rowClick")
      ? (e) => redirect({ applicationId: e.data.id })
      : null,
    rowHover: true,
    responsive: true,
  });

  const clear = () => {
    let resetForm = { ...state.form };
    Object.keys(resetForm).forEach((f) => {
      resetForm[f] = undefined;
    });

    setState((state) => ({
      ...state,
      showData: false,
      // loadingData: true,
      form: resetForm,
      page: 1,
      sortingColumn: null,
      sortingDirection: null,
      reportInit: false,
    }));
    sessionStorageSetItem(
      {
        reportTypeIndex: state.reportTypeIndex,
      },
      true
    );
  };

  const selectReport = (index) => {
    if (state.reportTypeIndex !== index) {
      clear();
    }
    setState((state) => ({
      ...state,
      reportTypeIndex: index,
    }));
    sessionStorageSetItem(
      {
        reportTypeIndex: index,
      },
      true
    );
    prepareFilters(null, index, false);
  };

  const renderFooter = () => (
    <div className="footer p-grid p-align-center p-justify-between">
      <div className="button-group-1">
        <Button onClick={() => clear()} className="p-button-outlined secondary">
          {t("rental_report_footerClear")}
        </Button>
      </div>
      <div
        className={`button-group-2 ${
          state.showData ? "has-export-button" : ""
        }`}
      >
        {state.showData ? (
          <Button
            onClick={() => exportReport()}
            className="p-button next-button"
          >
            {t("rental_report_footerExportToReport")}
          </Button>
        ) : null}
        <Button
          onClick={() => generateReport()}
          className="p-button next-button generate-button"
        >
          {t("rental_report_footerGenerateResult")}
        </Button>
      </div>
    </div>
  );

  const renderFormSkeleton = () => formSkeleton;

  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          RENTAL_T_CONTEXT_KEY.COMMON,
          RENTAL_T_CONTEXT_KEY.REPORT,
          RENTAL_T_CONTEXT_KEY.REPORT_COLUMNS,
        ]}
      />
      <div className="rental-report">
        <RentalMenu
          menu={rentalMenu}
          currentPageKey={RENTAL_MENU_PAGE_KEY.SEARCH_REPORT}
        />

        <div className="title">{t("rental_report_title")}</div>

        <Animated
          animationIn="slideInRight"
          animationOut="slideOutRight"
          animationInDuration={200}
          animationOutDuration={200}
          isVisible={true}
        >
          {state.mainLoading ? (
            <div style={{ marginTop: 20 }}>{renderFormSkeleton()}</div>
          ) : (
            <>
              <div className="filter-container">
                {isMobile ? (
                  <div className="dropdown-wrapper">
                    <Dropdown
                      value={reportTypeList[state.reportTypeIndex].value}
                      options={reportTypeList}
                      onChange={(e) => {
                        const reportIndex = reportTypeList.findIndex(
                          (r) => r.value === e.value
                        );
                        selectReport(reportIndex);
                      }}
                    />
                  </div>
                ) : (
                  <TabMenu
                    model={reportTypeList}
                    activeIndex={state.reportTypeIndex}
                    onTabChange={(e) => selectReport(e.index)}
                  />
                )}
              </div>

              {state.showFilter ? (
                <div className="filter-fields-container">
                  <MultiStepForm
                    form={state.form}
                    activeStep={state.activeStep}
                    config={state.formConfig}
                    touched={state.touched}
                  />
                </div>
              ) : null}

              {state.showData ? (
                state.loadingData ? (
                  <BaseTable
                    ref={tableLoadingRef}
                    isClientSize={false}
                    configModel={configModel}
                    searchConfig={BT_SEARCH}
                    searchFn={tableLoadingData}
                  />
                ) : (
                  <div className="report-list-table">
                    <BaseTable
                      ref={tableRef}
                      isClientSize={false}
                      configModel={configModel}
                      searchConfig={BT_SEARCH}
                      searchFn={(s) => tableGetData(s)}
                      disableMobile={true}
                    />
                  </div>
                )
              ) : null}
            </>
          )}
        </Animated>

        {/* Footer */}
        {renderFooter()}
      </div>
    </>
  );
};

export default ApplicationReport;
