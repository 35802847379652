import "./report.scss";

//components
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { TabMenu } from "primereact/tabmenu";
import { saveAs } from "file-saver";

import { resetBcDynamicPaths } from "../../../../../redux/actions/breadcrumb";
import LanguageLoader from "../../../../../components/language-loader/language-loader";
import { EEPORTAL_LABEL_BASE_TABLE } from "../../../../../constants";
import {
  NWOW_T_CONTEXT_KEY,
  NWOW_MENU_MODULE_KEY,
  NWOW_MENU_PAGE_KEY,
  NWOW_MENU_ACTION_KEY,
} from "../../constants/contants";
import { useTranslation } from "react-i18next";
import { Animated } from "react-animated-css";
import Button from "../../../rental/components/action-button";
import { useHistory } from "react-router";
import { useWindowSize } from "../../../utils/window-size/useWindowSize";
import {
  getReportColumnConfig,
  getReportFilterConfig,
  NWOW_REPORT_TYPE,
  NWOW_REPORT_KEY,
  parseApiFilter,
  prepareDefaultFilterValue,
  prepareFilterPayload,
  getFilterOptions,
  getNwowFilterOptions,
} from "./config";
import BaseForm from "../../../../../components/base-form/base-form";
import BaseTable from "../../../../../components/base-table/base-table-portal-rental";
import {
  DEFAULT_BT_SEARCH,
  getBTConfig,
} from "../../../../../components/base-table/base-table-model";
import { NwowReportService } from "../../../../../services/hrmnet-api";
import { isResponseOk } from "../../../../../utils/utils";
import { NwowMenu, ScrollToNwowMenuActiveItem } from "../../components";

const Report = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const [reportSelectIndex, setReportSelectIndex] = useState(0);
  const tableRef = useRef();

  const menus = useSelector((state) => state.menu.sideBar);
  const nwowMenu = menus.find((x) => x.key === NWOW_MENU_MODULE_KEY)?.subMenus;
  const reportSubMenu = nwowMenu.find(
    (x) => x.key === NWOW_MENU_PAGE_KEY.ENQUIRY_REPORT
  )?.subMenus;

  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );

  // Filter
  const [reportFilterMap, setReportFilterMap] = useState({});
  const [reportFilterFormMap, setReportFilterFormMap] = useState({});
  const [isGenerateReport, setIsGenerateReport] = useState(false);
  //
  let reportTabList = [
    ...(reportSubMenu &&
    reportSubMenu.find((x) => x.key === NWOW_REPORT_KEY.NWOW_TRANSACTION_REPORT)
      ? [
          {
            label: t("Nwow_Report_NwowTransactionReport"),
            reportType: NWOW_REPORT_TYPE.NWOW_TRANSACTION_REPORT,
          },
        ]
      : []),
  ].map((report, idx) => ({
    ...report,
    value: idx, // for dropdown value
  }));

  useEffect(() => {
    dispatch(resetBcDynamicPaths());
  }, [dispatch]);

  /**
   * On Report select change, get filter
   */
  useEffect(() => {
    const loadFilter = async () => {
      const selectedReport = reportTabList[reportSelectIndex].reportType;
      const res = await getNwowFilterOptions(selectedReport);
      //
      if (isResponseOk(res) && res.data) {
        const filters = parseApiFilter(selectedReport, res.data, t);
        setReportFilterMap((filterMap) => ({
          ...filterMap,
          [selectedReport]: filters,
        }));
        //
        const defaultFilerValue = prepareDefaultFilterValue(
          selectedReport,
          filters
        );
        setReportFilterFormMap((formMap) => ({
          ...formMap,
          [selectedReport]: {
            ...defaultFilerValue,
          },
        }));
      }
    };

    const reset = () => {
      setIsGenerateReport(false);
    };

    if (
      reportTabList.length &&
      !(reportTabList[reportSelectIndex].reportType in reportFilterMap)
    ) {
      loadFilter();
    }

    reset();
  }, [reportSelectIndex]);

  useEffect(() => {
    generateReport();
    ScrollToNwowMenuActiveItem();
  }, [selectedLangKey]);

  // Action
  const generateReport = () => {
    if (!isGenerateReport) {
      setIsGenerateReport(true);
    } else {
      tableRef.current.reload();
    }
  };

  const clear = () => {
    const selectedReport = reportTabList[reportSelectIndex].reportType;
    setReportFilterFormMap((formMap) => ({
      ...formMap,
      [selectedReport]: {},
    }));
    setIsGenerateReport(false);
  };

  const back = () => {
    history.goBack();
  };

  const exportReport = async () => {
    let res;
    const selectedReport = reportTabList[reportSelectIndex].reportType;
    const fileName = `${reportTabList[reportSelectIndex].label}.xlsx`;
    const payload = prepareFilterPayload(
      selectedReport,
      reportFilterFormMap[selectedReport]
    );
    switch (selectedReport) {
      case NWOW_REPORT_TYPE.NWOW_TRANSACTION_REPORT:
        res = await NwowReportService.nwowReportExportNwowTransactionReport(
          {
            body: payload,
          },
          {
            responseType: "blob",
          }
        );
        break;
      default:
        break;
    }

    if (res && !res.TraceId) {
      saveAs(res, fileName);
    }
  };

  const renderHeader = () => {
    return (
      <div className="header">
        <NwowMenu
          menu={nwowMenu}
          currentPageKey={NWOW_MENU_PAGE_KEY.ENQUIRY_REPORT}
        />
        <div className="title">{t("Nwow_Report_Title")}</div>
      </div>
    );
  };

  const renderFilterSection = () => {
    if (!reportTabList.length) return;
    const selectedReport = reportTabList[reportSelectIndex].reportType;
    const filterWithOption = [];
    if (reportFilterMap[selectedReport]) {
      Object.entries(reportFilterMap[selectedReport]).forEach(
        ([key, options]) => {
          if (options.length > 0) {
            filterWithOption.push(key);
          }
        }
      );
    }
    //
    return (
      <div className="section-container filter-container">
        <BaseForm
          id="leave-report-filter-form"
          config={getReportFilterConfig(
            selectedReport,
            {
              ...reportFilterMap[selectedReport],
              t,
            },
            filterWithOption
          )}
          form={reportFilterFormMap?.[selectedReport]}
          onChange={(change) => {
            setReportFilterFormMap((formMap) => ({
              ...formMap,
              [selectedReport]: {
                ...change.state.form,
              },
            }));
          }}
        />
      </div>
    );
  };

  const renderTabSelect = () => {
    return (
      <div className="report-tab">
        <Animated
          animationIn="slideInRight"
          animationOut="slideOutRight"
          animationInDuration={200}
          animationOutDuration={200}
          isVisible={true}
        >
          <div className="dropdown-wrapper p-d-md-none">
            <Dropdown
              value={reportTabList[reportSelectIndex].value ?? 0}
              options={reportTabList}
              onChange={(e) => {
                setReportSelectIndex(e.value);
              }}
            />
          </div>
          <div className="p-d-none p-d-md-inline-flex ">
            <TabMenu
              model={reportTabList}
              activeIndex={reportSelectIndex}
              onTabChange={(e) => {
                setReportSelectIndex(e.index);
              }}
            />
          </div>
        </Animated>
      </div>
    );
  };

  const fetchReport = async (searchConfig = DEFAULT_BT_SEARCH) => {
    const { pi, ps } = searchConfig;
    const selectedReport = reportTabList[reportSelectIndex].reportType;
    const payload = prepareFilterPayload(
      selectedReport,
      reportFilterFormMap[selectedReport]
    );
    let res;
    //
    switch (selectedReport) {
      case NWOW_REPORT_TYPE.NWOW_TRANSACTION_REPORT:
        res = await NwowReportService.nwowReportGetNwowTransactionReport({
          page: pi,
          pageSize: ps,
          body: payload,
        });
        break;
      default:
        break;
    }

    const tableSearchConfig = searchConfig
      ? {
          ...DEFAULT_BT_SEARCH,
          pi: pi,
          ps: ps,
        }
      : DEFAULT_BT_SEARCH;

    if (isResponseOk(res)) {
      return {
        datas: res.data,
        searchConfig: tableSearchConfig,
        total: res.pagination.totalRecords,
      };
    }

    return {
      datas: [],
      searchConfig: DEFAULT_BT_SEARCH,
      total: 0,
    };
  };

  const renderReportSection = () => {
    //

    if (!reportTabList.length || !isGenerateReport) return null;
    //
    const configModel = getBTConfig({
      columns: getReportColumnConfig(
        reportTabList[reportSelectIndex].reportType,
        t
      ),
      hasIndex: false,
      mode: "list",
      defaultMode: "list",
      showGlobal: false,
      hasColumnSelector: false,
      rowHover: true,
    });

    return (
      <div className="section-container report-container">
        <div className="report-title">
          {reportTabList[reportSelectIndex].label}
        </div>
        <BaseTable
          ref={tableRef}
          configModel={configModel}
          searchConfig={DEFAULT_BT_SEARCH}
          searchFn={fetchReport}
          disableMobile={true}
        />
      </div>
    );
  };

  const renderFooter = () => (
    <div className="footer p-grid p-align-center p-justify-between">
      <div className="left-button-group">
        <Button className="p-button-outlined secondary" onClick={back}>
          {t("Nwow_common_actionBack")}
        </Button>
        {reportTabList.length > 0 && (
          <Button className="p-button-outlined secondary" onClick={clear}>
            {t("Nwow_Common_ActionClear")}
          </Button>
        )}
      </div>

      <div className="right-button-group">
        {reportTabList.length > 0 && isGenerateReport && (
          <Button className="p-button" onClick={exportReport}>
            <span className="btn-desktop-text">
              {t("Nwow_Report_ActionExport")}
            </span>
            <span className="btn-mobile-text">
              {t("Nwow_Report_ActionExportMobile")}
            </span>
          </Button>
        )}

        {reportTabList.length > 0 && (
          <Button className="p-button" onClick={generateReport}>
            <span className="btn-desktop-text">
              {t("Nwow_Report_ActionGenerate")}
            </span>
            <span className="btn-mobile-text">
              {t("Nwow_Report_ActionGenerateMobile")}
            </span>
          </Button>
        )}
      </div>
    </div>
  );

  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          NWOW_T_CONTEXT_KEY.COMMON,
          NWOW_T_CONTEXT_KEY.REPORT,
          "Nwow.Report.Columns",
        ]}
      />

      <div className="leave-container leave-report">
        {/* Header */}
        {renderHeader()}

        {/* Filter */}
        <Animated
          animationIn="slideInRight"
          animationOut="slideOutRight"
          animationInDuration={200}
          animationOutDuration={200}
          isVisible={true}
        >
          {renderFilterSection()}
          {renderTabSelect()}
        </Animated>

        {/* Report */}
        <Animated
          animationIn="slideInRight"
          animationOut="slideOutRight"
          animationInDuration={200}
          animationOutDuration={200}
          isVisible={true}
        >
          {renderReportSection()}
        </Animated>

        {/* Footer */}
        {renderFooter()}
      </div>
    </>
  );
};

export default Report;
