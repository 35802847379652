import moment from "moment";
import { openModal } from "../../../../../redux/actions/modal";
import { BCType } from "../../../../../components/base-control/base-control";
import { showSpinner } from "../../../../../redux/actions/spinner";
import { showTimestampToastSuccess } from "../../../../../services/utils/message";
import { isResponseOk } from "../../../../../utils/utils";
import { NwowService as NwowService } from "../../../../../services/hrmnet-api";
import { FUNCTION_CODE, NWOW_SUBMODULE_CODE } from "../../../../../constants";
import { PortalLink } from "../../../../../services/utils";

const returnToApprovalList = (history) => {
  history.push(
    PortalLink(`${FUNCTION_CODE.Nwow}/${NWOW_SUBMODULE_CODE.APPROVAL}`)
  );
};

// approve
export const confirmApproveApplication = ({
  applicationId,
  date,
  dispatch,
  history,
  t,
}) => {
  dispatch(
    openModal({
      title: t("Nwow_approval_modal_title"),
      content: t("Nwow_approval_modal_approveNwowContent", {
        nwowDays: moment(date).format("YYYY-MM-DD"),
      }),
      classNameMainDialog: "confirm-message-modal form-modal",
      primaryButtonText: t("Nwow_common_actionConfirm"),
      form: {
        config: {
          controls: [
            {
              key: "Remarks", // custom field
              label: t("Nwow_approval_modal_remarks"),
              type: BCType.input,
            },
          ],
        },
        form: {},
      },
      primaryButtonClickFn: async ({ closeFn, form }) => {
        await approveApplication({
          applicationId,
          remarks: form["Remarks"],
          dispatch,
          history,
          t,
        });
        closeFn();
      },
      secondButtonClickFn: ({ closeFn }) => {
        closeFn();
      },
      force_touched: true,
    })
  );
};

const approveApplication = async ({
  applicationId,
  remarks,
  dispatch,
  history,
  t,
}) => {
  try {
    dispatch(showSpinner(true));
    const res = await NwowService.nwowApprovalAction({
      body: {
        id: [applicationId],
        action: "approve",
        remarks,
      },
    });
    if (isResponseOk(res)) {
      returnToApprovalList(history);
      showTimestampToastSuccess({
        message: t("Nwow_approval_toast_approveSuccess"),
        t: t,
      });
    }
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(showSpinner(false));
  }
};

// reject
export const confirmRejectApplication = ({
  applicationId,
  date,
  dispatch,
  history,
  t,
}) => {
  dispatch(
    openModal({
      title: t("Nwow_approval_modal_title"),
      content: t("Nwow_approval_modal_rejectNwowContent", {
        nwowDays: moment(date).format("YYYY-MM-DD"),
      }),
      classNameMainDialog: "confirm-message-modal form-modal",
      primaryButtonText: t("Nwow_common_actionConfirm"),
      form: {
        config: {
          controls: [
            {
              key: "Remarks", // custom field
              label: t("Nwow_approval_modal_remarks"),
              type: BCType.input,
            },
          ],
        },
        form: {},
      },
      primaryButtonClickFn: async ({ closeFn, form }) => {
        await rejectApplication({
          applicationId,
          remarks: form["Remarks"],
          dispatch,
          history,
          t,
        });
        closeFn();
      },
      secondButtonClickFn: ({ closeFn }) => {
        closeFn();
      },
      force_touched: true,
    })
  );
};

const rejectApplication = async ({
  applicationId,
  remarks,
  dispatch,
  history,
  t,
}) => {
  try {
    dispatch(showSpinner(true));
    const res = await NwowService.nwowApprovalAction({
      body: {
        id: [applicationId],
        action: "reject",
        remarks,
      },
    });
    if (isResponseOk(res)) {
      returnToApprovalList(history);
      showTimestampToastSuccess({
        message: t("Nwow_approval_toast_rejectSuccess"),
        t: t,
      });
    }
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(showSpinner(false));
  }
};
