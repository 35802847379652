import React, { useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useDispatch } from "react-redux";

// components
import {
  CreateApplication,
  EditRentalApplication,
  ChangeRentalApplication,
  ViewRentalApplication,
  ViewRentalApplicationHistory,
  ListRentalApplication,
  WithdrawRentalApplication,
  SubmitClaim,
  ApproveList,
  ApproveApplication,
  ApproveClaim,
  FinalizeApplication,
  SubmitClaimUpload,
  ApplicationReport,
  Applications,
} from "./modules";

import { RENTAL_SUBMODULE_CODE } from "./../../../constants/index";
import PageNotFound from "./../public/error/employee-error-404";
import { resetBcDynamicPaths } from "../../../redux/actions/breadcrumb";
import RentalConfigLoader from "./rental-config-loader";

const Rental = (props) => {
  let match = useRouteMatch();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetBcDynamicPaths());
  }, [dispatch]);

  const routes = [
    {
      id: "list",
      path: `${match.path}`,
      component: ListRentalApplication,
      exact: true,
      strict: true,
    },
    {
      id: "view",
      path: `${match.path}/${RENTAL_SUBMODULE_CODE.VIEW_APPLICATION}/:applicationId`,
      component: ViewRentalApplication,
      exact: true,
      strict: true,
    },
    {
      id: "view-history",
      path: `${match.path}/${RENTAL_SUBMODULE_CODE.VIEW_APPLICATION_HISTORY}/:applicationId`,
      component: ViewRentalApplicationHistory,
      exact: true,
      strict: true,
    },
    {
      id: "applications",
      path: `${match.path}/${RENTAL_SUBMODULE_CODE.APPLICATIONS}`,
      component: Applications,
      exact: true,
      strict: true,
    },
    {
      id: "create",
      path: `${match.path}/${RENTAL_SUBMODULE_CODE.CREATE_APPLICATION}`,
      component: CreateApplication,
      exact: true,
      strict: true,
    },
    {
      id: "edit",
      path: `${match.path}/${RENTAL_SUBMODULE_CODE.EDIT_APPLICATION}/:applicationId`,
      component: EditRentalApplication,
      exact: true,
      strict: true,
    },
    {
      id: "change",
      path: `${match.path}/${RENTAL_SUBMODULE_CODE.CHANGE_APPLICATION}/:applicationId`,
      component: ChangeRentalApplication,
      exact: true,
      strict: true,
    },
    {
      id: "withdraw",
      path: `${match.path}/${RENTAL_SUBMODULE_CODE.WITHDRAW_APPLICATION}/:applicationId`,
      component: WithdrawRentalApplication,
      exact: true,
      strict: true,
    },
    {
      id: "submit-claim",
      path: `${match.path}/${RENTAL_SUBMODULE_CODE.SUBMIT_CLAIM}/:applicationId`,
      component: SubmitClaim,
      exact: true,
      strict: true,
    },
    {
      id: "submit-claim-upload",
      path: `${match.path}/${RENTAL_SUBMODULE_CODE.SUBMIT_CLAIM}/:applicationId/upload`,
      component: SubmitClaimUpload,
      exact: true,
      strict: true,
    },
    {
      id: "approval",
      path: `${match.path}/${RENTAL_SUBMODULE_CODE.APPROVAL}`,
      component: ApproveList,
      exact: true,
      strict: true,
    },
    {
      id: "approve-application",
      path: `${match.path}/${RENTAL_SUBMODULE_CODE.APPROVAL}/:applicationId/${RENTAL_SUBMODULE_CODE.APPROVE_APPLICATION}`,
      component: ApproveApplication,
      exact: true,
      strict: true,
    },
    {
      id: "approve-claim",
      path: `${match.path}/${RENTAL_SUBMODULE_CODE.APPROVAL}/:applicationId/${RENTAL_SUBMODULE_CODE.APPROVE_CLAIM}`,
      component: ApproveClaim,
      exact: true,
      strict: true,
    },
    {
      id: "finalize-application",
      path: `${match.path}/${RENTAL_SUBMODULE_CODE.APPROVAL}/:applicationId/${RENTAL_SUBMODULE_CODE.FINALIZE_APPLICATION}`,
      component: FinalizeApplication,
      exact: true,
      strict: true,
    },
    {
      id: "report",
      path: `${match.path}/${RENTAL_SUBMODULE_CODE.REPORT}`,
      component: ApplicationReport,
      exact: true,
      strict: true,
    },
  ];

  return (
    <>
      <RentalConfigLoader />
      <Switch>
        {props.routeConfig ? props.routeConfig(routes) : null}
        <Route component={PageNotFound} />
      </Switch>
    </>
  );
};

export default Rental;
