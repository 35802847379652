import "./approval.scss";

//components
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { resetBcDynamicPaths } from "../../../../../redux/actions/breadcrumb";
import { openModal } from "../../../../../redux/actions/modal";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  getColumModel,
  DEFAULT_BT_SEARCH,
  getBTConfig,
} from "./../../../../../components/base-table/base-table-model";
import { formSkeleton } from "../../utils";
import moment from "moment";
import BaseTable from "../../../../../components/base-table/base-table-portal-rental";
import {
  FUNCTION_CODE,
  NWOW_SUBMODULE_CODE,
  EEPORTAL_LABEL_BASE_TABLE,
} from "../../../../../constants";
import { showSpinner } from "../../../../../redux/actions/spinner";
import { isResponseOk } from "../../../../../utils/utils";
import { showTimestampToastSuccess } from "../../../../../services/utils/message";
import LanguageLoader from "../../../../../components/language-loader/language-loader";
import { NWOW_T_CONTEXT_KEY } from "../../constants/contants";
import {
  NWOW_WORKFLOW_STATUS,
  NWOW_MENU_MODULE_KEY,
  NWOW_MENU_PAGE_KEY,
  NWOW_MENU_ACTION_KEY,
} from "../../constants/contants";
import { NwowMenu, ScrollToNwowMenuActiveItem } from "../../components";

// services
import { NwowService } from "../../../../../services/hrmnet-api";
import { sampleApprovalList } from "./sample";
import { PortalLink } from "../../../../../services/utils";

const DATE_FORMAT = "yyyy-MM-DD (ddd)";
const TIME_FORMAT = "h:mm A";
const DATETIME_FORMAT = "yyyy-MM-DD HH:mm";

const Approval = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let history = useHistory();
  const tableRef = useRef();

  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );

  const menus = useSelector((state) => state.menu.sideBar);
  const nwowMenu = menus.find((x) => x.key === NWOW_MENU_MODULE_KEY)?.subMenus;
  const nwowPlannerPermission = !!nwowMenu.find(
    (x) => x.key === NWOW_MENU_ACTION_KEY.NWOW_PLANNER
  );
  const approverSearchPermission = !!nwowMenu.find(
    (x) => x.key === NWOW_MENU_ACTION_KEY.EMPLOYEE_SEARCH
  );

  const modeList = [
    {
      label: t("Nwow_approval_filter_myApprovalList"),
      value: 0,
    },
    {
      label: t("Nwow_approval_filter_approverSearch"),
      value: 1,
    },
  ];

  const filterStatusList = [
    {
      label: t("Nwow_approval_filter_pendingApproval"),
      value: NWOW_WORKFLOW_STATUS.IN_PROGRESS,
    },
    {
      label: t("Nwow_approval_filter_approvedApplications"),
      value: NWOW_WORKFLOW_STATUS.COMPLETE,
    },
    {
      label: t("Nwow_approval_filter_rejectedApplications"),
      value: NWOW_WORKFLOW_STATUS.REJECT,
    },
    {
      label: t("Nwow_approval_filter_all"),
      value: -1,
    },
  ];

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [filterStatus, setFilterStatus] = useState(filterStatusList[0].value);
  const [expandedRows, setExpandedRows] = useState([]);
  const [selectedMode, setSelectedMode] = useState(modeList[0].value);
  const [approvers, setApprovers] = useState([]);
  const [selectedApprover, setSelectedApprover] = useState(null);
  const [approverData, setApproverData] = useState([]);

  const remarksRef = useRef();
  const updateRemarks = (value) => {
    remarksRef.current = value;
  };

  useEffect(() => {
    dispatch(resetBcDynamicPaths());
  }, [dispatch]);

  // init
  const loadData = async () => {
    var cmd = await NwowService.nwowGetNwowApprovalList({});
    let [res] = await Promise.all([cmd]);

    // mocked data
    // res = sampleApprovalList;
    if (res) {
      setData(res?.data || []);
      setFilterDataForDisplay(res?.data || []);
    } else {
      setData([]);
    }
    setIsLoading(false);
  };

  const loadApprovers = async () => {
    var cmd = await NwowService.nwowGetNwowApproversList({});
    let [res] = await Promise.all([cmd]);
    if (res) {
      const approversList = res.map((r) => {
        return {
          ...r,
          label: r.description,
        };
      });
      setApprovers(approversList);
    }
  };

  const loadApproverData = async (employeeCode) => {
    setIsLoadingData(true);
    var cmd = await NwowService.nwowGetNwowApprovalListByApprover({
      employeeCode,
    });
    let [res] = await Promise.all([cmd]);
    if (res && res.data) {
      setApproverData(res.data || []);
      setFilterDataForDisplay(res?.data || []);
    }
  };

  const setFilterDataForDisplay = (dataSource) => {
    setIsLoadingData(true);
    const dataList = dataSource
      ? dataSource
      : selectedMode === 1
      ? approverData
      : data;
    const filteredData = dataList.filter(
      (d) =>
        (filterStatus === 0 && d.isPending) ||
        (filterStatus === 1 && d.isApproved) ||
        (filterStatus === 2 && d.isRejected) ||
        (filterStatus === -1 && (d.isPending || d.isApproved || d.isRejected))
    );
    setFilteredData(filteredData);
  };

  useEffect(() => {
    loadData();
    ScrollToNwowMenuActiveItem();
    if (approverSearchPermission) {
      loadApprovers();
    }
  }, [selectedLangKey]);

  useEffect(() => {
    setFilterDataForDisplay();
    if (selectedMode === 0) {
      setSelectedApprover(null);
      setApproverData([]);
    }
  }, [selectedMode]);

  useEffect(() => {
    if (selectedApprover) {
      loadApproverData(selectedApprover);
    }
  }, [selectedApprover]);

  useEffect(() => {
    setFilterDataForDisplay();
    if (filterStatus !== NWOW_WORKFLOW_STATUS.IN_PROGRESS) {
      setSelectedData([]);
    }
  }, [filterStatus]);

  useEffect(() => {
    setIsLoadingData(false);
  }, [filteredData]);

  // table
  const tableGetData = async () => {
    if (isLoading) {
      return new Promise(() => {});
    } else {
      return {
        datas: filteredData,
        searchConfig: BT_SEARCH,
        total: filteredData.length,
      };
    }
  };
  const BT_SEARCH = {
    ...DEFAULT_BT_SEARCH,
    sortObj: {
      key: "applicationId",
      direction: -1,
    },
  };

  const configColumnEmployeeCode = getColumModel({
    header: t("Nwow_approval_table_employeeID"),
    key: "employeeCode",
    dataType: "string",
    sortable: true,
    width: 7,
    hideInMobile: true,
  });
  const configColumnEmployeeName = getColumModel({
    header: t("Nwow_approval_table_employeeName"),
    key: "employeeName",
    dataType: "string",
    sortable: true,
    width: 15,
  });
  const configColumnNwowName = getColumModel({
    header: t("Nwow_approval_table_nwowType"),
    key: "nwowName",
    dataType: "string",
    sortable: true,
    hideInMobile: true,
  });
  const configColumnTransactionType = getColumModel({
    header: t("Nwow_approval_table_transactionType"),
    key: "transactionType",
    dataType: "string",
    sortable: true,
    width: 11,
    hideInMobile: true,
  });
  const configColumnNwowPeriod = getColumModel({
    header: t("Nwow_approval_table_nwowPeriod"),
    key: "nwowPeriod",
    dataType: "string",
    sortable: true,
    width: 11,
    hideInDesktop: true,
    render: (data) =>
      data.nwowFrom &&
      moment(data.nwowForm).isValid() &&
      data.nwowTo &&
      moment(data.nwowTo).isValid() ? (
        <>
          <span className="nwowPeriod-from">
            {moment(data.nwowFrom).format(DATE_FORMAT).toUpperCase()} -{" "}
          </span>
          <br />
          <span className="nwowPeriod-to">
            {moment(data.nwowTo).format(DATE_FORMAT).toUpperCase()}
          </span>
        </>
      ) : (
        ""
      ),
  });
  const configColumnNwowFromDate = getColumModel({
    header: t("Nwow_approval_table_from"),
    key: "nwowFrom",
    dataType: "string",
    sortable: true,
    width: 11,
    hideInMobile: true,
    render: (data) =>
      data.nwowFrom && moment(data.nwowForm).isValid()
        ? moment(data.nwowFrom).format(DATE_FORMAT).toUpperCase()
        : data.nwowForm,
  });
  const configColumnNwowFromTime = getColumModel({
    header: t("Nwow_approval_table_startTime"),
    key: "nwowFrom",
    dataType: "string",
    sortable: false,
    width: 7,
    hideInMobile: true,
    render: (data) =>
      data.nwowFrom && moment(data.nwowForm).isValid()
        ? moment(data.nwowFrom).format(TIME_FORMAT)
        : data.nwowForm,
  });
  const configColumnNwowToDate = getColumModel({
    header: t("Nwow_approval_table_to"),
    key: "nwowTo",
    dataType: "string",
    sortable: true,
    width: 11,
    hideInMobile: true,
    render: (data) =>
      data.nwowTo && moment(data.nwowTo).isValid()
        ? moment(data.nwowTo).format(DATE_FORMAT).toUpperCase()
        : data.nwowTo,
  });
  const configColumnNwowToTime = getColumModel({
    header: t("Nwow_approval_table_endTime"),
    key: "nwowTo",
    dataType: "string",
    sortable: false,
    width: 7,
    hideInMobile: true,
    render: (data) =>
      data.nwowTo && moment(data.nwowTo).isValid()
        ? moment(data.nwowTo).format(TIME_FORMAT)
        : data.nwowTo,
  });
  const configColumnAppliedUnits = getColumModel({
    header: t("Nwow_approval_table_units"),
    key: "appliedUnits",
    dataType: "string",
    sortable: false,
    width: 7,
    hideInMobile: true,
  });
  const configColumnSubmissionDate = getColumModel({
    header: t("Nwow_approval_table_submissionDate"),
    key: "submissionDate",
    dataType: "string",
    sortable: true,
    hideInMobile: true,
    render: (data) =>
      data.submissionDate && moment(data.submissionDate).isValid()
        ? moment(data.submissionDate).format(DATETIME_FORMAT)
        : data.submissionDate,
  });

  let configColumns = [
    ...(filterStatus === NWOW_WORKFLOW_STATUS.IN_PROGRESS
      ? [
          getColumModel({
            header: "",
            key: "head-column-dummy",
            dataType: "string",
            frozen: true,
            sortable: false,
            width: 0.1,
            hideInMobile: true,
            render: (data) => <></>,
          }),
        ]
      : []),
    configColumnEmployeeCode,
    configColumnEmployeeName,
    configColumnNwowName,
    configColumnTransactionType,
    configColumnNwowPeriod,
    configColumnNwowFromDate,
    configColumnNwowFromTime,
    configColumnNwowToDate,
    configColumnNwowToTime,
    configColumnAppliedUnits,
    configColumnSubmissionDate,
  ];

  const customToolbar = (
    <div className="bt-toolbar-content">
      {/* <div className="current-year-container">
        <b>{t("Nwow_approval_table_currentYear")}: </b>
        {moment().format("yyyy")}
      </div> */}
      <div className="filter-type-container">
        {approverSearchPermission ? (
          <>
            <Dropdown
              className="filter-type-dropdown"
              value={selectedMode}
              options={modeList}
              onChange={(e) => {
                setSelectedMode(e.value);
              }}
            />
            {selectedMode === 1 && (
              <Dropdown
                className="filter-approver-dropdown"
                value={selectedApprover}
                options={approvers}
                onChange={(e) => {
                  setSelectedApprover(e.value);
                }}
                filter
              />
            )}
          </>
        ) : (
          <b>{t("Nwow_approval_filter_myApprovalList")}</b>
        )}
      </div>
      <div className="filter-status-container">
        <Dropdown
          className="filter-status-dropdown"
          value={filterStatus}
          options={filterStatusList}
          onChange={(e) => {
            setFilterStatus(e.value);
          }}
        />
      </div>
    </div>
  );

  const configModel = getBTConfig({
    columns: configColumns,
    hasIndex: false,
    mode: "list",
    defaultMode: "list",
    showGlobal: false,
    hasColumnSelector: false,
    rowHover: true,
    onRowClick: (e) => {
      const classString = e.originalEvent.target.classList.value;
      if (!classString.includes("selection-column")) {
        // TODO: transaction type identify
        if (e.data.transactionType.toLowerCase().includes("entitlement")) {
          history.push(
            PortalLink(
              `${FUNCTION_CODE.Nwow}/${NWOW_SUBMODULE_CODE.APPROVAL}/${NWOW_SUBMODULE_CODE.ENTITLEMENT}/${e.data.applicationId}`
            )
          );
        } else {
          history.push(
            PortalLink(
              `${FUNCTION_CODE.Nwow}/${NWOW_SUBMODULE_CODE.APPROVAL}/${NWOW_SUBMODULE_CODE.APPLICATION}/${e.data.applicationId}`
            )
          );
        }
      }
    },
    responsive: true,
    customToolbar: customToolbar,
    actionsMulti:
      filterStatus === NWOW_WORKFLOW_STATUS.IN_PROGRESS ? [{}] : null,
    hideItemSelectedToolbar: true,
    onSelectionChangeGetSelected: (data) => setSelectedData(data),
    isExpandable: true,
    isExpandInMobileOnly: true,
    expandedRows: expandedRows,
    onRowToggle: (e) => {
      setExpandedRows(e.data);
    },
    rowExpansionTemplate: (data) => {
      let _data = [];
      _data.push(data);
      const columns = [
        configColumnEmployeeCode,
        configColumnNwowName,
        configColumnTransactionType,
        configColumnNwowFromDate,
        configColumnNwowFromTime,
        configColumnNwowToDate,
        configColumnNwowToTime,
        configColumnAppliedUnits,
        configColumnSubmissionDate,
      ];

      const renderTableCell = (rowData, column) => {
        if (column.render) {
          return <div className="bt-cell-render">{column.render(rowData)}</div>;
        } else {
          return rowData[column.key];
        }
      };

      const columnsRender = [];
      columns.forEach((column, index) => {
        const columnConfig = {
          key: `bt-column-${index}`,
          field: column.key,
          header: column.header,
          className: `bt-${column.key}`,
          body: (rowData, config) => {
            return (
              <div className={`bt-row bt-row-${config.rowIndex} bt-cell`}>
                <span className="p-column-title mobile-p-column-title">
                  {column.header}:
                </span>
                <div className="bt-cell-value">
                  {renderTableCell(rowData, column)}
                </div>
              </div>
            );
          },
        };
        columnsRender.push(<Column {...columnConfig} />);
      });

      return (
        <DataTable
          key="info-table"
          value={_data}
          className="p-datatable-responsive table-expandable-header nwow-approval-list-mobile"
        >
          {columnsRender}
        </DataTable>
      );
    },
    // isRowGroup: true,
    // isrowGroupInMobileOnly: true,
    // rowGroupMode: "subheader",
    // groupField: "employeeCode",
    // rowGroupHeaderTemplate: (data) => (
    //   <span className="row-group-header">{data.employeeName}</span>
    // ),
    // rowGroupFooterTemplate: (data) => null,
  });

  // nav
  const backPage = () => {
    history.goBack();
    //history.push(`/${AppConstant.PORTAL}/${FUNCTION_CODE.nwow}`);
  };

  const toNwowPlannner = () => {
    history.push(
      PortalLink(`${FUNCTION_CODE.Nwow}/${NWOW_SUBMODULE_CODE.PLANNER}`)
    );
  };

  // submit
  const submitNwowAction = async (action) => {
    // api
    const applicationIdList = selectedData.reduce((acc, a) => {
      acc.push(a.applicationId);
      return acc;
    }, []);

    const body = {
      Id: applicationIdList,
      Remarks: remarksRef.current,
      Action: action,
    };

    try {
      //show spinner
      dispatch(showSpinner(true));

      const res = await NwowService.nwowApprovalAction({
        body: body,
      });
      if (!isResponseOk(res)) throw new Error(`${action} nwow failed`);
      showTimestampToastSuccess({
        message:
          action === "approve"
            ? t("Nwow_approval_toast_approveSuccess")
            : action === "reject"
            ? t("Nwow_approval_toast_rejectSuccess")
            : "",
        t: t,
      });

      // reload table
      setIsLoading(true);
      loadData();
    } catch (e) {
      // error
    } finally {
      // end spinner
      dispatch(showSpinner(false));
    }
  };

  const confirmAction = async (type) => {
    updateRemarks("");

    const nwowFromString = selectedData.reduce((acc, a) => {
      acc = acc.concat(
        acc.length ? ", " : "",
        a.nwowFrom && moment(a.nwowForm).isValid()
          ? moment(a.nwowFrom).format(DATE_FORMAT)
          : a.nwowForm
      );
      return acc;
    }, "");

    dispatch(
      openModal({
        title: t("Nwow_approval_modal_title"),
        renderModalBody: () => (
          <div className="finalize-claim-modal">
            <div>
              {type === "approve"
                ? t("Nwow_approval_modal_approveNwowContent", {
                    nwowDays: nwowFromString,
                  })
                : null}
              {type === "reject"
                ? t("Nwow_approval_modal_rejectNwowContent", {
                    nwowDays: nwowFromString,
                  })
                : null}
            </div>
            <label>{t("Nwow_approval_modal_remarks")}</label>
            <InputText onChange={(e) => updateRemarks(e.target.value)} />
          </div>
        ),
        classNameMainDialog: "confirm-message-modal",
        primaryButtonText: t("Nwow_common_actionConfirm"),
        primaryButtonClickFn: async ({ closeFn }) => {
          closeFn();
          submitNwowAction(
            type === "approve" ? "approve" : type === "reject" ? "reject" : ""
          );
        },
        secondButtonClickFn: ({ closeFn }) => {
          closeFn();
        },
      })
    );
  };

  // render
  const renderFormSkeleton = () => formSkeleton;

  const renderNwowApprovalList = () => (
    <div className="nwow-approval-list-datatable">
      <BaseTable
        ref={tableRef}
        isClientSize={true}
        configModel={configModel}
        searchConfig={BT_SEARCH}
        searchFn={tableGetData}
        approvalMode={true}
        selectableKey="applicationId"
        // disableMobile={true}
      />
    </div>
  );

  const renderFooter = () => (
    <div className="footer p-grid p-align-center p-justify-between">
      <div className="button-group-1">
        <Button
          onClick={() => backPage()}
          className="p-button-outlined secondary"
        >
          {t("Nwow_common_actionBack")}
        </Button>
      </div>
      {selectedData.length > 0 && (
        <div className="button-group-2">
          <Button onClick={() => confirmAction("approve")} className="p-button">
            {t("Nwow_common_actionApprove")}
          </Button>
          <Button onClick={() => confirmAction("reject")} className="p-button">
            {t("Nwow_common_actionReject")}
          </Button>
        </div>
      )}
    </div>
  );

  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          NWOW_T_CONTEXT_KEY.COMMON,
          NWOW_T_CONTEXT_KEY.APPROVAL,
          NWOW_T_CONTEXT_KEY.PLANNER,
        ]}
      />
      <div className="nwow-container nwow-approval-list">
        <div className="header">
          <NwowMenu
            menu={nwowMenu}
            currentPageKey={NWOW_MENU_PAGE_KEY.NWOW_APPROVER_CORNER}
          />
          <div className="title">
            {t("Nwow_approval_title")}
            <div className="title-desc">{t("Nwow_approval_desc")}</div>
          </div>
          <div className="action-group">
            {nwowPlannerPermission && (
              <Button
                className="p-button theme-action-button nwow-planner-btn"
                onClick={() => toNwowPlannner()}
              >
                {t("Nwow_planner_title")}
              </Button>
            )}
          </div>
        </div>
        <div className="main">
          {/* List */}
          {isLoading || isLoadingData
            ? renderFormSkeleton()
            : renderNwowApprovalList()}

          {/* Footer */}
          {isLoading ? null : renderFooter()}
        </div>
      </div>
    </>
  );
};

export default Approval;
