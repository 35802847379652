import "./leave.scss";

import React, { useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useDispatch } from "react-redux";

// components
import {
  LeaveApplicationList,
  SubmitLeaveApplication,
  EditApplication,
  ViewApplication,
  ApplyLeaveEntitlement,
  EditEntitlement,
  ViewEntitlement,
  LeaveBalance,
  LeaveCalendarView,
  LeaveDelegation,
  LeaveApproverCorner,
  LeavePlanner,
  LeaveReport,
  ApproveApplication,
  ApproveEntitlement,
  HolidayList,
  BlockLeave,
} from "./modules";

import { LEAVE_SUBMODULE_CODE } from "./../../../constants/index";
import PageNotFound from "./../public/error/employee-error-404";
import { resetBcDynamicPaths } from "../../../redux/actions/breadcrumb";

const Leave = (props) => {
  let match = useRouteMatch();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetBcDynamicPaths());
  }, [dispatch]);

  const routes = [
    {
      id: "list",
      path: `${match.path}`,
      component: LeaveApplicationList,
      exact: true,
      strict: true,
    },
    {
      id: "submit",
      path: `${match.path}/${LEAVE_SUBMODULE_CODE.SUBMIT_APPLICATION}`,
      component: SubmitLeaveApplication,
      exact: true,
      strict: true,
    },
    {
      id: "application-view",
      path: `${match.path}/${LEAVE_SUBMODULE_CODE.APPLICATION}/:applicationId`,
      component: ViewApplication,
      exact: true,
      strict: true,
    },
    {
      id: "application-edit",
      path: `${match.path}/${LEAVE_SUBMODULE_CODE.APPLICATION}/:applicationId/${LEAVE_SUBMODULE_CODE.EDIT}`,
      component: EditApplication,
      exact: true,
      strict: true,
    },
    {
      id: "entitlement",
      path: `${match.path}/${LEAVE_SUBMODULE_CODE.APPLY_ENTITLEMENT}`,
      component: ApplyLeaveEntitlement,
      exact: true,
      strict: true,
    },
    {
      id: "entitlement-view",
      path: `${match.path}/${LEAVE_SUBMODULE_CODE.ENTITLEMENT}/:applicationId`,
      component: ViewEntitlement,
      exact: true,
      strict: true,
    },
    {
      id: "entitlement-edit",
      path: `${match.path}/${LEAVE_SUBMODULE_CODE.ENTITLEMENT}/:applicationId/${LEAVE_SUBMODULE_CODE.EDIT}`,
      component: EditEntitlement,
      exact: true,
      strict: true,
    },
    {
      id: "balance",
      path: `${match.path}/${LEAVE_SUBMODULE_CODE.BALANCE}`,
      component: LeaveBalance,
      exact: true,
      strict: true,
    },
    {
      id: "calendar-view-all",
      path: `${match.path}/${LEAVE_SUBMODULE_CODE.CALENDAR_VIEW}`,
      component: LeaveCalendarView,
      exact: true,
      strict: true,
    },
    // {
    //   id: "calendar-view",
    //   path: `${match.path}/${LEAVE_SUBMODULE_CODE.CALENDAR_VIEW}/:applicationId`,
    //   component: LeaveCalendarView,
    //   exact: true,
    //   strict: true,
    // },
    {
      id: "delegation",
      path: `${match.path}/${LEAVE_SUBMODULE_CODE.DELEGATION}`,
      component: LeaveDelegation,
      exact: true,
      strict: true,
    },
    {
      id: "approval",
      path: `${match.path}/${LEAVE_SUBMODULE_CODE.APPROVAL}`,
      component: LeaveApproverCorner,
      exact: true,
      strict: true,
    },
    {
      id: "approve-application",
      path: `${match.path}/${LEAVE_SUBMODULE_CODE.APPROVAL}/${LEAVE_SUBMODULE_CODE.APPLICATION}/:applicationId`,
      component: ApproveApplication,
      exact: true,
      strict: true,
    },
    {
      id: "approve-entitlement",
      path: `${match.path}/${LEAVE_SUBMODULE_CODE.APPROVAL}/${LEAVE_SUBMODULE_CODE.ENTITLEMENT}/:applicationId`,
      component: ApproveEntitlement,
      exact: true,
      strict: true,
    },
    {
      id: "planner",
      path: `${match.path}/${LEAVE_SUBMODULE_CODE.PLANNER}`,
      component: LeavePlanner,
      exact: true,
      strict: true,
    },
    {
      id: "holiday",
      path: `${match.path}/${LEAVE_SUBMODULE_CODE.HOLIDAY}`,
      component: HolidayList,
      exact: true,
      strict: true,
    },
    {
      id: "block-leave",
      path: `${match.path}/${LEAVE_SUBMODULE_CODE.BLOCK_LEAVE}`,
      component: BlockLeave,
      exact: true,
      strict: true,
    },
    {
      id: "report",
      path: `${match.path}/${LEAVE_SUBMODULE_CODE.REPORT}`,
      component: LeaveReport,
      exact: true,
      strict: true,
    },
  ];

  return (
    <>
      <Switch>
        {props.routeConfig ? props.routeConfig(routes) : null}
        <Route component={PageNotFound} />
      </Switch>
    </>
  );
};

export default Leave;
