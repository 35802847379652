import "./list.scss";

//components
import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../../../redux/actions/modal";
import { resetBcDynamicPaths } from "../../../../../redux/actions/breadcrumb";
import { showSpinner } from "../../../../../redux/actions/spinner";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import {
  getColumModel,
  DEFAULT_BT_SEARCH,
  getBTConfig,
} from "./../../../../../components/base-table/base-table-model";
import BaseTable from "../../../../../components/base-table/base-table-portal-rental";
import { LeaveStatus, LeaveMenu } from "../../components";
import { formSkeleton } from "../../utils";
import { isResponseOk } from "../../../../../utils/utils";
import {
  // showErrorMessageModal,
  showTimestampToastSuccess,
} from "../../../../../services/utils/message";
// import { getResponseMessage } from "./../../../../../utils/utils";
import {
  EEPORTAL_LABEL_BASE_TABLE,
  FUNCTION_CODE,
  LEAVE_SUBMODULE_CODE,
  RESPONSE_MESSAGE_ERROR,
  MENU_NAME,
  Permission,
} from "../../../../../constants";
import {
  LEAVE_T_CONTEXT_KEY,
  LEAVE_MENU_MODULE_KEY,
  LEAVE_MENU_TYPE_KEY,
  LEAVE_MENU_PAGE_KEY,
  LEAVE_LIST_ACTIONS,
  LEAVE_MENU_ACTION_KEY,
} from "../../constants/contants";

// services
import { LeaveService } from "../../../../../services/hrmnet-api";
import LanguageLoader from "../../../../../components/language-loader/language-loader";
import { PortalLink } from "../../../../../services/utils";
import BaseTabScreen from "../../../../../components/base-tab-screen/base-tab-screen";
import axios from "axios";

const DATE_FORMAT = "yyyy-MM-DD (ddd)";
const DATE_FORMAT_MOBILE = "yyyy-MM-DD";
const TIME_FORMAT = "h:mm A";
const DATETIME_FORMAT = "yyyy-MM-DD HH:mm";

const LeaveApplicationList = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { t } = useTranslation();
  const tableRef = useRef();
  const defaultTabList = [
    {
      value: 0,
      name: t("leave_applicationList_title"),
      key: LEAVE_MENU_ACTION_KEY.SUBMIT_LEAVE_APPLICATION_OWN_LIST,
      type: "Action",
      description: t("leave_applicationList_desc"),
    },
    {
      value: 1,
      name: t("leave_applicationList_delegation_title"),
      key: LEAVE_MENU_ACTION_KEY.SUBMIT_LEAVE_APPLICATION_DELEGATED_LIST,
      type: "Action",
      description: t("leave_applicationList_delegation_desc"),
    },
    {
      value: 2,
      name: t("leave_applicationList_hrView_title"),
      key: LEAVE_MENU_ACTION_KEY.SUBMIT_LEAVE_APPLICATION_HRVIEW_LIST,
      type: "Action",
      description: t("leave_applicationList_hrView_desc"),
    },
  ];
  const menus = useSelector((state) => state.menu.sideBar);
  const leaveMenu = menus.find(
    (x) => x.key === LEAVE_MENU_MODULE_KEY
  )?.subMenus;

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingData, setIsLoadingData] = useState(true);
  // const [initData, setInitData] = useState(true);
  const [data, setData] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const [filterYearList, setFilterYearList] = useState([]);
  const [filterYear, setFilterYear] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedTab, setSelectedTab] = useState();
  const [tabList, setTabList] = useState();
  const [showCalendar, setShowCalendar] = useState(true);
  const isDelegatedRecord =
    selectedTab?.key ===
    LEAVE_MENU_ACTION_KEY.SUBMIT_LEAVE_APPLICATION_DELEGATED_LIST;
  const isHrView =
    selectedTab?.key ===
    LEAVE_MENU_ACTION_KEY.SUBMIT_LEAVE_APPLICATION_HRVIEW_LIST;
  const { cancel, token } = axios.CancelToken.source();
  const requestConfig = {
    cancelToken: token,
  };

  const remarksRef = useRef();
  const updateRemarks = (value) => {
    remarksRef.current = value;
  };

  useEffect(() => {
    dispatch(resetBcDynamicPaths());
  }, [dispatch]);

  useEffect(() => {
    async function loadHasDelegationTask() {
      const res = await LeaveService.leaveGetDelegateEmployeeApproveList(
        requestConfig
      );
      if (res && res?.data && res?.data?.length > 0) {
        var hasDelegation =
          res.data.findIndex(
            (x) =>
              ![Permission.HR_Authorized, Permission.Self].includes(
                x.descriptionValue
              )
          ) > -1;
        var hasHrView =
          res.data.findIndex((x) =>
            [Permission.HR_Authorized].includes(x.descriptionValue)
          ) > -1;
        const _tabList = [...defaultTabList];
        if (!hasDelegation) {
          _tabList.splice(
            _tabList.findIndex(
              (x) =>
                x?.key ===
                LEAVE_MENU_ACTION_KEY.SUBMIT_LEAVE_APPLICATION_DELEGATED_LIST
            ),
            1
          );
        }
        if (!hasHrView) {
          _tabList.splice(
            _tabList.findIndex(
              (x) =>
                x?.key ===
                LEAVE_MENU_ACTION_KEY.SUBMIT_LEAVE_APPLICATION_HRVIEW_LIST
            ),
            1
          );
        }
        return setTabList(_tabList);
      }
      return;
    }

    loadHasDelegationTask();
  }, []);

  // init
  useEffect(() => {
    loadData();
    // scroll to section
    let element = document.querySelector(".leave-menu");
    element?.scrollIntoView({ behavior: "smooth", block: "center" });
    // eslint-disable-next-line react-hooks/exhaustive-deps

    return () => {
      cancel(RESPONSE_MESSAGE_ERROR.CANCEL);
    };
  }, [selectedTab]);

  const loadPeriod = async () => {
    var cmd = LeaveService.leaveApproverCornerGetLeavePeriods({});
    let [res] = await Promise.all([cmd]);

    // mocked data
    // res = sampleApprovalList;
    if (res && res?.data && res?.data?.length > 0) {
      const data = res?.data.sort(function (a, b) {
        return b.id - a.id;
      });

      //default selected period must be equal to current year
      const currYear = new Date().getFullYear();
      const currPeriod = data.find((d) => d.value == currYear.toString());
      return {
        yearList: [
          ...new Map(Array.from(data, (x) => x.name).map((x) => [x, x])),
        ].map((x) => {
          return {
            label: x[0],
            value: Number(x[1]),
          };
        }),
        targetYear: Number(currPeriod?.name ?? data[0]?.name),
      };
    }
  };

  const fetchData = async (targetYear, isOwnRecord, hrView) => {
    var cmd = !isOwnRecord
      ? LeaveService.leaveGetDelegateApplicationList(
          { year: targetYear, hrView: hrView },
          requestConfig
        )
      : LeaveService.leaveGetApplicationList(requestConfig);
    let [res] = await Promise.all([cmd]);

    // mocked data
    // res = sampleApplicationList;
    if (res && res.data) {
      const resData = res.data
        .filter((x) => !!x)
        .map((d) => {
          return {
            ...d,
            actions: d.actions.filter((action) => {
              if (
                ![
                  LEAVE_LIST_ACTIONS.VIEW_APPLICATION,
                  LEAVE_LIST_ACTIONS.VIEW_APPLICATION_DETAILS,
                ].includes(action)
              ) {
                if (!d?.isAccrual) {
                  return Array.from(leaveMenu, (x) => x.key).includes(
                    MENU_NAME.Submit_Leave_Application
                  );
                }
                return Array.from(leaveMenu, (x) => x.key).includes(
                  MENU_NAME.Apply_for_Entitlement
                );
              }

              return true;
            }),
            leaveFromDate:
              d.leaveFrom && moment(d.leaveForm).isValid()
                ? moment(d.leaveFrom).format(DATE_FORMAT).toUpperCase()
                : d.leaveForm,
            leaveFromTime:
              d.leaveFrom && moment(d.leaveForm).isValid()
                ? moment(d.leaveFrom).format(TIME_FORMAT)
                : d.leaveForm,
            leaveToDate:
              d.leaveTo && moment(d.leaveTo).isValid()
                ? moment(d.leaveTo).format(DATE_FORMAT).toUpperCase()
                : d.leaveTo,
            leaveToTime:
              d.leaveTo && moment(d.leaveTo).isValid()
                ? moment(d.leaveTo).format(TIME_FORMAT)
                : d.leaveTo,
            leaveFromTo:
              d.leaveFrom &&
              moment(d.leaveForm).isValid() &&
              d.leaveTo &&
              moment(d.leaveTo).isValid()
                ? [
                    moment(d.leaveFrom)
                      .format(DATE_FORMAT_MOBILE)
                      .toUpperCase(),
                    " - ",
                    moment(d.leaveTo).format(DATE_FORMAT_MOBILE).toUpperCase(),
                  ].join("")
                : "",
            submissionDateDisplay:
              d.submissionDate && moment(d.submissionDate).isValid()
                ? moment(d.submissionDate).format(DATETIME_FORMAT)
                : d.submissionDate,
          };
        });

      return resData;
    }
  };

  const loadData = async () => {
    try {
      setIsLoading(true);
      var periodInfo = await loadPeriod();
      let targetYear = periodInfo.targetYear;
      let resData = [];
      resData =
        (await fetchData(
          targetYear,
          !isDelegatedRecord && !isHrView,
          isHrView
        )) ?? [];

      // mocked data
      // res = sampleApplicationList;
      if (Array.isArray(resData)) {
        setFilterYearList(periodInfo.yearList);
        setFilterYear(targetYear);
        setData(resData);
        setFilterDataForDisplay(resData, targetYear);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoadingData(false);
      setFilterYear(null);
      setData([]);
      setFilterYearList([]);
      setFilteredData([]);
    }
  };

  const setFilterDataForDisplay = (dataSource, targetYear) => {
    tableRef?.current?.loading(true);
    const dataList = dataSource ? dataSource : data;
    const dataYear = targetYear ? targetYear : filterYear;
    const filteredData = dataList.filter(
      (d) => Number(d.leaveYear) === dataYear
    );
    setFilteredData(filteredData);
    tableRef?.current?.reload();
  };

  useEffect(() => {
    const fetchFilterDataForDisplay = async () => {
      tableRef?.current?.loading(true);
      let isOwnRecord = !(isDelegatedRecord || isHrView);
      let dataSource = await fetchData(filterYear, isOwnRecord, isHrView);
      setFilteredData(dataSource);
      tableRef?.current?.reload();
    };

    if (isDelegatedRecord || isHrView) {
      return fetchFilterDataForDisplay();
    }

    setIsLoadingData(true);
    setFilterDataForDisplay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterYear]);

  useEffect(() => {
    setIsLoadingData(false);
  }, [filteredData]);

  // confirm
  const deleteLeave = async (record) => {
    try {
      // show spinner
      dispatch(showSpinner(true));
      var cmd = await LeaveService.leaveDeleteLeaveDraftApplication({
        applicationId: record.applicationId,
        employeeCode: record?.employeeCode,
      });
      let [res] = await Promise.all([cmd]);
      if (isResponseOk(res)) {
        showTimestampToastSuccess({
          message: "Success",
          t: t,
        });
        loadData();
      }
    } catch (e) {
      // error
    } finally {
      // end spinner
      dispatch(showSpinner(false));
    }
  };

  const cancelLeave = async (record) => {
    try {
      // show spinner
      dispatch(showSpinner(true));
      var cmd = await LeaveService.leaveCancelLeaveApplication({
        applicationId: record.applicationId,
        remarks: remarksRef.current,
        employeeCode: record?.employeeCode,
      });
      let [res] = await Promise.all([cmd]);
      if (isResponseOk(res)) {
        showTimestampToastSuccess({
          message: "Success",
          t: t,
        });
        loadData();
      }
    } catch (e) {
      // error
    } finally {
      // end spinner
      dispatch(showSpinner(false));
    }
  };

  const confirmLeaveAction = (action, record) => {
    updateRemarks("");
    const leaveFromDate =
      record.leaveFrom && moment(record.leaveForm).isValid()
        ? moment(record.leaveFrom).format(DATE_FORMAT).toUpperCase()
        : record.leaveForm;

    let modelTitle =
      action === "delete"
        ? t("leave_applicationList_deleteModalTitle")
        : action === "cancel"
        ? t("leave_applicationList_cancelModalTitle")
        : "";
    let modelContent =
      action === "delete"
        ? t("leave_applicationList_deleteModalContent", {
            leaveFromDate: leaveFromDate,
          })
        : action === "cancel"
        ? t("leave_applicationList_cancelModalContent", {
            leaveFromDate: leaveFromDate,
          })
        : "";

    if (isDelegatedRecord || isHrView) {
      modelTitle = t(
        "leave_applicationList_delegation_cancelModalTitle",
        record
      );
      modelContent = t(
        "leave_applicationList_delegation_cancelModalContent",
        record
      );
      if (action === "delete") {
        modelTitle = t(
          "leave_applicationList_delegation_deleteModalTitle",
          record
        );
        modelContent = t(
          "leave_applicationList_delegation_deleteModalContent",
          record
        );
      }
    }

    dispatch(
      openModal({
        title: (
          <div
            dangerouslySetInnerHTML={{
              __html: modelTitle,
            }}
          />
        ),
        renderModalBody: () => (
          <div className="finalize-claim-modal">
            <div
              dangerouslySetInnerHTML={{
                __html: modelContent,
              }}
            />
            {action === "cancel" ? (
              <>
                <label>{t("leave_applicationList_cancelModalRemarks")}</label>
                <InputText onChange={(e) => updateRemarks(e.target.value)} />
              </>
            ) : null}
          </div>
        ),
        classNameMainDialog: "confirm-message-modal",
        primaryButtonText: t("leave_common_actionConfirm"),
        //primaryButtonClickFn: async ({ closeFn }) => {
        primaryButtonClickFn: ({ closeFn }) => {
          closeFn();
          if (action === "delete") {
            deleteLeave(record);
          }
          if (action === "cancel") {
            cancelLeave(record);
          }
        },
        secondButtonClickFn: ({ closeFn }) => {
          closeFn();
        },
      })
    );
  };

  // redirect
  const redirectAction = (
    actionType,
    isAccrual,
    applicationId,
    employeeCode,
    employeeName
  ) => {
    let path = null;
    // if (transactionType?.toLowerCase() !== "preapproval leave entitlement") {
    if (!isAccrual) {
      if (actionType === "edit") {
        path = PortalLink(
          `${FUNCTION_CODE.Leave}/${LEAVE_SUBMODULE_CODE.APPLICATION}/${applicationId}/${LEAVE_SUBMODULE_CODE.EDIT}`
        );
      } else if (actionType === "view") {
        path = PortalLink(
          `${FUNCTION_CODE.Leave}/${LEAVE_SUBMODULE_CODE.APPLICATION}/${applicationId}`
        );
      }
    } else {
      if (actionType === "edit") {
        path = PortalLink(
          `${FUNCTION_CODE.Leave}/${LEAVE_SUBMODULE_CODE.ENTITLEMENT}/${applicationId}/${LEAVE_SUBMODULE_CODE.EDIT}`
        );
      } else if (actionType === "view") {
        path = PortalLink(
          `${FUNCTION_CODE.Leave}/${LEAVE_SUBMODULE_CODE.ENTITLEMENT}/${applicationId}`
        );
      }
    }

    if (isDelegatedRecord || isHrView) {
      return history.push({
        pathname: path,
        state: {
          delegateeMode: true,
          employeeCode: employeeCode,
          employeeName: employeeName,
        },
      });
    }

    if (path) {
      history.push(path);
    }
  };

  // table
  const tableGetData = async () => {
    if (isLoading) {
      return new Promise(() => {});
    } else {
      return {
        datas: filteredData,
        searchConfig: BT_SEARCH,
        total: filteredData?.length ?? 0,
      };
    }
  };
  const BT_SEARCH = {
    ...DEFAULT_BT_SEARCH,
    sortObj: {
      // key: "applicationId",
      key: "submissionDate",
      direction: -1,
    },
  };

  const configColumnLeaveType = getColumModel({
    header: t("leave_applicationList_leaveType"),
    key: "leaveType",
    dataType: "string",
    sortable: true,
    width: 12,
  });
  const configColumnTransactionType = getColumModel({
    header: t("leave_applicationList_transactionType"),
    key: "transactionType",
    dataType: "string",
    sortable: true,
    hideInMobile: true,
  });
  const configColumnLeaveFromToDate = getColumModel({
    header: t("leave_applicationList_leavePeriod"),
    key: "leaveFromTo",
    dataType: "string",
    sortable: true,
    width: 0.1,
    render: (data) => (
      <>
        <LeaveStatus status={data.status} data={data} showText={false} />
        <span>{data.leaveFromTo}</span>
      </>
    ),
  });
  const configColumnLeaveFromDate = getColumModel({
    header: t("leave_applicationList_from"),
    key: "leaveFrom",
    dataType: "string",
    sortable: true,
    width: 11,
    hideInMobile: true,
    render: (data) =>
      data.leaveFrom && moment(data.leaveForm).isValid()
        ? moment(data.leaveFrom).format(DATE_FORMAT).toUpperCase()
        : data.leaveForm,
  });
  const configColumnLeaveFromTime = getColumModel({
    header: t("leave_applicationList_startTime"),
    key: "leaveFrom",
    dataType: "string",
    sortable: false,
    width: 7,
    hideInMobile: true,
    render: (data) =>
      data.leaveFrom && moment(data.leaveForm).isValid()
        ? moment(data.leaveFrom).format(TIME_FORMAT)
        : data.leaveForm,
  });
  const configColumnLeaveFromDateTime = getColumModel({
    header: t("leave_applicationList_from"),
    key: "leaveFromDatetime",
    dataType: "string",
    sortable: true,
    width: 0.1,
    render: (data) =>
      data.leaveFrom && moment(data.leaveForm).isValid()
        ? moment(data.leaveFrom).format(DATETIME_FORMAT)
        : data.leaveForm,
  });
  const configColumnLeaveToDate = getColumModel({
    header: t("leave_applicationList_to"),
    key: "leaveTo",
    dataType: "string",
    sortable: true,
    width: 11,
    hideInMobile: true,
    render: (data) =>
      data.leaveTo && moment(data.leaveTo).isValid()
        ? moment(data.leaveTo).format(DATE_FORMAT).toUpperCase()
        : data.leaveTo,
  });
  const configColumnLeaveToTime = getColumModel({
    header: t("leave_applicationList_endTime"),
    key: "leaveTo",
    dataType: "string",
    sortable: false,
    width: 7,
    hideInMobile: true,
    render: (data) =>
      data.leaveTo && moment(data.leaveTo).isValid()
        ? moment(data.leaveTo).format(TIME_FORMAT)
        : data.leaveTo,
  });
  const configColumnLeaveToDateTime = getColumModel({
    header: t("leave_applicationList_to"),
    key: "leaveFromDatetime",
    dataType: "string",
    sortable: true,
    width: 0.1,
    render: (data) =>
      data.leaveTo && moment(data.leaveTo).isValid()
        ? moment(data.leaveTo).format(DATETIME_FORMAT)
        : data.leaveTo,
  });
  const configColumnUnit = getColumModel({
    header: t("leave_applicationList_units"),
    key: "unit",
    dataType: "string",
    sortable: false,
    width: 7,
    hideInMobile: true,
  });
  const configColumnStatus = getColumModel({
    header: t("leave_applicationList_status"),
    key: "status",
    dataType: "string",
    sortable: true,
    width: 12,
    hideInMobile: true,
    render: (data) => <LeaveStatus status={data.status} data={data} />,
  });
  const configColumnSubmissionDate = getColumModel({
    header: t("leave_applicationList_submissionDate"),
    key: "submissionDate",
    dataType: "string",
    sortable: true,
    hideInMobile: true,
    render: (data) =>
      data.submissionDate && moment(data.submissionDate).isValid()
        ? moment(data.submissionDate).format(DATETIME_FORMAT)
        : data.submissionDate,
  });
  const configColumnReferenceNo = getColumModel({
    header: t("leave_applicationList_refNo"),
    key: "referenceNo",
    dataType: "string",
    sortable: true,
    hideInMobile: true,
  });
  const configColumnDelegationEmployeeDisplayName = getColumModel({
    header: t("leave_applicationList_delegation_employeeName"),
    key: "employeeName",
    dataType: "string",
    sortable: true,
    hideInMobile: false,
  });
  const configColumnDelegationEmployeeCode = getColumModel({
    header: t("leave_applicationList_delegation_employeeCode"),
    key: "employeeCode",
    dataType: "string",
    sortable: true,
    hideInMobile: false,
  });

  const configColumns = [
    configColumnLeaveType,
    configColumnTransactionType,
    configColumnLeaveFromToDate,
    configColumnLeaveFromDate,
    configColumnLeaveFromTime,
    configColumnLeaveToDate,
    configColumnLeaveToTime,
    configColumnUnit,
    configColumnStatus,
    configColumnSubmissionDate,
    configColumnReferenceNo,
    getColumModel({
      header: "",
      key: "",
      dataType: "string",
      sortable: false,
      width: 0.1,
      frozen: true,
      hideInMobile: true,
    }),
  ];

  const customToolbar = (
    <div className="bt-toolbar-content">
      <b>{t("leave_applicationList_currentYear")}: </b>
      {/* {moment().format("yyyy")} */}
      <div className="filter-year-container">
        <Dropdown
          value={filterYear}
          options={filterYearList}
          onChange={(e) => {
            setFilterYear(e.value);
          }}
        />
      </div>
    </div>
  );

  const customToolbarAction = (
    <div className="bt-action">
      <Button
        className="bt-calendarbtn"
        icon="pi pi-calendar"
        tooltipOptions={{ position: "top" }}
        tooltip={t("leave_applicationList_calendarView")}
        onClick={() => {
          history.push(
            PortalLink(
              `${FUNCTION_CODE.Leave}/${LEAVE_SUBMODULE_CODE.CALENDAR_VIEW}`
            )
          );
        }}
      />
    </div>
  );

  const renderConfigColumns = () => {
    if (isDelegatedRecord || isHrView) {
      return [
        configColumnDelegationEmployeeDisplayName,
        configColumnDelegationEmployeeCode,
        ...configColumns,
      ];
    }

    return configColumns;
  };

  const configModel = getBTConfig({
    columns: renderConfigColumns(),
    hasIndex: false,
    mode: "list",
    defaultMode: "list",
    showGlobal: true,
    hasColumnSelector: false,
    rowHover: true,
    responsive: true,
    onRowClick: (e) => {
      const classString = e.originalEvent.target.classList.value;
      if (
        !(
          classString.includes("action-column") ||
          classString.includes("p-button-icon") ||
          classString.includes("p-menu") ||
          classString.includes("p-menuitem-text")
        )
      ) {
        if (e.data.actions.includes(LEAVE_LIST_ACTIONS.EDIT_APPLICATION)) {
          redirectAction(
            "edit",
            e.data.isAccrual,
            e.data.applicationId,
            e.data?.employeeCode,
            e.data?.employeeName
          );
        } else {
          redirectAction(
            "view",
            e.data.isAccrual,
            e.data.applicationId,
            e.data?.employeeCode,
            e.data?.employeeName
          );
        }
      }
    },
    actionWidth: 5.3,
    actionsSingle: [
      {
        isOverflow: true,
        name: LEAVE_LIST_ACTIONS.VIEW_APPLICATION,
        title: t("leave_applicationList_action_viewApplication"),
        clickFn: (data) =>
          redirectAction(
            "view",
            data.isAccrual,
            data.applicationId,
            data?.employeeCode,
            data?.employeeName
          ),
      },
      {
        // sample action
        isOverflow: true,
        name: LEAVE_LIST_ACTIONS.VIEW_APPLICATION_DETAILS,
        title: t("leave_applicationList_action_viewApplicationDetails"),
        clickFn: (data) =>
          redirectAction(
            "view",
            data.isAccrual,
            data.applicationId,
            data?.employeeCode,
            data?.employeeName
          ),
      },
      {
        isOverflow: true,
        name: LEAVE_LIST_ACTIONS.EDIT_APPLICATION,
        title: t("leave_applicationList_action_editApplication"),
        clickFn: (data) =>
          redirectAction(
            "edit",
            data.isAccrual,
            data.applicationId,
            data?.employeeCode,
            data?.employeeName
          ),
      },
      {
        isOverflow: true,
        name: LEAVE_LIST_ACTIONS.DELETE_DRAFTS,
        title: t("leave_applicationList_action_deleteDraft"),
        clickFn: (data) => {
          confirmLeaveAction("delete", data);
        },
      },
      {
        isOverflow: true,
        name: LEAVE_LIST_ACTIONS.CANCEL_APPLICATION,
        title: t("leave_applicationList_action_cancelApplication"),
        clickFn: (data) => {
          confirmLeaveAction("cancel", data);
        },
      },
    ],
    showActionsSingle: (action, actionsList) => {
      if (!action || !actionsList) return false;
      return actionsList.includes(action.title);
    },
    customToolbar: customToolbar,
    customToolbarAction: showCalendar ? customToolbarAction : <> </>,
    isExpandable: true,
    isExpandInMobileOnly: true,
    expandedRows: expandedRows,
    onRowToggle: (e) => {
      setExpandedRows(e.data);
    },
    rowExpansionTemplate: (data) => {
      let _data = [];
      _data.push(data);
      const columns = [
        configColumnSubmissionDate,
        configColumnStatus,
        configColumnUnit,
        configColumnLeaveType,
        configColumnTransactionType,
        configColumnLeaveFromDateTime,
        configColumnLeaveToDateTime,
      ];

      const renderTableCell = (rowData, column) => {
        if (column.render) {
          return <div className="bt-cell-render">{column.render(rowData)}</div>;
        } else {
          return rowData[column.key];
        }
      };

      const columnsRender = [];
      columns.forEach((column, index) => {
        const columnConfig = {
          key: `bt-column-${index}`,
          field: column.key,
          header: column.header,
          className: `bt-${column.key}`,
          body: (rowData, config) => {
            return (
              <div className={`bt-row bt-row-${config.rowIndex} bt-cell`}>
                <span className="p-column-title mobile-p-column-title">
                  {column.header}:
                </span>
                <div className="bt-cell-value">
                  {renderTableCell(rowData, column)}
                </div>
              </div>
            );
          },
        };
        columnsRender.push(<Column {...columnConfig} />);
      });

      return (
        <DataTable
          key="info-table"
          value={_data}
          className="p-datatable-responsive table-expandable-header leave-application-list-mobile"
        >
          {columnsRender}
        </DataTable>
      );
    },
  });

  // render
  const renderFormSkeleton = () => formSkeleton;

  const renderDropdown = () => {
    const leaveMenu = menus.find((x) => x.key === LEAVE_MENU_MODULE_KEY);
    if (leaveMenu) {
      return (
        <Dropdown
          className="nav-dropdown"
          options={leaveMenu.subMenus.filter(
            (s) =>
              s.type === LEAVE_MENU_TYPE_KEY.MENU &&
              s.key !== LEAVE_MENU_PAGE_KEY.LEAVE_APPLICATIONS
          )}
          optionLabel="name"
          optionValue="url"
          onChange={(e) => history.push(e.value)}
          placeholder={t("leave_applicationList_pleaseSelectNav")}
        />
      );
    } else {
      return null;
    }
  };

  const renderLeaveApplicationList = () => (
    <div className="leave-application-list-datatable">
      <BaseTable
        ref={tableRef}
        isClientSize={true}
        configModel={configModel}
        searchConfig={BT_SEARCH}
        searchFn={tableGetData}
        // disableMobile={true}
      />
    </div>
  );

  const renderHeader = () => {
    let title = defaultTabList[0]?.name;
    let description = defaultTabList[0]?.description;
    if (Array.isArray(tabList) && !!selectedTab?.key) {
      var index = defaultTabList.findIndex((x) => x.key === selectedTab.key);
      title = defaultTabList[index].name;
      description = defaultTabList[index].description;
    }

    return (
      <div className="title">
        {title}
        <div className="title-desc">{description}</div>
      </div>
    );
  };

  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          LEAVE_T_CONTEXT_KEY.COMMON,
          LEAVE_T_CONTEXT_KEY.LIST,
        ]}
      >
        <div className="leave-container leave-applicaiton-list">
          <div className="header">
            {renderHeader()}
            <LeaveMenu
              menu={leaveMenu}
              currentPageKey={LEAVE_MENU_PAGE_KEY.LEAVE_APPLICATIONS}
            />
            {renderDropdown()}
          </div>
          <BaseTabScreen
            onChange={(x) => {
              const _selectedTab = defaultTabList.find((y) => y.value === x);
              if (!!_selectedTab) {
                setSelectedTab(_selectedTab);
                if (x == 0) {
                  setShowCalendar(true);
                } else {
                  setShowCalendar(false);
                }
              }
            }}
            displayNotFound={false}
            menuKey={LEAVE_MENU_PAGE_KEY.LEAVE_APPLICATIONS}
            customMenuModules={!!tabList && tabList}
          >
            <div className="main">
              {/* Footer */}
              {isLoading || isLoadingData
                ? renderFormSkeleton()
                : renderLeaveApplicationList()}
            </div>
          </BaseTabScreen>
        </div>
      </LanguageLoader>
    </>
  );
};

export default LeaveApplicationList;
