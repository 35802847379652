import "./create.scss";

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../../../redux/actions/modal";
import { showSpinner } from "../../../../../redux/actions/spinner";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { Skeleton } from "primereact/skeleton";
import { useHistory } from "react-router-dom";

import {
  Steps,
  MultiStepForm,
  APPLICATION_STATUS,
  RentalMenu,
  ScrollToRentalMenuActiveItem,
} from "../../components";
import {
  configToStepNavModel,
  configToFormModel,
  formSkeleton,
  saveDraft,
  submitApplication,
  getCustomFunctionLibrary,
  initFormValue,
  CCType,
} from "../../utils";
import {
  HRIS_Api_Common_Enums_Enums_RentalApplicationForm,
  RentalDetailsService,
} from "./../../../../../services/hrmnet-api";
import {
  EEPORTAL_LABEL_BASE_TABLE,
  FUNCTION_CODE,
  MESSAGE_SEVERITY,
} from "../../../../../constants";
import {
  resetBcDynamicPaths,
  setBcDynamicPaths,
} from "../../../../../redux/actions/breadcrumb";
import {
  setRentalForm,
  resetRentalForm,
  setRentalFormConfig,
  setRentalSectionConfig,
  setRentalEmployeeDetails,
  clearRentalEmployeeDetails,
} from "../../../../../redux/actions/rental";
import {
  scrollToErrorControl,
  scrollToMainTop,
} from "../../../../../components/base-form/utils";
import {
  showErrorMessageModal,
  showTimestampToastSuccess,
  showToast,
} from "../../../../../services/utils/message";
import { isResponseOk } from "../../../../../utils/utils";
import { getResponseMessage } from "./../../../../../utils/utils";
import LanguageLoader from "../../../../../components/language-loader/language-loader";
import {
  RENTAL_T_CONTEXT_KEY,
  RENTAL_MENU_MODULE_KEY,
  RENTAL_MENU_PAGE_KEY,
} from "../../constants";
import { PortalLink } from "../../../../../services/utils";

const CreateRentalApplication = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const menus = useSelector((state) => state.menu.sideBar);
  const rentalMenu = menus.find(
    (x) => x.key === RENTAL_MENU_MODULE_KEY
  )?.subMenus;

  const [state, setState] = useState({
    isLoading: true,
    stepList: [],
    activeStep: 0,
    formResp: {},
    formName: "",
    touched: false,
  });

  const rentalConfig = useSelector((state) => state.rental.moduleConfig);

  const form = useSelector((state) => state.rental.form);
  const setForm = (x) => dispatch(setRentalForm(x));
  const formConfig = useSelector((state) => state.rental.formConfig);
  const setFormConfig = (x) => dispatch(setRentalFormConfig(x));
  const formSectionConfig = useSelector(
    (state) => state.rental.formSectionConfig
  );
  const setFormSectionConfig = (x) => dispatch(setRentalSectionConfig(x));
  const setEmployeeDetails = (x) => dispatch(setRentalEmployeeDetails(x));
  const clearEmployeeDetails = () => dispatch(clearRentalEmployeeDetails());

  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );

  const formObjRef = React.createRef();
  const selectedLangRef = useRef();

  // Init
  useEffect(() => {
    dispatch(resetRentalForm());
    return () => {
      dispatch(resetRentalForm());
    };
  }, [dispatch]);

  const customFunctionLibrary = getCustomFunctionLibrary({ dispatch });

  useEffect(() => {
    const loadForm = async () => {
      const isLangChange =
        selectedLangRef.current != null &&
        selectedLangRef.current !== selectedLangKey;
      selectedLangRef.current = selectedLangKey;

      const applicationId = props.editApplicationId;
      const api1 =
        await RentalDetailsService.rentalGetRentalNewApplicationSteps({
          formType:
            HRIS_Api_Common_Enums_Enums_RentalApplicationForm.NewApplicationForm,
        });
      const api2 = RentalDetailsService.rentalGetApplicationFormConfiguration();
      let api3;
      if (props.location?.state?.employeeCode) {
        api3 = RentalDetailsService.rentalGetPreviousApplications({
          employeeCode: props.location?.state?.employeeCode,
        });
      } else {
        clearEmployeeDetails();
        api3 = RentalDetailsService.rentalGetPreviousApplications();
      }
      let [stepRes, formConfigRes, appListRes] = await Promise.all([
        api1,
        api2,
        api3,
      ]);

      let applicationStatus;

      let stepList = [];
      let newForm = {};
      let newFormResp = {};
      let newFormConfig = {};
      let formName =
        props.editApplicationId == null
          ? t("rental_create_breadcrumb")
          : t("rental_edit_breadcrumb");
      let activeStep = 0;
      if (
        stepRes &&
        stepRes.data &&
        formConfigRes &&
        formConfigRes.data &&
        appListRes &&
        appListRes.data
      ) {
        let customData = {
          customFunctionLibrary: customFunctionLibrary,
          previousApplications: appListRes.data,
          attachmentControlConfig: formConfigRes.data.attachmentType,
        };
        stepList = configToStepNavModel(stepRes.data);

        // formName and breadcrumb
        if (formConfigRes.data.formName) {
          formName = formConfigRes.data.formName;
        }

        //hr_view: Create Rental application on behalf of employee
        if (props.location?.state?.employeeName) {
          formName = (
            <div
              dangerouslySetInnerHTML={{
                __html: t(
                  "Rental_New_Application_Form_Title_Behalf",
                  props.location.state
                ),
              }}
            />
          );
          setEmployeeDetails(props.location?.state);
        }

        // init form and active step
        if (!isLangChange) {
          if (applicationId != null) {
            const applicationDetailRes =
              await RentalDetailsService.rentalGetApplication({
                applicationId,
              });
            customData.comments = applicationDetailRes.data.comments;

            // special handling - remove comment when editing a draft
            applicationStatus = applicationDetailRes.data.applicationStatus;

            newForm = initFormValue(applicationDetailRes.data.sections);
          } else {
            newForm = initFormValue(formConfigRes.data.sections);
          }

          if (applicationId != null && stepList.length > 2) {
            activeStep = 1;
          }
        } else {
          newForm = { ...form };
          activeStep = state.activeStep;
        }

        // Special handling: hide comment when CreateApplication,
        if (
          applicationId == null ||
          applicationStatus === APPLICATION_STATUS.DRAFT
        ) {
          formConfigRes.data.sections = formConfigRes.data.sections.filter(
            (sec) => sec.fields?.[0]?.controlType !== CCType.COMMENT_LIST
          );
        }

        // newFormResp and formConfig
        newFormResp = formConfigRes.data;
        newFormConfig = configToFormModel({
          applicationId: applicationId,
          stepConfig: stepRes.data,
          sectionFieldConfig: formConfigRes.data.sections,
          customData: customData,
          t: t,
          form: newForm,
        });
      }

      setForm(newForm);
      setFormConfig(newFormConfig);
      setFormSectionConfig(newFormResp);

      // special handling - get effDate termDate range when load application
      // if (applicationId != null) {
      //   customFunctionLibrary[
      //     CustomFunctionKey.EVENT_FUNCTION_CALCULATE_END_DATE
      //   ]({ update: { value: 1, prevValue: 2 }, applyValue: false });
      // }

      setState({
        ...state,
        formName,
        stepList,
        activeStep,
        isLoading: false,
      });
    };
    loadForm();
    ScrollToRentalMenuActiveItem();

    return () => {
      setState({
        isLoading: true,
        stepList: [],
        activeStep: 0,
        formResp: {},
        formName: "",
        touched: false,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLangKey, props?.location?.state]);

  // Breadcrumb
  useEffect(() => {
    if (props.editApplicationId == null) {
      dispatch(resetBcDynamicPaths([{ label: state.formName || "" }]));
    } else {
      dispatch(setBcDynamicPaths([{ label: t("rental_edit_breadcrumb") }]));
    }
  }, [dispatch, t, props.editApplicationId, state.formName]);

  // Navigation and action
  const validateCurrentStep = () => {
    const isValid = !formObjRef.current.getActiveFormState({ expand: true })
      .invalid;

    // Custom Validation
    const activeFormValidation = formObjRef.current.getActiveFormState({ expand: true });
    if (
      (
        activeFormValidation.hasOwnProperty('App_Effective_Date') ||
        activeFormValidation.hasOwnProperty('App_Termination_Date')
      ) && (
        !form["App_Effective_Date"] ||
        !form["App_Termination_Date"] ||
        (
          form["App_Effective_Date"] &&
          form["App_Termination_Date"] &&
          new Date(form["App_Effective_Date"]) > new Date(form["App_Termination_Date"])
        )
      )
    ) {
      showToast({
        severity: MESSAGE_SEVERITY.ERROR,
        summary: "Error Message",
        detail: (
          <div
            id={`message-switch-error-Rfd_Fm`}
            className="hris-message-error"
            dangerouslySetInnerHTML={{
              __html: "Invalid Reimbursement Period",
            }}
          />
        ),
      });
      if (isValid) {
        let elements = document.querySelectorAll('label[for$="-App_Effective_Date"]');
        for (const el of elements) {
          if (el.offsetParent != null) {
            el.scrollIntoView({ behavior: "smooth", block: "center" });
            return;
          }
        }
      }
      return false;
    } else {
      setTimeout(() => {
        scrollToErrorControl();
      }, 100);
      return isValid;
    }
  };

  const nextStep = () => {
    const _state = { ...state };
    if (_state.activeStep < _state.stepList.length - 1) {
      // validation
      if (validateCurrentStep()) {
        // move to next setp
        setState({
          ...state,
          activeStep: state.activeStep + 1,
          touched: false,
        });
        scrollToMainTop();
      } else {
        setState({
          ...state,
          touched: true,
        });
      }
    }
  };

  const backStep = () => {
    if (state.activeStep > 0) {
      setState({
        ...state,
        activeStep: state.activeStep - 1,
      });
      scrollToMainTop();
    } else {
      backPage({ confirm: true });
    }
  };

  const backPage = ({ confirm } = {}) => {
    if (confirm) {
      dispatch(
        openModal({
          title: t("rental_create_backModalTitle"),
          content: t("rental_create_backModalContent"),
          classNameMainDialog: "confirm-message-modal",
          primaryButtonText: t("rental_common_actionOk"),
          primaryButtonClickFn: async ({ closeFn }) => {
            closeFn();
            history.push(PortalLink(FUNCTION_CODE.Rental));
          },
          secondButtonClickFn: ({ closeFn }) => {
            closeFn();
          },
        })
      );
    } else {
      history.push(PortalLink(FUNCTION_CODE.Rental));
    }
  };

  // Submission
  const confirmSaveDraft = async ({ confirm } = {}) => {
    if (confirm) {
      dispatch(
        openModal({
          title: t("rental_create_saveDraftModalTitle"),
          content: t("rental_create_saveDraftModalContent"),
          classNameMainDialog: "confirm-message-modal",
          primaryButtonText: t("rental_common_actionConfirm"),
          primaryButtonClickFn: async ({ closeFn }) => {
            closeFn();
            saveDraftAction();
          },
          secondButtonClickFn: ({ closeFn }) => {
            closeFn();
          },
        })
      );
    } else {
      saveDraftAction();
    }
  };

  const saveDraftAction = async () => {
    try {
      // show spinner
      dispatch(showSpinner(true));

      let res = await saveDraft(
        formSectionConfig.sections,
        form,
        props.editApplicationId
      );
      if (isResponseOk(res)) {
        showTimestampToastSuccess({
          message: t("rental_create_successMessageSave"),
          t: t,
        });
        backPage();
      } else {
        showErrorMessageModal({
          dispatch,
          t,
          content: getResponseMessage(res),
        });
      }
    } catch (e) {
      // show notification?
      console.error(e.message);
    } finally {
      // end spinner
      dispatch(showSpinner(false));
    }
  };

  const confirmSubmitApplication = async () => {
    if (validateCurrentStep()) {
      dispatch(
        openModal({
          title: t("rental_create_submitModalTitle"),
          content: t("rental_create_submitModalContent"),
          classNameMainDialog: "confirm-message-modal",
          primaryButtonText: t("rental_create_submitModalConfirm"),
          primaryButtonClickFn: async ({ closeFn }) => {
            closeFn();
            submitApplicationAction();
          },
          secondButtonClickFn: ({ closeFn }) => {
            closeFn();
          },
        })
      );
    } else {
      setState({
        ...state,
        touched: true,
      });
    }
  };

  const submitApplicationAction = async () => {
    try {
      // show spinner
      dispatch(showSpinner(true));
      let res = await submitApplication(
        formSectionConfig.sections,
        form,
        props.editApplicationId
      );
      if (isResponseOk(res)) {
        showTimestampToastSuccess({
          message: t("rental_create_successMessageSubmit"),
          t: t,
        });
        backPage();
      } else {
        showErrorMessageModal({
          dispatch,
          t,
          content: getResponseMessage(res),
        });
      }
    } catch (e) {
      // show notification?
      console.error(e.message);
    } finally {
      // end spinner
      dispatch(showSpinner(false));
    }
  };

  // Rendering
  const renderFormTitle = () => (
    <div className="rental-form-title">
      <Button
        onClick={backStep}
        icon="pi pi-angle-left"
        className="p-button-rounded-lg p-button-text"
      />
      <h2>{state.formName}</h2>
    </div>
  );

  const renderForm = () => (
    <MultiStepForm
      form={form}
      activeStep={state.activeStep}
      config={formConfig}
      ref={formObjRef}
      touched={state.touched}
    />
  );

  const renderFooter = () => {
    const enableDraft = rentalConfig.draftModule;
    return (
      <div className="footer p-grid p-align-center p-justify-between">
        <Button
          onClick={() => backStep()}
          className="p-button-outlined secondary back-button"
        >
          {t("rental_common_actionBack")}
        </Button>
        <div className="right-button-group">
          {(enableDraft && !props.HREdit) && (
            <Button
              onClick={() => confirmSaveDraft()}
              className="p-button-outlined secondary"
            >
              {props.editApplicationId != null
                ? t("rental_common_actionSave")
                : t("rental_create_footerSaveAsDraft")}
            </Button>
          )}

          {state.activeStep < state.stepList.length - 1 ? (
            <Button onClick={() => nextStep()} className="p-button next-button">
              {t("rental_common_actionNext")}
            </Button>
          ) : (
            <Button
              onClick={() => confirmSubmitApplication()}
              className="p-button next-button"
            >
              {t("rental_common_actionSubmit")}
            </Button>
          )}
        </div>
      </div>
    );
  };

  const renderStepSkeleton = () => (
    <div className="p-grid p-my-6">
      <Skeleton height="20px"></Skeleton>
    </div>
  );

  const renderFormSkeleton = () => formSkeleton;

  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          RENTAL_T_CONTEXT_KEY.COMMON,
          RENTAL_T_CONTEXT_KEY.CREATE,
        ]}
      />
      <div className="rental-ms-form rental-create">
        <div className="header header-sticky">
          <RentalMenu
            menu={rentalMenu}
            currentPageKey={RENTAL_MENU_PAGE_KEY.ADD_NEW_APPLICATION}
          />

          {/* Title */}
          {renderFormTitle()}

          {/* Steps Nav*/}
          {state.isLoading ? (
            renderStepSkeleton()
          ) : (
            <Steps
              className="steps"
              model={state.stepList}
              activeIndex={state.activeStep}
            />
          )}
        </div>
        {state.isLoading ? renderFormSkeleton() : renderForm()}

        {/* Footer */}
        {renderFooter()}
      </div>
    </>
  );
};

export default CreateRentalApplication;
