import moment from "moment";
import { BCType } from "../../../../../components/base-control/base-control";
import { generateBasicLayout } from "../../../../../components/base-form/utils";
import { generateBasicHalfColumnLayout } from "../../../../../components/base-form/utils";
import {
  AnalysisCode,
  convertToAttachmentControl,
  convertToControl,
  convertToNoteControl,
  convertToAcceptanceControl,
} from "./../../utils/analysis-code-helper";
import { getDisplayUom, extractUnit } from "../../utils/utils";
import { DATETIME_FORMAT, TIME_FORMAT } from "../../constants/contants";
import {
  NwowFormConfig,
  BASIC_CONTROL_LAYOUT_CONFIG,
  BASIC_CONTROL_LAYOUT_CONFIG_FULL_COLUMN,
  BASIC_CONTROL_LAYOUT_CONFIG_HALF_COLUMN,
  TIME_SNAP_OPTION,
  NwowApplicationModel,
  initFormAnalysisCode,
  initFormAttachment,
} from "../../utils/config-helper";
import { isAutoCalculateEndDate } from "./../../utils/config-helper";
import { config } from "dotenv/types";
export interface NwowInfo extends NwowFormConfig {
  [key: string]: any;
}

export const getInitLayout = () => {
  return {
    rows: [
      {
        columns: [
          {
            control: "NwowCode",
            config: { ...BASIC_CONTROL_LAYOUT_CONFIG },
          },
        ],
      },
    ],
  };
};

export const getBasicLayout = (nwowInfo: NwowFormConfig) => {
  const autoCalEndDate = isAutoCalculateEndDate(nwowInfo);
  return {
    rows: [
      {
        columns: [
          {
            control: "NwowCode",
            config: { ...BASIC_CONTROL_LAYOUT_CONFIG_FULL_COLUMN },
          },
          /*{
            control: "Balance",
            config: { ...BASIC_CONTROL_LAYOUT_CONFIG },
          },*/
        ],
      },
      {
        columns: [
          {
            control: "NwowStartDate",
            config: { ...BASIC_CONTROL_LAYOUT_CONFIG_HALF_COLUMN },
          },
          {
            control: "NwowEndDate",
            config: { ...BASIC_CONTROL_LAYOUT_CONFIG_HALF_COLUMN },
          },
        ],
      },
    ],
  };
};

export const getRemarkLayout = (nwowInfo: NwowFormConfig) => {
  const autoCalEndDate = isAutoCalculateEndDate(nwowInfo);
  return {
    rows: [
      {
        columns: [
          {
            control: "Remarks",
            config: { ...BASIC_CONTROL_LAYOUT_CONFIG_FULL_COLUMN },
          },
        ],
      },
    ],
  };
};

export const getInitControl = ({
  nwowTypes,
  t,
}: {
  nwowTypes: any[];
  t: any;
}) => {
  return [
    {
      key: "NwowCode",
      label: t("Nwow_form_selectNwowType"),
      type: BCType.select,
      required: true,
      enum: nwowTypes.map((nwow) => ({
        label: `${nwow.nwowCode} - ${nwow.nwowName}`,
        value: nwow.nwowCode,
      })),
      hasFilterEnum: false,
      placeholder: t("Nwow_form_selectNwowType"),
    },
  ];
};

export const getBasicControl = ({
  nwowTypes,
  t,
  nwowInfo,
  onSeeBalanceDetail,
  isChangeApplication,
}: {
  nwowTypes: any[];
  t: any;
  nwowInfo: NwowFormConfig;
  onSeeBalanceDetail: any;
  isChangeApplication: any;
}) => {
  // Basic setting
  const _formConfig = { ...nwowInfo };
  const showTodayBalance = false;
  const unit = getDisplayUom(_formConfig.uom, t);

  const timePickerStep =
    _formConfig.periodFromSnapTo &&
    _formConfig.periodFromSnapTo in TIME_SNAP_OPTION
      ? TIME_SNAP_OPTION[_formConfig.periodFromSnapTo].step
      : _formConfig.periodToSnapTo &&
        _formConfig.periodToSnapTo in TIME_SNAP_OPTION
      ? TIME_SNAP_OPTION[_formConfig.periodToSnapTo].step
      : 5;

  const autoCalEndDate = isAutoCalculateEndDate(nwowInfo);
  const sampleTimeSegment = [
    { label: "AM", value: "AM" },
    { label: "PM", value: "PM" },
  ];

  return [
    {
      key: "NwowCode",
      label: t("Nwow_form_selectNwowType"),
      type: BCType.select,
      required: true,
      enum: nwowTypes.map((nwow) => ({
        label: `${nwow.nwowCode} - ${nwow.nwowName}`,
        value: nwow.nwowCode,
      })),
      hasFilterEnum: false,
      placeholder: t("Nwow_form_selectNwowType"),
    },
    ...(_formConfig.showBalanceOnApplication
      ? [
          {
            key: "Balance",
            noLabel: true,
            componentRender: () => (
              <div className="nwow-application-balance">
                <div>
                  {showTodayBalance
                    ? t("Nwow_submit_yourBalanceAsOfToday", {
                        nwowType: _formConfig.nwowName,
                      })
                    : t("Nwow_submit_yourBalanceAsOfYeadEnd", {
                        nwowType: _formConfig.nwowName,
                      })}
                </div>
                <div onClick={onSeeBalanceDetail}>
                  (<u>{t("Nwow_submit_seeBalanceDetail")}</u>)
                </div>
                <div className="app-balance">{`${
                  showTodayBalance
                    ? _formConfig.nwowBalanceAsOfCalcDate
                    : _formConfig.nwowBalanceAsOfYearEnd
                } ${unit}`}</div>
              </div>
            ),
          },
        ]
      : []), // TODO*/
    {
      key: "NwowStartDate",
      label: t("Nwow_form_nwowStartDate"),
      type: BCType.date,
      required: true,
      dateFormat: "yy-mm-dd",
      placeholder: t("Nwow_form_select"),
      config: {
        footerTemplate: () => (
          <div className="nwow-submit-legend">
            <strong className="hollow-circle p-disabled" />
            <div className="description">
              {t("Nwow_form_calendarLegend_appliedNWoW")}
            </div>
          </div>
        ),
      },
    },
    {
      key: "NwowEndDate",
      label: t("Nwow_form_nwowEndDate"),
      type: BCType.date,
      required: !autoCalEndDate,
      dateFormat: "yy-mm-dd",
      placeholder: t("Nwow_form_select"),
      config: {
        readOnly: autoCalEndDate,
        footerTemplate: () => (
          <div className="nwow-submit-legend">
            <strong className="hollow-circle" />
            <div className="description">
              {t("Nwow_form_calendarLegend_appliedNWoW")}
            </div>
          </div>
        ),
      },
    },
  ];
};

// Create form
export const getControlsAndLayout = ({
  nwowTypes,
  nwowInfo,
  selectedNwowCode,
  onSeeBalanceDetail,
  t,
  isChangeApplication,
}: {
  nwowTypes: any[];
  nwowInfo: any;
  selectedNwowCode: string;
  onSeeBalanceDetail: any;
  t: any;
  isChangeApplication: any;
}) => {
  let controls = [];
  let layout: any = {};

  if (selectedNwowCode && Object.keys(nwowInfo).length) {
    controls = getBasicControl({
      nwowTypes,
      nwowInfo,
      t,
      onSeeBalanceDetail,
      isChangeApplication,
    });
    layout = getBasicLayout(nwowInfo);

    // render extra fields
    const analysisCodeControls = nwowInfo.nwowAnalysisCode.map((code: any) =>
      convertToControl({ data: code, t })
    );
    if (analysisCodeControls?.length) {
      controls = controls.concat(analysisCodeControls);
      layout.rows = layout.rows.concat(
        (generateBasicHalfColumnLayout(analysisCodeControls) as any).rows
      );
    }

    // render remark

    const remarkControls = [
      convertToRemarkControl({
        label: "Remarks",
        t: t,
        isChangeApplication: isChangeApplication,
        controlConfig: {
          required: false,
        },
      }),
    ];
    if (remarkControls?.length) {
      controls = controls.concat(remarkControls);
      layout.rows = layout.rows.concat(
        (generateBasicLayout(remarkControls) as any).rows
      );
    }

    // render required file attachment
    const requiredFileControls = nwowInfo.applyNwowRequiredAttachmentType.map(
      (fileConfig: any) =>
        convertToAttachmentControl({
          config: fileConfig,
          t: t,
          controlConfig: {
            required: true,
          },
        })
    );
    if (requiredFileControls?.length) {
      controls = controls.concat(requiredFileControls);
      layout.rows = layout.rows.concat(
        (generateBasicLayout(requiredFileControls) as any).rows
      );
    }

    // render optional file attachment
    const optionalFileControls = nwowInfo.applyNwowOptionalAttachmentType.map(
      (fileConfig: any) =>
        convertToAttachmentControl({
          config: fileConfig,
          t: t,
          controlConfig: {
            required: false,
          },
        })
    );
    if (optionalFileControls?.length) {
      controls = controls.concat(optionalFileControls);
      layout.rows = layout.rows.concat(
        (generateBasicLayout(optionalFileControls) as any).rows
      );
    }
    // render reminder
    if (nwowInfo.reminderMessage) {
      const noteControls = [convertToNoteControl(nwowInfo.reminderMessage)];
      controls = controls.concat(noteControls);
      layout.rows = layout.rows.concat(
        (generateBasicLayout(noteControls) as any).rows
      );
    }
    // render acceptance
    if (nwowInfo.reminderMessage) {
      const acceptanceControls = [
        convertToAcceptanceControl({
          config: nwowInfo.acceptanceLabel,
          t: t,
          controlConfig: {
            required: true,
          },
        }),
      ];
      controls = controls.concat(acceptanceControls);
      layout.rows = layout.rows.concat(
        (generateBasicLayout(acceptanceControls) as any).rows
      );
    }

    if (isChangeApplication) {
      controls.forEach((control) => {
        if (
          control.key != "NwowStartDate" &&
          control.key != "NwowEndDate" &&
          control.key != "Acceptance" &&
          control.key != "ATeleWeek"
        ) {
          if (control.config != undefined) {
            control.config.readOnly = isChangeApplication;
          } else {
            let newConfig: any = {
              readOnly: isChangeApplication,
            };
            control.config = newConfig;
          }
        }
      });
    }
  } else {
    controls = getInitControl({ nwowTypes, t });
    layout = getInitLayout();
  }

  return [controls, layout];
};

export const initFormWithNwowApplication = (
  application: NwowApplicationModel
) => {
  let form: any = {
    NwowCode: application.nwowCode,
    Balance: application.balanceAsOfYearEnd,
    NwowStartDate: new Date(application.nwowFrom),
    NwowStartTime: new Date(application.nwowFrom),
    NwowEndDate: new Date(application.nwowTo),
    NwowEndTime: new Date(application.nwowTo),
    Units: extractUnit(application.unit),
    //AdminChange: false,
    Remarks: application.remarks,
  };

  // analysis code
  if (application.analyses?.length) {
    const analysisCodeForm = initFormAnalysisCode(application.analyses);
    form = {
      ...form,
      ...analysisCodeForm,
    };
  }

  if (application.attachment?.length) {
    const attachmentForm = initFormAttachment(application.attachment);
    form = {
      ...form,
      ...attachmentForm,
    };
  }

  return form;
};

export const prepareApplicationPayload = ({
  form,
  nwowInfo,
  amend,
  preApproval,
  includeAttachment = true,
  defaultUnits = 0, // custom logic, requried by backend. Allow to set to 1 when calculate unit
}: {
  form: any;
  nwowInfo: NwowFormConfig;
  amend: boolean;
  preApproval: boolean;
  includeAttachment: boolean;
  defaultUnits: Number;
}) => {
  const {
    // Value that needs to process before submit
    NwowStartDate: nwowStartDate,
    NwowStartTime: nwowStartTime,
    NwowStartTimeSegment: nwowStartTimeSegment,
    NwowEndDate: nwowEndDate,
    NwowEndTime: nwowEndTime,
    NwowEndTimeSegment: nwowEndTimeSegment,
  } = form;

  let retForm: any = {
    NwowCode: form["NwowCode"],
    UnitOfMeasure: preApproval
      ? nwowInfo.nwowPreApproval?.[0].unitOfMeasure
      : nwowInfo.uom,
    Remarks: form["Remarks"] || "",
    Units: form["Units"] || defaultUnits,
  };

  // Process NwowStartDatetime
  // Backend required to always submit with a date even when date is not needed from use
  let retNwowStartDatetime;
  retNwowStartDatetime = moment(nwowStartDate || nwowEndDate || new Date());
  if (nwowInfo.useTimeSegmentName && nwowInfo?.segmentTimeInfo) {
    const nwowStartSegmentTime =
      nwowInfo.segmentTimeInfo["start"][nwowStartTimeSegment];
    retNwowStartDatetime = retNwowStartDatetime.set({
      hour: nwowStartSegmentTime.getHours() || 0,
      minute: nwowStartSegmentTime.getMinutes() || 0,
      second: nwowStartSegmentTime.getSeconds() || 0,
    });
  } else {
    retNwowStartDatetime = retNwowStartDatetime.set({
      hour: nwowStartTime?.getHours() || 0,
      minute: nwowStartTime?.getMinutes() || 0,
      second: nwowStartTime?.getSeconds() || 0,
    });
  }
  retNwowStartDatetime = retNwowStartDatetime.format(DATETIME_FORMAT);
  retForm["NwowStartDateTimeOrDate"] = retNwowStartDatetime;

  // Process NwowEndDatetime
  // Backend requried logic: if empty, match start date or fill new Date()
  let retNwowEndDatetime;
  retNwowEndDatetime = moment(nwowEndDate || nwowStartDate || new Date());
  if (nwowInfo.useTimeSegmentName && nwowInfo?.segmentTimeInfo) {
    const nwowEndSegmentTime =
      nwowInfo.segmentTimeInfo["end"][nwowEndTimeSegment];
    retNwowEndDatetime = retNwowEndDatetime.set({
      hour: nwowEndSegmentTime.getHours() || 0,
      minute: nwowEndSegmentTime.getMinutes() || 0,
      second: nwowEndSegmentTime.getSeconds() || 0,
    });
  } else {
    retNwowEndDatetime = retNwowEndDatetime.set({
      hour: nwowEndTime.getHours() || 0,
      minute: nwowEndTime.getMinutes() || 0,
      second: nwowEndTime.getSeconds() || 0,
    });
  }
  retNwowEndDatetime = retNwowEndDatetime.format(DATETIME_FORMAT);
  retForm["NwowEndDateTimeOrDate"] = retNwowEndDatetime;

  // Amend
  retForm["Amend"] = !!amend;

  // Preapproval logic -- eventDateApplication
  // if (preApproval && nwowInfo.nwowPreApproval?.[0]?.eventDateApplication) {
  //   retForm["NwowEndDateTimeOrDate"] = retForm["NwowStartDateTimeOrDate"];
  // }

  // PreApproval logic
  retForm["IsAccrual"] = !!preApproval;

  // analysis code
  let analysisCodeList = preApproval
    ? nwowInfo?.nwowPreApproval?.[0]?.applyEntitlementAnalysisCode || []
    : nwowInfo.nwowAnalysisCode || [];

  analysisCodeList.forEach((code: AnalysisCode) => {
    if (!("Analysis" in retForm)) {
      retForm["Analysis"] = [];
    }
    if (form[code.analysisCode]) {
      let value = form[code.analysisCode];
      if (value instanceof Date) {
        value = moment(value).format(DATETIME_FORMAT);
      }
      // construct json
      let jsonValue: any = {
        AnalysisCodeId: code.id,
        AnalysisCodeValue: [
          {
            Value: value,
          },
        ],
      };
      // stringify
      retForm["Analysis"].push(jsonValue);
    }
  });
  if (retForm["Analysis"]) {
    retForm["Analysis"] = JSON.stringify(retForm["Analysis"]);
  }

  // documents
  if (includeAttachment) {
    // temp solution: use key=AttachmentList and assume there will be only 1 attachmentType
    retForm["Attachment"] = null;

    let documentList = preApproval
      ? [
          ...(nwowInfo.nwowPreApproval?.[0]
            .applyEntitlementOptionalAttachmentType || []),
          ...(nwowInfo.nwowPreApproval?.[0]
            .applyEntitlementRequiredAttachmentType || []),
        ]
      : [
          ...nwowInfo.applyNwowOptionalAttachmentType,
          ...nwowInfo.applyNwowRequiredAttachmentType,
        ];

    documentList.forEach((attachmentConfig: any) => {
      if (form[attachmentConfig.typeCode]) {
        retForm["AttachmentTypeCode"] = attachmentConfig.typeCode;
        // new files
        retForm["AttachmentList"] = form[attachmentConfig.typeCode]?.filter(
          (x: any) => x instanceof File
        );
        // existing files
        form[attachmentConfig.typeCode]
          ?.filter((x: any) => !(x instanceof File))
          .forEach((apiFile: any, idx: number) => {
            const { id, name, uploadId, ..._apiFile } = apiFile;
            delete retForm["Attachment"];
            Object.keys(_apiFile).forEach((key: any) => {
              retForm[`Attachment[${idx}][${key}]`] = _apiFile[key];
            });
          });
      }
    });
  }

  return retForm;
};

export const identifyTimeSegment = ({
  form,
  unitInfo,
}: {
  form: any;
  unitInfo: any;
}) => {
  let result = {
    start: "",
    end: "",
  };

  const appStartTime = moment(form["NwowStartDate"]).format(TIME_FORMAT);
  const appEndTime = moment(form["NwowEndDate"]).format(TIME_FORMAT);

  if (unitInfo?.startDateInformation?.shiftActualStartAt) {
    const shiftStartTime = moment(
      unitInfo?.startDateInformation?.shiftActualStartAt
    ).format(TIME_FORMAT);
    if (appStartTime === shiftStartTime) {
      result["start"] = "AM";
    }
  }
  if (unitInfo?.toDateInformation?.mealActualStartAt) {
    const mealStartTime = moment(
      unitInfo?.toDateInformation?.mealActualStartAt
    ).format(TIME_FORMAT);
    if (appEndTime === mealStartTime) {
      result["end"] = "AM";
    }
  }
  if (unitInfo?.startDateInformation?.mealActualEndAt) {
    const mealEndTime = moment(
      unitInfo?.startDateInformation?.mealActualEndAt
    ).format(TIME_FORMAT);
    if (appStartTime === mealEndTime) {
      result["start"] = "PM";
    }
  }
  if (unitInfo?.toDateInformation?.shiftActualEndAt) {
    const shiftEndTime = moment(
      unitInfo?.toDateInformation?.shiftActualEndAt
    ).format(TIME_FORMAT);
    if (appEndTime === shiftEndTime) {
      result["end"] = "PM";
    }
  }

  return result;
};

export const convertToRemarkControl = ({
  label,
  controlConfig,
  t,
  isChangeApplication,
}: {
  label: string;
  controlConfig: any;
  t: any;
  isChangeApplication: any;
}) => {
  let RemarkControl = {
    key: "Remarks",
    className: "remark",
    label: label,
    type: BCType.textarea,
    placeholder: t("Nwow_form_entryRemarks"),
    readOnly: isChangeApplication,
    config: {
      readOnly: isChangeApplication,
    },
    ...controlConfig,
  };

  return RemarkControl;
};
