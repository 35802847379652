import "./compensation-docs.scss";
import { Animated } from "react-animated-css";

import moment from "moment";

import { useEffect, useState } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

//component
import BaseTable, {
  BUILD_SEARCH_CONFIG,
} from "../../../../components/base-table/base-table-portal";
import { DEFAULT_BT_SEARCH } from "../../../../components/base-table/base-table-model";
import { resetBcDynamicPaths } from "../../../../redux/actions/breadcrumb";

//const
import { columnConfig } from "./../utils";
import { tableConfigWithExpander } from "./../utils";

//services
import { DocumentsService } from "../../../../services/hrmnet-api";
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_FILE_NAME,
  FILE_RELEASE_DATE,
} from "../../../../constants";
import { GetUploadDownload } from "../../../../components/base-control/upload-control/utils";

const CompensationDocs = () => {
  // i18n
  const { t } = useTranslation();
  const dataTable = useRef();
  const downloadRes = useRef();
  const dispatch = useDispatch();
  const [expanderControl, setExpanderControl] = useState();
  const employeeCode = useSelector((state) => state.auth?.employeeCode);
  const selectedLangKey = useSelector(
    (state) => state.language?.language?.selectedLang?.key
  );
  const [rerenderLanguage, setRerenderLanguage] = useState(false);

  // ------------ customFunction ---------------------------------------------------

  /** Default search */
  const BT_SEARCH = {
    ...DEFAULT_BT_SEARCH,
    sortObj: {
      key: FILE_RELEASE_DATE,
      direction: -1,
    },
  };

  const tableSearchFn = async (searchConfig, employeeCode) => {
    if (!searchConfig) {
      searchConfig = { ...BT_SEARCH };
    }
    searchConfig = BUILD_SEARCH_CONFIG(searchConfig);

    try {
      var cmd = await DocumentsService.documentGetCompensations({
        pageIndex: 1,
        pageSize: 999,
      });
    } catch (error) {
      setExpanderControl({ displayedRecord: [] });
      return {
        datas: [],
        total: 0,
        searchConfig: searchConfig,
      };
    }

    const [res] = await Promise.all([cmd]);
    if (res && res.data) {
      setRerenderLanguage(true);
      const _datas = res.data.map((data) => {
        const _data = {
          ...data,
          id: data.id.toString(),
          customFileName: data?.fileName ?? data?.description,
          releaseDate: moment(data.releaseDate, DEFAULT_DATE_FORMAT),
        };

        if (!data?.fileName && !!data?.description) {
          delete _data.description;
        }

        return _data;
      });
      setExpanderControl({ ...expanderControl, displayedRecord: _datas });
      return {
        datas: _datas,
        total: res.data.length,
        searchConfig: searchConfig,
      };
    } else {
      return {
        datas: [],
        total: 0,
        searchConfig: searchConfig,
      };
    }
  };

  // -------------- use effect ------------------------------------------------
  useEffect(() => {
    dispatch(resetBcDynamicPaths());
  }, [dispatch]);

  const mountedRef = useRef(false);

  useEffect(() => {
    const initPage = async () => {
      if (mountedRef.current) return null;
    };

    try {
      initPage();
    } finally {
      if (!mountedRef.current) {
        // preventing to load twice
        mountedRef.current = true;
      }
    }
  }, []);

  useEffect(() => {
    if (rerenderLanguage) {
      dataTable.current?.rerenderLanguage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLangKey]);

  const axiosCallback = (resp) => {
    downloadRes.current = resp;
  };

  // actions
  const onSingleDownload = async (item) => {
    await GetUploadDownload(item.id, DEFAULT_FILE_NAME.COMPENSATION_DOC);
  };

  let configModel = tableConfigWithExpander(
    t,
    t("compensation_title"),
    t("compensation_description"),
    [
      // { expander: true, style: { width: "3em", padding: "0" }, isMobileOnly: true, isExpansionRow: false },
      columnConfig(
        t("compensation_datatable_docName"),
        "customFileName",
        { placeholder: t("compensation_datatable_docName_placeholder") },
        false,
        true
      ),
      // columnConfig(
      //   t("compensation_datatable_fileType"),
      //   "fileType",
      //   {
      //     placeholder: t("compensation_datatable_fileType_placeholder"),
      //     type: "select",
      //   },
      //   true
      // ),
      // columnConfig(
      //   t("compensation_datatable_taxCycleName"),
      //   "cycleName",
      //   { placeholder: t("compensation_datatable_taxCycleName_placeholder") },
      //   false
      // ),
      columnConfig(
        t("compensation_datatable_releaseDt"),
        "releaseDate",
        {
          placeholder: t("compensation_datatable_releaseDt_placeholder"),
          type: "daterange",
        },
        false,
        false,
        "date",
        {
          dateFormat: DEFAULT_DATE_FORMAT,
        }
      ),
      columnConfig(
        t("document_datatable_description"),
        "description",
        { placeholder: t("document_datatable_description_placeholder") },
        true,
        false
      ),
    ],
    onSingleDownload,
    expanderControl,
    setExpanderControl
  );

  return (
    <Animated
      animationIn="slideInRight"
      animationOut="slideOutRight"
      animationInDuration={200}
      animationOutDuration={200}
      isVisible={true}
    >
      <div className="compensation-docs-table">
        <BaseTable
          ref={dataTable}
          configModel={configModel}
          searchConfig={BT_SEARCH}
          searchFn={() => tableSearchFn(null, employeeCode)}
          isClientSize={true}
          id="compensation-docs-table"
        />
      </div>
    </Animated>
  );
};

export default CompensationDocs;
