import { BREADCRUMB_SET_DYNAMIC_PATHS } from "../reduxConstants";
import { BREADCRUMB_RESET_DYNAMIC_PATHS } from "../reduxConstants";
const initialState = {
  dynamicPaths: [],
};

export default function breadcrumb(state = initialState, action) {
  switch (action.type) {
    case BREADCRUMB_SET_DYNAMIC_PATHS: {
      return {
        ...state,
        dynamicPaths: action.payload,
      };
    }
    case BREADCRUMB_RESET_DYNAMIC_PATHS: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
