import { SET_SPINNER, RESET_SPINNER } from "../reduxConstants";

const initialState = {
  isOpen: false,
};

export default function spinner(state = { ...initialState }, action) {
  switch (action.type) {
    case SET_SPINNER: {
      return {
        ...action.payload,
      };
    }
    case RESET_SPINNER: {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
}
