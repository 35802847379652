import { SET_SIDEBAR, SIDEBAR_CLEAR } from "./../reduxConstants";

/**
 * set user status
 */
export const setSideBar = (sideBarInfo) => {
  return {
    type: SET_SIDEBAR,
    payload: sideBarInfo,
  };
};

/**
 * Clear the sidebar - back to initialState
 * @returns
 */
export const clearSideBar = () => {
  return {
    type: SIDEBAR_CLEAR,
    payload: {},
  };
};
