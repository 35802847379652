// scss
import "./main-header.scss";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

// import { Button } from "primereact/button";
import { Menubar } from "primereact/menubar";
import { useMediaQuery } from "react-responsive";

// import MainLogo from "./main-logo";
import MainToggle from "./main-toggle";
import MainUser from "./main-user";
// import MainEntityInfo from "./main-entity-info";
import MainLanguage from "./main-language/main-language";
import MainBreadcrumb from "./main-breadcrumb";
import MainNotificationIcon from "./main-notification-icon";

//const
import { IMAGE_DEFAULT } from "./../../../../constants/index";
import { PortalLink } from "../../../../services/utils";
import MainUnifyConnect from "./main-unify-connect";

/**
 *
 */
const MainHeader = () => {
  const defaultLogo = IMAGE_DEFAULT.TRICOR_LOGO;
  const clientInfo = useSelector((state) => state.global?.clientInfo);

  const renderIcon = (tenantLogo, isDesktop = true) => {
    return (
      <Link to={PortalLink()}>
        <div className="tenant-logo-container p-grid p-nogutter p-align-center">
          <img
            src={tenantLogo?.length === 0 ? defaultLogo : tenantLogo}
            alt=""
            className={
              isDesktop
                ? "tenant-logo-size p-justify-center p-col-align-center p-ml-3"
                : "tenant-logo-size p-justify-center p-col-align-center p-ml-1"
            }
          />
        </div>
      </Link>
    );
  };

  const renderLeftHeader = () => {
    return (
      <div className="p-d-flex tenant-left-header">
        <div className="p-d-md-none">
          <MainToggle />
        </div>
        {renderIcon(clientInfo?.tenantLogo)}
        {/* <MainLogo />
        <div className="p-d-none p-d-md-block">
          <Button
            type="button"
            disabled
            className="p-button-secondary p-button-text"
            icon={`pi pi-ellipsis-v`}
            iconPos="right"
          ></Button>
        </div> */}
        {/* <MainEntityInfo /> */}
        <MainBreadcrumb />
      </div>
    );
  };

  const renderLeftHeaderMobile = () => {
    return (
      <div className="p-d-flex tenant-left-header">
        <div className="p-d-md-none">
          <MainToggle />
        </div>
        {renderIcon(clientInfo?.tenantLogo, false)}
        {/* <MainEntityInfo /> */}
      </div>
    );
  };

  const renderRightHeader = () => {
    return (
      <>
        <div className="p-d-flex">
          {clientInfo?.signInUnifyEnabled && (
            <MainUnifyConnect unifyUrl={clientInfo?.signInUnifyUrl} />
          )}
          <MainNotificationIcon />
          {/* <MainSearch /> */}
          <MainLanguage isAuthenticated={true} />
          {/* <MainNotification /> */}
          <MainUser />
          {/* <MainSwitcher /> */}
        </div>
      </>
    );
  };

  const isDesktop = useMediaQuery({ minWidth: "769px" });

  return (
    <>
      <div id="main-header" className="main-header">
        <Menubar
          start={isDesktop ? renderLeftHeader() : renderLeftHeaderMobile()}
          end={renderRightHeader()}
        />
      </div>
    </>
  );
};
export default MainHeader;
