import moment from "moment";
import { TreeSelect } from "primereact/treeselect";
import { Button } from "primereact/button";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Skeleton } from "primereact/skeleton";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { BCType } from "../../../../components/base-control/base-control";
import { getControlModel } from "../../../../components/base-control/base-cotrol-model";
import {
  DEFAULT_BT_SEARCH,
  getBTConfig,
  getColumModel,
} from "../../../../components/base-table/base-table-model";
import BaseTable from "../../../../components/base-table/base-table-portal";
import {
  DEFAULT_DATE_FORMAT,
  FUNCTION_CODE,
  MATCH_MODE,
  MENU_NAME,
} from "../../../../constants";
import {
  resetMainForm,
  resetMainFormConfig,
  resetMainFormValue,
} from "../../../../redux/actions/form";
import { AdminAccessPermissionService } from "../../../../services/hrmnet-api";
import { GetCurrentActions } from "../../../layouts/components/main-sidebar/main-menu-tree";
import { useWindowSize } from "../../utils/window-size/useWindowSize";

const ROLE_NAME_CONFIGURATION_SORT_KEY = "roleName";

const AccessPermissionRole = () => {
  const { isMobile } = useWindowSize();
  const { t } = useTranslation();
  const dataTable = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const actionsAccessRights = useSelector((state) => {
    const result = [];
    GetCurrentActions(state.menu.sideBar, result);
    return result;
  });
  const [state, setState] = useState({
    isLoading: true,
    detailExpandedRows: null,
    tableConfig: null,
  });
  const [rerenderLanguage, setRerenderLanguage] = useState(false);
  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );

  /** Default search */
  const BT_SEARCH = {
    ...DEFAULT_BT_SEARCH,
    sortObj: {
      key: ROLE_NAME_CONFIGURATION_SORT_KEY,
      direction: 1,
    },
  };

  useEffect(() => {
    const loadInit = async () => {
      const _state = state;

      _state.isLoading = false;
      setState(_state);

      if (rerenderLanguage) {
        dataTable.current?.rerenderLanguage();
      }

      dispatch(resetMainFormConfig());
      dispatch(resetMainForm());
      dispatch(resetMainFormValue());
    };
    loadInit();

    return () => {
      setState({
        ...state,
        isLoading: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLangKey]);

  // actions
  const onUpdateAction = async (item) => {
    history.push({
      pathname: `${FUNCTION_CODE.AccessPermissionRole}/${FUNCTION_CODE.AccessPermissionRoleUpdate}`,
      state: {
        roleId: item.id,
      },
    });
  };

  const renderAction = () => {
    if (
      actionsAccessRights?.find(
        (x) => x.key === MENU_NAME.Access_Permission_Role_Edit
      )
    ) {
      return [
        {
          isOverflow: false,
          name: "access_permission_role_Edit_Action",
          title: t("access_permission_role_Edit_Action"),
          clickFn: (item) => onUpdateAction(item),
          renderIcon: "pi pi-pencil",
        },
      ];
    }

    return [];
  };

  const actionsSingle = renderAction();

  let configColumns = [
    getColumModel({
      header: t("access_permission_role_column_roleName"),
      key: "name",
      dataType: "string",
      config: {
        sortable: true,
      },
      width: 12,
      isRowExpansion: false,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t("access_permission_role_roleName_placeholder"),
        type: BCType.multiselect,
        config: {
          showClear: true,
        },
      }),
      frozen: actionsSingle.length > 0,
    }),
    getColumModel({
      header: t("access_permission_role_column_moduleNames"),
      key: "moduleNames",
      dataType: "string",
      config: {
        sortable: true,
      },
      isRowExpansion: true,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t("access_permission_role_moduleNames_placeholder"),
        type: BCType.multiselect,
        config: {
          showClear: true,
        },
      }),
      frozen: false,
      body: (data) => {
        return (
          <>
            {data?.moduleNames.map((x, index) => {
              return (
                <Chip
                  key={`access-permission-role-moduleNames-${index}`}
                  label={x}
                />
              );
            })}
          </>
        );
      },
      width: "30",
    }),
    // getColumModel({
    //   header: t("access_permission_role_column_dataAuthorizations"),
    //   key: "dataAuthorizations",
    //   config: {
    //     sortable: true,
    //   },
    //   isRowExpansion: true,
    //   mobileWidthAuto: true,
    //   control: getControlModel({
    //     placeholder: t("access_permission_role_dataAuthorizations_placeholder"),
    //   }),
    //   body: (rowData) => {
    //     if (rowData?.dataAuthorizations) {
    //       return <dl>{rowData?.dataAuthorizations.map((data, idx) => {
    //         return <div className="data-authorization-body" key={uuidv4()}><dt >{data.name}:</dt>
    //           <dd>{data.values.toString()}</dd></div>

    //       })}</dl>
    //     }

    //     return <></>
    //   },
    //   frozen: false,
    // }),
    getColumModel({
      header: t("access_permission_role_column_lastUpdated"),
      key: "lastUpdated",
      dataType: BCType.date,
      config: {
        dateFormat: DEFAULT_DATE_FORMAT,
        sortable: true,
      },
      isRowExpansion: false,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t("access_permission_role_lastUpdated_placeholder"),
        type: BCType.daterange,
      }),
      filterMatchMode: MATCH_MODE.RANGE,
      frozen: false,
    }),
    getColumModel({
      header: t("access_permission_role_column_updatedBy"),
      key: "updatedBy",
      dataType: "string",
      config: {
        sortable: true,
      },
      isRowExpansion: false,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t("access_permission_role_updatedBy_placeholder"),
        type: BCType.multiselect,
        config: {
          showClear: true,
        },
      }),
    }),
  ];

  const onAddAction = async () => {
    history.push({
      pathname: `${FUNCTION_CODE.AccessPermissionRole}/${FUNCTION_CODE.AccessPermissionRoleAdd}`,
    });
  };

  const renderRoleAdd = () => {
    return (
      actionsAccessRights?.find(
        (x) => x.key === MENU_NAME.Access_Permission_Role_Add
      ) && (
        <Button
          id="access-permission-role-add-button"
          className="add-button"
          icon="pi pi-plus"
          onClick={onAddAction}
          label={t("access_permission_role_Add_Action")}
        />
      )
    );
  };

  const configModel = getBTConfig({
    columns:
      (isMobile && configColumns.filter((x) => !x.isRowExpansion)) ||
      configColumns,
    actionsSingle: [...actionsSingle],
    hasIndex: false,
    mode: "list",
    defaultMode: "list",
    showGlobal: true,
    isExpandable: isMobile,
    expandedRows: state.expandedRows,
    customToolbar: renderRoleAdd(),
    onRowToggle: (e) => {
      let newState = {
        ...state,
        expandedRows: e.data,
      };
      setState(newState);
    },
    rowExpansionTemplate: (data) => {
      const mobileColumnsRender = [];
      configColumns
        .filter((x) => x.isRowExpansion)
        .forEach((y, index) => {
          if (data[y.key]) {
            mobileColumnsRender.push(
              <Column
                {...{
                  key: `bt-sub-column-${index}`,
                  field: y.key,
                  header: y.header,
                  style: { width: "8rem" },
                  headerStyle: { width: "8rem" },
                  body: (rowData, config) => {
                    if (!data) {
                      return <Skeleton></Skeleton>;
                    } else {
                      return (
                        <div
                          className={`bt-row bt-row-${config.rowIndex} bt-cell table-expandable-row`}
                        >
                          <span className="p-column-title">{y.header}:</span>
                          <div className="bt-cell-value">
                            {y.body && y.body(data, index)}
                            {y.dataType === BCType.date && !!rowData[y.key] && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: moment(rowData[y.key]).format(
                                    y.config?.dateFormat
                                  ),
                                }}
                              />
                            )}
                            {y.dataType === BCType.input && rowData[y.key]}
                          </div>
                        </div>
                      );
                    }
                  },
                }}
              />
            );
          }
        });

      if (mobileColumnsRender.length === 0) {
        return <></>;
      }
      return (
        <DataTable
          value={[data]}
          key={`detail-mobile-table`}
          className="p-datatable-mobiletable"
        >
          {mobileColumnsRender}
        </DataTable>
      );
    },
  });

  const tableSearchFn = async () => {
    let _datas = [];
    let _datasDataAuthorizationList = [];
    let _moduleList = [];
    let currentTable = {
      datas: [],
      total: 0,
      searchConfig: BT_SEARCH,
    };
    setRerenderLanguage(true);
    try {
      var cmd = AdminAccessPermissionService.accessGetRoleModulesAsync();
      var cmd1 = AdminAccessPermissionService.accessGetFiltersAsync();
      const moduleListApi =
        AdminAccessPermissionService.accessGetModulesAsync();

      const [res, res1, res2] = await Promise.all([cmd, cmd1, moduleListApi]);
      if (res && res?.data) {
        _datas = res.data;
      }
      if (res1 && res1.data) {
        _datasDataAuthorizationList = res1.data;
      }
      if (res2 && res2.data) {
        _moduleList = res2.data;
      }

      _datas?.map((x) => {
        if (x.dataAuthorizations) {
          x.dataAuthorizations = x.dataAuthorizations.map(
            (dataAuthorization) => {
              return {
                ...dataAuthorization,
                name: _datasDataAuthorizationList?.find(
                  (_dataAuthorizationList) =>
                    _dataAuthorizationList.id === dataAuthorization.id
                ).name,
              };
            }
          );
        }
        return x;
      });

      currentTable = {
        datas: _datas,
        total: _datas.length,
        searchConfig: BT_SEARCH,
      };
      return currentTable;
    } catch (error) {
      return currentTable;
    }
  };

  return (
    <BaseTable
      ref={dataTable}
      configModel={configModel}
      searchConfig={BT_SEARCH}
      searchFn={() => tableSearchFn()}
      isClientSize={true}
      id="access-permission-role-table"
    />
  );
};

export default AccessPermissionRole;
