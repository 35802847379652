import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  DEFAULT_DATE_FORMAT,
  FUNCTION_CODE,
  MATCH_MODE,
} from "../../../../constants";
import { resetBcDynamicPaths } from "../../../../redux/actions/breadcrumb";
import {
  resetMainForm,
  resetMainFormConfig,
  resetMainFormValue,
} from "../../../../redux/actions/form";
import BaseTable from "../../../../components/base-table/base-table-portal";
import { Animated } from "react-animated-css";
import {
  DEFAULT_BT_SEARCH,
  getBTConfig,
  getColumModel,
} from "../../../../components/base-table/base-table-model";
import { useWindowSize } from "../../utils/window-size/useWindowSize";
import { Column } from "primereact/column";
import { Skeleton } from "primereact/skeleton";
import { DataTable } from "primereact/datatable";
import { getControlModel } from "../../../../components/base-control/base-cotrol-model";
import { BCType } from "../../../../components/base-control/base-control";
import moment from "moment";
import { AdminService } from "../../../../services/hrmnet-api";

const COMPANY_DOCUMENT_CONFIGURATION_SORT_KEY = "documentType";

const CompanyDocumentConfiguration = () => {
  const { isMobile } = useWindowSize();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const dataTable = useRef();
  const [rerenderLanguage, setRerenderLanguage] = useState(false);

  /** Default search */
  const BT_SEARCH = {
    ...DEFAULT_BT_SEARCH,
    sortObj: {
      key: COMPANY_DOCUMENT_CONFIGURATION_SORT_KEY,
      direction: 1,
    },
  };

  const [state, setState] = useState({
    isLoading: true,
    formName: t("Company_Document_Configuration_Formname"),
    detailExpandedRows: null,
    tableConfig: null,
  });

  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );
  useEffect(() => {
    const loadInit = async () => {
      const _state = state;

      _state.isLoading = false;
      setState(_state);

      if (rerenderLanguage) {
        dataTable.current?.rerenderLanguage();
      }

      dispatch(resetMainFormConfig());
      dispatch(resetMainForm());
      dispatch(resetMainFormValue());
    };
    loadInit();

    return () => {
      setState({
        ...state,
        isLoading: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLangKey]);

  // Breadcrumb
  useEffect(() => {
    dispatch(resetBcDynamicPaths([{ label: state.formName || "" }]));
  }, [dispatch, t, state.formName]);

  const tableSearchFn = async () => {
    let _datas = [];
    let currentTable = {
      datas: [],
      total: 0,
      searchConfig: BT_SEARCH,
    };
    setRerenderLanguage(true);
    try {
      var cmd = await AdminService.companyDocumentGetCompanyDocumentList();
      const [res] = await Promise.all([cmd]);
      if (res && res?.data) {
        _datas = res.data;
      }

      currentTable = {
        datas: _datas,
        total: _datas.length,
        searchConfig: BT_SEARCH,
      };
      return currentTable;
    } catch (error) {
      return currentTable;
    }
  };

  // actions
  const onUpdateAction = async (item) => {
    history.push({
      pathname: `${FUNCTION_CODE.CompanyDocumentConfiguration}/${FUNCTION_CODE.CompanyDocumentConfigurationUpdate}`,
      state: {
        companyDocumentId: item.id,
      },
    });
  };

  let configColumns = [
    getColumModel({
      header: t("company_document_configuration_column_documentType"),
      key: "documentType",
      dataType: "string",
      config: {
        sortable: true,
      },
      width: 12,
      isRowExpansion: false,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t(
          "company_document_configuration_documentType_placeholder"
        ),
        type: "select",
      }),
      frozen: true,
    }),
    getColumModel({
      header: t("company_document_configuration_column_companyCode"),
      key: "company",
      dataType: "string",
      config: {
        sortable: true,
      },
      isRowExpansion: false,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t(
          "company_document_configuration_companyCode_placeholder"
        ),
      }),
      frozen: false,
    }),
    getColumModel({
      header: t("company_document_configuration_column_language"),
      key: "language",
      dataType: "string",
      config: {
        sortable: true,
      },
      isRowExpansion: false,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t("company_document_configuration_language_placeholder"),
        type: BCType.select,
      }),
      frozen: false,
    }),
    getColumModel({
      header: t("company_document_configuration_column_modifiedDate"),
      key: "lastUpdate",
      dataType: BCType.date,
      config: {
        dateFormat: DEFAULT_DATE_FORMAT,
        sortable: true,
      },
      isRowExpansion: true,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t(
          "company_document_configuration_modifiedDate_placeholder"
        ),
        type: BCType.daterange,
      }),
      filterMatchMode: MATCH_MODE.RANGE,
      body: (e) => (
        <div
          dangerouslySetInnerHTML={{
            __html: moment(e.lastUpdate).format(DEFAULT_DATE_FORMAT),
          }}
        />
      ),
      frozen: false,
    }),
    getColumModel({
      header: t("company_document_configuration_column_modifiedBy"),
      key: "updatedBy",
      dataType: "string",
      config: {
        sortable: true,
      },
      isRowExpansion: true,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t("company_document_configuration_modifiedBy_placeholder"),
      }),
    }),
  ];

  const configModel = getBTConfig({
    columns:
      (isMobile && configColumns.filter((x) => !x.isRowExpansion)) ||
      configColumns,
    actionsSingle: [
      {
        isOverflow: false,
        name: "company_document_configuration_Edit_Action",
        title: t("company_document_configuration_Edit_Action"),
        clickFn: (item) => onUpdateAction(item),
        renderIcon: "pi pi-pencil",
      },
    ],
    hasIndex: false,
    mode: "list",
    defaultMode: "list",
    showGlobal: true,
    isExpandable: isMobile,
    expandedRows: state.expandedRows,
    onRowToggle: (e) => {
      let newState = {
        ...state,
        expandedRows: e.data,
      };
      setState(newState);
    },
    rowExpansionTemplate: (data) => {
      const mobileColumnsRender = [];
      configColumns
        .filter((x) => x.isRowExpansion)
        .forEach((y, index) =>
          mobileColumnsRender.push(
            <Column
              {...{
                key: `bt-sub-column-${index}`,
                field: y.key,
                header: y.header,
                style: { width: "8rem" },
                headerStyle: { width: "8rem" },
                body: (rowData, config) => {
                  if (!data) {
                    return <Skeleton></Skeleton>;
                  } else {
                    return (
                      <div
                        className={`bt-row bt-row-${config.rowIndex} bt-cell table-expandable-row`}
                      >
                        <span className="p-column-title">{y.header}:</span>
                        <div className="bt-cell-value">
                          {y.dataType === BCType.date && !!rowData[y.key] ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: moment(rowData[y.key]).format(
                                  y.config?.dateFormat
                                ),
                              }}
                            />
                          ) : (
                            rowData[y.key]
                          )}
                        </div>
                      </div>
                    );
                  }
                },
              }}
            />
          )
        );

      return (
        <DataTable
          value={[data]}
          key="detail-mobile-table"
          className="p-datatable-mobiletable"
        >
          {mobileColumnsRender}
        </DataTable>
      );
    },
  });

  // Rendering
  const renderFormTitle = () => (
    <div className="form-title">
      <h2>{state.formName}</h2>
    </div>
  );

  return (
    <>
      <div className={`multimenu-style-tab`}>
        <div className="header header-sticky">
          {/* Title */}
          {renderFormTitle()}
        </div>
        <Animated
          animationIn="slideInRight"
          animationOut="slideOutRight"
          animationInDuration={200}
          animationOutDuration={200}
          isVisible={true}
        >
          <div className="company-document-configuration-table">
            <BaseTable
              ref={dataTable}
              configModel={configModel}
              searchConfig={BT_SEARCH}
              searchFn={() => tableSearchFn()}
              isClientSize={true}
              id="company-document-configuration-table"
            />
          </div>
        </Animated>
      </div>
    </>
  );
};

export default CompanyDocumentConfiguration;
