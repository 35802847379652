/**
 * url
 */
export enum UrlLink {
  BaseURL = "home",
  DefaultLink = "personal-info",
  PersonalInfo = "personal-info",
  ChangePassword = "change-password",
  Dependents = "dependents",
  Employments = "employments",
  Movements = "movements",
  BankAccounts = "bank-accounts",
  Contact = "contact",
}
/**
 * TabNavTitle
 */
export enum TabNavTitle {
  DefaultTitle = "employeeProfile_personalInfo",
  PersonalInfo = "employeeProfile_personalInfo",
  Dependents = "employeeProfile_dependents",
  Employments = "employeeProfile_employmentContract",
  Movements = "employeeProfile_movementInfo",
  BankAccounts = "employeeProfile_bank",
  Contact = "employeeProfile_employeeContact",
}
