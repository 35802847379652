import { Route, Switch, useRouteMatch } from "react-router-dom";
import LanguageLoader from "../../../components/language-loader/language-loader";
import {
  EEPORTAL_LABEL_ADMIN,
  EEPORTAL_LABEL_BASE_CONTROL,
  EEPORTAL_LABEL_BASE_TABLE,
  enumsKeyToArray,
  FUNCTION_CODE,
} from "../../../constants";
import PageNotFound from "../public/error/employee-error-404";
import EmployeeConfiguration from "./employee-configuration/employee-configuration";
import EmployeeDataAmendment from "./employee-data-amendment/employee-data-amendment";
import EmailConfiguration from "./email-configuration/email-configuration";
import CompanyDocumentConfiguration from "./company-document/company-document";
import CompanyDocumentConfigurationUpdate from "./company-document/update";
import AccessPermission from "./access-permission/access-permission";
import AccessPermissionEmployeeAccessForm from "./access-permission/access-permission-employee-access-form";
import AccessPermissionRoleForm from "./access-permission/access-permission-role-form";
import SettingConfiguration from "./setting-configuration/setting-configuration";
import EmailReminderForm from "./email-configuration/email-reminder-form";
import EmailTemplateForm from "./email-configuration/email-template-form";
import LeaveConfiguration from "./leave-configuration/leave-configuration";
import LeaveConfigurationPolicyForm from "./leave-configuration/leave-configuration-policy-form";
import RoleAccessForm from "./access-permission/access-permission-role-access-form";

const Admin = (props) => {
  let match = useRouteMatch();

  const routes = [
    {
      id: "employee-configuration",
      path: `${match.path}/${FUNCTION_CODE.EmployeeConfiguration}`,
      component: EmployeeConfiguration,
      exact: true,
      strict: true,
    },
    {
      id: "employee-data-amendment",
      path: `${match.path}/${FUNCTION_CODE.EmployeeDataAmendment}`,
      component: EmployeeDataAmendment,
      exact: true,
      strict: true,
    },
    {
      id: "email-configuration",
      path: `${match.path}/${FUNCTION_CODE.EmailConfiguration}`,
      component: EmailConfiguration,
      exact: true,
      strict: true,
    },
    {
      id: "email-configuration-email-template",
      path: `${match.path}/${FUNCTION_CODE.EmailConfiguration}/${FUNCTION_CODE.EmailTemplate}`,
      component: EmailConfiguration,
      exact: true,
      strict: true,
    },
    {
      id: "email-configuration-email-reminder",
      path: `${match.path}/${FUNCTION_CODE.EmailConfiguration}/${FUNCTION_CODE.EmailReminder}`,
      component: EmailConfiguration,
      exact: true,
      strict: true,
    },
    {
      id: "email-configuration-email-template-update",
      path: `${match.path}/${FUNCTION_CODE.EmailConfiguration}/${FUNCTION_CODE.EmailTemplate}/${FUNCTION_CODE.EmailTemplateUpdate}`,
      component: EmailTemplateForm,
      exact: true,
      strict: true,
    },
    {
      id: "email-configuration-email-reminder-update",
      path: `${match.path}/${FUNCTION_CODE.EmailConfiguration}/${FUNCTION_CODE.EmailReminder}/${FUNCTION_CODE.EmailReminderUpdate}`,
      component: EmailReminderForm,
      exact: true,
      strict: true,
    },
    {
      id: "email-configuration-email-reminder-create",
      path: `${match.path}/${FUNCTION_CODE.EmailConfiguration}/${FUNCTION_CODE.EmailReminder}/${FUNCTION_CODE.EmailReminderCreate}`,
      component: EmailReminderForm,
      exact: true,
      strict: true,
    },
    {
      id: "company-document",
      path: `${match.path}/${FUNCTION_CODE.CompanyDocumentConfiguration}`,
      component: CompanyDocumentConfiguration,
      exact: true,
      strict: true,
    },
    {
      id: "company-document-update",
      path: `${match.path}/${FUNCTION_CODE.CompanyDocumentConfiguration}/${FUNCTION_CODE.CompanyDocumentConfigurationUpdate}`,
      component: CompanyDocumentConfigurationUpdate,
      exact: true,
      strict: true,
    },
    {
      id: "access-permission",
      path: `${match.path}/${FUNCTION_CODE.AccessPermission}`,
      component: AccessPermission,
      exact: true,
      strict: true,
    },
    {
      id: "access-permission-role",
      path: `${match.path}/${FUNCTION_CODE.AccessPermission}/${FUNCTION_CODE.AccessPermissionRole}`,
      component: AccessPermission,
      exact: true,
      strict: true,
    },
    {
      id: "access-permission-employee-access",
      path: `${match.path}/${FUNCTION_CODE.AccessPermission}/${FUNCTION_CODE.AccessPermissionEmployeeAccess}`,
      component: AccessPermission,
      exact: true,
      strict: true,
    },
    {
      id: "access-permission-role-access",
      path: `${match.path}/${FUNCTION_CODE.AccessPermission}/${FUNCTION_CODE.AccessPermissionRoleAccess}`,
      component: AccessPermission,
      exact: true,
      strict: true,
    },
    {
      id: "access-permission-role-add",
      path: `${match.path}/${FUNCTION_CODE.AccessPermission}/${FUNCTION_CODE.AccessPermissionRole}/${FUNCTION_CODE.AccessPermissionRoleAdd}`,
      component: AccessPermissionRoleForm,
      exact: true,
      strict: true,
    },
    {
      id: "access-permission-role-update",
      path: `${match.path}/${FUNCTION_CODE.AccessPermission}/${FUNCTION_CODE.AccessPermissionRole}/${FUNCTION_CODE.AccessPermissionEmployeeAccessUpdate}`,
      component: AccessPermissionRoleForm,
      exact: true,
      strict: true,
    },
    {
      id: "access-permission-employee-access-add",
      path: `${match.path}/${FUNCTION_CODE.AccessPermission}/${FUNCTION_CODE.AccessPermissionEmployeeAccess}/${FUNCTION_CODE.AccessPermissionEmployeeAccessAdd}`,
      component: AccessPermissionEmployeeAccessForm,
      exact: true,
      strict: true,
    },
    {
      id: "access-permission-employee-access-update",
      path: `${match.path}/${FUNCTION_CODE.AccessPermission}/${FUNCTION_CODE.AccessPermissionEmployeeAccess}/${FUNCTION_CODE.AccessPermissionEmployeeAccessUpdate}`,
      component: AccessPermissionEmployeeAccessForm,
      exact: true,
      strict: true,
    },
    {
      id: "access-permission-role-access-add",
      path: `${match.path}/${FUNCTION_CODE.AccessPermission}/${FUNCTION_CODE.AccessPermissionRoleAccess}/${FUNCTION_CODE.AccessPermissionRoleAccessAdd}`,
      component: RoleAccessForm,
      exact: true,
      strict: true,
    },
    {
      id: "access-permission-role-access-update",
      path: `${match.path}/${FUNCTION_CODE.AccessPermission}/${FUNCTION_CODE.AccessPermissionRoleAccess}/${FUNCTION_CODE.AccessPermissionRoleAccessUpdate}`,
      component: RoleAccessForm,
      exact: true,
      strict: true,
    },
    {
      id: "setting-configuration",
      path: `${match.path}/${FUNCTION_CODE.SettingConfiguration}`,
      component: SettingConfiguration,
      exact: true,
      strict: true,
    },
    {
      id: "leave-configuration",
      path: `${match.path}/${FUNCTION_CODE.LeaveConfiguration}`,
      component: LeaveConfiguration,
      exact: true,
      strict: true,
    },
    {
      id: "leave-configuration-policy",
      path: `${match.path}/${FUNCTION_CODE.LeaveConfiguration}/${FUNCTION_CODE.LeaveConfigurationPolicy}`,
      component: LeaveConfiguration,
      exact: true,
      strict: true,
    },
    {
      id: "leave-configuration-policy-update",
      path: `${match.path}/${FUNCTION_CODE.LeaveConfiguration}/${FUNCTION_CODE.LeaveConfigurationPolicy}/${FUNCTION_CODE.LeaveConfigurationPolicyUpdate}`,
      component: LeaveConfigurationPolicyForm,
      exact: true,
      strict: true,
    },
    {
      id: "leave-configuration-policy-create",
      path: `${match.path}/${FUNCTION_CODE.LeaveConfiguration}/${FUNCTION_CODE.LeaveConfigurationPolicy}/${FUNCTION_CODE.LeaveConfigurationPolicyCreate}`,
      component: LeaveConfigurationPolicyForm,
      exact: true,
      strict: true,
    },
  ];

  return (
    <LanguageLoader
      contexts={enumsKeyToArray([
        EEPORTAL_LABEL_ADMIN,
        EEPORTAL_LABEL_BASE_TABLE,
        EEPORTAL_LABEL_BASE_CONTROL,
      ])}
    >
      <div id="admin">
        <Switch>
          {props.routeConfig ? props.routeConfig(routes) : null}
          <Route component={PageNotFound} />
        </Switch>
      </div>
    </LanguageLoader>
  );
};

export default Admin;
