import { AUTH_SET_INFO, AUTH_SET_TOKEN_EXPIRED } from "../reduxConstants";
import { AUTH_RESET_INFO } from "../reduxConstants";
const initialState = {
  isLoggedIn: false,
  isChangePassword: false,
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case AUTH_SET_INFO: {
      return {
        ...state,
        ...action.payload,
        isExpired: false,
      };
    }
    case AUTH_RESET_INFO: {
      return initialState;
    }
    case AUTH_SET_TOKEN_EXPIRED: {
      return {
        ...state,
        isExpired: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
