import { SET_LIST_TENANT } from "../reduxConstants";
const initialState = {
  appClientId: undefined,
};

export default function client(state = initialState, action) {
  switch (action.type) {
    case SET_LIST_TENANT: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
