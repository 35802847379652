import "./view.scss";

//components
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { resetBcDynamicPaths } from "../../../../../redux/actions/breadcrumb";
import CalendarView from "../calendar/calendar-view";
import { calendarViewSkeleton } from "./../../utils/skeleton";
import { LeaveService } from "../../../../../services/hrmnet-api";
import { isResponseOk } from "../../../../../utils/utils";

const View = (props) => {
  const dispatch = useDispatch();
  const delegateeMode = props?.location?.state?.delegateeMode;
  const employeeCode = props?.location?.state?.employeeCode;
  const employeeName = props?.location?.state?.employeeName;

  useEffect(() => {
    dispatch(resetBcDynamicPaths());
  }, [dispatch]);

  const [isLoading, setIsLoading] = useState(true);
  const applicationRef = useRef();

  useEffect(() => {
    const loadApplication = async () => {
      const applicationId = props.match.params.applicationId;
      const res = await LeaveService.leaveGetLeaveApplicationById({
        applicationId,
        isDelegated: delegateeMode,
      });

      if (isResponseOk(res)) {
        if (res.data?.leaveCode) {
          let hideBalance = false;
          const LeaveRes = await LeaveService.leaveGetLeaveTypesInfoByLeaveCode(
            {
              leavecode: res.data.leaveCode,
              employeeCode: employeeCode,
            }
          );
          if (isResponseOk(LeaveRes)) {
            hideBalance = LeaveRes.data[0]?.showBalanceOnApplication
              ? !LeaveRes.data[0].showBalanceOnApplication
              : true;
          }
          res.data.hideBalance = hideBalance;
        }
        applicationRef.current = res.data;
        setIsLoading(false);
      }
    };
    loadApplication();
  }, [props.match.params.applicationId]);

  if (isLoading) {
    return calendarViewSkeleton;
  }

  return (
    <div className="leave-view-application">
      <CalendarView
        application={applicationRef.current}
        employeeName={employeeName}
        delegateeMode={delegateeMode}
        employeeCode={employeeCode}
      />
    </div>
  );
};

export default View;
