import "./main-language.scss";

import { Button } from "primereact/button";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OverlayPanel } from "primereact/overlaypanel";
import { useTranslation } from "react-i18next";
import { Tree } from "primereact/tree";

//redux
import { setSideBar } from "./../../../../../redux/actions/sidebar";
import { openModal } from "./../../../../../redux/actions/modal";
import {
  broadCastUserSelectedLanguage,
  setUserSelectedLanguage,
} from "./../../../../../redux/actions/language";

function MainLanguage({ ...props }) {
  const { t, i18n } = useTranslation();
  let op = useRef();
  const language = useSelector((state) => state.language) || null;
  const isLoggedIn = useSelector((state) => state.auth?.isLoggedIn) || null;
  const isOpenSidebarMobile = useSelector(
    (state) => state.sidebar?.isOpenSidebarMobile
  );
  const dispatch = useDispatch();
  const setSideBarInfo = (x) => dispatch(setSideBar(x));
  const updateLanguageByKey = (x) => {
    if (isLoggedIn) {
      return dispatch(broadCastUserSelectedLanguage(x));
    }
    return dispatch(setUserSelectedLanguage(x));
  };
  const setModel = (x) => dispatch(openModal(x));

  const updateLanguage = async (e) => {
    // await loadCustomTranslation(state.selectedLang?.value, i18n).finally(
    await i18n.changeLanguage(e?.key).finally(() => {
      updateLanguageByKey(e?.key);
    });
    // );
    // reload saved bundle
    if (language?.loadedContext?.data[language]) {
      i18n.addResourceBundle(
        language,
        "translation",
        language?.loadedContext?.data[language],
        true,
        true
      );
    }
  };

  const onSelectionChange = async (e) => {
    setModel({
      title: t("dialogue_language_title"),
      content: t("dialogue_language_content"),
      primaryButtonText: t("dialogue_language_confirm"),

      primaryButtonClickFn: async ({ closeFn }) => {
        if (op) {
          op.hide();
        }
        updateLanguage(e);
        closeFn();
      },
      secondButtonClickFn: ({ closeFn }) => {
        closeFn();
      },
    });
  };
  const toggleNotification = async (e) => {
    op.toggle(e);
  };

  return (
    <>
      <div className="main-language p-grid p-nogutter p-align-center">
        {language?.languageList?.length > 1 && (
          <>
            <Button
              type="button"
              className={`p-button-secondary p-button-text ${
                props.isAuthenticated
                  ? "main-language-button"
                  : "main-language-login-button"
              }`}
              icon={`pi pi-globe ${
                props.isAuthenticated
                  ? "language-icon-size"
                  : "language-icon-size-login"
              }`}
              label={props.isAuthenticated ? "" : t("login_language")}
              iconPos="right"
              onClick={(e) => {
                // Close mobile sidebar if open while switching between different languages
                if (isOpenSidebarMobile) {
                  let newSideBarInfo = {};
                  newSideBarInfo = {
                    isOpenSidebarMobile: false,
                  };
                  setSideBarInfo(newSideBarInfo);
                }
                toggleNotification(e);
              }}
            ></Button>
            <OverlayPanel
              ref={(el) => (op = el)}
              id="overlay_panel"
              className={`main-header-panel`}
              appendTo={
                props.isAuthenticated
                  ? document.getElementById("main-header")
                  : document.getElementById("ep-login-main-top-toolbar")
              }
              // Disable scrolling in mobile when overlay panel is open
              // onShow={() =>
              //   document.body.classList.add("block-scroll-in-mobile")
              // }
              // onHide={() =>
              //   document.body.classList.remove("block-scroll-in-mobile")
              // }
            >
              <Tree
                className="c-setting-tree"
                selectionMode="single"
                selectionKeys={i18n.language}
                onSelectionChange={async (_) => {
                  let selectedLanguage = language?.languageList?.find(
                    (x) => x.key === _.value
                  );

                  if (_.value !== i18n.language) {
                    await onSelectionChange(selectedLanguage);
                  }
                }}
                value={language?.languageList || []}
              />
            </OverlayPanel>
          </>
        )}
      </div>
    </>
  );
}
export default MainLanguage;
