import "./view.scss";

//components
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { resetBcDynamicPaths } from "../../../../../redux/actions/breadcrumb";
import CalendarView from "../calendar/calendar-view";
import { calendarViewSkeleton } from "./../../utils/skeleton";
import { NwowService } from "../../../../../services/hrmnet-api";
import { isResponseOk } from "../../../../../utils/utils";

const View = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetBcDynamicPaths());
  }, [dispatch]);

  const [isLoading, setIsLoading] = useState(true);
  const applicationRef = useRef();

  useEffect(() => {
    const loadApplication = async () => {
      const applicationId = props.match.params.applicationId;
      const res = await NwowService.nwowGetNwowApplicationById({
        applicationId,
      });

      if (isResponseOk(res)) {
        if (res.data?.nwowCode) {
          let hideBalance = false;
          const NwowRes = await NwowService.nwowGetNwowTypesInfoByNwowCode({
            nwowcode: res.data.nwowCode,
          });
          if (isResponseOk(NwowRes)) {
            hideBalance = NwowRes.data[0]?.showBalanceOnApplication
              ? !NwowRes.data[0].showBalanceOnApplication
              : true;
          }
          res.data.hideBalance = hideBalance;
        }
        applicationRef.current = res.data;
        setIsLoading(false);
      }
    };
    loadApplication();
  }, [props.match.params.applicationId]);

  if (isLoading) {
    return calendarViewSkeleton;
  }

  return (
    <div className="nwow-view-application">
      <CalendarView application={applicationRef.current} />
    </div>
  );
};

export default View;
