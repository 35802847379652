import "./base-multi-step-form.scss";
import { ScrollTop } from "primereact/scrolltop";
import { Skeleton } from "primereact/skeleton";

export const BaseMultiStepFormSkeleton = (
  <div style={{ background: "#fff", width: "100%", padding: "1rem" }}>
    <div className="p-grid p-my-3">
      <div className="p-col-4">
        <Skeleton height="40px"></Skeleton>
      </div>
      <div className="p-col-4">
        <Skeleton height="40px"></Skeleton>
      </div>
      <div className="p-col-4">
        <Skeleton height="40px"></Skeleton>
      </div>
    </div>
    <div className="p-grid p-my-3">
      <div className="p-col-4">
        <Skeleton height="40px"></Skeleton>
      </div>
      <div className="p-col-4">
        <Skeleton height="40px"></Skeleton>
      </div>
    </div>
    <div className="p-grid p-my-3">
      <div className="p-col-4">
        <Skeleton height="40px"></Skeleton>
      </div>
      <div className="p-col-4">
        <Skeleton height="40px"></Skeleton>
      </div>
    </div>
  </div>
);
