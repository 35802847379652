import "./base-table.scss";

import { makeId, sortArray } from "../../utils/utils";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";

import { Animated } from "react-animated-css";
import BaseControl, { BCType } from "../base-control/base-control";
import BaseForm from "./../base-form/base-form";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DEFAULT_BT_SEARCH } from "./base-table-model";
import { DataTable } from "primereact/datatable";
import Highlighter from "react-highlight-words";
import { InputText } from "primereact/inputtext";
import { Menu } from "primereact/menu";
import { MultiSelect } from "primereact/multiselect";
import { sleep } from "./../../utils/utils";
import { ToggleButton } from "primereact/togglebutton";
import { TreeTable } from "primereact/treetable";
import { getControlModelByType } from "../base-control/base-cotrol-model";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Skeleton } from "primereact/skeleton";
import { Card } from "primereact/card";
import { MATCH_MODE } from "../../constants";
/**
 * Reset state of table
 *
 * @param {number} totalRecord
 * @param {BTConfigModel} searchConfig
 */
const resetTable = async (newState) => {
  let searchConfig = { ...newState.table.searchConfig };
  searchConfig.pi = 1;
  searchConfig.searchFormData = [];
  newState.table.searchConfig = searchConfig;
  newState.showFilter = false;
  newState.displayedRecord = newState.totalRecord;
  newState.displayedTotal = newState.totalRecord.length;
  newState.selectedRecord = [];
  newState.table.searchConfig.sortObj = {
    key: undefined,
    direction: undefined,
  };
  return newState;
};

/**
 * Get state after using searchFn
 */
const getData = async (searchFn, searchData = null) => {
  if (!searchFn) {
    return;
  }
  var res = searchData ? await searchFn(searchData) : await searchFn();
  let totalRecord = [];
  let total = 0;
  if (res.datas) {
    totalRecord = res.datas;
    total = res.total;
  }
  return {
    table: {
      ...res,
    },
    displayedRecord: totalRecord,
    displayedTotal: total,
    totalRecord,
    loading: false,
  };
};

/**
 * Update state after updating data
 */
const updateData = async (updateData) => {
  if (!updateData) {
    return;
  }
  var res = await updateData();
  let totalRecord = [];
  let total = 0;
  if (res.datas) {
    totalRecord = res.datas;
  }
  return {
    table: {
      ...res,
    },
    displayedRecord: totalRecord,
    displayedTotal: total,
    totalRecord,
    loading: false,
  };
};
/**
 * Update table state after changing language
 */
const rerenderLanguage = async () => {
  return {
    loading: false,
    rerenderLanguage: true,
  };
};
/**
 * Check current state is mobile or not
 * @return {boolean} True/ False
 */
const checkMobile = () => {
  // 1 rem = 16 px
  var viewportWidth = window.innerWidth;
  return viewportWidth <= 768;
};

/**
 * Recaculate table
 *
 */
const onResizeFn = async () => {
  try {
    // // caculate height
    const doc1 = document.querySelectorAll(`.p-datatable-frozen-view tr`);
    const doc2 = document.querySelectorAll(`.p-datatable-unfrozen-view tr`);
    if (doc1 && doc2 && doc1.length > 0 && doc2.length > 0) {
      for (let index = 0; index < doc1.length; index++) {
        const tr_frozen = doc1[index];
        const tr_unfrozen = doc2[index];
        let maxHeight = tr_frozen.clientHeight;
        if (tr_unfrozen.clientHeight > maxHeight) {
          maxHeight = tr_unfrozen.clientHeight;
        }
        tr_frozen.style.height = maxHeight + "px";
        tr_unfrozen.style.height = maxHeight + "px";
      }
    }
  } catch (error) {}
};
/**
 * Base table provice common render for an table
 * @author: Loki
 */
const BaseTable = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const id = props.id || "BaseTable_default_id";
  const [state, setState] = useState({
    loading: true,
    loadingMobile: false,
    table: {
      searchConfig: DEFAULT_BT_SEARCH,
      datas: [],
      total: 0,
    },
    showFilter: false,
    isGrid: props.configModel?.defaultMode === "grid",
    isMobile: false,
    totalRecord: [],
    displayedRecord: [],
    rerenderLanguage: false,
  });
  /** Expose function  */
  useImperativeHandle(ref, () => ({
    async add(data) {
      if (props.isClientSize) {
        let newState = { ...state };
        newState.totalRecord.unshift(data);
        newState = await resetTable(newState);
        newState.selectedRecord = null;
        setState(newState);
      } else {
        await onReset();
      }
    },
    async update(id, data) {
      if (props.isClientSize) {
        let newState = { ...state };
        let index = newState.totalRecord.findIndex((x) => x.id === id);
        if (index >= 0) {
          let newModel = { ...newState.totalRecord[index] };
          newModel = {
            ...newModel,
            ...data,
          };
          newState.totalRecord.splice(index, 1);
          newState.totalRecord.unshift(newModel);
        }
        newState = await resetTable(newState);
        newState.selectedRecord = null;
        setState(newState);
      } else {
        await onReset();
      }
    },
    async updateRow(id, data) {
      if (props.isClientSize) {
        let newState = { ...state };
        let index = newState.totalRecord.findIndex((x) => x.id === id);
        if (index >= 0) {
          newState.totalRecord[index] = data;
        }
        setState(newState);
      } else {
        await onReset();
      }
    },
    async delete(id) {
      if (props.isClientSize) {
        let newState = { ...state };
        let index = newState.totalRecord.findIndex((x) => x.id === id);
        newState.totalRecord.splice(index, 1);
        newState = await resetTable(newState);
        newState.selectedRecord = null;
        setState(newState);
      } else {
        await onReset();
      }
    },
    async deleteMany(listId) {
      if (props.isClientSize) {
        let newState = { ...state };
        listId.forEach((id) => {
          let index = newState.totalRecord.findIndex((x) => x.id === id);
          newState.totalRecord.splice(index, 1);
        });
        newState = await resetTable(newState);
        newState.selectedRecord = null;
        setState(newState);
      } else {
        await onReset();
      }
    },
    async reload() {
      if (props.isClientSize) {
        setState({
          ...state,
          loading: true,
        });
        let _state = await getData(props.searchFn);
        if (!mountedRef.current) return null;
        _state.selectedRecord = null;
        setState({
          ...state,
          ..._state,
        });
      } else {
        await onReset();
      }
    },
    async reload(searchData) {
      if (props.isClientSize) {
        setState({
          ...state,
          loading: true,
        });
        let _state = await getData(props.searchFn, searchData);
        if (!mountedRef.current) return null;
        _state.selectedRecord = null;
        setState({
          ...state,
          ..._state,
        });
      } else {
        await onReset();
      }
    },
    async getData() {
      return state.displayedRecord;
    },
    getDataSync() {
      return state.displayedRecord;
    },
    async updateData() {
      if (props.isClientSize) {
        setState({
          ...state,
          loading: true,
        });
        let _state = await updateData(props.updateData);
        if (!mountedRef.current) return null;
        _state.selectedFullRecord = null;
        _state.selectedRecord = null;
        setState({
          ...state,
          ..._state,
        });
      }
      return;
    },
    async rerenderLanguage() {
      if (props.isClientSize) {
        setState({
          ...state,
          loading: true,
          rerenderLanguage: false,
        });
        let _state = await rerenderLanguage();
        if (!mountedRef.current) return null;
        setState({
          ...state,
          ..._state,
        });
      }
      return;
    },
    updateSelectedRecords(data) {
      setState({
        ...state,
        selectedRecord: data,
      });
    },
    clearSelectedRecords() {
      setState({
        ...state,
        selectedRecord: [],
        selectedFullRecord: [],
      });
    },
    async onFilter(key, value, filterMatchMode) {
      if (tableRef && tableRef.filter) {
        tableRef.filter(value, key, filterMatchMode || MATCH_MODE.CONTAINS);
      }
    },
  }));

  const mountedRef = useRef(true);
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);
  let refGr = {};
  let tableRef = useRef();
  /** Load config */
  useEffect(() => {
    if (!mountedRef.current) return null;
    let tableColumnsOption = props.configModel?.columns?.map((x, index) => {
      return {
        ...x,
        className: x?.frozen || x?.onTop ? "bt-frozen" : "",
        disabled: x?.frozen,
        isDefaultHide: x?.frozen,
        index,
      };
    });
    let tableColumnsOptionSelect = tableColumnsOption.filter(
      (x) => !x?.frozen && !x.onTop
    );
    let tableColumnsSelect = [...tableColumnsOptionSelect];
    let tableColumnsFixOption = tableColumnsOption.filter(
      (x) => x.frozen || x.onTop
    );
    let tableColumns = tableColumnsOption.filter(
      (x) => !x.isDefaultHide || x?.frozen || x.onTop
    );
    setState({
      ...state,
      tableColumnsFixOption,
      tableColumns,
      tableColumnsSelect,
      tableColumnsOptionSelect,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.configModel, state.rerenderLanguage]);
  /** Load data */
  useEffect(() => {
    const initTable = async () => {
      if (!props.searchFn) {
        return;
      }
      let _state = await getData(props.searchFn);
      if (!mountedRef.current) return null;
      let tableColumnsOption = props.configModel?.columns?.map((x, index) => {
        return {
          ...x,
          className: x?.frozen || x?.onTop ? "bt-frozen" : "",
          disabled: x?.frozen,
          isDefaultHide: x?.frozen,
          index,
        };
      });
      let tableColumnsOptionSelect = tableColumnsOption.filter(
        (x) => !x?.frozen && !x.onTop
      );
      let tableColumnsSelect = [...tableColumnsOptionSelect];
      let tableColumnsFixOption = tableColumnsOption.filter(
        (x) => x.frozen || x.onTop
      );
      let tableColumns = tableColumnsOption.filter(
        (x) => !x.isDefaultHide || x?.frozen || x.onTop
      );
      setState({
        showFilter: false,
        isGrid: props.configModel.defaultMode === "grid",
        isMobile: checkMobile(),
        tableColumnsFixOption,
        tableColumns,
        tableColumnsSelect,
        tableColumnsOptionSelect,
        ..._state,
      });
    };
    initTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /** After render */
  useEffect(() => {
    var scrollable = document.querySelector(
      `#bt-table-${id} .p-datatable-unfrozen-view .p-datatable-scrollable-body`
    );
    if (scrollable) {
      var checkScroll = () => {
        try {
          var scrollLeft = scrollable.scrollLeft;
          var scrollRight =
            scrollable.scrollWidth -
            scrollable.clientWidth -
            scrollable.scrollLeft;
          var forzen = document.querySelector(".p-datatable-unfrozen-view");
          if (scrollLeft === 0) {
            forzen.classList.remove("have-scroll-left");
          } else {
            forzen.classList.add("have-scroll-left");
          }
          if (scrollRight === 0) {
            forzen.classList.remove("have-scroll-right");
          } else {
            forzen.classList.add("have-scroll-right");
          }
        } catch (error) {}
      };
      scrollable.addEventListener("scroll", checkScroll, false);
      checkScroll();
    }
    if (!state.isMobile && !state.isGrid) {
      if (props.configModel.actionsSingle.length > 0) {
        let node = document.querySelector(
          `#bt-table-${id} .p-datatable-unfrozen-view`
        );
        if (node) {
          node.style.marginLeft = `-${props.configModel.actionWidth}rem`;
        }
      }
    }
    if (!state.isGrid) {
      let paginator = document.querySelector(
        // eslint-disable-next-line no-useless-concat
        `#bt-table-${id} ` + ".p-paginator-bottom"
      );
      let checkPagnator = document.querySelector(
        // eslint-disable-next-line no-useless-concat
        `#bt-table-${id} ` + ".p-paginator-title"
      );
      if (paginator && !checkPagnator) {
        let insertNode = document.createElement("div");
        insertNode.innerText = t("base_table_items_per_page") + ": ";
        insertNode.className = "p-paginator-title";
        paginator.insertBefore(insertNode, paginator.firstChild);
      }
      if (paginator && checkPagnator && checkPagnator.innerHTML) {
        checkPagnator.innerHTML = t("base_table_items_per_page") + ": ";
      }
    }
    window.onresize = async () => {
      await onResizeFn();
      // 1 rem = 16 px
      if (!checkMobile()) {
        // desktop
        if (state.isMobile) {
          await onLoadData({
            ...state.table?.searchConfig,
            pi: 1,
          });
        }
      } else {
        // sm = mobile
        if (!state.isMobile) {
          await onLoadData({
            ...state.table?.searchConfig,
            pi: 1,
          });
        } else {
          //aw - reset search
          onChangeFilterControl(
            {
              key: "filterText",
            },
            {
              value: "",
              valueStr: "",
            }
          );
        }
      }
    };
    onResizeFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });
  /** Unmount */
  useEffect(
    () => () => {
      // Remove event
      window.onresize = function () {};
    },
    []
  );

  /**
   * Render for actionsMulti
   */
  const renderActionMulti = () => {
    if (!state.selectedRecord || state.selectedRecord.length === 0) {
      return null;
    }
    const batchAction = [];
    if (props.configModel.childActionMulti) {
      props.configModel.childActionMulti.forEach((action, index) => {
        batchAction.push(
          <Button
            disabled={state.processing || state.loading || state.loadingMobile}
            type="button"
            key={`action-multil-${index}`}
            onClick={() => action.clickFn(state.selectedRecord)}
            label={action.title}
            icon={action.renderIcon}
          />
        );
      });
    } else {
      props.configModel.actionsMulti.forEach((action, index) => {
        batchAction.push(
          <Button
            disabled={state.processing || state.loading || state.loadingMobile}
            type="button"
            key={`action-multil-${index}`}
            onClick={() => action.clickFn(state.selectedRecord)}
            label={action.title}
            icon={action.renderIcon}
          />
        );
      });
    }
    batchAction.push(
      <Button
        disabled={state.processing || state.loading || state.loadingMobile}
        type="button"
        key={`action-multil-1994`}
        onClick={() => {
          setState({
            ...state,
            selectedRecord: [],
            selectedFullRecord: [],
          });
          if (props.configModel.childActionCancel) {
            props.configModel.childActionCancel();
          }
        }}
        label={t("base_table_cancel")}
        icon="pi pi-times"
      />
    );
    return batchAction;
  };
  /**
   * Render for actionsSingle
   */
  const renderActionsSingle = (row, actionIndex) => {
    const standAction = [];
    const overflowAction = [];
    props.configModel.actionsSingle.forEach((action, index) => {
      if (state.isGrid) {
        standAction.push(
          <Button
            disabled={state.processing || state.loading || state.loadingMobile}
            type="button"
            key={`action-singler-${index}`}
            className={`${action.className} p-button-lg`}
            icon={action.renderIcon}
            label={action.title}
            onClick={() => action.clickFn(row)}
          />
        );
      } else {
        if (!action.isOverflow) {
          let currClassName = "";
          if (action?.conditionalClassName) {
            currClassName = action?.conditionalClassName(row);
          } else if (action?.className) {
            currClassName = action?.className;
          }
          standAction.push(
            <Button
              disabled={
                state.processing || state.loading || state.loadingMobile
              }
              type="button"
              key={`action-singler-${index}`}
              className={`${currClassName} p-button-text p-button-sm`}
              icon={action.renderIcon}
              tooltip={action.title}
              tooltipOptions={{ position: "top" }}
              onClick={() => action.clickFn(row)}
            />
          );
        } else {
          overflowAction.push({
            label: action.title,
            icon: action.renderIcon,

            command: () => action.clickFn(row),
          });
        }
      }
    });
    return (
      <>
        {state.isGrid ? (
          <>{standAction}</>
        ) : (
          <>
            {standAction}
            {overflowAction.length > 0 ? (
              <>
                <Menu
                  ref={(el) => (refGr[`action-single-${actionIndex}`] = el)}
                  model={overflowAction}
                  popup
                  className="p-menu-custom-overlay"
                  appendTo={document.body}
                  easing="ease-in"
                ></Menu>
                <Button
                  disabled={
                    state.processing || state.loading || state.loadingMobile
                  }
                  type="button"
                  className="p-button-secondary p-button-text p-button-sm"
                  icon={`pi pi-ellipsis-v`}
                  onClick={(event) => {
                    refGr[`action-single-${actionIndex}`].toggle(event);
                  }}
                />
              </>
            ) : null}
          </>
        )}
      </>
    );
  };
  /**
   * Render for actionHead
   */
  const renderActionsHead = (row) => {
    if (props.configModel.mode !== "both") {
      return null;
    }
    const standAction = [];
    const overflowAction = [];
    props.configModel.actionsHead.forEach((action, index) => {
      if (!action.isOverflow) {
        standAction.push(
          <Button
            disabled={state.processing || state.loading || state.loadingMobile}
            type="button"
            key={`action-self-${index}`}
            icon={action.renderIcon}
            className={`${action.className} `}
            onClick={() => action.clickFn(row)}
          >
            {state.isMobile ? action.shortTitle : action.title}
          </Button>
        );
      } else {
        overflowAction.push({
          label: action.title,
          icon: action.renderIcon,
          command: () => action.clickFn(row),
        });
      }
    });
    let overflowSort = [
      {
        label: "Latest",
        icon: "pi pi-sort-numeric-down",
        command: () => {
          onSort({
            sortField: props.configModel?.dateColumn,
            sortOrder: -1,
          });
        },
      },
      {
        label: "Earliest",
        icon: "pi pi-sort-numeric-up",
        command: () => {
          onSort({
            sortField: props.configModel?.dateColumn,
            sortOrder: 1,
          });
        },
      },
      {
        label: "A - Z",
        icon: "pi pi-sort-alpha-down",
        command: () => {
          onSort({
            sortField: props.configModel?.azColumn,
            sortOrder: 1,
          });
        },
      },
      {
        label: "Z - A",
        icon: "pi pi-sort-alpha-up",
        command: () => {
          onSort({
            sortField: props.configModel?.azColumn,
            sortOrder: -1,
          });
        },
      },
    ];

    let sortIcon = "pi pi-sort-alt";
    if (
      state.table?.searchConfig?.sortObj?.key === props.configModel?.azColumn
    ) {
      if (state.table?.searchConfig?.sortObj?.direction === 1) {
        sortIcon = "pi pi-sort-alpha-down";
      } else {
        sortIcon = "pi pi-sort-alpha-up";
      }
    }
    if (
      state.table?.searchConfig?.sortObj?.key === props.configModel?.dateColumn
    ) {
      if (state.table?.searchConfig?.sortObj?.direction === 1) {
        sortIcon = "pi pi-sort-numeric-down";
      } else {
        sortIcon = "pi pi-sort-numeric-up";
      }
    }

    return (
      <>
        {state.isGrid ? (
          <>
            <Button
              disabled={
                state.processing || state.loading || state.loadingMobile
              }
              className="bt-filterbtn p-button-secondary"
              type="button"
              tooltip={t("base_table_sort")}
              tooltipOptions={{ position: "top" }}
              icon={sortIcon}
              onClick={(event) => refGr[`action-sort`].toggle(event)}
            />
            <Menu
              ref={(el) => (refGr[`action-sort`] = el)}
              model={overflowSort}
              popup
              easing="ease-in"
              appendTo={document.body}
            ></Menu>
            <ToggleButton
              disabled={
                state.processing || state.loading || state.loadingMobile
              }
              className="bt-filterbtn p-button-secondary"
              checked={state.showFilter}
              onChange={toggleFilter}
              tooltip={t("base_table_toggle_filter")}
              tooltipOptions={{ position: "top" }}
              onIcon="pi pi-filter"
              offIcon="pi pi-filter"
              onLabel=""
              offLabel=""
            />

            <Button
              disabled={
                state.processing || state.loading || state.loadingMobile
              }
              type="button"
              className="p-button-secondary"
              tooltip={t("base_table_view_list")}
              tooltipOptions={{ position: "top" }}
              icon={`pi pi-bars`}
              onClick={() => {
                setState({ ...state, isGrid: false });
              }}
            />
            {overflowAction.length > 0 ? (
              <>
                <Button
                  disabled={
                    state.processing || state.loading || state.loadingMobile
                  }
                  type="button"
                  className="p-button-secondary"
                  icon={`pi pi-angle-down`}
                  label={t("base_table_actions")}
                  onClick={(event) => refGr[`action-self`].toggle(event)}
                />
                <Menu
                  ref={(el) => (refGr[`action-self`] = el)}
                  model={overflowAction}
                  popup
                  easing="ease-in"
                  appendTo={document.body}
                ></Menu>
              </>
            ) : null}
            {standAction}
          </>
        ) : (
          <>
            {overflowAction.length > 0 ? (
              <>
                <Button
                  disabled={
                    state.processing || state.loading || state.loadingMobile
                  }
                  type="button"
                  className="p-button-secondary"
                  icon={`pi pi-angle-down`}
                  label={t("base_table_actions")}
                  onClick={(event) => refGr[`action-self`].toggle(event)}
                />
                <Menu
                  ref={(el) => (refGr[`action-self`] = el)}
                  model={overflowAction}
                  popup
                  easing="ease-in"
                  appendTo={document.body}
                ></Menu>
              </>
            ) : null}
            {standAction}
            <Button
              disabled={
                state.processing || state.loading || state.loadingMobile
              }
              type="button"
              className="p-button-primary"
              tooltip={t("base_table_view_grid")}
              tooltipOptions={{ position: "top" }}
              icon={`pi pi-th-large`}
              onClick={() => {
                let tableColumns = sortArray(
                  state.tableColumnsOptionSelect.concat(
                    state.tableColumnsFixOption
                  ),
                  "index"
                );
                setState({
                  ...state,
                  tableColumns,
                  tableColumnsSelect: state.tableColumnsOptionSelect,
                  isGrid: true,
                });
              }}
            />
          </>
        )}
      </>
    );
  };
  /**
   * Render for actionsSelf
   */
  const renderActionsSelf = (row) => {
    const standAction = [];
    const overflowAction = [];
    props.configModel.actionsSelf.forEach((action, index) => {
      if (!action.isOverflow) {
        standAction.push(
          <Button
            disabled={state.processing || state.loading || state.loadingMobile}
            type="button"
            key={`action-self-${index}`}
            icon={action.renderIcon}
            className={`${action.className}`}
            onClick={() => action.clickFn(row)}
          >
            {state.isMobile ? action.shortTitle : action.title}
          </Button>
        );
      } else {
        overflowAction.push({
          label: action.title,
          icon: action.renderIcon,
          command: () => action.clickFn(row),
        });
      }
    });
    return (
      <>
        {state.isGrid ? (
          <>{standAction}</>
        ) : (
          <>
            {overflowAction.length > 0 ? (
              <>
                <Button
                  disabled={
                    state.processing || state.loading || state.loadingMobile
                  }
                  type="button"
                  className="p-button-secondary p-button-text"
                  icon={`pi pi-cog`}
                  onClick={(event) => refGr[`action-self`].toggle(event)}
                />
                <Menu
                  ref={(el) => (refGr[`action-self`] = el)}
                  model={overflowAction}
                  popup
                  easing="ease-in"
                  appendTo={document.body}
                ></Menu>
              </>
            ) : null}
            {standAction}
          </>
        )}
      </>
    );
  };

  /**
   * Render expander column
   */
  const renderExpanderColumn = () => {
    if (
      props.configModel.isExpandable &&
      props.configModel.rowExpansionTemplate !== undefined &&
      props.configModel.rowExpansionTemplate !== null
    ) {
      if (props.configModel.isExpandInMobileOnly) {
        if (state.isMobile) {
          if (props.configModel.expander) {
            return props.configModel.expander();
          }
          return (
            <Column
              key="expander-0"
              expander
              className="hris-bt-expander-column"
            />
          );
        }
      } else {
        if (props.configModel.expander) {
          return props.configModel.expander();
        }
        return (
          <Column
            key="expander-0"
            expander
            className="hris-bt-expander-column"
          />
        );
      }
    }
  };

  /**
   * Render row table
   */
  const renderTableRow = (hasSelection) => {
    let hasFrozen = false;
    let columns = state.tableColumns
      ? state.tableColumns.filter((x) => !(state.isMobile && x.hideMobile))
      : [];
    const columnsRender = [];
    let noContentColumn = true;

    columnsRender.push(renderExpanderColumn());

    columns.forEach((column, index) => {
      if (column.hideInMobile) {
        if (state.isMobile) {
          return;
        }
      }

      if (column.frozen) {
        hasFrozen = true;
      } else {
        noContentColumn = false;
      }
      let filterElement;
      let filterFunction = column.filterFunction;
      let filterMatchMode = column.filterMatchMode;

      if (!filterFunction && filterMatchMode === "range") {
        filterMatchMode = "custom";
        filterFunction = (value, filter) => {
          if (!filter || !Array.isArray(filter) || filter.length !== 2) {
            return true;
          }
          return (
            moment(filter[0]) <= moment(value) &&
            moment(value) <
              moment(moment(filter[1]).add(1, "day").toDate())
                .hours(0)
                .minutes(0)
                .seconds(0)
                .milliseconds(0)
                .toDate()
          );
        };
      }
      if (column && column.filter && !column.filterElement) {
        if (!column.control) {
          column.control = getControlModelByType(column.dataType);
        }
        let controlConfig = {
          ...column.control,
          noLabel: true,
          columnId: column.key,
        };

        controlConfig.type = controlConfig.type || "input";
        let enumArray = [];

        if ([BCType.select, BCType.multiselect].includes(controlConfig.type)) {
          let _enumArray = state.table.datas.filter(
            (data, i, ar) => ar.indexOf(data) === i
          );
          if (
            state.table.datas.filter((data, i, ar) =>
              Array.isArray(data[column.key])
            ).length > 0 &&
            !filterMatchMode
          ) {
            filterMatchMode = "contains";
          }

          _enumArray.forEach((data) => {
            if (Array.isArray(data[controlConfig.columnId])) {
              data[controlConfig.columnId].forEach((d) => {
                if (enumArray.findIndex((x) => x.value === d) < 0) {
                  enumArray.push({
                    label: d,
                    value: d,
                  });
                }
              });
            } else if (
              data[controlConfig.columnId] &&
              enumArray.findIndex(
                (x) => x.value === data[controlConfig.columnId]
              ) < 0
            ) {
              enumArray.push({
                label: data[controlConfig.columnId].toString(),
                value: data[controlConfig.columnId].toString(),
              });
            }
          });
        }

        enumArray.sort(function (a, b) {
          return b.label.toLowerCase().localeCompare(a.label.toLowerCase());
        });

        if (props.isClientSize) {
          let onTrueUpdateValueFn = (e) => {
            if (props.isClientSize) {
              if (!filterMatchMode) {
                switch (controlConfig.type) {
                  case "input":
                    filterMatchMode = "contains";
                    break;
                  case BCType.multiselect:
                    filterMatchMode = "in";
                    break;
                  case BCType.select:
                    filterMatchMode = "equals";
                    break;
                  default:
                    filterMatchMode = "equals";
                    break;
                }
              }
              if (tableRef && tableRef.filter) {
                // console.log("---filter---e.value---", e.value);
                // console.log("---filter---column.key---", column.key);
                // console.log("---filter---filterMatchMode---", filterMatchMode);
                tableRef.filter(
                  e.value,
                  column.key,
                  filterMatchMode || "contains"
                );
              }

              setTimeout(() => {
                onResizeFn();
              });
            }
          };
          filterElement = (
            <BaseControl
              {...controlConfig}
              fromFilter
              dataFilter={state.displayedRecord}
              key={`bt-filtercolumn-${index}`}
              id={`bt-filtercolumn-${index}`}
              onTrueUpdateValue={onTrueUpdateValueFn}
              enum={
                enumArray
                  ? enumArray.sort((a, b) =>
                      a.label.toUpper > b.label.toUpper ? 1 : -1
                    )
                  : []
              }
            />
          );
        } else {
          let onTrueUpdateValueFn = (e) => {
            if (!props.isClientSize) {
              if (!filterMatchMode) {
                switch (controlConfig.type) {
                  case "input":
                    filterMatchMode = "contains";
                    break;
                  case "select":
                    filterMatchMode = "equals";
                    break;
                  default:
                    filterMatchMode = "equals";
                    break;
                }
              }
              onChangeFilterControl(
                {
                  key: column.key,
                  filterMatchMode,
                },
                {
                  value: e.value,
                  valueStr: e.value,
                }
              );
            }
          };
          filterElement = (
            <BaseControl
              {...controlConfig}
              fromFilter
              value={
                state.table?.searchConfig?.filter
                  ? state.table?.searchConfig?.filter[column.key]
                  : undefined
              }
              key={`bt-filtercolumn-${index}`}
              id={`bt-filtercolumn-${index}`}
              onTrueUpdateValue={onTrueUpdateValueFn}
              enum={enumArray.sort((a, b) =>
                a.label.toUpper > b.label.toUpper ? 1 : -1
              )}
            />
          );
        }
      }
      if (!column.width && column.frozen) {
        column.width = 10;
      }
      const columnConfig = {
        filterElement,
        filterFunction,
        ...column.columnConfig,
        key: `bt-column-${index}`,
        field: column.key,
        header: column.header,
        frozen: !state.isMobile && !state.isGrid ? column.frozen : false,
        filter: state.showFilter ? column.filter : null,
        showFilter: true,
        sortable: column.sortable,
        style: {
          width:
            column.width && !state.isGrid && !column.isAuto
              ? `${column.width}rem`
              : column.isAuto
              ? column.isAuto
              : "auto",
        },
        headerStyle: {
          width:
            column.width &&
            !state.isGrid &&
            !(column.mobileWidthAuto && state.isMobile) &&
            !column.isAuto
              ? `${column.width}rem`
              : column.isAuto
              ? column.isAuto
              : "auto",
        },
        className: `bt-${column.key} ${
          column.mobileWidthAuto && state.isMobile
            ? "table-expandable-header"
            : ""
        }`,
        body: (rowData, config) => {
          if (state.loading) {
            return <Skeleton></Skeleton>;
          } else {
            return (
              <div className={`bt-row bt-row-${config.rowIndex}`}>
                <span className="p-column-title">{column.header}:</span>
                <div className="bt-cell-value">
                  {renderTableCell(rowData, column)}
                </div>
              </div>
            );
          }
        },
      };
      if (column.expanderWithBody) {
        columnsRender.push(
          <Column
            {...columnConfig}
            body={(rowData, props) =>
              column.expanderWithBody(rowData, props, state)
            }
            expander
          />
        );
      } else {
        columnsRender.push(<Column {...columnConfig} />);
      }
    });

    if (props.configModel.hasIndex) {
      columnsRender.unshift(
        <Column
          key={`column-1994`}
          field={"index"}
          header={t("base_table_no")}
          frozen={!state.isMobile && hasFrozen && !state.isGrid}
          style={{ width: "3rem" }}
          body={(_, config) => {
            return (
              <div className={`bt-row bt-row-${config.rowIndex} bt-cell`}>
                <span className="p-column-title">{t("base_table_no")}:</span>
                {(state.table?.searchConfig.pi - 1) *
                  state.table?.searchConfig.ps +
                  config.rowIndex +
                  1}
              </div>
            );
          }}
        />
      );
    }
    if (hasSelection) {
      columnsRender.unshift(
        <Column
          key={`column-1995`}
          selectionMode={!state.isGrid ? "multiple" : "single"}
          frozen={!state.isMobile && hasFrozen && !state.isGrid}
          style={{ width: "4em", height: "50px" }}
          className="selection-column"
        ></Column>
      );
    }
    if (noContentColumn) {
      columnsRender.push(
        <Column
          key={`column-2000`}
          field={"dummy2"}
          header={""}
          className="bt-content-dummy"
          filter={state.showFilter ? true : null}
          filterElement={<></>}
        ></Column>
      );
    }
    if (!state.isMobile && hasFrozen && !state.isGrid) {
      columnsRender.push(
        <Column
          key={`column-1999`}
          field={"dummy"}
          header={"dummy"}
          className="bt-frozen-dummy"
          frozen
          filter={state.showFilter ? true : null}
          filterElement={<></>}
        ></Column>
      );
    }
    if (props.configModel.actionsSingle.length > 0) {
      columnsRender.push(
        <Column
          key={`column-1996`}
          field={"actions"}
          header={
            state.isMobile
              ? props.configModel.actionMobileHeader ?? t("base_table_action")
              : t("base_table_action")
          }
          className="bt-frozen-right action-column"
          frozen={!state.isMobile && hasFrozen && !state.isGrid}
          style={{ width: `${props.configModel.actionWidth}rem` }}
          body={(dataRow, config) => {
            return (
              <div className={`bt-row bt-row-${config.rowIndex} bt-cell`}>
                <span className="p-column-title">
                  {props.configModel.actionMobileHeader ??
                    t("base_table_action") + ":"}
                </span>
                {renderActionsSingle(dataRow, config.rowIndex)}
              </div>
            );
          }}
        ></Column>
      );
    }
    if (state.isGrid) {
      columnsRender.push(
        <Column
          key={`column-1996`}
          field={"actions"}
          header={t("base_table_action")}
          className="bt-grid-column action-column"
          style={{ width: `${props.configModel.actionWidth}rem` }}
          body={(dataRow) => {
            return (
              // <div className="p-col-12 p-md-4">
              <div className="bt-grid-content p-shadow-1">
                <div className="bt-grid-body">
                  {props.configModel?.gridRender(dataRow)}
                </div>

                <div className="bt-grid-footer">
                  {renderActionsSingle(dataRow)}
                </div>
              </div>
              // </div>
            );
          }}
        ></Column>
      );
    }
    return columnsRender;
  };

  /**
   * Render cell item
   */
  const renderTableCell = (rowData, column) => {
    if (column.body) {
      return <div className="bt-cell-render">{column.body(rowData)}</div>;
    } else {
      let value = rowData[column.key];
      let type = column.dataType;
      switch (type) {
        case "date":
          console.log("column", column);
          if (value)
            return moment(new Date(value)).format(
              column.config?.dateFormat || "DD/MM/YYYY"
            );
          return "";
        case "boolean":
          return value.toString() === "true"
            ? t("base_table_true")
            : t("base_table_false");
        default:
          if (!column.excludeGlobalFilter) {
            return (
              <>
                <Highlighter
                  highlightClassName="custom-highlighter"
                  searchWords={[state.table?.searchConfig.filterText]}
                  autoEscape={true}
                  textToHighlight={value?.toString() || ""}
                ></Highlighter>
              </>
            );
          } else {
            return value;
          }
      }
    }
  };

  /**
   * Change form filter function
   */
  const onChangeFormFilter = (e) => {
    if (props.isClientSize) {
      let filterMatchMode = e.changed.control.filterMatchMode;
      if (!filterMatchMode) {
        let type = e.changed.control.type;
        switch (type) {
          case "input":
            filterMatchMode = "contains";
            break;
          default:
            filterMatchMode = "equals";
            break;
        }
      }
      if (filterMatchMode === "range") {
        filterMatchMode = "custom";
      }
      if (tableRef && tableRef.filter)
        tableRef.filter(
          e.changed.data.value,
          e.changed.control.key,
          filterMatchMode || "contains"
        );

      setTimeout(() => {
        onResizeFn();
      });
    } else {
    }
    // const { control, data } = e.changed;
    // onChangeFilterControl(control, data, delaySearch);
  };
  /**
   * Render list select item for toggle hide/show column
   */
  const renderVisibleChoice = () => {
    if (!props.configModel.hasColumnSelector) {
      return;
    }
    return (
      <>
        <MultiSelect
          disabled={
            state.processing ||
            state.loading ||
            state.loadingMobile ||
            !state.tableColumnsOptionSelect
          }
          value={state.tableColumnsSelect}
          options={state.tableColumnsOptionSelect || []}
          tooltip={t("base_table_toggle_column")}
          tooltipOptions={{ position: "top" }}
          onChange={(e) => {
            let tableColumnsSelect = e.value;
            let tableColumns = sortArray(
              tableColumnsSelect.concat(state.tableColumnsFixOption),
              "index"
            );

            setState({
              ...state,
              tableColumns,
              tableColumnsSelect,
            });
          }}
          filter={true}
          filterPlaceholder={t("base_table_all")}
          appendTo={document.body}
          className="bt-visiblechoice"
          panelClassName="bt-visiblechoice-panel p-menu-custom-overlay"
          optionLabel="header"
          placeholder={t("base_table_select_column")}
        />
      </>
    );
  };
  /**
   * Render list select item for toggle hide/show column
   */
  const toggleFilter = (e) => {
    if (!e.value) {
      onReset();
      if (tableRef && tableRef.reset) tableRef.reset();
    } else {
      setState({ ...state, showFilter: e.value });
    }
  };

  const renderFilterBtn = () => {
    const hasFilterColumn = !state.tableColumns?.find(
      (x) =>
        x.filter && (!state.isMobile || props.configModel.displayColumnFilter)
    );
    // if (
    //   !props.configModel.searchForm &&
    //   !state.tableColumns?.find((x) => x.filter && !state.isMobile)
    // ) {
    if (!props.configModel.searchForm && hasFilterColumn) {
      //aw
      return;
    }
    return (
      <>
        <ToggleButton
          className="bt-filterbtn p-button-text"
          checked={state.showFilter}
          onChange={toggleFilter}
          tooltipOptions={{ position: "top" }}
          tooltip={t("base_table_toggle_filter")}
          onIcon="pi pi-filter"
          offIcon="pi pi-filter"
          onLabel=""
          offLabel=""
        />
      </>
    );
  };
  /**
   * Change filter function
   */
  const onFilterChange = async (e) => {
    // onInputChange(e);
    const value = e.target.value;
    lastInputMoment = moment();
    await sleep(1000);
    if (moment().subtract(1000, "milliseconds") >= lastInputMoment) {
      onChangeFilterControl(
        {
          key: "filterText",
        },
        {
          value: value,
          valueStr: value,
        }
      );
    }
  };

  /**
   * Change pagination function
   */
  const onChangePagination = async (e) => {
    if (!props.isClientSize) {
      await onLoadData({
        ...state.table?.searchConfig,
        pi: e.page + 1,
        ps: e.rows,
      });
    } else {
      setState({
        ...state,
        processing: true,
      });
      await sleep(500);
      let newState = { ...state };
      newState.table.searchConfig.pi = e.page + 1;
      newState.table.searchConfig.ps = e.rows;
      newState.displayedRecord = newState.totalRecord;
      newState.displayedTotal = newState.totalRecord.length;
      newState.processing = false;
      setState(newState);
    }
  };
  /**
   * Change sort function
   */
  const onSort = async (e) => {
    if (!props.isClientSize) {
      await onLoadData({
        ...state.table?.searchConfig,
        sortObj: {
          key: e.sortField,
          direction: e.sortOrder,
        },
        pi: 1,
      });
    } else {
      setState({
        ...state,
        processing: true,
      });
      await sleep(500);
      let newState = { ...state };
      newState.table.searchConfig.sortObj = {
        key: e.sortField,
        direction: e.sortOrder,
      };
      newState.displayedRecord = newState.totalRecord;
      newState.displayedTotal = newState.totalRecord.length;
      newState.table.searchConfig.pi = 1;
      newState.processing = false;
      setState(newState);
    }
  };
  /**
   * Change pagination function
   */
  const onReset = async () => {
    if (!props.isClientSize) {
      let searchConfig = { ...state.table?.searchConfig };
      searchConfig.pi = 1;
      searchConfig.searchFormData = [];
      searchConfig.sortObj = {
        key: undefined,
        direction: undefined,
      };
      await onLoadData({
        ...searchConfig,
      });
    } else {
      let newState = { ...state };
      newState = await resetTable(newState);
      setState(newState);
    }
  };

  /**
   * Render AdvancedForm
   */
  const renderAdvancedForm = () => {
    if (state.showFilter && props.configModel.searchForm && state.isGrid) {
      const getBaseFormData = () => {
        let baseFormData = {};
        if (
          state.table &&
          state.table.searchConfig &&
          state.table.searchFormData
        ) {
          state.table.searchConfig.searchFormData.forEach((item) => {
            baseFormData[item.control.key] = item.data.value;
          });
        }

        return baseFormData;
      };
      let formConfig = {
        ...props.configModel.searchForm,
      };
      if (!formConfig.controls) {
        formConfig.control = [];
      }
      props.configModel.columns.forEach((x) => {
        if (x.control) {
          formConfig.controls.push({
            ...x.control,
            key: x.key,
            label: x.header,
            filterMatchMode: x.control?.filterMatchMode || x.filterMatchMode,
          });
        }
      });

      return (
        <div className="advanced-form">
          {!state.loading ? (
            <BaseForm
              config={formConfig}
              onChange={(e) => onChangeFormFilter(e, true)}
              form={getBaseFormData()}
              id={`${id}-baseform`}
            />
          ) : (
            <BaseForm
              id={`${id}-baseformterm`}
              config={props.configModel.searchForm}
            />
          )}
        </div>
      );
    } else {
      return null;
    }
  };
  /**
   * Render TableHeader
   */
  const renderTableHeader = () => {
    // Show Filter Button In Base Table When On Select Row
    const showFilterBtnInBaseTable = props.configModel.hideItemSelectedToolbar;
    return (
      <>
        <div className="bt-header">
          <div className="bt-header-toolbar">
            <div className="bt-title-group">
              <div className="bt-title">{props.configModel.title}</div>
              <div className="bt-desc">{props.configModel.description}</div>
            </div>
            <div className="bt-action-head">
              {renderActionsHead()}
              {state.isGrid ? renderActionsSelf() : null}
            </div>
          </div>
          {!state.isGrid ? (
            <>
              <Animated
                animationIn="slideInUp"
                animationOut="slideOutUp"
                animationInDuration={200}
                animationOutDuration={200}
                isVisible={
                  !state.selectedRecord ||
                  state.selectedRecord.length === 0 ||
                  showFilterBtnInBaseTable
                }
              >
                {renderToolBar()}
              </Animated>
              <Animated
                animationIn="slideInUp"
                animationOut="slideOutUp"
                animationInDuration={200}
                animationOutDuration={200}
                isVisible={
                  !(!state.selectedRecord || state.selectedRecord.length === 0)
                }
              >
                {renderToolBarBatch()}
              </Animated>
            </>
          ) : null}
          {state.isGrid ? (
            <>
              {renderAdvancedForm()}
              <Animated
                animationIn="slideInUp"
                animationOut="slideOutUp"
                animationInDuration={200}
                animationOutDuration={200}
                isVisible={
                  !(!state.selectedRecord || state.selectedRecord.length === 0)
                }
              >
                <div className="batch-in-grid">{renderToolBarBatch()}</div>
              </Animated>
            </>
          ) : null}
        </div>
      </>
    );
  };
  /**
   * Render table view
   */
  const renderTable = () => {
    // Caculate frozen
    let frozenWidth = 0;
    let hasSelection = false;
    if (
      props.configModel.actionsMulti &&
      props.configModel.actionsMulti.length > 0
    ) {
      frozenWidth += 3;
      hasSelection = true;
    }
    if (props.configModel.hasIndex) {
      frozenWidth += 3;
    }
    const columns = state.tableColumns
      ? state.tableColumns.filter((x) => x.frozen)
      : [];

    columns.forEach((column) => {
      frozenWidth += column.width;
    });
    const hasForzen = state.tableColumns
      ? state.tableColumns.filter(
          (x) => !(state.isMobile && x.hideMobile) && x.frozen
        )
      : [];
    frozenWidth = frozenWidth + 1;
    if (props.configModel.actionsSingle.length > 0) {
      frozenWidth = frozenWidth + props.configModel.actionWidth;
    }
    if (state.isMobile || hasForzen.length === 0) {
      frozenWidth = undefined;
    } else {
      frozenWidth = `${frozenWidth}rem`;
    }

    let tableConfig = {
      emptyMessage: t("base_table_no_results"),
      paginatorTemplate: !state.isGrid
        ? "RowsPerPageDropdown CurrentPageReport PageLinks PrevPageLink NextPageLink "
        : "PrevPageLink PageLinks NextPageLink",
      currentPageReportTemplate: `{first} - {last} ${t(
        "of"
      )} {totalRecords} ${t("base_table_items")}`,
      ...props.configModel?.tableConfig,

      // Render header and footer config
      rowGroupMode:
        state.loading || !props.configModel?.tableConfig?.rowGroupMode
          ? null
          : props.configModel?.tableConfig?.rowGroupMode,

      // scrollHeight: state.isGrid ? null : props.configModel?.tableHeight,
      id: id,
      key: `${id}-${state.isGrid ? "grid" : "list"}`,
      ref: (el) => (tableRef = el),
      // loading: state.loading,
      // loadingIcon: "",
      value: state.loading ? [{}] : state.displayedRecord,
      globalFilter: state.table?.searchConfig?.filterText,
      // Pagination
      rows: props.configModel.paginator
        ? state.isGrid
          ? state.table?.searchConfig.psGrid || 9
          : state.table?.searchConfig.ps
        : 10000,
      paginator:
        state.loading ||
        (state.displayedRecord?.length === 0 && props.isClientSize)
          ? false
          : props.configModel.paginator,
      first:
        !!state.table?.searchConfig.pi && !!state.table?.searchConfig.ps
          ? (state.table?.searchConfig.pi - 1) * state.table?.searchConfig.ps
          : null,
      rowsPerPageOptions: props.configModel.pageSizes,
      totalRecords: state.displayedTotal,
      onPage: onChangePagination,
      // Sorting
      sortMode: props.configModel.sortMode ?? "single",
      sortField: props.configModel.sortMode
        ? null
        : state.table?.searchConfig?.sortObj?.key,
      sortOrder: props.configModel.sortMode
        ? null
        : state.table?.searchConfig?.sortObj?.direction,
      onSort: props.configModel.sortMode ? null : onSort,
      // Scroll, frozen
      scrollable: true,
      frozenWidth: state.isGrid ? null : frozenWidth,
      // Lazy . Client vs ServerSide
      lazy: !props.isClientSize,
      rowClassName: (rowData) => {
        let className = {};
        if (props.configModel && props.configModel.rowClassName) {
          className = props.configModel.rowClassName(rowData);
        }
        if (rowData.hideSelection) className["hide-selection"] = true;
        if (rowData.hideAction) className["hide-action"] = true;
        return className;
      },
      // Selection
      // selection: hasSelection ? state.selectedFullRecord : null,
      /* If enable onBaseTableSingleRowSelection, 
            then assign state.selectedFullRecord to selection,
            else run the default behavior. */
      selection: props.configModel.onBaseTableSingleRowSelection
        ? props.configModel.tableConfig.selection
        : hasSelection
        ? state.selectedFullRecord
        : null,
      onSelectionChange: (e) => {
        let selectedFullRecord = state.isGrid
          ? e.value
            ? [e.value]
            : []
          : e.value;

        // let selectedRecord = selectedFullRecord.filter((x) => !x.hideSelection);
        let selectedRecord = selectedFullRecord;
        if (Array.isArray(selectedFullRecord)) {
          selectedRecord = selectedFullRecord.filter((x) => !x.hideSelection);
        }
        setState({
          ...state,
          selectedFullRecord,
          selectedRecord,
        });
        if (props.configModel.onBaseSelectionChange) {
          props.configModel.onBaseSelectionChange(e, state.displayedRecord);
        }
      },
      // onRowSelect - handle on row selected event
      onRowSelect: (e) => {
        if (props.configModel.onRowSelect) {
          props.configModel.onRowSelect(e);
        }
      },
      // onRowClick - handle on row click event
      onRowClick: (e) => {
        if (props.configModel.onRowClick) {
          props.configModel.onRowClick(e);
        }
      },
      expandedRows: props.configModel.expandedRows,
      onRowToggle: (e) => props.configModel.onRowToggle(e),
      header:
        props.configModel.header ??
        (state.isMobile ? props.configModel.mobileHeader : null),
      rowExpansionTemplate: (e) => props.configModel.rowExpansionTemplate(e),
      rowHover: props.configModel.rowHover,
    };

    //expander
    let isExpander = props.configModel.isExpandable && state.isMobile;
    let isScrollMode =
      props.configModel.scrollable &&
      props.configModel.scrollHeight &&
      state.isMobile;
    if (isScrollMode || isExpander) {
      tableConfig.scrollable = props.configModel.scrollable;
      tableConfig.scrollHeight =
        checkMobile() && props.configModel.scrollHeight
          ? props.configModel.scrollHeight
          : "";
    }

    if (
      props.configModel.scrollHeight &&
      props.configModel.virtualScrollerOptions
    ) {
      tableConfig.virtualScrollerOptions =
        props.configModel.virtualScrollerOptions;
      tableConfig.scrollHeight = props.configModel.scrollHeight;
    }

    //expander

    return (
      <div
        //expander
        className={`${
          !state.isGrid
            ? `datatable-responsive ${isExpander ? "expander-datatable" : ""}`
            : "datatable-grid"
        }`}
      >
        {props.isTree ? (
          <TreeTable {...tableConfig}>
            <Column expander />
            {renderTableRow(hasSelection)}
          </TreeTable>
        ) : (
          <DataTable
            {...tableConfig}
            tableClassName={`${
              isExpander ? "mobile-expander-header-style" : ""
            }`}
            className={`p-datatable-responsive ${
              checkMobile()
                ? !props.configModel.isExpandable
                  ? "mobile-view"
                  : "mobile-expander"
                : "web-view"
            }`}
          >
            {renderTableRow(hasSelection)}
          </DataTable>
        )}
      </div>
    );
  };
  const renderToolBar = () => {
    /* Hide Item Selected Toolbar and Render a Normal Toolbar
        When Select a Row in Base Table */
    const hideItemSelectedToolbar = props.configModel.hideItemSelectedToolbar;
    if (
      !state.selectedRecord ||
      state.selectedRecord.length === 0 ||
      hideItemSelectedToolbar
    ) {
      return (
        <div className="bt-toolbar">
          {props.configModel.customToolbar}
          {props.configModel.showGlobal && state.isMobile ? (
            <div className="bt-action bt-action-search">
              <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
                  type="search"
                  onInput={onFilterChange}
                  style={{
                    width: "100%", // aw - width: `${props.configModel.filterSize || 20}rem`,
                    maxWidth: "100%", // aw - maxWidth: "calc(100vw - 13rem)",
                  }}
                  placeholder={
                    props.configModel.filterLabel ||
                    t("base_table_search_in_table")
                  }
                />
              </span>
            </div>
          ) : (
            <div className="bt-dump"></div>
          )}

          <div className="bt-action"> {renderFilterBtn()}</div>
          <div className="bt-action">{renderVisibleChoice()}</div>
          <div className="bt-action-self">{renderActionsSelf()}</div>
          {props.configModel.customToolbarAction}
        </div>
      );
    } else {
      return null;
    }
  };

  const renderToolBarBatch = () => {
    /* Hide Render Toolbar Batch
        When Select a Row in Base Table */
    const hideToolbarBatch = props.configModel.hideItemSelectedToolbar;
    if (
      !state.selectedRecord ||
      state.selectedRecord.length === 0 ||
      hideToolbarBatch
    ) {
      return null;
    } else {
      return (
        <div className="bt-toolbar bt-toolbar-batch">
          <div className="left-panel">
            {!state.isGrid ? (
              <div className="bt-selected-label">
                {t("base_table_ItemsSelected", {
                  base_table_number: state.selectedRecord.length,
                })}
              </div>
            ) : null}
          </div>
          <div className="right-panel">{renderActionMulti()}</div>
        </div>
      );
    }
  };
  /**
   * Base function to load data
   * @param {*} searchConfig
   */
  const onLoadData = async (searchConfig) => {
    setState({
      ...state,
      loading: true,
    });
    var res = await props.searchFn(searchConfig);
    const totalRecord = res.datas;
    const total = res.total;
    let isMobile = checkMobile();
    setState({
      ...state,
      loading: false,
      table: {
        ...res,
      },
      totalRecord,
      displayedRecord: totalRecord,
      displayedTotal: total,
      isMobile,
      showFilter: false,
    });
  };
  let lastInputMoment = moment();
  /**
   * Change form filter control function
   */
  const onChangeFilterControl = async (control, data, delaySearch = false) => {
    let searchFormData = [];
    if (Array.isArray(state.table?.searchConfig?.searchFormData)) {
      searchFormData = [...state.table?.searchConfig?.searchFormData];
    }
    const indexForm = searchFormData.findIndex(
      (x) => x.control.key === control.key
    );
    if (indexForm >= 0) {
      searchFormData[indexForm].data = data;
    } else {
      searchFormData.push({
        data,
        control,
        id: makeId(),
      });
    }
    // click dummy btn
    let dummy = document.getElementById("bt-filter-form-dummyBtn");
    if (dummy) {
      dummy.click();
    }

    let filterText = state.table?.searchConfig.filterText;
    if (control.key === "filterText") {
      filterText = data.value;
    }
    const searchConfig = {
      ...state.table?.searchConfig,
      searchFormData,
      filterText,
    };
    if (!props.isClientSize) {
      if (control.key !== "filterText") {
        if (!delaySearch) {
          await onLoadData({
            ...searchConfig,
            pi: 1,
          });
        } else {
          setState({
            ...state,
            table: {
              ...state.table,
              searchConfig,
            },
          });
        }
      }
    } else {
      setState({
        ...state,
        table: {
          ...state.table,
          searchConfig,
        },
        displayedRecord: state.totalRecord,
        displayedTotal: state.totalRecord.length,
      });
    }
  };
  return (
    <Card>
      <div
        className={`bt-table bt-table-custom ${
          state.processing ? "bt-table-processing" : ""
        }`}
        id={`bt-table-${id}`}
        style={props.configModel.style}
      >
        {state.isMobile ? <div id={`checkMobile`}></div> : null}
        {renderTableHeader()}
        {renderTable()}
      </div>
    </Card>
  );
});

export default BaseTable;

export const BUILD_SEARCH_CONFIG = (searchConfig) => {
  let sort, direction, fullTextSearch;
  let filter = {};
  if (searchConfig.sortObj) {
    if (searchConfig.sortObj.direction === 1) {
      direction = "+";
    }
    if (searchConfig.sortObj.direction === -1) {
      direction = "-";
    }
    if (direction) {
      sort = `${direction}${searchConfig.sortObj.key}`;
    }
  }
  if (searchConfig.searchFormData && searchConfig.searchFormData.length > 0) {
    searchConfig.searchFormData.forEach((elm) => {
      if (elm.control.key === "filterText") {
        fullTextSearch = elm.data.value;
      } else {
        filter[elm.control.key] = elm.data.value;
      }
    });
  }
  return {
    ...searchConfig,
    sort,
    filter,
    fullTextSearch,
  };
};
