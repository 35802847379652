import "./login-alert.scss";
import React from "react";
import { Messages } from "primereact/messages";
import { setGlobal } from "./../../../../../../redux/actions/global";
import { useDispatch } from "react-redux";

function LoginAlert() {
  const dispatch = useDispatch();
  return (
    <>
      <Messages
        className="ep-login-alert"
        ref={(el) => {
          dispatch(
            setGlobal({
              alert: el,
            })
          );
        }}
      />
    </>
  );
}
export default LoginAlert;
