import { SET_PASSWORD_POLICY } from "../reduxConstants";
const initialState = {
  password: {},
};

export default function policy(state = initialState, action) {
  switch (action.type) {
    case SET_PASSWORD_POLICY: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
