// constants
export const AUTH_SET_INFO = "AUTH_SET_INFO";
export const AUTH_SET_TOKEN_EXPIRED = "AUTH_SET_TOKEN_EXPIRED";
export const AUTH_RESET_INFO = "AUTH_RESET_INFO";
export const SET_LIST_TENANT = "SET_LIST_TENANT";
export const SET_GLOBAL = "SET_GLOBAL";
export const RELOAD_TENANT = "RELOAD_TENANT";
export const BROADCAST_TENANT = "BROADCAST_TENANT";
export const CLEAR_TENANT = "CLEAR_TENANT";
export const SET_SIDEBAR = "SET_SIDEBAR";
export const SIDEBAR_CLEAR = "SIDEBAR_CLEAR";
export const SIDEBAR_MENU = "SIDEBAR_MENU";
export const SET_DEVICE_TYPE = "SET_DEVICE_TYPE";

// -- languages
export const ADD_LANGUAGE_LIST = "ADD_LANGUAGE_LIST";
export const BROADCAST_LANGUAGE_LIST = "BROADCAST_LANGUAGE_LIST";
export const SET_USER_SELECTED_LANGUAGE = "SET_USER_SELECTED_LANGUAGE";
export const BROADCAST_USER_SELECTED_LANGUAGE =
  "BROADCAST_USER_SELECTED_LANGUAGE";
export const LANGUAGE_SET_LOADED_CONTEXT = "LANGUAGE_SET_LOADED_CONTEXT";
export const LANGUAGE_CLEAR_LOADED_CONTEXT = "LANGUAGE_CLEAR_LOADED_CONTEXT";
export const CLEAR_LANGUAGE = "CLEAR_LANGUAGE";

export const SET_BREAD_CRUMB = "SET_BREAD_CRUMB";
export const SET_MODAL = "SET_MODAL";
export const SET_LOADING = "SET_LOADING";
export const SET_SIDE_MODAL = "SET_SIDE_MODAL";
export const SET_SIDE_MODAL_DYNA = "SET_SIDE_MODAL_DYNA";

export const SET_USER_INFO = "SET_USER_INFO";

export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const SET_COUNTDOWN_TIMER = "SET_COUNTDOWN_TIMER";

export const CLEAR_REDUX = "CLEAR_REDUX";

export const SET_PASSWORD_POLICY = "SET_PASSWORD_POLICY";

export const BREADCRUMB_SET_DYNAMIC_PATHS = "BREADCRUMB_SET_DYNAMIC_PATHS";
export const BREADCRUMB_RESET_DYNAMIC_PATHS = "BREADCRUMB_RESET_DYNAMIC_PATHS";

// spinner
export const SET_SPINNER = "SET_SPINNER";
export const RESET_SPINNER = "RESET_SPINNER";

// rental
export const SET_RENTAL_FORM = "SET_RENTAL_FORM";
export const RESET_RENTAL_FORM = "RESET_RENTAL_FORM";
export const SET_RENTAL_FORM_CONFIG = "SET_RENTAL_FORM_CONFIG";
export const SET_RENTAL_SECTION_CONFIG = "SET_RENTAL_SECTION_CONFIG";
export const RESET_RENTAL_FORM_CONFIG = "RESET_RENTAL_FORM_CONFIG";
export const RESET_RENTAL_FORM_VALUE = "RESET_RENTAL_FORM_VALUE";
export const SET_RENTAL_MODULE_CONFIG = "SET_RENTAL_MODULE_CONFIG";
export const CLEAR_ALL_RENTAL_CONTEXT = "CLEAR_ALL_RENTAL_CONTEXT";
export const SET_RENTAL_EMPLOYEE_DETAILS = "SET_RENTAL_EMPLOYEE_DETAILS";
export const CLEAR_RENTAL_EMPLOYEE_DETAILS = "CLEAR_RENTAL_EMPLOYEE_DETAILS";

//multi-step-form
export const SET_FORM = "SET_FORM";
export const RESET_FORM = "RESET_FORM";
export const SET_FORM_CONFIG = "SET_FORM_CONFIG";
export const FILTER_FIELD_ENUM = "FILTER_FIELD_ENUM";
export const UPDATE_FIELD_PROPERTIES = "UPDATE_FIELD_PROPERTIES";
export const SET_SECTION_CONFIG = "SET_SECTION_CONFIG";
export const RESET_FORM_CONFIG = "RESET_FORM_CONFIG";
export const RESET_FORM_VALUE = "RESET_FORM_VALUE";
export const SET_MODULE_CONFIG = "SET_MODULE_CONFIG";
export const CLEAR_ALL_CONTEXT = "CLEAR_ALL_CONTEXT";
export const SET_FORM_LANGUAGE = "SET_FORM_LANGUAGE";
