import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { TreeTable, TreeTableSelectionParams } from "primereact/treetable";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { jsonEqual } from "../../../utils/utils";
import {
  BCProps,
  BCStates,
  isError,
  renderError,
  validateControl,
} from "../base-control";
import "./tree-table-control.scss";

export interface TreeTableControlState extends BCStates {}

export interface TreeTableControlProps extends BCProps {}

const TreeTableControl: React.FC<TreeTableControlProps> = (props) => {
  const { treeValue, treeColumns, ...configuration } = props.config;
  const { t } = useTranslation();
  const ruleList = props.ruleList || [];
  if (props.required) {
    ruleList.push({
      name: "required",
    });
  }

  function ArrayToObject(value: string[]) {
    return value.reduce((a, v) => ({ ...a, [v]: { checked: true } }), {});
  }

  let initState: TreeTableControlState = {
    touched: false,
    loading: true,
    value: Array.isArray(props.value) ? ArrayToObject(props.value) : {},
    valueStr: props.value ? props.value.join(",") : [],
    controlState: {
      invalid: true,
    },
  };

  const [state, setState] = useState(initState);
  const mountedRef = useRef(true);
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const onChange = async (e: TreeTableSelectionParams) => {
    let valueStr: any = [];
    let data: any = {};

    for (const [key, value] of Object.entries(e.value)) {
      let obj: any = JSON.parse(JSON.stringify(value));
      if (obj.checked || obj.partialChecked) {
        data[key] = { checked: true };
        valueStr.push(key);
      }
    }

    const controlState = validateControl(ruleList, valueStr.join(","), t);
    let _state = {
      ...state,
      value: data,
      valueStr,
      controlState,
      loading: false,
    } as any;
    if (props.onChange) {
      props.onChange({
        controlState: _state.controlState,
        value: valueStr,
        valueStr: valueStr.join(","),
      });
    }
    if (props.onTrueUpdateValue) {
      props.onTrueUpdateValue({
        controlState: _state.controlState,
        value: valueStr,
        valueStr: valueStr.join(","),
      });
    }

    if (!jsonEqual(_state, state)) {
      setState(_state);
    }
  };

  const renderColumns = () => {
    return treeColumns.map((x: any, idx: number) => {
      return <Column {...x} key={idx} />;
    });
  };

  const rowClass = (data: any) => {
    if (isError(state, props)) {
      return {
        "p-invalid": data !== null,
      };
    }

    return { "tree-table-row": data };
  };

  const renderControl = () => {
    return (
      <TreeTable
        className={`${props.className} ${
          isError(state, props) ? "p-invalid" : ""
        }`}
        rowClassName={rowClass}
        selectionMode="checkbox"
        selectionKeys={state.value}
        {...configuration}
        value={treeValue}
        onSelectionChange={(event: any) => onChange(event)}
        disabled={props.config?.disabled || props.config?.readOnly}
        placeholder={props.placeholder}
        id={props.id}
      >
        {renderColumns()}
      </TreeTable>
    );
  };

  return (
    <div
      className={`tree-table-control-inner p-field ${
        props.noLabel ? "no-label" : ""
      }`}
    >
      <label htmlFor={props.id}>
        {props.label}
        {props.required && !props.noRequiredLabel ? (
          <small className="required p-invalid">&nbsp;*</small>
        ) : null}
        {props.tooltip ? (
          <Button
            type="button"
            tooltip={props.tooltip}
            tooltipOptions={{ position: "top" }}
            icon="pi pi-info-circle"
            className="p-button-rounded label-help p-button-text p-button-plain"
          />
        ) : null}
      </label>
      <div
        className={`p-inputgroup ${
          isError(state, props) ? "p-inputgroup-error" : ""
        }`}
      >
        {renderControl()}
        {props.hintRight && (
          <span className={"control-hint-right"}>{props.hintRight}</span>
        )}
      </div>
      {props.hintBottom && (
        <div className={"control-hint-bottom"}>{props.hintBottom}</div>
      )}
      {renderError(state, props, t)}
    </div>
  );
};

export default TreeTableControl;
