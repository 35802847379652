import "./employee-error-500.scss";

import { useHistory, Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { PortalLink } from "../../../../services/utils";

const PageSystemError = () => {
  const { t } = useTranslation();
  let history = useHistory();

  return (
    <div className="error-500-bg">
      <p>
        <span className="error-500-title">500</span>

        <span className="error-500-subtitle">{t("common_error_500")}</span>
        <span className="error-500-text">
          <Trans i18nKey="common_error_500_message_1">
            <Link to={PortalLink()} />
          </Trans>

          <Trans i18nKey="common_error_500_message_2">
            <span
              className="error-500-text-message"
              onClick={history.goBack}
            ></span>
          </Trans>
        </span>
      </p>
    </div>
  );
};

export default PageSystemError;
