import "./App.scss";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "react-lazy-load-image-component/src/effects/blur.css";
// routing
import { Redirect, Route, Switch } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// components
import MainModal from "./components/main-modal/main-modal";
import MainSideModal from "./components/main-side-modal/main-side-modal";
import MainToast from "./components/main-toast/main-toast";
import Portal from "./portal/portal";
import CommonHeader from "./portal/modules/public/common/common-header";
import IdleTimer from "./portal/modules/utils/idle-timer.js/idle-timer";

import {
  EEPORTAL_LABEL_BASE_CONTROL,
  EEPORTAL_LABEL_GENERAL,
  EEPORTAL_LABEL_LOGIN,
  enumsKeyToArray,
  LOGOUT_PATH,
  MODULE_CODE,
} from "./constants";
import GuardedRoute from "./components/guarded-route/guarded-route";

import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import MainLoading from "./portal/layouts/components/main-loading/main-loading";
// import { useState } from "react";

//const
import { LOGIN_PATH } from "./constants/index";
import LogoutService from "./services/utils/logout-service";
import { PortalLink } from "./services/utils/index";
import LanguageLoader from "./components/language-loader/language-loader";
import { ClientConfiguration } from "./portal/modules";
import { onWindowsUnload } from "./utils/utils";
import { PrivateRoutes } from "./portal/employee-router";
import MainSystemBanner from "./portal/layouts/components/main-system-banner/main-system-banner";
import {
  ExpiredPassword,
  ForgotPassword,
  LoginForm,
  ResetPassword,
} from "./portal/modules/public/login/components";
import MainSpinner from "./portal/layouts/components/main-spinner/main-spinner";
import ClientLoader from "./components/base-control/client-loader/client-loader";

export const PublicRoutes = [
  {
    id: LOGIN_PATH.LOGIN,
    path: `${LOGIN_PATH.LOGIN}/:clientCode`,
    component: LoginForm,
  },
  {
    id: LOGIN_PATH.LOGIN,
    path: `${LOGIN_PATH.LOGIN}/:clientCode/:entityCode`,
    component: LoginForm,
  },
  {
    id: LOGIN_PATH.FORGOT_PWD,
    path: `${LOGIN_PATH.FORGOT_PWD}/:clientCode`,
    component: ForgotPassword,
  },
  {
    id: LOGIN_PATH.FORGOT_PWD,
    path: `${LOGIN_PATH.FORGOT_PWD}/:clientCode/:entityCode`,
    component: ForgotPassword,
  },
  {
    id: LOGIN_PATH.RST_PWD,
    path: `${LOGIN_PATH.RST_PWD}/:clientCode`,
    component: ResetPassword,
  },
  {
    id: LOGIN_PATH.RST_PWD,
    path: `${LOGIN_PATH.RST_PWD}/:clientCode/:entityCode`,
    component: ResetPassword,
  },
  {
    id: LOGIN_PATH.FST_LOGIN,
    path: `${LOGIN_PATH.FST_LOGIN}/:clientCode`,
    component: ExpiredPassword,
  },
  {
    id: LOGIN_PATH.FST_LOGIN,
    path: `${LOGIN_PATH.FST_LOGIN}/:clientCode/:entityCode`,
    component: ExpiredPassword,
  },
  {
    id: LOGIN_PATH.PWD_EXP,
    path: `${LOGIN_PATH.PWD_EXP}/:clientCode`,
    component: ExpiredPassword,
  },
  {
    id: LOGIN_PATH.PWD_EXP,
    path: `${LOGIN_PATH.PWD_EXP}/:clientCode/:entityCode`,
    component: ExpiredPassword,
  },
  // {
  //   id: LOGIN_PATH.OKTA,
  //   path: `${LOGIN_PATH.OKTA}`,
  //   component: OktaLogin,
  // },
  {
    id: LOGIN_PATH.CLIENT_CONFIGURATION,
    path: `${LOGIN_PATH.CLIENT_CONFIGURATION}`,
    component: ClientConfiguration,
  },
  {
    id: MODULE_CODE.TermCondition,
    path: `${MODULE_CODE.TermCondition}`,
    component: CommonHeader,
    redirectExclude: true,
  },
  {
    id: MODULE_CODE.PrivacyPolicy,
    path: `${MODULE_CODE.PrivacyPolicy}`,
    component: CommonHeader,
    redirectExclude: true,
  },
  {
    path: ``,
    redirect: `/${LOGIN_PATH.CLIENT_CONFIGURATION}`,
    exact: true,
    redirectExclude: true,
  },
  {
    path: `login`,
    redirect: `/${LOGIN_PATH.CLIENT_CONFIGURATION}`,
    exact: true,
  },
  {
    path: `login/`,
    redirect: `/${LOGIN_PATH.CLIENT_CONFIGURATION}`,
    exact: true,
  },
];

/**
 * Application component
 */
function App() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const match = useRouteMatch();
  const { i18n, ready } = useTranslation();
  // const language =
  //   useSelector((state) => state.language?.language?.selectedLang?.value) ||
  //   "en";
  const loadedContext = useSelector((state) => state.language?.loadedContext);
  const clientInfo = useSelector((state) => state.global?.clientInfo);
  const modalIsOpen = useSelector((state) => state.modal.isOpen ?? false);

  // Start idle timer if user is logged in and autoSignoffEnabled is true
  // Remark: auto sign off timer values unit is in minute(s)
  const startIdleTimer =
    auth?.isLoggedIn &&
    clientInfo?.autoSignoffEnabled &&
    clientInfo?.autoSignoffIdleTime > 0 &&
    clientInfo?.autoSignoffAlertCountDown >= 0;

  // console.log("--app----auth-", auth);
  // const [langReady, setLangReady] = useState(false);

  // useEffect - when on page refresh clearLoadedContext
  // and if component is properly unmount then remove event listener
  // else remove event listener after clearLoadedContext
  useEffect(() => {
    window.addEventListener("beforeunload", onWindowsUnload(dispatch));
    return () => {
      window.removeEventListener("beforeunload", onWindowsUnload(dispatch));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // -- retrieve additional custom label from backend
  i18n.on("initialized", async (options) => {
    console.log("--i18n.on--");
    try {
      // console.log('--i18n-initialzied--', options);
      // let res = await EmpPortalConfigService.empPortalConfigCustomTranslation({
      //   parameterLocale: language,
      //   parameterEntityId: clientInfo?.entityId,
      //   parameterClientId: clientInfo?.clientId,
      // });

      // // console.log('---i18n-initialzied---res---', res, language);
      // i18n.addResourceBundle(language, "translation", res.data, true, true);

      // load the persisted bundle, so it still works after user refresh browser
      if (loadedContext?.data) {
        for (var lang in loadedContext?.data) {
          // console.log('--app--loading-stored-bundle--', lang, loadedContext?.data[lang]);
          i18n.addResourceBundle(
            lang,
            "translation",
            loadedContext?.data[lang],
            true,
            true
          );
        }
      }
    } catch (err) {
      console.log("---app-i18n-on-initialized-err-", err);
    } finally {
      // setLangReady(true);
    }
  });

  const idleTimer = useMemo(() => {
    if (startIdleTimer) {
      return <IdleTimer />;
    }

    return <></>;
  }, [startIdleTimer]);

  // console.log('---app-ready--', ready);
  if (!ready) {
    console.log("--!ready--");
    return (
      <>
        <MainLoading />
      </>
    );
  }

  const UnauthorizedRoute = () => {
    if (!auth?.isLoggedIn) {
      const unauthorizedRoutes = PublicRoutes.map((route, index) => {
        if (route.redirect) {
          return (
            <Route
              key={`${index}`}
              {...route}
              path={`${match.path}${route.path}`}
            >
              <Redirect to={`${route.redirect}`} />
            </Route>
          );
        }

        return (
          <Route
            key={`${index}`}
            path={`${match.path}${route.path}`}
            component={route.component}
            render={route.render}
            exact={true}
          />
        );
      });

      const redirectPrivateRoutes = PrivateRoutes.filter(
        (x) => !x.redirectExclude
      ).map((route, index) => {
        return (
          <Route
            key={`${unauthorizedRoutes.length + index}`}
            strict
            path={`${match.path}${route.path}`}
          >
            <Redirect to={`/${LOGOUT_PATH.LOGOUT}`} />
          </Route>
        );
      });

      return [...unauthorizedRoutes, ...redirectPrivateRoutes];
    }
  };

  return (
    <>
      <ClientLoader
        clientInfo={clientInfo}
        auth={auth?.isLoggedIn}
        loggedInDateTime={auth?.loggedInDateTime}
      >
        <LanguageLoader
          auth={auth?.isLoggedIn}
          contexts={enumsKeyToArray([
            EEPORTAL_LABEL_LOGIN,
            EEPORTAL_LABEL_BASE_CONTROL,
            EEPORTAL_LABEL_GENERAL,
          ])}
        >
          {/* <SessionManager /> */}
          <MainSystemBanner />
          <Switch>
            <Route
              exact
              path={`/${LOGOUT_PATH.LOGOUT}`}
              component={LogoutService}
            />
            <GuardedRoute
              path={PortalLink()}
              component={Portal}
              auth={auth?.isLoggedIn}
              authEmployeeCode={auth?.employeeCode}
            >
              {UnauthorizedRoute()}
            </GuardedRoute>
            <Route render={() => <CommonHeader isNotFound={true} />} />
          </Switch>
          {modalIsOpen && <MainModal />}
          <MainSideModal />
          <MainToast />
          {idleTimer}
        </LanguageLoader>
      </ClientLoader>
      <MainSpinner />
    </>
  );
}

export default App;
