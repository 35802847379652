import { EditorUtils } from "@progress/kendo-react-editor";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { useTranslation } from "react-i18next";
import { DefaultKendoEditorTools } from "./kendo-editor";

export interface CustomAutoCompleteDataObject {
  name: string;
  value: string;
}

interface CustomDropDownMenuProps {
  data: CustomAutoCompleteDataObject[];
  placeholder: string;
  view: any;
  id?: any;
}

export function SetInsert(event: DropdownChangeParams, view: any) {
  let selectedObject = event.value;
  if (!selectedObject) {
    return;
  }
  let dbMappingField = selectedObject;
  if (!dbMappingField) {
    return;
  }
  const schema = view.state.schema;

  schema.spec.nodes.addToEnd("nonEditable", DefaultKendoEditorTools);

  // get the new node from the schema
  const nodeType = schema.nodes.nonEditable;

  // create a new node with the selected text
  const node = nodeType.createAndFill(
    { class: "shortcode" },
    schema.text(`${dbMappingField}`)
  );

  // Insert the new node
  EditorUtils.insertNode(view, node);
  view.focus();
}

export const KendoCustomDropDownMenu = (props: CustomDropDownMenuProps) => {
  const { t } = useTranslation();
  const { data, view, placeholder, id } = props;

  return (
    <Dropdown
      options={data}
      style={{ width: `min-content` }}
      onChange={(e) => SetInsert(e, view)}
      optionLabel="name"
      filter
      showClear
      filterBy="name"
      placeholder={t(placeholder)}
    />
  );
};
