import "./main-loading.scss";

import Lottie from "react-lottie-player";
import loadingAnimation from "./main-loading.json";

const MainLoading = () => {
  return (
    <div className="main-loading-overlay">
      <div>
        <Lottie
          loop
          animationData={loadingAnimation}
          play
          height={"100%"}
          width={"100%"}
        />
      </div>
    </div>
  );
};
export default MainLoading;
