import { MESSAGES, RESPONSE_MESSAGE_TYPE } from "./../../constants/index";
import { MESSAGE_SEVERITY } from "./../../constants/index";
import moment from "moment";
import { store } from "../../redux/store";
import { openModal } from "../../redux/actions/modal";
import { v4 as uuidv4 } from "uuid";
/**
 *
 * @param {*} res
 * @param {*} toast
 * @param {*} alert
 * @param {*} i18n
 */
export const MessageSwitch = async (res, toast, alert, i18n) => {
  let msg = alert || toast;

  if (msg === undefined || msg === null) {
    return;
  }

  // msg.clear();
  var messages = [];
  if (Array.isArray(res.messages)) {
    messages = res.messages;
  } // message from error
  else if (res.data instanceof Blob) {
    let text = res.data;
    text = await new Response(res.data).text();

    let objText = JSON?.parse(text);
    if (objText.hasOwnProperty(MESSAGES) && objText.messages?.length > 0) {
      messages = objText.messages;
    } else {
      messages.push({
        statusText: res.statusText,
        text: text,
        type: RESPONSE_MESSAGE_TYPE.ERROR,
      });
    }
  } else if (res?.data?.hasOwnProperty("Message")) {
    const hasTranslation = i18n?.exists(res.data.Message);
    messages.push({
      statusText: res.statusText,
      text: hasTranslation ? i18n?.t(res.data.Message) : res.data.Message,
      type: RESPONSE_MESSAGE_TYPE.ERROR,
    });
  } else if (
    !!res?.data?.errors &&
    !!res.data?.errors?.id &&
    Array.isArray(res.data.errors.id)
  ) {
  } else if (!!res?.data?.text && !!res?.data?.type) {
    messages.push({
      statusText: res.statusText,
      text: res?.data?.text,
      type: res?.data?.type,
    });
  } else if (Array.isArray(res?.data?.messages)) {
    res?.data?.messages?.forEach((x) =>
      messages.push({
        statusText: res.statusText,
        text: x?.text,
        type: x?.type,
      })
    );
  } else if (Array.isArray(res?.data?.errors?.Files)) {
    res?.data?.errors?.Files?.forEach((x) =>
      messages.push({
        statusText: res.statusText,
        text: x,
        type: RESPONSE_MESSAGE_TYPE.ERROR,
      })
    );
  } else {
    let text = res?.data ?? i18n.t("misc_axios_network_error");
    let statusText = res?.statusText ?? i18n.t("misc_axios_notification");
    messages.push({
      statusText: statusText,
      text: text,
      type: RESPONSE_MESSAGE_TYPE.ERROR,
    });
  }

  messages.forEach((element) => {
    const idx = uuidv4();
    const param = {};
    if (element.placeholder) {
      element.placeholder.forEach((e, placeIndex) => {
        param["placeIndex" + placeIndex] = e;
      });
    }

    switch (element.type) {
      case RESPONSE_MESSAGE_TYPE.ERROR: {
        if (msg === alert) {
          alert.clear();
        }
        msg.show({
          severity: MESSAGE_SEVERITY.ERROR,
          summary: msg === alert ? "" : i18n.t("misc_axios_error_message"),
          detail: (
            <div
              id={`message-switch-error-${idx}`}
              className="hris-message-error"
              // dangerouslySetInnerHTML={{
              //   __html: element.text,
              // }}
            />
          ),
          life: 6000,
          sticky: msg === alert,
        });

        if (document.getElementById(`message-switch-error-${idx}`)) {
          document.getElementById(`message-switch-error-${idx}`).innerHTML =
            element.text;
        }
        break;
      }

      case RESPONSE_MESSAGE_TYPE.ALERT: {
        if (msg === alert) {
          alert.clear();
        }
        msg?.show({
          severity: MESSAGE_SEVERITY.INFO,
          detail: (
            <div
              id={`message-switch-info-${idx}`}
              className="hris-message-info"
              // dangerouslySetInnerHTML={{
              //   __html: element.text,
              // }}
            />
          ),
          life: 6000,
          sticky: msg === alert,
        });

        if (document.getElementById(`message-switch-info-${idx}`)) {
          document.getElementById(`message-switch-info-${idx}`).innerHTML =
            element.text;
        }
        break;
      }

      case RESPONSE_MESSAGE_TYPE.WARNING: {
        if (msg === alert) {
          alert.clear();
        }
        msg.show({
          severity: MESSAGE_SEVERITY.WARN,
          detail: (
            <div
              id={`message-switch-warn-${idx}`}
              className="hris-message-warn"
              // dangerouslySetInnerHTML={{
              //   __html: element.text,
              // }}
            />
          ),
          life: 6000,
          sticky: msg === alert,
        });

        if (document.getElementById(`message-switch-warn-${idx}`)) {
          document.getElementById(`message-switch-warn-${idx}`).innerHTML =
            element.text;
        }
        break;
      }
      case RESPONSE_MESSAGE_TYPE.TOAST: {
        toast.show({
          severity: MESSAGE_SEVERITY.INFO,
          summary: i18n.t("misc_axios_notification"),
          detail: element.text,
          life: 5000,
        });
        break;
      }
      case RESPONSE_MESSAGE_TYPE.SUCCESS: {
        if (msg === alert) {
          alert.clear();
        }
        msg.show({
          severity: MESSAGE_SEVERITY.SUCCESS,
          summary: msg === alert ? "" : i18n.t("misc_axios_notification"),
          detail: element.text,
          life: 3000,
          sticky: msg === alert,
        });
        break;
      }

      default: {
        if (msg === alert) {
          alert.clear();
        }
        msg.show({
          severity: MESSAGE_SEVERITY.WARN,
          detail: (
            <div
              id={`message-switch-warn-${idx}`}
              className="hris-message-detail"
              // dangerouslySetInnerHTML={{
              //   __html: element.text,
              // }}
            />
          ),
          life: 3000,
          sticky: msg === alert,
        });

        if (document.getElementById(`message-switch-warn-${idx}`)) {
          document.getElementById(`message-switch-warn-${idx}`).innerHTML =
            element.text;
        }
        break;
      }
    }
  });
};

export const showTimestampToastSuccess = ({
  message,
  toast,
  t = (x) => x,
  ...config
}) => {
  const _toast = toast || store.getState()?.global?.toast;
  _toast.show({
    severity: MESSAGE_SEVERITY.SUCCESS,
    content: (
      <div className="api-toast">
        <span className="pi pi-check api-toast-icon" />
        <div className="p-toast-message-text">
          <span className="p-toast-summary">{message}</span>
          <div className="p-toast-detail">
            <div className="api-toast-timestamp">
              {`${t("toast_timestamp")} [${moment().format("HH:mm:ss")}]`}
            </div>
          </div>
        </div>
      </div>
    ),
    life: 3000,
    ...config,
  });
};

export const showToast = ({ summary, ...config }) => {
  const _toast = store.getState()?.global?.toast;
  _toast.show({
    summary: summary,
    severity: MESSAGE_SEVERITY.INFO,
    life: 5000,
    ...config,
  });
};

export const showErrorMessageModal = ({ dispatch, t, ...config }) => {
  dispatch(
    openModal({
      title: (
        <span
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span className="pi pi-exclamation-circle"></span>
          <span>{t("misc_error_dialog_title")}</span>
        </span>
      ),
      content: t("misc_error_dialog_defaultMessage"),
      classNameMainDialog: "general-message-modal",
      primaryButtonText: t("misc_error_dialog_close"),
      primaryButtonClickFn: async ({ closeFn }) => {
        closeFn();
      },
      ...config,
    })
  );
};

export const showInfoMessageModal = ({ dispatch, t, ...config }) => {
  dispatch(
    openModal({
      content: t("misc_error_dialog_defaultMessage"),
      classNameMainDialog: "general-message-modal",
      primaryButtonText: t("login_back"),
      primaryButtonClickFn: async ({ closeFn }) => {
        closeFn();
      },
      ...config,
    })
  );
};
