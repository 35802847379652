import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { scrollToErrorControl } from "../../../../components/base-form/utils";
import { showSpinner } from "../../../../redux/actions/spinner";
import {
  AdminService,
  CompanyDocumentConfigurationService,
} from "../../../../services/hrmnet-api";
import { Skeleton } from "primereact/skeleton";
import BaseMultiStepForm from "../../../../components/base-multi-step-form/base-multi-step-form";
import { BaseMultiStepFormSkeleton } from "../../../../components/base-multi-step-form/base-multi-step-form-skeleton";
import {
  BaseMultiStepConfigToFormModel,
  BaseMultiStepConfigToStepNavModel,
  BaseMultiStepCustomFunctionLibrary,
} from "../../../../components/base-multi-step-form/base-multi-step-form-utils";
import { BaseMultiStepFormScrollToMultiStepFormMenuActiveItem } from "../../../../components/base-multi-step-form/base-multi-step-form-scroll";
import {
  setFormLanguage,
  setMainForm,
  setMainFormConfig,
  setMainFormSectionConfig,
} from "../../../../redux/actions/form";
import {
  CompanyDocumentConfigurationApiSteps,
  CompanyDocumentConfigurationInitForm,
} from "./company-document-configuration-default-form";
import { showErrorMessageModal } from "../../../../services/utils/message";
import { getResponseMessage, isResponseOk } from "../../../../utils/utils";
import { useHistory } from "react-router-dom";
import { FUNCTION_CODE, MODULE_CODE } from "../../../../constants";
import { PortalLink } from "../../../../services/utils";
import { store } from "../../../../redux/store";
import { MessageSeverity } from "primereact/api";

const CompanyDocumentConfigurationForm = ({ companyDocumentId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  let toast = store.getState().global.toast;

  const [state, setState] = useState({
    isLoading: true,
    activeStep: 0,
    formResp: {},
    touched: false,
  });

  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );
  const formLanguage = useSelector((state) => state.commonForm.formLanguage);

  const form = useSelector((state) => state.commonForm.form);
  const selectedTemplateName = useSelector(
    (state) => state.commonForm.form?.TemplateName
  );
  const setForm = (x) => dispatch(setMainForm(x));
  const formConfig = useSelector((state) => state.commonForm.formConfig);
  const setFormConfig = (x) => dispatch(setMainFormConfig(x));
  const setFormSectionConfig = (x) => dispatch(setMainFormSectionConfig(x));

  const formObjRef = React.createRef();

  let customFunctionLibrary = BaseMultiStepCustomFunctionLibrary({
    dispatch,
  });

  useEffect(() => {
    const loadForm = async () => {
      const applicationId = selectedTemplateName;
      const isLangChange = formLanguage != selectedLangKey;
      dispatch(setFormLanguage(selectedLangKey));
      //enum api
      //   const templateFieldApi =
      //     CompanyDocumentConfigurationService.CompanyDocumentConfigurationGetTemplateFields();

      const formApi = AdminService.companyDocumentGetCompanyDocumentById({
        id: companyDocumentId,
      });

      const [currentForm] = await Promise.all([formApi]);

      let stepRes = CompanyDocumentConfigurationApiSteps(t);
      let formConfigRes = CompanyDocumentConfigurationInitForm(t);

      let stepList = [];
      let newForm = {};
      let newFormResp = {};
      let newFormConfig = {};
      // let activeStep = 0;

      if (stepRes && stepRes.data && formConfigRes && formConfigRes.data) {
        //partial hardcore, find ways to completely detach from code

        let customData = {
          customFunctionLibrary: customFunctionLibrary,
        };
        stepList = BaseMultiStepConfigToStepNavModel(stepRes.data);

        // init form and active step
        if (
          !isLangChange ||
          (form &&
            Object.keys(form).length === 0 &&
            Object.getPrototypeOf(form) === Object.prototype)
        ) {
          newForm = currentForm?.data;
        } else {
          newForm = form;
          // activeStep = state.activeStep;
        }

        // newFormResp and formConfig
        newFormResp = formConfigRes.data;
        newFormConfig = BaseMultiStepConfigToFormModel({
          applicationId: applicationId,
          stepConfig: stepRes.data,
          sectionFieldConfig: formConfigRes.data.sections,
          customData: customData,
          t: t,
          form: newForm,
        });
      }

      setForm(newForm);
      setFormConfig(newFormConfig);
      setFormSectionConfig(newFormResp);

      setState({
        ...state,
        stepList,
        isLoading: false,
      });
    };

    loadForm();

    BaseMultiStepFormScrollToMultiStepFormMenuActiveItem(
      ".company-document-configuration-form-title"
    );

    return () => {
      setState({
        ...state,
        isLoading: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyDocumentId]);

  // Navigation and action
  const validateCurrentStep = () => {
    const isValid = !formObjRef.current.getActiveFormState({ expand: true })
      .invalid;
    setTimeout(() => {
      scrollToErrorControl();
    }, 100);
    return isValid;
  };

  const confirmSubmitApplication = async () => {
    if (validateCurrentStep()) {
      submitApplicationAction();
    } else {
      setState({
        ...state,
        touched: true,
      });
    }
  };

  const submitApplicationAction = async () => {
    try {
      // show spinner
      dispatch(showSpinner(true));
      let res = await AdminService.companyDocumentUpdateCompanyDocument({
        body: form,
      });
      if (isResponseOk(res)) {
        toast.show({
          severity: MessageSeverity.SUCCESS,
          detail: t("admin_common_submitMessageSuccess"),
          life: 3000,
        });
      } else {
        showErrorMessageModal({
          dispatch,
          t,
          content: getResponseMessage(res),
        });
      }
    } catch (e) {
      // show notification?
      console.error(e.message);
    } finally {
      // end spinner
      dispatch(showSpinner(false));
    }
  };

  function onChange({ state, changed }) {
    let effectiveTo =
      typeof state?.form?.effective_To === "string"
        ? new Date(state?.form?.effective_To)
        : state?.form?.effective_To;
    if (
      !!state?.form?.effective_To &&
      state?.form?.effective_To !== "string" &&
      state?.form?.effective_From > effectiveTo
    ) {
      setForm({ ...state?.form, effective_To: null });
      if (changed?.control?.key === "effective_To") {
        toast.show({
          severity: MessageSeverity.ERROR,
          detail: t(
            "company_document_template_configuration_invalidEffectiveTo"
          ),
          life: 3000,
        });
      }
    }
  }

  // Rendering
  const renderForm = () => (
    <BaseMultiStepForm
      form={form}
      activeStep={state.activeStep}
      config={formConfig}
      ref={formObjRef}
      touched={state.touched}
      onChange={onChange}
    />
  );

  const renderFooter = () => {
    return (
      <div className="footer p-grid p-align-center p-justify-between">
        <Button
          label={t("admin_common_actionCancel")}
          className="p-button-secondary"
          onClick={() =>
            history.push(
              PortalLink(
                `${MODULE_CODE.Admin}/${FUNCTION_CODE.CompanyDocumentConfiguration}`
              )
            )
          }
        />
        <div className="right-button-group">
          <Button
            onClick={() => confirmSubmitApplication()}
            className="p-button next-button"
          >
            {t("admin_common_actionSubmit")}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className=" admin-form-style  company-document-configuration">
        {state.isLoading ? (
          <div className="form-loading">{BaseMultiStepFormSkeleton}</div>
        ) : (
          renderForm()
        )}

        {/* Footer */}
        {renderFooter()}
      </div>
    </>
  );
};

export default CompanyDocumentConfigurationForm;
