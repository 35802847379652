import "./view-history.scss";

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import moment from "moment";

import { setBcDynamicPaths } from "../../../../../redux/actions/breadcrumb";
import { RentalDetailsService } from "./../../../../../services/hrmnet-api/index";
import { formSkeleton } from "../../utils";
import LanguageLoader from "../../../../../components/language-loader/language-loader";
import { RENTAL_T_CONTEXT_KEY } from "../../constants";
import { EEPORTAL_LABEL_BASE_TABLE } from "../../../../../constants";
import BaseTable, {
  BUILD_SEARCH_CONFIG,
} from "../../../../../components/base-table/base-table-portal-rental";
import {
  DEFAULT_BT_SEARCH,
  getBTConfig,
  getColumModel,
} from "../../../../../components/base-table/base-table-model";
import { openModal } from "../../../../../redux/actions/modal";
import { showSpinner } from "../../../../../redux/actions/spinner";

const DATETIME_FORMAT = "yyyy-MM-DD HH:mm";
const FILTERED_FIELDS = {
  EssRentalClaim: [
    "ApproverComment",
    "ApproverCommentList",
    "ClaimablePercent",
    "CopiedClaimId",
    "CurrencyId",
    "Declaration",
    "DeclareRsTick",
    "DeclareSpouseTick",
    "DeclareSubmitClaimTick",
    "DeclareTick",
    "DeclareUploadDocTick",
    "ElasticData",
    "EligibleCurrency",
    "EmpComment",
    "GeneralComment",
    "JointColleagueList",
    "JointDeclareTick",
    "LeaseAgrmtTick",
    "RatesGorvernmentRents",
    "ReceiptDoc",
  ],
  EssRentalMonthlyReceipt: [
    "RentalClaimId",
    "EmployeeCode",
    "DisplaySequence",
    "EmployeeComment",
    "ApproverComment",
    "PostingStatus",
  ],
};

const ViewRentalApplicationHistory = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();

  // default search
  const BT_SEARCH = {
    ...DEFAULT_BT_SEARCH,
  };

  // init
  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );

  const [state, setState] = useState({
    isLoading: true,
    data: [],
    detailData: null,
  });

  // data
  useEffect(() => {
    const loadApplicationHistory = async () => {
      const applicationId = props.match.params.applicationId;
      const api = RentalDetailsService.rentalGetApplicationLogSummary({
        applicationId,
      });
      let [res] = await Promise.all([api]);

      if (res.data) {
        setState((state) => ({
          ...state,
          data: res.data,
          isLoading: false,
        }));
      }
    };
    loadApplicationHistory();
  }, [selectedLangKey]);

  const loadHistoryDetail = async (logId) => {
    try {
      dispatch(showSpinner(true));
      const applicationId = props.match.params.applicationId;
      const api = RentalDetailsService.rentalGetApplicationLogDetail({
        applicationId,
        logId,
      });
      let [res] = await Promise.all([api]);

      if (res.data) {
        // filter fields
        let filteredData = res.data.reduce((a, r) => {
          if (!Object.keys(FILTERED_FIELDS).includes(r.objectType)) {
            a.push(r);
          } else if (!FILTERED_FIELDS[r.objectType].includes(r.propertyName)) {
            a.push(r);
          }
          return a;
        }, []);

        setState((state) => ({
          ...state,
          detailData: filteredData,
          isLoading: false,
        }));

        dispatch(showSpinner(false));
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(showSpinner(false));
    }
  };

  // breadcrumb
  useEffect(() => {
    dispatch(
      setBcDynamicPaths([{ label: t("rental_viewHistory_breadcrumb") }])
    );
  }, [dispatch, t]);

  // footer
  const backPage = () => {
    history.goBack();
  };

  const printPage = () => {
    var head = document.head.innerHTML;
    var content = document.getElementsByClassName("main-content")[0].innerHTML;

    var printWindow = window.open("", "");
    printWindow.document.write(
      "<html>" + head + "<body>" + content + "</body></html>"
    );
    printWindow.document.close();
    printWindow.focus();
    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 200);
  };

  // table
  const dataConfigColumns = [
    getColumModel({
      header: t("rental_viewHistory_operation"),
      key: "operation",
      dataType: "string",
      width: 10,
      isRowExpansion: false,
      isHeaderExpansion: true,
      hideMobile: false,
      hideInMobile: false,
      sortable: false,
    }),
    getColumModel({
      header: t("rental_viewHistory_actionType"),
      key: "actionType",
      dataType: "string",
      width: 10,
      isRowExpansion: true,
      isHeaderExpansion: true,
      hideMobile: false,
      hideInMobile: false,
      sortable: false,
    }),
    getColumModel({
      header: t("rental_viewHistory_actionBy"),
      key: "actionBy",
      dataType: "string",
      width: 10,
      isRowExpansion: true,
      isHeaderExpansion: true,
      hideMobile: false,
      hideInMobile: false,
      sortable: false,
    }),
    getColumModel({
      header: t("rental_viewHistory_actionDate"),
      key: "actionDate",
      dataType: "string",
      width: 10,
      isRowExpansion: true,
      isHeaderExpansion: true,
      hideMobile: false,
      hideInMobile: false,
      sortable: false,
      render: (data) =>
        data.actionDate && moment(data.actionDate).isValid()
          ? moment(data.actionDate).format(DATETIME_FORMAT)
          : data.actionDate,
    }),
  ];

  const detailConfigColumns = [
    getColumModel({
      header: t("rental_viewHistory_objectType"),
      key: "objectType",
      dataType: "string",
      width: 10,
      isRowExpansion: false,
      isHeaderExpansion: true,
      hideMobile: false,
      hideInMobile: false,
      sortable: false,
    }),
    getColumModel({
      header: t("rental_viewHistory_propertyName"),
      key: "propertyName",
      dataType: "string",
      width: 10,
      isRowExpansion: true,
      isHeaderExpansion: true,
      hideMobile: false,
      hideInMobile: false,
      sortable: false,
    }),
    getColumModel({
      header: t("rental_viewHistory_oldValue"),
      key: "oldValue",
      dataType: "string",
      width: 10,
      isRowExpansion: true,
      isHeaderExpansion: true,
      hideMobile: false,
      hideInMobile: false,
      sortable: false,
    }),
    getColumModel({
      header: t("rental_viewHistory_newValue"),
      key: "newValue",
      dataType: "string",
      width: 10,
      isRowExpansion: true,
      isHeaderExpansion: true,
      hideMobile: false,
      hideInMobile: false,
      sortable: false,
    }),
  ];

  const dataConfigModel = getBTConfig({
    title: t("rental_viewHistory_dataTableTitle"),
    // description: "",
    columns: dataConfigColumns,
    actionsSingle: [
      {
        isOverflow: false,
        name: "View_History_Detail",
        // title: t("rental_action_ViewHistoryDetail"),
        renderIcon: (
          <div className="view-detail-btn-text">
            {t("rental_viewHistory_viewDetail")}
          </div>
        ),
        clickFn: (item) => loadHistoryDetail(item.id),
      },
    ],
    actionsSingleConfig: {
      isRowExpansion: false,
      isHeaderExpansion: true,
    },
    actionWidth: 5.3,
    hasIndex: false,
    mode: "list",
    defaultMode: "list",
    showGlobal: true,
  });

  const detailConfigModel = getBTConfig({
    // title: "",
    // description: "",
    columns: detailConfigColumns,
    hasIndex: false,
    mode: "list",
    defaultMode: "list",
    showGlobal: true,
  });

  const dataTableSearchFn = async (searchConfig) => {
    if (!searchConfig) {
      searchConfig = { ...BT_SEARCH };
    }
    searchConfig = BUILD_SEARCH_CONFIG(searchConfig);

    const result = {
      datas: state.data,
      total: state.data.length,
      searchConfig: searchConfig,
    };

    return result;
  };

  const detailTableSearchFn = async (searchConfig) => {
    if (!searchConfig) {
      searchConfig = { ...BT_SEARCH };
    }
    searchConfig = BUILD_SEARCH_CONFIG(searchConfig);

    const result = {
      datas: state.detailData,
      total: state.detailData.length,
      searchConfig: searchConfig,
    };

    return result;
  };

  // modal
  useEffect(() => {
    if (state.detailData?.length >= 0) {
      dispatch(
        openModal({
          title: t("rental_viewHistory_detailTitle"),
          renderModalBody: () => (
            <BaseTable
              // ref={dataTable}
              configModel={detailConfigModel}
              searchConfig={BT_SEARCH}
              searchFn={detailTableSearchFn}
              isClientSize={true}
            />
          ),
          classNameMainDialog:
            "confirm-message-modal view-history-detail-table-container",
          hidePrimary: true,
          hideSecond: true,
        })
      );
    }
  }, [state.detailData]);

  // render
  const renderPageTitle = () => (
    <div className="header">
      <div className="page-title">
        <h2>{t("rental_viewHistory_pageTitle")}</h2>
      </div>
    </div>
  );

  const renderFormSkeleton = () => formSkeleton;

  const renderHistoryTable = () => {
    if (state.isLoading) return;

    return (
      <div className="view-history-table-container">
        <BaseTable
          // ref={dataTable}
          configModel={dataConfigModel}
          searchConfig={BT_SEARCH}
          searchFn={dataTableSearchFn}
          isClientSize={true}
        />
      </div>
    );
  };

  const renderFooter = () => (
    <div className="footer p-grid p-align-center p-justify-between">
      <Button
        onClick={() => backPage()}
        className="p-button-outlined secondary"
      >
        {t("rental_common_actionBack")}
      </Button>
      <Button onClick={() => printPage()} className="p-button next-button">
        {t("rental_common_actionPrint")}
      </Button>
    </div>
  );

  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          RENTAL_T_CONTEXT_KEY.COMMON,
          RENTAL_T_CONTEXT_KEY.VIEW_HISTORY,
        ]}
      />
      <div className="rental-view-history">
        {/* Page Title */}
        {!state.isLoading && renderPageTitle()}

        {/* Table */}
        {state.isLoading ? renderFormSkeleton() : renderHistoryTable()}

        {/* Footer */}
        {state.isLoading ? renderFormSkeleton() : renderFooter()}
      </div>
    </>
  );
};

export default ViewRentalApplicationHistory;
