import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "primereact/button";

import { FormControlsGenerator } from "./utils";
import SectionWithDataArray from "./section-with-data-array";
import BaseForm from "./../../../../../../components/base-form/base-form";

const DataArrayWithSections = (props) => {
  // TODO - the masking is dynamic, we need to update this to use Map()
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map
  const [mapMasked, setMapMasked] = useState(new Map([]));
  const { t } = useTranslation();
  const tab = [];
  const sectionWithChild = props.sectionWithChild ?? [];
  const i18nConstant = props.i18nConstant ?? "";
  if (!props.cData || props.cData.length === 0) {
    if (props.readOnly) {
      return <div style={{ padding: "1rem" }}>{props.noDataLabel}</div>;
    }
    return null;
  }

  const onStateChange = (key, state) => {
    let newMap = { ...mapMasked };
    newMap[key] = state;
    setMapMasked(newMap);
  };

  props.cData.forEach((data, index) => {
    if (mapMasked[index + "-close"] === undefined) {
      onStateChange(index + "-close", true);
    }

    tab.push(
      <div key={index}>
        <div
          className="c-group-sub-title"
          onClick={() => {
            onStateChange(index + "-close", !mapMasked[index + "-close"]);
          }}
        >
          {`${props.panelTitle} (${index + 1})`}

          <div className="c-group-sub-tile-action">
            <Button
              type="button"
              icon={
                mapMasked[index + "-close"]
                  ? "pi pi-chevron-down"
                  : "pi pi-chevron-up"
              }
              className="p-button-text"
            />
          </div>
        </div>
        <div
          className={`${
            mapMasked[index + "-close"] ? "hide-panel" : ""
          } c-group-panel-section-data-array`}
        >
          {props.sections.map((section, iSection) => {
            const structureSettings = FormControlsGenerator(
              section.fields,
              props.hideTooltip,
              mapMasked,
              setMapMasked,
              props.enumData,
              t,
              i18nConstant,
              index
            );

            const formConfig = props.formStructure(structureSettings);

            return (
              <div id={index} key={index + iSection}>
                <div className="c-group-title ep-language-toogle">
                  <p>
                    {t(
                      i18nConstant +
                        section.sectionName
                          .replace("-", "_")
                          .replace(/ /g, "")
                          .toLowerCase()
                    )}
                  </p>
                </div>
                <div className="c-group-panel-section-data-array">
                  <div className="c-group-panel-section-data-array">
                    {sectionWithChild.findIndex(
                      (x) => x.sectionCode === section.sectionCode
                    ) > -1 ? (
                      // <></>
                      <SectionWithDataArray
                        currIndex={index}
                        noDataLabel={t("common_noData")}
                        cData={
                          sectionWithChild.find(
                            (x) => x.sectionCode === section.sectionCode
                          ).dataKey === "all"
                            ? data
                            : data?.[
                                sectionWithChild.find(
                                  (x) => x.sectionCode === section.sectionCode
                                ).dataKey
                              ]
                        }
                        readOnly={props.readOnly}
                        panelTitle={t(
                          i18nConstant +
                            section.sectionName
                              .replace("-", "_")
                              .replace(/ /g, "")
                              .toLowerCase()
                        )}
                        formStructure={props.formStructure}
                        fields={section.fields}
                        hideTooltip={props.hideTooltip}
                        enumData={props.enumData}
                        i18nConstant={i18nConstant}
                      />
                    ) : (
                      <BaseForm
                        id={index}
                        config={formConfig}
                        form={data}
                        readOnly={props.readOnly}
                      />
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  });
  return tab;
};

export default DataArrayWithSections;
