import "./calendar-view-team.scss";

//components
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBcDynamicPaths } from "../../../../../redux/actions/breadcrumb";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Button } from "primereact/button";
import { useHistory } from "react-router";

import { Calendar, Legend } from "../../components";
import {
  DATE_LEAVE_STATUS,
  DATE_TYPE_CONFIG,
  LEAVE_WORKFLOW_STATUS,
  LEAVE_T_CONTEXT_KEY,
  DATE_FORMAT,
  DATE_TYPE,
} from "../../constants/contants";
import { isDateRangeOverlap, isResponseOk } from "../../../../../utils/utils";
import { BCType } from "../../../../../components/base-control/base-control";
import BaseForm from "../../../../../components/base-form/base-form";
import { getAllControls, initFormWithLeaveApplication } from "./config-helper";
import { LeaveService } from "../../../../../services/hrmnet-api";
import { openModal } from "../../../../../redux/actions/modal";
import { showSpinner } from "../../../../../redux/actions/spinner";
import {
  EEPORTAL_LABEL_BASE_TABLE,
  FUNCTION_CODE,
  LEAVE_SUBMODULE_CODE,
} from "../../../../../constants";
import LanguageLoader from "../../../../../components/language-loader/language-loader";
import { showTimestampToastSuccess } from "../../../../../services/utils/message";
import {
  confirmApproveApplication,
  confirmRejectApplication,
} from "../approval/helper";
import MainLoading from "../../../../layouts/components/main-loading/main-loading";
import { PortalLink } from "../../../../../services/utils";

const TeamCalendarView = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // If specified applicationId
  const viewMode = !!props.application;
  const approverMode = props.enableApproval;
  const isAllowApproveReject =
    !!props.application && props.application?.isAllowApproveReject;

  const initViewDate = viewMode
    ? new Date(props.application.leaveFrom)
    : new Date();
  const initSelectedDate = viewMode ? initViewDate : null;

  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );

  const [state, setState] = useState({
    isLoading: true,
    selectedDate: initSelectedDate,
    selectedApplicationList: [],
    selectedApplicationIndex: 0,
    selectedLeaveType: null,
    viewDate: initViewDate,
    dateConfig: {},
  });

  const [form, setForm] = useState({});

  useEffect(() => {
    if (approverMode) {
      dispatch(
        setBcDynamicPaths([
          { label: t("leave_approval_approveApplication_breadcrumb") },
        ])
      );
    } else if (viewMode) {
      dispatch(
        setBcDynamicPaths([{ label: t("leave_viewApplication_breadcrumb") }])
      );
    } else {
      dispatch(
        setBcDynamicPaths([{ label: t("leave_calendarView_breadcrumb") }])
      );
    }
  }, [dispatch]);

  useEffect(() => {
    const loadDateConfig = async () => {
      const dateConfig = await getCalendarConfigOfMonth(state.viewDate);
    };
    loadDateConfig();
  }, [state.viewDate]);

  //
  useEffect(() => {
    if (viewMode) {
      const _form = initFormWithLeaveApplication(props.application);
      setForm(_form);
    } else {
      const application = state.selectedApplicationList.find(
        (x) => x.leaveCode === form["LeaveCode"]
      );
      if (application) {
        const _form = initFormWithLeaveApplication(application);
        setForm(_form);
      }
    }
  }, [
    state.selectedApplicationList,
    form["LeaveCode"],
    props.application,
    selectedLangKey,
  ]);

  // const getApplicationByDate = async (date) => {
  //   const day = date.getDate();
  //   const month = date.getMonth() + 1;
  //   const year = date.getFullYear();

  //   let res = await LeaveService.leaveGetApplications({ year, month, day });

  //   if (res && res.data) {
  //     setState((state) => ({
  //       ...state,
  //       selectedApplicationList: res.data,
  //     }));
  //     return res.data;
  //   }

  //   return null;
  // };

  const onSelectDate = async (date) => {
    if (!viewMode || approverMode) {
      setState((state) => ({
        ...state,
        selectedDate: date,
        selectedApplicationList: [],
      }));

      // No need to fetch application of the day with API anymore

      // const applications = await getApplicationByDate(date);
      // if (applications?.length) {
      //   setForm((form) => ({
      //     ...form,
      //     LeaveCode: applications[0].leaveCode,
      //   }));
      // }
    }
  };

  const onChangeViewDate = (e) => {
    setState((state) => ({
      ...state,
      viewDate: e.value,
    }));
  };

  // Fetch Data
  const getCalendarConfigOfMonth = async (date) => {
    setState((state) => ({
      ...state,
      applicationCalendar: {},
      dateConfig: {},
      isLoadingCalendar: true,
    }));

    const res = await LeaveService.leaveGetTeamTransactions({
      body: {
        reportingLine: "ALL", // TODO: appit tmp ask frontend to hardcode
        year: date.getFullYear(),
        fromMonth: date.getMonth() + 1,
        toMonth: date.getMonth() + 1,
        viewingApplicationId: props.application?.applicationId,
      },
    });

    if (!isResponseOk(res)) return;

    let dateRes = await LeaveService.leaveGetDateTypeList({
      employeeCode: props.application?.employeeCode,
      fromDate: moment(date).startOf("month").format(DATE_FORMAT),
      toDate: moment(date).endOf("month").format(DATE_FORMAT),
    });

    if (!isResponseOk(dateRes)) return;

    // contruct date config and application calendar
    let calendarDateConfig = {};
    let applicationCalendar = {};

    if (dateRes && dateRes.data) {
      calendarDateConfig = dateRes.data.reduce((acc, curr, idx) => {
        const day = new Date(curr.targetDate).getDate();
        acc[day] = { ...DATE_TYPE_CONFIG[curr.dateType] };
        if (
          curr.dateType === DATE_TYPE.HOLIDAY ||
          curr.dateType === DATE_TYPE.STATUTORY
        ) {
          acc[day].holidayName = curr.holidayName;
        }
        return acc;
      }, {});
    }

    res.data.employees.forEach((employee) => {
      const monthIndex = Object.keys(employee.calendar).findIndex(
        (month) => month === moment(date).format("MMM")
      );
      if (
        employee.calendar &&
        Object.keys(employee.calendar).length &&
        monthIndex > -1
      ) {
        // Populate dateConfig and applications
        Object.entries(
          employee.calendar[Object.keys(employee.calendar)[monthIndex]]
        ).forEach(([date, datas]) => {
          if (!datas.length) return;
          const data = datas[0];
          const day = new Date(date).getDate();
          // populate base date config
          if (!(day in calendarDateConfig)) {
            // calendarDateConfig[day] = { ...DATE_TYPE_CONFIG[data.dateCode] };
          }
          // populate application
          if (data.leaveRecord) {
            // if approver and application is not relavant, return
            console.log("dateFrom", new Date(data.dateFrom));
            console.log("dateTo", new Date(data.dateTo));

            console.log(
              "props.application.leaveFrom",
              new Date(props.application.leaveFrom)
            );
            console.log(
              "props.application.leaveTo",
              new Date(props.application.leaveTo)
            );
            console.log(
              "isOverlap",
              isDateRangeOverlap(
                new Date(data.dateFrom),
                new Date(data.dateTo),
                new Date(props.application.leaveFrom),
                new Date(props.application.leaveTo)
              )
            );
            if (
              approverMode &&
              !isDateRangeOverlap(
                new Date(data.dateFrom),
                new Date(data.dateTo),
                new Date(props.application.leaveFrom),
                new Date(props.application.leaveTo)
              )
            ) {
              return;
            }
            // set mark
            calendarDateConfig[day].showMark = true;
            // add application
            const applicationDetail = {
              employeeName: employee.employeeName,
              leaveType: data.leaveRecord.leaveType,
              transactionType: data.leaveRecord.transactionType,
              status: data.leaveRecord.status,
              leaveFrom: data.dateFrom,
              leaveTo: data.dateTo,
            };
            if (
              props.application.referenceNo !== data.leaveRecord.referenceNo
            ) {
              if (day in applicationCalendar) {
                applicationCalendar[day].push(applicationDetail);
              } else {
                applicationCalendar[day] = [applicationDetail];
              }
            }
          }
        });
      }
    });

    setState((state) => ({
      ...state,
      dateConfig: calendarDateConfig,
      applicationCalendar: applicationCalendar,
      isLoading: false,
      isLoadingCalendar: false,
    }));
  };

  // action

  // Back
  const backToList = () => {
    history.goBack();
    // history.push(`/${AppConstant.PORTAL}/${FUNCTION_CODE.Leave}`);
  };

  // cancel
  const cancelApplicationModal = () => {
    dispatch(
      openModal({
        title: t("leave_viewApplication_cancelTitle"),
        content: t("leave_viewApplication_cancelMessage", {
          date: moment(initViewDate).format("YYYY-MM-DD"),
        }),
        classNameMainDialog: "confirm-message-modal form-modal",
        primaryButtonText: t("leave_common_actionYes"),
        secondaryButtonText: t("leave_common_actionNo"),
        form: {
          config: {
            controls: [
              {
                key: "Remarks", // custom field
                label: t("leave_viewApplication_cancelReason"),
                type: BCType.input,
              },
            ],
          },
          form: {},
        },
        primaryButtonClickFn: async ({ closeFn, form }) => {
          await cancelApplication({
            applicationId: props.application?.applicationId,
            remarks: form["Remarks"],
          });
          closeFn();
        },
        secondButtonClickFn: ({ closeFn }) => {
          closeFn();
        },
        force_touched: true,
      })
    );
  };

  const cancelApplication = async ({ applicationId, remarks }) => {
    try {
      dispatch(showSpinner(true));
      const res = await LeaveService.leaveCancelLeaveApplication({
        applicationId,
        remarks,
      });
      if (isResponseOk(res)) {
        backToList();
        showTimestampToastSuccess({
          message: t("leave_calendarView_cancelSuccess"),
          t: t,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(showSpinner(false));
    }
  };

  const redirectToSubmit = () => {
    history.push(
      PortalLink(
        `${FUNCTION_CODE.Leave}/${LEAVE_SUBMODULE_CODE.SUBMIT_APPLICATION}`
      )
    );
  };

  const redirectToLeaveBalance = () => {
    history.push(
      PortalLink(`${FUNCTION_CODE.Leave}/${LEAVE_SUBMODULE_CODE.BALANCE}`)
    );
  };

  // const renderLeaveTypeLegend = () => {
  //   if (state.isLoading) return null;
  //   if (!state.selectedApplicationList.length) return null;

  //   const leaveCodeSet = {};
  //   state.selectedApplicationList.forEach((app) => {
  //     leaveCodeSet[app.leaveCode] = {
  //       leaveCode: app.leaveCode,
  //       leaveType: app.leaveType,
  //       webColor: app.webColor,
  //     };
  //   });

  //   return (
  //     <div className="legend-list">
  //       <div className="section-title">
  //         {t("leave_calendarView_approvedLeaveLegend", {
  //           date: moment(state.selectedDate).format("D MMMM YYYY"),
  //         })}
  //       </div>
  //       {Object.values(leaveCodeSet).map((app, idx) => (
  //         <Legend
  //           key={idx}
  //           legend="square"
  //           legendColor={app.webColor}
  //           text={`${app.leaveCode} - ${app.leaveType}`}
  //           textColor={app.webColor}
  //         />
  //       ))}
  //     </div>
  //   );
  // };

  const renderDateLegend = () => {
    if (state.isLoading) {
      return null;
    } else {
      return (
        <>
          <div className="legend-list">
            <div className="section-title">
              {t("leave_calendarView_dateLegend")}
            </div>
            {Object.keys(DATE_LEAVE_STATUS).map((key, idx) => (
              <Legend
                key={idx}
                legend="round"
                legendColor={DATE_LEAVE_STATUS[key].color}
                // text={t(DATE_LEAVE_STATUS[key].label)}
                text={t("leave_calendarView_legendRemarks")}
              />
            ))}
            {Object.keys(state.dateConfig).map((day) =>
              Object.keys(state.dateConfig[day]).includes("holidayName") ? (
                <div className="custom-legend statutory-legend">
                  {moment(state.viewDate).date(day).format("MMM D")} -{" "}
                  {state.dateConfig[day]["holidayName"]}
                </div>
              ) : null
            )}
          </div>
        </>
      );
    }
  };

  const renderEmployeeLeaveList = () => {
    let applications = state.applicationCalendar?.[
      state.selectedDate.getDate()
    ]?.sort((a, b) => a.employeeName > b.employeeName);

    return (
      <div className="application-list">
        <div className="application-list-date">
          {t("leave_calendarView_otherApplicationsOn")}{" "}
          {moment(state.selectedDate).format("MMM DD, yyyy")}
        </div>
        {applications?.length ? (
          applications.map((app) => (
            <div className="application-list-entry">
              <div>{app.employeeName}</div>
              <div>{app.leaveType}</div>
              <div>{app.transactionType}</div>
              <div>
                {moment(app.leaveFrom).format("yyyy-MM-DD h:mm A")} -{" "}
                {moment(app.leaveTo).format("yyyy-MM-DD h:mm A")}
              </div>
              <div>{app.status}</div>
            </div>
          ))
        ) : (
          <div className="application-list-entry">No Result</div>
        )}
      </div>
    );
  };

  const renderApplicationMeta = () => {
    if (state.isLoading) return null;

    const application =
      props.application ||
      state?.selectedApplicationList?.find(
        (x) => x.leaveCode === form["LeaveCode"]
      );

    if (!application) {
      return (
        <div className="no-leave-placeholder">
          {t("leave_calendarView_noLeaves")}
        </div>
      );
    }
    const _form = initFormWithLeaveApplication(application);

    const metaControls = [
      ...(!viewMode || approverMode
        ? [
            {
              key: "$Employee", // custom field
              label: t("leave_calendarView_employee"),
              type: BCType.input,
            },
          ]
        : []),
      {
        key: "$RefNo", // custom field
        label: t("leave_calendarView_refNo"),
        type: BCType.input,
      },
      {
        key: "$Status", // custom field
        label: t("leave_calendarView_status"),
        componentRender: () => (
          <span className="p-ml-1">{application.status}</span>
        ),
      },
    ];
    return (
      <div className="leave-application-meta">
        {/* Generl Meta */}
        <BaseForm
          config={{
            controls: metaControls,
          }}
          form={_form}
          readOnly
        ></BaseForm>
      </div>
    );
  };

  const renderApplicationDetail = () => {
    console.log("render detail");
    let application;
    let leaveTypes;
    if (viewMode) {
      application = props.application;
      leaveTypes = [application];
    } else {
      application = state?.selectedApplicationList?.find(
        (x) => x.leaveCode === form["LeaveCode"]
      );
      leaveTypes = [...state.selectedApplicationList];
    }

    if (state.isLoading || !application) {
      return null;
    }

    if (application) {
      const controls = getAllControls({
        leaveTypes,
        application: application,
        leaveCodeReadOnly: viewMode,
        hideBalance: !viewMode || approverMode,
        onSeeBalanceDetail: redirectToLeaveBalance,
        t,
      });

      // render
      return (
        <div className="leave-application-detail">
          <BaseForm
            id="leave-application-detail"
            config={{
              controls,
            }}
            form={form}
            onChange={(state) => {
              setForm((form) => ({
                ...form,
                ...state.form,
              }));
            }}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  const renderHeader = () => {
    if (approverMode) {
      return (
        <div className="title">
          {t("leave_approval_approveApplicationTitle")}
        </div>
      );
    } else if (viewMode) {
      return (
        <>
          <div className="title">{t("leave_viewApplication_title")}</div>
          <Button className="p-button" onClick={redirectToSubmit}>
            {t("leave_viewApplication_addNewApplication")}
          </Button>
        </>
      );
    } else {
      return <div className="title">{t("leave_calendarView_title")}</div>;
    }
  };

  const renderFooter = () => (
    <div className="footer p-grid p-align-center p-justify-between">
      <Button
        onClick={() => history.goBack()}
        className="p-button-outlined secondary"
      >
        {t("leave_common_actionBack")}
      </Button>
      {approverMode && isAllowApproveReject && (
        <div className="right-button-group">
          <Button
            onClick={() => {
              confirmApproveApplication({
                applicationId: props.application.applicationId,
                date: new Date(props.application.leaveFrom),
                dispatch,
                history,
                t,
              });
            }}
            className="p-button"
          >
            {t("leave_common_actionApprove")}
          </Button>
          <Button
            onClick={() => {
              confirmRejectApplication({
                applicationId: props.application.applicationId,
                date: new Date(props.application.leaveFrom),
                dispatch,
                history,
                t,
              });
            }}
            className="p-button"
          >
            {t("leave_common_actionReject")}
          </Button>
        </div>
      )}
      {!approverMode && viewMode && props?.application?.cancellable && (
        <Button onClick={cancelApplicationModal} className="p-button">
          {t("leave_viewApplication_cancelApplication")}
        </Button>
      )}
    </div>
  );

  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          LEAVE_T_CONTEXT_KEY.COMMON,
          LEAVE_T_CONTEXT_KEY.CALENDAR_VIEW,
          LEAVE_T_CONTEXT_KEY.FORM,
        ]}
      />
      <div className="leave-container leave-calendar-view">
        <div className="header">{renderHeader()}</div>
        <div className="main">
          <div className="main-l">
            <div className="main-l-container">
              {state.isLoadingCalendar && (
                <div className="calendar-spinner">
                  <MainLoading />
                </div>
              )}
              <div className="leave-calendar">
                <Calendar
                  value={state.selectedDate}
                  inline
                  onChange={(e) => onSelectDate(e.value)}
                  dateConfig={state.dateConfig}
                  viewDate={state.viewDate}
                  onViewDateChange={onChangeViewDate}
                />
              </div>
              {/* {renderLeaveTypeLegend()} */}
              {renderDateLegend()}
              {renderEmployeeLeaveList()}
            </div>
          </div>
          <div className="main-r">
            {renderApplicationMeta()}
            {renderApplicationDetail()}
          </div>
        </div>
        <div className="footer">{renderFooter()}</div>
      </div>
    </>
  );
};

export default TeamCalendarView;
