import { Animated } from "react-animated-css";

import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Skeleton } from "primereact/skeleton";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ApplicationStatus } from ".";
import { FUNCTION_CODE, RENTAL_SUBMODULE_CODE } from "../../../../constants";

import "./rental-application-list.scss";

// component
import BaseTable from "../../../../components/base-table/base-table-portal-rental";
import { getControlModel } from "../../../../components/base-control/base-cotrol-model";
import {
  DEFAULT_BT_SEARCH,
  getBTConfig,
  getColumModel,
} from "../../../../components/base-table/base-table-model";
import { useWindowSize } from "../../utils/window-size/useWindowSize";

// services
import {
  EmployeeDetailsService,
  RentalDetailsService,
} from "../../../../services/hrmnet-api";
import { resetBcDynamicPaths } from "../../../../redux/actions/breadcrumb";
import { isResponseOk } from "../../../../utils/utils";
import { showTimestampToastSuccess } from "../../../../services/utils/message";
import { PortalLink } from "../../../../services/utils";
import { BCType } from "../../../../components/base-control/base-control";
import { Dropdown } from "primereact/dropdown";
import { TabMenu } from "primereact/tabmenu";
import BaseForm from "../../../../components/base-form/base-form";
import { Card } from "primereact/card";
import { Button } from "primereact/button";

const RentalAllViewApplications = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  let history = useHistory();
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const dataTable = useRef();
  const [state, setState] = useState({
    tableConfig: null,
    detailExpandedRows: null,
    form: null,
  });
  const [employeeNameEnum, setEmployeeNameEnum] = useState([]);
  const [data, setData] = useState([]);
  const [taxYear, setTaxYear] = useState([]);
  const [taxYearFilterIndex, setTaxYearFilterIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [employeeCodeFilterValue, setEmployeeCodeFilterValue] = useState([]);

  const mountedRef = useRef(true);

  useEffect(() => {
    dispatch(resetBcDynamicPaths());
  }, [dispatch]);

  const getApplicationList = async () => {
    var cmd1 = await RentalDetailsService.rentalGetAllApplicationList();
    const [res1] = await Promise.all([cmd1]);
    setData(res1);
    return res1;
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        var cmd1 = getApplicationList();
        var cmd2 = EmployeeDetailsService.employeeGetEmployeeList();
        const [res1, res2] = await Promise.all([cmd1, cmd2]);
        if (Array.isArray(res1)) {
          const uniqueTaxYearList = [
            ...new Set(
              res1?.map((data) => (data.taxYear ? data.taxYear : "-"))
            ),
          ]
            .sort()
            .reverse()
            .map((year) => ({ label: year, value: year }));
          setTaxYear(uniqueTaxYearList);
        }

        if (res2) {
          const _employeeNameEnum = res2.map((result) => {
            return {
              label: result.name,
              value: result.value,
            };
          });
          setEmployeeNameEnum(_employeeNameEnum);
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
    return () => {
      setTaxYearFilterIndex(0);
      setIsLoading(true);
      mountedRef.current = false;
    };
  }, []);

  // onTabChange
  useEffect(() => {
    if (dataTable.current) dataTable.current.reload();
  }, [taxYearFilterIndex, employeeCodeFilterValue, isLoading]);

  // default search
  const BT_SEARCH = {
    ...DEFAULT_BT_SEARCH,
    ps: 50,
    // searchFormData: employeeCodeFilterValue,
  };

  const tableSearchFn = async (isDataUpdated) => {
    let _data = [];
    if (isLoading) {
      return new Promise(() => {});
    } else {
      try {
        let currentData = data;
        if (isDataUpdated) {
          var cmd1 = await getApplicationList();
          const [res1] = await Promise.all([cmd1]);
          currentData = res1;
        }

        _data = currentData.filter(
          // tmp special handle for bad data
          (data) =>
            data.taxYear ===
              (taxYear[taxYearFilterIndex].label === "-"
                ? undefined
                : taxYear[taxYearFilterIndex].label) &&
            (employeeCodeFilterValue.length > 0
              ? employeeCodeFilterValue.includes(data.employeeCode)
              : true)
        );
      } catch (error) {
      } finally {
        return {
          datas: _data,
          searchConfig: BT_SEARCH,
          total: _data?.length,
        };
      }
    }
  };

  const onActionClick = (subModule) => {
    history.push(PortalLink(`${FUNCTION_CODE.Rental}/${subModule}`));
  };

  const reOpenApplication = async (applicationId) => {
    let res = await RentalDetailsService.rentalReOpenApplication({
      applicationId,
    });
    if (isResponseOk(res)) {
      showTimestampToastSuccess({
        message: t("rental_reOpenApplication_successMessage"),
        t: t,
      });
      dataTable.current.reload(true);
    }
  };

  const configColumns = [
    getColumModel({
      header: t("rental_datatable_ViewApplications_application_id"),
      key: "claimId",
      dataType: "string",
      config: {
        sortable: true,
      },
      isRowExpansion: false,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t(
          "rental_datatable_ViewApplications_application_id_placeholder"
        ),
      }),
      frozen: true,
    }),
    getColumModel({
      header: t("rental_datatable_ViewApplications_sequence_number"),
      key: "sequenceNumber",
      dataType: "string",
      config: {
        sortable: true,
      },
      mobileWidthAuto: true,
      isRowExpansion: true,
      control: getControlModel({
        placeholder: t(
          "rental_datatable_ViewApplications_sequence_number_placeholder"
        ),
      }),
    }),
    getColumModel({
      header: t("rental_datatable_ViewApplications_company_name"),
      key: "companyName",
      dataType: "string",
      config: {
        sortable: true,
      },
      isRowExpansion: false,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t(
          "rental_datatable_ViewApplications_company_name_placeholder"
        ),
      }),
    }),
    getColumModel({
      header: t("rental_datatable_ViewApplications_employee_name"),
      key: "employeeName",
      dataType: "string",
      config: {
        sortable: true,
      },
      isRowExpansion: false,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t(
          "rental_datatable_ViewApplications_employee_name_placeholder"
        ),
        type: BCType.multiselect,
        filterMatchMode: "in",
        // onTrueUpdateValue: (e) => {
        //   setEmployeeCodeFilterValue([
        //     {
        //       control: { key: "employeeName" },
        //       data: { value: e.value },
        //     },
        //   ]);
        // },
        // config: { filter: true },
        // enum: employeeNameEnum,
      }),
    }),
    getColumModel({
      header: t("rental_datatable_ViewApplications_taxYear"),
      key: "taxYear",
      dataType: "string",
      sortable: false,
      isRowExpansion: true,
      isHeaderExpansion: false,
      mobileWidthAuto: true,
      width: 6,
      control: getControlModel({
        placeholder: t("rental_datatable_ViewApplications_taxYear_placeholder"),
        type: BCType.multiselect,
        filterMatchMode: "in",
      }),
    }),
    getColumModel({
      header: t("rental_datatable_ViewApplications_status"),
      key: "status",
      dataType: "string",
      hideMobile: false,
      hideInMobile: true,
      isRowExpansion: false,
      control: getControlModel({
        placeholder: t("rental_datatable_ViewApplications_status_placeholder"),
        type: BCType.multiselect,
        filterMatchMode: "in",
      }),
      render: (data) => (
        <>
          <ApplicationStatus status={data.status} />
        </>
      ),
    }),
    getColumModel({
      header: t("rental_datatable_ViewApplications_application_start_date"),
      key: "applicationStartDate",
      dataType: "date",
      config: {
        dateFormat: "YYYY-MM-DD",
        sortable: true,
      },
      isRowExpansion: true,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t(
          "rental_datatable_ViewApplications_application_start_date_placeholder"
        ),
        type: "daterange",
      }),
    }),
    getColumModel({
      header: t("rental_datatable_ViewApplications_application_end_date"),
      key: "applicationEndDate",
      dataType: "date",
      config: {
        dateFormat: "YYYY-MM-DD",
        sortable: true,
      },
      isRowExpansion: true,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t(
          "rental_datatable_ViewApplications_application_end_date_placeholder"
        ),
        type: "daterange",
      }),
    }),
    getColumModel({
      header: t("rental_datatable_ViewApplications_lease_agreement_start_date"),
      key: "leaseAgreementStartDate",
      dataType: "date",
      config: {
        dateFormat: "YYYY-MM-DD",
        sortable: true,
      },
      isRowExpansion: true,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t(
          "rental_datatable_ViewApplications_agreement_start_date_placeholder"
        ),
        type: "daterange",
      }),
    }),
    getColumModel({
      header: t("rental_datatable_ViewApplications_lease_agreement_end_date"),
      key: "leaseAgreementEndDate",
      dataType: "date",
      config: {
        dateFormat: "YYYY-MM-DD",
        sortable: true,
      },
      isRowExpansion: true,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t(
          "rental_datatable_ViewApplications_lease_agreement_end_date_placeholder"
        ),
        type: "daterange",
      }),
    }),
    getColumModel({
      header: t("rental_datatable_ViewApplications_last_modified_date"),
      key: "lastModifiedDate",
      dataType: "date",
      config: {
        dateFormat: "YYYY-MM-DD",
        sortable: true,
      },
      isRowExpansion: true,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t(
          "rental_datatable_ViewApplications_last_modified_date_placeholder"
        ),
        type: "daterange",
      }),
    }),
  ];

  const taxYearTabMenu = () => {
    return (
      <div className="filter-container">
        {!isLoading && taxYear.length ? (
          isMobile ? (
            <div className="dropdown-wrapper">
              <Dropdown
                value={taxYear[taxYearFilterIndex].value}
                options={taxYear}
                onChange={(e) => {
                  setTaxYearFilterIndex(
                    taxYear.findIndex((t) => t.value === e.value)
                  );
                }}
              />
            </div>
          ) : (
            <TabMenu
              model={taxYear}
              activeIndex={taxYearFilterIndex}
              onTabChange={(e) => {
                setTaxYearFilterIndex(e.index);
              }}
            />
          )
        ) : null}
      </div>
    );
  };

  const configModel = getBTConfig({
    columns:
      (isMobile && configColumns.filter((x) => !x.isRowExpansion)) ||
      configColumns,
    actionsSingle: [
      {
        isOverflow: true,
        name: "View_Application",
        title: t("rental_action_viewApplication"),
        clickFn: (item) => {
          onActionClick(`${RENTAL_SUBMODULE_CODE.VIEW_APPLICATION}/${item.id}`);
        },
      },
      {
        isOverflow: true,
        name: "View_Application_History",
        title: t("rental_action_viewHistory"),
        clickFn: (item) => {
          onActionClick(
            `${RENTAL_SUBMODULE_CODE.VIEW_APPLICATION_HISTORY}/${item.id}`
          );
        },
      },
      {
        isOverflow: true,
        name: "ReOpen_Application",
        title: t("rental_action_reOpenApplication"),
        clickFn: (item) => {
          reOpenApplication(item.id);
        },
      },
      {
        isOverflow: true,
        name: "View_Claim",
        title: t("rental_action_viewClaim"),
        clickFn: (item) => {
          onActionClick(
            `${RENTAL_SUBMODULE_CODE.APPROVAL}/${item.id}/${RENTAL_SUBMODULE_CODE.APPROVE_CLAIM}`
          );
        },
      },
      {
        isOverflow: true,
        name: "Edit_Application",
        title: t("rental_action_editApplication"),
        clickFn: (item) => {
          history.push({
            pathname: PortalLink(
              `${FUNCTION_CODE.Rental}/${RENTAL_SUBMODULE_CODE.EDIT_APPLICATION}/${item.id}`
            ),
            state: {
              HREdit: true,
            },
          });
        },
      },
    ],
    pageSizes: [50, 150, 200, 300, 400],
    actionWidth: 5.3,
    hasIndex: false,
    mode: "list",
    defaultMode: "list",
    showGlobal: true,
    isExpandable: isMobile,
    expandedRows: state.expandedRows,
    onRowToggle: (e) => {
      let newState = {
        ...state,
        expandedRows: e.data,
      };
      setState(newState);
    },
    rowExpansionTemplate: (data) => {
      const mobileColumnsRender = [];
      configColumns
        .filter((x) => x.isRowExpansion)
        .forEach((y, index) =>
          mobileColumnsRender.push(
            <Column
              {...{
                key: `bt-sub-column-${index}`,
                field: y.key,
                header: y.header,
                style: { width: "8rem" },
                headerStyle: { width: "8rem" },
                body: (rowData, config) => {
                  if (!data) {
                    return <Skeleton></Skeleton>;
                  } else {
                    return (
                      <div
                        className={`bt-row bt-row-${config.rowIndex} bt-cell table-expandable-row`}
                      >
                        <span className="p-column-title">{y.header}:</span>
                        <div className="bt-cell-value">{rowData[y.key]}</div>
                      </div>
                    );
                  }
                },
              }}
            />
          )
        );

      return (
        <DataTable
          value={[data]}
          key="detail-mobile-table"
          className="p-datatable-mobiletable"
        >
          {mobileColumnsRender}
        </DataTable>
      );
    },
    searchForm: {
      controls: [],

      layout: {
        rows: [
          {
            columns: [
              {
                control: "employeeCode",
              },
            ],
          },
        ],
      },
    },
    customToolBar: taxYearTabMenu,
  });

  const EmployeeListFilterBaseForm = {
    controls: [
      getControlModel({
        key: "employeeCode",
        label: t("rental_datatable_ViewApplications_employee_name"),
        placeholder: t(
          "rental_datatable_ViewApplications_employee_name_placeholder"
        ),
        type: BCType.multiselect,
        filterMatchMode: "in",
        onTrueUpdateValue: (e) => {
          // setEmployeeCodeFilterValue([
          //   {
          //     control: { key: "employeeCode" },
          //     data: { value: e.value },
          //   },
          // ]);
          setEmployeeCodeFilterValue(e.value);
        },
        config: {
          filter: true,
          disabled: isLoading,
        },
        enum: employeeNameEnum,
      }),
    ],

    layout: {
      rows: [
        {
          columns: [
            {
              control: "employeeCode",
              config: {
                className: "p-col-12 p-lg-6",
              },
            },
          ],
        },
      ],
    },
  };

  function OnAddNewApplication(currentEmployee) {
    history.push({
      pathname: `${RENTAL_SUBMODULE_CODE.CREATE_APPLICATION}`,
      state: {
        employeeCode: currentEmployee.value,
        employeeName: currentEmployee.label,
      },
    });
  }

  const renderEmployeeList = useCallback(() => {
    let _EmployeeListFilterBaseForm = EmployeeListFilterBaseForm;
    if (employeeCodeFilterValue.length === 1) {
      _EmployeeListFilterBaseForm.layout.rows = [
        {
          columns: [
            {
              control: "employeeCode",
              config: {
                className: "p-col-12 p-lg-6",
              },
            },
            {
              component: () => (
                <Button
                  onClick={() =>
                    OnAddNewApplication(
                      employeeNameEnum.find(
                        (x) => x.value === employeeCodeFilterValue[0]
                      )
                    )
                  }
                  label={t("rental_ViewApplications_AddNewApplication")}
                ></Button>
              ),
              config: {
                className: "p-col-12 p-lg-6 add-new-application",
              },
            },
          ],
        },
      ];
    }
    return <BaseForm config={_EmployeeListFilterBaseForm} form={state.form} />;
  }, [state.form, EmployeeListFilterBaseForm, employeeCodeFilterValue]);

  return (
    <div className="rental-allowance-baseTable">
      <Animated
        animationIn="slideInRight"
        animationOut="slideOutRight"
        animationInDuration={200}
        animationOutDuration={200}
        isVisible={true}
      >
        <Card>{renderEmployeeList()}</Card>
        <div className="rental-datatable">
          <BaseTable
            ref={dataTable}
            configModel={configModel}
            searchConfig={BT_SEARCH}
            searchFn={tableSearchFn}
            isClientSize={true}
          />
        </div>
      </Animated>
    </div>
  );
});

export default RentalAllViewApplications;
