import "./withdraw.scss";

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { openModal } from "../../../../../redux/actions/modal";

import { setBcDynamicPaths } from "../../../../../redux/actions/breadcrumb";
import { RentalDetailsService } from "./../../../../../services/hrmnet-api/index";
import { MultiStepForm } from "../../components";
import { configToFormModel, formSkeleton, initFormValue } from "../../utils";
import {
  EEPORTAL_LABEL_BASE_TABLE,
  FUNCTION_CODE,
} from "../../../../../constants";

import { showTimestampToastSuccess } from "../../../../../services/utils/message";
import { isResponseOk } from "../../../../../utils/utils";
import LanguageLoader from "../../../../../components/language-loader/language-loader";
import { RENTAL_T_CONTEXT_KEY } from "../../constants";
import { showSpinner } from "../../../../../redux/actions/spinner";
import { PortalLink } from "../../../../../services/utils";

const WithdrawRentalApplication = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();

  const [state, setState] = useState({
    activeStep: 0,
    form: {},
    formName: "",
    formConfig: null,
    isLoading: true,
  });

  // Fetch Data
  useEffect(() => {
    const loadApplicationDetail = async () => {
      const applicationId = props.match.params.applicationId;
      const api = RentalDetailsService.rentalGetWithdrawApplication({
        applicationId,
      });
      const [res] = await Promise.all([api]);

      let stepConfig = [];
      if (
        res &&
        res.data &&
        res.data.sections &&
        res.data.sections.length > 0
      ) {
        // self-construct a stepConfig for a single step form
        stepConfig = [
          {
            sections: res.data.sections.map((sec) => ({
              sectionId: sec.id,
              sectionName: sec.sectionName,
              label: sec.sectionName,
              readOnly: true,
              isDefaultHide: sec.isDefaultHide,
            })),
          },
        ];
        const initForm = initFormValue(res.data.sections);
        let formConfig = configToFormModel({
          applicationId: applicationId,
          stepConfig: stepConfig,
          sectionFieldConfig: res.data.sections,
          customData: {
            attachmentControlConfig: res.data.attachmentType,
          },
          t: t,
          form: initForm,
        });
        setState((state) => ({
          ...state,
          formName: res?.data?.formName,
          formConfig,
          isLoading: false,
          form: initForm,
        }));
      }
    };
    loadApplicationDetail();
  }, [props.match.params.applicationId]);

  // Breadcrumb
  useEffect(() => {
    dispatch(setBcDynamicPaths([{ label: t("rental_withdraw_breadcrumb") }]));
  }, [dispatch, t]);

  const backPage = () => {
    history.push(PortalLink(FUNCTION_CODE.Rental));
  };

  const withdrawApplication = async () => {
    try {
      // show spinner
      dispatch(showSpinner(true));
      const applicationId = props.match.params.applicationId;
      const res = await RentalDetailsService.rentalWithdrawApplication({
        applicationId,
      });
      if (!isResponseOk(res)) throw new Error("Withdraw Failed");
      showTimestampToastSuccess({
        message: t("rental_withdraw_successMessage"),
        t: t,
      });
      backPage();
    } catch (e) {
      // show notification?
    } finally {
      // end spinner
      dispatch(showSpinner(false));
    }
  };

  const confirmWithdraw = ({ confirm } = {}) => {
    if (confirm) {
      dispatch(
        openModal({
          title: t("rental_withdraw_modal_title"),
          content: t("rental_withdraw_modal_content"),
          classNameMainDialog: "confirm-message-modal",
          primaryButtonText: t("rental_common_actionConfirm"),
          primaryButtonClickFn: ({ closeFn }) => {
            withdrawApplication();
            closeFn();
          },
          secondButtonClickFn: ({ closeFn }) => {
            closeFn();
          },
        })
      );
    } else {
      withdrawApplication();
    }
  };

  // render
  const renderFormTitle = () => (
    <div className="rental-form-title">
      <Button
        onClick={backPage}
        icon="pi pi-angle-left"
        className="p-button-rounded-lg p-button-text"
      />
      <h2>{state.formName}</h2>
    </div>
  );

  const renderFooter = () => (
    <div className="footer p-grid p-align-center p-justify-between">
      <Button
        onClick={() => backPage()}
        className="p-button-outlined secondary"
      >
        {t("rental_common_actionBack")}
      </Button>
      <Button
        onClick={() => confirmWithdraw({ confirm: true })}
        className="p-button next-button"
      >
        {t("rental_withdraw_footerWithdraw")}
      </Button>
    </div>
  );

  const renderFormSkeleton = () => formSkeleton;

  const renderForm = () => (
    <div>
      <MultiStepForm
        form={state.form}
        activeStep={state.activeStep}
        config={state.formConfig}
        touched={state.touched}
      />
    </div>
  );

  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          RENTAL_T_CONTEXT_KEY.COMMON,
          RENTAL_T_CONTEXT_KEY.WITHDRAW,
        ]}
      />
      <div className="rental-ms-form rental-withdraw">
        {/* Form Name */}
        {!state.isLoading && renderFormTitle()}

        {/* Form */}
        {state.isLoading ? renderFormSkeleton() : renderForm()}

        {/* Footer */}
        {renderFooter()}
      </div>
    </>
  );
};

export default WithdrawRentalApplication;
