// import "./setting-configuration.scss";
import { MessageSeverity } from "primereact/api";
import { Button } from "primereact/button";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { scrollToErrorControl } from "../../../../components/base-form/utils";
import BaseMultiStepForm from "../../../../components/base-multi-step-form/base-multi-step-form";
import { BaseMultiStepFormSkeleton } from "../../../../components/base-multi-step-form/base-multi-step-form-skeleton";
import {
  BaseMultiStepConfigToFormModel,
  BaseMultiStepCustomFunctionLibrary,
} from "../../../../components/base-multi-step-form/base-multi-step-form-utils";
import { resetBcDynamicPaths } from "../../../../redux/actions/breadcrumb";
import {
  resetMainForm,
  resetMainFormConfig,
  resetMainFormValue,
  setFormLanguage,
  setMainForm,
  setMainFormConfig,
  setMainFormSectionConfig,
} from "../../../../redux/actions/form";
import { showSpinner } from "../../../../redux/actions/spinner";
import { store } from "../../../../redux/store";
import { showErrorMessageModal } from "../../../../services/utils/message";
import { getResponseMessage, isResponseOk } from "../../../../utils/utils";
import { AdminSettingConfigurationService } from "../../../../services/hrmnet-api";
import { Dropdown } from "primereact/dropdown";
import { useWindowSize } from "../../utils/window-size/useWindowSize";
import { TabMenu } from "primereact/tabmenu";
import { ConvertToString } from "./setting-configuration-helper";
import { ProcessFormState } from "../../../../components/base-form/base-form";
import SettingConfigurationOrganization from "./setting-configuration-organizational";

const SettingConfiguration = () => {
  const { isMobile } = useWindowSize();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let toast = store.getState().global.toast;
  const [settingConfigurationSelectIndex, setSettingConfigurationSelectIndex] =
    useState(0);
  const organizationalRef = useRef();

  const [state, setState] = useState({
    isLoading: true,
    activeStep: 0,
    formResp: {},
    touched: false,
    allFormSectionConfig: {},
  });

  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );
  const formLanguage = useSelector((state) => state.commonForm.formLanguage);

  const form = useSelector((state) => state.commonForm.form);
  const selectedTemplateName = useSelector(
    (state) => state.commonForm.form?.TemplateName
  );
  const setForm = (x) => dispatch(setMainForm(x));
  const formConfig = useSelector((state) => state.commonForm.formConfig);
  const setFormConfig = (x) => dispatch(setMainFormConfig(x));
  const setFormSectionConfig = (x) => dispatch(setMainFormSectionConfig(x));

  const formObjRef = React.createRef();

  let customFunctionLibrary = BaseMultiStepCustomFunctionLibrary({
    dispatch,
  });

  useEffect(() => {
    dispatch(resetBcDynamicPaths());
  }, [dispatch, t]);

  useEffect(() => {
    const loadForm = async () => {
      const isLangChange = formLanguage != selectedLangKey;
      dispatch(setFormLanguage(selectedLangKey));
      //enum api

      const formApi =
        AdminSettingConfigurationService.settingConfigurationGetSettingConfigurationSessionList();

      const [_formConfigRes] = await Promise.all([formApi]);

      let newForm = {};
      let newFormConfig = {};
      let allFormSectionConfig = []; //keeep all sections - to load form when tab is changing
      // let activeStep = 0;

      var { allSectionsConfiguration, newFormResp } =
        ToUiFormConfig(_formConfigRes);
      newFormConfig = { ...allSectionsConfiguration };

      if (allSectionsConfiguration?.steps?.length > 0) {
        allFormSectionConfig = allSectionsConfiguration.steps[0]?.sections;
        newFormConfig.steps[0].sections = [
          ...newFormConfig.steps[0]?.sections.filter(
            (x, index) => index === settingConfigurationSelectIndex
          ),
        ];
      }

      // init form and active step
      if (
        !isLangChange ||
        (form &&
          Object.keys(form).length === 0 &&
          Object.getPrototypeOf(form) === Object.prototype)
      ) {
        newForm = { ...newFormConfig?.formValues };
      }

      setForm(newForm);
      setFormConfig(newFormConfig);
      setFormSectionConfig(newFormResp.section);

      setState({
        ...state,
        isLoading: false,
        allFormSectionConfig: allFormSectionConfig,
      });
    };

    loadForm();
    // document.addEventListener("scroll", function () {
    //   let elements = document.querySelector(".base_multi_step_form")?.children;
    //   if (!!elements && elements.length > 0) {
    //     setTimeout(() => {
    //       if (!IsInViewport(elements[settingConfigurationSelectIndex], settingConfigurationSelectIndex)) {
    //         const visibleFields = Array.from(elements).findIndex((x, index) => IsInViewport(x, index));
    //         setSettingConfigurationSelectIndex(visibleFields);
    //       }
    //     }, 500);
    //   }
    // }, {
    //   passive: true
    // });

    return () => {
      setState({
        ...state,
        isLoading: false,
      });
      if (formLanguage === selectedLangKey) {
        dispatch(resetMainFormConfig());
        dispatch(resetMainForm());
        dispatch(resetMainFormValue());
      }
      // document.removeEventListener("scroll", function () {
      //   setSettingConfigurationSelectIndex(0);
      // }, { passive: true })
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (settingConfigurationSelectIndex > -1) {
      let newFormConfig = { ...formConfig };

      if (
        formConfig?.steps &&
        formConfig?.steps?.length > 0 &&
        state.allFormSectionConfig.length > 0
      ) {
        newFormConfig.steps[0].sections = [
          ...state.allFormSectionConfig.filter(
            (x, index) => index === settingConfigurationSelectIndex
          ),
        ];
      }
      setFormConfig(newFormConfig);
    }
    return () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
  }, [settingConfigurationSelectIndex]);

  // Navigation and action
  const validateCurrentStep = () => {
    const isValid = !formObjRef.current.getActiveFormState({ expand: true })
      .invalid;
    setTimeout(() => {
      scrollToErrorControl();
    }, 100);
    return isValid;
  };

  const validateAllStep = async () => {
    const sectionsFormState = state.allFormSectionConfig.map(
      (section, index) => {
        return {
          index: index,
          invalid: ProcessFormState(form, section.controls, t).formState
            .invalid,
        };
      }
    );

    if (sectionsFormState.filter((x) => x.invalid === true).length > 0) {
      if (
        settingConfigurationSelectIndex !==
        sectionsFormState.filter((x) => x.invalid)[0]
      ) {
        setSettingConfigurationSelectIndex(
          sectionsFormState.filter((x) => x.invalid)[0].index
        );
        await validateCurrentStep();
      }
      setState({
        ...state,
        touched: true,
      });

      return false;
    }

    return true;
  };

  const confirmSubmitApplication = async () => {
    const isValid = await validateCurrentStep();
    if (isValid) {
      submitApplicationAction();
    } else {
      setState({
        ...state,
        touched: true,
      });
    }
  };

  const getCurrentForm = (compareChanges) => {
    const formattedForm = !!form
      ? Object.keys(form).map((key, index) => {
          return {
            settingKey: key,
            settingValue: ConvertToString(form[key]),
          };
        })
      : // .filter((x) => {
        //   if (!compareChanges) {
        //     return true;
        //   }
        //   const formConfigValues = !!formConfig?.formValues
        //     ? formConfig?.formValues[x.settingKey]
        //     : undefined;
        //   if (formConfig?.formValues?.hasOwnProperty(x.settingKey)) {
        //     const formConfigValuesToString =
        //       ConvertToString(formConfigValues);
        //     return x.settingValue !== formConfigValuesToString;
        //   } else if (typeof x.settingValue === "string") {
        //     return true;
        //   }

        //   return false;
        // })
        [];

    return formattedForm;
  };

  const submitApplicationAction = async () => {
    try {
      dispatch(showSpinner(true));
      const { targetOrganizationName, targetOrganizationType } =
        organizationalRef?.current?.getForm();
      const formattedForm = getCurrentForm(
        Array.isArray(targetOrganizationName) &&
          targetOrganizationName.length < 2
      );
      let res =
        await AdminSettingConfigurationService.settingConfigurationUpdateSettingConfiguration(
          {
            body: {
              targetOrganizationType: targetOrganizationType,
              targetOrganizationName: targetOrganizationName,
              settingConfigurations: formattedForm,
            },
          }
        );
      if (isResponseOk(res)) {
        toast.show({
          severity: MessageSeverity.SUCCESS,
          detail: t("admin_common_submitMessageSuccess"),
          life: 3000,
        });

        const _formConfig = { ...formConfig };
        _formConfig.formValues = { ...form };
        setFormConfig(_formConfig);
      } else {
        showErrorMessageModal({
          dispatch,
          t,
          content: getResponseMessage(res),
        });
      }
    } catch (e) {
      // show notification?
      console.error(e.message);
    } finally {
      // end spinner
      dispatch(showSpinner(false));
    }
  };

  // Rendering
  const renderForm = () => (
    <BaseMultiStepForm
      form={form}
      activeStep={state.activeStep}
      config={formConfig}
      ref={formObjRef}
      touched={state.touched}
    />
  );

  const renderFooter = () => {
    return (
      <div className="footer p-grid p-align-center p-justify-between">
        <div />
        <div className="right-button-group">
          <Button
            onClick={() => confirmSubmitApplication()}
            className="p-button next-button"
          >
            {t("admin_common_actionSubmit")}
          </Button>
        </div>
      </div>
    );
  };

  // Rendering
  const renderFormTitle = () => (
    <div className="form-title">
      <h2>{t("System_Configuration_Form_Title")}</h2>
    </div>
  );

  const renderTabSelect = () => {
    let settingConfigurationTabList = [];
    if (state.allFormSectionConfig?.length > 0) {
      settingConfigurationTabList = state.allFormSectionConfig?.map(
        (x, idx) => ({
          label: x.title,
          value: idx,
        })
      );
    }

    return (
      <div className="form-menu">
        {isMobile ? (
          <div className="dropdown-wrapper">
            <Dropdown
              value={
                settingConfigurationTabList[settingConfigurationSelectIndex]
                  ?.value
              }
              options={settingConfigurationTabList}
              onChange={(e) => {
                setSettingConfigurationSelectIndex(e.value);
              }}
            />
          </div>
        ) : (
          <TabMenu
            model={settingConfigurationTabList}
            activeIndex={settingConfigurationSelectIndex}
            onTabChange={(e) => {
              setSettingConfigurationSelectIndex(e.index);
            }}
          />
        )}
      </div>
    );
  };

  function ToUiFormConfig(formConfigResult) {
    const applicationId = selectedTemplateName;

    let stepRes = {
      data: [
        {
          displaySequence: 1,
          id: 1,
          sections:
            formConfigResult?.data?.map((x) => {
              return {
                displaySequence: x.displaySequence,
                isEditable: true,
                sectionId: x.id,
                sectionName: x.sectionName,
                targetOrganizations: x?.targetOrganizations,
              };
            }) ?? [],
        },
      ],
    };

    let formConfigRes = {
      data: {
        sections: formConfigResult?.data ?? [],
      },
    };

    if (stepRes && stepRes.data && formConfigRes && formConfigRes.data) {
      let customData = {
        customFunctionLibrary: customFunctionLibrary,
      };

      const sectionConfiguration = BaseMultiStepConfigToFormModel({
        applicationId: applicationId,
        stepConfig: stepRes.data,
        sectionFieldConfig: formConfigRes.data.sections,
        customData: customData,
        t: t,
        form: {}, //since prevvalue always obtained from formConfigRes, no need to provide form's value
      });

      return {
        allSectionsConfiguration: sectionConfiguration,
        newFormResp: formConfigRes.data,
      };
    }

    return {
      allSectionsConfiguration: {},
      newFormResp: {},
    };
  }

  return (
    <>
      <div className="admin-form-style  setting-configuration">
        <div className="header header-sticky">
          {/* Title */}
          {renderFormTitle()}
          <SettingConfigurationOrganization
            ref={organizationalRef}
            isLoading={state.isLoading}
            onFilterChange={ToUiFormConfig}
            setLoading={(loading) => setState({ ...state, isLoading: loading })}
          />
          {/* Tab */}
          {renderTabSelect()}
        </div>

        {state.isLoading ? (
          <div className="form-loading">{BaseMultiStepFormSkeleton}</div>
        ) : (
          renderForm()
        )}

        {/* Footer */}
        {renderFooter()}
      </div>
    </>
  );
};

export default SettingConfiguration;
