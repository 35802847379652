import { combineReducers } from "redux";
//
import { persistReducer, createTransform } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { parse, stringify } from "flatted";
//
import auth from "./auth";
import global from "./global";
import modal from "./modal";
import sideModal from "./sideModal";
import sidebar from "./sidebar";
import client from "./client";
import user from "./user";
import language from "./language";
import notification from "./notification";
import countdownTimer from "./countdown-timer";
import policy from "./policy";
import menu from "./menu";
import breadcrumb from "./breadcrumb";
import rental from "./rental";
import spinner from "./spinner";
import commonForm from "./form";

import { CLEAR_REDUX } from "./../reduxConstants";
import { encryptTransform } from "redux-persist-transform-encrypt";

export const transformCircular = createTransform(
  (inboundState, key) => stringify(inboundState),
  (outboundState, key) => parse(outboundState)
);

export const transformEncrypt =
  window.ENCRYPT_ENABLE === true
    ? encryptTransform({
        secretKey: process.env.REACT_APP_SECRET_KEY,
        onError: function (error) {
          // Handle the error.
        },
      })
    : transformCircular;

const rootPersistConfig = {
  // configuration object for redux-persist
  key: "root",
  storage, // define which storage to use
  // transforms: [transformCircular],
  blacklist: ["global", "sidebar", "notification"],
  transforms: [transformEncrypt],
};

// https://github.com/rt2zz/redux-persist#nested-persists
const globalPersistConfig = {
  // configuration object for redux-persist
  key: "global",
  storage, // define which storage to use
  // transforms: [transformCircular],
  blacklist: ["toast", "alert"],
  transforms: [transformEncrypt],
};

const globalReducer = combineReducers({
  auth,
  global: persistReducer(globalPersistConfig, global),
  modal,
  sideModal,
  sidebar,
  client,
  user,
  language,
  notification,
  countdownTimer,
  policy,
  menu,
  breadcrumb,
  rental,
  spinner,
  commonForm,
});

const rootReducer = (state, action) => {
  if (action.type === CLEAR_REDUX) {
    storage.removeItem("persist:root");
    const { global, language } = state;
    state = {
      global,
      language: { ...language, loadedContext: { context: {}, data: {} } },
    };
  }

  return globalReducer(state, action);
};

// const persistedReducer = persistReducer(persistConfig, rootReducer);
export default persistReducer(rootPersistConfig, rootReducer);

// export default combineReducers({
//   auth,
//   global: persistReducer(globalPersistConfig, global),
//   modal,
//   sideModal,
//   sidebar,
//   client,
//   user,
// });
