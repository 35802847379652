import "./action-button.scss";

const Button = ({ children, className, ...props }) => (
  <div
    className={`p-button theme-action-button ${className ? className : ""}`}
    {...props}
  >
    {children}
  </div>
);
export default Button;
