import "./calendar.scss";
import { Calendar } from "primereact/calendar";
import { useState } from "react";
import { useEffect } from "react";

interface CustomCalendarProps {
  className?: string;
  dateConfig?: DateConfig;
  [key: string]: any;
}

interface DateConfigItem {
  showMark: boolean;
  disabled: boolean;
  isRedDay: boolean;
}

interface DateConfig {
  [key: number]: DateConfigItem;
}

interface PDate {
  day: number;
  month: number;
  year: number;
  otherMonth: boolean;
  selectable: boolean;
}

const DEFAULT_DATE_CONFIG = {
  showMark: false,
  disabled: false,
  isRedDay: false,
};

const CustomCalendar = ({
  className,
  dateConfig,
  ...props
}: CustomCalendarProps) => {
  const [viewDate, setViewDate] = useState(
    props.viewDate ? props.viewDate : new Date()
  );

  useEffect(() => {
    if (props.viewDate) {
      setViewDate(props.viewDate);
    }
  }, [props.viewDate]);

  let dateTemplate = undefined;
  let disabledDates = props.disabledDates ? props.disabledDates : [];

  if (dateConfig) {
    dateTemplate = (date: PDate) => {
      if (date.otherMonth) {
        return <span>{date.day}</span>;
      }

      const config: DateConfigItem = {
        ...DEFAULT_DATE_CONFIG,
        ...dateConfig[date.day],
      };
      return (
        <div className="custom-date-template">
          <span
            className={`${
              config.disabled || config.isRedDay ? "custom-date-disabled" : ""
            } ${config.isRedDay ? "custom-date-red-day" : ""} ${
              config.showMark ? "custom-date-marked" : ""
            }`}
          >
            {date.day}
          </span>

          {config.showMark && (
            <div className="date-marker pi pi-circle-on"></div>
          )}
        </div>
      );
    };
    Object.entries(dateConfig).forEach(([day, config]) => {
      if (config.disabled || config.isRedDay) {
        disabledDates.push(new Date(new Date(viewDate).setDate(parseInt(day))));
      }
    });
  }

  const onViewDateChange = (e: any) => {
    if (props.onViewDateChange) {
      props.onViewDateChange(e);
    }
    setViewDate(e.value);
  };

  return (
    <Calendar
      className={`custom-calendar ${className || ""}`}
      dateTemplate={dateTemplate}
      viewDate={props.viewDate ? props.viewDate : viewDate}
      {...props}
      onViewDateChange={onViewDateChange}
      disabledDates={disabledDates}
    />
  );
};

export default CustomCalendar;
