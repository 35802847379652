import moment from "moment";

import { SectionConfig } from "./index";
import {
  HRIS_Api_DocumentType,
  DocumentsService,
  RentalDetailsService,
  RentalApprovalService,
} from "../../../../services/hrmnet-api";
import {
  CCType,
  getFieldKey,
  // getFormKeyByFieldName,
} from "./formConfiguration";
// import {
//   COPY_APPLICATION_VALUE,
//   NEW_APPLICATION_VALUE,
// } from "../components/application-type-control";
import { CLAIM_STATUS } from "../components/claim-status";
import { isResponseOk } from "../../../../utils/utils";
import { store } from "../../../../redux/store";

export enum CLAIM_APPROVAL_ACTION {
  APPROVE = "Approve_Claim",
  REJECT = "Reject_Claim",
  RETURN = "Return_Claim",
}

const CLAIM_APPROVAL_STATUS_ACTION_MAP: { [key: string]: any } = {
  [CLAIM_STATUS.VERIFIED]: CLAIM_APPROVAL_ACTION.APPROVE,
  [CLAIM_STATUS.REJECTED]: CLAIM_APPROVAL_ACTION.REJECT,
  [CLAIM_STATUS.RETURNED]: CLAIM_APPROVAL_ACTION.RETURN,
  [CLAIM_STATUS.OPEN]: CLAIM_APPROVAL_ACTION.RETURN,
};

/**
 * Given an list of mixed existing files and new files in blob, upload new files and return all files with uploadId
 * @param files
 * @returns Promise
 */
async function handleUploadFiles(
  files: [],
  docType: string = HRIS_Api_DocumentType.RentalReimbursement
) {
  if (!files?.length) {
    return [];
  }
  let existingFiles: any = [];
  let actualFiles: any = [];
  files.forEach((f: any) => {
    // if id => uploaded file
    if (f.uploadId == null) {
      actualFiles.push(f);
    } else {
      existingFiles.push({
        fileId: f.id,
        fileName: f.name,
        fileSize: f.size,
        uploadId: f.uploadId,
      });
    }
  });
  // upload new file
  let newUploadedFiles = [];
  if (actualFiles.length > 0) {
    const res = await uploadFiles(actualFiles);
    if (!isResponseOk(res)) throw new Error("Upload Files Failed");
    newUploadedFiles = res.data.files.map((f: any) => ({
      ...f,
      fileId: f.fileId.toString(),
      uploadId: res.data.uploadId.toString(),
    }));
  }
  return [...existingFiles, ...newUploadedFiles];
}

/**
 * Upload files
 * @param files
 * @param docType
 * @returns Promis
 */
async function uploadFiles(
  files: any,
  docType: string = HRIS_Api_DocumentType.RentalReimbursement
) {
  return DocumentsService.documentUploadFiles({
    message: "",
    files: files,
    releaseOnDate: new Date().toISOString(),
    documentType: docType,
  });
}

/**
 * Populate and return the submit application payload with data in form
 * @param sections section config
 * @param form form data
 */
interface payloadField {
  id: any;
  fieldName: any;
  value?: any;
  fileAttachments?: any;
}

export async function prepareSubmitFormPayload(
  sections: SectionConfig[],
  form: {
    [key: string]: any;
  },
  excludeFieldTypes?: string[]
) {
  let payloadSections: {
    id: any;
    fields: payloadField[];
  }[] = [];
  sections.forEach((section, sIdx) => {
    let payloadFieldList: payloadField[] = [];
    section.fields.forEach((field: any, fIdx: number) => {
      let payloadField: payloadField = {
        id: field.id,
        fieldName: field.fieldName,
      };
      let addPayload = true;
      if (
        !excludeFieldTypes?.length ||
        !excludeFieldTypes?.includes(field.controlType)
      ) {
        const formValue = form[getFieldKey(field)];
        switch (field.controlType) {
          case CCType.APPLICATION_TYPE:
            payloadField.value = 1;
            break;
          case CCType.ATTACHMENT:
            payloadField.fileAttachments = handleUploadFiles(formValue);
            break;
          case CCType.HINT:
            addPayload = false;
            break;
          case CCType.NOTES:
            addPayload = false;
            break;
          case CCType.DATE_PICKER:
            if (formValue) {
              let formatString = field.formatString || "yyyy-MM-dd";
              // Custom moment <-> Tricor date format mapper
              if (formatString.includes("dd")) {
                formatString = formatString.replace("dd", "DD");
              } else if (formatString.includes("DD")) {
                formatString = formatString.replace("DD", "dd");
              }
              const formattedDate = moment(formValue).format(formatString);
              payloadField.value = formattedDate;
            }
            break;
          default:
            payloadField.value = formValue;
            break;
        }
      }
      if (addPayload) {
        payloadFieldList.push(payloadField);
      }
    });
    payloadSections.push({
      id: section.id,
      fields: payloadFieldList,
    });
  });

  for (let section of payloadSections) {
    for (let field of section.fields) {
      if (field.fileAttachments) {
        field.fileAttachments = await field.fileAttachments;
      }
    }
  }

  return {
    sections: payloadSections,
  };
}

/**
 * Create Application - Save as Draft
 */
export async function saveDraft(
  sectionConfig: SectionConfig[],
  form: any,
  applicationId?: any
) {
  const payload = await prepareSubmitFormPayload(sectionConfig, form);
  const payloadWithStatus = {
    ...payload,
    id: applicationId != null ? applicationId : 0,
    isDraft: true,
  };
  return await RentalDetailsService.rentalSubmitDraftApplication({
    body: payloadWithStatus as any,
  });

  /*
  // previous save draft method
  if (applicationId != null) {
    return await RentalDetailsService.rentalUpdateDraftApplication({
      applicationId: applicationId,
      body: payload as any,
    });
  } else {
    return await RentalDetailsService.rentalCreateDraftApplication({
      body: payload as any,
    });
  }
  */
}

/**
 * Create Application - Submit Application
 */
export async function submitApplication(
  sectionConfig: SectionConfig[],
  form: any,
  applicationId?: any
) {
  const payload = await prepareSubmitFormPayload(sectionConfig, form);
  //Create Rental application on behalf of employee
  const employeeCode = store.getState().rental?.employeeDetails?.employeeCode;
  const payloadWithStatus = {
    ...payload,
    id: applicationId != null ? applicationId : 0,
    isDraft: false,
    employeeCode: employeeCode,
  };
  if (!employeeCode) {
    delete payloadWithStatus.employeeCode;
  }

  return await RentalDetailsService.rentalSubmitDraftApplication({
    body: payloadWithStatus as any,
  });

  /*
  // previous submit application method
  if (applicationId != null) {
    // submit existing application
    const res = await saveDraft(sectionConfig, form, applicationId);
    if (!res) return null;
    return RentalDetailsService.rentalSubmitApplication({
      applicationId,
    });
  } else {
    // submit new application
    const res = await saveDraft(sectionConfig, form);
    if (!res) return null;
    return RentalDetailsService.rentalSubmitNewApplication({
      applicationId: res.data.id,
    });
  }
  */
}

/**
 * Change Application - Submit
 */
export async function submitChange(
  sectionConfig: SectionConfig[],
  form: any,
  applicationId: any
) {
  const payload = await prepareSubmitFormPayload(sectionConfig, form);
  return RentalDetailsService.rentalSaveChangeApplication({
    applicationId,
    body: payload,
  });
}

/**
 * Submit Claim - Submit
 */
export const submitClaim = async ({
  sectionConfig,
  form,
  claims,
  applicationId,
}: {
  sectionConfig: SectionConfig[];
  form: any;
  claims: any[];
  applicationId: any;
}) => {
  // get first MonthSelect Control
  let monthSelectField = null;
  sectionLoop: for (const section of sectionConfig) {
    for (const field of section.fields) {
      if (field.controlType === CCType.CLAIM_SELECT_MONTH) {
        monthSelectField = { ...field };
        break sectionLoop;
      }
    }
  }

  // upload all files
  const monthSelectValue = form[getFieldKey(monthSelectField)];
  if (!monthSelectValue) return; // TODO: handling

  const files = Object.values(monthSelectValue).reduce(
    (prev: any, curr: any) => {
      prev.push(curr.file);
      return prev;
    },
    []
  );

  const res = await uploadFiles(files);
  if (!isResponseOk(res)) throw new Error("Upload Files Failed");

  // prepare payload
  const uploadedFilesMap = res.data.files.reduce((prev: any, curr: any) => {
    prev[curr.fileName] = {
      ...curr,
      fileId: curr.fileId.toString(),
      uploadId: res.data.uploadId.toString(),
    };
    return prev;
  }, {});

  const claimsNewFileMap: any = Object.values(monthSelectValue).reduce(
    (acc: any, cur: any) => {
      const filename = cur.file.name;
      cur.claimIds.forEach((claimId: any) => {
        if (!(claimId in acc)) acc[claimId] = [];
        acc[claimId].push(uploadedFilesMap[filename]);
      });
      return acc;
    },
    {}
  );

  const payload = claims.reduce((acc, claim: any) => {
    let _claim = {
      id: claim.id,
      applicationId: claim.applicationId,
      attachmentFiles: claim.attachmentFiles,
    };
    if (_claim.id in claimsNewFileMap) {
      _claim.attachmentFiles = _claim.attachmentFiles.concat(
        claimsNewFileMap[_claim.id]
      );
      acc.push(_claim);
    }
    return acc;
  }, []);

  // submit
  return RentalDetailsService.rentalSubmitClaims({
    applicationId: applicationId,
    body: payload,
  });
};

/**
 * Approve Application - return
 */
export async function returnApplicationApproval(data: {
  sectionConfig: SectionConfig[];
  form: any;
  applicationId: any;
}) {
  const { sectionConfig, form, applicationId } = data;
  const payload = await prepareSubmitFormPayload(sectionConfig, form, [
    CCType.APPLICATION_TYPE,
  ]);
  return await RentalApprovalService.rentalApprovalReturnApplication({
    applicationId: applicationId,
    body: payload,
  });
}

/**
 * Approve Application - approve
 */
export async function approveApplicationApproval(data: {
  sectionConfig: SectionConfig[];
  form: any;
  applicationId: any;
}) {
  const { sectionConfig, form, applicationId } = data;
  const payload = await prepareSubmitFormPayload(sectionConfig, form, [
    CCType.APPLICATION_TYPE,
  ]);
  return await RentalApprovalService.rentalApprovalApproveApplication({
    applicationId: applicationId,
    body: payload,
  });
}

/**
 * Approve Application - reject
 */
export async function rejectApplicationApproval(data: {
  sectionConfig: SectionConfig[];
  form: any;
  applicationId: any;
}) {
  const { sectionConfig, form, applicationId } = data;
  const payload = await prepareSubmitFormPayload(sectionConfig, form, [
    CCType.APPLICATION_TYPE,
  ]);
  return await RentalApprovalService.rentalApprovalRejectApplication({
    applicationId: applicationId,
    body: payload,
  });
}

/**
 * Approve Application - finalize
 */
export async function finalizeApplicationApproval(data: {
  sectionConfig: SectionConfig[];
  form: any;
  applicationId: any;
}) {
  const { applicationId } = data;
  return await RentalDetailsService.rentalFinializeApplication({
    applicationId
  });
}

/**
 * Approve Claim - Save
 */
export async function saveClaimApproval(data: {
  sectionConfig: SectionConfig[];
  form: any;
  applicationId: any;
}) {
  const { sectionConfig, form, applicationId } = data;

  const applicationPayload = await prepareSubmitFormPayload(
    sectionConfig,
    form,
    [CCType.APPROVE_CLAIM]
  );
  let payload: any = {
    applicationRequest: applicationPayload,
    claimRequests: [],
    isPreview: false,
  };

  // get form keys for the two target inputs: fileUpload and claimApproval
  let claimApprovalFormKey = "";
  eachSection: for (const section of sectionConfig) {
    for (const field of section.fields) {
      if (!claimApprovalFormKey && field.controlType === CCType.APPROVE_CLAIM) {
        claimApprovalFormKey = getFieldKey(field);
        break eachSection;
      }
    }
  }

  // update claims
  if (form[claimApprovalFormKey]) {
    // upload all the files
    const uniqueFileIdsSet = new Set();
    let uniqueFiles: any[] = [];
    let uniqueFileIds: any[] = [];
    Object.keys(form[claimApprovalFormKey]).forEach((claimId) => {
      if (form[claimApprovalFormKey][claimId].attachmentFiles) {
        form[claimApprovalFormKey][claimId].attachmentFiles.forEach(
          (f: any) => {
            if (!uniqueFileIdsSet.has(f.customFileId)) {
              uniqueFileIdsSet.add(f.customFileId);

              uniqueFiles.push(f);
              uniqueFileIds.push(f.customFileId);
            }
          }
        );
      }
    });
    let uploadedFiles;
    let customFileIdToUploadedFileMap: any = {};
    if (uniqueFiles.length) {
      let uploadRes = await uploadFiles(uniqueFiles);
      uploadedFiles = uploadRes.data?.files.map((file: any) => ({
        ...file,
        fileId: file.fileId.toString(),
        uploadId: (uploadRes.data?.uploadId).toString(),
      }));
      uploadedFiles.forEach((fileMeta: any, idx: any) => {
        customFileIdToUploadedFileMap[uniqueFileIds[idx]] = { ...fileMeta };
      });
    }

    const claimRequests = Object.keys(form[claimApprovalFormKey]).map(
      (claimId) => {
        const input = form[claimApprovalFormKey][claimId];
        let request: any = {
          id: claimId,
          rentalApprovalClaimDetails: {},
        };
        if (input.status != null) {
          request.action = CLAIM_APPROVAL_STATUS_ACTION_MAP[input.status];
        }
        if (input.claimPeriodFrom != null) {
          request.rentalApprovalClaimDetails.periodFrom = moment(
            input.claimPeriodFrom
          ).format("yyyy-MM-DD");
        }
        if (input.claimPeriodTo != null) {
          request.rentalApprovalClaimDetails.periodTo = moment(
            input.claimPeriodTo
          ).format("yyyy-MM-DD");
        }
        if (input.monthlyRentalCap != null) {
          request.rentalApprovalClaimDetails.cappedAmount =
            input.monthlyRentalCap;
        }
        if (input.reimbursementAmount != null) {
          request.rentalApprovalClaimDetails.reimbursementAmount =
            input.reimbursementAmount;
        }
        if (input.actualRentalAmount != null) {
          request.rentalApprovalClaimDetails.rentalAmount =
            input.actualRentalAmount;
        }
        if (input.govtRentMgtFee != null) {
          request.rentalApprovalClaimDetails.govtRentManagementFee =
            input.govtRentMgtFee;
        }
        if (input.attachmentFiles != null) {
          request.rentalApprovalClaimDetails.attachmentFiles =
            input.attachmentFiles.map(
              (f: any) => customFileIdToUploadedFileMap[f.customFileId]
            );
        }
        return request;
      }
    );
    payload.claimRequests = claimRequests;
  }

  console.log("payload", payload);

  return await RentalApprovalService.rentalApprovalClaimsApproval({
    applicationId: applicationId,
    body: payload,
  });
}

/**
 * Approve Claim - Finalize
 */
export async function finalizeClaimApproval({
  applicationId,
  remarks,
}: {
  applicationId: number;
  remarks: string;
}) {
  const res = await RentalDetailsService.rentalFinializeApplication({
    applicationId,
    remarks,
  });
  return res;
}
