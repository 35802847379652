import { SET_MODAL, SET_LOADING } from "./../reduxConstants";

/**
 * openModal
 */
export const openModal = (modal) => {
  return {
    type: SET_MODAL,
    payload: {
      ...modal,
      isOpen: true,
    },
  };
};

/**
 * Close modal
 */
export const closeModal = () => {
  return {
    type: SET_MODAL,
    payload: {
      isOpen: false,
    },
  };
};

/**
 * display main loading
 */
export const openLoading = () => {
  return {
    type: SET_LOADING,
    payload: true,
  };
};

/**
 * close main loading
 */
export const closeLoading = () => {
  return {
    type: SET_LOADING,
    payload: false,
  };
};
