import { SET_SPINNER, RESET_SPINNER } from "../reduxConstants";

export const showSpinner = (show) => {
  return {
    type: SET_SPINNER,
    payload: {
      isOpen: show,
    },
  };
};

export const clearSpinner = () => {
  return {
    type: RESET_SPINNER,
  };
};
