import "./../public/login/components/login-form/login-form.scss";
import "./../public/login/login.scss";

import { useEffect, useState } from "react";
import { getControlModel } from "../../../components/base-control/base-cotrol-model";
import { LOGIN_PATH, QUERY_PARAMS } from "../../../constants";
import { LoginLink } from "../../../services/utils";
import { LoginBtn } from "../public/login/components/utils";
import BaseForm from "../../../components/base-form/base-form";
import { useDispatch } from "react-redux";
import { removeTenant, setDeviceType } from "../../../redux/actions/global";
import { WEB_CONFIG_SUCCESS } from "../../../constants/index";
import { ConfigurationService } from "../../../services/hrmnet-api";
import { CompareParamValue } from "../../../utils/utils";
import { useHistory } from "react-router-dom";
import LoginWrapper from "../public/login/login-wrapper";
import { clearLanguage } from "../../../redux/actions/language";

function ClientConfiguration() {
  let history = useHistory();
  const biometricAuthEvent = "message";
  const dispatch = useDispatch();
  const removeCachedTenant = () => dispatch(removeTenant());
  const removeLanguage = () => dispatch(clearLanguage());
  const [state, setState] = useState(null);

  async function clearCache() {
    removeCachedTenant();
    removeLanguage();
  }

  useEffect(() => {
    let isMobileApp = CompareParamValue(QUERY_PARAMS.TYPE, QUERY_PARAMS.MOBILE);
    if (!isMobileApp) {
      dispatch(setDeviceType(QUERY_PARAMS.WEB));
    } else dispatch(setDeviceType(QUERY_PARAMS.MOBILE));

    clearCache();

    return () => {
      setState(null);
    };
  }, [dispatch]);

  const afterFormSubmitFn = async ({ res, form, formState }) => {
    let isValidTenant = res && !!res?.data;
    if (formState.invalid && !isValidTenant) {
      return false;
    }

    const init = () => {
      const webView = window.ReactNativeWebView || document.ReactNativeWebView;
      let isMobileApp = CompareParamValue(
        QUERY_PARAMS.TYPE,
        QUERY_PARAMS.MOBILE
      );
      const loginUrl = LoginLink(form);

      if (isMobileApp && webView) {
        const message = {
          type: WEB_CONFIG_SUCCESS,
          action: loginUrl,
        };

        webView.postMessage(JSON.stringify(message));

        window.addEventListener(biometricAuthEvent, function (msg) {
          alert(
            "--event  - message listener -- " +
              JSON.stringify(msg.data) +
              JSON.parse(msg.data).status
          );
        });
        document.addEventListener(biometricAuthEvent, function (msg) {
          alert(
            "--event  - message listener -- " +
              JSON.stringify(msg.data) +
              JSON.parse(msg.data).status
          );
        });
      } else {
        history.push(loginUrl);
      }

      return true;
    };

    return init();
  };

  const formSubmitFn = async ({ form, formState }) => {
    if (formState.invalid) {
      return {};
    }

    return await ConfigurationService.configurationGetLoginConfigurations({
      ...form,
    });
  };

  const clientForm = {
    controls: [
      getControlModel({
        key: "tenantCode",
        label: "Client/Tenant Name",
        placeholder: "Client/Tenant Name",
        required: true,
        noRequiredLabel: false,
      }),
      getControlModel({
        key: "entityCode",
        label: "Entity Name",
        placeholder: "Entity Name",
        required: false,
        noRequiredLabel: true,
      }),
    ],
    layout: {
      rows: [
        {
          columns: [
            {
              control: "tenantCode",
            },
          ],
        },
        {
          columns: [
            {
              control: "entityCode",
            },
          ],
        },
        {
          config: {
            style: {
              marginBottom: "0.5rem",
            },
          },
          columns: [LoginBtn("Connect"), {}],
        },
      ],
    },
    formSubmitFn,
    afterFormSubmitFn,
  };

  return (
    <LoginWrapper clientInfo={{}} id={`${LOGIN_PATH.CLIENT_CONFIGURATION}`}>
      <div style={{ marginTop: "30%" }} />
      <BaseForm config={clientForm} form={state?.form} />
    </LoginWrapper>
  );
}

export default ClientConfiguration;
