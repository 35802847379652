import "./approve-claim.scss";

import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";

import { setBcDynamicPaths } from "../../../../../redux/actions/breadcrumb";
import {
  RentalApprovalService,
  RentalDetailsService,
} from "./../../../../../services/hrmnet-api/index";
import { CLAIM_STATUS, MultiStepForm } from "../../components";
import {
  configToFormModel,
  finalizeClaimApproval,
  formSkeleton,
  getCustomFunctionLibrary,
  initFormValue,
  saveClaimApproval,
} from "../../utils";
import {
  EEPORTAL_LABEL_BASE_TABLE,
  FUNCTION_CODE,
  RENTAL_SUBMODULE_CODE,
} from "../../../../../constants";
import { openModal } from "../../../../../redux/actions/modal";
import { InputText } from "primereact/inputtext";
import { isResponseOk } from "../../../../../utils/utils";
import { showTimestampToastSuccess } from "../../../../../services/utils/message";
import {
  resetRentalForm,
  setRentalForm,
  setRentalFormConfig,
  setRentalSectionConfig,
} from "../../../../../redux/actions/rental";
import LanguageLoader from "../../../../../components/language-loader/language-loader";
import {
  RENTAL_T_CONTEXT_KEY,
  RENTAL_MENU_MODULE_KEY,
  RENTAL_MENU_ACTION_KEY,
} from "../../constants";
import { showSpinner } from "../../../../../redux/actions/spinner";
import { PortalLink } from "../../../../../services/utils";

const FINALIZE_READY_STATUS_LIST = [
  CLAIM_STATUS.VERIFIED,
  CLAIM_STATUS.REJECTED,
];

const ApproveClaim = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();

  const initState = {
    activeStep: 0,
    formName: "",
    formResp: {},
    menu: [],
    activeSectionId: -1,
    isLoading: true,
    initData: true,
    saved: true,
  };
  const [state, setState] = useState(initState);

  const form = useSelector((state) => state.rental.form);
  const setForm = (x) => dispatch(setRentalForm(x));
  const formConfig = useSelector((state) => state.rental.formConfig);
  const setFormConfig = (x) => dispatch(setRentalFormConfig(x));
  const setFormSectionConfig = (x) => dispatch(setRentalSectionConfig(x));

  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );
  const selectedLangRef = useRef();

  const menus = useSelector((state) => state.menu.sideBar);
  const rentalMenu = menus.find(
    (x) => x.key === RENTAL_MENU_MODULE_KEY
  )?.subMenus;
  const finalizePermission = rentalMenu.find(
    (x) => x.key === RENTAL_MENU_ACTION_KEY.RENTAL_FINALIZE_APPLICATIONS
  );

  // Init
  useEffect(() => {
    dispatch(resetRentalForm());
    return () => {
      dispatch(resetRentalForm());
    };
  }, [dispatch]);

  const prevFormJsonRef = useRef();

  const remarksRef = useRef();
  const updateRemarks = (value) => {
    remarksRef.current = value;
  };

  const resetState = () => {
    setState(initState);
  };

  // Fetch Datas
  useEffect(() => {
    const loadApplicationDetail = async () => {
      const isLangChange =
        selectedLangRef.current != null &&
        selectedLangRef.current !== selectedLangKey;
      selectedLangRef.current = selectedLangKey;

      const applicationId = props.match.params.applicationId;
      const api =
        RentalApprovalService.rentalApprovalGetClaimApprovalApplication({
          applicationId,
        });
      let [res] = await Promise.all([api]);

      if (
        res &&
        res.data &&
        res.data.sections &&
        res.data.sections.length > 0
      ) {
        // self-construct a stepConfig for a single step form
        const stepConfig = [
          {
            sections: res.data.sections.map((sec) => ({
              sectionId: sec.id,
              sectionName: sec.sectionName,
              label: sec.sectionName,
              isEditable: true,
              isDefaultHide: sec.isDefaultHide,
            })),
          },
        ];

        let initForm;
        if (!isLangChange) {
          initForm = initFormValue(res.data.sections);
        } else {
          initForm = form;
        }

        let formConfig = configToFormModel({
          applicationId: applicationId,
          stepConfig: stepConfig,
          sectionFieldConfig: res.data.sections,
          customData: {
            claims: res.data.claims,
            comments: res.data.comments,
            customFunctionLibrary: getCustomFunctionLibrary({ dispatch }),
            attachmentControlConfig: res.data.attachmentType,
          },
          t: t,
          form: initForm,
        });
        prevFormJsonRef.current = JSON.stringify(initForm);

        setForm(initForm);
        setFormConfig(formConfig);
        setFormSectionConfig(res.data);

        setState((state) => ({
          ...state,
          formName: res?.data?.formName,
          formResp: res?.data,
          menu: stepConfig[0].sections,
          isLoading: false,
        }));
      }
      setState((state) => ({
        ...state,
        initData: false,
      }));
    };
    if (state.initData) {
      loadApplicationDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.initData, selectedLangKey]);

  // Breadcrumb
  useEffect(() => {
    dispatch(
      setBcDynamicPaths([
        // { label: t("rental_approvalList_title") },
        { label: state.formName || "" },
      ])
    );
  }, [dispatch, t, state.formName]);

  // Nav
  const backPage = ({ confirm } = {}) => {
    if (confirm) {
      dispatch(
        openModal({
          title: t("rental_approveClaim_backModalTitle"),
          content: t("rental_approveClaim_backModalContent"),
          classNameMainDialog: "confirm-message-modal",
          primaryButtonText: t("rental_common_actionConfirm"),
          primaryButtonClickFn: async ({ closeFn }) => {
            closeFn();
            history.push(
              PortalLink(
                `${FUNCTION_CODE.Rental}/${RENTAL_SUBMODULE_CODE.APPROVAL}`
              )
            );
          },
          secondButtonClickFn: ({ closeFn }) => {
            closeFn();
          },
        })
      );
    } else {
      history.push(
        PortalLink(`${FUNCTION_CODE.Rental}/${RENTAL_SUBMODULE_CODE.APPROVAL}`)
      );
    }
  };

  // Utils
  const onFormChange = (update) => {
    const prevForm = JSON.parse(prevFormJsonRef.current);
    const isTrueUpdate =
      JSON.stringify(prevForm[update.changed.control.key]) !==
        JSON.stringify(update.changed.data.value) &&
      (prevForm[update.changed.control.key] != null ||
        update.changed.data.value != null); // handle form changed from undefined -> null

    const newForm = {
      ...form,
      ...update.state.form,
    };

    setForm(newForm);
    setState((prevState) => {
      prevFormJsonRef.current = JSON.stringify(newForm);
      return {
        ...prevState,
        saved: isTrueUpdate ? false : prevState.saved,
      };
    });
  };

  const printPage = () => {
    var head = document.head.innerHTML;
    var content = document.getElementsByClassName("main-content")[0].innerHTML;

    var printWindow = window.open("", "");
    printWindow.document.write(
      "<html>" + head + "<body>" + content + "</body></html>"
    );
    printWindow.document.close();
    printWindow.focus();
    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 200);
  };

  // Submit
  const confirmSaveClaim = async () => {
    dispatch(
      openModal({
        title: t("rental_approveClaim_saveModalTitle"),
        content: t("rental_approveClaim_saveModalContent"),
        classNameMainDialog: "confirm-message-modal",
        primaryButtonText: t("rental_common_actionConfirm"),
        primaryButtonClickFn: async ({ closeFn }) => {
          closeFn();
          saveClaim();
        },
        secondButtonClickFn: ({ closeFn }) => {
          closeFn();
        },
      })
    );
  };

  const saveClaim = async () => {
    try {
      //show spinner
      dispatch(showSpinner(true));

      const res = await saveClaimApproval({
        sectionConfig: state.formResp.sections,
        applicationId: props.match.params.applicationId,
        form: form,
      });
      if (!isResponseOk(res)) throw new Error("save claim failed");
      showTimestampToastSuccess({
        message: t("rental_approveClaim_successMessageSave"),
        t: t,
      });
      resetState();
    } catch (e) {
      // error
    } finally {
      // end spinner
      dispatch(showSpinner(false));
    }
  };

  const confirmFinalizeClaim = async () => {
    updateRemarks("");
    dispatch(
      openModal({
        title: t("rental_approveClaim_finalizeModalTitle"),
        renderModalBody: () => (
          <div className="finalize-claim-modal">
            <div>
              {t("rental_approveClaim_finalizeModalContent", {
                status: state.formResp.applicationStatus,
              })}
            </div>
            <label>
              {t("rental_approveClaim_finalizeModalContentRemarks")}
            </label>
            <InputText onChange={(e) => updateRemarks(e.target.value)} />
          </div>
        ),
        classNameMainDialog: "confirm-message-modal",
        primaryButtonText: t("rental_common_actionConfirm"),
        primaryButtonClickFn: async ({ closeFn }) => {
          closeFn();
          finalizeClaim();
        },
        secondButtonClickFn: ({ closeFn }) => {
          closeFn();
        },
      })
    );
  };

  const finalizeClaim = async () => {
    try {
      //show spinner
      dispatch(showSpinner(true));

      const res = await finalizeClaimApproval({
        applicationId: props.match.params.applicationId,
        remarks: remarksRef.current,
      });
      if (!isResponseOk(res)) throw new Error("finalize claim failed");
      showTimestampToastSuccess({
        message: t("rental_approveClaim_successMessageFinalize"),
        t: t,
      });
      backPage();
    } catch (e) {
      // error
    } finally {
      // end spinner
      dispatch(showSpinner(false));
    }
  };

  // render
  const renderFormTitle = () => (
    <div className="title">
      <Button
        onClick={() => backPage({ confirm: !state.saved })}
        icon="pi pi-angle-left"
        className="p-button-rounded-lg p-button-text"
      />
      {state.formName}
    </div>
  );

  const renderFormSkeleton = () => formSkeleton;

  const showFinalize = () => {
    return (
      state.saved &&
      state.formResp?.claims?.monthlyReceipts
        .filter((receipt) => receipt.enable)
        .every((receipt) => FINALIZE_READY_STATUS_LIST.includes(receipt.status))
    );
  };

  const renderForm = () => (
    <div>
      <MultiStepForm
        form={{ ...form }}
        sectionFilter={state.activeSectionId < 0 ? null : state.activeSectionId}
        activeStep={state.activeStep}
        config={formConfig}
        touched={state.touched}
        onChange={onFormChange}
      />
    </div>
  );

  const renderFooter = () => (
    <div className="footer p-grid p-align-center p-justify-between">
      <div className="button-group-1">
        <Button
          onClick={() => backPage({ confirm: !state.saved })}
          className="p-button-outlined secondary"
        >
          {t("rental_common_actionBack")}
        </Button>
        <Button
          onClick={() => printPage()}
          className="p-button-outlined secondary"
        >
          {t("rental_common_actionPrint")}
        </Button>
      </div>
      {!state.isLoading && (
        <div className="button-group-2">
          <Button
            disabled={state.saved}
            onClick={() => confirmSaveClaim()}
            className="p-button-outlined secondary"
          >
            {t("rental_common_actionSave")}
          </Button>
          {finalizePermission && (
            <Button
              disabled={!showFinalize()}
              onClick={() => confirmFinalizeClaim()}
              className="p-button finalize-btn"
            >
              {t("rental_approveClaim_finalizeApplication")}
            </Button>
          )}
        </div>
      )}
    </div>
  );

  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          RENTAL_T_CONTEXT_KEY.COMMON,
          RENTAL_T_CONTEXT_KEY.APPROVAL,
        ]}
      />
      <div className="rental-approve-claim">
        {/* Form Name */}
        {!state.isLoading && renderFormTitle()}

        {/* Form */}
        {state.isLoading ? renderFormSkeleton() : renderForm()}

        {/* Footer */}
        {state.isLoading ? renderFormSkeleton() : renderFooter()}
      </div>
    </>
  );
};

export default ApproveClaim;
