import { makeId } from "../../utils/utils";
import {
  SET_GLOBAL,
  SET_DEVICE_TYPE,
  RELOAD_TENANT,
  CLEAR_TENANT,
  BROADCAST_TENANT,
} from "./../reduxConstants";

export const setGlobal = (globalInfo) => {
  return {
    type: SET_GLOBAL,
    payload: globalInfo,
  };
};

/**
 * set tenant settings for individual tabs/window
 */
export const reloadTenant = (tenantInfo) => {
  return {
    type: RELOAD_TENANT,
    payload: {
      ...tenantInfo,
      loadTennantID: makeId(),
    },
  };
};

/**
 * sync tenant settings list for authorized pages across multiple open windows/tabs
 */
export const broadcastTenant = (tenantInfo) => {
  return {
    type: BROADCAST_TENANT,
    payload: {
      ...tenantInfo,
      loadTennantID: makeId(),
    },
  };
};

/**
 * clear stored tenant information
 */
export const removeTenant = () => {
  return {
    type: CLEAR_TENANT,
  };
};

/**
 * set device type
 */
export const setDeviceType = (type) => {
  return {
    type: SET_DEVICE_TYPE,
    payload: type,
  };
};
