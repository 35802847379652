import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { setBcDynamicPaths } from "../../../../../redux/actions/breadcrumb";

import { RentalDetailsService } from "../../../../../services/hrmnet-api";
import { ClaimTable } from "../../components";
import { ActionButton } from "../../components";
import { formSkeleton } from "../../utils";
import { openModal } from "../../../../../redux/actions/modal";
import { CLAIM_STATUS } from "../../components";

import { Skeleton } from "primereact/skeleton";

import "./submit-claim.scss";
import { useHistory } from "react-router";
import { Button } from "primereact/button";
import {
  EEPORTAL_LABEL_BASE_TABLE,
  FUNCTION_CODE,
  RENTAL_SUBMODULE_CODE,
} from "../../../../../constants";
import LanguageLoader from "../../../../../components/language-loader/language-loader";
import { RENTAL_T_CONTEXT_KEY } from "../../constants";
import { showSpinner } from "../../../../../redux/actions/spinner";
import { PortalLink } from "../../../../../services/utils";

const SubmitClaim = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();

  const [state, setState] = useState({
    activeStep: 0,
    table: [],
    isLoading: true,
    initData: true,
  });

  useEffect(() => {
    const loadClaimDetail = async () => {
      setState((state) => ({
        ...state,
        isLoading: true,
      }));
      const applicationId = props.match.params.applicationId;
      const claimListApi = RentalDetailsService.rentalGetClaimList({
        applicationId,
      });
      const appDetailApi = RentalDetailsService.rentalGetApplication({
        applicationId,
      });
      let [claimRes, appRes] = await Promise.all([claimListApi, appDetailApi]);

      let result = {};
      let applicationNo = "";

      if (claimRes && claimRes.data) {
        result = claimRes.data.map((x, i) => {
          return {
            ...x,
            claimPeriodFrom: x.claimPeriodFrom.substr(0, 10),
            claimPeriodTo: x.claimPeriodTo.substr(0, 10),
            id: x.id.toString(),
          };
        });
      }

      if (appRes && appRes.data) {
        applicationNo = appRes.data.applicationNo;
      }

      setState((state) => ({
        ...state,
        table: result,
        isLoading: false,
        initData: false,
        applicationNo,
      }));
    };
    if (state.initData) {
      loadClaimDetail();
    }
  }, [state.initData]);

  // Breadcrumb
  useEffect(() => {
    dispatch(setBcDynamicPaths([{ label: t("rental_claim_breadcrumb") }]));
  }, [dispatch, t]);

  const startUploadDocuments = () => {
    history.push(
      PortalLink(
        `${FUNCTION_CODE.Rental}/${RENTAL_SUBMODULE_CODE.SUBMIT_CLAIM}/${props.match.params.applicationId}/upload`
      )
    );
  };

  const backPage = () => {
    history.push(PortalLink(FUNCTION_CODE.Rental));
  };

  // render
  const renderPageTitle = () => (
    <div className="p-col-12 p-justify-between rental-submit-claim-header">
      <div className="title-group">
        <div className="title-group-main">
          <Button
            onClick={backPage}
            icon="pi pi-angle-left"
            className="p-button-rounded-lg p-button-text"
          />
          <div className="title">{t("rental_claim_title")}</div>
        </div>

        <div className="desc">
          {`${t("rental_claim_application_id")}  ${state.applicationNo}`}
        </div>
      </div>
      <ActionButton onClick={startUploadDocuments} className="start-upload-btn">
        {t("rental_claim_upload_documents")}
      </ActionButton>
    </div>
  );

  const confirmDeleteAttachment = (data) => {
    // TODO: status
    const modelContent =
      data.claimData.status === CLAIM_STATUS.APPROVED ||
      data.claimData.status === CLAIM_STATUS.VERIFIED
        ? "rental_claim_delete_document_modal_content_approved"
        : "rental_claim_delete_document_modal_content_pending";
    dispatch(
      openModal({
        title: t("rental_claim_delete_document_modal_title"),
        content: t(modelContent, {
          attachment_filename: data.fileName,
        }),
        classNameMainDialog: "confirm-message-modal",
        primaryButtonText: t("base_control_toggle_yes"),
        primaryButtonClickFn: ({ closeFn }) => {
          deleteAttachment(data);
          closeFn();
        },
        secondButtonClickFn: ({ closeFn }) => {
          closeFn();
        },
      })
    );
  };

  const deleteAttachment = async (data) => {
    try {
      dispatch(showSpinner(true));
      // api body
      const updatedData = [
        {
          id: Number(data.claimData.id),
          applicationId: data.claimData.applicationId,
          attachmentFiles: data.claimData.attachmentFiles
            .filter((a) => a.fileId !== data.fileId)
            .map((a) => ({
              uploadId: a.uploadId,
              fileId: a.fileId,
              fileName: a.fileName,
            })),
        },
      ];

      // update state table
      let updatedtable = state.table.map((c) => {
        if (c.id === data.claimData.id) {
          c.attachmentFiles = c.attachmentFiles.filter(
            (a) => a.fileId !== data.fileId
          );
        }
        return c;
      });

      // api
      const applicationId = data.claimData.applicationId;
      const api = RentalDetailsService.rentalSubmitClaims({
        applicationId,
        body: updatedData,
      });
      let [res] = await Promise.all([api]);

      if (res && res.messages?.[0]?.type !== "Error") {
        setState({
          ...state,
          initData: true,
          table: updatedtable,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(showSpinner(false));
    }
  };

  const renderClaimRecord = () => {
    return (
      <div className="claim-table-summary">
        <ClaimTable
          data={state.table}
          attachmentDeletable={true}
          deleteAttachment={(data) => {
            confirmDeleteAttachment(data);
          }}
        />
      </div>
    );
  };

  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          RENTAL_T_CONTEXT_KEY.COMMON,
          RENTAL_T_CONTEXT_KEY.SUBMIT_CLAIM,
          RENTAL_T_CONTEXT_KEY.APPROVAL, // approval claim table
        ]}
      />
      <div className="rental-submit-claim">
        {/* Page Name */}
        {!state.isLoading && renderPageTitle()}

        {/* Claim Records */}
        {state.isLoading ? <Skeleton></Skeleton> : renderClaimRecord()}
      </div>
    </>
  );
};

export default SubmitClaim;
