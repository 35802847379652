import moment from "moment";
import { BCType } from "../../../../../components/base-control/base-control";
import { Legend } from "../../components";
import { DISPLAY_UOM, NWOW_WORKFLOW_STATUS } from "../../constants/contants";
import {
  initFormAnalysisCode,
  initFormAttachment,
  NwowApplicationModel,
  NwowFormConfig,
} from "../../utils/config-helper";
import {
  AnalysisCode,
  convertToAttachmentControl,
} from "../../utils/analysis-code-helper";
import { convertToControl } from "./../../utils/analysis-code-helper";

export const initFormWithNwowApplication = (
  application: NwowApplicationModel
) => {
  let form: any = {
    $Employee: `${application.employeeName} (${application.employeeCode})`,
    $RefNo: application.referenceNo, //transactionReferenceNo
    $Status: application.status,
    NwowCode: application.nwowCode,
    NwowStartDate: moment(application.nwowFrom).format("YYYY/MM/DD"),
    NwowStartTime: moment(application.nwowFrom).format("h:mm A"),
    NwowEndDate: moment(application.nwowTo).format("YYYY/MM/DD"),
    NwowEndTime: moment(application.nwowTo).format("h:mm A"),
    Units: application.unit,
    Remarks: application.remarks,
    ApproverRemarks: application?.approverRemarks
      ? (application?.approverRemarks).join(" ")
      : "",
  };

  // analysis code
  if (application.analyses?.length) {
    const analysisCodeForm = initFormAnalysisCode(application.analyses);
    form = {
      ...form,
      ...analysisCodeForm,
    };
  }

  if (application.attachment?.length) {
    const attachmentForm = initFormAttachment(application.attachment);
    form = {
      ...form,
      ...attachmentForm,
    };
  }

  return form;
};

const nwowTypeDropdownTemplate = (option: any) => {
  if (!option) return null;
  return (
    <Legend legend="square" legendColor={option.color} text={option.label} />
  );
};

export const getBasicControl = ({
  nwowTypes,
  application,
  onSeeBalanceDetail,
  nwowCodeReadOnly = false,
  hideBalance = false,
  t,
}: {
  nwowTypes: any[];
  application: NwowApplicationModel;
  onSeeBalanceDetail: any;
  nwowCodeReadOnly: boolean;
  hideBalance: boolean;
  t: any;
}) => {
  const showTodayBalance = false;
  let unit = application.uom ? t(DISPLAY_UOM[application.uom]) : "";

  return [
    {
      key: "NwowCode",
      label: t("Nwow_form_nwowType"),
      type: BCType.select,
      enum: nwowTypes.map((nwow) => ({
        label: `${nwow.nwowCode} - ${nwow.nwowType}`,
        value: nwow.nwowCode,
        color: nwow.webColor,
      })),
      config: {
        filter: false,
        showClear: false,
        readOnly: nwowCodeReadOnly,
      },
      itemTemplate: nwowTypeDropdownTemplate,
      valueTemplate: nwowTypeDropdownTemplate,
      hasFilterEnum: false,
    },
    ...(!hideBalance
      ? [
          {
            key: "Balance",
            noLabel: true,
            componentRender: () => (
              <div className="nwow-application-balance">
                <div className="app-balance-desc">
                  {showTodayBalance
                    ? t("Nwow_submit_yourBalanceAsOfToday", {
                        nwowType: application.nwowType,
                      })
                    : t("Nwow_submit_yourBalanceAsOfYeadEnd", {
                        nwowType: application.nwowType,
                      })}
                  &nbsp;
                  <span onClick={onSeeBalanceDetail}>
                    (<u>{t("Nwow_submit_seeBalanceDetail")}</u>)
                  </span>
                </div>
                <div className="app-balance">{`${
                  showTodayBalance
                    ? application.balanceAsOfCalcDate
                    : application.balanceAsOfYearEnd
                } ${unit}`}</div>
              </div>
            ),
          },
        ]
      : []),
    {
      key: "NwowStartDate",
      label: t("Nwow_form_nwowStartDate"),
      type: BCType.date,
      dateFormat: "yy-mm-dd",
      placeholder: t("Nwow_form_select"),
      config: {
        readOnly: true,
      },
    },
    {
      key: "NwowStartTime",
      label: t("Nwow_form_nwowStartTime"),
      type: BCType.input,
      config: {
        tooltip: null,
        readOnly: true,
      },
    },
    {
      key: "NwowEndDate",
      label: t("Nwow_form_nwowEndDate"),
      type: BCType.date,
      dateFormat: "yy-mm-dd",
      placeholder: t("Nwow_form_select"),
      config: {
        readOnly: true,
      },
    },
    {
      key: "NwowEndTime",
      label: t("Nwow_form_nwowEndtime"),
      type: BCType.input,
      config: {
        tooltip: null,
        readOnly: true,
      },
    },
    {
      key: "Units",
      label: t("Nwow_form_unit"),
      type: BCType.input,
      config: {
        tooltip: null,
        readOnly: true,
      },
    },
    {
      key: "Remarks",
      label: t("Nwow_form_remarks"),
      type: BCType.textarea,
      config: {
        autoResize: true,
        rows: 1,
        tooltip: null,
        readOnly: true,
      },
      placeholder: t("Nwow_form_entryRemarks"),
    },
    ...(application.workflowStatus === NWOW_WORKFLOW_STATUS.COMPLETE ||
    application.workflowStatus === NWOW_WORKFLOW_STATUS.REJECT
      ? [
          {
            key: "ApproverRemarks",
            label: t("Nwow_form_approverRemarks"),
            type: BCType.textarea,
            config: {
              tooltip: null,
              readOnly: true,
            },
          },
        ]
      : []),
  ];
};

export const getAllControls = ({
  nwowTypes: nwowTypes,
  nwowTypeDropdownTemplate,
  application,
  onSeeBalanceDetail,
  nwowCodeReadOnly: nwowCodeReadOnly,
  hideBalance,
  t,
}: {
  nwowTypes: any[];
  nwowTypeDropdownTemplate: any;
  application: NwowApplicationModel;
  onSeeBalanceDetail: any;
  nwowCodeReadOnly: boolean;
  hideBalance: boolean;
  t: any;
}) => {
  const _application = { ...application };

  const basic = getBasicControl({
    nwowTypes,
    application: _application,
    onSeeBalanceDetail,
    nwowCodeReadOnly: nwowCodeReadOnly,
    hideBalance,
    t,
  });

  const controls = basic;

  // analysis code
  if (_application.analyses?.length) {
    _application.analyses.forEach((app) => {
      let newControl: any = convertToControl({
        data: app,
        t,
      });
      newControl = {
        ...newControl,
        config: {
          ...newControl.config,
          readOnly: true,
        },
        required: false,
      };
      controls.push(newControl);
    });
  }

  // TODO notes

  // attachments
  if (_application.attachment?.length) {
    const attachmentControlSet = new Set();
    _application.attachment.forEach((attachment) => {
      if (!attachmentControlSet.has(attachment.code)) {
        attachmentControlSet.add(attachment.code);
        const newControl = convertToAttachmentControl({
          config: {
            typeCode: attachment.code,
            description: attachment.description,
          },
          t,
          controlConfig: {
            config: {
              readOnly: true,
            },
          },
        });
        controls.push(newControl as any);
      }
    });
  }

  return controls;
};
