import "./auto-complete-control.scss";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  BCProps,
  BCStates,
  isError,
  renderError,
  validateControl,
} from "../base-control";
import { useTranslation } from "react-i18next";
import {
  AutoComplete,
  AutoCompleteChangeParams,
  AutoCompleteCompleteMethodParams,
  AutoCompleteSelectParams,
} from "primereact/autocomplete";
import { Button } from "primereact/button";
import { GetArrayDepth } from "./auto-complete-control-utils";

export interface AutoCompleteControlProps extends BCProps {
  config?: any;
  /**
   * data options
   */
  enum?: any[];
}
export interface AutoCompleteControlState extends BCStates {}

export interface AutoCompleteSuggestionGroupProps {
  code: string;
  name: string;
}

export interface AutoCompleteSuggestionProps {
  code: string;
  name: string;
  items?: AutoCompleteSuggestionProps[];
}

const AutoCompleteControl: React.FC<AutoCompleteControlProps> = (props) => {
  let { config } = props;
  const { t } = useTranslation();
  // extract props
  const ruleList = props.ruleList || [];
  if (props.required) {
    ruleList.push({
      name: "required",
    });
  }

  // State
  let initState: AutoCompleteControlState = {
    touched: false,
    value: props.value || "",
    valueStr: (props.value || "").toString(),
    controlState: {
      invalid: false,
    },
  };
  initState.controlState =
    props.controlState || validateControl(ruleList || [], initState.value, t);
  const [state, setState] = useState(initState);
  const filteredSuggestionInit = Array.isArray(props.enum)
    ? [...props.enum]
    : [];
  const [filteredSuggestion, setFilteredSuggestion] = useState(
    filteredSuggestionInit
  );
  const mountedRef = useRef(true);
  // unsubcribe
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  // Update state if control state changed
  useEffect(() => {
    const ruleList = props.ruleList || [];
    if (props.required) {
      ruleList.push({
        name: "required",
      });
    }

    let controlState =
      props.controlState || validateControl(ruleList || [], props.value, t);
    if (!mountedRef.current) return;
    setState({
      ...state,
      value: props.value,
      setDefault: true,
      controlState,
    });

    if (Array.isArray(props.enum) && GetArrayDepth(props.enum) > 1) {
      if (!config.optionGroupLabel) config.optionGroupLabel = "name";
      if (!config.optionGroupChildren) config.optionGroupChildren = "items";
      if (!config.field) config.field = "name";
      if (!config.optionGroupTemplate)
        config.optionGroupTemplate = groupedItemTemplate;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.controlState, props.required, props.value]);

  const groupedItemTemplate = (item: any) => {
    return (
      <div className="flex align-items-center country-item">
        <div>{item.name}</div>
      </div>
    );
  };

  const onChange = async (
    e: AutoCompleteChangeParams,
    updateLastDefault = false
  ) => {
    const data = e.value ?? "";
    const _value = e.value?.code ?? data;
    const controlState = validateControl(ruleList, data, t);
    let _state = {
      ...state,
      value: data,
      valueStr: _value,
      controlState,
      loading: false,
    } as any;
    if (props.onChange) {
      props.onChange({
        controlState: _state.controlState,
        value: _value,
        valueStr: _value,
      });
    }
    if (props.onTrueUpdateValue) {
      props.onTrueUpdateValue({
        controlState: _state.controlState,
        value: _value,
        valueStr: _value,
        mode: "onChange",
      });
    }

    setState(_state);
  };

  const onSelect = useCallback(
    async (e: AutoCompleteSelectParams, updateLastDefault = false) => {
      const data = e.value ?? "";
      const _value = e.value?.code ?? data;
      let _state = {
        ...state,
        value: data,
        valueStr: _value,
        loading: false,
      } as any;

      if (props.onTrueUpdateValue) {
        props.onTrueUpdateValue({
          controlState: _state.controlState,
          value: _value,
          valueStr: _value,
          mode: "onSelect",
        });
      }

      setState(_state);
    },
    []
  );

  const searchSuggestion = (event: AutoCompleteCompleteMethodParams) => {
    setTimeout(() => {
      let _filteredSuggestion = OnFilterSuggestion(
        event.query,
        filteredSuggestionInit,
        filteredSuggestion
      );

      setFilteredSuggestion(_filteredSuggestion);
    }, 250);
  };

  const cachedComponent = useMemo(() => {
    return (
      <AutoComplete
        field="name"
        value={state.value}
        completeMethod={searchSuggestion}
        suggestions={filteredSuggestion}
        onChange={onChange}
        onSelect={onSelect}
        {...config}
      />
    );
  }, [config, state, filteredSuggestion]);

  return (
    <>
      <div
        className={`auto-complete-inner p-field ${
          props.noLabel ? "no-label" : ""
        }`}
      >
        <label htmlFor={props.id}>
          {props.label}
          {props.required && !props.noRequiredLabel ? (
            <small className="required p-invalid">&nbsp;*</small>
          ) : null}
          {props.tooltip ? (
            <Button
              type="button"
              tooltip={props.tooltip}
              tooltipOptions={{ position: "top" }}
              icon="pi pi-info-circle"
              className="p-button-rounded label-help p-button-text p-button-plain"
            />
          ) : null}
        </label>
        <div
          className={`p-inputgroup ${
            isError(state, props) ? "p-inputgroup-error" : ""
          }`}
        >
          {cachedComponent}
        </div>
        {props.hintBottom && (
          <div className={"control-hint-bottom"}>{props.hintBottom}</div>
        )}
        {renderError(state, props, t)}
      </div>
    </>
  );
};

function OnFilterSuggestion(
  query: string,
  suggestions: any[],
  filteredSuggestion: any[]
) {
  let _filteredSuggestions = [];
  let _suggestion = suggestions;
  if (query.trim().length > 0) {
    //when suggestions is in group mode
    for (let suggestion of _suggestion) {
      if (suggestion.items) {
        let filteredItems = suggestion.items.filter(
          (item: AutoCompleteSuggestionProps) =>
            item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
        if (filteredItems && filteredItems.length) {
          _filteredSuggestions.push({
            ...suggestion,
            ...{ items: filteredItems },
          });
        }
      }
    }

    //when suggestions is in single array
    if (_filteredSuggestions && _filteredSuggestions.length === 0) {
      _filteredSuggestions = _suggestion.filter((x) => {
        return x.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      });
    }

    //fallback
    if (_filteredSuggestions && _filteredSuggestions.length === 0) {
      _filteredSuggestions = filteredSuggestion;
    }
  }

  if (!query.trim().length) {
    _filteredSuggestions = _suggestion;
  }

  return _filteredSuggestions;
}

export default AutoCompleteControl;
