import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  BLOB,
  DEFAULT_FILE_NAME,
  SUGGESTED_FILENAME,
} from "../../../../constants";
import {
  DownloadTemplatesService,
  UtilityService,
} from "../../../../services/hrmnet-api";
import { AdminUploader } from "../admin-container";
import "./employee-configuration.scss";
import { saveAs } from "file-saver";
import { useDispatch } from "react-redux";
import { resetBcDynamicPaths } from "../../../../redux/actions/breadcrumb";

const EmployeeConfiguration = () => {
  const documentUploader = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const fileUpload = {};

  const modal = {
    title: t("employee_configuration_model_title"),
    content: t("employee_configuration_model_content"),
    primaryButtonText: t("employee_configuration_model_confirm"),
  };

  const templateFileId = "xvKtz2cTdX1OMuwxI5WmfJ4aFHFmlf9Num2CQ+dyL7I=";

  // ------------ useEffect ---------------------------------------------------
  useEffect(() => {
    dispatch(resetBcDynamicPaths());
  }, [dispatch]);

  const submitAction = async (file) => {
    try {
      const res = await UtilityService.utilityUploadEmployeeMyInfo(
        {
          file: file,
        },
        {
          suppressErrorMsg: true,
        }
      );
      if (res) {
        if (res.Message && res.TraceId) {
          documentUploader.current.setError(res.Message);
        } else if (res.messages && res.messages.length > 0) {
          res.messages.forEach((message) => {
            documentUploader.current.setMessage(message.text);
          });
        } else if (res.data && res.data.status) {
          documentUploader.current.setMessage(
            t("employee_configuration_uploader_message_success")
          );
        }
      }
    } catch (error) {
      documentUploader.current.setError(
        t("employee_configuration_uploader_message_error")
      );
    }
  };

  async function GetFileDownload(filename) {
    const axiosCallback = (resp) => {
      filename = resp.headers[SUGGESTED_FILENAME];
    };
    var res =
      await DownloadTemplatesService.downloadTemplateGetEmployeeUploadTemplate(
        {},
        {
          responseType: BLOB,
          callback: axiosCallback,
        }
      );

    if (res) {
      saveAs(res, filename);
    }
  }

  const container = {
    id: "employee-configuration",
    title: t("employee_configuration_container_title"),
    toolbar: (
      <>
        <div className="panel-header">
          <div className="title">
            {t("employee_configuration_container_description")}
          </div>
          <button
            onClick={() => GetFileDownload(DEFAULT_FILE_NAME.DOCUMENT)}
            className="p-button p-component multi-step-form-show-more-btn"
          >
            <span className="p-button-label p-c">
              {t("employee_configuration_container_button")}
            </span>
          </button>
        </div>
      </>
    ),
  };

  return (
    <AdminUploader
      config={{ container: container, fileUpload: fileUpload, modal: modal }}
      submitAction={submitAction}
      ref={documentUploader}
    />
  );
};

export default EmployeeConfiguration;
