import "./bank-account.scss";

import { Animated } from "react-animated-css";
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Skeleton } from "primereact/skeleton";

import BaseForm from "./../../../../../components/base-form/base-form";

// services
import { EmployeeDetailsService } from "./../../../../../services/hrmnet-api";
import { EmployeeTabConfigurationService } from "./../../../../../services/hrmnet-api";

//components
import {
  MyInfoSkeleton,
  FormControlsGenerator,
  ToPascalCase,
  GetControlType,
} from "./components/utils";
import SectionWithDataArray from "./components/section-with-data-array";
import DataArrayWithSections from "./components/data-array-with-sections";
import { PortalSystemErrorLink } from "../../../../../services/utils";

const BankAccount = (props) => {
  let history = useHistory();
  const { t } = useTranslation();
  const [state, setState] = useState({
    loading: true,
    i18nConstant: "eeinfo_bnk_",
    hideTooltip: t("common_reveal"),
    sectionWithChild: [
      // { sectionCode: "BNK_BASIC", dataKey: "all" }
    ],
    childWithSection: [],
    sections: [],
  });

  // TODO - the masking is dynamic, we need to update this to use Map()
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map
  const [mapMasked, setMapMasked] = useState(new Map([]));
  const mountedRef = useRef(true);
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    const loadData = async () => {
      // const apiRequestParam = await ApiRequest.createParam();
      let enumData = {};

      try {
        const cmd2 =
          EmployeeTabConfigurationService.employeeConfigurationGetBankDetailTabConfigurations();

        const cmd3 = EmployeeDetailsService.employeeGetBankDetails();

        const [res2, res3] = await Promise.all([cmd2, cmd3]);

        let data = {};
        let sections = [];
        let currSection = [];
        let cSections = [];

        if (res3 && res3.data) {
          //TODO: conduct manual data mapping
          /* new API JSON format
        accountNo: null
        bankAccountName: null
        bankCode: null
        branchCode: null
        employerAccountCode: null
        employerBankCode: null
        employerBranchCode: null
        employerDescription: null 
         */
          data = res3.data;
          // data["erAccountCode"] = res3.data?.employerAccountCode;
          // data["erBankCode"] = res3.data?.employerBankCode;
          // data["erBranchCode"] = res3.data?.employerBranchCode;
          // data["erBankAccountDescription"] = res3.data?.employerDescription;
        }

        if (res2 && res2.data) {
          sections = res2.data;
          if (sections.length > 0) {
            currSection = sections.map((s, sIndex) => {
              let keyToPascalFields = [];
              if (s.fields.length > 0) {
                const visiblefields = s.fields.filter(
                  (x) => x.isVisible === true
                );
                keyToPascalFields = visiblefields.map((f, fIndex) => {
                  const pcKey = ToPascalCase(f.key);
                  if (f.isVisible) {
                    return {
                      ...f,
                      key: pcKey,
                      controlType: GetControlType(data[pcKey], pcKey),
                      fieldConfig: {
                        isVisible: f.isVisible,
                        isMasked: f.isMasked,
                        isEditable: f.isEditable,
                      },
                    };
                  }
                  return {};
                });
              }

              return {
                ...s,
                fields: keyToPascalFields,
              };
            });
          }
          sections = currSection.filter((x) => x.fields.length > 0);
        }

        if (sections.length === 0 || sections === undefined) {
          props.setTabViewVisibility(true);
          history.go(-1);
        } else if (
          state.childWithSection.length > 0 &&
          sections !== undefined &&
          sections.length > 0
        ) {
          state.childWithSection.forEach((e) => {
            const filteredArray = sections.filter((value) =>
              e.sectionCodes.includes(value.sectionCode)
            );
            sections = sections.filter((x) => !filteredArray.includes(x));
            cSections.push({
              sections: filteredArray,
              data: e.dataKey === "all" ? data : data[e.dataKey],
              title: e.title,
              childTitle: e.childTitle,
            });
          });
        }

        let _state = {
          ...state,
          enumData,
          data: data,
          sections: sections,
          cSections: cSections,
          loading: false,
        };
        setState(_state);
      } catch (error) {
        history.push(PortalSystemErrorLink());
      }
    };
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *
   * @param {*} clsName
   * @param {*} controlName
   * @returns
   */
  const bankAccoutColConfig = (clsName, controlName) => {
    return {
      config: {
        className: clsName,
      },
      control: controlName,
    };
  };

  /**
   *
   */
  const bankField = (fields) => {
    return {
      controls: fields.length > 0 || fields !== undefined ? fields : null,
      layout:
        fields.length === 0 || fields === undefined
          ? {}
          : {
              rows: [
                //[View_Section_ID] = 36
                {
                  columns: [
                    bankAccoutColConfig("p-col-12 p-md-4 p-lg-4", "accountNo"),
                    bankAccoutColConfig("p-col-12 p-md-4 p-lg-4", "bankCode"),
                    bankAccoutColConfig("p-col-12 p-md-4 p-lg-4", "branchCode"),
                  ],
                },
                {
                  columns: [
                    bankAccoutColConfig(
                      "p-col-12 p-md-12 p-lg-4",
                      "bankAccountName"
                    ),
                    bankAccoutColConfig(
                      "p-col-12 p-md-6 p-lg-4",
                      "employerBankCode"
                    ),
                    bankAccoutColConfig(
                      "p-col-12 p-md-6 p-lg-4",
                      "employerAccountCode"
                    ),
                    bankAccoutColConfig(
                      "p-col-12 p-md-6 p-lg-4",
                      "employerBranchCode"
                    ),
                  ],
                },
                {
                  columns: [
                    bankAccoutColConfig(
                      "p-col-12 p-md-12 p-lg-12",
                      "employerDescription"
                    ),
                  ],
                },
                // [View_Section_ID] = N/A - R2 only //not in db
                {
                  columns: [
                    bankAccoutColConfig("p-col-12 p-md-4 p-lg-2", "employeeId"),
                    bankAccoutColConfig("p-col-12 p-md-4 p-lg-2", "eeBankAcId"),
                    bankAccoutColConfig("p-col-12 p-md-4 p-lg-2", "payeeId"),
                  ],
                },
                {
                  columns: [
                    bankAccoutColConfig(
                      "p-col-12 p-md-6 p-lg-4",
                      "accountCurrency"
                    ),
                    bankAccoutColConfig(
                      "p-col-12 p-md-6 p-lg-2",
                      "defaultAccount"
                    ),
                  ],
                },
                {
                  columns: [
                    bankAccoutColConfig(
                      "p-col-12 p-md-6 p-lg-4",
                      "foreignCurrencyBankAccount"
                    ),
                    bankAccoutColConfig(
                      "p-col-12 p-md-6 p-lg-4",
                      "transactionReference"
                    ),
                  ],
                },
                {
                  columns: [
                    bankAccoutColConfig("p-col-12 p-md-12 p-lg-12", "remarks"),
                  ],
                },
              ],
            },
    };
  };

  const renderSection = (index, sectionTitle, fields, sectionCode) => {
    return (
      <div id={index} key={index}>
        <div className="c-group-title ep-language-toogle">
          <p>
            {t(
              state.i18nConstant +
                sectionTitle.replace("-", "_").replace(/ /g, "").toLowerCase()
            )}
          </p>
        </div>
        {
          <div className="c-group-panel">
            {state.sectionWithChild.findIndex(
              (x) => x.sectionCode === sectionCode
            ) > -1 ? (
              // <></>
              <SectionWithDataArray
                currIndex={index}
                noDataLabel={t("common_noData")}
                cData={
                  state.sectionWithChild.find(
                    (x) => x.sectionCode === sectionCode
                  ).dataKey === "all"
                    ? state.data
                    : state.data?.[
                        state.sectionWithChild.find(
                          (x) => x.sectionCode === sectionCode
                        ).dataKey
                      ]
                } //MANUAL, need to change to auto
                readOnly={props.readOnly ?? true}
                panelTitle={t(
                  state.i18nConstant +
                    sectionTitle
                      .replace("-", "_")
                      .replace(/ /g, "")
                      .toLowerCase()
                )}
                formStructure={bankField}
                fields={fields}
                hideTooltip={t(state.hideTooltip)}
                enumData={state.enumData}
                t={t}
                i18nConstant={state.i18nConstant}
              />
            ) : (
              // <></>
              <BaseForm
                id={"employeeContact" + index}
                config={bankField(
                  FormControlsGenerator(
                    fields,
                    t(state.hideTooltip),
                    mapMasked,
                    setMapMasked,
                    state.enumData,
                    t,
                    state.i18nConstant
                  )
                )}
                readOnly={props.readOnly ?? true}
                form={state.data}
              />
            )}
          </div>
        }
      </div>
    );
  };

  const renderContent = (
    <Animated
      animationIn="slideInRight"
      animationOut="slideOutRight"
      animationInDuration={200}
      animationOutDuration={200}
      isVisible={true}
    >
      {state.sections === null ? (
        { MyInfoSkeleton }
      ) : (
        <>
          {state?.cSections?.map((item, index) => {
            // console.log("--index--", index, state?.cSections);
            return (
              <div id={index} key={index}>
                <div className="c-group-title ep-language-toogle">
                  <p>
                    {t(
                      state.i18nConstant +
                        item.title
                          .replace("-", "_")
                          .replace(/ /g, "")
                          .toLowerCase()
                    )}
                  </p>
                </div>
                <div className="c-group-panel">
                  <DataArrayWithSections
                    cData={item.data}
                    sections={item.sections}
                    noDataLabel={t("common_noData")}
                    formStructure={bankField}
                    i18nConstant={state.i18nConstant}
                    hideTooltip={state.hideTooltip}
                    panelTitle={t(state.i18nConstant + item.childTitle)}
                    readOnly={state.readOnly ?? true}
                    enumData={state.enumData}
                  />
                </div>
              </div>
            );
          })}
          {state?.sections?.map((section, index) =>
            renderSection(
              index,
              "eebankac_" + section.sectionName,
              section.fields,
              section.sectionCode
            )
          )}
        </>
      )}
    </Animated>
  );
  return <>{state.loading ? <Skeleton /> : renderContent}</>;
};

export default BankAccount;
