import { SCHEDULER_TYPE } from "../../../../constants";

export const InitEmailReminderForm = {
  // scheduleId: 0,
  // description: "testing",
  // subject: "testing",
  // content: "this is to remind there is pending application approval",
  // cCEmails: "hhk001@hk.tricorglobal.com",
  // bccEmails: "hhk007@hk.tricorglobal.com",
  // employeeCodes: ["DEMO003", "MD001"],
  // schedule: "testing",

  // sendDate: new Date(),
  // sendTime: new Date(),

  // companyCodes: undefined,
  // recepientTemplate: undefined,
  // templateName: undefined,

  enabled: false,
  schedule: SCHEDULER_TYPE.IMMEDIATE,
};

export function EmailReminderFormApiSteps(t) {
  return {
    data: [
      {
        displaySequence: 1,
        id: 1,
        sections: [
          {
            displaySequence: 2,
            isEditable: true,
            sectionId: 100,
            sectionName: t("Email_Reminder_Formname_Section1"),
            translationKey: "Email_Reminder_Formname_Section1",
          },
          {
            displaySequence: 3,
            isEditable: true,
            sectionId: 200,
            sectionName: t("Email_Reminder_Formname_Section2"),
            translationKey: "Email_Reminder_Formname_Section2",
          },
          {
            displaySequence: 1,
            isEditable: true,
            sectionId: 300,
            sectionName: t("Email_Reminder_Formname_Section3"),
            translationKey: "Email_Reminder_Formname_Section3",
          },
        ],
      },
    ],
  };
}

export function EmailReminderInitForm(t) {
  return {
    data: {
      id: 1000,
      sections: [
        {
          defaultExpanded: true,
          displayLayout: 2,
          displaySequence: 2,
          id: 100,
          isDefaultHide: false,
          fields: [
            {
              controlType: "DropDownListMultiselect",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 1,
              fieldLabel: t("Email_Reminder_Companies"),
              fieldName: "companyCodes",
              id: 1,
              inputName: "companyCodes",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-col-12",
              translationKey: "Email_Reminder_Companies",
              viewSectionId: 100,
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              dependentFields:
                '[{"Value":"^.{0,0}$","Action":"GroupFilterEnum","Fields":"employeeCodes", "Sections":""}, {"Value":"^.{1,}$","Action":"GroupFilterEnum","Fields":"employeeCodes", "Sections":""}]',
            },
            {
              controlType: "DropDownListMultiselect",
              dataType: "string",
              displaySequence: 2,
              fieldGroup: 1,
              fieldLabel: t("Email_Reminder_Employees"),
              fieldName: "employeeCodes",
              id: 1,
              inputName: "employeeCodes",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-col-12",
              translationKey: "Email_Reminder_Employees",
              viewSectionId: 100,
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              dependentFields:
                '[{"Value":"^.{0,0}$","Action":"Hide","Fields":"", "Sections":""}, {"Value":"^.{1,}$","Action":"Show","Fields":"", "Sections":""}]',
            },
            {
              controlType: "Attachment",
              dataType: "number",
              displaySequence: 1,
              fieldGroup: 4,
              fieldLabel: t("Email_Reminder_RecipientTemplate"),
              fieldName: "recepientTemplate",
              id: 7,
              inputName: "recepientTemplate",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "Int32",
              styleClass: "p-col-12",
              translationKey: "Email_Reminder_RecipientTemplate",
              styleClass: "p-lg-12 p-md-12 p-sm-12",
              config: {
                htmlToolBar: `<button style="padding: 0;" type="button" onClick="onSubmitRecipientTemplate" class="p-button p-component p-button next-button">${t(
                  "Email_Reminder_SubmitRecipientTemplate"
                )}</button>`,
              },
            },
          ],
        },
        {
          defaultExpanded: true,
          displayLayout: 2,
          displaySequence: 3,
          id: 200,
          isDefaultHide: false,
          fields: [
            {
              controlType: "DropDownList",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 1,
              fieldLabel: t("Email_Reminder_TemplateName"),
              fieldName: "templateName",
              id: 2,
              inputName: "templateName",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-col-12",
              translationKey: "Email_Reminder_TemplateName",
              viewSectionId: 100,
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              eventFunctionName: "onTemplateNameSelected",
            },
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 2,
              fieldGroup: 1,
              fieldLabel: t("Email_Reminder_description"),
              fieldName: "description",
              id: 2,
              inputName: "description",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-col-12",
              translationKey: "Email_Reminder_description",
              viewSectionId: 100,
              styleClass: "p-lg-6 p-md-12 p-sm-12",
            },
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 2,
              fieldLabel: t("Email_Reminder_Subject"),
              fieldName: "subject",
              id: 4,
              inputName: "subject",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-col-12",
              translationKey: "Email_Reminder_Subject",
              styleClass: "p-lg-12 p-md-12 p-sm-12",
            },
            {
              controlType: "KendoEditor",
              dataType: "string",
              displaySequence: 2,
              fieldGroup: 2,
              fieldLabel: t("Email_Reminder_EmailContent"),
              fieldName: "content",
              id: 3,
              inputName: "content",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-col-12",
              translationKey: "Email_Reminder_EmailContent",
              viewSectionId: 200,
              styleClass: "p-lg-12 p-md-12 p-sm-12",
              config: {
                // customInsertListMultiple: [
                //   {
                //     id: "email-configuration/template-fields",
                //     placeholder: t(
                //       "Email_Template_Configuration_TemplateField_Placeholder"
                //     ),
                //   },
                // ],
              },
            },
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 3,
              fieldLabel: t("Email_Reminder_ccList"),
              fieldName: "ccList",
              id: 5,
              inputName: "ccList",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-col-12",
              translationKey: "Email_Reminder_ccList",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              config: {
                maxLength: 600,
              },
            },
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 2,
              fieldGroup: 3,
              fieldLabel: t("Email_Reminder_bccList"),
              fieldName: "bccList",
              id: 6,
              inputName: "bccList",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-col-12",
              translationKey: "Email_Reminder_bccList",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              config: {
                maxLength: 600,
              },
            },
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 4,
              fieldLabel: t("Email_Reminder_runTestEmail"),
              fieldName: "runTestEmail",
              id: 8,
              inputName: "runTestEmail",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-col-12",
              translationKey: "Email_Reminder_runTestEmail",
              // eventFunctionName: "onTestEmailChange",
              // dependentFields:
              //   '[{"Value":"undefined","Action":"Hide","Fields":"runTestButton", "Sections":""}, {"Value":"","Action":"Hide","Fields":"runTestButton", "Sections":""}]',
              dependentFields:
                '[{"Value":"^.{0,0}$","Action":"Hide","Fields":"runTestButton", "Sections":""}, {"Value":"^.{1,}$","Action":"Show","Fields":"runTestButton", "Sections":""}]',
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              config: {
                maxLength: 600,
              },
            },
            {
              controlType: "Hint",
              dataType: "string",
              displaySequence: 2,
              fieldGroup: 4,
              fieldName: "runTestButton",
              id: 9,
              inputName: "runTestButton",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: true,
              isHtmlField: true,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-col-12",
              value: `<button style="margin-top: 23px;" onClick="onSendTestEmail" class="p-button p-component p-button next-button">${t(
                "Email_Reminder_runTestButton"
              )}</button>`,
              styleClass: "p-lg-6 p-md-12 p-sm-12",
            },
          ],
        },
        {
          defaultExpanded: true,
          displayLayout: 2,
          displaySequence: 1,
          id: 300,
          isDefaultHide: false,
          fields: [
            {
              controlType: "DropDownList",
              dataType: "string",
              displaySequence: 2,
              fieldGroup: 1,
              fieldLabel: t("Email_Reminder_schedule"),
              fieldName: "schedule",
              id: 8,
              inputName: "schedule",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-col-12",
              translationKey: "Email_Reminder_schedule",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              dependentFields:
                '[{"Value":"Immediate","Action":"Hide","Fields":"sendDate,sendTime,unit", "Sections":""}, {"Value":"Scheduled","Action":"Show","Fields":"sendDate,sendTime,unit", "Sections":""}]',
              eventFunctionName: "onScheduleChange",
            },
            {
              controlType: "DatePicker",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 2,
              fieldLabel: t("Email_Reminder_Date"),
              fieldName: "sendDate",
              id: 1,
              inputName: "sendDate",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-col-12",
              translationKey: "Email_Reminder_Date",
              viewSectionId: 100,
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              config: {
                includeTimeZone: true,
                minDate: new Date(),
              },
              eventFunctionName: "onSendDateChange",
            },
            {
              controlType: "TimePicker",
              dataType: "string",
              displaySequence: 2,
              fieldGroup: 2,
              fieldLabel: t("Email_Reminder_Time"),
              fieldName: "sendTime",
              id: 2,
              inputName: "sendTime",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-col-12",
              translationKey: "Email_Reminder_Time",
              viewSectionId: 100,
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              eventFunctionName: "onSendTimeChange",
              config: {
                minDate: new Date(),
              },
            },
            {
              controlType: "DropDownList",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 3,
              fieldLabel: t("Email_Reminder_unit"),
              fieldName: "unit",
              id: 3,
              inputName: "unit",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-col-12",
              translationKey: "Email_Reminder_unit",
              viewSectionId: 100,
              // dropdownOptions: [
              //   {
              //     name: "Monthly",
              //     description: "Monthly",
              //     value: "Monthly",
              //   },
              //   {
              //     name: "Weekly",
              //     description: "Weekly",
              //     value: "Weekly",
              //   },
              //   {
              //     name: "Daily",
              //     description: "Daily",
              //     value: "Daily",
              //   },
              //   {
              //     name: "Every hour",
              //     description: "hour",
              //     value: "hour",
              //   },
              // ],
              styleClass: "p-lg-6 p-md-12 p-sm-12",
            },
            {
              controlType: "Toggle",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 1,
              fieldLabel: t("Email_Reminder_isEnabled"),
              fieldName: "enabled",
              id: 3,
              inputName: "enabled",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-col-12",
              translationKey: "Email_Reminder_isEnabled",
              viewSectionId: 100,
              dependentFields:
                '[{"Value":false,"Action":"Hide","Fields":"", "Sections":""},{"Value":"undefined","Action":"Hide","Fields":"", "Sections":""}, {"Value":false,"Action":"Hide","Fields":"", "Sections":""}, {"Value":true,"Action":"Show","Fields":"", "Sections":""}]',
              styleClass: "p-lg-6 p-md-12 p-sm-12",
            },
          ],
        },
      ],
    },
  };
}
