import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  closeLoading,
  openLoading,
} from "../../../../../../redux/actions/modal";
import { store } from "../../../../../../redux/store";
import { AuthenticationService } from "../../../../../../services/hrmnet-api";
import { ActiveSessionDialog } from "../../login-load-info";
import { OnLogin } from "../utils";
import "./support-login.scss";

const SupportLogin = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const setLoading = (loading) => {
    if (loading) {
      return dispatch(openLoading());
    }
    dispatch(closeLoading());
  };

  const changeSession = useCallback(async (confirm) => {
    if (confirm) {
      try {
        setLoading(true);
        const res =
          await AuthenticationService.authenticationAuthenticateBySupportToken({
            body: {
              token: props.token,
            },
          });

        if (res && res.data && res.data?.isValidUser) {
          await OnLogin(res, history);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    const init = async () => {
      try {
        //check active session
        await ActiveSessionDialog(store, t, history, changeSession);
      } catch (error) {
      } finally {
        history.replace();
      }
    };

    init();

    return function cleanup() {
      setLoading();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default SupportLogin;
