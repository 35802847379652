import "./employee-error-404.scss";

import { useHistory, Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { PortalLink } from "../../../../services/utils";

const PageNotFound = () => {
  const { t } = useTranslation();
  let history = useHistory();

  return (
    <div className="error-404-bg">
      <p>
        <span className="error-404-title">404</span>

        <span className="error-404-subtitle">{t("common_error_404")}</span>
        <span className="error-404-text">
          <Trans i18nKey="common_error_404_message_1">
            <Link to={PortalLink()} />
          </Trans>

          <Trans i18nKey="common_error_404_message_2">
            <span
              className="error-404-text-message"
              onClick={history.goBack}
            ></span>
          </Trans>
        </span>
      </p>
    </div>
  );
};

export default PageNotFound;
