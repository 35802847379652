import "./main-toggle.scss";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "primereact/button";
import { setSideBar } from "../../../../redux/actions/sidebar";
import { useLocation } from "react-router-dom";

const caculateKeys = (location) => {
  const path = location.pathname;
  let split = path.split("/");
  let _expandedKeys = {};
  let leafKeys;
  let moduleCd, featureCd, functionCd;
  if (split.length >= 5) {
    moduleCd = split[2];
    functionCd = split[3];
    featureCd = functionCd.slice(0, -2);
    _expandedKeys[moduleCd] = true;
    _expandedKeys[moduleCd + "-" + featureCd] = true;
    _expandedKeys[moduleCd + "-" + featureCd + "-" + functionCd] = true;
    leafKeys = moduleCd + "-" + featureCd + "-" + functionCd;
    return {
      expandedKeys: _expandedKeys,
      leafKeys,
    };
  } else {
    return {
      expandedKeys: undefined,
      leafKeys: undefined,
    };
  }
};
function MainToggle() {
  let location = useLocation();
  const sideBarInfo = useSelector((state) => state.sidebar) || null;

  const dispatch = useDispatch();
  const toggleSidebarMobile = () => {
    const keys = caculateKeys(location);
    var isOpenSidebarMobile = !sideBarInfo.isOpenSidebarMobile;
    if (isOpenSidebarMobile) {
      document.body.classList.add("block-scroll-in-mobile");
    } else {
      document.body.classList.remove("block-scroll-in-mobile");
    }
    dispatch(
      setSideBar({
        isOpenSidebarMobile,
        expandedKeys: keys.expandedKeys,
        leafKeys: keys.leafKeys,
      })
    );
  };

  return (
    <div className="main-toggle p-justify-center p-col-align-center">
      <Button
        type="button"
        className="p-button-secondary p-button-text"
        onClick={toggleSidebarMobile}
        icon={`pi ${sideBarInfo.isOpenSidebarMobile ? "pi-times" : "pi-bars"}`}
        iconPos="right"
      />
    </div>
  );
}
export default MainToggle;
