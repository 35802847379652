import { scrollToErrorControlWithSelector } from "../../base-form/utils";

export const onInlineTableCancel = (headers, currRef) => {
  for (let value of headers ?? []) {
    const cr = currRef.find((k, index) => {
      return k?.key === value.key;
    });
    if (!!cr) {
      cr.controls.onCancel();
    }
  }
};

export const onInlineTableSubmit = (headers, currRef) => {
  let formValue = {};
  let fieldState = {
    invalid: false,
  };
  for (let value of headers ?? []) {
    const cr = currRef.find((k, index) => {
      return k?.key === value.key;
    });
    if (!!cr) {
      fieldState = cr.controls.onValidation();
      if (fieldState.invalid === true) {
        scrollToErrorControlWithSelector();
        break;
      }

      formValue[cr.key] = cr.controls.onSubmit(fieldState);
    }
  }

  return { formValue, fieldState };
};

export const onInlineTableSaveAll = (headers, currRef) => {
  let formValue = {};
  let fieldState = {
    invalid: false,
  };
  for (let value of headers ?? []) {
    const cr = currRef.find((k, index) => {
      return k?.key === value.key;
    });
    if (!!cr) {
      fieldState = cr.controls.onValidation();
      if (fieldState.invalid === true) {
        scrollToErrorControlWithSelector();
        break;
      }

      formValue[cr.key] = cr.controls.onSubmit(fieldState);
    }
  }

  return { formValue, fieldState };
};
