import "./assign-claim-control.scss";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { useWindowSize } from "../../utils/window-size/useWindowSize";
import {
  BCProps,
  isError,
} from "../../../../components/base-control/base-control";
import { getFileSizeString } from "../../../../components/base-control/upload-control/utils";
import ClaimTable from "./claim-table";

function getFileKey(f: File) {
  // Simplify file handling
  // return `${f.name}_${f.size}_${f.lastModified}`;
  return f.name;
}

export function validationControl(value: any) {
  if (!value || !Object.keys(value).length) {
    return { invalid: false };
  }

  return {
    invalid: Object.values(value).some((x: any) => !x.claimIds?.length),
  };
}

interface MonthSelectClaimModel {
  file: File;
  claimIds: any[];
  touched: boolean;
  controlState: {
    invalid: boolean;
  };
}
interface MonthSelectionModel {
  [key: string]: MonthSelectClaimModel;
}
interface AssignClaimControlProps extends BCProps {
  onChange?: any;
  onRemove?: any;
  form: any;
  relativeFormKey: any;
  openClaims: {
    claimMonths: any[];
    openMonthYears: any[];
  };
}

export const AssignClaimControl: React.FC<AssignClaimControlProps> = ({
  relativeFormKey,
  openClaims,
  ...props
}) => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const form = useSelector((state: any) => state.rental.form);

  // extract props data
  const id = props.id || "";
  const lazy = props.fromFilter;

  // init state control
  let initState = {
    touched: false,
    files: [],
    value: props.value || {},
    valueStr: (props.value || "").toString(),
    controlState: {
      invalid: false,
    },
  };
  const [state, setState] = useState(initState);

  useEffect(() => {
    if (!form || !relativeFormKey) {
      return;
    }

    const filenames =
      form[relativeFormKey]
        ?.reduce((acc: "", cur: File) => [...acc, cur.name], [])
        .sort()
        .join(",") || "";
    const valueStr = state.value
      ? Object.keys(state.value).sort().join(",")
      : "";

    if (filenames !== valueStr) {
      let value = form?.[relativeFormKey]?.reduce(
        (acc: any, curr: any, idx: number) => {
          const fileKey = getFileKey(curr);
          const oldValue = state.value?.[fileKey]
            ? { ...state.value[fileKey] }
            : null;
          return {
            ...acc,
            [fileKey]: oldValue
              ? oldValue
              : {
                  file: curr,
                  claimIds: [],
                  touched: false,
                  controlState: {
                    invalid: true,
                  },
                },
          };
        },
        {}
      );
      onChange(value, valueStr, validateMainControl(value));
    }
  }, [form, relativeFormKey]);

  const validateMainControl = (value: any) => {
    if (!form || !relativeFormKey || !form[relativeFormKey]?.length) {
      return { invalid: false };
    } else {
      return {
        invalid: !!form?.[relativeFormKey]?.some(
          (f: File) => value[getFileKey(f)].claimIds.length === 0
        ),
      };
    }
  };

  const onRemove = (idx: number) => {
    if (props.onRemove) {
      props.onRemove(idx);
    }
  };

  const onChange = (value: any, valueStr: string, controlState: any) => {
    setState({
      ...state,
      touched: true,
      value,
      valueStr,
      controlState,
    });
    if (props.onChange) {
      props.onChange(value, valueStr, controlState);
    }
  };

  const onSelectMonth = (fileKey: string, value: any) => {
    let _value = state.value ? state.value : {};
    _value[fileKey].claimIds = value.sort();
    _value[fileKey].touched = true;
    _value[fileKey].controlState = {
      invalid: !value.length,
    };
    onChange(_value, JSON.stringify(value), validateMainControl(_value));
  };

  // render
  const dropdownOptions = openClaims.openMonthYears.map((claim: any) => ({
    label: claim.name,
    value: claim.value,
  }));

  const processClaimMonths = (receipts: any[]) => {
    const _value = state.value;

    const claimsNewFileMap = _value
      ? (Object.values(_value) as MonthSelectClaimModel[]).reduce(
          (acc: any, cur: MonthSelectClaimModel) => {
            cur.claimIds.forEach((claimId: any) => {
              if (!(claimId in acc)) acc[claimId] = [];
              acc[claimId].push(cur.file);
            });
            return acc;
          },
          {}
        )
      : {};

    const _receipts = receipts.map((receipt: any) => {
      let _receipt = { ...receipt };

      // add year to month
      _receipt.month = _receipt.year
        ? `${_receipt.month} ${_receipt.year}`
        : _receipt.month;

      // append new files
      if (!_receipt.attachmentFiles) _receipt.attachmentFiles = [];
      if (_receipt.id in claimsNewFileMap) {
        const newFiles = claimsNewFileMap[_receipt.id];
        _receipt.attachmentFiles = [..._receipt.attachmentFiles, ...newFiles];
      }
      return _receipt;
    });
    return _receipts;
  };

  const renderTable = () => {
    if (props.config?.readOnly) {
      processClaimMonths(openClaims?.claimMonths);
      return (
        <ClaimTable
          data={processClaimMonths(openClaims?.claimMonths)}
          attachmentDeletable={false}
        />
      );
    } else {
      const dropdown = (f: File) => (
        <MultiSelect
          className={`${
            state.value?.[getFileKey(f)] &&
            isError(state.value[getFileKey(f)], props) &&
            !state.value[getFileKey(f)].claimIds.length
              ? "p-invalid"
              : ""
          }`}
          display="chip"
          options={dropdownOptions}
          value={state.value?.[getFileKey(f)]?.claimIds}
          onChange={(e) => onSelectMonth(getFileKey(f), e.value)}
        />
      );

      const tableFooterTemplate = (f: any) => (
        <>
          <td>{t("rental_control_monthAndYear")}</td>
          <td colSpan={2}>{dropdown(f)}</td>
        </>
      );

      return (
        <DataTable
          emptyMessage={t("base_control_noFile")}
          className="file-download-table"
          value={form?.[relativeFormKey]?.map((f: File) => ({
            name: f.name,
            size: f.size,
          }))}
          rowGroupMode={isMobile ? "subheader" : undefined} // mobile
          groupField="name" // mobile
          rowGroupHeaderTemplate={() => (
            <div style={{ height: 1, background: "#f3f3f3" }} />
          )}
          rowGroupFooterTemplate={tableFooterTemplate}
        >
          <Column
            field="name"
            className="col-file-name"
            header={t("rental_control_attachments")}
          />
          {!isMobile && (
            <Column
              className="col-month"
              header={t("rental_control_monthAndYear")}
              body={(f) => dropdown(f)}
            />
          )}
          <Column
            className="col-file-size"
            header={!isMobile && t("rental_control_fileSize")}
            body={(f) => getFileSizeString(f.size)}
          />
          <Column
            className="action-column"
            body={(data, colProps: { rowIndex: number }) => (
              <Button
                type="button"
                icon="pi pi-times"
                className="p-button remove-button"
                onClick={() => {
                  onRemove(colProps.rowIndex);
                }}
              ></Button>
            )}
          />
        </DataTable>
      );
    }
  };

  return (
    <div className="assign-claim-control">
      {/* {isError(state, props) && <div>Error</div>} */}
      {renderTable()}
      {/* <pre>{JSON.stringify(state.value, null, 2)}</pre> */}
    </div>
  );
};

export default AssignClaimControl;
