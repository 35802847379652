import { Dropdown } from "primereact/dropdown";
import { TabMenu } from "primereact/tabmenu";
import { Animated } from "react-animated-css";
import { useDispatch, useSelector } from "react-redux";
import { useWindowSize } from "../../utils/window-size/useWindowSize";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import PageNotFound from "../../public/error/employee-error-404";
import { GetActiveMenus } from "../../../layouts/components/main-sidebar/main-menu-tree";
import { MENU_NAME } from "../../../../constants";
import EmailReminder from "./email-reminder";
import EmailTemplate from "./email-template";
import { resetBcDynamicPaths } from "../../../../redux/actions/breadcrumb";
import { useEffect } from "react";

const EmailConfiguration = ({ location }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  let match = useRouteMatch();
  const menus = useSelector((state) => state.menu.sideBar);
  const sidebar = useSelector((state) => state.sidebar);
  const tabList =
    GetActiveMenus(menus)
      .find((x) => x?.url === sidebar?.selectedKey?.split("~")[0])
      ?.subMenus?.find((x) => x?.url === sidebar?.selectedKey?.split("~")[1])
      ?.subMenus.map((x, idx) => ({
        label: x.name,
        value: idx,
        key: x.key,
        link: x.url,
      })) ?? [];

  // Breadcrumb
  useEffect(() => {
    dispatch(resetBcDynamicPaths());
  }, []);

  const renderContent = () => {
    return (
      <Animated
        animationIn="slideInRight"
        animationOut="slideOutRight"
        animationInDuration={200}
        animationOutDuration={200}
        isVisible={true}
      >
        <Switch>
          {tabList.map((x, index) => {
            return (
              <Route
                key={index}
                path={"/" + x.link}
                render={() => {
                  switch (x.key) {
                    case MENU_NAME.Email_Reminder:
                      return <EmailReminder />;

                    default:
                      return <EmailTemplate />;
                  }
                }}
                exact={true}
                strict={true}
              />
            );
          })}
          <Route path={`${match.path}`} render={() => {}} exact={true}>
            <Redirect
              to={{
                pathname: tabList.length > 0 ? "/" + tabList[0]?.link : "",
              }}
            />
          </Route>
        </Switch>
      </Animated>
    );
  };

  const renderTabSelect = () => {
    return (
      <div className="menu-tab">
        <div className="p-d-md-none dropdown-wrapper">
          {tabList && (
            <Dropdown
              value={
                tabList.find((x) => location.pathname.startsWith(x.link, 1))
                  ?.value
              }
              options={tabList}
              onChange={(e) => {
                history.push({
                  pathname: "/" + tabList[e.value].link,
                });
              }}
            />
          )}
        </div>
        <div className="p-d-md-block p-d-none dropdown-wrapper">
          {tabList && (
            <TabMenu
              model={tabList}
              activeIndex={tabList.findIndex((x) =>
                location.pathname.startsWith(x.link, 1)
              )}
              onTabChange={(e) => {
                history.push({
                  pathname: "/" + e.value.link,
                });
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const renderFormTitle = () => (
    <div className="form-title">
      <h2>
        {tabList.length > 1 &&
          menus
            .find((x) => x?.url === sidebar?.selectedKey?.split("~")[0])
            ?.subMenus?.find(
              (x) => x?.url === sidebar?.selectedKey?.split("~")[1]
            ).name}
        {tabList.length === 1 &&
          tabList.find((x) => location.pathname.startsWith(x.link, 1))?.label}
      </h2>
    </div>
  );

  return (
    <>
      {tabList?.length === 0 && <PageNotFound />}
      {tabList.length > 0 && (
        <div className="multimenu-style-tab">
          <div className="header header-sticky">
            {/* Title */}
            {renderFormTitle()}
          </div>
          {tabList.length > 1 && renderTabSelect()}
          {renderContent()}
        </div>
      )}
    </>
  );
};

export default EmailConfiguration;
