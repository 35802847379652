import "./azure-authentication-component.scss";
import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router";

import AzureAuthenticationContext from "./azure-authentication-context";

import { Button } from "primereact/button";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { openLoading } from "../redux/actions/modal";
import { AccountInfo } from "@azure/msal-browser";
import { useHistory } from "react-router-dom";
import { LOGOUT_PATH, SSO_INTERACTION_TYPE } from "../constants";

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const isIE = msie > 0 || msie11 > 0;

// Log In, Log Out Button
const AzureAuthenticationButton = ({
  onAuthenticated,
  msalConfig,
  interactionType,
}: any): JSX.Element => {
  // Azure client context
  const authenticationModule: AzureAuthenticationContext =
    new AzureAuthenticationContext(msalConfig);

  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const isValidUser = useSelector((state: any) => state.auth?.isValidUser);
  // let history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  //auto login when detect any account
  useEffect(() => {
    if (authenticationModule.getAccount()) {
      dispatch(openLoading());
      authenticationModule.silentLogin(onAuthenticated, setLoading, setIsError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logIn = (method: string): any => {
    dispatch(openLoading());
    onAuthenticated({ error: { errorMessage: "" } });
    const logInType = isIE ? SSO_INTERACTION_TYPE.REDIRECT : method;

    // Azure Login
    authenticationModule.login(
      logInType,
      onAuthenticated,
      setLoading,
      setIsError
    );
  };

  const logOut = (user: AccountInfo | undefined): any => {
    if (user) {
      onAuthenticated(undefined);
      // Azure Logout
      authenticationModule.logout(user);
    } else {
      history.push(`/${LOGOUT_PATH.LOGOUT}`);
    }
  };

  const showLogInButton = (): any => {
    return (
      <>
        {!loading && authenticationModule.idToken === "" && (
          <Button
            className="authentication-button"
            label={t("login_login")}
            onClick={() => logIn(interactionType ?? "loginPopup")}
          />
        )}
      </>
    );
  };

  const showLogOutButton = (): any => {
    return (
      <Button
        className="authentication-logout-button"
        label={t("employeeProfile_logout")}
        icon="pi pi-mu pi-power-off"
        iconPos="right"
        disabled={true}
        onClick={() => logOut(authenticationModule.getAccount())}
      />
    );
  };

  const showRetryButton = (): any => {
    return (
      <Button
        className="retry-message-button"
        label={"login_try_again"}
        onClick={() => {
          const account = authenticationModule.getAccount();
          if (
            authenticationModule.idToken &&
            authenticationModule.idToken.length > 0
          ) {
            onAuthenticated(
              authenticationModule.getAccount(),
              authenticationModule.idToken
            );
          } else if (account && !isValidUser) {
            dispatch(openLoading());
            authenticationModule.silentLogin(
              onAuthenticated,
              setLoading,
              setIsError
            );
          }
        }}
      />
    );
  };

  //when support endpoint returns error
  const showLogOutRetryButton = (): any => {
    return (
      <>
        {showLogOutButton()}
        {isError && showRetryButton()}
      </>
    );
  };

  const showButton = (): any => {
    return loading || isValidUser ? showLogOutRetryButton() : showLogInButton();
  };

  return (
    <div className="ep-login-container">
      {authenticationModule.isAuthenticationConfigured ? (
        showButton()
      ) : (
        <div>{t("login_authentication")}</div>
      )}
    </div>
  );
};

export default AzureAuthenticationButton;
