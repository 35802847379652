import {
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
} from "./serviceOptions";
const basePath = "";

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class AccessRightsService {
  /**
   *
   */
  static accessGetMenus(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/access/menus";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static accessGetHomeShortcuts(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/access/shortcuts";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static accessGetMobileShortcuts(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/access/mobile-shortcuts";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AdminService {
  /**
   * To get list all active company documents
   */
  static companyDocumentGetCompanyDocumentList(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/admin/company-document/list";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * To get company document by Id.
   */
  static companyDocumentGetCompanyDocumentById(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/admin/company-document/{id}";
      url = url.replace("{id}", params["id"] + "");

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * updating company documents.
   */
  static companyDocumentUpdateCompanyDocument(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Admin_CompanyDocumentRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/admin/company-document/update";

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AdminAccessPermissionService {
  /**
   *
   */
  static accessGetFiltersAsync(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/admin/access-permission/data-authorizations";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static accessGetFiltersAsync1(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/admin/access-permission/data-authorization/list";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static accessGetEmployeesAccessAsync(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/admin/access-permission/employee-accesses";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static accessSaveEmployeeAccessAsync(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Admin_EmployeePermissionRequest[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/admin/access-permission/employee-accesses";

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static accessGetEmployeesAccessAsync1(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/admin/access-permission/employee-access/list";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get all roles
   */
  static accessGetRolesAccessAsync(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/admin/access-permission/role-accesses";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update roles' authorization
   */
  static accessSaveRoleAccessAsync(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Admin_EmployeePermissionRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/admin/access-permission/role-accesses";

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static accessGetEmployeeAccessAsync(
    params: {
      /**  */
      employeeCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url =
        basePath + "/admin/access-permission/employee-access/{employeeCode}";
      url = url.replace("{employeeCode}", params["employeeCode"] + "");

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static accessGetEmployeeAccessAsync1(
    params: {
      /**  */
      employeeCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url =
        basePath + "/admin/access-permission/employee-accesses/{employeeCode}";
      url = url.replace("{employeeCode}", params["employeeCode"] + "");

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static accessSaveEmployeeAccessAsync1(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Admin_EmployeePermissionRequest[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/admin/access-permission/employee-accesses/save";

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static accessGetRoleModulesAsync(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/admin/access-permission/roles";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static accessSaveRoleModuleAsync(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Admin_RoleModuleRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/admin/access-permission/roles";

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static accessGetRoleModulesAsync1(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/admin/access-permission/role/list";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static accessGetRoleSelectOptionsAsync(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/admin/access-permission/role/selection-list";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static accessGetModulesAsync(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/admin/access-permission/modules";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static accessGetModulesAsync1(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/admin/access-permission/module/list";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static accessGetRoleModuleAsync(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/admin/access-permission/role/{id}";
      url = url.replace("{id}", params["id"] + "");

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static accessGetRoleModuleAsync1(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/admin/access-permission/roles/{id}";
      url = url.replace("{id}", params["id"] + "");

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static accessSaveRoleModuleAsync1(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Admin_RoleModuleRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/admin/access-permission/role/save";

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AdminSettingConfigurationService {
  /**
   * Get setting configuration's form
   */
  static settingConfigurationGetSettingConfigurations(
    params: {
      /**  */
      targetOrganizationType?: string;
      /**  */
      targetOrganizationName?: string;
      /**  */
      sectionId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/admin/setting/list";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = {
        targetOrganizationType: params["targetOrganizationType"],
        targetOrganizationName: params["targetOrganizationName"],
        sectionId: params["sectionId"],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static settingConfigurationGetSettingConfigurationSessionList(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/admin/setting/section-list";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update setting configurations' form in table TBLWEB_PARAMETER_SETTING
   */
  static settingConfigurationUpdateSettingConfiguration(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Admin_OrganizationalSettingConfigurationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/admin/setting/update";

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AdminLeavePolicySettingsService {
  /**
   *
   */
  static policySettingsGetPoliciesAsync(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/admin/leave/leave-policies";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static policySettingsSavePolicyAsync(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_InternalModels_LeaveModel_LeavePolicyModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/admin/leave/leave-policies";

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static policySettingsGetPolicyAsync(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/admin/leave/leave-policies/{id}";
      url = url.replace("{id}", params["id"] + "");

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static policySettingsGetModes(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/admin/leave/modes";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static policySettingsGetCutOffUoms(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/admin/leave/cut-off-uoms";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static policySettingsGetExpiryUoms(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/admin/leave/expiry-uoms";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static policySettingsGetUnitUoms(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/admin/leave/unit-uoms";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AuthenticationService {
  /**
   *
   */
  static authenticationAuthenticateByUserName(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Authentication_UserNameAuthenticationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/authentication/authenticatebyusername";

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static authenticationAuthenticateByEmail(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Authentication_EmailAuthenticationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/authentication/authenticatebyemail";

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static authenticationAuthenticateBySupportToken(
    params: {
      /**  */
      tenantName: string;
      /**  */
      entityName: string;
      /**  */
      acceptLanguage: string;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Authentication_SsoAuthenticationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/authentication/authenticatebysupporttoken";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        "Entity-Name": params["entityName"],
        "Accept-Language": params["acceptLanguage"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static authenticationAuthenticateBySsoToken(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Authentication_SsoAuthenticationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/authentication/authenticatebyssotoken";

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static authenticationAuthenticateByMfaOtp(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Authentication_OtpAuthenticationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/authentication/authenticatebyotp";

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Authenticate by biometric token
   */
  static authenticationAuthenticateByBiometricToken(
    params: {
      /**  */
      tenantName: string;
      /**  */
      entityName: string;
      /**  */
      acceptLanguage: string;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Authentication_SsoAuthenticationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/authentication/authenticatebybiometrictoken";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        "Entity-Name": params["entityName"],
        "Accept-Language": params["acceptLanguage"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static authenticationRefreshMfaOtp(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Authentication_RefreshOtpRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/authentication/refreshotp";

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static authenticationRefreshToken(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Authentication_RefreshTokenRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/authentication/refresh-token";

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static authenticationLogout(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/authentication/logout";

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ChangePasswordService {
  /**
   *
   */
  static changePasswordPasswordPolicy(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/changepassword/passwordpolicy";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Change password
   */
  static changePasswordChangePassword(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Authentication_ChangePasswordRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/changepassword";

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ConfigurationService {
  /**
   *
   */
  static configurationGetLanguages(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/configuration/languages";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static configurationGetLoginConfigurations(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/configuration/login";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ContentService {
  /**
   * Get Company Policy
   */
  static contentGetCompanyPolicy(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/content/company-policy";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get Privacy Policy
   */
  static contentGetPrivacyPolicy(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/content/privacy-policy";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get Terms and Conditions
   */
  static contentGetTermsAndConditions(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/content/terms-and-conditions";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get Assistance
   */
  static contentGetAssistance(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/content/assistance";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get FAQ
   */
  static contentGetFaq(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/content/faq";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static contentGetContentByContexts(
    params: {
      /**  */
      contexts?: string;
      /**  */
      language?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/content/getbycontexts";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = {
        contexts: params["contexts"],
        language: params["language"],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static contentGetContentByKeys(
    params: {
      /**  */
      context?: string;
      /**  */
      keys?: string;
      /**  */
      language?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/content/getbykeys";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = {
        context: params["context"],
        keys: params["keys"],
        language: params["language"],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static contentGetContentByKey(
    params: {
      /**  */
      key?: string;
      /**  */
      language?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/content/getbykey";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = { key: params["key"], language: params["language"] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static contentUpdateContentByKey(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Content_TransalationContentRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/content";

      const configs: IRequestConfig = getConfigs(
        "put",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DocumentsService {
  /**
   * Get payslip document based on employee code
   */
  static documentGetPayslips(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/document/payslips";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get tax returns document based on employee code
   */
  static documentGetTaxReturns(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/document/taxreturns";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get compensations document based on employee code
   */
  static documentGetCompensations(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/document/compensations";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get hr letters document based on employee code
   */
  static documentGetHrLetters(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/document/hr-letters";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static documentGetOthers(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/document/others";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get download single file based on file id
   */
  static documentDownloadSingleFile(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Document_DocumentDownloadRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/document/download/singlefile";

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get download single file or zip file based on file id
   */
  static documentGetFileDownload(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Document_DocumentDownloadRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/document/download";

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Upload files based on EPortal upload request
   */
  static documentUploadFiles(
    params: {
      /**  */
      folderId?: string;
      /**  */
      message: string;
      /**  */
      releaseOnDate: string;
      /**  */
      documentType: string;
      /**  */
      files: [];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/document";

      const configs: IRequestConfig = getConfigs(
        "post",
        "multipart/form-data",
        url,
        options
      );
      configs.params = { folderId: params["folderId"] };

      let data = null;
      data = new FormData();
      if (params["message"]) {
        if (
          Object.prototype.toString.call(params["message"]) === "[object Array]"
        ) {
          for (const item of params["message"]) {
            data.append("Message", item as any);
          }
        } else {
          data.append("Message", params["message"] as any);
        }
      }

      if (params["releaseOnDate"]) {
        if (
          Object.prototype.toString.call(params["releaseOnDate"]) ===
          "[object Array]"
        ) {
          for (const item of params["releaseOnDate"]) {
            data.append("ReleaseOnDate", item as any);
          }
        } else {
          data.append("ReleaseOnDate", params["releaseOnDate"] as any);
        }
      }

      if (params["documentType"]) {
        if (
          Object.prototype.toString.call(params["documentType"]) ===
          "[object Array]"
        ) {
          for (const item of params["documentType"]) {
            data.append("DocumentType", item as any);
          }
        } else {
          data.append("DocumentType", params["documentType"] as any);
        }
      }

      if (params["files"]) {
        if (
          Object.prototype.toString.call(params["files"]) === "[object Array]"
        ) {
          for (const item of params["files"]) {
            data.append("Files", item as any);
          }
        } else {
          data.append("Files", params["files"] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static documentGetFolders(
    params: {
      /**  */
      upload?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/document/folders";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = { upload: params["upload"] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static documentGetDocumentsByFolderId(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Document_FolderDocumentRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/document/documents-by-folderid";

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static documentGetFilesByUploadId(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Document_UploadFileRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/document/files-by-uploadid";

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static documentGetDocumentType(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/document/document-type";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DownloadTemplatesService {
  /**
   * Download configuration template file for employees upload template
   */
  static downloadTemplateGetEmployeeUploadTemplate(
    params: {
      /**  */
      tenantName: string;
      /**  */
      entityName: string;
      /**  */
      acceptLanguage: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/download/template/employees-upload-template";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        "Entity-Name": params["entityName"],
        "Accept-Language": params["acceptLanguage"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class EmailConfigurationService {
  /**
   * Get a list of email template dynamic fields
   */
  static emailConfigurationGetTemplateFields(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/admin/email-configuration/template-fields";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get a list of email templates
   */
  static emailConfigurationGetEmailTemplates(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/admin/email-configuration/email-templates";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get a list of email templates for dropdownlist
   */
  static emailConfigurationGetEmailTemplateList(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/admin/email-configuration/email-template/list";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get a email template by Id
   */
  static emailConfigurationGetEmailTemplate(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/admin/email-configuration/email-template/{id}";
      url = url.replace("{id}", params["id"] + "");

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update email configuration setting
   */
  static emailConfigurationUpdateEmailTemplate(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Admin_EmailConfigurationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/admin/email-configuration/update-email-template";

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get all Sunshine reminders details
   */
  static emailConfigurationGetAllRemindersAsync(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/admin/email-configuration/email-reminders";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Schedule send the reminder
   */
  static emailConfigurationCreateReminderAsync(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Email_EmailReminderRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/admin/email-configuration/email-reminders";

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Return excel data for employees
   */
  static emailConfigurationGetReceipientsAsync(
    params: {
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url =
        basePath +
        "/admin/email-configuration/email-reminders/recipient-template";

      const configs: IRequestConfig = getConfigs(
        "post",
        "multipart/form-data",
        url,
        options
      );

      let data = null;
      data = new FormData();
      if (params["file"]) {
        if (
          Object.prototype.toString.call(params["file"]) === "[object Array]"
        ) {
          for (const item of params["file"]) {
            data.append("file", item as any);
          }
        } else {
          data.append("file", params["file"] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get reminder details
   */
  static emailConfigurationReminderDetailsAsync(
    params: {
      /**  */
      scheduleId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url =
        basePath + "/admin/email-configuration/email-reminders/{scheduleId}";
      url = url.replace("{scheduleId}", params["scheduleId"] + "");

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class EmailService {
  /**
   * Send email
   */
  static emailSendEmail(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_InternalModels_EmailModel_EmailModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/email/send";

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class EmployeeDetailsService {
  /**
   * Get all employee list
   */
  static employeeGetEmployeeList(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/employee/list";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get employee profile summary based on employee code
   */
  static employeeGetProfileSummary(
    params: {
      /**  */
      employeeCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/employee/profilesummary";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = { employeeCode: params["employeeCode"] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get employee personal details based on employee code
   */
  static employeeGetPersonalDetails(
    params: {
      /**  */
      employeeCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/employee/personaldetails";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = { employeeCode: params["employeeCode"] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get employee contact details based on employee code
   */
  static employeeGetContactDetails(
    params: {
      /**  */
      employeeCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/employee/contactdetails";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = { employeeCode: params["employeeCode"] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get employee dependents details based on employee code
   */
  static employeeGetDependents(
    params: {
      /**  */
      employeeCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/employee/dependents";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = { employeeCode: params["employeeCode"] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get employee employment details based on employee code
   */
  static employeeGetEmploymentDetails(
    params: {
      /**  */
      entityCode: string;
      /**  */
      employeeCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/employee/employment/{entityCode}";
      url = url.replace("{entityCode}", params["entityCode"] + "");

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = { employeeCode: params["employeeCode"] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get employee movement details based on employee code and entity code
   */
  static employeeGetMovementDetails(
    params: {
      /**  */
      entityCode?: string;
      /**  */
      employeeCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/employee/movement";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = {
        entityCode: params["entityCode"],
        employeeCode: params["employeeCode"],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get employee bank details based on employee code
   */
  static employeeGetBankDetails(
    params: {
      /**  */
      employeeCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/employee/bankaccount";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = { employeeCode: params["employeeCode"] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get employee entities
   */
  static employeeGetEmployeeEntities(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/employee/entities";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get employee entity history
   */
  static employeeGetEmployeeEntityHistory(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/employee/entityhistory";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get employee list by report line
   */
  static employeeGetEmployeeListByReportLine(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/employee/employeebyreportline";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get employee amendment data
   */
  static employeeGetEmployeeDetails(
    params: {
      /**  */
      employeeCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/employee/details/{employeeCode}";
      url = url.replace("{employeeCode}", params["employeeCode"] + "");

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Amend employee data
   */
  static employeeUpdateEmployee(
    params: {
      /**  */
      body?: HRIS_Api_Models_Request_Employee_EmployeeUpdateRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/employee/update/custom";

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params["body"];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static employeeGetGradesAsync(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/employee/grades";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static employeeGetStaffTypesAsync(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/employee/staff-types";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static employeeGetTeamsAsync(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/employee/teams";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class EmployeeTabConfigurationService {
  /**
   *
   */
  static employeeConfigurationGetPersonalTabConfiguration(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/employee/configuration/personaltab";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static employeeConfigurationGetContactTabConfigurations(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/employee/configuration/contacttab";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static employeeConfigurationGetDependentTabConfigurations(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/employee/configuration/dependenttab";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static employeeConfigurationGetEmploymentTabConfigurations(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/employee/configuration/employmenttab";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static employeeConfigurationGetMovementTabConfigurations(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/employee/configuration/movementtab";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static employeeConfigurationGetBankDetailTabConfigurations(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/employee/configuration/banktab";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ForgotPasswordService {
  /**
   *
   */
  static forgotPasswordForgotPasswordConfigurationByUserName(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Authentication_ForgotPasswordConfigurationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/forgotpassword/configurationbyusername";

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static forgotPasswordForgotPasswordConfigurationByEmail(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Authentication_ForgotPasswordConfigurationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/forgotpassword/configurationbyemail";

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static forgotPasswordForgotPasswordSubmission(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Authentication_ForgotPasswordRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/forgotpassword";

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static forgotPasswordForgotPasswordVerifyToken(
    params: {
      /**  */
      token: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/forgotpassword/verifytoken/{token}";
      url = url.replace("{token}", params["token"] + "");

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static forgotPasswordResetPassword(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Authentication_ResetPasswordRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/forgotpassword/reset";

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class LeaveService {
  /**
   * Get current leave period based on year input
   */
  static leaveGetCurrentLeavePeriod(
    params: {
      /**  */
      year?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/current-period";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = { year: params["year"] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get all leave periods based on employee join date
   */
  static leaveGetLeavePeriods(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/periods";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get all leave periods
   */
  static leaveApproverCornerGetLeavePeriods(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/pending-approval/periods";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveGetApplicationList(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/application-list";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveGetDelegatedApplicationList(
    params: {
      /**  */
      delegatorEmployeeCodes?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/delegation/delegated-application-list";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = {
        delegatorEmployeeCodes: params["delegatorEmployeeCodes"],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * list of leave application apply on behalf of delagatee for delegator view
   */
  static leaveGetDelegateApplicationList(
    params: {
      /**  */
      year?: string;
      /**  */
      hrView?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/delegation/apply-leave-current";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = { year: params["year"], hrView: params["hrView"] };

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * list of delegator
   */
  static leaveGetDelegateEmployeeApproveList(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/delegation/delegator-list";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }

  /**
   *
   */
  static leaveLeaveTransactions(
    params: {
      /**  */
      year?: number;
      /**  */
      month?: number;
      /**  */
      leaveCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/transactions";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = {
        year: params["year"],
        month: params["month"],
        leaveCode: params["leaveCode"],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveGetApplications(
    params: {
      /**  */
      year?: number;
      /**  */
      month?: number;
      /**  */
      day?: number;
      /**  */
      isIncludeFilters?: boolean;
      /**  */
      employeeCode?: string;
      /**  */
      reportingLine?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/applications";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post", //"get",
        "application/json",
        url,
        options
      );
      // configs.params = {
      //   year: params["year"],
      //   month: params["month"],
      //   day: params["day"],
      // };
      const body = {
        year: params["year"],
        month: params["month"],
        day: params["day"],
        isIncludeFilters: params["isIncludeFilters"],
        employeeCode: params["employeeCode"],
        reportingLine: params["reportingLine"],
      };
      let data = body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveGetLeaveApplicationById(
    params: {
      /**  */
      applicationId: number;
      /**  */
      isDelegated?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/details/{applicationId}";
      url = url.replace("{applicationId}", params["applicationId"] + "");

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = { isDelegated: params["isDelegated"] };

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveGetLeaveTransactionHeaderbyRefNo(
    params: {
      /**  */
      referenceNo: string;
      /**  */
      employeeCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/transactionheader/{referenceNo}";
      url = url.replace("{referenceNo}", params["referenceNo"] + "");

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = { employeeCode: params["employeeCode"] };

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveGetLeaveTransactionDetailbyId(
    params: {
      /**  */
      applicationId: number;
      /**  */
      employeeCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/transactiondetail/{applicationId}";
      url = url.replace("{applicationId}", params["applicationId"] + "");

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = { employeeCode: params["employeeCode"] };

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveGetLeaveTransactionWorkflowbyId(
    params: {
      /**  */
      applicationId: number;
      /**  */
      employeeCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/transactionworkflow/{applicationId}";
      url = url.replace("{applicationId}", params["applicationId"] + "");

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = { employeeCode: params["employeeCode"] };

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveGetLeaveCodesAsync(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/leave-codes";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get employee leave category list
   */
  static leaveGetLeaveCategoryList(
    params: {
      /**  */
      applyentitlement: string;
      /**  */
      employeeCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/categories/{applyentitlement}";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = { employeeCode: params["employeeCode"] };

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get employee leave types list by category id
   */
  static leaveGetLeaveTypesListByCategoryId(
    params: {
      /**  */
      categoryid: string;
      /**  */
      applyentitlement: string;
      /**  */
      employeeCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/types/{categoryid}/{applyentitlement}";
      url = url.replace("{categoryid}", params["categoryid"] + "");
      url = url.replace("{applyentitlement}", params["applyentitlement"] + "");

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = { employeeCode: params["employeeCode"] };

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get employee leave type list
   */
  static leaveGetLeaveTypeList(
    params: {
      /**  */
      applyentitlement?: string;
      /**  */
      employeecode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/type";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = {
        applyentitlement: params["applyentitlement"],
        employeecode: params["employeecode"],
      };

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get leave type information for specific leave code
   */
  static leaveGetLeaveTypesInfoByLeaveCode(
    params: {
      /**  */
      leavecode?: string;
      /**  */
      employeeCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/information";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = {
        leavecode: params["leavecode"],
        employeeCode: params["employeeCode"],
      };

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get leave entitlement types
   */
  static leaveGetLeaveEntitlementTypes(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/entitlement-types";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveGetEquiryLeaveBalanceSummary(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/balance-summary";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveGetLeaveYearsByLeaveType(
    params: {
      /**  */
      leaveType: string;
      employeeCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/years/{leaveType}";
      url = url.replace("{leaveType}", params["leaveType"] + "");

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = {
        employeeCode: params["employeeCode"],
      };

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveGetEquiryLeaveBalance(
    params: {
      /**  */
      leavecode: string;
      /**  */
      targetdate: string;
      employeeCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/balance/{leavecode}/{targetdate}";
      url = url.replace("{leavecode}", params["leavecode"] + "");
      url = url.replace("{targetdate}", params["targetdate"] + "");

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = {
        employeeCode: params["employeeCode"],
      };

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveGetEquiryLeaveBalanceTransactionsByTransactionType(
    params: {
      /**  */
      leavecode: string;
      /**  */
      transactiontype: string;
      /**  */
      targetdate: string;
      employeeCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url =
        basePath + "/leave/balance/{leavecode}/{transactiontype}/{targetdate}";
      url = url.replace("{leavecode}", params["leavecode"] + "");
      url = url.replace("{transactiontype}", params["transactiontype"] + "");
      url = url.replace("{targetdate}", params["targetdate"] + "");

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = {
        employeeCode: params["employeeCode"],
      };

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveGetLeaveApprovalList(
    params: {
      /**  */
      applicationId?: number;
      /**  */
      year?: string;
      /**  */
      workflowStatus?: string;
      /**  */
      approverEmployeeCode?: string;
      /** not yet implemented */
      sorting?: string;
      /**  */
      clientSize?: boolean;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/pending-approval";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = {
        applicationId: params["applicationId"],
        Year: params["year"],
        WorkflowStatus: params["workflowStatus"],
        ApproverEmployeeCode: params["approverEmployeeCode"],
        sorting: params["sorting"],
        clientSize: params["clientSize"],
        Page: params["page"],
        PageSize: params["pageSize"],
      };

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  static leaveGetLeaveApproversList(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/pending-approval/approvers";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Validate leave application and save leave draft application
   */
  static leaveSaveLeaveDraftApplication(
    form?: any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/save-draft";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "multipart/form-data",
        url,
        options
      );

      let data: any = null;
      data = new FormData();

      Object.entries(form).forEach(([key, value]) => {
        if (Object.prototype.toString.call(value) === "[object Array]") {
          for (const item of value as any) {
            data.append(key, item as any);
          }
        } else {
          data.append(key, value);
        }
      });

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }

  /**
   * Update draft application or entitlement
   */
  static leaveUpdateLeaveDraftApplication(
    params: {
      /**  */
      applicationId?: number;
      /**  */
      form?: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/update-draft";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "put",
        "multipart/form-data",
        url,
        options
      );
      configs.params = { applicationId: params["applicationId"] };

      let data: any = null;
      data = new FormData();

      Object.entries(params.form).forEach(([key, value]) => {
        if (Object.prototype.toString.call(value) === "[object Array]") {
          for (const item of value as any) {
            data.append(key, item as any);
          }
        } else {
          data.append(key, value);
        }
      });

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Submit leave application
   */
  static leaveSubmitLeaveApplication(
    form: any = {},
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/submit";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "multipart/form-data",
        url,
        options
      );

      let data: any = null;
      data = new FormData();

      Object.entries(form).forEach(([key, value]) => {
        if (Object.prototype.toString.call(value) === "[object Array]") {
          for (const item of value as any) {
            data.append(key, item as any);
          }
        } else {
          data.append(key, value);
        }
      });

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }

  /**
   * Validate leave application and save leave draft application
   */
  static leaveCalculateUnits(
    form?: any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/applying-units/details";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "multipart/form-data",
        url,
        options
      );

      let data: any = null;
      data = new FormData();

      Object.entries(form).forEach(([key, value]) => {
        if (Object.prototype.toString.call(value) === "[object Array]") {
          for (const item of value as any) {
            data.append(key, item as any);
          }
        } else {
          data.append(key, value);
        }
      });

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Validate leave application and save leave draft application
   */
  static leaveCalculateUnitsEntitlement(
    form?: any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/applying-units/entitlements";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "multipart/form-data",
        url,
        options
      );

      let data: any = null;
      data = new FormData();

      Object.entries(form).forEach(([key, value]) => {
        if (Object.prototype.toString.call(value) === "[object Array]") {
          for (const item of value as any) {
            data.append(key, item as any);
          }
        } else {
          data.append(key, value);
        }
      });

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveApprovalAction(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Leave_LeaveBatchApprovalRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/approve-action";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveGetTeamTransactions(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Leave_LeaveTransactionFilter;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/team-transactions";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveExportLeaveTransactions(
    params: {
      /**  */
      sorting?: string;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Leave_LeaveTransactionFilter;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/team-transactions/export";

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = { sorting: params["sorting"] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get employee current leave delegation
   */
  static leaveGetDelegationCurrent(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/delegation/current";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get employee future leave delegation
   */
  static leaveGetDelegationFuture(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/delegation/future";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveGetDelegatedCurrent(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/delegation/delegated-to-list";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get employee leave delegation history
   */
  static leaveGetDelegationHistory(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/delegation/history";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get employee leave delegation log (all entries)
   */
  static leaveGetDelegationLog(
    params: {} = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/delegation/log";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get employee delegation leave task
   */
  static leaveGetDelegationLeaveTask(
    params: {} = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/delegation/tasks";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveGetDelegationOptions(
    params: {} = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/delegation/options";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Add leave delegation based on leave delegation task request
   */
  static leaveAddDelegation(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Leave_LeaveDelegationTaskRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/delegation/add";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Cancel leave delegation based on leave delegation id
   */
  static leaveCancelDelegation(
    params: {
      /**  */
      delegateid?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/delegation/cancel";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = { delegateid: params["delegateid"] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete leave draft application
   */
  static leaveDeleteLeaveDraftApplication(
    params: {
      /**  */
      applicationId?: number;
      /**  */
      employeeCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/delete-draft";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "delete",
        "application/json",
        url,
        options
      );
      configs.params = {
        applicationId: params["applicationId"],
        employeeCode: params["employeeCode"],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Submit leave draft application
   */
  static leaveSubmitLeaveDraftApplication(
    params: {
      /**  */
      applicationId?: number;
      /**  */
      form?: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/submit-draft";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "put",
        "multipart/form-data",
        url,
        options
      );
      configs.params = { applicationId: params["applicationId"] };

      let data: any = null;
      data = new FormData();

      Object.entries(params.form).forEach(([key, value]) => {
        if (Object.prototype.toString.call(value) === "[object Array]") {
          for (const item of value as any) {
            data.append(key, item as any);
          }
        } else {
          data.append(key, value);
        }
      });

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveCancelLeaveApplication(
    params: {
      /**  */
      applicationId?: number;
      /**  */
      remarks?: string;
      /**  */
      employeeCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/cancel";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "put",
        "application/json",
        url,
        options
      );
      configs.params = {
        applicationId: params["applicationId"],
        remarks: params["remarks"],
        employeeCode: params["employeeCode"],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveGetDateTypeList(
    params: {
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      employeeCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/datetype-list";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = {
        employeeCode: params["employeeCode"],
        FromDate: params["fromDate"],
        ToDate: params["toDate"],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveGetLeaveYears(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/years";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveGetHolidayList(
    params: {
      /**  */
      calendarClass?: string;
      /**  */
      year?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/holiday-list";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = {
        calendarClass: params["calendarClass"],
        year: params["year"],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveGetBlockLeave(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/block-leave";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveGetBlockLeaveByYear(
    params: {
      /**  */
      year?: number;
      employeeCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/block-leave/years/{year}";
      url = url.replace("{year}", params["year"] + "");

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      configs.params = {
        employeeCode: params["employeeCode"],
      };

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveDeclareBlockLeave(
    params: {
      /**  */
      leaveYear?: number;
      /**  */
      key?: number;
      /**  */
      startDate?: string;
      /**  */
      employeeCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url =
        basePath +
        "/block-leave/declare/years/{leaveYear}/keys/{key}/dates/{startDate}";
      url = url.replace("{leaveYear}", params["leaveYear"] + "");
      url = url.replace("{key}", params["key"] + "");
      url = url.replace("{startDate}", params["startDate"] + "");

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      configs.params = {
        employeeCode: params["employeeCode"],
      };

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveDownloadAttachment(
    params: {
      /**  */
      fileId: number;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Document_DocumentDownloadRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/attachments/download/singlefile";
      url = url.replace("{fileId}", params["fileId"] + "");

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class NwowService {
  /**
   * Get current leave period based on year input
   */
  static nwowGetCurrentNwowPeriod(
    params: {
      /**  */
      year?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/current-period";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = { year: params["year"] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get all leave periods based on employee join date
   */
  static nwowGetNwowPeriods(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/periods";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nwowGetApplicationList(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/application-list";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nwowNwowTransactions(
    params: {
      /**  */
      year?: number;
      /**  */
      month?: number;
      /**  */
      leaveCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/transactions";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = {
        year: params["year"] ?? 0,
        month: params["month"] ?? 0,
        leaveCode: params["leaveCode"],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nwowGetApplications(
    params: {
      /**  */
      year?: number;
      /**  */
      month?: number;
      /**  */
      day?: number;
      /**  */
      isIncludeFilters?: boolean;
      /**  */
      employeeCode?: string;
      /**  */
      reportingLine?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/applications";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post", //"get",
        "application/json",
        url,
        options
      );
      // configs.params = {
      //   year: params["year"],
      //   month: params["month"],
      //   day: params["day"],
      // };
      const body = {
        year: params["year"],
        month: params["month"],
        day: params["day"],
        isIncludeFilters: params["isIncludeFilters"],
        employeeCode: params["employeeCode"],
        reportingLine: params["reportingLine"],
      };
      let data = body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nwowGetNwowApplicationById(
    params: {
      /**  */
      applicationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/details/{applicationId}";
      url = url.replace("{applicationId}", params["applicationId"] + "");

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nwowGetNwowTransactionHeaderbyRefNo(
    params: {
      /**  */
      referenceNo: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/transactionheader/{referenceNo}";
      url = url.replace("{referenceNo}", params["referenceNo"] + "");

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nwowGetNwowTransactionDetailbyId(
    params: {
      /**  */
      applicationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/transactiondetail/{applicationId}";
      url = url.replace("{applicationId}", params["applicationId"] + "");

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nwowGetNwowTransactionWorkflowbyId(
    params: {
      /**  */
      applicationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/transactionworkflow/{applicationId}";
      url = url.replace("{applicationId}", params["applicationId"] + "");

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get all export options list
   */
  static masterGetExportOptionList(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/master/export-options";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nwowGetNwowCategoryList(
    params: {
      /**  */
      applyentitlement: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/categories/{applyentitlement}";
      url = url.replace("{applyentitlement}", params["applyentitlement"] + "");

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nwowGetNwowTypesListByCategoryId(
    params: {
      /**  */
      categoryid: string;
      /**  */
      applyentitlement: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/types/{categoryid}/{applyentitlement}";
      url = url.replace("{categoryid}", params["categoryid"] + "");
      url = url.replace("{applyentitlement}", params["applyentitlement"] + "");

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nwowGetNwowTypeListByEntitlement(
    params: {
      /**  */
      applyentitlement: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/type/{applyentitlement}";
      url = url.replace("{applyentitlement}", params["applyentitlement"] + "");

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nwowGetNwowTypeList(
    params: {
      /**  */
      applyentitlement: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/type";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get leave type information for specific leave code
   */
  static nwowGetNwowTypesInfoByNwowCode(
    params: {
      /**  */
      nwowcode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/information/{nwowcode}";
      url = url.replace("{nwowcode}", params["nwowcode"] + "");

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get leave entitlement types
   
  static nwowGetNwowEntitlementTypes(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/entitlement-types";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }*/
  /**
   *
   */
  static nwowGetEquiryNwowBalanceSummary(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/balance-summary";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }

  static nwowGetEquiryNwowBalanceTransactionsByTransactionType(
    params: {
      /**  */
      leavecode: string;
      /**  */
      transactiontype: string;
      /**  */
      targetdate: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url =
        basePath + "/nwow/balance/{leavecode}/{transactiontype}/{targetdate}";
      url = url.replace("{leavecode}", params["leavecode"] + "");
      url = url.replace("{transactiontype}", params["transactiontype"] + "");
      url = url.replace("{targetdate}", params["targetdate"] + "");

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nwowGetNwowApprovalList(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/pending-approval";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  static nwowGetNwowApproversList(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/pending-approval/approvers";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nwowGetNwowApprovalListByApprover(
    params: {
      /**  */
      employeeCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/pending-approval/approvers/{employeeCode}";
      url = url.replace("{employeeCode}", params["employeeCode"] + "");

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Validate leave application and save leave draft application
   */
  static nwowSaveNwowDraftApplication(
    form?: any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/save-draft";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "multipart/form-data",
        url,
        options
      );

      let data: any = null;
      data = new FormData();

      Object.entries(form).forEach(([key, value]) => {
        if (Object.prototype.toString.call(value) === "[object Array]") {
          for (const item of value as any) {
            data.append(key, item as any);
          }
        } else {
          data.append(key, value);
        }
      });

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }

  /**
   * Update draft application or entitlement
   */
  static nwowUpdateNwowDraftApplication(
    params: {
      /**  */
      applicationId?: number;
      /**  */
      form?: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/update-draft";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "put",
        "multipart/form-data",
        url,
        options
      );
      configs.params = { applicationId: params["applicationId"] };

      let data: any = null;
      data = new FormData();

      Object.entries(params.form).forEach(([key, value]) => {
        if (Object.prototype.toString.call(value) === "[object Array]") {
          for (const item of value as any) {
            data.append(key, item as any);
          }
        } else {
          data.append(key, value);
        }
      });

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Submit leave application
   */
  static nwowSubmitNwowApplication(
    form: any = {},
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/submit";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "multipart/form-data",
        url,
        options
      );

      let data: any = null;
      data = new FormData();

      Object.entries(form).forEach(([key, value]) => {
        if (Object.prototype.toString.call(value) === "[object Array]") {
          for (const item of value as any) {
            data.append(key, item as any);
          }
        } else {
          data.append(key, value);
        }
      });

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }

  /**
   * Validate leave application and save leave draft application
   */
  static nwowCalculateUnits(
    form?: any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/applying-units/details";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "multipart/form-data",
        url,
        options
      );

      let data: any = null;
      data = new FormData();

      Object.entries(form).forEach(([key, value]) => {
        if (Object.prototype.toString.call(value) === "[object Array]") {
          for (const item of value as any) {
            data.append(key, item as any);
          }
        } else {
          data.append(key, value);
        }
      });

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Validate leave application and save leave draft application
   */
  static nwowCalculateUnitsEntitlement(
    form?: any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/applying-units/entitlements";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "multipart/form-data",
        url,
        options
      );

      let data: any = null;
      data = new FormData();

      Object.entries(form).forEach(([key, value]) => {
        if (Object.prototype.toString.call(value) === "[object Array]") {
          for (const item of value as any) {
            data.append(key, item as any);
          }
        } else {
          data.append(key, value);
        }
      });

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nwowApprovalAction(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Leave_LeaveBatchApprovalRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/approve-action";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nwowGetTeamTransactions(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Leave_LeaveTransactionFilter;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/team-transactions";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nwowGetDelegationCurrent(
    params: {} = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/delegation/current";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nwowGetDelegationHistory(
    params: {} = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/delegation/history";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nwowGetDelegationLog(
    params: {} = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/delegation/log";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nwowGetDelegationNwowTask(
    params: {} = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/delegation/tasks";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nwowGetDelegationOptions(
    params: {} = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/delegation/options";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nwowAddDelegation(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Leave_LeaveDelegationTaskRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/delegation/add";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nwowCancelDelegation(
    params: {
      /**  */
      delegateid?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/delegation/cancel";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = { delegateid: params["delegateid"] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete leave draft application
   */
  static nwowDeleteNwowDraftApplication(
    params: {
      /**  */
      applicationId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/delete-draft";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "delete",
        "application/json",
        url,
        options
      );
      configs.params = { applicationId: params["applicationId"] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Submit leave draft application
   */
  static nwowSubmitNwowDraftApplication(
    params: {
      /**  */
      applicationId?: number;
      /**  */
      form?: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/submit-draft";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "put",
        "multipart/form-data",
        url,
        options
      );
      configs.params = { applicationId: params["applicationId"] };

      let data: any = null;
      data = new FormData();

      Object.entries(params.form).forEach(([key, value]) => {
        if (Object.prototype.toString.call(value) === "[object Array]") {
          for (const item of value as any) {
            data.append(key, item as any);
          }
        } else {
          data.append(key, value);
        }
      });

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Submit nwow change application
   */
  static nwowSubmitNwowChangeApplication(
    params: {
      /**  */
      applicationId?: number;
      /**  */
      form?: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/submit-change";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "put",
        "multipart/form-data",
        url,
        options
      );
      configs.params = { applicationId: params["applicationId"] };

      let data: any = null;
      data = new FormData();

      Object.entries(params.form).forEach(([key, value]) => {
        if (Object.prototype.toString.call(value) === "[object Array]") {
          for (const item of value as any) {
            data.append(key, item as any);
          }
        } else {
          data.append(key, value);
        }
      });

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nwowCancelNwowApplication(
    params: {
      /**  */
      applicationId?: string;
      /**  */
      remarks?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/cancel";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "put",
        "application/json",
        url,
        options
      );
      configs.params = {
        applicationId: params["applicationId"],
        remarks: params["remarks"],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nwowGetDateTypeList(
    params: {
      /**  */
      calendarClass?: string;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/datetype-list";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = {
        CalendarClass: params["calendarClass"],
        FromDate: params["fromDate"],
        ToDate: params["toDate"],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nwowGetNwowYears(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/years";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nwowGetHolidayList(
    params: {
      /**  */
      calendarClass?: string;
      /**  */
      year?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/holiday-list";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = {
        calendarClass: params["calendarClass"],
        year: params["year"],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }

  /**
   *
   */
  static nwowGetBlockNwowByYear(
    params: {
      /**  */
      year?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/block-leave/years/{year}";
      url = url.replace("{year}", params["year"] + "");

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }

  /**
   *
   */
  static nwowDownloadAttachment(
    params: {
      /**  */
      fileId: number;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Document_DocumentDownloadRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/attachments/download/singlefile";
      url = url.replace("{fileId}", params["fileId"] + "");

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }

  /**
   *
   */
  static nwowExportNwowTransactions(
    params: {
      /**  */
      sorting?: string;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Nwow_NwowTransactionFilter;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/team-transactions/export";

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = { sorting: params["sorting"] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class LeaveReportService {
  /**
   *
   */
  static leaveReportGetLeaveTransactionReport(
    params: {
      /**  */
      sortOrder?: string;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Leave_Reports_LeaveTransactionReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/report/leavetransaction";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = {
        SortOrder: params["sortOrder"],
        //Page: params["page"],
        //PageSize: params["pageSize"],
        IsLazyLoading: false,
      };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveReportExportLeaveTransactionReport(
    params: {
      /**  */
      sorting?: string;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Leave_Reports_LeaveTransactionReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/report/leavetransaction/export";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = { sorting: params["sorting"] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveReportGetLeaveBalanceSummaryReport(
    params: {
      /**  */
      sorting?: string;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Leave_Reports_LeaveBalanceSummaryReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/report/leavebalancesummary";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = {
        sorting: params["sorting"],
        //Page: params["page"],
        //PageSize: params["pageSize"],
        IsLazyLoading: false,
      };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveReportExportLeaveBalanceSummaryReport(
    params: {
      /**  */
      sorting?: string;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Leave_Reports_LeaveBalanceSummaryReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/report/leavebalancesummary/export";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = { sorting: params["sorting"] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveReportGetSickLeaveDetailsReport(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Leave_Reports_SickLeaveDetailsReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/report/sickleavedetails";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveReportExportSickLeaveDetailsReport(
    params: {
      /**  */
      sorting?: string;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Leave_Reports_SickLeaveDetailsReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/report/sickleavedetails/export";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = { sorting: params["sorting"] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveReportGetAnnualLeaveReport(
    params: {
      /**  */
      sorting?: string;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Leave_Reports_AnnualLeaveReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/report/annualleave";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = {
        sorting: params["sorting"],
        //Page: params["page"],
        //PageSize: params["pageSize"],
        IsLazyLoading: false,
      };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveReportExportAnnualLeaveReport(
    params: {
      /**  */
      sorting?: string;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Leave_Reports_AnnualLeaveReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/report/annualleave/export";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = { sorting: params["sorting"] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveReportGetStatutoryLeaveBalanceReport(
    params: {
      /**  */
      sorting?: string;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Leave_Reports_StatutoryLeaveBalanceReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/report/statutoryleavebalance"; //DALVIN TO-DO ... need to call the correct endpoint...

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = {
        sorting: params["sorting"],
        //Page: params["page"],
        //PageSize: params["pageSize"],
        IsLazyLoading: false,
      };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveReportExportStatutoryLeaveBalanceReport(
    params: {
      /**  */
      sorting?: string;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Leave_Reports_StatutoryLeaveBalanceReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/report/statutoryleavebalance/export"; //DALVIN TO-DO ... need to call the correct endpoint...

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = { sorting: params["sorting"] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveReportGetLeaveTransactionFilter(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Leave_Reports_LeaveReportFilterRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/report/leavetransaction/filters";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveReportGetLeaveBalanceFilter(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Leave_Reports_LeaveReportFilterRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/report/leavebalancesummary/filters";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveReportGetSickLeaveDetailsFilter(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Leave_Reports_LeaveReportFilterRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/report/sickleavedetails/filters";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveReportGetStatutoryLeaveBalanceFilter(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Leave_Reports_LeaveReportFilterRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/report/statutoryleavebalance/filters"; //DALVIN TO-DO ... need to call the correct endpoint...

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static leaveReportGetAnnualLeaveReportFilter(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/leave/report/annualleave/filters";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      axios(configs, resolve, reject);
    });
  }
}

export class NwowReportService {
  /**
   *
   */
  static nwowReportGetNwowTransactionReport(
    params: {
      /**  */
      sortOrder?: string;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Nwow_Reports_NwowTransactionReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/report/nwowtransaction";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = {
        SortOrder: params["sortOrder"],
        Page: params["page"],
        PageSize: params["pageSize"],
      };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nwowReportExportNwowTransactionReport(
    params: {
      /**  */
      sorting?: string;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Nwow_Reports_NwowTransactionReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/report/nwowtransaction/export";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = { sorting: params["sorting"] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nwowReportGetNwowBalanceSummaryReport(
    params: {
      /**  */
      sorting?: string;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Nwow_Reports_NwowBalanceSummaryReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/report/nwowbalancesummary";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = {
        sorting: params["sorting"],
        Page: params["page"],
        PageSize: params["pageSize"],
      };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nwowReportExportNwowBalanceSummaryReport(
    params: {
      /**  */
      sorting?: string;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Nwow_Reports_NwowBalanceSummaryReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/report/nwowbalancesummary/export";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = { sorting: params["sorting"] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nwowReportGetSickNwowDetailsReport(
    params: {
      /**  */
      sorting?: string;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Nwow_Reports_SickNwowDetailsReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/report/sicknwowdetails";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = {
        sorting: params["sorting"],
        Page: params["page"],
        PageSize: params["pageSize"],
      };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nwowReportExportSickNwowDetailsReport(
    params: {
      /**  */
      sorting?: string;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Nwow_Reports_SickNwowDetailsReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/report/sicknwowdetails/export";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = { sorting: params["sorting"] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nwowReportGetAnnualNwowReport(
    params: {
      /**  */
      sorting?: string;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Nwow_Reports_AnnualNwowReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/report/annualnwow";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = {
        sorting: params["sorting"],
        Page: params["page"],
        PageSize: params["pageSize"],
      };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nwowReportExportAnnualNwowReport(
    params: {
      /**  */
      sorting?: string;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Nwow_Reports_AnnualNwowReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/report/annualnwow/export";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = { sorting: params["sorting"] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nwowReportGetNwowTransactionFilter(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/report/nwowtransaction/filters";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nwowReportGetNwowBalanceFilter(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/report/nwowbalancesummary/filters";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nwowReportGetSickNwowDetailsFilter(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/report/sicknwowdetails/filters";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nwowReportGetAnnualNwowReportFilter(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/nwow/report/annualnwow/filters";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      axios(configs, resolve, reject);
    });
  }
}

export class HRIS_Api_Models_InternalModels_NwowModel_AccessPolicyFilterExpression {
  /**  */
  "filters"?: HRIS_Api_Models_InternalModels_NwowModel_AccessPolicyFilterExpression[];

  /**  */
  "logic"?: string;

  /**  */
  "field"?: string;

  /**  */
  "operator"?: string;

  /**  */
  "value"?: string;

  constructor(data: undefined | any = {}) {
    this["filters"] = data["filters"];
    this["logic"] = data["logic"];
    this["field"] = data["field"];
    this["operator"] = data["operator"];
    this["value"] = data["value"];
  }
}

export class HRIS_Api_Models_InternalModels_NwowModel_AccessPolicyFilterExpressions {
  /**  */
  "filters"?: HRIS_Api_Models_InternalModels_NwowModel_AccessPolicyFilterExpression[];

  /**  */
  "logic"?: string;

  constructor(data: undefined | any = {}) {
    this["filters"] = data["filters"];
    this["logic"] = data["logic"];
  }
}

export class HRIS_Api_Models_Request_Nwow_NwowTransactionFilter {
  /**  */
  "reportingLine"?: string;

  /**  */
  "groupBy"?: string;

  /**  */
  "filter"?: HRIS_Api_Models_InternalModels_NwowModel_AccessPolicyFilterExpressions;

  /**  */
  "year"?: number;

  /**  */
  "fromMonth"?: number;

  /**  */
  "toMonth"?: number;

  /**  */
  "viewingApplicationId"?: number;

  constructor(data: undefined | any = {}) {
    this["reportingLine"] = data["reportingLine"];
    this["groupBy"] = data["groupBy"];
    this["filter"] = data["filter"];
    this["year"] = data["year"];
    this["fromMonth"] = data["fromMonth"];
    this["toMonth"] = data["toMonth"];
    this["viewingApplicationId"] = data["viewingApplicationId"];
  }
}

export class MasterService {
  /**
   * Get all companies list
   */
  static masterGetCompanyList(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/master/companies";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalReportPostInterfaceFileHistory(
    params: {
      /**  */
      sorting?: string;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalInterfaceFileRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/posting/post";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = { sorting: params["sorting"] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static masterGetFilteredCompaniesAsync(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/master/companies/filtered";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get all departments list
   */
  static masterGetDepartmentList(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/master/department/list";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get all cost centers list
   */
  static masterGetCostCenterList(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/master/cost-centers";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get all export options list
   */
  static masterGetExportOptionList(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/master/export-options";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static masterGetRecurrenceTypesAsync(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/master/recurrence-types";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static masterGetMaster(
    params: {
      /**  */
      name?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/master/master-generics";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = { name: params["name"] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class RegistrationService {
  /**
   * Register User by biometric access token
   */
  static registrationRegisterByBiometricToken(
    params: {
      /**  */
      tenantName: string;
      /**  */
      entityName: string;
      /**  */
      acceptLanguage: string;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Authentication_SsoAuthenticationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/registration/registerbybiometrictoken";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        "Entity-Name": params["entityName"],
        "Accept-Language": params["acceptLanguage"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class RentalApprovalService {
  /**
   *
   */
  static rentalApprovalGetApprovalApplications(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/approval/list";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalApprovalGetApprovalClaims(
    params: {} = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/approval/claim/list";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalApprovalGetApprovalFinalizeApplications(
    params: {} = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/approval/claims/finalize";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalApprovalGetApplicationApprovalApplication(
    params: {
      /**  */
      applicationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/approval/view/{applicationId}";
      url = url.replace("{applicationId}", params["applicationId"] + "");

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalApprovalApproveApplication(
    params: {
      /**  */
      applicationId: number;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalApplicationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/approval/approve/{applicationId}";
      url = url.replace("{applicationId}", params["applicationId"] + "");

      const configs: IRequestConfig = getConfigs(
        "put",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalApprovalRejectApplication(
    params: {
      /**  */
      applicationId: number;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalApplicationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/approval/reject/{applicationId}";
      url = url.replace("{applicationId}", params["applicationId"] + "");

      const configs: IRequestConfig = getConfigs(
        "put",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalApprovalReturnApplication(
    params: {
      /**  */
      applicationId: number;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalApplicationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/approval/return/{applicationId}";
      url = url.replace("{applicationId}", params["applicationId"] + "");

      const configs: IRequestConfig = getConfigs(
        "put",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalApprovalGetClaimApprovalApplication(
    params: {
      /**  */
      applicationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/approval/claim/view/{applicationId}";
      url = url.replace("{applicationId}", params["applicationId"] + "");

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalApprovalApproveClaims(
    params: {
      /**  */
      applicationId: number;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalClaimRequest[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/approval/claim/approve/{applicationId}";
      url = url.replace("{applicationId}", params["applicationId"] + "");

      const configs: IRequestConfig = getConfigs(
        "put",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalApprovalRejectClaims(
    params: {
      /**  */
      applicationId: number;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalClaimRequest[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/approval/claim/reject/{applicationId}";
      url = url.replace("{applicationId}", params["applicationId"] + "");

      const configs: IRequestConfig = getConfigs(
        "put",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalApprovalReturnClaims(
    params: {
      /**  */
      applicationId: number;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalClaimRequest[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/approval/claim/return/{applicationId}";
      url = url.replace("{applicationId}", params["applicationId"] + "");

      const configs: IRequestConfig = getConfigs(
        "put",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalApprovalClaimsApproval(
    params: {
      /**  */
      applicationId: number;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalClaimApprovalRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/approval/claim/{applicationId}";
      url = url.replace("{applicationId}", params["applicationId"] + "");

      const configs: IRequestConfig = getConfigs(
        "put",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class RentalDetailsService {
  /**
   * Get Application Status List
   */
  static rentalGetApplicationStatuses(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/application/statuses";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get Application List
   */
  static rentalGetApplicationList(
    params: {
      /**  */
      filterField?: string;
      /**  */
      filterValue?: string;
      /**  */
      sortBy?: string;
      /**  */
      sortOrder?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/application/list";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = {
        filterField: params["filterField"],
        filterValue: params["filterValue"],
        SortBy: params["sortBy"],
        SortOrder: params["sortOrder"],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get All Application List
   */
  static rentalGetAllApplicationList(
    params: {
      /**  */
      tenantName: string;
      /**  */
      entityName: string;
      /**  */
      acceptLanguage: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/application/all-list";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        "Entity-Name": params["entityName"],
        "Accept-Language": params["acceptLanguage"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get Claim List of Application
   */
  static rentalGetClaimList(
    params: {
      /**  */
      applicationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/application/{applicationId}/claims";
      url = url.replace("{applicationId}", params["applicationId"] + "");

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get list of application no for Copy From
   */
  static rentalGetPreviousApplications(
    params: {
      /**  */
      employeeCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/application/previous";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = { employeeCode: params["employeeCode"] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get Application Steps
   */
  static rentalGetRentalNewApplicationSteps(
    params: {
      /**  */
      formType?: HRIS_Api_Common_Enums_Enums_RentalApplicationForm;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/application/steps";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = { formType: params["formType"] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get Application Form Configuration
   */
  static rentalGetApplicationFormConfiguration(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/application/configuration";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   * Get Change Application Details
   */
  static rentalGetChangeApplication(
    params: {
      /**  */
      applicationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/application/change/{applicationId}";
      url = url.replace("{applicationId}", params["applicationId"] + "");

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Save Application Change
   */
  static rentalSaveChangeApplication(
    params: {
      /**  */
      applicationId: number;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalApplicationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/application/change/{applicationId}";
      url = url.replace("{applicationId}", params["applicationId"] + "");

      const configs: IRequestConfig = getConfigs(
        "put",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get Withdraw Application Details
   */
  static rentalGetWithdrawApplication(
    params: {
      /**  */
      applicationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/application/withdraw/{applicationId}";
      url = url.replace("{applicationId}", params["applicationId"] + "");

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Save Withdraw Application
   */
  static rentalWithdrawApplication(
    params: {
      /**  */
      applicationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/application/withdraw/{applicationId}";
      url = url.replace("{applicationId}", params["applicationId"] + "");

      const configs: IRequestConfig = getConfigs(
        "put",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get View Application Details
   */
  static rentalGetViewApplication(
    params: {
      /**  */
      applicationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/application/view/{applicationId}";
      url = url.replace("{applicationId}", params["applicationId"] + "");

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalGetSubmitClaimApplication(
    params: {
      /**  */
      applicationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/application/submitclaim/{applicationId}";
      url = url.replace("{applicationId}", params["applicationId"] + "");

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get Application Details
   */
  static rentalGetApplication(
    params: {
      /**  */
      applicationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/application/{applicationId}";
      url = url.replace("{applicationId}", params["applicationId"] + "");

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get Application Details
   */
  static rentalGetCloneApplication(
    params: {
      /**  */
      applicationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/application/clone/{applicationId}";
      url = url.replace("{applicationId}", params["applicationId"] + "");

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Save Application
   */
  static rentalCreateDraftApplication(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalApplicationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/application/save";

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalUpdateDraftApplication(
    params: {
      /**  */
      applicationId: number;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalApplicationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/application/save/{applicationId}";
      url = url.replace("{applicationId}", params["applicationId"] + "");

      const configs: IRequestConfig = getConfigs(
        "put",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete Draft
   */
  static rentalDeleteDraftApplication(
    params: {
      /**  */
      applicationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/application/delete/{applicationId}";
      url = url.replace("{applicationId}", params["applicationId"] + "");

      const configs: IRequestConfig = getConfigs(
        "put",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Submit Application
   */
  static rentalSubmitApplication(
    params: {
      /**  */
      applicationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/application/submit/{applicationId}";
      url = url.replace("{applicationId}", params["applicationId"] + "");

      const configs: IRequestConfig = getConfigs(
        "put",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Submit Application
   */
  static rentalSubmitNewApplication(
    params: {
      /**  */
      applicationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/application/submitnew/{applicationId}";
      url = url.replace("{applicationId}", params["applicationId"] + "");

      const configs: IRequestConfig = getConfigs(
        "put",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Submit Draft / Application
   */
  static rentalSubmitDraftApplication(
    params: {
      /**  */
      body?: HRIS_Api_Models_Request_Rental_RentalApplicationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/application/submit";

      const configs: IRequestConfig = getConfigs(
        "put",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalFinializeApplication(
    params: {
      /**  */
      applicationId: number;
      /**  */
      remarks?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/application/finialize/{applicationId}";
      url = url.replace("{applicationId}", params["applicationId"] + "");

      const configs: IRequestConfig = getConfigs(
        "put",
        "application/json",
        url,
        options
      );
      // configs.params = { remarks: params["remarks"] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Reopen Application
   */
  static rentalReOpenApplication(
    params: {
      /**  */
      applicationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/application/reopen/{applicationId}";
      url = url.replace("{applicationId}", params["applicationId"] + "");

      const configs: IRequestConfig = getConfigs(
        "put",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalSubmitClaims(
    params: {
      /**  */
      applicationId: number;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalClaimRequest[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/application/claim/{applicationId}";
      url = url.replace("{applicationId}", params["applicationId"] + "");

      const configs: IRequestConfig = getConfigs(
        "put",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalCreateComment(
    params: {
      /**  */
      applicationId: number;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalCommentRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/application/comment/{applicationId}";
      url = url.replace("{applicationId}", params["applicationId"] + "");

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalGetParameters(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/parameters";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalGetApplicationLogReport(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalApplicationLogReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/report/applicationlog";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalGetApplicationSummaryReport(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalApplicationSummaryReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/report/applicationsummary";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalGetClaimSummaryReport(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalClaimSummaryReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/report/claimsummary";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalGetApplicationLogSummary(
    params: {
      /**  */
      applicationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/applications/{applicationId}/audit-logs";
      url = url.replace("{applicationId}", params["applicationId"] + "");

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalGetApplicationLogDetail(
    params: {
      /**  */
      applicationId: number;
      /**  */
      logId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url =
        basePath + "/rental/applications/{applicationId}/audit-logs/{logId}";
      url = url.replace("{applicationId}", params["applicationId"] + "");
      url = url.replace("{logId}", params["logId"] + "");

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalGetApplicationMySetting(
    params: {} = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/application/my-settings";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalRecalculateReimbursementEffectiveDate(
    params: {
      /**  */
      applicationId: number;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalApplicationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url =
        basePath +
        "/rental/application/recalculate/{applicationId}/effectivedate";
      url = url.replace("{applicationId}", params["applicationId"] + "");

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalRecalculateReimbursementEndDate(
    params: {
      /**  */
      applicationId: number;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalApplicationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url =
        basePath + "/rental/application/recalculate/{applicationId}/enddate";
      url = url.replace("{applicationId}", params["applicationId"] + "");

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalRecalculateRentalFees(
    params: {
      /**  */
      applicationId: number;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalApplicationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url =
        basePath + "/rental/application/recalculate/{applicationId}/rentalfees";
      url = url.replace("{applicationId}", params["applicationId"] + "");

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalRecalculateEligibleAmount(
    params: {
      /**  */
      applicationId: number;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalApplicationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url =
        basePath +
        "/rental/application/recalculate/{applicationId}/eligibleamount";
      url = url.replace("{applicationId}", params["applicationId"] + "");

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalValidateApplicationActualAmount(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalApplicationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/application/validate/actual-amount";

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Download company policy
   */
  static rentalDownloadCompanyPolicy(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/download/companypolicy";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class RentalReportsService {
  /**
   *
   */
  static rentalReportGetAnnualTaxReport(
    params: {
      /**  */
      sorting?: string;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalAnnualTaxReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/report/annualtax";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = {
        sorting: params["sorting"],
        Page: params["page"],
        PageSize: params["pageSize"],
      };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalReportExportAnnualTaxReport(
    params: {
      /**  */
      sorting?: string;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalAnnualTaxReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/report/annualtax/export";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = { sorting: params["sorting"] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalReportGetApplicationSummaryReport(
    params: {
      /**  */
      sorting?: string;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalApplicationSummaryReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/report/applicationsummary";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = {
        sorting: params["sorting"],
        Page: params["page"],
        PageSize: params["pageSize"],
      };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalReportExportApplicationSummaryReport(
    params: {
      /**  */
      sorting?: string;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalApplicationSummaryReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/report/applicationsummary/export";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = { sorting: params["sorting"] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalReportGetApplicationLogReport(
    params: {
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      sort?: string;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalApplicationLogReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/report/applicationlog";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = {
        Page: params["page"],
        PageSize: params["pageSize"],
        sort: params["sort"],
      };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalReportExportApplicationLogReport(
    params: {
      /**  */
      sorting?: string;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalApplicationLogReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/report/applicationlog/export";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = { sorting: params["sorting"] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalReportGetAttachmentLogReport(
    params: {
      /**  */
      sorting?: string;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalAttachmentLogReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/report/attachments/logs";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = {
        sorting: params["sorting"],
        Page: params["page"],
        PageSize: params["pageSize"],
      };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalReportExportAttachmentLogReport(
    params: {
      /**  */
      sorting?: string;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalAttachmentLogReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/report/attachments/logs/export";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = { sorting: params["sorting"] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalReportGetClaimSummaryReport(
    params: {
      /**  */
      sorting?: string;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalClaimSummaryReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/report/claimsummary";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = {
        sorting: params["sorting"],
        Page: params["page"],
        PageSize: params["pageSize"],
      };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalReportExportClaimSummaryReport(
    params: {
      /**  */
      sorting?: string;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalClaimSummaryReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/report/claimsummary/export";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = { sorting: params["sorting"] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalReportGetClaimDetailReport(
    params: {
      /**  */
      sorting?: string;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalClaimDetailReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/report/claimdetail";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = {
        sorting: params["sorting"],
        Page: params["page"],
        PageSize: params["pageSize"],
      };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalReportExportClaimDetailReport(
    params: {
      /**  */
      sorting?: string;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalClaimDetailReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/report/claimdetail/export";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = { sorting: params["sorting"] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalReportGetInterfaceFileHistories(
    params: {
      /**  */
      sorting?: string;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/posting/histories";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = {
        sorting: params["sorting"],
        Page: params["page"],
        PageSize: params["pageSize"],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalReportExportInterfaceFileHistory(
    params: {
      /**  */
      sorting?: string;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalInterfaceFileRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/posting/history";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = { sorting: params["sorting"] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalReportExportInterfaceFile(
    params: {
      /**  */
      sorting?: string;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalInterfaceFileRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/posting";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = { sorting: params["sorting"] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalReportPostInterfaceFileHistory(
    params: {
      /**  */
      sorting?: string;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalInterfaceFileRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/posting/post";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = { sorting: params["sorting"] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalReportGetMonthlyRentalReport(
    params: {
      /**  */
      sorting?: string;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalMonthlyRentalReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/report/monthlyrental";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = {
        sorting: params["sorting"],
        Page: params["page"],
        PageSize: params["pageSize"],
      };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalReportExportMonthlyRentalReport(
    params: {
      /**  */
      sorting?: string;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Rental_RentalMonthlyRentalReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/report/monthlyrental/export";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );
      configs.params = { sorting: params["sorting"] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rentalReportGetFilterOptions(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/rental/report/filteroptions";

      options.headers = {
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SystemNotificationsService {
  /**
   * Get System Notification
   */
  static systemNotificationGetSystemNotification(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/notification/system-notification";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class UserNotificationsService {
  /**
   *
   */
  static emailNotificationSendEmailNotification(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Notification_EmailNotificationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/notification/send-email";

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Summary - Home Newsfeed
   */
  static notificationGetSummary(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/notification/summary";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get Notifications
   */
  static notificationGetNotifications(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/notification";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update Notification Status
   */
  static notificationUpdateNotificationStatus(
    params: {
      /**  */
      notificationId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/notification";

      const configs: IRequestConfig = getConfigs(
        "put",
        "application/json",
        url,
        options
      );
      configs.params = { notificationId: params["notificationId"] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get Unread Notification Count
   */
  static notificationGetUnreadNotificationCount(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/notifications/count";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update All Unread Notification Status
   */
  static notificationUpdateAllUnreadNotificationStatus(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/notifications/allread";

      const configs: IRequestConfig = getConfigs(
        "put",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class UtilityService {
  /**
   * Update translations based on uploaded excel file
   */
  static utilityUploadTranslation(
    params: {
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/utility/upload-translation";

      const configs: IRequestConfig = getConfigs(
        "post",
        "multipart/form-data",
        url,
        options
      );

      let data = null;
      data = new FormData();
      if (params["file"]) {
        if (
          Object.prototype.toString.call(params["file"]) === "[object Array]"
        ) {
          for (const item of params["file"]) {
            data.append("file", item as any);
          }
        } else {
          data.append("file", params["file"] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update rental form configuration based on uploaded excel file
   */
  static utilityUploadRentaFormConfiguration(
    params: {
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/utility/upload-rentalform-configuration";

      const configs: IRequestConfig = getConfigs(
        "post",
        "multipart/form-data",
        url,
        options
      );

      let data = null;
      data = new FormData();
      if (params["file"]) {
        if (
          Object.prototype.toString.call(params["file"]) === "[object Array]"
        ) {
          for (const item of params["file"]) {
            data.append("file", item as any);
          }
        } else {
          data.append("file", params["file"] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update unify rental form configuration based on uploaded excel file
   */
  static utilityUploadUnifyRentaFormConfiguration(
    params: {
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/utility/upload-unify-rentalform-configuration";

      const configs: IRequestConfig = getConfigs(
        "post",
        "multipart/form-data",
        url,
        options
      );

      let data = null;
      data = new FormData();
      if (params["file"]) {
        if (
          Object.prototype.toString.call(params["file"]) === "[object Array]"
        ) {
          for (const item of params["file"]) {
            data.append("file", item as any);
          }
        } else {
          data.append("file", params["file"] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update company document based on uploaded excel file
   */
  static utilityUploadCompanyDocument(
    params: {
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/utility/upload-company-document";

      const configs: IRequestConfig = getConfigs(
        "post",
        "multipart/form-data",
        url,
        options
      );

      let data = null;
      data = new FormData();
      if (params["file"]) {
        if (
          Object.prototype.toString.call(params["file"]) === "[object Array]"
        ) {
          for (const item of params["file"]) {
            data.append("file", item as any);
          }
        } else {
          data.append("file", params["file"] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update message template configuration based on uploaded excel file
   */
  static utilityUploadMessageTemplateConfiguration(
    params: {
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/utility/upload-message-template-configuration";

      const configs: IRequestConfig = getConfigs(
        "post",
        "multipart/form-data",
        url,
        options
      );

      let data = null;
      data = new FormData();
      if (params["file"]) {
        if (
          Object.prototype.toString.call(params["file"]) === "[object Array]"
        ) {
          for (const item of params["file"]) {
            data.append("file", item as any);
          }
        } else {
          data.append("file", params["file"] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update employee tab configuration based on uploaded excel file
   */
  static utilityUploadEmployeeTabConfiguration(
    params: {
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/utility/upload-employee-tab-configuration";

      const configs: IRequestConfig = getConfigs(
        "post",
        "multipart/form-data",
        url,
        options
      );

      let data = null;
      data = new FormData();
      if (params["file"]) {
        if (
          Object.prototype.toString.call(params["file"]) === "[object Array]"
        ) {
          for (const item of params["file"]) {
            data.append("file", item as any);
          }
        } else {
          data.append("file", params["file"] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update employee my info based on uploaded excel file
   */
  static utilityUploadEmployeeMyInfo(
    params: {
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/utility/upload-employee-my-info";

      const configs: IRequestConfig = getConfigs(
        "post",
        "multipart/form-data",
        url,
        options
      );

      let data = null;
      data = new FormData();
      if (params["file"]) {
        if (
          Object.prototype.toString.call(params["file"]) === "[object Array]"
        ) {
          for (const item of params["file"]) {
            data.append("file", item as any);
          }
        } else {
          data.append("file", params["file"] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update client configuration settings based on uploaded excel file
   */
  static utilityUploadClientConfigurationSettings(
    params: {
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/utility/upload-client-configuration-settings";

      const configs: IRequestConfig = getConfigs(
        "post",
        "multipart/form-data",
        url,
        options
      );

      let data = null;
      data = new FormData();
      if (params["file"]) {
        if (
          Object.prototype.toString.call(params["file"]) === "[object Array]"
        ) {
          for (const item of params["file"]) {
            data.append("file", item as any);
          }
        } else {
          data.append("file", params["file"] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update default configuration settings based on uploaded excel file
   */
  static utilityUploadDefaultConfigurationSettings(
    params: {
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/utility/upload-default-configuration-settings";

      const configs: IRequestConfig = getConfigs(
        "post",
        "multipart/form-data",
        url,
        options
      );

      let data = null;
      data = new FormData();
      if (params["file"]) {
        if (
          Object.prototype.toString.call(params["file"]) === "[object Array]"
        ) {
          for (const item of params["file"]) {
            data.append("file", item as any);
          }
        } else {
          data.append("file", params["file"] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Add or update employee records
   */
  static utilityAddorUpdateEmployeeRecords(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Xml_Employee[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/utility/employee-records";

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Tenant Onboard automation
   */
  static utilityOnboardAutomation(
    params: {
      /** requestBody */
      body?: HRIS_Api_Models_Request_Utility_AzureAuthentication;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/utility/onboard-automation";

      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class WeatherForecastService {
  /**
   *
   */
  static weatherForecastGet(
    options: IRequestOptions = {}
  ): Promise<HRIS_Api_WeatherForecast[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/WeatherForecast";

      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class HRIS_Api_Models_Common_EntityViewField {
  /**  */
  "id"?: number;

  /**  */
  "viewSectionId"?: number;

  /**  */
  "entityFieldName"?: string;

  /**  */
  "inputLabel"?: string;

  /**  */
  "editable"?: boolean;

  /**  */
  "inputHint"?: string;

  /**  */
  "styleClass"?: string;

  /**  */
  "displaySequence"?: number;

  /**  */
  "status"?: string;

  /**  */
  "defaultValue"?: string;

  /**  */
  "controlType"?: string;

  /**  */
  "hideLabelField"?: string;

  /**  */
  "isHTMLField"?: boolean;

  /**  */
  "isSpecialField"?: boolean;

  /**  */
  "isDefaultHide"?: boolean;

  /**  */
  "isUploadField"?: boolean;

  /**  */
  "eventFunctionName"?: string;

  /**  */
  "depandFieldJSON"?: string;

  /**  */
  "settingValue"?: string;

  /**  */
  "lastUpdated"?: Date;

  /**  */
  "lastUpdatedBy"?: string;

  /**  */
  "dataList"?: HRIS_Api_Models_Response_Common_SelectModelResponse[];

  constructor(data: undefined | any = {}) {
    this["id"] = data["id"];
    this["viewSectionId"] = data["viewSectionId"];
    this["entityFieldName"] = data["entityFieldName"];
    this["inputLabel"] = data["inputLabel"];
    this["editable"] = data["editable"];
    this["inputHint"] = data["inputHint"];
    this["styleClass"] = data["styleClass"];
    this["displaySequence"] = data["displaySequence"];
    this["status"] = data["status"];
    this["defaultValue"] = data["defaultValue"];
    this["controlType"] = data["controlType"];
    this["hideLabelField"] = data["hideLabelField"];
    this["isHTMLField"] = data["isHTMLField"];
    this["isSpecialField"] = data["isSpecialField"];
    this["isDefaultHide"] = data["isDefaultHide"];
    this["isUploadField"] = data["isUploadField"];
    this["eventFunctionName"] = data["eventFunctionName"];
    this["depandFieldJSON"] = data["depandFieldJSON"];
    this["settingValue"] = data["settingValue"];
    this["lastUpdated"] = data["lastUpdated"];
    this["lastUpdatedBy"] = data["lastUpdatedBy"];
    this["dataList"] = data["dataList"];
  }
}

export class HRIS_Api_Models_Common_Nested_Date_Range {
  /**  */
  "from"?: HRIS_Api_Models_Common_Date_Range;

  /**  */
  "to"?: HRIS_Api_Models_Common_Date_Range;

  constructor(data: undefined | any = {}) {
    this["from"] = data["from"];
    this["to"] = data["to"];
  }
}
export class HRIS_Api_Models_Common_Date_Range {
  /**  */
  "from"?: Date;

  /**  */
  "to"?: Date;

  constructor(data: undefined | any = {}) {
    this["from"] = data["from"];
    this["to"] = data["to"];
  }
}

export class HRIS_Api_Models_Common_Date_Range_String {
  /**  */
  "from"?: string;

  /**  */
  "to"?: string;

  constructor(data: undefined | any = {}) {
    this["from"] = data["from"];
    this["to"] = data["to"];
  }
}

export class HRIS_Api_Models_InternalModels_LeaveModel_AccessPolicyFilterExpression {
  /**  */
  "field"?: string;

  /**  */
  "operator"?: string;

  /**  */
  "value"?: string;

  constructor(data: undefined | any = {}) {
    this["field"] = data["field"];
    this["operator"] = data["operator"];
    this["value"] = data["value"];
  }
}
export class HRIS_Api_Models_InternalModels_LeaveModel_LeavePolicyDetailModel {
  /**  */
  "id"?: number;

  /**  */
  "leaveCode"?: string;

  /**  */
  "bfUnit"?: string;

  /**  */
  "bfUnitUom"?: string;

  /**  */
  "bfExpiry"?: string;

  /**  */
  "bfExpiryUom"?: string;

  /**  */
  "mode"?: HRIS_Api_Common_Enums_LeaveBalanceTaskModeTypes;

  /**  */
  "isStatutory"?: boolean;

  constructor(data: undefined | any = {}) {
    this["id"] = data["id"];
    this["leaveCode"] = data["leaveCode"];
    this["bfUnit"] = data["bfUnit"];
    this["bfUnitUom"] = data["bfUnitUom"];
    this["bfExpiry"] = data["bfExpiry"];
    this["bfExpiryUom"] = data["bfExpiryUom"];
    this["mode"] = data["mode"];
    this["isStatutory"] = data["isStatutory"];
  }
}

export class HRIS_Api_Models_InternalModels_LeaveModel_LeavePolicyModel {
  /**  */
  "id"?: number;

  /**  */
  "name"?: string;

  /**  */
  "bfCutOff"?: string;

  /**  */
  "bfCutOffUom"?: string;

  /**  */
  "companyCode"?: string;

  /**  */
  "grade"?: string;

  /**  */
  "staffType"?: string;

  /**  */
  "team"?: string;

  /**  */
  "dateJoinGroupMonthOfService"?: number;

  /**  */
  "commencementDateMonthOfService"?: number;

  /**  */
  "employeeId"?: string;

  /**  */
  "details"?: HRIS_Api_Models_InternalModels_LeaveModel_LeavePolicyDetailModel[];

  constructor(data: undefined | any = {}) {
    this["id"] = data["id"];
    this["name"] = data["name"];
    this["bfCutOff"] = data["bfCutOff"];
    this["bfCutOffUom"] = data["bfCutOffUom"];
    this["companyCode"] = data["companyCode"];
    this["grade"] = data["grade"];
    this["staffType"] = data["staffType"];
    this["team"] = data["team"];
    this["dateJoinGroupMonthOfService"] = data["dateJoinGroupMonthOfService"];
    this["commencementDateMonthOfService"] =
      data["commencementDateMonthOfService"];
    this["employeeId"] = data["employeeId"];
    this["details"] = data["details"];
  }
}

export enum HRIS_Api_Common_Enums_LeaveBalanceTaskModeTypes {
  "BringForward" = "BringForward",
  "Forfeiture" = "Forfeiture",
  "BringForwardAndForfeiture" = "BringForwardAndForfeiture",
}

export class HRIS_Api_Models_InternalModels_EmailModel_Attachment {
  /**  */
  "content"?: string;

  /**  */
  "type"?: string;

  /**  */
  "filename"?: string;

  constructor(data: undefined | any = {}) {
    this["content"] = data["content"];
    this["type"] = data["type"];
    this["filename"] = data["filename"];
  }
}

export class HRIS_Api_Models_InternalModels_EmailModel_EmailModel {
  /**  */
  "toEmails"?: string[];

  /**  */
  "ccEmails"?: string[];

  /**  */
  "bccEmails"?: string[];

  /**  */
  "subject"?: string;

  /**  */
  "content"?: string;

  /**  */
  "attachments"?: HRIS_Api_Models_InternalModels_EmailModel_Attachment[];

  constructor(data: undefined | any = {}) {
    this["toEmails"] = data["toEmails"];
    this["ccEmails"] = data["ccEmails"];
    this["bccEmails"] = data["bccEmails"];
    this["subject"] = data["subject"];
    this["content"] = data["content"];
    this["attachments"] = data["attachments"];
  }
}

export class HRIS_Api_Models_InternalModels_LeaveModel_AccessPolicyFilterExpressions {
  /**  */
  "filters"?: HRIS_Api_Models_InternalModels_LeaveModel_AccessPolicyFilterExpression[];

  /**  */
  "logic"?: string;

  constructor(data: undefined | any = {}) {
    this["filters"] = data["filters"];
    this["logic"] = data["logic"];
  }
}

export class HRIS_Api_Models_Request_Authentication_ChangePasswordRequest {
  /**  */
  "oldPassword"?: string;

  /**  */
  "newPassword"?: string;

  /**  */
  "confirmPassword"?: string;

  constructor(data: undefined | any = {}) {
    this["oldPassword"] = data["oldPassword"];
    this["newPassword"] = data["newPassword"];
    this["confirmPassword"] = data["confirmPassword"];
  }
}

export class HRIS_Api_Models_Request_Authentication_EmailAuthenticationRequest {
  /**  */
  "email"?: string;

  /**  */
  "password"?: string;

  constructor(data: undefined | any = {}) {
    this["email"] = data["email"];
    this["password"] = data["password"];
  }
}

export class HRIS_Api_Models_Request_Authentication_ForgotPasswordConfigurationRequest {
  /**  */
  "userName"?: string;

  /**  */
  "email"?: string;

  constructor(data: undefined | any = {}) {
    this["userName"] = data["userName"];
    this["email"] = data["email"];
  }
}

export class HRIS_Api_Models_Request_Authentication_ForgotPasswordField {
  /**  */
  "id"?: number;

  /**  */
  "name"?: string;

  /**  */
  "value"?: string;

  constructor(data: undefined | any = {}) {
    this["id"] = data["id"];
    this["name"] = data["name"];
    this["value"] = data["value"];
  }
}

export class HRIS_Api_Models_Request_Authentication_ForgotPasswordRequest {
  /**  */
  "userName"?: string;

  /**  */
  "email"?: string;

  /**  */
  "forgotPasswordFields"?: HRIS_Api_Models_Request_Authentication_ForgotPasswordField[];

  constructor(data: undefined | any = {}) {
    this["userName"] = data["userName"];
    this["email"] = data["email"];
    this["forgotPasswordFields"] = data["forgotPasswordFields"];
  }
}

export class HRIS_Api_Models_Request_Authentication_OtpAuthenticationRequest {
  /**  */
  "employeeCode"?: string;

  /**  */
  "otp"?: string;

  constructor(data: undefined | any = {}) {
    this["employeeCode"] = data["employeeCode"];
    this["otp"] = data["otp"];
  }
}

export class HRIS_Api_Models_Request_Authentication_RefreshOtpRequest {
  /**  */
  "employeeCode"?: string;

  constructor(data: undefined | any = {}) {
    this["employeeCode"] = data["employeeCode"];
  }
}

export class HRIS_Api_Models_Request_Authentication_RefreshTokenRequest {
  /**  */
  "token"?: string;

  /**  */
  "refreshToken"?: string;

  constructor(data: undefined | any = {}) {
    this["token"] = data["token"];
    this["refreshToken"] = data["refreshToken"];
  }
}

export class HRIS_Api_Models_Request_Authentication_ResetPasswordRequest {
  /**  */
  "token"?: string;

  /**  */
  "newPassword"?: string;

  /**  */
  "confirmPassword"?: string;

  constructor(data: undefined | any = {}) {
    this["token"] = data["token"];
    this["newPassword"] = data["newPassword"];
    this["confirmPassword"] = data["confirmPassword"];
  }
}

export class HRIS_Api_Models_Request_Authentication_SsoAuthenticationRequest {
  /**  */
  "token"?: string;

  constructor(data: undefined | any = {}) {
    this["token"] = data["token"];
  }
}

export class HRIS_Api_Models_Request_Authentication_UserNameAuthenticationRequest {
  /**  */
  "userName"?: string;

  /**  */
  "password"?: string;

  constructor(data: undefined | any = {}) {
    this["userName"] = data["userName"];
    this["password"] = data["password"];
  }
}

export class HRIS_Api_Models_Request_Content_TransalationContentRequest {
  /**  */
  "key"?: string;

  /**  */
  "context"?: string;

  /**  */
  "sourceText"?: string;

  /**  */
  "targetText"?: string;

  /**  */
  "language"?: string;

  /**  */
  "isHtml"?: boolean;

  constructor(data: undefined | any = {}) {
    this["key"] = data["key"];
    this["context"] = data["context"];
    this["sourceText"] = data["sourceText"];
    this["targetText"] = data["targetText"];
    this["language"] = data["language"];
    this["isHtml"] = data["isHtml"];
  }
}

export class HRIS_Api_Models_Request_Leave_LeaveApplicationRequest {
  /**  */
  "id"?: number;

  /**  */
  "employeeId"?: number;

  /**  */
  "employeeName"?: string;

  /**  */
  "applicationNo"?: string;

  /**  */
  "leaveCode"?: string;

  /**  */
  "leaveCategory"?: string;

  /**  */
  "leaveDateTimeStart"?: Date;

  /**  */
  "leaveDateTimeEnd"?: Date;

  /**  */
  "status"?: string;

  /**  */
  "fields"?: HRIS_Api_Models_Common_EntityViewField[];

  constructor(data: undefined | any = {}) {
    this["id"] = data["id"];
    this["employeeId"] = data["employeeId"];
    this["employeeName"] = data["employeeName"];
    this["applicationNo"] = data["applicationNo"];
    this["leaveCode"] = data["leaveCode"];
    this["leaveCategory"] = data["leaveCategory"];
    this["leaveDateTimeStart"] = data["leaveDateTimeStart"];
    this["leaveDateTimeEnd"] = data["leaveDateTimeEnd"];
    this["status"] = data["status"];
    this["fields"] = data["fields"];
  }
}

export class HRIS_Api_Models_Request_Leave_LeaveApprovalRequest {
  /**  */
  "applicationIds"?: number[];

  /**  */
  "remarks"?: string;

  constructor(data: undefined | any = {}) {
    this["applicationIds"] = data["applicationIds"];
    this["remarks"] = data["remarks"];
  }
}

export class HRIS_Api_Models_Request_Leave_LeaveEntitlementRequest {
  /**  */
  "id"?: number;

  /**  */
  "employeeId"?: number;

  /**  */
  "employeeName"?: string;

  /**  */
  "entitlementNo"?: string;

  /**  */
  "leaveCode"?: string;

  /**  */
  "leaveCategory"?: string;

  /**  */
  "remarks"?: string;

  /**  */
  "status"?: string;

  /**  */
  "fields"?: HRIS_Api_Models_Common_EntityViewField[];

  constructor(data: undefined | any = {}) {
    this["id"] = data["id"];
    this["employeeId"] = data["employeeId"];
    this["employeeName"] = data["employeeName"];
    this["entitlementNo"] = data["entitlementNo"];
    this["leaveCode"] = data["leaveCode"];
    this["leaveCategory"] = data["leaveCategory"];
    this["remarks"] = data["remarks"];
    this["status"] = data["status"];
    this["fields"] = data["fields"];
  }
}

export class HRIS_Api_Models_Request_Notification_EmailNotificationRequest {
  /**  */
  "employeeCode"?: string;

  /**  */
  "employeeName"?: string;

  /**  */
  "employeeEmail"?: string;

  /**  */
  "documentType"?: string;

  /**  */
  "fileName"?: string;

  /**  */
  "description"?: string;

  /**  */
  "cycleYear"?: string;

  constructor(data: undefined | any = {}) {
    this["employeeCode"] = data["employeeCode"];
    this["employeeName"] = data["employeeName"];
    this["employeeEmail"] = data["employeeEmail"];
    this["documentType"] = data["documentType"];
    this["fileName"] = data["fileName"];
    this["description"] = data["description"];
    this["cycleYear"] = data["cycleYear"];
  }
}

export class HRIS_Api_Models_Request_Leave_LeaveBatchApprovalRequest {
  /**  */
  "id"?: number[];

  /**  */
  "action"?: string;

  /**  */
  "remarks"?: string;

  constructor(data: undefined | any = {}) {
    this["id"] = data["id"];
    this["action"] = data["action"];
    this["remarks"] = data["remarks"];
  }
}

export class HRIS_Api_Models_Request_Leave_LeaveDelegationTaskRequest {
  /**  */
  "taskId"?: number;

  /**  */
  "delegateFrom"?: string;

  /**  */
  "delegateTo"?: string;

  /**  */
  "effectiveFrom"?: Date;

  /**  */
  "effectiveTo"?: Date;

  constructor(data: undefined | any = {}) {
    this["taskId"] = data["taskId"];
    this["delegateFrom"] = data["delegateFrom"];
    this["delegateTo"] = data["delegateTo"];
    this["effectiveFrom"] = data["effectiveFrom"];
    this["effectiveTo"] = data["effectiveTo"];
  }
}

export class HRIS_Api_Models_Request_Leave_LeaveTransactionFilter {
  /**  */
  "reportingLine"?: string;

  /**  */
  "filter"?: HRIS_Api_Models_InternalModels_LeaveModel_AccessPolicyFilterExpressions;

  /**  */
  "year"?: number;

  /**  */
  "fromMonth"?: number;

  /**  */
  "toMonth"?: number;

  constructor(data: undefined | any = {}) {
    this["reportingLine"] = data["reportingLine"];
    this["filter"] = data["filter"];
    this["year"] = data["year"];
    this["fromMonth"] = data["fromMonth"];
    this["toMonth"] = data["toMonth"];
  }
}

export class HRIS_Api_Models_Request_Leave_Reports_LeaveBalanceSummaryReportRequest {
  /**  */
  "employeeCodes"?: string[];

  /**  */
  "reportingLines"?: string[];

  /**  */
  "companyCode"?: string[];

  /**  */
  "division"?: string[];

  /**  */
  "department"?: string[];

  /**  */
  "leaveDate"?: HRIS_Api_Models_Common_Date_Range;

  /**  */
  "leaveTypes"?: string[];

  /**  */
  "transactionTypes"?: string[];

  /**  */
  "selectedColumns"?: string[];

  constructor(data: undefined | any = {}) {
    this["employeeCodes"] = data["employeeCodes"];
    this["reportingLines"] = data["reportingLines"];
    this["companyCode"] = data["companyCode"];
    this["division"] = data["division"];
    this["department"] = data["department"];
    this["leaveDate"] = data["leaveDate"];
    this["leaveTypes"] = data["leaveTypes"];
    this["transactionTypes"] = data["transactionTypes"];
    this["selectedColumns"] = data["selectedColumns"];
  }
}

export class HRIS_Api_Models_Request_Leave_Reports_LeaveReportFilterRequest {
  /**  */
  "reportingLine"?: string;

  /**  */
  "employeeCode"?: string[];

  constructor(data: undefined | any = {}) {
    this["reportingLine"] = data["reportingLine"];
    this["employeeCode"] = data["employeeCode"];
  }
}

export class HRIS_Api_Models_Request_Leave_Reports_LeaveTransactionReportRequest {
  /**  */
  "employeeCodes"?: string[];

  /**  */
  "reportingLines"?: string[];

  /**  */
  "companyCode"?: string[];

  /**  */
  "division"?: string[];

  /**  */
  "department"?: string[];

  /**  */
  "leaveDate"?: HRIS_Api_Models_Common_Date_Range;

  /**  */
  "leaveTypes"?: string[];

  /**  */
  "transactionTypes"?: string[];

  constructor(data: undefined | any = {}) {
    this["employeeCodes"] = data["employeeCodes"];
    this["reportingLines"] = data["reportingLines"];
    this["companyCode"] = data["companyCode"];
    this["division"] = data["division"];
    this["department"] = data["department"];
    this["leaveDate"] = data["leaveDate"];
    this["leaveTypes"] = data["leaveTypes"];
    this["transactionTypes"] = data["transactionTypes"];
  }
}

export class HRIS_Api_Models_Request_Leave_Reports_SickLeaveDetailsReportRequest {
  /**  */
  "employeeCodes"?: string[];

  /**  */
  "reportingLines"?: string[];

  /**  */
  "companyCode"?: string[];

  /**  */
  "division"?: string[];

  /**  */
  "department"?: string[];

  /**  */
  "leaveDate"?: HRIS_Api_Models_Common_Date_Range;

  /**  */
  "leaveTypes"?: string[];

  constructor(data: undefined | any = {}) {
    this["employeeCodes"] = data["employeeCodes"];
    this["reportingLines"] = data["reportingLines"];
    this["companyCode"] = data["companyCode"];
    this["division"] = data["division"];
    this["department"] = data["department"];
    this["leaveDate"] = data["leaveDate"];
    this["leaveTypes"] = data["leaveTypes"];
  }
}

export class HRIS_Api_Models_Request_Leave_Reports_AnnualLeaveReportRequest {
  /**  */
  "employeeCodes"?: string[];

  /**  */
  "reportingLines"?: string[];

  /**  */
  "companyCode"?: string[];

  /**  */
  "division"?: string[];

  /**  */
  "department"?: string[];

  /**  */
  "leaveDate"?: HRIS_Api_Models_Common_Date_Range;

  /**  */
  "leaveTypes"?: string[];

  constructor(data: undefined | any = {}) {
    this["employeeCodes"] = data["employeeCodes"];
    this["reportingLines"] = data["reportingLines"];
    this["companyCode"] = data["companyCode"];
    this["division"] = data["division"];
    this["department"] = data["department"];
    this["leaveDate"] = data["leaveDate"];
    this["leaveTypes"] = data["leaveTypes"];
  }
}

export class HRIS_Api_Models_Request_Nwow_Reports_SickNwowDetailsReportRequest {
  /**  */
  "employeeCodes"?: string[];

  /**  */
  "reportingLines"?: string[];

  /**  */
  "leaveDate"?: HRIS_Api_Models_Common_Date_Range;

  /**  */
  "leaveTypes"?: string[];

  constructor(data: undefined | any = {}) {
    this["employeeCodes"] = data["employeeCodes"];
    this["reportingLines"] = data["reportingLines"];
    this["leaveDate"] = data["leaveDate"];
    this["leaveTypes"] = data["leaveTypes"];
  }
}

export class HRIS_Api_Models_Request_Nwow_Reports_NwowBalanceSummaryReportRequest {
  /**  */
  "employeeCodes"?: string[];

  /**  */
  "reportingLines"?: string[];

  /**  */
  "nwowDate"?: HRIS_Api_Models_Common_Date_Range;

  /**  */
  "nwowTypes"?: string[];

  /**  */
  "transactionTypes"?: string[];

  constructor(data: undefined | any = {}) {
    this["employeeCodes"] = data["employeeCodes"];
    this["reportingLines"] = data["reportingLines"];
    this["nwowDate"] = data["nwowDate"];
    this["nwowTypes"] = data["nwowTypes"];
    this["transactionTypes"] = data["transactionTypes"];
  }
}

export class HRIS_Api_Models_Request_Nwow_Reports_NwowTransactionReportRequest {
  /**  */
  "employeeCodes"?: string[];

  /**  */
  "reportingLines"?: string[];

  /**  */
  "nwowDate"?: HRIS_Api_Models_Common_Date_Range;

  /**  */
  "nwowTypes"?: string[];

  /**  */
  "transactionTypes"?: string[];

  constructor(data: undefined | any = {}) {
    this["employeeCodes"] = data["employeeCodes"];
    this["reportingLines"] = data["reportingLines"];
    this["nwowDate"] = data["nwowDate"];
    this["nwowTypes"] = data["nwowTypes"];
    this["transactionTypes"] = data["transactionTypes"];
  }
}

export class HRIS_Api_Models_Request_Nwow_Reports_AnnualNwowReportRequest {
  /**  */
  "employeeCodes"?: string[];

  /**  */
  "reportingLines"?: string[];

  /**  */
  "leaveDate"?: HRIS_Api_Models_Common_Date_Range;

  /**  */
  "leaveTypes"?: string[];

  constructor(data: undefined | any = {}) {
    this["employeeCodes"] = data["employeeCodes"];
    this["reportingLines"] = data["reportingLines"];
    this["leaveDate"] = data["leaveDate"];
    this["leaveTypes"] = data["leaveTypes"];
  }
}

export class HRIS_Api_Models_Request_Leave_Reports_StatutoryLeaveBalanceReportRequest {
  /**  */
  "employeeCodes"?: string[];

  /**  */
  "reportingLines"?: string[];

  /**  */
  "companyCode"?: string[];

  /**  */
  "division"?: string[];

  /**  */
  "department"?: string[];

  /**  */
  "leaveDate"?: HRIS_Api_Models_Common_Date_Range;

  /**  */
  "leaveTypes"?: string[];

  /**  */
  "transactionTypes"?: string[];

  constructor(data: undefined | any = {}) {
    this["employeeCodes"] = data["employeeCodes"];
    this["reportingLines"] = data["reportingLines"];
    this["companyCode"] = data["companyCode"];
    this["division"] = data["division"];
    this["department"] = data["department"];
    this["leaveDate"] = data["leaveDate"];
    this["leaveTypes"] = data["leaveTypes"];
    this["transactionTypes"] = data["transactionTypes"];
  }
}

export class HRIS_Api_Models_Request_Rental_RentalAnnualTaxReportRequest {
  /**  */
  "employeeCodes"?: string[];

  /**  */
  "statuses"?: string[];

  /**  */
  "assessmentYears"?: string[];

  /**  */
  "applicationStatuses"?: HRIS_Api_Common_Enums_Enums_RentalApplicationStatus[];

  /**  */
  "businessUnits"?: string[];

  /**  */
  "companyCodes"?: string[];

  /**  */
  "departments"?: string[];

  /**  */
  "divisions"?: string[];

  /**  */
  "applicationNo"?: HRIS_Api_Models_Common_Date_Range_String;

  /**  */
  "employeeTerminateDate"?: HRIS_Api_Models_Common_Date_Range;

  /**  */
  "applicationDate"?: HRIS_Api_Models_Common_Date_Range;

  /**  */
  "approvalDate"?: HRIS_Api_Models_Common_Date_Range;

  /**  */
  "reimbursementDate"?: HRIS_Api_Models_Common_Date_Range;

  constructor(data: undefined | any = {}) {
    this["employeeCodes"] = data["employeeCodes"];
    this["statuses"] = data["statuses"];
    this["assessmentYears"] = data["assessmentYears"];
    this["applicationStatuses"] = data["applicationStatuses"];
    this["businessUnits"] = data["businessUnits"];
    this["companyCodes"] = data["companyCodes"];
    this["departments"] = data["departments"];
    this["divisions"] = data["divisions"];
    this["applicationNo"] = data["applicationNo"];
    this["employeeTerminateDate"] = data["employeeTerminateDate"];
    this["applicationDate"] = data["applicationDate"];
    this["approvalDate"] = data["approvalDate"];
    this["reimbursementDate"] = data["reimbursementDate"];
  }
}

export class HRIS_Api_Models_Request_Rental_RentalApplicationFieldRequest {
  /**  */
  "id"?: number;

  /**  */
  "viewSectionId"?: number;

  /**  */
  "fieldName"?: string;

  /**  */
  "inputName"?: string;

  /**  */
  "fieldLabel"?: string;

  /**  */
  "controlType"?: string;

  /**  */
  "dataType"?: string;

  /**  */
  "storageDataType"?: string;

  /**  */
  "inputHint"?: string;

  /**  */
  "formatString"?: string;

  /**  */
  "length"?: number;

  /**  */
  "displaySequence"?: number;

  /**  */
  "defaultValue"?: string;

  /**  */
  "value"?: any | null;

  /**  */
  "fileAttachments"?: HRIS_Api_Models_Request_Rental_RentalAttachmentRequest[];

  /**  */
  "isAllowNull"?: boolean;

  /**  */
  "isEditable"?: boolean;

  /**  */
  "isHideLabelField"?: boolean;

  /**  */
  "isHtmlField"?: boolean;

  /**  */
  "isSpecialField"?: boolean;

  /**  */
  "isDefaultHide"?: boolean;

  /**  */
  "isDetailView"?: boolean;

  /**  */
  "eventFunctionName"?: string;

  /**  */
  "depandFieldJson"?: string;

  /**  */
  "settingValue"?: string;

  /**  */
  "isUploadField"?: boolean;

  /**  */
  "styleClass"?: string;

  /**  */
  "controlStyle"?: string;

  constructor(data: undefined | any = {}) {
    this["id"] = data["id"];
    this["viewSectionId"] = data["viewSectionId"];
    this["fieldName"] = data["fieldName"];
    this["inputName"] = data["inputName"];
    this["fieldLabel"] = data["fieldLabel"];
    this["controlType"] = data["controlType"];
    this["dataType"] = data["dataType"];
    this["storageDataType"] = data["storageDataType"];
    this["inputHint"] = data["inputHint"];
    this["formatString"] = data["formatString"];
    this["length"] = data["length"];
    this["displaySequence"] = data["displaySequence"];
    this["defaultValue"] = data["defaultValue"];
    this["value"] = data["value"];
    this["fileAttachments"] = data["fileAttachments"];
    this["isAllowNull"] = data["isAllowNull"];
    this["isEditable"] = data["isEditable"];
    this["isHideLabelField"] = data["isHideLabelField"];
    this["isHtmlField"] = data["isHtmlField"];
    this["isSpecialField"] = data["isSpecialField"];
    this["isDefaultHide"] = data["isDefaultHide"];
    this["isDetailView"] = data["isDetailView"];
    this["eventFunctionName"] = data["eventFunctionName"];
    this["depandFieldJson"] = data["depandFieldJson"];
    this["settingValue"] = data["settingValue"];
    this["isUploadField"] = data["isUploadField"];
    this["styleClass"] = data["styleClass"];
    this["controlStyle"] = data["controlStyle"];
  }
}

export class HRIS_Api_Models_Request_Rental_RentalApplicationLogReportRequest {
  /**  */
  "companyCode"?: string;

  /**  */
  "assessmentYear"?: string;

  /**  */
  "applicationStatus"?: string;

  /**  */
  "department"?: string;

  /**  */
  "employeeId"?: string;

  /**  */
  "applicationFromNo"?: string;

  /**  */
  "applicationToNo"?: string;

  /**  */
  "applicationFromDate"?: Date;

  /**  */
  "applicationToDate"?: Date;

  /**  */
  "approvalFromDate"?: Date;

  /**  */
  "approvalToDate"?: Date;

  /**  */
  "reimbursementStartDate"?: Date;

  /**  */
  "reimbursementEndDate"?: Date;

  constructor(data: undefined | any = {}) {
    this["companyCode"] = data["companyCode"];
    this["assessmentYear"] = data["assessmentYear"];
    this["applicationStatus"] = data["applicationStatus"];
    this["department"] = data["department"];
    this["employeeId"] = data["employeeId"];
    this["applicationFromNo"] = data["applicationFromNo"];
    this["applicationToNo"] = data["applicationToNo"];
    this["applicationFromDate"] = data["applicationFromDate"];
    this["applicationToDate"] = data["applicationToDate"];
    this["approvalFromDate"] = data["approvalFromDate"];
    this["approvalToDate"] = data["approvalToDate"];
    this["reimbursementStartDate"] = data["reimbursementStartDate"];
    this["reimbursementEndDate"] = data["reimbursementEndDate"];
  }
}

export class HRIS_Api_Models_Request_Rental_RentalApplicationRequest {
  /**  */
  "id"?: number;

  /**  */
  "formName"?: string;

  /**  */
  "applicationId"?: number;

  /**  */
  "applicationStatus"?: string;

  /**  */
  "isDraft"?: boolean;

  /**  */
  "employeeCode"?: string;

  /**  */
  "sections"?: HRIS_Api_Models_Request_Rental_RentalApplicationSectionRequest[];

  /**  */
  "comments"?: HRIS_Api_Models_Request_Rental_RentalCommentRequest[];

  /**  */
  "claims"?: HRIS_Api_Models_Request_Rental_RentalClaimRequest[];

  /**  */
  "excludeUpdateFields"?: string[];

  constructor(data: undefined | any = {}) {
    this["id"] = data["id"];
    this["formName"] = data["formName"];
    this["applicationId"] = data["applicationId"];
    this["applicationStatus"] = data["applicationStatus"];
    this["isDraft"] = data["isDraft"];
    this["employeeCode"] = data["employeeCode"];
    this["sections"] = data["sections"];
    this["comments"] = data["comments"];
    this["claims"] = data["claims"];
    this["excludeUpdateFields"] = data["excludeUpdateFields"];
  }
}

export class HRIS_Api_Models_Request_Rental_RentalApplicationSectionRequest {
  /**  */
  "id"?: number;

  /**  */
  "sectionName"?: string;

  /**  */
  "displaySequence"?: number;

  /**  */
  "defaultExpanded"?: boolean;

  /**  */
  "displayLayout"?: number;

  /**  */
  "fields"?: HRIS_Api_Models_Request_Rental_RentalApplicationFieldRequest[];

  constructor(data: undefined | any = {}) {
    this["id"] = data["id"];
    this["sectionName"] = data["sectionName"];
    this["displaySequence"] = data["displaySequence"];
    this["defaultExpanded"] = data["defaultExpanded"];
    this["displayLayout"] = data["displayLayout"];
    this["fields"] = data["fields"];
  }
}

export class HRIS_Api_Models_Request_Rental_RentalApplicationSummaryReportRequest {
  /**  */
  "companyCode"?: string;

  /**  */
  "assessmentYear"?: string;

  /**  */
  "applicationStatus"?: string;

  /**  */
  "department"?: string;

  /**  */
  "employeeId"?: string;

  /**  */
  "applicationFromNo"?: string;

  /**  */
  "applicationToNo"?: string;

  /**  */
  "applicationFromDate"?: Date;

  /**  */
  "applicationToDate"?: Date;

  /**  */
  "approvalFromDate"?: Date;

  /**  */
  "approvalToDate"?: Date;

  /**  */
  "reimbursementStartDate"?: Date;

  /**  */
  "reimbursementEndDate"?: Date;

  constructor(data: undefined | any = {}) {
    this["companyCode"] = data["companyCode"];
    this["assessmentYear"] = data["assessmentYear"];
    this["applicationStatus"] = data["applicationStatus"];
    this["department"] = data["department"];
    this["employeeId"] = data["employeeId"];
    this["applicationFromNo"] = data["applicationFromNo"];
    this["applicationToNo"] = data["applicationToNo"];
    this["applicationFromDate"] = data["applicationFromDate"];
    this["applicationToDate"] = data["applicationToDate"];
    this["approvalFromDate"] = data["approvalFromDate"];
    this["approvalToDate"] = data["approvalToDate"];
    this["reimbursementStartDate"] = data["reimbursementStartDate"];
    this["reimbursementEndDate"] = data["reimbursementEndDate"];
  }
}

export class HRIS_Api_Models_Request_Rental_RentalApprovalClaim {
  /**  */
  "id"?: number;

  /**  */
  "action"?: HRIS_Api_Common_Enums_Enums_RentalApplicationAction;

  constructor(data: undefined | any = {}) {
    this["id"] = data["id"];
    this["action"] = data["action"];
  }
}

export class HRIS_Api_Models_Request_Rental_RentalAttachmentRequest {
  /**  */
  "id"?: number;

  /**  */
  "uploadId"?: number;

  /**  */
  "fileId"?: number;

  /**  */
  "fileName"?: string;

  /**  */
  "status"?: string;

  constructor(data: undefined | any = {}) {
    this["id"] = data["id"];
    this["uploadId"] = data["uploadId"];
    this["fileId"] = data["fileId"];
    this["fileName"] = data["fileName"];
    this["status"] = data["status"];
  }
}

export class HRIS_Api_Models_Request_Rental_RentalAttachmentLogReportRequest {
  /**  */
  "type"?: string;

  /**  */
  "companyCodes"?: string[];

  /**  */
  "companyNames"?: string[];

  /**  */
  "actionDate"?: HRIS_Api_Models_Common_Date_Range;

  constructor(data: undefined | any = {}) {
    this["type"] = data["type"];
    this["companyCodes"] = data["companyCodes"];
    this["companyNames"] = data["companyNames"];
    this["actionDate"] = data["actionDate"];
  }
}

export class HRIS_Api_Models_Request_Rental_RentalClaimApprovalRequest {
  /**  */
  "claimRequests"?: HRIS_Api_Models_Request_Rental_RentalApprovalClaim[];

  /**  */
  "isPreview"?: boolean;

  /**  */
  "uploadId"?: number;

  constructor(data: undefined | any = {}) {
    this["claimRequests"] = data["claimRequests"];
    this["isPreview"] = data["isPreview"];
    this["uploadId"] = data["uploadId"];
  }
}

export class HRIS_Api_Models_Request_Rental_RentalClaimDetailReportRequest {
  /**  */
  "employeeCodes"?: string[];

  /**  */
  "statuses"?: string[];

  /**  */
  "assessmentYears"?: string[];

  /**  */
  "applicationStatuses"?: HRIS_Api_Common_Enums_Enums_RentalApplicationStatus[];

  /**  */
  "businessUnits"?: string[];

  /**  */
  "companyCodes"?: string[];

  /**  */
  "departments"?: string[];

  /**  */
  "divisions"?: string[];

  /**  */
  "applicationNo"?: HRIS_Api_Models_Common_Date_Range_String;

  /**  */
  "employeeTerminateDate"?: HRIS_Api_Models_Common_Date_Range;

  /**  */
  "applicationDate"?: HRIS_Api_Models_Common_Date_Range;

  /**  */
  "approvalDate"?: HRIS_Api_Models_Common_Date_Range;

  /**  */
  "reimbursementDate"?: HRIS_Api_Models_Common_Date_Range;

  constructor(data: undefined | any = {}) {
    this["employeeCodes"] = data["employeeCodes"];
    this["statuses"] = data["statuses"];
    this["assessmentYears"] = data["assessmentYears"];
    this["applicationStatuses"] = data["applicationStatuses"];
    this["businessUnits"] = data["businessUnits"];
    this["companyCodes"] = data["companyCodes"];
    this["departments"] = data["departments"];
    this["divisions"] = data["divisions"];
    this["applicationNo"] = data["applicationNo"];
    this["employeeTerminateDate"] = data["employeeTerminateDate"];
    this["applicationDate"] = data["applicationDate"];
    this["approvalDate"] = data["approvalDate"];
    this["reimbursementDate"] = data["reimbursementDate"];
  }
}

export class HRIS_Api_Models_Request_Rental_RentalClaimRequest {
  /**  */
  "id"?: number;

  /**  */
  "employeeCode"?: string;

  /**  */
  "uploadId"?: number;

  /**  */
  "displaySequence"?: number;

  /**  */
  "period"?: string;

  /**  */
  "periodFrom"?: Date;

  /**  */
  "periodTo"?: Date;

  /**  */
  "monthlySalary"?: number;

  /**  */
  "cappedAmount"?: number;

  /**  */
  "rentalAmount"?: number;

  /**  */
  "claimedAmount"?: number;

  /**  */
  "approvedAmount"?: number;

  /**  */
  "employeeComment"?: string;

  /**  */
  "approverComment"?: string;

  /**  */
  "status"?: string;

  /**  */
  "postingStatus"?: string;

  /**  */
  "enable"?: boolean;

  /**  */
  "reimbursementAmount"?: number;

  /**  */
  "govtRentManagementFee"?: number;

  /**  */
  "totalClaims"?: number;

  /**  */
  "adjustedHousing"?: string;

  /**  */
  "attachmentFiles"?: HRIS_Api_Models_Request_Rental_RentalAttachmentRequest[];

  constructor(data: undefined | any = {}) {
    this["id"] = data["id"];
    this["employeeCode"] = data["employeeCode"];
    this["uploadId"] = data["uploadId"];
    this["displaySequence"] = data["displaySequence"];
    this["period"] = data["period"];
    this["periodFrom"] = data["periodFrom"];
    this["periodTo"] = data["periodTo"];
    this["monthlySalary"] = data["monthlySalary"];
    this["cappedAmount"] = data["cappedAmount"];
    this["rentalAmount"] = data["rentalAmount"];
    this["claimedAmount"] = data["claimedAmount"];
    this["approvedAmount"] = data["approvedAmount"];
    this["employeeComment"] = data["employeeComment"];
    this["approverComment"] = data["approverComment"];
    this["status"] = data["status"];
    this["postingStatus"] = data["postingStatus"];
    this["enable"] = data["enable"];
    this["reimbursementAmount"] = data["reimbursementAmount"];
    this["govtRentManagementFee"] = data["govtRentManagementFee"];
    this["totalClaims"] = data["totalClaims"];
    this["adjustedHousing"] = data["adjustedHousing"];
    this["attachmentFiles"] = data["attachmentFiles"];
  }
}

export class HRIS_Api_Models_Request_Rental_RentalClaimSummaryReportRequest {
  /**  */
  "companyCode"?: string;

  /**  */
  "assessmentYear"?: string;

  /**  */
  "applicationStatus"?: string;

  /**  */
  "department"?: string;

  /**  */
  "applicationFromNo"?: string;

  /**  */
  "applicationToNo"?: string;

  /**  */
  "applicationFromDate"?: Date;

  /**  */
  "applicationToDate"?: Date;

  /**  */
  "approvalFromDate"?: Date;

  /**  */
  "approvalToDate"?: Date;

  /**  */
  "reimbursementStartDate"?: Date;

  /**  */
  "reimbursementEndDate"?: Date;

  constructor(data: undefined | any = {}) {
    this["companyCode"] = data["companyCode"];
    this["assessmentYear"] = data["assessmentYear"];
    this["applicationStatus"] = data["applicationStatus"];
    this["department"] = data["department"];
    this["applicationFromNo"] = data["applicationFromNo"];
    this["applicationToNo"] = data["applicationToNo"];
    this["applicationFromDate"] = data["applicationFromDate"];
    this["applicationToDate"] = data["applicationToDate"];
    this["approvalFromDate"] = data["approvalFromDate"];
    this["approvalToDate"] = data["approvalToDate"];
    this["reimbursementStartDate"] = data["reimbursementStartDate"];
    this["reimbursementEndDate"] = data["reimbursementEndDate"];
  }
}

export class HRIS_Api_Models_Request_Rental_RentalCommentRequest {
  /**  */
  "id"?: number;

  /**  */
  "rentalClaimId"?: number;

  /**  */
  "commentBy"?: string;

  /**  */
  "commentDate"?: Date;

  /**  */
  "commentType"?: number;

  /**  */
  "comment"?: string;

  /**  */
  "status"?: string;

  /**  */
  "createdAt"?: Date;

  /**  */
  "createdBy"?: string;

  constructor(data: undefined | any = {}) {
    this["id"] = data["id"];
    this["rentalClaimId"] = data["rentalClaimId"];
    this["commentBy"] = data["commentBy"];
    this["commentDate"] = data["commentDate"];
    this["commentType"] = data["commentType"];
    this["comment"] = data["comment"];
    this["status"] = data["status"];
    this["createdAt"] = data["createdAt"];
    this["createdBy"] = data["createdBy"];
  }
}

export class HRIS_Api_Models_Request_Rental_RentalInterfaceFileRequest {
  /**  */
  "companyCode"?: string;

  /**  */
  "departmentCodes"?: string[];

  /**  */
  "costCenterCodes"?: string[];

  /**  */
  "employeeCodes"?: string[];

  /**  */
  "periodFrom"?: string;

  /**  */
  "periodTo"?: string;

  /**  */
  "effectiveFrom"?: string;

  /**  */
  "effectiveTo"?: string;

  /**  */
  "batchReferences"?: string;

  constructor(data: undefined | any = {}) {
    this["companyCode"] = data["companyCode"];
    this["departmentCodes"] = data["departmentCodes"];
    this["costCenterCodes"] = data["costCenterCodes"];
    this["employeeCodes"] = data["employeeCodes"];
    this["periodFrom"] = data["periodFrom"];
    this["periodTo"] = data["periodTo"];
    this["effectiveFrom"] = data["effectiveFrom"];
    this["effectiveTo"] = data["effectiveTo"];
    this["batchReferences"] = data["batchReferences"];
  }
}

export class HRIS_Api_Models_Request_Rental_RentalMonthlyRentalReportRequest {
  /**  */
  "employeeCodes"?: string[];

  /**  */
  "statuses"?: string[];

  /**  */
  "assessmentYears"?: string[];

  /**  */
  "applicationStatuses"?: HRIS_Api_Common_Enums_Enums_RentalApplicationStatus[];

  /**  */
  "businessUnits"?: string[];

  /**  */
  "companyCodes"?: string[];

  /**  */
  "departments"?: string[];

  /**  */
  "divisions"?: string[];

  /**  */
  "applicationNo"?: HRIS_Api_Models_Common_Date_Range_String;

  /**  */
  "employeeTerminateDate"?: HRIS_Api_Models_Common_Date_Range;

  /**  */
  "applicationDate"?: HRIS_Api_Models_Common_Date_Range;

  /**  */
  "approvalDate"?: HRIS_Api_Models_Common_Date_Range;

  /**  */
  "reimbursementDate"?: HRIS_Api_Models_Common_Date_Range;

  constructor(data: undefined | any = {}) {
    this["employeeCodes"] = data["employeeCodes"];
    this["statuses"] = data["statuses"];
    this["assessmentYears"] = data["assessmentYears"];
    this["applicationStatuses"] = data["applicationStatuses"];
    this["businessUnits"] = data["businessUnits"];
    this["companyCodes"] = data["companyCodes"];
    this["departments"] = data["departments"];
    this["divisions"] = data["divisions"];
    this["applicationNo"] = data["applicationNo"];
    this["employeeTerminateDate"] = data["employeeTerminateDate"];
    this["applicationDate"] = data["applicationDate"];
    this["approvalDate"] = data["approvalDate"];
    this["reimbursementDate"] = data["reimbursementDate"];
  }
}

export class HRIS_Api_Models_Request_Admin_CompanyDocumentRequest {
  /**  */
  "id"?: string;

  /**  */
  "effective_From"?: string;

  /**  */
  "effective_To"?: string;

  /**  */
  "status"?: string;

  /**  */
  "content"?: string;

  constructor(data: undefined | any = {}) {
    this["id"] = data["id"];
    this["effective_From"] = data["effective_From"];
    this["effective_To"] = data["effective_To"];
    this["status"] = data["status"];
    this["content"] = data["content"];
  }
}

export class HRIS_Api_Models_Request_Admin_EmailConfigurationRequest {
  /**  */
  "id"?: number;

  /**  */
  "moduleName"?: string;

  /**  */
  "templateName"?: string;

  /**  */
  "subject"?: string;

  /**  */
  "description"?: string;

  /**  */
  "templateText"?: string;

  /**  */
  "effectiveFrom"?: string;

  /**  */
  "effectiveTo"?: string;

  /**  */
  "ccEmails"?: string;

  /**  */
  "bccEmails"?: string;

  /**  */
  "language"?: string;

  /**  */
  "IsEnabled"?: string;

  constructor(data: undefined | any = {}) {
    this["id"] = data["id"];
    this["moduleName"] = data["moduleName"];
    this["templateName"] = data["templateName"];
    this["subject"] = data["subject"];
    this["description"] = data["description"];
    this["templateText"] = data["templateText"];
    this["effectiveFrom"] = data["effectiveFrom"];
    this["effectiveTo"] = data["effectiveTo"];
    this["ccEmails"] = data["ccEmails"];
    this["bccEmails"] = data["bccEmails"];
    this["language"] = data["language"];
    this["IsEnabled"] = data["IsEnabled"];
  }
}

export class HRIS_Api_Models_Request_Email_EmailReminderRequest {
  /**  */
  "scheduleId"?: string;

  /**  */
  "description"?: string;

  /**  */
  "subject"?: string;

  /**  */
  "content"?: string;

  /**  */
  "schedule"?: string;

  /**  */
  "ccList"?: string;

  /**  */
  "bccList"?: string;

  /**  */
  "recipients"?: string[];

  /**  */
  "unit"?: HRIS_Api_Common_Enums_TaskUnitTypes;

  /**  */
  "sendDateTime"?: Date;

  /**  */
  "enabled"?: boolean;

  constructor(data: undefined | any = {}) {
    this["scheduleId"] = data["scheduleId"];
    this["description"] = data["description"];
    this["subject"] = data["subject"];
    this["content"] = data["content"];
    this["schedule"] = data["schedule"];
    this["ccList"] = data["ccList"];
    this["bccList"] = data["bccList"];
    this["recipients"] = data["recipients"];
    this["unit"] = data["unit"];
    this["sendDateTime"] = data["sendDateTime"];
    this["enabled"] = data["enabled"];
  }
}

export class HRIS_Api_Models_Request_Admin_OrganizationalSettingConfigurationRequest {
  /**  */
  "targetOrganizationType"?: string;

  /**  */
  "targetOrganizationName"?: string[];

  /**  */
  "settingConfigurations"?: HRIS_Api_Models_Request_Admin_SettingConfigurationRequest[];

  constructor(data: undefined | any = {}) {
    this["targetOrganizationType"] = data["targetOrganizationType"];
    this["targetOrganizationName"] = data["targetOrganizationName"];
    this["settingConfigurations"] = data["settingConfigurations"];
  }
}

export class HRIS_Api_Models_Request_Admin_SettingConfigurationRequest {
  /**  */
  "settingKey"?: string;

  /**  */
  "settingValue"?: string;

  constructor(data: undefined | any = {}) {
    this["settingKey"] = data["settingKey"];
    this["settingValue"] = data["settingValue"];
  }
}

export class HRIS_Api_Models_Request_Admin_DataAuthorizationRequest {
  /**  */
  "id"?: number;

  /**  */
  "values"?: string[];

  constructor(data: undefined | any = {}) {
    this["id"] = data["id"];
    this["values"] = data["values"];
  }
}

export class HRIS_Api_Models_Request_Admin_EmployeePermissionRequest {
  /**  */
  "employeeCode"?: string;

  /**  */
  "roleIds"?: number[];

  /**  */
  "dataAuthorizations"?: HRIS_Api_Models_Request_Admin_DataAuthorizationRequest[];

  constructor(data: undefined | any = {}) {
    this["employeeCode"] = data["employeeCode"];
    this["roleIds"] = data["roleIds"];
    this["dataAuthorizations"] = data["dataAuthorizations"];
  }
}

export class HRIS_Api_Models_Request_Admin_RoleModuleRequest {
  /**  */
  "id"?: number;

  /**  */
  "name"?: string;

  /**  */
  "remark"?: string;

  /**  */
  "moduleIds"?: number[];

  /**  */
  "dataAuthorizations"?: HRIS_Api_Models_Request_Admin_DataAuthorizationRequest[];

  constructor(data: undefined | any = {}) {
    this["id"] = data["id"];
    this["name"] = data["name"];
    this["remark"] = data["remark"];
    this["moduleIds"] = data["moduleIds"];
    this["dataAuthorizations"] = data["dataAuthorizations"];
  }
}

export class HRIS_Api_Models_Response_Common_SelectModelResponse {
  /**  */
  "id"?: number;

  /**  */
  "name"?: string;

  /**  */
  "description"?: string;

  /**  */
  "value"?: string;

  constructor(data: undefined | any = {}) {
    this["id"] = data["id"];
    this["name"] = data["name"];
    this["description"] = data["description"];
    this["value"] = data["value"];
  }
}

export class HRIS_Api_Models_Request_Document_DocumentDownloadRequest {
  /**  */
  "fileId"?: string;

  constructor(data: undefined | any = {}) {
    this["fileId"] = data["fileId"];
  }
}

export class HRIS_Api_WeatherForecast {
  /**  */
  "date"?: Date;

  /**  */
  "temperatureC"?: number;

  /**  */
  "temperatureF"?: number;

  /**  */
  "summary"?: string;

  constructor(data: undefined | any = {}) {
    this["date"] = data["date"];
    this["temperatureC"] = data["temperatureC"];
    this["temperatureF"] = data["temperatureF"];
    this["summary"] = data["summary"];
  }
}

export enum HRIS_Api_Common_Enums_Enums_RentalApplicationAction {
  "View_Application" = "View_Application",
  "Create_Draft" = "Create_Draft",
  "Update_Draft" = "Update_Draft",
  "Submit_Application" = "Submit_Application",
  "Approve_Application" = "Approve_Application",
  "Reject_Application" = "Reject_Application",
  "Change_Application" = "Change_Application",
  "Return_Application" = "Return_Application",
  "Submit_Claims" = "Submit_Claims",
  "Send_Comments" = "Send_Comments",
  "Approve_Claim" = "Approve_Claim",
  "Reject_Claim" = "Reject_Claim",
  "Return_Claim" = "Return_Claim",
  "Withdraw_Application" = "Withdraw_Application",
  "Finialize_Application" = "Finialize_Application",
}

export enum HRIS_Api_Common_Enums_Enums_RentalApplicationForm {
  "NewApplicationForm" = "NewApplicationForm",
  "ViewRentalForm" = "ViewRentalForm",
  "ChangeRentalForm" = "ChangeRentalForm",
  "ApproveApplicationForm" = "ApproveApplicationForm",
  "SubmitClaimForm" = "SubmitClaimForm",
  "ApproveClaimForm" = "ApproveClaimForm",
  "WithdrawRentalForm" = "WithdrawRentalForm",
}

export enum HRIS_Api_Common_Enums_Enums_RentalApplicationStatus {
  "Draft" = "Draft",
  "Submitted" = "Submitted",
  "Changed" = "Changed",
  "Withdraw" = "Withdraw",
  "Returned" = "Returned",
  "Approved" = "Approved",
  "Rejected" = "Rejected",
  "Progress" = "Progress",
  "PartialCompleted" = "PartialCompleted",
  "Disapproved" = "Disapproved",
  "Completed" = "Completed",
}
export enum HRIS_Api_Common_Enums_TaskUnitTypes {
  "Daily" = "Daily",
  "Weekly" = "Weekly",
  "Monthly" = "Monthly",
  "Quarterly" = "Quarterly",
  "HalfYearly" = "HalfYearly",
  "Yearly" = "Yearly",
  "Once" = "Once",
}

export enum HRIS_Api_DocumentType {
  "RentalReimbursement" = "Rental Reimbursement",
  "LeaveApplication" = "Leave Application",
}

export class HRIS_Api_Models_Request_Document_FolderDocumentRequest {
  /**  */
  "folderId"?: string;

  constructor(data: undefined | any = {}) {
    this["folderId"] = data["folderId"];
  }
}

export class HRIS_Api_Models_Request_Document_UploadFileRequest {
  /**  */
  "uploadId"?: string;

  constructor(data: undefined | any = {}) {
    this["uploadId"] = data["uploadId"];
  }
}

export class HRIS_Api_Models_Request_Utility_AzureAuthentication {
  /**  */
  "username"?: string;

  /**  */
  "password"?: string;

  constructor(data: undefined | any = {}) {
    this["username"] = data["username"];
    this["password"] = data["password"];
  }
}

export class HRIS_Api_Models_Request_Xml_AddressInfo {
  /**  */
  "effectiveDate"?: Date;

  /**  */
  "filename"?: string;

  /**  */
  "addressType"?: string;

  /**  */
  "addressLine1"?: string;

  /**  */
  "addressLine2"?: string;

  /**  */
  "addressLine3"?: string;

  /**  */
  "addressLine4"?: string;

  /**  */
  "postalCode"?: string;

  /**  */
  "country"?: string;

  constructor(data: undefined | any = {}) {
    this["effectiveDate"] = data["effectiveDate"];
    this["filename"] = data["filename"];
    this["addressType"] = data["addressType"];
    this["addressLine1"] = data["addressLine1"];
    this["addressLine2"] = data["addressLine2"];
    this["addressLine3"] = data["addressLine3"];
    this["addressLine4"] = data["addressLine4"];
    this["postalCode"] = data["postalCode"];
    this["country"] = data["country"];
  }
}

export class HRIS_Api_Models_Request_Xml_AssignmentInfo {
  /**  */
  "effectiveDate"?: Date;

  /**  */
  "filename"?: string;

  /**  */
  "legalEntityId"?: string;

  /**  */
  "originalDateOfHire"?: Date;

  /**  */
  "enterpriseHireDate"?: Date;

  /**  */
  "contractProjectedEndDate"?: Date;

  /**  */
  "assAttributeNumber1"?: string;

  /**  */
  "assAttribute3"?: string;

  /**  */
  "gradeId"?: string;

  /**  */
  "assAttribute20"?: string;

  /**  */
  "assAttribute21"?: string;

  /**  */
  "assignmentName"?: string;

  /**  */
  "jobId"?: string;

  /**  */
  "businessUnitId"?: string;

  /**  */
  "acceptedTerminationDate"?: Date;

  /**  */
  "actualTerminationDate"?: Date;

  /**  */
  "notifiedTerminationDate"?: Date;

  /**  */
  "dateProbationEnd"?: Date;

  /**  */
  "noticePeriod"?: number;

  /**  */
  "noticePeriodUom"?: string;

  /**  */
  "probationPeriod"?: number;

  /**  */
  "probationUnit"?: string;

  /**  */
  "managerPersonNumber"?: string;

  /**  */
  "organizationId"?: string;

  constructor(data: undefined | any = {}) {
    this["effectiveDate"] = data["effectiveDate"];
    this["filename"] = data["filename"];
    this["legalEntityId"] = data["legalEntityId"];
    this["originalDateOfHire"] = data["originalDateOfHire"];
    this["enterpriseHireDate"] = data["enterpriseHireDate"];
    this["contractProjectedEndDate"] = data["contractProjectedEndDate"];
    this["assAttributeNumber1"] = data["assAttributeNumber1"];
    this["assAttribute3"] = data["assAttribute3"];
    this["gradeId"] = data["gradeId"];
    this["assAttribute20"] = data["assAttribute20"];
    this["assAttribute21"] = data["assAttribute21"];
    this["assignmentName"] = data["assignmentName"];
    this["jobId"] = data["jobId"];
    this["businessUnitId"] = data["businessUnitId"];
    this["acceptedTerminationDate"] = data["acceptedTerminationDate"];
    this["actualTerminationDate"] = data["actualTerminationDate"];
    this["notifiedTerminationDate"] = data["notifiedTerminationDate"];
    this["dateProbationEnd"] = data["dateProbationEnd"];
    this["noticePeriod"] = data["noticePeriod"];
    this["noticePeriodUom"] = data["noticePeriodUom"];
    this["probationPeriod"] = data["probationPeriod"];
    this["probationUnit"] = data["probationUnit"];
    this["managerPersonNumber"] = data["managerPersonNumber"];
    this["organizationId"] = data["organizationId"];
  }
}

export class HRIS_Api_Models_Request_Xml_BiographicalInfo {
  /**  */
  "effectiveDate"?: Date;

  /**  */
  "filename"?: string;

  /**  */
  "birthdate"?: Date;

  constructor(data: undefined | any = {}) {
    this["effectiveDate"] = data["effectiveDate"];
    this["filename"] = data["filename"];
    this["birthdate"] = data["birthdate"];
  }
}

export class HRIS_Api_Models_Request_Xml_CompensationDetails {
  /**  */
  "company"?: string;

  /**  */
  "effdt"?: string;

  /**  */
  "comP_RATECD"?: string;

  /**  */
  "comprate"?: string;

  /**  */
  "comP_PCT"?: string;

  /**  */
  "comP_FREQUENCY"?: string;

  /**  */
  "currencY_CD"?: string;

  constructor(data: undefined | any = {}) {
    this["company"] = data["company"];
    this["effdt"] = data["effdt"];
    this["comP_RATECD"] = data["comP_RATECD"];
    this["comprate"] = data["comprate"];
    this["comP_PCT"] = data["comP_PCT"];
    this["comP_FREQUENCY"] = data["comP_FREQUENCY"];
    this["currencY_CD"] = data["currencY_CD"];
  }
}

export class HRIS_Api_Models_Request_Xml_CompensationInfo {
  /**  */
  "effectiveDate"?: Date;

  /**  */
  "filename"?: string;

  /**  */
  "compensationDetails"?: HRIS_Api_Models_Request_Xml_CompensationDetails[];

  constructor(data: undefined | any = {}) {
    this["effectiveDate"] = data["effectiveDate"];
    this["filename"] = data["filename"];
    this["compensationDetails"] = data["compensationDetails"];
  }
}

export class HRIS_Api_Models_Request_Xml_DependentInfo {
  /**  */
  "effectiveDate"?: Date;

  /**  */
  "filename"?: string;

  constructor(data: undefined | any = {}) {
    this["effectiveDate"] = data["effectiveDate"];
    this["filename"] = data["filename"];
  }
}

export class HRIS_Api_Models_Request_Xml_DisabilityInfo {
  /**  */
  "effectiveDate"?: Date;

  /**  */
  "filename"?: string;

  constructor(data: undefined | any = {}) {
    this["effectiveDate"] = data["effectiveDate"];
    this["filename"] = data["filename"];
  }
}

export class HRIS_Api_Models_Request_Xml_EmailInfo {
  /**  */
  "effectiveDate"?: Date;

  /**  */
  "filename"?: string;

  /**  */
  "emailType"?: string;

  /**  */
  "emailAddress"?: string;

  constructor(data: undefined | any = {}) {
    this["effectiveDate"] = data["effectiveDate"];
    this["filename"] = data["filename"];
    this["emailType"] = data["emailType"];
    this["emailAddress"] = data["emailAddress"];
  }
}

export class HRIS_Api_Models_Request_Xml_Employee {
  /**  */
  "employeeCode"?: string;

  /**  */
  "nameInfo"?: HRIS_Api_Models_Request_Xml_NameInfo;

  /**  */
  "addressInfo"?: HRIS_Api_Models_Request_Xml_AddressInfo;

  /**  */
  "biographicalInfo"?: HRIS_Api_Models_Request_Xml_BiographicalInfo;

  /**  */
  "dependentInfo"?: HRIS_Api_Models_Request_Xml_DependentInfo;

  /**  */
  "assignmentInfo"?: HRIS_Api_Models_Request_Xml_AssignmentInfo;

  /**  */
  "personalPaymentInfo"?: HRIS_Api_Models_Request_Xml_PersonalPaymentInfo;

  /**  */
  "passportInfo"?: HRIS_Api_Models_Request_Xml_PassportInfo;

  /**  */
  "emailInfo"?: HRIS_Api_Models_Request_Xml_EmailInfo;

  /**  */
  "phoneInfo"?: HRIS_Api_Models_Request_Xml_PhoneInfo;

  /**  */
  "legislativeInfo"?: HRIS_Api_Models_Request_Xml_LegislativeInfo;

  /**  */
  "disabilityInfo"?: HRIS_Api_Models_Request_Xml_DisabilityInfo;

  /**  */
  "otherPersonInfo"?: HRIS_Api_Models_Request_Xml_OtherPersonInfo;

  /**  */
  "nationalIdInfo"?: HRIS_Api_Models_Request_Xml_NationalIdInfo;

  /**  */
  "compensationInfo"?: HRIS_Api_Models_Request_Xml_CompensationInfo;

  constructor(data: undefined | any = {}) {
    this["employeeCode"] = data["employeeCode"];
    this["nameInfo"] = data["nameInfo"];
    this["addressInfo"] = data["addressInfo"];
    this["biographicalInfo"] = data["biographicalInfo"];
    this["dependentInfo"] = data["dependentInfo"];
    this["assignmentInfo"] = data["assignmentInfo"];
    this["personalPaymentInfo"] = data["personalPaymentInfo"];
    this["passportInfo"] = data["passportInfo"];
    this["emailInfo"] = data["emailInfo"];
    this["phoneInfo"] = data["phoneInfo"];
    this["legislativeInfo"] = data["legislativeInfo"];
    this["disabilityInfo"] = data["disabilityInfo"];
    this["otherPersonInfo"] = data["otherPersonInfo"];
    this["nationalIdInfo"] = data["nationalIdInfo"];
    this["compensationInfo"] = data["compensationInfo"];
  }
}

export class HRIS_Api_Models_Request_Xml_LegislativeInfo {
  /**  */
  "effectiveDate"?: Date;

  /**  */
  "filename"?: string;

  /**  */
  "maritalStatus"?: string;

  /**  */
  "sex"?: string;

  constructor(data: undefined | any = {}) {
    this["effectiveDate"] = data["effectiveDate"];
    this["filename"] = data["filename"];
    this["maritalStatus"] = data["maritalStatus"];
    this["sex"] = data["sex"];
  }
}

export class HRIS_Api_Models_Request_Xml_NameInfo {
  /**  */
  "effectiveDate"?: Date;

  /**  */
  "filename"?: string;

  /**  */
  "lastName"?: string;

  /**  */
  "firstName"?: string;

  /**  */
  "knownAs"?: string;

  /**  */
  "userName"?: string;

  constructor(data: undefined | any = {}) {
    this["effectiveDate"] = data["effectiveDate"];
    this["filename"] = data["filename"];
    this["lastName"] = data["lastName"];
    this["firstName"] = data["firstName"];
    this["knownAs"] = data["knownAs"];
    this["userName"] = data["userName"];
  }
}

export class HRIS_Api_Models_Request_Xml_NationalIdInfo {
  /**  */
  "effectiveDate"?: Date;

  /**  */
  "filename"?: string;

  /**  */
  "nationalIdentifierType"?: string;

  /**  */
  "nationalIdentifierNumber"?: string;

  /**  */
  "placeOfIssue"?: string;

  constructor(data: undefined | any = {}) {
    this["effectiveDate"] = data["effectiveDate"];
    this["filename"] = data["filename"];
    this["nationalIdentifierType"] = data["nationalIdentifierType"];
    this["nationalIdentifierNumber"] = data["nationalIdentifierNumber"];
    this["placeOfIssue"] = data["placeOfIssue"];
  }
}

export class HRIS_Api_Models_Request_Xml_OtherPersonInfo {
  /**  */
  "effectiveDate"?: Date;

  /**  */
  "filename"?: string;

  constructor(data: undefined | any = {}) {
    this["effectiveDate"] = data["effectiveDate"];
    this["filename"] = data["filename"];
  }
}

export class HRIS_Api_Models_Request_Xml_PassportInfo {
  /**  */
  "effectiveDate"?: Date;

  /**  */
  "filename"?: string;

  constructor(data: undefined | any = {}) {
    this["effectiveDate"] = data["effectiveDate"];
    this["filename"] = data["filename"];
  }
}

export class HRIS_Api_Models_Request_Xml_PersonalPaymentInfo {
  /**  */
  "effectiveDate"?: Date;

  /**  */
  "filename"?: string;

  /**  */
  "legalEntityIdentifier"?: string;

  /**  */
  "bankAccountName"?: string;

  /**  */
  "bankAccountNumber"?: string;

  /**  */
  "bankNumber"?: string;

  /**  */
  "branchNumber"?: string;

  constructor(data: undefined | any = {}) {
    this["effectiveDate"] = data["effectiveDate"];
    this["filename"] = data["filename"];
    this["legalEntityIdentifier"] = data["legalEntityIdentifier"];
    this["bankAccountName"] = data["bankAccountName"];
    this["bankAccountNumber"] = data["bankAccountNumber"];
    this["bankNumber"] = data["bankNumber"];
    this["branchNumber"] = data["branchNumber"];
  }
}

export class HRIS_Api_Models_Request_Xml_PhoneDetails {
  /**  */
  "personAllPhoneType"?: string;

  /**  */
  "countryCode"?: string;

  /**  */
  "areaCode"?: string;

  /**  */
  "searchPhoneNumber"?: string;

  constructor(data: undefined | any = {}) {
    this["personAllPhoneType"] = data["personAllPhoneType"];
    this["countryCode"] = data["countryCode"];
    this["areaCode"] = data["areaCode"];
    this["searchPhoneNumber"] = data["searchPhoneNumber"];
  }
}

export class HRIS_Api_Models_Request_Xml_PhoneInfo {
  /**  */
  "effectiveDate"?: Date;

  /**  */
  "filename"?: string;

  /**  */
  "phoneDetails"?: HRIS_Api_Models_Request_Xml_PhoneDetails[];

  constructor(data: undefined | any = {}) {
    this["effectiveDate"] = data["effectiveDate"];
    this["filename"] = data["filename"];
    this["phoneDetails"] = data["phoneDetails"];
  }
}

export class HRIS_Api_Models_Request_Employee_EmployeeUpdateRequest {
  /**  */
  "employee"?: string;

  /**  */
  "payslipName"?: string;

  /**  */
  "workEmail"?: string;

  /**  */
  "department"?: string;

  /**  */
  "alternativeEmail"?: string;

  /**  */
  "newSalaryEffDate"?: string;

  /**  */
  "annualSalary"?: number;

  /**  */
  "employeeTerminates"?: string;

  /**  */
  "lastTerminates"?: string;

  constructor(data: undefined | any = {}) {
    this["employee"] = data["employee"];
    this["payslipName"] = data["payslipName"];
    this["workEmail"] = data["workEmail"];
    this["department"] = data["department"];
    this["alternativeEmail"] = data["alternativeEmail"];
    this["newSalaryEffDate"] = data["newSalaryEffDate"];
    this["annualSalary"] = data["annualSalary"];
    this["employeeTerminates"] = data["employeeTerminates"];
    this["lastTerminates"] = data["lastTerminates"];
  }
}
