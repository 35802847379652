import "./main-user.scss";

import { useRef, useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { TieredMenu } from "primereact/tieredmenu";

import { setSideBar } from "../../../../redux/actions/sidebar";
import { setUserInfo } from "../../../../redux/actions/user";

import MainPasswordDialog from "./main-password-dialog";

import {
  EmployeeDetailsService,
  RegistrationService,
} from "../../../../services/hrmnet-api/index";
import {
  ERROR_PAGE,
  MODULE_CODE,
  FUNCTION_CODE,
  PROFILE_IMAGE_PLACEHOLDER,
  LOGIN_OPTION,
  QUERY_PARAMS,
  BIOMETRIC_ACTION,
  BIOMETRIC,
  BIOMETRIC_STATUS,
  MESSAGE_SEVERITY,
  LOGOUT_PATH,
} from "./../../../../constants/index";
import { UrlLink } from "../../../modules/my-information/my-information-url";

import { PortalLink } from "./../../../../services/utils/index";
import { store } from "../../../../redux/store";
import { closeLoading, openLoading } from "../../../../redux/actions/modal";
// import { logout } from "./../../../../services/utils/index";
// import { LoginLink } from "./../../../../services/utils/index";

/**
 *
 */
const MainUser = () => {
  const biometricAuthEvent = "message";
  const { t, i18n } = useTranslation();
  const history = useHistory();
  // let { portal } = useParams();
  let op = useRef();
  const initState = {
    isBiometricSupportAvailable: false,
    isBiometricRegistered: false,
    loading: true,
    isMessageListenerAdded: false,
  };
  const [state, setState] = useState(initState);
  const [showPwdHistDialog, setShowPwdHistDialog] = useState(false);
  const userProfile = useSelector((state) => state.user?.profile);
  const employeeCode = useSelector((state) => state.auth?.employeeCode);
  const isSupportUser = useSelector((state) => state.auth?.isSupportUser);
  const isBiometricAuthentication = useSelector(
    (state) =>
      state.global?.clientInfo?.authenticationTypes?.includes(
        LOGIN_OPTION.LOGIN_ENABLE_BIO
      ) && state.global.deviceType === QUERY_PARAMS.MOBILE
  );
  const isOpenSidebarMobile = useSelector(
    (state) => state.sidebar?.isOpenSidebarMobile
  );
  const clientInfo = useSelector((state) => state.global.clientInfo);
  const accessRights = useSelector((state) => {
    return getKey(
      state.menu?.sideBar,
      Array.from(state.menu?.sideBar, (x) => x.key)
    );
  });

  const dispatch = useDispatch();
  let message = store.getState().global.toast;
  const biometricHandlerRef = useRef(BiometricHandler);

  useEffect(() => {
    biometricHandlerRef.current = BiometricHandler;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const initPage = async () => {
      try {
        //when session is changed
        //update auth information
        let initForm = {
          employeeCode: employeeCode,
        };

        if (!isSupportUser) {
          const cmd = EmployeeDetailsService.employeeGetProfileSummary();

          const [res] = await Promise.all([cmd]);

          if (res && res.data) {
            initForm = {
              ...res.data,
              email: res.data?.emailId,
              staffId: res.data?.employeeCode,
              profileImageUrl: res.data?.profileImageUrl || 0,
            };
          }
        }

        dispatch(
          setUserInfo({
            profile: initForm,
          })
        );
      } catch (error) {
        history.push(`/${ERROR_PAGE.MAIN_ERROR_500}`);
      }
    };

    initPage();
    setState({ ...state, loading: false });

    // TODO - FIX ME - hardcoded for test
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeCode]);

  useEffect(() => {
    const func = (evt) => biometricHandlerRef.current(evt);
    const webView = window.ReactNativeWebView;
    if (isBiometricAuthentication && webView) {
      function initBiometric() {
        BiometricPostMessage(BIOMETRIC_ACTION.BIOMETRIC_SUPPORT_CHECK_REQUEST);
        document.addEventListener(biometricAuthEvent, func); //android
        window.addEventListener(biometricAuthEvent, func); //ios
      }

      initBiometric();
    }

    return () => {
      const handleCleanUp = () => {
        setState({
          ...state,
          loading: true,
        });
      };

      document.removeEventListener(biometricAuthEvent, func); //android
      window.removeEventListener(biometricAuthEvent, func); //ios
      handleCleanUp();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.loading) {
      dispatch(openLoading());
    } else {
      dispatch(closeLoading());
    }
  }, [state.loading, dispatch]);

  const biometricMessage = (severity, msg) => {
    message.show({
      severity: severity,
      detail: (
        <div
          dangerouslySetInnerHTML={{
            __html: msg,
          }}
        />
      ),
      life: 3000,
    });
  };

  function getKey(arr, res) {
    arr.forEach((element) => {
      if (element.subMenus.length > 0) {
        const subMenusKey = Array.from(element.subMenus, (x) => x.key);
        res = [...res, ...subMenusKey];
      }
    });

    return res;
  }

  function BiometricPostMessage(action) {
    const webView = window.ReactNativeWebView;
    const msg = {
      type: BIOMETRIC,
      action: action,
    };
    webView.postMessage(JSON.stringify(msg));
  }

  async function RegisterBiometric(payload) {
    const cmd = RegistrationService.registrationRegisterByBiometricToken({
      body: { token: payload },
    });
    const [res] = await Promise.all([cmd]);

    if (res.data && res.data?.status) {
      setState({
        ...state,
        isBiometricSupportAvailable: true,
        isBiometricRegistered: true,
        loading: false,
      });
    } else {
      BiometricPostMessage(BIOMETRIC_ACTION.BIOMETRIC_AUTH_DELETE_KEY_REQUEST);
      setState({
        ...state,
        isBiometricSupportAvailable: true,
        isBiometricRegistered: false,
        loading: false,
      });
    }
  }

  async function BiometricHandler(msg, isEventRemoved) {
    if (!isEventRemoved) {
      var status = JSON.parse(msg.data).status;

      switch (status) {
        case BIOMETRIC_STATUS.BIOMETRIC_AUTH_SUPPORT:
          BiometricPostMessage(
            BIOMETRIC_ACTION.BIOMETRIC_AUTH_KEY_EXIST_REQUEST
          );
          break;

        case BIOMETRIC_STATUS.BIOMETRIC_AUTH_NOT_SUPPORT:
          setState({
            ...state,
            isBiometricSupportAvailable: false,
            isBiometricRegistered: false,
            loading: false,
            isMessageListenerAdded: true,
          });
          break;

        case BIOMETRIC_STATUS.BIOMETRIC_AUTH_NOT_REGISTER:
          setState({
            ...state,
            isBiometricSupportAvailable: true,
            isBiometricRegistered: false,
            loading: false,
            isMessageListenerAdded: true,
          });
          break;

        case BIOMETRIC_STATUS.BIOMETRIC_AUTH_REGISTERED:
          setState({
            ...state,
            isBiometricSupportAvailable: true,
            isBiometricRegistered: true,
            loading: false,
            isMessageListenerAdded: true,
          });
          break;

        case BIOMETRIC_STATUS.BIOMETRIC_AUTH_FAIL:
          setState({
            ...state,
            isBiometricSupportAvailable: true,
            isBiometricRegistered: true,
            loading: false,
            isMessageListenerAdded: true,
          });
          //user click cancel
          break;

        case BIOMETRIC_STATUS.BIOMETRIC_AUTH_REGISTER_SUCCESS:
          await RegisterBiometric(JSON.parse(msg.data).payload);
          break;

        case BIOMETRIC_STATUS.BIOMETRIC_VALIDATE_FAIL:
          biometricMessage(
            MESSAGE_SEVERITY.ERROR,
            t("employeeProfile_myBiometric_validationFail")
          );
          break;

        case BIOMETRIC_STATUS.BIOMETRIC_VALIDATE_SUCCESS:
          BiometricPostMessage(
            BIOMETRIC_ACTION.BIOMETRIC_AUTH_REGISTER_REQUEST
          );
          break;

        case BIOMETRIC_STATUS.BIOMETRIC_AUTH_SUCCESS:
          setState({
            ...state,
            isBiometricSupportAvailable: true,
            isBiometricRegistered: true,
            loading: false,
            isMessageListenerAdded: true,
          });
          break;

        default:
          setState({ ...state, loading: false, isMessageListenerAdded: true });
          break;
      }
    }
  }

  const toggleNotification = async (e) => {
    op.toggle(e);
  };
  const showPasswordHist = () => {
    setShowPwdHistDialog(true);
  };

  const closePasswordHist = () => {
    setShowPwdHistDialog(false);
  };

  // --------------------------------------------------------------------------
  const handleRenderPwdHistDialog = () => {
    if (!showPasswordHist) {
      return <></>;
    }

    return (
      <MainPasswordDialog
        closeFn={closePasswordHist}
        visible={showPwdHistDialog}
      />
    );
  };

  // --------------------------------------------------------------------------
  /**
   * items' id must be same as submenus key if restriction content is true
   */
  const items = [
    {
      id: "biometric",
      label: state.isBiometricRegistered
        ? t("employeeProfile_myBiometric_disable")
        : t("employeeProfile_myBiometric_enable"),
      icon: `pi pi-mu pi-${!state.isBiometricRegistered ? "check" : "times"}`,
      command: (event) => {
        if (!state.isBiometricRegistered) {
          setState({ ...state, loading: true });
          BiometricPostMessage(BIOMETRIC_ACTION.BIOMETRIC_VALIDATE_REQUEST);
          op.hide(event);
        } else {
          BiometricPostMessage(
            BIOMETRIC_ACTION.BIOMETRIC_AUTH_DELETE_KEY_REQUEST
          );
          setState({
            ...state,
            isBiometricSupportAvailable: true,
            isBiometricRegistered: false,
          });
          op.hide(event);
          biometricMessage(
            MESSAGE_SEVERITY.SUCCESS,
            t("employeeProfile_myBiometric_disableBiometric")
          );
        }
      },
      restrictedContent: false,
    },
    {
      id: "My_Info",
      label: t("employeeProfile_myInformation"),
      icon: "pi pi-mu pi-user",
      command: (event) => {
        history.push(
          PortalLink(
            `${FUNCTION_CODE.EmploymentManagement}/${UrlLink.PersonalInfo}`
          )
        );
        op.hide(event);
      },
      restrictedContent: true,
    },
    /*{
      id: "Notifications",
      label: t("employeeProfile_notifications"),
      icon: "pi pi-mu pi-bell",
      command: (event) => {
        history.push(PortalLink(MODULE_CODE.Messaging));
        op.hide(event);
      },
      restrictedContent: true,
    },*/
    {
      id: "changePassword",
      label: t("employeeProfile_changePassword"),
      icon: "pi pi-mu pi-key",
      command: (event) => {
        history.push(PortalLink(MODULE_CODE.ChangePassword));
        op.hide(event);
      },
      restrictedContent: false,
    },
    {
      id: "logout",
      label: t("employeeProfile_logout"),
      icon: "pi pi-mu pi-power-off",
      command: async (event) => {
        history.push(`/${LOGOUT_PATH.LOGOUT}`);
      },
      restrictedContent: false,
    },
  ];

  const renderAvatar = (profileImageUrl) => {
    return (
      <img
        className="avatar-main"
        alt="user-avatar"
        src={profileImageUrl ?? PROFILE_IMAGE_PLACEHOLDER.DEFAULT}
        onError={(e) => (e.target.src = PROFILE_IMAGE_PLACEHOLDER.ERROR)}
      />
    );
  };

  return (
    <>
      <div className="main-user p-grid p-nogutter p-align-center">
        <Button
          type="button"
          onClick={(e) => {
            // Close mobile sidebar if open while opening the user profile overlay
            if (isOpenSidebarMobile) {
              let newSideBarInfo = {};
              newSideBarInfo = {
                isOpenSidebarMobile: false,
              };
              dispatch(setSideBar(newSideBarInfo));
            }
            toggleNotification(e);
          }}
          className="p-button-secondary p-button-text main-user-button"
          // icon={`pi pi-user`}
          iconPos="right"
        >
          {renderAvatar(userProfile?.profileImageUrl)}
        </Button>
        <OverlayPanel
          ref={(el) => (op = el)}
          id="overlay_panel"
          className="main-header-panel main-user-panel"
          dismissable
          appendTo={document.getElementById("main-header")}
          onShow={() => {
            const webView = window.ReactNativeWebView;
            if (
              isBiometricAuthentication &&
              webView &&
              !state.isMessageListenerAdded
            ) {
              BiometricPostMessage(
                BIOMETRIC_ACTION.BIOMETRIC_SUPPORT_CHECK_REQUEST
              );
            }
          }}
          // Disable scrolling in mobile when overlay panel is open
          // onShow={() => document.body.classList.add("block-scroll-in-mobile")}
          // onHide={() =>
          //   document.body.classList.remove("block-scroll-in-mobile")
          // }
        >
          <div className="user-info">
            <div className="avatar">
              <div>
                {renderAvatar(userProfile?.profileImageUrl)}
                <p className="fullname">{userProfile?.name}</p>
              </div>
            </div>
            <div className="info">
              <TieredMenu
                model={items
                  .filter((x) => (isSupportUser ? x.id !== "myInfo" : x))
                  .filter((x) =>
                    state.isBiometricSupportAvailable ? x : x.id !== "biometric"
                  )
                  .filter((x) => {
                    if (
                      !clientInfo?.authenticationTypes?.some((r) =>
                        [
                          LOGIN_OPTION.LOGIN_ENABLE_EML,
                          LOGIN_OPTION.LOGIN_ENABLE_USR,
                        ].includes(r)
                      )
                    ) {
                      return x.id !== "changePassword";
                    }
                    return true;
                  })
                  .filter(
                    (x) =>
                      !x.restrictedContent ||
                      (x.restrictedContent && accessRights.includes(x.id))
                  )}
              />
            </div>
          </div>
        </OverlayPanel>
        {handleRenderPwdHistDialog()}
      </div>
    </>
  );
};
export default MainUser;
