// Translation Context Key
export enum RENTAL_T_CONTEXT_KEY {
  COMMON = "Rental.Common",
  CREATE = "Rental.Create",
  VIEW = "Rental.View",
  CHANGE = "Rental.Change",
  WITHDRAW = "Rental.Withdraw",
  SUBMIT_CLAIM = "Rental.SubmitClaim",
  APPROVAL = "Rental.Approval",
  REPORT = "Rental.Report",
  REPORT_COLUMNS = "Rental.Report.Columns",
  VIEW_APPLICATIONS = "Rental.ViewApplications",
  VIEW_HISTORY = "Rental.ViewHistory",
}

export const RENTAL_MENU_MODULE_KEY = "eRental";

export enum RENTAL_MENU_TYPE_KEY {
  ACTION = "Action",
  MENU = "Menu",
}

export enum RENTAL_MENU_PAGE_KEY {
  ADD_NEW_APPLICATION = "Add_New_Application",
  RENTAL_APPLICATIONS = "Rental_Applications",
  RENTAL_APPROVER_CORNER = "Rental_Approver_Corner",
  SEARCH_REPORT = "Search_Report",
  VIEW_ALL_APPLICATIONS = "View_All_Rental_Applications",
}

export enum RENTAL_MENU_ACTION_KEY {
  RENTAL_APPROVE_APPLICATION = "Rental_Approve_Application",
  RENTAL_APPROVE_CLAIM = "Rental_Approve_Claim",
  RENTAL_FINALIZE_APPLICATIONS = "Rental_Finalize_Applications",
}
