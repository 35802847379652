import "./base-form.scss";
import "./section-base-form.scss";

import BaseControl, { validateControl } from "./../base-control/base-control";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { isObjectEmpty, jsonEqual, sleep } from "./../../utils/utils";

import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { Message } from "primereact/message";
import { Panel } from "primereact/panel";
import { Ripple } from "primereact/ripple";

import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const initFormState = (form, controlsConfig, t) => {
  let formState = {
    invalid: false,
  };
  const fieldGroupsToValidate = [];
  controlsConfig.forEach((control) => {
    if (
      control.type === "checkbox" &&
      control.dependentFields &&
      form[control.key] === true
    ) {
      fieldGroupsToValidate.push(control.fieldGroup);
    }
  });
  controlsConfig.forEach((control) => {
    if (!fieldGroupsToValidate.includes(control.fieldGroup)) {
      return;
    }
    if (control?.layoutConfig?.hidden) {
      return;
    }
    let ruleList = control.ruleList || [];
    if (control.required) {
      ruleList.push({
        name: "required",
      });
    }
    if (!form.hasOwnProperty(control.key)) {
      form[control.key] = undefined;
    }
    let controlState = validateControl(
      ruleList,
      form[control.key],
      t,
      control.type
    );
    if (!controlState.invalid && control.validateControl) {
      controlState = control.validateControl(form[control.key]);
    }
    formState[control.key] = controlState;
    if (controlState.invalid) {
      formState.invalid = true;
    }
  });
  return {
    form,
    formState,
  };
};

// BaseForm --- start---
const SectionBaseForm = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );
  // extract props data
  const id = props.id || "BaseForm_default_id";
  // init state control
  let initState = {
    touched: false,
    loading: true,
    submiting: false,
    isSubmited: false,
    form: props.form || {},
    formState: {
      invalid: false,
    },
    formAlert: [],
  };

  let refGr = useRef({});
  const [state, setState] = useState(initState);
  // Unsubcribe function
  const mountedRef = useRef(true);
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);
  // On change
  useEffect(() => {
    const initForm = async () => {
      await sleep(1);
      let _form = {};
      if (props.loadFornFn) {
        _form = await props.loadFornFn();
      } else {
        _form = props.form || {};
      }

      var initF = initFormState(_form, props.config?.controls || [], t);

      const _state = {
        loading: false,
        submiting: false,
        touched: props.defaultTouch,
        layoutConfig: props.config?.layout,
        ...initF,
        formAlert: [],
      };
      if (!mountedRef.current) return null;
      setState(_state);
    };
    initForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.loadFornFn, props.form, props.defaultTouch]);

  useEffect(() => {
    const _state = {
      ...state,
      touched: props.touched,
    };
    if (!mountedRef.current) return null;
    setState(_state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.touched]);

  // -- language change
  useEffect(() => {
    if (!state.touched) {
      return;
    }
    let _form = state.form || {};
    const _controlsConfig = props.config?.controls || [];
    const _initFormState = initFormState(_form, _controlsConfig, t);
    // re-render with new validate error messages
    let newState = {
      ...state,
      formState: _initFormState.formState,
    };
    setState(newState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLangKey]);

  // onbeforeunload
  useEffect(() => {
    if (props.readOnly) {
      window.onbeforeunload = function () {};
    } else {
      window.onbeforeunload = function () {};
      // window.onbeforeunload = function () {
      //   return "Your work will be lost.";
      // };
    }
  });
  useEffect(
    () => () => {
      window.onbeforeunload = function () {};
    },
    []
  );
  /** Watch change */
  useEffect(() => {
    if (props.onStateChange) {
      props.onStateChange({
        loading: state.loading,
        submitting: state.submitting,
        touched: state.touched,
        invalid: state.formState.invalid,
        formState: state.formState,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.loading, state.submitting, state.touched, state.formState]);

  /** Expose function  */
  useImperativeHandle(ref, () => ({
    submitForm() {
      return onSubmitForm();
    },
    submitFormResult(res) {
      return onSubmitFormResult(res);
    },
    getFormState() {
      let formState = initFormState(
        state.form,
        props.config?.controls || [],
        t
      ).formState;
      return {
        ...formState,
        touched: state.touched,
      };
    },
    getForm() {
      return {
        ...state.form,
      };
    },
    setValidation(validateResult) {
      return onSetValidation(validateResult);
    },
  }));

  const validateFormState = (_formState) => {
    let invalid = false;
    for (const key in _formState) {
      if (_formState[key].invalid) {
        invalid = true;
      }
    }
    return {
      ..._formState,
      invalid,
    };
  };

  const onChangeModelControl = (control, data) => {
    let _state = { ...state };

    if (data.valueObj) {
      _state.form[control.key + "_obj"] = data.valueObj;
    }
    _state.form[control.key] = data.value;

    _state.formState[control.key] = data.controlState;
    _state.formState = validateFormState(_state.formState);
    _state.touched = true;
    if (props.onChange) {
      props.onChange({
        state: _state,
        changed: {
          control,
          data,
        },
      });
    }
    if (!jsonEqual(_state, state)) {
      setState(_state);
    }
    if (props.onChangeFormState) {
      // if (
      //   state.formState.invalid !== _state.formState.invalid ||
      //   state.touched !== _state.touched
      // ) {
      props.onChangeFormState({
        ..._state.formState,
        touched: _state.touched,
      });
      // }
    }
    return true;
  };

  const onSubmitForm = async () => {
    // Validate 1st
    const _state = { ...state };
    const formState = validateFormState(state.formState);
    if (formState.invalid) {
      _state.formState = formState;
      setState(_state);
      return {
        form: {
          ...state.form,
        },
        formState: {
          ...state.formState,
        },
        touched: state.touched,
      };
    }
    setState({
      ...state,
      submiting: true,
      isSubmited: true,
    });
    await sleep(1);
    let res;

    if (props.config?.formSubmitFn) {
      res = await props.config?.formSubmitFn({
        form: _state.form,
        formState: _state.formState,
        touched: _state.touched,
      });
      if (res && res.data && !isObjectEmpty(res.data)) {
        _state.formState.invalid = false;
      } else {
        _state.formState.invalid = true;
      }

      if (res && res.message && res.message.alert) {
        // _state.formAlert = res.message.alert; - aw
      }

      if (res && res.messages?.length === 0 && _state.formState.invalid) {
        _state.formAlert = [
          {
            level: "WARN",
            labelCode: "FORM_INVALID",
          },
        ];
      }

      if (res && res.message && res.message.validateResult) {
        res.message.validateResult.forEach((item) => {
          for (const key in _state.formState) {
            if (_state.formState.hasOwnProperty(key)) {
              if (key === item.componentId) {
                const param = {};
                if (item.placeholder) {
                  item.placeholder.forEach((element, placeIndex) => {
                    param["" + placeIndex] = element;
                  });
                }
                _state.formState[key] = {
                  invalid: true,
                  error: t(item.labelCode, param),
                  ruleDetail: {
                    name: t("From backend"),
                    param: null,
                  },
                };
              }
            }
          }
        });
      }
    }

    let isChangeState = false;
    if (props.config?.afterFormSubmitFn) {
      isChangeState = await props.config?.afterFormSubmitFn({
        res,
        form: _state.form,
        formState: _state.formState,
      });
    }

    if (!isChangeState) {
      // _state.touched = false;
      _state.submiting = false;
      if (props.onChange) {
        props.onChange({
          state: _state,
        });
      }
      setState(_state);
    }

    return {
      form: {
        ..._state.form,
      },
      formState: {
        ..._state.formState,
      },
    };
  };
  const onSetValidation = (validateResult) => {
    let _state = { ...state };
    let hasInvalid = false;
    validateResult.forEach((item) => {
      for (const key in _state.formState) {
        if (_state.formState.hasOwnProperty(key)) {
          if (key === item.componentId) {
            const param = {};
            if (item.placeholder) {
              item.placeholder.forEach((element, placeIndex) => {
                param["" + placeIndex] = element;
              });
            }
            hasInvalid = true;
            _state.formState[key] = {
              invalid: true,
              error: t(item.labelCode, param),
              ruleDetail: {
                name: t("From backend"),
                param: null,
              },
            };
          }
        }
      }
    });
    _state.formState.invalid = hasInvalid;
    if (hasInvalid) {
      _state.touched = true;
    } else {
      _state.touched = false;
    }
    setState(_state);
    return hasInvalid;
  };
  const onSubmitFormResult = async (res) => {
    let _state = { ...state };
    if (res && res.message && res.message.validateResult) {
      res.message.validateResult.forEach((item) => {
        for (const key in _state.formState) {
          if (_state.formState.hasOwnProperty(key)) {
            if (key === item.componentId) {
              _state.formState.invalid = true;
              const param = {};
              if (item.placeholder) {
                item.placeholder.forEach((element, placeIndex) => {
                  param["" + placeIndex] = element;
                });
              }
              _state.formState[key] = {
                invalid: true,
                error: t(item.labelCode, param),
                ruleDetail: {
                  name: t("From backend"),
                  param: null,
                },
              };
            }
          }
        }
      });
    }
    setState(_state);
  };

  const buildSections = (layout) => {
    let autoFocus = props.autoFocus;
    const controlsConfig = props.config ? [...props.config.controls] : [];
    const getControlObj = (control) =>
      controlsConfig.find((x) => x.key === control);

    let renderSections = [];
    if (layout.desc) {
      const descObj = getControlObj(layout.desc.control);
      renderSections.push(
        <div
          key={`bf-row-desc`}
          className={descObj.layoutConfig.className}
          style={{ background: "#f3f3f3" }}
        >
          {descObj.label}
        </div>
      );
    }

    let sectionLayout = [];
    layout.sections.forEach((section, sectionIndex) => {
      const sectionObj = getControlObj(section.sectionTitle.control);
      let control = { ...sectionObj };
      let controlState = state.formState[control.key];
      let column = section.sectionTitle;
      let value = state.form[column.control];
      const sectionRows = [];
      section.rows.forEach((row, rowIndex) => {
        const columns = [];
        row.columns.forEach((column, columnIndex) => {
          let controlObj = getControlObj(column.control);
          const holder = [];
          if (controlObj) {
            let control = { ...controlObj };
            let controlState = state.formState[control.key];
            let value = state.form[column.control];
            let oldValue = props?.originalForm?.[control.key];
            if (!control.config) {
              control.config = {};
            }
            if (props.readOnly) {
              control.config.disabled = true;
              control.config.readOnly = true;
              control.viewMode = true;
            }
            control.noLabel = true;
            const renderInputUpdateGroup = () => {
              let hideOldValue = false;
              let bfControlClassName = "p-col-6 p-lg-4";
              switch (control.type) {
                case "upload":
                  hideOldValue = true;
                  bfControlClassName = "p-col-12 p-lg-5";
                  break;
                case "rental-hint":
                  hideOldValue = true;
                  break;
                case "rental-lable":
                  hideOldValue = true;
                  break;
                default:
                  break;
              }
              return (
                <>
                  <div
                    className={`sbf-update-col ${
                      /*hideOldValue || !oldValue ? "sbf-update-col-dummy" :*/ ""
                    } p-col-6 p-lg-3 p-md-6`}
                  >
                    {!hideOldValue && oldValue ? (
                      <div className="sbf-org-value-col">
                        <span>
                          <BaseControl
                            id={`${id}-${control.key}-old-value`}
                            key={`${id}-${rowIndex}-${columnIndex}-old-value`}
                            {...control}
                            value={oldValue}
                            touched={false}
                            config={{
                              readOnly: true,
                            }}
                            hintBottom={null}
                            hintRight={null}
                          />
                        </span>
                        <i className="p-col-fixed pi pi-arrow-right sbf-change-icon" />
                      </div>
                    ) : null}
                  </div>

                  <div
                    className={`bf-control ${bfControlClassName} 
                    ${
                      hideOldValue || !oldValue
                        ? "with-sbf-update-col-dummy"
                        : ""
                    }
                    ${
                      !control.config.disabled &&
                      !control.config.readOnly & autoFocus
                        ? "autofocus"
                        : ""
                    }`}
                  >
                    <BaseControl
                      id={`${id}-${control.key}`}
                      key={`${id}-${rowIndex}-${columnIndex}-holder-control-${state.touched}`}
                      onChange={(data) => onChangeModelControl(control, data)}
                      {...control}
                      controlState={controlState}
                      value={value}
                      autoFocus={
                        !control.config.disabled &&
                        !control.config.readOnly & autoFocus
                          ? true
                          : false
                      }
                      formSubmited={state.isSubmited}
                      fromFrom={true}
                      loading={props.loading}
                      touched={state.touched || props.touched}
                      onTouched={() => {
                        if (props.onTouched) {
                          props.onTouched();
                          return;
                        }
                        if (!state.touched) {
                          setState({
                            ...state,
                            touched: true,
                          });
                        }
                      }}
                    />
                  </div>
                </>
              );
            };
            if (!props.readOnly || value != null) {
              const noLabelControls = [
                "checkbox",
                "rental-hint",
                "rental-label",
              ];
              const showLabel = !noLabelControls.includes(control.type);
              console.log(`control: ${control.type}, show: ${showLabel}`);
              holder.push(
                <div
                  key={`${id}-${rowIndex}-${columnIndex}-holder`}
                  id={`${id}-${rowIndex}-${columnIndex}-holder`}
                  className="sbf-row"
                >
                  <div className="p-col-12 p-lg-4 p-md-12">
                    {showLabel && (
                      <label htmlFor={`${id}-${control.key}`}>
                        {control.label}
                        {control.required ? (
                          <small className="required p-invalid">&nbsp;*</small>
                        ) : null}
                        {control.tooltip ? (
                          <Button
                            type="button"
                            tooltip={control.tooltip}
                            tooltipOptions={{ position: "top" }}
                            icon="pi pi-info-circle"
                            className="p-button-rounded label-help p-button-text p-button-plain"
                          />
                        ) : null}
                      </label>
                    )}
                  </div>
                  {renderInputUpdateGroup()}
                </div>
              );
            }
          }

          if (holder.length) {
            columns.push(
              <div
                {...column.config}
                key={`bf-column-${columnIndex}`}
                className={`bf-column bf-column-${columnIndex} p-col p-lg-12 ${
                  "" /*column.config ? column.config.className : ""*/
                }`}
              >
                {holder}
              </div>
            );
          }
        });

        if (columns.length) {
          //aw
          sectionRows.push(
            <div
              {...row.config}
              key={`bf-row-${rowIndex}`}
              className={`bf-row bf-row-${rowIndex} p-grid ${
                row.config ? row.config.className : ""
              }`}
            >
              {columns}
            </div>
          );
        }
      });

      const headerTemplate = (options) => {
        const toggleIcon = options.collapsed
          ? "pi pi-chevron-down"
          : "pi pi-chevron-up";
        const btnId = `panel-toggle-${sectionIndex}`;
        const toggleBtn = document.getElementById(btnId);

        const checkboxChange = (data) => {
          if (
            (data.value && options.collapsed) ||
            (!data.value && !options.collapsed)
          ) {
            toggleBtn.click();
          }
        };

        if (props.readOnly) {
          control.config.disabled = true;
          control.config.readOnly = true;
          control.viewMode = true;
        }

        return (
          <div className={options.className}>
            <div
              className={`${options.titleClassName} p-pl-1 sfb-panel-header`}
            >
              <BaseControl
                id={`${id}-${control.key}`}
                key={`${id}-${sectionIndex}-holder-control-${state.touched}`}
                onChange={(data) => {
                  onChangeModelControl(control, data);
                  checkboxChange(data);
                }}
                {...control}
                controlState={controlState}
                value={value}
                autoFocus={
                  !control.config.disabled &&
                  !control.config.readOnly & autoFocus
                    ? true
                    : false
                }
                formSubmited={state.isSubmited}
                fromFrom={true}
                loading={props.loading}
                touched={state.touched || props.touched}
                onTouched={() => {
                  if (props.onTouched) {
                    props.onTouched();
                    return;
                  }
                  if (!state.touched) {
                    setState({
                      ...state,
                      touched: true,
                    });
                  }
                }}
              />
            </div>
            <button
              id={btnId}
              className={options.togglerClassName}
              onClick={options.onTogglerClick}
            >
              <span className={toggleIcon}></span>
              <Ripple />
            </button>
          </div>
        );
      };

      sectionLayout.push(
        <Panel headerTemplate={headerTemplate} collapsed={!value} toggleable>
          {sectionRows.length ? sectionRows : "No change"}
        </Panel>
      );
    });
    renderSections.push(sectionLayout);
    return renderSections;
  };

  const buildLayout = () => {
    const layoutConfig = props.config?.layout;
    if (!layoutConfig || !layoutConfig.sections) {
      return null;
    }
    return (
      <>
        <form
          id={id}
          className={`${
            props.readOnly ? "bf-main-readonly sbf-main-readonly" : ""
          } bf-main sbf-main`}
        >
          {props.builder ? (
            <div className="bfb-panel">
              <>
                <Menu
                  ref={(el) => (refGr.current[`form`] = el)}
                  model={[
                    {
                      label: t("base_form_add_row"),
                      icon: "pi pi-plus",
                      command: () => {
                        layoutConfig.rows.push({
                          columns: [],
                        });
                        setState({
                          ...state,
                          layoutConfig,
                        });
                      },
                    },
                  ]}
                  popup
                  className="p-menu-custom-overlay"
                  appendTo={document.body}
                  easing="ease-in"
                ></Menu>
                <Button
                  type="button"
                  className="p-button-sm"
                  icon={`pi pi-ellipsis-v`}
                  label={`${t("base_form")} `}
                  onClick={(event) => {
                    refGr.current[`form`].toggle(event);
                  }}
                />
              </>
            </div>
          ) : null}
          {/* {buildRows(layoutConfig.rows)} */}
          {buildSections(layoutConfig)}
        </form>
      </>
    );
  };
  const buildAlert = () => {
    return state.formAlert.map((alter) => {
      const param = {};
      if (alter.placeholder) {
        alter.placeholder.forEach((element, placeIndex) => {
          param["" + placeIndex] = element;
        });
      }
      return (
        <>
          <Message
            className="bf-invalid"
            severity={alter.level.toLowerCase()}
            text={t(alter.labelCode, {
              ...param,
            })}
          ></Message>
        </>
      );
    });
  };
  return (
    <>
      <span style={{ display: "none" }}>{JSON.stringify(state)}</span>
      {buildAlert()}
      {buildLayout()}
    </>
  );
});

export default SectionBaseForm;
