import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  resetBcDynamicPaths,
  setBcDynamicPaths,
} from "../../../../redux/actions/breadcrumb";
import CompanyDocumentConfigurationForm from "./company-document-form";

const CompanyDocumentConfigurationUpdate = ({ location }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formName = t("Company_Document_Configuration_Formname_Update");

  // Breadcrumb
  useEffect(() => {
    if (formName) {
      dispatch(setBcDynamicPaths([{ label: formName }]));
    }

    return () => dispatch(resetBcDynamicPaths());
  }, [dispatch, t, formName]);

  // Rendering
  const renderFormTitle = () => (
    <div className="form-title">
      <h2>{formName}</h2>
    </div>
  );

  return (
    <>
      <div className="admin-form-style company-document-configuration-Update">
        <div className="header header-sticky">
          {/* Title */}
          {renderFormTitle()}
        </div>
        <div className={`company-document-configuration-select-form`}>
          <CompanyDocumentConfigurationForm
            companyDocumentId={location?.state?.companyDocumentId}
          />
        </div>
      </div>
    </>
  );
};

export default CompanyDocumentConfigurationUpdate;
