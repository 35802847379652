import "./horizontal-layout.scss";

import MainHeader from "./components/main-header/main-header";
import MainSideBar from "./components/main-sidebar/main-sidebar";
import BottomNavBar from "./components/main-footer/bottom-nav-bar";
import ContentFooter from "./components/main-footer/content-footer";
import { useDispatch, useSelector } from "react-redux";

//components
import EmployeeRouter from "./../employee-router";
import {
  EEPORTAL_LABEL_BASE_CONTROL,
  EEPORTAL_LABEL_GENERAL,
  EEPORTAL_LABEL_LOGIN,
  enumsKeyToArray,
  ERROR_PAGE,
  ViewType,
} from "../../constants";
import LanguageLoader from "../../components/language-loader/language-loader";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { ProcessMenuTree } from "./components/main-sidebar/main-menu-tree";
import { AccessRightsService } from "../../services/hrmnet-api";
import { setSideBar } from "../../redux/actions/sidebar";
import { setSideBarMenu } from "../../redux/actions/menu";

/**
 * Horizontal Layout
 */
const HorizontalLayout = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const sideBarInfo = useSelector((state) => state.sidebar) || null;
  const sideBarMenus = useSelector((state) => state.menu.sideBar);
  const selectedLang = useSelector(
    (state) => state.language.language?.selectedLang
  );
  const entityCode = useSelector(
    (state) => state.global?.clientInfo?.entityCode
  );
  let location = useLocation();

  const currIntialLoad = useRef(true);
  const currSelectedLang = useRef(selectedLang);
  const currEntity = useRef(entityCode);
  const [menuTree, setMenuTree] = useState([]);
  const [loading, setLoading] = useState(true);

  const isMenuTreeHasLabel = menuTree[0]?.label;

  const getMenus = async (history) => {
    try {
      var res = await AccessRightsService.accessGetMenus();
      let items = [];
      if (res && res.data) {
        items = res.data ? res.data : [];
      }
      const menuItems = items.map((x, index) => {
        return {
          ...x,
          display: true,
        };
      });

      return menuItems || [];
    } catch (error) {
      history.push(`/${ERROR_PAGE.MAIN_ERROR_500}`);
      return [];
    }
  };

  /**
   * Get the leaf keys in pathname (maximum depth should be 2)
   *
   * @param {*} leafKeys
   * @param {*} sidebarMenu
   * @param {*} returnKey
   * @returns
   */
  const getDeepestValidLeafKeys = (leafKeys, sidebarMenu, returnKey = "") => {
    const leafKeySplitIdx = leafKeys.indexOf("~");
    const currentLeafKey =
      leafKeySplitIdx >= 0 ? leafKeys.substr(0, leafKeySplitIdx) : leafKeys;
    const remainingLeafKey =
      leafKeySplitIdx >= 0 ? leafKeys.substr(leafKeySplitIdx + 1) : "";

    if (!currentLeafKey) return returnKey;

    const targetMenu = sidebarMenu.find((menu) => menu.url === currentLeafKey);

    if (!targetMenu) return returnKey;

    returnKey = returnKey ? `${returnKey}~${currentLeafKey}` : currentLeafKey;
    return getDeepestValidLeafKeys(
      remainingLeafKey,
      targetMenu.subMenus,
      returnKey
    );
  };

  /**
   * Update these when pathname format is updated.
   *
   * minLengthOfPathName => 1 when there is other info in pathname other than module && function code in the beginning of pathname string.
   *
   * If pathname only have module and function code in the beginning of pathname string,
   * please just use:
   *
   * minLengthOfPathName = 1
   *
   * for example: /app/mod/func, minLengthOfPathName = 3
   *
   * indexOfModuleCd is the index/position of module in pathname
   * indexOfFunctionCd is the index/position of function in pathname
   */
  const indexOfModuleCd = 1;
  const indexOfFunctionCd = 2;
  const minLengthOfPathName = 1; //normally is equal to indexOfFunctionCd

  const caculateKeys = (pathname) => {
    const path = pathname;
    let split = path.split("/");
    let _expandedKeys = {};
    let leafKeys;
    let moduleCd, functionCd;
    if (split.length >= minLengthOfPathName) {
      moduleCd = split[indexOfModuleCd];
      functionCd = split[indexOfFunctionCd];
      _expandedKeys[moduleCd] = true;

      leafKeys = functionCd
        ? getDeepestValidLeafKeys(
            moduleCd + "~" + moduleCd + "/" + functionCd,
            sideBarMenus
          )
        : moduleCd;

      return {
        expandedKeys: _expandedKeys,
        moduleCd,
        leafKeys,
      };
    } else {
      return {
        expandedKeys: undefined,
        leafKeys: undefined,
      };
    }
  };

  // ----------main-sidebar--useEffect()---------------------------------------
  const resetSideBarInfo = {
    ...sideBarInfo,
    selectedKey: "",
    expandedKeys: {},
    leafKeys: "",
  };

  const menuTreeAndSideBarSetup = (info, menusItem) => {
    const genMenuTree = ProcessMenuTree(t, info, menusItem);
    const selectMenu = genMenuTree.find((x) => x.isActive);
    dispatch(
      setSideBar({
        ...info,
        data: selectMenu?.activeModuleKeys,
        isLeafNode:
          selectMenu !== undefined ? selectMenu.children?.length > 0 : false,
      })
    );
    setMenuTree(genMenuTree);
    setLoading(false);
  };

  const getNavigation = async () => {
    // check if language got changed
    const isLangChange = currSelectedLang.current !== selectedLang;

    // check if it is initial load
    if (
      ((sideBarMenus.length === 0 || menuTree.length === 0) &&
        currIntialLoad.current) ||
      isLangChange
    ) {
      currIntialLoad.current = false; // set current initial load to false
      currSelectedLang.current = selectedLang; // remember the current language
      const menusItem = await getMenus(history);
      dispatch(setSideBarMenu(menusItem));
    }

    // check if entity got changed
    const isEntityChange = currEntity.current !== entityCode;

    if (isEntityChange) {
      currEntity.current = entityCode; // remember the current entity
      const menusItem = await getMenus(history);
      dispatch(setSideBarMenu(menusItem));
      // Reload to force all components to retrieve data from backend again
      window.location.reload();
    }

    if (sideBarMenus.length > 0) {
      const keys = caculateKeys(window.location.pathname);

      let selectedKey = keys.leafKeys;
      if (!selectedKey) {
        menuTreeAndSideBarSetup(resetSideBarInfo, sideBarMenus);
        return;
      }
      let _expandedKeys = {};
      let split = selectedKey.split("~");
      if (split.length === 1) {
        _expandedKeys[split[0]] = true;
      }

      if (split.length >= 2) {
        _expandedKeys[split[0]] = true;
        _expandedKeys[split[0] + "~" + split[1]] = true;
      }

      let newSideBarInfo = {
        selectedKey: selectedKey,
        expandedKeys: _expandedKeys,
      };
      menuTreeAndSideBarSetup(newSideBarInfo, sideBarMenus);
    }
  };

  useEffect(() => {
    // console.log('--main-sidebar--useEffect-', sideBarMenus, selectedLang);
    const _getNavigation = async () => {
      await getNavigation();
    };
    _getNavigation();

    return () => {
      setLoading(true);
      setMenuTree([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    history,
    location.pathname,
    t,
    sideBarMenus,
    selectedLang,
    entityCode,
    isMenuTreeHasLabel,
  ]);

  return (
    <LanguageLoader
      contexts={enumsKeyToArray([
        EEPORTAL_LABEL_LOGIN,
        EEPORTAL_LABEL_BASE_CONTROL,
        EEPORTAL_LABEL_GENERAL,
      ])}
    >
      <MainHeader />
      <div className="content-web">
        <div className="hidden p-d-md-flex hris-sidebar web">
          <MainSideBar menuTree={menuTree} />
        </div>
        <div
          className={`p-d-sm-${
            sideBarInfo.isOpenSidebarMobile ? "flex" : "none"
          } p-d-md-${
            !sideBarInfo.isOpenSidebarMobile ? "flex" : "none"
          } hris-sidebar mobile`}
        >
          <MainSideBar
            type={ViewType.MOBILE}
            visible={sideBarInfo.isOpenSidebarMobile}
            menuTree={menuTree}
          />
        </div>
        <main className={`open-sidebar main-content`}>
          {!loading && <EmployeeRouter />}
          <ContentFooter />
        </main>
      </div>
      <BottomNavBar />
    </LanguageLoader>
  );
};

export default HorizontalLayout;
