import moment from "moment";
import { BCType } from "../../../../../components/base-control/base-control";
import { convertApiDateFormatToPrime } from "../../../../../utils/utils";
import { DATE_FORMAT } from "../../constants/contants";
import { NwowReportService } from "../../../../../services/hrmnet-api";
import { translateReportingLine } from "../../utils/utils";

export const NWOW_REPORT_KEY = {
  NWOW_TRANSACTION_REPORT: "Nwow_Transaction_Report",
  NWOW_BALANCE_REPORT: "Nwow_Balance_Report",
  ANNUAL_NWOW_REPORT: "Annual_Nwow_Report",
};

export const NWOW_REPORT_TYPE = {
  NWOW_TRANSACTION_REPORT: "NWOW_TRANSACTION",
  NWOW_BALANCE_REPORT: "NWOW_BALANCE",
  ANNUAL_NWOW_REPORT: "ANNUAL_NWOW",
};

// Filter config
const employeeCodeFilter = ({ employeeCodeFilterOption, t }) => ({
  key: "employeeCode",
  type: BCType.multiselect,
  label: t("Nwow_Report_Filter_EmployeeCode"),
  enum: employeeCodeFilterOption,
  placeholder: t("base_control_select_choose"),
  config: { filter: true },
});

const reportingLineFilter = ({ reportingLineFilterOption, t }) => ({
  key: "reportingLine",
  type: BCType.select,
  label: t("Nwow_Report_Filter_ReportingLine"),
  enum: reportingLineFilterOption,
  placeholder: t("base_control_select_choose"),
});

const nwowDateFromFilter = ({ dateFormat = "yyyy-MM-dd", t }) => ({
  key: "nwowDateFrom",
  type: BCType.date,
  label: t("Nwow_Report_Filter_NwowDateFrom"),
  dateFormat: convertApiDateFormatToPrime(dateFormat),
  placeholder: dateFormat?.toUpperCase(),
});

const nwowDateToFilter = ({ dateFormat = "yyyy-MM-dd", t }) => ({
  key: "nwowDateTo",
  type: BCType.date,
  label: t("Nwow_Report_Filter_NwowDateTo"),
  dateFormat: convertApiDateFormatToPrime(dateFormat),
  placeholder: dateFormat?.toUpperCase(),
});

const nwowDayFilter = ({ nwowDayFilterOptions, t }) => ({
  key: "nwowDay",
  type: BCType.multiselect,
  label: t("Nwow_Report_Filter_NwowDay"),
  enum: nwowDayFilterOptions,
  placeholder: t("base_control_select_choose"),
  config: {
    display: "chip",
  },
});

const nwowTypeMultiSelectFilter = ({ nwowTypeFilterOptions, t }) => ({
  key: "nwowTypeMulti",
  type: BCType.multiselect,
  label: t("Nwow_Report_Filter_NwowType"),
  enum: nwowTypeFilterOptions,
  placeholder: t("base_control_select_choose"),
  config: {
    display: "chip",
  },
});

const transactionTypeFilter = ({ transactionTypeFilterOptions, t }) => ({
  key: "transactionType",
  type: BCType.multiselect,
  label: t("Nwow_Report_Filter_TransactionType"),
  enum: transactionTypeFilterOptions,
  placeholder: t("base_control_select_choose"),
  config: {
    display: "chip",
  },
});

const yearFilter = ({ yearsFilterOptions, t }) => ({
  key: "year",
  type: BCType.select,
  label: t("Nwow_Report_Filter_Year"),
  enum: yearsFilterOptions,
  placeholder: t("base_control_select_choose"),
});

const nwowTransactionReportFilter = (config, filterWithOption) => [
  [employeeCodeFilter(config), reportingLineFilter(config)],
  [nwowDateFromFilter(config), nwowDateToFilter(config), nwowDayFilter(config)],
  [
    ...(filterWithOption.includes("nwowTypeFilterOptions")
      ? [nwowTypeMultiSelectFilter(config)]
      : []),
    transactionTypeFilter(config),
  ],
];

// Report Column Config
export const NWOW_REPORT_DEFAULT_COLUMN_CONFIG = {
  width: 11,
  dataType: "string",
  sortable: false,
};

// Nwow Transaction
const submissionDate = (t) => ({
  ...NWOW_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("Nwow_Report_Column_SubmissionDate"),
  key: "submissionDate",
});

const applicationTime = (t) => ({
  ...NWOW_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("Nwow_Report_Column_ApplicationTime"),
  key: "applicationTime",
});

const referenceNo = (t) => ({
  ...NWOW_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("Nwow_Report_Column_ReferenceNo"),
  key: "referenceNo",
});

const transactionRef = (t) => ({
  ...NWOW_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("Nwow_Report_Column_TransactionRef"),
  key: "transactionRef",
});

const company = (t) => ({
  ...NWOW_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("Nwow_Report_Column_Company"),
  key: "company",
  width: "12",
});

const location = (t) => ({
  ...NWOW_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("Nwow_Report_Column_Location"),
  key: "location",
});

const employeeCode = (t) => ({
  ...NWOW_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("Nwow_Report_Column_EmployeeCode"),
  key: "employeeCode",
});

const employeeName = (t) => ({
  ...NWOW_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("Nwow_Report_Column_EmployeeName"),
  key: "employeeName",
});

const department = (t) => ({
  ...NWOW_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("Nwow_Report_Column_Department"),
  key: "department",
});

const nwowType = (t) => ({
  ...NWOW_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("Nwow_Report_Column_NwowType"),
  key: "nwowType",
});

const transactionType = (t) => ({
  ...NWOW_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("Nwow_Report_Column_TransactionType"),
  key: "transactionType",
});

const month = (t) => ({
  ...NWOW_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("Nwow_Report_Column_Month"),
  key: "month",
});

const fromDate = (t) => ({
  ...NWOW_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("Nwow_Report_Column_FromDate"),
  key: "fromDate",
});

const toDate = (t) => ({
  ...NWOW_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("Nwow_Report_Column_ToDate"),
  key: "toDate",
});

const appliedUnits = (t) => ({
  ...NWOW_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("Nwow_Report_Column_AppliedUnits"),
  key: "appliedUnits",
});

const daysOfWeek = (t) => ({
  ...NWOW_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("Nwow_Report_Column_DaysOfWeek"),
  key: "daysOfWeek",
});

const workSiteLocation = (t) => ({
  ...NWOW_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("Nwow_Report_Column_WorkSiteLocation"),
  key: "workSiteLocation",
});

const workSiteAddress = (t) => ({
  ...NWOW_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("Nwow_Report_Column_WorkSiteAddress"),
  key: "workSiteAddress",
});

const applicationStatus = (t) => ({
  ...NWOW_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("Nwow_Report_Column_ApplicationStatus"),
  key: "applicationStatus",
});

const workflowStatus = (t) => ({
  ...NWOW_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("Nwow_Report_Column_WorkflowStatus"),
  key: "workflowStatus",
});

const approverName = (t) => ({
  ...NWOW_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("Nwow_Report_Column_ApproverName"),
  key: "approverName",
});

const approverCompletionDate = (t) => ({
  ...NWOW_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("Nwow_Report_Column_ApproverCompletionDate"),
  key: "approverCompletionDate",
});

// Column Config
const nwowTransactionReportColumns = (t) => [
  submissionDate(t),
  applicationTime(t),
  referenceNo(t),
  transactionRef(t),
  daysOfWeek(t),
  workSiteLocation(t),
  workSiteAddress(t),
  company(t),
  location(t),
  employeeCode(t),
  employeeName(t),
  department(t),
  nwowType(t),
  transactionType(t),
  month(t),
  fromDate(t),
  toDate(t),
  appliedUnits(t),
  applicationStatus(t),
  workflowStatus(t),
  approverName(t),
  approverCompletionDate(t),
];

// Filter Payload
const prepareFilterPayloadNwowTransaction = (form) => {
  let payload = {};
  if (!form) return payload;

  if (form.employeeCode) {
    payload.employeeCodes = form.employeeCode;
  }
  if (form.reportingLine) {
    payload.reportingLines = [form.reportingLine];
  }
  if (form.nwowDateFrom) {
    const value = moment(form.nwowDateFrom).format(DATE_FORMAT);
    payload.nwowDate = payload.nwowDate
      ? { ...payload.nwowDate, from: value }
      : { from: value };
  }
  if (form.nwowDateTo) {
    const value = moment(form.nwowDateTo).format(DATE_FORMAT);
    payload.nwowDate = payload.nwowDate
      ? { ...payload.nwowDate, to: value }
      : { to: value };
  }
  if (form.nwowTypeMulti) {
    payload.nwowTypes = form.nwowTypeMulti;
  }

  if (form.nwowDay) {
    payload.nwowDays = form.nwowDay;
  }

  if (form.transactionType) {
    payload.transactionTypes = form.transactionType;
  }
  return payload;
};

// Fetch Filter
const parseApiFilterNwowTransaction = (data, t) => {
  //
  let config = {};
  if (data.employeeCodes) {
    config.employeeCodeFilterOption = data.employeeCodes.map((option) => ({
      label: option.name,
      value: option.value,
    }));
  }
  if (data.reportingLines) {
    config.reportingLineFilterOption = data.reportingLines.map((option) => ({
      label: translateReportingLine(option, t),
      value: option,
    }));
  }

  if (data.nwowTypes) {
    config.nwowTypeFilterOptions = data.nwowTypes.map((option) => ({
      label: option.name + " (" + option.description + ")",
      value: option.value,
    }));
  }
  if (data.transactionTypes) {
    config.transactionTypeFilterOptions = data.transactionTypes.map(
      (option) => ({
        label: option.name,
        value: option.value,
      })
    );
  }

  if (data.days) {
    config.nwowDayFilterOptions = data.days.map((option) => ({
      label: option.name,
      value: option.value,
    }));
  }
  return config;
};

export const getReportFilterConfig = (reportType, config, filterWithOption) => {
  let filters;
  //
  switch (reportType) {
    case NWOW_REPORT_TYPE.NWOW_TRANSACTION_REPORT:
      filters = nwowTransactionReportFilter(config, filterWithOption);
      break;
    default:
      break;
  }
  if (filters) {
    return {
      controls: filters.reduce((acc, cur) => {
        acc = acc.concat([...cur]);
        return acc;
      }, []),
      layout: {
        rows: filters.map((filterRow) => {
          return {
            columns: filterRow.map((filter) => {
              return {
                control: filter.key,
                config: {
                  className: "p-col-12 p-lg-4 p-md-6 p-sm-12",
                },
              };
            }),
          };
        }),
      },
    };
  }
  return {};
};

export const getReportColumnConfig = (reportType, t) => {
  let columns = [];
  switch (reportType) {
    case NWOW_REPORT_TYPE.NWOW_TRANSACTION_REPORT:
      columns = nwowTransactionReportColumns(t);
      break;
    default:
      break;
  }
  return columns;
};

export const parseApiFilter = (reportType, data, translation) => {
  switch (reportType) {
    case NWOW_REPORT_TYPE.NWOW_TRANSACTION_REPORT:
      return parseApiFilterNwowTransaction(data, translation);
    default:
      return {};
  }
};

const prepareDefaultFilterValueNwowTransaction = (filters) => {
  //
  let defaultFilters = {};
  if (filters.employeeCodeFilterOption?.length === 1) {
    defaultFilters.employeeCode = [filters.employeeCodeFilterOption[0].value];
    defaultFilters.employeeCode_obj = [filters.employeeCodeFilterOption[0]];
  }
  if (filters.reportingLineFilterOption.length > 0) {
    const targetReportingLineALL = filters.reportingLineFilterOption.filter(
      (rl) => rl.value === "ALL"
    );
    if (targetReportingLineALL.length > 0) {
      defaultFilters.reportingLine = targetReportingLineALL[0].value;
      defaultFilters.reportingLine_obj = targetReportingLineALL[0];
    }
  }
  if (filters.nwowTypeFilterOptions?.length > 0) {
    const targetNwowTypeALN = filters.nwowTypeFilterOptions.filter(
      (lt) => lt.value === "ALN"
    );
    const targetNwowTypePTO = filters.nwowTypeFilterOptions.filter(
      (lt) => lt.value === "PTO"
    );
    if (targetNwowTypeALN.length > 0) {
      defaultFilters.nwowTypeMulti = [targetNwowTypeALN[0].value];
      defaultFilters.nwowTypeMulti_obj = [targetNwowTypeALN[0]];
    } else if (targetNwowTypePTO.length > 0) {
      defaultFilters.nwowTypeMulti = [targetNwowTypePTO[0].value];
      defaultFilters.nwowTypeMulti_obj = [targetNwowTypePTO[0]];
    } else {
      defaultFilters.nwowTypeMulti = [filters.nwowTypeFilterOptions[0].value];
      defaultFilters.nwowTypeMulti_obj = [filters.nwowTypeFilterOptions[0]];
    }
  }
  if (filters.transactionTypeFilterOptions?.length > 0) {
    let transactionType = [];
    let transactionType_obj = [];
    filters.transactionTypeFilterOptions.forEach((tr) => {
      transactionType.push(tr.value);
      transactionType_obj.push(tr);
    });
    defaultFilters.transactionType = transactionType;
    defaultFilters.transactionType_obj = transactionType_obj;
  }
  defaultFilters.nwowDateFrom = new Date(
    moment().startOf("month").format(DATE_FORMAT)
  );
  defaultFilters.nwowDateTo = new Date(
    moment().endOf("month").format(DATE_FORMAT)
  );

  if (filters.yearsFilterOptions?.length > 0) {
    defaultFilters.year = filters.yearsFilterOptions[0].value;
    defaultFilters.year_obj = filters.yearsFilterOptions[0];
  }

  return defaultFilters;
};

export const prepareDefaultFilterValue = (reportType, filters) => {
  switch (reportType) {
    case NWOW_REPORT_TYPE.NWOW_TRANSACTION_REPORT:
      return prepareDefaultFilterValueNwowTransaction(filters);
    default:
      return {};
  }
};

export const prepareFilterPayload = (reportType, form) => {
  let payload = {};
  switch (reportType) {
    case NWOW_REPORT_TYPE.NWOW_TRANSACTION_REPORT:
      payload = prepareFilterPayloadNwowTransaction(form);
      break;
    default:
      break;
  }
  return payload;
};

export const getFilterOptions = async (reportType) => {
  switch (reportType) {
    case NWOW_REPORT_TYPE.NWOW_TRANSACTION_REPORT:
      return NwowReportService.nwowReportGetNwowTransactionFilter();
    default:
      return;
  }
};

export const getNwowFilterOptions = async (reportType) => {
  switch (reportType) {
    case NWOW_REPORT_TYPE.NWOW_TRANSACTION_REPORT:
      return NwowReportService.nwowReportGetNwowTransactionFilter();
    default:
      return;
  }
};
