import {
  ADD_LANGUAGE_LIST,
  BROADCAST_LANGUAGE_LIST,
  LANGUAGE_SET_LOADED_CONTEXT,
  LANGUAGE_CLEAR_LOADED_CONTEXT,
  SET_USER_SELECTED_LANGUAGE,
  CLEAR_LANGUAGE,
  BROADCAST_USER_SELECTED_LANGUAGE,
} from "./../reduxConstants";

/**
 * set user languages list for individual tabs/window
 */
export const addLanguageList = (languageList) => {
  return {
    type: ADD_LANGUAGE_LIST,
    payload: languageList,
  };
};

/**
 * sync user languages list for authorized pages across multiple open windows/tabs
 */
export const broadcastLanguageList = (languageList) => {
  return {
    type: BROADCAST_LANGUAGE_LIST,
    payload: languageList,
  };
};

/**
 * clear stored language settings
 */
export const clearLanguage = () => {
  return {
    type: CLEAR_LANGUAGE,
  };
};

/**
 * sync user selected language for authorized pages across multiple open windows/tabs
 */
export const broadCastUserSelectedLanguage = (language) => {
  return {
    type: BROADCAST_USER_SELECTED_LANGUAGE,
    payload: language,
  };
};

/**
 * set user selected language for individual tabs
 */
export const setUserSelectedLanguage = (language) => {
  return {
    type: SET_USER_SELECTED_LANGUAGE,
    payload: language,
  };
};

/**
 * Set the loadedContext status
 *
 * @param {*} context
 * @param {*} data
 * @returns
 */
export const setLoadedContext = (context, data) => {
  const loadedContext = { context, data };
  return {
    type: LANGUAGE_SET_LOADED_CONTEXT,
    payload: loadedContext,
  };
};

/**
 * Clear loadedContext
 *
 * @returns
 */
export const clearLoadedContext = () => {
  return {
    type: LANGUAGE_CLEAR_LOADED_CONTEXT,
  };
};
