export const KendoDefaultInsertCode = {
  name: "nonEditable",
  inline: true,
  group: "inline",
  content: "inline+",
  marks: "",
  attrs: {
    contenteditable: { default: null },
    class: { default: null },
    style: { default: null },
  },
  atom: true,
  parseDOM: [{ tag: "span.uneditable", priority: 51 }],
  // The styles can be added via the class as well
  toDOM: () => [
    "span",
    {
      contenteditable: false,
      class: "uneditable",
      style: "user-select: none; font-weight: bold;",
    },
    0,
  ],
};
