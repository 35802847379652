import "./newsfeed.scss";
import { UserNotificationsService } from "../../../../services/hrmnet-api";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setNotification } from "../../../../redux/actions/notification";
import { Link } from "react-router-dom";
import { Skeleton } from "primereact/skeleton";
import { Panel } from "primereact/panel";
import { Ripple } from "primereact/ripple";
import { useMediaQuery } from "react-responsive";

import leaveLogo from "./icons/leave.png";
import payLogo from "./icons/pay.png";

import { MessageBtn } from "./../../notifications/notification-page/notification-constant";
import { MODULE_CODE, MESSAGE_TYPE } from "./../../../../constants/index";
import { PortalLink } from "../../../../services/utils";
import { showSpinner } from "../../../../redux/actions/spinner";

const Newsfeed = () => {
  const dispatch = useDispatch();
  const entityDisplayName = useSelector(
    (state) => state.global.clientInfo?.entityDisplayName
  );
  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );
  const employeeCode = useSelector((state) => state.auth?.employeeCode);
  const [state, setState] = useState({
    loading: true,
    notifications: [],
  });
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: "768px" });

  const getData = async () => {
    try {
      var res = await UserNotificationsService.notificationGetSummary(
        {},
        {
          suppressErrorMsg: true,
        }
      );
      let notifications = [];
      if (res && res.data) {
        notifications = res.data;
      }
      setState({
        notifications,
        loading: false,
      });
      let notificationContent = {
        notificationNewsfeedContent: notifications,
      };
      dispatch(setNotification(notificationContent));
    } catch (error) {
      setState({
        notifications: [],
        loading: false,
      });
    }
  };

  useEffect(() => {
    getData();
    return () => {
      setState({
        loading: true,
        notifications: [],
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLangKey, employeeCode]);

  const renderIcon = (msgType) => {
    switch (msgType) {
      case MESSAGE_TYPE.Tax:
        return (
          <img
            src={leaveLogo}
            alt="leaveLogo.png"
            className="newsfeed-icon"
          ></img>
        );

      case MESSAGE_TYPE.Payslip:
        return (
          <img src={payLogo} alt="payLogo.png" className="newsfeed-icon"></img>
        );

      case MESSAGE_TYPE.HrLetter:
        return (
          <img
            src={payLogo}
            alt="leaveLogo.png"
            className="newsfeed-icon"
          ></img>
        );

      default:
        return (
          <img
            src={leaveLogo}
            alt="leaveLogo.png"
            className="newsfeed-icon"
          ></img>
        );
    }
  };

  const updateMessageIsRead = async (messageId, index) => {
    setState({
      ...state,
      loading: true,
    });

    try {
      var res =
        await UserNotificationsService.notificationUpdateNotificationStatus({
          notificationId: messageId,
        });

      var notifications = state.notifications;
      if (res && res?.data) {
        notifications[index].status = "Read";
      }
      setState({
        notifications,
        loading: false,
      });
      let notificationContent = {
        notificationNewsfeedContent: notifications,
      };
      dispatch(setNotification(notificationContent));
    } catch (error) {
      setState({
        notifications,
        loading: false,
      });
    }
  };

  const takeActionBtn = (message, index) => {
    return (
      <div
        className="p-col p-button"
        onClick={() => updateMessageIsRead(message?.notificationId, index)}
      >
        <p>{t("home_newsfeed_details")}</p>
      </div>
    );
  };

  const updateMessageReadAll = async () => {
    setState({
      ...state,
      loading: true,
    });
    dispatch(showSpinner(true));

    try {
      var api =
        await UserNotificationsService.notificationUpdateAllUnreadNotificationStatus(
          {}
        );
      let [res] = await Promise.all([api]);

      if (res && res?.data && res.data.status) {
        getData();
        dispatch(showSpinner(false));
      }
    } catch (error) {
      setState({
        ...state,
        loading: false,
      });
    }
  };

  const ReadAllBtn = () => {
    return (
      <div
        className="p-button newsfeed-readall-btn"
        onClick={() => updateMessageReadAll()}
      >
        <span>{t("notification_readAll")}</span>
      </div>
    );
  };

  // Newsfeed header
  const NewsfeedHeader = (props) => {
    return (
      <div className="p-grid newsfeed">
        <div className="p-col-12 newsfeed-title">
          <p>
            {t("home_newsfeed_whatsHappening")} {`${entityDisplayName}`} ?
          </p>
          <ReadAllBtn />
        </div>
        {props.children}
      </div>
    );
  };

  const NewsfeedSkeleton = () => {
    return (
      <div className="p-col-12 newsfeed-group-panel-skeleton">
        <Skeleton className="p-col-4" shape="square" size="3rem"></Skeleton>
        <Skeleton
          className="p-col-4"
          shape="rectangle"
          height="1.3rem"
          width="30vw"
        ></Skeleton>
        <Skeleton
          className="p-col-4"
          shape="rectangle"
          height="1.3rem"
          width="5vw"
        ></Skeleton>
      </div>
    );
  };

  const NewsfeedGroupPanel = (props) => {
    return (
      <div
        className={`p-col-12 ${
          isMobile
            ? "newsfeed-group-panel newsfeed-group-panel-mobile"
            : "newsfeed-group-panel"
        }`}
      >
        {props.children}
      </div>
    );
  };

  const NewsfeedContent = () => {
    return state.notifications.map((x, index) => {
      // console.log("testing", x.isRead);
      return (
        <div className="p-grid" key={index}>
          <div className="p-col-12">
            <div className="p-col-2 p-md-1 p-lg-1 p-grid newsfeed-logo">
              {renderIcon(x.messageType)}
            </div>
            <div className={`p-col newsfeed-content-${x.status === "Read"}`}>
              <p>{x.message}</p>
            </div>
            <div className="p-col-3 p-grid newsfeed-content-btn">
              <MessageBtn
                notification={x}
                takeActionBtn={() => takeActionBtn(x, index)}
              />
            </div>
          </div>
          <hr className="p-col-12 newsfeed-divider" />
        </div>
      );
    });
  };

  const NewsfeedFooter = () => {
    return (
      <div className="p-mt-3">
        <Link to={PortalLink(MODULE_CODE.Messaging)}>
          <u>{t("home_newsfeed_olderNotifications")}</u>
        </Link>
      </div>
    );
  };

  const NewsfeedDesktop = () => {
    return (
      <NewsfeedHeader>
        <NewsfeedGroupPanel>
          <NewsfeedContent />
          <NewsfeedFooter />
        </NewsfeedGroupPanel>
      </NewsfeedHeader>
    );
  };

  // Newsfeed mobile
  const mobileTemplate = (options) => {
    const toggleIcon = options.collapsed
      ? "pi pi-chevron-down"
      : "pi pi-chevron-up";
    const className = `${options.className} newsfeed-mobile`;
    const titleClassName = `${options.titleClassName} newsfeed-title-mobile`;

    return (
      <div className={className}>
        <div className={titleClassName}>
          {t("home_newsfeed_whatsHappening")} {`${entityDisplayName}`} ?
        </div>
        <div className="newsfeed-panel-actions">
          <ReadAllBtn />
          <button
            className={options.togglerClassName}
            onClick={options.onTogglerClick}
          >
            <span className={toggleIcon}></span>
            <Ripple />
          </button>
        </div>
      </div>
    );
  };

  const NewsfeedMobile = () => {
    return (
      <Panel headerTemplate={mobileTemplate} toggleable>
        <NewsfeedGroupPanel>
          <NewsfeedContent />
          <NewsfeedFooter />
        </NewsfeedGroupPanel>
      </Panel>
    );
  };

  return (
    <>
      {state.loading ? (
        <NewsfeedHeader>
          <NewsfeedSkeleton />
        </NewsfeedHeader>
      ) : (
        <>
          {state.notifications.length <= 0 ? (
            <NewsfeedHeader>
              <NewsfeedGroupPanel>
                <div style={{ padding: "1rem" }}>{t("common_noData")}</div>
              </NewsfeedGroupPanel>
            </NewsfeedHeader>
          ) : (
            <>{isMobile ? <NewsfeedMobile /> : <NewsfeedDesktop />}</>
          )}
        </>
      )}
    </>
  );
};

export default Newsfeed;
