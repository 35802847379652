import { useTranslation } from "react-i18next";

import "./claim-status.scss";

export enum CLAIM_STATUS {
  OPEN = "Open",
  PENDING_FOR_VERIFICATION = "PendingForVerification",
  REJECTED = "Rejected",
  VERIFIED = "Verified",

  // non workflow
  RETURNED = "Returned",
}

type ClaimStatusProps = {
  status: CLAIM_STATUS;
  indicator?: boolean;
  showText: boolean;
};

const ClaimStatus = ({
  status,
  indicator = true,
  showText = true,
}: ClaimStatusProps) => {
  const { t } = useTranslation();
  let indicatorClassName = "";
  let text = "";

  switch (status) {
    case CLAIM_STATUS.OPEN:
      indicatorClassName =
        "claim-status__indicator claim-status__indicator--open";
      text = t("rental_claimStatus_open");
      break;
    case CLAIM_STATUS.PENDING_FOR_VERIFICATION:
      indicatorClassName =
        "claim-status__indicator claim-status__indicator--pending-for-verification";
      text = t("rental_claimStatus_pendingForVerification");
      break;
    case CLAIM_STATUS.VERIFIED:
      indicatorClassName =
        "claim-status__indicator claim-status__indicator--verified";
      text = t("rental_claimStatus_verified");
      break;
    case CLAIM_STATUS.REJECTED:
      indicatorClassName =
        "claim-status__indicator claim-status__indicator--rejected";
      text = t("rental_claimStatus_rejected");
      break;
    case CLAIM_STATUS.RETURNED:
      indicatorClassName =
        "claim-status__indicator claim-status__indicator--returned";
      text = t("rental_claimStatus_returned");
      break;
    default:
      text = status;
      break;
  }

  return (
    <div className="claim-status">
      {indicator && <span className={indicatorClassName}></span>}
      {showText && <span>{text}</span>}
    </div>
  );
};

export default ClaimStatus;
