import { SET_MODAL, SET_LOADING } from "../reduxConstants";
const initialState = {
  isOpen: false,
  data: {},
  modals: {},
  isClosable: true,
  loading: false,
};

export default function modal(state = initialState, action) {
  switch (action.type) {
    case SET_MODAL: {
      return {
        ...action.payload,
      };
    }
    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
