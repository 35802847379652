import {
  closeLoading,
  openLoading,
  openModal,
} from "./../../../../redux/actions/modal";

//utils
import { OnLogout, PortalLink } from "./../../../../services/utils/index";

import { ReloadTenantEntityInfo } from "./components/utils";
import { QUERY_PARAMS } from "../../../../constants";
import { store } from "../../../../redux/store";
import { CompareParamValue } from "../../../../utils/utils";
import { AuthenticationService } from "../../../../services/hrmnet-api";

/**
 *
 * @param {*} currentTenantName
 * @param {*} currentEntityName
 * @returns
 */
export function isSameSiteSettings(
  currentTenantName = null,
  currentEntityName = null,
  isPreLoginPages = false
) {
  const clientCode =
    currentTenantName ?? window.location.pathname.split("/")[2];
  const entityCode =
    currentEntityName ?? window.location.pathname.split("/")[3];

  const tenantName = store.getState().global?.clientInfo?.tenantCode;
  const entityName = store.getState().global?.clientInfo?.entityCode;
  const sessionUserType = !!store.getState()?.auth?.isSupportUser;
  const loginUserType = CompareParamValue(
    QUERY_PARAMS.SOURCE,
    QUERY_PARAMS.SUPPORT
  );
  let isSameSiteSettings =
    tenantName?.toUpperCase() === clientCode?.toUpperCase() &&
    entityName?.toUpperCase() === entityCode?.toUpperCase();
  if (isPreLoginPages || sessionUserType) {
    return isSameSiteSettings;
  }
  isSameSiteSettings = isSameSiteSettings && sessionUserType === loginUserType;
  return isSameSiteSettings;
}

//check active session during login
export async function ActiveSessionDialog(
  store,
  t,
  history,
  confirmChangeSession,
  formPayload
) {
  const keys = store.getState().auth;
  if (keys && keys.token && keys.isLoggedIn) {
    if (isSameSiteSettings()) {
      history.push(PortalLink());
      return true;
    }

    store.dispatch(
      openModal({
        classNameMainDialog: "hris-login-changeTenantClient-dialog",
        isClosable: false,
        title: t("login_modal_activeSession_title"),
        content: t("login_modal_activeSession_content"),
        primaryButtonText: t("login_modal_activeSession_button"),
        secondButtonClickFn: async ({ closeFn }) => {
          //continue current session
          //reload current tenant and entity stored based on local storage
          //saved in redux state
          closeFn();
          history.push(PortalLink());
          return true;
        },
        primaryButtonClickFn: async ({ closeFn }) => {
          //confirm change session
          //reload site settings based on url
          closeFn();
          //additional step to check if the sitesetting is tally with localStorage
          const requestOption = {
            suppressErrorMsg: true,
          };
          await AuthenticationService.authenticationLogout(requestOption);
          if (confirmChangeSession) {
            store.dispatch(openLoading());
            if (!isSameSiteSettings()) {
              await ReloadTenantEntityInfo();
            }
            return await confirmChangeSession(true, formPayload);
          }
        },
      })
    );
  }

  if (confirmChangeSession) {
    store.dispatch(openLoading());
    if (!isSameSiteSettings()) {
      await ReloadTenantEntityInfo();
    }
    return await confirmChangeSession(true, formPayload);
  }
}
