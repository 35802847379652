export enum USER_ROLES {
  EMPLOYEE = "Employee",
  MANAGER = "Manager",
  HR = "HR",
}

/**
 * Modules that require user role checking
 */
export enum CONTROLLED_MODULE {
  RENTAL_CREATE_APPLICATION = "RENTAL_CREATE_APPLICATION",
  RENTAL_REPORT = "RENTAL_REPORT", // Report Page
  RENTAL_APPROVAL = "RENTAL_APPROVAL", // Approval Corner Page
}

/**
 * User roles required for each controlled module
 */
export const CONTROLLED_MODULE_ACCESS: {
  [key in CONTROLLED_MODULE]: USER_ROLES[];
} = {
  // rental
  [CONTROLLED_MODULE.RENTAL_CREATE_APPLICATION]: [
    USER_ROLES.EMPLOYEE,
    USER_ROLES.MANAGER,
    USER_ROLES.HR,
  ],
  [CONTROLLED_MODULE.RENTAL_REPORT]: [USER_ROLES.MANAGER, USER_ROLES.HR],
  [CONTROLLED_MODULE.RENTAL_APPROVAL]: [USER_ROLES.MANAGER, USER_ROLES.HR],
};
