import "./my-menu-tab.scss";

import PersonalInfo from "./module/personal-info";
import Contact from "./module/contact";
import Depandent from "./module/dependent";
import Employment from "./module/employment";
import Movement from "./module/movement";
import BankAccount from "./module/bank-account";

import { TabNavTitle, UrlLink } from "./../my-information-url";

export const MyMenuTab = (props) => {
  const items = [
    {
      id: "MyMenuTab-1",
      label: props.t(TabNavTitle.PersonalInfo),
      form: (
        <PersonalInfo
          setTabViewVisibility={(e) =>
            props.setTabViewVisibility({
              ...props.tabViewVisibility,
              psnDisabled: e,
            })
          }
        />
      ),
      path: UrlLink.PersonalInfo,
      disabled: props.tabViewVisibility?.psnDisabled ?? false,
      contentClassName: `ep-tabs-${props.tabViewVisibility}`,
    },
    {
      id: "MyMenuTab-2",
      label: props.t(TabNavTitle.Contact),
      form: (
        <Contact
          setTabViewVisibility={(e) =>
            props.setTabViewVisibility({
              ...props.tabViewVisibility,
              conDisabled: e,
            })
          }
        />
      ),
      path: UrlLink.Contact,
      disabled: props.tabViewVisibility?.conDisabled ?? false,
      contentClassName: `ep-tabs-${props.tabViewVisibility}`,
    },
    {
      id: "MyMenuTab-3",
      label: props.t(TabNavTitle.Dependents),
      form: (
        <Depandent
          setTabViewVisibility={(e) =>
            props.setTabViewVisibility({
              ...props.tabViewVisibility,
              depDisabled: e,
            })
          }
        />
      ),
      path: UrlLink.Dependents,
      disabled: props.tabViewVisibility?.depDisabled ?? false,
      contentClassName: `ep-tabs-${props.tabViewVisibility}`,
    },
    {
      id: "MyMenuTab-4",
      label: props.t(TabNavTitle.Employments),
      path: UrlLink.Employments,
      form: (
        <Employment
          setTabViewVisibility={(e) =>
            props.setTabViewVisibility({
              ...props.tabViewVisibility,
              empDisabled: e,
            })
          }
        />
      ),
      disabled: props.tabViewVisibility?.empDisabled ?? false,
      contentClassName: `ep-tabs-${props.tabViewVisibility}`,
    },
    {
      id: "MyMenuTab-5",
      label: props.t(TabNavTitle.Movements),
      path: UrlLink.Movements,
      form: (
        <Movement
          setTabViewVisibility={(e) =>
            props.setTabViewVisibility({
              ...props.tabViewVisibility,
              movDisabled: e,
            })
          }
        />
      ),
      disabled: props.tabViewVisibility?.movDisabled ?? false,
      contentClassName: `ep-tabs-${props.tabViewVisibility}`,
    },
    {
      id: "MyMenuTab-6",
      label: props.t(TabNavTitle.BankAccounts),
      path: UrlLink.BankAccounts,
      form: (
        <BankAccount
          setTabViewVisibility={(e) =>
            props.setTabViewVisibility({
              ...props.tabViewVisibility,
              bnkDisabled: e,
            })
          }
        />
      ),
      disabled: props.tabViewVisibility?.bnkDisabled ?? false,
      contentClassName: `ep-tabs-${props.tabViewVisibility}`,
    },
  ];

  return items;
};
