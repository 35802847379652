import "./applications.scss";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { RentalMenu } from "../../components";
import { resetBcDynamicPaths } from "../../../../../redux/actions/breadcrumb";
import { EEPORTAL_LABEL_BASE_TABLE } from "../../../../../constants";
import LanguageLoader from "../../../../../components/language-loader/language-loader";
import {
  RENTAL_T_CONTEXT_KEY,
  RENTAL_MENU_MODULE_KEY,
  RENTAL_MENU_PAGE_KEY,
} from "../../constants";
import RentalAllViewApplications from "../../components/rental-all-view-applications";

const Applications = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const menus = useSelector((state) => state.menu.sideBar);
  const rentalMenu = menus.find(
    (x) => x.key === RENTAL_MENU_MODULE_KEY
  )?.subMenus;

  // Breadcrumb
  useEffect(() => {
    dispatch(resetBcDynamicPaths());
  }, [dispatch, t]);

  useEffect(() => {
    // scroll to section
    let element = document.querySelector(".rental-menu");
    element?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, []);

  return (
    <>
      <div className="rental-allowance">
        <LanguageLoader
          contexts={[
            EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
            RENTAL_T_CONTEXT_KEY.COMMON,
            RENTAL_T_CONTEXT_KEY.VIEW_APPLICATIONS,
          ]}
        >
          <div className="p-col-12 p-justify-between rental-header">
            <RentalMenu
              menu={rentalMenu}
              currentPageKey={RENTAL_MENU_PAGE_KEY.VIEW_ALL_APPLICATIONS}
            />
            <div className="title-group">
              <h2>{t("rental_pagetitle_viewApplications")}</h2>
            </div>
            <div className="info"></div>
          </div>
          <RentalAllViewApplications />
        </LanguageLoader>
      </div>
    </>
  );
};

export default Applications;
