export const scrollToErrorControl = () => {
  let elements = document.querySelectorAll(".p-component.p-invalid");
  for (const el of elements) {
    if (el.offsetParent != null) {
      el.scrollIntoView({ behavior: "smooth", block: "center" });
      return;
    }
  }
};

export const scrollToErrorControlWithSelector = (
  selector = "BaseControl_default_id.p-component.p-invalid"
) => {
  let elements = document.querySelectorAll(selector);
  for (const el of elements) {
    if (el.offsetParent != null) {
      el.scrollIntoView({ behavior: "smooth", block: "center" });
      return;
    }
  }
};

export const scrollToMainTop = () => {
  document.querySelector("main")?.scrollTo(0, 0);
  window.scrollTo(0, 0); // for mobile
};

export const generateBasicLayout = (controls) => {
  if (!controls?.length) return {};
  let layout = {
    rows: [],
  };
  controls.forEach((control) => {
    layout.rows.push({ columns: [{ control: control.key }] });
  });
  return layout;
};

export const generateBasicHalfColumnLayout = (controls) => {
  if (!controls?.length) return {};
  let layout = {
    rows: [],
  };
  var i = 1;
  var prevControl;
  controls.forEach((control) => {
    if (i % 2 == 0 && controls.length != i) {
      layout.rows.push({
        columns: [
          {
            control: prevControl.key,
            config: { ...BASIC_CONTROL_LAYOUT_CONFIG_HALF_COLUMN },
          },
          {
            control: control.key,
            config: { ...BASIC_CONTROL_LAYOUT_CONFIG_HALF_COLUMN },
          },
        ],
      });
    } else if (controls.length == i) {
      layout.rows.push({ columns: [{ control: control.key }] });
    }
    prevControl = control;
    i++;
  });
  return layout;
};

export const GetComponentValue = (type, form, key, enums) => {
  switch (type) {
    case "select":
      return form[key + "_obj"] ?? enums?.find((x) => x.value === form[key]);
    default:
      return form[key];
  }
};

export const BASIC_CONTROL_LAYOUT_CONFIG_HALF_COLUMN = {
  className: "p-col-6",
};
