import {
  PublicClientApplication,
  AuthenticationResult,
  AccountInfo,
  EndSessionRequest,
  RedirectRequest,
  PopupRequest,
  Configuration,
  SilentRequest,
} from "@azure/msal-browser";
import { MSAL_CONFIG } from "./azure-authentication-config";
import { SSO_INTERACTION_TYPE } from "./../constants/index";

export class AzureAuthenticationContext {
  public myMSALObj: PublicClientApplication = new PublicClientApplication(
    MSAL_CONFIG
  );
  private account?: AccountInfo;
  private loginRedirectRequest?: RedirectRequest;
  private loginRequest?: PopupRequest;
  private accessTokenRequest: SilentRequest;

  public isAuthenticationConfigured = false;
  public idToken = "";

  private setRequestObjects(): void {
    this.loginRequest = {
      scopes: ["User.Read"],
      prompt: "select_account",
    };

    this.loginRedirectRequest = {
      ...this.loginRequest,
      redirectStartPage: window.location.href,
    };
  }

  constructor(props: any) {
    // @ts-ignore
    this.myMSALObj = this.setMsalObj(props);
    this.setRequestObjects();

    this.accessTokenRequest = {
      scopes: ["User.Read"],
      account: this.account,
    };
  }

  private setMsalObj(mslConfig: Configuration): PublicClientApplication {
    if (mslConfig?.auth?.clientId) {
      this.isAuthenticationConfigured = true;
    }
    return new PublicClientApplication(mslConfig);
  }

  silentLogin(onAuthenticated: any, setLoading: any, setIsError: any): void {
    this.accessTokenRequest.account = this.getAccount();
    this.myMSALObj
      .acquireTokenSilent(this.accessTokenRequest)
      .then((resp: AuthenticationResult) => {
        this.handleResponse(resp, onAuthenticated, setLoading, setIsError);
      })
      .catch((err) => {
        onAuthenticated({ error: err }, this.idToken, setIsError);
      });
  }

  login(
    signInType: string,
    onAuthenticated: any,
    setLoading: any,
    setIsError: any
  ): void {
    if (signInType === SSO_INTERACTION_TYPE.POP_UP) {
      this.myMSALObj
        .loginPopup(this.loginRequest)
        .then((resp: AuthenticationResult) => {
          this.handleResponse(resp, onAuthenticated, setLoading, setIsError);
        })
        .catch((err) => {
          onAuthenticated({ error: err }, this.idToken, setIsError);
        });
    } else if (signInType === SSO_INTERACTION_TYPE.REDIRECT) {
      this.myMSALObj.loginRedirect(this.loginRedirectRequest);
    }
  }

  logout(account: any): void {
    const logOutRequest: EndSessionRequest = {
      account,
    };

    this.myMSALObj.logout(logOutRequest);
  }
  handleResponse(
    response: AuthenticationResult,
    onAuthenticated: any,
    setLoading: any,
    setIsError: any
  ) {
    if (response !== null && response.account !== null) {
      this.account = response.account;
      this.idToken = response.idToken;
    } else {
      this.account = this.getAccount();
    }

    if (this.account) {
      onAuthenticated(this.account, this.idToken, setIsError);
      setLoading(true);
    } else {
    }
  }

  public getAccount(): AccountInfo | undefined {
    const currentAccounts = this.myMSALObj.getAllAccounts();
    if (currentAccounts === null) {
      // @ts-ignore
      // console.log("No accounts detected");
      return undefined;
    }

    if (currentAccounts.length > 1) {
      // TBD: Add choose account code here
      // @ts-ignore
      // console.log(
      //   "Multiple accounts detected, need to add choose account code."
      // );
      return currentAccounts[0];
    } else if (currentAccounts.length === 1) {
      return currentAccounts[0];
    }
  }
}

export default AzureAuthenticationContext;
