import "./base-tab-screen.scss";
import { Dropdown } from "primereact/dropdown";
import { TabMenu } from "primereact/tabmenu";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { MENU_TYPE } from "../../constants";
import PageNotFound from "../../portal/modules/public/error/employee-error-404";
import { ISideBarMenu } from "../../redux/reducers/menu";

export interface BaseTabScreenProps {
  locationBasedTab?: boolean;
  formTitle?: string;
  defaultActionKey?: string;
  menuKey?: string;
  children: JSX.Element;
  customMenuModules?: BaseTabScreenSideBarMenu[];
  displayNotFound?: boolean;
  onChange?: (value: number) => void;
}

export interface BaseTabScreenSideBarMenu extends ISideBarMenu {
  link: string;
  value: number;
}

export interface MenuProps {}

const BaseTabScreen: React.FC<BaseTabScreenProps> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [selectedTab, setSelectedTab] =
    useState<BaseTabScreenSideBarMenu | null>();
  const locationBasedTab = props.locationBasedTab ?? false;
  const displayNotFound = props?.displayNotFound ?? false;
  const tabMenu: BaseTabScreenSideBarMenu[] = useSelector((state: any) => {
    let submodules = props?.customMenuModules ?? [];
    if (locationBasedTab) {
      let menuModule = GetCurrentSubmodules(1, {
        id: 0,
        name: "root",
        key: "root",
        type: MENU_TYPE.MENU,
        url: "",
        subMenus: state.menu.sideBar,
      });

      submodules = menuModule?.subMenus
        .filter((x: ISideBarMenu) => x.type === MENU_TYPE.Action)
        .map((x: ISideBarMenu, index: number) => {
          return { ...x, link: x.url, value: index };
        });

      if (!!submodules && submodules?.length === 0) {
        submodules.push({
          ...menuModule,
          type: MENU_TYPE.Action,
          link: menuModule.url,
          value: 0,
        });
      }
    }

    return submodules ?? [];
  });

  function GetCurrentSubmodules(
    moduleUrlIndex: number,
    sideBar: ISideBarMenu
  ): ISideBarMenu {
    let pathNameObj = location.pathname.split("/");
    let moduleUrl = pathNameObj.reduce(
      (prevVal: string, currVal: string, idx: number) => {
        if (idx <= moduleUrlIndex) {
          return prevVal + "/" + currVal;
        }

        return prevVal;
      }
    );
    if (
      pathNameObj.length <= moduleUrlIndex ||
      !sideBar ||
      !sideBar?.subMenus ||
      sideBar?.subMenus?.length === 0
    ) {
      return sideBar;
    }

    sideBar =
      sideBar.subMenus.find(
        (x: ISideBarMenu) =>
          "/" + x.url === moduleUrl && x.type === MENU_TYPE.MENU
      ) ?? sideBar;

    return GetCurrentSubmodules(++moduleUrlIndex, sideBar);
  }

  const renderTabSelect = () => {
    return (
      <div className="menu-tab">
        <div className="dropdown-wrapper p-d-md-none">
          {tabMenu && tabMenu.length > 0 && (
            <Dropdown
              value={
                locationBasedTab
                  ? tabMenu.find((x) => location.pathname.startsWith(x.link, 1))
                      ?.value
                  : selectedTab?.value ?? tabMenu[0].value
              }
              options={tabMenu.map((x) => {
                const { url, ...value } = x;
                return {
                  ...value,
                  label: value.name,
                };
              })}
              onChange={(e) => {
                if (locationBasedTab) {
                  history.push({
                    pathname: "/" + tabMenu[e.value].link,
                  });
                } else {
                  if (!!props?.onChange) {
                    props?.onChange(e.value);
                  }
                  setSelectedTab(
                    tabMenu.find(
                      (x: BaseTabScreenSideBarMenu) => x.value === e.value
                    )
                  );
                }
              }}
            />
          )}
        </div>
        <div className="tabmenu-wrapper p-d-none p-d-md-block">
          {tabMenu && tabMenu.length > 0 && (
            <TabMenu
              model={tabMenu.map((x) => {
                const { url, ...value } = x;
                return {
                  ...value,
                  label: value.name,
                };
              })}
              activeIndex={
                locationBasedTab
                  ? tabMenu.findIndex((x) =>
                      location.pathname.startsWith(x.link, 1)
                    )
                  : getTabValue(selectedTab?.value)
              }
              onTabChange={(e) => {
                if (locationBasedTab) {
                  history.push({
                    pathname: "/" + e.value.link,
                  });
                } else {
                  const selectedTabMenu = tabMenu.find(
                    (x: BaseTabScreenSideBarMenu) => x.value === e.value?.value
                  );
                  if (!!props?.onChange && !!selectedTabMenu) {
                    props?.onChange(e.value?.value);
                  }
                  setSelectedTab(selectedTabMenu);
                }
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const getTabValue = (value?: number) => {
    let modulesIndex = props.customMenuModules?.findIndex(x => x.value === selectedTab?.value);
    if(!!modulesIndex && modulesIndex > -1)
    {
      return modulesIndex;
    }

    return value;
  }

  const renderContent = () => {
    if (tabMenu.length === 0 && !displayNotFound) {
      return props.children;
    }
    if (tabMenu.length === 0 && displayNotFound) {
      return <PageNotFound />;
    }
    return (
      <div className="base-tab-screen">
        {renderTabSelect()}
        {props.children}
      </div>
    );
  };

  return renderContent();
};

export default BaseTabScreen;
