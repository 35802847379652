import "./main-toast.scss";
import React from "react";
import { Toast } from "primereact/toast";
import { setGlobal } from "./../../redux/actions/global";
import { useDispatch } from "react-redux";

function MainToast() {
  const dispatch = useDispatch();
  return (
    <>
      <Toast
        ref={(el) => {
          dispatch(
            setGlobal({
              toast: el,
            })
          );
        }}
      />
    </>
  );
}
export default MainToast;
