import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { RentalDetailsService } from "../../../services/hrmnet-api";
import { setRentalModuleConfig } from "../../../redux/actions/rental";

/**
 * Wrapper to provide a service to call backend to retrive config for rental module
 *
 * @param {*} param0
 * @returns
 */
const RentalConfigLoader = ({ children }) => {
  // --------------------- hooks to various library -------------------------
  const dispatch = useDispatch();
  const rentalConfig = useSelector((state) => state.rental.moduleConfig);

  // ----- life cycle -------------------------------------------------------
  useEffect(() => {
    const loadConfig = async () => {
      let res = await RentalDetailsService.rentalGetParameters();
      let config = {
        draftModule: res?.["RNT_Draft_Module"] === "Y",
      };
      dispatch(setRentalModuleConfig(config));
    };
    if (!rentalConfig || !Object.keys(rentalConfig).length) {
      loadConfig();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};
export default RentalConfigLoader;
