// import "./employee-data-amendment.scss";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { resetBcDynamicPaths } from "../../../../redux/actions/breadcrumb";
import { EmployeeDetailsService } from "../../../../services/hrmnet-api";
import {
  resetMainForm,
  resetMainFormConfig,
  resetMainFormValue,
} from "../../../../redux/actions/form";
import BaseForm from "../../../../components/base-form/base-form";
import { getControlModel } from "../../../../components/base-control/base-cotrol-model";
import { BCType } from "../../../../components/base-control/base-control";
import EmployeeDataAmendmentForm from "./employee-data-amendment-form";

const SelectEmployeeFormConfig = (t, setEmployeeCode, employeeList) => {
  const config = {
    controls: [
      getControlModel({
        key: "employee",
        label: t("Employee_Data_Amendment_Employee"),
        required: true,
        type: BCType.select,
        placeholder: t("base_control_select_choose"),
        enum: employeeList,
        hasFilterEnum: false,
        config: {
          showClear: false,
        },
        noLabel: true,
        hintBottom: true,
        onTrueUpdateValue: (e) => {
          setEmployeeCode(e.value);
        },
      }),
    ],
    layout: {
      rows: [
        {
          columns: [
            {
              control: "employee",
            },
          ],
        },
      ],
    },
  };

  return config;
};

const EmployeeDataAmendment = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    isLoading: true,
    form: {},
    formName: t("Employee_Data_Amendment_Formname"),
    isListLoaded: false,
  });

  const [employeeList, setEmployeeList] = useState([]);
  const [employeeCode, setEmployeeCode] = useState();

  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );
  const form = useSelector((state) => state.commonForm.form);
  const formLanguage = useSelector((state) => state.commonForm.formLanguage);

  useEffect(() => {
    const isLangChange = formLanguage != selectedLangKey;
    const loadForm = async () => {
      const _state = state;

      //enum
      const api1 = EmployeeDetailsService.employeeGetEmployeeList();
      let [appListRes] = await Promise.all([api1]);
      if (appListRes) {
        if (appListRes.length > 0) {
          setEmployeeList(
            appListRes.map((data) => {
              return {
                label: data.name,
                value: data.value,
              };
            })
          );
        }
        _state.isListLoaded = false;
      }

      // init form and active step
      if (isLangChange && form.Employee) {
        _state.form = {
          employee: form.Employee,
        };
        setEmployeeCode(form.Employee);
      }

      _state.isLoading = false;
      setState(_state);
    };

    loadForm();

    return () => {
      if (!isLangChange) {
        dispatch(resetMainFormConfig());
        dispatch(resetMainForm());
        dispatch(resetMainFormValue());
      }

      setState({
        ...state,
        isLoading: true,
        isListLoaded: false,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLangKey]);

  // Breadcrumb
  useEffect(() => {
    dispatch(resetBcDynamicPaths([{ label: state.formName || "" }]));
  }, [dispatch, t, state.formName]);

  // Rendering
  const renderFormTitle = () => (
    <div className="form-title">
      <h2>{state.formName}</h2>
    </div>
  );

  return (
    <>
      <div className="multimenu-style-tab">
        <div className="header header-sticky">
          {/* Title */}
          {renderFormTitle()}
          <div className="base_multi_step_form-group filter-menu">
            <div className="base_multi_step_form-panel">
              <div className="panel-header">
                <div className="title">
                  {t("Employee_Data_Amendment_Employee_SectionName")}
                </div>
              </div>
              <div className="panel-content ">
                <BaseForm
                  config={SelectEmployeeFormConfig(
                    t,
                    setEmployeeCode,
                    employeeList
                  )}
                  form={state.form}
                />
              </div>
            </div>
            <div></div>
          </div>
        </div>
        {employeeCode && (
          <EmployeeDataAmendmentForm
            isLangChange={formLanguage != selectedLangKey}
            employeeCode={employeeCode}
          />
        )}
      </div>
    </>
  );
};

export default EmployeeDataAmendment;
