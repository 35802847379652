import * as React from "react";
import * as ReactDOM from "react-dom";
import { Button } from "@progress/kendo-react-buttons";
import { Window } from "@progress/kendo-react-dialogs";
import { Upload } from "@progress/kendo-react-upload";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { EditorUtils } from "@progress/kendo-react-editor";
import { insertImageFiles } from "./kendo-utils";
import { useTranslation } from "react-i18next";
import { store } from "../../../redux/store";
import { HEADER_KEY } from "../../../constants";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";

export const InsertImageDialog = (props) => {
  const { t, i18n } = useTranslation();
  const [selected, setSelected] = React.useState(0);
  const [files, setFiles] = React.useState([]);
  const token = store.getState().auth?.token;
  const tenantName = store.getState().global?.clientInfo?.tenantCode;
  const entityName = store.getState().global?.clientInfo?.entityCode;
  const isModalOpen = store.getState().modal?.isOpen;
  let src;
  let altText;
  let title;
  let width;
  let height;
  //TODO
  const saveUrl = `${window.REACT_APP_API_URL}/document`;
  const removeUrl = `${window.REACT_APP_API_URL}/document`;
  const onBeforeUpload = (event) => {
    event.headers["Authorization"] = `Bearer ${token}`;
    event.headers[HEADER_KEY.HRMNET_ENTITY_NAME] = entityName;
    event.headers[HEADER_KEY.HRMNET_TENANT_NAME] = tenantName;

    if (!event.headers[HEADER_KEY.HRMNET_ENTITY_NAME]) {
      delete event.headers[HEADER_KEY.HRMNET_ENTITY_NAME];
    }

    if (!event.headers[HEADER_KEY.HRMNET_TENANT_NAME]) {
      delete event.headers[HEADER_KEY.HRMNET_TENANT_NAME];
    }

    //TODO
    event.additionalData = {
      ReleaseOnDate: new Date().toISOString(),
      DocumentType: "Email Template",
    };
  };

  const onBeforeRemove = (event) => {
    event.headers["Authorization"] = `Bearer ${token}`;
    event.headers[HEADER_KEY.HRMNET_ENTITY_NAME] = entityName;
    event.headers[HEADER_KEY.HRMNET_TENANT_NAME] = tenantName;

    if (!event.headers[HEADER_KEY.HRMNET_ENTITY_NAME]) {
      delete event.headers[HEADER_KEY.HRMNET_ENTITY_NAME];
    }

    if (!event.headers[HEADER_KEY.HRMNET_TENANT_NAME]) {
      delete event.headers[HEADER_KEY.HRMNET_TENANT_NAME];
    }
  };

  const onTabSelect = (event) => {
    setFiles([]);
    setSelected(event.selected);
  };

  const onClose = () => {
    props.onClose.call(undefined);
  };

  const onAddFiles = (event) => {
    setFiles(
      event.newState.map((f) => f.getRawFile && f.getRawFile()).filter((f) => f)
    );
  };

  const onInsert = () => {
    const { view, imageNode } = props;
    const nodes = view.state.schema.nodes;
    const nodeType = nodes[imageNode];
    const position = null;
    const data = {
      src: src ? src.value : null,
      title: title ? title.value : null,
      alt: altText ? altText.value : null,
      width: width ? width.value : null,
      height: height ? height.value : null,
    };
    const attrs = Object.keys(data)
      .filter((key) => data[key] !== null && data[key] !== "")
      .reduce(
        (acc, curr) =>
          Object.assign(acc, {
            [curr]: data[curr],
          }),
        {}
      );

    if (files.length) {
      insertImageFiles({
        view,
        files,
        nodeType,
        position,
        attrs,
      });
    } else {
      const newImage = nodeType.createAndFill(attrs);
      EditorUtils.insertNode(view, newImage, true);
    }

    view.focus();
    onClose();
  };

  const { view, imageNode } = props;
  const state = view && view.state;
  let attrs = {};

  if (
    state &&
    state.selection.node &&
    state.selection.node.type === state.schema.nodes[imageNode]
  ) {
    attrs = state.selection.node.attrs;
  }

  const fields = (
    <React.Fragment>
      <div className="k-edit-label">
        <label htmlFor="k-editor-image-alt">
          {t("kendo_editor_insertImage_alternate_text")}
        </label>
      </div>
      <div className="k-edit-field">
        <input
          type="text"
          className="k-textbox"
          id="k-editor-image-alt"
          defaultValue={attrs.alt}
          ref={(e) => (altText = e)}
        />
      </div>
      <div className="k-edit-label">
        <label htmlFor="k-editor-image-title">
          {t("kendo_editor_insertImage_title")}
        </label>
      </div>
      <div className="k-edit-field">
        <input
          type="text"
          className="k-textbox"
          id="k-editor-image-title"
          defaultValue={attrs.title}
          ref={(e) => (title = e)}
        />
      </div>
      <div className="k-edit-label">
        <label htmlFor="k-editor-image-width">
          {t("kendo_editor_insertImage_width")}
        </label>
      </div>
      <div className="k-edit-field">
        <input
          type="text"
          className="k-textbox"
          id="k-editor-image-width"
          defaultValue={attrs.width}
          ref={(e) => (width = e)}
        />
      </div>
      <div className="k-edit-label">
        <label htmlFor="k-editor-image-height">
          {t("kendo_editor_insertImage_height")}
        </label>
      </div>
      <div className="k-edit-field">
        <input
          type="text"
          className="k-textbox"
          id="k-editor-image-height"
          defaultValue={attrs.height}
          ref={(e) => (height = e)}
        />
      </div>
    </React.Fragment>
  );
  const buttons = (
    <div
      className={"text-right"}
      style={{
        clear: "both",
      }}
    >
      <Button onClick={onClose}>{t("kendo_editor_insertImage_cancel")}</Button>
      <Button onClick={onInsert} themeColor={"primary"}>
        {t("kendo_editor_insertImage_insert")}
      </Button>
    </div>
  );

  async function onSaveRequest(files, options, onProgress) {
    //TODO
  }

  async function onRemoveRequest(files, options) {}

  const renderComponent = React.useMemo(() => {
    return (
      !isModalOpen && (
        <LocalizationProvider language={i18n.language}>
          <IntlProvider locale={i18n.language}>
            <Window
              title={t("kendo_editor_insertImage_insertImage")}
              resizable={false}
              draggable={false}
              onClose={onClose}
              initialWidth={500}
              initialHeight={480}
            >
              <TabStrip
                selected={selected}
                onSelect={onTabSelect}
                animation={false}
              >
                {Object.entries(attrs).length === 0 && (
                  <TabStripTab title={t("kendo_editor_insertImage_upload")}>
                    <div className="k-edit-form-container pt-3 pb-3">
                      <div className="k-edit-label">
                        <label htmlFor="k-editor-image-width">
                          {t("kendo_editor_insertImage_image")}
                        </label>
                      </div>
                      <div className="k-edit-field">
                        <Upload
                          batch={false}
                          multiple={true}
                          defaultFiles={[]}
                          saveUrl={saveUrl}
                          // removeUrl={removeUrl}
                          onAdd={onAddFiles}
                          // onBeforeUpload={onBeforeUpload}
                          // onBeforeRemove={onBeforeRemove}
                          // withCredentials={false}
                          // saveUrl={
                          //   "https://demos.telerik.com/kendo-ui/service-v4/upload/save"
                          // }
                          // removeUrl={
                          //   "https://demos.telerik.com/kendo-ui/service-v4/upload/remove"
                          // }
                          // saveUrl={onSaveRequest}
                          // removeUrl={onRemoveRequest}
                          accept="image/*"
                        />
                      </div>
                      {fields}
                      {buttons}
                    </div>
                  </TabStripTab>
                )}
                <TabStripTab title={t("kendo_editor_insertImage_by_url")}>
                  <div className="k-edit-form-container pt-3 pb-3">
                    <div className="k-edit-label">
                      <label htmlFor="k-editor-image-url">
                        {t("kendo_editor_insertImage_Web_address")}
                      </label>
                    </div>
                    <div className="k-edit-field">
                      <input
                        type="text"
                        className="k-textbox"
                        id="k-editor-image-url"
                        defaultValue={attrs.src}
                        disabled={/^data:image/.test(attrs.src || "")}
                        ref={(e) => (src = e)}
                        autoFocus={true}
                      />
                    </div>
                    {fields}
                    {buttons}
                  </div>
                </TabStripTab>
              </TabStrip>
              <style>{`.k-dropzone { width: 100%; }`}</style>
            </Window>
          </IntlProvider>
        </LocalizationProvider>
      )
    );
  }, [i18n.language, selected, files, isModalOpen]);

  return ReactDOM.createPortal(renderComponent, document.body);
};
