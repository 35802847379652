import React from "react";

import { Card } from "primereact/card";

const SupportDesktopWrapper = (props) => {
  const renderDesktopView = () => {
    return (
      <Card title={props.title}>
        {props.supportItem.map((x, index) => {
          if (x === undefined) {
            return (
              <div key={index}>
                <p>{props.noDataTitle}</p>
              </div>
            );
          }
          return <div key={index} dangerouslySetInnerHTML={{ __html: x }} />;
        })}
      </Card>
    );
  };

  return <>{renderDesktopView()}</>;
};

export default SupportDesktopWrapper;
