import { Button } from "primereact/button";
import { Skeleton } from "primereact/skeleton";

import { getControlModel } from "./../../../../../../components/base-control/base-cotrol-model";
import { BCType } from "./../../../../../../components/base-control/base-control";
import { DEFAULT_MYINFO_DATE_FORMAT } from "../../../../../../constants";
import { isDate } from "../../../../../../utils/utils";

export const EpMaskedInput = (
  key,
  label,
  mapMasked,
  datatype,
  onMapMaskedChange,
  enumData,
  toolTipLabel,
  childIndex = ""
) => {
  const onStateChange = (key, state) => {
    let newMap = { ...mapMasked };
    newMap[key] = state;
    onMapMaskedChange(newMap);
  };
  // console.log("cPanel - key", toolTipLabel);

  const config = {
    key: key,
    label: label,
    type: mapMasked[key + childIndex] ? datatype : "password",
    revealState: mapMasked[key + childIndex],
    enum: enumData,
    action: {
      icon: mapMasked[key + childIndex] ? "pi pi-eye" : "pi pi-eye-slash",
      tooltip: toolTipLabel,
      onMouseDown: () => {
        onStateChange(key + childIndex, true);
      },
      onMouseUp: () => {
        onStateChange(key + childIndex, false);
      },
      onMouseLeave: () => {
        onStateChange(key + childIndex, false);
      },
      onTouchMove: () => {
        onStateChange(key + childIndex, false);
      },
      onTouchEnd: () => {
        onStateChange(key + childIndex, false);
      },
      onTouchStart: () => {
        onStateChange(key + childIndex, true);
      },
    },
  };

  return config;
};

export const EpLanguageToggle = (props, onMapMaskedChange) => {
  const onStateChange = (key) => {
    let newMap = { ...props.mapMasked };
    newMap[key] = !props.mapMasked[key];
    onMapMaskedChange(newMap);
  };
  return (
    <Button
      label={props.label}
      icon={props.icon}
      tooltip={props.tooltip}
      tooltipOptions={{ position: "top" }}
      onClick={() => onStateChange(props.key)}
      className={props.className + " custom-input-action-btn"}
      type="button"
    />
  );
};

/**
 *
 * @param {*} fieldCode
 * @returns
 */
export const EpFConfig = {
  isMasked: false,
  isEditable: false,
  isVisible: false,
};

/**
 *
 * @param {*} controls
 * @param {*} fieldConfig
 * @param {*} controlModel
 * @returns
 */
export const EpConfigControl = (
  controls,
  fieldConfig,
  controlModel,
  toolTipLabel,
  mapMasked,
  setMapMasked,
  key = ""
) => {
  if (!fieldConfig.isVisible) {
    return;
  }

  if (controlModel.type === BCType.date) {
    controlModel.dateFormat = DEFAULT_MYINFO_DATE_FORMAT;
  }

  if (fieldConfig.isMasked) {
    var configControl = controls.push(
      EpMaskedInput(
        controlModel.key,
        controlModel.label,
        mapMasked,
        controlModel.type ? controlModel.type : "",
        setMapMasked,
        controlModel.enum,
        toolTipLabel,
        key
      )
    );
    controls.push(configControl);
  } else {
    controls.push(getControlModel(controlModel));
  }
};

/**
 *
 * @param {*} label
 * @param {*} item
 * @param {*} enumData
 * @returns
 */
export const EpControlSetting = (label, item, enumData = {}) => {
  // console.log("--EpControlSetting--", enumData, item.key.toLowerCase())
  return {
    key: item.key,
    label: label,
    type:
      enumData[item.key] != null && item.controlType !== "multiselect"
        ? "select"
        : item.controlType,
    enum: enumData[item.key] != null ? enumData[item.key] : null,
  };
};

export const MyInfoSkeleton = (
  <div className="p-col-12">
    <div className="c-group-title ep-language-toogle">
      <Skeleton />
    </div>
    <div className="c-group-panel">
      <div className="p-grid">
        <Skeleton width="100%" height="20vh" />
      </div>
    </div>
  </div>
);

export const FormControlsGenerator = (
  config = [],
  toolTipLabel,
  mapMasked,
  setMapMasked,
  enumData = [],
  t,
  i18nConstant = "",
  key = ""
) => {
  let controls = [];
  config.map((field) => {
    if (field !== undefined) {
      const label = t(i18nConstant + field.key);
      const controlSettings = EpControlSetting(label, field, enumData);

      const cc = EpConfigControl(
        controls,
        field.fieldConfig === undefined ? EpFConfig : field.fieldConfig,
        controlSettings,
        toolTipLabel,
        mapMasked,
        setMapMasked,
        key
      );
      // console.log("testing - 2) backendGenControls", controlSettings, cc)
      return cc;
    }
    return {};
  });
  return controls;
};

export function ToPascalCase(string) {
  return `${string}`
    .replace(new RegExp(/[-_]+/, "g"), " ")
    .replace(new RegExp(/[^\w\s]/, "g"), "")
    .replace(
      new RegExp(/\s+(.)(\w+)/, "g"),
      ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`
    )
    .replace(new RegExp(/\s/, "g"), "")
    .replace(new RegExp(/\w/), (s) => s.toLowerCase());
}

export function GetControlType(type, key) {
  if (isDate(type)) {
    return BCType.date;
  }

  switch (typeof type) {
    case "number":
      return BCType.number;

    case "boolean":
      return BCType.toogle;

    case "date":
      return BCType.date;

    default:
      return BCType.input;
  }
}
