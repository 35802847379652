import "./company-policy.scss";

import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { MODULE_CODE } from "./../../../constants/index";
import CompanyPolicy from "./companypolicy/company-policy";
import Faq from "./faq/faq";
import PageNotFound from "../public/error/employee-error-404";

const CompanyPolicies = (props) => {
  let match = useRouteMatch();
  const routes = [
    {
      id: "CompanyPolicy",
      path: `${match.path}/${MODULE_CODE.CompanyPolicy}`,
      component: CompanyPolicy,
      exact: true,
    },
    {
      id: "Faq",
      path: `${match.path}/${MODULE_CODE.Faq}`,
      component: Faq,
      exact: true,
    },
  ];

  return (
    <>
      <Switch>
        {props.routeConfig ? props.routeConfig(routes) : null}
        <Route component={PageNotFound} />
      </Switch>
    </>
  );
};

export default CompanyPolicies;
