import "./balance.scss";

//components
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { resetBcDynamicPaths } from "../../../../../redux/actions/breadcrumb";
import { useTranslation } from "react-i18next";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import moment from "moment";

import { formSkeleton } from "../../utils";
import { LeaveService } from "../../../../../services/hrmnet-api";
import {
  LeaveStatus,
  LeaveMenu,
  ScrollToLeaveMenuActiveItem,
} from "../../components";
import {
  sampleLeaveTypeList,
  sampleYearList,
  sampleEquiryLeaveBalance,
  sampleEquiryLeaveBalanceTransactionTypeApplication,
  sampleEquiryLeaveBalanceTransactionTypeApplicationAmendCancel,
} from "./sample";
import { sampleApplicationList } from "./../list/sample";
import LanguageLoader from "../../../../../components/language-loader/language-loader";
import {
  EEPORTAL_LABEL_BASE_TABLE,
  Permission,
} from "../../../../../constants";
import {
  AppConstant,
  FUNCTION_CODE,
  LEAVE_SUBMODULE_CODE,
} from "../../../../../constants";
import {
  LEAVE_T_CONTEXT_KEY,
  LEAVE_MENU_MODULE_KEY,
  LEAVE_MENU_PAGE_KEY,
} from "../../constants/contants";
import { getDisplayUom } from "../../utils/utils";
import { ToUIOption } from "../../../public/login/components/utils";
import SelectControl from "../../../../../components/base-control/select-control/select-control";
import CheckboxControl from "../../../../../components/base-control/checkbox/checkbox-control";

const DATE_FORMAT_TITLE = "yyyy-MM-DD";
const DATE_FORMAT_RECORD = "yyyy-MM-DD (ddd)";
const TIME_FORMAT = "h:mm A";
const DATETIME_FORMAT = "yyyy-MM-DD HH:mm";

const Balance = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  let history = useHistory();
  const userProfile = useSelector((state) => state.user?.profile);
  const isSupportUser = useSelector((state) => state.auth?.isSupportUser);
  const menus = useSelector((state) => state.menu.sideBar);
  const leaveMenu = menus.find(
    (x) => x.key === LEAVE_MENU_MODULE_KEY
  )?.subMenus;

  const [isLoading, setIsLoading] = useState(true);
  const [leaveType, setLeaveType] = useState([]);
  const [leaveYear, setLeaveYear] = useState([]);
  const [filterType, setFilterType] = useState("");
  const [filterYear, setFilterYear] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [isLoadingBalanceRecord, setIsLoadingBalanceRecord] = useState(true);
  const [data, setData] = useState({});
  const [expandedRows, setExpandedRows] = useState(null);
  // const [isLoadingTransactionRecord, setIsLoadingTransactionRecord] = useState(true);
  const [transaction, setTransaction] = useState([]);
  const [leaveEmployee, setLeaveEmployee] = useState([]);
  const [employeeCode, setEmployeeCode] = useState(
    props?.location?.state?.employeeCode
  );
  const [isSelf, setIsSelf] = useState(true);
  const [filterEmployee, setFilterEmployee] = useState(null);
  const [showLeaveFilter, setShowLeaveFilter] = useState(true);

  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );

  useEffect(() => {
    dispatch(resetBcDynamicPaths());
  }, [dispatch]);

  // init
  useEffect(() => {
    const loadInfo = async () => {
      setIsLoading(true);
      // get leave type and year
      var api1 = await LeaveService.leaveGetLeaveTypeList({
        ...(employeeCode && { employeecode: employeeCode }),
      });
      // var api2 = await LeaveService.leaveGetLeaveYears({});
      let [leaveTypeRes] = await Promise.all([api1]);

      // mocked data
      // leaveTypeRes = sampleLeaveTypeList;
      let leaveTypeList = [];
      if (leaveTypeRes && leaveTypeRes.data) {
        leaveTypeRes.data.forEach((t, i) => {
          if (t && t.showBalanceOnApplication) {
            leaveTypeList.push({
              label: t.leaveName,
              value: t.leaveCode,
              balance: t.leaveBalanceAsOfYearEnd,
              asEndOfPeriod: t.asEndOfPeriod,
              unit: t.uom,
            });
          }
        });
      }
      setLeaveType(leaveTypeList);

      setIsLoading(false);
    };

    if (isSupportUser && !employeeCode) {
      setIsSelf(false);
      setShowLeaveFilter(false);
      setIsLoading(false);
    } else {
      loadInfo();
      setIsSelf(!employeeCode);
    }

    ScrollToLeaveMenuActiveItem();
  }, [selectedLangKey, employeeCode]);

  useEffect(() => {
    const loadInfo = async () => {
      var res = await LeaveService.leaveGetDelegateEmployeeApproveList();
      let [delegateRes] = await Promise.all([res]);
      if (delegateRes && delegateRes.data) {
        setLeaveEmployee(
          delegateRes.data
            .filter((x) => x.descriptionValue !== Permission.Self)
            .map((x) => {
              x.description = i18n.exists(x.description)
                ? t(x.description)
                : x.descriptionValue;
              return x;
            })
        );
      }
    };
    loadInfo();
  }, [selectedLangKey]);

  useEffect(() => {
    // link from submit page
    if (employeeCode && leaveEmployee.length > 0) {
      var employee = leaveEmployee.find((x) => x.value === employeeCode);
      setFilterEmployee(employee?.name);
    }
  }, [leaveEmployee]);

  // filter
  useEffect(() => {
    if (!filterType) return;

    const getYearList = async () => {
      // reset
      setLeaveYear({});
      setFilterYear("");
      setShowResult(false);
      setData({});
      setTransaction({});
      setExpandedRows([]);

      // get year by leave type
      var api = await LeaveService.leaveGetLeaveYearsByLeaveType({
        leaveType: filterType,
        ...(employeeCode && { employeeCode: employeeCode }),
      });
      let [leaveYearRes] = await Promise.all([api]);

      // mocked data
      // leaveYearRes = sampleYearList;
      let leaveYearList = [];
      if (leaveYearRes && leaveYearRes.data) {
        leaveYearRes.data.forEach((y) => {
          leaveYearList.push({
            label: y.name,
            value: y.value,
          });
        });
      }
      setLeaveYear(leaveYearList);

      // pre-select year filter
      const yearMatched = leaveYearList.find(
        (y) => y.value === moment().format("yyyy")
      );
      if (yearMatched) {
        setFilterYear(yearMatched.value);
      }
    };
    getYearList();
  }, [filterType, selectedLangKey]);

  useEffect(() => {
    if (!filterType || !filterYear) return;

    const getResult = async () => {
      // reset
      setShowResult(true);
      setData({});
      setTransaction({});
      setExpandedRows([]);
      setIsLoadingBalanceRecord(true);

      // specific targetDate
      const selectedType = leaveType.filter((lt) => lt.value === filterType);
      let targetDate = moment(selectedType[0].asEndOfPeriod)
        .year(filterYear)
        .format(DATE_FORMAT_TITLE);

      // get leave balance by type and date
      var cmd = await LeaveService.leaveGetEquiryLeaveBalance({
        leavecode: filterType,
        targetdate: targetDate,
        ...(employeeCode && { employeeCode: employeeCode }),
      });
      let [res] = await Promise.all([cmd]);

      // mocked data
      // res = sampleEquiryLeaveBalance;
      if (res && res.data) {
        res.data.balanaceSetupList = res.data.balanaceSetupList.map((b, i) => {
          return {
            ...b,
            index: i,
          };
        });

        setData(res.data);
      }

      setIsLoadingBalanceRecord(false);
    };
    getResult();
  }, [filterYear, selectedLangKey]);

  const resetFilter = () => {
    setLeaveYear({});
    setFilterType("");
    setFilterYear("");
    setShowResult(false);
    setData({});
    setTransaction({});
    setExpandedRows([]);
  };

  // render
  const renderFormSkeleton = () => formSkeleton;

  const renderLeaveSummary = () => (
    <div className="leave-summary leave-section-wrapper">
      {leaveType.map((lt) => (
        <div
          className={`summary-block ${
            leaveType.length < 4 ? "flex-width" : ""
          }`}
          onClick={() => {
            setFilterType(lt.value);
          }}
        >
          <div className="summary-title">
            {t("leave_balance_summary_balanceAsOf", {
              leaveType: lt.label,
              today: lt.asEndOfPeriod,
            })}
          </div>
          <div className="summary-days">
            {`${lt.balance} ${getDisplayUom(lt.unit, t)}`}
          </div>
        </div>
      ))}
    </div>
  );

  const renderLeaveFilterInit = () => (
    <div className="leave-filter leave-section-wrapper">
      <div className="filter-block-employee filter-block__employee p-col-4">
        <div className="base-control">
          <SelectControl
            key="employeeCode"
            label={t("leave_balance_filter_employee")}
            placeholder={t("leave_balance_filter_selectEmployee")}
            className="filter-employee"
            enum={ToUIOption(leaveEmployee)}
            onChange={(e) => {
              setEmployeeCode(e.value);
              setLeaveType([]);
              resetFilter();
              setFilterEmployee(e.valueStr);
              setShowLeaveFilter(true);
            }}
            value={employeeCode}
            config={{ disabled: isSelf }}
          />
        </div>
      </div>
      {isSupportUser ? (
        <></>
      ) : (
        <div className="filter-block-employee filter-block__self p-col-4">
          <div className="base-control">
            <CheckboxControl
              key="self"
              label={t("leave_balance_filter_self")}
              value={isSelf}
              onChange={(e) => {
                setIsSelf(e.value);
                setFilterEmployee(null);
                resetFilter();
                setShowLeaveFilter(e.value);
                if (e.value) {
                  setEmployeeCode(null);
                }
              }}
            />
          </div>
        </div>
      )}
    </div>
  );

  const renderLeaveFilter = () => (
    <div className="leave-filter leave-section-wrapper">
      <div className="filter-block filter-block__leave-type">
        <div className="base-control">
          <label className="filter-label">
            {t("leave_balance_filter_leaveType")}
          </label>
          <Dropdown
            placeholder={t("leave_balance_filter_selectLeaveType")}
            options={leaveType}
            value={filterType}
            onChange={(e) => {
              setFilterType(e.value);
            }}
            className="filter-leave-type"
          />
        </div>
      </div>
      {filterType && (
        <div className="filter-block filter-block__year">
          <div className="base-control">
            <label className="filter-label">
              {t("leave_balance_filter_year")}
            </label>
            <Dropdown
              placeholder={t("leave_balance_filter_selectYear")}
              options={leaveYear}
              value={filterYear}
              onChange={(e) => {
                setFilterYear(e.value);
              }}
              className="filter-year"
            />
          </div>
        </div>
      )}
    </div>
  );

  const renderLeaveDetailInfo = () => {
    const employeeName = userProfile?.name;
    if (!filterType || !filterYear || !employeeName || !data.employeeCode)
      return;

    return (
      <div className="detail-info-container">
        <div className="detail-info">
          <div className="detail-info-field detail-info-field__employee">
            <span className="detail-info-label">
              {t("leave_balance_detail_employee")}:
            </span>
            {filterEmployee ? (
              <span>{filterEmployee}</span>
            ) : (
              <span>
                {employeeName} ({data.employeeCode})
              </span>
            )}
          </div>
          <div className="detail-info-field detail-info-field__leave-type">
            <span className="detail-info-label">
              {t("leave_balance_detail_leaveType")}:
            </span>
            <span>
              {filterType} (
              {leaveType.filter((t) => t.value === filterType)[0].label})
            </span>
          </div>
          <div className="detail-info-field detail-info-field__from">
            <span className="detail-info-label">
              {t("leave_balance_detail_from")}:
            </span>
            <span>{moment(data?.beginDate).format(DATE_FORMAT_TITLE)}</span>
          </div>
          <div className="detail-info-field detail-info-field__to">
            <span className="detail-info-label">
              {t("leave_balance_detail_to")}:
            </span>
            <span>{moment(data?.endDate).format(DATE_FORMAT_TITLE)}</span>
          </div>
        </div>
        {/* <Button
          className="bt-addbtn"
          icon="pi pi-plus"
          tooltipOptions={{ position: "top" }}
          tooltip={t("leave_balance_action_applyLeave")}
          onClick={() => {
            history.push(
              `/${AppConstant.PORTAL}/${FUNCTION_CODE.Leave}/${LEAVE_SUBMODULE_CODE.SUBMIT_APPLICATION}`
            );
          }}
        /> */}
      </div>
    );
  };

  const renderRowExpansionTemplate = (e) => {
    const setLeaveTransactions = (i, table) => {
      const transactionData = { ...transaction };
      transactionData[i] = table;
      setTransaction(transactionData);
    };

    const getLeaveTransactions = async (i, transactionType) => {
      let table = [];

      // specific targetDate
      const selectedType = leaveType.filter((lt) => lt.value === filterType);
      let targetDate = moment(selectedType[0].asEndOfPeriod)
        .year(filterYear)
        .format(DATE_FORMAT_TITLE);

      var cmd =
        await LeaveService.leaveGetEquiryLeaveBalanceTransactionsByTransactionType(
          {
            leavecode: filterType,
            transactiontype: transactionType,
            targetdate: targetDate,
            ...(employeeCode && { employeeCode: employeeCode }),
          }
        );
      let [res] = await Promise.all([cmd]);
      if (res) {
        // mocked data
        // res = sampleEquiryLeaveBalanceTransactionTypeApplication;

        setLeaveTransactions(i, res.data);
      }
    };

    if (!(e.index.toString() in transaction)) {
      if (!e.transactionType) {
        setLeaveTransactions(e.index, []);
      } else {
        getLeaveTransactions(e.index, e.transactionType);
      }
    }

    const columns = [
      {
        key: "leaveType",
        header: t("leave_applicationList_leaveType"),
        className: "cell-custom-extra-large",
        sortable: true,
      },
      {
        key: "transactionType",
        header: t("leave_applicationList_transactionType"),
        className: "cell-custom-medium",
        sortable: true,
      },
      {
        key: "leaveFrom",
        header: t("leave_applicationList_from"),
        className: "cell-custom-large",
        sortable: true,
        render: (data) =>
          data.leaveFrom && moment(data.leaveForm).isValid()
            ? moment(data.leaveFrom).format(DATE_FORMAT_RECORD).toUpperCase()
            : data.leaveForm,
      },
      {
        key: "leaveFrom",
        header: t("leave_applicationList_startTime"),
        className: "cell-custom-small",
        sortable: true,
        render: (data) =>
          data.leaveFrom && moment(data.leaveForm).isValid()
            ? moment(data.leaveFrom).format(TIME_FORMAT)
            : data.leaveForm,
      },
      {
        key: "leaveTo",
        header: t("leave_applicationList_to"),
        className: "cell-custom-large",
        sortable: true,
        render: (data) =>
          data.leaveTo && moment(data.leaveTo).isValid()
            ? moment(data.leaveTo).format(DATE_FORMAT_RECORD).toUpperCase()
            : data.leaveTo,
      },
      {
        key: "leaveTo",
        header: t("leave_applicationList_endTime"),
        className: "cell-custom-small",
        sortable: true,
        render: (data) =>
          data.leaveTo && moment(data.leaveTo).isValid()
            ? moment(data.leaveTo).format(TIME_FORMAT)
            : data.leaveTo,
      },
      {
        key: "unit",
        header: t("leave_applicationList_units"),
        className: "cell-custom-small",
        sortable: false,
      },
      {
        key: "status",
        header: t("leave_applicationList_status"),
        className: "cell-custom-extra-large",
        sortable: true,
        render: (data) => <LeaveStatus status={data.status} data={data} />,
      },
      {
        key: "submissionDate",
        header: t("leave_applicationList_submissionDate"),
        className: "cell-custom-large",
        sortable: true,
        render: (data) =>
          data.leaveTo && moment(data.submissionDate).isValid()
            ? moment(data.submissionDate).format(DATETIME_FORMAT)
            : data.submissionDate,
      },
      {
        key: "referenceNo",
        header: t("leave_applicationList_refNo"),
        className: "cell-custom-large",
        sortable: true,
      },
    ];

    const desktopColumnsRender = [];
    columns.forEach((column, index) => {
      const columnConfig = {
        key: `bt-column-${index}`,
        field: column.key,
        header: column.header,
        className: `bt-${column.key} ${
          column.className ? column.className : ""
        }`,
        sortable: column.sortable,
      };
      if (column.render) {
        columnConfig.body = column.render;
      }
      desktopColumnsRender.push(<Column {...columnConfig} />);
    });
    const mobileColumnsRender = [];
    columns.forEach((column, index) => {
      const columnConfig = {
        key: `bt-column-${index}`,
        field: column.key,
        header: column.header,
        className: `bt-${column.key} ${
          column.className ? column.className : ""
        }`,
        sortable: column.sortable,
        body: (rowData, config) => {
          return (
            <div className={`bt-row bt-row-${config.rowIndex} bt-cell`}>
              <span className="p-column-title mobile-p-column-title">
                {column.header}:
              </span>
              <div className="bt-cell-value">
                {column.render ? (
                  <div className="bt-cell-render">{column.render(rowData)}</div>
                ) : (
                  rowData[column.key]
                )}
              </div>
            </div>
          );
        },
      };
      mobileColumnsRender.push(<Column {...columnConfig} />);
    });

    return (
      <div className="leave-balance-transaction-table">
        <div className="leave-balance-transaction-table-header">
          {t("leave_balance_table_transactions")}
        </div>
        <DataTable
          className="leave-balance-transaction-table-desktop"
          value={transaction[e.index]}
          emptyMessage={t("base_table_no_results")}
        >
          {desktopColumnsRender}
        </DataTable>
        <DataTable
          className="p-datatable-responsive table-expandable-header leave-balance-transaction-table-mobile"
          value={transaction[e.index]}
          emptyMessage={t("base_table_no_results")}
        >
          {mobileColumnsRender}
        </DataTable>
      </div>
    );
  };

  const renderLeaveDetailBalance = () => {
    return (
      <div className="leave-balance-table">
        <DataTable
          value={data.balanaceSetupList}
          loading={isLoadingBalanceRecord}
          expandedRows={expandedRows}
          onRowToggle={(e) => {
            setExpandedRows(e.data);
          }}
          rowExpansionTemplate={renderRowExpansionTemplate}
        >
          <Column expander style={{ width: "3em" }} />
          <Column
            field="item"
            header={t("leave_balance_table_balanceItem")}
            className="table-balance-item"
          />
          <Column
            field="balance"
            header={data && data.uom ? getDisplayUom(data.uom, t) : ""}
            className="table-days"
            style={{ textAlign: "center" }}
          />
        </DataTable>
      </div>
    );
  };

  const renderLeaveDetail = () => {
    return (
      <div className="leave-detail leave-section-wrapper">
        <div className="detail-wrapper">
          {renderLeaveDetailInfo()}
          {renderLeaveDetailBalance()}
        </div>
      </div>
    );
  };

  const renderFooter = () => (
    <div className="footer p-grid p-align-center p-justify-between">
      <div className="button-group-1">
        <Button
          onClick={() => history.goBack()}
          className="p-button-outlined secondary"
        >
          {t("leave_common_actionBack")}
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          LEAVE_T_CONTEXT_KEY.COMMON,
          LEAVE_T_CONTEXT_KEY.BALANCE,
          LEAVE_T_CONTEXT_KEY.DELEGATION,
        ]}
      />
      <div className="leave-container leave-balance">
        <div className="header">
          <LeaveMenu
            menu={leaveMenu}
            currentPageKey={LEAVE_MENU_PAGE_KEY.MY_LEAVE_BALANCE}
          />
          <div className="title">
            {t("leave_balance_pageTitle")}
            <div className="title-desc">{t("leave_balance_pageTitle")}</div>
          </div>
        </div>
        <div className="main">
          {/* Leave Filter */}
          {isLoading
            ? renderFormSkeleton()
            : leaveEmployee.length > 0
            ? renderLeaveFilterInit()
            : null}

          {/* Leave Summary */}
          {isLoading
            ? renderFormSkeleton()
            : showLeaveFilter
            ? renderLeaveSummary()
            : null}

          {/* Leave Filter */}
          {isLoading
            ? renderFormSkeleton()
            : showLeaveFilter && leaveType.length > 0
            ? renderLeaveFilter()
            : null}

          {/* Leave Detail */}
          {showResult ? renderLeaveDetail() : null}

          {/* Footer */}
          {isLoading ? null : renderFooter()}
        </div>
      </div>
    </>
  );
};

export default Balance;
