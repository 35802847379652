import "./login-footer.scss";

import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";

//const
import { MODULE_CODE, QUERY_PARAMS } from "./../../../../../../constants/index";
import { CompareParamValue } from "../../../../../../utils/utils";

const LoginFooter = () => {
  const { clientCode, entityCode } = useParams();
  const clientInfo = useSelector((state) => state.global?.clientInfo) || null;
  const { t } = useTranslation();
  var year = moment().format("YYYY");
  const isMobileApp = CompareParamValue(QUERY_PARAMS.TYPE, QUERY_PARAMS.MOBILE);

  if (!clientCode && !entityCode) {
    return <></>;
  }

  return (
    <>
      <div className="ep-login-footer">
        {/* <p className="copy-rights">
          {`${t("common_footer_copyright")} ${year} ${
            clientInfo?.entityDisplayName
          } ${t("common_footer_rights")}`}
        </p> */}
        <p className="copy-rights">
          {`${t("common_footer_copyright")} ${year} TRICOR ${t(
            "common_footer_rights"
          )}`}
        </p>
        <div className="p-mt-2">
          <p className="copy-rights">
            <Link
              to={`/${MODULE_CODE.TermCondition}${
                isMobileApp
                  ? `?${QUERY_PARAMS.TYPE}=${QUERY_PARAMS.MOBILE}`
                  : ""
              }`}
              className="ep-login-policies"
            >
              {t("common_footer_terms")}
            </Link>
            {" | "}
            <Link
              to={`/${MODULE_CODE.PrivacyPolicy}${
                isMobileApp
                  ? `?${QUERY_PARAMS.TYPE}=${QUERY_PARAMS.MOBILE}`
                  : ""
              }`}
              className="ep-login-policies"
            >
              {t("common_footer_privacyPolicy")}
            </Link>
          </p>
        </div>
        <span>
          {clientInfo?.signInSupportEnabled === false ||
          clientInfo?.signInSupportEnabled === undefined ? null : (
            <a
              href={`mailto:${clientInfo?.signInSupportEmail}`}
              className="ep-login-policies"
            >
              <div className="p-mt-2">
                <p className="copy-rights">
                  <u>{t("common_footer_needHelp")}</u>
                </p>
              </div>
            </a>
          )}
        </span>
      </div>
    </>
  );
};

export default LoginFooter;
