import "./list.scss";
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  getColumModel,
  DEFAULT_BT_SEARCH,
  getBTConfig,
} from "./../../../../../components/base-table/base-table-model";
import BaseTable from "../../../../../components/base-table/base-table-portal-rental";
import { useWindowSize } from "../../../utils/window-size/useWindowSize";

import { TabMenu } from "primereact/tabmenu";
import { Dropdown } from "primereact/dropdown";
import { RentalApprovalService } from "./../../../../../services/hrmnet-api/index";
import {
  EEPORTAL_LABEL_BASE_TABLE,
  RENTAL_SUBMODULE_CODE,
} from "../../../../../constants";
import { Animated } from "react-animated-css";
import { useDispatch, useSelector } from "react-redux";
import { resetBcDynamicPaths } from "../../../../../redux/actions/breadcrumb";
import LanguageLoader from "../../../../../components/language-loader/language-loader";
import {
  RENTAL_T_CONTEXT_KEY,
  RENTAL_MENU_MODULE_KEY,
  RENTAL_MENU_PAGE_KEY,
  RENTAL_MENU_ACTION_KEY,
} from "../../constants";
import { getControlModel } from "../../../../../components/base-control/base-cotrol-model";
import MainLoading from "../../../../layouts/components/main-loading/main-loading";
import moment from "moment";
import { RentalMenu, ScrollToRentalMenuActiveItem } from "../../components";

const ApprovalList = () => {
  const { t } = useTranslation();
  let history = useHistory();
  const tableRef = useRef();
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const menus = useSelector((state) => state.menu.sideBar);
  const rentalMenu = menus.find(
    (x) => x.key === RENTAL_MENU_MODULE_KEY
  )?.subMenus;
  const approveApplicationPermission = rentalMenu.find(
    (x) => x.key === RENTAL_MENU_ACTION_KEY.RENTAL_APPROVE_APPLICATION
  );
  const approveClaimPermission = rentalMenu.find(
    (x) => x.key === RENTAL_MENU_ACTION_KEY.RENTAL_APPROVE_CLAIM
  );
  const finalizePermission = rentalMenu.find(
    (x) => x.key === RENTAL_MENU_ACTION_KEY.RENTAL_FINALIZE_APPLICATIONS
  );

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [taxYear, setTaxYear] = useState([]);
  const [taxYearFilterIndex, setTaxYearFilterIndex] = useState(0);
  const [approvalTypeSelectIndex, setApprovalTypeSelectIndex] = useState(0);

  const approvalTypeList = [
    ...(approveApplicationPermission
      ? [
          {
            label: t("rental_approvalList_approveApplication"),
            value: t("rental_approvalList_approveApplication"),
            key: RENTAL_MENU_ACTION_KEY.RENTAL_APPROVE_APPLICATION,
          },
        ]
      : []),
    ...(approveClaimPermission
      ? [
          {
            label: t("rental_approvalList_approveClaim"),
            value: t("rental_approvalList_approveClaim"),
            key: RENTAL_MENU_ACTION_KEY.RENTAL_APPROVE_CLAIM,
          },
        ]
      : []),
    ...(finalizePermission
      ? [
          {
            label: t("rental_approvalList_finalizeApplication"),
            value: t("rental_approvalList_finalizeApplication"),
            key: RENTAL_MENU_ACTION_KEY.RENTAL_FINALIZE_APPLICATIONS,
          },
        ]
      : []),
  ];

  // Init
  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      setTaxYearFilterIndex(0);
      let res = null;
      let key = approvalTypeList.length
        ? approvalTypeList[approvalTypeSelectIndex].key
        : null;
      switch (key) {
        case RENTAL_MENU_ACTION_KEY.RENTAL_APPROVE_APPLICATION: // 0: // Application
          res =
            await RentalApprovalService.rentalApprovalGetApprovalApplications();
          break;
        case RENTAL_MENU_ACTION_KEY.RENTAL_APPROVE_CLAIM: // 1: // Claim
          res = await RentalApprovalService.rentalApprovalGetApprovalClaims();
          break;
        case RENTAL_MENU_ACTION_KEY.RENTAL_FINALIZE_APPLICATIONS: // 2: // Finalize
          res =
            await RentalApprovalService.rentalApprovalGetApprovalFinalizeApplications();
          break;
        default:
          break;
      }
      if (res?.data) {
        res.data = res.data.map((data) => {
          // data process
          // if (data.applicationStartDate) {
          //   data.applicationStartDate = moment(
          //     data.applicationStartDate,
          //     "DD/MM/YYYY"
          //   );
          // }
          // if (data.applicationEndDate) {
          //   data.applicationEndDate = moment(
          //     data.applicationEndDate,
          //     "DD/MM/YYYY"
          //   );
          // }
          return data;
        });
        setData(res.data);
        // tmp special handle for bad data with no taxYear
        const uniqueTaxYearList = [
          ...new Set(
            res.data.map((data) => (data.taxYear ? data.taxYear : "-"))
          ),
        ]
          .sort()
          .reverse()
          .map((year) => ({ label: year, value: year }));
        setTaxYear(uniqueTaxYearList);
      }
      setIsLoading(false);
    };
    loadData();
  }, [approvalTypeSelectIndex]);

  useEffect(() => {
    ScrollToRentalMenuActiveItem();
  }, []);

  // onTabChange
  useEffect(() => {
    if (tableRef.current) tableRef.current.reload();
  }, [taxYearFilterIndex, isLoading]);

  // Breadcrumb
  useEffect(() => {
    dispatch(resetBcDynamicPaths());
  }, [dispatch, t]);

  // Navigation
  const redirect = ({ applicationId }) => {
    let key = approvalTypeList.length
      ? approvalTypeList[approvalTypeSelectIndex].key
      : null;
    switch (key) {
      case RENTAL_MENU_ACTION_KEY.RENTAL_APPROVE_APPLICATION: // 0: // Application
        history.push(
          `${RENTAL_SUBMODULE_CODE.APPROVAL}/${applicationId}/${RENTAL_SUBMODULE_CODE.APPROVE_APPLICATION}`
        );
        break;
      case RENTAL_MENU_ACTION_KEY.RENTAL_APPROVE_CLAIM: // 1: // Claim
        history.push(
          `${RENTAL_SUBMODULE_CODE.APPROVAL}/${applicationId}/${RENTAL_SUBMODULE_CODE.APPROVE_CLAIM}`
        );
        break;
      case RENTAL_MENU_ACTION_KEY.RENTAL_FINALIZE_APPLICATIONS: // 2: // Finalize
        history.push(
          `${RENTAL_SUBMODULE_CODE.APPROVAL}/${applicationId}/${RENTAL_SUBMODULE_CODE.FINALIZE_APPLICATION}`
        );
        break;
      default:
        break;
    }
  };

  // Table Configurations
  const tableGetData = async () => {
    if (isLoading) {
      return new Promise(() => {});
    } else {
      return {
        datas: data.filter(
          // tmp special handle for bad data
          (data) =>
            data.taxYear ===
            (taxYear[taxYearFilterIndex].label === "-"
              ? undefined
              : taxYear[taxYearFilterIndex].label)
        ),
        searchConfig: BT_SEARCH,
        total: data.length,
      };
    }
  };
  const BT_SEARCH = {
    ...DEFAULT_BT_SEARCH,
    // sortObj: {
    //   key: "applicationId",
    //   direction: -1,
    // },
  };

  const configColumns = [
    getColumModel({
      header: t("rental_approvalList_applicationId"),
      key: "applicationId",
      dataType: "string",
      width: "20%",
      control: getControlModel({
        placeholder: t("rental_approvalList_applicationId_searchPlaceholder"),
      }),
      config: {
        sortable: true,
      },
    }),
    // getColumModel({
    //   header: t("rental_approvalList_applicationType"),
    //   key: "applicationType",
    //   dataType: "string",
    //   width: "25%",
    //   config: {
    //     sortable: true,
    //   },
    // }),
    ...(approvalTypeList.length &&
    [
      RENTAL_MENU_ACTION_KEY.RENTAL_APPROVE_CLAIM,
      RENTAL_MENU_ACTION_KEY.RENTAL_FINALIZE_APPLICATIONS,
    ].includes(approvalTypeList[approvalTypeSelectIndex].key)
      ? [
          getColumModel({
            header: t("rental_approvalList_reimbursementStartDate"),
            key: "applicationStartDate",
            dataType: "string",
            width: "20%",
            control: getControlModel({
              // type: "date",
              placeholder: t(
                "rental_approvalList_reimbursementStartDate_searchPlaceholder"
              ),
            }),
            filter: false,
            config: {
              sortable: true,
            },
            hideInMobile: true,
          }),
          getColumModel({
            header: t("rental_approvalList_reimbursementEndDate"),
            key: "applicationEndDate",
            dataType: "string",
            width: "20%",
            control: getControlModel({
              // type: "date",
              placeholder: t(
                "rental_approvalList_reimbursementEndDate_searchPlaceholder"
              ),
            }),
            filter: false,
            config: {
              sortable: true,
            },
            hideInMobile: true,
          }),
        ]
      : []),
    getColumModel({
      header: t("rental_approvalList_employeeId"),
      key: "employeeId",
      dataType: "string",
      width: "20%",
      control: getControlModel({
        placeholder: t("rental_approvalList_employeeId_searchPlaceholder"),
      }),
      config: {
        sortable: true,
      },
      hideInMobile: true,
    }),
    getColumModel({
      header: t("rental_approvalList_employeeName"),
      key: "employeeName",
      dataType: "string",
      width: "20%",
      control: getControlModel({
        placeholder: t("rental_approvalList_employeeName_searchPlaceholder"),
      }),
      config: {
        sortable: true,
      },
    }),
    ...(approvalTypeList.length &&
    [RENTAL_MENU_ACTION_KEY.RENTAL_FINALIZE_APPLICATIONS].includes(
      approvalTypeList[approvalTypeSelectIndex].key
    )
      ? [
          getColumModel({
            header: t("rental_approvalList_approvedByName"),
            key: "approvedByName",
            dataType: "string",
            width: "20%",
            /*control: getControlModel({
              placeholder: t(
                "rental_approvalList_approvedByName_searchPlaceholder"
              ),
            }),*/
            filter: false,
            config: {
              sortable: true,
            },
            hideInMobile: true,
          }),
          getColumModel({
            header: t("rental_approvalList_verifiedByName"),
            key: "verifiedByName",
            dataType: "string",
            width: "20%",
            /*control: getControlModel({
              placeholder: t(
                "rental_approvalList_verifiedByName_searchPlaceholder"
              ),
            }),*/
            filter: false,
            config: {
              sortable: true,
            },
            hideInMobile: true,
          }),
        ]
      : []),
  ];

  const configModel = getBTConfig({
    columns: configColumns,
    hasIndex: false,
    mode: "list",
    defaultMode: "list",
    showGlobal: true,
    hasColumnSelector: false,
    onRowClick: (e) => redirect({ applicationId: e.data.id }),
    rowHover: true,
    responsive: true,
  });

  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          RENTAL_T_CONTEXT_KEY.COMMON,
          RENTAL_T_CONTEXT_KEY.APPROVAL,
        ]}
      />
      <div className="rental-approval-list">
        <RentalMenu
          menu={rentalMenu}
          currentPageKey={RENTAL_MENU_PAGE_KEY.RENTAL_APPROVER_CORNER}
        />

        <div className="title">{t("rental_approvalList_title")}</div>

        <Animated
          animationIn="slideInRight"
          animationOut="slideOutRight"
          animationInDuration={200}
          animationOutDuration={200}
          isVisible={true}
        >
          <div className="filter-container">
            {isMobile ? (
              <div className="dropdown-wrapper">
                <Dropdown
                  value={approvalTypeList[approvalTypeSelectIndex].value}
                  options={approvalTypeList}
                  onChange={(e) => {
                    setApprovalTypeSelectIndex(
                      approvalTypeList.findIndex((t) => t.value === e.value)
                    );
                  }}
                />
              </div>
            ) : (
              <TabMenu
                model={approvalTypeList}
                activeIndex={approvalTypeSelectIndex}
                onTabChange={(e) => {
                  setApprovalTypeSelectIndex(e.index);
                }}
              />
            )}
          </div>

          <div className="filter-container">
            {!isLoading && taxYear.length ? (
              isMobile ? (
                <div className="dropdown-wrapper">
                  <Dropdown
                    value={taxYear[taxYearFilterIndex].value}
                    options={taxYear}
                    onChange={(e) => {
                      setTaxYearFilterIndex(
                        taxYear.findIndex((t) => t.value === e.value)
                      );
                    }}
                  />
                </div>
              ) : (
                <TabMenu
                  model={taxYear}
                  activeIndex={taxYearFilterIndex}
                  onTabChange={(e) => {
                    setTaxYearFilterIndex(e.index);
                  }}
                />
              )
            ) : null}
          </div>

          <div className="rental-approval-list-table">
            <BaseTable
              ref={tableRef}
              isClientSize={true}
              configModel={configModel}
              searchConfig={BT_SEARCH}
              searchFn={tableGetData}
              disableMobile={true}
            />
          </div>
        </Animated>
      </div>
    </>
  );
};

export default ApprovalList;
