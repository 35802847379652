import { SIDEBAR_MENU } from "./../reduxConstants";

/**
 * set sideBar's menus
 * @returns
 */
export const setSideBarMenu = (payload) => {
  return {
    type: SIDEBAR_MENU,
    payload: payload,
  };
};
