var groupBy = function (xs: any[], key: string) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export function GetSuggestionsGroup(
  inputArray: any[],
  key: string,
  itemLabelKey: string,
  itemValueKey: string
) {
  let groupedInputArray = groupBy(inputArray, key);
  return Object.keys(groupedInputArray).map((key: string) => {
    return {
      name: key,
      code: key,
      items: groupedInputArray[key].map((sub: any) => {
        return {
          code: sub[itemLabelKey],
          name: sub[itemValueKey],
        };
      }),
    };
  });
}

export function GetArrayDepth(value: any): number {
  let test = Object.keys(value[0]).filter((x: any) =>
    Array.isArray(value[0][x])
  );
  if (!!test && test.length === 0) {
    return 0;
  }
  return Array.isArray(value)
    ? 1 +
        Math.max(
          ...Object.keys(value[0])
            .filter((x: any) => Array.isArray(value[0][x]))
            .map((x) => {
              return 1;
            })
        )
    : 0;
}
