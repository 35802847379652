import { SET_USER_INFO } from "./../reduxConstants";

/**
 * set user information
 */
export const setUserInfo = (user) => {
  return {
    type: SET_USER_INFO,
    payload: user,
  };
};
