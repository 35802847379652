import { getColumModel } from "./../../../../../components/base-table/base-table-model";
import { ExportInterfaceFileHistory, PostInterfaceFileHistory } from "./report";
import moment from "moment";

const DATETIME_FORMAT = "yyyy/MM/DD HH:mmA";

const commonFieldAttribute = {
  length: 0,
  isAllowNull: true,
  isEditable: true,
  isHideLabelField: false,
  isHtmlField: false,
  isSpecialField: false,
  isDefaultHide: false,
};

const companyCodeField = (t) => ({
  id: 1001,
  viewSectionId: 101,
  fieldName: "Company_Code",
  inputName: "companyCodes",
  fieldLabel: t("rental_report_filter_companyCodes"),
  controlType: "DropDownListMultiselect",
  dataType: "string",
  storageDataType: "String",
  value: "",
  isDetailView: true,
  dropdownOptions: [],
  ...commonFieldAttribute,
});
const assessmentYearField = (t) => ({
  id: 1002,
  viewSectionId: 102,
  fieldName: "Assessment_Year",
  inputName: "assessmentYears",
  fieldLabel: t("rental_report_filter_assessmentYears"),
  controlType: "DropDownListMultiselect",
  dataType: "string",
  storageDataType: "String",
  value: "",
  isDetailView: false,
  dropdownOptions: [],
  ...commonFieldAttribute,
});
const applicationStatusField = (t, statusDefaultVal = "") => ({
  id: 1003,
  viewSectionId: 103,
  fieldName: "Application_Status",
  inputName: "applicationStatuses",
  fieldLabel: t("rental_report_filter_applicationStatuses"),
  controlType: "DropDownListMultiselect",
  dataType: "string",
  storageDataType: "String",
  value: statusDefaultVal,
  isDetailView: false,
  dropdownOptions: [],
  ...commonFieldAttribute,
});
const departmentField = (t) => ({
  id: 1004,
  viewSectionId: 104,
  fieldName: "Department",
  inputName: "departments",
  fieldLabel: t("rental_report_filter_departments"),
  controlType: "DropDownListMultiselect",
  dataType: "string",
  storageDataType: "String",
  value: "",
  isDetailView: true,
  dropdownOptions: [],
  ...commonFieldAttribute,
});
const employeeCodeField = (t) => ({
  id: 1005,
  viewSectionId: 105,
  fieldName: "Employee_Code",
  inputName: "employeeCodes",
  fieldLabel: t("rental_report_filter_employeeCodes"),
  controlType: "DropDownListMultiselect",
  dataType: "string",
  storageDataType: "String",
  value: "",
  isDetailView: false,
  dropdownOptions: [],
  ...commonFieldAttribute,
});
const applicationNoFrom = (t) => ({
  id: 1006,
  viewSectionId: 106,
  fieldName: "Application_No_From",
  inputName: "applicationNos",
  fieldLabel: t("rental_report_filter_applicationNoFrom"),
  controlType: "DropDownList",
  dataType: "string",
  storageDataType: "String",
  value: "",
  isDetailView: false,
  dropdownOptions: [],
  ...commonFieldAttribute,
});
const applicationNoTo = (t) => ({
  id: 1007,
  viewSectionId: 107,
  fieldName: "Application_No_To",
  inputName: "applicationNos",
  fieldLabel: t("rental_report_filter_applicationNoTo"),
  controlType: "DropDownList",
  dataType: "string",
  storageDataType: "String",
  value: "",
  isDetailView: false,
  dropdownOptions: [],
  ...commonFieldAttribute,
});
const applicationDateFrom = (t) => ({
  id: 1008,
  viewSectionId: 108,
  fieldName: "Application_Date_From",
  inputName: "applicationDateFrom",
  fieldLabel: t("rental_report_filter_applicationDateFrom"),
  controlType: "DatePicker",
  dataType: "datetime",
  storageDataType: "Datetime",
  value: "",
  isDetailView: true,
  ...commonFieldAttribute,
});
const applicationDateTo = (t) => ({
  id: 1009,
  viewSectionId: 109,
  fieldName: "Application_Date_To",
  inputName: "applicationDateTo",
  fieldLabel: t("rental_report_filter_applicationDateTo"),
  controlType: "DatePicker",
  dataType: "datetime",
  storageDataType: "Datetime",
  value: "",
  isDetailView: true,
  ...commonFieldAttribute,
});
const approvalDateFrom = (t) => ({
  id: 1010,
  viewSectionId: 110,
  fieldName: "Approval_Date_From",
  inputName: "approvalDateFrom",
  fieldLabel: t("rental_report_filter_approvalDateFrom"),
  controlType: "DatePicker",
  dataType: "datetime",
  storageDataType: "Datetime",
  value: "",
  isDetailView: true,
  ...commonFieldAttribute,
});
const approvalDateTo = (t) => ({
  id: 1011,
  viewSectionId: 111,
  fieldName: "Approval_Date_To",
  inputName: "approvalDateTo",
  fieldLabel: t("rental_report_filter_approvalDateTo"),
  controlType: "DatePicker",
  dataType: "datetime",
  storageDataType: "Datetime",
  value: "",
  isDetailView: true,
  ...commonFieldAttribute,
});
const reimbursementStartDateFrom = (t) => ({
  id: 1012,
  viewSectionId: 112,
  fieldName: "Reimbursement_Start_Date_From",
  inputName: "reimbursementStartDateFrom",
  fieldLabel: t("rental_report_filter_reimbursementStartDateFrom"),
  controlType: "DatePicker",
  dataType: "datetime",
  storageDataType: "Datetime",
  value: "",
  isDetailView: true,
  ...commonFieldAttribute,
});
const reimbursementStartDateTo = (t) => ({
  id: 1013,
  viewSectionId: 113,
  fieldName: "Reimbursement_Start_Date_To",
  inputName: "reimbursementStartDateTo",
  fieldLabel: t("rental_report_filter_reimbursementStartDateTo"),
  controlType: "DatePicker",
  dataType: "datetime",
  storageDataType: "Datetime",
  value: "",
  isDetailView: true,
  ...commonFieldAttribute,
});
const reimbursementEndDateFrom = (t) => ({
  id: 1014,
  viewSectionId: 114,
  fieldName: "Reimbursement_End_Date_From",
  inputName: "reimbursementEndDateFrom",
  fieldLabel: t("rental_report_filter_reimbursementEndDateFrom"),
  controlType: "DatePicker",
  dataType: "datetime",
  storageDataType: "Datetime",
  value: "",
  isDetailView: true,
  ...commonFieldAttribute,
});
const reimbursementEndDateTo = (t) => ({
  id: 1015,
  viewSectionId: 115,
  fieldName: "Reimbursement_End_Date_To",
  inputName: "reimbursementEndDateTo",
  fieldLabel: t("rental_report_filter_reimbursementEndDateTo"),
  controlType: "DatePicker",
  dataType: "datetime",
  storageDataType: "Datetime",
  value: "",
  isDetailView: true,
  ...commonFieldAttribute,
});
const businessUnitField = (t) => ({
  id: 1016,
  viewSectionId: 116,
  fieldName: "Business_Unit",
  inputName: "businessUnits",
  fieldLabel: t("rental_report_filter_businessUnits"),
  controlType: "DropDownListMultiselect",
  dataType: "string",
  storageDataType: "String",
  value: "",
  isDetailView: true,
  dropdownOptions: [],
  ...commonFieldAttribute,
});
const divisionField = (t) => ({
  id: 1017,
  viewSectionId: 117,
  fieldName: "Division",
  inputName: "divisions",
  fieldLabel: t("rental_report_filter_divisions"),
  controlType: "DropDownListMultiselect",
  dataType: "string",
  storageDataType: "String",
  value: "",
  isDetailView: true,
  dropdownOptions: [],
  ...commonFieldAttribute,
});
const staffTypeField = (t) => ({
  id: 1018,
  viewSectionId: 118,
  fieldName: "Staff_Type",
  inputName: "staffTypes",
  fieldLabel: t("rental_report_filter_staffTypes"),
  controlType: "DropDownListMultiselect",
  dataType: "string",
  storageDataType: "String",
  value: "",
  isDetailView: true,
  dropdownOptions: [],
  ...commonFieldAttribute,
});
const lastEmploymentDateFrom = (t) => ({
  id: 1019,
  viewSectionId: 119,
  fieldName: "Last_Employment_Date_From",
  inputName: "lastEmploymentDateFrom",
  fieldLabel: t("rental_report_filter_lastEmploymentDateFrom"),
  controlType: "DatePicker",
  dataType: "datetime",
  storageDataType: "Datetime",
  value: "",
  isDetailView: true,
  ...commonFieldAttribute,
});
const lastEmploymentDateTo = (t) => ({
  id: 1020,
  viewSectionId: 120,
  fieldName: "Last_Employment_Date_To",
  inputName: "lastEmploymentDateTo",
  fieldLabel: t("rental_report_filter_lastEmploymentDateTo"),
  controlType: "DatePicker",
  dataType: "datetime",
  storageDataType: "Datetime",
  value: "",
  isDetailView: true,
  ...commonFieldAttribute,
});
const monthField = (t) => ({
  id: 1021,
  viewSectionId: 121,
  fieldName: "Month",
  inputName: "months",
  fieldLabel: t("rental_report_filter_Month"),
  controlType: "DropDownListMultiselect",
  dataType: "string",
  storageDataType: "String",
  value: "",
  isDetailView: true,
  dropdownOptions: [],
  ...commonFieldAttribute,
});
const lastModifiedDateFrom = (t) => ({
  id: 1022,
  viewSectionId: 122,
  fieldName: "Last_Modified_Date_From",
  inputName: "lastModifiedDateFrom",
  fieldLabel: t("rental_report_filter_lastModifiedDateFrom"),
  controlType: "DatePicker",
  dataType: "datetime",
  storageDataType: "Datetime",
  value: "",
  isDetailView: true,
  ...commonFieldAttribute,
});
const lastModifiedDateTo = (t) => ({
  id: 1023,
  viewSectionId: 123,
  fieldName: "Last_Modified_Date_To",
  inputName: "lastModifiedDateTo",
  fieldLabel: t("rental_report_filter_lastModifiedDateTo"),
  controlType: "DatePicker",
  dataType: "datetime",
  storageDataType: "Datetime",
  value: "",
  isDetailView: true,
  ...commonFieldAttribute,
});
const actionDateFrom = (t) => ({
  id: 1024,
  viewSectionId: 124,
  fieldName: "Action_Date_From",
  inputName: "actionDateFrom",
  fieldLabel: t("rental_report_filter_actionDateFrom"),
  controlType: "DatePicker",
  dataType: "datetime",
  storageDataType: "Datetime",
  value: "",
  isDetailView: true,
  ...commonFieldAttribute,
});
const actionDateTo = (t) => ({
  id: 1025,
  viewSectionId: 125,
  fieldName: "Action_Date_To",
  inputName: "actionDateTo",
  fieldLabel: t("rental_report_filter_actionDateTo"),
  controlType: "DatePicker",
  dataType: "datetime",
  storageDataType: "Datetime",
  value: "",
  isDetailView: true,
  ...commonFieldAttribute,
});
const companyCodeSingleField = (t) => ({
  id: 1026,
  viewSectionId: 126,
  fieldName: "Company_Code_Single",
  inputName: "companyCodeSingle",
  fieldLabel: t("rental_report_filter_companyCodes"),
  controlType: "DropDownList",
  dataType: "string",
  storageDataType: "String",
  value: "",
  isDetailView: true,
  dropdownOptions: [],
  ...commonFieldAttribute,
});
const costCenterField = (t) => ({
  id: 1027,
  viewSectionId: 127,
  fieldName: "Cost_Center",
  inputName: "costCenterCodes",
  fieldLabel: t("rental_report_filter_costCenter"),
  controlType: "DropDownListMultiselect",
  dataType: "string",
  storageDataType: "String",
  value: "",
  isDetailView: true,
  dropdownOptions: [],
  ...commonFieldAttribute,
});
const periodDateFrom = (t) => ({
  id: 1028,
  viewSectionId: 128,
  fieldName: "Period_Date_From",
  inputName: "periodDateFrom",
  fieldLabel: t("rental_report_filter_periodDateFrom"),
  controlType: "DatePicker",
  dataType: "datetime",
  storageDataType: "Datetime",
  value: "",
  isDetailView: true,
  ...commonFieldAttribute,
});
const periodDateTo = (t) => ({
  id: 1029,
  viewSectionId: 129,
  fieldName: "Period_Date_To",
  inputName: "periodDateTo",
  fieldLabel: t("rental_report_filter_periodDateTo"),
  controlType: "DatePicker",
  dataType: "datetime",
  storageDataType: "Datetime",
  value: "",
  isDetailView: true,
  ...commonFieldAttribute,
});
const effectivePeriodDateFrom = (t) => ({
  id: 1030,
  viewSectionId: 130,
  fieldName: "Effective_Period_Date_From",
  inputName: "effectivePeriodDateFrom",
  fieldLabel: t("rental_report_filter_effectivePeriodDateFrom"),
  controlType: "DatePicker",
  dataType: "datetime",
  storageDataType: "Datetime",
  value: "",
  isDetailView: true,
  ...commonFieldAttribute,
});
const effectivePeriodDateTo = (t) => ({
  id: 1031,
  viewSectionId: 131,
  fieldName: "Effective_Period_Date_To",
  inputName: "effectivePeriodDateTo",
  fieldLabel: t("rental_report_filter_effectivePeriodDateTo"),
  controlType: "DatePicker",
  dataType: "datetime",
  storageDataType: "Datetime",
  value: "",
  isDetailView: true,
  ...commonFieldAttribute,
});
const postingStatus = (t) => ({
  id: 1032,
  viewSectionId: 132,
  fieldName: "Posting_Status",
  inputName: "postingStatus",
  fieldLabel: t("rental_report_filter_postingStatus"),
  controlType: "DropDownList",
  dataType: "string",
  storageDataType: "String",
  value: "",
  isDetailView: false,
  dropdownOptions: [],
  ...commonFieldAttribute,
});

const getReportFilterFields = (reportFilter) => {
  let filterIndex = 0;
  let filters = [];
  reportFilter.forEach((rowFilters, ri) => {
    rowFilters.forEach((f) => {
      filterIndex++;
      let filter = { ...f };
      filter.displaySequence = filterIndex;
      filter.fieldGroup = ri + 1;
      filters.push(filter);
    });
  });
  return filters;
};

const getFilterFieldConfig = (t, reportFilter) => {
  return {
    data: {
      id: 100,
      formName: "Search/Report",
      sections: [
        {
          id: 101,
          sectionName: t("rental_report_filter_searchFields"),
          displaySequence: 1,
          defaultExpanded: true,
          fields: getReportFilterFields(reportFilter),
        },
      ],
    },
    messages: [
      {
        type: "Success",
        text: "",
      },
    ],
  };
};

const applicationSummaryFilter = (t) => [
  [companyCodeField(t), assessmentYearField(t), applicationStatusField(t)],
  [departmentField(t), employeeCodeField(t)],
  [applicationNoFrom(t), applicationNoTo(t)],
  [applicationDateFrom(t), applicationDateTo(t)],
  [approvalDateFrom(t), approvalDateTo(t)],
  [reimbursementStartDateFrom(t), reimbursementStartDateTo(t)],
  [reimbursementEndDateFrom(t), reimbursementEndDateTo(t)],
];
const claimSummaryFilter = (t) => [
  [companyCodeField(t), assessmentYearField(t), applicationStatusField(t)],
  [employeeCodeField(t)],
  [applicationNoFrom(t), applicationNoTo(t)],
  [applicationDateFrom(t), applicationDateTo(t)],
  [approvalDateFrom(t), approvalDateTo(t)],
  [reimbursementStartDateFrom(t), reimbursementStartDateTo(t)],
  [reimbursementEndDateFrom(t), reimbursementEndDateTo(t)],
];
const applicationLogFilter = (t) => [
  [companyCodeField(t), assessmentYearField(t), applicationStatusField(t)],
  [businessUnitField(t), departmentField(t), divisionField(t)],
  [staffTypeField(t), employeeCodeField(t)],
  [reimbursementStartDateFrom(t), reimbursementStartDateTo(t)],
  [reimbursementEndDateFrom(t), reimbursementEndDateTo(t)],
];
const annualTaxFilter = (t) => [
  [
    companyCodeField(t),
    assessmentYearField(t),
    applicationStatusField(t, ["Application (Finalized)"]),
  ],
  [reimbursementStartDateFrom(t), reimbursementStartDateTo(t)],
  [reimbursementEndDateFrom(t), reimbursementEndDateTo(t)],
  [lastEmploymentDateFrom(t), lastEmploymentDateTo(t)],
];
const claimDetailFilter = (t) => [
  [companyCodeField(t), assessmentYearField(t), applicationStatusField(t)],
  [businessUnitField(t), departmentField(t), divisionField(t)],
  [staffTypeField(t), employeeCodeField(t)],
  [reimbursementStartDateFrom(t), reimbursementStartDateTo(t)],
  [reimbursementEndDateFrom(t), reimbursementEndDateTo(t)],
];
const monthlyRentalFilter = (t) => [
  [companyCodeField(t), assessmentYearField(t)],
  [monthField(t), lastModifiedDateFrom(t), lastModifiedDateTo(t)],
  // [assessmentYearField(t), applicationStatusField(t)],
  // [employeeCodeField(t)],
  // [applicationNoFrom(t), applicationNoTo(t)],
  // [applicationDateFrom(t), applicationDateTo(t)],
  // [approvalDateFrom(t), approvalDateTo(t)],
  // [reimbursementStartDateFrom(t), reimbursementStartDateTo(t)],
  // [reimbursementEndDateFrom(t), reimbursementEndDateTo(t)],
];
const rentalAttachmentFilter = (t) => [
  [companyCodeField(t)],
  [actionDateFrom(t), actionDateTo(t)],
];
const rentalInterfaceFilter = (t) => [
  [companyCodeSingleField(t), departmentField(t), costCenterField(t)],
  [employeeCodeField(t)],
  [periodDateFrom(t), periodDateTo(t), postingStatus(t)],
  [effectivePeriodDateFrom(t), effectivePeriodDateTo(t)],
];

const columnConfig = (column) =>
  getColumModel({
    header: column.header,
    key: column.key,
    dataType: "string",
    width: column.width ? column.width : 11,
    sortable: column.sortable ? column.sortable : false,
    render: column.render ? column.render : null,
  });

const getColumnConfig = (t, table) => {
  const rentalReimbursementId = {
    key: "rentalReimbursementId",
    header: t("rental_report_column_rentalReimbursementId"),
  };
  const rentalReimbursementIdApplicationId = {
    key: "rentalReimbursementId",
    header: t("rental_report_column_rentalReimbursementId_applicationId"),
  };
  const sequenceNumber = {
    key: "sequenceNumber",
    header: t("rental_report_column_sequenceNumber"),
    width: 8,
  };
  const taxYearOfAssessment = {
    key: "taxYearOfAssessment",
    header: t("rental_report_column_taxYearOfAssessment"),
  };
  const hongKongtaxYearOfAssessment = {
    key: "taxYearOfAssessment",
    header: t("rental_report_column_hongKongtaxYearOfAssessment"),
  };
  const status = {
    key: "status",
    header: t("rental_report_column_status"),
  };
  const statusUpdateStatus = {
    key: "status",
    header: t("rental_report_column_status_updateStatus"),
  };
  const department = {
    key: "department",
    header: t("rental_report_column_department"),
  };
  const employeeCode = {
    key: "employeeCode",
    header: t("rental_report_column_employeeCode"),
  };
  const employeeCodeEmployeeID = {
    key: "employeeCode",
    header: t("rental_report_column_employeeCode_employeeID"),
  };
  const employeeName = {
    key: "employeeName",
    header: t("rental_report_column_employeeName"),
  };
  const dateJoinedGroup = {
    key: "dateJoinedGroup",
    header: t("rental_report_column_dateJoinedGroup"),
  };
  const dateJoinedCompany = {
    key: "dateJoinedCompany",
    header: t("rental_report_column_dateJoinedCompany"),
  };
  const dateJoinedCompanyHkLegalEntityJoinedDate = {
    key: "dateJoinedCompany",
    header: t("rental_report_column_dateJoinedCompany_hkLegalEntityJoinedDate"),
  };
  const cessationEmploymentDateLastEmploymentDate = {
    key: "cessationEmploymentDate",
    header: t(
      "rental_report_column_cessationEmploymentDate_lastEmploymentDate"
    ),
  };
  const cessationEmploymentDate = {
    key: "cessationEmploymentDate",
    header: t("rental_report_column_cessationEmploymentDate"),
  };
  const address = {
    key: "address",
    header: t("rental_report_column_address"),
    width: 15,
  };
  const addressQuarterAddress = {
    key: "address",
    header: t("rental_report_column_address_quarterAddress"),
    width: 15,
  };
  const district = {
    key: "district",
    header: t("rental_report_column_district"),
  };
  const districtArea = {
    key: "district",
    header: t("rental_report_column_area"),
  };
  const accommodationType = {
    key: "accommodationType",
    header: t("rental_report_column_accommodationType"),
    width: 15,
  };
  const accommodationTypeNature = {
    key: "accommodationType",
    header: t("rental_report_column_accommodationType_nature"),
    width: 15,
  };
  const accommodationTypeQuarterType = {
    key: "accommodationType",
    header: t("rental_report_column_accommodationType_quarterType"),
    width: 15,
  };
  const landlordNames = {
    key: "landlordNames",
    header: t("rental_report_column_landlordNames"),
    width: 15,
  };
  const landlordRelationship = {
    key: "landlordRelationship",
    header: t("rental_report_column_landlordRelationship"),
    width: 15,
  };
  const landlordRelationshipRelationshipWithLandlord = {
    key: "landlordRelationship",
    header: t(
      "rental_report_column_landlordRelationship_relationshipWithLandlord"
    ),
    width: 15,
  };
  const jointTenancyAgreement = {
    key: "jointTenancyAgreement",
    header: t("rental_report_column_jointTenancyAgreement"),
  };
  const jointTenancyAgreementJointTenancyAgreementAgreementNotIncludeEmployeeName =
    {
      key: "jointTenancyAgreement",
      header: t(
        "rental_report_column_jointTenancyAgreement_jointTenancyAgreementAgreementNotIncludeEmployeeName"
      ),
    };
  const tenantNames = {
    key: "tenantNames",
    header: t("rental_report_column_tenantNames"),
  };
  const spouseName = {
    key: "spouseName",
    header: t("rental_report_column_spouseName"),
  };
  const leaseStartDate = {
    key: "leaseStartDate",
    header: t("rental_report_column_leaseStartDate"),
  };
  const leaseEndDate = {
    key: "leaseEndDate",
    header: t("rental_report_column_leaseEndDate"),
  };
  const monthlyRental = {
    key: "monthlyRental",
    header: t("rental_report_column_monthlyRental"),
  };
  const actualSharePortionDeclarationByAmount = {
    key: "actualSharePortionDeclarationByAmount",
    header: t("rental_report_column_actualSharePortionDeclarationByAmount"),
  };
  const actualSharePortionDeclarationByAmountActualAmountofRentalSharedHKD = {
    key: "actualSharePortionDeclarationByAmount",
    header: t(
      "rental_report_column_actualSharePortionDeclarationByAmount_actualAmountofRentalSharedHKD"
    ),
  };
  const actualSharePortionDeclarationByPercentage = {
    key: "actualSharePortionDeclarationByPercentage",
    header: t("rental_report_column_actualSharePortionDeclarationByPercentage"),
  };
  const monthlyRatesGovernmentRents = {
    key: "monthlyRatesGovernmentRents",
    header: t("rental_report_column_monthlyRatesGovernmentRents"),
  };
  const monthlyManagementFees = {
    key: "monthlyManagementFees",
    header: t("rental_report_column_monthlyManagementFees"),
  };
  const monthlyRentalReimbursementCap = {
    key: "monthlyRentalReimbursementCap",
    header: t("rental_report_column_monthlyRentalReimbursementCap"),
  };
  const acceptableMonthlyReimbursementAmount = {
    key: "acceptableMonthlyReimbursementAmount",
    header: t("rental_report_column_acceptableMonthlyReimbursementAmount"),
  };
  const acceptableMonthlyReimbursementAmountAcceptableMonthlyClaimAmount = {
    key: "acceptableMonthlyReimbursementAmount",
    header: t(
      "rental_report_column_acceptableMonthlyReimbursementAmount_acceptableMonthlyClaimAmount"
    ),
  };
  const reimbursementEffectiveDate = {
    key: "reimbursementEffectiveDate",
    header: t("rental_report_column_reimbursementEffectiveDate"),
  };
  const reimbursementEffectiveDateReimbursementStartDate = {
    key: "reimbursementEffectiveDate",
    header: t(
      "rental_report_column_reimbursementEffectiveDate_reimbursementStartDate"
    ),
  };
  const reimbursementEffectiveDateReimbursementEffectiveDateYYYYMMDD = {
    key: "reimbursementEffectiveDate",
    header: t(
      "rental_report_column_reimbursementEffectiveDate_reimbursementEffectiveDateYYYYMMDD"
    ),
  };
  const reimbursementEndDate = {
    key: "reimbursementEndDate",
    header: t("rental_report_column_reimbursementEndDate"),
  };
  const reimbursementEndDateReimbursementEndDateYYYYMMDD = {
    key: "reimbursementEndDate",
    header: t(
      "rental_report_column_reimbursementEndDate_reimbursementEndDateYYYYMMDD"
    ),
  };
  const rentalAmount = {
    key: "monthlyRental",
    header: t("rental_report_column_rentalAmount"),
  };
  const ratesGovernmentRentsAndManagementFees = {
    key: "ratesGovernmentRentsAndManagementFees",
    header: t("rental_report_column_ratesGovernmentRentsAndManagementFees"),
  };
  const totalClaim = {
    key: "totalClaim",
    header: t("rental_report_column_totalClaim"),
  };
  const totalClaimRentPaidtoLandlordbyEmployee = {
    key: "totalClaim",
    header: t("rental_report_column_totalClaim_rentPaidtoLandlordbyEmployee"),
  };
  const totalClaimClaimAmount = {
    key: "totalClaim",
    header: t("rental_report_column_totalClaim_claimAmount"),
  };
  const totalClaimTotalActualRentalAmountRentPaidtoLandlordbyEmployee = {
    key: "totalClaim",
    header: t(
      "rental_report_column_totalActualRentalAmountRentPaidtoLandlordbyEmployee"
    ),
  };
  const totalReimbursementAmount = {
    key: "totalReimbursementAmount",
    header: t("rental_report_column_totalReimbursementAmount"),
  };
  const totalReimbursementAmountTotalReimbursementAmountPayroll = {
    key: "totalReimbursementAmount",
    header: t(
      "rental_report_column_totalReimbursementAmount_totalReimbursementAmountPayroll"
    ),
  };
  const totalApprovedAmount = {
    key: "totalApprovedAmount",
    header: t("rental_report_column_totalApprovedAmount"),
  };
  const totalApprovedAmountRentRefundedtoEmployeebyEmployer = {
    key: "totalApprovedAmount",
    header: t(
      "rental_report_column_totalApprovedAmount_rentRefundedtoEmployeebyEmployer"
    ),
  };
  const totalApprovedAmountTotalApprovedClaimsRentRefundedtoEmployeebyEmployer =
    {
      key: "totalApprovedAmount",
      header: t(
        "rental_report_column_totalApprovedAmount_totalApprovedClaimsRentRefundedtoEmployeebyEmployer"
      ),
    };
  const adjustmentToHousingAllowanceSlashBasicSalary = {
    key: "adjustmentToHousingAllowanceSlashBasicSalary",
    header: t(
      "rental_report_column_adjustmentToHousingAllowanceSlashBasicSalary"
    ),
  };
  const month = {
    key: "month",
    header: t("rental_report_column_month"),
  };
  const aprStatus = {
    key: "aprStatus",
    header: t("rental_report_column_aprStatus"),
  };
  const aprCappedAmount = {
    key: "aprCappedAmount",
    header: t("rental_report_column_aprCappedAmount"),
  };
  const aprReimbursementAmount = {
    key: "aprReimbursementAmount",
    header: t("rental_report_column_aprReimbursementAmount"),
  };
  const aprClaim = {
    key: "aprClaim",
    header: t("rental_report_column_aprClaim"),
  };
  const aprClaimAprilActualRentalAmount = {
    key: "aprClaim",
    header: t("rental_report_column_aprClaim_aprilActualRentalAmount"),
  };
  const aprApprovedAmount = {
    key: "aprApprovedAmount",
    header: t("rental_report_column_aprApprovedAmount"),
  };
  const aprAdjustedHousing = {
    key: "aprAdjustedHousing",
    header: t("rental_report_column_aprAdjustedHousing"),
  };
  const mayStatus = {
    key: "mayStatus",
    header: t("rental_report_column_mayStatus"),
  };
  const mayCappedAmount = {
    key: "mayCappedAmount",
    header: t("rental_report_column_mayCappedAmount"),
  };
  const mayReimbursementAmount = {
    key: "mayReimbursementAmount",
    header: t("rental_report_column_mayReimbursementAmount"),
  };
  const mayClaim = {
    key: "mayClaim",
    header: t("rental_report_column_mayClaim"),
  };
  const mayClaimMayActualRentalAmount = {
    key: "mayClaim",
    header: t("rental_report_column_mayClaim_mayActualRentalAmount"),
  };
  const mayApprovedAmount = {
    key: "mayApprovedAmount",
    header: t("rental_report_column_mayApprovedAmount"),
  };
  const mayAdjustedHousing = {
    key: "mayAdjustedHousing",
    header: t("rental_report_column_mayAdjustedHousing"),
  };
  const junStatus = {
    key: "junStatus",
    header: t("rental_report_column_junStatus"),
  };
  const junCappedAmount = {
    key: "junCappedAmount",
    header: t("rental_report_column_junCappedAmount"),
  };
  const junReimbursementAmount = {
    key: "junReimbursementAmount",
    header: t("rental_report_column_junReimbursementAmount"),
  };
  const junClaim = {
    key: "junClaim",
    header: t("rental_report_column_junClaim"),
  };
  const junClaimJuneActualRentalAmount = {
    key: "junClaim",
    header: t("rental_report_column_junClaim_juneActualRentalAmount"),
  };
  const junApprovedAmount = {
    key: "junApprovedAmount",
    header: t("rental_report_column_junApprovedAmount"),
  };
  const junAdjustedHousing = {
    key: "junAdjustedHousing",
    header: t("rental_report_column_junAdjustedHousing"),
  };
  const julStatus = {
    key: "julStatus",
    header: t("rental_report_column_julStatus"),
  };
  const julCappedAmount = {
    key: "julCappedAmount",
    header: t("rental_report_column_julCappedAmount"),
  };
  const julReimbursementAmount = {
    key: "julReimbursementAmount",
    header: t("rental_report_column_julReimbursementAmount"),
  };
  const julClaim = {
    key: "julClaim",
    header: t("rental_report_column_julClaim"),
  };
  const julClaimJulyActualRentalAmount = {
    key: "julClaim",
    header: t("rental_report_column_julClaim_julyActualRentalAmount"),
  };
  const julApprovedAmount = {
    key: "julApprovedAmount",
    header: t("rental_report_column_julApprovedAmount"),
  };
  const julAdjustedHousing = {
    key: "julAdjustedHousing",
    header: t("rental_report_column_julAdjustedHousing"),
  };
  const augStatus = {
    key: "augStatus",
    header: t("rental_report_column_augStatus"),
  };
  const augCappedAmount = {
    key: "augCappedAmount",
    header: t("rental_report_column_augCappedAmount"),
  };
  const augReimbursementAmount = {
    key: "augReimbursementAmount",
    header: t("rental_report_column_augReimbursementAmount"),
  };
  const augClaim = {
    key: "augClaim",
    header: t("rental_report_column_augClaim"),
  };
  const augClaimAugustActualRentalAmount = {
    key: "augClaim",
    header: t("rental_report_column_augClaim_augustActualRentalAmount"),
  };
  const augApprovedAmount = {
    key: "augApprovedAmount",
    header: t("rental_report_column_augApprovedAmount"),
  };
  const augAdjustedHousing = {
    key: "augAdjustedHousing",
    header: t("rental_report_column_augAdjustedHousing"),
  };
  const sepStatus = {
    key: "sepStatus",
    header: t("rental_report_column_sepStatus"),
  };
  const sepCappedAmount = {
    key: "sepCappedAmount",
    header: t("rental_report_column_sepCappedAmount"),
  };
  const sepReimbursementAmount = {
    key: "sepReimbursementAmount",
    header: t("rental_report_column_sepReimbursementAmount"),
  };
  const sepClaim = {
    key: "sepClaim",
    header: t("rental_report_column_sepClaim"),
  };
  const sepClaimSeptemberActualRentalAmount = {
    key: "sepClaim",
    header: t("rental_report_column_sepClaim_septemberActualRentalAmount"),
  };
  const sepApprovedAmount = {
    key: "sepApprovedAmount",
    header: t("rental_report_column_sepApprovedAmount"),
  };
  const sepAdjustedHousing = {
    key: "sepAdjustedHousing",
    header: t("rental_report_column_sepAdjustedHousing"),
  };
  const octStatus = {
    key: "octStatus",
    header: t("rental_report_column_octStatus"),
  };
  const octCappedAmount = {
    key: "octCappedAmount",
    header: t("rental_report_column_octCappedAmount"),
  };
  const octReimbursementAmount = {
    key: "octReimbursementAmount",
    header: t("rental_report_column_octReimbursementAmount"),
  };
  const octClaim = {
    key: "octClaim",
    header: t("rental_report_column_octClaim"),
  };
  const octClaimOctoberActualRentalAmount = {
    key: "octClaim",
    header: t("rental_report_column_octClaim_octoberActualRentalAmount"),
  };
  const octApprovedAmount = {
    key: "octApprovedAmount",
    header: t("rental_report_column_octApprovedAmount"),
  };
  const octAdjustedHousing = {
    key: "octAdjustedHousing",
    header: t("rental_report_column_octAdjustedHousing"),
  };
  const novStatus = {
    key: "novStatus",
    header: t("rental_report_column_novStatus"),
  };
  const novCappedAmount = {
    key: "novCappedAmount",
    header: t("rental_report_column_novCappedAmount"),
  };
  const novReimbursementAmount = {
    key: "novReimbursementAmount",
    header: t("rental_report_column_novReimbursementAmount"),
  };
  const novClaim = {
    key: "novClaim",
    header: t("rental_report_column_novClaim"),
  };
  const novClaimNovemberActualRentalAmount = {
    key: "novClaim",
    header: t("rental_report_column_novClaim_novemberActualRentalAmount"),
  };
  const novApprovedAmount = {
    key: "novApprovedAmount",
    header: t("rental_report_column_novApprovedAmount"),
  };
  const novAdjustedHousing = {
    key: "novAdjustedHousing",
    header: t("rental_report_column_novAdjustedHousing"),
  };
  const decStatus = {
    key: "decStatus",
    header: t("rental_report_column_decStatus"),
  };
  const decCappedAmount = {
    key: "decCappedAmount",
    header: t("rental_report_column_decCappedAmount"),
  };
  const decReimbursementAmount = {
    key: "decReimbursementAmount",
    header: t("rental_report_column_decReimbursementAmount"),
  };
  const decClaim = {
    key: "decClaim",
    header: t("rental_report_column_decClaim"),
  };
  const decClaimDecemberActualRentalAmount = {
    key: "decClaim",
    header: t("rental_report_column_decClaim_decemberActualRentalAmount"),
  };
  const decApprovedAmount = {
    key: "decApprovedAmount",
    header: t("rental_report_column_decApprovedAmount"),
  };
  const decAdjustedHousing = {
    key: "decAdjustedHousing",
    header: t("rental_report_column_decAdjustedHousing"),
  };
  const janStatus = {
    key: "janStatus",
    header: t("rental_report_column_janStatus"),
  };
  const janCappedAmount = {
    key: "janCappedAmount",
    header: t("rental_report_column_janCappedAmount"),
  };
  const janReimbursementAmount = {
    key: "janReimbursementAmount",
    header: t("rental_report_column_janReimbursementAmount"),
  };
  const janClaim = {
    key: "janClaim",
    header: t("rental_report_column_janClaim"),
  };
  const janClaimJanuaryActualRentalAmount = {
    key: "janClaim",
    header: t("rental_report_column_janClaim_januaryActualRentalAmount"),
  };
  const janApprovedAmount = {
    key: "janApprovedAmount",
    header: t("rental_report_column_janApprovedAmount"),
  };
  const janAdjustedHousing = {
    key: "janAdjustedHousing",
    header: t("rental_report_column_janAdjustedHousing"),
  };
  const febStatus = {
    key: "febStatus",
    header: t("rental_report_column_febStatus"),
  };
  const febCappedAmount = {
    key: "febCappedAmount",
    header: t("rental_report_column_febCappedAmount"),
  };
  const febReimbursementAmount = {
    key: "febReimbursementAmount",
    header: t("rental_report_column_febReimbursementAmount"),
  };
  const febClaim = {
    key: "febClaim",
    header: t("rental_report_column_febClaim"),
  };
  const febClaimFebruaryActualRentalAmount = {
    key: "febClaim",
    header: t("rental_report_column_febClaim_februaryActualRentalAmount"),
  };
  const febApprovedAmount = {
    key: "febApprovedAmount",
    header: t("rental_report_column_febApprovedAmount"),
  };
  const febAdjustedHousing = {
    key: "febAdjustedHousing",
    header: t("rental_report_column_febAdjustedHousing"),
  };
  const marStatus = {
    key: "marStatus",
    header: t("rental_report_column_marStatus"),
  };
  const marCappedAmount = {
    key: "marCappedAmount",
    header: t("rental_report_column_marCappedAmount"),
  };
  const marReimbursementAmount = {
    key: "marReimbursementAmount",
    header: t("rental_report_column_marReimbursementAmount"),
  };
  const marClaim = {
    key: "marClaim",
    header: t("rental_report_column_marClaim"),
  };
  const marClaimMarchActualRentalAmount = {
    key: "marClaim",
    header: t("rental_report_column_marClaim_marchActualRentalAmount"),
  };
  const marApprovedAmount = {
    key: "marApprovedAmount",
    header: t("rental_report_column_marApprovedAmount"),
  };
  const marAdjustedHousing = {
    key: "marAdjustedHousing",
    header: t("rental_report_column_marAdjustedHousing"),
  };
  const lastModifiedByLastUpdateBy = {
    key: "lastModifiedBy",
    header: t("rental_report_column_lastModifiedBy_lastUpdateBy"),
  };
  const lastModifiedLastUpdateTimeStamp = {
    key: "lastModified",
    header: t("rental_report_column_lastModified_lastUpdateTimeStamp"),
  };
  const lastModifiedLastModifiedDate = {
    key: "lastModified",
    header: t("rental_report_column_lastModified_lastModifiedDate"),
  };
  const comment = {
    key: "comment",
    header: t("rental_report_column_comment"),
    width: 33,
    render: (data) => {
      const commentHtml = data.comment.replace(/(\r\n|\n|\r)/gm, "<br/>");
      return <div dangerouslySetInnerHTML={{ __html: commentHtml }} />;
    },
  };

  const changeTypeCode = {
    key: "changeTypeCode",
    header: t("rental_report_column_changeTypeCode"),
  };
  const changeType = {
    key: "changeType",
    header: t("rental_report_column_changeType"),
  };
  const changeReasonCode = {
    key: "changeReasonCode",
    header: t("rental_report_column_changeReasonCode"),
  };
  const changeReason = {
    key: "changeReason",
    header: t("rental_report_column_changeReason"),
  };
  const applicationSubmissionDate = {
    key: "applicationSubmissionDate",
    header: t("rental_report_column_applicationSubmissionDate"),
  };
  const company = {
    key: "company",
    header: t("rental_report_column_company"),
  };
  const companyCompanyName = {
    key: "company",
    header: t("rental_report_column_company_companyName"),
  };
  const companyNameCompanyName = {
    key: "companyName",
    header: t("rental_report_column_company_companyName"),
  };
  const employeeEmail = {
    key: "employeeEmail",
    header: t("rental_report_column_employeeEmail"),
  };
  const businessUnit = {
    key: "businessUnit",
    header: t("rental_report_column_businessUnit"),
  };
  const area = {
    key: "area",
    header: t("rental_report_column_area"),
  };
  const relationship = {
    key: "relationship",
    header: t("rental_report_column_relationship"),
  };
  const relationshipRelationwithLandlord = {
    key: "relationship",
    header: t(
      "rental_report_column_landlordRelationship_relationshipWithLandlord"
    ),
  };
  const jointTenancyRelationship = {
    key: "jointTenancyRelationship",
    header: t("rental_report_column_jointTenancyRelationship"),
  };
  const employeeComment = {
    key: "employeeComment",
    header: t("rental_report_column_employeeComment"),
    width: 33,
    render: (data) => {
      const commentHtml = data.employeeComment.replace(
        /(\r\n|\n|\r)/gm,
        "<br/>"
      );
      return <div dangerouslySetInnerHTML={{ __html: commentHtml }} />;
    },
  };
  const employeeCommentEmployeeComment = {
    key: "employeeComment",
    header: t("rental_report_column_employeeComment_employeeComment"),
    width: 33,
    render: (data) => {
      const commentHtml = data.employeeComment.replace(
        /(\r\n|\n|\r)/gm,
        "<br/>"
      );
      return <div dangerouslySetInnerHTML={{ __html: commentHtml }} />;
    },
  };
  const verifierComment = {
    key: "verifierComment",
    header: t("rental_report_column_verifierComment"),
    width: 33,
    render: (data) => {
      const commentHtml = data.verifierComment.replace(
        /(\r\n|\n|\r)/gm,
        "<br/>"
      );
      return <div dangerouslySetInnerHTML={{ __html: commentHtml }} />;
    },
  };
  const claimStatus = {
    key: "claimStatus",
    header: t("rental_report_column_claimStatus"),
  };
  const previousMonthsRetroactiveAdjReimbursement = {
    key: "",
    header: t("rental_report_column_previousMonthsRetroactiveAdjReimbursement"),
  };
  const currentMonthAmountofReimbursement = {
    key: "reimbursementCurrentMonth",
    header: t("rental_report_column_currentMonthAmountofReimbursement"),
  };
  const lastWorkDateLastEmploymentDate = {
    key: "last_Work_Date",
    header: t("rental_report_column_lastWorkDateLastEmploymentDate"),
  };
  const uploadId = {
    key: "uploadId",
    header: t("rental_report_column_uploadId"),
  };
  const fileId = {
    key: "fileId",
    header: t("rental_report_column_fileId"),
  };
  const fileName = {
    key: "fileName",
    header: t("rental_report_column_fileName"),
  };
  const fileType = {
    key: "fileType",
    header: t("rental_report_column_fileType"),
  };
  const claimId = {
    key: "claimId",
    header: t("rental_report_column_claimId"),
  };
  const companyCode = {
    key: "companyCode",
    header: t("rental_report_column_companyCode"),
  };
  const attachmentType = {
    key: "attachmentType",
    header: t("rental_report_column_attachmentType"),
  };
  const action = {
    key: "action",
    header: t("rental_report_column_action"),
  };
  const actionDate = {
    key: "actionDate",
    header: t("rental_report_column_actionDate"),
  };
  const actionBy = {
    key: "actionBy",
    header: t("rental_report_column_actionBy"),
  };
  const actionByName = {
    key: "actionByName",
    header: t("rental_report_column_actionByName"),
  };
  const reference = {
    key: "reference",
    header: t("rental_report_column_reference"),
  };
  const exportedBy = {
    key: "exportedBy",
    header: t("rental_report_column_exportedBy"),
    sortable: true,
  };
  const exportedAt = {
    key: "exportedAt",
    header: t("rental_report_column_exportedAt"),
    sortable: true,
  };
  const exportToExcel = {
    key: "exportToExcel",
    header: t("rental_report_column_exportToExcel"),
    render: (data) => (
      <button
        onClick={() => {
          ExportInterfaceFileHistory(data.reference, data.fileName);
        }}
        class="p-button p-component"
      >
        {t("rental_report_footerExportToReport")}
      </button>
    ),
  };
  const postingStatus = {
    key: "posted",
    header: t("rental_report_column_postingStatus"),
    render: (data) => (
      <>
        {data.posted === false ? (
          <button
            onClick={() => {
              PostInterfaceFileHistory(data.reference);
            }}
            class="p-button p-component"
          >
            {t("rental_report_action_post")}
          </button>
        ) : null}
      </>
    ),
  };

  let columns = [];
  // TODO:
  //  claimSummary - "Rental Amount, Comment" not mapped, "marStatus" fix
  //  SequenceNumber, Department, Type of Accomodation preparing
  switch (table) {
    case "applicationSummary":
    case "APPLICATION_SUMMARY":
      columns = [
        rentalReimbursementId,
        sequenceNumber,
        hongKongtaxYearOfAssessment,
        statusUpdateStatus,
        department,
        employeeCode,
        employeeName,
        dateJoinedGroup,
        dateJoinedCompany,
        cessationEmploymentDate,
        address,
        district,
        accommodationType,
        landlordNames,
        landlordRelationship,
        jointTenancyAgreementJointTenancyAgreementAgreementNotIncludeEmployeeName,
        tenantNames,
        spouseName,
        leaseStartDate,
        leaseEndDate,
        monthlyRental,
        actualSharePortionDeclarationByAmount,
        monthlyRentalReimbursementCap,
        acceptableMonthlyReimbursementAmount,
        reimbursementEffectiveDate,
        reimbursementEndDate,
        changeTypeCode,
        changeType,
        changeReasonCode,
        changeReason,
      ];
      break;
    case "claimSummary":
    case "CLAIM_SUMMARY":
      columns = [
        rentalReimbursementId,
        sequenceNumber,
        hongKongtaxYearOfAssessment,
        statusUpdateStatus,
        department,
        employeeCode,
        employeeName,
        dateJoinedGroup,
        dateJoinedCompany,
        cessationEmploymentDate,
        address,
        district,
        accommodationType,
        landlordNames,
        landlordRelationship,
        jointTenancyAgreementJointTenancyAgreementAgreementNotIncludeEmployeeName,
        tenantNames,
        spouseName,
        leaseStartDate,
        leaseEndDate,
        actualSharePortionDeclarationByAmount,
        monthlyRatesGovernmentRents,
        monthlyManagementFees,
        monthlyRentalReimbursementCap,
        acceptableMonthlyReimbursementAmount,
        reimbursementEffectiveDate,
        reimbursementEndDate,
        rentalAmount,
        ratesGovernmentRentsAndManagementFees,
        totalClaim,
        totalReimbursementAmount,
        totalApprovedAmount,
        aprStatus,
        aprCappedAmount,
        aprReimbursementAmount,
        aprClaim,
        aprApprovedAmount,
        aprAdjustedHousing,
        mayStatus,
        mayCappedAmount,
        mayReimbursementAmount,
        mayClaim,
        mayApprovedAmount,
        mayAdjustedHousing,
        junStatus,
        junCappedAmount,
        junReimbursementAmount,
        junClaim,
        junApprovedAmount,
        junAdjustedHousing,
        julStatus,
        julCappedAmount,
        julReimbursementAmount,
        julClaim,
        julApprovedAmount,
        julAdjustedHousing,
        augStatus,
        augCappedAmount,
        augReimbursementAmount,
        augClaim,
        augApprovedAmount,
        augAdjustedHousing,
        sepStatus,
        sepCappedAmount,
        sepReimbursementAmount,
        sepClaim,
        sepApprovedAmount,
        sepAdjustedHousing,
        octStatus,
        octCappedAmount,
        octReimbursementAmount,
        octClaim,
        octApprovedAmount,
        octAdjustedHousing,
        novStatus,
        novCappedAmount,
        novReimbursementAmount,
        novClaim,
        novApprovedAmount,
        novAdjustedHousing,
        decStatus,
        decCappedAmount,
        decReimbursementAmount,
        decClaim,
        decApprovedAmount,
        decAdjustedHousing,
        janStatus,
        janCappedAmount,
        janReimbursementAmount,
        janClaim,
        janApprovedAmount,
        janAdjustedHousing,
        febStatus,
        febCappedAmount,
        febReimbursementAmount,
        febClaim,
        febApprovedAmount,
        febAdjustedHousing,
        marStatus,
        marCappedAmount,
        marReimbursementAmount,
        marClaim,
        marApprovedAmount,
        marAdjustedHousing,
        changeTypeCode,
        changeType,
        changeReasonCode,
        changeReason,
        lastModifiedLastModifiedDate,
        employeeComment,
      ];
      break;
    case "applicationLog":
    case "APPLICATION_LOG":
      columns = [
        rentalReimbursementIdApplicationId,
        sequenceNumber,
        applicationSubmissionDate,
        taxYearOfAssessment,
        companyCompanyName,
        employeeCodeEmployeeID,
        employeeName,
        employeeEmail,
        businessUnit,
        department,
        dateJoinedCompanyHkLegalEntityJoinedDate,
        cessationEmploymentDateLastEmploymentDate,
        address,
        districtArea,
        accommodationTypeNature,
        landlordNames,
        landlordRelationshipRelationshipWithLandlord,
        relationship,
        jointTenancyAgreement,
        jointTenancyRelationship,
        leaseStartDate,
        leaseEndDate,
        monthlyRental,
        // actualSharePortionDeclarationByPercentage,
        actualSharePortionDeclarationByAmount,
        monthlyRentalReimbursementCap,
        acceptableMonthlyReimbursementAmountAcceptableMonthlyClaimAmount,
        reimbursementEffectiveDateReimbursementStartDate,
        reimbursementEndDate,
        status,
        // changeTypeCode,
        changeType,
        // changeReasonCode,
        changeReason,
        lastModifiedByLastUpdateBy,
        lastModifiedLastUpdateTimeStamp,
        // employeeCommentEmployeeComment,
        verifierComment,
      ];
      break;
    case "annualTax":
    case "ANNUAL_TAX":
      columns = [
        rentalReimbursementIdApplicationId,
        employeeCodeEmployeeID,
        employeeName,
        companyCompanyName,
        dateJoinedCompanyHkLegalEntityJoinedDate,
        cessationEmploymentDateLastEmploymentDate,
        addressQuarterAddress,
        accommodationTypeNature,
        leaseStartDate,
        leaseEndDate,
        monthlyRental,
        reimbursementEffectiveDateReimbursementEffectiveDateYYYYMMDD,
        reimbursementEndDateReimbursementEndDateYYYYMMDD,
        totalReimbursementAmountTotalReimbursementAmountPayroll,
        totalClaimRentPaidtoLandlordbyEmployee,
        totalApprovedAmountRentRefundedtoEmployeebyEmployer,
        adjustmentToHousingAllowanceSlashBasicSalary,
      ];
      break;
    case "claimDetail":
    case "CLAIM_DETAIL":
      columns = [
        rentalReimbursementIdApplicationId,
        sequenceNumber,
        taxYearOfAssessment,
        status,
        claimStatus,
        employeeCodeEmployeeID,
        employeeName,
        companyCompanyName,
        dateJoinedCompanyHkLegalEntityJoinedDate,
        cessationEmploymentDateLastEmploymentDate,
        address,
        district,
        accommodationTypeNature,
        leaseStartDate,
        leaseEndDate,
        monthlyRentalReimbursementCap,
        acceptableMonthlyReimbursementAmount,
        month,
        reimbursementEffectiveDate,
        reimbursementEndDate,
        totalReimbursementAmountTotalReimbursementAmountPayroll,
        totalClaimTotalActualRentalAmountRentPaidtoLandlordbyEmployee,
        totalApprovedAmountTotalApprovedClaimsRentRefundedtoEmployeebyEmployer,
        adjustmentToHousingAllowanceSlashBasicSalary,
        // aprStatus,
        // aprCappedAmount,
        // aprReimbursementAmount,
        // aprClaimAprilActualRentalAmount,
        // aprApprovedAmount,
        // aprAdjustedHousing,
        // mayStatus,
        // mayCappedAmount,
        // mayReimbursementAmount,
        // mayClaimMayActualRentalAmount,
        // mayApprovedAmount,
        // mayAdjustedHousing,
        // junStatus,
        // junCappedAmount,
        // junReimbursementAmount,
        // junClaimJuneActualRentalAmount,
        // junApprovedAmount,
        // junAdjustedHousing,
        // julStatus,
        // julCappedAmount,
        // julReimbursementAmount,
        // julClaimJulyActualRentalAmount,
        // julApprovedAmount,
        // julAdjustedHousing,
        // augStatus,
        // augCappedAmount,
        // augReimbursementAmount,
        // augClaimAugustActualRentalAmount,
        // augApprovedAmount,
        // augAdjustedHousing,
        // sepStatus,
        // sepCappedAmount,
        // sepReimbursementAmount,
        // sepClaimSeptemberActualRentalAmount,
        // sepApprovedAmount,
        // sepAdjustedHousing,
        // octStatus,
        // octCappedAmount,
        // octReimbursementAmount,
        // octClaimOctoberActualRentalAmount,
        // octApprovedAmount,
        // octAdjustedHousing,
        // novStatus,
        // novCappedAmount,
        // novReimbursementAmount,
        // novClaimNovemberActualRentalAmount,
        // novApprovedAmount,
        // novAdjustedHousing,
        // decStatus,
        // decCappedAmount,
        // decReimbursementAmount,
        // decClaimDecemberActualRentalAmount,
        // decApprovedAmount,
        // decAdjustedHousing,
        // janStatus,
        // janCappedAmount,
        // janReimbursementAmount,
        // janClaimJanuaryActualRentalAmount,
        // janApprovedAmount,
        // janAdjustedHousing,
        // febStatus,
        // febCappedAmount,
        // febReimbursementAmount,
        // febClaimFebruaryActualRentalAmount,
        // febApprovedAmount,
        // febAdjustedHousing,
        // marStatus,
        // marCappedAmount,
        // marReimbursementAmount,
        // marClaimMarchActualRentalAmount,
        // marApprovedAmount,
        // marAdjustedHousing,
        lastModifiedByLastUpdateBy,
        lastModifiedLastUpdateTimeStamp,
        comment,
      ];
      break;
    case "monthlyRental":
    case "MONTHLY_RENTAL":
      columns = [
        rentalReimbursementId,
        // sequenceNumber,
        // hongKongtaxYearOfAssessment,
        // statusUpdateStatus,
        // department,
        employeeCode,
        employeeName,
        companyNameCompanyName,
        // dateJoinedGroup,
        dateJoinedCompanyHkLegalEntityJoinedDate,
        lastWorkDateLastEmploymentDate,
        // cessationEmploymentDate,
        address,
        relationshipRelationwithLandlord,
        jointTenancyAgreement,
        // jointTenancyAgreementJointTenancyAgreementAgreementNotIncludeEmployeeName,
        leaseStartDate,
        leaseEndDate,
        monthlyRental,
        actualSharePortionDeclarationByAmountActualAmountofRentalSharedHKD,
        totalClaimClaimAmount,
        monthlyRentalReimbursementCap,
        acceptableMonthlyReimbursementAmount,
        reimbursementEffectiveDate,
        reimbursementEndDate,
        previousMonthsRetroactiveAdjReimbursement,
        currentMonthAmountofReimbursement,
        // totalReimbursementAmount,
        // aprCappedAmount,
        // aprReimbursementAmount,
        // mayCappedAmount,
        // mayReimbursementAmount,
        // junCappedAmount,
        // junReimbursementAmount,
        // julCappedAmount,
        // julReimbursementAmount,
        // augCappedAmount,
        // augReimbursementAmount,
        // sepCappedAmount,
        // sepReimbursementAmount,
        // octCappedAmount,
        // octReimbursementAmount,
        // novCappedAmount,
        // novReimbursementAmount,
        // decCappedAmount,
        // decReimbursementAmount,
        // janCappedAmount,
        // janReimbursementAmount,
        // febCappedAmount,
        // febReimbursementAmount,
        // marCappedAmount,
        // marReimbursementAmount,
        // changeTypeCode,
        // changeType,
        // changeReasonCode,
        // changeReason,
        lastModifiedLastModifiedDate,
        // comment,
      ];
      break;
    case "rentalAttachment":
    case "RENTAL_ATTACHMENT":
      columns = [
        uploadId,
        fileId,
        fileName,
        fileType,
        claimId,
        companyCode,
        companyNameCompanyName,
        employeeCode,
        employeeName,
        attachmentType,
        action,
        actionDate,
        actionBy,
        actionByName,
      ];
      break;
    case "rentalInterface":
    case "RENTAL_INTERFACE":
      columns = [
        reference,
        fileName,
        exportedBy,
        exportedAt,
        exportToExcel,
        postingStatus,
      ];
      break;
    default:
      break;
  }

  let tableColumnConfig = columns.reduce((a, c) => {
    a.push(columnConfig(c));
    return a;
  }, []);

  return tableColumnConfig;
};

export const RENTAL_REPORT_TYPE = {
  APPLICATION_SUMMARY: {
    KEY: "Application_Summary_Report",
    TITLE: "rental_report_applicationSummaryReport_title",
    LABEL: "rental_report_applicationSummaryReport",
    GET_FIELDS: applicationSummaryFilter,
    ROW_CLICK: true,
  },
  CLAIM_SUMMARY: {
    KEY: "Claim_Summary_Report",
    TITLE: "rental_report_claimSummaryReport_title",
    LABEL: "rental_report_claimSummaryReport",
    GET_FIELDS: claimSummaryFilter,
    ROW_CLICK: true,
  },
  APPLICATION_LOG: {
    KEY: "Application_Log_Report",
    TITLE: "rental_report_applicationLogReport_title",
    LABEL: "rental_report_applicationLogReport",
    GET_FIELDS: applicationLogFilter,
    ROW_CLICK: true,
  },
  ANNUAL_TAX: {
    KEY: "Annual_Tax_Report",
    TITLE: "rental_report_annualTaxReport_title",
    LABEL: "rental_report_annualTaxReport",
    GET_FIELDS: annualTaxFilter,
    ROW_CLICK: true,
  },
  CLAIM_DETAIL: {
    KEY: "Claim_Details_Report",
    TITLE: "rental_report_claimDetailReport_title",
    LABEL: "rental_report_claimDetailReport",
    GET_FIELDS: claimDetailFilter,
    ROW_CLICK: true,
  },
  MONTHLY_RENTAL: {
    KEY: "Monthly_Rental_Report",
    TITLE: "rental_report_monthlyRentalReport_title",
    LABEL: "rental_report_monthlyRentalReport",
    GET_FIELDS: monthlyRentalFilter,
    ROW_CLICK: true,
  },
  RENTAL_ATTACHMENT: {
    KEY: "Rental_Attachment_Report",
    TITLE: "rental_report_eRentalDownloadHistory_title",
    LABEL: "rental_report_eRentalDownloadHistory",
    GET_FIELDS: rentalAttachmentFilter,
    ROW_CLICK: false,
  },
  RENTAL_INTERFACE: {
    KEY: "Rental_Interface_File",
    TITLE: "rental_report_interfaceFile_title",
    LABEL: "rental_report_interfaceFile",
    GET_FIELDS: rentalInterfaceFilter,
    ROW_CLICK: false,
  },
};

const getReportTypeConfig = (t, type) => ({
  title: t(RENTAL_REPORT_TYPE[type].TITLE),
  reportType: {
    label: t(RENTAL_REPORT_TYPE[type].LABEL),
    value: t(RENTAL_REPORT_TYPE[type].LABEL),
  },
  filterFields: getFilterFieldConfig(t, RENTAL_REPORT_TYPE[type].GET_FIELDS(t)),
  columnConfig: getColumnConfig(t, type),
  rowClick: RENTAL_REPORT_TYPE[type].ROW_CLICK,
});

export const reportConfig = (t, reportList) => {
  if (reportList) {
    return reportList.reduce((a, r) => {
      if (Object.keys(RENTAL_REPORT_TYPE).includes(r)) {
        a.push(getReportTypeConfig(t, r));
      }
      return a;
    }, []);
  }

  return [];
};

export const reportConfigBkup = (t) => [
  // {
  //   title: t("rental_report_applicationSummaryReport_title"),
  //   reportType: {
  //     label: t("rental_report_applicationSummaryReport"),
  //     value: t("rental_report_applicationSummaryReport"),
  //   },
  //   filterFields: getFilterFieldConfig(t, applicationSummaryFilter(t)),
  //   columnConfig: getColumnConfig(t, "applicationSummary"),
  // },
  // {
  //   title: t("rental_report_claimSummaryReport_title"),
  //   reportType: {
  //     label: t("rental_report_claimSummaryReport"),
  //     value: t("rental_report_claimSummaryReport"),
  //   },
  //   filterFields: getFilterFieldConfig(t, claimSummaryFilter(t)),
  //   columnConfig: getColumnConfig(t, "claimSummary"),
  // },
  {
    title: t("rental_report_applicationLogReport_title"),
    reportType: {
      label: t("rental_report_applicationLogReport"),
      value: t("rental_report_applicationLogReport"),
    },
    filterFields: getFilterFieldConfig(t, applicationLogFilter(t)),
    columnConfig: getColumnConfig(t, "applicationLog"),
  },
  {
    title: t("rental_report_annualTaxReport_title"),
    reportType: {
      label: t("rental_report_annualTaxReport"),
      value: t("rental_report_annualTaxReport"),
    },
    filterFields: getFilterFieldConfig(t, annualTaxFilter(t)),
    columnConfig: getColumnConfig(t, "annualTax"),
  },
  {
    title: t("rental_report_claimDetailReport_title"),
    reportType: {
      label: t("rental_report_claimDetailReport"),
      value: t("rental_report_claimDetailReport"),
    },
    filterFields: getFilterFieldConfig(t, claimDetailFilter(t)),
    columnConfig: getColumnConfig(t, "claimDetail"),
  },
  {
    title: t("rental_report_monthlyRentalReport_title"),
    reportType: {
      label: t("rental_report_monthlyRentalReport"),
      value: t("rental_report_monthlyRentalReport"),
    },
    filterFields: getFilterFieldConfig(t, monthlyRentalFilter(t)),
    columnConfig: getColumnConfig(t, "monthlyRental"),
  },
];
