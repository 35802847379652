import { FindAndReplaceDialog } from "@progress/kendo-react-editor/dist/npm/dialogs/FindReplace";

const FindReplaceDialog = (props) => {
  let view;

  const onFindClose = () => props.setShowDialog(false);

  return (
    <>
      {props.showDialog && view && (
        <FindAndReplaceDialog view={view} onClose={onFindClose} />
      )}
    </>
  );
};

export default FindReplaceDialog;
