import CryptoJS from "crypto-js";

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

export function Ecrypt(message) {
  if (typeof message === "string") {
    return CryptoJS.AES.encrypt(message, SECRET_KEY);
  }
  return CryptoJS.AES.encrypt(JSON.stringify(message), SECRET_KEY);
}

export function Decrypt(message, isObj = false) {
  if (message !== null) {
    if (typeof message === "string" && !isObj) {
      return CryptoJS.AES.decrypt(message.toString(), SECRET_KEY).toString(
        CryptoJS.enc.Utf8
      );
    }
    try {
      var bytes = CryptoJS.AES.decrypt(message, SECRET_KEY);
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return decryptedData;
    } catch (error) {
      return;
    }
  }
}
