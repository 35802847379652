//direct from api
export const InitEmailConfigurationForm = {
  id: undefined,
  moduleName: "",
  templateName: undefined,
  subject: undefined,
  description: undefined,
  templateText: "",
  effectiveFrom: undefined,
  effectiveTo: undefined,
  lastUpdate: undefined,
  updatedBy: undefined,
  ccEmails: "",
  bccEmails: "",
  language: undefined,
};

export function EmailConfigurationApiSteps(t) {
  return {
    data: [
      {
        displaySequence: 1,
        id: 1,
        sections: [
          {
            displaySequence: 1,
            isEditable: true,
            sectionId: 100,
            sectionName: t("Email_Template_Configuration_Formname_Update"),
          },
        ],
      },
    ],
  };
}

export function EmailConfigurationInitForm(t) {
  return {
    data: {
      // formName: t("Email_Template_Update_Configuration_Formname"), //
      id: 1000,
      sections: [
        {
          defaultExpanded: true,
          displayLayout: 2,
          displaySequence: 1,
          id: 100,
          isDefaultHide: false,
          fields: [
            {
              // controlType: "AutoComplete",
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 1,
              fieldLabel: t("Email_Template_Configuration_ModuleName"), //"ModuleName,
              fieldName: "moduleName",
              id: 1,
              inputName: "moduleName",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: false,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "Email_Template_Configuration_ModuleName",
              viewSectionId: 100,
              config: {
                // forceSelection: true,
                dropdown: true,
              },
              // eventFunctionName: "onTemplateNameSelect",
            },
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 2,
              fieldGroup: 1,
              fieldLabel: t("Email_Template_Configuration_Language"), //"Language",
              fieldName: "language",
              id: 2,
              inputName: "language",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: false,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "Email_Template_Configuration_Language",
              viewSectionId: 200,
            },
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 3,
              fieldGroup: 2,
              fieldLabel: t("Email_Template_Configuration_TemplateName"), //"TemplateName",
              fieldName: "templateName",
              id: 3,
              inputName: "templateName",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: false,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "Email_Template_Configuration_TemplateName",
              viewSectionId: 300,
            },
            {
              controlType: "Toggle",
              dataType: "string",
              displaySequence: 4,
              fieldGroup: 2,
              fieldLabel: t("Email_Template_Configuration_IsEnabled"), //enable email
              fieldName: "isEnabled",
              id: 4,
              inputName: "isEnabled",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "Email_Template_Configuration_IsEnabled",
              viewSectionId: 400,
            },
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 5,
              fieldGroup: 3,
              fieldLabel: t("Email_Template_Configuration_Description"), //"Description",
              fieldName: "description",
              id: 5,
              inputName: "description",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-12 p-md-12 p-sm-12",
              translationKey: "Email_Template_Configuration_Description",
              viewSectionId: 500,
            },
            {
              controlType: "DatePicker",
              dataType: "string",
              displaySequence: 6,
              dropdownOptions: [],
              fieldGroup: 4,
              fieldLabel: t("Email_Template_Configuration_EffectiveFrom"), //"effectiveFrom",
              fieldName: "effectiveFrom",
              id: 6,
              inputName: "effectiveFrom",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: 0,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "Email_Template_Configuration_EffectiveFrom",
              viewSectionId: 600,
              config: {
                showButtonBar: true,
                clearButtonClassName: "display-none",
              },
            },
            {
              controlType: "DatePicker",
              dataType: "string",
              displaySequence: 7,
              dropdownOptions: [],
              fieldGroup: 4,
              fieldLabel: t("Email_Template_Configuration_EffectiveTo"), //"effectiveTo",
              fieldName: "effectiveTo",
              id: 7,
              inputName: "effectiveTo",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: 0,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "Email_Template_Configuration_EffectiveTo",
              viewSectionId: 700,
              config: {
                showButtonBar: true,
                todayButtonClassName: "display-none",
                minDate: new Date(),
              },
            },
            {
              controlType: "Chips",
              dataType: "string",
              displaySequence: 8,
              dropdownOptions: [],
              fieldGroup: 5,
              fieldLabel: t("Email_Template_Configuration_CcEmails"), //"CcEmails",
              fieldName: "ccEmails",
              id: 8,
              inputName: "ccEmails",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: 0,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "Email_Template_Configuration_CcEmails",
              viewSectionId: 800,
              config: {
                ruleList: [
                  {
                    name: "pattern",
                    param: "([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+.[a-zA-Z0-9_-]+)",
                    error: t("base_control_input_email_invalid"),
                  },
                ],
              },
            },
            {
              controlType: "Chips",
              dataType: "string",
              displaySequence: 9,
              fieldGroup: 5,
              fieldLabel: t("Email_Template_Configuration_BccEmails"), //"BccEmails",
              fieldName: "bccEmails",
              id: 9,
              inputName: "bccEmails",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "Email_Template_Configuration_BccEmails",
              viewSectionId: 900,
              config: {
                ruleList: [
                  {
                    name: "pattern",
                    param: "([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+.[a-zA-Z0-9_-]+)",
                    error: t("base_control_input_email_invalid"),
                  },
                ],
              },
              // dependentFields:
              //   '[{"Value":"undefined","Action":"Hide","Fields":"EffectiveTo, EffectiveFrom", "Sections":""},{"Value":true,"Action":"Show","Fields":"EffectiveTo, EffectiveFrom", "Sections":""},{"Value":false,"Action":"Hide","Fields":"EffectiveTo, EffectiveFrom", "Sections":""}]',
            },
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 10,
              fieldGroup: 6,
              fieldLabel: t("Email_Template_Configuration_Subject"), //"subject",
              fieldName: "subject",
              id: 10,
              inputName: "subject",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-12 p-md-12 p-sm-12",
              translationKey: "Email_Template_Configuration_Subject",
              viewSectionId: 1000,
            },
            {
              controlType: "KendoEditor",
              dataType: "string",
              displaySequence: 11,
              fieldGroup: 6,
              fieldLabel: t("Email_Template_Configuration_TemplateText"), //"TemplateText",
              fieldName: "templateText",
              id: 11,
              inputName: "templateText",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-12 p-md-12 p-sm-12",
              translationKey: "Email_Template_Configuration_TemplateText",
              viewSectionId: 1100,
              config: {
                // customInsertListSingle: {
                //   placeholder: "testing",
                //   data: [
                //     { name: 'Albania', value: 'Alb' },
                //     { name: 'Andorra', value: 'And' },
                //     { name: 'Armenia', value: 'Arm' },
                //     { name: 'Austria', value: 'Aus' },
                //     { name: 'Azerbaijan', value: 'Aze' },
                //   ]
                // },
                customInsertListMultiple: [
                  {
                    id: "email-configuration/template-fields",
                    placeholder: t(
                      "Email_Template_Configuration_TemplateField_Placeholder"
                    ),
                    data: [
                      { name: "Albania", value: "Alb" },
                      { name: "Andorra", value: "And" },
                      { name: "Armenia", value: "Arm" },
                      { name: "Austria", value: "Aus" },
                      { name: "Azerbaijan", value: "Aze" },
                    ],
                  },
                  // {
                  //     placeholder: "testing",
                  //     data: "email-configuration/template-fields"
                  // }
                ],
              },
            },
          ],
        },
      ],
    },
  };
}
