import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { scrollToErrorControl } from "../../../../components/base-form/utils";
import { showSpinner } from "../../../../redux/actions/spinner";
import { EmailConfigurationService } from "../../../../services/hrmnet-api";
import BaseMultiStepForm from "../../../../components/base-multi-step-form/base-multi-step-form";
import { BaseMultiStepFormSkeleton } from "../../../../components/base-multi-step-form/base-multi-step-form-skeleton";
import {
  BaseMultiStepConfigToFormModel,
  BaseMultiStepConfigToStepNavModel,
  BaseMultiStepCustomFunctionLibrary,
} from "../../../../components/base-multi-step-form/base-multi-step-form-utils";
import { BaseMultiStepFormScrollToMultiStepFormMenuActiveItem } from "../../../../components/base-multi-step-form/base-multi-step-form-scroll";
import {
  setFormLanguage,
  setMainForm,
  setMainFormConfig,
  setMainFormSectionConfig,
} from "../../../../redux/actions/form";
import {
  EmailConfigurationApiSteps,
  EmailConfigurationInitForm,
} from "./email-template-default-form";
import { showErrorMessageModal } from "../../../../services/utils/message";
import { getResponseMessage, isResponseOk } from "../../../../utils/utils";
import { useHistory } from "react-router-dom";
import { FUNCTION_CODE, MODULE_CODE } from "../../../../constants";
import { PortalLink } from "../../../../services/utils";
import { store } from "../../../../redux/store";
import { MessageSeverity } from "primereact/api";
import { GetCurrentActions } from "../../../layouts/components/main-sidebar/main-menu-tree";

const EmailTemplateForm = ({ location }) => {
  const { t } = useTranslation();
  const templateId = location?.state?.templateId;
  const dispatch = useDispatch();
  const history = useHistory();
  let toast = store.getState().global.toast;

  const [state, setState] = useState({
    isLoading: true,
    activeStep: 0,
    formResp: {},
    touched: false,
  });

  const actionsAccessRights = useSelector((state) => {
    const result = [];
    GetCurrentActions(state.menu.sideBar, result, 1, false);
    return result;
  });

  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );
  const formLanguage = useSelector((state) => state.commonForm.formLanguage);

  const form = useSelector((state) => state.commonForm.form);
  const selectedTemplateName = useSelector(
    (state) => state.commonForm.form?.TemplateName
  );
  const setForm = (x) => dispatch(setMainForm(x));
  const formConfig = useSelector((state) => state.commonForm.formConfig);
  const setFormConfig = (x) => dispatch(setMainFormConfig(x));
  const setFormSectionConfig = (x) => dispatch(setMainFormSectionConfig(x));

  const formObjRef = React.createRef();

  let customFunctionLibrary = BaseMultiStepCustomFunctionLibrary({
    dispatch,
  });

  useEffect(() => {
    const loadForm = async () => {
      const applicationId = selectedTemplateName;
      const isLangChange = formLanguage != selectedLangKey;
      dispatch(setFormLanguage(selectedLangKey));
      //enum api
      const templateFieldApi =
        EmailConfigurationService.emailConfigurationGetTemplateFields();

      const formApi =
        EmailConfigurationService.emailConfigurationGetEmailTemplate({
          id: templateId,
        });

      const [templateFieldEnum, currentForm] = await Promise.all([
        templateFieldApi,
        formApi,
      ]);

      let stepRes = EmailConfigurationApiSteps(t);
      let formConfigRes = EmailConfigurationInitForm(t);

      const templateTextFieldIndex =
        formConfigRes?.data?.sections[0]?.fields?.findIndex(
          (x) => x.fieldName === "templateText" //fieldname should same as objname
        );
      if (templateTextFieldIndex > -1) {
        const customInsertListMultipleForTemplateFieldIndex =
          formConfigRes?.data?.sections[0]?.fields[
            templateTextFieldIndex
          ].config?.customInsertListMultiple?.findIndex(
            (x) => x.id === "email-configuration/template-fields"
          );
        if (customInsertListMultipleForTemplateFieldIndex > -1) {
          formConfigRes.data.sections[0].fields[
            templateTextFieldIndex
          ].config.customInsertListMultiple[
            customInsertListMultipleForTemplateFieldIndex
          ].data = templateFieldEnum || [];
        }
      }

      let stepList = [];
      let newForm = {};
      let newFormResp = {};
      let newFormConfig = {};
      // let activeStep = 0;

      if (stepRes && stepRes.data && formConfigRes && formConfigRes.data) {
        //partial hardcore, find ways to completely detach from code

        let customData = {
          customFunctionLibrary: customFunctionLibrary,
        };
        stepList = BaseMultiStepConfigToStepNavModel(stepRes.data);

        // init form and active step
        if (
          !isLangChange ||
          (form &&
            Object.keys(form).length === 0 &&
            Object.getPrototypeOf(form) === Object.prototype)
        ) {
          newForm = currentForm?.data;
        } else {
          newForm = form;
          // activeStep = state.activeStep;
        }

        // newFormResp and formConfig
        newFormResp = formConfigRes.data;
        newFormConfig = BaseMultiStepConfigToFormModel({
          applicationId: applicationId,
          stepConfig: stepRes.data,
          sectionFieldConfig: formConfigRes.data.sections,
          customData: customData,
          t: t,
          form: newForm,
        });
      }

      setForm(newForm);
      setFormConfig(newFormConfig);
      setFormSectionConfig(newFormResp);

      setState({
        ...state,
        stepList,
        isLoading: false,
      });
    };

    loadForm();

    BaseMultiStepFormScrollToMultiStepFormMenuActiveItem(".form-title");

    return () => {
      setState({
        ...state,
        isLoading: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId]);

  // Navigation and action
  const validateCurrentStep = () => {
    const isValid = !formObjRef.current.getActiveFormState({ expand: true })
      .invalid;
    setTimeout(() => {
      scrollToErrorControl();
    }, 100);
    return isValid;
  };

  const confirmSubmitApplication = async () => {
    if (validateCurrentStep()) {
      submitApplicationAction();
    } else {
      setState({
        ...state,
        touched: true,
      });
    }
  };

  const submitApplicationAction = async () => {
    try {
      // show spinner
      dispatch(showSpinner(true));
      let res =
        await EmailConfigurationService.emailConfigurationUpdateEmailTemplate({
          body: form,
        });
      if (isResponseOk(res)) {
        toast.show({
          severity: MessageSeverity.SUCCESS,
          detail: t("admin_common_submitMessageSuccess"),
          life: 3000,
        });
      } else {
        showErrorMessageModal({
          dispatch,
          t,
          content: getResponseMessage(res),
        });
      }
    } catch (e) {
      // show notification?
      console.error(e.message);
    } finally {
      // end spinner
      dispatch(showSpinner(false));
    }
  };

  function onChange({ state, changed }) {
    let effectiveTo =
      typeof state?.form?.effectiveTo === "string"
        ? new Date(state?.form?.effectiveTo)
        : state?.form?.effectiveTo;
    if (
      !!state?.form?.effectiveTo &&
      state?.form?.effectiveTo !== "string" &&
      state?.form?.effectiveFrom > effectiveTo
    ) {
      setForm({ ...state?.form, effectiveTo: null });
      if (changed?.control?.key === "effectiveTo") {
        toast.show({
          severity: MessageSeverity.ERROR,
          detail: t("email_template_configuration_invalidEffectiveTo"),
          life: 3000,
        });
      }
    }
  }

  // Rendering
  const renderForm = () => (
    <BaseMultiStepForm
      form={form}
      activeStep={state.activeStep}
      config={formConfig}
      ref={formObjRef}
      touched={state.touched}
      onChange={onChange}
    />
  );

  const renderFooter = () => {
    return (
      <div className="footer p-grid p-align-center p-justify-between">
        <Button
          label={t("admin_common_actionCancel")}
          className="p-button-secondary"
          onClick={() =>
            history.push(
              PortalLink(
                `${MODULE_CODE.Admin}/${FUNCTION_CODE.EmailConfiguration}/${FUNCTION_CODE.EmailTemplate}`
              )
            )
          }
        />
        <div className="right-button-group">
          <Button
            onClick={() => confirmSubmitApplication()}
            className="p-button next-button"
          >
            {t("admin_common_actionSubmit")}
          </Button>
        </div>
      </div>
    );
  };

  // Rendering
  const renderFormTitle = () => (
    <div className="form-title">
      <h2>
        {actionsAccessRights.length > 0 ? actionsAccessRights[0]?.name : ""}
      </h2>
    </div>
  );

  return (
    <>
      <div className="admin-form-style email-configuration-Update">
        <div className="header header-sticky">
          {/* Title */}
          {renderFormTitle()}
        </div>
        <div className={`email-configuration-select-form`}>
          <div className=" email-configuration-ms-form  email-configuration">
            {state.isLoading ? (
              <div className="form-loading">{BaseMultiStepFormSkeleton}</div>
            ) : (
              renderForm()
            )}

            {/* Footer */}
            {renderFooter()}
          </div>
        </div>
      </div>
    </>
  );
};
export default EmailTemplateForm;
