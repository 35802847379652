import "./security-question.scss";

import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

//service
import { ForgotPasswordService } from "./../../../../../../../services/hrmnet-api";

//component
import { getControlModel } from "./../../../../../../../components/base-control/base-cotrol-model";
import BaseForm from "./../../../../../../../components/base-form/base-form";

//prime
import { Button } from "primereact/button";

//const
import { LoginLink } from "./../../../../../../../services/utils/index";

//utils
import { CancelBtn } from "./../../utils";

const SecurityQuestion = (props) => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    form: {},
  });
  const [status, setStatus] = useState({ status: false, submitted: false });
  const [, setSecurityQuestions] = useState(false);
  const [formConfig, setFormConfig] = useState();
  const [messages, setMessages] = useState([]);

  function onCancel() {
    setState({ form: {} });
    setStatus({ status: false, submitted: false });
    if (props.setIsValidated) {
      props.setIsValidated(false);
    }
  }

  useEffect(() => {
    let controls = [];
    let rows = [];
    const formatQuestions = (securityQuestions) => {
      securityQuestions.map((securityQuestion, index) => {
        const control = getControlModel({
          key: securityQuestion.id.toString(),
          label: t(securityQuestion.label),
          required: true,
          noRequiredLabel: true,
          // type: componentType(securityQuestion.label)
        });

        const row = {
          columns: [
            {
              control: securityQuestion.id.toString(),
            },
          ],
        };
        controls.push(control);
        rows.push(row);
        return null;
      });

      if (rows.length > 0) {
        const action = {
          config: {
            style: {
              marginBottom: "1rem",
              marginTop: "1rem",
            },
          },
          columns: [
            {
              action: {
                type: "submit",
                label: t("login_forgotPassword_next"),
                submitingLabel: t("login_submitting"),
                config: {
                  kind: "primary",
                  style: {
                    width: "100%",
                    maxWidth: "100%",
                    textAlign: "left",
                    minHeight: "3.5rem",
                  },
                },
              },
            },
            CancelBtn(t("login_back"), onCancel),
          ],
        };
        rows.push(action);
      }

      const _formConfig = {
        controls: controls,
        layout: { rows: rows },
        formSubmitFn,
        afterFormSubmitFn,
      };
      setFormConfig(_formConfig);
    };

    if (props.securityQuestions) {
      setSecurityQuestions(formatQuestions(props.securityQuestions));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  // functions -------------------------------------------------------------
  const afterFormSubmitFn = (payload) => {
    const { res, formState } = payload;
    if (res && res?.messages && res?.messages.length > 0) {
      setMessages(res.messages);
    }

    setStatus({
      ...status,
      status: res?.data?.status ?? false,
      submitted: true,
    });
    return res?.data?.status ?? false;
  };

  const formSubmitFn = (payload) => {
    const { form } = payload;

    const _forgotPasswordFields = props.securityQuestions.map(
      (securityQuestion) => {
        const formResult = form[securityQuestion.id];
        let _formResult;
        if (formResult !== undefined) {
          _formResult = {
            id: securityQuestion.id,
            name: securityQuestion.name,
            value: form[securityQuestion.id],
          };
        }
        return _formResult;
      }
    );

    if (form && !!_forgotPasswordFields && _forgotPasswordFields.length > 0) {
      return ForgotPasswordService.forgotPasswordForgotPasswordSubmission(
        {
          body: {
            ...props.userNameEmail,
            forgotPasswordFields: _forgotPasswordFields,
          },
        },
        {
          suppressErrorMsg: true,
        }
      );
    }
    return;
  };

  const cancelButton = () => {
    return (
      <Button
        type="cancel"
        label={t("login_back")}
        disabled={state.loading}
        style={{
          width: "50%",
          maxWidth: "100%",
          textAlign: "left",
          minHeight: "3.5rem",
          marginBottom: "1rem",
          marginTop: "1rem",
        }}
        onClick={() => onCancel()}
      />
    );
  };

  const renderMessages = () => {
    return messages.map((message, i) => {
      return <p key={i}>{message.text}</p>;
    });
  };

  const renderMessageContent = () => {
    if (!status.submitted) {
      return null;
    }

    return (
      <>
        {/* success */}
        {!!status?.status && (
          <h1>{t("login_forgotPassword_checkYourEmail")}</h1>
        )}
        {/* error */}
        {!status?.status && <h1>{t("login_forgotPassword_error")}</h1>}

        {messages.length > 0 && renderMessages()}

        {/* error */}
        {!!status?.status && (
          <Link
            to={{
              pathname: LoginLink(),
              activePanel: 1,
            }}
          >
            <Button
              type="submit"
              label={t("login_login")}
              style={{
                width: "50%",
                maxWidth: "100%",
                textAlign: "left",
                minHeight: "3.5rem",
                marginBottom: "1rem",
                marginTop: "1rem",
              }}
            ></Button>
          </Link>
        )}
        {/* success */}
        {!status?.status && cancelButton()}
      </>
    );
  };

  const renderContent = () => {
    return (
      <>
        <h1>{t("login_forgotPassword")}</h1>
        <p>{t("login_forgotPassword_description")}</p>
        <BaseForm config={formConfig} form={state.form} />
      </>
    );
  };

  return (
    <>
      {props.securityQuestions?.length > 0 && !status.submitted
        ? renderContent()
        : renderMessageContent()}
    </>
  );
};

export default SecurityQuestion;
