import { AuthenticationService } from "./hrmnet-api";
import axios from "axios";
import i18n from "../i18n";
import qs from "qs";
import { MessageSwitch } from "./utils/message";
import {
  LOGOUT_PATH,
  LOGIN_PATH,
  HEADER_KEY,
  RESPONSE_MESSAGE_ERROR,
  RESPONSE_MESSAGE_TYPE,
} from "./../constants";

import { ReloadTenantEntityInfo } from "./../portal/modules/public/login/components/utils";
import { setAuthInfo, setTokenExpired } from "../redux/actions/auth";

let subscribers = [];
const refreshAccessToken = async (
  axiosApiInstance,
  originalRequest,
  history,
  store
) => {
  const keys = store.getState().auth;

  if (!keys || !keys.refreshToken) {
    throw {
      messages: [
        {
          text: i18n.t("Refresh_Token_Null_Error_Message"),
          type: RESPONSE_MESSAGE_TYPE.ERROR,
        },
      ],
    };
  }

  if (!store.getState().auth?.isExpired) {
    store.dispatch(setTokenExpired());
    var res = await AuthenticationService.authenticationRefreshToken(
      {
        body: {
          token: keys?.token,
          refreshToken: keys?.refreshToken,
        },
      },
      {
        suppressErrorMsg: true,
      }
    );

    if (res && res.data) {
      // 1) put token to LocalStorage
      //await StorageSerive.setToken(res.data);
      let newToken = { ...keys };
      newToken.token = res.data.token;
      newToken.refreshToken = res.data.refreshToken;
      store.dispatch(setAuthInfo(newToken));
      // console.log("--last-refreshed---", axiosApiInstance.lastRefresh); --temporary
      // const new_keys = await StorageSerive.getToken(); --temporary
      // console.log('---refreshed-token--', new_keys);
      // 2) Change Authorization header - not required - request interceptor will check the token from localStorage
      // axios.defaults.headers.common["Authorization"] = "Bearer " + new_keys.token;

      // 3) return originalRequest object with Axios.
      // console.log('----res && res.data--', originalRequest); --temporary
      return newToken.token; //
    }

    throw res;
  }
  return keys?.token;
};

//
const gotoLogin = (history, store) => {
  // Use window.location.href to perform logout operation correctly and refresh page
  // When token expired
  window.location.href = `/${LOGOUT_PATH.LOGOUT}`;
};
const gotoPage = async (history, page) => {
  history.push({ pathname: `/${page}` });
};

function handlerAPIError(error, store, history) {
  let toast = store.getState().global.toast;
  let alert = store.getState().global.alert;

  if (error.message === RESPONSE_MESSAGE_ERROR.CANCEL) {
    return Promise.reject(error);
  }

  if (!error || !error.response || !error.response.status) {
    MessageSwitch(error, toast, null, i18n);
    // toast.show({
    //   severity: "error",
    //   summary: i18n.t("misc_axios_notification"),
    //   detail: i18n.t("misc_axios_network_error"),
    //   life: 3000,
    // });
    return error;
  }
  // console.log("--status--", error.response.status, error.response);
  switch (error.response.status) {
    case 500:
      // gotoPage(history, "COM/COM0101/500");
      MessageSwitch(error.response, toast, null, i18n);
      break;
    case 404: {
      // gotoPage(history, "COM/COM0101/404");
      MessageSwitch(error.response, toast, null, i18n);
      return Promise.reject(error); // trigger exception
    }
    case 403:
      // gotoPage(history, "COM/COM0101/403");
      if (toast) {
        const toastObj = {
          severity: "error",
          summary: i18n.t("misc_axios_forbidden"),
          detail: i18n.t("misc_axios_permission_denied"),
          life: 5000,
        };
        if (
          error.response &&
          !!error.response?.data &&
          !!error.response?.data?.Message
        ) {
          toastObj.detail = error.response?.data?.Message;
          if (i18n.exists(error.response?.data?.Message)) {
            toastObj.detail = i18n.t(error.response?.data?.Message);
          }
        }
        toast.show(toastObj);
      }
      return Promise.reject(error); // trigger exception
    // break;
    case 401: {
      MessageSwitch(error.response, toast, alert, i18n);
      gotoLogin(history);
      break;
    }
    case 400: {
      MessageSwitch(error.response, toast, alert, i18n);
      return Promise.reject(error); // trigger exception
    }
    case 409: {
      MessageSwitch(error.response, toast, alert, i18n);
      return Promise.reject(error); // trigger exception
    }
    case 422: {
      MessageSwitch(error.response, toast, alert, i18n);
      return Promise.reject(error); // trigger exception
    }
    default: {
      const res = error.response;
      if (res && res.messages && res.messages.length > 0) {
        MessageSwitch(res, toast, alert, i18n);
        return Promise.reject(error); // trigger exception
      }
      break;
    }
  }

  return error.response;
}

function handlerMessage(response, store, history) {
  // If suppressErrorMsg is true then return
  if (response?.config?.suppressErrorMsg) {
    return;
  }
  let res = response?.data;
  let toast = store.getState().global.toast;
  let alert = store.getState().global.alert;
  if (res && res.messages && res.messages.length > 0) {
    MessageSwitch(res, toast, alert, i18n);
  }

  if (res && res.redirectUrl) {
    gotoPage(history, res.redirectUrl);
  }
}
export function createAxiosApiInstance(baseURL, store, history) {
  const instance = axios.create({
    baseURL: baseURL,
    timeout: window?.REACT_APP_TIMEOUT ?? 480000,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
  // Request interceptor for API calls
  //instance.inRefresh = false;
  instance.interceptors.request.use(
    async (config) => {
      // get key
      let keys = store.getState().auth;

      // language
      const language =
        store.getState().language?.language?.selectedLang?.key || "en-HK";

      const pathWithTenantEntityName = [
        LOGIN_PATH.LOGIN,
        LOGIN_PATH.FORGOT_PWD,
        LOGIN_PATH.FST_LOGIN,
        LOGIN_PATH.PWD_EXP,
        LOGIN_PATH.RST_PWD,
      ];
      const isPreLoginPages = pathWithTenantEntityName.includes(
        window.location.pathname.split("/")[1]
      );

      const excludeAuthorizationHeader = () => {
        if (
          keys?.token &&
          !config.url.endsWith("refresh-token") &&
          !config.url.endsWith("company-policy") &&
          !config.url.endsWith("privacy-policy") &&
          !config.url.endsWith("terms-and-conditions") &&
          !config.url.endsWith("assistance") &&
          !config.url.endsWith("faq") &&
          !config.url.endsWith("getbycontexts") &&
          !config.url.endsWith("getbykeys") &&
          !config.url.endsWith("getbykey") &&
          !config.url.endsWith("content") &&
          !config.url.endsWith("system-notification") &&
          !(isPreLoginPages && config.url.startsWith("/configuration")) &&
          !(isPreLoginPages && config.url.startsWith("/authentication"))
        ) {
          config.headers["Authorization"] = `Bearer ${keys?.token}`;
        }
      };
      excludeAuthorizationHeader();
      // language
      config.headers["Accept-Language"] = language;

      // module
      let moduleIds = [];

      if (
        !!store.getState().sidebar?.data &&
        Array.isArray(store.getState().sidebar?.data)
      ) {
        moduleIds = Array.from(store.getState().sidebar?.data, (x) => x?.key);
      }

      //check whether current url entered during pre-authorized page is same as in storage
      const clientCode = isPreLoginPages
        ? window.location.pathname.split("/")[2]
        : store.getState().global?.clientInfo?.tenantCode;
      let entityCode = undefined;
      entityCode = isPreLoginPages
        ? window.location.pathname.split("/")[3]
        : store.getState().global?.clientInfo?.entityCode;

      // if different, retrieve tenant-entity infos
      if (isPreLoginPages) {
        await ReloadTenantEntityInfo(config.url);
      }

      if (!config.headers[HEADER_KEY.HRMNET_ENTITY_NAME]) {
        config.headers[HEADER_KEY.HRMNET_ENTITY_NAME] = entityCode;
      }
      if (!config.headers[HEADER_KEY.HRMNET_TENANT_NAME]) {
        config.headers[HEADER_KEY.HRMNET_TENANT_NAME] = clientCode;
      }

      if (!config.headers[HEADER_KEY.HRMNET_ENTITY_NAME]) {
        delete config.headers[HEADER_KEY.HRMNET_ENTITY_NAME];
      }

      if (!config.headers[HEADER_KEY.HRMNET_TENANT_NAME]) {
        delete config.headers[HEADER_KEY.HRMNET_TENANT_NAME];
      }

      if (!!moduleIds && moduleIds.length > 0) {
        config.headers[HEADER_KEY.MODULES] = moduleIds[moduleIds.length - 1];
      }

      const excludeTenantEntityHeader = () => {
        if (config.url.endsWith("system-notification")) {
          delete config.headers[HEADER_KEY.HRMNET_ENTITY_NAME];
          delete config.headers[HEADER_KEY.HRMNET_TENANT_NAME];
        }
      };

      excludeTenantEntityHeader();

      // info that are passed from request params
      // override stored information
      const passedToken = config.token;
      const passedTenantCode = config.tenantCode;
      const passedEntityCode = config.entityCode;

      if (!!passedToken) {
        config.headers["Authorization"] = `Bearer ${passedToken}`;
      }

      if (!!passedTenantCode) {
        config.headers[HEADER_KEY.HRMNET_TENANT_NAME] = passedTenantCode;
      }

      if (!!passedEntityCode) {
        config.headers[HEADER_KEY.HRMNET_ENTITY_NAME] = passedEntityCode;
      }

      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
  // Response interceptor for API calls
  instance.interceptors.response.use(
    (response) => {
      // tweak to get the response object
      if (response.config.callback) {
        response.config.callback(response);
      }

      handlerMessage(response, store, history);
      return response;
    },
    async function (error) {
      const originalRequest = error.config;
      if (error.response?.config?.suppressErrorMsg) {
        return Promise.reject(error);
      }

      if (
        !error.response?.data?.Message &&
        !error.response?.data?.TraceId &&
        (store.getState().auth?.isExpired ||
          (error && error.response && error.response.status === 401))
      ) {
        const retryOriginalRequest = new Promise((resolve) => {
          addSubscriber((token) => {
            if (!!originalRequest?.headers?.Authorization) {
              originalRequest.headers.Authorization = "Bearer " + token;
            }
            return resolve(instance(originalRequest));
          });
        });

        if (!store.getState().auth?.isExpired) {
          await refreshAccessToken(instance, originalRequest, history, store)
            .then((access_token) => {
              onAccessTokenFetched(access_token);
            })
            .catch((error) => {
              store.dispatch(setTokenExpired(false));
              handlerAPIError(error, store, history);
              gotoLogin(history, store);
              return Promise.resolve({
                data: null,
              });
            })
            .finally(() => {
              store.dispatch(setTokenExpired(false));
              subscribers = [];
            });
        }

        return retryOriginalRequest;
      }
      return handlerAPIError(error, store, history);
    }
  );
  return instance;
}

function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter((callback) => callback(access_token));
}

function addSubscriber(callback) {
  subscribers.push(callback);
}
//#endregion
