import "./list.scss";

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";

import RentalApplicationList from "../../components/rental-application-list";
import { ActionButton, RentalMenu } from "../../components";
// import { ActionButton, FilterDropdown } from "../../components";
import {
  resetBcDynamicPaths,
  setBcDynamicPaths,
} from "../../../../../redux/actions/breadcrumb";
import {
  EEPORTAL_LABEL_BASE_TABLE,
  FUNCTION_CODE,
  RENTAL_SUBMODULE_CODE,
} from "../../../../../constants";
import {
  CONTROLLED_MODULE,
  USER_ROLES,
} from "./../../../../../constants/permissions";
import { getUserRole } from "../../../../../utils/permissions";
import { checkAccess } from "./../../../../../utils/permissions";
import LanguageLoader from "../../../../../components/language-loader/language-loader";
import {
  RENTAL_T_CONTEXT_KEY,
  RENTAL_MENU_MODULE_KEY,
  RENTAL_MENU_TYPE_KEY,
  RENTAL_MENU_PAGE_KEY,
} from "../../constants";
import { PortalLink } from "../../../../../services/utils";

const ListRentalApplication = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();
  const menus = useSelector((state) => state.menu.sideBar);
  const rentalMenu = menus.find(
    (x) => x.key === RENTAL_MENU_MODULE_KEY
  )?.subMenus;

  // Breadcrumb
  useEffect(() => {
    dispatch(resetBcDynamicPaths());
  }, [dispatch, t]);

  useEffect(() => {
    // scroll to section
    let element = document.querySelector(".rental-menu");
    element.scrollIntoView({ behavior: "smooth", block: "center" });
  }, []);

  const toCreateApp = () => {
    history.push(
      PortalLink(
        `${FUNCTION_CODE.Rental}/${RENTAL_SUBMODULE_CODE.CREATE_APPLICATION}`
      )
    );
  };

  // render
  const renderAction = () => {
    if (!menus?.length) return null;

    let rentalMenu = menus
      .find((x) => x.key === RENTAL_MENU_MODULE_KEY)
      ?.subMenus?.filter(
        (s) =>
          s.type === RENTAL_MENU_TYPE_KEY.MENU &&
          s.key !== RENTAL_MENU_PAGE_KEY.RENTAL_APPLICATIONS
      );

    if (
      rentalMenu?.length === 1 &&
      rentalMenu?.[0].url ===
        `${FUNCTION_CODE.Rental}/${RENTAL_SUBMODULE_CODE.CREATE_APPLICATION}`
    ) {
      return (
        <ActionButton
          onClick={toCreateApp}
          className="new-app-btn"
          // style={{ marginLeft: "1rem" }}
        >
          {t("rental_action_addNewApplication")}
        </ActionButton>
      );
    } else {
      return (
        <Dropdown
          placeholder={t("rental_common_pleaseSelect")}
          options={rentalMenu}
          optionLabel="name"
          optionValue="url"
          onChange={(e) => {
            history.push(e.value);
          }}
        />
      );
    }
  };

  return (
    <>
      <div className="rental-allowance">
        <LanguageLoader
          contexts={[
            EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
            RENTAL_T_CONTEXT_KEY.COMMON,
            RENTAL_T_CONTEXT_KEY.APPROVAL, // For approval claim table headers
          ]}
        />
        <div className="p-col-12 p-justify-between rental-header">
          <RentalMenu
            menu={rentalMenu}
            currentPageKey={RENTAL_MENU_PAGE_KEY.RENTAL_APPLICATIONS}
          />
          <div className="title-group">
            <h2>{t("rental_pagetitle_HrApproval")}</h2>
          </div>
          <div className="info"></div>
          <div className="action-group">{renderAction()}</div>
        </div>
        <RentalApplicationList />
      </div>
    </>
  );
};

export default ListRentalApplication;
