import { Skeleton } from "primereact/skeleton";

export const formSkeleton = (
  <div style={{ background: "#fff", width: "100%", padding: "1rem" }}>
    <div className="p-grid p-my-3">
      <div className="p-col-4">
        <Skeleton height="40px"></Skeleton>
      </div>
      <div className="p-col-4">
        <Skeleton height="40px"></Skeleton>
      </div>
      <div className="p-col-4">
        <Skeleton height="40px"></Skeleton>
      </div>
    </div>
    <div className="p-grid p-my-3">
      <div className="p-col-4">
        <Skeleton height="40px"></Skeleton>
      </div>
      <div className="p-col-4">
        <Skeleton height="40px"></Skeleton>
      </div>
    </div>
    <div className="p-grid p-my-3">
      <div className="p-col-4">
        <Skeleton height="40px"></Skeleton>
      </div>
      <div className="p-col-4">
        <Skeleton height="40px"></Skeleton>
      </div>
    </div>
  </div>
);

export const twoColformSkeleton = (
  <div style={{ background: "#fff", width: "100%", padding: "1rem" }}>
    <div className="p-grid p-my-3">
      <div className="p-col-6">
        <Skeleton height="40px"></Skeleton>
      </div>
      <div className="p-col-6">
        <Skeleton height="40px"></Skeleton>
      </div>
    </div>
    <div className="p-grid p-my-3">
      <div className="p-col-6">
        <Skeleton height="40px"></Skeleton>
      </div>
      <div className="p-col-6">
        <Skeleton height="40px"></Skeleton>
      </div>
    </div>
    <div className="p-grid p-my-3">
      <div className="p-col-6">
        <Skeleton height="40px"></Skeleton>
      </div>
    </div>
  </div>
);

export const calendarViewSkeleton = (
  <div
    style={{
      background: "#fff",
      width: "100%",
      padding: "1rem",
      minHeight: "70vh",
    }}
  >
    <div className="p-grid">
      <div
        className="p-col-12 p-sm-12 p-md-6 p-lg-6"
        style={{ padding: "3rem" }}
      >
        <Skeleton className="p-mb-5" height="300px"></Skeleton>
        <Skeleton className="p-mb-3" height="40px"></Skeleton>
        <Skeleton className="p-mb-5" height="40px"></Skeleton>
        <Skeleton className="p-mb-3" height="40px"></Skeleton>
        <Skeleton className="p-mb-5" height="40px"></Skeleton>
      </div>
      <div
        className="p-col-12 p-sm-12 p-md-6 p-lg-6"
        style={{ padding: "3rem" }}
      >
        <Skeleton className="p-mb-5" height="40px"></Skeleton>
        <Skeleton className="p-mb-5" height="40px"></Skeleton>
        <Skeleton className="p-mb-5" height="40px"></Skeleton>
        <Skeleton className="p-mb-5" height="40px"></Skeleton>
        <Skeleton className="p-mb-5" height="40px"></Skeleton>
        <Skeleton className="p-mb-5" height="40px"></Skeleton>
        <Skeleton className="p-mb-5" height="40px"></Skeleton>
        <Skeleton className="p-mb-5" height="40px"></Skeleton>
      </div>
    </div>
  </div>
);
