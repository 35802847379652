import { CreateApplication } from "..";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setBcDynamicPaths } from "../../../../../redux/actions/breadcrumb";

const EditRentalApplication = (props) => {
  return (
    <CreateApplication editApplicationId={props.match.params.applicationId} HREdit={props.location?.state?.HREdit} />
  );
};

export default EditRentalApplication;
