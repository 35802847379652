import "./multi-step-form.scss";
import React, { useImperativeHandle, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getControlModel } from "../../../../components/base-control/base-cotrol-model";
import BaseForm from "../../../../components/base-form/base-form";
import SectionBaseForm from "../../../../components/base-form/section-base-form";
import { Button } from "primereact/button";
import { Animated } from "react-animated-css";

export const BFType = {
  BASE_FORM: "BASE_FORM",
  SECTION_FORM: "SECTION_FORM",
};

export const DISPLAY_LAYOUT_TYPE = {
  HORIZONTAL: 1,
  GRID: 2,
  SECTION_TABLE: 3,
};

export const DISPLAY_LAYOUT_TYPE_CLASSNAME = {
  [DISPLAY_LAYOUT_TYPE.HORIZONTAL]: "horizontal",
  [DISPLAY_LAYOUT_TYPE.GRID]: "grid",
  [DISPLAY_LAYOUT_TYPE.SECTION_TABLE]: "section-table",
};

const DEFAULT_CONFIG = {};

const MultiStepForm = React.forwardRef(
  (
    { config, activeStep, sectionFilter, form, touched, onChange, ...props },
    ref
  ) => {
    const { t } = useTranslation();
    let forms = [];
    let formRefs = [];
    let _config = {
      ...DEFAULT_CONFIG,
      ...config,
    };

    const [collapsed, setCollapsed] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const expandableCheck = () => {
        const sectionCollapsedList = [];
        _config?.steps?.forEach((step) => {
          const collapsedList = [];
          step.sections.forEach((section) => {
            collapsedList.push(
              section?.defaultExpanded !== null
                ? !section.defaultExpanded
                : null
            );
          });
          sectionCollapsedList.push(collapsedList);
        });

        setCollapsed(sectionCollapsedList);
        setLoading(false);
      };
      expandableCheck();
    }, []);

    useImperativeHandle(ref, () => ({
      getActiveFormState({ expand } = {}) {
        const _collapsed = JSON.parse(JSON.stringify(collapsed));
        const formState = formRefs.reduce(
          (acc, cur, idx) => {
            let curFormState = cur.current.getFormState();
            if (curFormState.invalid) {
              _collapsed[activeStep][idx] = false;
            }
            return {
              ...acc,
              ...curFormState,
              invalid: acc.invalid || curFormState.invalid,
            };
          },
          { invalid: false }
        );
        if (expand) {
          setCollapsed(_collapsed);
        }
        return formState;
      },
    }));

    _config?.steps?.[activeStep]?.sections.forEach((section, sectionIdx) => {
      let layout = {};
      const controls = [];

      let formType = BFType.BASE_FORM;
      const displayLayout = section.displayLayout;
      switch (displayLayout) {
        case DISPLAY_LAYOUT_TYPE.SECTION_TABLE:
          formType = BFType.SECTION_FORM;
          break;
        default:
          break;
      }

      section.controls.forEach((control) => {
        controls.push(getControlModel(control));
      });

      // construct field group
      // { [key: number | null]: string[] }
      let groupMap = {};
      section.controls.forEach((ctrl) => {
        const group = ctrl.fieldGroup != null ? ctrl.fieldGroup : null;
        if (!(group in groupMap)) {
          groupMap[group] = [ctrl];
        } else {
          groupMap[group].push(ctrl);
        }
      });

      if (formType === BFType.BASE_FORM) {
        // layout = { rows: [{ columns: [] }] };
        layout = { rows: [] };
        Object.keys(groupMap)
          .sort()
          .forEach((group) => {
            let columns = [];
            groupMap[group].forEach((control) => {
              let col = {
                control: control.key,
                config: control.layoutConfig
                  ? { ...control.layoutConfig }
                  : undefined,
              };
              columns.push(col);
            });
            layout.rows.push({ columns: columns });
          });
      }

      if (formType === BFType.SECTION_FORM) {
        let layoutSections = [];
        section.controls
          .filter((c) => c.type === "checkbox" && c.dependentFields)
          .forEach((sf) => {
            let layoutSectionRows = [];
            const group = sf.fieldGroup ? sf.fieldGroup : null;
            groupMap[group]
              .filter((control) => sf.key !== control.key)
              .forEach((childControl) => {
                layoutSectionRows.push({
                  columns: [
                    {
                      control: childControl.key,
                      config: childControl.layoutConfig
                        ? { ...childControl.layoutConfig }
                        : undefined,
                    },
                  ],
                });
              });

            layoutSections.push({
              sectionTitle: {
                control: sf.key,
              },
              rows: layoutSectionRows,
            });
          });

        const customLayout = {
          rows: [],
          desc: {
            control: section.controls[0].key,
          },
          sections: layoutSections,
        };

        layout = customLayout;
      }

      formRefs.push(React.createRef());

      let _sectionConfig = section.config;
      if (!_sectionConfig) {
        _sectionConfig = { className: "" };
      }
      if (sectionFilter && sectionFilter !== section.sectionId) {
        _sectionConfig = {
          ..._sectionConfig,
          hidden: true,
        };
      }
      if (displayLayout && DISPLAY_LAYOUT_TYPE_CLASSNAME[displayLayout]) {
        _sectionConfig = {
          ..._sectionConfig,
          className: _sectionConfig.className
            ? `${_sectionConfig.className} ${DISPLAY_LAYOUT_TYPE_CLASSNAME[displayLayout]}`
            : DISPLAY_LAYOUT_TYPE_CLASSNAME[displayLayout],
        };
      }
      const formContent = (
        <div>
          {formType === BFType.BASE_FORM && (
            <BaseForm
              id={`multi-step-form-${activeStep}-${sectionIdx}`}
              config={{
                controls,
                layout,
              }}
              form={form}
              ref={formRefs[sectionIdx]}
              touched={touched}
              readOnly={section.readOnly}
              onChange={onChange}
            />
          )}
          {formType === BFType.SECTION_FORM && (
            <SectionBaseForm
              id={`multi-step-form-${activeStep}-${sectionIdx}`}
              config={{
                controls,
                layout,
              }}
              form={form}
              originalForm={_config?.originalForm}
              ref={formRefs[sectionIdx]}
              touched={touched}
              readOnly={section.readOnly}
              onChange={onChange}
            />
          )}
        </div>
      );

      let expandable = false;
      let sectionCollapsed = false;
      if (!loading) {
        expandable = collapsed[activeStep][sectionIdx] !== null;
        sectionCollapsed =
          collapsed[activeStep][sectionIdx] !== null
            ? collapsed[activeStep][sectionIdx]
            : false;
      }

      const onTogglerClick = () => {
        const sectionCollapsedList = [...collapsed];
        sectionCollapsedList[activeStep][sectionIdx] =
          !sectionCollapsedList[activeStep][sectionIdx];
        setCollapsed(sectionCollapsedList);
      };

      const { className, ...config } = _sectionConfig;

      forms.push(
        <div
          className={`multi-step-form-group ${className ? className : ""}`}
          key={`multi-step-form-${activeStep}-${sectionIdx}`}
          {...config}
        >
          {expandable ? (
            <div className="multi-step-form-panel">
              <div className="panel-header">
                <div className="title">{section.title}</div>
                <Button
                  className="multi-step-form-show-more-btn"
                  label={
                    sectionCollapsed
                      ? t("rental_action_showMore")
                      : t("rental_action_showLess")
                  }
                  icon={
                    sectionCollapsed ? "pi pi-chevron-down" : "pi pi-chevron-up"
                  }
                  iconPos="left"
                  onClick={onTogglerClick}
                />
              </div>
              <div
                className={`panel-content ${
                  sectionCollapsed ? "panel-content-collapsed" : ""
                }`}
              >
                <div className="p-pt-4">{formContent}</div>
              </div>
            </div>
          ) : (
            <div>
              <div className="title p-mb-4">{section.title}</div>
              <div>{formContent}</div>
            </div>
          )}
        </div>
      );
    });

    return (
      <Animated
        animationIn="slideInRight"
        animationOut="slideOutRight"
        animationInDuration={200}
        animationOutDuration={200}
        isVisible={true}
      >
        <div className="multi-step-form">{forms}</div>
      </Animated>
    );
  }
);

export default MultiStepForm;
