// Components
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import CountdownTimer from "../countdown-timer/countdown-timer";

// Redux
import { openModal } from "../../../../redux/actions/modal";
import { setCountdownTimer } from "../../../../redux/actions/countdown-timer";
import { LOGOUT_PATH } from "../../../../constants";

const IdleTimer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector((state) => state.auth);
  const clientInfo = useSelector((state) => state.global?.clientInfo);
  const countdownTimerContent = useSelector((state) => state.countdownTimer);

  // ---------------------- Start of idle tracking ------------------------------------------------------
  const convertToMilliseconds = (time) => {
    // Milliseconds = time * 60 * 1000
    return time * 60 * 1000;
  };

  const convertToDateTimeCd = (time) => {
    // convertToDateTimeCd = new Date().getTime() + convertToMilliseconds(time);
    return new Date().getTime() + convertToMilliseconds(time);
  };

  const convertToSeconds = (time) => {
    // Seconds = time * 60
    return time * 60;
  };

  // const userSetIdleTimeout = 1000 * 10; // For testing - 10 seconds
  // Get user autoSignoffIdleTime and convert to milliseconds
  const userSetIdleTimeout = convertToMilliseconds(
    clientInfo?.autoSignoffIdleTime
  );

  // const timeBeforeAutoSignOff = 1000 * 5; // For testing - 5 seconds
  // Get user autoSignoffAlertCountDown and convert to milliseconds
  const timeBeforeAutoSignOff = convertToMilliseconds(
    clientInfo?.autoSignoffAlertCountDown
  );

  // Idle timeout calculation = user auto sign off idle time - user auto sign off alert countdown
  // For instance 15 minutes - 2 minutes = 13 minutes in milliseconds (idle time to timeout and display countdown timer modal)
  const idleTimeout = userSetIdleTimeout - timeBeforeAutoSignOff;

  // Then prompt countdown timer alert dialog
  const modalConfig = {
    title: t("misc_idle_dialog_title"),
    renderModalBody: () => {
      return (
        <CountdownTimer
          onComplete={() => {
            dispatch(
              // If countdown timer is up then disabled secondary button
              openModal({
                ...modalConfig,
                classNameMainDialog: "cd-main-complete-dialog",
                primaryButtonText: t(
                  "misc_idle_dialog_complete_primary_button"
                ),
                primaryButtonClickFn: async ({ closeFn }) => {
                  closeFn();
                  // Reset the idle timer
                  reset();
                  history.push(`/${LOGOUT_PATH.LOGOUT}`);
                },
                hideSecond: true,
              })
            );
          }}
        />
      );
    },
    primaryButtonText: t("misc_idle_dialog_primary_button"),
    secondaryButtonText: t("misc_idle_dialog_secondary_button"),
    // Remove the close icon
    isClosable: false,
    primaryButtonClickFn: async ({ closeFn }) => {
      closeFn();
      // Reset the idle timer
      reset();
      history.push(`/${LOGOUT_PATH.LOGOUT}`);
    },
    secondButtonClickFn: ({ closeFn }) => {
      // Reset the idle timer
      reset();
      dispatch(setCountdownTimer({ isCountingDown: false }));
      closeFn();
    },
  };

  const handleOnIdle = () => {
    if (auth?.isLoggedIn && clientInfo?.autoSignoffEnabled) {
      // Pause idle tracking on page refresh, if isCountingDown is true
      if (countdownTimerContent?.isCountingDown) {
        pause();
        return;
      }
      // Pause idle tracking
      pause();
      // For testing - 10 seconds
      // countdownTime: new Date().getTime() + 10 * 1000;
      dispatch(
        setCountdownTimer({
          userCustomizeCdTime: convertToSeconds(
            clientInfo?.autoSignoffAlertCountDown
          ),
          countdownTime: convertToDateTimeCd(
            clientInfo?.autoSignoffAlertCountDown
          ),
          isCountingDown: true,
        })
      );
      dispatch(openModal(modalConfig));
    }
  };

  const { pause, reset } = useIdleTimer({
    timeout: idleTimeout,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  useEffect(() => {
    // Handle on page refresh
    if (
      auth?.isLoggedIn &&
      countdownTimerContent?.isCountingDown &&
      clientInfo?.autoSignoffEnabled
    ) {
      dispatch(openModal({ ...modalConfig }));
      if (countdownTimerContent?.countdownTime === 0) {
        dispatch(
          openModal({
            ...modalConfig,
            classNameMainDialog: "cd-main-complete-dialog",
            primaryButtonText: t("misc_idle_dialog_complete_primary_button"),
            primaryButtonClickFn: async ({ closeFn }) => {
              // Reset the idle timer
              reset();
              history.push(`/${LOGOUT_PATH.LOGOUT}`);
            },
            hideSecond: true,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalConfig]);
  // ---------------------- End of idle tracking --------------------------------------------------------
  return <></>;
};

export default IdleTimer;
