//redux functions
import { setAuthInfo } from "./../../../../../redux/actions/auth";
import {
  broadcastTenant,
  reloadTenant,
} from "./../../../../../redux/actions/global";
import {
  addLanguageList,
  broadCastUserSelectedLanguage,
  broadcastLanguageList,
  setUserSelectedLanguage,
} from "./../../../../../redux/actions/language";

//const
import {
  LOGIN_PATH,
  RESPONSE_MESSAGE_TYPE,
} from "./../../../../../constants/index";
import { STORAGE_KEY } from "./../../../../../constants/index";

//utils
import { PortalLink } from "./../../../../../services/utils/index";

//api
import { ConfigurationService } from "./../../../../../services/hrmnet-api";
import { Ecrypt } from "../../../../../utils/crypto";
import { store } from "../../../../../redux/store";

export const OnLogin = async (res, history) => {
  const isChangePassword = res.data?.isChangePassword; //TODO

  const isFirstTimeLogin = res.data?.isFirstTimeLogin;

  const authInfo = {
    ...res.data,
    isLoggedIn:
      isChangePassword || isFirstTimeLogin
        ? false
        : res.data?.token !== null && res.data?.token !== undefined,
  };

  const pathname = window.location.pathname;

  let currPath;
  if (isChangePassword) {
    currPath = {
      pathname: pathname.replace(pathname.split("/")[1], LOGIN_PATH.PWD_EXP),
      state: {
        token: authInfo?.token,
        title: "login_passwordExpired_title",
        description: "login_passwordExpired_description",
      },
    };
  } else if (isFirstTimeLogin) {
    currPath = {
      pathname: pathname.replace(pathname.split("/")[1], LOGIN_PATH.FST_LOGIN),
      state: {
        token: authInfo?.token,
        title: "login_firstLogin_title",
        description: "",
      },
    };
  } else {
    //broadcast to other tabs
    await ReloadTenantEntityInfo(undefined, true);

    // only store user information once isValidUser is true
    store.dispatch(setAuthInfo(authInfo));

    // handle redirect to payslip
    var npage = window.sessionStorage.getItem(STORAGE_KEY.PATH_AFTER_LOGIN);
    var previousSessionEmployeeCode = window.sessionStorage.getItem(
      STORAGE_KEY.AUTH_EMPLOYEE_CODE
    );
    var validSessionEmployeeCode =
      previousSessionEmployeeCode === authInfo?.employeeCode &&
      authInfo?.employeeCode !== undefined
        ? true
        : false;
    if (npage !== null && validSessionEmployeeCode) {
      window.sessionStorage.removeItem(STORAGE_KEY.PATH_AFTER_LOGIN);
      window.sessionStorage.removeItem(STORAGE_KEY.AUTH_EMPLOYEE_CODE);
      currPath = npage;
    } else {
      currPath = PortalLink();
    }
  }

  //require history push due to modal dismissal issue
  //only dismiss when navigate using history "POP", "PUSH"
  return history.push(currPath);
};

/**
 * Login - login button
 *
 * @param {*} label
 * @returns
 */
export const LoginBtn = (label) => {
  return {
    action: {
      type: "submit",
      label: label,
      submitingLabel: label, //t("login_submitting"),
      config: {
        kind: "primary",
        style: {
          width: "100%",
          textAlign: "left",
          minHeight: "3.5rem",
        },
      },
    },
  };
};

/**
 * Login - cancel button
 *
 * @param {*} label
 * @param {*} onCancel
 * @returns
 */
export const CancelBtn = (label, onCancel) => {
  return {
    action: {
      type: "cancel",
      label: label, //t("login_cancel"),
      submitingLabel: label, //t("login_cancel"),
      config: {
        className: "p-button-secondary",
        kind: "primary",
        style: {
          width: "100%",
          textAlign: "left",
          minHeight: "3.5rem",
        },
        onClick: () => onCancel(),
      },
    },
  };
};

/**
 * Reload tenant-entity information
 * Dispatch into redux/local storage
 *
 * @param {string} url
 * @param {*} auth
 * @param {boolean} siteSettingShouldBeUpdate flag to conditionally query client info
 * @returns
 */

export async function ReloadTenantEntityInfo(url = null, isLogin = false) {
  //changing site settings
  const excludeHeaderChecking = [
    "/configuration/login",
    "/configuration/languages",
    "/content/getbycontexts",
    "/authentication/refresh-token",
    // "/authentication/authenticatebyusername",
    // "/authentication/authenticatebyemail",
    // "/authentication/authenticatebyssotoken",
    "/notification/system-notification",
  ];

  //skip reloading tenant-entity if request api included in excludeHeaderChecking
  if (url && excludeHeaderChecking.findIndex((x) => x === url) > -1) {
    return;
  }

  const setUserLanguage = (lang, isLogin) => {
    if (isLogin) {
      return store.dispatch(broadCastUserSelectedLanguage(lang));
    }
    return store.dispatch(setUserSelectedLanguage(lang));
  };

  const setTenant = (clientInfo, isLogin) => {
    if (isLogin) {
      return store.dispatch(broadcastTenant(clientInfo));
    }
    return store.dispatch(reloadTenant(clientInfo));
  };

  const setLanguageOptions = (langList, isLogin) => {
    if (isLogin) {
      return store.dispatch(broadcastLanguageList(langList));
    }
    return store.dispatch(addLanguageList(langList));
  };

  const getLanguage = async (clientLanguage, isLogin) => {
    try {
      const res = await ConfigurationService.configurationGetLanguages({
        suppressErrorMsg: true,
      });
      if (res && res.data) {
        let languageList = res.data;
        if (Array.isArray(languageList)) {
          setLanguageOptions(languageList, isLogin);
        }
      }
    } catch (error) {
    } finally {
      setUserLanguage(clientLanguage, isLogin);
    }
  };

  const getTenant = async (isLogin) => {
    // load login configuration first
    // follow by language lists to avoid send redundant request for language context
    let clientInfo = {};
    const res = await ConfigurationService.configurationGetLoginConfigurations({
      suppressErrorMsg: true,
    });

    if (res && Array.isArray(res?.messages)) {
      const hasError =
        res.messages.filter((x) => x.type === RESPONSE_MESSAGE_TYPE.ERROR)
          ?.length > 0;
      if (hasError) {
        throw new Error();
      }
    }

    if (res && res.data) {
      clientInfo = {
        ...res.data,
        entityDisplayName: res.data.entityName ?? res.data.tenantName, //TODO
      };

      // retain same tenant/entity code to redirect to same login url upon log out
      let pathnameCount = window.location.pathname.split("/").length;
      if (pathnameCount > 2) {
        clientInfo.tenantCode = window.location.pathname.split("/")[2];
      }

      if (pathnameCount > 3) {
        clientInfo.entityCode = window.location.pathname.split("/")[3];
      }

      if (clientInfo?.appClientId) {
        await localStorage.setItem(
          STORAGE_KEY.APP_CLIENT_ID,
          Ecrypt(clientInfo?.appClientId)
        );
      }

      setTenant(clientInfo, isLogin);
      setUserLanguage(undefined, isLogin);

      //force other tabs to reload
      let pathname = clientInfo?.tenantCode ?? "/";
      if (!!pathname) {
        if (!!clientInfo?.entityCode) {
          pathname += "/";
          pathname += clientInfo?.entityCode;
        }
      }
      await getLanguage(clientInfo?.language, isLogin);
    }
    return clientInfo;
  };

  return getTenant(isLogin);
}

/**
 *
 * @param {string[]} enums
 * @param {string} valueStr
 * @returns
 */
export const CSVToArray = (enums, valueStr) => {
  const _valueStr = "," + valueStr + ",";
  if (!enums) {
    return [];
  }

  return Array.from(
    enums?.filter((x) => _valueStr.includes("," + x.value + ",")),
    (x) => x.value
  );
};

export function ToUIOption(enums) {
  if (Array.isArray(enums)) {
    return enums?.map((option) => {
      const result = {
        label: option.name,
        value: option.value ?? option.id,
      };
      if (!!option.description && option.description?.toLowerCase() !== option.name.toLowerCase()) {
        result.group = option.description;
      }
      if (!!option?.disabled) {
        result.disabled = option.disabled;
      }
      return result;
    });
  }
  return enums;
}
