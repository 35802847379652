//#region Employee Form

export const DataAmendmentForm = {
  Employee: undefined,
  PayslipName: undefined,
  Department: undefined,
  WorkEmail: undefined,
  AlternativeEmail: undefined,
  NewSalaryEffDate: undefined,
  EmployeeTerminates: undefined,
  AnnualSalary: undefined,
  LastWorkDate: undefined,
};

//direct from api
export function EmployeeDataAmendmentApiSteps(t) {
  return {
    data: [
      {
        displaySequence: 1,
        id: 1,
        stepName: t("Employee_data_Amendment_Step1"), //"Start",
        translationKey: "Employee_data_Amendment_Step1",
        sections: [
          // {
          //   displaySequence: 1,
          //   isEditable: true,
          //   sectionId: 100,
          //   sectionName: t("Employee_Data_Amendment_Employee_SectionName"), //"Employee",
          //   translationKey: "Employee_Data_Amendment_Employee_SectionName",
          // },
          {
            displaySequence: 1,
            isEditable: true,
            sectionId: 200,
            sectionName: t("Employee_Data_Amendment_BasicDetails"), //"Basic Details",
            translationKey: "Employee_Data_Amendment_BasicDetails",
          },
          {
            displaySequence: 2,
            isEditable: true,
            sectionId: 300,
            sectionName: t("Employee_Data_Amendment_OtherDetails"), //"Other Details",
            translationKey: "Employee_Data_Amendment_OtherDetails",
          },
        ],
      },
    ],
  };
}

export function EmployeeDataAmendmentConfiguration(t) {
  return {
    data: {
      formName: t("Employee_Data_Amendment_Formname"), //"Employee Data Amendment",
      id: 1000,
      sections: [
        // {
        //   defaultExpanded: true,
        //   displayLayout: 1,
        //   displaySequence: 1,
        //   id: 100,
        //   isDefaultHide: false,
        //   sectionName: t("Employee_Data_Amendment_Employee_SectionName"), //"Employee",
        //   translationKey: "Employee_Data_Amendment_Employee_SectionName",
        //   fields: [
        //     {
        //       controlType: "DropDownList",
        //       dataType: "string",
        //       displaySequence: 1,
        //       dropdownOptions: [],
        //       fieldGroup: 1,
        //       fieldLabel: t("Employee_Data_Amendment_Employee"), //"",
        //       fieldName: "Employee",
        //       id: 101,
        //       inputName: "Employee",
        //       isAllowNull: true,
        //       isDefaultHide: false,
        //       isDetailView: false,
        //       isEditable: true,
        //       isElasticData: false,
        //       isHideLabelField: true,
        //       isHtmlField: false,
        //       isSpecialField: false,
        //       length: 0,
        //       storageDataType: "String",
        //       translationKey: "Employee_Data_Amendment_Employee",
        //       viewSectionId: 100,
        //       eventFunctionName: "select-employee",
        //       dependentFields:
        //         '[{"Value":"$any","Action":"Show","Fields":"DisplayName, WorkEmail, Department, PersonalEmail","Sections":"200"},{"Value":"$any","Action":"Show","Fields":"NewSalaryEffectiveDate, BasicSalary, Termination_Date, Last_Working_Date","Sections":"300"},{"Value":"undefined","Action":"Hide","Fields":"DisplayName, WorkEmail, Department, PersonalEmail","Sections":"200"},{"Value":"undefined","Action":"Hide","Fields":"NewSalaryEffectiveDate, BasicSalary, Termination_Date, Last_Working_Date","Sections":"300"}]',
        //     },
        //   ],
        // },
        {
          defaultExpanded: true,
          displayLayout: 2,
          displaySequence: 2,
          id: 200,
          isDefaultHide: false,
          sectionName: t("Employee_Data_Amendment_BasicDetails"), //"Basic Details",
          translationKey: "Employee_Data_Amendment_BasicDetails",
          fields: [
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 1,
              fieldLabel: t("Employee_Data_Amendment_EmployeeName"), //"Employee Name",
              fieldName: "PayslipName",
              id: 2,
              inputName: "PayslipName",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "Employee_Data_Amendment_EmployeeName",
              viewSectionId: 200,
            },
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 2,
              fieldGroup: 1,
              fieldLabel: t("Employee_Data_Amendment_Email"), //"Email",
              fieldName: "WorkEmail",
              id: 3,
              inputName: "WorkEmail",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "Employee_Data_Amendment_Email",
              viewSectionId: 200,
            },
            {
              controlType: "DropDownList",
              dataType: "string",
              displaySequence: 3,
              dropdownOptions: [],
              fieldGroup: 2,
              fieldLabel: t("Employee_Data_Amendment_Department"), //"Department",
              fieldName: "Department",
              id: 4,
              inputName: "Department",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: 0,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "Employee_Data_Amendment_Department",
              viewSectionId: 200,
            },
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 4,
              fieldGroup: 2,
              fieldLabel: t("Employee_Data_Amendment_PersonalEmail"), //"Personal Email",
              fieldName: "AlternativeEmail",
              id: 5,
              inputName: "AlternativeEmail",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "Employee_Data_Amendment_PersonalEmail",
              viewSectionId: 200,
            },
          ],
        },
        {
          defaultExpanded: true,
          displayLayout: 2,
          displaySequence: 3,
          id: 300,
          isDefaultHide: false,
          sectionName: "Other Details",
          translationKey: "Employee_Data_Amendment_OtherDetails",
          fields: [
            {
              controlType: "DatePicker",
              dataType: "string",
              // dependentFields: "[{\"Value\":\"$any\",\"Action\":\"SetMinDate\",\"Fields\":\"Rent_Free_Period_To\"},{\"Value\":\"$any\",\"Action\":\"SetMinDate\",\"Fields\":\"Rent_Free_Period_To\"}]",
              displaySequence: 1,
              fieldGroup: 1,
              fieldLabel: t("Employee_Data_Amendment_NewSalaryEffectiveDate"), //"New Salary Effective Date",
              fieldName: "NewSalaryEffDate",
              id: 6,
              inputName: "NewSalaryEffDate",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "Employee_Data_Amendment_NewSalaryEffectiveDate",
              viewSectionId: 300,
            },
            {
              controlType: "NumericBox",
              dataType: "number",
              displaySequence: 2,
              // eventFunctionName: "calculateEligibleAmount",
              fieldGroup: 1,
              fieldLabel: t("Employee_Data_Amendment_NewSalary"), //"New Salary",
              fieldName: "AnnualSalary",
              id: 7,
              inputName: "AnnualSalary",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: 0,
              storageDataType: "Int32",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "Employee_Data_Amendment_NewSalary",
              viewSectionId: 300,
            },
            {
              controlType: "DatePicker",
              dataType: "string",
              displaySequence: 3,
              fieldGroup: 2,
              fieldLabel: t("Employee_Data_Amendment_TerminationDate"), //"Termination Date",
              fieldName: "EmployeeTerminates",
              id: 8,
              inputName: "EmployeeTerminates",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "Employee_Data_Amendment_TerminationDate",
              viewSectionId: 300,
            },
            {
              controlType: "DatePicker",
              dataType: "string",
              displaySequence: 4,
              fieldGroup: 2,
              fieldLabel: t("Employee_Data_Amendment_LastWorkingDate"), //"Last Working Date",
              fieldName: "LastWorkDate",
              id: 9,
              inputName: "LastWorkDate",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "Employee_Data_Amendment_LastWorkingDate",
              viewSectionId: 300,
            },
          ],
        },
      ],
    },
  };
}

export function EmployeeDataAmendmentConfigurationFunction(
  t,
  {
    employee,
    payslipName,
    department,
    workEmail,
    alternativeEmail,
    newSalaryEffDate,
    employeeTerminates,
    annualSalary,
    lastWorkDate,
  }
) {
  return {
    data: {
      formName: t("Employee_Data_Amendment_Formname"), //"Employee Data Amendment",
      id: 1000,
      sections: [
        {
          defaultExpanded: true,
          displayLayout: 1,
          displaySequence: 1,
          id: 100,
          isDefaultHide: false,
          sectionName: t("Employee_Data_Amendment_Employee_SectionName"), //"Employee",
          translationKey: "Employee_Data_Amendment_Employee_SectionName",
          fields: [
            {
              controlType: "DropDownList",
              dataType: "string",
              displaySequence: 1,
              dropdownOptions: [],
              fieldGroup: 1,
              fieldLabel: t("Employee_Data_Amendment_Employee"), //"",
              fieldName: "Employee",
              id: 101,
              inputName: "Employee",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: true,
              isHtmlField: false,
              isSpecialField: false,
              length: 0,
              storageDataType: "String",
              viewSectionId: 100,
              translationKey: "Employee_Data_Amendment_Employee",
              value: employee,
              dependentFields:
                '[{"Value":"$any","Action":"Show","Fields":"DisplayName, WorkEmail, Department, PersonalEmail","Sections":"200"},{"Value":"$any","Action":"Show","Fields":"NewSalaryEffectiveDate, BasicSalary, Termination_Date, Last_Working_Date","Sections":"300"},{"Value":"undefined","Action":"Hide","Fields":"DisplayName, WorkEmail, Department, PersonalEmail","Sections":"200"},{"Value":"undefined","Action":"Hide","Fields":"NewSalaryEffectiveDate, BasicSalary, Termination_Date, Last_Working_Date","Sections":"300"}]',
            },
          ],
        },
        {
          defaultExpanded: true,
          displayLayout: 2,
          displaySequence: 2,
          id: 200,
          isDefaultHide: false,
          sectionName: t("Employee_Data_Amendment_BasicDetails"), //"Basic Details",
          translationKey: "Employee_Data_Amendment_BasicDetails",
          fields: [
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 1,
              fieldLabel: t("Employee_Data_Amendment_EmployeeName"), //"Employee Name",
              fieldName: "PayslipName",
              id: 2,
              inputName: "PayslipName",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "Employee_Data_Amendment_EmployeeName",
              viewSectionId: 200,
              value: payslipName,
            },
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 2,
              fieldGroup: 1,
              fieldLabel: t("Employee_Data_Amendment_Email"), //"Email",
              fieldName: "WorkEmail",
              id: 3,
              inputName: "WorkEmail",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "Employee_Data_Amendment_Email",
              viewSectionId: 200,
              value: workEmail,
            },
            {
              controlType: "DropDownList",
              dataType: "string",
              displaySequence: 3,
              dropdownOptions: [],
              fieldGroup: 2,
              fieldLabel: t("Employee_Data_Amendment_Department"), //"Department",
              fieldName: "Department",
              id: 4,
              inputName: "Department",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: 0,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "Employee_Data_Amendment_Department",
              viewSectionId: 200,
              value: department,
            },
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 4,
              fieldGroup: 2,
              fieldLabel: t("Employee_Data_Amendment_PersonalEmail"), //"Personal Email",
              fieldName: "AlternativeEmail",
              id: 5,
              inputName: "AlternativeEmail",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "Employee_Data_Amendment_PersonalEmail",
              viewSectionId: 200,
              value: alternativeEmail,
            },
          ],
        },
        {
          defaultExpanded: true,
          displayLayout: 2,
          displaySequence: 3,
          id: 300,
          isDefaultHide: false,
          sectionName: t("Employee_Data_Amendment_OtherDetails"), //"Other Details",
          translationKey: "Employee_Data_Amendment_OtherDetails",
          fields: [
            {
              controlType: "DatePicker",
              dataType: "string",
              // dependentFields: "[{\"Value\":\"$any\",\"Action\":\"SetMinDate\",\"Fields\":\"Rent_Free_Period_To\"},{\"Value\":\"$any\",\"Action\":\"SetMinDate\",\"Fields\":\"Rent_Free_Period_To\"}]",
              displaySequence: 1,
              fieldGroup: 1,
              fieldLabel: t("Employee_Data_Amendment_NewSalaryEffectiveDate"), //"New Salary Effective Date",
              fieldName: "NewSalaryEffDate",
              id: 6,
              inputName: "NewSalaryEffDate",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "Employee_Data_Amendment_NewSalaryEffectiveDate",
              viewSectionId: 300,
              value: newSalaryEffDate,
            },
            {
              controlType: "NumericBox",
              dataType: "number",
              displaySequence: 2,
              // eventFunctionName: "calculateEligibleAmount",
              fieldGroup: 1,
              fieldLabel: t("Employee_Data_Amendment_NewSalary"), //"New Salary",
              fieldName: "AnnualSalary",
              id: 7,
              inputName: "AnnualSalary",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: 0,
              storageDataType: "Int32",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "Employee_Data_Amendment_NewSalary",
              viewSectionId: 300,
              value: annualSalary,
            },
            {
              controlType: "DatePicker",
              dataType: "string",
              displaySequence: 3,
              fieldGroup: 2,
              fieldLabel: t("Employee_Data_Amendment_TerminationDate"), //"Termination Date",
              fieldName: "EmployeeTerminates",
              id: 8,
              inputName: "EmployeeTerminates",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "Employee_Data_Amendment_TerminationDate",
              viewSectionId: 300,
              value: employeeTerminates,
            },
            {
              controlType: "DatePicker",
              dataType: "string",
              displaySequence: 4,
              fieldGroup: 2,
              fieldLabel: t("Employee_Data_Amendment_LastWorkingDate"), //"Last Working Date",
              fieldName: "LastWorkDate",
              id: 9,
              inputName: "LastWorkDate",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey: "Employee_Data_Amendment_LastWorkingDate",
              viewSectionId: 300,
              value: lastWorkDate,
            },
          ],
        },
      ],
    },
  };
}

//#endregion
