import "./input-group.scss";

import React, { useEffect, useRef, useState } from "react";
import BaseControl, {
  BCProps,
  BCStates,
  BCType,
  isError,
  renderError,
  validateControl,
} from "../base-control";

import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { jsonEqual } from "../../../utils/utils";
import { SelectControlOption } from "../select-control/select-control";

export interface InputGroupAction {}

export interface InputGroupProps extends BCProps {
  /**
   * data options
   */
  enum?: SelectControlOption[];
}

export interface InputGroupState extends BCStates {}

const valueToForm = (val: string, enums: any[], placeholder: string) => {
  let result: any = {
    unitValue: "",
    unitType: "",
  };
  let value = "";
  const controlArraysRegex = /<[a-zA-Z]+>/g;
  const controlArraysMatches = enums[0].value.match(controlArraysRegex);
  const controlArrays = Object.keys(controlArraysMatches).map((key: any) => {
    const _controlArray: any = {
      key: controlArraysMatches[key].replace("<", "").replace(">", ""),
      required: false,
      type: BCType.input,
      noLabel: true,
      placeholder: placeholder,
    };
    if (_controlArray.key === "unitType") {
      _controlArray.placeholder = "";
      _controlArray.enum = enums;
      _controlArray.type = BCType.select;
    }
    return _controlArray;
  });
  if (enums.length > 0) {
    enums.forEach((x: any) => {
      const regexpString = x.value;
      const regexpNames = new RegExp(regexpString, "mg");
      for (const match of val.matchAll(regexpNames)) {
        const _matchGroup = { ...match.groups };
        result = match.groups;
        result.unitType = x.value;
        const _value = Object.keys(_matchGroup).map(
          (key: any) => _matchGroup[key]
        );
        value = _value.join("");
      }
    });
  }
  return {
    InitForm: result,
    InitValue: value,
    InitControlArrays: controlArrays,
  };
};

const InputGroup: React.FC<InputGroupProps> = (props) => {
  const { t } = useTranslation();
  const ruleList = props.ruleList || [];
  if (props.required) {
    ruleList.push({
      name: "required",
    });
  }
  // init state control
  let initState: InputGroupState = {
    touched: false,
    value: "",
    form: {
      unitValue: "",
      unitType: "",
    },
    valueStr: (props.value || "").toString(),
    controlState: {
      invalid: false,
    },
  };
  initState.controlState =
    props.controlState || validateControl(ruleList || [], initState.value, t);
  // Update state if value changed
  const [state, setState] = useState(initState);
  // unsubcribe
  const mountedRef = useRef(true);
  useEffect(() => {
    const { InitForm, InitValue, InitControlArrays } = valueToForm(
      props.value,
      props.enum ?? [],
      props.placeholder ?? ""
    );
    let value = InitValue;

    const ruleList = props.ruleList || [];
    if (props.required) {
      ruleList.push({
        name: "required",
      });
    }
    let controlState =
      props.controlState || validateControl(ruleList || [], value, t);
    if (!mountedRef.current) return;
    setState({
      touched: false,
      value,
      valueStr: (value || "").toString(),
      controlState,
      form: InitForm,
      controlArrays: InitControlArrays,
    });
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, props.controlState, props.enum]);

  function GetUnitType(input: string) {
    if (!input) {
      return "";
    }
    const unitTypeRegex = /unitType>\w+/i;
    const unitTypeMatch = input.match(unitTypeRegex);
    if (!!unitTypeMatch && unitTypeMatch.length > 0 && unitTypeMatch[0]) {
      const unitType = unitTypeMatch[0].replace(/^unitType>/, "");
      return unitType;
    }

    return input;
  }

  const onChange = async (_value: any, key: string) => {
    const _form = {
      ...state.form,
    };
    _form[key] = _value;
    let valueStr = "";
    const isEveryPropertiesHasValue = Object.keys(_form).every(
      (element: any) => !!_form[element]
    );
    if (isEveryPropertiesHasValue) {
      state?.controlArrays.forEach((element: any) => {
        if (element.key === "unitType") {
          valueStr += GetUnitType(_form[element.key]);
        } else {
          valueStr += _form[element.key];
        }
      });
    }
    const value = valueStr;
    const controlState = validateControl(ruleList, valueStr, t);
    let _state = {
      ...state,
      value,
      valueStr,
      controlState,
      loading: false,
      form: _form,
    };

    if (props.onChange) {
      props.onChange({
        controlState: controlState,
        value: valueStr,
        valueStr: valueStr,
      });
    }
    if (props.onTrueUpdateValue) {
      props.onTrueUpdateValue({
        controlState: controlState,
        value: valueStr,
        valueStr: valueStr,
      });
    }
    if (!jsonEqual(_state, state)) {
      setState(_state);
    }
  };

  const renderControl = () => {
    return state?.controlArrays?.map((x: any) => {
      return (
        <BaseControl
          {...x}
          value={state.form[x.key]}
          onChange={({ value }) => onChange(value, x.key)}
        />
      );
    });
  };

  return (
    <>
      <div
        className={`inputgroup-inner p-field ${
          props.noLabel ? "no-label" : ""
        }`}
      >
        <label htmlFor={props.id}>
          {props.label}
          {props.required && !props.noRequiredLabel ? (
            <small className="required p-invalid">&nbsp;*</small>
          ) : null}
          {props.tooltip ? (
            <Button
              type="button"
              tooltip={props.tooltip}
              tooltipOptions={{ position: "top" }}
              icon="pi pi-info-circle"
              className="p-button-rounded label-help p-button-text p-button-plain"
            />
          ) : null}
        </label>
        <div
          className={`p-inputgroup ${
            isError(state, props) ? "p-inputgroup-error" : ""
          }`}
        >
          {renderControl()}
          {props.hintRight && (
            <span className={"inputgroup-hint-right"}>{props.hintRight}</span>
          )}
        </div>
        {props.hintBottom && (
          <div className={"inputgroup-hint-bottom"}>{props.hintBottom}</div>
        )}
        {renderError(state, props, t)}
      </div>
    </>
  );
};

export default InputGroup;
