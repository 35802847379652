import "./documents.scss";

import { Route, Switch, useRouteMatch } from "react-router-dom";

import PaySlip from "./payslip/payslip";
import TaxDocs from "./tax-docs/tax-docs";
import CompensationDocs from "./compensation-docs/compensation-docs";
import HrLetters from "./hr-letters/hr-letters";

import {
  EEPORTAL_LABEL_BASE_TABLE,
  EEPORTAL_LABEL_MY_DOCS,
  enumsKeyToArray,
  FUNCTION_CODE,
} from "./../../../constants/index";

import PageNotFound from "../public/error/employee-error-404";
import LanguageLoader from "../../../components/language-loader/language-loader";
import HrCorner from "./hr-corner/hr-corner";
import OtherDocs from "./other-docs/other-docs";
import DocumentUpload from "./document-upload/document-upload";

const Documents = (props) => {
  let match = useRouteMatch();

  const routes = [
    {
      id: "payslip",
      path: `${match.path}/${FUNCTION_CODE.Payslip}`,
      component: PaySlip,
      exact: true,
      strict: true,
    },
    {
      id: "tax-docs",
      path: `${match.path}/${FUNCTION_CODE.Tax}`,
      component: TaxDocs,
      exact: true,
      strict: true,
    },
    {
      id: "compensation-docs",
      path: `${match.path}/${FUNCTION_CODE.Compensation}`,
      component: CompensationDocs,
      exact: true,
      strict: true,
    },
    {
      id: "hr-letters",
      path: `${match.path}/${FUNCTION_CODE.HrLetters}`,
      component: HrLetters,
      exact: true,
      strict: true,
    },
    {
      id: "hr-documents",
      path: `${match.path}/${FUNCTION_CODE.HrCorner}`,
      component: HrCorner,
      exact: true,
      strict: true,
    },
    {
      id: "other-docs",
      path: `${match.path}/${FUNCTION_CODE.OtherDocs}`,
      component: OtherDocs,
      exact: true,
      strict: true,
    },
    {
      id: "document-upload",
      path: `${match.path}/${FUNCTION_CODE.DocumentUpload}`,
      component: DocumentUpload,
      exact: true,
      strict: true,
    },
  ];

  return (
    <>
      <LanguageLoader
        contexts={enumsKeyToArray([
          EEPORTAL_LABEL_BASE_TABLE,
          EEPORTAL_LABEL_MY_DOCS,
        ])}
      >
        <div className="document">
          <Switch>
            {props.routeConfig ? props.routeConfig(routes) : null}
            <Route component={PageNotFound} />
          </Switch>
        </div>
      </LanguageLoader>
    </>
  );
};

export default Documents;
