export const InitAccessPermissionRoleFormConfig = {
  name: undefined,
  remarks: undefined,
  permission: undefined,
};

export function AccessPermissionRoleApiSteps(t) {
  return {
    data: [
      {
        displaySequence: 1,
        id: 1,
        sections: [
          {
            displaySequence: 1,
            isEditable: true,
            sectionId: 100,
            sectionName: t("Acces_Permission_Role_Form_Section1Name"),
          },
        ],
      },
    ],
  };
}

const renderBodyExpanderWithColumn = (rowData, props, isSelected) => {
  if (!!rowData?.children) {
    return (
      <table>
        <tbody>
          <tr>
            <td className="access-permission-role-expander-element">
              {props.expander.element}
            </td>
            <td colSpan="10">{rowData.name}</td>
          </tr>
        </tbody>
      </table>
    );
  }
  return <div>{rowData.name}</div>;
};

export function AccessPermissionRoleConfigurationInitForm(t, modulesListValue) {
  return {
    data: {
      id: 1000,
      sections: [
        {
          defaultExpanded: true,
          displayLayout: 2,
          displaySequence: 1,
          id: 100,
          isDefaultHide: false,
          fields: [
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 1,
              fieldLabel: t("Access_Permission_Role_Configuration_Name"),
              fieldName: "name",
              id: 1,
              inputName: "name",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-12 p-md-12 p-sm-12",
              translationKey: "Access_Permission_Role_Configuration_Name",
              viewSectionId: 100,
            },
            {
              controlType: "TextBox",
              dataType: "string",
              displaySequence: 2,
              fieldGroup: 2,
              fieldLabel: t("Access_Permission_Role_Configuration_Remark"),
              fieldName: "remark",
              id: 2,
              inputName: "remark",
              isAllowNull: true,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-12 p-md-12 p-sm-12",
              translationKey: "Access_Permission_Role_Configuration_Remark",
              viewSectionId: 200,
            },
            /* {
              controlType: "TreeTable",
              dataType: "string",
              displaySequence: 3,
              fieldGroup: 3,
              fieldLabel: t("Access_Permission_Role_Configuration_Permission"),
              fieldName: "permission",
              id: 3,
              inputName: "permission",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-12 p-md-12 p-sm-12",
              translationKey: "Access_Permission_Role_Configuration_Permission",
              viewSectionId: 300,
              config: {
                treeColumns: [
                  {
                    field: "moduleName",
                    header: t(
                      "Access_Permission_Role_Configuration_Permission_TreeTable_ModuleName"
                    ),
                    expander: true,
                  },
                ],
                treeValue: [
                  {
                    key: "Home",
                    data: {
                      moduleName: "Home",
                    },
                  },
                  {
                    key: "Notification",
                    data: {
                      moduleName: "Notification",
                    },
                  },
                  {
                    key: "Rental",
                    data: {
                      moduleName: "Rental",
                    },
                    children: [
                      {
                        key: "Rental_Feature",
                        data: {
                          moduleName: "Feature",
                        },
                        children: [
                          {
                            key: "Add_New_Application",
                            data: {
                              moduleName: "Add New Application",
                            },
                          },
                          {
                            key: "Rental_Application_Records",
                            data: {
                              moduleName: "Rental Application Records",
                            },
                          },
                          {
                            key: "Approval_Corner",
                            data: {
                              moduleName: "Approval Corner",
                            },
                          },
                          {
                            key: "Search_Report",
                            data: {
                              moduleName: "Search / Report",
                            },
                          },
                        ],
                      },
                      {
                        key: "Rental_Actions",
                        data: {
                          moduleName: "Actions",
                        },
                        children: [
                          {
                            key: "View_Application",
                            data: {
                              moduleName: "View Application",
                            },
                          },
                          {
                            key: "Edit_Application",
                            data: {
                              moduleName: "Edit Application",
                            },
                          },
                          {
                            key: "Delete_Application",
                            data: {
                              moduleName: "Delete Application",
                            },
                          },
                          {
                            key: "Withdraw_Application",
                            data: {
                              moduleName: "Withdraw Application",
                            },
                          },
                          {
                            key: "Change_Application",
                            data: {
                              moduleName: "Change Application",
                            },
                          },
                          {
                            key: "Submit_Application",
                            data: {
                              moduleName: "Submit Application",
                            },
                          },
                        ],
                      },
                      {
                        key: "Rental_Reports",
                        data: {
                          moduleName: "Reports",
                        },
                        children: [
                          {
                            key: "Application_Log_Report",
                            data: {
                              moduleName: "Application Log Report",
                            },
                          },
                          {
                            key: "Annual_Tax_Report",
                            data: {
                              moduleName: "Annual Tax Report",
                            },
                          },
                          {
                            key: "Claim_Detail_Report",
                            data: {
                              moduleName: "Claim Details Report",
                            },
                          },
                          {
                            key: "Monthly_Rental_Report",
                            data: {
                              moduleName: "Monthly Rental Report",
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            }, */
            {
              controlType: "DataTable",
              dataType: "string",
              displaySequence: 3,
              fieldGroup: 3,
              fieldLabel: t("Access_Permission_Role_Configuration_Modules"),
              fieldName: "moduleIds",
              id: 3,
              inputName: "moduleIds",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-12 p-md-12 p-sm-12",
              translationKey: "Access_Permission_Role_Configuration_Modules",
              viewSectionId: 300,
              config: {
                dataKey: "id",
                parentKey: "parentId",
                childrenKey: "subModules",
                rowExpansionTemplateConfig: [
                  {
                    columnConfig: [
                      {
                        field: "name",
                        header: t(
                          "access_permission_role_configuration_module_rowExpansionTemplate_feature"
                        ),
                      },
                    ],
                    field: "Menu",
                  },
                  {
                    columnConfig: [
                      {
                        field: "name",
                        header: t(
                          "access_permission_role_configuration_module_rowExpansionTemplate_actions"
                        ),
                      },
                    ],
                    field: "Action",
                  },
                  {
                    columnConfig: [
                      {
                        field: "name",
                        header: t(
                          "access_permission_role_configuration_module_rowExpansionTemplate_reports"
                        ),
                      },
                    ],
                    field: "Report",
                  },
                ],
                columns: [
                  {
                    field: "name",
                    header: t(
                      "access_permission_role_configuration_module_name"
                    ),
                    expander: true,
                    body: (rowData, props, isSelected) => {
                      return (
                        <div className={`bt-row bt-row-${props.rowIndex}`}>
                          <div className="bt-cell-value">
                            {renderBodyExpanderWithColumn(
                              rowData,
                              props,
                              isSelected
                            )}
                          </div>
                        </div>
                      );
                    },
                  },
                ],
                value: modulesListValue,
              },
            },
          ],
        },
      ],
    },
  };
}
