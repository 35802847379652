import "./document-wrapper.scss";

import { useTranslation } from "react-i18next";
import {
  COMPANY_POLICY_DEFAULT_DOCUMENT_NAME,
  HTML_ATTIBUTES_ONCLICK,
  HTML_ATTIBUTES_VALUE,
} from "../../../../constants";
import { store } from "../../../../redux/store";
import { getCustomFunctionLibrary } from "../../rental/utils";

const htmlOnClickHandler = (e) => {
  if (e.target?.attributes?.[HTML_ATTIBUTES_ONCLICK]) {
    const fn = e.target.attributes[HTML_ATTIBUTES_ONCLICK]?.value;
    const value = e.target.attributes[HTML_ATTIBUTES_VALUE]?.value;
    const dispatch = store.dispatch;
    const customFunctionLibrary = getCustomFunctionLibrary({ dispatch });
    if (fn && customFunctionLibrary && fn in customFunctionLibrary) {
      customFunctionLibrary[fn](value, COMPANY_POLICY_DEFAULT_DOCUMENT_NAME);
    }
  }
};

const renderContent = (content, noDataTitle, t) => {
  if (content === undefined || content === null || content === "") {
    return <p>{t(noDataTitle)}</p>;
  }

  /**
   * reference: https://stackoverflow.com/questions/54109790/how-to-add-onclick-event-to-a-string-rendered-by-dangerouslysetinnerhtml-in-reac
   */
  return (
    <div
      onClick={(e) => htmlOnClickHandler(e)}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

/**
 *
 * @param {string} content
 * @param {string} noDataTitle
 * @returns
 */
const DocumentWrapper = (props) => {
  const { t } = useTranslation();

  return (
    <div className="doc-nodata">
      {renderContent(props.content, props.noDataTitle, t)}
    </div>
  );
};

export default DocumentWrapper;
