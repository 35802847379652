import "./main-spinner.scss";

import { useSelector } from "react-redux";

import MainLoading from "../main-loading/main-loading";

const MainSpinner = () => {
  const show = useSelector((state) => {
    return state?.modal?.loading || state?.spinner?.isOpen;
  });

  return (
    <>
      {show && (
        <div className="main-spinner">
          <MainLoading />
        </div>
      )}
    </>
  );
};

export default MainSpinner;
