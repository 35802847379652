import { AnalysisCode } from "./analysis-code-helper";

export const BASIC_CONTROL_LAYOUT_CONFIG = {
  className: "p-col-12 p-lg-6 p-sm-12",
};

export const BASIC_CONTROL_LAYOUT_CONFIG_HALF_COLUMN = {
  className: "p-col-6",
};

export const BASIC_CONTROL_LAYOUT_CONFIG_FULL_COLUMN = {
  ...BASIC_CONTROL_LAYOUT_CONFIG,
  className: "p-col-12 p-lg-12 p-sm-12",
};

export enum TIME_SNAP_ENUM {
  HOUR = "HOUR",
  HALF_HOURS = "HALF-HOURS",
  QUARTER_HOURS = "QUARTER-HOURS",
  MINUTES = "MINUTES",
}

export const TIME_SNAP_OPTION: any = {
  [TIME_SNAP_ENUM.HOUR]: {
    step: 60,
  },
  [TIME_SNAP_ENUM.HALF_HOURS]: {
    step: 30,
  },
  [TIME_SNAP_ENUM.QUARTER_HOURS]: {
    step: 15,
  },
  [TIME_SNAP_ENUM.MINUTES]: {
    step: 1,
  },
};

export interface NwowApplicationModel {
  applicationId: number;
  employeeName: string;
  employeeCode: string;
  referenceNo: string;
  transactionReferenceNo: string;
  submissionDate: string;
  balanceAsOfCalcDate: number;
  balanceAsOfYearEnd: number;
  nwowCode: string;
  nwowType: string;
  balance: number;
  nwowFrom: string;
  nwowTo: string;
  unit: string;
  uom: string;
  status: string;
  remarks: string;
  approverRemarks: string[];
  reminderText: string;
  analyses: AnalysisCode[];
  attachment: {
    fileName: string;
    code: string;
    description: string;
    fileId: string;
  }[];
  workflowStatus: number;
}

export interface NwowFormConfig {
  // General
  nwowName?: string;
  showBalanceOnApplication?: boolean;
  nwowBalanceAsOfCalcDate?: boolean;
  nwowBalanceAsOfYearEnd?: boolean;
  uom?: string;
  nwowAnalysisCode: any[];
  applyNwowRequiredAttachmentType: any[];
  applyNwowOptionalAttachmentType: any[];
  includeRestDays: boolean;
  includePaidRestDays: boolean;
  includeHolidays: boolean;
  includeStatutoryHolidays: boolean;
  minPreApproveApplication: number;
  maxPreApproveApplication: number;
  useTimeSegmentName: boolean;
  segmentTimeInfo: any;
  periodFromSnapTo?: string;
  periodToSnapTo?: string;

  // Preapproval
  nwowPreApproval: {
    showDate?: boolean;
    showTime?: boolean;
    showUnit?: boolean;
    eventDateApplication?: boolean;
    eventDateLabel?: string;
    unitOfMeasure?: string;
    periodFromSnapTo?: string;
    periodToSnapTo?: string;
    reminderMessage?: string;
    applyEntitlementAnalysisCode: AnalysisCode[];
    applyEntitlementRequiredAttachmentType: any[];
    applyEntitlementOptionalAttachmentType: any[];
    useTimeSegmentName?: boolean;
  }[];
}

export const initFormAnalysisCode = (analysisCodes: AnalysisCode[]) => {
  const _form: any = {};
  analysisCodes.forEach((app) => {
    const _value = app.value ? JSON.parse(app.value)?.[0]?.["Value"] : null;
    _form[app.analysisCode] = _value;
  });
  return _form;
};

export const initFormAttachment = (attachments: any) => {
  const _form: any = {};

  attachments.forEach((attachment: any) => {
    const attachmentValue = {
      id: attachment.attachmentId,
      name: attachment.fileName,
      uploadId: "dummy-uploadId",
      ...attachment,
    };
    if (attachment.code in _form) {
      _form[attachment.code].push(attachmentValue);
    } else {
      _form[attachment.code] = [attachmentValue];
    }
  });

  return _form;
};

export const isAutoCalculateEndDate = (nwowConfig: NwowFormConfig) => {
  return (
    nwowConfig.includeHolidays &&
    nwowConfig.includePaidRestDays &&
    nwowConfig.includeRestDays &&
    nwowConfig.includeStatutoryHolidays &&
    nwowConfig.minPreApproveApplication != null &&
    nwowConfig.minPreApproveApplication !== 0 &&
    nwowConfig.maxPreApproveApplication === nwowConfig.minPreApproveApplication
  );
};
