import "./forgot-password.scss";
import { useState } from "react";

//component
import SecurityQuestion from "./security-question/security-question";
import UserIdentification from "./user-identification/user-identification";
import CommonHeader from "../../../common/common-header";
import LoginWrapper from "../../login-wrapper";

const ForgotPassword = () => {
  const [securityQuestions, setSecurityQuestions] = useState([]);
  const [userNameEmail, setUserNameEmail] = useState();
  const [isValidated, setIsValidated] = useState(false);

  return (
    <CommonHeader>
      <LoginWrapper>
        {!isValidated && (
          <UserIdentification
            setSecurityQuestions={(e) => setSecurityQuestions(e)}
            setUserNameEmail={(e) => setUserNameEmail(e)}
            setIsValidated={(e) => setIsValidated(e)}
          />
        )}
        {isValidated && (
          <SecurityQuestion
            securityQuestions={securityQuestions}
            userNameEmail={userNameEmail}
            setIsValidated={(e) => setIsValidated(e)}
          />
        )}
      </LoginWrapper>
    </CommonHeader>
  );
};

export default ForgotPassword;
