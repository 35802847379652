import { useState, useEffect } from "react";
import { Switch, useRouteMatch, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import LanguageLoader from "./../../../components/language-loader/language-loader";

//components
import MyMenu from "./my-menu/my-menu";
import MySummary from "./my-summary/my-summary";
import { UrlLink } from "./my-information-url";
import PageNotFound from "../public/error/employee-error-404";
import { resetBcDynamicPaths } from "../../../redux/actions/breadcrumb";
import { EEPORTAL_LABEL_MY_INFO, enumsKeyToArray } from "../../../constants";

const MyInformation = () => {
  let match = useRouteMatch();
  const [state] = useState({
    activePanel: 0,
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetBcDynamicPaths());
  }, [dispatch]);

  const renderContent = (activePanel) => {
    return (
      <>
        <MyMenu activePanel={activePanel} />
      </>
    );
  };

  const myInfoRoutes = [
    { path: UrlLink.PersonalInfo },
    { path: UrlLink.Dependents },
    { path: UrlLink.Employments },
    { path: UrlLink.Movements },
    { path: UrlLink.BankAccounts },
    { path: UrlLink.Contact },
  ];

  return (
    <>
      <LanguageLoader contexts={enumsKeyToArray([EEPORTAL_LABEL_MY_INFO])}>
        <MySummary />
        <Switch>
          {myInfoRoutes.map((route, index) => {
            return (
              <Route
                key={`${index}`}
                path={`${match.path}/${route.path}`}
                render={() => renderContent(state.activePanel)}
                exact
                strict
              />
            );
          })}
          <Route
            exact
            path={`${match.path}`}
            render={(/* props */) => renderContent(state.activePanel)}
          />
          <Route component={PageNotFound} />
        </Switch>
      </LanguageLoader>
    </>
  );
};

export default MyInformation;
