import "./view.scss";

//components
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { resetBcDynamicPaths } from "../../../../../redux/actions/breadcrumb";
import { isResponseOk } from "../../../../../utils/utils";
import { formSkeleton } from "../../utils";
import ApplyEntitlementWithDelegation from "./entitlement-delegated";
import { LeaveService } from "../../../../../services/hrmnet-api";

const View = (props) => {
    const dispatch = useDispatch();
    const delegateeMode = props?.location?.state?.delegateeMode;

  const [isLoading, setIsLoading] = useState(true);
  const applicationRef = useRef();

  useEffect(() => {
    dispatch(resetBcDynamicPaths());
  }, [dispatch]);

  useEffect(() => {
    const loadApplication = async () => {
      const applicationId = props.match.params.applicationId;
      let res = await LeaveService.leaveGetLeaveApplicationById({
        applicationId,
        isDelegated: delegateeMode,
      });
      if (isResponseOk(res)) {
        applicationRef.current = res.data;
        setIsLoading(false);
      }
    };
    loadApplication();
  }, [props.match.params.applicationId]);

  if (isLoading) {
    return formSkeleton;
  }

  return (
    <>
      <ApplyEntitlementWithDelegation
        application={applicationRef.current}
        readOnly
      />
    </>
  );
};

export default View;
