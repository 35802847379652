import "./planner.scss";

//components
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setBcDynamicPaths } from "../../../../../redux/actions/breadcrumb";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import moment from "moment";

import { formSkeleton } from "../../utils";
import { Legend } from "../../components";
import {
  FUNCTION_CODE,
  EEPORTAL_LABEL_BASE_TABLE,
  NWOW_SUBMODULE_CODE,
} from "../../../../../constants";
import {
  DATE_TYPE,
  DATE_TYPE_CONFIG,
  NWOW_MENU_ACTION_KEY,
  NWOW_T_CONTEXT_KEY,
} from "../../constants/contants";
import {
  NWOW_WORKFLOW_STATUS,
  NWOW_MENU_MODULE_KEY,
} from "../../constants/contants";
import BaseForm from "./../../../../../components/base-form/base-form";
import { getControlModel } from "./../../../../../components/base-control/base-cotrol-model";
import LanguageLoader from "../../../../../components/language-loader/language-loader";
import { sampleTeamTransactionList } from "./sample";

// services
import { NwowService } from "../../../../../services/hrmnet-api";
import { PortalLink } from "../../../../../services/utils";
import ReactTooltip from "react-tooltip";
import { translateReportingLine } from "../../utils/utils";

const Planner = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let history = useHistory();
  const userProfile = useSelector((state) => state.user?.profile);

  const menus = useSelector((state) => state.menu.sideBar);
  const employeeSearchPermission = true;

  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );

  const [state, setState] = useState({
    isLoading: true,
    isLoadingData: true,
    nwowCodeData: [],
    nwowLegendCodeData: [],
    nwowDateColumn: [],
    leaveData: [],
    expandedRows: [],
    leaveType: [],
    filterExpanded: true,
    form: {},
    formConfigInit: false,
    formConfig: {
      controls: [],
      layout: {},
    },
  });

  useEffect(() => {
    dispatch(setBcDynamicPaths([{ label: t("Nwow_Planner_Title") }]));
  }, [dispatch]);

  const loadFilterConfig = (options, currentFilter) => {
    let renderForm = {
      controls: [
        ...(employeeSearchPermission
          ? [
              getControlModel({
                key: "employeeName",
                label: t("Nwow_Planner_Filter_EmployeeName"),
                type: "multiselect",
                placeholder: t("base_control_multiselect_choose"),
                config: {
                  readOnly: false,
                  filter: true,
                },
              }),
            ]
          : []),
        getControlModel({
          key: "reportingLine",
          label: t("Nwow_Planner_Filter_ReportingLine"),
          type: "select",
          placeholder: t("base_control_multiselect_choose"),
          config: {
            readOnly: false,
            filter: true,
          },
        }),
        getControlModel({
          key: "groupBy",
          label: t("Nwow_Planner_Filter_GroupBy"),
          type: "select",
          placeholder: t("base_control_multiselect_choose"),
          config: {
            readOnly: false,
            filter: true,
          },
        }),
        getControlModel({
          key: "year",
          label: t("Nwow_Planner_Filter_Year"),
          type: "select",
          placeholder: t("base_control_multiselect_choose"),
          config: {
            readOnly: false,
            filter: true,
          },
        }),
        getControlModel({
          key: "fromMonth",
          label: t("Nwow_Planner_Filter_MonthFrom"),
          type: "select",
          placeholder: t("base_control_multiselect_choose"),
          config: {
            readOnly: false,
            filter: true,
          },
        }),
        getControlModel({
          key: "toMonth",
          label: t("Nwow_Planner_Filter_MonthTo"),
          type: "select",
          placeholder: t("base_control_multiselect_choose"),
          config: {
            readOnly: false,
            filter: true,
          },
        }),
      ],
      layout: {
        rows: [
          {
            columns: [
              {
                control: "employeeName",
                config: {
                  className: "p-col-12 p-lg-4 p-md-6 p-sm-12",
                },
              },
              {
                control: "reportingLine",
                config: {
                  className: "p-col-12 p-lg-4 p-md-6 p-sm-12",
                },
              },
            ],
          },
          {
            columns: [
              {
                control: "year",
                config: {
                  className: "p-col-12 p-lg-4 p-md-6 p-sm-12",
                },
              },
              {
                control: "fromMonth",
                config: {
                  className: "p-col-12 p-lg-4 p-md-6 p-sm-12",
                },
              },
              {
                control: "toMonth",
                config: {
                  className: "p-col-12 p-lg-4 p-md-6 p-sm-12",
                },
              },
            ],
          },
        ],
      },
    };

    const months = [
      { value: "1", label: t("Nwow_Planner_Filter_January") },
      { value: "2", label: t("Nwow_Planner_Filter_Febuary") },
      { value: "3", label: t("Nwow_Planner_Filter_March") },
      { value: "4", label: t("Nwow_Planner_Filter_April") },
      { value: "5", label: t("Nwow_Planner_Filter_May") },
      { value: "6", label: t("Nwow_Planner_Filter_June") },
      { value: "7", label: t("Nwow_Planner_Filter_July") },
      { value: "8", label: t("Nwow_Planner_Filter_August") },
      { value: "9", label: t("Nwow_Planner_Filter_September") },
      { value: "10", label: t("Nwow_Planner_Filter_October") },
      { value: "11", label: t("Nwow_Planner_Filter_November") },
      { value: "12", label: t("Nwow_Planner_Filter_December") },
    ];

    renderForm.controls.map((c) => {
      switch (c.key) {
        case "employeeName":
          if (options.employeeCodes) {
            c.enum = options.employeeCodes.map((option) => {
              return {
                ...option,
                label: option.description,
              };
            });
          }
          break;
        case "reportingLine":
          if (options.reportingLine) {
            c.enum = options.reportingLine.map((option) => {
              return {
                label: translateReportingLine(option, t),
                value: option,
              };
            });
          }
          break;
        case "groupBy":
          if (options.groupByList) {
            c.enum = options.groupByList.map((option) => {
              return {
                label: option,
                value: option,
              };
            });
          }
          break;
        case "year":
          if (options.year) {
            c.enum = options.year.map((option) => {
              return {
                ...option,
                label: option.description,
              };
            });
          }
          break;
        case "fromMonth":
        case "toMonth":
          c.enum = months;
          break;
        default:
          break;
      }
      return c;
    });

    let defaultForm = {};
    let currentFilterKey = currentFilter ? Object.keys(currentFilter) : null;
    if (currentFilter && currentFilterKey && currentFilterKey.length) {
      if ("reportingLine" in currentFilter) {
        defaultForm["reportingLine"] =
          currentFilter["reportingLine"].toString();
      }
      if ("filter" in currentFilter) {
        if ("filters" in currentFilter["filter"]) {
          let employeeCode = [];
          currentFilter["filter"]["filters"].forEach((f) => {
            if (f.field === "Employee_Code") {
              employeeCode.push(f.value);
            }
          });
          defaultForm["employeeName"] = employeeCode;
        }
      }
      if (employeeSearchPermission) {
        if (
          options?.employeeCodes.filter((e) => e.value === userProfile.staffId)
        ) {
          let employeeCode = [];
          employeeCode.push(userProfile.staffId);
          defaultForm["employeeName"] = employeeCode;
        }
      }
      if ("groupBy" in currentFilter) {
        defaultForm["groupBy"] = currentFilter["groupBy"].toString();
      }
      if ("year" in currentFilter) {
        defaultForm["year"] = currentFilter["year"].toString();
      }
      if ("fromMonth" in currentFilter) {
        defaultForm["fromMonth"] = currentFilter["fromMonth"].toString();
      }
      if ("toMonth" in currentFilter) {
        defaultForm["toMonth"] = currentFilter["toMonth"].toString();
      }
    }

    setState((state) => ({
      ...state,
      form: defaultForm,
      formConfigInit: true,
      formConfig: renderForm,
    }));
  };

  const summarizeData = (data) => {
    let parsedDataByMonth = [];
    let parsedDataByEmployee = [];
    let nwowCode = {};
    let allLeaveDate = [];
    //
    data.forEach((d) => {
      let dayDataByEmployee = {};
      var num = 0;
      Object.entries(d.calendar).forEach(([monthString, leaveDays]) => {
        let dayDataByMonth = {};

        Object.entries(leaveDays).forEach(([leaveDate, nwowInfo]) => {
          dayDataByMonth[moment(leaveDate).format("D")] = nwowInfo;
          dayDataByEmployee[leaveDate] = nwowInfo;

          // leave day
          if (!(leaveDate in allLeaveDate)) {
            allLeaveDate.push(leaveDate);
          }
          num++;

          nwowInfo.map((ni) => {
            if (!(ni.dateCode in nwowCode)) {
              //
              nwowCode[ni.dateCode] = {};
            }
            if (ni?.nwowRecord) {
              if (!(ni.nwowRecord.nwowCode in nwowCode)) {
                nwowCode[ni.nwowRecord.nwowCode] = {};
              } else if (!nwowCode[ni.nwowRecord.nwowCode]["label"]) {
                nwowCode[ni.nwowRecord.nwowCode]["label"] =
                  ni.nwowRecord?.nwowType;
                nwowCode[ni.nwowRecord.nwowCode]["color"] =
                  ni.nwowRecord?.webColor;
                nwowCode[ni.nwowRecord.nwowCode]["icon"] =
                  ni.nwowRecord?.webAttr;
                nwowCode[ni.nwowRecord.nwowCode]["type"] = ni.nwowRecord?.type;
              }
            }
          });
        });

        let recordByMonth = {
          ...d,
          month: monthString,
          days: dayDataByMonth,
        };
        delete recordByMonth.calendar;
        parsedDataByMonth.push(recordByMonth);
      });

      let recordByEmployee = {
        ...d,
        days: dayDataByEmployee,
      };
      delete recordByEmployee.calendar;
      parsedDataByEmployee.push(recordByEmployee);
    });

    // date column
    allLeaveDate.sort();
    const startDay = moment(allLeaveDate[0]).startOf("month");
    const endDay = moment(allLeaveDate[allLeaveDate.length - 1]).endOf("month");
    let dateColumn = [];
    let monthColSpan = {};
    for (let d = startDay; d < endDay; d.add(1, "days")) {
      if (!dateColumn.includes(d.format("YYYY-MM-DDT00:00:00"))) {
        dateColumn.push(d.format("YYYY-MM-DDT00:00:00"));
      }
      if (!(d.format("M") in monthColSpan)) {
        monthColSpan[d.format("MMM")] = Number(
          moment(d).endOf("month").format("D")
        );
      }
    }

    // Default Nwow Type
    Object.entries(nwowCode).forEach(([lc, lcInfo]) => {
      if (!Object.entries(lcInfo).length) {
        const key = Object.keys(DATE_TYPE).find((k) => DATE_TYPE[k] === lc);
        if (key) {
          nwowCode[lc]["label"] = t("leave_leaveType_".concat(key));
          nwowCode[lc]["color"] = DATE_TYPE_CONFIG[DATE_TYPE[key]]["color"];
        }
      }
    });

    var nwowLegendCode = Object.keys(nwowCode)
      .sort()
      .reduce((obj, key) => {
        obj[key] = nwowCode[key];
        return obj;
      }, {});

    setState((state) => ({
      ...state,
      nwowCodeData: nwowCode,
      nwowLegendCodeData: nwowLegendCode,
      nwowDateColumn: dateColumn,
      leaveData: parsedDataByEmployee,
      expandedRows: parsedDataByEmployee,
    }));
  };

  const loadData = async (params) => {
    setState((state) => ({
      ...state,
      isLoadingData: true,
    }));

    renderDayColumns(columns);

    let body = {};
    if (params && Object.keys(params).length) {
      if ("employeeName" in params && employeeSearchPermission) {
        let employee = params["employeeName"].reduce((acc, e) => {
          acc.push({
            field: "employeeCode",
            operator: "eq",
            value: e,
          });
          return acc;
        }, []);

        body["Filter"] = {
          filters: employee,
          logic: "or",
        };
      }
      //
      if ("reportingLine" in params) {
        body["ReportingLine"] = params["reportingLine"];
      }
      if ("groupBy" in params) {
        body["GroupBy"] = params["groupBy"];
      }
      if ("year" in params) {
        body["Year"] = params["year"];
      }
      if ("fromMonth" in params) {
        body["FromMonth"] = params["fromMonth"];
      }
      if ("toMonth" in params) {
        body["ToMonth"] = params["toMonth"];
      }
    }

    // init params
    var cmd = await NwowService.nwowGetTeamTransactions({
      body: body,
    });

    //
    let [res] = await Promise.all([cmd]);

    // mocked data
    if (res && res.data) {
      if (res.data.employees) {
        summarizeData(res.data.employees);
      }
      let filterOptions = {};
      if (!state.formConfigInit && res.data.filterOptions) {
        filterOptions = res.data.filterOptions;
        loadFilterConfig(res.data.filterOptions, res.data.currentFilter);
      }
      setState((state) => ({
        ...state,
        isLoading: false,
        isLoadingData: false,
      }));
    }
  };

  useEffect(() => {
    loadData();
  }, [selectedLangKey]);

  const columns = [
    {
      header: state.form?.year ? state.form.year : moment().format("YYYY"),
      key: "month",
      width: 4,
      className: "info-cell info-cell__year",
      frozen: true,
    },
    {
      header: t("Nwow_planner_table_employee"),
      key: "employeeName",
      width: 15,
      className: "info-cell info-cell__employee",
      frozen: true,
    },
  ];

  const generateLeavePlanner = () => {
    //
    let params = {};
    const fields = [
      "employeeName",
      "reportingLine",
      "groupBy",
      "year",
      "fromMonth",
      "toMonth",
      "ftTypes",
      "leaveTypes",
    ];

    fields.forEach((f) => {
      if (state.form[f]) {
        params[f] = state.form[f];
      }
    });

    loadData(params);
  };

  // render
  const renderFormSkeleton = () => formSkeleton;

  const renderFilter = () => {
    return (
      <div className="filter-form">
        <div className="title p-mb-4 filter-title">
          {t("Nwow_Planner_Filter_Title")}
          <Button
            className="show-more-btn"
            label={
              state.filterExpanded
                ? t("Nwow_Action_ShowLess")
                : t("Nwow_Action_ShowMore")
            }
            icon={
              state.filterExpanded ? "pi pi-chevron-up" : "pi pi-chevron-down"
            }
            iconPos="left"
            onClick={() => {
              setState((state) => ({
                ...state,
                filterExpanded: !state.filterExpanded,
              }));
            }}
          />
        </div>
        <div
          className={`filter-form-container ${
            !state.filterExpanded ? "filter-form-container-collapsed" : ""
          }`}
        >
          <BaseForm
            id="nwow-planner-filter"
            config={state.formConfig}
            form={state.form}
            // onChange={() => generateLeavePlanner()}
          />
          <div className="filter-form-action">
            <Button
              onClick={() => generateLeavePlanner()}
              className="p-button next-button"
            >
              {t("Nwow_Planner_Action_Generate")}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const renderDayColumns = (cols) => {
    state.nwowDateColumn.forEach((d) => {
      cols.push({
        header: (
          <div>
            <span>
              {moment(d).format("D") === "1" ? moment(d).format("MMM") : ""}
            </span>
            <br />
            <span>{moment(d).format("D")}</span>
          </div>
        ),
        key: d,
        className: "nwow-cell",
        frozen: false,
        width: 2,
        body: (rowData) => {
          if (d in rowData.days) {
            var cellColor = "rgba(0,0,0,0)";
            var dateCode = rowData.days[d][0].dateCode;
            rowData.days[d].map((date) => {
              if (date.nwowRecord?.webAttr === undefined) {
                cellColor = Object.values(DATE_TYPE).includes(date.dateCode)
                  ? DATE_TYPE_CONFIG[
                      DATE_TYPE[
                        Object.keys(DATE_TYPE).find(
                          (k) => DATE_TYPE[k] === date.dateCode
                        )
                      ]
                    ]["color"]
                  : date?.nwowRecord?.webColor;

                dateCode = date?.dateCode;
              }
            });

            const cellBorder =
              rowData.days[d][0]?.nwowRecord?.workflowStatus ===
              NWOW_WORKFLOW_STATUS.COMPLETE
                ? "solid"
                : "";

            const typeS = rowData.days[d][0]?.type;
            const cellIcon = rowData.days[d][0]?.nwowRecord?.webAttr;

            const dateS = rowData.days[d][0].dateFrom;
            var uId = dateCode + dateS;
            uId = uId.replaceAll("-", "");
            uId = uId.replaceAll(":", "");
            uId = uId.replaceAll("T", "");

            return (
              <>
                <div
                  style={{
                    background: cellColor,
                    height: "100%",
                    borderStyle: cellBorder,
                    borderWidth: 3,
                    verticalAlign: "middle",
                    textAlign: "center",
                  }}
                  data-for={uId}
                  data-tip={dateCode}
                >
                  {rowData.days[d].map((date) => {
                    if (date.nwowRecord?.webAttr !== undefined) {
                      var present = false;
                      if (date.nwowRecord.daysOfWeek != undefined) {
                        date.nwowRecord.daysOfWeek.map((val, ind) => {
                          var date = new Date(Date.parse(d));
                          if (date.getDay() == val) {
                            present = true;
                          }
                        });
                      } else {
                        present = true;
                      }
                      if (present) {
                        return (
                          <div
                            className={
                              "pi " + date.nwowRecord?.webAttr + " ppi"
                            }
                            style={{
                              width: "90%",
                              top: 0,
                              height: "auto",
                              bottom: 0,
                              margin: "auto",
                              textAlign: "center",
                              verticalAlign: "",
                            }}
                          >
                            &nbsp;
                          </div>
                        );
                      } else {
                        return <></>;
                      }
                    }
                  })}
                </div>

                {/* <ReactTooltip id={uId} type='dark'  >
                  <span>{dateCode}</span>
                </ReactTooltip> */}
              </>
            );
          }
          return null;
        },
      });
    });

    return cols;
  };

  const renderTableLegend = () => {
    return (
      <div className="nwow-planner-legend">
        <div className="legend-title">{t("Nwow_Planner_Table_Legend")}</div>
        <div className="legend-container">
          <div className="custom-legend approved-nwow">
            <span className="legend-icon legend-square"></span>
            <span className="legend-text">
              {t("Nwow_Planner_Legend_ApprovedNwow")}
            </span>
          </div>
          {Object.keys(state.nwowLegendCodeData).map((lc, i) => {
            var trLabel = state.nwowLegendCodeData[lc].label;
            if (trLabel.includes("_")) {
              trLabel = t(trLabel);
            }

            return (
              <Legend
                key={i}
                legend="square"
                legendColor={state.nwowLegendCodeData[lc].color}
                legendIcon={state.nwowLegendCodeData[lc].icon}
                text={`${lc} - ${trLabel}`}
                textColor={state.nwowLegendCodeData[lc].color}
              />
            );
          })}
        </div>
      </div>
    );
  };

  const renderRegionRow = () => {
    let tableColumns = [...columns];
    tableColumns = renderDayColumns(tableColumns);

    const columnsRender = [];
    tableColumns.forEach((column, index) => {
      const columnConfig = {
        key: `bt-column-${index}`,
        field: column.key,
        header: column.header,
        frozen: column.frozen,
        filter: null,
        showFilter: true,
        sortable: column.sortable,
        className: column.className,
        style: {
          width: column.width ? `${column.width}rem` : "auto",
        },
        headerStyle: {
          width: column.width ? `${column.width}rem` : "auto",
        },
        body: column.body,
      };
      columnsRender.push(<Column {...columnConfig} />);
    });

    return columnsRender;
  };

  const renderLeavePlannerTable = () => {
    return (
      <div>
        {renderTableLegend()}
        <DataTable
          key="info-table"
          value={state.leaveData}
          className="p-datatable-responsive nwow-planner-table"
          scrollable
          frozenWidth="19rem"
          loading={state.isLoadingData}
        >
          {renderRegionRow()}
        </DataTable>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="footer p-grid p-align-center p-justify-between">
        <Button
          onClick={() => {
            history.push(
              PortalLink(
                `${FUNCTION_CODE.Nwow}/${NWOW_SUBMODULE_CODE.SUBMIT_APPLICATION}`
              )
            );
          }}
          className="p-button-outlined secondary"
        >
          {t("Nwow_common_actionBack")}
        </Button>
      </div>
    );
  };

  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          NWOW_T_CONTEXT_KEY.COMMON,
          NWOW_T_CONTEXT_KEY.PLANNER,
        ]}
      />
      <div className="nwow-container nwow-planner">
        <div className="header">
          <div className="title">
            {t("Nwow_Planner_Title")}
            <div className="title-desc">{t("Nwow_Planner_Desc")}</div>
          </div>
        </div>
        <div className="main">
          <div className="nwow-planner-filter">
            {/* Filter */}
            {state.isLoading ? null : renderFilter()}
          </div>

          <div className="nwow-planner-container">
            {/* Leave Planner Table */}
            {state.isLoading ? renderFormSkeleton() : renderLeavePlannerTable()}

            {/* Footer */}
            {state.isLoading ? null : renderFooter()}
          </div>
        </div>
      </div>
    </>
  );
};

export default Planner;
