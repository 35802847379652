import { Card } from "primereact/card";
import { Skeleton } from "primereact/skeleton";
import { useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { PROFILE_IMAGE_PLACEHOLDER } from "../../../../constants/index";

import "./home-profile.scss";

const HomeProfile = () => {
  const { t } = useTranslation();
  const userProfile = useSelector((state) => state.user?.profile);

  // unsubcribe
  const mountedRef = useRef(true);
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const renderAvatar = (profileImageUrl) => {
    return (
      <LazyLoadImage
        className="profile-img"
        alt="profilePic"
        effect="blur"
        src={profileImageUrl ?? PROFILE_IMAGE_PLACEHOLDER.DEFAULT}
        onError={(e) => (e.target.src = PROFILE_IMAGE_PLACEHOLDER.ERROR)}
      />
    );
  };

  const contentWrapper = (title, content, icon) => {
    return (
      <div className="p-d-flex p-mb-2">
        <p className="profile-content">
          {icon ? (
            <i className={`pi ${icon} p-mr-2 p-mt-1`}></i>
          ) : (
            `${t(title)}: `
          )}
          {content == null || undefined ? (
            <>{t("common_notApplicable")}</>
          ) : (
            <>{` ${content}`}</>
          )}
        </p>
      </div>
    );
  };

  return (
    <>
      <div className="profile">
        <Card className="profile-card">
          {!userProfile ? (
            <div className="p-d-flex">
              <Skeleton
                shape="circle"
                size="4rem"
                className="p-mr-2"
              ></Skeleton>
              <div style={{ flex: "1" }}>
                <Skeleton width="100%" className="p-mb-3"></Skeleton>
                <Skeleton width="75%" className="p-mb-1"></Skeleton>
                <Skeleton width="75%"></Skeleton>
              </div>
            </div>
          ) : (
            <>
              <div className="profile">
                <div className="p-d-flex profile-img-position">
                  {renderAvatar(userProfile?.profileImageUrl)}
                  <div className="home-profile-container p-ml-2 p-d-flex p-flex-column">
                    <div className="profile-container-content p-ml-3 p-d-flex p-flex-column">
                      <span className="profile-name p-mb-2">
                        {userProfile?.name == null || undefined ? (
                          <>{t("common_notApplicable")}</>
                        ) : (
                          <>{userProfile?.name}</>
                        )}
                      </span>
                      {contentWrapper(
                        "home_staffId",
                        userProfile?.staffId,
                        null
                      )}
                      {contentWrapper("", userProfile?.mobileNo, "pi-phone")}
                      {contentWrapper("", userProfile?.email, "pi-envelope")}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Card>
      </div>
    </>
  );
};

export default HomeProfile;
