import { saveAs } from "file-saver";

import { NwowService } from "../../../../services/hrmnet-api";
import { DISPLAY_UOM } from "../constants/contants";

export function getDisplayUom(uom, t) {
  if (!uom) return uom;
  if (DISPLAY_UOM[uom]) return t(DISPLAY_UOM[uom]);
  return uom;
}

export function extractUnit(input) {
  if (input == null) return NaN;

  if (typeof input === "string") {
    return Number(input.split(" ")[0]);
  } else {
    return NaN;
  }
}

export async function downloadFile(file) {
  if (file.id != null && file.downloadAttachmentId != null) {
    var res = await NwowService.nwowDownloadAttachment(
      {
        body: { fileId: file.downloadAttachmentId },
      },
      {
        responseType: "blob",
      }
    );
    if (res) {
      var filename = file.name ? file.name : "Document";
      saveAs(res, filename);
    }
  } else {
    // Is already blob
    saveAs(file, file.name);
  }
}

export const translateReportingLine = (data, t) => {
  if (data != null) {
    if (data == "FUNCTION_REPORT_TO") {
      return t("Nwow_report_filter_function_report_to");
    } else {
      return data;
    }
  }
};
