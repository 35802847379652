import "./error-500.scss";

import { useTranslation } from "react-i18next";
import { Animated } from "react-animated-css";

const Page500 = () => {
  const { t } = useTranslation();

  return (
    <>
      <Animated
        animationIn="slideInRight"
        animationOut="slideOutRight"
        animationInDuration={200}
        animationOutDuration={200}
        isVisible={true}
      >
        <div className="error-500-main-bg">
          <p>
            <span className="error-500-main-title">500</span>
            <span className="error-500-main-subtitle">
              {t("common_error_500")}
            </span>
          </p>
        </div>
      </Animated>
    </>
  );
};

export default Page500;
