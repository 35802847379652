import "./payslip.scss";
import { Animated } from "react-animated-css";

import moment from "moment";

import { useEffect, useState } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

//component
import BaseTable, {
  BUILD_SEARCH_CONFIG,
} from "../../../../components/base-table/base-table-portal";
import { DEFAULT_BT_SEARCH } from "../../../../components/base-table/base-table-model";
import { resetBcDynamicPaths } from "../../../../redux/actions/breadcrumb";

//services
import { DocumentsService } from "./../../../../services/hrmnet-api";

//const
import { addRowGroupHeaderFooterTemplate, columnConfig } from "./../utils";
import { tableConfigWithExpander } from "./../utils";
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_FILE_NAME,
  FILE_RELEASE_DATE,
} from "../../../../constants";
import { GetUploadDownload } from "../../../../components/base-control/upload-control/utils";
import { BCType } from "../../../../components/base-control/base-control";

const PaySlip = () => {
  // i18n
  const { t } = useTranslation();
  const dataTable = useRef();
  const downloadRes = useRef();
  const dispatch = useDispatch();
  const [expanderControl, setExpanderControl] = useState();
  const employeeCode = useSelector((state) => state.auth?.employeeCode);
  const selectedLangKey = useSelector(
    (state) => state.language?.language?.selectedLang?.key
  );
  const [rerenderLanguage, setRerenderLanguage] = useState(false);

  /** Default search */
  const BT_SEARCH = {
    ...DEFAULT_BT_SEARCH,
    sortObj: {
      key: FILE_RELEASE_DATE,
      direction: -1,
    },
  };

  // ------------ customFunction ---------------------------------------------------
  const tableSearchFn = async (searchConfig, employeeCode) => {
    if (!searchConfig) {
      searchConfig = { ...BT_SEARCH };
    }
    searchConfig = BUILD_SEARCH_CONFIG(searchConfig);

    try {
      var cmd = await DocumentsService.documentGetPayslips({
        pageIndex: 1,
        pageSize: 999,
      });
    } catch (error) {
      setExpanderControl({ displayedRecord: [] });
      return {
        datas: [],
        total: 0,
        searchConfig: searchConfig,
      };
    }

    const [res] = await Promise.all([cmd]);
    if (res && res.data) {
      setRerenderLanguage(true);
      const _datas = res.data.map((data) => {
        const _data = {
          ...data,
          id: data.id.toString(),
          customFileName: data?.fileName ?? data?.description,
          releaseDate: moment(data.releaseDate, DEFAULT_DATE_FORMAT),
        };

        if (!data?.fileName && !!data?.description) {
          delete _data.description;
        }

        return _data;
      });
      setExpanderControl({ ...expanderControl, displayedRecord: _datas });
      return {
        datas: _datas,
        total: res.data.length,
        searchConfig: searchConfig,
      };
    } else {
      return {
        datas: [],
        total: 0,
        searchConfig: searchConfig,
      };
    }
  };

  // ------------ useEffect ---------------------------------------------------
  useEffect(() => {
    dispatch(resetBcDynamicPaths());
  }, [dispatch]);

  const mountedRef = useRef(false);

  useEffect(() => {
    const initPage = async () => {
      if (mountedRef.current) return null;
    };

    try {
      initPage();
    } finally {
      if (!mountedRef.current) {
        // preventing to load twice
        mountedRef.current = true;
      }
    }
  }, []);

  useEffect(() => {
    if (rerenderLanguage) {
      dataTable.current?.rerenderLanguage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLangKey]);

  const axiosCallback = (resp) => {
    downloadRes.current = resp;
  };

  // actions
  const onSingleDownload = async (item) => {
    await GetUploadDownload(item.id, DEFAULT_FILE_NAME.PAYSLIPS);
  };

  let colCycleName = columnConfig(
    t("payslips_datatable_payCycleName"),
    "cycleName",
    { placeholder: t("payslips_datatable_payCycleName_placeholder") },
    false
  );
  let cycleNameConfig = colCycleName.columnConfig || {};
  cycleNameConfig.sortFunction = (event) => {
    let data = dataTable.current?.getDataSync();

    // Add leading zero if month don't have 2 digits
    const zeroPadMonth = (month) => {
      if (month > 9) {
        return `${month}`;
      }
      return `0${month}`;
    };

    // Convert to a proper date format for sorting
    const convertToDate = (value) => {
      // Return 000000 if string value is empty
      if (value === "") return `000000`;
      const month = zeroPadMonth(value.split("/")[0]);
      const year = value.split("/")[1];
      return `${year}${month}`;
    };

    if (event.order === 1) {
      // Ascending sorting
      return data.sort((a, b) => {
        // let astr = a.cycleName || "";
        // return astr.localeCompare(b.cycleName);

        const value1 = a.cycleName || "";
        const value2 = b.cycleName || "";

        if (convertToDate(value1) < convertToDate(value2)) {
          return -1;
        }
        if (convertToDate(value1) > convertToDate(value2)) {
          return 1;
        }
        return 0;
      });
    } else {
      // Descending sorting
      return data.sort((a, b) => {
        // let astr = a.cycleName || "";
        // return -astr.localeCompare(b.cycleName);

        const value1 = a.cycleName || "";
        const value2 = b.cycleName || "";

        if (convertToDate(value1) > convertToDate(value2)) {
          return -1;
        }
        if (convertToDate(value1) < convertToDate(value2)) {
          return 1;
        }
        return 0;
      });
    }
  };
  colCycleName.columnConfig = cycleNameConfig;

  let configModel = tableConfigWithExpander(
    t,
    t("payslips_payslip"),
    t("payslips_datatable_description"),
    [
      //{ expander: true, style: { width: "3em", padding: "0" } },
      columnConfig(
        t("payslips_datatable_docName"),
        "customFileName",
        { placeholder: t("payslips_datatable_docName_placeholder") },
        false,
        true
      ),
      columnConfig(
        t("payslips_datatable_payCycleYear"),
        "cycleYear",
        {
          placeholder: t("payslips_datatable_payCycleYear_placeholder"),
          type: BCType.multiselect,
        },
        true
      ),
      colCycleName,
      columnConfig(
        t("payslips_datatable_releaseDt"),
        "releaseDate",
        {
          placeholder: t("payslips_datatable_releaseDt_placeholder"),
          type: "daterange",
        },
        true,
        false,
        "date",
        {
          dateFormat: DEFAULT_DATE_FORMAT,
        }
      ),
      columnConfig(
        t("document_datatable_description"),
        "description",
        { placeholder: t("document_datatable_description_placeholder") },
        true,
        false
      ),
    ],
    onSingleDownload,
    expanderControl,
    setExpanderControl
  );

  // Add row group subheader and subfooter
  configModel.tableConfig = addRowGroupHeaderFooterTemplate(
    configModel.tableConfig || {},
    t("base_table_no_company_name_subheader")
  );

  return (
    <div className="ep-payslip-baseTable">
      <Animated
        animationIn="slideInRight"
        animationOut="slideOutRight"
        animationInDuration={200}
        animationOutDuration={200}
        isVisible={true}
      >
        <div className="payslip-table">
          <BaseTable
            ref={dataTable}
            configModel={configModel}
            searchConfig={BT_SEARCH}
            searchFn={() => tableSearchFn(null, employeeCode)}
            isClientSize={true}
            id="payslip-table"
          />
        </div>
      </Animated>
    </div>
  );
};

export default PaySlip;
