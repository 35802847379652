import "./calendar-view.scss";

//components
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBcDynamicPaths } from "../../../../../redux/actions/breadcrumb";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TabMenu } from "primereact/tabmenu";
import { useHistory } from "react-router";

import {
  Calendar,
  Legend,
  NwowMenu,
  ScrollToNwowMenuActiveItem,
} from "../../components";
import {
  DATE_NWOW_STATUS,
  DATE_TYPE_CONFIG,
  NWOW_WORKFLOW_STATUS,
  NWOW_T_CONTEXT_KEY,
  DATE_TYPE,
  NWOW_MENU_MODULE_KEY,
  NWOW_MENU_PAGE_KEY,
  NWOW_MENU_ACTION_KEY,
} from "../../constants/contants";
import {
  getDateArray,
  getStartEndDateOfMonth,
  isResponseOk,
} from "./../../../../../utils/utils";
import { BCType } from "../../../../../components/base-control/base-control";
import { getControlModel } from "./../../../../../components/base-control/base-cotrol-model";
import BaseForm from "../../../../../components/base-form/base-form";
import {
  getAllControls,
  initFormWithNwowApplication as initFormWithNwowApplication,
} from "./config-helper";
import { NwowService } from "../../../../../services/hrmnet-api";
import { openModal } from "../../../../../redux/actions/modal";
import { showSpinner } from "../../../../../redux/actions/spinner";
import {
  EEPORTAL_LABEL_BASE_TABLE,
  FUNCTION_CODE,
  NWOW_SUBMODULE_CODE,
} from "../../../../../constants";
import LanguageLoader from "../../../../../components/language-loader/language-loader";
import { showTimestampToastSuccess } from "../../../../../services/utils/message";
import {
  confirmApproveApplication,
  confirmRejectApplication,
} from "../approval/helper";
import { PortalLink } from "../../../../../services/utils";

const CalendarView = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userProfile = useSelector((state) => state.user?.profile);

  // If specified applicationId
  const viewMode = !!props.application;
  const approverMode = props.enableApproval;

  const initViewDate = viewMode
    ? new Date(props.application.nwowFrom)
    : new Date();
  const initSelectedDate = viewMode ? initViewDate : null;

  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );

  const menus = useSelector((state) => state.menu.sideBar);
  const nwowMenu = menus.find((x) => x.key === NWOW_MENU_MODULE_KEY)?.subMenus;
  const employeeSearchPermission = !!nwowMenu.find(
    (x) => x.key === NWOW_MENU_ACTION_KEY.EMPLOYEE_SEARCH
  );

  const [state, setState] = useState({
    isLoading: true,
    selectedDate: initSelectedDate,
    selectedApplicationList: [],
    selectedApplicationIndex: 0,
    selectedNwowType: null,
    viewDate: initViewDate,
    dateConfig: {},
    monthApplication: {},
    filterForm: {},
    filterFormConfigInit: false,
    filterFormConfig: {
      controls: [],
      layout: {},
    },
    showWorkflow: false,
    isLoadingWorkflow: false,
    applicationWorkflow: null,
    applicationWorkflowInfo: null,
    workflowExpandedRows: null,
    isLoadingWorkflowExpansion: false,
    requestedApplicationId: [],
  });

  const [form, setForm] = useState({});

  useEffect(() => {
    if (approverMode) {
      dispatch(
        setBcDynamicPaths([
          { label: t("Nwow_approval_approveApplication_breadcrumb") },
        ])
      );
    } else if (viewMode) {
      dispatch(
        setBcDynamicPaths([{ label: t("Nwow_viewApplication_breadcrumb") }])
      );
    } else {
      dispatch(
        setBcDynamicPaths([{ label: t("Nwow_calendarView_breadcrumb") }])
      );
    }
  }, [dispatch]);

  useEffect(() => {
    ScrollToNwowMenuActiveItem();
  }, []);

  useEffect(() => {
    const loadDateConfig = async () => {
      setState((state) => ({
        ...state,
        dateConfig: {},
      }));
      const calendarData = await getCalendarConfigOfMonth(state.viewDate);
      const dateConfig = calendarData.dateConfig;
      const monthApplication = {
        ...state.monthApplication,
        ...calendarData.monthApplication,
      };
      setState((state) => ({
        ...state,
        dateConfig,
        monthApplication,
        isLoading: false,
      }));
    };

    const delayTimer = setTimeout(() => {
      loadDateConfig();
    }, 800);
    return () => clearTimeout(delayTimer);
  }, [
    state.viewDate,
    JSON.stringify(state.filterForm["employeeCode"]),
    JSON.stringify(state.filterForm["reportingLine"]),
  ]);

  //
  useEffect(() => {
    if (viewMode) {
      const _form = initFormWithNwowApplication(props.application);
      setForm(_form);
    } else {
      const application = state.selectedApplicationList.find(
        (x) => x.nwowCode === form["NwowCode"]
      );
      if (application) {
        const _form = initFormWithNwowApplication(application);
        setForm(_form);
      }
    }
  }, [
    state.selectedApplicationList,
    form["NwowCode"],
    props.application,
    selectedLangKey,
  ]);

  const getApplicationByDate = async (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    // TODO
    // let res = await NwowService.nwowGetApplications({ year, month, day });

    // if (res && res.data) {
    //   setState((state) => ({
    //     ...state,
    //     selectedApplicationList: res.data,
    //   }));

    const monthKey = [year, "-", month].join("");
    let res;
    if (Object.keys(state.monthApplication).includes(monthKey)) {
      res = {};
      res.data = state.monthApplication[monthKey];
    } else {
      let appRes = await NwowService.nwowGetApplications({
        year,
        month,
      });

      const applicationData = Object.keys(appRes.data).includes("applications")
        ? appRes.data.applications
        : appRes.data;

      res = {};
      res.data = applicationData;
    }

    let dayData = [];
    if (res && res.data) {
      res.data.forEach((app) => {
        if (
          app.workflowStatus === NWOW_WORKFLOW_STATUS.COMPLETE &&
          app.isApproved === true &&
          app.isRejected === false
        ) {
          const allApprovedDates = getDateArray(app.nwowFrom, app.nwowTo);
          allApprovedDates.forEach((date) => {
            const approvedDay = date.getDate();
            if (day === approvedDay) {
              dayData.push(app);
            }
          });
        }
      });

      setState((state) => ({
        ...state,
        selectedApplicationList: dayData,
      }));
      return dayData;
    }

    return null;
  };

  const onSelectDate = async (date) => {
    if (!viewMode) {
      setState((state) => ({
        ...state,
        selectedDate: date,
        selectedApplicationList: [],
        showWorkflow: false,
        applicationWorkflow: null,
        applicationWorkflowInfo: null,
        workflowExpandedRows: null,
      }));
      const applications = await getApplicationByDate(date);
      if (applications?.length) {
        setForm((form) => ({
          ...form,
          NwowCode: applications[0].nwowCode,
        }));
      }
    }
  };

  const onChangeViewDate = (e) => {
    setState((state) => ({
      ...state,
      viewDate: e.value,
    }));
  };

  // Fetch Data
  const getCalendarConfigOfMonth = async (date) => {
    const [monthStartDate, monthEndDate] = getStartEndDateOfMonth(date);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    let applicationApi = NwowService.nwowGetApplications({
      year,
      month,
      ...(employeeSearchPermission ? { isIncludeFilters: true } : {}),
      ...(employeeSearchPermission && state.filterForm.employeeCode
        ? { employeeCode: state.filterForm.employeeCode }
        : {}),
      ...(employeeSearchPermission && state.filterForm.reportingLine
        ? { reportingLine: state.filterForm.reportingLine }
        : {}),
    });

    let dateApi = NwowService.nwowGetDateTypeList({
      fromDate: moment(monthStartDate).format("YYYY-MM-DD"),
      toDate: moment(monthEndDate).format("YYYY-MM-DD"),
    });
    let [dateRes, appRes] = await Promise.all([dateApi, applicationApi]);

    if (dateRes && dateRes.data && appRes && appRes.data) {
      // filter for HR user and calendar view only
      if (
        employeeSearchPermission &&
        !viewMode &&
        !state.filterFormConfigInit
      ) {
        let renderForm = {
          controls: [
            ...(appRes.data.employees
              ? [
                  getControlModel({
                    key: "employeeCode",
                    label: t("Nwow_calendarView_workflow_employeeName"),
                    type: "select",
                    placeholder: t("base_control_multiselect_choose"),
                    config: {
                      readOnly: false,
                      filter: true,
                      display: "chip",
                    },
                  }),
                ]
              : []),
            ...(appRes.data.reportingLines &&
            appRes.data.reportingLines.length > 1
              ? [
                  getControlModel({
                    key: "reportingLine",
                    label: t("Nwow_planner_filter_reportingLine"),
                    type: "select",
                    placeholder: t("base_control_multiselect_choose"),
                    config: {
                      readOnly: false,
                      filter: true,
                    },
                  }),
                ]
              : []),
          ],
          layout: {
            rows: [
              {
                columns: [
                  {
                    control: "employeeCode",
                    config: {
                      className: "p-col-12 p-lg-12 p-md-12 p-sm-12",
                    },
                  },
                  {
                    control: "reportingLine",
                    config: {
                      className: "p-col-12 p-lg-12 p-md-12 p-sm-12",
                    },
                  },
                ],
              },
            ],
          },
        };

        renderForm.controls.map((c) => {
          switch (c.key) {
            case "employeeCode":
              if (appRes.data.employees) {
                c.enum = appRes.data.employees.map((option) => {
                  return {
                    ...option,
                    label: option.description,
                  };
                });
              }
              break;
            case "reportingLine":
              if (appRes.data.reportingLines) {
                c.enum = appRes.data.reportingLines.map((option) => {
                  return {
                    label: option,
                    value: option,
                  };
                });
              }
              break;
            default:
              break;
          }
          return c;
        });

        let defaultForm = {};
        if (appRes.data.employees && appRes.data.employees.length === 1) {
          defaultForm["employeeCode"] = userProfile.staffId;
        }

        setState((state) => ({
          ...state,
          filterForm: defaultForm,
          filterFormConfigInit: true,
          filterFormConfig: renderForm,
        }));
      }

      // set date types
      let calendarDateConfig = dateRes.data.reduce((acc, curr, idx) => {
        const day = new Date(curr.targetDate).getDate();
        acc[day] = { ...DATE_TYPE_CONFIG[curr.dateType] };
        if (
          curr.dateType === DATE_TYPE.HOLIDAY ||
          curr.dateType === DATE_TYPE.STATUTORY
        ) {
          acc[day].holidayName = curr.holidayName;
        }
        return acc;
      }, {});

      // set approved dates
      const applicationData = Object.keys(appRes.data).includes("applications")
        ? appRes.data.applications
        : appRes.data;
      if (!approverMode) {
        let markSummary = {};
        applicationData.forEach((app) => {
          if (
            app.workflowStatus === NWOW_WORKFLOW_STATUS.COMPLETE &&
            app.isApproved
          ) {
            const allApprovedDates = getDateArray(app.nwowFrom, app.nwowTo);
            allApprovedDates
              .filter(
                (date) =>
                  date.getFullYear() === year && date.getMonth() + 1 === month
              )
              .forEach((date) => {
                const day = date.getDate();
                markSummary[day] = markSummary[day] ? true : !app.isRejected;
              });
          }
        });
        Object.entries(markSummary).forEach(([day, showMark]) => {
          if (day in calendarDateConfig && showMark) {
            calendarDateConfig[day].showMark = true;
          }
        });

        // appRes.data.forEach((app) => {
        //   if (app.workflowStatus === nwow_WORKFLOW_STATUS.COMPLETE) {
        //     const allApprovedDates = getDateArray(app.nwowFrom, app.nwowTo);
        //     allApprovedDates
        //       .filter(
        //         (date) =>
        //           date.getFullYear() === year && date.getMonth() + 1 === month
        //       )
        //       .forEach((date) => {
        //         const day = date.getDate();
        //         if (day in calendarDateConfig) {
        //           calendarDateConfig[day].showMark = true;
        //         }
        //       });
        //   }
        // });
      }

      const monthKey = [year, "-", month].join("");
      const calendarData = {
        dateConfig: calendarDateConfig,
        monthApplication: {
          [monthKey]: applicationData,
        },
      };

      return calendarData;
    }
  };

  const getApplicationWorkflow = async (refNo) => {
    if (state.isLoadingWorkflow) return;
    setState((state) => ({
      ...state,
      showWorkflow: true,
      isLoadingWorkflow: true,
    }));

    if (!state.applicationWorkflow) {
      let tranRes = await NwowService.nwowGetNwowTransactionHeaderbyRefNo({
        referenceNo: refNo,
      });

      if (tranRes && tranRes.data) {
        setState((state) => ({
          ...state,
          applicationWorkflow: tranRes.data,
        }));
      }
    }

    setState((state) => ({
      ...state,
      isLoadingWorkflow: false,
    }));

    // scroll to section
    let element = document.querySelector(".nwow-workflow");
    element.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const getWorkflowRowExpansionData = async (appId) => {
    let tranDetailApi = NwowService.nwowGetNwowTransactionDetailbyId({
      applicationId: appId,
    });
    let tranWorkflowApi = NwowService.nwowGetNwowTransactionWorkflowbyId({
      applicationId: appId,
    });
    let [tranDetailRes, tranWorkflowRes] = await Promise.all([
      tranDetailApi,
      tranWorkflowApi,
    ]);

    let tranData = {
      displayTab: 0,
    };
    if (
      tranDetailRes &&
      tranDetailRes.data &&
      tranWorkflowRes &&
      tranWorkflowRes.data
    ) {
      tranData.detail = tranDetailRes.data;
      tranData.content = tranWorkflowRes.data;
    }

    let workflowDetailData = { ...state.applicationWorkflowInfo };
    workflowDetailData[appId] = tranData;

    setState((state) => ({
      ...state,
      applicationWorkflowInfo: workflowDetailData,
    }));
  };

  useEffect(() => {
    setState((state) => ({
      ...state,
      isLoadingWorkflowExpansion: false,
    }));
  }, [state.applicationWorkflowInfo]);

  // action

  // Back
  const backToList = () => {
    history.push(PortalLink(`${FUNCTION_CODE.Nwow}`));
  };

  // Redirect
  const changeApplicationRedirect = () => {
    history.push(
      PortalLink(
        `${FUNCTION_CODE.Nwow}/${NWOW_SUBMODULE_CODE.APPLICATION}/${props.application?.applicationId}/${NWOW_SUBMODULE_CODE.CHANGE}`
      )
    );
  };

  // cancel
  const cancelApplicationModal = () => {
    dispatch(
      openModal({
        title: t("Nwow_viewApplication_cancelTitle"),
        content: t("Nwow_viewApplication_cancelMessage", {
          date: moment(initViewDate).format("YYYY-MM-DD"),
        }),
        classNameMainDialog: "confirm-message-modal form-modal",
        primaryButtonText: t("Nwow_common_actionYes"),
        secondaryButtonText: t("Nwow_common_actionNo"),
        form: {
          config: {
            controls: [
              {
                key: "Remarks", // custom field
                label: t("Nwow_viewApplication_cancelReason"),
                type: BCType.input,
              },
            ],
          },
          form: {},
        },
        primaryButtonClickFn: async ({ closeFn, form }) => {
          await cancelApplication({
            applicationId: props.application?.applicationId,
            remarks: form["Remarks"],
          });
          closeFn();
        },
        secondButtonClickFn: ({ closeFn }) => {
          closeFn();
        },
        force_touched: true,
      })
    );
  };

  const cancelApplication = async ({ applicationId, remarks }) => {
    try {
      dispatch(showSpinner(true));
      const res = await NwowService.nwowCancelNwowApplication({
        applicationId,
        remarks,
      });
      if (isResponseOk(res)) {
        backToList();
        showTimestampToastSuccess({
          message: t("Nwow_calendarView_cancelSuccess"),
          t: t,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(showSpinner(false));
    }
  };

  const redirectToSubmit = () => {
    history.push(
      PortalLink(
        `${FUNCTION_CODE.Nwow}/${NWOW_SUBMODULE_CODE.SUBMIT_APPLICATION}`
      )
    );
  };

  const redirectToNwowBalance = () => {
    history.push(
      PortalLink(`${FUNCTION_CODE.Nwow}/${NWOW_SUBMODULE_CODE.BALANCE}`)
    );
  };

  const renderFilter = () => {
    if (viewMode || !employeeSearchPermission) return;

    return (
      <BaseForm
        id="calendar-view-filter"
        config={state.filterFormConfig}
        form={state.filterForm}
        onChange={(change) => {
          setState((state) => ({
            ...state,
            filterForm: change.state.form,
          }));
        }}
      />
    );
  };

  const renderNwowTypeLegend = () => {
    if (state.isLoading) return null;
    if (!state.selectedApplicationList.length) return null;

    const nwowCodeSet = {};
    state.selectedApplicationList.forEach((app) => {
      nwowCodeSet[app.nwowCode] = {
        nwowCode: app.nwowCode,
        nwowType: app.nwowType,
        webColor: app.webColor,
      };
    });

    return (
      <div className="legend-list">
        <div className="section-title">
          {t("Nwow_calendarView_approvedNwowLegend", {
            date: moment(state.selectedDate).format("D MMMM YYYY"),
          })}
        </div>
        {Object.values(nwowCodeSet).map((app, idx) => (
          <Legend
            key={idx}
            legend="square"
            legendColor={app.webColor}
            text={`${app.nwowCode} - ${app.nwowType}`}
            textColor={app.webColor}
          />
        ))}
      </div>
    );
  };

  const renderDateLegend = () => {
    if (state.isLoading) {
      return null;
    } else {
      return (
        <>
          <div className="legend-list">
            <div className="section-title">
              {t("Nwow_calendarView_dateLegend")}
            </div>
            {Object.keys(DATE_NWOW_STATUS).map((key, idx) => (
              <Legend
                key={idx}
                legend="round"
                legendColor={DATE_NWOW_STATUS[key].color}
                text={t(DATE_NWOW_STATUS[key].label)}
              />
            ))}
            {Object.keys(state.dateConfig).map((day) =>
              Object.keys(state.dateConfig[day]).includes("holidayName") ? (
                <div className="custom-legend statutory-legend">
                  {moment(state.viewDate).date(day).format("MMM D")} -{" "}
                  {state.dateConfig[day]["holidayName"]}
                </div>
              ) : null
            )}
          </div>
        </>
      );
    }
  };

  const renderApplicationMeta = () => {
    if (state.isLoading) return null;

    const application =
      props.application ||
      state?.selectedApplicationList?.find(
        (x) => x.nwowCode === form["NwowCode"]
      );

    if (!application) {
      return (
        <div className="no-nwow-placeholder">
          {t("Nwow_calendarView_noNwows")}
        </div>
      );
    }
    const _form = initFormWithNwowApplication(application);

    const metaControls = [
      ...(!viewMode || approverMode
        ? [
            {
              key: "$Employee", // custom field
              label: t("Nwow_calendarView_employee"),
              type: BCType.input,
            },
          ]
        : []),
      {
        key: "$RefNo", // custom field
        label: t("Nwow_calendarView_refNo"),
        type: BCType.input,
      },
      {
        key: "$Status", // custom field
        label: t("Nwow_calendarView_status"),
        componentRender: () => (
          <>
            <span className="p-ml-1">{application.status}</span>
            <span
              className="nwow-workflow-btn"
              onClick={() => getApplicationWorkflow(application.referenceNo)}
            >
              ({t("Nwow_calendarView_workflow_title")})
            </span>
          </>
        ),
      },
    ];
    return (
      <div className="nwow-application-meta">
        {/* Generl Meta */}
        <BaseForm
          config={{
            controls: metaControls,
          }}
          form={_form}
          readOnly
        ></BaseForm>
      </div>
    );
  };

  const renderApplicationDetail = () => {
    let application;
    let nwowTypes;
    if (viewMode) {
      application = props.application;
      nwowTypes = [application];
    } else {
      application = state?.selectedApplicationList?.find(
        (x) => x.nwowCode === form["NwowCode"]
      );
      nwowTypes = [...state.selectedApplicationList];
    }

    if (state.isLoading || !application) {
      return null;
    }

    if (application) {
      const controls = getAllControls({
        nwowTypes: nwowTypes,
        application: application,
        nwowCodeReadOnly: viewMode,
        hideBalance:
          (viewMode && application?.hideBalance) || !viewMode || approverMode,
        onSeeBalanceDetail: redirectToNwowBalance,
        t,
      });

      // render
      return (
        <div className="nwow-application-detail">
          <BaseForm
            id="nwow-application-detail"
            config={{
              controls,
            }}
            form={form}
            onChange={(state) => {
              setForm((form) => ({
                ...form,
                ...state.form,
              }));
            }}
          />
        </div>
      );
    } else {
      return null;
    }
  };
  const renderWorkflow = () => {
    if (state.isLoading || state.isLoadingWorkflow || !state.showWorkflow) {
      return null;
    }

    const rendernwowWorkflowColumn = (columns) =>
      columns.map((column) => {
        const columnConfig = {
          field: column.key,
          header: column.header,
          style: column.style,
          body: (data) => (
            <>
              <div className="p-column-title">{column.header}: </div>
              <div className="p-column-value">
                {column.key === "nwowFrom" || column.key === "nwowTo"
                  ? moment(data[column.key]).format("YYYY-MM-DD h:mm A (ddd)")
                  : column.key === "submissionDate" ||
                    column.key === "lastUpdated" ||
                    column.key === "activityUpdated"
                  ? moment(data[column.key]).format("YYYY-MM-DD HH:mm")
                  : data[column.key]}
              </div>
            </>
          ),
        };
        return <Column {...columnConfig} />;
      });

    const nwowWorkflowHeaderColumnConfig = [
      {
        key: "applicationId",
        header: "ID",
      },
      {
        key: "submissionDate",
        header: t("Nwow_calendarView_workflow_applicationDate"),
      },
      {
        key: "transactionReferenceNo",
        header: t("Nwow_calendarView_workflow_transactionSequence"),
      },
      {
        key: "transactionType",
        header: t("Nwow_calendarView_workflow_transactionType"),
      },
      {
        key: "nwowFrom",
        header: t("Nwow_calendarView_workflow_startDate"),
      },
      {
        key: "nwowTo",
        header: t("Nwow_calendarView_workflow_endDate"),
      },
      {
        key: "appliedUnits",
        header: t("Nwow_calendarView_workflow_appliedUnitsInPeriod"),
      },
      {
        key: "committedUnits",
        header: t("Nwow_calendarView_workflow_approvedUnitsInPeriod"),
      },
      {
        key: "status",
        header: t("Nwow_calendarView_workflow_status"),
      },
      {
        key: "workflowStatus",
        header: t("Nwow_calendarView_workflow_workflowStatus"),
      },
      {
        key: "lastUpdated",
        header: t("Nwow_calendarView_workflow_approvalRejectDate"),
      },
    ];
    const nwowWorkflowDetailColumnConfig = [
      {
        key: "year",
        header: t("Nwow_calendarView_workflow_year"),
        style: { width: "6rem" },
      },
      {
        key: "period",
        header: t("Nwow_calendarView_workflow_period"),
        style: { width: "6rem" },
      },
      {
        key: "periodDescription",
        header: t("Nwow_calendarView_workflow_periodDescription"),
      },
      {
        key: "nwowFrom",
        header: t("Nwow_calendarView_workflow_startDate"),
      },
      {
        key: "nwowTo",
        header: t("Nwow_calendarView_workflow_endDate"),
      },
      {
        key: "appliedUnits",
        header: t("Nwow_calendarView_workflow_appliedUnitsInPeriod"),
      },
      {
        key: "committedUnits",
        header: t("Nwow_calendarView_workflow_approvedUnitsInPeriod"),
      },
    ];
    const nwowWorkflowContentColumnConfig = [
      {
        key: "activityId",
        header: t("Nwow_calendarView_workflow_activity"),
        style: { width: "6rem" },
      },
      {
        key: "activityUpdated",
        header: t("Nwow_calendarView_workflow_updatedOn"),
        style: { width: "10rem" },
      },
      {
        key: "activityDescription",
        header: t("Nwow_calendarView_workflow_description"),
      },
      {
        key: "userName",
        header: t("Nwow_calendarView_workflow_employeeName"),
      },
      {
        key: "workflowActivityStatus",
        header: t("Nwow_calendarView_workflow_status"),
        style: { width: "8rem" },
      },
      {
        key: "comments",
        header: t("Nwow_calendarView_workflow_comments"),
      },
    ];

    const rendernwowWorkflowHeaderColumn = rendernwowWorkflowColumn(
      nwowWorkflowHeaderColumnConfig
    );
    rendernwowWorkflowHeaderColumn.unshift(
      <Column
        expander
        style={{ width: "3em" }}
        className="workflow-expander-start"
      />
    );
    rendernwowWorkflowHeaderColumn.push(
      <Column
        expander
        style={{ width: "3em" }}
        className="workflow-expander-end"
      />
    );
    const renderNwowWorkflowDetailColumn = rendernwowWorkflowColumn(
      nwowWorkflowDetailColumnConfig
    );
    const renderNwowWorkflowContentColumn = rendernwowWorkflowColumn(
      nwowWorkflowContentColumnConfig
    );

    const workflowTabMenu = [
      { label: t("Nwow_calendarView_workflow_breakdownByPeriods") },
      { label: t("Nwow_calendarView_workflow_title") },
    ];

    const workflowRowExpansion = (data) => {
      if (!state.requestedApplicationId.includes(data.applicationId)) {
        const reqAppId = [...state.requestedApplicationId];
        reqAppId.push(data.applicationId);
        setState((state) => ({
          ...state,
          requestedApplicationId: reqAppId,
          isLoadingWorkflowExpansion: true,
        }));
        getWorkflowRowExpansionData(data.applicationId);
      }

      if (state.isLoadingWorkflowExpansion) {
        return <div>{t("Nwow_calendarView_workflow_loading")} ...</div>;
      } else if (!state.applicationWorkflowInfo) {
        return <div>{t("Nwow_calendarView_workflow_loading")} ...</div>;
      }

      const workflowRowExpansionData =
        state.applicationWorkflowInfo[data.applicationId];

      return (
        <>
          <TabMenu
            model={workflowTabMenu}
            activeIndex={workflowRowExpansionData?.displayTab}
            className="nwow-workflow-tabmenu"
            onTabChange={(e) => {
              let applicationWorkflowInfo = {
                ...state.applicationWorkflowInfo,
              };
              applicationWorkflowInfo[data.applicationId].displayTab = e.index;
              setState((state) => ({
                ...state,
                applicationWorkflowInfo,
              }));
            }}
          />
          {workflowRowExpansionData?.displayTab === 0 ? (
            <DataTable
              value={workflowRowExpansionData.detail}
              emptyMessage={t("Nwow_calendarView_workflow_noDetailsFound")}
              className="nwow-workflow-detail-table"
            >
              {renderNwowWorkflowDetailColumn}
            </DataTable>
          ) : null}
          {workflowRowExpansionData?.displayTab === 1 ? (
            <DataTable
              value={workflowRowExpansionData.content}
              emptyMessage={t("Nwow_calendarView_workflow_noDetailsFound")}
              className="nwow-workflow-content-table"
            >
              {renderNwowWorkflowContentColumn}
            </DataTable>
          ) : null}
        </>
      );
    };

    return (
      <div className="nwow-workflow">
        <div className="nwow-workflow-title">
          {t("Nwow_calendarView_workflow_nwowWorkflow")}
        </div>
        <DataTable
          value={state.applicationWorkflow}
          className="nwow-workflow-header-table"
          expandedRows={state.workflowExpandedRows}
          onRowToggle={(e) => {
            setState((state) => ({
              ...state,
              workflowExpandedRows: e.data,
            }));
          }}
          rowExpansionTemplate={workflowRowExpansion}
        >
          {rendernwowWorkflowHeaderColumn}
        </DataTable>
      </div>
    );
  };

  const renderHeader = () => {
    if (approverMode) {
      return (
        <div className="title">
          {t("Nwow_approval_approveApplicationTitle")}
        </div>
      );
    } else if (viewMode) {
      return (
        <>
          <div className="title">{t("Nwow_viewApplication_title")}</div>
          <Button className="p-button" onClick={redirectToSubmit}>
            {t("Nwow_viewApplication_addNewApplication")}
          </Button>
        </>
      );
    } else {
      return <div className="title">{t("Nwow_calendarView_title")}</div>;
    }
  };

  const renderFooter = () => (
    <div className="footer p-grid p-align-center p-justify-between">
      <Button
        onClick={() => history.goBack()}
        className="p-button-outlined secondary"
      >
        {t("Nwow_common_actionBack")}
      </Button>
      {approverMode && (
        <div className="right-button-group">
          <Button
            onClick={() => {
              confirmApproveApplication({
                applicationId: props.application.applicationId,
                date: new Date(props.application.nwowFrom),
                dispatch,
                history,
                t,
              });
            }}
            className="p-button"
          >
            {t("Nwow_common_actionApprove")}
          </Button>
          <Button
            onClick={() => {
              confirmRejectApplication({
                applicationId: props.application.applicationId,
                date: new Date(props.application.nwowFrom),
                dispatch,
                history,
                t,
              });
            }}
            className="p-button"
          >
            {t("Nwow_common_actionReject")}
          </Button>
        </div>
      )}
      <div className="right-button-group">
        {!approverMode && viewMode && props?.application?.isAllowChange && (
          <Button onClick={changeApplicationRedirect} className="p-button">
            {t("Nwow_viewApplication_changeApplication")}
          </Button>
        )}
        {!approverMode && viewMode && props?.application?.cancellable && (
          <Button onClick={cancelApplicationModal} className="p-button">
            {t("Nwow_viewApplication_cancelApplication")}
          </Button>
        )}
      </div>
    </div>
  );

  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          NWOW_T_CONTEXT_KEY.COMMON,
          NWOW_T_CONTEXT_KEY.CALENDAR_VIEW,
          NWOW_T_CONTEXT_KEY.FORM,
          NWOW_T_CONTEXT_KEY.VIEW_APPLICATION,
        ]}
      />
      <div className="nwow-container nwow-calendar-view">
        <div className="header">
          <NwowMenu
            menu={nwowMenu}
            currentPageKey={NWOW_MENU_PAGE_KEY.CHANGE_TO_CALENDAR_VIEW}
          />
          {renderHeader()}
        </div>
        <div className="main">
          <div className="main-l">
            <div className="main-l-container">
              <div className="nwow-calendar">
                <Calendar
                  value={state.selectedDate}
                  inline
                  onChange={(e) => onSelectDate(e.value)}
                  dateConfig={state.dateConfig}
                  viewDate={state.viewDate}
                  onViewDateChange={onChangeViewDate}
                  className={viewMode ? "hide-arrow" : ""}
                  monthNavigator={viewMode ? false : true}
                  yearNavigator={viewMode ? false : true}
                  yearRange={`${new Date().getFullYear() - 10}:${
                    new Date().getFullYear() + 1
                  }`}
                />
              </div>
              {renderFilter()}
              {renderNwowTypeLegend()}
              {renderDateLegend()}
            </div>
          </div>
          <div className="main-r">
            {renderApplicationMeta()}
            {renderApplicationDetail()}
          </div>
          {renderWorkflow()}
        </div>
        <div className="footer">{renderFooter()}</div>
      </div>
    </>
  );
};

export default CalendarView;
