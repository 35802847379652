import { getControlModel } from "./../../../../components/base-control/base-cotrol-model";
import { useTranslation } from "react-i18next";
function MySummaryForm() {
  const { t } = useTranslation();
  const mySummaryWrapper = (key, label) => {
    return getControlModel({
      key: key,
      label: t(label),
    });
  };
  const FormConfig = {
    controls: [
      mySummaryWrapper("internalJobTitle", "eeinfo_summary_internalJobTitle"),
      mySummaryWrapper("division", "eeinfo_summary_division"),
      mySummaryWrapper("reportTo", "eeinfo_summary_manager"),
      mySummaryWrapper("externalJobTitle", "eeinfo_summary_externalJobTitle"),
      mySummaryWrapper("staffType", "eeinfo_summary_staffType"),
      mySummaryWrapper("jobGrade", "eeinfo_summary_jobGrade"),
    ],
    layout: {
      rows: [
        {
          columns: [
            { control: "internalJobTitle" },
            { control: "division" },
            { control: "reportTo" },
          ],
        },
        {
          columns: [
            { control: "externalJobTitle" },
            { control: "staffType" },
            { control: "jobGrade" },
          ],
        },
      ],
    },
  };

  return FormConfig;
}

export default MySummaryForm;
