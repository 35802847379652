import "./submit.scss";

//components
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import merge from "deepmerge";
import { useHistory } from "react-router";
import { Button } from "primereact/button";

import {
  resetBcDynamicPaths,
  setBcDynamicPaths,
} from "../../../../../redux/actions/breadcrumb";
import { twoColformSkeleton } from "./../../utils/skeleton";
import { useTranslation } from "react-i18next";
import BaseForm from "../../../../../components/base-form/base-form";
import {
  getControlsAndLayout,
  initFormWithLeaveApplication,
  prepareApplicationPayload,
  identifyTimeSegment,
} from "./config-helper";
import { openModal } from "../../../../../redux/actions/modal";
import {
  EEPORTAL_LABEL_BASE_TABLE,
  FUNCTION_CODE,
  LEAVE_SUBMODULE_CODE,
  MESSAGE_SEVERITY,
  MODULE_CODE,
  RESPONSE_MESSAGE_ERROR,
} from "../../../../../constants";
import {
  LEAVE_T_CONTEXT_KEY,
  DATETIME_FORMAT,
  DATE_TYPE,
  DATE_TYPE_CONFIG,
  LEAVE_MENU_MODULE_KEY,
  LEAVE_MENU_PAGE_KEY,
  UOM,
} from "../../constants/contants";
import { LeaveService } from "../../../../../services/hrmnet-api";
import { isResponseOk } from "../../../../../utils/utils";
import {
  showTimestampToastSuccess,
  showToast,
} from "../../../../../services/utils/message";
import { showSpinner } from "../../../../../redux/actions/spinner";
import LanguageLoader from "../../../../../components/language-loader/language-loader";
import { isAutoCalculateEndDate } from "../../utils/config-helper";
import { LeaveMenu, ScrollToLeaveMenuActiveItem } from "../../components";
import { PortalLink } from "../../../../../services/utils";
import axios from "axios";
import { Tooltip } from 'primereact/tooltip';

const mergeControlAndControlConfig = (controls, controlConfig) => {
  return controls.map((control) => {
    if (control.key in controlConfig) {
      return {
        ...control,
        ...controlConfig[control.key],
        config: {
          ...control.config,
          ...controlConfig[control.key].config,
        },
      };
    } else {
      return control;
    }
  });
};

const Submit = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const menus = useSelector((state) => state.menu.sideBar);
  const leaveMenu = menus.find(
    (x) => x.key === LEAVE_MENU_MODULE_KEY
  )?.subMenus;

  const editMode = !!props.application;
  const delegateeMode = props?.delegateeMode ?? false;
  const disabledAppliedDates = true;

  const initState = {
    touched: false,
    isLoading: props?.isUserApplication ?? true,
    isLoadingForm: props?.isUserApplication ?? true,
    disabledLeaveType: true,
  };
  const initForm = {
    EmployeeCode: props?.employeeCode,
    IsUserApplication: props?.isUserApplication,
    LeaveStartDate: null,
    LeaveStartTime: new Date(1970, 1, 1, 9, 0, 0),
    LeaveStartTimeSegment: "AM",
    LeaveEndDate: null,
    LeaveEndTime: new Date(1970, 1, 1, 18, 0, 0),
    LeaveEndTimeSegment: "PM",
  };

  const [state, setState] = useState(initState);
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [leaveInfo, setLeaveInfo] = useState({});
  const [restDates, setRestDates] = useState(null);
  const [leaveAppliedDates, setLeaveAppliedDates] = useState(null);
  const [redDayDates, setRedDayDates] = useState(null);
  const [isInitEditMode, setIsInitEditMode] = useState(editMode);

  const [form, setForm] = useState(initForm);
  const [formControls, setFormControls] = useState([]);
  const [formLayout, setFormLayout] = useState([]);

  // for validation
  const [formControlConfig, setFormControlConfig] = useState({});
  const [triggerCalculateUnit, setTriggerCalculateUnit] = useState(false);
  const [pendingCalculateUnit, setPendingCalculateUnit] = useState(false);
  const [blockCalculateUnit, setBlockCalculateUnit] = useState(false);

  const formRef = React.createRef();
  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );
  const { cancel, token } = axios.CancelToken.source();
  const requestConfig = {
    cancelToken: token,
  };

  // init
  useEffect(() => {
    const getLeaveTypes = async () => {
      try {
        setState({
          ...state,
          disabledLeaveType: true,
        });
        const res = await LeaveService.leaveGetLeaveTypeList(
          {
            employeecode: form["EmployeeCode"],
          },
          requestConfig
        );
        if (res && res.data) {
          setLeaveTypes(res.data);
        }
        setState({
          ...state,
          isLoading: false,
          disabledLeaveType: false,
        });
      } catch (error) {
        setLeaveTypes([]);
      }
    };

    if (!!form["EmployeeCode"] || !!form["IsUserApplication"]) {
      getLeaveTypes();
      ScrollToLeaveMenuActiveItem();
    }

    return () => {
      cancel(RESPONSE_MESSAGE_ERROR.CANCEL);
    };
  }, [
    selectedLangKey,
    props?.employeeList,
    form["EmployeeCode"],
    form["IsUserApplication"],
  ]);

  const calculateUnits = async () => {
    if (!formControls) {
      return;
    }
    if (
      !form["LeaveStartDate"] ||
      !form["LeaveStartTime"] ||
      !form["LeaveEndDate"] ||
      !form["LeaveEndTime"]
    ) {
      setForm((form) => ({
        ...form,
        Units: undefined,
      }));
      return;
    }

    if (blockCalculateUnit) {
      setPendingCalculateUnit(true);
      return;
    }

    // start calculate
    setBlockCalculateUnit(true);
    const payload = prepareApplicationPayload({
      form,
      leaveInfo,
      preApproval: false,
      includeAttachment: false,
      defaultUnits: 1, // Custom logic, requried by backend
    });
    let res = await LeaveService.leaveCalculateUnits(payload);
    if (isResponseOk(res)) {
      // Warning if isPartialBeforeMeal/isPartialAfterMeal
      if (
        (res.data?.startDateInformation &&
          (res.data.startDateInformation?.isPartialBeforeMeal ||
            res.data.startDateInformation?.isPartialAfterMeal)) ||
        (res.data?.toDateInformation &&
          (res.data.toDateInformation?.isPartialBeforeMeal ||
            res.data.toDateInformation?.isPartialAfterMeal))
      ) {
        if (!(leaveInfo.uom === UOM.HOUR && !leaveInfo.useTimeSegmentName)) {
          showToast({
            summary: t("leave_form_messageMismatchTime"),
            severity: MESSAGE_SEVERITY.WARN,
          });
        }
      }

      let updatedLeaveStartTime;
      let updatedLeaveEndTime;
      if (leaveInfo.useTimeSegmentName && res.data?.unitsBeforeThresholdDeduction > 0) {
        let segmentTimeInfo = prepareTimeSegment(res.data);
        updatedLeaveStartTime =
          segmentTimeInfo["start"][form["LeaveStartTimeSegment"]];
        updatedLeaveEndTime =
          segmentTimeInfo["end"][form["LeaveEndTimeSegment"]];
        if (segmentTimeInfo) {
          setLeaveInfo((leaveInfo) => ({
            ...leaveInfo,
            segmentTimeInfo: segmentTimeInfo,
          }));
        }
      }

      setForm((form) => ({
        ...form,
        // LeaveStartDate: leaveInfo.useTimeSegmentName
        //   ? updatedLeaveStartTime
        //   : form["LeaveStartDate"],
        // LeaveStartTime: leaveInfo.useTimeSegmentName
        //   ? updatedLeaveStartTime
        //   : form["LeaveStartTime"],
        // LeaveEndDate: leaveInfo.useTimeSegmentName
        //   ? updatedLeaveEndTime
        //   : form["LeaveEndDate"],
        // LeaveEndTime: leaveInfo.useTimeSegmentName
        //   ? updatedLeaveEndTime
        //   : form["LeaveEndTime"],
        Units: Number(res.data.units),
      }));
    }
  };

  const calculateEndDate = async () => {
    const numDaysToAdd = Number(leaveInfo.minPreApproveApplication) - 1;
    if (
      !isAutoCalculateEndDate(leaveInfo) ||
      !numDaysToAdd ||
      !form["LeaveStartDate"]
    ) {
      return;
    }

    // calculate
    let newEndDate = new Date(form["LeaveStartDate"]);
    newEndDate.setDate(newEndDate.getDate() + numDaysToAdd);
    setForm((form) => ({
      ...form,
      LeaveEndDate: newEndDate,
    }));
  };

  const prepareTimeSegment = (data) => {
    if (
      data?.startDateInformation?.shiftActualStartAt &&
      data?.toDateInformation?.mealActualStartAt &&
      data?.startDateInformation?.mealActualEndAt &&
      data?.toDateInformation?.shiftActualEndAt
    ) {
      const segmentTimeInfo = {
        start: {
          AM: new Date(data.startDateInformation.shiftActualStartAt),
          PM: new Date(data.startDateInformation.mealActualEndAt),
        },
        end: {
          AM: new Date(data.toDateInformation.mealActualStartAt),
          PM: new Date(data.toDateInformation.shiftActualEndAt),
        },
      };
      return segmentTimeInfo;
    }

    return null;
  };

  const dateTemplate = (date) => {
    const application = leaveAppliedDates?.find(
      (d) =>
        d.targetDate.getFullYear() === date.year &&
        d.targetDate.getMonth() === date.month &&
        d.targetDate.getDate() === date.day
    );

    if(!!application)
    {
      return (
        <>
        {application?.tooltip}
        <strong
          className={`${application.target} pointer`}
          style={{
            width: "2rem",
            height: "2rem",
            border: "2px solid",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bold",
          }}
        >
          {date.day}
        </strong>
        </>
        )
    }

    const isRedDay = redDayDates?.find(
      (d) =>
        d.getFullYear() === date.year &&
        d.getMonth() === date.month &&
        d.getDate() === date.day
    );
    if (isRedDay) {
      return (
        <strong
          style={{
            color: "#ba1b22",
            fontWeight: 900,
            opacity: 1,
          }}
        >
          {date.day}
        </strong>
      );
    } else {
      return date.day;
    }
  };

  const updateFormControlConfig = () => {
    let appliedDates = Array.isArray(leaveAppliedDates) ? Array.from(leaveAppliedDates?.filter(x => x?.disabled), x => x.targetDate) : [];

    let disabledDates = [];
    if(!!appliedDates.length && disabledAppliedDates)
    {
      disabledDates = appliedDates
    }
    if(restDates && redDayDates)
    {
      disabledDates = [...disabledDates, ...restDates, ...redDayDates]
    }

    if (formControls.length) {
      setFormControlConfig((formControlConfig) => ({
        ...formControlConfig,
        LeaveStartDate: {
          config: {
            ...formControlConfig?.["LeaveStartDate"]?.config,
            className: form["LeaveStartDate"]
              ? null
              : formControlConfig?.["LeaveStartDate"]?.config.className, // update error status
            // maxDate: form["LeaveEndDate"],
            minDate: leaveInfo?.earliestAllowedDate
              ? new Date(leaveInfo.earliestAllowedDate)
              : new Date(moment().subtract(1, "years").startOf("year")),
            maxDate: leaveInfo?.latestAllowedDate
              ? new Date(leaveInfo.latestAllowedDate)
              : new Date(moment().add(1, "years").endOf("year")),
            viewDate: form["LeaveStartDate"]
              ? form["LeaveStartDate"]
              : form["LeaveEndDate"],
            disabledDates:
            !!disabledDates.length ? disabledDates : null,
            dateTemplate: dateTemplate,
          },
        },
        LeaveEndDate: {
          config: {
            ...formControlConfig?.["LeaveEndDate"]?.config,
            className: form["LeaveEndDate"]
              ? null
              : formControlConfig?.["LeaveEndDate"]?.config.className, // update error status
            // minDate: form["LeaveStartDate"],
            minDate: form["LeaveStartDate"]
              ? form["LeaveStartDate"]
              : leaveInfo?.earliestAllowedDate
              ? new Date(leaveInfo.earliestAllowedDate)
              : new Date(moment().subtract(1, "years").startOf("year")),
            maxDate: leaveInfo?.latestAllowedDate
              ? new Date(leaveInfo.latestAllowedDate)
              : new Date(moment().add(1, "years").endOf("year")),
            viewDate: form["LeaveEndDate"]
              ? form["LeaveEndDate"]
              : form["LeaveStartDate"],
            disabledDates:
            !!disabledDates.length ? disabledDates : null,
            dateTemplate: dateTemplate,
          },
        },
        LeaveStartTime: {
          config: {
            ...formControlConfig?.["LeaveStartTime"]?.config,
            className: form["LeaveStartTime"]
              ? null
              : formControlConfig?.["LeaveStartTime"]?.config.className, // update error status
            readOnly:
              !form["LeaveStartDate"] || isAutoCalculateEndDate(leaveInfo),
          },
        },
        LeaveStartTimeSegment: {
          config: {
            readOnly:
              !form["LeaveStartDate"] || isAutoCalculateEndDate(leaveInfo),
          },
        },
        LeaveEndTime: {
          config: {
            ...formControlConfig?.["LeaveEndTime"]?.config,
            className: form["LeaveEndTime"]
              ? null
              : formControlConfig?.["LeaveEndTime"]?.config.className, // update error status
            readOnly:
              !form["LeaveStartDate"] ||
              !form["LeaveEndDate"] ||
              isAutoCalculateEndDate(leaveInfo),
          },
        },
        LeaveEndTimeSegment: {
          config: {
            readOnly:
              !form["LeaveStartDate"] ||
              !form["LeaveEndDate"] ||
              isAutoCalculateEndDate(leaveInfo),
          },
        },
      }));
    }
  };

  // form validation
  useEffect(() => {
    updateFormControlConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formControls,
    JSON.stringify(form["LeaveStartDate"]),
    JSON.stringify(form["LeaveStartTime"]),
    JSON.stringify(form["LeaveEndDate"]),
    JSON.stringify(form["LeaveEndTime"]),
  ]);

  // autofill end date
  useEffect(() => {
    if (!isInitEditMode) {
      const numDaysToAdd = Number(leaveInfo.minPreApproveApplication) - 1;
      if (
        !isAutoCalculateEndDate(leaveInfo) ||
        !numDaysToAdd ||
        !form["LeaveStartDate"]
      ) {
        let newEndDate = new Date(form["LeaveStartDate"]);
        newEndDate.setDate(newEndDate.getDate());
        if(form["LeaveStartDate"]){
          setForm((form) => ({
            ...form,
            LeaveEndDate: newEndDate,
          }));
        }
        return;
      }
      calculateUnits();
      calculateEndDate();
    }
  }, [JSON.stringify(form["LeaveStartDate"])]);

  // calculate units
  useEffect(() => {
    console.log("isInitEditMode", isInitEditMode);
    if (!isInitEditMode) {
      calculateUnits();
      calculateEndDate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // JSON.stringify(form["LeaveStartDate"]),
    JSON.stringify(form["LeaveStartTime"]),
    JSON.stringify(form["LeaveStartTimeSegment"]),
    JSON.stringify(form["LeaveEndDate"]),
    JSON.stringify(form["LeaveEndTime"]),
    JSON.stringify(form["LeaveEndTimeSegment"]),
  ]);

  // calculate unit trigger
  // this is to cater calling calculateUnits() in the timer
  // timer is initiated once on startup so it cannot access state regularly
  useEffect(() => {
    if (triggerCalculateUnit) {
      setTriggerCalculateUnit(false);
      calculateUnits();
    }
  }, [triggerCalculateUnit]);

  // Timer for execute pending calculate unit job
  React.useEffect(() => {
    const interval = setInterval(() => {
      setBlockCalculateUnit(false);
      setPendingCalculateUnit((pending) => {
        if (pending) {
          setTriggerCalculateUnit(true);
        }
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const loadForm = async () => {
    let leaveInfo = {};

    // prepare to update form
    setState((state) => ({
      ...state,
      isLoadingForm: true,
    }));

    // reset form or init edit application
    let _form = {};
    if (isInitEditMode) {
      _form = { ...form, ...initFormWithLeaveApplication(props.application) };
    } else {
      _form = {
        ...initForm,
        // ...form,
        LeaveCode: form["LeaveCode"],
        EmployeeCode: form["EmployeeCode"],
        IsUserApplication: form["IsUserApplication"],
      };
    }

    // Load form config
    if (_form["LeaveCode"]) {
      let res = await LeaveService.leaveGetLeaveTypesInfoByLeaveCode({
        leavecode: _form["LeaveCode"],
        employeeCode: _form["EmployeeCode"],
      });

      if (res?.data?.length) {
        leaveInfo = { ...res.data[0] };
      } else {
        _form["LeaveCode"] = null;
      }

      // get disallowed days
      if (
        !restDates &&
        !redDayDates &&
        leaveInfo?.earliestAllowedDate &&
        leaveInfo?.latestAllowedDate
      ) {
        const res = await LeaveService.leaveGetDateTypeList({
          fromDate: moment(leaveInfo["earliestAllowedDate"]).format(
            "YYYY-MM-DD"
          ),
          toDate: moment(leaveInfo["latestAllowedDate"]).format("YYYY-MM-DD"),
          employeeCode: form["EmployeeCode"],
        });

        if (res?.data?.length) {
          let restDatesList = [];
          let redDayDatesList = [];
          let leaveAppliedDayDatesList = [];
          res.data.forEach((d, id) => {
            if(!!d?.applications?.length) {
              let unit = 0;
              d?.applications?.forEach(x => {
                if(x?.nwowRecord?.uom === UOM.DAY)
                {
                  // assuming "7.0 Day(s)"
                  unit += parseFloat(x?.nwowRecord?.unit.split(' '));
                }
              });
              leaveAppliedDayDatesList.push({
                target: `application${id}`,
                targetDate: new Date(d.targetDate),
                disabled: unit >= 1,
                tooltip: <Tooltip 
                mouseTrack
                mouseTrackTop={15}
                key={id} 
                position="top"
                target={`.application${id}`} >
                  <div key={id} style={{
                    display: "grid"
                  }}>
                  {
                    d?.applications?.map(x => {
                      if(!x?.nwowRecord?.nwowType)
                      {
                        return;
                      }
                      return <strong>{x?.nwowRecord?.nwowType}</strong>
                    })
                    .filter(x => !!x)
                  }
                  </div>
                </Tooltip>,
              });
              return;
            }

            if (
              [
                DATE_TYPE.REST,
                DATE_TYPE.NON_PAID_REST,
                DATE_TYPE.HOLIDAY,
                DATE_TYPE.STATUTORY,
              ].includes(d.dateType)
            ) {
              if (DATE_TYPE_CONFIG[d.dateType].isRedDay) {
                redDayDatesList.push(new Date(d.targetDate));
              } else {
                restDatesList.push(new Date(d.targetDate));
              }
            }
          });
          setRestDates(restDatesList);
          setLeaveAppliedDates(leaveAppliedDayDatesList);
          setRedDayDates(redDayDatesList);
        }
      }
    }

    // use segment in edit mode
    if (
      editMode &&
      leaveInfo.useTimeSegmentName &&
      !isAutoCalculateEndDate(leaveInfo) &&
      _form["LeaveStartDate"] &&
      _form["LeaveEndDate"]
    ) {
      // temp to identify segment
      const tempPayload = {
        LeaveCode: _form["LeaveCode"],
        UnitOfMeasure: leaveInfo.uom,
        Remarks: "",
        Units: 1,
        LeaveStartDateTimeOrDate: moment(_form["LeaveStartDate"]).format(
          DATETIME_FORMAT
        ),
        LeaveEndDateTimeOrDate: moment(_form["LeaveEndDate"]).format(
          DATETIME_FORMAT
        ),
        Amend: false,
        IsAccrual: false,
      };

      if (!!_form["EmployeeCode"] && !_form["IsUserApplication"]) {
        tempPayload["EmployeeCode"] = _form["EmployeeCode"];
      }

      let unitRes = await LeaveService.leaveCalculateUnits(tempPayload);
      if (isResponseOk(unitRes)) {
        let result = identifyTimeSegment({
          form: _form,
          unitInfo: unitRes.data,
        });
        let updateSegment = {};
        if (result["start"].length > 0) {
          updateSegment["LeaveStartTimeSegment"] = result["start"];
        }
        if (result["end"].length > 0) {
          updateSegment["LeaveEndTimeSegment"] = result["end"];
        }

        _form = {
          ..._form,
          ...updateSegment,
        };

        let segmentTimeInfo = prepareTimeSegment(unitRes.data);
        if (segmentTimeInfo) {
          leaveInfo.segmentTimeInfo = segmentTimeInfo;
        }
      }
    }

    // default time
    if (!editMode && leaveInfo?.officeHrStart && leaveInfo?.officeHrEnd) {
      const startHour = Number((leaveInfo?.officeHrStart).substr(0, 2));
      const startMinute = Number((leaveInfo?.officeHrStart).substr(3, 2));
      const endHour = Number((leaveInfo?.officeHrEnd).substr(0, 2));
      const endMinute = Number((leaveInfo?.officeHrEnd).substr(3, 2));

      const startDateTime = new Date(
        1970,
        1,
        1,
        !isNaN(startHour) ? startHour : 0,
        !isNaN(startMinute) ? startMinute : 0,
        0
      );
      const endDateTime = new Date(
        1970,
        1,
        1,
        !isNaN(endHour) ? endHour : 0,
        !isNaN(endMinute) ? endMinute : 0,
        0
      );

      _form = {
        ..._form,
        LeaveStartTime: startDateTime,
        LeaveEndTime: endDateTime,
      };
    }

    setLeaveInfo(leaveInfo);
    const [controls, layout] = getControlsAndLayout({
      leaveInfo,
      leaveTypes,
      selectedLeaveCode: _form["LeaveCode"],
      onSeeBalanceDetail: redirectToLeaveBalance,
      t,
      delegatedEmployeeList: props?.employeeList,
      form,
      editMode,
      delegateeMode,
    });
    setFormControls(controls);
    setFormLayout(layout);

    setForm(_form);

    // Finish update form
    setState((state) => ({
      ...state,
      isLoadingForm: false,
    }));
  };

  // init when edit application
  useEffect(() => {
    if (isInitEditMode && leaveTypes.length) {
      loadForm();
    }
  }, [leaveTypes]);

  useEffect(() => {
    if (props?.application?.applicationId) {
      dispatch(
        setBcDynamicPaths([{ label: t("leave_submit_breadcrumb_edit") }])
      );
    } else {
      dispatch(setBcDynamicPaths([{ label: t("leave_submit_breadcrumb") }]));
      // dispatch(resetBcDynamicPaths());
    }
  }, [dispatch]);

  // Reload Form when fetched leave types and when leave code is selected
  useEffect(() => {
    // skip reload when init'ing for edit application
    if (
      !isInitEditMode ||
      (!JSON.stringify(form["IsUserApplication"]) &&
        !JSON.stringify(form["EmployeeCode"]) &&
        !editMode)
    ) {
      loadForm();
      setFormControlConfig({});
    }

    // update isInitEditMode when init finished
    if (form["LeaveCode"] && leaveTypes.length && isInitEditMode) {
      setIsInitEditMode(false);
    }
  }, [
    JSON.stringify(form["LeaveCode"]),
    leaveTypes,
    JSON.stringify(form["EmployeeCode"]),
    JSON.stringify(form["IsUserApplication"]),
  ]);

  // Validation
  const validateAll = () => {
    if (!validateBase()) {
      return false;
    }
    setState((state) => ({
      ...state,
      touched: true,
    }));
    return !formRef.current.getFormState().invalid;
  };

  const validateBase = () => {
    let _config = {};
    let hasEmpty = false;
    [
      "LeaveStartDate",
      "LeaveStartTime",
      "LeaveEndDate",
      "LeaveEndTime",
    ].forEach((field) => {
      if (!form[field]) {
        _config[field] = { config: { className: "p-invalid" } };
        hasEmpty = true;
      }
    });

    if (hasEmpty) {
      setFormControlConfig((controlConfig) => merge(controlConfig, _config));
      return false;
    }

    // validate start time < end time
    const payload = prepareApplicationPayload({
      form,
      leaveInfo,
      preApproval: false,
      includeAttachment: false,
    });

    if (
      new Date(payload["LeaveStartDateTimeOrDate"]) >=
      new Date(payload["LeaveEndDateTimeOrDate"])
    ) {
      // bad input

      // highlight error fields
      _config["LeaveStartTime"] = { config: { className: "p-invalid" } };
      _config["LeaveEndTime"] = { config: { className: "p-invalid" } };

      setFormControlConfig((controlConfig) => merge(controlConfig, _config));
      showToast({
        summary: t("leave_form_messagePleaseProvideValidDateRange"),
        severity: MESSAGE_SEVERITY.WARN,
      });
      return false;
    }

    return true;
  };

  const redirectToLeaveBalance = () => {
    let viewBalanceParam = {
      pathname: PortalLink(
        `${FUNCTION_CODE.Leave}/${LEAVE_SUBMODULE_CODE.BALANCE}`
      ),
      state: {
        employeeCode: form["EmployeeCode"],
      },
    };

    if (form["IsUserApplication"] === true) {
      delete viewBalanceParam.state;
    }

    history.push(viewBalanceParam);
  };

  // Save
  const save = async () => {
    try {
      dispatch(showSpinner(true));
      if (!validateBase()) return;

      const applicationId = props?.application?.applicationId;

      const payload = prepareApplicationPayload({
        form,
        leaveInfo,
        preApproval: false,
      });

      let res;
      if (applicationId != null) {
        res = await LeaveService.leaveUpdateLeaveDraftApplication({
          applicationId,
          form: payload,
        });
      } else {
        res = await LeaveService.leaveSaveLeaveDraftApplication(payload);
      }

      if (isResponseOk(res)) {
        backToList();
        showTimestampToastSuccess({
          message: t("leave_submit_saveSuccessMessage"),
          t: t,
        });
      }
    } catch (e) {
    } finally {
      dispatch(showSpinner(false));
    }
  };

  // Submit
  const confirmSubmit = () => {
    if (!validateAll()) {
      return;
    }
    dispatch(
      openModal({
        title: t("leave_message_confirmation"),
        content: t("leave_submit_confirmSubmitMessage"),
        classNameMainDialog: "confirm-message-modal",
        primaryButtonText: t("leave_submit_confirmSubmitButton"),
        primaryButtonClickFn: async ({ closeFn }) => {
          closeFn();
          submitAction();
        },
        secondButtonClickFn: ({ closeFn }) => {
          closeFn();
        },
      })
    );
  };

  const submitAction = async () => {
    try {
      dispatch(showSpinner(true));
      const applicationId = props?.application?.applicationId;
      const payload = prepareApplicationPayload({
        form,
        leaveInfo,
        preApproval: false,
      });
      let res;

      if (applicationId != null) {
        res = await LeaveService.leaveSubmitLeaveDraftApplication({
          applicationId,
          form: payload,
        });
      } else {
        res = await LeaveService.leaveSubmitLeaveApplication(payload);
      }

      if (isResponseOk(res)) {
        backToList();
        showTimestampToastSuccess({
          message: t("leave_submit_submitSuccessMessage"),
          t: t,
        });
      }
    } catch (e) {
    } finally {
      dispatch(showSpinner(false));
    }
  };

  // Back
  const backToList = () => {
    history.push(PortalLink(`${FUNCTION_CODE.Leave}`));
  };

  const confirmBack = async () => {
    dispatch(
      openModal({
        title: t("leave_message_confirmation"),
        content: t("leave_submit_confirmBackMessage"),
        classNameMainDialog: "confirm-message-modal",
        primaryButtonText: t("leave_common_actionConfirm"),
        primaryButtonClickFn: async ({ closeFn }) => {
          closeFn();
          backToList();
        },
        secondButtonClickFn: ({ closeFn }) => {
          closeFn();
        },
      })
    );
  };

  const confirmDeleteDraft = async () => {
    dispatch(
      openModal({
        title: t("leave_editApplication_confirmDeleteTitle"),
        content: t("leave_editApplication_confirmDeleteMessage"),
        classNameMainDialog: "confirm-message-modal",
        primaryButtonText: t("leave_common_actionConfirm"),
        primaryButtonClickFn: async ({ closeFn }) => {
          closeFn();
          deleteDraft();
        },
        secondButtonClickFn: ({ closeFn }) => {
          closeFn();
        },
      })
    );
  };

  const deleteDraft = async () => {
    try {
      dispatch(showSpinner(true));
      const res = await LeaveService.leaveDeleteLeaveDraftApplication({
        applicationId: props.application.applicationId,
        employeeCode: form["EmployeeCode"],
      });
      if (isResponseOk(res)) {
        backToList();
        showTimestampToastSuccess({
          message: t("leave_editApplication_deleteSuccessMessage"),
          t: t,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(showSpinner(false));
    }
  };

  // Render
  const renderSubmitForm = () => {
    if (state.isLoadingForm) {
      return twoColformSkeleton;
    }

    const controls = mergeControlAndControlConfig(
      formControls,
      formControlConfig
    ).filter((x) => !!x);

    // display self checkbox conditionally
    if (props.showSelf === false && Array.isArray(controls)) {
      const index = controls.findIndex((x) => x.key === "IsUserApplication");
      if (index > -1) {
        controls.splice(index, 1);
      }
    }

    const index = controls.findIndex((x) => x.key === "LeaveCode");
    if (index > -1) {
      controls[index] = {
        ...controls[index],
        config: {
          disabled: state.disabledLeaveType,
        },
      };
    }

    return (
      <BaseForm
        ref={formRef}
        config={{
          controls: controls,
          layout: formLayout,
        }}
        form={form}
        touched={state.touched}
        onChange={(change) => {
          let _form = change.state.form;
          const _changed = change.changed;
          if (_changed?.control?.key === "IsUserApplication" && !editMode) {
            _form = {
              ...initForm,
              IsUserApplication: _changed?.data?.value,
            };
          }

          if (
            _changed?.control?.key === "EmployeeCode" &&
            !!_changed?.data?.value &&
            !editMode
          ) {
            _form = {
              ...initForm,
              EmployeeCode: _changed?.data?.value,
              IsUserApplication: false,
            };
          }

          if (
            [
              "LeaveStartDate",
              "LeaveStartTimeSegment",
              "LeaveEndDate",
              "LeaveEndTimeSegment",
            ].includes(_changed?.control?.key)
          ) {
            delete _form.Units;
          }

          setForm({ ..._form });
        }}
      />
    );
  };

  const renderFooter = () => {
    return (
      <div className="footer p-grid p-align-center p-justify-between">
        <div className="left-button-group">
          <Button
            className="p-button-outlined secondary back-button"
            onClick={confirmBack}
          >
            {t("leave_common_actionBack")}
          </Button>
          {editMode && (
            <Button
              className="p-button-outlined secondary back-button"
              onClick={confirmDeleteDraft}
            >
              {t("leave_common_actionDelete")}
            </Button>
          )}
        </div>

        <div className="right-button-group">
          {form["LeaveCode"] && (
            <>
              <Button className="p-button-outlined secondary" onClick={save}>
                {t("leave_common_actionSave")}
              </Button>
              <Button
                className="p-button"
                onClick={confirmSubmit}
                disabled={isNaN(form["Units"])}
              >
                {t("leave_common_actionSubmit")}
              </Button>
            </>
          )}
        </div>
      </div>
    );
  };

  const renderTitle = () => {
    let selectedEmployee;
    if (!!form["EmployeeCode"]) {
      selectedEmployee = props?.employeeList?.find(
        (x) => x?.value === form["EmployeeCode"]
      );
    }

    if (!!form["EmployeeCode"] && selectedEmployee) {
      return t("leave_submit_title_applyDelegated", selectedEmployee);
    }

    return t("leave_submit_title");
  };

  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          LEAVE_T_CONTEXT_KEY.COMMON,
          LEAVE_T_CONTEXT_KEY.SUBMIT_APPLICATION,
          LEAVE_T_CONTEXT_KEY.FORM,
        ]}
      >
        <div className="leave-container leave-submit-application">
          <div className="header">
            <LeaveMenu
              menu={leaveMenu}
              currentPageKey={LEAVE_MENU_PAGE_KEY.SUBMIT_LEAVE_APPLICATION}
            />
            <div className="title">
              <div
                dangerouslySetInnerHTML={{
                  __html: renderTitle(),
                }}
              />
            </div>
          </div>

          {/* Main */}
          <div className="main leave-application-form">
            {state.isLoading ? twoColformSkeleton : renderSubmitForm()}
          </div>

          {/* Footer */}
          {renderFooter()}
        </div>
      </LanguageLoader>
    </>
  );
};

export default Submit;
