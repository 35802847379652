import "./my-menu.scss";

import { useRef } from "react";
import { useState } from "react";
import { Animated } from "react-animated-css";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";

import { TabPanel, TabView } from "primereact/tabview";
import { FUNCTION_CODE } from "./../../../../constants/index";

import { MyMenuTab } from "./my-menu-tab";
import { PortalLink } from "../../../../services/utils";

const MyMenu = (props) => {
  const { t } = useTranslation();
  const [activePanel, setActivePanel] = useState(-1);
  const [navItems, setNavItems] = useState([]);
  let history = useHistory();
  const location = useLocation();
  //tabViewConfig
  const [tabViewVisibility, setTabViewVisibility] = useState({
    psnDisabled: false,
    conDisabled: false,
    depDisabled: false,
    empDisabled: false,
    movDisabled: false,
    bnkDisabled: false,
  });

  // unsubcribe
  const mountedRef = useRef(true);
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const setTabViewVisibilityFunc = (e) => {
    setTabViewVisibility(e);
  };

  useEffect(() => {
    const allNavTabs = MyMenuTab({
      t: t,
      tabViewVisibility: tabViewVisibility,
      setTabViewVisibility: setTabViewVisibilityFunc,
    });

    if (allNavTabs.length > 0) {
      setNavItems(allNavTabs);

      let split = location.pathname.split("/");
      const currPanelIndex = allNavTabs.findIndex(
        (x) => x.path === split[split.length - 1]
      );
      if (currPanelIndex > -1) {
        onTabChange(
          { index: currPanelIndex },
          allNavTabs[currPanelIndex]?.path
        );
      } else {
        onTabChange(
          { index: props.activePanel },
          allNavTabs[props.activePanel]?.path
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, location.pathname, props.activePanel, props.selectedKey]);

  const onTabChange = (e, path = "") => {
    const currPath =
      navItems[e.index]?.path !== undefined
        ? navItems[e.index]?.path
        : path !== ""
        ? path
        : "";
    setActivePanel(e.index);
    const nLoc = PortalLink(
      `${FUNCTION_CODE.EmploymentManagement}/${currPath}`
    );
    if (location.pathname === nLoc) {
      return;
    } else {
      history.push(nLoc);
    }
  };

  const renderContent = () => {
    if (navItems.length > 0) {
      return navItems.map((x, index) => {
        return (
          <TabPanel
            key={index}
            header={x.label}
            contentClassName="ep-tabs"
            disabled={x.disabled}
          >
            <div>{x.form}</div>
          </TabPanel>
        );
      });
    }
  };

  const renderMenuContent = (
    <>
      <div className="profile-tab-panel p-col-12">
        <TabView
          activeIndex={activePanel}
          onTabChange={(e) => {
            onTabChange(e);
          }}
        >
          {renderContent()}
        </TabView>
      </div>
    </>
  );

  return (
    <>
      <Animated
        animationIn="slideInRight"
        animationOut="slideOutRight"
        animationInDuration={200}
        animationOutDuration={200}
        isVisible={true}
      >
        <div className="c-page-body p-grid">{renderMenuContent}</div>
      </Animated>
    </>
  );
};

export default MyMenu;
