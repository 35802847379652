import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { scrollToErrorControl } from "../../../../components/base-form/utils";
import { showSpinner } from "../../../../redux/actions/spinner";
import BaseMultiStepForm from "../../../../components/base-multi-step-form/base-multi-step-form";
import { BaseMultiStepFormSkeleton } from "../../../../components/base-multi-step-form/base-multi-step-form-skeleton";
import {
  BaseMultiStepConfigToFormModel,
  BaseMultiStepConfigToStepNavModel,
  BaseMultiStepCustomFunctionLibrary,
} from "../../../../components/base-multi-step-form/base-multi-step-form-utils";
import { BaseMultiStepFormScrollToMultiStepFormMenuActiveItem } from "../../../../components/base-multi-step-form/base-multi-step-form-scroll";
import {
  setFormLanguage,
  setMainForm,
  setMainFormConfig,
  setMainFormSectionConfig,
  updateMainFormProperties,
} from "../../../../redux/actions/form";
import { showErrorMessageModal } from "../../../../services/utils/message";
import { getResponseMessage, isResponseOk } from "../../../../utils/utils";
import { useHistory } from "react-router-dom";
import {
  BroughtForwardLeavePolicyModes,
  FUNCTION_CODE,
  MODULE_CODE,
} from "../../../../constants";
import { PortalLink } from "../../../../services/utils";
import { store } from "../../../../redux/store";
import { MessageSeverity } from "primereact/api";
import { GetCurrentActions } from "../../../layouts/components/main-sidebar/main-menu-tree";
import {
  FormApiSteps,
  FormSectionFields,
  InitForm,
} from "./leave-configuration-policy-default-config";
import {
  AdminLeavePolicySettingsService,
  EmployeeDetailsService,
  LeaveService,
  MasterService,
} from "../../../../services/hrmnet-api";

const LeaveConfigurationPolicyForm = ({ location }) => {
  const policyId = location?.state?.policyId;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  let toast = store.getState().global.toast;

  const [state, setState] = useState({
    isLoading: true,
    activeStep: 0,
    formResp: {},
    touched: undefined,
  });

  const actionsAccessRights = useSelector((state) => {
    const result = [];
    GetCurrentActions(state.menu.sideBar, result, 1, false);
    return result;
  });

  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );
  const formLanguage = useSelector((state) => state.commonForm.formLanguage);

  const form = useSelector((state) => state.commonForm.form);
  const selectedTemplateName = useSelector(
    (state) => state.commonForm.form?.TemplateName
  );
  const setForm = (x) => dispatch(setMainForm(x));
  const formConfig = useSelector((state) => state.commonForm.formConfig);
  const setFormConfig = (x) => dispatch(setMainFormConfig(x));
  const setFormSectionConfig = (x) => dispatch(setMainFormSectionConfig(x));
  const updateFormProperties = (x) => dispatch(updateMainFormProperties(x));

  const formObjRef = React.createRef();

  let customFunctionLibrary = BaseMultiStepCustomFunctionLibrary({
    dispatch,
  });

  customFunctionLibrary["onDetailsUpdate"] = async ({
    update,
    control,
    section,
  }) => {
    const restrictedFields = ["bfUnitUom", "bfUnit", "bfExpiryUom", "bfExpiry"];
    const formProperties = {
      details: {
        headers: [],
      },
    };
    if (
      update.key === "mode" &&
      update.isHeaderForm &&
      !!update.value &&
      update.value !== BroughtForwardLeavePolicyModes.BringForwardAndForfeiture
    ) {
      restrictedFields.forEach((x) => {
        formProperties.details.headers.push({
          key: x,
          required: true,
          config: {
            readOnly: true,
            disabled: true,
          },
        });
      });
      updateFormProperties(formProperties);
    } else if (
      update.key === "mode" &&
      (!update.value ||
        (update.isHeaderForm &&
          update.value ===
            BroughtForwardLeavePolicyModes.BringForwardAndForfeiture))
    ) {
      restrictedFields.forEach((x) => {
        formProperties.details.headers.push({
          key: x,
          required: true,
          config: {
            readOnly: false,
            disabled: false,
          },
        });
      });
      updateFormProperties(formProperties);
    }
  };

  useEffect(() => {
    const loadForm = async () => {
      const applicationId = selectedTemplateName;
      const isLangChange = formLanguage != selectedLangKey;
      dispatch(setFormLanguage(selectedLangKey));

      let stepRes = FormApiSteps(t);

      let stepList = [];
      let newForm = InitForm;
      let newFormResp = {};
      let newFormConfig = {};

      var companyList = [];
      var employeelist = [];
      var modelist = [];
      var cutUomlist = [];
      var expiryUomlist = [];
      var unitUomlist = [];
      var gradelist = [];
      var staffTypelist = [];
      var teamlist = [];
      var leaveCodelist = [];

      const employeeListApi = EmployeeDetailsService.employeeGetEmployeeList();
      const companyListApi = MasterService.masterGetCompanyList();
      const modelistApi =
        AdminLeavePolicySettingsService.policySettingsGetModes();
      const cutUomApi =
        AdminLeavePolicySettingsService.policySettingsGetCutOffUoms();
      const expiryUomApi =
        AdminLeavePolicySettingsService.policySettingsGetExpiryUoms();
      const unitUomApi =
        AdminLeavePolicySettingsService.policySettingsGetUnitUoms();
      const gradeApi = EmployeeDetailsService.employeeGetGradesAsync();
      const staffTypesApi = EmployeeDetailsService.employeeGetStaffTypesAsync();
      const teamApi = EmployeeDetailsService.employeeGetTeamsAsync();
      const leaveCodeApi = LeaveService.leaveGetLeaveCodesAsync();

      if (!policyId) {
        [
          companyList,
          employeelist,
          modelist,
          cutUomlist,
          unitUomlist,
          expiryUomlist,
          gradelist,
          staffTypelist,
          teamlist,
          leaveCodelist,
        ] = await Promise.all([
          companyListApi,
          employeeListApi,
          modelistApi,
          cutUomApi,
          unitUomApi,
          expiryUomApi,
          gradeApi,
          teamApi,
          staffTypesApi,
          leaveCodeApi,
        ]);
      } else {
        var currentForm;
        const formApi =
          AdminLeavePolicySettingsService.policySettingsGetPolicyAsync({
            id: policyId,
          });
        [
          companyList,
          employeelist,
          currentForm,
          modelist,
          cutUomlist,
          unitUomlist,
          expiryUomlist,
          gradelist,
          staffTypelist,
          teamlist,
          leaveCodelist,
        ] = await Promise.all([
          companyListApi,
          employeeListApi,
          formApi,
          modelistApi,
          cutUomApi,
          unitUomApi,
          expiryUomApi,
          gradeApi,
          staffTypesApi,
          teamApi,
          leaveCodeApi,
        ]);
        newForm = currentForm.data;
      }
      let formConfigRes = FormSectionFields(t, {
        bfExpiryUom: expiryUomlist,
        bfUnitUom: unitUomlist,
        mode: modelist,
        leaveCode: leaveCodelist,
      });

      if (stepRes && stepRes.data && formConfigRes && formConfigRes.data) {
        //partial hardcore, find ways to completely detach from code

        let customData = {
          customFunctionLibrary: customFunctionLibrary,
        };
        stepList = BaseMultiStepConfigToStepNavModel(stepRes.data);

        // init form and active step
        if (
          !isLangChange ||
          (form &&
            Object.keys(form).length === 0 &&
            Object.getPrototypeOf(form) === Object.prototype)
        ) {
          // newForm = InitForm;
        } else {
          newForm = form;
        }

        // newFormResp and formConfig
        newFormResp = formConfigRes.data;
        newFormConfig = BaseMultiStepConfigToFormModel({
          applicationId: applicationId,
          stepConfig: stepRes.data,
          sectionFieldConfig: formConfigRes.data.sections,
          customData: customData,
          t: t,
          form: newForm,
          dropdownOptions: {
            employeeId: employeelist,
            companyCode: companyList,
            team: teamlist,
            staffType: staffTypelist,
            grade: gradelist,
            bfCutOffUom: cutUomlist,
          },
        });
      }

      setForm(newForm);
      setFormConfig(newFormConfig);
      setFormSectionConfig(newFormResp);

      setState({
        ...state,
        stepList,
        isLoading: false,
      });
    };

    loadForm();

    BaseMultiStepFormScrollToMultiStepFormMenuActiveItem(".form-title");

    return () => {
      setState({
        ...state,
        isLoading: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyId]);

  // Navigation and action
  const validateCurrentStep = () => {
    const isValid = !formObjRef.current.getActiveFormState({ expand: true })
      .invalid;
    setTimeout(() => {
      scrollToErrorControl();
    }, 100);
    return isValid;
  };

  const confirmSubmitApplication = async () => {
    if (validateCurrentStep()) {
      submitApplicationAction();
    } else {
      setState({
        ...state,
        touched: true,
      });
    }
  };

  const submitApplicationAction = async () => {
    try {
      // show spinner
      dispatch(showSpinner(true));
      const _form = { ...form };
      Object.keys(form).forEach((key) => {
        if (!form[key]) {
          delete _form[key];
        }
      });

      const details = form?.details ?? [];
      details.forEach((x, index) => {
        if (typeof x?.id === "string" && x?.id?.startsWith("hris_")) {
          delete form?.details[index]?.id;
        }
      });

      _form.details = form?.details.map((x) => {
        return {
          ...x,
          bfExpiry: x.bfExpiry.toString(),
          bfUnit: x.bfUnit.toString(),
        };
      });
      let res =
        await AdminLeavePolicySettingsService.policySettingsSavePolicyAsync({
          body: {
            ..._form,
          },
        });
      if (isResponseOk(res)) {
        toast.show({
          severity: MessageSeverity.SUCCESS,
          detail: t("admin_common_submitMessageSuccess"),
          life: 3000,
        });

        history.push(
          `/${MODULE_CODE.Admin}/${FUNCTION_CODE.LeaveConfiguration}/${FUNCTION_CODE.LeaveConfigurationPolicy}`
        );
      } else {
        showErrorMessageModal({
          dispatch,
          t,
          content: getResponseMessage(res),
        });
      }
    } catch (e) {
      // show notification?
      console.error(e.message);
    } finally {
      // end spinner
      dispatch(showSpinner(false));
    }
  };

  function onChange({ state, changed }) {
    if (
      [
        "commencementDateMonthOfService",
        "dateJoinGroupMonthOfService",
      ].includes(changed?.control?.key)
    ) {
      const showCommencementDateMonthOfService =
        !!state?.form?.commencementDateMonthOfService &&
        !state?.form?.dateJoinGroupMonthOfService;
      const showDateJoinGroupMonthOfService =
        !!state?.form?.dateJoinGroupMonthOfService &&
        !state?.form?.commencementDateMonthOfService;
      const formProperties = {
        commencementDateMonthOfService: {
          layoutConfig: {
            hidden: false,
          },
        },
        dateJoinGroupMonthOfService: {
          layoutConfig: {
            hidden: false,
          },
        },
      };

      if (showCommencementDateMonthOfService) {
        formProperties.dateJoinGroupMonthOfService.layoutConfig.hidden = true;
      }
      if (showDateJoinGroupMonthOfService) {
        formProperties.commencementDateMonthOfService.layoutConfig.hidden = true;
      }
      updateFormProperties(formProperties);
    }
  }

  // Rendering
  const renderForm = () => (
    <BaseMultiStepForm
      form={form}
      activeStep={state.activeStep}
      config={formConfig}
      ref={formObjRef}
      touched={state.touched}
      onChange={onChange}
    />
  );

  const renderFooter = () => {
    return (
      <div className="footer p-grid p-align-center p-justify-between">
        <Button
          label={t("admin_common_actionCancel")}
          className="p-button-secondary"
          onClick={() =>
            history.push(
              PortalLink(
                `${MODULE_CODE.Admin}/${FUNCTION_CODE.EmailConfiguration}/${FUNCTION_CODE.EmailReminder}`
              )
            )
          }
        />
        <div className="right-button-group">
          <Button
            onClick={() => confirmSubmitApplication()}
            className="p-button next-button"
          >
            {t("admin_common_actionSubmit")}
          </Button>
        </div>
      </div>
    );
  };

  // Rendering
  const renderFormTitle = () => (
    <div className="form-title">
      <h2>
        {actionsAccessRights.length > 0 ? actionsAccessRights[0]?.name : ""}
      </h2>
    </div>
  );

  return (
    <>
      <div className=" admin-form-style">
        <div className="header header-sticky">
          {/* Title */}
          {renderFormTitle()}
        </div>
        {state.isLoading ? (
          <div className="form-loading">{BaseMultiStepFormSkeleton}</div>
        ) : (
          renderForm()
        )}

        {/* Footer */}
        {renderFooter()}
      </div>
    </>
  );
};

export default LeaveConfigurationPolicyForm;
