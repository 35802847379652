import "./change-password.scss";

import { useState } from "react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Animated } from "react-animated-css";

// services
import { ChangePasswordService } from "./../../../services/hrmnet-api";

//component
import BaseForm from "./../../../components/base-form/base-form";
import { getControlModel } from "./../../../components/base-control/base-cotrol-model";
import LanguageLoader from "./../../../components/language-loader/language-loader";

//redux
import { setBcDynamicPaths } from "./../../../redux/actions/breadcrumb";
import { EEPORTAL_LABEL_CHANGE_PWD, enumsKeyToArray } from "../../../constants";
import { resetAuthInfo } from "../../../redux/actions/auth";
import { Card } from "primereact/card";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [state, setState] = useState({
    loading: false,
    form: {},
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    dispatch(
      setBcDynamicPaths([{ label: t("employeeProfile_changePassword") }])
    );
  }, [dispatch, t]);

  useEffect(() => {
    if (isSubmitted) {
      dispatch(resetAuthInfo());
    }

    return () => setIsSubmitted(false);
  }, [isSubmitted]);

  const formSubmitFn = (payload) => {
    const { form } = payload;
    return ChangePasswordService.changePasswordChangePassword({ body: form });
  };

  const afterFormSubmitFn = async ({ res, formState }) => {
    if (res && res?.data && res.data?.status) {
      setIsSubmitted(true);
      setState({ form: null });
    }
    if (!formState.invalid) {
      return true;
    }
    return false;
  };

  //form layout
  const renderForm = {
    controls: [
      getControlModel({
        required: true,
        noRequiredLabel: true,
        key: "oldPassword",
        label: t("employeeProfile_changePassword_currentPassword"),
        type: "password",
        placeholder: " ",
        config: {
          feedback: false, // we don't need to show strength during login
          toggleMask: true,
        },
        showPasswordHint: false,
        ruleList: [
          {
            name: "required",
            error: t("employeeProfile_changePassword_error_required"),
          },
        ],
      }),
      getControlModel({
        key: "newPassword",
        label: t("employeeProfile_changePassword_newPassword"),
        type: "password",
        placeholder: " ",
        required: true,
        noRequiredLabel: true,
        showPasswordHint: true,
        config: {
          toggleMask: true,
        },
      }),
      getControlModel({
        key: "confirmPassword",
        label: t("employeeProfile_changePassword_verifyPassword"),
        type: "password",
        placeholder: " ",
        required: true,
        noRequiredLabel: true,
        showPasswordHint: false,
        isPasswordUpdate: true,
        config: {
          feedback: false, // we don't need to show strength during login
          toggleMask: true,
        },
      }),
    ],
    layout: {
      rows: [
        {
          columns: [
            {
              control: "oldPassword",
            },
          ],
        },
        {
          columns: [
            {
              control: "newPassword",
            },
          ],
        },
        {
          columns: [
            {
              control: "confirmPassword",
            },
          ],
        },
        {
          config: {
            style: {
              marginBottom: "1rem",
              marginTop: "1rem",
            },
          },
          columns: [
            {
              action: {
                type: "submit",
                label: t("employeeProfile_changePassword_title"),
                submitingLabel: t("Submitting"),
                config: {
                  kind: "primary",
                  style: {
                    width: "100%",
                    textAlign: "left",
                    minHeight: "3.5rem",
                  },
                },
              },
            },
            {},
          ],
        },
      ],
    },
    formSubmitFn,
    afterFormSubmitFn,
  };

  return (
    <>
      <Animated
        animationIn="slideInRight"
        animationOut="slideOutRight"
        animationInDuration={200}
        animationOutDuration={200}
        isVisible={true}
      >
        <LanguageLoader contexts={enumsKeyToArray([EEPORTAL_LABEL_CHANGE_PWD])}>
          <Card>
            <h1 className="cp-title">
              {t("employeeProfile_changePassword_title")}
            </h1>
            <div className="p-col-12 p-md-6 p-lg-6">
              <BaseForm
                id="changePassword"
                config={renderForm}
                form={state.form}
              />
            </div>
          </Card>
        </LanguageLoader>
      </Animated>
    </>
  );
};

export default ChangePassword;
