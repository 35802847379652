import "./date-range-control.scss";

import {
  BCProps,
  BCStates,
  BCValidateResult,
  isError,
  validateControl,
} from "../base-control";
import React, { useEffect, useRef, useState } from "react";
import { jsonEqual, sleep } from "./../../../utils/utils";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Menu } from "primereact/menu";
import { MenuItem } from "primereact/components/menuitem/MenuItem";
import { OverlayPanel } from "primereact/overlaypanel";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { DEFAULT_DATE_FORMAT } from "../../../constants";
/**
 *
 */
export interface DateRangeControlQuickOption extends MenuItem {
  /**
   * Funtion caculate date
   */
  optionFn?: () => any[];
  /**
   * Value of option
   */
  value?: string;
}

export interface DateRangeControlProps extends BCProps {
  /**
   * Unix id
   */
  quickOption?: DateRangeControlQuickOption[];
}

export interface DateRangeControlState extends BCStates {
  /**
   * Data of control
   */
  valueTerm: any;
  /**
   * Display text of value
   */
  valueStrTerm: string;
  /**
   * State of control
   */
  controlStateTerm: BCValidateResult;
  /**
   * Display view cal first
   */
  viewFirst: Date;
  /**
   * Display view cal second
   */
  viewSecond: Date;

  /**
   * Display picker calender
   */
  viewPicker: boolean;
}

/**
 * Get text display of date range
 * @param dateRange
 * @param placeholder
 */
const getDateRangeValue = (dateRange: any[]): string => {
  let result = "";
  if (Array.isArray(dateRange)) {
    if (dateRange.length >= 1 && dateRange[0]) {
      result = "";
      result = result + moment(dateRange[0]).format(DEFAULT_DATE_FORMAT);
    }
    if (dateRange.length === 2 && dateRange[1]) {
      result =
        result + " - " + moment(dateRange[1]).format(DEFAULT_DATE_FORMAT);
    }
  }
  return result;
};

const DateRangeControl: React.FC<DateRangeControlProps> = (props) => {
  let panelRef: any = useRef();
  const { t } = useTranslation();
  // extract props
  const ruleList = props.ruleList || [];
  if (props.required) {
    ruleList.push({
      name: "required",
    });
  }
  // Get quickaction
  let quickOption: DateRangeControlQuickOption[] = props.quickOption || [
    {
      label: t("base_control_date_range_this_quater"),
      value: "THIS_QUARTER",
      optionFn: () => {
        return [
          moment().startOf("quarter").toDate(),
          moment().endOf("quarter").toDate(),
        ];
      },
    },
    {
      label: t("base_control_date_range_last_quater"),
      value: "LAST_QUARTER",
      optionFn: () => {
        return [
          moment().subtract(1, "quarter").startOf("quarter").toDate(),
          moment().subtract(1, "quarter").endOf("quarter").toDate(),
        ];
      },
    },
    {
      label: t("base_control_date_range_this_year"),
      value: "THIS_YEAR",
      optionFn: () => {
        return [
          moment().startOf("year").toDate(),
          moment().endOf("year").toDate(),
        ];
      },
    },
    {
      label: t("base_control_date_range_last_year"),
      value: "LAST_YEAR",
      optionFn: () => {
        return [
          moment().subtract(1, "year").startOf("year").toDate(),
          moment().subtract(1, "year").endOf("year").toDate(),
        ];
      },
    },
  ];

  quickOption.push({
    label: t("base_control_date_range_custom_range"),
    value: "CUSTOM_RANGE",
  });

  quickOption.unshift({
    label: t("base_control_date_range_all_data"),
    value: "ALL",
    optionFn: () => {
      return [];
    },
  });
  quickOption = quickOption.map((x) => {
    return {
      ...x,
      command: async (e: any) => {
        try {
          e.stopPropagation();
        } catch (error) {}
        if (x.optionFn) {
          let value = x.optionFn();
          onChange({ value }, true);
        } else {
          let _state = caculateCalenderView(state);
          _state.viewPicker = !_state.viewPicker;
          if (!mountedRef.current) return null;
          setState(_state);
          try {
            var node;
            node = document.getElementById(props.id || "dummy");

            await sleep(10);
            node?.click();
            node?.click();
          } catch (error) {}
        }
      },
    };
  });
  // State
  let initState: DateRangeControlState = {
    touched: false,
    loading: true,
    value: props.value || [],
    valueStr: getDateRangeValue(props.value || []),
    controlState: {
      invalid: false,
    },
    controlStateTerm: {
      invalid: false,
    },
    valueTerm: null,
    valueStrTerm: "",
    viewFirst: new Date(),
    viewSecond: new Date(),
    viewPicker: false,
  };
  initState.valueStrTerm = initState.value;
  initState.valueStrTerm = initState.valueStr;

  initState.controlState =
    props.controlState ||
    validateControl(ruleList || [], initState.value, t, "daterange");
  if (initState.controlState.invalid) {
    if (props.onChange) {
      props.onChange({
        controlState: initState.controlState,
        value: initState.value,
        valueStr: initState.valueStr,
      });
      if (props.onTrueUpdateValue) {
        props.onTrueUpdateValue({
          controlState: initState.controlState,
          value: initState.value,
          valueStr: initState.valueStr,
        });
      }
    }
  }
  // prepare state
  const caculateCalenderView = (
    _state: DateRangeControlState
  ): DateRangeControlState => {
    let firstValue, secondValue, viewFirst, viewSecond;
    let value = _state.valueStrTerm;
    if (Array.isArray(value) && value.length === 2) {
      firstValue = value[0];
      if (firstValue) {
        firstValue = moment(firstValue);
      } else {
        firstValue = moment();
      }
      viewFirst = firstValue.startOf("month").toDate();

      secondValue = value[1];
      if (secondValue) {
        secondValue = moment(secondValue);
      } else {
        secondValue = moment();
      }
      if (
        secondValue.month() === firstValue.month() &&
        secondValue.year() === firstValue.year()
      ) {
        secondValue = secondValue.add(1, "months");
      }
      viewSecond = secondValue.endOf("month").toDate();
    } else {
      viewFirst = moment().startOf("month").toDate();
      viewSecond = moment().add(1, "months").startOf("month").toDate();
    }
    return {
      ..._state,
      viewFirst,
      viewSecond,
    };
  };

  initState = caculateCalenderView(initState);

  initState.controlStateTerm = validateControl(
    [
      ...ruleList,
      {
        name: "required",
      },
    ],
    initState.valueTerm,
    t,
    "daterange"
  );
  initState.controlState = validateControl(
    ruleList,
    initState.valueTerm,
    t,
    "daterange"
  );
  const [state, setState] = useState(initState);
  const mountedRef = useRef(true);
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);
  /**
   * Check hide first cal next and second cal prev button
   */
  const isDisableFirstNextSecondPrev = () => {
    let _state = { ...state };
    let result =
      moment(_state.viewFirst).year() === moment(_state.viewSecond).year() &&
      moment(_state.viewFirst).add(1, "months").month() ===
        moment(_state.viewSecond).month();
    return result;
  };
  /**
   * Update view
   * @param event
   * @param panel
   */
  const onViewDateChange = (event: any, panel: any) => {
    let _state = { ...state };
    if (panel === "first") {
      _state.viewFirst = event.value;
    } else {
      _state.viewSecond = event.value;
    }

    if (!mountedRef.current) return null;
    setState(_state);
  };
  /**
   * On change calender
   * @param event
   * @param setValue Set value to control
   */
  const onChange = async (event: any, setValue: boolean) => {
    const valueTerm = event.value;
    const valueStrTerm = getDateRangeValue(valueTerm);
    const controlStateTerm = validateControl(
      [
        ...ruleList,
        {
          name: "required",
        },
      ],
      valueTerm,
      t,
      "daterange"
    );
    let _state: DateRangeControlState = {
      ...state,
      valueTerm,
      valueStrTerm,
      controlStateTerm,
      loading: false,
    };
    if (setValue) {
      panelRef.hide();
      _state.viewPicker = false;
      _state.value = valueTerm;
      _state.valueStr = valueStrTerm;
      _state.controlState = validateControl(
        ruleList,
        valueTerm,
        t,
        "daterange"
      );

      if (props.onChange) {
        props.onChange({
          controlState: _state.controlState,
          value: _state.value,
          valueStr: _state.valueStr,
        });
      }
      if (props.onTrueUpdateValue) {
        props.onTrueUpdateValue({
          controlState: _state.controlState,
          value: _state.value,
          valueStr: _state.valueStr,
        });
      }
    }
    _state = caculateCalenderView(_state);
    if (!jsonEqual(_state, state)) {
      if (!mountedRef.current) return null;
      setState(_state);
    }
  };

  const onFocus = (e: any) => {
    let _state = { ...state };
    _state.touched = true;
    if (!jsonEqual(_state, state)) {
      if (!mountedRef.current) return null;
      setState(_state);
    }
  };
  const onClick = (e: any) => {
    e.preventDefault();
    if (panelRef && !panelRef.state.visible) {
      panelRef.show(e);
    }
  };
  const onChangeInput = async (e: any) => {
    const value = e.target.value || "";
    // True format "{start} - {end}"
    let split = value.split("-");
    if (split.length === 2) {
      let start = moment(split[0].trim(), DEFAULT_DATE_FORMAT, true);
      let end = moment(split[1].trim(), DEFAULT_DATE_FORMAT, true);
      if (start && end && start <= end) {
        await onChange(
          {
            value: [start.toDate(), end.toDate()],
          },
          true
        );
        return;
      }
    }
    if (!mountedRef.current) return null;
    setState({
      ...state,
      valueStr: e.target.value,
    });
  };
  const onBlur = () => {
    const valueStr = getDateRangeValue(state.value);
    if (state.valueStr !== valueStr) {
      if (!mountedRef.current) return null;
      setState({
        ...state,
        valueStr,
      });
    }
  };
  return (
    <>
      <div
        className={`date-range-control-inner p-field ${
          props.noLabel ? "no-label" : ""
        }`}
      >
        <label htmlFor={props.id}>
          {props.label}
          {props.required && !props.noRequiredLabel ? (
            <small className="required p-invalid">&nbsp;*</small>
          ) : null}
          {props.tooltip ? (
            <Button
              type="button"
              tooltip={props.tooltip}
              tooltipOptions={{ position: "top" }}
              icon="pi pi-info-circle"
              className="p-button-rounded label-help p-button-text p-button-plain"
            />
          ) : null}
        </label>
        <div
          className={`p-inputgroup ${
            isError(state, props) ? "p-inputgroup-error" : ""
          }`}
        >
          {props.fromFilter ? (
            <InputText
              id={props.id}
              className={`${props.className} ${
                state.controlState.invalid && state.touched ? "p-invalid" : ""
              } `}
              placeholder={props.placeholder}
              autoFocus={props.autoFocus}
              value={state.valueStr}
              onFocus={onFocus}
              onBlur={onBlur}
              readOnly
              onChange={onChangeInput}
              onClick={(e) => onClick(e)}
              tooltip={state.valueStr}
              tooltipOptions={{ position: "top" }}
            />
          ) : (
            <div className="p-inputgroup">
              <InputText
                id={props.id}
                className={`${props.className} ${
                  state.controlState.invalid && state.touched ? "p-invalid" : ""
                } `}
                placeholder={props.placeholder}
                autoFocus={props.autoFocus}
                value={state.valueStr}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={onChangeInput}
                onClick={(e) => onClick(e)}
                tooltip={state.valueStr}
                tooltipOptions={{ position: "top" }}
                // readOnly
              />
              <Button
                type="button"
                className="p-component p-datepicker-trigger"
                icon="pi pi-calendar"
                id={props.id + "-btn"}
                onClick={(e) => onClick(e)}
              />
            </div>
          )}

          <OverlayPanel
            ref={(el) => (panelRef = el)}
            appendTo={document.body}
            className={`
            bc-drc-range-panel
            ${state.viewPicker ? "view-picker" : ""}
            `}
          >
            <div className="panel-body">
              <div className={`quick-action`}>
                <Menu model={quickOption} />
              </div>
              {state.viewPicker ? (
                <div
                  className={`cal-holder  
                 ${
                   isDisableFirstNextSecondPrev()
                     ? "is-disable-first-next-second-prev"
                     : ""
                 }
                 `}
                >
                  <div className="first-cal">
                    <Calendar
                      value={state.valueTerm}
                      readOnlyInput
                      numberOfMonths={1}
                      selectionMode="range"
                      viewDate={state.viewFirst}
                      inline
                      onChange={(e) => onChange(e, false)}
                      onViewDateChange={(e) => onViewDateChange(e, "first")}
                    />
                  </div>
                  <div className="second-cal">
                    <Calendar
                      value={state.valueTerm}
                      readOnlyInput
                      numberOfMonths={1}
                      selectionMode={"range"}
                      viewDate={state.viewSecond}
                      inline
                      onChange={(e) => onChange(e, false)}
                      onViewDateChange={(e) => onViewDateChange(e, "second")}
                    />
                  </div>
                </div>
              ) : null}
            </div>
            {state.viewPicker ? (
              <div className={`panel-footer p-menu`}>
                <div className="display-date">{state.valueStrTerm}</div>
                <Button
                  label={t("base_control_date_range_quick_select")}
                  onClick={() => {
                    setState({
                      ...state,
                      viewPicker: false,
                    });
                  }}
                  icon="pi pi-caret-left"
                  iconPos="right"
                  className="back-quick-select p-button-link"
                />
                <div className="actions">
                  <div className="carbon-btn-group">
                    <Button
                      type="button"
                      label={t("base_control_date_range_cancel")}
                      // icon="pi pi-times"
                      className="p-button-secondary"
                      onClick={() => {
                        if (!mountedRef.current) return null;
                        setState({
                          ...state,
                          valueTerm: state.value,
                          viewPicker: false,
                          valueStrTerm: state.valueStr,
                        });
                        panelRef.hide();
                      }}
                    />
                    <Button
                      type="button"
                      onClick={() => {
                        onChange(
                          {
                            value: state.valueTerm,
                          },
                          true
                        );
                      }}
                      disabled={state.controlStateTerm.invalid}
                      label={t("base_control_date_range_apply")}
                      autoFocus
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </OverlayPanel>
        </div>
        {state.controlState.invalid && state.controlState.error ? (
          <small
            id={`${props.id}-error`}
            className="p-invalid p-d-block p-invalid-custom"
          >
            {t(state.controlState.error, state.controlState.ruleDetail)}
          </small>
        ) : null}
      </div>
    </>
  );
};

export default DateRangeControl;
