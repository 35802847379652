import { Button } from "primereact/button";
import { Column, ColumnProps } from "primereact/column";
import {
  DataTable,
  DataTableRowToggleParams,
  DataTableSelectionChangeParams,
} from "primereact/datatable";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { jsonEqual } from "../../../utils/utils";
import {
  BCProps,
  BCStates,
  isError,
  renderError,
  validateControl,
} from "../base-control";
import "./datatable-control.scss";

export interface DataTableControlState extends BCStates {}

export interface DataTableControlProps extends BCProps {}

const DataTableControl: React.FC<DataTableControlProps> = (props) => {
  const {
    columns,
    collapseOnParentSelected = false,
    expandOnChildSelected = false,
    rowExpansionTemplateConfig,
    dataKey,
    parentKey,
    childrenKey,
    ...configuration
  } = props.config;
  const { t } = useTranslation();
  const ruleList = props.ruleList || [];
  if (props.required) {
    ruleList.push({
      name: "required",
    });
  }

  function ArrayToList(value: any[]) {
    return value.map((x: any) => {
      let result: any = {};
      result[dataKey] = x;
      return result;
    });
  }

  let initState: DataTableControlState = {
    touched: false,
    loading: true,
    value: Array.isArray(props.value) ? ArrayToList(props.value) : [],
    valueStr: Array.isArray(props.value) ? props.value.join(",") : props.value,
    controlState: {
      invalid: true,
    },
  };

  const [state, setState] = useState(initState);
  let initExpandedRows: any[] = [];
  const [expandedRows, setExpandedRows] = useState(initExpandedRows);
  const mountedRef = useRef(true);
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (
      Array.isArray(props.value) &&
      Array.isArray(configuration?.value) &&
      expandOnChildSelected
    ) {
      const expansion: any = {};
      configuration?.value.forEach((element: any) => {
        if (
          element.hasOwnProperty(childrenKey) &&
          Array.isArray(element[childrenKey])
        ) {
          const children: any[] = Array.from(
            element[childrenKey],
            (c: any) => c[dataKey]
          );
          const found = props.value.some((r: any) => children.includes(r));
          if (found) {
            expansion[element[dataKey]] = true;
          }
        }
      });
      setExpandedRows(expansion);
    }
  }, [props.value]);

  function RemoveDuplicate(obj: any[]) {
    return obj.filter((value, index) => {
      const _value = value[dataKey];
      return (
        index ===
        obj.findIndex((obj) => {
          return obj[dataKey] === _value;
        })
      );
    });
  }

  function RemoveChildren(obj: any[]) {
    var newValue: any[] = [];
    const sortFirstLevel = obj
      .filter((x: any) => !!x && !x.hasOwnProperty(parentKey))
      .sort((a, b) => a[dataKey] - b[dataKey]);
    const sortChilren = obj
      .filter((x: any) => !!x && !!x.hasOwnProperty(parentKey))
      .sort((a, b) => a[parentKey] - b[parentKey]);
    var _obj = [...sortFirstLevel, ...sortChilren];
    _obj.forEach((x: any) => {
      if (!!x && x.hasOwnProperty(dataKey)) {
        if (x.hasOwnProperty(parentKey)) {
          if (newValue.find((y: any) => y[dataKey] === x[parentKey])) {
            newValue.push(x);
          }
        } else {
          newValue.push(x);
        }
      }
    });
    return newValue;
  }

  const onChange = async (
    e: DataTableSelectionChangeParams,
    childrenModules: any[],
    isLevelOne: boolean = false
  ) => {
    let data: any[] = e.value;

    if (childrenModules) {
      var _data: any[] = [];

      //adding children
      if (isLevelOne) {
        const currentArray = e.value.filter((x: any) =>
          x.hasOwnProperty(childrenKey)
        );
        currentArray.forEach((element: any) => {
          if (
            !!element &&
            element.hasOwnProperty(childrenKey) &&
            Array.isArray(element[childrenKey]) &&
            element[childrenKey]?.length > 0
          ) {
            if (
              state.value.findIndex(
                (x: any) => x[dataKey] === element[dataKey]
              ) < 0
            ) {
              _data = [..._data, ...element[childrenKey]];
            }
          }
        });
      }

      //adding parent
      if (!isLevelOne && Array.isArray(e.value)) {
        //ensure leavel one menu is selected
        e.value.forEach((child: any) => {
          const parent = configuration.value
            .filter((x: any) => x.hasOwnProperty(childrenKey))
            .find((x: any) => {
              if (x.hasOwnProperty(childrenKey)) {
                return Array.from(
                  x[childrenKey],
                  (y: any) => y[dataKey]
                ).includes(child[dataKey]);
              }
              return false;
            });
          if (
            !!parent &&
            !data.find((x: any) => x[dataKey] === parent[dataKey])
          ) {
            _data.push({ ...parent });
          }
        });
      }

      //if parent is unchecked, chidlren also unselected
      data.push(
        ...[..._data, ...state.value].filter(
          (x: any) => !childrenModules.includes(x[dataKey])
        )
      );

      if (e.value.length > 0) {
        data = RemoveDuplicate(data);
      }
      data = RemoveChildren([...data]);
    }

    //close expansion when level one is selected
    if (collapseOnParentSelected) {
      const _expandedRows = expandedRows;
      Object.keys(expandedRows).forEach((key: any) => {
        if (
          data.find((_data: any) => {
            return _data[dataKey].toString() === key.toString();
          })
        ) {
          delete _expandedRows[key];
        }
      });

      setExpandedRows(_expandedRows);
    }

    let valueStr: any = Array.from(data, (x: any) => x[dataKey]);
    const controlState = validateControl(ruleList, valueStr.join(","), t);
    let _state = {
      ...state,
      value: data,
      valueStr,
      controlState,
      loading: false,
    } as any;
    if (props.onChange) {
      props.onChange({
        controlState: _state.controlState,
        value: valueStr,
        valueStr: valueStr.join(","),
      });
    }
    if (props.onTrueUpdateValue) {
      props.onTrueUpdateValue({
        controlState: _state.controlState,
        value: valueStr,
        valueStr: valueStr.join(","),
      });
    }

    if (!jsonEqual(_state, state)) {
      setState(_state);
    }
  };

  const renderColumns = () => {
    return columns?.map((x: any, idx: number) => {
      return (
        <Column
          {...x}
          body={(data: any, props: ColumnProps) =>
            x.body(
              data,
              props,
              Array.from(
                state.value,
                (sValue: any) => sValue[dataKey]
              ).includes(data[dataKey])
            )
          }
          key={idx}
        />
      );
    });
  };

  const rowClass = (data: any) => {
    if (isError(state, props)) {
      return {
        "p-invalid": data !== null,
      };
    }

    return { "data-table-row": data };
  };

  function GetSelectionValues(values: any[]) {
    if (Array.isArray(state?.value) && Array.isArray(values)) {
      return Array.from(state?.value, (sValue: any) => sValue[dataKey])
        .filter((_value: any) =>
          Array.from(values, (sValue: any) => sValue[dataKey]).includes(_value)
        )
        .map((_value: any) => {
          return values.find((x: any) => x[dataKey] === _value);
        });
    }

    return [];
  }

  const renderControl = () => {
    return (
      <DataTable
        className={`${props.className} ${
          isError(state, props) ? "p-invalid" : ""
        }`}
        rowClassName={rowClass}
        dataKey={dataKey}
        selectionMode="checkbox"
        selection={GetSelectionValues(configuration.value)}
        //expander configuration
        expandedRows={expandedRows}
        onRowToggle={(e: DataTableRowToggleParams) => setExpandedRows(e.data)}
        rowExpansionTemplate={(data: any) => {
          return (
            <div className="datatable-control-rowExpansionTemplate">
              {rowExpansionTemplateConfig.map((x: any) => {
                var value: any = data.children;
                const field = x.field || "";

                //only display when parent is selected, apply to 3nd level onwards

                const isParentSelected = (child: any) => {
                  if (!!state.value && state.value.length > 0) {
                    return (
                      state.value.findIndex(
                        (y: any) => y[dataKey] === child[parentKey]
                      ) > -1
                    );
                  }
                  return false;
                };
                const fieldValues = value[field]?.filter((x: any) => {
                  return x[parentKey] === data[dataKey] || isParentSelected(x);
                });

                if (!!fieldValues && fieldValues.length > 0) {
                  return (
                    <DataTable
                      rowClassName={rowClass}
                      key={`${x.field}`}
                      dataKey={dataKey}
                      value={fieldValues}
                      selection={GetSelectionValues(fieldValues)}
                      selectionMode="checkbox"
                      onSelectionChange={(event: any) =>
                        onChange(
                          event,
                          Array.from(
                            value[field],
                            (_value: any) => _value[dataKey]
                          )
                        )
                      }
                      showSelectionElement={() =>
                        !props.config?.disabled || !props.config?.readOnly
                      }
                    >
                      <Column
                        selectionMode="multiple"
                        headerStyle={{ width: "3em" }}
                      ></Column>
                      {x.columnConfig.map((y: any) => {
                        return (
                          <Column
                            className="datatable-control-rowExpansion-column"
                            key={`${x.field}-${y.field}`}
                            {...y}
                          ></Column>
                        );
                      })}
                    </DataTable>
                  );
                }
              })}
            </div>
          );
        }}
        {...configuration}
        onSelectionChange={(event: any) =>
          onChange(
            event,
            Array.from(configuration.value, (x: any) => x[dataKey]),
            true
          )
        }
        showSelectionElement={() =>
          !props.config?.disabled || !props.config?.readOnly
        }
        id={props.id}
      >
        <Column
          selectionMode="multiple"
          headerStyle={{ width: "3em" }}
        ></Column>
        {renderColumns()}
      </DataTable>
    );
  };

  return (
    <div
      className={`data-table-control-inner p-field ${
        props.noLabel ? "no-label" : ""
      }`}
    >
      <label htmlFor={props.id}>
        {props.label}
        {props.required && !props.noRequiredLabel ? (
          <small className="required p-invalid">&nbsp;*</small>
        ) : null}
        {props.tooltip ? (
          <Button
            type="button"
            tooltip={props.tooltip}
            tooltipOptions={{ position: "top" }}
            icon="pi pi-info-circle"
            className="p-button-rounded label-help p-button-text p-button-plain"
          />
        ) : null}
      </label>
      <div
        className={`p-inputgroup ${
          isError(state, props) ? "p-inputgroup-error" : ""
        }`}
      >
        {renderControl()}
        {props.hintRight && (
          <span className={"control-hint-right"}>{props.hintRight}</span>
        )}
      </div>
      {props.hintBottom && (
        <div className={"control-hint-bottom"}>{props.hintBottom}</div>
      )}
      {renderError(state, props, t)}
    </div>
  );
};

export default DataTableControl;
