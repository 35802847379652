import "./login-form.scss";

import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";

//prime
import { TabView, TabPanel } from "primereact/tabview";
import {
  LOGIN_OPTION,
  QUERY_PARAMS,
} from "./../../../../../../constants/index";
//component
import SSOLogin from "./../sso-login/sso-login";
import StandardLogin from "./../standard-login/standard-login";
import EmailLogin from "./../email-login/email-login";
import BiometricLogin from "../biometric-login/biometric-login";
import LoginWrapper from "../../login-wrapper";
import { useSelector } from "react-redux";
import {
  CompareParamValue,
  GetParamValue,
} from "../../../../../../utils/utils";
import UnifySunshineLogin from "../unify-sunshine-login/unify-sunshine-login";
import SupportLogin from "../support-login/support-login";

const LoginForm = () => {
  const { t } = useTranslation();
  const [activeLoginPanel, setActiveLoginPanel] = useState(0);
  const [authenticationPanels, setAuthenticationPanels] = useState([]);
  const clientInfo = useSelector((state) => state.global?.clientInfo);

  const LoginMethodTabMenuItems = [
    {
      id: LOGIN_OPTION.LOGIN_ENABLE_SSO,
      label: "login_SSO",
      component: SSOLogin,
    },
    {
      id: LOGIN_OPTION.LOGIN_ENABLE_USR,
      label: "login_usernameSignIn",
      component: StandardLogin,
    },
    {
      id: LOGIN_OPTION.LOGIN_ENABLE_EML,
      label: "login_emailSignIn",
      component: EmailLogin,
    },
    {
      id: LOGIN_OPTION.LOGIN_ENABLE_BIO,
      label: "login_biometricSignIn",
      component: BiometricLogin,
      className: "p-d-md-none",
    },
  ];

  useEffect(() => {
    const getLoginMethods = async () => {
      if (!!clientInfo?.authenticationTypes) {
        const _authenticationPanels = LoginMethodTabMenuItems.filter(
          (panel) => {
            const isMobileApp = CompareParamValue(
              QUERY_PARAMS.TYPE,
              QUERY_PARAMS.MOBILE
            );

            return clientInfo?.authenticationTypes.some((x) => {
              if (
                !isMobileApp &&
                x.toUpperCase() === panel.id.toUpperCase() &&
                panel.id === LOGIN_OPTION.LOGIN_ENABLE_BIO
              ) {
                return false;
              }

              return x.toUpperCase() === panel.id.toUpperCase();
            });
          }
        );

        setAuthenticationPanels(_authenticationPanels);

        var adfsToken = GetParamValue(QUERY_PARAMS.SSO_ADFS_TOKEN);
        if (!!adfsToken) {
          const ssoAuthenticationId = _authenticationPanels.findIndex(
            (panel) => panel?.id === LOGIN_OPTION.LOGIN_ENABLE_SSO
          );
          if (ssoAuthenticationId > -1) {
            setActiveLoginPanel(ssoAuthenticationId);
          }
        }
      }
    };

    getLoginMethods();

    return () => {
      setActiveLoginPanel(0);
      setAuthenticationPanels([]);
    };
  }, []);

  const onTabChange = (e) => {
    setActiveLoginPanel(e);
  };

  const renderTabContent = () => {
    const renderSupportAuthentication = () => {
      if (CompareParamValue(QUERY_PARAMS.SOURCE, QUERY_PARAMS.SUPPORT)) {
        return <SupportLogin token={GetParamValue(QUERY_PARAMS.TOKEN)} />;
      }
    };

    const renderUnifyAuthentication = () => {
      if (CompareParamValue(QUERY_PARAMS.SOURCE, QUERY_PARAMS.UNIFY)) {
        return <UnifySunshineLogin token={GetParamValue(QUERY_PARAMS.TOKEN)} />;
      }
    };

    return authenticationPanels?.map((panel, index) => {
      return (
        <TabPanel
          key={index}
          className={`login-tab-panels`}
          header={t(panel.label)}
          headerClassName={panel.className}
        >
          <div className={`login-tab-form`}>
            <panel.component />
            {renderSupportAuthentication()}
            {renderUnifyAuthentication()}
          </div>
        </TabPanel>
      );
    });
  };

  const renderTab = () => {
    return (
      <TabView
        className="login-tabview"
        activeIndex={activeLoginPanel}
        onTabChange={(e) => {
          onTabChange(e.index);
        }}
      >
        {renderTabContent()}
      </TabView>
    );
  };

  return (
    <LoginWrapper>
      <div className="login-form-tab">{renderTab()}</div>
    </LoginWrapper>
  );
};

export default LoginForm;
