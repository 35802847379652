import "./home.scss";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//redux functions
import { resetBcDynamicPaths } from "./../../../redux/actions/breadcrumb";

//components
import WelcomeMessage from "./components/welcome-message";
import ShortcutIcons from "./components/shortcut-icons";
import AssistanceMessage from "./components/assistance-message";
import Newsfeed from "./components/newsfeed";
import HomeProfile from "./components/home-profile";
import LanguageLoader from "./../../../components/language-loader/language-loader";
import {
  EEPORTAL_LABEL_HOME,
  EEPORTAL_LABEL_NOTIFICATION,
  enumsKeyToArray,
} from "../../../constants";

const Home = () => {
  const dispatch = useDispatch();
  const isSupportUser = useSelector((state) => state.auth?.isSupportUser);

  useEffect(() => {
    dispatch(resetBcDynamicPaths());
  }, [dispatch]);

  return (
    <>
      <LanguageLoader
        contexts={enumsKeyToArray([
          EEPORTAL_LABEL_HOME,
          EEPORTAL_LABEL_NOTIFICATION,
        ])}
      >
        <div className="p-d-flex p-flex-column p-flex-lg-row home">
          <div className="p-col-12 p-md-12 p-lg-8 home-left-panel">
            <WelcomeMessage />
            <Newsfeed />
            <ShortcutIcons />
          </div>
          <div className="p-col-12 p-md-12 p-lg-4 home-right-panel">
            {!isSupportUser && <HomeProfile />}
            <AssistanceMessage />
          </div>
        </div>
      </LanguageLoader>
    </>
  );
};

export default Home;
