import "./submit-claim-upload.scss";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../../../redux/actions/modal";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { Skeleton } from "primereact/skeleton";
import { useHistory } from "react-router-dom";

import { Steps, MultiStepForm } from "../../components";
import {
  configToStepNavModel,
  configToFormModel,
  formSkeleton,
  submitClaim,
  initFormValue,
} from "../../utils";
import {
  HRIS_Api_Common_Enums_Enums_RentalApplicationForm,
  RentalDetailsService,
} from "./../../../../../services/hrmnet-api";
import {
  EEPORTAL_LABEL_BASE_TABLE,
  FUNCTION_CODE,
  RENTAL_SUBMODULE_CODE,
} from "../../../../../constants";
import {
  setRentalForm,
  resetRentalForm,
  setRentalFormConfig,
} from "../../../../../redux/actions/rental";
import { isResponseOk } from "../../../../../utils/utils";
import { showTimestampToastSuccess } from "../../../../../services/utils/message";
import {
  scrollToErrorControl,
  scrollToMainTop,
} from "../../../../../components/base-form/utils";
import { getCustomFunctionLibrary } from "./../../utils/formCustomFunction";
import LanguageLoader from "../../../../../components/language-loader/language-loader";
import { RENTAL_T_CONTEXT_KEY } from "../../constants";
import { showSpinner } from "../../../../../redux/actions/spinner";
import { PortalLink } from "../../../../../services/utils";

export const SubmitClaimUpload = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [state, setState] = useState({
    isLoading: true,
    stepList: [],
    activeStep: 0,
    formResp: {},
    formName: "",
    touched: false,
  });

  const form = useSelector((state) => state.rental.form);
  const setForm = (x) => dispatch(setRentalForm(x));
  const formConfig = useSelector((state) => state.rental.formConfig);
  const setFormConfig = (x) => dispatch(setRentalFormConfig(x));

  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );

  const selectedLangRef = useRef();

  const formRef = React.createRef();
  const customFunctionLibrary = getCustomFunctionLibrary({ dispatch });

  useEffect(() => {
    resetRentalForm();
    return () => {
      resetRentalForm();
    };
  }, []);

  useEffect(() => {
    const loadForm = async () => {
      const isLangChange =
        selectedLangRef.current != null &&
        selectedLangRef.current !== selectedLangKey;
      selectedLangRef.current = selectedLangKey;

      const applicationId = props.match.params.applicationId;
      const api1 = RentalDetailsService.rentalGetRentalNewApplicationSteps({
        formType:
          HRIS_Api_Common_Enums_Enums_RentalApplicationForm.SubmitClaimForm,
      });
      const api2 = RentalDetailsService.rentalGetSubmitClaimApplication({
        applicationId,
      });

      let [stepRes, formConfigRes] = await Promise.all([api1, api2]);

      let comments = [];
      comments = formConfigRes?.data?.comments;

      let stepList = [];
      let initForm = {};
      let formResp = {};
      let formConfig = {};
      let formName = "";
      if (stepRes && stepRes.data && formConfigRes && formConfigRes.data) {
        const customData = {
          customFunctionLibrary: customFunctionLibrary,
          openClaims: formConfigRes.data.claims,
          comments: comments,
          attachmentControlConfig: formConfigRes.data.attachmentType,
        };
        stepList = configToStepNavModel(stepRes.data);
        if (!isLangChange) {
          initForm = initFormValue(formConfigRes.data.sections);
        } else {
          initForm = form;
        }
        formResp = formConfigRes.data;
        formConfig = configToFormModel({
          applicationId: applicationId,
          stepConfig: stepRes.data,
          sectionFieldConfig: formConfigRes.data.sections,
          customData: customData,
          t: t,
          form: initForm,
        });
        formName = formConfigRes.data.formName;
      }
      setForm(initForm);
      setFormConfig(formConfig);
      setState({
        ...state,
        formName,
        stepList,
        formResp,
        isLoading: false,
      });
    };
    loadForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLangKey]);

  // Navigation and action
  const validateCurrentStep = () => {
    const isValid = !formRef.current.getActiveFormState().invalid;
    setTimeout(() => {
      scrollToErrorControl();
    }, 100);
    return isValid;
  };

  const nextStep = () => {
    const _state = { ...state };
    if (_state.activeStep < _state.stepList.length - 1) {
      // validation
      if (validateCurrentStep()) {
        // move to next setp
        setState({
          ...state,
          activeStep: state.activeStep + 1,
          touched: false,
        });
        scrollToMainTop();
      } else {
        setState({
          ...state,
          touched: true,
        });
      }
    }
  };

  const backStep = () => {
    if (state.activeStep > 0) {
      setState({
        ...state,
        activeStep: state.activeStep - 1,
      });
      scrollToMainTop();
    } else {
      backPage({ confirm: true });
    }
  };

  const backPage = ({ confirm } = {}) => {
    if (confirm) {
      dispatch(
        openModal({
          title: t("rental_submitClaim_backModalTitle"),
          content: t("rental_submitClaim_backModalContent"),
          classNameMainDialog: "confirm-message-modal",
          primaryButtonText: t("rental_common_actionConfirm"),
          primaryButtonClickFn: async ({ closeFn }) => {
            closeFn();
            history.push(
              PortalLink(
                `${FUNCTION_CODE.Rental}/${RENTAL_SUBMODULE_CODE.SUBMIT_CLAIM}/${props.match.params.applicationId}`
              )
            );
          },
          secondButtonClickFn: ({ closeFn }) => {
            closeFn();
          },
        })
      );
    } else {
      history.push(
        PortalLink(
          `${FUNCTION_CODE.Rental}/${RENTAL_SUBMODULE_CODE.SUBMIT_CLAIM}/${props.match.params.applicationId}`
        )
      );
    }
  };

  const sendComment = async (commentDetail) => {
    try {
      const applicationId = props.match.params.applicationId;
      const api = RentalDetailsService.rentalCreateComment({
        applicationId: applicationId,
        body: commentDetail,
      });
      const [res] = await Promise.all([api]);
      return res;
    } catch (e) {
      // show notification?
      console.error(e.message);
    }
  };

  const submit = async () => {
    try {
      // show spinner
      dispatch(showSpinner(true));
      const res = await submitClaim({
        sectionConfig: state.formResp.sections,
        form: form,
        claims: state.formResp.claims.claimMonths,
        applicationId: props.match.params.applicationId,
      });
      if (!isResponseOk(res)) throw new Error("Submit failed");
      showTimestampToastSuccess({
        message: t("rental_submitClaim_successMessageSubmit"),
        t: t,
      });
      backPage();
    } catch (e) {
      // show notification?
      console.error(e.message);
    } finally {
      // end spinner
      dispatch(showSpinner(false));
    }
  };

  const confirmSubmitApplication = async () => {
    if (validateCurrentStep()) {
      dispatch(
        openModal({
          title: t("rental_submitClaim_submitModalTitle"),
          content: t("rental_submitClaim_submitModalContent"),
          classNameMainDialog: "confirm-message-modal",
          primaryButtonText: t("rental_submitClaim_submitModalConfirm"),
          primaryButtonClickFn: async ({ closeFn }) => {
            closeFn();
            submit();
          },
          secondButtonClickFn: ({ closeFn }) => {
            closeFn();
          },
        })
      );
    } else {
      setState({
        ...state,
        touched: true,
      });
    }
  };

  const onFormChange = ({ state }) => {
    setForm((form) => ({
      ...form,
      ...state.form,
    }));
  };

  // Rendering
  const renderFormTitle = () => (
    <div className="rental-form-title">
      <Button
        onClick={backStep}
        icon="pi pi-angle-left"
        className="p-button-rounded-lg p-button-text"
      />
      <span className="title">{state.formName}</span>
    </div>
  );

  const renderForm = () => (
    <MultiStepForm
      form={form}
      activeStep={state.activeStep}
      config={formConfig}
      ref={formRef}
      touched={state.touched}
      onChange={onFormChange}
    />
  );

  const renderFooter = () => (
    <div className="footer p-grid p-align-center p-justify-between">
      <Button
        onClick={() => backStep()}
        className="p-button-outlined secondary back-button"
      >
        {t("rental_common_actionBack")}
      </Button>
      <div className="">
        {state.activeStep < state.stepList.length - 1 ? (
          <Button onClick={() => nextStep()} className="p-button next-button">
            {t("rental_common_actionNext")}
          </Button>
        ) : (
          <Button
            onClick={confirmSubmitApplication}
            className="p-button next-button"
          >
            {t("rental_common_actionSubmit")}
          </Button>
        )}
      </div>
    </div>
  );

  const renderStepSkeleton = () => (
    <div className="p-grid p-my-6">
      <Skeleton height="20px"></Skeleton>
    </div>
  );

  const renderFormSkeleton = () => formSkeleton;

  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          RENTAL_T_CONTEXT_KEY.COMMON,
          RENTAL_T_CONTEXT_KEY.SUBMIT_CLAIM,
          RENTAL_T_CONTEXT_KEY.APPROVAL, // approval claim table
        ]}
      />
      <div className="rental-submit-claim-upload rental-ms-form">
        <div className="header">
          {/* Title */}
          {renderFormTitle()}

          {/* Steps Nav*/}
          {state.isLoading ? (
            renderStepSkeleton()
          ) : (
            <Steps
              className="steps"
              model={state.stepList}
              activeIndex={state.activeStep}
            />
          )}
        </div>
        {state.isLoading ? renderFormSkeleton() : renderForm()}

        {/* Footer */}
        {renderFooter()}
      </div>
    </>
  );
};

export default SubmitClaimUpload;
