import "./user-identification.scss";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

//service
import { ForgotPasswordService } from "./../../../../../../../services/hrmnet-api";

//component
import { getControlModel } from "./../../../../../../../components/base-control/base-cotrol-model";
import BaseForm from "./../../../../../../../components/base-form/base-form";

//prime
import { Button } from "primereact/button";

//util
import { CancelBtn } from "./../../utils";
import { LoginLink } from "./../../../../../../../services/utils";

const UserIdentification = (props) => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    form: {},
  });
  const [isUserNotFound, setUserIsNotFound] = useState(false);
  let history = useHistory();

  // functions -------------------------------------------------------------
  const afterFormSubmitFn = async (payload) => {
    const { res, formState } = payload;

    if (!formState.invalid && res && res?.data && res.data?.length > 0) {
      if (props.setSecurityQuestions) {
        props.setSecurityQuestions(res.data);
      }
      if (props.setIsValidated) {
        props.setIsValidated(true);
      }
    } else {
      setUserIsNotFound(true);
    }
    return res?.data?.length > 0 ?? false;
  };

  const formSubmitFn = async (payload) => {
    const { form } = payload;
    let _body = { userName: "", email: "" };
    var emailPattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    try {
      if (!emailPattern.test(form.usernameEmail)) {
        _body = { userName: form.usernameEmail };
        props.setUserNameEmail(_body);

        return await ForgotPasswordService.forgotPasswordForgotPasswordConfigurationByUserName(
          { body: _body }
        );
      }

      _body = { email: form.usernameEmail };
      props.setUserNameEmail(_body);
      return await ForgotPasswordService.forgotPasswordForgotPasswordConfigurationByEmail(
        { body: _body }
      );
    } catch (error) {}
    return;
  };

  function onCancel() {
    setState({ form: {} });
    setUserIsNotFound(false);
  }

  const renderFailMessage = () => {
    return (
      <>
        <h1>{t("login_forgotPassword_error")}</h1>
        <p>{t("login_forgotPassword_checkYourEmail_errorDescription")}</p>
        <Button
          type="cancel"
          label={t("login_back")}
          disabled={state.loading}
          style={{
            width: "50%",
            maxWidth: "100%",
            textAlign: "left",
            minHeight: "3.5rem",
            marginBottom: "1rem",
            marginTop: "1rem",
          }}
          onClick={() => onCancel()}
        />
      </>
    );
  };

  // render
  const renderForm = {
    controls: [
      getControlModel({
        key: "usernameEmail",
        label: t("login_usernameEmail"),
        placeholder: t("login_yourEmailAddressUsername"),
        required: true,
        noRequiredLabel: true,
      }),
    ],
    layout: {
      rows: [
        {
          columns: [
            {
              control: "usernameEmail",
            },
          ],
        },
        {
          config: {
            style: {
              marginBottom: "1rem",
              marginTop: "1rem",
            },
          },
          columns: [
            {
              action: {
                type: "submit",
                label: t("login_forgotPassword_next"),
                submitingLabel: t("login_submitting"),
                config: {
                  kind: "primary",
                  style: {
                    width: "100%",
                    maxWidth: "100%",
                    textAlign: "left",
                    minHeight: "3.5rem",
                  },
                },
              },
            },
            CancelBtn(t("login_back"), () => history.push(LoginLink())),
          ],
        },
      ],
    },
    formSubmitFn,
    afterFormSubmitFn,
  };

  const renderContent = () => {
    return (
      <>
        <h1>{t("login_forgotPassword")}</h1>
        <p>{t("login_forgotPassword_description")}</p>
        <BaseForm config={renderForm} form={state.form} />
      </>
    );
  };

  return <>{isUserNotFound ? renderFailMessage() : renderContent()}</>;
};

export default UserIdentification;
