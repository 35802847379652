import { useTranslation } from "react-i18next";

import "./application-status.scss";

export enum APPLICATION_STATUS {
  DRAFT = "Draft",
  SUBMITTED = "Submitted",
  APPROVED = "Approved",
  REJECTED = "Rejected",
  WITHDRAW = "Withdraw",
  COMPLETED = "Completed",
  RETURNED = "Returned",

  // Non workflow status
  CHANGED = "Changed",
  PROGRESS = "Progress",
  PARTIAL_COMPLETED = "PartialCompleted",
  DISAPPROVED = "Disapproved",
  REOPENED = "ReOpened",
}

type ApplicationStatusProps = {
  status: APPLICATION_STATUS;
  showText: boolean;
};

const ApplicationStatus = ({
  status,
  showText = true,
}: ApplicationStatusProps) => {
  const { t } = useTranslation();
  let indicatorClassName = "";
  let text = "";

  switch (status) {
    case APPLICATION_STATUS.DRAFT:
      indicatorClassName =
        "application-status__indicator application-status__indicator--draft";
      text = t("rental_applicationStatus_draft");
      break;
    case APPLICATION_STATUS.SUBMITTED:
      indicatorClassName =
        "application-status__indicator application-status__indicator--submitted";
      text = t("rental_applicationStatus_submitted");
      break;
    case APPLICATION_STATUS.APPROVED:
      indicatorClassName =
        "application-status__indicator application-status__indicator--approved";
      text = t("rental_applicationStatus_approved");
      break;
    case APPLICATION_STATUS.REJECTED:
      indicatorClassName =
        "application-status__indicator application-status__indicator--rejected";
      text = t("rental_applicationStatus_rejected");
      break;
    case APPLICATION_STATUS.WITHDRAW:
      indicatorClassName =
        "application-status__indicator application-status__indicator--withdraw";
      text = t("rental_applicationStatus_withdrawn");
      break;
    case APPLICATION_STATUS.COMPLETED:
      indicatorClassName =
        "application-status__indicator application-status__indicator--completed";
      text = t("rental_applicationStatus_completed");
      break;
    case APPLICATION_STATUS.RETURNED:
      indicatorClassName =
        "application-status__indicator application-status__indicator--returned";
      text = t("rental_applicationStatus_returned");
      break;

    // non workflow status
    case APPLICATION_STATUS.CHANGED: // SUBMITTED
      indicatorClassName =
        "application-status__indicator application-status__indicator--submitted";
      text = t("rental_applicationStatus_submitted");
      break;
    case APPLICATION_STATUS.PROGRESS: // APPROVED
      indicatorClassName =
        "application-status__indicator application-status__indicator--approved";
      text = t("rental_applicationStatus_approved");
      break;
    case APPLICATION_STATUS.PARTIAL_COMPLETED: // APPROVED
      indicatorClassName =
        "application-status__indicator application-status__indicator--approved";
      text = t("rental_applicationStatus_approved");
      break;
    case APPLICATION_STATUS.DISAPPROVED: // COMPLETED
      indicatorClassName =
        "application-status__indicator application-status__indicator--completed";
      text = t("rental_applicationStatus_completed");
      break;
    case APPLICATION_STATUS.REOPENED: // APPROVED
      indicatorClassName =
        "application-status__indicator application-status__indicator--approved";
      text = t("rental_applicationStatus_approved");
      break;
    default:
      text = status;
      break;
  }

  return (
    <span className="application-status">
      <span className={indicatorClassName}></span>
      {showText && <span>{text}</span>}
    </span>
  );
};

export default ApplicationStatus;
