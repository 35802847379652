import "./hr-corner.scss";

import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Animated } from "react-animated-css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DEFAULT_BT_SEARCH } from "../../../../components/base-table/base-table-model";
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_FILE_NAME,
  FILE_NAME,
  FILE_RELEASE_DATE,
  FILTER_MODE,
  HR_CORNER_OBJECT_KEY,
  MATCH_MODE,
  RESPONSE_MESSAGE_ERROR,
  STRING,
} from "../../../../constants";
import BaseTable from "../../../../components/base-table/base-table-portal";
import {
  checkMobile,
  columnConfig,
  tableConfigChild,
  tableConfigWithExpander,
} from "../utils";
import { resetBcDynamicPaths } from "../../../../redux/actions/breadcrumb";
import { BCType } from "../../../../components/base-control/base-control";
import { Dropdown } from "primereact/dropdown";
import {
  GetFileDownload,
  GetUploadDownload,
} from "../../../../components/base-control/upload-control/utils";
import { DocumentsService } from "../../../../services/hrmnet-api";
import { Skeleton } from "primereact/skeleton";
import axios from "axios";

const HrCorner = () => {
  /** Default search */
  const BT_SEARCH = {
    ...DEFAULT_BT_SEARCH,
    sortObj: {
      key: FILE_RELEASE_DATE,
      direction: -1,
    },
  };

  const FILES_BT_SEARCH = {
    sortObj: {
      key: FILE_NAME,
      direction: -1,
    },
  };

  // i18n
  const { t } = useTranslation();
  const dataTable = useRef();
  const fileDataTable = useRef([]);
  const [currentUploadId, setCurrentUploadId] = useState();
  const currentUploadFolder = useRef({
    datas: [],
    total: 0,
    searchConfig: BT_SEARCH,
  });
  let requestRef = useRef();
  const dispatch = useDispatch();
  const [expanderControl, setExpanderControl] = useState();
  const selectedLangKey = useSelector(
    (state) => state.language?.language?.selectedLang?.key
  );
  const [rerenderLanguage, setRerenderLanguage] = useState(false);
  // const [groupOption, setGroupOption] = useState(null);
  const [optionsFolder, setOptionsFolder] = useState(null);
  const [documentTypeList, setDocumentTypeList] = useState([]);
  // const [selectedRecords, setSelectedRecords] = useState([]);
  const [selectedFileUpload, setSelectedFileUpload] = useState([]); //to keep track and loop through filedatatable for clearSelectedRecords for each child datatable
  const [currentDocumentType, setCurrentDocumentType] = useState();
  // ------------ customFunction ---------------------------------------------------

  const tableSearchFn = async (folderOption) => {
    const { cancel, token } = axios.CancelToken.source();
    const requestConfig = {
      cancelToken: token,
    };
    let _datas = [];
    let currentTable = {
      datas: [],
      total: 0,
      searchConfig: BT_SEARCH,
    };
    setRerenderLanguage(true);
    requestRef.current = { cancel, token };

    if (
      !!folderOption &&
      (typeof folderOption === STRING || folderOption instanceof String)
    ) {
      var cmd = DocumentsService.documentGetDocumentsByFolderId(
        {
          body: { folderId: folderOption },
        },
        requestConfig
      );
      const [res] = await Promise.all([cmd]);
      if (res && res.data && res.data.files) {
        _datas = res.data.files?.map((data) => {
          const _data = {
            ...data,
            fileName: data?.fileName ?? data?.description,
            releaseDate: moment(data.releaseDate, DEFAULT_DATE_FORMAT),
          };

          if (!data?.fileName && !!data?.description) {
            delete _data.description;
          }

          return _data;
        });
      }
      if (res && res.data && res.data.documentType) {
        const _documentTypeList = res.data.documentType?.map((data) => {
          return {
            name: data.toString(),
            value: data.toString(),
          };
        });
        setDocumentTypeList(_documentTypeList);
      }

      currentTable = {
        datas: _datas,
        total: _datas.length,
        searchConfig: BT_SEARCH,
      };
      currentUploadFolder.current = currentTable;
    } else {
      currentTable = currentUploadFolder.current;
    }

    setExpanderControl({ ...expanderControl, displayedRecord: _datas });
    return currentTable;
  };

  async function fileSearchFn(data) {
    let _datas = [];
    try {
      var cmd = await DocumentsService.documentGetFilesByUploadId({
        body: { uploadId: data.id },
      });
      const [res] = await Promise.all([cmd]);
      if (res && res.data) {
        _datas = res.data.map((x, index) => {
          return {
            fileUploadId: data.id,
            ...x,
          };
        });
      }
    } catch (error) {}

    return {
      datas: _datas,
      total: _datas.length,
      searchConfig: FILES_BT_SEARCH,
    };
  }

  // ------------------------------ use effect --------------------------------
  //#region use effect
  useEffect(() => {
    dispatch(resetBcDynamicPaths());
  }, [dispatch]);

  const mountedRef = useRef(false);

  useEffect(() => {
    if (rerenderLanguage) {
      dataTable.current?.rerenderLanguage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLangKey]);

  useEffect(() => {
    let ignore = false;
    const initPage = async () => {
      if (mountedRef.current) return null;
    };

    const getFolder = async () => {
      const { cancel, token } = axios.CancelToken.source();
      const requestConfig = {
        cancelToken: token,
      };
      requestRef.current = { cancel, token };
      var cmd = await DocumentsService.documentGetFolders(requestConfig);
      const [res] = await Promise.all([cmd]);
      if (res && res.data) {
        setOptionsFolder(
          res.data.map((data, index) => {
            if (index === 0) {
              dataTable?.current?.reload(data?.id);
              setCurrentUploadId(data?.id);
            }
            return {
              ...data,
              name: data.name,
              value: data?.id,
            };
          })
        );
      }
    };

    try {
      initPage();
      if (!ignore) {
        getFolder();
      }
    } finally {
      if (!mountedRef.current) {
        // preventing to load twice
        mountedRef.current = true;
      }
    }

    return () => {
      ignore = true;
      requestRef?.current?.cancel(RESPONSE_MESSAGE_ERROR.CANCEL);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //#endregion

  const onFileDownload = async (item) => {
    await GetFileDownload(item.id, DEFAULT_FILE_NAME.DOCUMENT);
  };

  const onUploadDownload = async (item) => {
    await GetUploadDownload(item.id, DEFAULT_FILE_NAME.DOCUMENT);
    await dataTable.current.updateRow(item.id, {
      ...item,
      isDownloaded: false,
    });
  };

  // async function onMultipleDownload (data) {
  //   console.log("--fileId", data)
  // };

  //#region Child
  const fileColumnConfig = [
    columnConfig(
      t("hrCorner_datatable_fileName"),
      HR_CORNER_OBJECT_KEY.FILE_NAME,
      { placeholder: t("hrCorner_datatable_fileName_placeholder") },
      false
    ),
  ];

  // function onSelectionChange(e, fullRecords)
  // {
  //   let currSelectedRecords = [...selectedRecords.filter(x => !fullRecords.includes(x)), ...e.value]
  //   setSelectedRecords(currSelectedRecords);
  //   let currSelectedFileUpload = [...selectedFileUpload.filter(x => x !== fullRecords[0].fileUploadId)]

  //   if(currSelectedRecords.length === 0)
  //   {
  //     dataTable.current.clearSelectedRecords();
  //   }
  //   else
  //   {
  //     setSelectedFileUpload([...currSelectedFileUpload, fullRecords[0].fileUploadId])
  //     dataTable.current.updateSelectedRecords(currSelectedRecords);
  //   }
  // }

  let fileConfigModel = tableConfigChild(
    t,
    fileColumnConfig,
    onFileDownload,
    "pi pi-download",
    "single",
    [
      // {
      //   renderIcon: "pi pi-download",
      //   title: t("hrCorner_download"),
      //   isOverflow: true,
      //   showOnGrid: true,
      //   clickFn: async (data) => console.log("-testing"),
      // },
    ],
    null, // onSelectionChange,
    { itemSize: 10 },
    "600px"
  );

  function fileExpanderTemplate(data) {
    return (
      <>
        {checkMobile() && (
          <>
            <div className="bt-row bt-row-0">
              <span className="p-column-title">
                {t("hrCorner_datatable_noOfFiles")}:
              </span>
              <div className="bt-cell-value">
                <span>{data[HR_CORNER_OBJECT_KEY.NUMBER_OF_FILES]}</span>
              </div>
            </div>
            <div className="bt-row bt-row-1">
              <span className="p-column-title">
                {t("document_datatable_description")}:
              </span>
              <div className="bt-cell-value">
                <span>{data[HR_CORNER_OBJECT_KEY.DESCRIPTION]}</span>
              </div>
            </div>
          </>
        )}
        <BaseTable
          ref={(el) => (fileDataTable.current[data.id] = el)}
          configModel={fileConfigModel}
          searchConfig={FILES_BT_SEARCH}
          searchFn={() => fileSearchFn(data)}
          isClientSize={true}
          id="file-download-table"
        />
      </>
    );
  }

  //#endregion

  //#region Main DataTable

  const onFolderOptionChange = useCallback((e) => {
    requestRef?.current?.cancel(RESPONSE_MESSAGE_ERROR.CANCEL);
    setCurrentUploadId(e.value);
    if (e.value === undefined) {
      setCurrentDocumentType(undefined);
      currentUploadFolder.current = {
        datas: [],
        total: 0,
        searchConfig: BT_SEARCH,
      };
    }
    dataTable.current.reload(e.value);
  }, []);

  const onDocumentTypeListChange = (e) => {
    setCurrentDocumentType(e.value);
    if (e.value === undefined) {
      dataTable.current.onFilter(
        HR_CORNER_OBJECT_KEY.DOCUMENT_TYPE,
        e.value,
        MATCH_MODE.CONTAINS
      );
    } else {
      dataTable.current.onFilter(
        HR_CORNER_OBJECT_KEY.DOCUMENT_TYPE,
        e.value,
        MATCH_MODE.EQUALS
      );
    }
  };

  function onClearChildSelectedRecords() {
    selectedFileUpload.forEach((x) => {
      fileDataTable.current[x]?.clearSelectedRecords();
    });
  }

  const renderBodyExpanderWithColumn = (rowData, props) => {
    if (rowData?.numberOfFiles > 1) {
      return (
        <table>
          <tbody>
            <tr>
              <td>{props.expander.element}</td>
              <td colSpan="10">
                <div
                  className={`hr-corner${rowData.isDownloaded ? "-bold" : ""}`}
                  dangerouslySetInnerHTML={{ __html: rowData?.fileName }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      );
    }
    return (
      <div
        className={`hr-corner${rowData.isDownloaded ? "-bold" : ""}`}
        dangerouslySetInnerHTML={{ __html: rowData?.fileName }}
      />
    );
  };

  const allColumnConfigs = [
    columnConfig(
      t("hrCorner_datatable_docName"),
      HR_CORNER_OBJECT_KEY.FILE_NAME,
      { placeholder: t("hrCorner_datatable_docName_placeholder") },
      false,
      false,
      null,
      null,
      null,
      (rowData, props, state) => {
        if (state.loading) {
          return <Skeleton></Skeleton>;
        } else {
          return (
            <div className={`bt-row bt-row-${props.rowIndex}`}>
              <span className="p-column-title">
                {t("hrCorner_datatable_docName")}:
              </span>
              <div className="bt-cell-value">
                {renderBodyExpanderWithColumn(rowData, props)}
              </div>
            </div>
          );
        }
      },
      "45%"
    ),
    columnConfig(
      t("hrCorner_datatable_documentType"),
      HR_CORNER_OBJECT_KEY.DOCUMENT_TYPE,
      {
        type: BCType.multiselect,
        enum: documentTypeList,
        placeholder: t("hrCorner_datatable_documentType_placeholder"),
      },
      false,
      false,
      null,
      null,
      null,
      (e, props, state) => (
        <div
          className={`hr-corner${e.isDownloaded ? "-bold" : ""}`}
          dangerouslySetInnerHTML={{ __html: e.documentType }}
        />
      ),
      null,
      "25%"
    ),
    columnConfig(
      t("hrCorner_datatable_noOfFiles"),
      HR_CORNER_OBJECT_KEY.NUMBER_OF_FILES,
      {
        placeholder: t("hrCorner_datatable_noOfFiles_placeholder"),
      },
      true,
      false,
      BCType.number,
      null,
      null,
      (e, props, state) => (
        <div
          className={`hr-corner${e.isDownloaded ? "-bold" : ""}`}
          dangerouslySetInnerHTML={{ __html: e.numberOfFiles }}
        />
      ),
      null,
      "20%"
    ),
    columnConfig(
      t("hrCorner_datatable_releaseDt"),
      HR_CORNER_OBJECT_KEY.RELEASE_DATE,
      {
        placeholder: t("hrCorner_datatable_releaseDt_placeholder"),
        type: BCType.daterange,
      },
      false,
      false,
      BCType.date,
      {
        dateFormat: DEFAULT_DATE_FORMAT,
      },
      null,
      (e, props, state) => (
        <div
          className={`hr-corner${e.isDownloaded ? "-bold" : ""}`}
          dangerouslySetInnerHTML={{
            __html: moment(e.releaseDate).format(DEFAULT_DATE_FORMAT),
          }}
        />
      ),
      null,
      "25%"
    ),
    columnConfig(
      t("document_datatable_description"),
      HR_CORNER_OBJECT_KEY.DESCRIPTION,
      { placeholder: t("document_datatable_description_placeholder") },
      false,
      false,
      BCType.input,
      null,
      null,
      (e, props, state) => (
        <div
          className={`hr-corner${e.isDownloaded ? "-bold" : ""}`}
          dangerouslySetInnerHTML={{
            __html: e[HR_CORNER_OBJECT_KEY.DESCRIPTION],
          }}
        />
      ),
      null,
      "20%"
    ),
  ];

  const renderDropDown = useMemo(() => {
    return (
      <>
        <Dropdown
          placeholder={t("hrCorner_dropdown_selectFolder")}
          options={optionsFolder}
          optionLabel="name"
          optionValue="value"
          value={currentUploadId}
          onChange={onFolderOptionChange}
          className="filter-dropdown"
          showClear
          filter
          virtualScrollerOptions={{ itemSize: 10 }}
          emptyFilterMessage={t("base_control_select_loading_no_results")}
        />
        <Dropdown
          placeholder={t("hrCorner_dropdown_selectDocumentType")}
          options={documentTypeList}
          optionLabel="name"
          optionValue="value"
          value={currentDocumentType}
          onChange={onDocumentTypeListChange}
          className={`filter-dropdown${
            documentTypeList.length < 1 || currentUploadId === undefined
              ? "-none"
              : ""
          }`}
          showClear
          filter
          virtualScrollerOptions={{ itemSize: 1 }}
          emptyFilterMessage={t("base_control_select_loading_no_results")}
        />
      </>
    );
  }, [currentUploadId, optionsFolder, currentDocumentType, documentTypeList]);

  let configModel = tableConfigWithExpander(
    t,
    t("hrCorner_title"),
    t("hrCorner_description"),
    allColumnConfigs,
    onUploadDownload,
    expanderControl,
    setExpanderControl,
    false,
    "pi pi-download",
    <>{renderDropDown}</>,
    true, //expander in all view
    FILTER_MODE.MULTILPLE,
    (e) => `hr-corner${e.isDownloaded ? "-bold" : ""}`,
    false,
    fileExpanderTemplate,
    (e) => {
      let newState = {
        ...expanderControl,
        expandedRows: e.data,
        expandAllDisabled:
          e.data.length === expanderControl?.displayedRecord.length,
        collapseAllDisabled: e.data.length === 0,
      };
      setExpanderControl(newState);
    },
    [
      // {
      //   renderIcon: "pi pi-download",
      //   title: t("hrCorner_download"),
      //   isOverflow: true,
      //   showOnGrid: true,
      //   clickFn: onMultipleDownload,
      // },
    ],
    onClearChildSelectedRecords
    // onExpanderDisplay
  );
  //#endregion

  return (
    <Animated
      animationIn="slideInRight"
      animationOut="slideOutRight"
      animationInDuration={200}
      animationOutDuration={200}
      isVisible={true}
    >
      <div className="document-download-table">
        <BaseTable
          ref={dataTable}
          configModel={configModel}
          searchConfig={BT_SEARCH}
          searchFn={(searchData) => tableSearchFn(searchData)}
          isClientSize={true}
          id="document-download-table"
        />
      </div>
    </Animated>
  );
};

export default HrCorner;
