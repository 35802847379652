import {
  SET_RENTAL_FORM,
  RESET_RENTAL_FORM,
  SET_RENTAL_FORM_CONFIG,
  SET_RENTAL_SECTION_CONFIG,
  RESET_RENTAL_FORM_CONFIG,
  RESET_RENTAL_FORM_VALUE,
  SET_RENTAL_MODULE_CONFIG,
  CLEAR_ALL_RENTAL_CONTEXT,
  SET_RENTAL_EMPLOYEE_DETAILS,
  CLEAR_RENTAL_EMPLOYEE_DETAILS,
} from "../reduxConstants";

/**
 * set rental key-value form
 */
export const setRentalForm = (payload) => {
  return {
    type: SET_RENTAL_FORM,
    payload: payload,
  };
};

/**
 * reset rental key-value form
 */
export const resetRentalForm = (payload) => {
  return {
    type: RESET_RENTAL_FORM,
    payload: payload,
  };
};

/**
 * set rental form configuration
 */
export const setRentalFormConfig = (payload) => {
  return {
    type: SET_RENTAL_FORM_CONFIG,
    payload: payload,
  };
};

/**
 * set rental form configuration
 */
export const setRentalSectionConfig = (payload) => {
  return {
    type: SET_RENTAL_SECTION_CONFIG,
    payload: payload,
  };
};

/**
 * Reset rental form config to original
 */
export const resetRentalFormConfig = () => {
  return {
    type: RESET_RENTAL_FORM_CONFIG,
  };
};

/**
 * Reset rental form value
 */
export const resetRentalFormValue = () => {
  return {
    type: RESET_RENTAL_FORM_VALUE,
  };
};

/**
 * set rental module config
 */
export const setRentalModuleConfig = (payload) => {
  return {
    type: SET_RENTAL_MODULE_CONFIG,
    payload: payload,
  };
};

/**
 * clear all rental context
 */
export const clearAllRentalContext = () => {
  return {
    type: CLEAR_ALL_RENTAL_CONTEXT,
  };
};

/**
 * record employee details for create new rental application on their behalf
 */
export const setRentalEmployeeDetails = (payload) => {
  return {
    type: SET_RENTAL_EMPLOYEE_DETAILS,
    payload: payload,
  };
};

/**
 * clear employee details for create new rental application on their behalf
 */
export const clearRentalEmployeeDetails = () => {
  return {
    type: CLEAR_RENTAL_EMPLOYEE_DETAILS,
  };
};
