import { SET_COUNTDOWN_TIMER } from "../reduxConstants";

/**
 * set countdown timer
 */
export const setCountdownTimer = (countdownTime) => {
  return {
    type: SET_COUNTDOWN_TIMER,
    payload: countdownTime,
  };
};
