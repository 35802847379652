import cloneDeep from "lodash.clonedeep";
import merge from "deepmerge";

import {
  SET_FORM,
  RESET_FORM,
  SET_FORM_CONFIG,
  SET_SECTION_CONFIG,
  RESET_FORM_CONFIG,
  RESET_FORM_VALUE,
  SET_MODULE_CONFIG,
  CLEAR_ALL_CONTEXT,
  SET_FORM_LANGUAGE,
  FILTER_FIELD_ENUM,
  UPDATE_FIELD_PROPERTIES,
} from "../reduxConstants";
import { BaseInitDependentFieldActionFn } from "../../components/base-multi-step-form/base-multi-step-form-utils";
const initialState = {
  originalForm: null,
  form: {},
  originalFormConfig: null,
  formConfig: {},
  formSectionConfig: {},
  moduleConfig: {},
};

export default function commonForm(
  state = JSON.parse(JSON.stringify(initialState)),
  action
) {
  switch (action.type) {
    case SET_FORM: {
      let newForm;
      if (typeof action.payload === "function") {
        newForm = {
          ...action.payload(state.form),
        };
        return {
          ...state,
          originalForm: state.originalForm
            ? state.originalForm
            : cloneDeep(newForm),
          form: newForm,
        };
      } else {
        newForm = {
          ...action.payload,
        };
        return {
          ...state,
          originalForm: state.originalForm
            ? state.originalForm
            : cloneDeep(newForm),
          form: newForm,
        };
      }
    }
    case RESET_FORM: {
      return {
        ...JSON.parse(JSON.stringify(initialState)),
        moduleConfig: state.moduleConfig,
      };
    }
    case SET_FORM_CONFIG: {
      let newFormConfig;
      if (typeof action.payload === "function") {
        newFormConfig = {
          ...action.payload(state.formConfig),
        };
        return {
          ...state,
          originalFormConfig: state.originalFormConfig
            ? state.originalFormConfig
            : cloneDeep(newFormConfig),
          formConfig: newFormConfig,
        };
      } else {
        newFormConfig = {
          ...action.payload,
        };
        return {
          ...state,
          originalFormConfig: state.originalFormConfig
            ? state.originalFormConfig
            : cloneDeep(newFormConfig),
          formConfig: newFormConfig,
        };
      }
    }
    case FILTER_FIELD_ENUM: {
      let controlConfigChangeMap = {
        ...action.payload?.formConfig,
      };

      let _formConfig = { ...state.formConfig };

      _formConfig.steps.forEach((step, stepIdx) => {
        step.sections.forEach((section, sectionIdx) => {
          section.controls.forEach((control, ctrlIdx) => {
            const controlChange = controlConfigChangeMap[control.refKey];
            if (controlChange) {
              //if object is empty, but mapping is avaible, assuming reset fields state especially its enum
              if (
                controlChange &&
                Object.keys(controlChange).length === 0 &&
                Object.getPrototypeOf(controlChange) === Object.prototype &&
                !!state.formConfig.dropdownOptions[control.refKey] &&
                Array.isArray(state.formConfig.dropdownOptions[control.refKey])
              ) {
                controlChange.enum = [
                  ...state.formConfig.dropdownOptions[control.refKey],
                ];
              }

              _formConfig.steps[stepIdx].sections[sectionIdx].controls[
                ctrlIdx
              ] = merge(
                _formConfig.steps[stepIdx].sections[sectionIdx].controls[
                  ctrlIdx
                ],
                controlChange
              );

              //somehow not merging enum = [], shallow merging
              if (
                !!controlChange?.enum &&
                _formConfig.steps[stepIdx].sections[sectionIdx].controls[
                  ctrlIdx
                ]?.enum !== controlChange?.enum
              ) {
                _formConfig.steps[stepIdx].sections[sectionIdx].controls[
                  ctrlIdx
                ].enum = controlChange?.enum;
              }
            }
          });
        });
      });

      let form = { ...state.form, ...action.payload?.form };
      let formConfig = BaseInitDependentFieldActionFn({
        formConfig: _formConfig,
        form: { ...form },
      });

      /*Object.keys(controlConfigChangeMap).forEach((key) => {
        if (!!controlConfigChangeMap[key]?.enum) {
          formConfig.dropdownOptions[key] = controlConfigChangeMap[key]?.enum;
          if (form?.hasOwnProperty(key)) {
            form[key] = form?.[key]?.filter((x) =>
              Array.from(
                controlConfigChangeMap[key]?.enum,
                (x) => x.value
              )?.includes(x)
            );
          }
        }
      });*/

      return {
        ...state,
        formConfig: formConfig,
        form: form,
      };
    }
    case UPDATE_FIELD_PROPERTIES: {
      let controlConfigChangeMap = {
        ...action.payload,
      };

      let _formConfig = { ...state.formConfig };

      _formConfig.steps.forEach((step, stepIdx) => {
        step.sections.forEach((section, sectionIdx) => {
          section.controls.forEach((control, ctrlIdx) => {
            const controlChange = controlConfigChangeMap[control.refKey];
            if (controlChange) {
              _formConfig.steps[stepIdx].sections[sectionIdx].controls[
                ctrlIdx
              ] = merge(
                _formConfig.steps[stepIdx].sections[sectionIdx].controls[
                  ctrlIdx
                ],
                controlChange
              );

              //special handling on config.headers - BCType.Array Component
              if (
                controlChange.hasOwnProperty("headers") &&
                control.hasOwnProperty("headers")
              ) {
                _formConfig.steps[stepIdx].sections[sectionIdx].controls[
                  ctrlIdx
                ].headers = control?.headers?.map((x) => {
                  let headers = controlChange.headers.find(
                    (h) => h.key === x.key
                  );
                  if (!!headers) {
                    return merge(x, headers);
                  }
                  return x;
                });
              }
            }
          });
        });
      });

      let form = { ...state.form, ...action.payload?.form };
      let formConfig = BaseInitDependentFieldActionFn({
        formConfig: _formConfig,
        form: { ...form },
      });

      return {
        ...state,
        formConfig: formConfig,
        form: form,
      };
    }
    case SET_SECTION_CONFIG: {
      return {
        ...state,
        formSectionConfig: {
          ...action.payload,
        },
      };
    }
    case RESET_FORM_VALUE: {
      if (state.originalFormConfig) {
        return {
          ...state,
          form: cloneDeep(state.originalForm),
        };
      } else {
        return state;
      }
    }
    case RESET_FORM_CONFIG: {
      if (state.originalFormConfig) {
        return {
          ...state,
          formConfig: cloneDeep(state.originalFormConfig),
        };
      } else {
        return state;
      }
    }
    case SET_MODULE_CONFIG: {
      return {
        ...state,
        moduleConfig: {
          ...state.moduleConfig,
          ...action.payload,
        },
      };
    }
    case CLEAR_ALL_CONTEXT: {
      return JSON.parse(JSON.stringify(initialState));
    }
    case SET_FORM_LANGUAGE: {
      return {
        ...state,
        formLanguage: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
