import "./main-sidebar.scss";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { ScrollPanel } from "primereact/scrollpanel";
import { Sidebar } from "primereact/sidebar";

import { setSideBar } from "../../../../redux/actions/sidebar";

import { ViewType } from "../../../../constants";
import MainSideBarLeaf from "./main-sidebar-leaf";

import { PortalLink } from "../../../../services/utils";

/**
 *
 * @param {("web" | "mobile")} type
 * @param {boolean} visible
 * @returns
 */
function MainSideBar({ type, visible, menuTree }) {
  const dispatch = useDispatch();
  const sideBarInfo = useSelector((state) => state.sidebar) || null;

  let history = useHistory();

  /* --------------------------------- Rework Sidebar Mobile Implementation Logic Start ------------------------------------- */
  const onDispatchRework = (props) => {
    let newSideBarInfo = {};
    let _expandedKeys = {};
    let leafKeys = {};
    let link;

    link = PortalLink(props.functionCd);
    history.push(link);

    let _hasSubMenu = props.functionCd.split("/");
    _expandedKeys[_hasSubMenu[0]] = true;
    _expandedKeys[_hasSubMenu[0] + "~" + props.functionCd] = true;

    leafKeys = props.moduleCd;

    newSideBarInfo = {
      selectedKey:
        _hasSubMenu.length === 2
          ? _hasSubMenu[0] + "~" + props.functionCd
          : props.functionCd,
      expandedKeys: _expandedKeys,
      leafKeys: leafKeys,
      isOpenSidebarMobile:
        type === ViewType.MOBILE && props.isOpenSidebarMobile,
      isLeafNode: props.isLeafNode,
    };

    dispatch(setSideBar(newSideBarInfo));
  };

  const onSelectRework = async (e) => {
    let data = e.data;

    if (data.module) {
      let _rework = {
        functionCd: data.function?.url,
        isLeafNode: e.children !== undefined || e.leaf,
        isOpenSidebarMobile:
          type === ViewType.MOBILE &&
          data.function?.url?.split("/").length === 2,
      };

      if (data.module?.subMenus?.length > 0) {
        _rework = {
          ..._rework,
          isOpenSidebarMobile:
            type === ViewType.MOBILE && e.children !== undefined,
        };
      } else if (e.leaf) {
        return;
      }

      onDispatchRework(_rework);
    }
  };

  // Sidebar Menus
  function SidebarMenus(props) {
    const menus = props.menus;

    return (
      <>
        <ul id="sidebar-menus-container">
          {menus.length > 0 ? (
            menus
              .filter((x) => x.display)
              .map((menu) => {
                return <SidebarMenuItem key={menu.key} menuItem={menu} />;
              })
          ) : (
            <></>
          )}
        </ul>
      </>
    );
  }

  function SidebarMenuItem(props) {
    const menuItem = props.menuItem;
    const menuItemKey =
      menuItem.data.function && menuItem.data.function.functionCd;

    // If data.function is undefined then current selectedKey ==== menuItem.key
    const selectedKeyWithUndefinedFunction =
      !menuItemKey && sideBarInfo.selectedKey === menuItem.key;

    // If data.function is defined then current selectedKey === menuItemKey
    const selectedKeyWithDefinedFunction =
      menuItem.isActive && menuItem.display;

    const selectedSidebarMenu =
      selectedKeyWithUndefinedFunction || selectedKeyWithDefinedFunction;

    return (
      <>
        <li
          className={`${
            selectedSidebarMenu
              ? "sidebar-menu-item-list-container selected-sidebar-menu-item-list-container"
              : "sidebar-menu-item-list-container"
          }`}
          onClick={() => {
            //when same menu is repeatively clicked
            if (
              sideBarInfo.selectedKey
                .split("~")
                .find((x) => x === menuItem.key && menuItem.children)
            ) {
              let newSideBarInfo = {};
              newSideBarInfo = {
                ...sideBarInfo,
                isLeafNode:
                  !sideBarInfo.isLeafNode && menuItem?.children?.length > 0,
              };
              dispatch(setSideBar(newSideBarInfo));
              return;
            }
            document.body.classList.remove("block-scroll-in-mobile");
            onSelectRework(menuItem);
          }}
        >
          <div
            className={`${
              selectedSidebarMenu
                ? "sidebar-menu-item-container selected-sidebar-menu-item-container"
                : "sidebar-menu-item-container"
            }`}
          >
            <div
              className={`${
                selectedSidebarMenu
                  ? `sidebar-menu-item-icon ${menuItem.data.module.icon} selected-sidebar-menu-item-icon`
                  : `sidebar-menu-item-icon ${menuItem.data.module.icon}`
              }`}
            />
            <div
              className={`${
                selectedSidebarMenu
                  ? "sidebar-menu-item-text selected-sidebar-menu-item-text"
                  : "sidebar-menu-item-text"
              }`}
            >
              {menuItem.data.module.name}
            </div>
          </div>
        </li>
      </>
    );
  }

  const renderSideBar = (
    isOpenSidebarMobile = true,
    sideBarId = ViewType.WEB
  ) => {
    let selectedSidebarSubMenus = sideBarInfo.isLeafNode ?? false;
    return (
      <Sidebar
        id={sideBarId}
        className={
          selectedSidebarSubMenus
            ? "sidebar-left sidebar-left-submenu-mobile-expand"
            : "sidebar-left"
        }
        visible={isOpenSidebarMobile}
        modal={true}
        showCloseIcon={false}
        baseZIndex={999}
        closeOnEscape
        onHide={() => {
          let newSideBarInfo = {
            ...sideBarInfo,
            isOpenSidebarMobile: false,
          };
          document.body.classList.remove("block-scroll-in-mobile");
          dispatch(setSideBar(newSideBarInfo));
        }}
      >
        <ScrollPanel className="sidebar-left-scroll-panel">
          <SidebarMenus menus={menuTree} />
        </ScrollPanel>
        {selectedSidebarSubMenus && (
          <MainSideBarLeaf
            type={sideBarId}
            onSelectRework={(e) => onSelectRework({ ...e, leaf: true })}
            menu={
              menuTree.length > 0 &&
              menuTree.find(
                (x) => x.isActive && x.display && x.children?.length > 0
              )
            }
          />
        )}
      </Sidebar>
    );
  };

  return renderSideBar(visible, type);
}
export default MainSideBar;
