import { getColumModel } from "./../../../../../components/base-table/base-table-model";
import moment from "moment";
import { BCType } from "../../../../../components/base-control/base-control";
import { convertApiDateFormatToPrime } from "../../../../../utils/utils";
import { filter } from "lodash";
import { DATE_FORMAT } from "../../constants/contants";
import { LeaveReportService } from "../../../../../services/hrmnet-api";
import { ToUIOption } from "../../../public/login/components/utils";

export const LEAVE_REPORT_TYPE = {
  LEAVE_TRANSACTION_REPORT: "LEAVE_TRANSACTION",
  LEAVE_BALANCE_REPORT: "LEAVE_BALANCE",
  SICK_LEAVE_DETAILS_REPORT: "SICK_LEAVE_DETAILS",
  ANNUAL_LEAVE_REPORT: "ANNUAL_LEAVE_REPORT",
  STATUTORY_LEAVE_BALANCE_REPORT: "STATUTORY_LEAVE_BALANCE",
};

export const LEAVE_REPORT_KEY = {
  LEAVE_TRANSACTION_REPORT: "Leave_Transaction_Report",
  LEAVE_BALANCE_REPORT: "Leave_Balance_Report",
  SICK_LEAVE_DETAILS_REPORT: "Sick_Leave_Details_Report",
  ANNUAL_LEAVE_REPORT: "Annual_Leave_Report",
  STATUTORY_LEAVE_BALANCE_REPORT: "Statutory_Leave_Balance_Report",
};

// Filter config
const employeeCodeFilter = ({ employeeCodeFilterOption, t }) => ({
  key: "employeeCode",
  type: BCType.multiselect,
  label: t("leave_report_filter_employeeCode"),
  enum: employeeCodeFilterOption,
  placeholder: t("base_control_select_choose"),
  config: { filter: true },
});

const reportingLineFilter = ({ reportingLineFilterOption, t }) => ({
  key: "reportingLine",
  type: BCType.select,
  label: t("leave_report_filter_reportingLine"),
  enum: reportingLineFilterOption,
  placeholder: t("base_control_select_choose"),
});

const leaveDateFromFilter = ({ dateFormat = "yyyy-MM-dd", t }) => ({
  key: "leaveDateFrom",
  type: BCType.date,
  label: t("leave_report_filter_leaveDateFrom"),
  dateFormat: convertApiDateFormatToPrime(dateFormat),
  placeholder: dateFormat?.toUpperCase(),
});

const leaveDateToFilter = ({ dateFormat = "yyyy-MM-dd", t }) => ({
  key: "leaveDateTo",
  type: BCType.date,
  label: t("leave_report_filter_leaveDateTo"),
  dateFormat: convertApiDateFormatToPrime(dateFormat),
  placeholder: dateFormat?.toUpperCase(),
});

const leaveDateFromMonthFilter = ({ dateFormat = "yyyy-MM", t }) => ({
  key: "leaveDateFromMonth",
  type: BCType.date,
  label: t("leave_report_filter_leaveDateFrom"),
  dateFormat: convertApiDateFormatToPrime(dateFormat),
  placeholder: dateFormat?.toUpperCase(),
  config: {
    monthPicker: true,
    yearRange: `${new Date().getFullYear() - 10}:${
      new Date().getFullYear() + 1
    }`,
  },
});

const leaveDateToMonthFilter = ({ dateFormat = "yyyy-MM", t }) => ({
  key: "leaveDateToMonth",
  type: BCType.date,
  label: t("leave_report_filter_leaveDateTo"),
  dateFormat: convertApiDateFormatToPrime(dateFormat),
  placeholder: dateFormat?.toUpperCase(),
  config: {
    monthPicker: true,
    yearRange: `${new Date().getFullYear() - 10}:${
      new Date().getFullYear() + 1
    }`,
  },
});

const leaveTypeFilter = ({ leaveTypeFilterOptions, t }) => ({
  key: "leaveType",
  type: BCType.select,
  label: t("leave_report_filter_leaveType"),
  enum: leaveTypeFilterOptions,
  placeholder: t("base_control_select_choose"),
});

const leaveTypeMultiSelectFilter = ({ leaveTypeFilterOptions, t }) => ({
  key: "leaveTypeMulti",
  type: BCType.multiselect,
  label: t("leave_report_filter_leaveType"),
  enum: leaveTypeFilterOptions,
  placeholder: t("base_control_select_choose"),
  config: {
    display: "chip",
    tooltip: false,
  },
});

const transactionTypeFilter = ({ transactionTypeFilterOptions, t }) => ({
  key: "transactionType",
  type: BCType.multiselect,
  label: t("leave_report_filter_transactionType"),
  enum: transactionTypeFilterOptions,
  placeholder: t("base_control_select_choose"),
  config: {
    display: "chip",
    tooltip: false,
  },
});

const yearFilter = ({
  // yearFilterOptions = [{ label: "2021", value: "2021" }],
  yearsFilterOptions,
  t,
}) => ({
  key: "year",
  type: BCType.select,
  label: t("leave_report_filter_year"),
  enum: yearsFilterOptions,
  placeholder: t("base_control_select_choose"),
});

const leaveTransactionReportFilter = (config, filterWithOption) => [
  [employeeCodeFilter(config), reportingLineFilter(config)],
  [leaveDateFromFilter(config), leaveDateToFilter(config)],
  [
    ...(filterWithOption.includes("leaveTypeFilterOptions")
      ? [leaveTypeMultiSelectFilter(config)]
      : []),
    transactionTypeFilter(config),
  ],
];

const leaveBalanceSummaryReportFilter = (config) => [
  [employeeCodeFilter(config), reportingLineFilter(config)],
  [leaveTypeFilter(config), yearFilter(config)],
];

const sickLeaveDetailsReportFilter = (config) => [
  [employeeCodeFilter(config), reportingLineFilter(config)],
  [leaveTypeFilter(config)],
  [leaveDateFromMonthFilter(config), leaveDateToMonthFilter(config)],
];

const annualLeaveReportFilter = (config) => [
  [leaveTypeFilter(config), yearFilter(config)],
];

const statutoryLeaveBalanceReportFilter = (config) => [
  //DALVIN TO-DO ... check if filters are correct...
  [employeeCodeFilter(config), reportingLineFilter(config)],
  [leaveTypeFilter(config)],
  [leaveDateFromMonthFilter(config), leaveDateToMonthFilter(config)],
];

// Report Column Config
export const LEAVE_REPORT_DEFAULT_COLUMN_CONFIG = {
  width: 11,
  dataType: "string",
  sortable: false,
  filter: true,
};

// Leave Transaction
const submissionDate = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_SubmissionDate"),
  key: "submissionDate",
});

const applicationTime = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_ApplicationTime"),
  key: "applicationTime",
});

const referenceNo = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_ReferenceNo"),
  key: "referenceNo",
});

const transactionRef = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_TransactionRef"),
  key: "transactionRef",
});

const company = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_Company"),
  key: "company",
  width: "12",
});

const location = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_Location"),
  key: "location",
});

const employeeCode = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_EmployeeCode"),
  key: "employeeCode",
});

const employeeName = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_EmployeeName"),
  key: "employeeName",
});

const department = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_Department"),
  key: "department",
});

const leaveType = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_LeaveType"),
  key: "leaveType",
});

const transactionType = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_TransactionType"),
  key: "transactionType",
});

const month = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_Month"),
  key: "month",
});

const fromDate = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_FromDate"),
  key: "fromDate",
});

const fromTime = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_FromTime"),
  key: "fromTime",
});

const toDate = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_ToDate"),
  key: "toDate",
});

const toTime = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_ToTime"),
  key: "toTime",
});

const appliedUnits = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_AppliedUnits"),
  key: "appliedUnits",
});

const unitOfMeasure = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_UnitOfMeasure"),
  key: "unitOfMeasure",
});

const applicationStatus = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_ApplicationStatus"),
  key: "applicationStatus",
});

const workflowStatus = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_WorkflowStatus"),
  key: "workflowStatus",
});

const approverName = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_ApproverName"),
  key: "approverName",
});

const approverCompletionDate = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_ApproverCompletionDate"),
  key: "approverCompletionDate",
});

const remarksFromApprover = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_RemarksFromApprover"),
  key: "remarksFromApprover",
});

const remarksFromApplicant = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_RemarksFromApplicant"),
  key: "remarksFromApplicant",
});

// Leave Balance
const status = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_Status"),
  key: "status",
});

const staffType = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_StaffType"),
  key: "staffType",
});

const dateJoinedGroup = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_DateJoinedGroup"),
  key: "dateJoinedGroup",
  width: "13",
});

const dateJoinedCompany = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_DateJoinedCompany"),
  key: "dateJoinedCompany",
  width: "13",
});

const lastEmploymentDate = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_LastEmploymentDate"),
  key: "lastEmploymentDate",
});

const grade = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_Grade"),
  key: "grade",
});

const costCenter = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_CostCenter"),
  key: "costCenter",
});

const email = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_Email"),
  key: "email",
});

const yearPerioudFrom = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_YearPeriodFrom"),
  key: "yearPeriodFrom",
  width: "13",
});

const yearPeriodTo = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_YearPeriodTo"),
  key: "yearPeriodTo",
  width: "13",
});

const grandfatherEntitlement = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_GrandfatherEntitlement"),
  key: "grandfatherEntitlement",
});

const totalLeaveBalanceBroughtForward = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_TotalLeaveBalanceBroughtForward"),
  key: "totalLeaveBalanceBroughtForward",
});

const priorYearAdjustment = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_PriorYearAdjustment"),
  key: "priorYearAdjustment",
});

const totalCurrentYearLeaveEntitlement = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_TotalCurrentYearLeaveEntitlement"),
  key: "totalCurrentYearLeaveEntitlement",
});

const netOpeningLeaveBalance = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_NetOpeningLeaveBalance"),
  key: "netOpeningLeaveBalance",
});

const currentYearAdjustment = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_CurrentYearAdjustment"),
  key: "currentYearAdjustment",
});

const month1 = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_Month1"),
  key: "month1",
});

const month2 = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_Month2"),
  key: "month2",
});

const month3 = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_Month3"),
  key: "month3",
});

const month4 = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_Month4"),
  key: "month4",
});

const month5 = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_Month5"),
  key: "month5",
});

const month6 = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_Month6"),
  key: "month6",
});

const month7 = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_Month7"),
  key: "month7",
});

const month8 = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_Month8"),
  key: "month8",
});

const month9 = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_Month9"),
  key: "month9",
});

const month10 = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_Month10"),
  key: "month10",
});

const month11 = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_Month11"),
  key: "month11",
});

const month12 = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_Month12"),
  key: "month12",
});

const totalLeaveApplied = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_TotalLeaveApplied"),
  key: "totalLeaveApplied",
});

const daysEncashed = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_DaysEncashed"),
  key: "daysEncashed",
});

const forfeiture = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_Forfeiture"),
  key: "forfeiture",
});

const yearEndTotalProjectedBalance = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_YearEndTotalProjectedBalance"),
  key: "yearEndTotalProjectedBalance",
});

const sickLeavePeriod = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_SickLeavePeriod"),
  key: "sickLeavePeriod",
  width: "15",
});

const boughtForward = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_BoughtForward"),
  key: "broughtForward",
  width: "8",
});

const entitlement = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_Entitlement"),
  key: "entitlement",
  width: "10",
});

const applied = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_Applied"),
  key: "applied",
  width: "10",
});

const adjusted = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_Adjusted"),
  key: "adjusted",
  width: "8",
});

const balance = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_Balance"),
  key: "balance",
  width: "8",
});

//Annual Leave props
const entity = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_Entity"),
  key: "entity",
  width: "8",
});

const companyCode = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_CompanyCode"),
  key: "companyCode",
  width: "8",
});

const sesaId = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_SesaId"),
  key: "sesaId",
  width: "8",
});

const businessUnit = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_BusinessUnit"),
  key: "businessUnit",
  width: "8",
});

const wholeYearEntitlement = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_WholeYearEntitlement"),
  key: "wholeYearEntitlement",
  width: "8",
});

const entitlementStatutory = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_EntitlementStatutory"),
  key: "entitlementStatutory",
  width: "8",
});

const entitlementCompany = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_EntitlementCompany"),
  key: "entitlementCompany",
  width: "8",
});

const leaveTaken = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_LeaveTaken"),
  key: "leaveTaken",
  width: "8",
});

const balanceBeforeStatutory = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_BalanceBeforeStatutory"),
  key: "balanceBeforeStatutory",
  width: "8",
});

const balanceBeforeCompany = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_BalanceBeforeCompany"),
  key: "balanceBeforeCompany",
  width: "8",
});

const essForfeiture = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_EssForfeiture"),
  key: "essForfeiture",
  width: "8",
});

const hrForfeiture = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_HRForfeiture"),
  key: "hrForfeiture",
  width: "8",
});

const essView = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_EssView"),
  key: "essView",
  width: "8",
});

const leaveCarriedStatutory = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_LeaveCarriedStatutory"),
  key: "leaveCarriedStatutory",
  width: "8",
});

const leaveCarriedCompany = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_LeaveCarriedCompany"),
  key: "leaveCarriedCompany",
  width: "8",
});

// Statutory Leave Balance
const coverPeriodStartDate = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_CoverPeriodStartDate"),
  key: "coverPeriodStartDate",
  width: "13",
});

const coverPeriodStartTime = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_CoverPeriodStartTime"),
  key: "coverPeriodStartTime",
});

const coverPeriodEndDate = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_CoverPeriodEndDate"),
  key: "coverPeriodEndDate",
  width: "13",
});

const coverPeriodEndTime = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_CoverPeriodEndTime"),
  key: "coverPeriodEndTime",
});

const lyStatutoryMovement = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_LYStatutoryMovement"),
  key: "lyStatutoryMovement",
});

const lyStatutoryBalance = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_LYStatutoryBalance"),
  key: "lyStatutoryBalance",
});

const tyStatutoryMovement = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_TYStatutoryMovement"),
  key: "tyStatutoryMovement",
});

const tyStatutoryBalance = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_TYStatutoryBalance"),
  key: "tyStatutoryBalance",
});

const companyMovement = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_CompanyMovement"),
  key: "companyMovement",
});

const companyBalance = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_CompanyBalance"),
  key: "companyBalance",
});

const totalYearly = (t) => ({
  ...LEAVE_REPORT_DEFAULT_COLUMN_CONFIG,
  header: t("leave_report_column_TotalYearly"),
  key: "totalYearly",
});

// Column Config
const leaveTransactionReportColumns = (t) => [
  submissionDate(t),
  applicationTime(t),
  referenceNo(t),
  transactionRef(t),
  company(t),
  location(t),
  employeeCode(t),
  employeeName(t),
  department(t),
  leaveType(t),
  transactionType(t),
  month(t),
  fromDate(t),
  fromTime(t),
  toDate(t),
  toTime(t),
  appliedUnits(t),
  unitOfMeasure(t),
  applicationStatus(t),
  workflowStatus(t),
  approverName(t),
  approverCompletionDate(t),
  remarksFromApprover(t),
  remarksFromApplicant(t),
];

const leaveBalanceReportColumns = (t) => [
  company(t),
  location(t),
  employeeCode(t),
  employeeName(t),
  department(t),
  status(t),
  staffType(t),
  dateJoinedGroup(t),
  dateJoinedCompany(t),
  lastEmploymentDate(t),
  grade(t),
  costCenter(t),
  email(t),
  yearPerioudFrom(t),
  yearPeriodTo(t),
  grandfatherEntitlement(t),
  totalLeaveBalanceBroughtForward(t),
  priorYearAdjustment(t),
  totalCurrentYearLeaveEntitlement(t),
  netOpeningLeaveBalance(t),
  currentYearAdjustment(t),
  month1(t),
  month2(t),
  month3(t),
  month4(t),
  month5(t),
  month6(t),
  month7(t),
  month8(t),
  month9(t),
  month10(t),
  month11(t),
  month12(t),
  totalLeaveApplied(t),
  daysEncashed(t),
  forfeiture(t),
  yearEndTotalProjectedBalance(t),
];

const sickLeaveDetailsReportColumns = (t) => [
  department(t),
  employeeCode(t),
  employeeName(t),
  status(t),
  dateJoinedGroup(t),
  dateJoinedCompany(t),
  sickLeavePeriod(t),
  boughtForward(t),
  entitlement(t),
  applied(t),
  adjusted(t),
  balance(t),
];

const annualLeaveReportColumns = (t) => [
  entity(t),
  employeeCode(t),
  companyCode(t),
  sesaId(t),
  employeeName(t),
  dateJoinedGroup(t),
  lastEmploymentDate(t),
  businessUnit(t),
  department(t),
  wholeYearEntitlement(t),
  entitlementStatutory(t),
  entitlementCompany(t),
  leaveTaken(t),
  balanceBeforeStatutory(t),
  balanceBeforeCompany(t),
  essForfeiture(t),
  hrForfeiture(t),
  essView(t),
  leaveCarriedStatutory(t),
  leaveCarriedCompany(t),
];

const statutoryLeaveBalanceReportColumns = (t) => [
  //DALVIN TO-DO ... check if columns are correct...
  department(t),
  employeeCode(t),
  employeeName(t),
  coverPeriodStartDate(t),
  coverPeriodStartTime(t),
  coverPeriodEndDate(t),
  coverPeriodEndTime(t),
  transactionType(t),
  lyStatutoryMovement(t),
  lyStatutoryBalance(t),
  tyStatutoryMovement(t),
  tyStatutoryBalance(t),
  companyMovement(t),
  companyBalance(t),
  totalYearly(t),
];

// Filter Payload
const prepareFilterPayloadLeaveTransaction = (form) => {
  let payload = {};
  if (!form) return payload;

  if (form.employeeCode) {
    payload.employeeCodes = form.employeeCode;
  }
  if (form.reportingLine) {
    payload.reportingLines = [form.reportingLine];
  }
  if (form.leaveDateFrom) {
    const value = moment(form.leaveDateFrom).format(DATE_FORMAT);
    payload.leaveDate = payload.leaveDate
      ? { ...payload.leaveDate, from: value }
      : { from: value };
  }
  if (form.leaveDateTo) {
    const value = moment(form.leaveDateTo).format(DATE_FORMAT);
    payload.leaveDate = payload.leaveDate
      ? { ...payload.leaveDate, to: value }
      : { to: value };
  }
  if (form.leaveTypeMulti) {
    payload.leaveTypes = form.leaveTypeMulti;
  }
  if (form.transactionType) {
    payload.transactionTypes = form.transactionType;
  }
  return payload;
};

const prepareFilterPayloadLeaveBalance = (form) => {
  let payload = {};
  if (!form) return payload;

  if (form.employeeCode) {
    payload.employeeCodes = form.employeeCode;
  }
  if (form.reportingLine) {
    payload.reportingLine = form.reportingLine;
  }
  if (form.leaveType) {
    payload.leaveCode = form.leaveType;
  }
  if (form.year) {
    payload.year = form.year;
  }

  return payload;
};

const prepareFilterPayloadSickLeaveDetails = (form) => {
  let payload = {};
  if (!form) return payload;

  if (form.employeeCode) {
    payload.employeeCodes = form.employeeCode;
  }
  if (form.reportingLine) {
    payload.reportingLine = form.reportingLine;
  }
  if (form.leaveType) {
    payload.leaveCode = form.leaveType;
  }
  if (form.leaveDateFromMonth) {
    const value = moment(form.leaveDateFromMonth)
      .startOf("month")
      .format(DATE_FORMAT);
    payload.from = value;
  }
  if (form.leaveDateToMonth) {
    const value = moment(form.leaveDateToMonth)
      .endOf("month")
      .format(DATE_FORMAT);
    payload.to = value;
  }

  // if (form.leaveDateFrom) {
  //   const value = moment(form.leaveDateFrom).format(DATE_FORMAT);
  //   payload.from = value;
  //   // payload.leaveDate = payload.leaveDate
  //   //   ? { ...payload.leaveDate, from: value }
  //   //   : { from: value };
  // }
  // if (form.leaveDateTo) {
  //   const value = moment(form.leaveDateTo).format(DATE_FORMAT);
  //   payload.to = value;
  //   // payload.leaveDate = payload.leaveDate
  //   //   ? { ...payload.leaveDate, to: value }
  //   //   : { to: value };
  // }

  return payload;
};

const prepareFilterPayloadAnnualLeave = (form) => {
  let payload = {};
  if (!form) return payload;

  if (form.leaveType) {
    payload.leaveCode = form.leaveType;
  }
  if (form.year) {
    payload.year = form.year;
  }
  return payload;
};

const prepareFilterPayloadStatutoryLeaveBalance = (form) => {
  //DALVIN TO-DO ... check if validations are correct...
  let payload = { leaveDate: {} };
  if (!form) return payload;

  if (form.employeeCode) {
    payload.employeeCodes = form.employeeCode;
  }
  if (form.reportingLine) {
    payload.reportingLine = form.reportingLine;
  }
  if (form.leaveType) {
    payload.leaveCode = form.leaveType;
  }
  if (form.leaveDateFromMonth) {
    const value = moment(form.leaveDateFromMonth)
      .startOf("month")
      .format(DATE_FORMAT);
    payload.leaveDate.from = value;
  }
  if (form.leaveDateToMonth) {
    const value = moment(form.leaveDateToMonth)
      .endOf("month")
      .format(DATE_FORMAT);
    payload.leaveDate.to = value;
  }

  return payload;
};

// Fetch Filter
const parseApiFilterLeaveTransaction = (data, t) => {
  let config = {};
  if (data.employeeCodes) {
    config.employeeCodeFilterOption = data.employeeCodes.map((option) => ({
      label: option.name,
      value: option.value,
    }));
  }
  if (data.reportingLines) {
    config.reportingLineFilterOption = data.reportingLines.map((option) => ({
      label: translateReportingLine(option.name, t),
      value: option.value,
    }));
  }
  if (data.leaveTypes) {
    config.leaveTypeFilterOptions = data.leaveTypes.map((option) => ({
      label: option.name,
      value: option.value,
    }));
  }
  if (data.transactionTypes) {
    config.transactionTypeFilterOptions = data.transactionTypes.map(
      (option) => ({
        label: option.name,
        value: option.value,
      })
    );
  }
  return config;
};

const parseApiFilterLeaveBalance = (data, t) => {
  let config = {};
  if (data.employeeCodes) {
    config.employeeCodeFilterOption = data.employeeCodes.map((option) => ({
      label: option.name,
      value: option.value,
    }));
  }
  if (data.reportingLines) {
    config.reportingLineFilterOption = data.reportingLines.map((option) => ({
      label: translateReportingLine(option.name, t),
      value: option.value,
    }));
  }
  if (data.leaveTypes) {
    config.leaveTypeFilterOptions = data.leaveTypes.map((option) => ({
      label: option.name,
      value: option.value,
    }));
  }
  if (data.leaveYears) {
    config.yearsFilterOptions = data.leaveYears.map((option) => ({
      label: option.name,
      value: option.value,
    }));
  }
  return config;
};

const parseApiFilterSickLeaveDetails = (data, t) => {
  let config = {};
  if (data.employeeCodes) {
    config.employeeCodeFilterOption = data.employeeCodes.map((option) => ({
      label: option.name,
      value: option.value,
    }));
  }
  if (data.reportingLines) {
    config.reportingLineFilterOption = data.reportingLines.map((option) => ({
      label: translateReportingLine(option.name, t),
      value: option.value,
    }));
  }
  if (data.leaveTypes) {
    config.leaveTypeFilterOptions = data.leaveTypes.map((option) => ({
      label: option.name,
      value: option.value,
    }));
  }
  return config;
};

const parseApiFilterAnnualLeave = (data) => {
  let config = {};

  if (data.leaveTypes) {
    config.leaveTypeFilterOptions = data.leaveTypes.map((option) => ({
      label: option.name,
      value: option.value,
    }));
  }
  if (data.leaveYears) {
    config.yearsFilterOptions = data.leaveYears.map((option) => ({
      label: option.name,
      value: option.value,
    }));
  }
  return config;
};

const translateReportingLine = (data, t) => {
  if (data != null) {
    if (data == "FUNCTION_REPORT_TO") {
      return t("leave_report_filter_function_report_to");
    } else {
      return data;
    }
  }
};

const parseApiFilterStatutoryLeaveBalance = (data, t) => {
  //DALVIN TO-DO ... check if validations are correct...
  let config = {};
  if (data.employeeCodes) {
    config.employeeCodeFilterOption = data.employeeCodes.map((option) => ({
      label: option.name,
      value: option.value,
    }));
  }
  if (data.reportingLines) {
    config.reportingLineFilterOption = data.reportingLines.map((option) => ({
      label: translateReportingLine(option.name, t),
      value: option.value,
    }));
  }
  if (data.leaveTypes) {
    config.leaveTypeFilterOptions = data.leaveTypes.map((option) => ({
      label: option.name,
      value: option.value,
    }));
  }
  return config;
};

// Export
export const getReportFilterConfig = (
  reportType,
  config,
  filterWithOption,
  commonFilter
) => {
  let filters;
  switch (reportType) {
    case LEAVE_REPORT_TYPE.LEAVE_TRANSACTION_REPORT:
      filters = leaveTransactionReportFilter(config, filterWithOption);
      break;
    case LEAVE_REPORT_TYPE.LEAVE_BALANCE_REPORT:
      filters = leaveBalanceSummaryReportFilter(config);
      break;
    case LEAVE_REPORT_TYPE.SICK_LEAVE_DETAILS_REPORT:
      filters = sickLeaveDetailsReportFilter(config);
      break;
    case LEAVE_REPORT_TYPE.ANNUAL_LEAVE_REPORT:
      filters = annualLeaveReportFilter(config);
      break;
    case LEAVE_REPORT_TYPE.STATUTORY_LEAVE_BALANCE_REPORT:
      filters = statutoryLeaveBalanceReportFilter(config);
      break;
    default:
      break;
  }

  function convertToColumns(array, columns) {
    const result = [];
    let row = [];

    for (let i = 0; i < array.length; i++) {
      row.push(array[i]);

      if ((i + 1) % columns === 0 || i === array.length - 1) {
        result.push(row);
        row = [];
      }
    }

    return result;
  }

  if (Array.isArray(commonFilter) && commonFilter.length > 0) {
    let start = -1;
    let employeeCodeFilterIndex = filters.findIndex(
      (row) =>
        row.findIndex((column) =>
          ["employeeCode", "reportingLine"].includes(column.key)
        ) > -1
    );
    let leaveTypeFilterIndex = filters.findIndex(
      (row) =>
        row.findIndex((column) => ["leaveType", "year"].includes(column.key)) >
        -1
    );
    if (employeeCodeFilterIndex < 0 && leaveTypeFilterIndex > -1) {
      start = leaveTypeFilterIndex;
    }

    if (employeeCodeFilterIndex > -1) {
      start = employeeCodeFilterIndex;
      ++start;
    }

    if (start > -1) {
      let newRows = convertToColumns(commonFilter, 2);
      newRows.forEach((row, index) => {
        filters.splice(start + index, 0, row);
      });
    }
  }

  if (filters) {
    return {
      controls: filters.reduce((acc, cur) => {
        acc = acc.concat([...cur]);
        return acc;
      }, []),
      layout: {
        rows: filters.map((filterRow) => {
          return {
            columns: filterRow.map((filter) => {
              return {
                control: filter.key,
                config: {
                  className: "p-col-12 p-lg-4 p-md-6 p-sm-12",
                },
              };
            }),
          };
        }),
      },
    };
  }
  return {};
};

export const getReportColumnConfig = (reportType, t) => {
  let columns = [];
  switch (reportType) {
    case LEAVE_REPORT_TYPE.LEAVE_TRANSACTION_REPORT:
      columns = leaveTransactionReportColumns(t);
      break;
    case LEAVE_REPORT_TYPE.LEAVE_BALANCE_REPORT:
      columns = leaveBalanceReportColumns(t);
      break;
    case LEAVE_REPORT_TYPE.SICK_LEAVE_DETAILS_REPORT:
      columns = sickLeaveDetailsReportColumns(t);
      break;
    case LEAVE_REPORT_TYPE.ANNUAL_LEAVE_REPORT:
      columns = annualLeaveReportColumns(t);
      break;
    case LEAVE_REPORT_TYPE.STATUTORY_LEAVE_BALANCE_REPORT:
      columns = statutoryLeaveBalanceReportColumns(t);
      break;
    default:
      break;
  }
  return columns;
};

export const parseApiFilter = (reportType, data, translation) => {
  let filters = {};
  let configs = [];
  switch (reportType) {
    case LEAVE_REPORT_TYPE.LEAVE_TRANSACTION_REPORT:
      filters = parseApiFilterLeaveTransaction(data, translation);
      break;
    case LEAVE_REPORT_TYPE.LEAVE_BALANCE_REPORT:
      filters = parseApiFilterLeaveBalance(data, translation);
      break;
    case LEAVE_REPORT_TYPE.SICK_LEAVE_DETAILS_REPORT:
      filters = parseApiFilterSickLeaveDetails(data, translation);
      break;
    case LEAVE_REPORT_TYPE.ANNUAL_LEAVE_REPORT:
      filters = parseApiFilterAnnualLeave(data);
      break;
    case LEAVE_REPORT_TYPE.STATUTORY_LEAVE_BALANCE_REPORT:
      filters = parseApiFilterStatutoryLeaveBalance(data, translation);
      break;
    default:
      return { filters, configs };
  }

  //adding common general filters
  if (!!data) {
    for (const property in data) {
      let filter = data[property];
      if (
        filter.hasOwnProperty("field") &&
        filter.hasOwnProperty("dataList") &&
        filter.hasOwnProperty("translationKey")
      ) {
        let dataList = ToUIOption(filter.dataList);
        let translationKey = filter.translationKey;
        if (Array.isArray(dataList)) {
          filters[property] = dataList;
          configs.push({
            key: property,
            type: filter?.ComponentType ?? BCType.multiselect,
            label: translation(translationKey),
            enum: dataList,
            placeholder: translation("base_control_select_choose"),
          });
        }
      }
    }
  }

  return { filters, configs };
};

const prepareDefaultFilterValueLeaveTransaction = (filters) => {
  let defaultFilters = {};
  if (filters.employeeCodeFilterOption?.length === 1) {
    defaultFilters.employeeCode = [filters.employeeCodeFilterOption[0].value];
    defaultFilters.employeeCode_obj = [filters.employeeCodeFilterOption[0]];
  }
  // if (filters.reportingLineFilterOption.length > 0) {
  //   const targetReportingLineALL = filters.reportingLineFilterOption.filter(
  //     (rl) => rl.value === "ALL"
  //   );
  //   if (targetReportingLineALL.length > 0) {
  //     defaultFilters.reportingLine = targetReportingLineALL[0].value;
  //     defaultFilters.reportingLine_obj = targetReportingLineALL[0];
  //   }
  // }
  if (filters.leaveTypeFilterOptions?.length > 0) {
    const targetLeaveTypeALN = filters.leaveTypeFilterOptions.filter(
      (lt) => lt.value === "ALN"
    );
    const targetLeaveTypePTO = filters.leaveTypeFilterOptions.filter(
      (lt) => lt.value === "PTO"
    );
    if (targetLeaveTypeALN.length > 0) {
      defaultFilters.leaveTypeMulti = [targetLeaveTypeALN[0].value];
      defaultFilters.leaveTypeMulti_obj = [targetLeaveTypeALN[0]];
    } else if (targetLeaveTypePTO.length > 0) {
      defaultFilters.leaveTypeMulti = [targetLeaveTypePTO[0].value];
      defaultFilters.leaveTypeMulti_obj = [targetLeaveTypePTO[0]];
    } else {
      defaultFilters.leaveTypeMulti = [filters.leaveTypeFilterOptions[0].value];
      defaultFilters.leaveTypeMulti_obj = [filters.leaveTypeFilterOptions[0]];
    }
  }
  if (filters.transactionTypeFilterOptions?.length > 0) {
    let transactionType = [];
    let transactionType_obj = [];
    filters.transactionTypeFilterOptions.forEach((tr) => {
      transactionType.push(tr.value);
      transactionType_obj.push(tr);
    });
    defaultFilters.transactionType = transactionType;
    defaultFilters.transactionType_obj = transactionType_obj;
  }
  defaultFilters.leaveDateFrom = new Date(
    moment().startOf("month").format(DATE_FORMAT)
  );
  defaultFilters.leaveDateTo = new Date(
    moment().endOf("month").format(DATE_FORMAT)
  );

  return defaultFilters;
};

const prepareDefaultFilterValueLeaveBalance = (filters) => {
  let defaultFilters = {};
  if (filters.employeeCodeFilterOption?.length === 1) {
    defaultFilters.employeeCode = [filters.employeeCodeFilterOption[0].value];
    defaultFilters.employeeCode_obj = [filters.employeeCodeFilterOption[0]];
  }
  if (filters.leaveTypeFilterOptions?.length > 0) {
    defaultFilters.leaveType = filters.leaveTypeFilterOptions[0].value;
    defaultFilters.leaveType_obj = filters.leaveTypeFilterOptions[0];
  }
  if (filters.yearsFilterOptions?.length > 0) {
    defaultFilters.year = filters.yearsFilterOptions[0].value;
    defaultFilters.year_obj = filters.yearsFilterOptions[0];
  }
  return defaultFilters;
};

const prepareDefaultFilterValueSickLeaveDetails = (filters) => {
  let defaultFilters = {};
  if (filters.employeeCodeFilterOption?.length === 1) {
    defaultFilters.employeeCode = [filters.employeeCodeFilterOption[0].value];
    defaultFilters.employeeCode_obj = [filters.employeeCodeFilterOption[0]];
  }
  if (filters.leaveTypeFilterOptions?.length > 0) {
    defaultFilters.leaveType = filters.leaveTypeFilterOptions[0].value;
    defaultFilters.leaveType_obj = filters.leaveTypeFilterOptions[0];
  }
  defaultFilters.leaveDateFromMonth = new Date(
    moment().startOf("month").format("YYYY-MM")
  );
  defaultFilters.leaveDateToMonth = new Date(
    moment().endOf("month").format("YYYY-MM")
  );
  return defaultFilters;
};

const prepareDefaultFilterValueAAnnualLeave = (filters) => {
  let defaultFilters = {};

  if (filters.leaveTypeFilterOptions?.length > 0) {
    defaultFilters.leaveType = filters.leaveTypeFilterOptions[0].value;
    defaultFilters.leaveType_obj = filters.leaveTypeFilterOptions[0];
  }
  if (filters.yearsFilterOptions?.length > 0) {
    defaultFilters.year = filters.yearsFilterOptions[0].value;
    defaultFilters.year_obj = filters.yearsFilterOptions[0];
  }
  return defaultFilters;
};

const prepareDefaultFilterValueStatutoryLeaveBalance = (filters) => {
  //DALVIN TO-DO ... check if validations are correct...
  let defaultFilters = {};
  if (filters.employeeCodeFilterOption?.length === 1) {
    defaultFilters.employeeCode = [filters.employeeCodeFilterOption[0].value];
    defaultFilters.employeeCode_obj = [filters.employeeCodeFilterOption[0]];
  }
  if (filters.leaveTypeFilterOptions?.length > 0) {
    defaultFilters.leaveType = filters.leaveTypeFilterOptions[0].value;
    defaultFilters.leaveType_obj = filters.leaveTypeFilterOptions[0];
  }
  defaultFilters.leaveDateFromMonth = new Date(
    moment().startOf("month").format("YYYY-MM")
  );
  defaultFilters.leaveDateToMonth = new Date(
    moment().endOf("month").format("YYYY-MM")
  );
  return defaultFilters;
};

export const prepareDefaultFilterValue = (reportType, filters) => {
  switch (reportType) {
    case LEAVE_REPORT_TYPE.LEAVE_TRANSACTION_REPORT:
      return prepareDefaultFilterValueLeaveTransaction(filters);
    case LEAVE_REPORT_TYPE.LEAVE_BALANCE_REPORT:
      return prepareDefaultFilterValueLeaveBalance(filters);
    case LEAVE_REPORT_TYPE.SICK_LEAVE_DETAILS_REPORT:
      return prepareDefaultFilterValueSickLeaveDetails(filters);
    case LEAVE_REPORT_TYPE.ANNUAL_LEAVE_REPORT:
      return prepareDefaultFilterValueAAnnualLeave(filters);
    case LEAVE_REPORT_TYPE.STATUTORY_LEAVE_BALANCE_REPORT:
      return prepareDefaultFilterValueStatutoryLeaveBalance(filters);
    default:
      return {};
  }
};

export const prepareFilterPayload = (reportType, form) => {
  let payload = {};
  switch (reportType) {
    case LEAVE_REPORT_TYPE.LEAVE_TRANSACTION_REPORT:
      payload = prepareFilterPayloadLeaveTransaction(form);
      break;
    case LEAVE_REPORT_TYPE.LEAVE_BALANCE_REPORT:
      payload = prepareFilterPayloadLeaveBalance(form);
      break;
    case LEAVE_REPORT_TYPE.SICK_LEAVE_DETAILS_REPORT:
      payload = prepareFilterPayloadSickLeaveDetails(form);
      break;
    case LEAVE_REPORT_TYPE.ANNUAL_LEAVE_REPORT:
      payload = prepareFilterPayloadAnnualLeave(form);
      break;
    case LEAVE_REPORT_TYPE.STATUTORY_LEAVE_BALANCE_REPORT:
      payload = prepareFilterPayloadStatutoryLeaveBalance(form);
      break;
    default:
      break;
  }
  return payload;
};

export const getFilterOptions = async (
  reportType,
  reportingLine,
  employeeCode
) => {
  const body = {
    reportingLine,
    employeeCode,
  };

  if (!!reportingLine) {
    body.employeeCode = employeeCode ?? [];
  }

  switch (reportType) {
    case LEAVE_REPORT_TYPE.LEAVE_TRANSACTION_REPORT:
      return LeaveReportService.leaveReportGetLeaveTransactionFilter({
        body,
      });
    case LEAVE_REPORT_TYPE.LEAVE_BALANCE_REPORT:
      return LeaveReportService.leaveReportGetLeaveBalanceFilter({
        body,
      });
    case LEAVE_REPORT_TYPE.SICK_LEAVE_DETAILS_REPORT:
      return LeaveReportService.leaveReportGetSickLeaveDetailsFilter({
        body,
      });
    case LEAVE_REPORT_TYPE.ANNUAL_LEAVE_REPORT:
      return LeaveReportService.leaveReportGetAnnualLeaveReportFilter();
    case LEAVE_REPORT_TYPE.STATUTORY_LEAVE_BALANCE_REPORT:
      return LeaveReportService.leaveReportGetStatutoryLeaveBalanceFilter({
        body,
      });
    default:
      return;
  }
};
