import "./../../../../../layouts/components/main-header/main-entity-info.scss";
import "./multi-entities-drop-down.scss";

import React from "react";
import { useRef } from "react";

import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { Tree } from "primereact/tree";
import { LazyLoadImage } from "react-lazy-load-image-component";

//const
import { IMAGE_DEFAULT } from "./../../../../../../constants/index";

function MultiEntititesDropDown(props) {
  let op = useRef();
  // const dispatch = useDispatch();
  // const client = useSelector((state) => state.client);

  const defaultLogo = IMAGE_DEFAULT.DEFAULT;

  const renderIcon = (img) => {
    return (
      <div className="hris-multi-entites-logo-container">
        <LazyLoadImage
          className="hris-multi-entites-logo-size p-justify-center p-col-align-center"
          effect="blur"
          src={img}
          onError={(e) => (e.target.src = defaultLogo)}
        />
      </div>
    );
  };

  const toggleNotification = async (e) => {
    op.toggle(e);
  };

  const updateEntityInfo = async (e) => {
    if (op) {
      op.hide();
    }
    if (props.setSelectedClient) {
      props.setSelectedClient(e);
    }
    // loadLogo(e.key, props?.entitiesList);
  };

  const entityInfoTemplate = (entity) => {
    return (
      <div className="p-grid entity-template">
        <img
          alt={entity?.value}
          src={entity.logo ?? defaultLogo}
          onLoadStart={(e) => (e.target.src = defaultLogo)}
          onError={(e) => (e.target.src = defaultLogo)}
          className="entity-template-icon"
        />
        <div>{entity?.label}</div>
      </div>
    );
  };

  const renderEntitySwitch = () => {
    return (
      <>
        <div className="main-entity-info">
          <Button
            type="button"
            className="p-button-secondary p-button-text main-entity-info-button"
            icon={`pi pi-chevron-down`}
            label={props.selectedClient?.label}
            iconPos="left"
            disabled={false}
            onClick={(e) => {
              toggleNotification(e);
            }}
          />
          <OverlayPanel
            ref={(el) => (op = el)}
            id="overlay_panel"
            className="main-entity-info-panel"
            breakpoints={{ "640px": "100vw" }}
            dismissable
          >
            <Tree
              className="c-setting-tree"
              selectionMode="single"
              selectionKeys={props.selectedClient?.key}
              onSelectionChange={async (_) => {
                if (_.value) {
                  let _selectedEntity = props?.entitiesList.find(
                    (x) => x.key === _.value
                  );
                  await updateEntityInfo(_selectedEntity);
                }
              }}
              value={props.entitiesList || []}
              nodeTemplate={entityInfoTemplate}
            />
          </OverlayPanel>
        </div>
      </>
    );
  };

  return (
    <>
      {props.entitiesList?.length > 1 ? (
        <div className="entity-display-name hris-multi-entity-flex">
          {renderIcon(props.selectedClient.logo ?? defaultLogo)}
          {renderEntitySwitch()}
        </div>
      ) : null}
    </>
  );
}
export default MultiEntititesDropDown;
