import "./main-unify-connect.scss";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useSelector } from "react-redux";

const MainUnifyConnect = ({ unifyUrl }) => {
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const global = useSelector((state) => state.global);

  function ToUnifyRedirect(data, url, configs) {
    //https://stackoverflow.com/questions/3951768/window-open-and-pass-parameters-by-post-method
    let windowName =
      "w_" + Date.now() + Math.floor(Math.random() * 100000).toString();
    var form = document.createElement("form");
    form.setAttribute("method", configs.method);
    form.setAttribute("action", url);

    form.setAttribute("target", windowName);

    for (var i in data) {
      if (data.hasOwnProperty(i)) {
        var input = document.createElement("input");
        input.type = "hidden";
        input.name = i;
        input.value = data[i];
        form.appendChild(input);
      }
    }
    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  }

  function UnifyApi(data, url, configs) {
    return new Promise((resolve, reject) => {
      configs.url = url;
      configs.data = data;
      axios
        .request(configs)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  return (
    <a
      className="button-to-link"
      onClick={() => {
        const registerSso = async () => {
          try {
            let data = new FormData();
            data.append("userid", auth.employeeCode);
            data.append("username", auth.employeeCode);
            data.append("loginProfile", global.clientInfo?.tenantCode);
            data.append("subdomain", global.clientInfo?.tenantCode);
            const configs = {
              method: "post",
              header: {
                "Content-Type": "multipart/form-data",
              },
            };
            const res = await UnifyApi(
              data,
              `${unifyUrl || window.UNIFY_URL}/HRMBoost/SSO/RegisterSSO`,
              configs
            );
            if (res?.success && res?.ssid) {
              ToUnifyRedirect(
                {
                  ssid: res?.ssid,
                },
                `${unifyUrl || window.UNIFY_URL}/verifylogin.asp`,
                configs
              );
            }
          } catch (error) {
            console.log(error);
          }
        };
        registerSso();
      }}
    >
      {t("connect_to_unify")}
    </a>
  );
};

export default MainUnifyConnect;
