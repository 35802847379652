import { CLEAR_REDUX } from "./../reduxConstants";

/**
 * set clear persist storage
 */
export const clearRedux = () => {
  return {
    type: CLEAR_REDUX,
  };
};
