import "./approve-entitlement.scss";

//components
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { resetBcDynamicPaths } from "../../../../../redux/actions/breadcrumb";
import { isResponseOk } from "../../../../../utils/utils";
import { twoColformSkeleton } from "../../utils";
import { ApplyLeaveEntitlement } from "../index";
import { LeaveService } from "../../../../../services/hrmnet-api";

const ApproveEntitlement = (props) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const applicationRef = useRef();

  useEffect(() => {
    dispatch(resetBcDynamicPaths());
  }, [dispatch]);

  useEffect(() => {
    const loadApplication = async () => {
      const applicationId = props.match.params.applicationId;
      let res = await LeaveService.leaveGetLeaveApplicationById({
        applicationId,
      });
      if (isResponseOk(res)) {
        applicationRef.current = res.data;
        setIsLoading(false);
      }
    };
    loadApplication();
  }, [props.match.params.applicationId]);

  if (isLoading) {
    return twoColformSkeleton;
  }

  return (
    <>
      <ApplyLeaveEntitlement
        application={applicationRef.current}
        readOnly
        enableApproval
      />
    </>
  );
};

export default ApproveEntitlement;
