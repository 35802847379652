import { BCType } from "../../../../../components/base-control/base-control";
import { generateBasicLayout } from "../../../../../components/base-form/utils";
import {
  convertToAttachmentControl,
  convertToControl,
  convertToNoteControl,
} from "../../utils/analysis-code-helper";
import {
  LeaveFormConfig,
  TIME_SNAP_OPTION,
  BASIC_CONTROL_LAYOUT_CONFIG,
  BASIC_CONTROL_LAYOUT_CONFIG_HALF_COLUMN,
  LeaveApplicationModel,
  initFormAnalysisCode,
  initFormAttachment,
} from "../../utils/config-helper";
import { extractUnit, getDisplayUom } from "../../utils/utils";

export { prepareApplicationPayload } from "../submit/config-helper";

export const getInitLayout = () => {
  return {
    rows: [
      {
        columns: [
          {
            control: "EmployeeCode",
            config: { ...BASIC_CONTROL_LAYOUT_CONFIG },
          },
          {
            control: "IsUserApplication",
            config: {
              ...BASIC_CONTROL_LAYOUT_CONFIG,
              className: `${BASIC_CONTROL_LAYOUT_CONFIG?.className} checkbox-custom`,
            },
          },
        ],
      },
      {
        columns: [
          {
            control: "LeaveCode",
            config: { ...BASIC_CONTROL_LAYOUT_CONFIG },
          },
        ],
      },
    ],
  };
};

export const getBasicLayout = (
  leaveInfo: LeaveFormConfig,
  isApprovedOrRejected: boolean
) => {
  const { showTime, showDate, showUnit, eventDateApplication } =
    leaveInfo.leavePreApproval?.[0] || {};

  if (eventDateApplication) {
    return {
      rows: [
        {
          columns: [
            {
              control: "EmployeeCode",
              config: { ...BASIC_CONTROL_LAYOUT_CONFIG },
            },
            {
              control: "IsUserApplication",
              config: {
                ...BASIC_CONTROL_LAYOUT_CONFIG,
                className: `${BASIC_CONTROL_LAYOUT_CONFIG?.className} checkbox-custom`,
              },
            },
          ],
        },
        {
          columns: [
            {
              control: "LeaveCode",
              config: { ...BASIC_CONTROL_LAYOUT_CONFIG },
            },
          ],
        },
        {
          columns: [
            ...(showDate
              ? [
                  {
                    control: "LeaveStartDate",
                    config: { ...BASIC_CONTROL_LAYOUT_CONFIG },
                  },
                ]
              : []),
          ],
        },
        {
          columns: [
            ...(showTime
              ? leaveInfo.leavePreApproval?.[0]?.useTimeSegmentName
                ? [
                    {
                      control: "LeaveStartTimeSegment",
                      config: { ...BASIC_CONTROL_LAYOUT_CONFIG_HALF_COLUMN },
                    },
                  ]
                : [
                    {
                      control: "LeaveStartTime",
                      config: { ...BASIC_CONTROL_LAYOUT_CONFIG_HALF_COLUMN },
                    },
                  ]
              : []),
            ...(showTime
              ? leaveInfo.leavePreApproval?.[0]?.useTimeSegmentName
                ? [
                    {
                      control: "LeaveEndTimeSegment",
                      config: { ...BASIC_CONTROL_LAYOUT_CONFIG_HALF_COLUMN },
                    },
                  ]
                : [
                    {
                      control: "LeaveEndTime",
                      config: { ...BASIC_CONTROL_LAYOUT_CONFIG_HALF_COLUMN },
                    },
                  ]
              : []),
          ],
        },
        {
          columns: [
            ...(showUnit
              ? [
                  {
                    control: "Units",
                    config: { ...BASIC_CONTROL_LAYOUT_CONFIG },
                  },
                ]
              : []),
          ],
        },
        ...(isApprovedOrRejected
          ? [
              {
                columns: [
                  {
                    control: "ApproverRemarks",
                    config: { ...BASIC_CONTROL_LAYOUT_CONFIG },
                  },
                ],
              },
            ]
          : []),
      ],
    };
  } else {
    return {
      rows: [
        {
          columns: [
            {
              control: "EmployeeCode",
              config: { ...BASIC_CONTROL_LAYOUT_CONFIG },
            },
            {
              control: "IsUserApplication",
              config: {
                ...BASIC_CONTROL_LAYOUT_CONFIG,
                className: `${BASIC_CONTROL_LAYOUT_CONFIG?.className} checkbox-custom`,
              },
            },
          ],
        },
        {
          columns: [
            {
              control: "LeaveCode",
              config: { ...BASIC_CONTROL_LAYOUT_CONFIG },
            },
          ],
        },
        {
          columns: [
            ...(showDate
              ? [
                  {
                    control: "LeaveStartDate",
                    config: { ...BASIC_CONTROL_LAYOUT_CONFIG_HALF_COLUMN },
                  },
                ]
              : []),
            ...(showTime
              ? leaveInfo.leavePreApproval?.[0]?.useTimeSegmentName
                ? [
                    {
                      control: "LeaveStartTimeSegment",
                      config: { ...BASIC_CONTROL_LAYOUT_CONFIG_HALF_COLUMN },
                    },
                  ]
                : [
                    {
                      control: "LeaveStartTime",
                      config: { ...BASIC_CONTROL_LAYOUT_CONFIG_HALF_COLUMN },
                    },
                  ]
              : []),
          ],
        },
        {
          columns: [
            ...(showDate
              ? [
                  {
                    control: "LeaveEndDate",
                    config: { ...BASIC_CONTROL_LAYOUT_CONFIG_HALF_COLUMN },
                  },
                ]
              : []),
            ...(showTime
              ? leaveInfo.leavePreApproval?.[0]?.useTimeSegmentName
                ? [
                    {
                      control: "LeaveEndTimeSegment",
                      config: { ...BASIC_CONTROL_LAYOUT_CONFIG_HALF_COLUMN },
                    },
                  ]
                : [
                    {
                      control: "LeaveEndTime",
                      config: { ...BASIC_CONTROL_LAYOUT_CONFIG_HALF_COLUMN },
                    },
                  ]
              : []),
          ],
        },
        {
          columns: [
            ...(showUnit
              ? [
                  {
                    control: "Units",
                    config: { ...BASIC_CONTROL_LAYOUT_CONFIG },
                  },
                ]
              : []),
          ],
        },
        ...(isApprovedOrRejected
          ? [
              {
                columns: [
                  {
                    control: "ApproverRemarks",
                    config: { ...BASIC_CONTROL_LAYOUT_CONFIG },
                  },
                ],
              },
            ]
          : []),
      ],
    };
  }
};

export const getInitControl = ({
  leaveTypes,
  t,
  delegatedEmployeeList = [],
}: {
  leaveTypes: any[];
  t: any;
  delegatedEmployeeList?: any[];
}) => {
  const controls = [
    {
      key: "LeaveCode",
      label: t("leave_form_leaveType"),
      type: BCType.select,
      required: true,
      enum: leaveTypes.map((leave) => ({
        label: `${leave.leaveCode} - ${leave.leaveName}`,
        value: leave.leaveCode,
      })),
      hasFilterEnum: false,
      placeholder: t("leave_form_selectLeaveType"),
    },
    {
      key: "EmployeeCode",
      label: t("leave_form_delegatedEmployee"),
      type: BCType.select,
      required: true,
      enum: delegatedEmployeeList,
      hasFilterEnum: false,
      placeholder: t("leave_form_delegatedEmployee_placeholder"),
      config: {
        disabled: false,
      },
    },
    {
      key: "IsUserApplication",
      label: t("leave_form_userSubmit"),
      type: BCType.checkbox,
      required: false,
      placeholder: t("leave_form_userSubmit_placeholder"),
    },
  ];
  return controls;
};

export const getBasicControl = ({
  leaveTypes,
  leaveInfo,
  readOnly,
  isApprovedOrRejected,
  t,
  delegatedEmployeeList,
}: {
  leaveTypes: any[];
  leaveInfo: LeaveFormConfig;
  readOnly: boolean;
  isApprovedOrRejected: boolean;
  t: any;
  delegatedEmployeeList?: any[];
}) => {
  const _formConfig = { ...leaveInfo };

  const timePickerStep =
    _formConfig.leavePreApproval?.[0]?.periodFromSnapTo &&
    _formConfig.leavePreApproval?.[0]?.periodFromSnapTo in TIME_SNAP_OPTION
      ? TIME_SNAP_OPTION[_formConfig.leavePreApproval?.[0]?.periodFromSnapTo]
          .step
      : 5;
  const sampleTimeSegment = [
    { label: "AM", value: "AM" },
    { label: "PM", value: "PM" },
  ];

  const {
    showTime,
    showDate,
    showUnit,
    eventDateApplication,
    eventDateLabel,
    unitOfMeasure,
    periodFromSnapTo,
    periodToSnapTo,
    useTimeSegmentName,
  } = _formConfig.leavePreApproval?.[0] || {};

  const displayUnit = getDisplayUom(unitOfMeasure, t);

  return [
    ,
    {
      key: "EmployeeCode",
      label: t("leave_form_delegatedEmployee"),
      type: BCType.select,
      required: true,
      enum: delegatedEmployeeList,
      hasFilterEnum: false,
      placeholder: t("leave_form_delegatedEmployee_placeholder"),
      config: {
        disabled: false,
      },
    },
    {
      key: "IsUserApplication",
      label: t("leave_form_userSubmit"),
      type: BCType.checkbox,
      required: false,
      placeholder: t("leave_form_userSubmit_placeholder"),
    },
    {
      key: "LeaveCode",
      label: t("leave_form_leaveType"),
      type: BCType.select,
      required: true,
      enum: leaveTypes.map((leave) => ({
        label: `${leave.leaveCode} - ${leave.leaveName}`,
        value: leave.leaveCode,
      })),
      hasFilterEnum: false,
      placeholder: t("leave_form_selectLeaveType"),
      config: {
        readOnly: readOnly,
      },
    },
    ...(showDate
      ? [
          {
            key: "LeaveStartDate",
            label:
              eventDateApplication && eventDateLabel
                ? eventDateLabel
                : t("leave_form_startDate"),
            type: BCType.date,
            required: true,
            dateFormat: "yy-mm-dd",
            placeholder: t("leave_form_select"),
            config: {
              readOnly: readOnly,
            },
          },
        ]
      : []),
    ...(showTime
      ? useTimeSegmentName
        ? [
            {
              key: "LeaveStartTimeSegment",
              label: t("leave_form_startTime"),
              type: BCType.select,
              required: true,
              enum: sampleTimeSegment,
              config: {
                readOnly: readOnly,
                tooltip: null,
                filter: false,
                showClear: false,
              },
            },
          ]
        : [
            {
              key: "LeaveStartTime",
              label: t("leave_form_startTime"),
              type: BCType.date,
              required: true,
              includeTimeZone: true,
              config: {
                readOnly: readOnly,
                tooltip: null,
                timeOnly: true,
                showIcon: false,
                hourFormat: "12",
                stepMinute: timePickerStep,
              },
            },
          ]
      : []),
    ...(showDate && !eventDateApplication
      ? [
          {
            key: "LeaveEndDate",
            label: t("leave_form_endDate"),
            type: BCType.date,
            required: true,
            dateFormat: "yy-mm-dd",
            placeholder: t("leave_form_select"),
            config: {
              readOnly: readOnly,
            },
          },
        ]
      : []),
    ...(showTime
      ? useTimeSegmentName
        ? [
            {
              key: "LeaveEndTimeSegment",
              label: t("leave_form_endTime"),
              type: BCType.select,
              required: true,
              enum: sampleTimeSegment,
              config: {
                readOnly: readOnly,
                tooltip: null,
                filter: false,
                showClear: false,
              },
            },
          ]
        : [
            {
              key: "LeaveEndTime",
              label: t("leave_form_endTime"),
              type: BCType.date,
              required: true,
              includeTimeZone: true,
              config: {
                readOnly: readOnly,
                tooltip: null,
                timeOnly: true,
                showIcon: false,
                hourFormat: "12",
                stepMinute: timePickerStep,
              },
            },
          ]
      : []),
    ...(showUnit
      ? [
          {
            key: "Units",
            label: t("leave_form_unit"),
            type: BCType.number,
            config: {
              suffix: displayUnit ? ` ${displayUnit}` : null,
              mode: "decimal",
              minFractionDigits: 1,
              maxFractionDigits: 1,
              readOnly: true,
              showButtons: false,
            },
            placeholderStrict: true,
            placeholder: " ",
          },
        ]
      : []),
    ...(isApprovedOrRejected
      ? [
          {
            key: "ApproverRemarks",
            label: t("leave_form_approverRemarks"),
            type: BCType.input,
            config: {
              tooltip: null,
              readOnly: true,
            },
          },
        ]
      : []),
    // {
    //   key: "Remarks",
    //   label: t("leave_form_remarks"),
    //   type: BCType.textarea,
    //   required: true,
    //   config: {
    //     autoResize: true,
    //     rows: 1,
    //     tooltip: null,
    //   },
    //   placeholder: t("leave_form_entryRemarks"),
    //   maxLength: 70,
    // },
  ];
};

export const initFormWithLeaveApplication = (
  application: LeaveApplicationModel
) => {
  let form: any = {
    LeaveCode: application.leaveCode,
    Balance: application.balanceAsOfYearEnd,
    LeaveStartDate: new Date(application.leaveFrom),
    LeaveStartTime: new Date(application.leaveFrom),
    LeaveEndDate: new Date(application.leaveTo),
    LeaveEndTime: new Date(application.leaveTo),
    Units: extractUnit(application.unit),
    Remarks: application.remarks,
    ApproverRemarks: application?.approverRemarks
      ? (application?.approverRemarks).join(" ")
      : "",
  };

  // analysis code
  if (application.analyses?.length) {
    const analysisCodeForm = initFormAnalysisCode(application.analyses);
    form = {
      ...form,
      ...analysisCodeForm,
    };
  }

  if (application.attachment?.length) {
    const attachmentForm = initFormAttachment(application.attachment);
    form = {
      ...form,
      ...attachmentForm,
    };
  }

  return form;
};

// Create form
export const getControlsAndLayout = ({
  leaveInfo,
  selectedLeaveCode,
  leaveTypes,
  isApprovedOrRejected,
  t,
  delegatedEmployeeList,
  form,
  editMode,
  delegateeMode,
  readOnly = false,
}: {
  leaveInfo: LeaveFormConfig;
  selectedLeaveCode: string;
  readOnly: boolean;
  delegateeMode?: boolean;
  editMode?: boolean;
  isApprovedOrRejected: boolean;
  leaveTypes: any[];
  t: any;
  delegatedEmployeeList: any[];
  form: any;
}) => {
  let controls = [];
  let layout: any = {};
  let _editMode = editMode ?? false;
  let _delegateeMode = delegateeMode ?? false;

  if (selectedLeaveCode) {
    controls = getBasicControl({
      leaveTypes,
      leaveInfo,
      readOnly,
      isApprovedOrRejected,
      t,
      delegatedEmployeeList,
    });
    layout = getBasicLayout(leaveInfo, isApprovedOrRejected);

    // render extra fields
    const analysisCodeControls =
      leaveInfo.leavePreApproval?.[0]?.applyEntitlementAnalysisCode.map(
        (code) =>
          convertToControl({
            config: code,
            controlConfig: {
              config: {
                readOnly: readOnly,
              },
            },
            t,
          })
      );
    if (analysisCodeControls?.length) {
      controls = controls.concat(analysisCodeControls);
      const basicLayout: any = generateBasicLayout(analysisCodeControls);
      layout.rows = layout.rows.concat(basicLayout.rows);
    }

    // render reminder
    const reminderMessage = leaveInfo.leavePreApproval?.[0]?.reminderMessage;
    if (reminderMessage) {
      const noteControls: any = [convertToNoteControl(reminderMessage)];
      controls = controls.concat(noteControls);
      const basicLayout: any = generateBasicLayout(noteControls);
      layout.rows = layout.rows.concat(basicLayout.rows);
    }

    // render required file attachment
    const requiredFileControls =
      leaveInfo.leavePreApproval?.[0]?.applyEntitlementRequiredAttachmentType.map(
        (fileConfig) =>
          convertToAttachmentControl({
            config: fileConfig,
            t: t,
            controlConfig: {
              required: true,
              config: {
                readOnly: readOnly,
              },
            },
          })
      );
    if (requiredFileControls?.length) {
      controls = controls.concat(requiredFileControls);
      const basicLayout: any = generateBasicLayout(requiredFileControls);
      layout.rows = layout.rows.concat(basicLayout?.rows);
    }

    // render optional file attachment
    const optionalFileControls =
      leaveInfo.leavePreApproval?.[0]?.applyEntitlementOptionalAttachmentType.map(
        (fileConfig) =>
          convertToAttachmentControl({
            config: fileConfig,
            t: t,
            controlConfig: {
              required: true,
              config: {
                readOnly: readOnly,
              },
            },
          })
      );
    if (optionalFileControls?.length) {
      controls = controls.concat(optionalFileControls);
      const basicLayout: any = generateBasicLayout(requiredFileControls);
      layout.rows = layout.rows.concat(basicLayout?.rows);
    }
  } else {
    controls = getInitControl({ leaveTypes, t, delegatedEmployeeList });
    layout = getInitLayout();
  }

  const _layout: any = { rows: [] };
  layout.rows.forEach((row: any) => {
    let _column = row?.columns?.filter((column: any) => {
      if (
        (!delegatedEmployeeList &&
          !Array.isArray(delegatedEmployeeList) &&
          ["EmployeeCode", "IsUserApplication"].includes(column.control)) ||
        (!form["EmployeeCode"] &&
          !form["IsUserApplication"] &&
          !["EmployeeCode", "IsUserApplication"].includes(column.control)) ||
        (_delegateeMode &&
          _editMode &&
          column.control === "IsUserApplication") ||
        (!_delegateeMode &&
          _editMode &&
          ["EmployeeCode", "IsUserApplication"].includes(column.control))
      ) {
        return false;
      }

      return true;
    });

    if (_column.length > 0) {
      _layout.rows.push({
        columns: _column,
      });
    }
  });

  controls = controls.map((x: any) => {
    if (x?.key === "EmployeeCode") {
      return {
        ...x,
        config: {
          disabled: form["IsUserApplication"] === true || _editMode,
        },
        required: !form["IsUserApplication"] && !_editMode,
      };
    }

    if (x?.key === "IsUserApplication" && _editMode) {
      return {
        ...x,
        config: {
          disabled: _editMode,
        },
        required: !_editMode,
      };
    }

    return x;
  });

  return [controls, _layout];
};
