import { Link } from "react-router-dom";
import { PortalLink } from "../../../../services/utils";
import {
  MODULE_CODE,
  FUNCTION_CODE,
  MESSAGE_TYPE,
} from "./../../../../constants/index";

function DocumentLink(url) {
  if (url) {
    return PortalLink(`${MODULE_CODE.Document}/${url}`);
  }
}

export const MessageBtn = (props) => {
  let redirectPath;
  switch (props.notification.messageType) {
    case MESSAGE_TYPE.Payslip:
      return (
        <Link to={DocumentLink(FUNCTION_CODE.Payslip)}>
          {props.takeActionBtn()}
        </Link>
      );
    case MESSAGE_TYPE.Tax:
      return (
        <Link to={DocumentLink(FUNCTION_CODE.Tax)}>
          {props.takeActionBtn()}
        </Link>
      );
    case MESSAGE_TYPE.Compensation:
      return (
        <Link to={DocumentLink(FUNCTION_CODE.Compensation)}>
          {props.takeActionBtn()}
        </Link>
      );
    case MESSAGE_TYPE.HrLetter:
      return (
        <Link to={DocumentLink(FUNCTION_CODE.HrLetters)}>
          {props.takeActionBtn()}
        </Link>
      );
    case MESSAGE_TYPE.Rental:
      redirectPath = props.notification.actionUrl
        ? props.notification.actionUrl
        : PortalLink(MODULE_CODE.Rental);
      if (redirectPath.startsWith("http")) {
        return <a href={redirectPath}>{props.takeActionBtn()}</a>;
      } else {
        return <Link to={redirectPath}>{props.takeActionBtn()}</Link>;
      }
    case MESSAGE_TYPE.Leave:
      redirectPath =
        props.notification.actionUrl || PortalLink(MODULE_CODE.Leave);
      if (redirectPath.startsWith("http")) {
        return <a href={redirectPath}>{props.takeActionBtn()}</a>;
      } else {
        return <Link to={redirectPath}>{props.takeActionBtn()}</Link>;
      }
    case MESSAGE_TYPE.Nwow:
      redirectPath =
        props.notification.actionUrl || PortalLink(MODULE_CODE.Nwow);
      if (redirectPath.startsWith("http")) {
        return <a href={redirectPath}>{props.takeActionBtn()}</a>;
      } else {
        return <Link to={redirectPath}>{props.takeActionBtn()}</Link>;
      }
    default:
      return (
        <Link to={PortalLink(MODULE_CODE.Messaging)}>
          {props.takeActionBtn()}
        </Link>
      );
  }
};

// Button Take Action - Navigate to notifications origin
export const navigateToNotificationOrigin = (message, history) => {
  switch (message.messageType) {
    case MESSAGE_TYPE.Payslip: {
      history.push(DocumentLink(FUNCTION_CODE.Payslip));
      break;
    }
    case MESSAGE_TYPE.Tax: {
      history.push(DocumentLink(FUNCTION_CODE.Tax));
      break;
    }
    case MESSAGE_TYPE.Compensation: {
      history.push(DocumentLink(FUNCTION_CODE.Compensation));
      break;
    }
    case MESSAGE_TYPE.HrLetter: {
      history.push(DocumentLink(FUNCTION_CODE.HrLetters));
      break;
    }
    case MESSAGE_TYPE.Rental: {
      const redirectPath = message.actionUrl
        ? message.actionUrl
        : PortalLink(MODULE_CODE.Rental);
      if (redirectPath.startsWith("http")) {
        window.location.href = redirectPath;
      } else {
        history.push(redirectPath);
      }
      break;
    }
    case MESSAGE_TYPE.Leave: {
      const redirectPath = message.actionUrl || PortalLink(MODULE_CODE.Leave);
      if (redirectPath.startsWith("http")) {
        window.location.href = redirectPath;
      } else {
        history.push(redirectPath);
      }
      break;
    }
    case MESSAGE_TYPE.Nwow: {
      const redirectPath = message.actionUrl || PortalLink(MODULE_CODE.Nwow);
      if (redirectPath.startsWith("http")) {
        window.location.href = redirectPath;
      } else {
        history.push(redirectPath);
      }
      break;
    }
    default: {
      history.push(PortalLink(MODULE_CODE.Messaging));
      break;
    }
  }
};
