import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  DEFAULT_DATE_FORMAT,
  FUNCTION_CODE,
  MATCH_MODE,
  MENU_NAME,
} from "../../../../constants";
import {
  resetMainForm,
  resetMainFormConfig,
  resetMainFormValue,
} from "../../../../redux/actions/form";
import BaseTable from "../../../../components/base-table/base-table-portal";
import { Animated } from "react-animated-css";
import {
  DEFAULT_BT_SEARCH,
  getBTConfig,
  getColumModel,
} from "../../../../components/base-table/base-table-model";
import { EmailConfigurationService } from "../../../../services/hrmnet-api";
import { useWindowSize } from "../../utils/window-size/useWindowSize";
import { Column } from "primereact/column";
import { Skeleton } from "primereact/skeleton";
import { DataTable } from "primereact/datatable";
import { getControlModel } from "../../../../components/base-control/base-cotrol-model";
import { BCType } from "../../../../components/base-control/base-control";
import moment from "moment";
import { GetCurrentActions } from "../../../layouts/components/main-sidebar/main-menu-tree";

const EMAIL_CONFIGURATION_SORT_KEY = "module";

const EmailTemplate = () => {
  const { isMobile } = useWindowSize();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const actionsAccessRights = useSelector((state) => {
    const result = [];
    GetCurrentActions(state.menu.sideBar, result);
    return result;
  });
  const dataTable = useRef();
  const [rerenderLanguage, setRerenderLanguage] = useState(false);

  /** Default search */
  const BT_SEARCH = {
    ...DEFAULT_BT_SEARCH,
    sortObj: {
      key: EMAIL_CONFIGURATION_SORT_KEY,
      direction: 1,
    },
  };

  const [state, setState] = useState({
    isLoading: true,
    detailExpandedRows: null,
    tableConfig: null,
  });

  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );
  useEffect(() => {
    const loadInit = async () => {
      const _state = state;

      _state.isLoading = false;
      setState(_state);

      if (rerenderLanguage) {
        dataTable.current?.rerenderLanguage();
      }

      dispatch(resetMainFormConfig());
      dispatch(resetMainForm());
      dispatch(resetMainFormValue());
    };
    loadInit();

    return () => {
      setState({
        ...state,
        isLoading: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLangKey]);

  const tableSearchFn = async () => {
    let _datas = [];
    let currentTable = {
      datas: [],
      total: 0,
      searchConfig: BT_SEARCH,
    };
    setRerenderLanguage(true);
    try {
      var cmd =
        await EmailConfigurationService.emailConfigurationGetEmailTemplates();
      const [res] = await Promise.all([cmd]);
      if (res && res?.data) {
        _datas = res.data.map((data) => {
          return {
            ...data,
            lastUpdate: moment(data.lastUpdate, DEFAULT_DATE_FORMAT),
          };
        });
      }

      currentTable = {
        datas: _datas,
        total: _datas.length,
        searchConfig: BT_SEARCH,
      };
      return currentTable;
    } catch (error) {
      return currentTable;
    }
  };

  // actions
  const onUpdateAction = async (item) => {
    history.push({
      pathname: `${FUNCTION_CODE.EmailTemplate}/${FUNCTION_CODE.EmailTemplateUpdate}`,
      state: {
        templateId: item.id,
      },
    });
  };

  const renderAction = () => {
    if (
      actionsAccessRights?.find(
        (x) => x.key === MENU_NAME.Email_Template_Update
      )
    ) {
      return [
        {
          isOverflow: false,
          name: "email_template_configuration_Edit_Action",
          title: t("email_template_configuration_Edit_Action"),
          clickFn: (item) => onUpdateAction(item),
          renderIcon: "pi pi-pencil",
        },
      ];
    }

    return [];
  };

  const actionsSingle = renderAction();

  let configColumns = [
    getColumModel({
      header: t("email_template_configuration_column_moduleName"),
      key: "moduleName",
      dataType: "string",
      config: {
        sortable: true,
      },
      isRowExpansion: false,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t("email_template_configuration_moduleName_placeholder"),
        type: "select",
      }),
      frozen: actionsSingle.length > 0,
    }),
    getColumModel({
      header: t("email_template_configuration_column_templateName"),
      key: "templateName",
      dataType: "string",
      config: {
        sortable: true,
      },
      isRowExpansion: false,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t("email_template_configuration_templateName_placeholder"),
      }),
      frozen: false,
    }),
    getColumModel({
      header: t("email_template_configuration_column_language"),
      key: "language",
      dataType: "string",
      config: {
        sortable: true,
      },
      isRowExpansion: false,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t("email_template_configuration_language_placeholder"),
        type: BCType.select,
      }),
      frozen: false,
    }),
    getColumModel({
      header: t("email_template_configuration_column_lastUpdate"),
      key: "lastUpdate",
      dataType: BCType.date,
      config: {
        dateFormat: DEFAULT_DATE_FORMAT,
        sortable: true,
      },
      isRowExpansion: true,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t("email_template_configuration_lastUpdate_placeholder"),
        type: BCType.daterange,
      }),
      filterMatchMode: MATCH_MODE.RANGE,
      body: (e) => (
        <div
          dangerouslySetInnerHTML={{
            __html: moment(e.releaseDate).format(DEFAULT_DATE_FORMAT),
          }}
        />
      ),
      frozen: false,
    }),
    getColumModel({
      header: t("email_template_configuration_column_updatedBy"),
      key: "updatedBy",
      dataType: "string",
      config: {
        sortable: true,
      },
      isRowExpansion: true,
      mobileWidthAuto: true,
      control: getControlModel({
        placeholder: t("email_template_configuration_updatedBy_placeholder"),
      }),
    }),
  ];

  const configModel = getBTConfig({
    columns:
      (isMobile && configColumns.filter((x) => !x.isRowExpansion)) ||
      configColumns,
    actionsSingle: actionsSingle,
    hasIndex: false,
    mode: "list",
    defaultMode: "list",
    showGlobal: true,
    isExpandable: isMobile,
    expandedRows: state.expandedRows,
    onRowToggle: (e) => {
      let newState = {
        ...state,
        expandedRows: e.data,
      };
      setState(newState);
    },
    rowExpansionTemplate: (data) => {
      const mobileColumnsRender = [];
      configColumns
        .filter((x) => x.isRowExpansion)
        .forEach((y, index) =>
          mobileColumnsRender.push(
            <Column
              {...{
                key: `bt-sub-column-${index}`,
                field: y.key,
                header: y.header,
                style: { width: "8rem" },
                headerStyle: { width: "8rem" },
                body: (rowData, config) => {
                  if (!data) {
                    return <Skeleton></Skeleton>;
                  } else {
                    return (
                      <div
                        className={`bt-row bt-row-${config.rowIndex} bt-cell table-expandable-row`}
                      >
                        <span className="p-column-title">{y.header}:</span>
                        <div className="bt-cell-value">
                          {y.dataType === BCType.date
                            ? moment(new Date(rowData[y.key])).format(
                                y.config?.dateFormat
                              )
                            : rowData[y.key]}
                        </div>
                      </div>
                    );
                  }
                },
              }}
            />
          )
        );

      return (
        <DataTable
          value={[data]}
          key="detail-mobile-table"
          className="p-datatable-mobiletable"
        >
          {mobileColumnsRender}
        </DataTable>
      );
    },
  });

  return (
    <>
      <div className={`email-template-select-form`}>
        <div className="email-template-ms-form email-template">
          <Animated
            animationIn="slideInRight"
            animationOut="slideOutRight"
            animationInDuration={200}
            animationOutDuration={200}
            isVisible={true}
          >
            <div className="email-template-table">
              <BaseTable
                ref={dataTable}
                configModel={configModel}
                searchConfig={BT_SEARCH}
                searchFn={() => tableSearchFn()}
                isClientSize={true}
                id="email-template-table"
              />
            </div>
          </Animated>
        </div>
      </div>
    </>
  );
};

export default EmailTemplate;
