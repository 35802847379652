import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Permission } from "../../../../../constants";
import { ISideBarMenu } from "../../../../../redux/reducers/menu";
import { LeaveService } from "../../../../../services/hrmnet-api";
import { ToUIOption } from "../../../public/login/components/utils";
import {
  LEAVE_MENU_MODULE_KEY,
  LEAVE_MENU_PAGE_KEY,
  LEAVE_T_CONTEXT_KEY,
} from "../../constants/contants";
import ApplyEntitlement from "./entitlement";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import LanguageLoader from "../../../../../components/language-loader/language-loader";

interface ValidateProps extends RouteComponentProps {
  employeeCode?: string;
}

interface SelectModelDataResponse {
  name: string;
  description: string;
  descriptionValue: Permission;
  value: string;
}

interface SelectModelResponse {
  data: SelectModelDataResponse[];
  messages: any[];
}

const ApplyEntitlementWithDelegation: React.FC<ValidateProps> = (props) => {
  const { t, i18n } = useTranslation();
  const [delegatedEmployeeList, setDelegatedEmployeeList] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isUserApplication, setIsUserApplication] = useState(true);
  const [showSelf, setShowSelf] = useState(true);
  const menus = useSelector((state: any) => state.menu.sideBar);
  const leaveMenu = menus.find(
    (x: ISideBarMenu) => x.key === LEAVE_MENU_MODULE_KEY
  )?.subMenus;
  const selectedEmployeeCode =
    props.employeeCode ?? props?.location?.state?.employeeCode;

  useEffect(() => {
    async function GetDelegatedEmployeeList() {
      try {
        const res = await LeaveService.leaveGetDelegateEmployeeApproveList();
        if (!!res?.data && Array.isArray(res?.data) && res?.data?.length) {
          const userCanApplyLeave =
            res.data.findIndex(
              (x: SelectModelDataResponse) =>
                x.descriptionValue === Permission.Self
            ) > -1;
          setIsUserApplication(userCanApplyLeave && !selectedEmployeeCode);
          setShowSelf(userCanApplyLeave);
          setDelegatedEmployeeList(
            res?.data
              ?.filter(
                (x: SelectModelDataResponse) =>
                  x.descriptionValue !== Permission.Self
              )
              .map((x: SelectModelDataResponse) => {
                x.description = i18n.exists(x.description)
                  ? t(x.description)
                  : x.descriptionValue;
                return x;
              })
          );
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }

    GetDelegatedEmployeeList();
  }, []);

  const renderContent = useMemo(() => {
    if (isLoading) {
      return <></>;
    }

    return (
      <LanguageLoader contexts={[LEAVE_T_CONTEXT_KEY.DELEGATION]}>
        <ApplyEntitlement
          employeeList={ToUIOption(delegatedEmployeeList)}
          showSelf={showSelf}
          isUserApplication={isUserApplication && !selectedEmployeeCode}
          {...props}
          employeeCode={selectedEmployeeCode}
        />
      </LanguageLoader>
    );
  }, [isLoading]);

  return renderContent;
};

export default ApplyEntitlementWithDelegation;
