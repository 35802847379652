import cloneDeep from "lodash.clonedeep";

import {
  SET_RENTAL_FORM,
  RESET_RENTAL_FORM,
  SET_RENTAL_FORM_CONFIG,
  SET_RENTAL_SECTION_CONFIG,
  RESET_RENTAL_FORM_CONFIG,
  RESET_RENTAL_FORM_VALUE,
  SET_RENTAL_MODULE_CONFIG,
  CLEAR_ALL_RENTAL_CONTEXT,
  SET_RENTAL_EMPLOYEE_DETAILS,
  CLEAR_RENTAL_EMPLOYEE_DETAILS,
} from "../reduxConstants";
const initialState = {
  originalForm: null,
  form: {},
  originalFormConfig: null,
  formConfig: {},
  formSectionConfig: {},
  moduleConfig: {},
  employeeDetails: {},
};

export default function rental(
  state = JSON.parse(JSON.stringify(initialState)),
  action
) {
  switch (action.type) {
    case SET_RENTAL_FORM: {
      let newForm;
      if (typeof action.payload === "function") {
        newForm = {
          ...action.payload(state.form),
        };
        return {
          ...state,
          originalForm: state.originalForm
            ? state.originalForm
            : cloneDeep(newForm),
          form: newForm,
        };
      } else {
        newForm = {
          ...action.payload,
        };
        return {
          ...state,
          originalForm: state.originalForm
            ? state.originalForm
            : cloneDeep(newForm),
          form: newForm,
        };
      }
    }
    case RESET_RENTAL_FORM: {
      return {
        ...JSON.parse(JSON.stringify(initialState)),
        moduleConfig: state.moduleConfig,
      };
    }
    case SET_RENTAL_FORM_CONFIG: {
      let newFormConfig;
      if (typeof action.payload === "function") {
        newFormConfig = {
          ...action.payload(state.formConfig),
        };
        return {
          ...state,
          originalFormConfig: state.originalFormConfig
            ? state.originalFormConfig
            : cloneDeep(newFormConfig),
          formConfig: newFormConfig,
        };
      } else {
        newFormConfig = {
          ...action.payload,
        };
        return {
          ...state,
          originalFormConfig: state.originalFormConfig
            ? state.originalFormConfig
            : cloneDeep(newFormConfig),
          formConfig: newFormConfig,
        };
      }
    }
    case SET_RENTAL_SECTION_CONFIG: {
      return {
        ...state,
        formSectionConfig: {
          ...action.payload,
        },
      };
    }
    case RESET_RENTAL_FORM_VALUE: {
      if (state.originalFormConfig) {
        return {
          ...state,
          form: cloneDeep(state.originalForm),
        };
      } else {
        return state;
      }
    }
    case RESET_RENTAL_FORM_CONFIG: {
      if (state.originalFormConfig) {
        return {
          ...state,
          formConfig: cloneDeep(state.originalFormConfig),
        };
      } else {
        return state;
      }
    }
    case SET_RENTAL_MODULE_CONFIG: {
      return {
        ...state,
        moduleConfig: {
          ...state.moduleConfig,
          ...action.payload,
        },
      };
    }
    case CLEAR_ALL_RENTAL_CONTEXT: {
      return JSON.parse(JSON.stringify(initialState));
    }
    case SET_RENTAL_EMPLOYEE_DETAILS: {
      return {
        ...state,
        employeeDetails: action.payload,
      };
    }
    case CLEAR_RENTAL_EMPLOYEE_DETAILS: {
      return {
        ...state,
        employeeDetails: initialState.employeeDetails,
      };
    }
    default: {
      return state;
    }
  }
}
