import "./error-404.scss";

import { useTranslation } from "react-i18next";
import { Animated } from "react-animated-css";

const Page404 = () => {
  const { t } = useTranslation();

  return (
    <>
      <Animated
        animationIn="slideInRight"
        animationOut="slideOutRight"
        animationInDuration={200}
        animationOutDuration={200}
        isVisible={true}
      >
        <div className="error-bg">
          <p>
            <span className="error-title">404</span>
            <span className="error-subtitle">{t("common_error_404")}</span>
          </p>
        </div>
      </Animated>
    </>
  );
};

export default Page404;
