import moment from "moment";

export function IsInViewport(el, index) {
  const rect = el.querySelector(".panel-header").getBoundingClientRect();
  const clientHeight =
    window.innerHeight ?? document.documentElement.clientHeight;
  const clientWidth = window.innerWidth ?? document.documentElement.clientWidth;
  const isVisible =
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= clientHeight &&
    rect.right <= clientWidth;
  return isVisible;
}

export function ScrollToSection(index) {
  let element = document.querySelector(".base_multi_step_form")?.children[
    index
  ];

  if (!!element && element.offsetParent != null) {
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  }
  return;
}

export function ConvertToString(value) {
  if (value instanceof Date) {
    value.setMilliseconds(0);
    value.setSeconds(0);
    let valueString = moment(new Date(value.valueOf())).format();
    return valueString;
  }
  if (typeof value === "boolean") {
    return value.toString();
  }
  if (Array.isArray(value)) {
    return value.join(",");
  }
  if (typeof value === "object") {
    if (Object.keys(value).length === 0) {
      return "";
    }
    return JSON.stringify(value);
  }

  if (value === null || value === undefined) {
    return null;
  }
  return value.toString();
}
