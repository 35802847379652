import { BreadCrumb } from "primereact/breadcrumb";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ProcessMenuTree } from "../main-sidebar/main-menu-tree";

import "./main-breadcrumb.scss";
// import { useHistory } from "react-router-dom";
// import { AppConstant } from "../../../../constants";

const MainBreadcrumb = () => {
  const { t } = useTranslation();
  // const history = useHistory();
  const sideBarInfo = useSelector((state) => state.sidebar) || null;
  const sideBarMenus = useSelector((state) => state.menu?.sideBar) || [];

  const genMenuTree = ProcessMenuTree(t, sideBarInfo, sideBarMenus, false);
  const bcDynamicPaths = useSelector((state) => state.breadcrumb?.dynamicPaths);
  let isActiveLabel;
  // let isActiveKey;
  let isActiveSubMenuLabel;
  // let isActiveSubMenuKey;
  let activeLabels;
  // let activeKeys;
  let activeItems = [];
  let hasItem;

  // Get current active Menu label
  for (var menuTree in genMenuTree) {
    if (genMenuTree[menuTree].isActive) {
      // Blacklist breadcrumb based on sidebar menu key
      // if (
      //   !(genMenuTree[menuTree].key.toLowerCase() === "rental") &&
      //   !(genMenuTree[menuTree].key.toLowerCase() === "leave")
      // ) {
      isActiveLabel = genMenuTree[menuTree].label;
      // isActiveKey = genMenuTree[menuTree]?.key;
      // }
    }
  }

  // Get current active SubMenu label
  if (
    genMenuTree.length > 0 &&
    genMenuTree.find((x) => x.isActive && x.children?.length > 0)
  ) {
    let getSubMenus = genMenuTree.find(
      (x) => x.isActive && x.children?.length > 0
    );
    for (var subMenuTree in getSubMenus.children) {
      if (getSubMenus.children[subMenuTree].isActive) {
        isActiveSubMenuLabel = getSubMenus.children[subMenuTree].label;
        // isActiveSubMenuKey =
        //   (getSubMenus.children[subMenuTree]?.key).split("~")[1];
      }
    }
  }

  // Store an array of active labels into this variable
  // If the parent label is the same as children label then
  // If don't want to display then add the below statement into the if statement
  // -> (isActiveLabel !== isActiveSubMenuLabel &&)
  activeLabels = [isActiveLabel];
  if (genMenuTree.find((x) => x.isActive && x.children?.length > 0)) {
    if (isActiveSubMenuLabel) {
      activeLabels.push(isActiveSubMenuLabel);
    }
  }

  // activeKeys = [isActiveKey];
  // if (genMenuTree.find((x) => x.isActive && x.children?.length > 1)) {
  //   if (isActiveSubMenuKey) {
  //     activeKeys.push(isActiveSubMenuKey);
  //   }
  // }

  function GetAllActiveMenus(menu, result, depth = 2) {
    if (menu?.isActive) {
      result.push({ label: menu.label });
    }

    if (menu?.children?.length > 0) {
      const currentMenus = menu.children.filter((x) => x.isActive);
      //cater for menu with root level
      if (currentMenus.length > 1) {
        const nextMenus = currentMenus.find(
          (x) =>
            window.location.pathname ===
            "/" + x?.location?.split("/").slice(0, depth).join("/")
        );
        GetAllActiveMenus(nextMenus, result, depth + 1);
      } else {
        GetAllActiveMenus(
          menu.children.find((x) => x.isActive),
          result,
          depth + 1
        );
      }
    }
  }

  // Map the array and model the object into the model format
  if (isActiveLabel) {
    if (bcDynamicPaths.length === 0) {
      GetAllActiveMenus(
        genMenuTree.find((x) => x.isActive),
        activeItems
      );
    } else {
      activeItems = activeLabels.map((x /* , index */) => {
        return {
          label: x,
          // command: () => {
          //   history.push(`/${AppConstant.PORTAL}/${activeKeys[index]}`);
          // },
        };
      });
      activeItems = activeItems.concat(bcDynamicPaths);
    }
  } else {
    activeItems = bcDynamicPaths;
  }
  hasItem = activeItems.length > 0;

  return hasItem ? (
    <div className="main-breadcrumb">
      <BreadCrumb model={activeItems} />
    </div>
  ) : null;
};
export default MainBreadcrumb;
