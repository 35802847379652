import React from "react";

import { Panel } from "primereact/panel";
import { Ripple } from "primereact/ripple";

const SupportMobileWrapper = (props) => {
  const mobileTemplate = (options) => {
    const toggleIcon = options.collapsed
      ? "pi pi-chevron-down"
      : "pi pi-chevron-up";
    const className = `${options.className}`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className} style={{ background: "#ffffff" }}>
        <span className={titleClassName}>{props.title}</span>
        <button
          className={options.togglerClassName}
          onClick={options.onTogglerClick}
        >
          <span className={toggleIcon}></span>
          <Ripple />
        </button>
      </div>
    );
  };

  const renderMobileView = () => {
    return (
      <Panel headerTemplate={mobileTemplate} toggleable>
        {props.supportItem.map((x, index) => {
          if (x === undefined) {
            return (
              <div key={index}>
                <p>{props.noDataTitle}</p>
              </div>
            );
          }
          return <div key={index} dangerouslySetInnerHTML={{ __html: x }} />;
        })}
      </Panel>
    );
  };

  return <>{renderMobileView()}</>;
};

export default SupportMobileWrapper;
