import "./holiday.scss";

//components
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { resetBcDynamicPaths } from "../../../../../redux/actions/breadcrumb";
import { useTranslation } from "react-i18next";
import { Animated } from "react-animated-css";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import moment from "moment";

import { NwowService } from "../../../../../services/hrmnet-api";
import LanguageLoader from "../../../../../components/language-loader/language-loader";
import { EEPORTAL_LABEL_BASE_TABLE } from "../../../../../constants";
import {
  NWOW_T_CONTEXT_KEY,
  NWOW_MENU_MODULE_KEY,
  NWOW_MENU_PAGE_KEY,
} from "../../constants/contants";
import { formSkeleton } from "../../utils";
import { NwowMenu, ScrollToNwowMenuActiveItem } from "../../components";

const DATE_FORMAT = "D MMMM yyyy";

const Holiday = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let history = useHistory();
  const menus = useSelector((state) => state.menu.sideBar);
  const nwowMenu = menus.find((x) => x.key === NWOW_MENU_MODULE_KEY)?.subMenus;

  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );

  const [state, setState] = useState({
    isLoading: true,
    calendarClass: [],
    year: [],
    holiday: [],
    selectedCalendarClass: null,
    selectedYear: null,
    isFiltering: true,
    filteredHoliday: [],
  });

  useEffect(() => {
    dispatch(resetBcDynamicPaths());
  }, [dispatch]);

  // init
  useEffect(() => {
    const loadData = async () => {
      var api = await NwowService.nwowGetHolidayList({});
      let [res] = await Promise.all([api]);

      if (res && res.data) {
        const holiday = res.data;

        const calendarClassList = new Set();
        const yearList = new Set();
        res.data.forEach((d) => {
          calendarClassList.add(d.calendarClass);
          yearList.add(d.year);
        });
        const yearArray = Array.from(yearList).sort((a, b) => b - a);

        const calendarClass = Array.from(calendarClassList).reduce((a, c) => {
          a.push({ label: c, value: c });
          return a;
        }, []);
        const year = yearArray.reduce((a, c) => {
          a.push({ label: c.toString(), value: Number(c) });
          return a;
        }, []);

        setState((state) => ({
          ...state,
          isLoading: false,
          calendarClass,
          year,
          holiday,
          selectedCalendarClass: calendarClass.length
            ? calendarClass[0].value
            : null,
          selectedYear: year.length ? year[0].value : null,
        }));
      }
      ScrollToNwowMenuActiveItem();
    };
    loadData();
  }, [selectedLangKey]);

  useEffect(() => {
    setState((state) => ({
      ...state,
      isFiltering: true,
    }));
    const filteredHoliday = state.holiday.filter(
      (h) =>
        h.calendarClass === state.selectedCalendarClass &&
        h.year === state.selectedYear
    );
    setState((state) => ({
      ...state,
      isFiltering: false,
      filteredHoliday,
    }));
  }, [state.selectedCalendarClass, state.selectedYear]);

  // render
  const renderFormSkeleton = () => formSkeleton;

  const renderFilter = () => {
    if (state.isLoading) return;

    return (
      <div className="holiday-filter">
        <div className="holiday-filter-block">
          <div className="base-control">
            <label className="filter-label">
              {t("Nwow_holiday_filter_calendarClass")}
            </label>
            <Dropdown
              placeholder={t("base_control_select_choose")}
              options={state.calendarClass}
              value={state.selectedCalendarClass}
              onChange={(e) => {
                setState((state) => ({
                  ...state,
                  selectedCalendarClass: e.value,
                }));
              }}
            />
          </div>
        </div>
        <div className="holiday-filter-block">
          <div className="base-control">
            <label className="filter-label">
              {t("Nwow_balance_filter_year")}
            </label>
            <Dropdown
              placeholder={t("base_control_select_choose")}
              options={state.year}
              value={state.selectedYear}
              onChange={(e) => {
                setState((state) => ({
                  ...state,
                  selectedYear: e.value,
                }));
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderHolidayList = () => {
    if (state.isLoading) return;

    return (
      <div className="nwow-holiday-list-container">
        <DataTable
          value={state.filteredHoliday}
          loading={state.isFiltering}
          emptyMessage={t("base_table_no_results")}
        >
          <Column
            field="year"
            header={t("Nwow_holiday_list_date")}
            style={{ verticalAlign: "top" }}
            body={(data) => (
              <div>{moment(data.specialDate).format(DATE_FORMAT)}</div>
            )}
          />
          <Column field="longDesc" header={t("Nwow_holiday_list_holiday")} />
        </DataTable>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="footer p-grid p-align-center p-justify-between">
        <div className="button-group-1">
          <Button
            onClick={() => history.goBack()}
            className="p-button-outlined secondary"
          >
            {t("Nwow_common_actionBack")}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          NWOW_T_CONTEXT_KEY.COMMON,
          NWOW_T_CONTEXT_KEY.BALANCE,
          NWOW_T_CONTEXT_KEY.HOLIDAY,
        ]}
      />
      <div className="nwow-container nwow-holiday">
        <Animated
          animationIn="slideInRight"
          animationOut="slideOutRight"
          animationInDuration={200}
          animationOutDuration={200}
          isVisible={true}
        >
          <div className="header">
            <NwowMenu
              menu={nwowMenu}
              currentPageKey={NWOW_MENU_PAGE_KEY.HOLIDAY_LIST}
            />
            <div className="title">{t("Nwow_holiday_pageTitle")}</div>
          </div>
          <div className="main">
            {/* Filter */}
            {state.isLoading ? null : renderFilter()}

            {/* Holiday List */}
            {state.isLoading ? renderFormSkeleton() : renderHolidayList()}
          </div>
        </Animated>
        {/* Footer */}
        {state.isLoading ? null : renderFooter()}
      </div>
    </>
  );
};

export default Holiday;
