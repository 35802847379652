import "./delegation.scss";

//components
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { resetBcDynamicPaths } from "../../../../../redux/actions/breadcrumb";
import LanguageLoader from "../../../../../components/language-loader/language-loader";
import { EEPORTAL_LABEL_BASE_TABLE } from "../../../../../constants";
import { useTranslation } from "react-i18next";
import Button from "../../../rental/components/action-button";
import { Animated } from "react-animated-css";
import { useWindowSize } from "../../../utils/window-size/useWindowSize";
import { Dropdown } from "primereact/dropdown";
import { TabMenu } from "primereact/tabmenu";
import { NwowService } from "../../../../../services/hrmnet-api";
import {
  DEFAULT_BT_SEARCH,
  getBTConfig,
} from "./../../../../../components/base-table/base-table-model";
import {
  getColumns,
  getDelegationFormConfigStepOne,
  getDelegationFormConfigStepTwo,
} from "./config";
import { openModal } from "../../../../../redux/actions/modal";
import { isResponseOk } from "../../../../../utils/utils";
import { showSpinner } from "../../../../../redux/actions/spinner";
import BaseTable from "../../../../../components/base-table/base-table-portal-rental";
import {
  DATE_FORMAT,
  DELEGATE_TYPE,
  NWOW_T_CONTEXT_KEY,
  NWOW_MENU_MODULE_KEY,
  NWOW_MENU_PAGE_KEY,
} from "../../constants/contants";
import moment from "moment";
import { showTimestampToastSuccess } from "../../../../../services/utils/message";
import { Skeleton } from "primereact/skeleton";
import { NwowMenu, ScrollToNwowMenuActiveItem } from "../../components";

const Delegation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  let history = useHistory();
  const menus = useSelector((state) => state.menu.sideBar);
  const nwowMenu = menus.find((x) => x.key === NWOW_MENU_MODULE_KEY)?.subMenus;

  useEffect(() => {
    dispatch(resetBcDynamicPaths());
  }, [dispatch]);

  const [tabIndex, setTabIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingOptions, setIsLoadionOptions] = useState(true);
  const [data, setData] = useState([]);
  const [delegationTaskList, setDelegationTaskList] = useState([]);
  // map includes options for apply and approve nwow
  const [delegateeOptions, setDelegateeOptions] = useState([]);
  const [showTableAction, setShowTableAction] = useState(true);
  const tableRef = useRef();

  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );

  const delegationTabList = [
    {
      label: t("Nwow_delegation_currentTab"),
      value: 0,
    },
    {
      label: t("Nwow_delegation_historyTab"),
      value: 1,
    },
    // {
    //   label: t("Nwow_delegation_log"),
    //   value: 2,
    // },
  ];

  // Init
  useEffect(() => {
    const getTaskList = async () => {
      const res = await NwowService.nwowGetDelegationNwowTask();
      if (isResponseOk(res) && res.data) {
        const tasks = res.data
          .filter((task) => task.value !== "1") // Custom logic, remove "Apply Nwow"
          .map((task) => ({
            label: task.name,
            value: task.value,
          }));
        setDelegationTaskList(tasks);
      }
    };
    const getEmployeeList = async () => {
      const res = await NwowService.nwowGetDelegationOptions();
      if (isResponseOk(res) && res.data) {
        let options = {};
        res.data.forEach((data) => {
          if (data.delegateType in DELEGATE_TYPE) {
            options[DELEGATE_TYPE[data.delegateType]] = data.employees.map(
              (option) => ({
                label: option.name,
                value: option.value,
              })
            );
          }
        });
        setDelegateeOptions(options);
      }
    };
    const initOptions = async () => {
      setIsLoadionOptions(true);
      await getEmployeeList();
      await getTaskList();
      setIsLoadionOptions(false);
    };
    initOptions();
    ScrollToNwowMenuActiveItem();
  }, [selectedLangKey]);

  const loadData = async () => {
    setIsLoading(true);
    let res = null;
    let data;
    switch (tabIndex) {
      case 0: // Current Tab
        res = await NwowService.nwowGetDelegationCurrent();
        data = res.data;
        setShowTableAction(true);
        // data process - add action
        data.forEach((ea, i) => {
          data[i].actions = ["cancel"];
        });
        break;
      case 1: // History Tab
        res = await NwowService.nwowGetDelegationHistory();
        data = res.data;
        setShowTableAction(false);
        break;
      case 2: // Log Tab
        res = await NwowService.nwowGetDelegationLog();
        data = [];
        setShowTableAction(false);
        break;
      default:
        break;
    }
    if (res?.data) {
      setData(res.data);
    }
    setIsLoading(false);
    if (tableRef.current) tableRef.current.reload();
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIndex, selectedLangKey]);

  // Render Data
  const tableGetData = async () => {
    if (isLoading) {
      return new Promise(() => {});
    } else {
      // auto fill empty data
      return {
        datas: data,
        searchConfig: BT_SEARCH,
        total: data.length,
      };
    }
  };

  const BT_SEARCH = {
    ...DEFAULT_BT_SEARCH,
    // sortObj: {
    //   key: "applicationId",
    //   direction: -1,
    // },
  };

  const configModel = getBTConfig({
    columns: getColumns({
      showAction: showTableAction,
      t,
    }),
    hasIndex: false,
    mode: "list",
    defaultMode: "list",
    showGlobal: false,
    hasColumnSelector: false,
    // onRowClick: (e) => redirect({ applicationId: e.data.id }),
    rowHover: true,
    responsive: true,
    actionWidth: 5.3,
    actionsSingle: showTableAction
      ? [
          {
            isOverflow: true,
            name: "cancel",
            title: t("Nwow_delegation_cancelDelegation"),
            clickFn: (data) => {
              openCancelDelegationModal(data.delegateid);
            },
          },
        ]
      : null,
  });

  // Action
  const openAddNewDelegationModal = (delegationTask) => {
    populateAddNewDelegationModal(null);
  };

  const populateAddNewDelegationModal = (form, validate = false) => {
    let _config;
    if (form?.["taskId"] == null) {
      _config = getDelegationFormConfigStepOne({
        taskOptions: delegationTaskList,
        t,
      });
      form = {};
    } else {
      _config = getDelegationFormConfigStepTwo({
        form: form,
        employeeOptions: delegateeOptions[form["taskId"]] || {},
        taskOptions: delegationTaskList,
        t,
      });
    }
    dispatch(
      openModal({
        title: t("Nwow_delegation_addNewDelegation"),
        classNameMainDialog: "confirm-message-modal form-modal",
        primaryButtonText: t("Nwow_common_actionSubmit"),
        form: {
          form: {
            ...form,
          },
          touched: validate ? true : false,
          config: _config,
          alwaysAllowSubmit: true,
          onChange: (change) =>
            populateAddNewDelegationModal(change.state.form, validate || false),
        },
        alwaysAllowSubmit: true,
        primaryButtonClickFn: async ({ closeFn, form, formState }) => {
          if (formState.invalid) {
            populateAddNewDelegationModal(form, true);
            return;
          }
          addNewDelegation({ closeFn, form });
        },
        secondButtonClickFn: ({ closeFn }) => {
          closeFn();
        },
      })
    );
  };

  const addNewDelegation = async ({ closeFn, form }) => {
    try {
      dispatch(showSpinner(true));
      // prepare payload
      const payload = {
        taskId: form.taskId,
        delegateFrom: form.delegateFrom,
        delegateTo: form.delegateTo,
        effectiveFrom: moment(form.effectiveFrom).format(DATE_FORMAT),
        effectiveTo: moment(form.effectiveTo).format(DATE_FORMAT),
      };
      const res = await NwowService.nwowAddDelegation({
        body: payload,
      });
      if (isResponseOk(res)) {
        showTimestampToastSuccess({
          message: t("Nwow_delegation_addSuccess"),
          t: t,
        });
        closeFn();
        loadData();
      }
    } catch (e) {
    } finally {
      dispatch(showSpinner(false));
    }
  };

  const openCancelDelegationModal = (delegateId) => {
    dispatch(
      openModal({
        title: t("Nwow_delegation_cancelDelegation"),
        classNameMainDialog: "confirm-message-modal",
        content: t("Nwow_delegation_cancelDelegationMessage"),
        primaryButtonClickFn: async ({ closeFn }) => {
          closeFn();
          cancelDelegation(delegateId);
        },
        secondButtonClickFn: ({ closeFn }) => {
          closeFn();
        },
      })
    );
  };

  const cancelDelegation = async (delegateId) => {
    try {
      dispatch(showSpinner(true));
      const res = await NwowService.nwowCancelDelegation({
        delegateid: delegateId,
      });
      if (isResponseOk(res)) {
        showTimestampToastSuccess({
          message: t("Nwow_delegation_cancelSuccess"),
          t: t,
        });
        loadData();
      }
    } catch (e) {
    } finally {
      dispatch(showSpinner(false));
    }
  };

  // render
  const renderHeader = () => {
    return (
      <div className="header">
        <NwowMenu
          menu={nwowMenu}
          currentPageKey={NWOW_MENU_PAGE_KEY.DELEGATION}
        />
        <div className="title">
          {t("Nwow_delegation_title")}
          <div className="title-desc">&nbsp;</div>
        </div>
        {isLoadingOptions ? (
          <Skeleton height={"45px"} width={"200px"} />
        ) : (
          <Button className="p-button" onClick={openAddNewDelegationModal}>
            {t("Nwow_delegation_addNewDelegation")}
          </Button>
        )}
      </div>
    );
  };

  const renderTabSelect = () => {
    if (isLoading || isLoadingOptions) {
      return (
        <div className="delegation-tab">
          <Skeleton height={"50px"} />
        </div>
      );
    }
    return (
      <div className="delegation-tab">
        <Animated
          animationIn="slideInRight"
          animationOut="slideOutRight"
          animationInDuration={200}
          animationOutDuration={200}
          isVisible={true}
        >
          {isMobile ? (
            <div className="dropdown-wrapper">
              <Dropdown
                value={delegationTabList[tabIndex].value}
                options={delegationTabList}
                onChange={(e) => {
                  setTabIndex(e.value);
                }}
              />
            </div>
          ) : (
            <TabMenu
              model={delegationTabList}
              activeIndex={tabIndex}
              onTabChange={(e) => {
                setTabIndex(e.index);
              }}
            />
          )}
        </Animated>
      </div>
    );
  };

  const renderMain = () => {
    if (isLoading || isLoadingOptions) {
      return (
        <div className="main" style={{ padding: "1rem" }}>
          <Skeleton height={"30px"} className="p-mb-2" />
          <Skeleton height={"30px"} className="p-mb-2" />
          <Skeleton height={"30px"} />
        </div>
      );
    }

    return (
      <Animated
        animationIn="slideInRight"
        animationOut="slideOutRight"
        animationInDuration={200}
        animationOutDuration={200}
        isVisible={true}
      >
        <div className="main">
          <BaseTable
            id="delegation-table"
            ref={tableRef}
            isClientSize={true}
            configModel={configModel}
            searchConfig={BT_SEARCH}
            searchFn={tableGetData}
            disableMobile={true}
          />
        </div>
      </Animated>
    );
  };

  const renderFooter = () => {
    if (isLoading || isLoadingOptions) return;

    return (
      <div className="footer p-grid p-align-center p-justify-between">
        <div className="button-group-1">
          <Button
            onClick={() => history.goBack()}
            className="p-button-outlined secondary"
          >
            {t("Nwow_common_actionBack")}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          NWOW_T_CONTEXT_KEY.COMMON,
          NWOW_T_CONTEXT_KEY.DELEGATION,
        ]}
      />

      <div className="nwow-container nwow-delegation">
        {/* Header */}
        {renderHeader()}

        {/* Tab */}
        {renderTabSelect()}

        {/* Main */}
        {renderMain()}

        {/* Footer */}
        {renderFooter()}
      </div>
    </>
  );
};

export default Delegation;
