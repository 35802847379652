import { MessageSeverity } from "primereact/api";
import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { scrollToErrorControl } from "../../../../components/base-form/utils";
import BaseMultiStepForm from "../../../../components/base-multi-step-form/base-multi-step-form";
import { BaseMultiStepFormSkeleton } from "../../../../components/base-multi-step-form/base-multi-step-form-skeleton";
import {
  BaseMultiStepConfigToFormModel,
  BaseMultiStepConfigToStepNavModel,
  BaseMultiStepCustomFunctionLibrary,
} from "../../../../components/base-multi-step-form/base-multi-step-form-utils";
import { FUNCTION_CODE, MODULE_CODE } from "../../../../constants";
import {
  setFormLanguage,
  setMainForm,
  setMainFormConfig,
  setMainFormSectionConfig,
} from "../../../../redux/actions/form";
import { showSpinner } from "../../../../redux/actions/spinner";
import { store } from "../../../../redux/store";
import { AdminAccessPermissionService } from "../../../../services/hrmnet-api";
import { PortalLink } from "../../../../services/utils";
import { showErrorMessageModal } from "../../../../services/utils/message";
import { getResponseMessage, isResponseOk } from "../../../../utils/utils";
import { GetCurrentActions } from "../../../layouts/components/main-sidebar/main-menu-tree";
import {
  InitRoleAccess,
  RoleAccessConfigurationInitForm,
  RoleAccessSteps,
} from "./access-permission-role-access-form-config";

const RoleAccessForm = ({ location }) => {
  const editedRoleId = location?.state?.roleId;
  const editedDataAuthorizations = location?.state?.dataAuthorizations;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  let toast = store.getState().global.toast;

  const [state, setState] = useState({
    isLoading: true,
    activeStep: 0,
    formResp: {},
    touched: false,
    datasDataAuthorizationList: [],
  });

  const actionsAccessRights = useSelector((state) => {
    const result = [];
    GetCurrentActions(state.menu.sideBar, result, 1, false);
    return result;
  });

  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );
  const formLanguage = useSelector((state) => state.commonForm.formLanguage);

  const form = useSelector((state) => state.commonForm.form);
  const selectedTemplateName = useSelector(
    (state) => state.commonForm.form?.TemplateName
  );
  const setForm = (x) => dispatch(setMainForm(x));
  const formConfig = useSelector((state) => state.commonForm.formConfig);
  const setFormConfig = (x) => dispatch(setMainFormConfig(x));
  const setFormSectionConfig = (x) => dispatch(setMainFormSectionConfig(x));
  const dataAuthorizationWithNonArrayFields = ["Status"];
  const formObjRef = React.createRef();

  let customFunctionLibrary = BaseMultiStepCustomFunctionLibrary({
    dispatch,
  });

  function createFieldConfig(
    fieldName,
    dropdownOptions,
    displaySequence,
    fieldGroup,
    id,
    viewSectionId
  ) {
    const fieldConfig = {
      controlType: "DropDownListMultiselect",
      dataType: "string",
      fieldName: fieldName,
      displaySequence: displaySequence,
      fieldGroup: fieldGroup,
      fieldLabel:
        t(`Access_Permission_Employee_Access_Configuration_${fieldName}`) ===
        `Access_Permission_Employee_Access_Configuration_${fieldName}`
          ? fieldName
          : t(`Access_Permission_Employee_Access_Configuration_${fieldName}`),
      id: id,
      inputName: fieldName,
      isAllowNull: true,
      isDefaultHide: false,
      isDetailView: false,
      isEditable: true,
      isElasticData: false,
      isHideLabelField: false,
      isHtmlField: false,
      isSpecialField: false,
      length: -1,
      storageDataType: "String",
      styleClass: "p-lg-6 p-md-12 p-sm-12",
      viewSectionId: viewSectionId,
      dropdownOptions: dropdownOptions,
      config: {
        display: "chip",
      },
    };

    if (dataAuthorizationWithNonArrayFields.includes(fieldName)) {
      fieldConfig.controlType = "DropDownList";
    }

    return fieldConfig;
  }

  useEffect(() => {
    const loadForm = async () => {
      const applicationId = selectedTemplateName;
      const isLangChange = formLanguage != selectedLangKey;
      dispatch(setFormLanguage(selectedLangKey));

      let stepRes = RoleAccessSteps(t);
      let formConfigRes = RoleAccessConfigurationInitForm(t, !!editedRoleId);

      let stepList = [];
      let newForm = InitRoleAccess;
      let newFormResp = {};
      let newFormConfig = {};
      // let activeStep = 0;
      let currentForm = {};

      const dataAuthorizationListApi =
        AdminAccessPermissionService.accessGetFiltersAsync();
      const roleListApi =
        AdminAccessPermissionService.accessGetRoleModulesAsync1();

      const [dataAuthorizationListRes, roleListRes] = await Promise.all([
        dataAuthorizationListApi,
        roleListApi,
      ]);

      const dataAuthorizationList = dataAuthorizationListRes?.data;
      const roleList = roleListRes?.data;

      if (!!editedRoleId) {
        newForm = JSON.parse(JSON.stringify(location?.state));
        newForm.roleIds = [editedRoleId];
      }

      if (dataAuthorizationListRes && dataAuthorizationListRes?.data) {
        if (Array.isArray(dataAuthorizationList)) {
          dataAuthorizationList.forEach((x, index) => {
            if (
              !!editedDataAuthorizations &&
              Array.isArray(editedDataAuthorizations)
            ) {
              const da = editedDataAuthorizations?.find((w) => w.id === x.id);
              if (!!da) {
                newForm[x.name] = da.values;
                if (dataAuthorizationWithNonArrayFields.includes(x.name)) {
                  newForm[x.name] = da.values.join(", ");
                }
              }
            }
            var displaySequence = index % 2 === 0 ? 2 : 1;
            var fieldGroup = Math.trunc(index / 2) + 1;
            formConfigRes.data.sections[1].fields.push(
              createFieldConfig(
                x.name,
                x?.dataList || [],
                displaySequence,
                fieldGroup,
                x.id,
                formConfigRes.data.sections[1].id
              )
            );
          });
        }
      }

      if (typeof newForm?.roleIds === "string") {
        newForm.roleIds = [newForm?.roleIds];
      }

      if (stepRes && stepRes.data && formConfigRes && formConfigRes.data) {
        //partial hardcore, find ways to completely detach from code

        let customData = {
          customFunctionLibrary: customFunctionLibrary,
        };
        stepList = BaseMultiStepConfigToStepNavModel(stepRes.data);

        // init form and active step
        if (
          !isLangChange ||
          (form &&
            Object.keys(form).length === 0 &&
            Object.getPrototypeOf(form) === Object.prototype)
        ) {
          // newForm = currentForm?.data;
        } else {
          newForm = form;
          // activeStep = state.activeStep;
        }

        // newFormResp and formConfig
        newFormResp = formConfigRes.data;
        newFormConfig = BaseMultiStepConfigToFormModel({
          applicationId: applicationId,
          stepConfig: stepRes.data,
          sectionFieldConfig: formConfigRes.data.sections,
          customData: customData,
          t: t,
          form: newForm,
          dropdownOptions: {
            roleIds: roleList.map((x) => {
              return { ...x, value: x.id };
            }),
          },
        });
      }

      setForm(newForm);
      setFormConfig(newFormConfig);
      setFormSectionConfig(newFormResp);

      setState({
        ...state,
        stepList,
        isLoading: false,
        datasDataAuthorizationList: dataAuthorizationList,
      });
    };

    loadForm();

    return () => {
      setState({
        ...state,
        isLoading: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedRoleId]);

  // Navigation and action
  const validateCurrentStep = () => {
    const isValid = !formObjRef.current.getActiveFormState({ expand: true })
      .invalid;
    setTimeout(() => {
      scrollToErrorControl();
    }, 100);
    return isValid;
  };

  const confirmSubmitApplication = async () => {
    if (validateCurrentStep()) {
      submitApplicationAction();
    } else {
      setState({
        ...state,
        touched: true,
      });
    }
  };

  const submitApplicationAction = async () => {
    try {
      // show spinner
      dispatch(showSpinner(true));
      let res = await AdminAccessPermissionService.accessSaveRoleAccessAsync({
        body: {
          dataAuthorizations: FormDataToDataAuthorizationObject(form).filter(
            (x) => !!x && !!x?.values
          ),
          roleIds: form.roleIds,
        },
      });
      if (isResponseOk(res)) {
        toast.show({
          severity: MessageSeverity.SUCCESS,
          detail: t("admin_common_submitMessageSuccess"),
          life: 3000,
        });

        if (!!editedRoleId) {
          history.push(
            `/${MODULE_CODE.Admin}/${FUNCTION_CODE.AccessPermission}/${FUNCTION_CODE.AccessPermissionRoleAccess}`
          );
        }
      } else {
        showErrorMessageModal({
          dispatch,
          t,
          content: getResponseMessage(res),
        });
      }
    } catch (e) {
      // show notification?
      console.error(e.message);
    } finally {
      // end spinner
      dispatch(showSpinner(false));
    }
  };

  // Rendering
  const renderForm = () => (
    <BaseMultiStepForm
      form={form}
      activeStep={state.activeStep}
      config={formConfig}
      ref={formObjRef}
      touched={state.touched}
    />
  );

  const renderFooter = () => {
    return (
      <div className="footer p-grid p-align-center p-justify-between">
        <Button
          label={t("admin_common_actionCancel")}
          className="p-button-secondary"
          onClick={() =>
            history.push({
              pathname: PortalLink(
                `${MODULE_CODE.Admin}/${FUNCTION_CODE.AccessPermission}/${FUNCTION_CODE.AccessPermissionRoleAccess}`
              ),
            })
          }
        />
        <div className="right-button-group">
          <Button
            onClick={() => confirmSubmitApplication()}
            className="p-button next-button"
          >
            {t("admin_common_actionSubmit")}
          </Button>
        </div>
      </div>
    );
  };

  function FormDataToDataAuthorizationObject(formdata) {
    return Object.keys(formdata).map((key) => {
      const currentDataAuthorization = state?.datasDataAuthorizationList?.find(
        (x) => x.name === key
      );
      if (currentDataAuthorization) {
        const index = formdata?.dataAuthorizations?.findIndex(
          (x) => x.id === currentDataAuthorization.id
        );
        if (index > -1) {
          const data = formdata?.dataAuthorizations?.find(
            (x) => x.id === currentDataAuthorization.id
          );
          if (typeof data.values === "string") {
            data.values = [formdata[key]];
          } else {
            data.values = formdata[key];
          }
          return data;
        }

        if (typeof formdata[key] === "string") {
          return {
            id: currentDataAuthorization.id,
            values: [formdata[key]],
          };
        }

        return {
          id: currentDataAuthorization.id,
          values: formdata[key],
        };
      }
    });
  }

  // Rendering
  const renderFormTitle = () => (
    <div className="form-title">
      <h2>
        {actionsAccessRights.length > 0 ? actionsAccessRights[0]?.name : ""}
      </h2>
    </div>
  );

  return (
    <>
      <div className=" admin-form-style  acess-permission-employee-access">
        <div className="header header-sticky">
          {/* Title */}
          {renderFormTitle()}
        </div>
        {state.isLoading ? (
          <div className="form-loading">{BaseMultiStepFormSkeleton}</div>
        ) : (
          renderForm()
        )}

        {/* Footer */}
        {renderFooter()}
      </div>
    </>
  );
};

export default RoleAccessForm;
