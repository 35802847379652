export const DATE_FORMAT = "YYYY-MM-DD";
export const TIME_FORMAT = "HH:mm:ss";
export const DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const LEAVE_WORKFLOW_STATUS = {
  IN_PROGRESS: 0,
  COMPLETE: 1,
  REJECT: 2,
  SUSPEND: 3,

  // Special
  DRAFT: 6,
  DELETED: 8,
  ADMIN_ENTRY_CREATED: 9,
  LEAVE_STATUS_AMENDED: 10,
};

export const LEAVE_TYPE = {
  ANNUAL: "AL",
  COMPENSATION: "CEL",
  SICK: "L",
  COMPASSIONATE: "CL",
  OTHER: "OL",
  PATERNITY: "PL",
  MARRIAGE: "ML",
  BUSINESS: "BL",
  NO_PAY: "NPL",
  BIRTHDAY: "BL",
  JURY_DUTY: "JL",
  STUDY_EXAM: "STUDYL",
};

export const DATE_LEAVE_STATUS = {
  [LEAVE_WORKFLOW_STATUS.COMPLETE]: {
    label: "leave_dateLeaveStatus_approved",
    color: "#000000",
  },
};

export const DATE_TYPE = {
  WORKING: "Y",
  REST: "P",
  NON_PAID_REST: "N",
  HOLIDAY: "H",
  STATUTORY: "S",
};

/**
 * interface DateConfigItem
 * showMark: boolean;
 * disabled: boolean;
 * isRedDay: boolean;
 */
export const DATE_TYPE_CONFIG = {
  [DATE_TYPE.WORKING]: {
    disabled: false,
    isRedDay: false,
    color: "#ff0000",
  },
  [DATE_TYPE.REST]: {
    disabled: true,
    isRedDay: false,
    color: "#2d7829",
  },
  [DATE_TYPE.NON_PAID_REST]: {
    disabled: true,
    isRedDay: false,
    color: "#fece2f",
  },
  [DATE_TYPE.HOLIDAY]: {
    disabled: false,
    isRedDay: true,
    color: "#0000ff",
  },
  [DATE_TYPE.STATUTORY]: {
    disabled: false,
    isRedDay: true,
    color: "#ff00ff",
  },
};

export const UOM = {
  DAY: "DAY",
  HOUR: "HOUR",
};

export const DISPLAY_UOM = {
  [UOM.DAY]: "leave_common_uomDay",
  [UOM.HOUR]: "leave_common_uomHour",
};

export const DELEGATE_TYPE = {
  "Apply Leave": 1,
  "Approve Leave": 2,
};

export const LEAVE_T_CONTEXT_KEY = {
  APPROVAL: "UI.Leave.Approval",
  BALANCE: "UI.Leave.Balance",
  BLOCK_LEAVE: "UI.Leave.BlockLeave",
  CALENDAR_VIEW: "UI.Leave.CalendarView",
  COMMON: "UI.Leave.Common",
  DELEGATION: "UI.Leave.Delegation",
  ENTITLEMENT: "UI.Leave.Entitlement",
  FORM: "UI.Leave.Form",
  HOLIDAY: "UI.Leave.Holiday",
  LIST: "UI.Leave.List",
  PLANNER: "UI.Leave.Planner",
  REPORT: "UI.Leave.Report",
  SUBMIT_APPLICATION: "UI.Leave.SubmitApplication",
  VIEW_APPLICATION: "UI.Leave.ViewApplication",
};

export const LEAVE_MENU_MODULE_KEY = "eLeave";

export const LEAVE_MENU_TYPE_KEY = {
  ACTION: "Action",
  MENU: "Menu",
};

export const LEAVE_MENU_PAGE_KEY = {
  APPLY_FOR_ENTITLEMENT: "Apply_for_Entitlement",
  BLOCK_LEAVE: "Block_Leave",
  CHANGE_TO_CALENDAR_VIEW: "Change_to_Calendar_View",
  DELEGATION: "Delegation",
  ENQUIRY_REPORT: "Enquiry_Report",
  HOLIDAY_LIST: "Holiday_List",
  LEAVE_APPLICATIONS: "Leave_Applications",
  LEAVE_APPROVER_CORNER: "Leave_Approver_Corner",
  MY_LEAVE_BALANCE: "My_Leave_Balance",
  SUBMIT_LEAVE_APPLICATION: "Submit_Leave_Application",
};

export const LEAVE_MENU_ACTION_KEY = {
  EMPLOYEE_SEARCH: "Employee_Search",
  LEAVE_PLANNER: "Leave_Planner",
  SUBMIT_LEAVE_APPLICATION_DELEGATED_LIST:
    "Submit_Leave_Application_Delegated_List",
  SUBMIT_LEAVE_APPLICATION_OWN_LIST: "Submit_Leave_Application_Own_List",
  SUBMIT_LEAVE_APPLICATION_HRVIEW_LIST: "Submit_Leave_Application_HRVIEW_List",
};

export const LEAVE_LIST_ACTIONS = {
  VIEW_APPLICATION: "View Application",
  VIEW_APPLICATION_DETAILS: "View Application Details",
  EDIT_APPLICATION: "Edit Application",
  DELETE_DRAFTS: "Delete Draft",
  CANCEL_APPLICATION: "Cancel Application",
};
