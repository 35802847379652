export const BaseMultiStepFormScrollToMultiStepFormMenuActiveItem = (
  selector
) => {
  let element = document.querySelector(selector);
  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
      inline: "start",
      block: "nearest",
    });
  }
};
