import "./view.scss";

import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { TabMenu } from "primereact/tabmenu";
import { Button } from "primereact/button";

import { setBcDynamicPaths } from "../../../../../redux/actions/breadcrumb";
import { RentalDetailsService } from "./../../../../../services/hrmnet-api/index";
import { APPLICATION_STATUS, MultiStepForm } from "../../components";
import {
  CCType,
  configToFormModel,
  formSkeleton,
  getCustomFunctionLibrary,
  initFormValue,
} from "../../utils";
import { useWindowSize } from "../../../utils/window-size/useWindowSize";
import LanguageLoader from "../../../../../components/language-loader/language-loader";
import { RENTAL_T_CONTEXT_KEY } from "../../constants";
import { EEPORTAL_LABEL_BASE_TABLE } from "../../../../../constants";
import {
  resetRentalForm,
  setRentalForm,
  setRentalFormConfig,
  setRentalSectionConfig,
} from "../../../../../redux/actions/rental";

const ViewRentalApplication = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();
  const { isMobile } = useWindowSize();

  const form = useSelector((state) => state.rental.form);
  const setForm = (x) => dispatch(setRentalForm(x));
  const formConfig = useSelector((state) => state.rental.formConfig);
  const setFormConfig = (x) => dispatch(setRentalFormConfig(x));
  const formSectionConfig = useSelector(
    (state) => state.rental.formSectionConfig
  );
  const setFormSectionConfig = (x) => dispatch(setRentalSectionConfig(x));

  // Init
  useEffect(() => {
    dispatch(resetRentalForm());
    return () => {
      dispatch(resetRentalForm());
    };
  }, [dispatch]);

  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );

  const [state, setState] = useState({
    activeStep: 0,
    form: {},
    formName: "",
    formConfig: null,
    menu: [],
    activeSectionId: -1,
    isLoading: true,
  });

  // Fetch Datas
  useEffect(() => {
    const loadApplicationDetail = async () => {
      const applicationId = props.match.params.applicationId;
      const api = RentalDetailsService.rentalGetViewApplication({
        applicationId,
      });
      let [res] = await Promise.all([api]);

      if (
        res &&
        res.data &&
        res.data.sections &&
        res.data.sections.length > 0
      ) {
        // Special handling, show administrator/verifier supporting documents
        const admSuppDocSecIndex = res.data.sections.findIndex(
          (sec) => sec.fields?.[0].inputName === "ApproverUploadDoc"
        );
        if (admSuppDocSecIndex > -1) {
          const admFileAttachments =
            res.data.sections[admSuppDocSecIndex]?.fields[0]?.fileAttachments;
          if (admFileAttachments && admFileAttachments.length > 0) {
            res.data.sections[admSuppDocSecIndex].isDefaultHide = false;
            res.data.sections[admSuppDocSecIndex].fields[0].inputHint = "";
          }
        }
        // self-construct a stepConfig for a single step form
        const stepConfig = [
          {
            sections: res.data.sections
              // Special handling, hide comment section for Drafts
              .filter((sec) => {
                return (
                  res.data.applicationStatus !== APPLICATION_STATUS.DRAFT ||
                  sec.fields?.[0].controlType !== CCType.COMMENT_LIST
                );
              })
              .map((sec) => ({
                sectionId: sec.id,
                sectionName: sec.sectionName,
                label: sec.sectionName,
                isDefaultHide: sec.isDefaultHide,
              })),
          },
        ];
        const initForm = initFormValue(res.data.sections);
        let formConfig = configToFormModel({
          applicationId: applicationId,
          stepConfig: stepConfig,
          sectionFieldConfig: res.data.sections,
          customData: {
            claims: res.data.claims.monthlyReceipts,
            comments: res.data.comments,
            attachmentControlConfig: res.data.attachmentType,
            customFunctionLibrary: getCustomFunctionLibrary({ dispatch }),
          },
          t: t,
          form: initForm,
        });
        setForm(initForm);
        setFormConfig(formConfig);
        setFormSectionConfig(res.data);

        setState((state) => ({
          ...state,
          formId: res?.data?.applicationId,
          formName: res?.data?.formName,
          menu: stepConfig[0].sections,
          isLoading: false,
        }));
      }
    };
    loadApplicationDetail();
  }, [selectedLangKey]);

  // useEffect
  useEffect(() => {
    if (isMobile && state.formConfig) {
      setState((state) => ({
        ...state,
        activeSectionId: state.formConfig?.steps[0].sections[0].sectionId,
      }));
    } else {
      setState((state) => ({
        ...state,
        activeSectionId: -1,
      }));
    }
  }, [isMobile, state.formConfig]);

  // Breadcrumb
  useEffect(() => {
    dispatch(setBcDynamicPaths([{ label: t("rental_view_breadcrumb") }]));
  }, [dispatch, t]);

  const backPage = () => {
    history.goBack();
  };

  const printPage = () => {
    var head = document.head.innerHTML;
    var content = document.getElementsByClassName("main-content")[0].innerHTML;

    var printWindow = window.open("", "");
    printWindow.document.write(
      "<html>" + head + "<body>" + content + "</body></html>"
    );
    printWindow.document.close();
    printWindow.focus();
    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 200);
  };

  // render
  const renderFormTitle = () => (
    <div className="header">
      <div className="rental-form-title">
        <Button
          onClick={backPage}
          icon="pi pi-angle-left"
          className="p-button-rounded-lg p-button-text"
        />
        <h2>{state.formName}</h2>
      </div>
      <div className="rental-form-id">
        <span>{state.formId}</span>
      </div>
    </div>
  );

  const renderFormSkeleton = () => formSkeleton;

  const renderForm = () => (
    <>
      <div className="rental-tabmenu-container">
        <TabMenu
          className="rental-tabmenu"
          model={state.menu}
          activeIndex={state.menu.findIndex(
            (item) => item.sectionId === state.activeSectionId
          )}
          onTabChange={(e) => {
            setState((state) => ({
              ...state,
              activeSectionId:
                e.value.sectionId === state.activeSectionId
                  ? -1
                  : e.value.sectionId,
            }));
          }}
        />
      </div>

      <MultiStepForm
        form={form}
        sectionFilter={state.activeSectionId < 0 ? null : state.activeSectionId}
        activeStep={state.activeStep}
        config={formConfig}
        touched={state.touched}
      />
    </>
  );

  const renderFooter = () => (
    <div className="footer p-grid p-align-center p-justify-between">
      <Button
        onClick={() => backPage()}
        className="p-button-outlined secondary"
      >
        {t("rental_common_actionBack")}
      </Button>
      <Button onClick={() => printPage()} className="p-button next-button">
        {t("rental_common_actionPrint")}
      </Button>
    </div>
  );

  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          RENTAL_T_CONTEXT_KEY.COMMON,
          RENTAL_T_CONTEXT_KEY.VIEW,
          RENTAL_T_CONTEXT_KEY.APPROVAL, // approval claim table
        ]}
      />
      <div className="rental-ms-form rental-view">
        {/* Form Name */}
        {!state.isLoading && renderFormTitle()}

        {/* Form */}
        {state.isLoading ? renderFormSkeleton() : renderForm()}

        {/* Footer */}
        {state.isLoading ? renderFormSkeleton() : renderFooter()}
      </div>
    </>
  );
};

export default ViewRentalApplication;
