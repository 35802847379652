// import { Button } from "primereact/button";
import { useState, useEffect } from "react";
// import { useTranslation } from "react-i18next";
//
import { Dialog } from "primereact/dialog";

/**
 * Dialog component to display password
 */
const MainPasswordDialog = (props) => {
  // ------------------------------------------------------------------------
  // const { t } = useTranslation();
  // const [listPwd, setListPwd] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [visible, setVisible] = useState(true);

  // ------------------------------------------------------------------------
  useEffect(() => {
    // console.log("---pwd-dialog---useEffect---", props);
    const loadData = () => {};
    try {
      loadData();
    } catch (err) {
    } finally {
      setLoading(false);
    }
  }, []);

  // ------------------------------------------------------------------------
  const closeFn = () => {
    // setVisible(false);
    if (props.closeFn) {
      props.closeFn();
    }
  };

  // ------------------------------------------------------------------------
  const renderComponent = () => {
    // console.log("---main-password-dialog--renderComponent");
    return renderDialog();
  };

  // ------------------------------------------------------------------------
  const renderDialog = () => {
    // console.log("---main-password-dialog--renderDialog");
    return (
      <>
        <Dialog
          header="Header"
          style={{ minWidth: "50vw" }}
          closable={true}
          onHide={closeFn}
          visible={props.visible}
        >
          {renderDialogContent()}
        </Dialog>
      </>
    );
  };
  // ------------------------------------------------------------------------
  const renderDialogContent = () => {
    if (loading) {
      return <div>Loading</div>;
    }
    return <div>To be implemented - Password history</div>;
  };
  // ------------------------------------------------------------------------
  // return the rendering
  return <>{renderComponent()}</>;
};

export default MainPasswordDialog;
