import React, { useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { scrollToErrorControlWithSelector } from "../../base-form/utils";
import BaseControl, {
  BCRules,
  BCType,
  BCValidateResult,
  validateControl,
} from "../base-control";
import { MultiSelectControlOption } from "../multiselect-control/multiselect-control";
import { SelectControlOption } from "../select-control/select-control";

interface ConfigType {
  readOnly?: boolean;
  disabled?: boolean;
}

export type ArrayControlRefHandler = {
  onCancel: () => void;
  reset: () => void;
  onSubmit: (controlState: BCValidateResult) => any;
  onValidation: () => BCValidateResult;
  updateValue: (value: any) => void;
  getTouched: () => boolean;
};

interface ArrayControlBaseFieldControlProps {
  prevvalue?: any;
  config: ConfigType;
  onChange: (param: {
    [key: string]: any;
    controlState: BCValidateResult;
    value: any;
    valueStr: string;
  }) => boolean;
  placeholder?: string;
  required?: boolean;
  type: BCType;
  /**
   * Value of control
   */
  ruleList?: BCRules[];
  enum?: SelectControlOption[] | MultiSelectControlOption[];
  prevControlState?: BCValidateResult;
  fieldKey: string;
  defaultValue?: any;
}

const ArrayControlBaseField = React.forwardRef<
  ArrayControlRefHandler,
  ArrayControlBaseFieldControlProps
>((props, ref) => {
  const {
    defaultValue,
    ruleList,
    required,
    fieldKey,
    config,
    prevvalue,
    onChange,
    prevControlState,
    ...rest
  } = props;
  const [value, setValue] = useState<any | null>(prevvalue ?? defaultValue);
  const [touched, setTouched] = useState<boolean>(false);
  const [controlState, setControlState] =
    useState<BCValidateResult | undefined>(prevControlState);
  const { t } = useTranslation();

  useEffect(() => {
    if (config?.readOnly !== true || config?.disabled !== true) {
      setValue(prevvalue ?? defaultValue);
    } else {
      setValue(defaultValue);
    }

    return () => setValue(prevvalue ?? defaultValue);
  }, [config]);

  useImperativeHandle(ref, () => ({
    onValidation: () => {
      const _ruleList = ruleList || [];
      if (required) {
        _ruleList.push({
          name: "required",
        });
      }
      const _controlState = validateControl(_ruleList ?? [], value, t);
      setControlState({ ..._controlState });
      return _controlState;
    },
    onSubmit: (_controlState) => {
      if (_controlState?.invalid) {
        scrollToErrorControlWithSelector();
        return;
      }
      return value;
    },
    getTouched: () => {
      return touched;
    },
    reset: () => {
      const _param: any = {};
      _param.value = null;
      _param.isHeaderForm = true;
      _param.key = fieldKey;
      onChange(_param);
      setValue(defaultValue);
      setTouched(false);
      return prevvalue;
    },
    onCancel: () => {
      const _param: any = {};
      _param.value = null;
      _param.isHeaderForm = true;
      _param.key = fieldKey;
      onChange(_param);
      setValue(defaultValue);
      setTouched(false);
      return prevvalue;
    },
    updateValue: (val: any) => {
      const _param: any = {};
      _param.value = val;
      _param.key = fieldKey;
      _param.isHeaderForm = true;
      onChange(_param);
      setTouched(false);
      setValue(val);
    },
  }));

  return (
    <BaseControl
      config={config}
      {...rest}
      value={value}
      formSubmited={controlState?.invalid}
      touched={controlState?.invalid}
      controlState={controlState}
      onTouched={() => {
        setTouched(true);
      }}
      required={required}
      onChange={(params) => {
        const _param: any = {};
        _param.value = params.value;
        _param.key = fieldKey;
        _param.isHeaderForm = true;
        setValue(params.value);
        return onChange(_param);
      }}
    />
  );
});

export default ArrayControlBaseField;
