export const DATE_FORMAT = "YYYY-MM-DD";
export const TIME_FORMAT = "HH:mm:ss";
export const DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const NWOW_WORKFLOW_STATUS = {
  IN_PROGRESS: 0,
  COMPLETE: 1,
  REJECT: 2,
  SUSPEND: 3,

  // Special
  DRAFT: 6,
  DELETED: 8,
  ADMIN_ENTRY_CREATED: 9,
  LEAVE_STATUS_AMENDED: 10,
};

export const LEAVE_TYPE = {
  ANNUAL: "AL",
  COMPENSATION: "CEL",
  SICK: "L",
  COMPASSIONATE: "CL",
  OTHER: "OL",
  PATERNITY: "PL",
  MARRIAGE: "ML",
  BUSINESS: "BL",
  NO_PAY: "NPL",
  BIRTHDAY: "BL",
  JURY_DUTY: "JL",
  STUDY_EXAM: "STUDYL",
};

export const DATE_NWOW_STATUS = {
  [NWOW_WORKFLOW_STATUS.COMPLETE]: {
    label: "Nwow_dateNwowStatus_approved",
    color: "#000000",
  },
};

export const DATE_TYPE = {
  WORKING: "Y",
  REST: "P",
  NON_PAID_REST: "N",
  HOLIDAY: "H",
  STATUTORY: "S",
};

/**
 * interface DateConfigItem
 * showMark: boolean;
 * disabled: boolean;
 * isRedDay: boolean;
 */
export const DATE_TYPE_CONFIG = {
  [DATE_TYPE.WORKING]: {
    disabled: false,
    isRedDay: false,
    color: "#ff0000",
  },
  [DATE_TYPE.REST]: {
    disabled: true,
    isRedDay: false,
    color: "#2d7829",
  },
  [DATE_TYPE.NON_PAID_REST]: {
    disabled: true,
    isRedDay: false,
    color: "#fece2f",
  },
  [DATE_TYPE.HOLIDAY]: {
    disabled: false,
    isRedDay: true,
    color: "#0000ff",
  },
  [DATE_TYPE.STATUTORY]: {
    disabled: false,
    isRedDay: true,
    color: "#ff00ff",
  },
};

export const UOM = {
  DAY: "DAY",
  HOUR: "HOUR",
};

export const DISPLAY_UOM = {
  [UOM.DAY]: "leave_common_uomDay",
  [UOM.HOUR]: "leave_common_uomHour",
};

export const DELEGATE_TYPE = {
  "Apply Leave": 1,
  "Approve Leave": 2,
};

export const NWOW_T_CONTEXT_KEY = {
  APPROVAL: "UI.Nwow.Approval",
  BALANCE: "UI.Nwow.Balance",
  BLOCK_LEAVE: "UI.Nwow.BlockLeave",
  CALENDAR_VIEW: "UI.Nwow.CalendarView",
  COMMON: "UI.Nwow.Common",
  DELEGATION: "UI.Nwow.Delegation",
  ENTITLEMENT: "UI.Nwow.Entitlement",
  FORM: "UI.Nwow.Form",
  HOLIDAY: "UI.Nwow.Holiday",
  LIST: "UI.Nwow.List",
  PLANNER: "UI.Nwow.Planner",
  REPORT: "UI.Nwow.Report",
  SUBMIT_APPLICATION: "UI.Nwow.SubmitApplication",
  VIEW_APPLICATION: "UI.Nwow.ViewApplication",
};

export const NWOW_MENU_MODULE_KEY = "Nwow";

export const NWOW_MENU_TYPE_KEY = {
  ACTION: "Action",
  MENU: "Menu",
};

export const NWOW_MENU_PAGE_KEY = {
  APPLY_FOR_ENTITLEMENT: "Apply_for_Entitlement",
  BLOCK_LEAVE: "Block_Leave",
  CHANGE_TO_CALENDAR_VIEW: "Change_to_Calendar_View",
  DELEGATION: "Delegation",
  ENQUIRY_REPORT: "Nwow_Report",
  HOLIDAY_LIST: "Holiday_List",
  NWOW_APPLICATIONS: "Leave_Applications",
  NWOW_APPROVER_CORNER: "Leave_Approver_Corner",
  MY_LEAVE_BALANCE: "My_Leave_Balance",
  SUBMIT_LEAVE_APPLICATION: "Submit_Leave_Application",
};

export const NWOW_MENU_ACTION_KEY = {
  EMPLOYEE_SEARCH: "Employee_Search",
  NWOW_PLANNER: "Leave_Planner",
};
