import "./application-type-control.scss";

import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { useTranslation } from "react-i18next";
import { RadioButton } from "primereact/radiobutton";

import {
  BCProps,
  BCStates,
  isError,
  renderError,
  validateControl,
} from "../../../../components/base-control/base-control";

export const NEW_APPLICATION_VALUE = "NEW";
export const COPY_APPLICATION_VALUE = "COPY";

export interface ApplicationTypeControlProps extends BCProps {
  controlKey: any;
  valueObj: any;
  onFocus: any;
  onChange: any;
  onSelectApplication: any;
  onSelectNewApplication: any;
  enum: ApplicationTypeControlOption[];
}

export interface ApplicationTypeControlOption {
  /**
   * Item label
   */
  label: string;
  /**
   * Item value
   */
  value: string;
  /**
   * Addtional props
   */
  [key: string]: any;
}

const ApplicationTypeControl: React.FC<ApplicationTypeControlProps> = (
  props
) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    if (props.value?.select == null) {
      onChange({ select: NEW_APPLICATION_VALUE });
    }
  }, []);

  const onChange = (value: any) => {
    if (props.onChange) {
      props.onChange(value);
    }
    setValue(value);
  };

  const handleDropdownOnChange = (value: any) => {
    if (props.onSelectApplication) {
      props.onSelectApplication(value);
    }
    onChange(value);
  };

  const handleSelectNewApplication = (eventValue: any) => {
    if (value.select !== NEW_APPLICATION_VALUE) {
      if (props.onSelectNewApplication) {
        props.onSelectNewApplication({
          selfKey: props.controlKey,
          value: eventValue,
        });
      }
    }
    onChange({ select: eventValue });
  };

  const displayValue = (value: any) => {
    if (value.select === COPY_APPLICATION_VALUE) {
      return t("rental_create_copyApplication");
    } else {
      return t("rental_create_newApplication");
    }
  };

  if (props.config.readOnly) {
    return <span>{displayValue(value.select)}</span>;
  }

  return (
    <>
      <div className="application-type-control p-grid">
        <div className="p-col p-lg-6 p-md-12 p-sm-12">
          <table>
            <tbody>
              <tr>
                <td>
                  <RadioButton
                    value={NEW_APPLICATION_VALUE}
                    onChange={(e) => handleSelectNewApplication(e.value)}
                    checked={value?.select === NEW_APPLICATION_VALUE}
                  />
                </td>
                <td>
                  <label>{t("rental_create_newApplication")}</label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="p-col p-lg-6 p-md-12 p-sm-12">
          <table>
            <tbody>
              <tr>
                <td>
                  <RadioButton
                    value={COPY_APPLICATION_VALUE}
                    onChange={(e) => onChange({ select: e.value })}
                    checked={value?.select === COPY_APPLICATION_VALUE}
                  />
                </td>
                <td>
                  <label>{t("rental_create_copyApplication")}</label>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <Dropdown
                    placeholder={t("rental_create_pleaseSelectApplication")}
                    value={value?.value}
                    onChange={(e) => {
                      handleDropdownOnChange({
                        select: COPY_APPLICATION_VALUE,
                        value: e.value,
                      });
                    }}
                    // value={select?.value}
                    options={props.enum}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ApplicationTypeControl;

// export function submitPreprocess(value: any, valueObj: any) {
//     if
// }
