import axios from "axios";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BCType } from "../../../../components/base-control/base-control";
import { getControlModel } from "../../../../components/base-control/base-cotrol-model";
import BaseForm from "../../../../components/base-form/base-form";
import {
  LookUpDsName,
  RESPONSE_MESSAGE_ERROR,
  Target_Organization,
} from "../../../../constants";
import { setMainForm, setMainFormConfig } from "../../../../redux/actions/form";
import {
  AdminSettingConfigurationService,
  MasterService,
} from "../../../../services/hrmnet-api";
import { ToUIOption } from "../../public/login/components/utils";

function SettingConfigurationOrganization(props, ref) {
  const { isLoading, setLoading, onFilterChange } = props;
  const formRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { cancel, token } = axios.CancelToken.source();
  const requestConfig = {
    cancelToken: token,
  };

  const setForm = (x) => dispatch(setMainForm(x));
  const setFormConfig = (x) => dispatch(setMainFormConfig(x));

  const [targetOrganizationNameOptions, setTargetOrganizationNameOptions] =
    useState([]);

  const { filterControl, formControl, sectionId, formConfig } = useSelector(
    (state) => {
      if (state.commonForm.formConfig?.steps?.length > 0) {
        if (state.commonForm.formConfig?.steps[0]?.sections?.length > 0) {
          return {
            filterControl:
              state.commonForm.formConfig?.steps[0]?.sections[0]?.filterControl,
            formControl:
              state.commonForm.formConfig?.steps[0]?.sections[0]?.controls,
            formConfig: state.commonForm.formConfig,
            sectionId:
              state.commonForm.formConfig?.steps[0]?.sections[0]?.sectionId,
          };
        }
        return {};
      }
      return {};
    }
  );

  const [state, setState] = useState({
    form: {},
  });
  const [targetOrganizationOptions, setTargetOrganizationOptions] = useState(
    ToUIOption([
      {
        description: Target_Organization.Global,
        name: Target_Organization.Global,
        value: Target_Organization.Global,
      },
    ])
  );

  useImperativeHandle(ref, () => ({
    getForm: () => {
      return formRef?.current?.getForm();
    },
  }));

  useEffect(() => {
    async function getTargetOrganizationTypeOptions(type, filterControl) {
      const options = await getTargetOrganizationOptions(type);
      const optionsUI = ToUIOption(
        options?.filter((x) =>
          filterControl.map((y) => y.trim()).includes(x.value)
        )
      );
      setTargetOrganizationOptions(optionsUI);
      if (Array.isArray(optionsUI) && optionsUI.length > 0) {
        onTargetOrganizationOptionsChange(optionsUI[0].value);
      }
    }

    if (
      sectionId > 0 &&
      isLoading === false &&
      Array.isArray(filterControl) &&
      filterControl?.length > 0
    ) {
      getTargetOrganizationTypeOptions(
        LookUpDsName.Target_Organizations_DS,
        filterControl
      );
    }

    return () => {
      setTargetOrganizationOptions([]);
      setTargetOrganizationNameOptions([]);
      cancel(RESPONSE_MESSAGE_ERROR.CANCEL);
    };
  }, [sectionId, isLoading]);

  const getTargetOrganizationOptions = async (type) => {
    try {
      const res = await MasterService.masterGetMaster(
        {
          name: type,
        },
        requestConfig
      );
      if (res) {
        return res;
      }

      return [];
    } catch (error) {
      return;
    }
  };

  const getCurrentSection = async (name) => {
    try {
      if (!name || name.length > 0) {
        const res =
          await AdminSettingConfigurationService.settingConfigurationGetSettingConfigurations(
            {
              targetOrganizationType:
                formRef?.current?.getForm()?.targetOrganizationType,
              targetOrganizationName:
                Array.isArray(name) && name.length > 0 ? name[0] : name,
              sectionId: sectionId,
            },
            requestConfig
          );
        if (!!res && Array.isArray(res.data) && res.data.length > 0) {
          if (onFilterChange) {
            var { allSectionsConfiguration } = onFilterChange(res);
            setFormConfig(allSectionsConfiguration);
            if (!name || (Array.isArray(name) && name?.length === 1)) {
              setForm(allSectionsConfiguration?.formValues);
            } else {
              setForm({});
            }
          }
        } else {
          resetFormControl();
        }
      }
    } catch (error) {
      return;
    }
  };

  function resetFormControl() {
    const result = { ...formConfig };
    if (result?.steps?.length > 0) {
      if (result?.steps[0]?.sections?.length > 0) {
        result.steps[0].sections[0].controls = [];
      }
    }
    setFormConfig(result);
  }

  async function onTargetOrganizationOptionsChange(value) {
    const options = await getTargetOrganizationOptions(value);

    if (Array.isArray(options) && options.length > 0) {
      setTargetOrganizationNameOptions(ToUIOption(options));
      resetFormControl();
    } else {
      setTargetOrganizationNameOptions([]);
      getCurrentSection(undefined);
    }
    //reset targetOrganizationName value
    setState({
      ...state,
      form: {
        targetOrganizationType: value,
        targetOrganizationName: null,
      },
    });
  }

  function onTargetOrganizationNameOptionsChange(value) {
    if (
      value?.length === 1 ||
      (Array.isArray(formControl) && formControl?.length === 0)
    ) {
      getCurrentSection(value);
    } else if (!value || (Array.isArray(value) && value?.length === 0)) {
      resetFormControl();
    }
  }

  return (
    <div className="base_multi_step_form-group horizontal filter-menu">
      <div className="panel-header">
        <div className="title">
          {t("SettingConfigurationOrganization_SectionName")}
        </div>
      </div>
      <BaseForm
        ref={formRef}
        readOnly={isLoading}
        config={SettingConfigurationOrganizationForm({
          t: t,
          onTargetOrganizationOptionsChange: onTargetOrganizationOptionsChange,
          onTargetOrganizationNameOptionsChange:
            onTargetOrganizationNameOptionsChange,
          targetOrganizationOptions: targetOrganizationOptions,
          targetOrganizationNameOptions: targetOrganizationNameOptions,
        })}
        form={state.form}
      />
    </div>
  );
}

const SettingConfigurationOrganizationForm = ({
  t,
  onTargetOrganizationOptionsChange,
  onTargetOrganizationNameOptionsChange,
  targetOrganizationOptions,
  targetOrganizationNameOptions,
}) => {
  const config = {
    controls: [
      getControlModel({
        key: "targetOrganizationType",
        label: t("SettingConfigurationOrganization_targetOrganizationType"),
        required: true,
        type: BCType.select,
        placeholder: t("base_control_select_choose"),
        enum: targetOrganizationOptions,
        hasFilterEnum: false,
        config: {
          showClear: false,
          disabled:
            !targetOrganizationOptions || targetOrganizationOptions.length < 2,
          readOnly:
            !targetOrganizationOptions || targetOrganizationOptions.length < 2,
        },
        noLabel: false,
        hintBottom: true,
        onTrueUpdateValue: (e) => {
          onTargetOrganizationOptionsChange(e.value);
        },
      }),
      getControlModel({
        key: "targetOrganizationName",
        label: t("SettingConfigurationOrganization_targetOrganizationName"),
        required: true,
        type: BCType.multiselect,
        placeholder: t("base_control_select_choose"),
        enum: targetOrganizationNameOptions,
        hasFilterEnum: false,
        config: {
          showClear: true,
          disabled:
            !targetOrganizationNameOptions ||
            targetOrganizationNameOptions.length === 0,
          readOnly:
            !targetOrganizationNameOptions ||
            targetOrganizationNameOptions.length === 0,
        },
        noLabel: false,
        hintBottom: true,
        onTrueUpdateValue: (e) => {
          onTargetOrganizationNameOptionsChange(e.value);
        },
      }),
    ],
    layout: {
      rows: [
        {
          columns: [
            {
              control: "targetOrganizationType",
            },
            {
              control: "targetOrganizationName",
            },
          ],
        },
      ],
    },
  };

  return config;
};

export default forwardRef(SettingConfigurationOrganization);
