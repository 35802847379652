import "./legend.scss";

interface LegendProps {
  renderLegend?: any;
  legend: "round" | "square";
  legendColor?: string;
  legendIcon?: string;
  text?: string;
  textColor?: string;
  className?: string;
}

const SquareSVG = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1 1" {...props}>
    <path d="M0 0v1h1V0" />
  </svg>
);

export const Legend = (props: LegendProps) => {
  const renderLegend = () => {
    if (props.renderLegend) {
      return <div className="legend-icon">{props.renderLegend()}</div>;
    }

    if (props.legend === "square") {
      return (
        <>
          {props.legendIcon != undefined ? (
            <div
              className={"pi " + props.legendIcon}
              style={{ backgroundColor: "rgba(0,0,0,0)", marginRight: "5px" }}
            >
              {" "}
              &nbsp;
            </div>
          ) : (
            <span
              className={"legend-icon legend-square pi " + props.legendIcon}
            >
              <SquareSVG fill={props.legendColor} />
            </span>
          )}
        </>
      );
    } else {
      const style = props.legendColor ? { color: props.legendColor } : {};
      return (
        <span
          style={style}
          className="legend-icon legend-round pi pi-circle-on"
        ></span>
      );
    }
  };

  const renderText = () => {
    const style = props.textColor ? { color: props.textColor } : {};
    return (
      <span className="legend-text" style={style}>
        {props.text}
      </span>
    );
  };

  return (
    <div className={`custom-legend ${props.className || ""}`}>
      {renderLegend()}
      {renderText()}
    </div>
  );
};
