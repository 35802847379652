import "./approve-application.scss";

import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";

import { setBcDynamicPaths } from "../../../../../redux/actions/breadcrumb";
import { RentalApprovalService } from "./../../../../../services/hrmnet-api/index";
import { APPLICATION_STATUS, MultiStepForm } from "../../components";
import {
  returnApplicationApproval,
  approveApplicationApproval,
  rejectApplicationApproval,
  finalizeApplicationApproval,
  configToFormModel,
  formSkeleton,
  initFormValue,
  getCustomFunctionLibrary,
} from "../../utils";
import {
  EEPORTAL_LABEL_BASE_TABLE,
  FUNCTION_CODE,
  RENTAL_SUBMODULE_CODE,
} from "../../../../../constants";
import { openModal } from "../../../../../redux/actions/modal";
import {
  resetRentalForm,
  setRentalForm,
  setRentalFormConfig,
  setRentalSectionConfig,
} from "../../../../../redux/actions/rental";
import { isResponseOk } from "../../../../../utils/utils";
import {
  showInfoMessageModal,
  showTimestampToastSuccess,
} from "../../../../../services/utils/message";
import { scrollToErrorControl } from "../../../../../components/base-form/utils";
import LanguageLoader from "../../../../../components/language-loader/language-loader";
import {
  RENTAL_T_CONTEXT_KEY,
  RENTAL_MENU_MODULE_KEY,
  RENTAL_MENU_ACTION_KEY,
} from "../../constants";
import { showSpinner } from "../../../../../redux/actions/spinner";
import { PortalLink } from "../../../../../services/utils";

const APPLICATION_ACTION = {
  RETURN: 0,
  APPROVE: 1,
  REJECT: 2,
  FINALIZE: 3,
};

const ApproveApplication = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();

  const [state, setState] = useState({
    activeStep: 0,
    form: {},
    formName: "",
    formConfig: null,
    applicationNo: "",
    menu: [],
    activeSectionId: -1,
    isApplicationDisabled: false, // handle withdrawn application
    isLoading: true,
  });
  const formObjRef = React.createRef();

  const form = useSelector((state) => state.rental.form);
  const setForm = (x) => dispatch(setRentalForm(x));
  const formConfig = useSelector((state) => state.rental.formConfig);
  const setFormConfig = (x) => dispatch(setRentalFormConfig(x));
  const formSectionConfig = useSelector(
    (state) => state.rental.formSectionConfig
  );
  const setFormSectionConfig = (x) => dispatch(setRentalSectionConfig(x));
  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );

  const selectedLangRef = useRef();

  const menus = useSelector((state) => state.menu.sideBar);
  const rentalMenu = menus.find(
    (x) => x.key === RENTAL_MENU_MODULE_KEY
  )?.subMenus;
  const finalizePermission = rentalMenu.find(
    (x) => x.key === RENTAL_MENU_ACTION_KEY.RENTAL_FINALIZE_APPLICATIONS
  );

  const isFinalize = !!props.finalizeApplication;

  // Init
  useEffect(() => {
    dispatch(resetRentalForm());
    return () => {
      dispatch(resetRentalForm());
    };
  }, []);

  const customFunctionLibrary = getCustomFunctionLibrary({ dispatch });

  // Fetch Datas
  useEffect(() => {
    const loadApplicationDetail = async () => {
      const isLangChange =
        selectedLangRef.current != null &&
        selectedLangRef.current !== selectedLangKey;
      selectedLangRef.current = selectedLangKey;

      const applicationId = isFinalize
        ? props.applicationId
        : props.match.params.applicationId;
      const api =
        RentalApprovalService.rentalApprovalGetApplicationApprovalApplication({
          applicationId,
        });
      let [res] = await Promise.all([api]);
      // res = sampleApproveApplicationConfig;
      if (
        res &&
        res.data &&
        res.data.sections &&
        res.data.sections.length > 0
      ) {
        // self-construct a stepConfig for a single step form
        const stepConfig = [
          {
            sections: res.data.sections.map((sec) => ({
              sectionId: sec.id,
              sectionName: sec.sectionName,
              label: sec.sectionName,
              isEditable: true,
              isDefaultHide: sec.isDefaultHide,
            })),
          },
        ];
        let initForm;
        if (!isLangChange) {
          initForm = initFormValue(res.data.sections);
        } else {
          initForm = form;
        }
        let formConfig = configToFormModel({
          applicationId: applicationId,
          stepConfig: stepConfig,
          sectionFieldConfig: res.data.sections,
          customData: {
            claims: res.data?.claims,
            comments: res.data.comments,
            customFunctionLibrary: customFunctionLibrary,
            attachmentControlConfig: res.data.attachmentType,
          },
          t: t,
          form: initForm,
        });

        setForm(initForm);
        setFormConfig(formConfig);
        setFormSectionConfig(res.data);

        // If application is withdrawn
        let isApplicationDisabled = false;
        if (res.data.applicationStatus === APPLICATION_STATUS.WITHDRAW) {
          isApplicationDisabled = true;
        }
        setState((state) => ({
          ...state,
          isApplicationDisabled,
          formName: res?.data?.formName,
          applicationNo: res?.data?.applicationNo,
          menu: stepConfig[0].sections,
          isLoading: false,
        }));
      }
    };
    loadApplicationDetail();
  }, [selectedLangKey]);

  // Breadcrumb
  useEffect(() => {
    dispatch(
      setBcDynamicPaths([
        // { label: t("rental_approvalList_title") },
        { label: state.formName || "" },
      ])
    );
  }, [dispatch, t, state.formName]);

  // Nav
  const backPage = ({ confirm } = {}) => {
    if (confirm) {
      dispatch(
        openModal({
          title: t("rental_approveApp_backModalTitle"),
          content: t("rental_approveApp_backModalContent"),
          classNameMainDialog: "confirm-message-modal",
          primaryButtonText: t("rental_common_actionConfirm"),
          primaryButtonClickFn: async ({ closeFn }) => {
            closeFn();
            history.push(
              PortalLink(
                `${FUNCTION_CODE.Rental}/${RENTAL_SUBMODULE_CODE.APPROVAL}`
              )
            );
          },
          secondButtonClickFn: ({ closeFn }) => {
            closeFn();
          },
        })
      );
    } else {
      history.push(
        PortalLink(`${FUNCTION_CODE.Rental}/${RENTAL_SUBMODULE_CODE.APPROVAL}`)
      );
    }
  };

  // Utils
  const printPage = () => {
    var head = document.head.innerHTML;
    var content = document.getElementsByClassName("main-content")[0].innerHTML;

    var printWindow = window.open("", "");
    printWindow.document.write(
      "<html>" + head + "<body>" + content + "</body></html>"
    );
    printWindow.document.close();
    printWindow.focus();
    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 200);
  };

  const validateForm = () => {
    const isValid = !formObjRef.current.getActiveFormState().invalid;
    setTimeout(() => {
      scrollToErrorControl();
    }, 100);
    return isValid;
  };

  // Submit
  const confirmAction = (action) => {
    if (state.isApplicationDisabled) {
      showInfoMessageModal({
        dispatch,
        t,
        content: t("rental_approveApp_withdrawnApplicationError"),
      });
      return;
    }

    if (!validateForm()) {
      setState({
        ...state,
        touched: true,
      });
      return;
    }
    let confirmMessageAction = "";
    switch (action) {
      case APPLICATION_ACTION.RETURN:
        confirmMessageAction = t("rental_approveApp_actionReturnShort");
        break;
      case APPLICATION_ACTION.APPROVE:
        confirmMessageAction = t("rental_approveApp_actionApprove");
        break;
      case APPLICATION_ACTION.REJECT:
        confirmMessageAction = t("rental_approveApp_actionReject");
        break;
      case APPLICATION_ACTION.FINALIZE:
        confirmMessageAction = t("rental_approveApp_actionFinalize");
        break;
      default:
        break;
    }

    dispatch(
      openModal({
        title: t("rental_approveApp_confirmTitle", {
          action: confirmMessageAction,
        }),
        content: t("rental_approveApp_confirmMessage", {
          action: confirmMessageAction.toLowerCase(),
          applicationId: state.applicationNo,
        }),
        classNameMainDialog: "confirm-message-modal",
        primaryButtonText: t("rental_common_actionConfirm"),
        primaryButtonClickFn: async ({ closeFn }) => {
          closeFn();
          approvalAction(action);
        },
        secondButtonClickFn: ({ closeFn }) => {
          closeFn();
        },
      })
    );
  };

  const approvalAction = async (action) => {
    let actionFn = null;
    let successMessage = "";
    switch (action) {
      case APPLICATION_ACTION.RETURN:
        actionFn = returnApplicationApproval;
        successMessage = t("rental_approveApp_successMessageReturn");
        break;
      case APPLICATION_ACTION.APPROVE:
        actionFn = approveApplicationApproval;
        successMessage = t("rental_approveApp_successMessageApprove");
        break;
      case APPLICATION_ACTION.REJECT:
        actionFn = rejectApplicationApproval;
        successMessage = t("rental_approveApp_successMessageReject");
        break;
      case APPLICATION_ACTION.FINALIZE:
        actionFn = finalizeApplicationApproval;
        successMessage = t("rental_approveApp_successMessageFinalize");
        break;
      default:
        break;
    }

    if (actionFn) {
      const applicationId = isFinalize
        ? props.applicationId
        : props.match.params.applicationId;
      try {
        // start spinner
        dispatch(showSpinner(true));
        const res = await actionFn({
          sectionConfig: formSectionConfig.sections,
          form: form,
          applicationId: applicationId,
        });
        if (!isResponseOk(res)) throw new Error("return failed");
        showTimestampToastSuccess({
          message: successMessage,
          t: t,
        });
        backPage({ confirm: false });
      } catch (e) {
      } finally {
        // end spinner
        dispatch(showSpinner(false));
      }
    }
  };
  // render
  const renderFormTitle = () => (
    <div className="title">
      <Button
        onClick={() => backPage({ confirm: true })}
        icon="pi pi-angle-left"
        className="p-button-rounded-lg p-button-text"
      />
      {state.formName}
    </div>
  );

  const renderFormSkeleton = () => formSkeleton;

  const renderForm = () => (
    <div>
      <MultiStepForm
        form={form}
        sectionFilter={state.activeSectionId < 0 ? null : state.activeSectionId}
        activeStep={state.activeStep}
        config={formConfig}
        touched={state.touched}
        ref={formObjRef}
      />
    </div>
  );

  const renderFooter = () => (
    <div className="footer p-grid p-align-center p-justify-between">
      <div className="button-group-1">
        <Button
          onClick={() => backPage({ confirm: true })}
          className="p-button-outlined secondary"
        >
          {t("rental_common_actionBack")}
        </Button>
        <Button
          onClick={() => printPage()}
          className="p-button-outlined secondary"
        >
          {t("rental_common_actionPrint")}
        </Button>
      </div>
      {isFinalize ? (
        <div className="button-group-2">
          <Button
            onClick={() => confirmAction(APPLICATION_ACTION.FINALIZE)}
            className="p-button"
          >
            {t("rental_approveApp_actionFinalize")}
          </Button>
        </div>
      ) : (
        <div className="button-group-2">
          <Button
            onClick={() => confirmAction(APPLICATION_ACTION.RETURN)}
            className="p-button"
          >
            {t("rental_approveApp_actionReturn")}
          </Button>
          <Button
            onClick={() => confirmAction(APPLICATION_ACTION.APPROVE)}
            className="p-button"
          >
            {t("rental_approveApp_actionApprove")}
          </Button>
          <Button
            onClick={() => confirmAction(APPLICATION_ACTION.REJECT)}
            className="p-button"
          >
            {t("rental_approveApp_actionReject")}
          </Button>
        </div>
      )}
    </div>
  );

  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          RENTAL_T_CONTEXT_KEY.COMMON,
          RENTAL_T_CONTEXT_KEY.APPROVAL,
        ]}
      />
      <div className="rental-approve-application">
        {/* Form Name */}
        {!state.isLoading && renderFormTitle()}

        {/* Form */}
        {state.isLoading ? renderFormSkeleton() : renderForm()}

        {/* Footer */}
        {state.isLoading ? renderFormSkeleton() : renderFooter()}
      </div>
    </>
  );
};

export default ApproveApplication;
