export const InitAccessPermissionEmployeeAccessFormConfig = {
  employeeCode: null,
  roleIds: [],
};

export function AccessPermissionEmployeeAccessApiSteps(t) {
  return {
    data: [
      {
        displaySequence: 1,
        id: 1,
        sections: [
          {
            displaySequence: 1,
            isEditable: true,
            sectionId: 100,
            sectionName: t(
              "access_permission_employee_access_form_Section1Name"
            ), //"Employee List",
          },
          {
            displaySequence: 2,
            isEditable: true,
            sectionId: 200,
            sectionName: t(
              "access_permission_employee_access_form_Section2Name"
            ), //"Data Authorization",
          },
        ],
      },
    ],
  };
}

export function AccessPermissionEmployeeAccessConfigurationInitForm(t, isEdit) {
  return {
    data: {
      id: 1000,
      sections: [
        {
          defaultExpanded: true,
          displayLayout: 2,
          displaySequence: 1,
          id: 100,
          isDefaultHide: false,
          fields: [
            {
              controlType: "DropDownListMultiselect",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 1,
              fieldLabel: t(
                "Access_Permission_Employee_Access_Configuration_Employees"
              ),
              fieldName: "employeeCode",
              id: 1,
              inputName: "employeeCode",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: !isEdit,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey:
                "Access_Permission_Employee_Access_Configuration_Employees",
              viewSectionId: 100,
            },
          ],
        },
        {
          defaultExpanded: true,
          displayLayout: 2,
          displaySequence: 2,
          id: 200,
          isDefaultHide: false,
          fields: [
            {
              controlType: "DropDownListMultiselect",
              dataType: "string",
              displaySequence: 1,
              fieldGroup: 0,
              fieldLabel: t(
                "Access_Permission_Employee_Access_Configuration_Roles"
              ), //"Language",
              fieldName: "roleIds",
              id: 0,
              inputName: "roleIds",
              isAllowNull: false,
              isDefaultHide: false,
              isDetailView: false,
              isEditable: true,
              isElasticData: false,
              isHideLabelField: false,
              isHtmlField: false,
              isSpecialField: false,
              length: -1,
              storageDataType: "String",
              styleClass: "p-lg-6 p-md-12 p-sm-12",
              translationKey:
                "Access_Permission_Employee_Access_Configuration_Roles",
              viewSectionId: 200,
              config: {
                display: "chip",
              },
            },
          ],
        },
      ],
    },
  };
}
