import "./upload-control.scss";

import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import {
  BCProps,
  BCStates,
  isError,
  renderError,
  validateControl,
} from "./../base-control";
import FileUpload from "./file-upload";
import { Button } from "primereact/button";

function isFileEqual(f1: File, f2: File) {
  // Simplify file handling
  // return (
  //   f1.name === f2.name &&
  //   f1.lastModified === f2.lastModified &&
  //   f1.size === f2.size
  // );
  return f1.name === f2.name;
}

export interface UploadControlProps extends BCProps {
  /*
   * Allow multiple upload
   */
  multiple?: boolean; // TODO: To be implemented
  /*
   * Max file size in bytes
   */
  maxFileSize?: string; // TODO: To be implemented
  /*
   * Accepted file type
   */
  accept?: string; // TODO: To be implemented
  /**
   * on Download
   */
  onDownload?: any;
  /**
   * on File Error: wrong extension / exceed limit
   */
  onFileError?: any;
}

export interface UploadControlState extends BCStates {}

const UploadControl: React.FC<UploadControlProps> = (props) => {
  const { t } = useTranslation();
  // extract props data
  const id = props.id || "";
  const lazy = props.fromFilter;
  const ruleList = props.ruleList || [];
  if (props.required) {
    ruleList.push({
      name: "required",
    });
  }

  // init state control
  let initState: UploadControlState = {
    touched: false,
    value: props.value,
    valueStr: (props.value || "").toString(),
    controlState: {
      invalid: false,
    },
  };
  initState.controlState =
    props.controlState || validateControl(ruleList || [], initState.value, t);
  const [state, setState] = useState(initState);
  const mountedRef = useRef(true);

  // unsubcribe
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  // Update state if control state changed
  useEffect(() => {
    const ruleList = props.ruleList || [];
    if (props.required) {
      ruleList.push({
        name: "required",
      });
    }

    let controlState =
      props.controlState || validateControl(ruleList || [], props.value, t);
    if (!mountedRef.current) return;
    setState({
      ...state,
      value: props.value,
      setDefault: true,
      controlState,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.controlState, props.required, props.value]);

  // Update if rule update
  useEffect(() => {
    if (!mountedRef.current) return;
    onChange(state.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.required, props.ruleList]);

  useEffect(() => {
    if (
      props.defaultValue &&
      props.defaultValue !== state.lastDefault &&
      state.setDefault
    ) {
      onChange(state.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultValue, state.lastDefault]);

  // Update display value if state value changed
  useEffect(() => {
    if (lazy) {
      try {
        //
        if (id) {
          let elm = document.getElementById(id) as any;
          if (elm) elm.value = state.value || "";
        }
      } catch (error) {}
    }
  }, [id, lazy, state.value]);

  const onSelect = (e: any) => {
    let value = state.value;
    let _state = { ...state };
    if (_state.value) {
      let filteredFiles = [...e.files].filter((file: any) => {
        return !_state.value.some((oriFile: any) =>
          isFileEqual(oriFile, file)
        );
      });
      _state.value = [..._state.value, ...filteredFiles];
    } else {
      _state.value = [...e.files];
    }

    value = _state.value;
    
    setState(_state);

    return value;
  };

  const onRemove = (e: { index: number }) => {
    if (state.value && state.value.length > 0) {
      let _state = { ...state };
      _state.value.splice(e.index, 1);
      setState(_state);
      onChange(_state.value);
    }
  };

  const onChange = async (value: File[]) => {
    const controlState = validateControl(ruleList, value, t);
    // onChange
    let valueStr = Array.isArray(value)
      ? value.map((f) => f.name).join(";") + ";"
      : "";
    if (props.onChange) {
      props.onChange({
        controlState: controlState,
        value: value,
        valueStr: valueStr,
      });
    }
  };

  const renderControl = () => {
    let commonProp = {
      ...props.config,
      id,
    };

    if (props.config?.readOnly) {
      return (
        <DataTable
          emptyMessage={t("base_control_upload_no_file")}
          className="file-download-table"
          value={state.value}
        >
          <Column
            header={t("base_control_upload_filename")}
            body={(data) => data.name}
          />
          <Column
            className="action-column"
            header={t("base_table_action")}
            body={(data) => (
              <Button
                type="button"
                icon="pi pi-download"
                className="p-button-text download-button custom-input-action-btn"
                onClick={() => {
                  props?.onDownload?.(data);
                }}
              ></Button>
            )}
          />
        </DataTable>
      );
    }

    return (
      <div
        className={`file-upload-container p-component ${
          isError(state, props) ? "p-invalid" : ""
        }`}
      >
        <FileUpload
          files={state.value}
          onRemove={onRemove}
          onSelect={(e: any) => {
            let value = onSelect(e);
            onChange(value);
          }}
          multiple={!!props.multiple}
          onDownload={props.onDownload}
          onFileError={props.onFileError}
          {...commonProp}
        />
      </div>
    );
  };

  return (
    <>
      <div
        className={`upload-control p-field ${props.noLabel ? "no-label" : ""} ${
          isError(state, props) ? "p-invalid" : ""
        }
        `}
      >
        <label htmlFor={props.id}>
          {props.label}
          {props.required && !props.noRequiredLabel ? (
            <small className="required p-invalid">&nbsp;*</small>
          ) : null}
        </label>
        <div
          className={`p-inputgroup ${
            isError(state, props) ? "p-inputgroup-error" : ""
          }`}
        >
          {renderControl()}
          {/*aw*/}
        </div>
        {props.hintBottom && (
          <div className={"control-hint-bottom"}>{props.hintBottom}</div>
        )}
        {renderError(state, props, t)}
      </div>
    </>
  );
};

UploadControl.defaultProps = {};

export default UploadControl;
