import { Button } from "primereact/button";
import { useCallback, useRef, useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  BIOMETRIC,
  BIOMETRIC_ACTION,
  BIOMETRIC_STATUS,
  LOGIN_PATH,
} from "../../../../../../constants";
import {
  closeLoading,
  openLoading,
} from "../../../../../../redux/actions/modal";
import { store } from "../../../../../../redux/store";
import { AuthenticationService } from "../../../../../../services/hrmnet-api";
import { ActiveSessionDialog } from "../../login-load-info";
import { OnLogin } from "../utils";
import "./biometric-login.scss";

const BiometricLogin = () => {
  const biometricAuthEvent = "message";
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();
  const initState = {
    isError: true,
    isMessageListenerAdded: false,
  };
  const [state, setState] = useState(initState);
  const biometricHandlerRef = useRef(BiometricHandler);

  const changeSession = useCallback(async (confirm, payload) => {
    if (confirm) {
      try {
        setLoading(true);
        const cmd =
          AuthenticationService.authenticationAuthenticateByBiometricToken({
            body: { token: payload },
          });
        const [res] = await Promise.all([cmd]);

        if (res.data && res.data?.token) {
          await OnLogin(res, history);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
  }, []);

  async function BiometricLogin(payload) {
    //check active session
    await ActiveSessionDialog(store, t, history, changeSession, payload);
  }

  function BiometricPostMessage(action) {
    const webView = window.ReactNativeWebView;
    const msg = {
      type: BIOMETRIC,
      action: action,
    };
    webView.postMessage(JSON.stringify(msg));
  }

  async function BiometricHandler(msg) {
    var status = JSON.parse(msg.data).status;
    switch (status) {
      case BIOMETRIC_STATUS.BIOMETRIC_AUTH_SUPPORT:
        BiometricPostMessage(BIOMETRIC_ACTION.BIOMETRIC_AUTH_KEY_EXIST_REQUEST);
        break;

      case BIOMETRIC_STATUS.BIOMETRIC_AUTH_NOT_REGISTER:
        setState({ ...state, isError: true, isMessageListenerAdded: true });
        setLoading(false);
        break;

      case BIOMETRIC_STATUS.BIOMETRIC_AUTH_FAIL:
        setState({ ...state, isError: false, isMessageListenerAdded: true });
        setLoading(false);
        break;

      case BIOMETRIC_STATUS.BIOMETRIC_AUTH_SUCCESS:
        setState({ ...state, isError: false, isMessageListenerAdded: true });
        await BiometricLogin(JSON.parse(msg.data).payload);
        break;

      case BIOMETRIC_STATUS.BIOMETRIC_AUTH_NOT_SUPPORT:
        setState({ ...state, isError: true, isMessageListenerAdded: true });
        setLoading(false);
        break;

      case BIOMETRIC_STATUS.BIOMETRIC_AUTH_REGISTERED:
        setState({ ...state, isError: false, isMessageListenerAdded: true });
        setLoading(false);
        break;

      default:
        setLoading(false);
        break;
    }
  }

  useEffect(() => {
    biometricHandlerRef.current = BiometricHandler;
  }, [BiometricHandler]);

  useEffect(() => {
    const func = (evt) => biometricHandlerRef.current(evt);
    const init = () => {
      BiometricPostMessage(BIOMETRIC_ACTION.BIOMETRIC_SUPPORT_CHECK_REQUEST);
      document.addEventListener(biometricAuthEvent, func); //android
      window.addEventListener(biometricAuthEvent, func); //ios
    };

    const webView = window.ReactNativeWebView;
    if (
      webView &&
      !state.isMessageListenerAdded &&
      document.location.pathname.split("/").includes(LOGIN_PATH.LOGIN)
    ) {
      init();
    }

    return () => {
      const handleCleanUp = () => {
        setState({ ...initState, isError: undefined });
      };
      document.removeEventListener(biometricAuthEvent, func); //android
      window.removeEventListener(biometricAuthEvent, func); //ios
      handleCleanUp();
      setLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBiometricAuth = () => {
    BiometricPostMessage(BIOMETRIC_ACTION.BIOMETRIC_AUTH_REQUEST);
  };

  function setLoading(isLoading) {
    if (isLoading) {
      dispatch(openLoading());
      return;
    }
    dispatch(closeLoading());
  }

  return (
    <>
      {state.isError ? (
        <p>{t("login_biometric_error")}</p>
      ) : (
        <Button className="biometric-button" onClick={() => onBiometricAuth()}>
          <span className="p-button-label p-c">{t("login_login")}</span>
        </Button>
      )}
    </>
  );
};

export default BiometricLogin;
