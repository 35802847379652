import { SET_USER_INFO } from "../reduxConstants";
const initialState = {};

export default function user(state = initialState, action) {
  switch (action.type) {
    case SET_USER_INFO: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
