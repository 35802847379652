import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import {
  EmployeeDataAmendmentApiSteps,
  EmployeeDataAmendmentConfiguration,
  EmployeeDataAmendmentConfigurationFunction,
} from "./default-section-fields";
import { scrollToErrorControl } from "../../../../components/base-form/utils";
import { showSpinner } from "../../../../redux/actions/spinner";
import { getResponseMessage, isResponseOk } from "../../../../utils/utils";
import {
  showErrorMessageModal,
  showTimestampToastSuccess,
} from "../../../../services/utils/message";
import {
  EmployeeDetailsService,
  MasterService,
} from "../../../../services/hrmnet-api";
import { Skeleton } from "primereact/skeleton";
import BaseMultiStepForm from "../../../../components/base-multi-step-form/base-multi-step-form";
import { BaseMultiStepFormSkeleton } from "../../../../components/base-multi-step-form/base-multi-step-form-skeleton";
import {
  BaseMultiStepConfigToFormModel,
  BaseMultiStepConfigToStepNavModel,
  BaseMultiStepCustomFunctionLibrary,
  BaseMultiStepFormInitFormValue,
} from "../../../../components/base-multi-step-form/base-multi-step-form-utils";
import BaseMultiSteps from "../../../../components/base-multi-step-form/base-multi-steps";
import { BaseMultiStepFormScrollToMultiStepFormMenuActiveItem } from "../../../../components/base-multi-step-form/base-multi-step-form-scroll";
import {
  setFormLanguage,
  setMainForm,
  setMainFormConfig,
  setMainFormSectionConfig,
} from "../../../../redux/actions/form";

//setRentalForm -> setAdminForm

const EmployeeDataAmendmentForm = ({ isLangChange, employeeCode }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    isLoading: true,
    activeStep: 0,
    formResp: {},
    formName: "",
    touched: false,
  });

  const form = useSelector((state) => state.commonForm.form);
  const setForm = (x) => dispatch(setMainForm(x));
  const formConfig = useSelector((state) => state.commonForm.formConfig);
  const setFormConfig = (x) => dispatch(setMainFormConfig(x));
  const setFormSectionConfig = (x) => dispatch(setMainFormSectionConfig(x));

  const formObjRef = React.createRef();

  const customFunctionLibrary = BaseMultiStepCustomFunctionLibrary({
    dispatch,
  });

  const selectedLangKey = useSelector(
    (state) => state.language.language?.selectedLang?.key
  );

  useEffect(() => {
    const loadForm = async (employeeCode) => {
      const applicationId = employeeCode;
      dispatch(setFormLanguage(selectedLangKey));

      //enum
      const api2 = MasterService.masterGetDepartmentList();
      let [departmentListRes] = await Promise.all([api2]);
      let stepRes = EmployeeDataAmendmentApiSteps(t);
      let formConfigRes = EmployeeDataAmendmentConfiguration(t);

      let stepList = [];
      let newForm = {};
      let newFormResp = {};
      let newFormConfig = {};
      let formName;
      let activeStep = 0;

      if (stepRes && stepRes.data && formConfigRes && formConfigRes.data) {
        //partial hardcore, find ways to completely detach from code
        let basicDetailsSection = formConfigRes.data.sections.filter(
          (x) => x.id === 200
        );
        if (
          departmentListRes &&
          departmentListRes.length > 0 &&
          basicDetailsSection.length === 1
        ) {
          const departmentFieldIndex = basicDetailsSection[0].fields.findIndex(
            (x) => x.id === 4
          );
          if (departmentFieldIndex > -1) {
            basicDetailsSection[0].fields[
              departmentFieldIndex
            ].dropdownOptions = departmentListRes.map((data) => {
              return {
                name: data.name,
                description: data.name,
                value: data.value,
              };
            });
          }
        }

        let customData = {
          customFunctionLibrary: customFunctionLibrary,
        };
        stepList = BaseMultiStepConfigToStepNavModel(stepRes.data);

        // formName and breadcrumb
        if (formConfigRes.data.formName) {
          formName = formConfigRes.data.formName;
        }

        // init form and active step
        if (!isLangChange) {
          const employeeDetail =
            await EmployeeDetailsService.employeeGetEmployeeDetails({
              employeeCode: employeeCode,
            });
          if (employeeDetail && employeeDetail.data) {
            newForm = BaseMultiStepFormInitFormValue(
              EmployeeDataAmendmentConfigurationFunction(t, employeeDetail.data)
                .data.sections
            );
          } else
            newForm = BaseMultiStepFormInitFormValue(
              formConfigRes.data.sections
            );
        } else {
          newForm = { ...form };
          activeStep = state.activeStep;
        }

        // newFormResp and formConfig
        newFormResp = formConfigRes.data;
        newFormConfig = BaseMultiStepConfigToFormModel({
          applicationId: applicationId,
          stepConfig: stepRes.data,
          sectionFieldConfig: formConfigRes.data.sections,
          customData: customData,
          t: t,
          form: newForm,
        });
      }

      setForm(newForm);
      setFormConfig(newFormConfig);
      setFormSectionConfig(newFormResp);

      setState({
        ...state,
        formName,
        stepList,
        activeStep,
        isLoading: false,
      });
    };

    loadForm(employeeCode);

    BaseMultiStepFormScrollToMultiStepFormMenuActiveItem(
      ".employee-data-amendment-form-title"
    );

    return () => {
      setState({
        ...state,
        isLoading: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeCode]);

  // Navigation and action
  const validateCurrentStep = () => {
    const isValid = !formObjRef.current.getActiveFormState({ expand: true })
      .invalid;
    setTimeout(() => {
      scrollToErrorControl();
    }, 100);
    return isValid;
  };

  const confirmSubmitApplication = async () => {
    if (validateCurrentStep()) {
      submitApplicationAction();
    } else {
      setState({
        ...state,
        touched: true,
      });
    }
  };

  const submitApplicationAction = async () => {
    try {
      // show spinner
      dispatch(showSpinner(true));
      let res = await EmployeeDetailsService.employeeUpdateEmployee({
        body: form,
      });
      if (isResponseOk(res)) {
        showTimestampToastSuccess({
          message: t("employee_data_amendment_successMessageSubmit"),
          t: t,
        });
      } else {
        showErrorMessageModal({
          dispatch,
          t,
          content: getResponseMessage(res),
        });
      }
    } catch (e) {
      // show notification?
      console.error(e.message);
    } finally {
      // end spinner
      dispatch(showSpinner(false));
    }
  };

  // Rendering

  const renderForm = () => (
    <BaseMultiStepForm
      form={form}
      activeStep={state.activeStep}
      config={formConfig}
      ref={formObjRef}
      touched={state.touched}
    />
  );

  const renderFooter = () => {
    return (
      <div className="footer p-grid p-align-center p-justify-between">
        <div></div>
        <div className="right-button-group">
          <Button
            onClick={() => confirmSubmitApplication()}
            className="p-button next-button"
          >
            {t("admin_common_actionSubmit")}
          </Button>
        </div>
      </div>
    );
  };

  const renderStepSkeleton = () => (
    <div className="p-grid p-my-6">
      <Skeleton height="20px"></Skeleton>
    </div>
  );

  return (
    <>
      <div className="admin-form-style">
        <div className="header header-sticky">
          <BaseMultiSteps
            className="steps"
            model={state.stepList}
            activeIndex={state.activeStep}
            style={{
              display: `${formConfig?.steps?.length > 1 ? "block" : "none"}`,
            }}
          />
        </div>

        {state.isLoading ? (
          <div className="form-loading">{BaseMultiStepFormSkeleton}</div>
        ) : (
          renderForm()
        )}

        {/* Footer */}
        {renderFooter()}
      </div>
    </>
  );
};

export default EmployeeDataAmendmentForm;
