import "./list.scss";

//components
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../../../redux/actions/modal";
import { resetBcDynamicPaths } from "../../../../../redux/actions/breadcrumb";
import { showSpinner } from "../../../../../redux/actions/spinner";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import {
  getColumModel,
  DEFAULT_BT_SEARCH,
  getBTConfig,
} from "./../../../../../components/base-table/base-table-model";
import BaseTable from "../../../../../components/base-table/base-table-portal-rental";
import { NwowStatus, NwowMenu } from "../../components";
import { formSkeleton } from "../../utils";
import { isResponseOk } from "../../../../../utils/utils";
import {
  // showErrorMessageModal,
  showTimestampToastSuccess,
} from "../../../../../services/utils/message";
// import { getResponseMessage } from "./../../../../../utils/utils";
import {
  EEPORTAL_LABEL_BASE_TABLE,
  FUNCTION_CODE,
  NWOW_SUBMODULE_CODE,
} from "../../../../../constants";
import {
  NWOW_T_CONTEXT_KEY,
  NWOW_MENU_MODULE_KEY,
  NWOW_MENU_TYPE_KEY,
  NWOW_MENU_PAGE_KEY,
} from "../../constants/contants";

// services
import { NwowService } from "../../../../../services/hrmnet-api";
import LanguageLoader from "../../../../../components/language-loader/language-loader";
import { PortalLink } from "../../../../../services/utils";

const DATE_FORMAT = "yyyy-MM-DD (ddd)";
const DATE_FORMAT_MOBILE = "yyyy-MM-DD";
const TIME_FORMAT = "h:mm A";
const DATETIME_FORMAT = "yyyy-MM-DD HH:mm";

const NwowApplicationList = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { t } = useTranslation();
  const tableRef = useRef();

  const menus = useSelector((state) => state.menu.sideBar);
  const nwowMenu = menus.find((x) => x.key === NWOW_MENU_MODULE_KEY)?.subMenus;

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingData, setIsLoadingData] = useState(true);
  // const [initData, setInitData] = useState(true);
  const [data, setData] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const [filterYearList, setFilterYearList] = useState([]);
  const [filterYear, setFilterYear] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  const remarksRef = useRef();
  const updateRemarks = (value) => {
    remarksRef.current = value;
  };

  useEffect(() => {
    dispatch(resetBcDynamicPaths());
  }, [dispatch]);

  // init
  useEffect(() => {
    loadData();
    // scroll to section
    let element = document.querySelector(".nwow-menu");
    element?.scrollIntoView({ behavior: "smooth", block: "center" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    setIsLoading(true);
    var cmd = await NwowService.nwowGetApplicationList({});
    let [res] = await Promise.all([cmd]);

    // mocked data
    // res = sampleApplicationList;
    if (res && res.data) {
      const resData = res.data.map((d) => {
        return {
          ...d,
          nwowFromDate:
            d.nwowFrom && moment(d.nwowForm).isValid()
              ? moment(d.nwowFrom).format(DATE_FORMAT).toUpperCase()
              : d.nwowForm,
          nwowFromTime:
            d.nwowFrom && moment(d.nwowForm).isValid()
              ? moment(d.nwowFrom).format(TIME_FORMAT)
              : d.nwowForm,
          nwowToDate:
            d.nwowTo && moment(d.nwowTo).isValid()
              ? moment(d.nwowTo).format(DATE_FORMAT).toUpperCase()
              : d.nwowTo,
          nwowToTime:
            d.nwowTo && moment(d.nwowTo).isValid()
              ? moment(d.nwowTo).format(TIME_FORMAT)
              : d.nwowTo,
          nwowFromTo:
            d.nwowFrom &&
            moment(d.nwowForm).isValid() &&
            d.nwowTo &&
            moment(d.nwowTo).isValid()
              ? [
                  moment(d.nwowFrom).format(DATE_FORMAT_MOBILE).toUpperCase(),
                  " - ",
                  moment(d.nwowTo).format(DATE_FORMAT_MOBILE).toUpperCase(),
                ].join("")
              : "",
          submissionDateDisplay:
            d.submissionDate && moment(d.submissionDate).isValid()
              ? moment(d.submissionDate).format(DATETIME_FORMAT)
              : d.submissionDate,
        };
      });

      // filter year
      const years = new Set();
      resData.forEach((d) => {
        if (!d?.nwowYear) {
          d.nwowYear = "1";
        }
        years.add(d.nwowYear);
      });
      const yearArray = Array.from(years).sort((a, b) => b - a);
      const yearList = yearArray.reduce((a, y) => {
        a.push({
          label: y,
          value: Number(y),
        });
        return a;
      }, []);
      setFilterYearList(yearList);
      const targetYear = yearList.length > 0 ? yearList[0].value : null;
      setFilterYear(targetYear);

      setData(resData);
      setFilterDataForDisplay(resData, targetYear);
      setIsLoading(false);
    }
  };

  const setFilterDataForDisplay = (dataSource, targetYear) => {
    setIsLoadingData(true);
    const dataList = dataSource ? dataSource : data;
    const dataYear = targetYear ? targetYear : filterYear;
    const filteredData = dataList.filter(
      (d) => Number(d.nwowYear) === dataYear
    );
    setFilteredData(filteredData);
  };

  useEffect(() => {
    setFilterDataForDisplay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterYear]);

  useEffect(() => {
    setIsLoadingData(false);
  }, [filteredData]);

  // confirm
  const deleteNwow = async (record) => {
    try {
      // show spinner
      dispatch(showSpinner(true));
      var cmd = await NwowService.nwowDeleteNwowDraftApplication({
        applicationId: record.applicationId,
      });
      let [res] = await Promise.all([cmd]);
      if (isResponseOk(res)) {
        showTimestampToastSuccess({
          message: "Success",
          t: t,
        });
        loadData();
      }
    } catch (e) {
      // error
    } finally {
      // end spinner
      dispatch(showSpinner(false));
    }
  };

  const cancelNwow = async (record) => {
    try {
      // show spinner
      dispatch(showSpinner(true));
      var cmd = await NwowService.nwowCancelNwowApplication({
        applicationId: record.applicationId,
        remarks: remarksRef.current,
      });
      let [res] = await Promise.all([cmd]);
      if (isResponseOk(res)) {
        showTimestampToastSuccess({
          message: "Success",
          t: t,
        });
        loadData();
      }
    } catch (e) {
      // error
    } finally {
      // end spinner
      dispatch(showSpinner(false));
    }
  };

  const confirmNwowAction = (action, record) => {
    updateRemarks("");
    const nwowFromDate =
      record.nwowFrom && moment(record.nwowForm).isValid()
        ? moment(record.nwowFrom).format(DATE_FORMAT).toUpperCase()
        : record.nwowForm;

    const modelTitle =
      action === "delete"
        ? t("Nwow_applicationList_deleteModalTitle")
        : action === "cancel"
        ? t("Nwow_applicationList_cancelModalTitle")
        : "";
    const modelContent =
      action === "delete"
        ? t("Nwow_applicationList_deleteModalContent", {
            nwowFromDate: nwowFromDate,
          })
        : action === "cancel"
        ? t("Nwow_applicationList_cancelModalContent", {
            nwowFromDate: nwowFromDate,
          })
        : "";

    dispatch(
      openModal({
        title: modelTitle,
        renderModalBody: () => (
          <div className="finalize-claim-modal">
            <div>{modelContent}</div>
            {action === "cancel" ? (
              <>
                <label>{t("Nwow_applicationList_cancelModalRemarks")}</label>
                <InputText onChange={(e) => updateRemarks(e.target.value)} />
              </>
            ) : null}
          </div>
        ),
        classNameMainDialog: "confirm-message-modal",
        primaryButtonText: t("Nwow_common_actionConfirm"),
        //primaryButtonClickFn: async ({ closeFn }) => {
        primaryButtonClickFn: ({ closeFn }) => {
          closeFn();
          if (action === "delete") {
            deleteNwow(record);
          }
          if (action === "cancel") {
            cancelNwow(record);
          }
        },
        secondButtonClickFn: ({ closeFn }) => {
          closeFn();
        },
      })
    );
  };

  // redirect
  const redirectAction = (actionType, isAccrual, applicationId) => {
    let path = null;
    // if (transactionType?.toLowerCase() !== "preapproval nwow entitlement") {
    if (!isAccrual) {
      if (actionType === "edit") {
        path = PortalLink(
          `${FUNCTION_CODE.Nwow}/${NWOW_SUBMODULE_CODE.APPLICATION}/${applicationId}/${NWOW_SUBMODULE_CODE.EDIT}`
        );
      } else if (actionType === "view") {
        path = PortalLink(
          `${FUNCTION_CODE.Nwow}/${NWOW_SUBMODULE_CODE.APPLICATION}/${applicationId}`
        );
      } else if (actionType === "change") {
        path = PortalLink(
          `${FUNCTION_CODE.Nwow}/${NWOW_SUBMODULE_CODE.APPLICATION}/${applicationId}/${NWOW_SUBMODULE_CODE.CHANGE}`
        );
      }
    }

    if (path) {
      history.push(path);
    }
  };

  // table
  const tableGetData = async () => {
    if (isLoading) {
      return new Promise(() => {});
    } else {
      return {
        datas: filteredData,
        searchConfig: BT_SEARCH,
        total: filteredData.length,
      };
    }
  };
  const BT_SEARCH = {
    ...DEFAULT_BT_SEARCH,
    sortObj: {
      // key: "applicationId",
      key: "submissionDate",
      direction: -1,
    },
  };

  const configColumnNwowType = getColumModel({
    header: t("Nwow_applicationList_NwowType"),
    key: "nwowType",
    dataType: "string",
    sortable: true,
    width: 12,
  });

  const configColumnCategory = getColumModel({
    header: t("Nwow_applicationList_nwowCategory"),
    key: "nwowCategory",
    dataType: "string",
    sortable: true,
    width: 12,
  });

  const configColumnCompany = getColumModel({
    header: t("Nwow_applicationList_Company"),
    key: "companyName",
    dataType: "string",
    sortable: true,
    width: 12,
  });

  const configColumnTransactionType = getColumModel({
    header: t("Nwow_applicationList_transactionType"),
    key: "transactionType",
    dataType: "string",
    sortable: true,
    hideInMobile: true,
  });
  const configColumnNwowFromToDate = getColumModel({
    header: t("Nwow_applicationList_nwowPeriod"),
    key: "nwowFromTo",
    dataType: "string",
    sortable: true,
    width: 0.1,
    render: (data) => (
      <>
        <NwowStatus status={data.status} data={data} showText={false} />
        <span>{data.nwowFromTo}</span>
      </>
    ),
  });
  const configColumnNwowFromDate = getColumModel({
    header: t("Nwow_applicationList_from"),
    key: "nwowFrom",
    dataType: "string",
    sortable: true,
    width: 11,
    hideInMobile: true,
    render: (data) =>
      data.nwowFrom && moment(data.nwowForm).isValid()
        ? moment(data.nwowFrom).format(DATE_FORMAT).toUpperCase()
        : data.nwowForm,
  });
  const configColumnNwowFromTime = getColumModel({
    header: t("Nwow_applicationList_startTime"),
    key: "nwowFrom",
    dataType: "string",
    sortable: false,
    width: 7,
    hideInMobile: true,
    render: (data) =>
      data.nwowFrom && moment(data.nwowForm).isValid()
        ? moment(data.nwowFrom).format(TIME_FORMAT)
        : data.nwowForm,
  });
  const configColumnNwowFromDateTime = getColumModel({
    header: t("Nwow_applicationList_from"),
    key: "nwowFromDatetime",
    dataType: "string",
    sortable: true,
    width: 0.1,
    render: (data) =>
      data.nwowFrom && moment(data.nwowForm).isValid()
        ? moment(data.nwowFrom).format(DATETIME_FORMAT)
        : data.nwowForm,
  });
  const configColumnNwowToDate = getColumModel({
    header: t("Nwow_applicationList_to"),
    key: "nwowTo",
    dataType: "string",
    sortable: true,
    width: 11,
    hideInMobile: true,
    render: (data) =>
      data.nwowTo && moment(data.nwowTo).isValid()
        ? moment(data.nwowTo).format(DATE_FORMAT).toUpperCase()
        : data.nwowTo,
  });
  const configColumnNwowToTime = getColumModel({
    header: t("Nwow_applicationList_endTime"),
    key: "nwowTo",
    dataType: "string",
    sortable: false,
    width: 7,
    hideInMobile: true,
    render: (data) =>
      data.nwowTo && moment(data.nwowTo).isValid()
        ? moment(data.nwowTo).format(TIME_FORMAT)
        : data.nwowTo,
  });
  const configColumnNwowToDateTime = getColumModel({
    header: t("Nwow_applicationList_to"),
    key: "nwowFromDatetime",
    dataType: "string",
    sortable: true,
    width: 0.1,
    render: (data) =>
      data.nwowTo && moment(data.nwowTo).isValid()
        ? moment(data.nwowTo).format(DATETIME_FORMAT)
        : data.nwowTo,
  });
  const configColumnUnit = getColumModel({
    header: t("Nwow_applicationList_units"),
    key: "unit",
    dataType: "string",
    sortable: false,
    width: 7,
    hideInMobile: true,
  });
  const configColumnStatus = getColumModel({
    header: t("Nwow_applicationList_status"),
    key: "status",
    dataType: "string",
    sortable: true,
    width: 12,
    hideInMobile: true,
    render: (data) => <NwowStatus status={data.status} data={data} />,
  });
  const configColumnSubmissionDate = getColumModel({
    header: t("Nwow_applicationList_submissionDate"),
    key: "submissionDate",
    dataType: "string",
    sortable: true,
    hideInMobile: true,
    render: (data) =>
      data.submissionDate && moment(data.submissionDate).isValid()
        ? moment(data.submissionDate).format(DATETIME_FORMAT)
        : data.submissionDate,
  });
  const configColumnReferenceNo = getColumModel({
    header: t("Nwow_applicationList_refNo"),
    key: "referenceNo",
    dataType: "string",
    sortable: true,
    hideInMobile: true,
  });
  const configColumnDay = getColumModel({
    header: t("Nwow_applicationList_day"),
    key: "day",
    dataType: "string",
    sortable: true,
    hideInMobile: true,
  });
  const configColumnPlace = getColumModel({
    header: t("Nwow_applicationList_place"),
    key: "place",
    dataType: "string",
    sortable: true,
    hideInMobile: true,
  });
  const configColumnWeekDays = getColumModel({
    header: t("Nwow_applicationList_weekDays"),
    key: "weekDays",
    dataType: "string",
    sortable: true,
    hideInMobile: true,
  });
  const configColumnAddress = getColumModel({
    header: t("Nwow_applicationList_address"),
    key: "address",
    dataType: "string",
    sortable: true,
    hideInMobile: true,
  });
  const configColumnContact = getColumModel({
    header: t("Nwow_applicationList_contact"),
    key: "contact",
    dataType: "string",
    sortable: true,
    hideInMobile: true,
  });

  const configColumns = [
    configColumnCompany,
    configColumnCategory,
    configColumnNwowType,
    configColumnTransactionType,
    // configColumnNwowFromToDate,
    configColumnNwowFromDate,
    //configColumnNwowFromTime,
    configColumnNwowToDate,
    //configColumnNwowToTime,
    configColumnUnit,
    configColumnStatus,
    configColumnSubmissionDate,
    configColumnDay,
    configColumnPlace,
    configColumnWeekDays,
    configColumnAddress,
    configColumnContact,
    configColumnReferenceNo,
    getColumModel({
      header: "",
      key: "",
      dataType: "string",
      sortable: false,
      width: 0.1,
      frozen: true,
      hideInMobile: true,
    }),
  ];

  const customToolbar = (
    <div className="bt-toolbar-content">
      <b>{t("Nwow_applicationList_currentYear")}: </b>
      {/* {moment().format("yyyy")} */}
      <div className="filter-year-container">
        <Dropdown
          value={filterYear}
          options={filterYearList}
          onChange={(e) => {
            setFilterYear(e.value);
          }}
        />
      </div>
    </div>
  );

  const customToolbarAction = (
    <div className="bt-action">
      <Button
        className="bt-calendarbtn"
        icon="pi pi-calendar"
        tooltipOptions={{ position: "top" }}
        tooltip={t("Nwow_applicationList_calendarView")}
        onClick={() => {
          history.push(
            PortalLink(
              `${FUNCTION_CODE.Nwow}/${NWOW_SUBMODULE_CODE.CALENDAR_VIEW}`
            )
          );
        }}
      />
    </div>
  );

  const configModel = getBTConfig({
    columns: configColumns,
    hasIndex: false,
    mode: "list",
    defaultMode: "list",
    showGlobal: true,
    hasColumnSelector: false,
    rowHover: true,
    responsive: true,
    onRowClick: (e) => {
      const classString = e.originalEvent.target.classList.value;
      if (
        !(
          classString.includes("action-column") ||
          classString.includes("p-button-icon") ||
          classString.includes("p-menu") ||
          classString.includes("p-menuitem-text")
        )
      ) {
        if (e.data.actions.includes("Edit Application")) {
          redirectAction("edit", e.data.isAccrual, e.data.applicationId);
        } else {
          redirectAction("view", e.data.isAccrual, e.data.applicationId);
        }
      }
    },
    actionWidth: 5.3,
    actionsSingle: [
      {
        isOverflow: true,
        name: "View Application",
        title: t("Nwow_applicationList_action_viewApplication"),
        clickFn: (data) =>
          redirectAction("view", data.isAccrual, data.applicationId),
      },
      {
        // sample action
        isOverflow: true,
        name: "View Application Details",
        title: t("Nwow_applicationList_action_viewApplicationDetails"),
        clickFn: (data) =>
          redirectAction("view", data.isAccrual, data.applicationId),
      },
      {
        isOverflow: true,
        name: "Edit Application",
        title: t("Nwow_applicationList_action_editApplication"),
        clickFn: (data) =>
          redirectAction("edit", data.isAccrual, data.applicationId),
      },
      {
        isOverflow: true,
        name: "Delete Draft",
        title: t("Nwow_applicationList_action_deleteDraft"),
        clickFn: (data) => {
          confirmNwowAction("delete", data);
        },
      },
      {
        isOverflow: true,
        name: "Change Application",
        title: t("Nwow_applicationList_action_changeApplication"),
        clickFn: (data) =>
          redirectAction("change", data.isAccrual, data.applicationId),
      },
      {
        isOverflow: true,
        name: "Cancel Application",
        title: t("Nwow_applicationList_action_cancelApplication"),
        clickFn: (data) => {
          confirmNwowAction("cancel", data);
        },
      },
    ],
    showActionsSingle: (action, actionsList) => {
      if (!action || !actionsList) return false;
      return actionsList.includes(action.title);
    },
    customToolbar: customToolbar,
    customToolbarAction: customToolbarAction,
    isExpandable: true,
    isExpandInMobileOnly: true,
    expandedRows: expandedRows,
    onRowToggle: (e) => {
      setExpandedRows(e.data);
    },
    rowExpansionTemplate: (data) => {
      let _data = [];
      _data.push(data);
      const columns = [
        configColumnSubmissionDate,
        configColumnStatus,
        configColumnUnit,
        configColumnNwowType,
        configColumnCompany,
        configColumnCategory,
        configColumnTransactionType,
        configColumnNwowFromDateTime,
        configColumnNwowToDateTime,
      ];

      const renderTableCell = (rowData, column) => {
        if (column.render) {
          return <div className="bt-cell-render">{column.render(rowData)}</div>;
        } else {
          return rowData[column.key];
        }
      };

      const columnsRender = [];
      columns.forEach((column, index) => {
        const columnConfig = {
          key: `bt-column-${index}`,
          field: column.key,
          header: column.header,
          className: `bt-${column.key}`,
          body: (rowData, config) => {
            return (
              <div className={`bt-row bt-row-${config.rowIndex} bt-cell`}>
                <span className="p-column-title mobile-p-column-title">
                  {column.header}:
                </span>
                <div className="bt-cell-value">
                  {renderTableCell(rowData, column)}
                </div>
              </div>
            );
          },
        };
        columnsRender.push(<Column {...columnConfig} />);
      });

      return (
        <DataTable
          key="info-table"
          value={_data}
          className="p-datatable-responsive table-expandable-header nwow-application-list-mobile"
        >
          {columnsRender}
        </DataTable>
      );
    },
  });

  // render
  const renderFormSkeleton = () => formSkeleton;

  const renderDropdown = () => {
    const nwowMenu = menus.find((x) => x.key === NWOW_MENU_MODULE_KEY);
    if (nwowMenu) {
      return (
        <Dropdown
          className="nav-dropdown"
          options={nwowMenu.subMenus.filter(
            (s) =>
              s.type === NWOW_MENU_TYPE_KEY.MENU &&
              s.key !== NWOW_MENU_PAGE_KEY.NWOW_APPLICATIONS
          )}
          optionLabel="name"
          optionValue="url"
          onChange={(e) => history.push(e.value)}
          placeholder={t("Nwow_applicationList_pleaseSelectNav")}
        />
      );
    } else {
      return null;
    }
  };

  const renderNwowApplicationList = () => (
    <div className="nwow-application-list-datatable">
      <BaseTable
        ref={tableRef}
        isClientSize={true}
        configModel={configModel}
        searchConfig={BT_SEARCH}
        searchFn={tableGetData}
        // disableMobile={true}
      />
    </div>
  );

  return (
    <>
      <LanguageLoader
        contexts={[
          EEPORTAL_LABEL_BASE_TABLE.BASE_TABLE,
          NWOW_T_CONTEXT_KEY.COMMON,
          NWOW_T_CONTEXT_KEY.LIST,
        ]}
      />

      <div className="nwow-container nwow-application-list">
        <div className="header">
          <div className="title">
            {t("Nwow_applicationList_title")}
            <div className="title-desc">{t("Nwow_applicationList_desc")}</div>
          </div>
          <NwowMenu
            menu={nwowMenu}
            currentPageKey={NWOW_MENU_PAGE_KEY.NWOW_APPLICATIONS}
          />
          {renderDropdown()}
        </div>
        <div className="main">
          {/* Footer */}
          {isLoading || isLoadingData
            ? renderFormSkeleton()
            : renderNwowApplicationList()}
        </div>
      </div>
    </>
  );
};

export default NwowApplicationList;
