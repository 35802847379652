import "./steps.scss";
import { Steps as PrimeSteps } from "primereact/steps";

const Steps = ({ className, ...props }) => {
  return (
    <PrimeSteps
      className={`custom-steps ${className ? className : ""}`}
      {...props}
    />
  );
};

export default Steps;
