import { BCProps } from "../base-control/base-control";
import { BFProps } from "../base-form/base-form-model";
import { sleep } from "./../../utils/utils";

export interface BaseTableProps {
  configModel: BTConfigModel;
  dataModel: BTDataModel;
}
/**
 * Base table config model
 */
export interface BTConfigModel {
  /**
   * Config search form
   */
  searchForm?: BFProps;

  /**
   * Config column
   */
  columns?: BTColumnModel[];
  /**
   * Config select page size. Eg [10,20,100]
   */
  pageSizes?: number[];
  /**
   * Action base of table not required row. Eg Add, Import, Export.
   */
  actionsSelf?: BTActionSelfModel[];
  /**
   * Action for each row. Eg Edit an record, Delete an record
   */
  actionsSingle?: BTActionSingleModel[];
  /**
   * Action for number of selected row. Eg Delete selected record, Download batch ...
   */
  actionsMulti?: BTActionMultiModel[];
  /**
   * Action for number of selected row. Eg Delete selected record, Download batch ...
   */
  actionsHead?: BTActionMultiModel[];
  /**
   * Size of action bar in rem
   * @default 8
   */
  actionWidth?: number;
  /**
   * Condition if has No. Column
   */
  hasIndex?: boolean;
  /**
   * Condition if has No. Column
   */
  hasSelection?: boolean;
  /**
   * Default mode of screen
   */
  defaultMode?: "grid" | "list";
  /**
   * Mode of screen
   */
  mode?: "grid" | "list" | "both";
  /**
   * Tile of table
   */
  title?: string;
  /**
   * Description of table
   */
  description?: string;

  /**
   * Direct apply style to table
   */
  style?: any;
  /**
   * Condition if have column visble selector
   */
  hasColumnSelector?: boolean;
  /**
   * Head of tale
   */
  tableHeight?: string;
  /**
   * Extra config for table
   */
  tableConfig?: any;
  /**
   * Show global search
   */
  showGlobal?: boolean;
  /**
   * Show global search
   */
  gridRender?: (data: any) => any;
  /**
   * Show paginator
   */
  paginator?: boolean;
  /**
   * Condition can check box
   */
  // checkboxIf?: (data: any, index?: number) => boolean;
  /**
   * For Base Table
   * On Row Selection Change
   * Assign the Row Value to the Current Row Selection
   */
  onBaseSelectionChange?: any;
  /**
   * For Base Table
   * On Row Selected, Hide Toolbar
   * But Still Show Filter Button
   */
  hideItemSelectedToolbar?: boolean;
  /**
   * For BaseTable
   * If onBaseTableSingleRowSelection is true,
   * Then Assign state.selectedFullRecord to selection,
   * Else Run the Default Behavior.
   */
  onBaseTableSingleRowSelection?: boolean;

  sortField?: string;
  groupRowsBy?: string;
}
/**
 * Config for data
 */
export interface BTDataModel {
  /**
   * search Config
   */
  searchConfig?: BTSearchModel;
  /**
   * Search function
   * @requires
   */
  searchFn?: (searchConfig: BTSearchModel) => Promise<BTDataModel>;
  /**
   * Condition if using client side
   */
  isClientSize?: boolean;
}
/**
 * Config for data
 */
export interface BTSearchModel {
  /**
   * Page index
   */
  pi?: number;
  /**
   * Page size
   */
  ps?: number;
  /**
   * Sort
   */
  sort?: BTSortModel;

  /**
   * Filter text
   */
  filterText?: string;
  /**
   * Data for advand search form
   */
  searchFormData?: any[];
}
export interface BTSortModel {
  /**
   * Sort key
   */
  key: string;
  /**
   * Sort direction
   */
  direction: "NONE" | "ASC" | "DESC";
}

/**
 * Config for action
 */
export interface BTActionBaseModel {
  /**
   * Icon display
   */
  renderIcon?: string;
  /**
   * Title
   */
  title?: string;
  /**
   * Description
   */
  description?: string;
  /**
   * ClassName
   */
  className?: string;
  /**
   * Kind
   */
  kind?: string;
  /**
   * Is overflow
   */
  isOverflow?: string;
}
/**
 * Config for actions
 */
export interface BTActionHeadModel extends BTActionBaseModel {
  /**
   * action when clicked
   */
  clickFn?: (data: any, index?: number) => any;
}
/**
 * Config for actions
 */
export interface BTActionSelfModel extends BTActionBaseModel {
  /**
   * action when clicked
   */
  clickFn?: (data: any, index?: number) => any;
}
export interface BTActionSingleModel extends BTActionBaseModel {
  /**
   * action when clicked
   */
  clickFn?: (data: any, index?: number) => any;
  /**
   * Condition display
   */
  if?: (data: any, index?: number) => boolean;
}
export interface BTActionMultiModel extends BTActionBaseModel {
  /**
   * Condition display
   */
  clickFn?: (selectedData: any[]) => any;
}
/**
 * Config for column
 */
export interface BTColumnModel {
  /**
   * key map with data
   */
  key?: string;

  /**
   * For
   */
  header?: string;
  /**
   * Width of column using rem
   */
  width?: number;

  /**
   * Render function
   */
  render?: (data?: any, index?: number) => any;
  /**
   * Datatype
   */
  dataType?: "date" | "boolean" | "string";
  /**
   * Can sort
   */
  sortable?: boolean;
  /**
   * Forzen column
   */
  frozen?: boolean;
  /**
   * Can filter column
   */
  filter?: boolean;
  /**
   * Can filter column
   */
  filterMatchMode?:
    | "range"
    | "startsWith"
    | "contains"
    | "endsWidth"
    | "equals"
    | "notEquals"
    | "in"
    | "lt"
    | "lte"
    | "gt"
    | "gte"
    | "custom";

  /**
   * Exclude golbal filter
   */
  excludeGlobalFilter?: boolean;

  /**
   * Is default hidden on grid
   */
  isDefaultHide?: boolean;
  /**
   * Hide if in mobile
   */
  hideMobile?: boolean;
  /**
   * Is allway on top
   */
  onTop?: boolean;

  /**
   * Control of column
   */
  control?: BCProps;
  /**
   * Extra column config
   */
  columnConfig?: any;
}

/**
 * Default data
 */
export const DEFAULT_COLUMN_ITEM: BTColumnModel = {
  // Default 10 rem
  width: 10,
  dataType: "string",
  sortable: true,
  filter: true,
};
export const DEFAULT_ACTIONS_HEAD: BTActionHeadModel[] = [];
export const DEFAULT_ACTIONS_SELF: BTActionSelfModel[] = [];
export const DEFAULT_ACTIONS_SINGLE: BTActionSingleModel[] = [];
export const DEFAULT_ACTIONS_MULTI: BTActionMultiModel[] = [];
export const DEFAULT_COLUMNS: BTColumnModel[] = [];
export const DEFAULT_DATAS: any[] = [];
export const DEFAULT_PAGESIZE: number[] = [10, 20, 30, 50, 100];
export const DEFAULT_BT_CONFIG: BTConfigModel = {
  columns: DEFAULT_COLUMNS,
  pageSizes: DEFAULT_PAGESIZE,
  actionsSelf: DEFAULT_ACTIONS_SELF,
  actionsSingle: DEFAULT_ACTIONS_SINGLE,
  actionsMulti: DEFAULT_ACTIONS_MULTI,
  actionsHead: DEFAULT_ACTIONS_HEAD,
  defaultMode: "list",
  mode: "list",
  actionWidth: 5.3,
  hasColumnSelector: false,
  hasSelection: true,
  hasIndex: false,
  showGlobal: false,
  tableHeight: "calc(100vh - 28rem)",
  paginator: true,
  style: {
    width: "100%",
    minHeight: "calc(100vh - 14rem)",
    position: "relative",
  },
};
export const DEFAULT_BT_SEARCH: BTSearchModel = {
  pi: 1,
  ps: 10,
  searchFormData: [],
};
export const DEFAULT_BT_DATA: BTDataModel = {
  searchConfig: DEFAULT_BT_SEARCH,
  searchFn: async (searchConfig: BTSearchModel) => {
    await sleep(1);
    return {
      searchConfig,
      total: 0,
      datas: [],
    };
  },
};
export const getBTConfig = (config: BTConfigModel) => {
  return {
    ...DEFAULT_BT_CONFIG,
    ...config,
  };
};
export const getBTData = (data: BTDataModel): BTDataModel => {
  return {
    ...DEFAULT_BT_DATA,
    ...data,
  };
};
export const getColumModel = (column: BTColumnModel): BTColumnModel => {
  return {
    ...DEFAULT_COLUMN_ITEM,
    ...column,
  };
};
export const getBTProps = (
  config: BTConfigModel,
  data: BTDataModel
): BaseTableProps => {
  return {
    configModel: getBTConfig(config),
    dataModel: getBTData(data),
  };
};
