import "./password-expired.scss";

import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Animated } from "react-animated-css";

// services
import { ChangePasswordService } from "./../../../../../../services/hrmnet-api";

//component
import BaseForm from "./../../../../../../components/base-form/base-form";
import { getControlModel } from "./../../../../../../components/base-control/base-cotrol-model";

//primereact
import { Skeleton } from "primereact/skeleton";

//redux
import { store } from "./../../../../../../redux/store";

//const
import { MessageSwitch } from "./../../../../../../services/utils/message";
import { RESPONSE_MESSAGE_TYPE } from "./../../../../../../constants/index";
import { LoginLink } from "../../../../../../services/utils";
import { CancelBtn } from "../utils";
import LoginWrapper from "../../login-wrapper";

const ExpiredPassword = ({ location }) => {
  const token = location?.state?.token;
  const title = location?.state?.title;
  const description = location?.state?.description;
  const history = useHistory();
  const { t, i18n } = useTranslation();
  let message = store.getState().global?.toast;
  const [state, setState] = useState({
    form: {},
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (isSubmitted) {
      history.push(LoginLink());
    }
    if (!token) {
      history.push(LoginLink());
    }

    return () => setIsSubmitted(false);
  }, [isSubmitted, token]);

  // functions -------------------------------------------------------------
  const afterFormSubmitFn = ({ formState, res }) => {
    if (res && res?.data && res.data?.status) {
      let messageObj = {
        messages: [
          {
            type: RESPONSE_MESSAGE_TYPE.SUCCESS,
            text: t("login_passwordExpired_success"),
          },
        ],
      };
      MessageSwitch(messageObj, message, null, i18n);
      setIsSubmitted(true);
      setState({ form: null });
    }

    return res?.data?.status ?? false;
  };

  const formSubmitFn = (payload) => {
    const { form } = payload;

    return ChangePasswordService.changePasswordChangePassword(
      {
        body: form,
      },
      {
        token: token,
      }
    );
  };

  //form layout
  const renderForm = {
    controls: [
      getControlModel({
        key: "oldPassword",
        label: t("login_passwordExpired_oldPassword"),
        type: "password",
        placeholder: " ",
        required: true,
        noRequiredLabel: true,
        showPasswordHint: false,
        config: {
          toggleMask: true,
        },
      }),
      getControlModel({
        key: "newPassword",
        label: t("login_passwordExpired_newPassword"),
        type: "password",
        placeholder: " ",
        required: true,
        noRequiredLabel: true,
        showPasswordHint: true,
        config: {
          toggleMask: true,
        },
      }),
      getControlModel({
        key: "confirmPassword",
        label: t("login_passwordExpired_verifyPassword"),
        type: "password",
        placeholder: " ",
        required: true,
        noRequiredLabel: true,
        showPasswordHint: false,
        isPasswordUpdate: true,
        config: {
          feedback: false, // we don't need to show strength during login
          toggleMask: true,
        },
      }),
    ],
    layout: {
      rows: [
        {
          columns: [
            {
              control: "oldPassword",
            },
          ],
        },
        {
          columns: [
            {
              control: "newPassword",
            },
          ],
        },
        {
          columns: [
            {
              control: "confirmPassword",
            },
          ],
        },
        {
          config: {
            style: {
              marginBottom: "1rem",
              marginTop: "1rem",
            },
          },
          columns: [
            {
              action: {
                type: "submit",
                label: t("login_passwordExpired_button"),
                submitingLabel: t("Submitting"),
                config: {
                  kind: "primary",
                  style: {
                    width: "100%",
                    textAlign: "left",
                    minHeight: "3.5rem",
                  },
                },
              },
            },
            CancelBtn(t("login_back"), () => history.push(LoginLink())),
          ],
        },
      ],
    },
    formSubmitFn,
    afterFormSubmitFn,
  };

  const renderContent = () => {
    return (
      <Animated
        animationIn="slideInRight"
        animationOut="slideOutRight"
        animationInDuration={200}
        animationOutDuration={200}
        isVisible={true}
        className="pe"
      >
        <h2>{t(title)}</h2>
        <p>{t(description)}</p>
        <div className="pe-form">
          {state.loading || state.submitting ? (
            <div className="comp-loading">
              <Skeleton />
            </div>
          ) : (
            <div className="c-group-panel">
              <div className="p-field p-grid">
                <div className="p-col-12">
                  <BaseForm
                    id="expiredPassword"
                    config={renderForm}
                    form={state.form}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </Animated>
    );
  };

  return (
    <LoginWrapper>
      <div className="login-form-tab">{renderContent()}</div>
    </LoginWrapper>
  );
};

export default ExpiredPassword;
