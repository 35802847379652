import merge from "deepmerge";

import {
  BCProps,
  BCType,
} from "../../../../components/base-control/base-control";
import {
  AssignClaimControl,
  assignClaimValidationControl,
  ClaimTable,
  CommentChatroom,
  ApproveClaimControl,
  LabelControl,
} from "../components";
import {
  CustomFunctionKey,
  DependentFieldActionInterface,
  DEPENDENT_FIELD_ACTION_WILDCARD,
} from ".";
import ApplicationTypeControl, {
  NEW_APPLICATION_VALUE,
  COPY_APPLICATION_VALUE,
} from "../components/application-type-control";
import { RentalDetailsService } from "../../../../services/hrmnet-api";
import { DEPENDENT_FIELD_ACTION_TYPE } from "./formCustomFunction";
import { showToast } from "../../../../services/utils/message";
import {
  HTML_ATTIBUTES_ONCLICK,
  HTML_ATTIBUTES_VALUE,
  MESSAGE_SEVERITY,
} from "../../../../constants";
import {
  extractAllowedFileExt,
  processAttachmentControlConfig,
} from "../../../../utils/utils";

// Config Control Type
export enum CCType {
  HIDDEN_FIELD = "HiddenField",
  LABEL = "Label",
  HINT = "Hint",
  TEXT_BOX = "TextBox",
  DROPDOWN_LIST = "DropDownList",
  DROPDOWN_LIST_MULTISELECT = "DropDownListMultiselect",
  CHECKBOX = "CheckBox",
  NUMBERIC_BOX = "NumericBox",
  DATE_PICKER = "DatePicker",
  NOTES = "Notes",
  ATTACHMENT = "Attachment",
  COMMENT_LIST = "CommentList",
  CLAIM_SUMMARY = "ClaimList",
  APPLICATION_TYPE = "ApplicationType",
  CLAIM_SELECT_MONTH = "MonthSelection",
  APPROVE_CLAIM = "ApproveClaim",
}

const DEPENDENT_FIELD_SUPPORT_CC_TYPE = [
  CCType.DROPDOWN_LIST,
  CCType.TEXT_BOX,
  CCType.DATE_PICKER,
];

export interface SectionConfig {
  id: number;
  sectionName: string;
  displaySequence: number;
  displayLayout?: number;
  defaultExpanded?: boolean;
  fields: FieldConfiguration[];
}

export interface StepSectionConfig {
  isEditable?: boolean;
  sectionId: number;
  sectionName: string;
  displaySequence: number;
  isDefaultHide?: boolean;
}

export interface StepConfig {
  id: number;
  stepName: string;
  displaySequnce: number;
  sections: StepSectionConfig[];
}

export interface FieldConfiguration {
  id: number;
  viewSectionId?: number;
  fieldName: string;
  fieldLabel?: string;
  controlType: CCType;
  inputHint?: string;
  length?: number;
  formatString?: string;
  displaySequence: number;
  defaultValue?: any;
  value?: any;
  isAllowNull?: boolean;
  isEditable?: boolean;
  isHideLabelField?: boolean;
  isHtmlField?: boolean;
  isSpecialField?: boolean;
  isDefaultHide?: boolean;
  isDetailView?: boolean;
  styleClass?: string;
  eventFunctionName?: any;
  dependentFields?: string;
  fieldGroup?: number | null;

  // frontend logic
  decimal?: number | null;
  [key: string]: any;
}

interface StepNavModel {
  label: string;
}

interface ConfigCustomFunction {
  [key: string]: any;
}

interface AttachmentControlConfig {
  description: string;
  allowedFileExt: string;
  personalContent: boolean;
  allowedFileSize: number;
  allowedNoOfFiles: number;
}

interface customDataInterface {
  sectionFieldConfig?: any;
  customFunctionLibrary?: ConfigCustomFunction;
  comments?: any[]; // CommentList
  claims?: any[]; // ClaimList
  previousApplications?: any[]; // ApplicationList
  openClaims?: any[]; // MonthSelection
  attachmentControlConfig?: AttachmentControlConfig;
}

export interface onTrueUpdateInterface {
  update: {
    prevValue?: any;
    value: any;
    controlState?: any;
  };
  control: FieldConfiguration;
  section: SectionConfig;
}

export interface FormConfigControlInterface extends BCProps {
  key: string;
  refKey: string;
  dependentFields: any;
  layoutConfig?: any;
  hintBottom?: any;
  hintRight?: any;
  fieldGroup?: any;
  [key: string]: any;
}

export interface FormConfigSectionInterface {
  readOnly: boolean;
  sectionId: number;
  title: string;
  displayLayout?: number;
  defaultExpanded: boolean | null;
  controls: FormConfigControlInterface[];
  config: {
    hidden: boolean;
  };
  [key: string]: any;
}

export interface FormConfigInterface {
  applicationId?: any;
  steps: {
    sections: FormConfigSectionInterface[];
  }[];
}

export function configToStepNavModel(config: StepConfig[]): StepNavModel[] {
  return config.map((conf) => ({ label: conf.stepName }));
}

function createHint(hint: string | undefined | null) {
  return hint ? (
    <span dangerouslySetInnerHTML={{ __html: hint } as any}></span>
  ) : null;
}

export function getFieldKey(field: any) {
  // return `${field.id}|${field.fieldName}`;
  return field.fieldName;
}

export function convertConfigToControl(
  stepSectionConfig: StepSectionConfig,
  sectionConfig: SectionConfig,
  fieldConfig: FieldConfiguration | null,
  customData: customDataInterface,
  t: any
) {
  if (!fieldConfig) return null;

  // TMP: Add hardcorded from/to date handling by using dependentFieldAction
  if (
    [
      "Rfd_Fm",
      "Rfd_To",
      "Rent_Free_Period_From",
      "Rent_Free_Period_To",
      "App_Termination_Date",
      "App_Effective_Date",
    ].includes(fieldConfig.fieldName)
  ) {
    let _dependentFields =
      fieldConfig.dependentFields &&
      Array.isArray(JSON.parse(fieldConfig.dependentFields))
        ? JSON.parse(fieldConfig.dependentFields)
        : [];
    let customDependentFieldAction: any = {
      Rfd_Fm: {
        Value: DEPENDENT_FIELD_ACTION_WILDCARD,
        Action: DEPENDENT_FIELD_ACTION_TYPE.SET_MIN_DATE,
        Fields: "Rfd_To",
      },
      Rfd_To: {
        Value: DEPENDENT_FIELD_ACTION_WILDCARD,
        Action: DEPENDENT_FIELD_ACTION_TYPE.SET_MAX_DATE,
        Fields: "Rfd_Fm",
      },
      Rent_Free_Period_From: {
        Value: DEPENDENT_FIELD_ACTION_WILDCARD,
        Action: DEPENDENT_FIELD_ACTION_TYPE.SET_MIN_DATE,
        Fields: "Rent_Free_Period_To",
      },
      Rent_Free_Period_To: {
        Value: DEPENDENT_FIELD_ACTION_WILDCARD,
        Action: DEPENDENT_FIELD_ACTION_TYPE.SET_MAX_DATE,
        Fields: "Rent_Free_Period_From",
      },
      App_Effective_Date: {
        Value: DEPENDENT_FIELD_ACTION_WILDCARD,
        Action: DEPENDENT_FIELD_ACTION_TYPE.SET_MIN_DATE,
        Fields: "App_Termination_Date",
      },
      App_Termination_Date: {
        Value: DEPENDENT_FIELD_ACTION_WILDCARD,
        Action: DEPENDENT_FIELD_ACTION_TYPE.SET_MAX_DATE,
        Fields: "App_Effective_Date",
      },
    };
    _dependentFields.push(customDependentFieldAction[fieldConfig.fieldName]);
    fieldConfig.dependentFields = JSON.stringify(_dependentFields);
  }

  // Base configuration
  const [hintBottom, hintRight] = extractInputHint(fieldConfig.inputHint);
  let baseControlProps: FormConfigControlInterface = {
    span: 0,
    key: getFieldKey(fieldConfig),
    refKey: fieldConfig.fieldName,
    label: fieldConfig.fieldLabel || fieldConfig.fieldName,
    noLabel: !!fieldConfig.isHideLabelField,
    required:
      stepSectionConfig.isEditable &&
      fieldConfig.isEditable &&
      !fieldConfig.isAllowNull,
    // maxLength: fieldConfig.length ? fieldConfig.length : undefined,
    defaultValue: fieldConfig.defaultValue,
    hintBottom: createHint(hintBottom),
    hintRight: createHint(hintRight),
    config: {
      readOnly: !fieldConfig.isEditable || !stepSectionConfig.isEditable,
    },
    layoutConfig: {
      className: `p-col-12 ${
        fieldConfig.styleClass
          ? fieldConfig.styleClass
          : "p-lg-4 p-md-6 p-sm-12"
      }`,
      holderClassName: fieldConfig.controlStyle
        ? `${fieldConfig.controlStyle}`
        : "",
      hidden: fieldConfig.isDefaultHide,
    },
    dependentFields: fieldConfig.dependentFields,
    fieldGroup: fieldConfig.fieldGroup,
  };

  // Handle dynamic event function
  let eventFunctionName = fieldConfig.eventFunctionName || "";

  // Add custom eventFunction
  if (
    DEPENDENT_FIELD_SUPPORT_CC_TYPE.includes(fieldConfig.controlType) &&
    fieldConfig.dependentFields
  ) {
    eventFunctionName = eventFunctionName
      ? eventFunctionName
          .split(",")
          .concat(CustomFunctionKey.DEPENDENT_FIELD_ACTION)
          .join(",")
      : CustomFunctionKey.DEPENDENT_FIELD_ACTION;
  }

  // Populate all event functions into onTrueUpdateValue props
  if (eventFunctionName) {
    const { fields, ...restSectionConfig } = sectionConfig;
    const updateFunction = async (change: any) => {
      for (const fnName of eventFunctionName.split(",")) {
        if (customData?.customFunctionLibrary?.[fnName]) {
          await customData?.customFunctionLibrary?.[fnName]({
            update: change,
            control: fieldConfig,
            section: restSectionConfig,
          } as any);
        }
      }
    };
    baseControlProps.onTrueUpdateValue = updateFunction as any;
  }

  // html onclick handler for Hint/Note
  const htmlOnClickHandler = (e: any) => {
    if (e.target?.attributes?.[HTML_ATTIBUTES_ONCLICK]) {
      const fn = e.target.attributes[HTML_ATTIBUTES_ONCLICK].value;
      const value = e.target.attributes[HTML_ATTIBUTES_VALUE]?.value;
      if (
        fn &&
        customData.customFunctionLibrary &&
        fn in customData.customFunctionLibrary
      ) {
        customData.customFunctionLibrary[fn](value);
      }
    }
  };

  switch (fieldConfig.controlType) {
    case CCType.HIDDEN_FIELD:
      return {
        ...baseControlProps,
        span: 0,
        componentRender: () => (
          <input hidden value={fieldConfig.defaultValue} />
        ),
        layoutConfig: {
          ...baseControlProps.layoutConfig,
          className: `${baseControlProps.layoutConfig.className} hidden`,
        },
      };
    case CCType.LABEL:
      return {
        ...baseControlProps,
        type: "rental-label",
        required: false,
        componentRender: (props: any) => <LabelControl {...props} />,
      };
    case CCType.HINT:
      return {
        ...baseControlProps,
        type: "rental-hint",
        required: false,
        noLabel: true,
        componentRender: () => (
          <span
            onClick={htmlOnClickHandler}
            className="form-hint"
            dangerouslySetInnerHTML={
              {
                __html: fieldConfig.value
                  ? fieldConfig.value
                  : fieldConfig.defaultValue,
              } as any
            }
          ></span>
        ),
      };
    case CCType.TEXT_BOX:
      return {
        ...baseControlProps,
        type: BCType.input,
        placeholder: t("base_control_input_text"),
      };
    case CCType.DROPDOWN_LIST:
      return {
        ...baseControlProps,
        type: BCType.select,
        placeholder: t("base_control_select_choose"),
        enum: fieldConfig.dropdownOptions?.map((option: any) => ({
          label: option.name,
          value: option.value,
        })),
        hasFilterEnum: false,
        config: {
          ...baseControlProps.config,
          showClear: false,
        },
      };
    case CCType.DROPDOWN_LIST_MULTISELECT:
      return {
        ...baseControlProps,
        type: BCType.multiselect,
        placeholder: t("base_control_select_choose"),
        enum: fieldConfig.dropdownOptions?.map((option: any) => ({
          label: option.name,
          value: option.value,
        })),
        config: { ...baseControlProps.config, ...{ filter: true } },
      };
    case CCType.CHECKBOX:
      return {
        ...baseControlProps,
        type: BCType.checkbox,
      };
    case CCType.NUMBERIC_BOX:
      const readOnly = baseControlProps.config.readOnly;
      // special logic: if readonly, 2 decimal
      const decimal = readOnly ? 2 : 0;
      return {
        ...baseControlProps,
        type: BCType.number,
        min: 0,
        placeholder: t("base_control_number_input_number"),
        placeholderStrict: true,
        config: {
          ...baseControlProps.config,
          mode: decimal ? "decimal" : undefined,
          minFractionDigits: decimal,
          maxFractionDigits: decimal,
        },
      };
    case CCType.DATE_PICKER:
      // Custom Tricor <-> Primereact DateFormat mapper
      let dateFormat = fieldConfig.formatString || "yyyy-MM-dd";
      const placeholder = dateFormat.toUpperCase();
      if (dateFormat.includes("yyyy")) {
        dateFormat = dateFormat.replace("yyyy", "yy");
      } else if (dateFormat.includes("yy")) {
        dateFormat = dateFormat.replace("yy", "y");
      }

      if (dateFormat.includes("MM")) {
        dateFormat = dateFormat.replace("MM", "mm");
      } else if (dateFormat.includes("mm")) {
        dateFormat = dateFormat.replace("mm", "MM");
      }
      return {
        ...baseControlProps,
        config: {
          ...baseControlProps.config,
        },
        type: BCType.date,
        placeholder: placeholder,
        dateFormat: dateFormat, // TODO: should use fieldConfig.formatString
      };
    case CCType.ATTACHMENT:
      const attachmentConfig = processAttachmentControlConfig(
        customData.attachmentControlConfig
      ) as any;
      return {
        ...baseControlProps,
        type: BCType.upload,
        defaultValue: null,
        layoutConfig: {
          ...baseControlProps.layoutConfig,
          className: `${baseControlProps.layoutConfig.className} p-lg-12 p-md-12 p-sm-12`,
        },
        config: {
          ...baseControlProps.config,
          accept: attachmentConfig.allowedFileExt,
          maxFileSize: attachmentConfig.allowedFileSize,
        },
        multiple: true,
        onDownload:
          customData?.customFunctionLibrary?.[
            CustomFunctionKey.UPLOAD_CONTROL_ON_DOWNLOAD
          ],
        onFileError: ({ defaultMessage }: any) =>
          showToast({
            summary: defaultMessage,
            severity: MESSAGE_SEVERITY.WARN,
          }),
      };
    case CCType.NOTES:
      return {
        ...baseControlProps,
        required: false,
        noLabel: true,
        componentRender: () => (
          <div
            onClick={htmlOnClickHandler}
            className="form-note"
            style={{ flex: 1 }}
            dangerouslySetInnerHTML={
              {
                __html: fieldConfig.value
                  ? fieldConfig.value
                  : fieldConfig.defaultValue,
              } as any
            }
          ></div>
        ),
        layoutConfig: {
          ...baseControlProps.layoutConfig,
          className: `${baseControlProps.layoutConfig.className} p-lg-12 p-md-12 p-sm-12`,
        },
      };
    case CCType.COMMENT_LIST:
      return {
        ...baseControlProps,
        required: false,
        noLabel: true,
        componentRender: () => (
          <div style={{ margin: "-10px auto", width: "100%" }}>
            <CommentChatroom
              commentList={customData?.comments ? customData.comments : []}
              readOnly={baseControlProps.config.readOnly}
              onSend={async (comment: string) => {
                const res = await customData?.customFunctionLibrary?.[
                  CustomFunctionKey.SEND_COMMENT
                ](comment);
                return res;
              }}
            />
          </div>
        ),
        layoutConfig: {
          ...baseControlProps.layoutConfig,
          className: `${baseControlProps.layoutConfig.className} p-lg-12 p-md-12 p-sm-12`,
        },
      };
    case CCType.CLAIM_SUMMARY:
      return {
        ...baseControlProps,
        noLabel: true,
        componentRender: () => (
          <div style={{ margin: "0px auto" }}>
            <ClaimTable data={customData?.claims ? customData.claims : []} />
          </div>
        ),
        layoutConfig: {
          ...baseControlProps.layoutConfig,
          className: `${baseControlProps.layoutConfig.className} p-lg-12 p-md-12 p-sm-12`,
        },
      };
    case CCType.APPLICATION_TYPE:
      return {
        ...baseControlProps,
        componentRender: (props: any) => (
          <ApplicationTypeControl
            controlKey={getFieldKey(fieldConfig)}
            enum={customData.previousApplications?.map((x) => ({
              label: x.name,
              value: x.value,
            }))}
            onSelectApplication={
              customData.customFunctionLibrary?.[
                CustomFunctionKey.APPLICATION_TYPE_CONTROL_ON_SELECT
              ]
            }
            onSelectNewApplication={
              customData.customFunctionLibrary?.[
                CustomFunctionKey.APPLICATION_TYPE_CONTROL_ON_SELECT_NEW
              ]
            }
            {...props}
          />
        ),
        layoutConfig: {
          ...baseControlProps.layoutConfig,
          className: `${baseControlProps.layoutConfig.className} p-lg-12 p-md-12 p-sm-12`,
        },
      };
    case CCType.CLAIM_SELECT_MONTH:
      const relativeFieldName = fieldConfig.dependentFields
        ? JSON.parse(fieldConfig.dependentFields)?.[0]?.Fields
        : null;
      return {
        ...baseControlProps,
        componentRender: (props: any) => (
          <AssignClaimControl
            relativeFormKey={relativeFieldName}
            openClaims={customData.openClaims}
            onRemove={(idx: number) => {
              customData?.customFunctionLibrary?.[
                CustomFunctionKey.ASSIGN_CLAIM_CONTROL_ON_REMOVE
              ]({
                relativeFormKey: relativeFieldName,
                fileIndex: idx,
              });
            }}
            {...props}
          />
        ),
        validateControl: assignClaimValidationControl,
        layoutConfig: {
          ...baseControlProps.layoutConfig,
          className: `${baseControlProps.layoutConfig.className} p-lg-12 p-md-12 p-sm-12`,
        },
      };
    case CCType.APPROVE_CLAIM:
      const attachmentConf = processAttachmentControlConfig(
        customData.attachmentControlConfig
      ) as any;
      return {
        ...baseControlProps,
        componentRender: (props: any) => (
          <ApproveClaimControl
            attachmentConfig={attachmentConf}
            data={customData?.claims ? customData.claims : []}
            onFileError={({ defaultMessage }: any) =>
              showToast({
                summary: defaultMessage,
                severity: MESSAGE_SEVERITY.WARN,
              })
            }
            {...props}
          />
        ),
        layoutConfig: {
          ...baseControlProps.layoutConfig,
          className: `${baseControlProps.layoutConfig.className} p-lg-12 p-md-12 p-sm-12`,
        },
      };
    default:
      return {};
  }
}

export function initDependentFieldActionFn({
  formConfig,
  form,
}: {
  formConfig: FormConfigInterface;
  form: any;
}) {
  let _formConfig = { ...formConfig };

  function hideShow({
    action,
    show,
  }: {
    action: DependentFieldActionInterface;
    show: boolean;
  }) {
    action.Fields.split(",").forEach((field) => {
      field = field.trim();
      const controlConfig = controlConfigChangeMap[field] || {};
      const layoutConfig = controlConfig.layoutConfig || {};
      controlConfigChangeMap[field] = {
        ...controlConfig,
        layoutConfig: {
          ...layoutConfig,
          hidden: !show,
        },
      };
    });
    action.Sections.split(",").forEach((section) => {
      section = section.trim();
      const sectionConfig = sectionConfigChangeMap[section.toString()] || {};
      const sectionConfigConfig = sectionConfig.config || {};
      sectionConfigChangeMap[section] = {
        ...sectionConfig,
        config: {
          ...sectionConfigConfig,
          hidden: !show,
        },
      };
    });
  }

  function setMinMaxDate({
    action,
    minDate,
    maxDate,
  }: {
    action: DependentFieldActionInterface;
    minDate?: Date | null;
    maxDate?: Date | null;
  }) {
    action.Fields.split(",").forEach((field) => {
      field = field.trim();
      const controlConfig = controlConfigChangeMap[field] || {};
      let _innerConfig = { ...controlConfig.config } || {};
      if (minDate) {
        _innerConfig.minDate = minDate;
      }
      if (maxDate) {
        _innerConfig.maxDate = maxDate;
      }
      controlConfigChangeMap[field] = {
        ...controlConfig,
        config: _innerConfig,
      };
    });
  }

  // Create sectionConfigChangeMap and controlConfigChangeMap to save what to update for each section/control
  let sectionConfigChangeMap: any = {};
  let controlConfigChangeMap: any = {};
  formConfig.steps.forEach((step) => {
    step.sections.forEach((section) => {
      section.controls.forEach((control) => {
        const formValue = form[control.key];
        if (control.dependentFields) {
          const actions = JSON.parse(
            control.dependentFields
          ) as DependentFieldActionInterface[];
          actions.forEach((action) => {
            if (
              action.Value !== DEPENDENT_FIELD_ACTION_WILDCARD &&
              formValue !== action.Value
            )
              return;

            switch (action.Action) {
              case DEPENDENT_FIELD_ACTION_TYPE.HIDE:
                hideShow({ action: action, show: false });
                break;
              case DEPENDENT_FIELD_ACTION_TYPE.SHOW:
                hideShow({ action: action, show: true });
                break;
              case DEPENDENT_FIELD_ACTION_TYPE.SET_MAX_DATE:
                const maxDate =
                  formValue && new Date(formValue).getTime()
                    ? new Date(new Date(formValue).getTime())
                    : null;
                setMinMaxDate({ action: action, maxDate: maxDate });
                break;
              case DEPENDENT_FIELD_ACTION_TYPE.SET_MIN_DATE:
                const minDate =
                  formValue && new Date(formValue).getTime()
                    ? new Date(new Date(formValue).getTime())
                    : null;
                setMinMaxDate({ action: action, minDate: minDate });
                break;
              default:
                break;
            }
          });
        }
      });
    });
  });

  // apply changes
  formConfig.steps.forEach((step, stepIdx) => {
    step.sections.forEach((section, sectionIdx) => {
      const sectionChange =
        sectionConfigChangeMap[section.sectionId.toString()];
      if (sectionChange) {
        _formConfig.steps[stepIdx].sections[sectionIdx] = merge(
          _formConfig.steps[stepIdx].sections[sectionIdx],
          sectionChange
        );
      }
      section.controls.forEach((control, ctrlIdx) => {
        const controlChange = controlConfigChangeMap[control.refKey];
        if (controlChange) {
          _formConfig.steps[stepIdx].sections[sectionIdx].controls[ctrlIdx] =
            merge(
              _formConfig.steps[stepIdx].sections[sectionIdx].controls[ctrlIdx],
              controlChange
            );
        }
      });
    });
  });
  return _formConfig;
}

export function configToFormModel({
  applicationId,
  stepConfig,
  sectionFieldConfig,
  customData,
  t = (x: any) => x,
  form,
}: {
  applicationId: any;
  stepConfig: StepConfig[];
  sectionFieldConfig: SectionConfig[];
  customData: customDataInterface;
  t: any;
  form?: any;
}): FormConfigInterface {
  let sectionFieldConfigMap: { [key: number]: SectionConfig } =
    sectionFieldConfig.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {});
  let formModel = {
    applicationId: applicationId,
    steps: [],
  } as any;
  stepConfig.forEach((conf) => {
    let fullStepConfig = { sections: [] } as any;
    conf.sections.forEach((section) => {
      if (section.sectionId in sectionFieldConfigMap) {
        let fullSectionConfig: FormConfigSectionInterface = {
          config: {
            hidden: !!section.isDefaultHide,
          },
          readOnly: !section.isEditable,
          sectionId: section.sectionId,
          title: section.sectionName,
          displayLayout: sectionFieldConfigMap[section.sectionId].displayLayout,
          defaultExpanded:
            !!sectionFieldConfigMap[section.sectionId].defaultExpanded,
          controls: sectionFieldConfigMap[section.sectionId].fields
            ?.sort((a, b) => {
              return a.displaySequence - b.displaySequence;
            })
            .map((field) =>
              convertConfigToControl(
                section,
                sectionFieldConfigMap[section.sectionId],
                field,
                {
                  ...customData,
                  sectionFieldConfig: sectionFieldConfig,
                },
                t
              )
            ) as any,
        };
        fullStepConfig.sections.push(fullSectionConfig);
      }
    });

    formModel.steps.push(fullStepConfig);
  });

  // if form is provided, apply dependentFieldAction
  if (form) {
    formModel = initDependentFieldActionFn({
      formConfig: formModel,
      form: form,
    });
  }

  return formModel;
}

/**
 *
 * @param inputHint
 * @returns string[] : [hintBottom, hintRight]
 */
export function extractInputHint(inputHint: string | undefined | null) {
  if (!inputHint) return [null, null];
  if (inputHint.startsWith("{(")) {
    let retArray = [null, null];
    try {
      let hintObj = JSON.parse(inputHint.slice(2, -2));
      retArray[0] = hintObj?.m?.text?.trim();
      retArray[1] = hintObj?.l?.text?.trim();
    } catch {}
    return retArray;
  } else {
    return [inputHint, null];
  }
}

export function initFormValue(
  sectionFieldConfig: SectionConfig[],
  excludeFieldTypes?: string[]
) {
  const controlList = sectionFieldConfig.reduce(
    (accList: any[], section: SectionConfig) => {
      accList = accList.concat(section.fields);
      return accList;
    },
    []
  );
  return initFormValueByControlList(controlList, excludeFieldTypes);
}

/**
 * Search the *first* field with fieldName and return the field's formKey
 * @param sectionFieldConfig
 * @param fieldName
 * @returns string
 */
export function getFormKeyByFieldName(
  sectionFieldConfig: SectionConfig[],
  fieldName: string | undefined
) {
  if (!sectionFieldConfig || !fieldName) return null;
  for (const section of sectionFieldConfig) {
    for (const field of section.fields) {
      if (field.fieldName === fieldName) {
        return getFieldKey(field);
      }
    }
  }
  return "";
}

/**
 * Construct Form for Recalculate APIs
 */
export function initFormValueByControlList(
  controlList: FieldConfiguration[],
  excludeFieldTypes?: string[]
) {
  let retForm = {} as any;
  controlList.forEach((field) => {
    if (excludeFieldTypes?.includes(field.controlType)) {
      return;
    }
    const fieldKey = getFieldKey(field);
    retForm[fieldKey] = undefined;
    switch (field.controlType) {
      case CCType.APPLICATION_TYPE:
        if (field.value === COPY_APPLICATION_VALUE) {
          // Custom logic. Fill value with some string to prevent being cast as NEW when submit
          retForm[fieldKey] = {
            select: COPY_APPLICATION_VALUE,
            value: "LOADED",
          };
        } else {
          retForm[fieldKey] = {
            select: NEW_APPLICATION_VALUE,
          };
        }
        break;
      case CCType.ATTACHMENT:
        if (Array.isArray(field.fileAttachments)) {
          retForm[fieldKey] = field.fileAttachments.map((file) => ({
            id: file.fileId,
            name: file.fileName,
            size: file.fileSize,
            uploadId: file.uploadId,
          }));
        }
        break;
      case CCType.NUMBERIC_BOX:
        retForm[fieldKey] = field.value
          ? Number(field.value)
          : field.defaultValue;
        break;
      case CCType.CHECKBOX:
        retForm[fieldKey] =
          field.value?.toString()?.toLowerCase() === "true" ? true : false;
        break;
      case CCType.APPROVE_CLAIM:
        retForm[fieldKey] = null;
        break;
      default:
        retForm[fieldKey] =
          field.value !== undefined ? field.value : field.defaultValue;
        break;
    }
  });
  return retForm;
}
