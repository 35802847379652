import "./comment-chatroom.scss";

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

const CommentChatroom = (props) => {
  const [readOnly, setReadOnly] = useState(false);
  const [commentDate, setCommentDate] = useState([]);
  const [commentList, setCommentList] = useState([]);
  const { t } = useTranslation();
  const userProfile = useSelector((state) => state.user?.profile);

  const init = () => {
    let initDate = [];
    let initComment = [];

    if (props?.readOnly) {
      setReadOnly(true);
    }

    props.commentList.forEach((c) => {
      const datetime = parseDatetime(c.commentDate);
      const result = dateCheck(initDate, initComment, datetime[0]);
      initDate = result[0];
      initComment = result[1];
      initComment = addMessage(initDate, initComment, c, datetime);
    });

    setCommentDate(initDate);
    setCommentList(initComment);
    scrollToBottom(200);
  };

  const parseDatetime = (datetimeString) => {
    const datetime = datetimeString ? new Date(datetimeString) : new Date();
    const y = datetime.getFullYear();
    const m = (datetime.getMonth() + 1).toString().padStart(2, "0");
    const d = datetime.getDate().toString().padStart(2, "0");
    const date = [y, "/", m, "/", d].join("");
    const h = datetime.getHours().toString().padStart(2, "0");
    const min = datetime.getMinutes().toString().padStart(2, "0");
    const time = [h, ":", min].join("");
    return [date, time];
  };

  const dateCheck = (dateArray, commentArray, date) => {
    if (!dateArray.includes(date)) {
      dateArray.push(date);
      commentArray.push({
        date: date,
        comment: [],
      });
    }
    return [dateArray, commentArray];
  };

  const getEmployeeCode = () => {
    return userProfile?.employeeCode == null || undefined
      ? t("common_notApplicable")
      : userProfile?.employeeCode;
  };

  const getEmployeeName = () => {
    return userProfile?.name == null || undefined
      ? t("common_notApplicable")
      : userProfile?.name;
  };

  const addMessage = (dateArray, commentArray, comment, commentDatetime) => {
    const index = dateArray.findIndex((d) => d === commentDatetime[0]);
    const commentList = [...commentArray[index].comment];
    commentList.push({
      // ...comment,
      isReceived: comment.commentBy === getEmployeeCode() ? false : true,
      senderName: comment.commentByName,
      senderNameInitials: comment.commentByName.split(" ").reduce((a, n, i) => {
        if (i < 2) {
          a = a + n.charAt(0);
        }
        return a;
      }, ""),
      content: comment.comment,
      date: commentDatetime[0],
      time: commentDatetime[1],
    });
    commentArray[index].comment = commentList;
    return commentArray;
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      sendMessage();
    }
  };

  const sendMessage = async () => {
    var messageTextSelector = document.querySelector(
      ".message-input-block .message-input"
    );
    var messageText = messageTextSelector.value.trim();

    if (messageText.length > 0) {
      const messageDatetime = parseDatetime(null);
      const message = {
        commentBy: getEmployeeCode(),
        commentByName: getEmployeeName(),
        comment: messageText,
      };
      const messageBody = {
        comment: messageText,
      };

      let newDate = [...commentDate];
      let newComment = [...commentList];
      const result = dateCheck(newDate, newComment, messageDatetime[0]);
      newDate = result[0];
      newComment = result[1];
      newComment = addMessage(newDate, newComment, message, messageDatetime);

      if (props.onSend) {
        const res = await props.onSend(messageBody);
        if (res && res.messages?.[0]?.type !== "Error") {
          setCommentDate(newDate);
          setCommentList(newComment);
        }
      }
    }
    messageTextSelector.value = "";
    scrollToBottom(100);
  };

  const scrollToBottom = (delay) => {
    var commentBlockSelector = document.querySelector(".comment-block");
    setTimeout(function () {
      commentBlockSelector.scrollTop = commentBlockSelector.scrollHeight;
    }, delay);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className="comment-chatroom-container">
      <div className="comment-block">
        {commentList.map((r, ri) => (
          <div key={`comment-${ri}`}>
            <div className="content-block content-block__center">
              <div className="date-block">{r.date}</div>
            </div>
            {r.comment.map((c, ci) => (
              <div
                className={`content-block ${
                  c.isReceived ? "content-block__left" : "content-block__right"
                } message-block`}
                key={`message-${ci}`}
              >
                {c.isReceived ? (
                  <div className="sender-initial-block">
                    <div className="sender-initial">{c.senderNameInitials}</div>
                  </div>
                ) : null}
                <div
                  className={`message-bubble-block ${
                    c.isReceived
                      ? "message-bubble-block__received"
                      : "message-bubble-block__sent"
                  }`}
                >
                  <div className="message-info">
                    {c.isReceived ? (
                      <div className="sender-name">{c.senderName}</div>
                    ) : null}
                    <div className="message-time">{c.time}</div>
                  </div>
                  <div className="message-content">{c.content}</div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      {!readOnly ? (
        <div className="content-block content-block__left">
          <div className="message-input-block">
            <div
              className={`message-input-wrapper ${
                readOnly ? "message-input-wrapper__readOnly" : ""
              }`}
            >
              <InputText
                className="message-input"
                placeholder="Type a new comment"
                disabled={readOnly}
                onKeyDown={handleKeyDown}
              />
            </div>
            <Button
              className={`send-message-btn ${
                readOnly ? "send-message-btn__readOnly" : ""
              } custom-input-action-btn`}
              label={t("rental_common_sendMessage")}
              disabled={readOnly}
              onClick={(e) => {
                e.preventDefault();
                sendMessage();
              }}
            />
            <Button className={!readOnly ? "placeholder-btn" : ""} />
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default CommentChatroom;
