import "./notification-details.scss";

import moment from "moment";
import { UserNotificationsService } from "../../../../services/hrmnet-api";
import { Button } from "primereact/button";

import { setNotification } from "../../../../redux/actions/notification";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { navigateToNotificationOrigin } from "./notification-constant";
import { DEFAULT_DATETIME_FORMAT } from "../../../../constants";

const NotificationDetails = ({ innerRef, updateData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const notificationContent = useSelector((state) => state.notification);
  const toast = useSelector((state) => state.global.toast);
  let history = useHistory();
  let notificationIndex = {};

  // Get current notification index to navigate previous and next notification details
  const getCurrentNotificationIndex = () => {
    notificationContent.notificationDesktopContent?.map(function (
      notification,
      index
    ) {
      if (
        notification.notificationId ===
        notificationContent?.selectedNotification.notificationId
      ) {
        notificationIndex = index;
      }
      return [];
    });
  };

  // Update notification read status in database
  const updateMessageIsRead = async (notificationId) => {
    try {
      await UserNotificationsService.notificationUpdateNotificationStatus({
        notificationId: notificationId,
      });
    } catch (error) {}
  };

  // Update redux and database is_read only once
  // Else pass the previous or next notificationIndex and assign it as current selectedNotification
  const updateReduxAndDatabaseOnce = () => {
    let newNotificationDetailsDesktop = {};
    let currentSelectedNotificationDetails =
      notificationContent?.notificationDesktopContent[notificationIndex];
    // If on the first notification.status === New, then update the notification
    // read status in the redux and database once
    if (currentSelectedNotificationDetails.status === "New") {
      let updateNotificationDesktopReadStatus = [
        ...notificationContent?.notificationDesktopContent,
      ];
      // Update Read Status in selectedNotification in Redux State
      currentSelectedNotificationDetails.status = "Read";
      // Update Read Status in notificationDesktopContent in Redux State
      for (var notification in updateNotificationDesktopReadStatus) {
        if (
          updateNotificationDesktopReadStatus[notification].notificationId ===
          notificationContent?.notificationDesktopContent[notificationIndex]
            .notificationId
        ) {
          updateNotificationDesktopReadStatus[notification].status = "Read";
        }
      }
      newNotificationDetailsDesktop = {
        notificationDesktopContent: updateNotificationDesktopReadStatus,
        selectedNotification: currentSelectedNotificationDetails,
      };
      // Update the read status in the database
      updateMessageIsRead(
        notificationContent?.notificationDesktopContent[notificationIndex]
          .notificationId
      );
      updateData();
    } else {
      // Else pass the previous or next notificationIndex and assign it as current selectedNotification
      newNotificationDetailsDesktop = {
        selectedNotification: currentSelectedNotificationDetails,
      };
    }
    dispatch(setNotification(newNotificationDetailsDesktop));
  };

  // Left button - render previous notification details
  const leftBtnNotification = () => {
    getCurrentNotificationIndex();
    if (notificationIndex <= 0) {
      toast.show({
        severity: "error",
        summary: t("notification_current_latest"),
        detail: t("notification_current_latest_details"),
        life: 10000,
      });
      return;
    }
    notificationIndex -= 1;
    updateReduxAndDatabaseOnce();
  };

  // Right button - render next notification details
  const rightBtnNotification = () => {
    getCurrentNotificationIndex();
    if (
      notificationIndex >=
      notificationContent.notificationDesktopContent?.length - 1
    ) {
      toast.show({
        severity: "error",
        summary: t("notification_current_oldest"),
        detail: t("notification_current_oldest_details"),
        life: 10000,
      });
      return;
    }
    notificationIndex += 1;
    updateReduxAndDatabaseOnce();
  };

  return (
    <>
      <div
        ref={innerRef}
        className="notification-details-card-container p-card p-card-body"
      >
        <div className="n-d-c-c-control-panel p-grid p-nogutter p-justify-between">
          <div className="n-d-c-c-left p-col-fixed">
            <Button
              className="p-button p-component p-button-icon-only"
              onClick={() => {
                leftBtnNotification();
              }}
            >
              <i className="pi pi-angle-left" />
            </Button>
          </div>
          <div className="n-d-c-c-center p-col-fixed">
            <Button
              className="n-d-c-c-btn-center p-button p-component p-button-icon-only"
              onClick={() => {
                let newNotificationDetailsDesktop = {
                  scrollToNotificationGridTable: true,
                };
                dispatch(setNotification(newNotificationDetailsDesktop));
              }}
            >
              <span>{t("notification_back_to_top")}</span>
            </Button>
          </div>
          <div className="n-d-c-c-right p-col-fixed">
            <Button
              className="p-button p-component p-button-icon-only"
              onClick={() => {
                rightBtnNotification();
              }}
            >
              <i className="pi pi-angle-right" />
            </Button>
          </div>
        </div>
        <div className="p-card-content">
          <div className="notification-details-container">
            <div className="notification-grid notification-content">
              <div className="p-col-12 p-d-flex">
                <div className="p-col-12 p-md-3 p-lg-3 notification-item">
                  <span>{t("notification_title")}:</span>
                </div>
                <div className="notification-item-content">
                  <span>
                    {notificationContent?.selectedNotification?.title}
                  </span>
                </div>
                <div className="p-col-6 p-md-3 p-lg-3 p-order-1 p-order-lg-2 p-order-md-2 notification-button">
                  <Button
                    label={t("notification_take_action")}
                    className="notification-item-button"
                    onClick={() => {
                      navigateToNotificationOrigin(
                        notificationContent.selectedNotification,
                        history
                      );
                    }}
                  >
                    <i className="pi pi-sign-in notification-icon-left"></i>
                  </Button>
                </div>
              </div>
              <div className="p-col-12 p-d-flex">
                <div className="p-col-12 p-md-3 p-lg-3 notification-item">
                  <span>{t("notification_dateTime")}:</span>
                </div>
                <div className="notification-item-content">
                  <span>
                    {moment(
                      notificationContent?.selectedNotification?.publishedDate
                    ).format(DEFAULT_DATETIME_FORMAT)}
                  </span>
                </div>
              </div>
              <div className="p-col-12 p-d-flex">
                <div className="p-col-12 p-md-3 p-lg-3 notification-item">
                  <span>{t("notification_message")}:</span>
                </div>
                <div className="notification-item-content-message">
                  <span>
                    <p>{notificationContent?.selectedNotification?.message}</p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NotificationDetails;
