import React, { useImperativeHandle, useMemo, useRef, useState } from "react";
import { scrollToErrorControlWithSelector } from "../../base-form/utils";
import { BCValidateResult } from "../base-control";
import { ArrayControlHeader } from "./array-control";
import ArrayControlBaseField, {
  ArrayControlRefHandler,
} from "./array-control-base-field";

interface ArrayControlBaseFormControlProps {
  prevvalue?: any;
  // onChange: (param: { [key: string]: any; controlState: BCValidateResult; value: any; valueStr: string; }) => boolean;
  headers?: ArrayControlHeader[];
  /**
   * Function on update data truely
   * @returns is change parent state
   */
  onTrueUpdateValue?: (param: {
    controlState: BCValidateResult;
    value: any;
    valueStr: string;
    [key: string]: any;
  }) => boolean;
}

export type ArrayControlBaseFormRefHandler = {
  onCancel: () => void;
  onSubmit: () => any | undefined;
  onValidation: () => boolean;
  updateValue: (value: any) => void;
};

export interface ArrayControlRefHandlerObject {
  key: string;
  controls: ArrayControlRefHandler;
}

const ArrayControlBaseForm = React.forwardRef<
  ArrayControlBaseFormRefHandler,
  ArrayControlBaseFormControlProps
>((props, ref) => {
  const fieldRef = useRef<(ArrayControlRefHandlerObject | null)[]>([]);
  const { prevvalue, headers, onTrueUpdateValue } = props;

  useImperativeHandle(ref, () => ({
    onValidation: () => {
      let fieldState = {
        invalid: false,
      };
      for (let value of headers ?? []) {
        const cr = fieldRef.current.find((k, index) => {
          return k?.key === value.key;
        });
        if (!!cr) {
          fieldState = cr.controls.onValidation();
          if (fieldState.invalid === true) {
            scrollToErrorControlWithSelector();
            return fieldState.invalid;
          }
        }
      }
      return fieldState.invalid;
    },
    onSubmit: () => {
      let fieldState = {
        invalid: false,
      };
      let _formValue: any = {};
      let isTouched = prevvalue.hasOwnProperty("id");
      if (!isTouched) {
        fieldRef.current.forEach((field) => {
          if (field?.controls.getTouched()) {
            isTouched = true;
          }
        });
      }

      if (!isTouched) {
        return;
      }

      for (let value of headers ?? []) {
        const cr = fieldRef.current.find((k, index) => {
          return k?.key === value.key;
        });
        if (!!cr) {
          fieldState = cr.controls.onValidation();
          if (fieldState.invalid === true) {
            scrollToErrorControlWithSelector();
            return null;
          }

          _formValue[cr.key] = cr.controls.onSubmit(fieldState);
        }
      }
      if (prevvalue.hasOwnProperty("id")) {
        _formValue["id"] = prevvalue["id"];
      }
      return _formValue;
    },
    onCancel: () => {
      for (let value of headers ?? []) {
        const cr = fieldRef.current.find((k, index) => {
          return k?.key === value.key;
        });
        if (!!cr) {
          cr.controls.onCancel();
        }
      }
      return prevvalue;
    },
    updateValue: (value: any) => {
      for (const key in value) {
        if (Object.prototype.hasOwnProperty.call(value, key)) {
          const element = value[key];
          const cr = fieldRef.current.find((k, index) => {
            return k?.key === key;
          });
          if (!!cr) {
            cr.controls.updateValue(element);
          }
        }
      }
    },
  }));

  const renderContent = () => {
    if (!headers) {
      return <></>;
    }

    const groupedFields = headers
      ?.sort((a: any, b: any) => a?.fieldGroup - b?.fieldGroup)
      ?.reduce((prev: any, curr: any) => {
        prev[curr?.fieldGroup] = prev[curr?.fieldGroup] || [];
        prev[curr?.fieldGroup].push(curr);
        return prev;
      }, []);

    return (
      <div className="array-control-inline-form">
        {groupedFields.map((groupedField: any, rowIndex: number) => {
          const classNameValue = `p-col p-col-12 p-lg-${Math.trunc(
            12 / groupedField.length
          )} p-md-12 p-sm-12`;
          return (
            <div
              className="p-grid"
              key={`array-control-inline-form-grid-${rowIndex}`}
            >
              {groupedField.map((header: any, index: number) => {
                const { config, fieldGroup, ...rest } = header;

                rest.onChange = (params: any) => {
                  if (onTrueUpdateValue) {
                    onTrueUpdateValue(params);
                  }
                  return true;
                };

                return (
                  <div
                    className={classNameValue}
                    key={`array-control-inline-form-field-${index}`}
                  >
                    <ArrayControlBaseField
                      ref={(el: ArrayControlRefHandler) => {
                        const refObject: ArrayControlRefHandlerObject = {
                          key: header.key,
                          controls: el,
                        };
                        fieldRef.current[headers.indexOf(header)] = refObject;
                      }}
                      config={config}
                      {...rest}
                      prevvalue={prevvalue[header.key]}
                      fieldKey={header.key}
                    />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  return <>{renderContent()}</>;
});

export default ArrayControlBaseForm;
