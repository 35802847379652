import "./common-header.scss";
import { Animated } from "react-animated-css";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { useTranslation } from "react-i18next";
import { Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";

import EmployeeTerms from "./../../terms-and-conditions/terms-and-conditions";
import EmployeePrivacy from "./../../privacy-policy/privacy-policy";

import Page404 from "./components/error/error-404";
import Page500 from "./components/error/error-500";

import {
  MODULE_CODE,
  LOGIN_PATH,
  QUERY_PARAMS,
} from "./../../../../constants/index";
import { LoginLink } from "./../../../../services/utils/index";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { CompareParamValue } from "../../../../utils/utils";

export const renderLeftHeader = (t) => {
  const isMobileApp = CompareParamValue(QUERY_PARAMS.TYPE, QUERY_PARAMS.MOBILE);
  const isNotErrorPage =
    window.location.pathname
      .split("/")
      .includes(`${MODULE_CODE.PrivacyPolicy}`) ||
    window.location.pathname
      .split("/")
      .includes(`${MODULE_CODE.TermCondition}`);
  const isDisplay =
    isMobileApp &&
    (window.REACT_APP_ENVIRONMENT === "Dev" ||
      window.REACT_APP_ENVIRONMENT === "UAT") &&
    !isNotErrorPage;
  if (isDisplay) {
    function goBack() {
      window.location.replace(
        `${window.location.origin}/${LOGIN_PATH.CLIENT_CONFIGURATION}?${QUERY_PARAMS.TYPE}=${QUERY_PARAMS.MOBILE}`
      );
    }
    return (
      <div className="login-go-back">
        <Button
          label={t("login_change_client")}
          className="p-button-rounded p-button-text"
          onClick={() => goBack()}
        />
      </div>
    );
  }
};

// routes settings
const routes = [
  {
    path: `${MODULE_CODE.TermCondition}`,
    component: EmployeeTerms,
  },
  {
    path: `${MODULE_CODE.PrivacyPolicy}`,
    component: EmployeePrivacy,
  },
];

const CommonHeader = ({
  isNotFound = false,
  isSystemError = false,
  ...rest
}) => {
  const { t } = useTranslation();
  const clientInfo = useSelector((state) => state.global.clientInfo);
  const commonHeaderRoutes = Array.from(
    routes.filter((r) => r.path),
    (route) => route.path
  );

  const renderContent = () => {
    return (
      <Switch>
        {routes.map((route, i) => {
          return (
            <Route
              key={i}
              path={`/${route.path}`}
              render={() => <route.component />} //TODO
              exact
            />
          );
        })}
        {isNotFound && <Route component={Page404} />}
        {isSystemError && <Route component={Page500} />}
      </Switch>
    );
  };

  const renderHeader = () => {
    return (
      <div className="common-header">
        <Toolbar
          id="ep-login-main-top-toolbar"
          className="ep-login-top-toolbar"
          right={() => renderLeftHeader(t)}
          left={
            <a href={LoginLink(clientInfo)}>
              <LazyLoadImage
                className="common-entity-logo"
                alt={t("common_logo")}
                effect="blur"
                src="/assets/images/logo.png"
              />
            </a>
          }
        />
      </div>
    );
  };

  const renderMain = () => {
    if (
      isNotFound ||
      isSystemError ||
      commonHeaderRoutes.includes(window.location.pathname.split("/")[1])
    ) {
      return (
        <>
          {renderHeader()}
          <div className="common-content">{renderContent()}</div>
        </>
      );
    }

    return rest.children;
  };

  return (
    <>
      <Animated
        animationIn="slideInRight"
        animationOut="slideOutRight"
        animationInDuration={200}
        animationOutDuration={200}
        isVisible={true}
        className="common-animated"
      >
        {renderMain()}
      </Animated>
    </>
  );
};

export default CommonHeader;
