import { SET_COUNTDOWN_TIMER } from "../reduxConstants";
const initialState = {
  userCustomizeCdTime: 0,
  countdownTime: 0,
  isCountingDown: false,
};

export default function countdownTimer(state = initialState, action) {
  switch (action.type) {
    case SET_COUNTDOWN_TIMER: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
