import "./approve-claim-control.scss";

import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";

import {
  BCProps,
  BCStates,
  validateControl,
} from "../../../../components/base-control/base-control";

import { ClaimTable } from "../components";

export interface ApproveClaimConrolProps extends BCProps {
  data: any;
  onChange?: any;
  attachmentConfig?: any; // AttachmentControlConfig
  onFileError?: any;
}

export interface ApproveClaimControlState extends BCStates {}

const ApproveClaimControl: React.FC<ApproveClaimConrolProps> = (props) => {
  const { t } = useTranslation();

  // extract props data
  const ruleList = props.ruleList || [];
  if (props.required) {
    ruleList.push({
      name: "required",
    });
  }

  // init state control
  let initState: ApproveClaimControlState = {
    touched: false,
    value: props.value,
    valueStr: (props.value || "").toString(),
    controlState: {
      invalid: false,
    },
  };
  initState.controlState =
    props.controlState || validateControl(ruleList || [], initState.value, t);
  const [state, setState] = useState(initState);

  // Update state if control state changed
  useEffect(() => {
    const ruleList = props.ruleList || [];
    if (props.required) {
      ruleList.push({
        name: "required",
      });
    }

    let controlState =
      props.controlState || validateControl(ruleList || [], props.value, t);

    setState({
      ...state,
      value: props.value,
      setDefault: true,
      controlState,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.controlState, props.required, props.value]);

  // Update if rule update
  useEffect(() => {
    onChange(state.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.required, props.ruleList]);

  const onChange = async (value: any) => {
    const controlState = { invalid: false };
    let valueStr = "";
    if (props.onChange) {
      props.onChange(value, valueStr, controlState);
    }
  };

  const changeHandler = (change: any) => {
    let _value = { ...state.value };
    Object.keys(change).forEach((id) => {
      _value[id] =
        id in _value
          ? {
              ..._value[id],
              ...change[id],
            }
          : {
              ...change[id],
            };
    });
    onChange(_value);
    setState({
      ...state,
      value: _value,
    });
  };

  const validateFiles = (files: any[]) => {
    const attachmentConfig = props.attachmentConfig;

    let isValid = true;

    // file size check
    if (attachmentConfig.allowedFileSize) {
      isValid = files.every((x: File) => {
        return x.size <= attachmentConfig.allowedFileSize;
      });
    }

    // file extension check
    if (attachmentConfig.allowedFileExt) {
      files.every((x: any) => {
        return attachmentConfig.allowedFileExt
          ?.split(",")
          .includes(x.name.toLowerCase().split(".").pop());
      });
      isValid =
        isValid &&
        files.every((x: any) =>
          attachmentConfig.allowedFileExt
            ?.split(",")
            .includes("." + x.name.toLowerCase().split(".").pop())
        );
    }
    return isValid;
  };

  return (
    <ClaimTable
      data={props.data.monthlyReceipts}
      approvalMode={true}
      claimChange={state.value}
      changeHandler={(change: any) => changeHandler(change)}
      attachmentConfig={props.attachmentConfig}
      validateFiles={validateFiles}
      onFileError={props.onFileError}
    />
  );
};

export default ApproveClaimControl;
