import "./leave-menu.scss";
import { TabMenu } from "primereact/tabmenu";
import { LEAVE_MENU_TYPE_KEY } from "./../constants/contants";

interface LeaveMenuProps {
  menu: any;
  currentPageKey?: string;
  redirectPage?: any;
  [key: string]: any;
}

export const LeaveMenu = ({ menu, currentPageKey }: LeaveMenuProps) => {
  if (!menu) return null;
  let selectedIndex = -1;

  const menuItems = menu
    .filter((m: any) => m.type === LEAVE_MENU_TYPE_KEY.MENU)
    .map((m: any, i: number) => {
      if (m.key === currentPageKey) {
        selectedIndex = i;
      }
      return {
        ...m,
        label: m.name,
        url: selectedIndex === 0 ? m.url : `/${m.url}`,
        icon: `leave-menu-icon-${m.key.toLowerCase()}`,
      };
    });

  return (
    <div className="leave-menu">
      <TabMenu
        className="leave-tab-menu"
        model={menuItems}
        activeIndex={selectedIndex}
      />
    </div>
  );
};

export const ScrollToLeaveMenuActiveItem = () => {
  let element = document.querySelector(
    ".header .leave-menu .p-tabmenuitem.p-highlight"
  );
  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
      inline: "start",
      block: "nearest",
    });
  }
};
