import { Toolbar } from "primereact/toolbar";
import { IMAGE_PATH } from "../../../../constants";
import CommonHeader, { renderLeftHeader } from "../common/common-header";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { LoginAlert, LoginFooter } from "./components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MainLanguage from "../../../layouts/components/main-header/main-language/main-language";
import { useMemo } from "react";

function LoginWrapper(props) {
  const storedClientInfo = useSelector((state) => state.global?.clientInfo);
  const { t } = useTranslation();
  const backgroundImg = {
    source: IMAGE_PATH.LOGIN_BACKGROUND,
    default: "",
  };

  const renderHeader = () => {
    if (props.renderRightHeader && !props?.renderLeftHeader) {
      return (
        <Toolbar
          id="ep-login-main-top-toolbar"
          className="ep-login-top-toolbar"
          right={props?.renderRightHeader}
        />
      );
    }

    if (props.renderRightHeader && !!props?.renderLeftHeader) {
      return (
        <Toolbar
          id="ep-login-main-top-toolbar"
          className="ep-login-top-toolbar"
          right={props?.renderRightHeader}
          left={props?.renderLeftHeader}
        />
      );
    }

    const renderRightHeader = () => {
      return (
        <div className="login-language">
          <MainLanguage isAuthenticated={false} />
        </div>
      );
    };

    return (
      <Toolbar
        id="ep-login-main-top-toolbar"
        className="ep-login-top-toolbar"
        right={renderRightHeader}
        left={renderLeftHeader(t)}
      />
    );
  };

  const renderTenantLogo = (logo) => {
    if (!!logo) {
      return (
        <div className="ep-login-header">
          <LazyLoadImage alt="logo" effect="blur" src={logo} className="logo" />
        </div>
      );
    }

    return;
  };

  const renderDisplayName = (displayName) => {
    if (!!displayName) {
      return (
        <span>
          <h1 className="login-form-entity-display-name">{displayName}</h1>
        </span>
      );
    }

    return;
  };

  const renderFooter = () => {
    return <LoginFooter />;
  };

  const renderContent = useMemo(() => {
    if (props.error) {
      return <CommonHeader isNotFound={true} />;
    }

    let _clientInfo = { ...storedClientInfo };

    if (!!props.clientInfo) {
      _clientInfo = { ...props.clientInfo };
    }

    return (
      <div className={`ep-login ${props.className}`} id={props.id}>
        <div className="p-grid p-grid-fullpage ep-login-no-margin">
          <div className="p-col-12 p-md-6 p-lg-9 p-d-none p-d-md-block ep-login-bg">
            <div className={`ep-login-img-full${backgroundImg.default}`}>
              <LazyLoadImage
                alt="logo"
                effect="blur"
                src={backgroundImg.source}
              />
            </div>
            <div className="ep-login-info">
              <div className="p-grid">
                <div className="p-col-11 p-md-11 p-lg-9 p-offset-1"></div>
              </div>
            </div>
          </div>
          <div className="p-col-12 p-md-6 p-lg-3 ep-login-panel">
            {renderHeader()}
            <div className="ep-login-panel-form">
              <div className="ep-login-header">
                <LazyLoadImage
                  className="logo"
                  alt={t("common_logo")}
                  effect="blur"
                  src="/assets/images/logo.png"
                />
              </div>
              {renderTenantLogo(_clientInfo?.entityLogoUrl)}
              {renderDisplayName(_clientInfo?.entityDisplayName)}
              <LoginAlert />
              <div className="ep-login-body">{props.children}</div>
            </div>
            {renderFooter()}
          </div>
        </div>
      </div>
    );
  }, [props, storedClientInfo]);

  return renderContent;
}

export default LoginWrapper;
