import "./employment.scss";

import { Animated } from "react-animated-css";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Skeleton } from "primereact/skeleton";

import BaseForm from "./../../../../../components/base-form/base-form";

// services
import { EmployeeDetailsService } from "./../../../../../services/hrmnet-api";
import { EmployeeTabConfigurationService } from "./../../../../../services/hrmnet-api";

import MultiEntititesDropDown from "./multi-entities-drop-down/multi-entities-drop-down";

//components
import {
  MyInfoSkeleton,
  FormControlsGenerator,
  ToPascalCase,
  GetControlType,
} from "./components/utils";
import SectionWithDataArray from "./components/section-with-data-array";
import DataArrayWithSections from "./components/data-array-with-sections";
import { PortalSystemErrorLink } from "../../../../../services/utils";

const Employment = (props) => {
  const clientInfo = useSelector((state) => state.global?.clientInfo);
  const { t } = useTranslation();
  let history = useHistory();
  const [state, setState] = useState({
    loading: true,
    i18nConstant: "eeinfo_emp_",
    hideTooltip: t("common_reveal"),
    sectionWithChild: [{ sectionCode: "EMP_TER", dataKey: "terminations" }],
    childWithSection: [
      // {
      //   sectionCodes: [
      //     "EMP_BASIC",
      //     "EMP_LVG",
      //     "EMP_PAY",
      //     "EMP_ADD",
      //     "EMP_TER",
      //     "EMP_CNTRCT_BASIC",
      //     "EMP_CNTRCT_ADD",
      //   ],
      //   dataKey: "all",
      //   title: "employments",
      //   childTitle: "employment",
      // },
    ],
    sections: [],
  });
  const [mapMasked, setMapMasked] = useState(new Map([]));
  const [selectedClient, setSelectedClient] = useState();
  const [entitiesList, setEntitiesList] = useState([]);

  const mountedRef = useRef(true);
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    const loadEntitiesList = async () => {
      var res = await EmployeeDetailsService.employeeGetEmployeeEntities();
      if (res && res.data) {
        const _entitiesList = res.data.map((data) => {
          return {
            ...data,
            key: data.value,
            label: data.name,
          };
        });

        let _selectedEntity =
          _entitiesList.find((x) => x.key === clientInfo.entityCode) ??
          _entitiesList[0];
        setSelectedClient(_selectedEntity);
        setEntitiesList(_entitiesList);
      }
    };

    loadEntitiesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const loadData = async () => {
      setState({ ...state, loading: true });
      let enumData = {};

      try {
        const cmd2 =
          EmployeeTabConfigurationService.employeeConfigurationGetEmploymentTabConfigurations();

        const cmd3 = EmployeeDetailsService.employeeGetEmploymentDetails({
          entityCode: selectedClient.key,
        });

        const [res2, res3] = await Promise.all([cmd2, cmd3]);
        let data = {};
        let sections = [];
        let currSection = [];
        let childSections = [];

        if (res3 && res3.data) {
          // data = res3.data;
          //TODO: conduct manual data mapping
          /* new API JSON format
        clockCardNo: ""
        companyCode: "DEMO01" //ABSENT IN FIELD *NEED TO ADD*
        companyName: "" //ABSENT IN FIELD *NEED TO ADD*
        contractEndDate: ""
        contractStartDate: ""
        employeeGlobalNo: null
        employeeNo: "HK004"
        entityStartDate: "01/01/2013"
        payslipPassword: ""
        yearOfServiceStartDate: "01/01/2013"
         */
          data = res3.data;
          // data["globalNo"] = res3.data?.employeeGlobalNo;
          // data["yearServiceStartDate"] = res3.data?.yearOfServiceStartDate;
          // data["company"] = res3.data?.companyName;
        }

        if (res2 && res2.data) {
          sections = res2.data;
          if (sections.length > 0) {
            currSection = sections.map((section, sIndex) => {
              let keyToPascalFields = [];
              if (section.fields.length > 0) {
                const visiblefields = section.fields.filter(
                  (x) => x.isVisible === true
                );
                keyToPascalFields = visiblefields.map((field, fIndex) => {
                  const pcKey = ToPascalCase(field.key);
                  if (field.isVisible) {
                    return {
                      ...field,
                      key: pcKey,
                      controlType: GetControlType(data[pcKey], pcKey),
                      fieldConfig: {
                        isVisible: field.isVisible,
                        isMasked: field.isMasked,
                        isEditable: field.isEditable,
                      },
                    };
                  }
                  return {};
                });
              }

              return {
                ...section,
                fields: keyToPascalFields,
              };
            });
          }
          sections = currSection.filter((x) => x.fields.length > 0);
        }

        if (sections.length === 0 || sections === undefined) {
          props.setTabViewVisibility(true);
          history.go(-1);
        } else if (
          state.childWithSection.length > 0 &&
          sections !== undefined &&
          sections.length > 0
        ) {
          state.childWithSection.forEach((childWithSection) => {
            const filteredArray = sections.filter((value) =>
              childWithSection.sectionCodes.includes(value.sectionCode)
            );
            sections = sections.filter((x) => !filteredArray.includes(x));
            childSections.push({
              sections: filteredArray,
              data:
                childWithSection.dataKey === "all"
                  ? data
                  : data[childWithSection.dataKey],
              title: childWithSection.title,
              childTitle: childWithSection.childTitle,
            });
          });
        }

        let _state = {
          ...state,
          enumData,
          data: data,
          sections: sections,
          cSections: childSections,
          loading: false,
        };

        setState(_state);
      } catch (error) {
        history.push(PortalSystemErrorLink());
      }
    };
    if (selectedClient && selectedClient.key) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient]);

  /**
   *
   * @param {*} clsName
   * @param {*} controlName
   * @returns
   */
  const employmentColConfig = (
    controlName,
    clsName = "p-col-12 p-md-6 p-lg-4"
  ) => {
    return {
      config: {
        className: clsName,
      },
      control: controlName,
    };
  };

  const employmentField = (fields) => {
    return {
      controls: fields.length > 0 || fields !== undefined ? fields : null,
      layout:
        fields.length === 0 || fields === undefined
          ? {}
          : {
              rows: [
                //[View_Section_ID] = 32
                {
                  columns: [
                    employmentColConfig("company"), //not in db
                    employmentColConfig("companyCode"), //not in db
                    employmentColConfig("companyName"),
                  ],
                },
                {
                  columns: [
                    employmentColConfig("employeeId", "p-col-12 p-md-4 p-lg-4"), //not in db
                    employmentColConfig(
                      "employmentContractId",
                      "p-col-12 p-md-4 p-lg-4"
                    ), //not in db
                    employmentColConfig("contractNo", "p-col-12 p-md-4 p-lg-4"), //not in db
                  ],
                },
                {
                  columns: [
                    employmentColConfig("employeeNo", "p-col-12 p-md-4 p-lg-4"),
                    employmentColConfig("globalNo", "p-col-12 p-md-4 p-lg-4"), // not in db
                    employmentColConfig(
                      "employeeGlobalNo",
                      "p-col-12 p-md-4 p-lg-4"
                    ),
                    employmentColConfig(
                      "clockCardNo",
                      "p-col-12 p-md-4 p-lg-4"
                    ),
                  ],
                },
                {
                  columns: [
                    employmentColConfig(
                      "contractStartDate",
                      "p-col-12 p-md-4 p-lg-4"
                    ),
                    employmentColConfig(
                      "contractEndDate",
                      "p-col-12 p-md-4 p-lg-4"
                    ),
                    employmentColConfig(
                      "yearServiceStartDate",
                      "p-col-12 p-md-4 p-lg-4"
                    ), // not in db
                    employmentColConfig(
                      "yearOfServiceStartDate",
                      "p-col-12 p-md-4 p-lg-4"
                    ),
                  ],
                },
                {
                  columns: [
                    employmentColConfig(
                      "entityStartDate",
                      "p-col-12 p-md-4 p-lg-4"
                    ),
                  ],
                },
                //[View_Section_ID] = 33
                {
                  columns: [
                    employmentColConfig(
                      "payslipPassword",
                      "p-col-12 p-md-6 p-lg-6"
                    ),
                    employmentColConfig(
                      "receiveEPayslip",
                      "p-col-12 p-md-6 p-lg-6"
                    ), //not in db
                  ],
                },
                // [View_Section_ID] = N/A - R2 only //not in db
                {
                  columns: [employmentColConfig("contractRemarks", "p-col-12")],
                },
                {
                  columns: [
                    employmentColConfig("contractReserveFields", "p-col-12"),
                  ],
                },
                {
                  columns: [employmentColConfig("remarks", "p-col-12")],
                },
                {
                  columns: [employmentColConfig("reserveFields", "p-col-12")],
                },
                // [View_Section_ID] = N/A - R2 only //not in db
                {
                  columns: [
                    employmentColConfig("confirmed", "p-col-12 p-md-4 p-lg-2"),
                    employmentColConfig("reHired", "p-col-12 p-md-4 p-lg-2"),
                  ],
                },
                {
                  columns: [
                    employmentColConfig(
                      "holdLastPayment",
                      "p-col-12 p-md-6 p-lg-4"
                    ),
                    employmentColConfig(
                      "suspendPayroll",
                      "p-col-12 p-md-6 p-lg-4"
                    ),
                  ],
                },
                {
                  columns: [
                    employmentColConfig("defaultPaymentMethod"),
                    employmentColConfig("defaultPayrollRun"),
                    employmentColConfig("erBankAccount"),
                  ],
                },
                {
                  columns: [
                    employmentColConfig(
                      "leavingHKAfterTermination",
                      "p-col-12 p-md-6 p-lg-4"
                    ),
                  ],
                },
                {
                  columns: [employmentColConfig("seven13StartDate")],
                },
                {
                  columns: [
                    employmentColConfig("probationEndDate"),
                    employmentColConfig("probationType"),
                    employmentColConfig("probationUnit"),
                  ],
                },
                {
                  columns: [
                    employmentColConfig("statusCode"),
                    employmentColConfig("statusEndDate"),
                    employmentColConfig("statusStartDate"),
                  ],
                },
                {
                  columns: [
                    employmentColConfig("lastEmploymentDate"),
                    employmentColConfig("lastWorkingDate"),
                    employmentColConfig("longServicePaymentEndDate"),
                  ],
                },
                {
                  columns: [
                    employmentColConfig("noticePeriodType"),
                    employmentColConfig("noticePeriodUnit"),
                    employmentColConfig("notificationDate"),
                  ],
                },
                {
                  columns: [employmentColConfig("payUntil")],
                },
                {
                  columns: [
                    employmentColConfig("terminationCodeMPF"),
                    employmentColConfig("terminationReason"),
                  ],
                },
                {
                  columns: [
                    employmentColConfig("terminationReasonAlternative"),
                    employmentColConfig("terminationReasonClient"),
                    employmentColConfig("terminationReasonTax"),
                  ],
                },
                {
                  columns: [
                    employmentColConfig("yearOfServices"),
                    employmentColConfig("yearServiceEndDate"),
                  ],
                },
              ],
            },
    };
  };

  const renderSection = (index, sectionTitle, fields, sectionCode) => {
    return (
      <div id={index} key={index}>
        <div className="c-group-title ep-language-toogle">
          <p>
            {t(
              state.i18nConstant +
                sectionTitle.replace("-", "_").replace(/ /g, "").toLowerCase()
            )}
          </p>
        </div>
        {
          <div className="c-group-panel">
            {state.sectionWithChild.findIndex(
              (x) => x.sectionCode === sectionCode
            ) > -1 ? (
              // <></>
              <SectionWithDataArray
                currIndex={index}
                noDataLabel={t("common_noData")}
                cData={
                  state.sectionWithChild.find(
                    (x) => x.sectionCode === sectionCode
                  ).dataKey === "all"
                    ? state.data
                    : state.data?.[
                        state.sectionWithChild.find(
                          (x) => x.sectionCode === sectionCode
                        ).dataKey
                      ]
                } //MANUAL, need to change to auto
                readOnly={props.readOnly ?? true}
                panelTitle={t(
                  state.i18nConstant +
                    sectionTitle
                      .replace("-", "_")
                      .replace(/ /g, "")
                      .toLowerCase()
                )}
                formStructure={employmentField}
                fields={fields}
                hideTooltip={t(state.hideTooltip)}
                enumData={state.enumData}
                t={t}
                i18nConstant={state.i18nConstant}
              />
            ) : (
              // <></>
              <BaseForm
                id={"employeeContact" + index}
                config={employmentField(
                  FormControlsGenerator(
                    fields,
                    t(state.hideTooltip),
                    mapMasked,
                    setMapMasked,
                    state.enumData,
                    t,
                    state.i18nConstant
                  )
                )}
                readOnly={props.readOnly ?? true}
                form={state.data}
              />
            )}
          </div>
        }
      </div>
    );
  };

  const renderContent = (
    <Animated
      animationIn="slideInRight"
      animationOut="slideOutRight"
      animationInDuration={200}
      animationOutDuration={200}
      isVisible={true}
    >
      {state.sections === null ? (
        { MyInfoSkeleton }
      ) : (
        <>
          {state?.childSections?.map((item, index) => {
            // console.log("--index--", index, state?.childSections);
            return (
              <div id={index} key={index}>
                <div className="c-group-title ep-language-toogle">
                  <p>
                    {t(
                      state.i18nConstant +
                        item.title
                          .replace("-", "_")
                          .replace(/ /g, "")
                          .toLowerCase()
                    )}
                  </p>
                </div>
                <div className="c-group-panel">
                  <DataArrayWithSections
                    cData={item.data}
                    sections={item.sections}
                    noDataLabel={t("common_noData")}
                    formStructure={employmentField}
                    i18nConstant={state.i18nConstant}
                    hideTooltip={state.hideTooltip}
                    panelTitle={t(state.i18nConstant + item.childTitle)}
                    readOnly={state.readOnly ?? true}
                    enumData={state.enumData}
                    sectionWithChild={state.sectionWithChild}
                  />
                </div>
              </div>
            );
          })}
          {state?.sections?.map((section, index) =>
            renderSection(
              index,
              "employment_" + section.sectionName,
              section.fields,
              section.sectionCode
            )
          )}
        </>
      )}
    </Animated>
  );
  return (
    <>
      <MultiEntititesDropDown
        entitiesList={entitiesList}
        setSelectedClient={setSelectedClient}
        selectedClient={selectedClient}
      />
      {state.loading ? <Skeleton /> : renderContent}
    </>
  );
};

export default Employment;
