import { getColumModel } from "./../../../components/base-table/base-table-model";
import { getBTConfig } from "./../../../components/base-table/base-table-model";

import BaseTable from "./../../../components/base-table/base-table-portal";
import { BUILD_SEARCH_CONFIG } from "./../../../components/base-table/base-table-portal";
import { DEFAULT_BT_SEARCH } from "./../../../components/base-table/base-table-model";

import { Button } from "primereact/button";
import { FILTER_MODE, MATCH_MODE } from "../../../constants";
import { BCType } from "../../../components/base-control/base-control";

/**
 *
 * @param {*} header
 * @param {*} key
 * @param {*} control
 * @param {*} hideInMobile
 * @param {*} frozen
 * @param {*} dataType
 * @param {*} config
 * @param {*} body
 * @returns
 */
export const columnConfig = (
  header,
  key,
  control,
  hideInMobile,
  frozen = false,
  dataType = "string",
  config = {},
  body = null,
  expanderWithBody = null,
  isAuto = "auto"
) => {
  let _columnConfig = {
    header: header,
    key: key,
    frozen: frozen,
    dataType: dataType,
    width: frozen ? 20 : 5,
    control: control,
    mobileWidthAuto: true,
    hideInMobile: hideInMobile,
    config: config,
    expanderWithBody: expanderWithBody,
  };

  if (dataType === BCType.date) {
    _columnConfig.filterMatchMode = MATCH_MODE.RANGE;
  }

  if (body) {
    _columnConfig.render = body;
  }

  if (isAuto) {
    _columnConfig.isAuto = isAuto;
  }

  return getColumModel(_columnConfig);
};

const documentRowExpansionTemplate = (data, columns) => {
  const tableSearchFn = (searchConfig) => {
    if (!searchConfig) {
      searchConfig = { ...BT_SEARCH };
    }
    searchConfig = BUILD_SEARCH_CONFIG(searchConfig);
    return {
      datas: [data],
      total: data.length,
      searchConfig: searchConfig,
    };
  };

  const BT_SEARCH = {
    ...DEFAULT_BT_SEARCH,
    sortObj: {
      key: "fileName",
      direction: 1,
    },
  };
  const _tableConfig = getBTConfig({
    columns: columns
      .filter((x) => x.hideInMobile)
      .map((column, i) => {
        return {
          ...column,
          hideInMobile: false,
        };
      }),
  });

  return (
    <BaseTable
      configModel={_tableConfig}
      searchConfig={BT_SEARCH}
      searchFn={() => tableSearchFn(null)}
      isClientSize={true}
    />
  );
};

/**
 *
 * @param {*} t
 * @param {string} title
 * @param {string} description
 * @param {*} columns
 * @param {*} onSingleDownload
 * @param {*} state
 * @param {*} setState
 * @param {boolean} noExpansionTemplate
 * @param {"pi pi-download"|*} actionIcon
 * @param {*} customToolbar
 * @param {boolean} isExpandInMobileOnly
 * @param {("single"|"multiple")} sortMode
 * @param {boolean} hasColumnSelector
 * @param {*} rowExpansionTemplate
 * @returns
 */
export const tableConfigWithExpander = (
  t,
  title,
  description,
  columns,
  onSingleDownload,
  state,
  setState,
  noExpansionTemplate = false,
  actionIcon = "pi pi-download",
  customToolbar = null,
  isExpandInMobileOnly = true,
  sortMode = FILTER_MODE.SINGLE,
  conditionalClassName,
  hasColumnSelector = false,
  rowExpansionTemplate = documentRowExpansionTemplate,
  onRowToggle = null,
  childActionMulti = null,
  childActionCancel = null,
  expander = null
) => {
  const expandAll = () => {
    let newState = {
      ...state,
      expandedRows: state?.displayedRecord,
      expandAllDisabled: true,
      collapseAllDisabled: false,
    };
    setState(newState);
  };

  const collapseAll = () => {
    let newState = {
      ...state,
      expandedRows: null,
      expandAllDisabled: false,
      collapseAllDisabled: true,
    };
    setState(newState);
  };

  const header = (
    <div className="table-header-container p-grid">
      <Button
        disabled={
          state?.expandAllDisabled ||
          state?.displayedRecord.length === 0 ||
          state?.displayedRecord === undefined
        }
        iconPos="right"
        icon="pi pi-plus"
        label={t("base_table_expand_all")}
        onClick={expandAll}
        className="button-enabled"
      />
      <Button
        disabled={
          state?.collapseAllDisabled === undefined || state?.collapseAllDisabled
        }
        iconPos="right"
        icon="pi pi-minus"
        label={t("base_table_collapse_all")}
        onClick={collapseAll}
        className="button-enabled"
      />
    </div>
  );

  if (onRowToggle === null) {
    onRowToggle = (e) => {
      let newState = {
        ...state,
        expandedRows: e.data,
        expandAllDisabled: e.data.length === state.displayedRecord.length,
        collapseAllDisabled: e.data.length === 0,
      };
      setState(newState);
    };
  }

  if (noExpansionTemplate) {
    return getBTConfig({
      title: title,
      description: description,
      columns: columns,
      actionsSingle: onSingleDownload
        ? [
            {
              conditionalClassName: conditionalClassName,
              renderIcon: actionIcon,
              clickFn: (item) => {
                onSingleDownload(item);
              },
            },
          ]
        : null,
      hasIndex: false,
      mode: "list",
      defaultMode: "list",
      showGlobal: true,
      isExpandable: true, // columns config - web + mobile
      isExpandInMobileOnly: isExpandInMobileOnly, // columns config - mobile only
      mobileHeader:
        columns.filter((x) => x.hideInMobile).length > 0 ? header : null,
      actionMobileHeader: "",
      customToolbar: customToolbar,
      sortMode: sortMode,
      hasColumnSelector: hasColumnSelector,
      childActionMulti: childActionMulti,
      childActionCancel: childActionCancel,
      expander: expander,
    });
  }

  return getBTConfig({
    title: title,
    description: description,
    columns: columns,
    actionsSingle: onSingleDownload ? (
      [
        {
          conditionalClassName: conditionalClassName,
          renderIcon: actionIcon,
          clickFn: (item) => {
            onSingleDownload(item);
          },
        },
      ]
    ) : (
      <></>
    ),
    hasIndex: false,
    mode: "list",
    defaultMode: "list",
    showGlobal: true,
    isExpandable: true, // columns config - web + mobile
    isExpandInMobileOnly: isExpandInMobileOnly, // columns config - mobile only
    mobileHeader:
      columns.filter((x) => x.hideInMobile).length > 0 ? header : null,
    actionMobileHeader: "",
    expandedRows: state?.expandedRows,
    onRowToggle: (e) => onRowToggle(e),
    rowExpansionTemplate: (e) => rowExpansionTemplate(e, columns),
    customToolbar: customToolbar,
    sortMode: sortMode,
    hasColumnSelector: hasColumnSelector,
    childActionMulti: childActionMulti,
    childActionCancel: childActionCancel,
    expander: expander,
    // actionsMulti: [
    //   {
    //     renderIcon: "pi pi-folder",
    //     title: "Download",
    //     isOverflow: true,
    //     showOnGrid: true,
    //     clickFn: async (data) => console.log("-testing"),
    //   },
    // ],
  });
};

/**
 * Expander Template DataTable
 * @param {*} t
 * @param {*} columnConfig
 * @param {*} singleAction
 * @param {*} singleActionIcon
 * @param {*} sortMode
 * @param {*} actionsMulti
 * @param {*} onSelectionChange
 * @returns
 */
export const tableConfigChild = (
  t,
  columnConfig,
  singleAction,
  singleActionIcon,
  sortMode,
  actionsMulti,
  onSelectionChange = null,
  virtualScrollerOptions = null,
  scrollHeight = null,
  displayColumnFilter = true
) => {
  return getBTConfig({
    columns: columnConfig,
    actionsSingle: singleAction ? (
      [
        {
          renderIcon: singleActionIcon,
          clickFn: (item) => {
            singleAction(item);
          },
        },
      ]
    ) : (
      <></>
    ),
    hasIndex: false,
    mode: "list",
    defaultMode: "list",
    showGlobal: false,
    isExpandable: true,
    isExpandInMobileOnly: false,
    actionMobileHeader: "",
    mobileHeader: null,
    sortMode: sortMode,
    actionsMulti: actionsMulti,
    onBaseSelectionChange: onSelectionChange,
    virtualScrollerOptions: virtualScrollerOptions,
    scrollHeight: scrollHeight,
    displayColumnFilter: displayColumnFilter,
  });
};

const headerTemplate = (data, groupField, defaultSubheader) => {
  const groupColumn = data[groupField] || "";
  if (groupColumn === "") {
    return <div title={defaultSubheader}>{defaultSubheader}</div>;
  }
  return <div title={groupColumn}>{groupColumn}</div>;
};

const footerTemplate = () => {
  return null;
};

/**
 *
 * @param {*} tableConfig
 * @param {*} defaultSubheader
 * @param {*} groupField
 * @returns
 */
export const addRowGroupHeaderFooterTemplate = (
  tableConfig,
  defaultSubheader,
  groupField = "companyName"
) => {
  tableConfig.rowGroupMode = "subheader";
  tableConfig.groupField = groupField;
  tableConfig.rowGroupHeaderTemplate = (data) =>
    headerTemplate(data, groupField, defaultSubheader);
  tableConfig.rowGroupFooterTemplate = footerTemplate;
  return tableConfig;
};

/**
 * Check current state is mobile or not
 * @return {boolean} True/ False
 */
export const checkMobile = () => {
  // 1 rem = 16 px
  var viewportWidth = window.innerWidth;
  return viewportWidth <= 768;
};
