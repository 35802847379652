import "./kendo-editor-control.scss";

import { columnResizing } from "prosemirror-tables";
import {
  Editor,
  EditorChangeEvent,
  EditorTools,
  EditorUtils,
  ProseMirror,
} from "@progress/kendo-react-editor";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { InsertImage } from "./kendo-insert-image-tool";
import { insertImagePlugin } from "./kendo-insert-image-plugin";
import { checkKendoEditorTag, insertImageFiles } from "./kendo-utils";
import FindReplaceDialog from "./kendo-find-replace-dialog";
import {
  BCProps,
  BCStates,
  isError,
  renderError,
  validateControl,
} from "../base-control";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import {
  CustomAutoCompleteDataObject,
  KendoCustomDropDownMenu,
} from "./kendo-custom-tools";
import { KendoDefaultInsertCode } from "./kendo-editor-default-setup";
import { LocalizationProvider } from "@progress/kendo-react-intl";
import { jsonEqual } from "../../../utils/utils";
const { Schema, EditorView, EditorState, keymap } = ProseMirror;
const {
  imageResizing,
  textHighlight,
  pasteCleanup,
  sanitize,
  sanitizeClassAttr,
  sanitizeStyleAttr,
  removeAttribute,
  replaceImageSourcesFromRtf,
} = EditorUtils;

const pasteSettings = {
  convertMsLists: true,
  // stripTags: 'span|font'
  attributes: {
    class: sanitizeClassAttr,
    style: sanitizeStyleAttr,
    // keep `width`, `height` and `src` attributes
    width: () => {},
    height: () => {},
    src: () => {},
    // Removes `lang` attribute
    // lang: removeAttribute,
    // removes other (unspecified above) attributes
    "*": removeAttribute,
  },
};

const {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  FindAndReplace,
  Subscript,
  Superscript,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  Indent,
  Outdent,
  OrderedList,
  UnorderedList,
  Undo,
  Redo,
  FontSize,
  FontName,
  FormatBlock,
  Link,
  Unlink,
  ViewHtml,
  InsertTable,
  AddRowBefore,
  AddRowAfter,
  AddColumnBefore,
  AddColumnAfter,
  DeleteRow,
  DeleteColumn,
  DeleteTable,
  MergeCells,
  SplitCell,
  ForeColor,
  BackColor,
  CleanFormatting,
  SelectAll,
  InsertFile,
  Pdf,
  Print,
} = EditorTools;

export const DefaultKendoEditorTools = [
  [Bold, Italic, Underline, Strikethrough, FindAndReplace],
  [Subscript, Superscript],
  ForeColor,
  BackColor,
  [CleanFormatting],
  [AlignLeft, AlignCenter, AlignRight, AlignJustify],
  [Indent, Outdent],
  [OrderedList, UnorderedList],
  FontSize,
  FontName,
  FormatBlock,
  [SelectAll],
  [Undo, Redo],
  [Link, Unlink, InsertImage, ViewHtml],
  [InsertTable, InsertFile],
  [Pdf, Print],
  [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
  [DeleteRow, DeleteColumn, DeleteTable],
  [MergeCells, SplitCell],
];

interface CustomInsertListToolObjectProps {
  placeholder: string;
  data: CustomAutoCompleteDataObject[];
}

export interface KendoEditorControlProps extends BCProps {
  config?: any;
}

export interface KendoEditorControlState extends BCStates {}

const KendoEditorControl: React.FC<KendoEditorControlProps> = (props) => {
  const { customInsertListSingle, customInsertListMultiple, ...configuration } =
    props.config;
  const { t, i18n } = useTranslation();
  // extract props
  const ruleList = props.ruleList || [];
  if (props.required) {
    ruleList.push({
      name: "required",
    });
  }

  // State
  let initState: KendoEditorControlState = {
    touched: false,
    value: props.value || "",
    valueStr: (props.value || "").toString(),
    controlState: {
      invalid: false,
    },
  };
  initState.controlState =
    props.controlState || validateControl(ruleList || [], initState.value, t);
  const [state, setState] = useState(initState);
  const mountedRef = useRef(true);
  const [showDialog, setShowDialog] = useState(false);
  // unsubcribe
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  // Update state if control state changed
  useEffect(() => {
    const ruleList = props.ruleList || [];
    if (props.required) {
      ruleList.push({
        name: "required",
      });
    }

    let controlState =
      props.controlState || validateControl(ruleList || [], props.value, t);
    if (!mountedRef.current) return;
    setState({
      ...state,
      value: props.value,
      setDefault: true,
      controlState,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.controlState, props.required, props.value]);

  const onImageInsert = (args: any) => {
    const { files, view, event } = args;
    const nodeType = view.state.schema.nodes.image;
    const position =
      event.type === "drop"
        ? view.posAtCoords({
            left: event.clientX,
            top: event.clientY,
          })
        : null;
    insertImageFiles({
      view,
      files,
      nodeType,
      position,
    });
    return files.length > 0;
  };

  function EditorOnMount(event: any) {
    const { viewProps } = event;
    const { schema } = viewProps.state;
    const plugins = [
      ...viewProps.state.plugins,
      imageResizing(),
      columnResizing({}),
      insertImagePlugin(onImageInsert),
      textHighlight(), // add a key binding Ctrl+F/Cmd+F which will open the `Find and Replace` Dialog.
      keymap({
        "Mod-f": () => {
          setShowDialog(true);
          return true;
        },
      }),
    ];

    // Append a new node.
    let nodes = schema.spec.nodes.addToEnd(
      "nonEditable",
      KendoDefaultInsertCode
    );

    // Create the new schema.
    const mySchema = new Schema({ nodes: nodes, marks: schema.spec.marks });

    // Create a new document using the modified schema.
    const doc = EditorUtils.createDocument(mySchema, state.value);

    // Return the custom EditorView object that will be used by Editor.
    return new EditorView(
      { mount: event.dom },
      {
        ...event.viewProps,
        state: EditorState.create({ doc, plugins }),
      }
    );
  }

  const onChange = async (e: EditorChangeEvent, updateLastDefault = false) => {
    const data = checkKendoEditorTag(e.html);
    let valueStr = data;
    const controlState = validateControl(ruleList, data, t);
    let _state = {
      ...state,
      value: data,
      valueStr,
      controlState,
      loading: false,
    } as any;
    if (props.onChange) {
      props.onChange({
        controlState: _state.controlState,
        value: _state.value,
        valueStr: _state.valueStr,
      });
    }
    if (props.onTrueUpdateValue) {
      props.onTrueUpdateValue({
        controlState: _state.controlState,
        value: _state.value,
        valueStr: _state.valueStr,
      });
    }

    if (!jsonEqual(_state, state)) {
      setState(_state);
    }
  };

  const createInsertListTool = (viewProps: any) => {
    if (!!customInsertListSingle) {
      return (
        <KendoCustomDropDownMenu
          view={viewProps.view}
          data={customInsertListSingle.data}
          placeholder={customInsertListSingle.placeholder}
        />
      );
    } else if (
      !!customInsertListMultiple &&
      customInsertListMultiple.length > 0
    ) {
      return customInsertListMultiple?.map(
        (x: CustomInsertListToolObjectProps, index: any) => {
          return (
            <KendoCustomDropDownMenu
              key={index}
              id={index}
              view={viewProps.view}
              data={x.data}
              placeholder={x.placeholder}
            />
          );
        }
      );
    }

    return null;
  };

  const renderComponent = useCallback(() => {
    return (
      <LocalizationProvider language={i18n.language}>
        <Editor
          tools={[createInsertListTool, ...DefaultKendoEditorTools]}
          onPasteHtml={(event) => {
            let html = pasteCleanup(sanitize(event.pastedHtml), pasteSettings); // If the pasted HTML contains images with sources pointing to the local file system,
            // `replaceImageSourcesFromRtf` will extract the sources from the RTF and place them to images 'src' attribute in base64 format.

            if (event.nativeEvent.clipboardData) {
              html = replaceImageSourcesFromRtf(
                html,
                event.nativeEvent.clipboardData
              );
            }

            return html;
          }}
          contentStyle={{ height: 300 }}
          className={`${props.className} ${
            isError(state, props) ? "p-invalid" : ""
          }`}
          onMount={(e) => EditorOnMount(e)}
          // defaultEditMode="div"
          onChange={onChange}
          value={state.value}
          {...configuration}
        />
      </LocalizationProvider>
    );
  }, [customInsertListSingle, state, configuration, i18n.language]);

  return (
    <>
      <div
        className={`kendo-editor-control-inner p-field ${
          props.noLabel ? "no-label" : ""
        }`}
      >
        <label htmlFor={props.id}>
          {props.label}
          {props.required && !props.noRequiredLabel ? (
            <small className="required p-invalid">&nbsp;*</small>
          ) : null}
          {props.tooltip ? (
            <Button
              type="button"
              tooltip={props.tooltip}
              tooltipOptions={{ position: "top" }}
              icon="pi pi-info-circle"
              className="p-button-rounded label-help p-button-text p-button-plain"
            />
          ) : null}
        </label>
        <div
          className={`p-inputgroup ${
            isError(state, props) ? "p-inputgroup-error" : ""
          }`}
        >
          {renderComponent()}
          <FindReplaceDialog
            showDialog={showDialog}
            setShowDialog={setShowDialog}
          />
          {props.hintRight && (
            <span className={"control-hint-right"}>{props.hintRight}</span>
          )}
        </div>
        {props.hintBottom && (
          <div className={"control-hint-bottom"}>{props.hintBottom}</div>
        )}
        {renderError(state, props, t)}
      </div>
    </>
  );
};

export default KendoEditorControl;
