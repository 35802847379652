import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
// import { useDispatch } from "react-redux";

// service
import { ForgotPasswordService } from "./../../../../../../services/hrmnet-api";

// components
import { getControlModel } from "./../../../../../../components/base-control/base-cotrol-model";
import BaseForm from "./../../../../../../components/base-form/base-form";
// import { OnLogout } from "./../../../../../../services/utils/index";

//redux
import { store } from "./../../../../../../redux/store";

//const
import {
  LOGIN_OPTION,
  QUERY_PARAMS,
} from "./../../../../../../constants/index";
import { MessageSwitch } from "./../../../../../../services/utils/message";
import { GetParamValue } from "../../../../../../utils/utils";
import { LoginLink } from "../../../../../../services/utils";
import { CancelBtn } from "../utils";
import { resetAuthInfo } from "../../../../../../redux/actions/auth";
import { useDispatch } from "react-redux";
import { Button } from "primereact/button";
import LoginWrapper from "../../login-wrapper";

/**
 * Reset password form
 */
const ResetPassword = () => {
  // component state
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isVerified, setVerified] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [state] = useState({
    form: {
      newPassword: "",
      confirmPassword: "",
    },
  });
  let history = useHistory();
  const dispatch = useDispatch();
  let message = store.getState().global?.toast;
  const token = GetParamValue(QUERY_PARAMS.ID);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const verifyToken = async () => {
      try {
        let res =
          await ForgotPasswordService.forgotPasswordForgotPasswordVerifyToken({
            token: token,
          });
        if (res?.data?.isVerified) {
          setVerified(res?.data?.isVerified);
          setUserDetails({
            ...res?.data,
            autenticationType: res?.data?.autenticationType.toLowerCase(),
          });
        }
      } catch (err) {
      } finally {
        setLoading(false);
      }
    };
    verifyToken();
  }, [token]);

  useEffect(() => {
    if (!!isSubmitted) {
      dispatch(resetAuthInfo());
      history.push(LoginLink());
    }

    return () => setIsSubmitted(false);
  }, [isSubmitted]);

  // functions -------------------------------------------------------------
  const afterFormSubmitFn = (payload) => {
    const { res, formState } = payload;
    const messages = [];
    if (!formState.invalid) {
      if (res && res?.data && res.data?.status) {
        messages.push({
          type: "Success",
          text: t("login_passwordExpired_success"),
        });
        if (messages.length > 0) {
          MessageSwitch({ messages: messages }, message, null, i18n);
        }
      }
    }

    setIsSubmitted(res?.data?.status ?? false);
    return res?.data?.status ?? false;
  };

  const formSubmitFn = (payload) => {
    const { form } = payload;
    return ForgotPasswordService.forgotPasswordResetPassword({
      body: {
        token: token,
        ...form,
      },
    });
  };

  // render
  const renderNotVerified = () => {
    const cancelButton = () => {
      return (
        <Button
          type="cancel"
          label={t("login_back")}
          disabled={state.loading}
          style={{
            width: "50%",
            maxWidth: "100%",
            textAlign: "left",
            minHeight: "3.5rem",
            marginBottom: "1rem",
            marginTop: "1rem",
          }}
          onClick={() => setIsSubmitted(true)}
        />
      );
    };
    return (
      <>
        <div>{t("login_error_token")}</div>
        {cancelButton()}
      </>
    );
  };

  const renderResetPasswordForm = {
    controls: [
      getControlModel({
        key: "newPassword",
        label: t("login_newPassword"),
        placeholder: " ",
        type: "password",
        required: true,
        noRequiredLabel: true,
        config: {
          toggleMask: true,
          panelClassName: "ep-pwd-feedback",
        },
        showPasswordHint: true,
      }),
      getControlModel({
        key: "confirmPassword",
        label: t("login_confirmPassword"),
        type: "password",
        placeholder: " ",
        required: true,
        noRequiredLabel: true,
        config: {
          toggleMask: true,
          feedback: false, // we don't need to show feedback for confirm password
        },
        isPasswordUpdate: true,
      }),
    ],
    layout: {
      rows: [
        {
          columns: [
            {
              control: "newPassword",
            },
          ],
        },
        {
          columns: [
            {
              control: "confirmPassword",
            },
          ],
        },
        {
          config: {
            style: {
              marginBottom: "1rem",
              marginTop: "1rem",
            },
          },
          columns: [
            {
              action: {
                type: "submit",
                label: t("login_resetPassword"),
                submitingLabel: t("login_submitting"),
                config: {
                  kind: "primary",
                  style: {
                    width: "100%",
                    maxWidth: "100%",
                    textAlign: "left",
                    minHeight: "3.5rem",
                  },
                },
              },
            },
            CancelBtn(t("login_back"), () => history.push(LoginLink())),
          ],
        },
      ],
    },
    formSubmitFn,
    afterFormSubmitFn,
  };

  const renderContent = () => {
    if (isLoading) {
      return <ProgressSpinner />;
    }

    //
    if (isVerified) {
      return <BaseForm config={renderResetPasswordForm} form={state.form} />;
    } else {
      return renderNotVerified();
    }
  };

  return (
    <LoginWrapper>
      <h2>{t("login_resetPassword_title")}</h2>
      <h3 className="ep-first-login-title">
        {t("login_yourLoginEmailIs", userDetails)}{" "}
        <span className="ep-first-login-email">
          {userDetails?.autenticationType ===
          LOGIN_OPTION.LOGIN_ENABLE_USR.toLowerCase()
            ? userDetails?.employeeCode
            : userDetails?.emailId}
        </span>{" "}
      </h3>
      {renderContent()}
    </LoginWrapper>
  );
};

export default ResetPassword;
